import React from 'react';
import { useIntl } from 'react-intl';

import { Box, Header, Icon, ScrollView } from '@fhs-legacy/universal-components';
import { ActionsheetContent, ActionsheetWrapper } from 'components/action-sheet';
import { SearchBar } from 'pages/store-locator/search-bar';

import { IStoreSelectorProps } from '../types';

import { HeaderWrapper, IconWrapper, SearchBarWrapper, SearchContainer } from './styled';
import { TabView } from './tab-view';

export const StoreSelector: React.FC<React.PropsWithChildren<IStoreSelectorProps>> = ({
  onDismiss,
  updateStore,
  isOpen,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <ActionsheetWrapper
        onOpenEventMessage="Support dialog store locator"
        isOpen={isOpen}
        onClose={onDismiss}
      >
        <ActionsheetContent width="full">
          <ScrollView
            contentContainerStyle={{ flexGrow: 1 }}
            height="full"
            width="full"
            keyboardShouldPersistTaps="handled"
          >
            <HeaderWrapper>
              <IconWrapper onPress={onDismiss}>
                <Icon variant="back" color="token.icon-default" />
              </IconWrapper>
              <Header variant="headerTwo" justifyContent="center" alignItems="center">
                {formatMessage({ id: 'searchLocation' })}
              </Header>
              <Box />
            </HeaderWrapper>
            <SearchContainer>
              <SearchBarWrapper>
                <SearchBar />
              </SearchBarWrapper>
            </SearchContainer>
            <TabView onDismiss={onDismiss} updateStore={updateStore} />
          </ScrollView>
        </ActionsheetContent>
      </ActionsheetWrapper>
    </>
  );
};

export default StoreSelector;
