import { Svg, SvgPath, type SvgProps, registerSVG } from '../../../src/components/svg';

export function Instagram(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgPath
        d="m12 3.8018c2.6702 0 2.9864 0.01017 4.0408 0.05828 0.975 0.0445 1.5045 0.20741 1.857
      0.34431 0.4667 0.18141 0.7998 0.39815 1.1498 0.74806 0.3499 0.3499 0.5666 0.68307 0.748
      1.1498 0.137 0.35242 0.2999 0.88192 0.3444 1.8568 0.0481 1.0545 0.0582 1.3708 0.0582 4.041 0
      2.6701-0.0101 2.9864-0.0582 4.0408-0.0445 0.975-0.2074 1.5045-0.3444 1.8569-0.1814
      0.4668-0.3981 0.7999-0.748 1.1499-0.3499 0.3499-0.6831 0.5666-1.1498 0.748-0.3525
      0.1369-0.882 0.2998-1.857 0.3443-1.0543 0.0481-1.3706 0.0583-4.0408 0.0583-2.6703
      0-2.9865-0.0102-4.0408-0.0583-0.975-0.0445-1.5045-0.2074-1.8568-0.3443-0.46678-0.1814-0.79994-0.3981-1.1498-0.748-0.34991-0.35-0.56665-0.6831-0.74802-1.1499-0.13695-0.3524-0.29985-0.8819-0.34435-1.8568-0.04811-1.0545-0.05828-1.3708-0.05828-4.0409 0-2.6702 0.01016-2.9864 0.05828-4.0409 0.04449-0.975 0.2074-1.5045 0.34435-1.8569 0.18137-0.46673 0.3981-0.79989 0.74802-1.1498 0.34992-0.34995 0.68308-0.56666 1.1498-0.74806 0.35237-0.1369 0.88183-0.29981 1.8568-0.34431 1.0545-0.04811 1.3708-0.05828 4.0409-0.05828h-1e-4zm1e-4 -1.8018c2.7158 0 3.0564 0.01151 4.123 0.06018 1.0644 0.04858 1.7913 0.21761 2.4274 0.46483 0.6576 0.25556 1.2153 0.59749 1.7712 1.1534s0.8978 1.1136 1.1534 1.7712c0.2472 0.63611 0.4162 1.363 0.4648 2.4274 0.0487 1.0666 0.0602 1.4071 0.0602 4.123s-0.0115 3.0564-0.0602 4.123c-0.0486 1.0644-0.2176 1.7914-0.4648 2.4274-0.2556 0.6576-0.5975 1.2153-1.1534 1.7712-0.556 0.5559-1.1136 0.8978-1.7712 1.1534-0.6361 0.2472-1.363 0.4163-2.4274 0.4649-1.0666 0.0486-1.4071 0.0601-4.123 0.0601-2.7159 0-3.0564-0.0115-4.123-0.0601-1.0644-0.0486-1.7913-0.2176-2.4274-0.4649-0.65759-0.2555-1.2152-0.5975-1.7712-1.1534-0.55593-0.5559-0.89783-1.1137-1.1534-1.7712-0.24722-0.6361-0.41624-1.363-0.46483-2.4274-0.04867-1.0666-0.06014-1.4071-0.06014-4.123 0-2.7158 0.01147-3.0564 0.06014-4.123 0.04859-1.0644 0.21761-1.7913 0.46483-2.4274 0.25556-0.65759 0.59749-1.2152 1.1534-1.7712 0.55593-0.55593 1.1136-0.89784 1.7712-1.1534 0.63612-0.24722 1.363-0.41624 2.4274-0.46483 1.0666-0.04867 1.4071-0.06018 4.123-0.06018zm0 4.8648c-2.8361 0-5.1352 2.2991-5.1352 5.1352 0 2.8361 2.2991 5.1352 5.1352 5.1352 2.8361 0 5.1352-2.2991 5.1352-5.1352 0-2.8361-2.2991-5.1352-5.1352-5.1352zm0 8.4684c-1.8409 0-3.3334-1.4924-3.3334-3.3334 0-1.8409 1.4925-3.3333 3.3334-3.3333s3.3333 1.4924 3.3333 3.3333c0 1.841-1.4924 3.3334-3.3333 3.3334zm6.538-8.6717c0-0.66275-0.5372-1.2-1.2-1.2s-1.2 0.53724-1.2 1.2c0 0.66275 0.5373 1.2 1.2 1.2s1.2-0.53728 1.2-1.2z"
        fillRule="evenodd"
      />
    </Svg>
  );
}

registerSVG('Instagram', Instagram);
