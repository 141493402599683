import { type ReactElement, cloneElement } from 'react';
import { type ColorValue } from 'react-native';

import { tokens } from '../../tokens';
import { IconProps } from '../icon';
import { usePressableState } from '../pressable/pressable';

import { useButtonContext } from './button';

const buttonSizeToIconSize = {
  xl: 24,
  lg: 24,
  md: 20,
  sm: 18,
  xs: 16,
};

export type ButtonIconProps = {
  children: ReactElement<IconProps>;
  size?: number;
  color?: string;
};
export function ButtonIcon(props: ButtonIconProps) {
  const buttonContext = useButtonContext();
  const pressableState = usePressableState();

  const size = props.size || buttonSizeToIconSize[buttonContext.size];

  let color: ColorValue;

  switch (buttonContext.type) {
    case 'solid': {
      color = tokens.colors.$white;
      break;
    }

    case 'solid-inverted': {
      color = tokens.colors.$houseRedDarken;
      break;
    }

    case 'outline': {
      color = tokens.colors.$houseRedDarken;
      break;
    }

    case 'outline-subtle': {
      color = tokens.colors.$houseDark;
      break;
    }

    case 'ghost': {
      color = pressableState.hovered ? tokens.colors.$houseRedDarken : tokens.colors.$houseDark;
      break;
    }

    case 'unstyled': {
      color = tokens.colors.$houseDark;
      break;
    }
  }

  if (buttonContext.disabled) {
    color = tokens.colors.$blackOpacity30;
  }

  if (props.color) {
    color = props.color;
  }

  return cloneElement(props.children, { size, color });
}
