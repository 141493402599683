import { Box, Header, Text } from '@fhs-legacy/universal-components';
import BlockRenderer from 'components/sanity-block-renderer';
import { theme } from 'styles/configure-theme';

export const UnknownWidget = Box;

export const Header2 = Header.withConfig<{ pageSlug?: string }>({
  fontSize: {
    base: 'lg',
    desktop: '2xl',
  },
  fontFamily: 'Montserrat',
  fontWeight: 600,
  margin: 0,
  marginTop: '$5',
  marginBottom: '$3',
});

export const Header1 = Header.withConfig({
  variant: 'headerOne',
  fontWeight: 'light',
  padding: 0,
  paddingTop: '$8',
  margin: 0,
  marginBottom: '$2',
  fontSize: '4xl',
});

export const Header3 = Header.withConfig({
  fontSize: {
    base: 'md',
    desktop: 'lg',
  },
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  margin: 0,
  marginBottom: 0,
});

export const Header4 = Header.withConfig({
  variant: 'headerFour',
  fontSize: 'lg',
  fontWeight: 'light',
  padding: 0,
  paddingTop: '$8',
  margin: 0,
  marginBottom: '$2',
});

export const Header5 = Header.withConfig({
  variant: 'headerFour',
  fontSize: 'lg',
  fontWeight: 'light',
  padding: 0,
  paddingTop: '$8',
  margin: 0,
  marginBottom: '$2',
});

export const Paragraph = Text.withConfig<{ pageSlug?: string }>({
  variant: 'copyOne',
  fontSize: {
    base: 'sm',
    desktop: 'md',
  },
  lineHeight: 'lg',
});

export const BlockWidgetContainer = BlockRenderer.withConfig({
  marginBottom: '$4',
  marginX: 'auto',
  width: '90%',
  maxWidth: '730px',
});

export const ListItemNumber = Text;

export const ListItem = Text.withConfig({
  variant: 'copyOne',
  fontSize: {
    base: 'md',
    desktop: 'xl',
  },
  lineHeight: 'md',
  marginTop: '$2',
});

export const blockWidgetStrongProps = {
  lineHeight: 'lg',
};

export const blockWidgetLinkProps = {
  color: theme.token('text-link-hover'),
};
