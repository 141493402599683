// epxo has a bug in expo-tracking-transparency that prevents it from being included in web.
// so we need to polyfill it for now

export function getTrackingPermissionsAsync() {
  return Promise.resolve({ granted: true });
}

export enum PermissionStatus {
  /**
   * User has granted the permission.
   */
  GRANTED = 'granted',
  /**
   * User hasn't granted or denied the permission yet.
   */
  UNDETERMINED = 'undetermined',
  /**
   * User has denied the permission.
   */
  DENIED = 'denied',
}

export function requestTrackingPermissionsAsync() {
  return Promise.resolve({
    granted: true,
    expires: 'never',
    canAskAgain: true,
    status: PermissionStatus.GRANTED,
  });
}
