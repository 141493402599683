import { keyBy } from 'lodash';
import { useEffect, useMemo } from 'react';

import { ICartEntry } from '@rbi-ctg/menu';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';

/**
 * Internal helper function to use the same logic on the applied rewards filtering.
 */
const makeExistsInArrayByKey = <T extends object, K extends keyof T>(arr: T[], key: K) => {
  const arrMapByKey = keyBy(arr, key);
  const existsInArrayByKey = (value: string) => Boolean(arrMapByKey[value]);

  return existsInArrayByKey;
};

/**
 * This hook prevents sending wrong data to the price function.
 * * The applied reward can be orphaned from cart entry by handy data manipulation.
 */
export const useSanitizedRewards = ({ cartEntries }: { cartEntries: ICartEntry[] }) => {
  const dispatch = useAppDispatch();
  const appliedLoyaltyRewardsArray = useAppSelector(
    selectors.loyalty.selectAppliedLoyaltyRewardsArray
  );

  // it is a mimic of `appliedLoyaltyRewardsArray`, it returns a new object
  // only when rewards were filtered out
  const rewardsApplied = useMemo(() => {
    if (!appliedLoyaltyRewardsArray || !appliedLoyaltyRewardsArray.length) {
      return appliedLoyaltyRewardsArray;
    }

    const existRewardCartEntry = makeExistsInArrayByKey(cartEntries, 'cartId');

    // filter the rewards that have a cartEntry associated to it
    const validRewards = appliedLoyaltyRewardsArray.filter(entry =>
      existRewardCartEntry(entry.cartId)
    );

    const hasFilteredRewards = appliedLoyaltyRewardsArray.length !== validRewards.length;

    return hasFilteredRewards ? validRewards : appliedLoyaltyRewardsArray;
  }, [appliedLoyaltyRewardsArray, cartEntries]);

  useEffect(() => {
    if (
      !!rewardsApplied &&
      !!appliedLoyaltyRewardsArray &&
      rewardsApplied.length !== appliedLoyaltyRewardsArray.length
    ) {
      const existsInRewardsApplied = makeExistsInArrayByKey(rewardsApplied, 'cartId');

      const rewardCartIdsToRemove = appliedLoyaltyRewardsArray.reduce(
        (rewardCartIdsToFilter: string[], { cartId }) => {
          if (!existsInRewardsApplied(cartId)) {
            rewardCartIdsToFilter.push(cartId);
          }
          return rewardCartIdsToFilter;
        },
        []
      );

      dispatch(actions.loyalty.removeAppliedRewards({ cartIds: rewardCartIdsToRemove }));
    }
  }, [appliedLoyaltyRewardsArray, dispatch, rewardsApplied]);

  return rewardsApplied;
};
