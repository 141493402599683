import { useField } from 'formik';

import { addWithConfig } from '../../utils';
import { usePropsWithFormikFormControlName } from '../formik-form-control';
import { Switch } from '../switch';

import type { FormikSwitchProps } from './types';

export const FormikSwitch = (props: FormikSwitchProps) => {
  const { name, switchProps, ...rest } = usePropsWithFormikFormControlName(props);
  const [field, meta, helpers] = useField(name);
  const isInvalid = Boolean(meta.error && meta.touched);
  return (
    <Switch
      isInvalid={isInvalid}
      isChecked={field.value}
      value={field.value}
      onToggle={() => {
        helpers.setValue(!field.value);
        helpers.setTouched(true);
      }}
      {...rest}
      {...switchProps}
    />
  );
};

export default addWithConfig(FormikSwitch);
