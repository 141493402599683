import { StyleSheet, View, type ViewProps } from 'react-native';

export type YStackProps = ViewProps;

export function YStack({ style, ...props }: YStackProps) {
  return <View {...props} style={[styles.ystack, style]} />;
}

const styles = StyleSheet.create({
  ystack: {
    flexDirection: 'column',
  },
});
