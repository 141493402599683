import { Link } from 'expo-router';
import { useIntl } from 'react-intl';

import { Button, IconAdd } from '@fhs/ui';
import { routes } from 'utils/routing';

export const AddMoreItemsButton = () => {
  const { formatMessage } = useIntl();

  return (
    <Link href={routes.menu} asChild>
      <Button type="outline" size="md">
        <Button.Icon>
          <IconAdd />
        </Button.Icon>
        <Button.Text>{formatMessage({ id: 'addMoreItems' })}</Button.Text>
      </Button>
    </Link>
  );
};
