import { CognitoUserPool } from 'amazon-cognito-identity-js';

import { getConfigValue } from '../environment';

import storageSync from './storage';
import { ICognitoStorageWithExclusionKeys } from './types';

/**
 * @class
 * The cognito class provides helper methods for configuring and interacting
 * with cognito services
 */
export class Cognito {
  public static storage: ICognitoStorageWithExclusionKeys =
    storageSync as ICognitoStorageWithExclusionKeys;

  private static userPoolEndpoint() {
    const region = getConfigValue({ key: 'aws', defaultValue: {} }).region;
    return `https://cognito-idp.${region}.amazonaws.com/`;
  }

  public static userPool() {
    const { userPoolClientId, userPoolId } = getConfigValue({
      key: 'aws',
      defaultValue: {},
    });

    return new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: userPoolClientId,
      endpoint: Cognito.userPoolEndpoint(),
      Storage: storageSync as ICognitoStorageWithExclusionKeys,
    });
  }
}

export default Cognito;
