import { useState } from 'react';
import { StyleSheet } from 'react-native';
import { useMaskedInputProps } from 'react-native-mask-input';

import {
  FormControlTextInput,
  IconDollar,
  RadioGroup,
  Text,
  XStack,
  YStack,
  tokens,
} from '@fhs/ui';
import { useIsDesktopBp } from '@fhs-legacy/frontend/src/hooks/breakpoints';
import { useUIContext } from '@fhs-legacy/frontend/src/state/ui';
import { getCurrencyMask } from '@fhs-legacy/frontend/src/utils/masks';

import { PickUpTipOption } from './pick-up-tip-option';

export type TipOption = {
  value: string;
  label: string;
};

type PickUpTipSelectorProps = {
  value: string;
  otherValue?: string;
  pickUpTipsCents: number;
  tipOptions: TipOption[];
  errorMessage?: string;
  onSelect: (value: string) => void;
  onChangeOtherValue: (value: string) => void;
};

export function PickUpTipSelector({
  tipOptions,
  value,
  otherValue,
  pickUpTipsCents,
  errorMessage,
  onSelect,
  onChangeOtherValue,
}: PickUpTipSelectorProps) {
  const { currencySymbol, formatCurrencyForLocale } = useUIContext();

  // Make sure persisted value is formatted to apply the mask properly
  const initialMaskedTipValue = otherValue ? otherValue.replace(/^0+/, '').padStart(3, '0') : '';

  const [maskedTip, setMaskedTip] = useState(initialMaskedTipValue);

  const isDesktop = useIsDesktopBp();

  const { onChangeText, value: maskedValue } = useMaskedInputProps({
    value: maskedTip,
    onChangeText: (_, unmasked) => {
      setMaskedTip((+unmasked / 100).toFixed(2));
      onChangeOtherValue(unmasked);
    },
    mask: getCurrencyMask(currencySymbol ?? ''),
  });

  if (isDesktop) {
    return (
      <XStack style={styles.container}>
        <YStack style={styles.desktopLeftWrapper}>
          <XStack style={styles.tipSelectorDesktop}>
            <Text weight="semibold" style={styles.tipText}>
              Tip:
            </Text>
            <RadioGroup style={styles.radioGroupDesktop} value={value} onChange={onSelect}>
              {tipOptions.map(tip => (
                <RadioGroup.Item value={tip.value} label={tip.label} key={tip.value}>
                  <PickUpTipOption text={tip.label} />
                </RadioGroup.Item>
              ))}
              <RadioGroup.Item value="other" label="Other">
                <PickUpTipOption text="Other" />
              </RadioGroup.Item>
            </RadioGroup>
          </XStack>
          {value === 'other' && (
            <YStack style={styles.inputContainerDesktop}>
              <Text weight="semibold">Tip Amount</Text>
              <FormControlTextInput
                placeholder="e.g. $10.50"
                onChangeText={onChangeText}
                value={maskedValue}
                keyboardType="number-pad"
                rightIcon={<IconDollar size={24} color={tokens.colors.$blackOpacity30} />}
                maxLength={7} // Considering spaces and $
                error={errorMessage}
              />
            </YStack>
          )}
        </YStack>
        <YStack style={styles.desltopRightWrapper}>
          <Text weight="semibold" style={styles.tipText}>
            + {formatCurrencyForLocale(pickUpTipsCents)}
          </Text>
        </YStack>
      </XStack>
    );
  }

  return (
    <>
      <XStack style={styles.tipRow} testID="tip-amount">
        <Text weight="semibold" style={styles.tipText}>
          Tip
        </Text>
        <Text weight="semibold" style={styles.tipText}>
          + {formatCurrencyForLocale(pickUpTipsCents)}
        </Text>
      </XStack>
      <YStack style={styles.container}>
        <RadioGroup style={styles.radioGroup} value={value} onChange={onSelect}>
          {tipOptions.map(tip => (
            <RadioGroup.Item value={tip.value} label={tip.label} key={tip.value}>
              <PickUpTipOption text={tip.label} />
            </RadioGroup.Item>
          ))}
          <RadioGroup.Item value="other" label="Other">
            <PickUpTipOption text="Other" />
          </RadioGroup.Item>
        </RadioGroup>
        {value === 'other' && (
          <YStack style={styles.inputContainer}>
            <Text weight="semibold">Tip Amount</Text>
            <FormControlTextInput
              placeholder="e.g. $10.50"
              onChangeText={onChangeText}
              value={maskedValue}
              keyboardType="number-pad"
              rightIcon={<IconDollar size={24} color={tokens.colors.$blackOpacity30} />}
              maxLength={7} // Considering spaces and $
              error={errorMessage}
            />
          </YStack>
        )}
      </YStack>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    flex: 1,
  },
  radioGroup: {
    flexDirection: 'row',
    gap: 8,
    justifyContent: 'space-between',
    flex: 1,
  },
  radioGroupDesktop: {
    flexDirection: 'row',
    gap: 16,
    flex: 1,
  },
  inputContainer: {
    marginTop: 20,
    gap: 8,
  },
  inputContainerDesktop: {
    flex: 1,
    gap: 4,
    marginTop: 20,
  },
  tipSelectorDesktop: {
    alignItems: 'center',
    gap: 16,
  },
  tipContainer: {
    marginTop: 8,
  },
  tipRow: {
    justifyContent: 'space-between',
    marginBottom: 8,
    marginTop: 20,
  },
  tipText: {
    fontSize: 14,
    lineHeight: 18,
  },
  desktopLeftWrapper: {
    flex: 1,
  },
  desltopRightWrapper: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
});
