import { useMemo } from 'react';

import { IBaseItem, ISanityItem } from '@rbi-ctg/menu';
import { ServiceMode } from 'generated/rbi-graphql';
import { useFeatureMenu } from 'hooks/use-feature-menu';
import { useMenuOptionsContext } from 'state/menu-options';
import { useOrderContext } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';

import { existsInEntries } from './utils';

export const useMenuFeatureUpsell = () => {
  const { serviceMode } = useServiceModeContext();
  const { filterForAvailability } = useMenuOptionsContext();
  const { cartEntries } = useOrderContext();

  const { featureMenu, featureMenuLoading } = useFeatureMenu();

  const upsellType: string = useMemo(() => {
    switch (serviceMode) {
      case ServiceMode.DELIVERY:
        return 'upsellItemsAtCheckoutDelivery';
      case ServiceMode.CATERING_PICKUP:
      case ServiceMode.CATERING_DELIVERY:
        return 'upsellItemsAtCheckoutCatering';
      default:
        return 'upsellItemsAtCheckoutRestaurant';
    }
  }, [serviceMode]);

  const items: IBaseItem[] = useMemo(
    () =>
      (featureMenu?.[upsellType] || [])
        .filter(
          (item: IBaseItem) =>
            // Check if doesn't exists in the cart
            !existsInEntries(item, cartEntries) &&
            // Check if is available
            !!filterForAvailability(item as ISanityItem)
        )
        .slice(0, 2),
    [cartEntries, featureMenu, filterForAvailability, upsellType]
  );

  return {
    items,
    loading: featureMenuLoading,
  };
};
