import React from 'react';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { CARD_ALIAS_TRANSLATION_ID } from 'utils/gift-card/constants';
import { routes } from 'utils/routing';

export default () => {
  const { formatMessage } = useIntl();
  return (
    <ActionButton
      // @ts-expect-error TS(2322) FIXME: Type '{ children: string; transparent: true; fullW... Remove this comment to see the full error message
      transparent
      fullWidth
      variant={ActionButtonVariants.OUTLINE}
      to={routes.transferGiftCard}
      testID="transfer-gift-card"
    >
      {formatMessage(
        { id: 'transferBalanceFromPrepaidCard' },
        { cardAlias: formatMessage({ id: CARD_ALIAS_TRANSLATION_ID }) }
      )}
    </ActionButton>
  );
};
