import * as React from 'react';

import { Flex } from '@fhs-legacy/universal-components';
import { StoreCardButtonOptions } from 'state/launchdarkly/variations';
import { designTokens } from 'styles/constants/design-tokens';
import { STORE_LOCATOR_STORE_LIST_ORDER_BUTTON } from 'utils/test-ids';

import { StoreActionsButton, StoreActionsCard, StoreActionsError } from './components';
import { IStoreActionsViewProps } from './types';

const TEST_IDS = {
  [StoreCardButtonOptions.INFO]: '', // not tested
  [StoreCardButtonOptions.OFFERS]: '', // not tested
  [StoreCardButtonOptions.ORDER]: STORE_LOCATOR_STORE_LIST_ORDER_BUTTON,
};

export const StoreActionsView: React.VFC<IStoreActionsViewProps> = ({ options, message }) => {
  return (
    <Flex background={designTokens['background-default']}>
      <StoreActionsCard>
        {options.map(({ id, disabled, icon, text, onClick, loading }) => (
          <StoreActionsButton
            testID={TEST_IDS[id]}
            dd-action-name={text}
            key={id}
            disabled={!!disabled && !!message}
            icon={icon}
            text={text}
            onPress={onClick}
            loading={loading}
          />
        ))}
      </StoreActionsCard>

      {message && <StoreActionsError message={message} />}
    </Flex>
  );
};
