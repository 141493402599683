import {
  ScrollView as RnScrollView,
  type ScrollViewProps as RnScrollViewProps,
  StyleSheet,
} from 'react-native';

export type ScrollViewProps = Omit<RnScrollViewProps, 'contentContainerStyle'> & {
  // No margins should be applied to the contentContainer. Use padding instead.
  // Applying margins can make some of the content inaccessible via scrolling.
  contentContainerStyle?: Omit<
    RnScrollViewProps['contentContainerStyle'],
    | 'margin'
    | 'marginVertical'
    | 'marginHorizontal'
    | 'marginTop'
    | 'marginRight'
    | 'marginBottom'
    | 'marginLeft'
    | 'marginStart'
    | 'marginEnd'
  >;
};

export function ScrollView(props: ScrollViewProps) {
  return (
    <RnScrollView
      // Do not bounce scroll if content is not tall enough to need scrollability
      alwaysBounceVertical={false}
      {...props}
      style={[styles.scrollView, props.style]}
      contentContainerStyle={[styles.contentContainer, props.contentContainerStyle]}
    />
  );
}

const styles = StyleSheet.create({
  scrollView: {},
  contentContainer: {
    flexGrow: 1,
    flexShrink: 0,
  },
});
