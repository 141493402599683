import React, { Dispatch, SetStateAction, useRef } from 'react';
import { useIntl } from 'react-intl';

import { Button, FormControl } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import Dialog from 'components/dialog';
import { PromoCodeError } from 'pages/loyalty/loyalty-offers/loyalty-promo-code/types';

import { StyledInput } from '../loyalty-promo-code-drawer.styled';
import { buildErrorMessageFromPromoCodeError } from '../utils';

export type ILoyaltyPromoCodeDialogProps = {
  ctaIsDisabled: boolean;
  error: PromoCodeError | null;
  heading: string;
  isOpen: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  promoCodeValue: string;
  setPromoCodeValue: Dispatch<SetStateAction<string>>;
};

export const LoyaltyPromoCodeDialog = ({
  ctaIsDisabled,
  error,
  heading,
  isOpen,
  onDismiss,
  onConfirm,
  promoCodeValue,
  setPromoCodeValue,
}: ILoyaltyPromoCodeDialogProps) => {
  const { formatMessage } = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Dialog
      testID="loyalty-promo-code-dialog"
      showDialog={isOpen}
      heading={heading}
      onDismiss={onDismiss}
      bodyComponent={
        <FormControl isInvalid={!!error}>
          <StyledInput
            ref={inputRef}
            value={promoCodeValue}
            onChangeText={setPromoCodeValue}
            _focus={{
              borderColor: error ? 'token.border-color-error' : undefined,
            }}
          />
          {!!error && (
            <FormControl.ErrorMessage>
              {buildErrorMessageFromPromoCodeError(error, formatMessage)}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
      }
      actions={
        <Button.Group>
          <ActionButton fullWidth onPress={onConfirm} mb="$2" disabled={ctaIsDisabled}>
            {formatMessage({ id: 'apply' })}
          </ActionButton>
          <ActionButton fullWidth onPress={onDismiss} variant={ActionButtonVariants.TEXT_ONLY}>
            {formatMessage({ id: 'cancel' })}
          </ActionButton>
        </Button.Group>
      }
      modalAppearanceEventMessage="Apply Promo Code Dialog"
    />
  );
};

export default LoyaltyPromoCodeDialog;
