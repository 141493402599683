import { useLocalSearchParams } from 'expo-router';

enum RewardRedemptionMode {
  MobileOrder = 'mobile',
  InStore = 'in-store',
}

export const useRewardsFlow = () => {
  const { rewardRedemptionMode } = useLocalSearchParams<{
    rewardRedemptionMode: RewardRedemptionMode;
  }>();
  const mobileOrderRedemptionSearchParams = {
    rewardRedemptionMode: RewardRedemptionMode.MobileOrder,
  };
  const inStoreRedemptionSearchParams = { rewardRedemptionMode: RewardRedemptionMode.InStore };
  const canUseMobileRedemption =
    !rewardRedemptionMode || rewardRedemptionMode === RewardRedemptionMode.MobileOrder;
  const canUseInStoreRedemption =
    !rewardRedemptionMode || rewardRedemptionMode === RewardRedemptionMode.InStore;
  return {
    rewardRedemptionMode,
    mobileOrderRedemptionSearchParams,
    inStoreRedemptionSearchParams,
    canUseMobileRedemption,
    canUseInStoreRedemption,
  };
};
