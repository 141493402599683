import { Dimensions } from 'react-native';

import { Box, HStack, Header, ScrollView, Text } from '@fhs-legacy/universal-components';

export const DeliveryCardContainer = Box.withConfig({
  alignItems: 'center',
  width: 'full',

  _text: {
    textAlign: 'left',
  },

  backgroundColor: {
    base: Styles.color.grey.five,
    desktop: 'none',
  },
});

export const Card = Box.withConfig({
  padding: '22px',
  margin: 0,
  marginBottom: '$4',
  width: 'full',
  backgroundColor: Styles.color.white,
  borderRadius: 10,

  style: {
    shadowColor: Styles.shadowColor,
    shadowOffset: {
      width: 0,
      height: 0.5,
    },
    shadowRadius: 1.5,
    shadowOpacity: 1,
  },
});

export const ETA = Text.withConfig({
  fontSize: 'sm',
  paddingX: 0,
  paddingY: '$3',
  marginTop: '-12px',
  marginRight: 0,
  marginBottom: '$3',
  marginLeft: 0,
  borderBottomWidth: 1,
  borderBottomColor: Styles.color.grey.four,
});

export const CardHeading = Header.withConfig({
  marginBottom: '$4',
  variant: 'headerThree',
});

export const TextGroup = Box.withConfig({
  borderBottomWidth: 1,
  borderBottomColor: Styles.color.grey.four,
  paddingBottom: '$4',
  marginBottom: '$4',
});

export const CardText = Text.withConfig({
  fontSize: 'sm',
  margin: 0,
});

export const FeeDeliveryItem = Text.withConfig({
  fontSize: 'sm',
  margin: 0,
  textTransform: Styles.textTransform.headlines,
});

export const TotalRow = HStack.withConfig({
  justifyContent: 'space-between',

  _text: {
    lineHeight: 'xl',
  },
});

export const FixedMapWindow = Box.withConfig({
  height: Dimensions.get('screen').height / 5,
  maxHeight: '20%',
  width: 'full',
  marginBottom: '$6',
});

export const StyledOrderIssues = Box.withConfig({
  backgroundColor: Styles.color.white,
  margin: 'auto',
  width: 'full',
  padding: '$4',
  marginBottom: '$4',
  borderRadius: 10,
  style: {
    shadowColor: Styles.shadowColor,
    shadowOffset: {
      width: 0,
      height: 0.5,
    },
    shadowRadius: 1.5,
    shadowOpacity: 1,
  },
});

export const DeliveryConfirmationRoot = ScrollView.withConfig({
  width: 'full',
});
export const ScrollContainer = Box;

export const FutureOrderTimeCardWrapper = Box.withConfig({
  marginBottom: '$4',
});
