import { IBackendCartEntries } from '@rbi-ctg/menu';
import { IDeliveryAddress } from 'generated/rbi-graphql';

export type FavoriteStore = {
  storeId: string;
  storeNumber?: string;
};
export type FavoriteStores = Array<FavoriteStore>;

export enum BooleanAsString {
  True = 'true',
  False = 'false',
}

export type CommunicationPreferences = Array<{
  id: string;
  value: string | BooleanAsString.True | BooleanAsString.False;
}>;

export type UserDetails = {
  cognitoId: string;
  thLegacyCognitoId?: string;
  loyaltyId?: string;
  details: {
    dob: string;
    email: string;
    emailVerified: boolean;
    name: string;
    phoneNumber: string;
    phoneVerified: boolean;
    promotionalEmails: boolean;
    isoCountryCode: string;
    zipcode: string;
    defaultReloadAmt: number;
    defaultAccountIdentifier: string;
    defaultFdAccountId: string;
    defaultPaymentAccountId: string;
    defaultScanAndPayAccountIdentifier: string;
    autoReloadEnabled: boolean;
    autoReloadThreshold: number;
    deliveryAddresses: Array<IDeliveryAddress> | null;
    communicationPreferences: CommunicationPreferences | null;
    favoriteStores: FavoriteStores | null;
    loyaltyTier?: string | null;
  };
  uniquePurchasedItems?: IBackendCartEntries[];
};
