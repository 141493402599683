import { Box, Header, ScrollView, Divider as UclDivider } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

const bgColor = {
  backgroundColor: theme.token('background-default'),
};

export const Container = Box.withConfig({
  width: '90%',
  maxWidth: 390,
  alignSelf: 'center',
  marginBottom: '$4',
});

export const ModalHead = Box.withConfig({
  width: 'full',
  display: 'flex',
  flexGrow: 0,
  flexShrink: 0,
  minHeight: '88px',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottomWidth: 1,
  borderBottomColor: theme.token('border-color-default'),

  flexDirection: {
    desktop: 'row',
  },
});

export const StyledHeading = Header.withConfig({
  variant: 'headerOne',
  margin: 0,
  padding: 0,
  marginTop: '$4',
});

export const Block = Box;

export const Divider = UclDivider.withConfig({
  marginY: '$4',
  orientation: 'horizontal',
  thickness: '1',
});

export const OrderDetailsScrollContainer = ScrollView.withConfig({
  flexGrow: 1,
  flexShrink: 1,
  height: '100%',
  paddingTop: '$4',
  position: 'relative',
});

export const Footer = Box.withConfig({
  width: 'full',
  flexGrow: 0,
  flexShrink: 0,
  bottom: 0,
  borderTopWidth: 1,
  borderTopColor: theme.token('border-color-default'),
  paddingX: 0,
  paddingY: '$4',
  ...bgColor,
  _text: {
    color: theme.token('text-default'),
  },
});

export const LoadingContainer = Box.withConfig({
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
});

/*
 * this duplicates the amount of spacing around an ActionLink
 * so that the loading indicator can transition to an ActionLink
 * without elements around it shifting on the page
 */
export const NeedHelpButtonLoadingIndicatorWrapper = Box.withConfig({
  padding: '$3',
  borderWidth: 1,
  borderColor: 'transparent',
});

// TH exports a different style for this component
export const StyledCartEntries = Block;
