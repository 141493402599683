{
  "cal": "Cals",
  "cancelledOrderAction": "We’re sorry, but it looks like your order has been cancelled and will be refunded within the next few business days. If you’d like to place your order again you can use the button below.",
  "deliveryFeeDescription": "Delivery fee varies for each restaurant based on your location and other factors.",
  "deliveryTerms": "Delivery Terms and Fees Apply. Click {link} for Details.",
  "errorDialogFavoriteStoreMessage": "To set a favourite store, first sign up or log in to an account.",
  "euNutritionValues": "{calories} / {joules}",
  "favorite": "Favourites",
  "favoriteOrders": "Favourite Orders",
  "favoriteRemoveAllModalBody": "Removing all the items in this order will delete your favourite. Are you sure you want to delete this order?",
  "itemFavorited": "Item saved to favourites!",
  "itemFavoritedError": "Oops! There was an error with your favourite. Please try again later.",
  "itemFavoritedRemove": "Item removed from favourites!",
  "minimumPointsRequired": "Minimum points required 50",
  "nameYourFavorite": "Name Your Favourite",
  "noFavoritesHeader": "Favourites",
  "noFavoritesSubheader": "Favourite an item from your cart or recents to save it as a favourite.",
  "orderConfirmationCheckoutInstruction": "Look for the Rapid Rescue signage in the restaurant to pick up your order.",
  "paymentNotSupported": "Please try selecting another payment method or another nearby restaurant.",
  "pointsExpireMonthly": "Points older than 12 months will expire at the end of each month.",
  "prepareNow": "Prepare Now",
  "removeFavorite": "Remove from Favourites?",
  "removeFavoriteConfirm": "Are you sure you want to remove this favourite?",
  "removeFavoriteOrderSucess": "Order removed from favourites.",
  "removeFavoriteSucess": "Your favourite has been removed.",
  "removeItemFavorites": "Remove This Item From Favourites?",
  "removeItemFavoritesConfirm": "Are you sure you want to remove this favourite?",
  "removeRestaurantFromFavorites": "Remove this restaurant from favourites",
  "saveFavoriteOrderButton": "Save In Favourites",
  "saveFavoriteOrderSucess": "Order saved to favourites!",
  "saveFavoriteOrderUnsavedBody": "Do you want to continue without saving your changes in the favourite order?",
  "serviceFeeDescription": "Service Fee consists of {percent}% of your order before taxes.",
  "setRestaurantAsFavorite": "Set this restaurant as a favourite",
  "shareMyLocation": "Share my location",
  "smallCartFeeDescription": "This fee only applies to orders less than {amount}. Add items to remove the fee.",
  "storeLocatorEmptyStateFavoritesNoAuthBody": "To set a favourite location, first log in or sign up for an account.",
  "storeLocatorEmptyStateFavoritesNoAuthTitle": "Login to Save Favourites",
  "storeLocatorEmptyStateFavoritesNoStoresBody": "Tap the heart on a store to save it as a favourite.",
  "storeLocatorEmptyStateFavoritesNoStoresTitle": "No Favourites Yet",
  "sulphurDioxide": "Sulphites",
  "theseAreOurFavorite": "These are our favourite",
  "unauthenticatedFavoritesHeader": "Log In to Save Favourites",
  "unauthenticatedFavoritesSubtext": "Sign up or log in to save your favourites.",
  "yourFavorites": "Favourites",
  "zipCode": "Postal Code",
  "zipCodeError": "Please enter either a valid Canadian postal code",
  "zipCodeInvalid": "Postal Code Invalid",
  "zipCodeRequiredError": "Postal code is a required field"
}
