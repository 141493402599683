import { isEqual, uniqWith } from 'lodash';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@fhs-legacy/universal-components';
import Currency from 'components/currency';
import { DiscountTypes } from 'enums/menu';
import { geographicalFeeLabelIdMap } from 'pages/account/account-orders/constants';
import { getFeesByGroup, getStandardizedName } from 'pages/cart/your-cart/totals/utils';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useUIContext } from 'state/ui';
import { computeDeliveryFee, computeOtherDiscountAmount } from 'utils/cart/helper';

import getRewardsDiscount from './get-rewards-discount';
import {
  FeeDeliveryItem,
  ReceiptDetail,
  ReceiptDetailsText,
  RewardsDiscountDetail,
  SDiscount,
} from './receipt-details.styled';
import { IOrderFooterProps } from './types';

const ReceiptDetails: FC<React.PropsWithChildren<IOrderFooterProps>> = ({ order }) => {
  const { formatCurrencyForLocale: currencyFormatter } = useUIContext();
  const { delivery } = order;
  const { subTotalCents, taxCents, totalCents, pickUpTipsCents } = order.cart;
  // There is a bug with having multiple object in discount array stored in cartEntry
  // https://rbictg.atlassian.net/browse/FHS-1175 for investigation
  const allOfferDiscounts = uniqWith(order.cart.discounts || [], isEqual);
  const savingOfferCents = allOfferDiscounts?.reduce(
    (acc, discount) =>
      discount.name === DiscountTypes.COMBO_AND_OFFER_DISCOUNTS ? acc + discount.value : acc,
    0
  );
  const { formatMessage } = useIntl();

  const enableHideTaxLine = useFlag(LaunchDarklyFlag.ENABLE_HIDE_TAX_LINE);
  const enableFeesOnOrderReceipt = useFlag(LaunchDarklyFlag.ENABLE_FEES_ON_ORDER_RECEIPT);
  const enableDiscountsOnOrderReceipt = useFlag(LaunchDarklyFlag.ENABLE_DISCOUNTS_ON_ORDER_RECEIPT);

  const baseDeliveryFeeCents = delivery?.feeCents || 0;
  const deliveryFeeDiscountCents = delivery?.feeDiscountCents || 0;
  const serviceFeeCents = delivery?.serviceFeeCents || 0;
  const smallCartFeeCents = delivery?.smallCartFeeCents || 0;
  const geographicalFeeCents = delivery?.geographicalFeeCents || 0;
  const discounts = useMemo(() => order.cart.discounts || [], [order.cart.discounts]);
  const dropoffState = delivery?.dropoff?.state;

  // Use a custom label for geographical delivery fees as required
  const geographicalFeeLabelId =
    dropoffState && geographicalFeeLabelIdMap.hasOwnProperty(dropoffState)
      ? geographicalFeeLabelIdMap[dropoffState]
      : 'geographicalFee';

  const finalDeliveryFeeCents = computeDeliveryFee({
    feeCents: baseDeliveryFeeCents,
    feeDiscountCents: deliveryFeeDiscountCents,
    serviceFeeCents,
    smallCartFeeCents,
    geographicalFeeCents,
  });
  const isFreeDelivery = finalDeliveryFeeCents === 0;
  const rewardsDiscountCents = useMemo(
    () => getRewardsDiscount({ discounts, enableDiscountsOnOrderReceipt }),
    [discounts, enableDiscountsOnOrderReceipt]
  );

  const otherDiscountAmount = computeOtherDiscountAmount(discounts);

  const fees = order.cart.fees ?? [];
  const itemFees = getFeesByGroup(fees);

  return (
    <Box>
      {enableFeesOnOrderReceipt &&
        !!itemFees &&
        Object.entries(itemFees).map(([name, entryCents]: [string, number], index) => (
          <ReceiptDetail key={index}>
            <ReceiptDetailsText>
              {formatMessage({ id: `${getStandardizedName({ name })}Fee` })}
            </ReceiptDetailsText>

            <ReceiptDetailsText>{currencyFormatter(entryCents)}</ReceiptDetailsText>
          </ReceiptDetail>
        ))}
      {order.delivery && (
        <Box>
          <ReceiptDetail>
            <ReceiptDetailsText>{formatMessage({ id: 'deliveryFee' })}:</ReceiptDetailsText>
            {isFreeDelivery ? (
              <FeeDeliveryItem>
                {formatMessage({ id: 'free' })}
                &nbsp;
                <SDiscount>
                  <Currency amount={deliveryFeeDiscountCents} />
                </SDiscount>
              </FeeDeliveryItem>
            ) : (
              <ReceiptDetailsText>{currencyFormatter(finalDeliveryFeeCents)}</ReceiptDetailsText>
            )}
          </ReceiptDetail>
          {geographicalFeeCents > 0 && (
            <ReceiptDetail>
              <ReceiptDetailsText>
                {formatMessage({ id: geographicalFeeLabelId })}:
              </ReceiptDetailsText>
              <ReceiptDetailsText>{currencyFormatter(geographicalFeeCents)}</ReceiptDetailsText>
            </ReceiptDetail>
          )}
          {serviceFeeCents > 0 && (
            <ReceiptDetail>
              <ReceiptDetailsText>{formatMessage({ id: 'serviceFee' })}:</ReceiptDetailsText>
              <ReceiptDetailsText>{currencyFormatter(serviceFeeCents)}</ReceiptDetailsText>
            </ReceiptDetail>
          )}
          {smallCartFeeCents > 0 && (
            <ReceiptDetail>
              <ReceiptDetailsText>{formatMessage({ id: 'smallCartFee' })}:</ReceiptDetailsText>
              <ReceiptDetailsText>{currencyFormatter(smallCartFeeCents)}</ReceiptDetailsText>
            </ReceiptDetail>
          )}
          <ReceiptDetail>
            <ReceiptDetailsText>{formatMessage({ id: 'tip' })}:</ReceiptDetailsText>
            <ReceiptDetailsText>{currencyFormatter(order.delivery.tipCents)}</ReceiptDetailsText>
          </ReceiptDetail>
        </Box>
      )}
      {enableDiscountsOnOrderReceipt && !!otherDiscountAmount && (
        <RewardsDiscountDetail>
          <ReceiptDetailsText>{formatMessage({ id: 'otherDiscounts' })}</ReceiptDetailsText>
          <ReceiptDetailsText>{` - ${currencyFormatter(otherDiscountAmount)}`}</ReceiptDetailsText>
        </RewardsDiscountDetail>
      )}
      {!!rewardsDiscountCents && (
        <RewardsDiscountDetail>
          <ReceiptDetailsText>{formatMessage({ id: 'rewardSavings' })}</ReceiptDetailsText>
          <ReceiptDetailsText>{` - ${currencyFormatter(rewardsDiscountCents)}`}</ReceiptDetailsText>
        </RewardsDiscountDetail>
      )}
      {!!savingOfferCents && (
        <RewardsDiscountDetail>
          <ReceiptDetailsText>{formatMessage({ id: 'comboOfferSavings' })}</ReceiptDetailsText>
          <ReceiptDetailsText>{` - ${currencyFormatter(savingOfferCents)}`}</ReceiptDetailsText>
        </RewardsDiscountDetail>
      )}

      <ReceiptDetail>
        <ReceiptDetailsText>{formatMessage({ id: 'subtotal' })}</ReceiptDetailsText>
        <ReceiptDetailsText>{currencyFormatter(subTotalCents)}</ReceiptDetailsText>
      </ReceiptDetail>
      {!enableHideTaxLine && (
        <ReceiptDetail>
          <ReceiptDetailsText>{formatMessage({ id: 'tax' })}</ReceiptDetailsText>
          <ReceiptDetailsText>{currencyFormatter(taxCents)}</ReceiptDetailsText>
        </ReceiptDetail>
      )}
      {!!pickUpTipsCents && (
        <ReceiptDetail>
          <ReceiptDetailsText>Tips</ReceiptDetailsText>
          <ReceiptDetailsText>{currencyFormatter(pickUpTipsCents)}</ReceiptDetailsText>
        </ReceiptDetail>
      )}

      {/* TODO Add bag fee here when available from backend. */}
      <ReceiptDetail $total>
        <ReceiptDetailsText $total>{formatMessage({ id: 'total' })}</ReceiptDetailsText>
        <ReceiptDetailsText $total>{currencyFormatter(totalCents)}</ReceiptDetailsText>
      </ReceiptDetail>
    </Box>
  );
};

export default ReceiptDetails;
