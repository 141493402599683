import { Stack } from 'expo-router';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { GestureResponderEvent } from 'react-native';

import { tokens } from '@fhs/ui';
import { Box } from '@fhs-legacy/universal-components';
import { ICartEntry } from '@rbi-ctg/menu';
import ActionButton, { ActionLink } from 'components/action-button';
import Modal, { ModalContent } from 'components/modal';
import themeModal from 'components/modal/theme';
import Picture from 'components/picture';
import { useCartContext } from 'state/cart';
import { useOffersContext } from 'state/offers';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { useUIContext } from 'state/ui';
import { routes } from 'utils/routing';

import { Container, Item, ItemContainer, ItemName, ModalMessage, TileImageWrapper } from './styled';
import theme from './theme';

interface IProps {
  isInCart?: boolean;
  items: ICartEntry[];
  onDismiss: (e: GestureResponderEvent) => void;
  onPrimaryCTAClick?: (e?: GestureResponderEvent) => void;
  secondaryCTALabelText?: string;
  onSecondaryCTAClick?: (e?: GestureResponderEvent) => void;
}

const OrderItemUnavailable = ({
  isInCart,
  items,
  onDismiss,
  onPrimaryCTAClick,
  secondaryCTALabelText,
  onSecondaryCTAClick,
}: IProps) => {
  const { formatMessage } = useIntl();
  const { resetStore } = useStoreContext();
  const { onCancelRedemption } = useOffersContext();
  const { buildImageUrl } = useUIContext();
  const useClosestContext = isInCart ? useCartContext : useOrderContext;

  const { emptyCart, selectServiceMode, removeAllFromCart, setUnavailableCartEntries } =
    useClosestContext();

  const handleRemoveUnavailableItems = useCallback(
    (e: any) => {
      removeAllFromCart(items);
      setUnavailableCartEntries([]);
      onDismiss(e);
    },
    [items, removeAllFromCart, onDismiss, setUnavailableCartEntries]
  );

  const handleStartOver = useCallback(
    (e: GestureResponderEvent) => {
      selectServiceMode(null);
      setUnavailableCartEntries([]);
      emptyCart();
      onCancelRedemption();
      resetStore();
      onDismiss(e);
    },
    [
      onDismiss,
      selectServiceMode,
      emptyCart,
      onCancelRedemption,
      resetStore,
      setUnavailableCartEntries,
    ]
  );

  const modalHeading = formatMessage({ id: 'itemsUnavailable' });
  const modalBody = formatMessage({ id: 'someItemsAreUnavailableMessage' });

  return (
    <Modal
      onDismiss={handleRemoveUnavailableItems}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Cart error: Order has unavailable items',
        modalMessage: typeof modalBody === 'string' ? modalBody : undefined,
      }}
      backgroundColor={tokens.colors.$houseLight}
      footer={
        <Box width="full" padding="$4" backgroundColor={themeModal.modalWrapperBackground}>
          <Box marginTop="$4" marginBottom="0" minWidth="auto" width="100%">
            <ActionButton
              fullWidth
              onPress={(e: GestureResponderEvent) => {
                handleRemoveUnavailableItems(e);
                onPrimaryCTAClick && onPrimaryCTAClick(e);
              }}
            >
              {formatMessage({ id: 'removeItems' })}
            </ActionButton>
          </Box>
          <Box marginTop="$4" marginBottom="0" minWidth="auto" width="100%">
            <ActionLink
              fullWidth
              variant={theme.emptyCartBtnVariant}
              to={routes.menu}
              onPress={(e: GestureResponderEvent) => {
                handleStartOver(e);
                onSecondaryCTAClick && onSecondaryCTAClick(e);
              }}
            >
              {secondaryCTALabelText ?? formatMessage({ id: 'emptyCart' })}
            </ActionLink>
          </Box>
        </Box>
      }
    >
      <ModalContent>
        <Stack.Screen options={{ title: modalHeading }} />
        <Container flex={1}>
          <ModalMessage testID="body">{modalBody}</ModalMessage>
          <ItemContainer>
            {items.map((item, index) => (
              <Item key={`${item.cartId}-${index}`} $isFirstChild={index === 0}>
                {item.image && (
                  <TileImageWrapper>
                    <Picture
                      alt=""
                      image={item.image}
                      size={'full'}
                      buildImageUrl={buildImageUrl}
                    />
                  </TileImageWrapper>
                )}
                <ItemName>{item.name}</ItemName>
              </Item>
            ))}
          </ItemContainer>
        </Container>
      </ModalContent>
    </Modal>
  );
};

export default OrderItemUnavailable;
