import React from 'react';

import { Text } from '@fhs-legacy/universal-components';
import SodiumFlag from 'components/product-detail/nutrition-information/sodium-flag';
import { useLocale } from 'state/intl';
import { REGIONS } from 'state/intl/types';

const SodiumDisclaimerUS = () => {
  const { region } = useLocale();
  if (region === REGIONS.US) {
    return (
      <Text variant="copyTwo">
        Warning: <SodiumFlag size={3} mt={-0.5} /> indicates that sodium (salt) content of this item
        is higher than the total daily recommended limit (2,300mg). High Sodium intake can increase
        blood pressure and risk of heart disease and stroke.
      </Text>
    );
  }
  return null;
};

export default SodiumDisclaimerUS;
