import { withStaticProperties } from '@fhs/utils';

import { Dropdown as _Dropdown } from './dropdown';
import { DropdownClosePaneOnChange } from './dropdown-close-pane-on-change';
import { DropdownPane } from './dropdown-pane';
import { DropdownTrigger } from './dropdown-trigger';

export const Dropdown = withStaticProperties(_Dropdown, {
  Trigger: DropdownTrigger,
  Pane: DropdownPane,
  ClosePaneOnChange: DropdownClosePaneOnChange,
});

export { useDropdownState, useDropdownSetters } from './context';

export type { DropdownProps } from './dropdown';
export type { DropdownTriggerProps } from './dropdown-trigger';
export type { DropdownPaneProps } from './dropdown-pane';
export type { DropdownClosePaneOnChangeProps } from './dropdown-close-pane-on-change';
