import { CustomEventNames, EventTypes, ICRMEventsContext } from 'state/crm-events';
import { ClickEventComponentNames } from 'state/crm-events/constants';

export const logCartContinue = (logRBIEvent: ICRMEventsContext['logRBIEvent']) => {
  logRBIEvent({
    name: CustomEventNames.CLICK_EVENT,
    type: EventTypes.Other,
    attributes: {
      component: ClickEventComponentNames.BUTTON,
      text: 'Continue in Cart',
    },
  });
};
export const logCartPlaceOrder = (logRBIEvent: ICRMEventsContext['logRBIEvent']) => {
  logRBIEvent({
    name: CustomEventNames.CLICK_EVENT,
    type: EventTypes.Other,
    attributes: {
      component: ClickEventComponentNames.BUTTON,
      text: 'Place Secure Order',
    },
  });
};
export const logCartContinueOrder = (logRBIEvent: ICRMEventsContext['logRBIEvent']) => {
  logRBIEvent({
    name: CustomEventNames.CLICK_EVENT,
    type: EventTypes.Other,
    attributes: {
      component: ClickEventComponentNames.BUTTON,
      text: 'Continue',
    },
  });
};
