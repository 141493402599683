import { selectors, useAppSelector } from 'state/global-state';

import type { LoyaltyUser } from './types';

export const useLoyaltyUser = (): {
  loyaltyUser: LoyaltyUser | null;
  loading: boolean;
  loyaltyUserId: string;
} => {
  const { loyaltyUser, loading } = useAppSelector(selectors.loyalty.selectLoyaltyUser);
  const loyaltyUserId = useAppSelector(selectors.loyalty.selectLoyaltyUserId);
  return {
    loyaltyUser,
    loading,
    loyaltyUserId,
  };
};
