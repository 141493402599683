import { format, isToday, isTomorrow } from 'date-fns';
import { useEffect, useState } from 'react';
import { ActivityIndicator, Pressable, StyleSheet, View } from 'react-native';

import { useMutation, useQuery } from '@fhs/client';
import { Button, IconCheck, IconClose, ScrollView, Text, tokens } from '@fhs/ui';

import { getOrderTimeslots, setOrderFulfillmentTime } from '../../api';

type Props = {
  onClose(): void;
};

export function CartActionPickupTimeSelector(props: Props) {
  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedSlot, setSelectedSlot] = useState('');
  const [asapTimeSlot, setAsapTimeslot] = useState('');

  // TypeScript is acting very weird on the response type of this query
  // and basically everything is nullable somehow so there is a lot of null checks
  // and force! assertions everywhere.
  const { data: days, isPending } = useQuery(getOrderTimeslots);
  const { mutate: updateFulfillmentTime, isPending: isMutating } = useMutation({
    ...setOrderFulfillmentTime,
    onSuccess() {
      props.onClose();
    },
  });

  useEffect(() => {
    if (!days) {
      return;
    }

    const firstAvailableDay =
      days.findIndex(day => (day?.options ?? []).length > 0 && day?.isDisabled === false) ?? 0;
    const firstTimeSlot = days[firstAvailableDay]?.options?.[0]?.time ?? '';
    setSelectedSlot(firstTimeSlot);
    setAsapTimeslot(firstTimeSlot);
    setSelectedDay(firstAvailableDay);
  }, [days]);

  // If there is any time slots missing in the view, show the error message

  if (isPending) {
    return <ActivityIndicator />;
  }

  const times = days?.[selectedDay]?.options! ?? [];
  const warningMessage = days?.[selectedDay]?.warningMessage;

  return (
    <>
      <View style={styles.header}>
        <Text.Heading type="three">Schedule Pickup</Text.Heading>
        <Button type="ghost" onPress={props.onClose} style={styles.closeButton}>
          <Button.Icon>
            <IconClose />
          </Button.Icon>
        </Button>
      </View>
      <Text.Heading type="three" style={styles.heading}>
        Select Date
      </Text.Heading>
      <ScrollView
        horizontal
        contentContainerStyle={styles.dayContainer}
        showsHorizontalScrollIndicator={false}
        style={styles.container}
      >
        {(days ?? []).map((slot, i) => (
          <Pressable
            aria-disabled={slot!.isDisabled}
            disabled={slot!.isDisabled}
            style={[
              styles.dayButton,
              selectedDay === i && styles.selectedDay,
              slot!.isDisabled && styles.disabledDay,
            ]}
            onPress={() => setSelectedDay(i)}
          >
            <Text.Ui
              size="xs"
              style={[
                selectedDay === i
                  ? styles.selectedFormattedRelativeDay
                  : styles.formattedRelativeDay,
                slot!.isDisabled && styles.disabledText,
              ]}
            >
              {formatRelativeDay(slot!.date)}
            </Text.Ui>
            <Text.Ui size="md" style={slot!.isDisabled && styles.disabledText}>
              {slot!.isDisabled ? 'Closed' : format(new Date(slot!.date), 'MMM d')}
            </Text.Ui>
          </Pressable>
        ))}
      </ScrollView>
      <Text.Heading type="three" style={styles.heading}>
        Select Time
      </Text.Heading>
      <ScrollView style={styles.timeslotContainer}>
        {warningMessage && (
          <Text.Ui style={styles.errorMessage} size="md">
            {warningMessage}
          </Text.Ui>
        )}
        {times.map(({ time }) => (
          <Pressable style={styles.timeslotRow} onPress={() => setSelectedSlot(time)}>
            {selectedSlot === time && <IconCheck size={18} />}
            <Text.Ui size="md">
              {time === asapTimeSlot ? 'ASAP' : format(new Date(time), 'h:mm a')}
            </Text.Ui>
          </Pressable>
        ))}
      </ScrollView>
      <View style={styles.container}>
        <Button
          loading={isMutating}
          disabled={isMutating}
          onPress={() => {
            updateFulfillmentTime({ fulfillmentTime: selectedSlot });
          }}
        >
          <Button.Text>Continue</Button.Text>
        </Button>
      </View>
    </>
  );
}

function formatRelativeDay(date: string): string {
  //add 12:00:00 to the date to avoid timezone issues
  const dateObj = new Date(date + 'T12:00:00');

  if (isToday(dateObj)) {
    return 'Today';
  }

  if (isTomorrow(dateObj)) {
    return 'Tomorrow';
  }

  // For dates within the current week, show the day name
  return format(dateObj, 'EEEE');
}

global.formatRelativeDay = formatRelativeDay;

const styles = StyleSheet.create({
  errorMessage: {
    paddingVertical: 8,
    color: tokens.colors.$houseRedDarken,
  },
  dayContainer: {
    gap: 8,
  },
  timeslotContainer: {
    height: 224,
    marginHorizontal: 16,
  },
  container: {
    paddingHorizontal: 16,
    paddingBottom: 18,
  },
  heading: {
    marginVertical: 8,
    marginHorizontal: 16,
  },
  disabledDay: {
    backgroundColor: tokens.colors.$blackOpacity04,
    borderColor: tokens.colors.$blackOpacity30,
  },
  selectedDay: {
    backgroundColor: tokens.colors.$houseRed10,
    borderColor: tokens.colors.$houseRedDarken,
  },
  disabledText: {
    color: tokens.colors.$disabledText,
  },
  formattedRelativeDay: {
    color: tokens.colors.$placeholderText,
  },
  selectedFormattedRelativeDay: {
    color: tokens.colors.$houseRedDarken,
  },
  dayButton: {
    width: 104,
    gap: 4,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity04,
    borderRadius: 8,
    paddingVertical: 12,
    paddingHorizontal: 16,
  },

  timeslotRow: {
    flexDirection: 'row',
    gap: 8,
    borderBottomColor: tokens.colors.$blackOpacity04,
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  header: {
    alignItems: 'center',
    marginBottom: 34,
  },
  closeButton: {
    position: 'absolute',
    right: 16,
    top: -10,
  },
});
