export type IDeliveryInputAddress = {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  route?: string;
  state?: string;
  streetNumber?: string;
  zip?: string;
  country?: string;
  latitude: number;
  longitude: number;
};
