import React, { useState } from 'react';

import { Button } from '@fhs-legacy/universal-components';
import useEffectOnce from 'hooks/use-effect-once';
import { EnvListContainer, HiddenEnvItem, HiddenSection } from 'pages/diagnostics/components';
import { useStoreContext } from 'state/store';
import LocalStorage from 'utils/local-storage';

const LocalStorageItemsContent = () => {
  const [localStorageData, setLocalStorageData] = useState({});
  const { resetStore } = useStoreContext();

  useEffectOnce(() => {
    LocalStorage.getAll()
      .then(data => {
        setLocalStorageData(data);
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('failed to get local storage data');
      });
  });
  return (
    <>
      <Button onPress={resetStore}>Reset selected store</Button>
      <EnvListContainer>
        {Object.entries(localStorageData).map(([key, value]) => (
          <HiddenEnvItem key={key} envKey={key} value={JSON.stringify(value)} />
        ))}
      </EnvListContainer>
    </>
  );
};

export const LocalStorageItems = () => (
  <HiddenSection title="Local Storage Items" content={<LocalStorageItemsContent />} />
);
