import React from 'react';
import { useIntl } from 'react-intl';

import { ErrorEmptyStateImage } from './images';
import { EmptyStateWrapper, HeaderText, ImageWrapper, StyledActionButtonLink } from './styled';

interface IErrorStateProps {
  onRefresh?: () => void;
}

const ErrorState: React.FC<React.PropsWithChildren<IErrorStateProps>> = ({ onRefresh }) => {
  const { formatMessage } = useIntl();

  return (
    <EmptyStateWrapper>
      <ImageWrapper>
        <ErrorEmptyStateImage />
      </ImageWrapper>
      <HeaderText>{formatMessage({ id: 'recentItemsFailed' })}</HeaderText>
      {!!onRefresh && (
        <StyledActionButtonLink onPress={onRefresh}>
          {formatMessage({ id: 'refresh' })}
        </StyledActionButtonLink>
      )}
    </EmptyStateWrapper>
  );
};

export default ErrorState;
