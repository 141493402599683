import { IItem, IItemOption } from '@rbi-ctg/menu';
import { IModifierData } from 'components/product-detail/modifier/types';
import { getModifierGroupName } from 'components/product-detail/modifier/utils';
import { getModifiersActions } from 'components/product-detail/utils';
import {
  IModifierSelectionActionWithData,
  IUserManualModifications,
  UserSelectionComboSlot,
  UserSelectionModifier,
  UserSelectionModifierMap,
} from 'state/product-wizard/types';
import { ProductWizardUtils } from 'state/product-wizard/utils';

import { DefaultModifiersMap, GroupModifiersByDisplayNameFn, ModifiersPropsMap } from './types';

const getModifierPropsSelections = (modifierSelections: UserSelectionModifierMap) => {
  return Object.values(modifierSelections).reduce((acc, { modifier }) => {
    acc[modifier._key] = modifier;
    return acc;
  }, {} as IModifierData['selections']);
};

export const groupModifiersByDisplayName: GroupModifiersByDisplayNameFn = ({
  modifiers,
  modifiersMap,
  pickerAspectExclusions,
}) => {
  return modifiers.reduce((acc: ModifiersPropsMap, modifier: IItemOption) => {
    // Do not display modifier...

    // ...if it's excluded by pickerAspect mappings
    if (pickerAspectExclusions?.has(modifier._key)) {
      return acc;
    }

    // ...if there isn't a default selection for it
    const currentModifierSelection = modifiersMap[modifier._key];
    if (!currentModifierSelection) {
      return acc;
    }

    const displayGroupName = getModifierGroupName(modifier);
    const modifierProps = {
      modifier,
      selections: getModifierPropsSelections(currentModifierSelection),
    };
    acc[displayGroupName] = [...(acc[displayGroupName] || []), modifierProps];
    return acc;
  }, {});
};

export const getModifiersDefaultOptions = (selectedItem: IItem): DefaultModifiersMap => {
  return Object.values(ProductWizardUtils.ComputeDefaultModifiersSelections(selectedItem)).reduce(
    (defaultsMap, modifierMap) => {
      Object.values(modifierMap).forEach(selectionAction => {
        defaultsMap[getModifierDefaultOptionKey(selectionAction)] =
          selectionAction.modifier.quantity;
      });
      return defaultsMap;
    },
    {} as DefaultModifiersMap
  );
};

export const getModifierDefaultOptionKey = (
  selectionAction: IModifierSelectionActionWithData
): string =>
  ProductWizardUtils.GetModifierOptionKey(
    selectionAction.option._key,
    selectionAction.modifier._key
  );

export const isDefaultSelectionAction = (
  defaultModifiersMap: DefaultModifiersMap,
  selAction: IModifierSelectionActionWithData
) =>
  defaultModifiersMap[getModifierDefaultOptionKey(selAction)]
    ? defaultModifiersMap[getModifierDefaultOptionKey(selAction)] === selAction.modifier.quantity
    : selAction.modifier.quantity === 0;

export const getUserModifications = (
  comboSlots: UserSelectionComboSlot,
  modifiers: UserSelectionModifier,
  defaultComboSlotSelections: UserSelectionComboSlot
): Omit<IUserManualModifications, 'pickerAspects'> => {
  const entries = comboSlots.entries();
  let entry = entries.next();
  const userModifications: Omit<IUserManualModifications, 'pickerAspects'> = {
    comboSlot: false,
    modifiers: false,
  };

  while (!entry.done) {
    const [key, value] = entry.value;

    if (value.selectedItem) {
      const defaultSlotItem = defaultComboSlotSelections.get(key)?.selectedItem;
      if (value.selectedItem._id !== defaultSlotItem?._id) {
        userModifications.comboSlot = true;
      }
      const defaultModifiersMap = getModifiersDefaultOptions(value.selectedItem);
      const hasModifiersChanges = getModifiersActions(modifiers[key]).some(
        selAction => !isDefaultSelectionAction(defaultModifiersMap, selAction)
      );

      if (hasModifiersChanges) {
        userModifications.modifiers = true;
      }

      if (userModifications.modifiers && userModifications.comboSlot) {
        return userModifications;
      }
    }
    entry = entries.next();
  }

  return userModifications;
};
