import { isDiscountOffer } from 'state/loyalty/utils';
import { isCombo, isPicker } from 'utils/menu';

import type { OfferValidator } from './types';
import { isDiscountOfferPluAlwaysAvailable, isOfferPluAvailable } from './utils';

export type ValidationRule<T> = (data: T) => boolean;

export const validateRequiredProperties: OfferValidator = cmsOffer => {
  return !!cmsOffer?._id && !!cmsOffer?.loyaltyEngineId;
};

// compose function to accept external parameters
export const validatePluAvailabilityForVendor: OfferValidator = (cmsOffer, { prices, vendor }) =>
  isDiscountOfferPluAlwaysAvailable({ cmsOffer, vendor }) ||
  isOfferPluAvailable({ cmsOffer, vendor, prices });

export const validateIncentiveOfferBenefitField: OfferValidator = cmsOffer => {
  return (
    // if discount the incentive is always be a discount so this check is not valid
    isDiscountOffer(cmsOffer) ||
    !!cmsOffer.incentives?.every(incentive => {
      // the field `isOfferBenefit` is in pickers and combos only
      return isPicker(incentive) || isCombo(incentive) ? incentive.isOfferBenefit : true;
    })
  );
};
