/* eslint-disable complexity */
import { usePropsResolution } from '@fhs-legacy/native-base';

import { addWithConfig } from '../../utils';
import { Box } from '../box';
import { HStack } from '../h-stack';
import { Header } from '../header';
import { Icon } from '../icon';
import { Image } from '../image';
import { Pressable } from '../pressable';
import { Text } from '../text';
import { VStack } from '../v-stack';

import type { MarketingCarouselItemProps } from './types';

export const MarketingCarouselItem = ({
  content,
  header,
  image,
  imageAlt,
  imageUri,
  onPress,
  ...props
}: MarketingCarouselItemProps) => {
  const resolvedProps = usePropsResolution('MarketingCarouselItem', { ...props });

  return (
    <Pressable {...resolvedProps} accessibilityRole="button" onPress={onPress}>
      {
        // @ts-ignore
        ({ isHovered, isPressed, isFocused }) => (
          <VStack rounded="12" flexGrow={1}>
            <Box
              bg={isPressed || isFocused ? 'blackOpacity.10' : 'transparent'}
              borderColor="token.border-color-default"
              borderWidth={1}
              borderBottomWidth={0}
              borderTopRadius="12"
              alignItems="center"
            >
              {image || (
                <Image
                  accessibilityIgnoresInvertColors
                  resizeMode="contain"
                  source={{
                    uri: imageUri,
                  }}
                  alt={imageAlt}
                  height="100"
                  width="100"
                />
              )}
            </Box>
            <HStack
              bg={isPressed || isFocused ? 'token.background-focus' : 'white'}
              alignItems="center"
              pl={['18', '$8']}
              pr={['23', '34']}
              pb={['27', '31']}
              pt="$3"
              borderBottomRadius="12"
              borderColor="token.border-color-default"
              borderWidth={1}
              borderTopWidth={0}
              width={['253', '276']}
              space={2}
            >
              <Box borderBottomRadius="12" flexShrink={1}>
                <Header
                  variant="headerThree"
                  mb="$2"
                  color={isHovered ? 'token.text-active' : 'token.text-default'}
                >
                  {header}
                </Header>
                {typeof content === 'string' ? <Text variant="copyTwo">{content}</Text> : content}
              </Box>
              <Box>
                <Icon variant="forward" size="sm" />
              </Box>
            </HStack>
          </VStack>
        )
      }
    </Pressable>
  );
};

export default addWithConfig(MarketingCarouselItem);
