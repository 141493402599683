import React, { useCallback, useEffect, useState } from 'react';
import { LayoutChangeEvent, NativeSyntheticEvent, TextLayoutEventData } from 'react-native';

import { ISanityBlockContent, ISanityTypographyBlock } from '@rbi-ctg/menu';
import BlockRenderer from 'components/sanity-block-renderer';

import ReadMoreButton from './read-more-button';

interface IReadMoreSanityAccordionProps {
  content: ISanityBlockContent[] | ISanityTypographyBlock[];
  collapsed?: boolean;
  collapsedLines?: number;
  maxCollapsedHeight?: number;
}

const NUM_OF_COLLAPSED_LINES = 2;
const MAX_COLLAPSED_HEIGHT = 45;

const ReadMoreSanityAccordion: React.FC<React.PropsWithChildren<IReadMoreSanityAccordionProps>> = ({
  content,
  collapsed = false,
  collapsedLines = NUM_OF_COLLAPSED_LINES,
  maxCollapsedHeight = MAX_COLLAPSED_HEIGHT,
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(collapsed);
  const [numOfLines, setNumOfLines] = useState<number>(0);
  const [contentHeight, setContentHeight] = useState<number | null>(null);

  useEffect(() => {
    setIsCollapsed(collapsed);
  }, [collapsed]);

  const numberOfLines = numOfLines === 0 ? 0 : isCollapsed ? collapsedLines : numOfLines;

  const shouldRenderMoreButton = () => {
    if (contentHeight === null) {
      return false;
    }
    return numOfLines >= collapsedLines && contentHeight > maxCollapsedHeight;
  };

  const toggleAccordion = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onTextLayout = useCallback(
    (e: NativeSyntheticEvent<TextLayoutEventData>) => {
      if (numOfLines === 0) {
        setNumOfLines(e.nativeEvent.lines.length);
      }
    },
    [numOfLines]
  );

  const onLayout = useCallback(
    (e: LayoutChangeEvent) => {
      if (contentHeight === null) {
        setContentHeight(e.nativeEvent.layout.height);
      }
    },
    [contentHeight]
  );

  return (
    <>
      <BlockRenderer
        width="full"
        content={content}
        textProps={{ onTextLayout, numberOfLines }}
        onLayout={onLayout}
      />
      {shouldRenderMoreButton() && (
        <ReadMoreButton collapsed={isCollapsed} onPress={toggleAccordion} />
      )}
    </>
  );
};

export default ReadMoreSanityAccordion;
