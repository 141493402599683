import { StyleSheet, View } from 'react-native';

import { IconCheck, IconRemove, tokens } from '../..';
import { usePressableState } from '../pressable';

import { useCheckbox } from './context';
import type { CheckedState } from './types';

const checkBoxContent = (checked: CheckedState) => {
  if (checked === 'mixed') {
    return <IconRemove color={tokens.colors.$white} size={18} />;
  }

  if (checked) {
    return <IconCheck color={tokens.colors.$white} size={18} />;
  }

  return null;
};

export type CheckboxIndicatorProps = {};

/**
 * This is a non-interactive checkbox indicator.
 * It is for if you want to render an indicator inside of a CheckboxPressable.
 * You don't want to use a CheckboxControl when wrapping in a CheckboxPressable
 * because they would duplicate accessibility functionality while also adding
 * a nested focusable component.
 */
export const CheckboxIndicator = (props: CheckboxIndicatorProps) => {
  const pressableState = usePressableState();

  const checkbox = useCheckbox();

  const isCheckboxSelected = Boolean(checkbox.checked); // purposefully casting 'mixed' to true;

  return (
    <View
      {...props}
      style={[
        styles.checkbox,
        isCheckboxSelected && styles.checkboxSelected,
        pressableState.hovered && !isCheckboxSelected && styles.hovered,
        pressableState.disabled && isCheckboxSelected && styles.disabledSelected,
      ]}
    >
      {checkBoxContent(checkbox.checked)}
    </View>
  );
};

const styles = StyleSheet.create({
  checkbox: {
    alignSelf: 'center',
    width: 20,
    height: 20,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity30,
    justifyContent: 'center',
    borderRadius: 4,
  },
  checkboxSelected: {
    backgroundColor: tokens.colors.$houseRedDarken,
    borderColor: tokens.colors.$transparent,
  },
  // hover
  hovered: {
    borderColor: tokens.colors.$houseDark,
  },
  // disabled
  disabledSelected: {
    backgroundColor: tokens.colors.$houseRedOpacity10,
  },
});
