import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconAccessible = (props: IconProps) => {
  const { size = 16, color = 'black', ...otherProps } = props;

  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 24 24" color={color} {...otherProps}>
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M14 4.1a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm5 7v2c-1.75-.01-3.71-.88-5-1.95v3.45h3c1.1 0 2 .9 2 2v5.5h-2v-5h-5c-1.1 0-2-.9-2-2V9.19c0-1.05.76-1.98 1.81-2.07.44-.04.84.06 1.19.26h.01c.005 0 .008.003.01.006.003.002.005.005.01.005.23.11.44.26.61.45l1.29 1.43c.98 1.08 2.53 1.85 4.07 1.83Zm-9 9c1.31 0 2.42-.84 2.83-2h2.07A5 5 0 1 1 9 12.2v2.07c-1.16.42-2 1.52-2 2.83 0 1.66 1.34 3 3 3Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
