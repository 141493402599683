import { router, useLocalSearchParams, useNavigation } from 'expo-router';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Button } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import Dialog from 'components/dialog';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { useOrderContext } from 'state/order';

import { useProductCart } from '../use-product-cart';

export type EnsureOfferIsAddedDialogRef = {
  disable: () => void;
};

// If a user hit "continue" on an offer and ends up in a product
// detail view, we have had many users who back out of the product
// detail view instead of hitting add.
//
// This logical container watches to see if an offer is selected and verifies
// that when trying to navigate away.
//
// if this case hits, we pop a modal for the user to verify if they want to add
// the item or continue leaving without adding.
export const EnsureOfferIsAddedDialog = forwardRef<EnsureOfferIsAddedDialogRef>(
  function EnsureOfferIsAddedDialog(_, ref) {
    const navigation = useNavigation();
    const unsubscribeRef = useRef(() => {});
    const { offerId: urlParamsOfferId } = useLocalSearchParams<{ offerId?: string }>();
    const selectedOffer = useAppSelector(selectors.loyalty.selectSelectedOffer);
    const dispatch = useAppDispatch();
    const { addToOrder } = useProductCart();
    const { cartEntries } = useOrderContext();
    const [showDialog, setShowDialog] = useState(false);

    // Attach a method to the ref to be able to disable
    // the navigation dispatcher. This effectively allows a parent component
    // to noop this component as needed.
    useImperativeHandle(ref, () => ({
      disable() {
        setShowDialog(false);
        unsubscribeRef.current();
      },
    }));

    // This is the primary logic gate control for this component.
    const cartHasOffer = useMemo(
      () =>
        urlParamsOfferId &&
        urlParamsOfferId === selectedOffer?._id &&
        cartEntries.some((e: any) => e._id === selectedOffer?._id),
      [cartEntries, selectedOffer, urlParamsOfferId]
    );

    useEffect(() => {
      unsubscribeRef.current = navigation.addListener('beforeRemove', e => {
        if (cartHasOffer === false) {
          e.preventDefault();
          setShowDialog(true);
        }
      });
      return unsubscribeRef.current;
    }, [navigation, cartHasOffer]);

    return (
      <Dialog
        modalAppearanceEventMessage="offer-not-applied-modal"
        showDialog={showDialog}
        heading="Offer Not Applied"
        body="Are you sure you want to leave before adding this offer to your cart? Make sure you don't lose the deal!"
        actions={
          <Button.Group>
            <ActionButton
              fullWidth
              onPress={() => {
                unsubscribeRef.current();
                addToOrder();
                dispatch(actions.loyalty.setSelectedOffer(null));
                if (router.canGoBack()) {
                  return router.back();
                } else {
                  router.push('/menu');
                }
              }}
              mb="$2"
            >
              Add To Cart
            </ActionButton>
            <ActionButton
              fullWidth
              onPress={() => {
                unsubscribeRef.current();
                dispatch(actions.loyalty.setSelectedOffer(null));
                if (router.canGoBack()) {
                  return router.back();
                } else {
                  router.push('/menu');
                }
              }}
              variant={ActionButtonVariants.OUTLINE}
            >
              Leave Offer
            </ActionButton>
          </Button.Group>
        }
      />
    );
  }
);
