import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { tokens } from '../../tokens';

const OUTLINE_WIDTH = 4;

type PressableOutlineProps = {
  borderTopLeftRadius: number;
  borderTopRightRadius: number;
  borderBottomRightRadius: number;
  borderBottomLeftRadius: number;
  borderWidth: number;
  style: StyleProp<ViewStyle>;
};

export function PressableOutline(props: PressableOutlineProps) {
  const outlinePosition = 0 - OUTLINE_WIDTH - props.borderWidth;

  return (
    <View
      style={[
        outlineStyles.baseline,
        {
          borderTopLeftRadius: props.borderTopLeftRadius + OUTLINE_WIDTH,
          borderTopRightRadius: props.borderTopRightRadius + OUTLINE_WIDTH,
          borderBottomLeftRadius: props.borderBottomLeftRadius + OUTLINE_WIDTH,
          borderBottomRightRadius: props.borderBottomRightRadius + OUTLINE_WIDTH,
          top: outlinePosition,
          right: outlinePosition,
          bottom: outlinePosition,
          left: outlinePosition,
          borderWidth: OUTLINE_WIDTH,
        },
        props.style,
      ]}
    />
  );
}

const outlineStyles = StyleSheet.create({
  baseline: {
    position: 'absolute',
    borderColor: tokens.colors.$transparent,
  },
});
