import Head from 'expo-router/head';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { PageWrapper } from 'components/page-wrapper';
import AuthRequired from 'pages/authentication/auth-required';

import { LoyaltyHistory } from '../loyalty/loyalty-history';

const History: FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Head>
        <title>{`${formatMessage({ id: 'rewardsHistory' })} - Firehouse Subs`}</title>
      </Head>
      <PageWrapper>
        <AuthRequired>
          <LoyaltyHistory />
        </AuthRequired>
      </PageWrapper>
    </>
  );
};

export default History;
