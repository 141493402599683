import { router } from 'expo-router';
import { StyleSheet, View } from 'react-native';

import { GenericCard, tokens } from '@fhs/ui';
import { IconProfile } from '@fhs/ui/src/components/icon/svgs/profile';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';

export function UnauthenticatedWithNoOffers() {
  return (
    <View style={styles.container}>
      <GenericCard
        icon={<IconProfile fill={tokens.colors.$blackOpacity10} />}
        title="Sign In For Offers & Rewards"
        description="Log in or create an account to earn points, enjoy exclusive offers and free rewards."
        buttonTitle="Sign In Now"
        buttonHandle={() => router.navigate(routes.signIn)}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: tokens.colors.$white,
    borderTopWidth: 1,
    borderColor: tokens.colors.$black10,
  },
});
