import { ActionStatus, AddressModalAction, AddressModalState } from './types';

export const addressReducer = (state: AddressModalState, action: AddressModalAction) => {
  switch (action.type) {
    case ActionStatus.IDLE:
      return { ...state, address: null, error: null, status: action.type };
    case ActionStatus.QUOTE_UNAVAILABLE: {
      return { ...state, error: null, status: action.type };
    }
    case ActionStatus.PENDING: {
      return { ...state, address: action.address, error: null, status: ActionStatus.PENDING };
    }
    case ActionStatus.ERROR: {
      return { ...state, error: action.error, status: ActionStatus.ERROR };
    }
    default: {
      throw new Error('Unrecognized state transition');
    }
  }
};
