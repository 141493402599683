import { IconButton as NbIconButton } from '@fhs-legacy/native-base';

import { addWithConfig } from '../../utils';
import { Icon } from '../icon';

import type { IconButtonProps } from './types';

export const IconButton = ({ icon, iconName, ...props }: IconButtonProps) => (
  <NbIconButton {...props} icon={icon || <Icon variant={iconName} />} />
);

export default addWithConfig(IconButton);
