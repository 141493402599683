import React from 'react';

import { ScrollView } from '@fhs-legacy/universal-components';

import { Container } from './loyalty-claim-points.styled';

const LoyaltyClaimPointsContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <ScrollView paddingTop="$2" background="white">
      <Container>{children}</Container>
    </ScrollView>
  );
};

export default LoyaltyClaimPointsContainer;
