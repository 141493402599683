import * as React from 'react';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import BaseReceiptHeadInfo from './receipt-head-info.base';
import { Title } from './receipt-head-info.styled';
import { IOrderHeaderProps } from './types';

const ReceiptHeadInfo: FC<React.PropsWithChildren<IOrderHeaderProps>> = ({ order }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Title>{formatMessage({ id: 'orderDetailsHeading' })}</Title>
      <BaseReceiptHeadInfo order={order} />
    </>
  );
};

export default ReceiptHeadInfo;
