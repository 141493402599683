import { ICombo, IComboSlotSelections, IItem, IModifierSelection, IPicker } from '@rbi-ctg/menu';

import { partitionComboSlots } from './combo';

// go through the comboSlots that have been selected and pull put extras,
export function partitionModalState(
  selectedOption: ICombo,
  comboSlotSelections: IComboSlotSelections,
  modifierSelections: IModifierSelection[] = []
) {
  const includedComboSlotSelections = {};
  const prunedItems: {
    item: (IItem | IPicker) & { quantity: number };
    modifierSelections: IModifierSelection[];
  }[] = [];

  const partitionedComboSlots = Object.keys(comboSlotSelections).length
    ? partitionComboSlots(selectedOption.options, comboSlotSelections)
    : [];

  partitionedComboSlots.forEach(comboSlot => {
    // modifiers can only be added to an option of max quantity 0 or 1, so all modifier selections for a combo slot must apply to a single selection.
    const modifierSelectionsForComboSlot = modifierSelections.filter(
      ({ comboSlotId }) => comboSlotId === comboSlot.id
    );

    comboSlot.selections.forEach(selection => {
      // all selections included
      const current = includedComboSlotSelections[comboSlot.id] || {
        data: comboSlot.data,
        selections: [],
      };

      if (selection.notIncluded === 0) {
        const updatedSelections = current.selections.concat(selection);
        // @ts-ignore FIXME The operand of a 'delete' operator must be optional.ts(2790)
        delete selection.notIncluded;
        includedComboSlotSelections[comboSlot.id] = {
          ...current,
          selections: updatedSelections,
        };
        // if some selections included
      } else if (selection.notIncluded > 0 && selection.notIncluded < selection.quantity) {
        // add non included items to pruned array
        prunedItems.push({
          item: {
            ...selection.option.option,
            quantity: selection.notIncluded,
          },
          modifierSelections: modifierSelectionsForComboSlot,
        });
        // update comboSlot to proper included quantity
        const includedQuantity = selection.quantity - selection.notIncluded;

        const updatedSelections = current.selections.concat({
          ...selection,
          quantity: includedQuantity,
        });

        includedComboSlotSelections[comboSlot.id] = {
          ...current,
          selections: updatedSelections,
        };

        // if no selections are included
      } else {
        prunedItems.push({
          item: {
            ...selection.option.option,
            quantity: selection.notIncluded,
          },
          modifierSelections: modifierSelectionsForComboSlot,
        });
      }
    });
  });
  return { includedComboSlotSelections, prunedItems };
}
