import React from 'react';
import { useIntl } from 'react-intl';

import type { OfferListItemProps } from '@fhs-legacy/universal-components';
import { OffersListItem } from '@fhs-legacy/universal-components';
import Picture from 'components/picture';
import type { IMenuImageFragment } from 'generated/sanity-graphql';

import { IncentiveCardDescription } from '../loyalty-incentives-components/incentive-card/incentive-card.styled';

import { OffersServiceMode } from './types';
import { getServiceModeText } from './utils';

type IOfferListItemBaseProps = Omit<
  OfferListItemProps,
  'image' | 'header' | 'description' | 'serviceModeText' | 'imageAlt' | 'imageUri'
> & {
  incentiveName: string;
  description: any;
  image: IMenuImageFragment | null;
  imageDescription: string;
  serviceMode?: OffersServiceMode;
};

const OfferListItemBasePicture = Picture.withConfig({
  height: '97px',
  width: '97px',
  objectFitContain: true,
});

export const OfferListItemBase = ({
  image,
  imageDescription,
  incentiveName,
  description,
  serviceMode,
  ...props
}: IOfferListItemBaseProps) => {
  const { formatMessage } = useIntl();
  const serviceModeText = getServiceModeText(serviceMode, formatMessage);

  return (
    <OffersListItem
      image={<OfferListItemBasePicture image={image} alt={imageDescription} />}
      header={incentiveName}
      description={description && <IncentiveCardDescription content={description} />}
      serviceModeText={serviceModeText}
      {...props}
    />
  );
};
