import { router } from 'expo-router';
import { produce } from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useMemo } from 'react';
import { Platform } from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import {
  MenuSectionItem,
  OfferDetail,
  Step as OfferStep,
} from '@fhs/backend/amplify/functions/_temp/graphql/API';
import { create, devtools } from '@fhs/zustand';
import { IIncentiveSelection } from '@fhs-legacy/frontend/src/generated/graphql-gateway';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';
import { ICartEntry } from '@rbi-ctg/menu';

import { useAddIncentiveToCart } from '../hooks/use-add-incentive-to-cart';
import { useOrderLegacyStates } from '../hooks/use-legacy-order-states';

const isWeb = Platform.OS === 'web';

type OfferGuideCartState = {
  onBackAttempt?: () => boolean;
  offerInfo?: Omit<SimplyOfferType, 'steps'>;
  steps: Step[];
  currentStepIndex: number;
  isGoingForward: boolean;
  showSummary: boolean;
  selectedEligibleItem?: MenuSectionItem;
};

type OfferGuideCartActions = {
  modifyItemSelectionInStep: (step: number, cartEntry: ICartEntry) => void;
  goToStep: (index: number, isEdit?: boolean) => void;
  goToPrevStep: () => void;
  goToNextStep: () => void;
  goToNextNotDoneStep: () => void;
  goFromScratch: () => void;
  applyPreSelectedItems: (cartEntries: ICartEntry[]) => void;
  setOffer: (offer: SimplyOfferType) => void;
  setSelectedEligibleItem: (eligibleItem?: MenuSectionItem) => void;
  reset: () => void;
  setOnBackAttempt: (callback: (() => boolean) | undefined) => void;
};

const initialState: OfferGuideCartState = {
  onBackAttempt: undefined,
  offerInfo: undefined,
  steps: [],
  currentStepIndex: 0,
  isGoingForward: true,
  showSummary: false,
  selectedEligibleItem: undefined,
};

export type Step = OfferStep & {
  selectedEntry?: ICartEntry;
};

export type SimplyOfferType = OfferDetail & {
  steps: Step[];
};

const _isValidIndex = (newIndex: number, stepsLength: number) => {
  if (newIndex < 0 || newIndex >= stepsLength) {
    return false;
  }

  return true;
};

const _getNextNotDoneStep = (steps: Step[]) => {
  const lastIndexWithoutProduct = steps.findIndex(step => !step.selectedEntry);

  if (lastIndexWithoutProduct === -1) {
    return steps.length;
  }
  return lastIndexWithoutProduct;
};

export const useOfferCart = create<OfferGuideCartState & OfferGuideCartActions>()(
  devtools((set, get) => {
    const goToStep = (index: number) => {
      const state = get();
      const stepsLength = state.steps.length;
      if (index === stepsLength) {
        setTimeout(() => {
          set({ currentStepIndex: index, showSummary: true });
        }, 100);
        set({ isGoingForward: true });
        return;
      }

      if (_isValidIndex(index, stepsLength)) {
        setTimeout(() => {
          set({ currentStepIndex: index, showSummary: false });
        }, 100);
        if (index < state.currentStepIndex) {
          set({ isGoingForward: false });
          return;
        }
        set({ isGoingForward: true });
      }
    };
    return {
      ...initialState,
      reset: () => set(initialState),
      setSelectedEligibleItem: (eligibleItem?: MenuSectionItem) =>
        set({ selectedEligibleItem: eligibleItem }),
      setOnBackAttempt: (callback: (() => boolean) | undefined) => set({ onBackAttempt: callback }),
      goToNextStep: () => goToStep(get().currentStepIndex + 1),
      goToNextNotDoneStep: () => goToStep(_getNextNotDoneStep(get().steps)),
      goToPrevStep: () => goToStep(get().currentStepIndex - 1),
      goToStep,
      applyPreSelectedItems: (cartEntries: ICartEntry[]) =>
        set(state => {
          const newSteps = produce(state.steps, draftSteps => {
            draftSteps.forEach(draftStep => {
              const selectedEntry = cartEntries.find(
                cartEntry => cartEntry.cartId === draftStep.preSelectedItem
              );
              draftStep.preSelectedItem = undefined;

              if (selectedEntry) {
                const eligibleItem = draftStep.eligibleItems.find(
                  item => item.id === selectedEntry?._id
                );

                draftStep.selectedEntry = addCartEntryImageAndUrl(selectedEntry, eligibleItem);
              }
            });
          });

          const nextStepIndex = _getNextNotDoneStep(newSteps);
          goToStep(nextStepIndex);

          return { steps: newSteps };
        }),
      goFromScratch: () =>
        set(state => {
          const newSteps = produce(state.steps, draftSteps => {
            draftSteps.forEach(draftStep => {
              draftStep.preSelectedItem = undefined;
            });
          });

          return { steps: newSteps };
        }),
      setOffer: (offer: SimplyOfferType) => {
        const { steps, ...offerInfo } = offer;
        set({ steps, offerInfo });
      },
      modifyItemSelectionInStep: (step: number, cartEntry: ICartEntry) =>
        set(state => {
          const newSteps = produce(state.steps, draftSteps => {
            draftSteps[step].selectedEntry = cartEntry;
          });

          const nextStepIndex = _getNextNotDoneStep(newSteps);
          goToStep(nextStepIndex);

          return { steps: newSteps };
        }),
    };
  })
);

export const useIsOfferCartDirty = () => {
  const steps = useOfferCart(state => state.steps);
  const isDirty = useMemo(() => steps.some(step => !!step.selectedEntry), [steps]);

  return isDirty;
};

export const useIsItemInCart = () => {
  const steps = useOfferCart(state => state.steps);
  const isItemInCart = useMemo(() => steps.some(step => !!step.preSelectedItem), [steps]);

  return isItemInCart;
};

export const useSelectedStep = () => {
  const steps = useOfferCart(state => state.steps);
  const currentStepIndex = useOfferCart(state => state.currentStepIndex);
  const selectedStep = useMemo(() => steps[currentStepIndex], [steps, currentStepIndex]);

  return selectedStep;
};

export const useEditStepSelectedEntry = () => {
  const setSelectedEligibleItem = useOfferCart(state => state.setSelectedEligibleItem);
  const goToStep = useOfferCart(state => state.goToStep);

  const editStepSelectedEntry = useCallback(
    (step: Step, stepIndex: number) => {
      const selectedEligibleItem = step.eligibleItems.find(
        eligibleItem => step?.selectedEntry?._id === eligibleItem.id
      );
      if (selectedEligibleItem) {
        router.setParams({
          isEdit: 'true',
        });

        setSelectedEligibleItem(selectedEligibleItem);
        goToStep(stepIndex);
        setTimeout(() => {
          router.setParams({
            isEdit: undefined,
          });
        }, 100);
      }
    },
    [setSelectedEligibleItem, goToStep]
  );

  return { editStepSelectedEntry };
};

export const useAddGuideOfferToCart = () => {
  const { offerInfo, steps: offerSteps, reset } = useOfferCart();
  const { cartEntries, addItemToCart, removeFromCart, updateQuantity } = useOrderLegacyStates();
  const { addIncentiveToCart } = useAddIncentiveToCart();

  const addGuideOfferToCart = useCallback(() => {
    if (offerInfo) {
      const selections: IIncentiveSelection[] = [];
      const cartEntriesClone = cloneDeep(cartEntries);
      offerSteps.forEach(step => {
        const itemInCart = cartEntriesClone.find(
          cartEntry => cartEntry.cartId === step.selectedEntry?.cartId
        );

        if (itemInCart) {
          const itemClone = cloneDeep(itemInCart);
          itemClone.quantity = 1;
          itemClone.cartId = uuidv4();
          addItemToCart(itemClone, undefined, false);

          selections.push({
            incentiveId: offerInfo.id,
            key: step.key,
            lineId: itemClone?.cartId ?? '',
            quantity: 1,
            rank: step.rank,
          });

          itemInCart.quantity--;

          if (itemInCart.quantity <= 0) {
            removeFromCart({ cartId: itemInCart.cartId });
          } else {
            updateQuantity(itemInCart.cartId, itemInCart.quantity);
          }

          return;
        }

        selections.push({
          incentiveId: offerInfo.id,
          key: step.key,
          lineId: step.selectedEntry?.cartId ?? '',
          quantity: 1,
          rank: step.rank,
        });
        addItemToCart(step.selectedEntry, undefined, false);
      });

      addIncentiveToCart(offerInfo, selections, true);
      router.dismiss();
      reset();
      setTimeout(() => {
        if (isWeb) {
          router.replace(routes.menu);
        } else {
          router.navigate(routes.menu);
        }
      }, 100);
    }
  }, [
    reset,
    offerInfo,
    offerSteps,
    addItemToCart,
    addIncentiveToCart,
    cartEntries,
    removeFromCart,
    updateQuantity,
  ]);

  return { addGuideOfferToCart };
};

export const addCartEntryImageAndUrl = (cartEntry: ICartEntry, eligibleItem?: MenuSectionItem) => {
  return {
    ...cartEntry,
    url: eligibleItem?.url ?? cartEntry.url,
    image: {
      ...cartEntry.image,
      asset: { ...cartEntry?.image?.asset, url: eligibleItem?.image?.asset?.uri ?? '' },
    },
  } as ICartEntry;
};
