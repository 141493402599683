import { Box, Radio, makeUclComponent } from '@fhs-legacy/universal-components';
import { Accordion, AccordionItem } from 'components/accordion';

export const RadioWrapper = Box.withConfig({
  width: 'full',
  borderTopWidth: '1px',
  borderStyle: 'solid',
  borderTopColor: Styles.color.grey.four,
});

export const CustomRadio = Radio.withConfig({
  width: 'full',
  flexDirection: 'row-reverse',
  height: '$12',
  paddingX: '$2',
  _text: {
    flex: 1,
    fontSize: 'sm',
  },
});

export const TransactionDateOptionWrapper = Box.withConfig({
  margin: 0,
});

export const StyledAccordion = makeUclComponent(Accordion).withConfig({
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: Styles.color.grey.four,
  borderRadius: Styles.borderRadius,
  backgroundColor: Styles.color.cardBackground,
});

export const StyledAccordionItem = makeUclComponent(AccordionItem);
