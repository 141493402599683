import { Link } from 'expo-router';
import { StyleSheet, View } from 'react-native';

import { Button, IconChevronRight, IconOfferOutline, tokens } from '@fhs/ui';

export function CartOffersAndPromosLink() {
  return (
    <Link href="/rewards/offers" asChild>
      <Button type="unstyled" borderRadius={0} style={styles.button}>
        <View style={styles.iconContainer}>
          <IconOfferOutline size={24} />
        </View>
        <Button.Text style={styles.text}>Offers & Promos Codes</Button.Text>
        <IconChevronRight size={24} />
      </Button>
    </Link>
  );
}

const styles = StyleSheet.create({
  iconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: tokens.colors.$blackOpacity04,
    borderRadius: 999,
    height: 40,
    width: 40,
  },
  text: {
    fontWeight: '300',
    flex: 1,
  },

  button: {
    justifyContent: 'flex-start',
    padding: 16,
    height: 72,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: tokens.colors.$blackOpacity04,
    borderRadius: 0,
  },
});
