import { ImageBackground } from 'expo-image';
import { StyleSheet } from 'react-native';

import { YStack } from '@fhs/ui';

import { CtaButton } from '../shared/cta-button';

export function DonateTodayCard({ donateHref }: { donateHref: string }) {
  return (
    <ImageBackground
      source={require('./donate-today-card-background.webp')}
      placeholder={{ thumbhash: 'cxgKD4T3iJeIeHaNdjW3uHiJQ6BZB40H' }}
      alt="Donate Today"
      style={styles.card}
    >
      <YStack style={styles.container}>
        <CtaButton href={donateHref} text="Donate Now" />
      </YStack>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  card: {
    width: '50%',
    aspectRatio: 1354 / 1270,
  },
  container: {
    position: 'absolute',
    bottom: '10%',
    left: 0,
    right: 0,
    alignItems: 'center',
  },
});
