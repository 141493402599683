import { type ReactNode, createContext, useCallback, useContext, useRef } from 'react';
import { Pressable, type PressableProps } from 'react-native';

type FocusControlFn = () => void;
type FormFieldContextType = {
  setFocusControl: (focusControl: FocusControlFn) => void;
  labelText: string;
};
const FormFieldContext = createContext<FormFieldContextType>({
  setFocusControl: () => {},
  labelText: '',
});

export const useFormFieldContext = () => useContext(FormFieldContext);

export type FormFieldProps = Omit<PressableProps, 'children'> & {
  name: string;
  labelText: string;
  validation?: () => {};
  children: ReactNode;
};

// WIP
export function FormField({
  name: _name,
  labelText,
  validation: _validation,
  children,
  ...pressableProps
}: FormFieldProps) {
  const focusControlRef = useRef<FocusControlFn | null>(null);
  const setFocusControl = useCallback((focusControl: FocusControlFn) => {
    focusControlRef.current = focusControl;
  }, []);

  return (
    <Pressable {...pressableProps} accessible={false} onPress={() => focusControlRef.current?.()}>
      <FormFieldContext.Provider value={{ setFocusControl, labelText: labelText }}>
        {children}
      </FormFieldContext.Provider>
    </Pressable>
  );
}
