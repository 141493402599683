import { Path, Rect, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconPaymentTypeMastercard = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Rect width={24} height={16} y={4.101} fill="#fff" rx={2} />
      <Rect width={23} height={15} x={0.5} y={4.601} stroke="#000" strokeOpacity={0.1} rx={1.5} />
      <Path
        fill="#ED0006"
        fillRule="evenodd"
        d="M11.891 15.967a4.974 4.974 0 0 1-3.105 1.067c-2.643 0-4.786-2-4.786-4.467C4 10.1 6.143 8.101 8.786 8.101c1.185 0 2.27.401 3.105 1.067A4.974 4.974 0 0 1 14.996 8.1c2.643 0 4.786 2 4.786 4.466 0 2.467-2.143 4.467-4.786 4.467a4.974 4.974 0 0 1-3.105-1.067Z"
        clipRule="evenodd"
      />
      <Path
        fill="#F9A000"
        fillRule="evenodd"
        d="M11.891 15.967c1.03-.82 1.682-2.039 1.682-3.4 0-1.361-.653-2.58-1.682-3.4a4.974 4.974 0 0 1 3.105-1.066c2.643 0 4.786 2 4.786 4.466 0 2.467-2.143 4.467-4.786 4.467a4.974 4.974 0 0 1-3.105-1.067Z"
        clipRule="evenodd"
      />
      <Path
        fill="#FF5E00"
        fillRule="evenodd"
        d="M11.891 15.967c1.03-.82 1.682-2.039 1.682-3.4 0-1.361-.653-2.58-1.682-3.4-1.029.82-1.681 2.039-1.681 3.4 0 1.361.652 2.58 1.681 3.4Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
