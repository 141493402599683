import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconPaymentTypeGenericCreditCard = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 5.1h20a1 1 0 0 1 1 1v1H1v-1a1 1 0 0 1 1-1Zm-1 6v7a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1v-7H1Zm-1-5a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-12Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
