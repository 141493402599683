import Constants from 'expo-constants';
import React, { FC, ReactNode, useState } from 'react';
import { Clipboard, TouchableHighlight } from 'react-native';

import {
  Box,
  Button,
  Checkbox,
  HStack,
  Header,
  ScrollView,
  VStack,
} from '@fhs-legacy/universal-components';

import theme from '../theme';

export const getConfigValues = (): any => {
  return Constants.expoConfig?.extra?.eas ?? {};
};

export const Inner = ScrollView.withConfig({
  flex: 1,
  maxWidth: {
    desktop: Styles.layout.smallerSectionMaxWidth,
  },
});

export const EnvListContainer = Box.withConfig({});

export const EnvTitle = Box.withConfig({
  _text: {
    color: theme.envTitleColor,
  },
});

export const EnvDescription = Box.withConfig({
  _text: {
    color: Styles.color.black,
    fontWeight: Styles.fontWeight.heavy,
  },
});

interface IEnvItemBoolean {
  envKey: string;
  value: boolean;
}

export const EnvItemBoolean = ({ envKey, value }: IEnvItemBoolean) => (
  <Checkbox marginY="$4" name={envKey} value={envKey} isChecked={!!value} children={envKey} />
);

interface IEnvItem {
  envKey: string;
  value?: string | null;
}

export const EnvItem = ({ envKey, value }: IEnvItem) => {
  if (typeof value === 'boolean') {
    value = value ? 'true' : 'false';
  }
  return (
    <TouchableHighlight
      underlayColor="gray"
      onLongPress={() => {
        if (value) {
          Clipboard.setString(value);
          // eslint-disable-next-line no-alert
          alert(`Copied text "${value}"`);
        }
      }}
    >
      <VStack pb={'$2'} width="full">
        <EnvTitle>{envKey}</EnvTitle>
        <EnvDescription>{value || 'unknown'}</EnvDescription>
      </VStack>
    </TouchableHighlight>
  );
};

export const HiddenEnvItem = ({ envKey, value }: IEnvItem) => {
  const [visible, setVisible] = useState(false);
  if (typeof value === 'boolean') {
    value = value ? 'true' : 'false';
  }

  return (
    <VStack pb={'$2'} width="full">
      <HStack justifyContent="space-between" alignItems="center">
        <EnvTitle>{envKey}</EnvTitle>
        <Button onPress={() => setVisible(v => !v)} size={'sm'} variant="ghost">
          {visible ? 'Hide Value' : 'Show Value'}
        </Button>
      </HStack>
      {visible ? <EnvDescription>{value || 'unknown'}</EnvDescription> : null}
    </VStack>
  );
};

export const ServiceModeItem: FC<React.PropsWithChildren<any>> = ({ envKey, value }) => (
  <>
    <Header variant="headerFour">{envKey}</Header>
    <Box>
      {Object.keys(value).map(statusKey => (
        <Box key={statusKey}>
          {statusKey}:{Boolean(value[statusKey]).toString()}
        </Box>
      ))}
    </Box>
  </>
);

interface IEnvListProps {
  data: {
    [key: string]: any;
  };
  renderItem?(key: string, value: any, index: number): ReactNode;
}

export const EnvList: FC<React.PropsWithChildren<IEnvListProps>> = ({
  data,
  renderItem = (key, value) => <EnvItem key={key} envKey={key} value={value} />,
}) => <>{Object.entries(data).map(([key, value], index) => renderItem(key, value, index))}</>;

interface IHiddenSection {
  title: string;
  content?: ReactNode | null;
}
export const HiddenSection = ({ title, content }: IHiddenSection) => {
  const [visible, setVisible] = useState(false);

  return (
    <VStack pb={'$2'} width="full">
      <HStack justifyContent="space-between" alignItems="center">
        <Header pt={4} variant="headerThree">
          {title}
        </Header>
        <Button onPress={() => setVisible(v => !v)} size={'md'} variant="ghost">
          {visible ? 'Hide Section' : 'Show Section'}
        </Button>
      </HStack>
      {visible ? content : null}
    </VStack>
  );
};
