import React, { VFC } from 'react';
import { useIntl } from 'react-intl';

import { theme } from 'styles/configure-theme';

import { MethodType } from './styled';

interface ICashMethod {
  isDisabled: boolean;
}

const CashMethod: VFC<ICashMethod> = ({ isDisabled }) => {
  const { formatMessage } = useIntl();

  return (
    <MethodType color={isDisabled ? theme.token('text-disabled') : theme.token('text-default')}>
      {formatMessage({ id: 'payWithCash' })}
    </MethodType>
  );
};

export default CashMethod;
