import React, { FC } from 'react';

import { StickyFooter } from 'components/sticky-footer/sticky-footer';
import { ElementContent } from 'components/sticky-footer/types';

interface IWizardCtaWrapperProps {
  elements: ElementContent[];
}

export const WizardCtaWrapper: FC<React.PropsWithChildren<IWizardCtaWrapperProps>> = ({
  elements,
}) => {
  const elementsToRender = elements.filter(({ element }) => element);

  return <StickyFooter padding="16px" elements={elementsToRender} />;
};
