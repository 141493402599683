import React, { FC } from 'react';
import { useWindowDimensions } from 'react-native';

import { makeUclComponent } from '@fhs-legacy/universal-components';
import { useLoyaltyBackground } from 'hooks/use-loyalty-background';

import { USE_ONLY_BACKGROUND_PROP } from './constants';
import { StyledImageBackground, Wrapper } from './loyalty-background.styled';
import { ILoyaltyBackgroundProps } from './loyalty-background.types';

const LoyaltyBackground: FC<React.PropsWithChildren<ILoyaltyBackgroundProps>> = ({
  children,
  bgImage,
  fullHeight,
  constrainToViewHeight,
  ...props
}) => {
  const { height: $windowHeight } = useWindowDimensions();
  const { image: defaultBgImage } = useLoyaltyBackground();

  const sourceUri = bgImage || defaultBgImage;
  const withoutImageBackground = (USE_ONLY_BACKGROUND_PROP && !bgImage) || !sourceUri;
  let content;
  if (withoutImageBackground) {
    content = <>{children}</>;
  } else {
    content = <StyledImageBackground source={{ uri: sourceUri }}>{children}</StyledImageBackground>;
  }

  return (
    <Wrapper
      testID="lyl-bg"
      $fullHeight={fullHeight}
      $constrainToViewHeight={constrainToViewHeight}
      $windowHeight={$windowHeight}
      {...props}
    >
      {content}
    </Wrapper>
  );
};

export default makeUclComponent(LoyaltyBackground);
