import { router } from 'expo-router';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { ISignUpCompleteQuery, useSignInJwtMutation } from 'generated/graphql-gateway';
import { useToast } from 'hooks/use-toast';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { isTest, platform, welcomeEmailDomain } from 'utils/environment';
import { ISignupForm } from 'utils/form/types';
import { encodeBase64String } from 'utils/parse-string';
import { routes } from 'utils/routing';
import { TOAST_NEW_ACCOUNT_CREATED } from 'utils/test-ids';

interface IAutoSignin {
  signUpJwt: string;
  signUpCompleteData?: ISignUpCompleteQuery;
  stopPolling: () => void;
}

const AutoSignin: React.FC<React.PropsWithChildren<IAutoSignin>> = ({
  signUpJwt,
  signUpCompleteData,
  stopPolling,
}) => {
  const toast = useToast();
  const { values } = useFormikContext<ISignupForm>();
  const { logRBIEvent } = useCRMEventsContext();
  const [signInJwtMutation] = useSignInJwtMutation();
  const { formatMessage } = useIntl();

  const { email } = values;

  useEffect(() => {
    if (signUpJwt && signUpCompleteData?.signUpComplete) {
      stopPolling();
      signInJwtMutation({
        variables: {
          input: {
            email,
            stage: welcomeEmailDomain(),
            platform: platform(),
          },
        },
      })
        .then(() => {
          const user = encodeBase64String({ value: email });

          logRBIEvent({
            name: CustomEventNames.AUTO_SIGN_IN,
            type: EventTypes.Other,
            attributes: {
              signUpType: email ? 'Email' : 'Phone Number',
            },
          });

          router.replace({ pathname: routes.confirmJwt, params: { token: signUpJwt, user } });

          toast.show({
            testID: TOAST_NEW_ACCOUNT_CREATED,
            duration: isTest ? 9999999 : undefined,
            text: formatMessage({ id: 'autoSignInSuccess' }),
            variant: 'positive',
          });
        })
        .catch(err => {
          throw err;
        });
    }
  }, [
    toast,
    stopPolling,
    signUpJwt,
    logRBIEvent,
    signUpCompleteData,
    email,
    signInJwtMutation,
    formatMessage,
  ]);
  return null;
};

export default AutoSignin;
