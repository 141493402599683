import { throttle } from 'lodash';
import { ReactNode, useCallback, useLayoutEffect, useRef } from 'react';
import { GestureResponderEvent, StyleSheet, View, useWindowDimensions } from 'react-native';

import { useBoxRef } from '@fhs/utils';

import { Pressable, PressableProps } from '../pressable';

import { useDropdownSetters, useDropdownState } from './context';

export type DropdownTriggerProps = Omit<PressableProps, 'children'> & {
  children: ReactNode | (({ isExpanded }: { isExpanded: boolean }) => ReactNode);
};
export function DropdownTrigger({ children, ...props }: DropdownTriggerProps) {
  const triggerRef = useRef<View | null>(null);
  const { setTriggerLayout, setIsExpanded } = useDropdownSetters();
  const { isExpanded } = useDropdownState();

  const calculateTriggerLayout = useCallback(
    throttle(() => {
      if (triggerRef.current) {
        triggerRef.current.measure((_x, _y, width, height, pageX, pageY) => {
          // @TODO: Figure out why measure sometimes 0s out all the values
          if (width !== 0 && height !== 0) {
            setTriggerLayout({ width, height, pageX, pageY });
          }
        });
      }
    }, 100),
    [setTriggerLayout]
  );

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  useLayoutEffect(() => {
    // Trigger remeasure when window dimensions change or dropdown is expanded
    calculateTriggerLayout();
  }, [isExpanded, calculateTriggerLayout, windowWidth, windowHeight]);

  const handlePressRef = useBoxRef((evt: GestureResponderEvent) => {
    setIsExpanded(true);

    if (props.onPress) {
      props.onPress(evt);
    }
  });

  return (
    <Pressable
      accessibilityState={{ expanded: isExpanded }}
      ref={triggerRef}
      {...props}
      onLayout={calculateTriggerLayout}
      onPress={handlePressRef.current}
      outlineStyle={styles.transparentBorder}
      outlinePressedStyle={styles.transparentBorder}
    >
      {typeof children === 'function' ? children({ isExpanded }) : children}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  transparentBorder: {
    borderColor: 'transparent',
  },
});
