import React, { useMemo } from 'react';

import { Box, Text } from '@fhs-legacy/universal-components';

import Price from '../price';

import { IPriceAndQuantityProps } from './types';

const Quantity = Text.withConfig({
  textAlign: 'right',
});

const MWContainer = Box.withConfig({
  alignItems: 'flex-end',
});

export const PriceAndQuantity: React.FC<React.PropsWithChildren<IPriceAndQuantityProps>> = ({
  item,
  isOffer,
  showQuantity,
  rewardApplied,
}) => {
  const valueId = useMemo(() => `item-quantity-${item.name.replace(/\s+/g, '-')}`, [item.name]);

  return (
    <MWContainer testID={`item-price-${item.cartId || item._id}`}>
      <Price item={item} isOffer={isOffer} rewardApplied={rewardApplied} />
      {showQuantity && <Quantity testID={valueId}>{item.quantity}</Quantity>}
    </MWContainer>
  );
};
