import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { TextInput as RNTextInput } from 'react-native';

import { Box, Pressable, ScrollView, Text } from '@fhs-legacy/universal-components';
import Currency from 'components/currency';
import { TextInput } from 'components/ucl';
import { useLocale } from 'state/intl';
import { CURRENCY_PREFIX } from 'state/intl/types';
import { TipAmounts, useOrderContext } from 'state/order';
import { ITipSelection } from 'state/order/types';
import { dollarsToCents } from 'utils';
import { checkIfValidTip } from 'utils/payment';

import SectionHeading from '../../section-heading';
import theme from '../theme';

export const TipList = ScrollView.withConfig({
  keyboardShouldPersistTaps: 'always',
  width: 'full',
  overflow: 'hidden',
  backgroundColor: Styles.color.white,
  borderColor: Styles.color.grey.three,
  borderWidth: 1,
  borderLeftRadius: Styles.borderRadius,
  borderRightRadius: Styles.borderRadius,
  padding: 0,
  marginBottom: '$8',
});

export const PressableTip = Pressable.withConfig<{
  $isSelected: boolean;
  $isFirst?: boolean;
  $isLast?: boolean;
}>(p => ({
  borderRightWidth: p.$isLast ? 0 : 1,
  borderRightColor: p.$isLast ? 'transparent' : Styles.color.grey.three,
  backgroundColor: p.$isSelected ? theme.tipSelectionBackground : Styles.color.white,
  padding: '$2.5',
  borderLeftRadius: p.$isFirst ? 3 : 0,
  borderRightRadius: p.$isLast ? 3 : 0,
}));

export const TipText = Text.withConfig<{ $isSelected: boolean }>(p => ({
  fontWeight: 'bold',
  textAlign: 'center',
  color: p.$isSelected ? Styles.color.white : theme.tipSelectorLabelColor,
}));

export const TipErrorMsg = Box.withConfig({
  marginTop: '$0.5',
  _text: {
    color: Styles.color.error,
    fontSize: '2xs',
  },
});

export const TipNoteMsg = Box.withConfig({
  marginTop: '$0.5',
  marginBottom: '$1',
  _text: {
    color: Styles.color.secondary,
    fontSize: 'xs',
  },
});

const MAX_TIP_LENGTH = 6; // Dont allow number longer than $99.99

type Props = {
  subTotalCents: number;
  showTipPercentage: boolean;
  otherDiscountAmount: number;
  isLoading?: boolean;
  isDisabled: boolean;
};

const DEFAULT_TIP_AMOUNT = '0';

const TipSelector: React.FC<React.PropsWithChildren<Props>> = ({
  subTotalCents,
  otherDiscountAmount,
  showTipPercentage,
  isLoading,
  isDisabled,
}) => {
  const { formatMessage } = useIntl();
  const { locale } = useLocale();
  const {
    setTipSelection,
    tipSelection,
    updateTipAmount,
    tipAmount: currentTipAmount,
  } = useOrderContext();

  const onTipPercentChange = useCallback(
    (tipPercent: string) => {
      updateTipAmount({
        subTotalCents,
        otherDiscountAmount,
        newTipOption: 'percent',
        newTipAmount: parseFloat(tipPercent),
      });
    },
    [otherDiscountAmount, subTotalCents, updateTipAmount]
  );

  const onTipAmountChange = useCallback(
    (newTipAmount: string) => {
      updateTipAmount({
        subTotalCents,
        otherDiscountAmount,
        newTipOption: 'dollar',
        newTipAmount: parseFloat(newTipAmount),
      });
    },
    [otherDiscountAmount, subTotalCents, updateTipAmount]
  );

  const tip = showTipPercentage ? tipSelection.percentAmount : tipSelection.dollarAmount;
  const [tipString, setTipString] = useState(
    `${CURRENCY_PREFIX[locale]}${tipSelection.otherAmount}`
  );
  const [errorString, setErrorString] = useState('');

  const updateOtherTipAmount = (otherAmount: string) => {
    setTipSelection((selection: ITipSelection) => ({
      ...selection,
      isOtherSelected: true,
      otherAmount: Number(otherAmount),
    }));
    onTipAmountChange(otherAmount);
  };

  const handleTipAmountChange = (tip: string) => {
    const parsedTip = tip.replace(/[^0-9.]/g, '');
    setTipString(`${CURRENCY_PREFIX[locale]}${parsedTip}`);

    if (!parsedTip) {
      setErrorString(formatMessage({ id: 'tipAmountError' }));
      updateOtherTipAmount(DEFAULT_TIP_AMOUNT);
      return;
    }

    // Example: '1.2'
    const tipAmountAsString = parsedTip
      .split('')
      .filter(i => i !== CURRENCY_PREFIX[locale])
      .join('');
    const isValidTip = checkIfValidTip({
      tipAmount: dollarsToCents(Number(tipAmountAsString)),
    });
    if (!isValidTip) {
      setErrorString(formatMessage({ id: 'tipAmountMaxError' }));
      updateOtherTipAmount(DEFAULT_TIP_AMOUNT);
      return;
    }

    setErrorString('');
    updateOtherTipAmount(tipAmountAsString);
  };

  const onTipChange = useCallback(
    (amount: string) => {
      if (showTipPercentage) {
        onTipPercentChange(amount);
      } else {
        onTipAmountChange(amount);
      }
    },
    [showTipPercentage, onTipAmountChange, onTipPercentChange]
  );
  const buildRadioProps = (tipAmount: string | number) => {
    const numAmount = tipAmount === 'other' ? 0 : tipAmount;
    return {
      $isSelected: tipSelection.isOtherSelected ? tipAmount === 'other' : tip === tipAmount,
      onPress: () => {
        if (isDisabled) {
          return;
        }
        setTipSelection((selection: ITipSelection) => {
          if (tipAmount === 'other') {
            // Reset to default when changing to "Other"
            onTipAmountChange(DEFAULT_TIP_AMOUNT);
            return {
              ...selection,
              isOtherSelected: true,
              otherAmount: 0,
            };
          }
          if (showTipPercentage) {
            return {
              ...selection,
              isOtherSelected: false,
              percentAmount: tipAmount as number,
            };
          }
          return {
            ...selection,
            isOtherSelected: false,
            dollarAmount: tipAmount as number,
          };
        });
        setTipString('');
        setErrorString('');
        if (tipAmount !== 'other') {
          onTipChange(numAmount.toString());
        }
      },
    };
  };

  // Show the error message above the Tip select btns when the tip is > than the subtotal
  // If the user has a invalid tip, keep the error message when the user clicks on the 'other' button until the user start to type in the input
  const showTipSelectBtnError =
    !isLoading &&
    (!tipSelection.isOtherSelected || !tipString) &&
    currentTipAmount > TipAmounts.MAX_AMOUNT;

  const tipOtherInputRef = useRef<RNTextInput>(null);
  const { isOtherSelected } = tipSelection;
  const previousTipOtherSelected = useRef(isOtherSelected);

  // focus tip input only when "Other" option is first selected
  // prevents keyboard from opening on mobile devices when cart renders Other by default
  useEffect(() => {
    if (!previousTipOtherSelected.current && isOtherSelected && tipOtherInputRef.current) {
      tipOtherInputRef.current.focus();
    }
  }, [isOtherSelected]);

  const tipData = showTipPercentage ? TipAmounts.PERCENT_AMOUNTS : TipAmounts.DOLLAR_AMOUNTS;

  return (
    <Box alignItems="center">
      <Box alignItems="flex-start" width="full">
        <SectionHeading>{formatMessage({ id: 'deliveryTipLabel' })}</SectionHeading>
      </Box>
      <TipList
        showsHorizontalScrollIndicator={false}
        horizontal
        accessibilityLabel="Select Tip"
        contentContainerStyle={{ flexGrow: 1 }}
      >
        {tipData.map((amount, index) => {
          return (
            <PressableTip
              key={index}
              flex={1}
              accessibilityLabel={`${amount} tip`}
              {...buildRadioProps(amount)}
              $isFirst={index === 0}
            >
              {showTipPercentage ? (
                <TipText $isSelected={buildRadioProps(amount).$isSelected}>{`${amount}%`}</TipText>
              ) : (
                <Currency
                  amount={amount * 100}
                  isLoading={isLoading}
                  textProps={{
                    fontWeight: 'normal',
                    margin: 0,
                    textAlign: 'center',
                    color: buildRadioProps(amount).$isSelected
                      ? Styles.color.white
                      : theme.tipSelectorLabelColor,
                  }}
                />
              )}
            </PressableTip>
          );
        })}
        <PressableTip flex={1} accessibilityLabel="Other tip" {...buildRadioProps('other')} $isLast>
          <TipText $isSelected={tipSelection.isOtherSelected}>
            {formatMessage({ id: 'other' })}
          </TipText>
        </PressableTip>
      </TipList>
      {!!otherDiscountAmount && <TipNoteMsg>{formatMessage({ id: 'tipDisclaimer' })}</TipNoteMsg>}

      {showTipSelectBtnError && (
        <TipErrorMsg accessibilityRole="alert" testID="showTipSelectBtnError">
          {formatMessage({ id: 'tipAmountMaxError' })}
        </TipErrorMsg>
      )}
      {isOtherSelected && (
        <TextInput
          testID="customTipInput"
          type="text"
          label={formatMessage({ id: 'tipField' })}
          value={tipString}
          errorMessage={errorString}
          onChangeText={handleTipAmountChange}
          // mask={currencyMask(locale)} // TODO: RN - Implement mask
          maxLength={MAX_TIP_LENGTH}
          ref={tipOtherInputRef}
          keyboardType="numeric"
          returnKeyType="done"
          isDisabled={isDisabled}
        />
      )}
    </Box>
  );
};

export default TipSelector;
