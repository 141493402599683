import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconUnlocked = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M12 17.1c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Zm6-9h-1v-2c0-2.76-2.24-5-5-5s-5 2.24-5 5h1.9C8.9 4.39 10.29 3 12 3c1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2Zm0 12H6v-10h12v10Z"
      />
    </Svg>
  );
};
