import { useCallback, useEffect, useState } from 'react';

interface ResizeKeyboardEvent extends KeyboardEvent {
  keyboardHeight?: number;
}

/**
 * Listens for ionic window events
 *  - provides whether or not keyboard is open
 *  - proviees keyboard height
 */
export const useKeyboardState = () => {
  const [keyboardState, setKeyboardState] = useState({
    isKeyboardOpen: false,
    keyboardHeight: 0,
  });

  const keyboardShowHandler = useCallback(({ keyboardHeight }: ResizeKeyboardEvent) => {
    setKeyboardState({ isKeyboardOpen: true, keyboardHeight: keyboardHeight ?? 0 });
  }, []);

  const keyboardHideHandler = useCallback(() => {
    setKeyboardState({ isKeyboardOpen: false, keyboardHeight: 0 });
  }, []);

  useEffect(() => {
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    window.addEventListener('keyboardWillShow', keyboardShowHandler);
    window.addEventListener('keyboardWillHide', keyboardHideHandler);
    return () => {
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      window.removeEventListener('keyboardWillShow', keyboardShowHandler);
      window.removeEventListener('keyboardWillHide', keyboardHideHandler);
    };
  }, [keyboardHideHandler, keyboardShowHandler]);

  return keyboardState;
};
