import React from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { StaticMenuProvider, useStaticMenuContext } from 'state/static-menu';

import { MainMenuProvider, useMainMenuContext } from '../main-menu';
import { MenuOptionsProvider, useMenuOptionsContext } from '../menu-options';
import { MenuUIProvider } from '../menu-ui';

export type Menu = ReturnType<typeof useMainMenuContext> &
  ReturnType<typeof useMenuOptionsContext> &
  ReturnType<typeof useStaticMenuContext> & {
    showStaticMenu: boolean;
    showBurgersForBreakfast: boolean;
  };

export const useMenuContext = (): Menu => {
  const menuCtx = useMainMenuContext();
  const menuOptionsCtx = useMenuOptionsContext();
  const staticMenuData = useStaticMenuContext();

  return {
    ...menuCtx,
    ...menuOptionsCtx,
    ...staticMenuData,
    showStaticMenu: false,
    showBurgersForBreakfast: false,
  };
};

export const MenuProvider = ({ children }: IBaseProps) => {
  return (
    <MainMenuProvider>
      <MenuOptionsProvider>
        <StaticMenuProvider>
          <MenuUIProvider>{children}</MenuUIProvider>
        </StaticMenuProvider>
      </MenuOptionsProvider>
    </MainMenuProvider>
  );
};
