import { ErrorBoundaryProps } from 'expo-router';
import { ScrollView, StyleSheet } from 'react-native';

import { Text } from '@fhs/ui';

// This ErrorBoundary has a "retry" function. We should port our
// logic to this error boundary and drop the global one from our providers
export function ErrorBoundary(props: ErrorBoundaryProps) {
  if (__DEV__) {
    const [, ...stack] = props.error.stack?.split('\n') ?? [];
    return (
      <ScrollView style={styles.container}>
        <Text.Heading type="three" style={styles.padding}>
          {props.error.message}
        </Text.Heading>

        {'componentStack' in props.error && (
          <>
            <Text.Ui size="md">Component Stack</Text.Ui>
            <Text>{`${props.error.componentStack}`}</Text>
          </>
        )}
        <Text.Heading type="four">Stack Trace</Text.Heading>
        <Text>{stack.join('\n')}</Text>
      </ScrollView>
    );
  }

  // This throw will make sure this error boundary goes to our Global Error Boundary from our reduced providers
  throw props.error;
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
  },
  padding: {
    paddingVertical: 8,
  },
});
