import dlv from 'dlv';
import { router } from 'expo-router';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import {
  Box,
  HStack,
  Icon,
  IconButton,
  Image,
  Text,
  VStack,
} from '@fhs-legacy/universal-components';
import { IBaseProps } from '@rbi-ctg/frontend';
import RadioIndicator from 'components/radio-indicator';
import useDialogModal from 'hooks/use-dialog-modal';
import { CardTypes, ICredit, IPaymentMethod } from 'state/payment/types';
import { theme } from 'styles/configure-theme';
import { mapPaymentMethodCardTypes } from 'utils/payment/map-payment-method-card-type';
import { routes } from 'utils/routing';

import CashMethod from './cash-method';
import { CONFIRM_DELETE_PAYMENT_METHOD_ID } from './constants';
import { cardIconRenderer } from './credit-card-icon';
import PayPalMethod from './paypal-method';
import PrepaidCardIcon from './prepaid-card-icon';
import PrepaidMethodBalance from './prepaid-method-balance';
import { MethodContainer, MethodType, MethodTypeInnerWrapper, MethodTypeWrapper } from './styled';
import { UnverifiedMethod } from './unverified-method';

export interface IPaymentMethodOptionProps extends IBaseProps {
  method: IPaymentMethod;
  selected?: boolean;
  onPress?: (fdAccountId: string) => void;
  showDefault?: boolean;
  removable?: boolean;
  readonly?: boolean;
  deletePaymentMethod?: (fdAccountId: string) => Promise<void>;
  hideDeleteIcon?: boolean;
  disableHover?: boolean;
  disableMethod?: boolean;
  hideBorder?: boolean;
  ariaLabelledby?: string;
  revaultRequired?: boolean;
  fromCheckout?: boolean;
  isDefaultPaymentMethod?: boolean;
  fullWidthDescription?: boolean;
}

function PaymentMethodOption({
  selected,
  method,
  onPress,
  showDefault,
  removable,
  deletePaymentMethod,
  disableHover = false,
  disableMethod = false,
  hideDeleteIcon = false,
  hideBorder = false,
  ariaLabelledby = 'payment-method-loads-with payment-method-label payment-method-card payment-method-alias payment-method-expirationDate',
  revaultRequired,
  fromCheckout = false,
  isDefaultPaymentMethod = false,
  fullWidthDescription = true,
}: IPaymentMethodOptionProps) {
  const { formatMessage } = useIntl();

  const accountIdentifier = method.accountIdentifier ?? method.fdAccountId ?? '';
  const accountAlias = dlv(method, 'credit.alias', dlv(method, 'prepaid.alias'));
  const showDeleteButton = !hideDeleteIcon && removable;
  const textColor = disableMethod ? theme.token('text-disabled') : theme.token('text-default');

  const onDeleteMethod = useCallback(() => {
    deletePaymentMethod?.(accountIdentifier);
  }, [deletePaymentMethod, accountIdentifier]);

  const onClickMethod = useCallback(() => {
    if (disableMethod) {
      return;
    }
    if (typeof onPress === 'function') {
      onPress(accountIdentifier);
    }
  }, [disableMethod, onPress, accountIdentifier]);

  const [DeletionConfirmationDialog, openDeletionConfirmationDialog] = useDialogModal({
    onConfirm: onDeleteMethod,
    showCancel: true,
    modalAppearanceEventMessage: 'Confirmation: Delete payment method',
  });

  const onClickRevault = useCallback(
    (e: any) => {
      e.stopPropagation();
      router.navigate({ pathname: routes.addCard, params: { accountToDelete: accountIdentifier } });
    },
    [accountIdentifier]
  );

  const formatExpirationDate = (credit: ICredit) => {
    const { expiryYear: expYr, expiryMonth: expMon } = credit || {};
    if (!expYr || !expMon) {
      return;
    }
    const expirationDate = new Date(2000 + parseInt(expYr, 10), parseInt(expMon, 10));
    const currentDate = new Date();
    const isValid = expirationDate.getTime() - currentDate.getTime();

    return `${
      isValid ? formatMessage({ id: 'expire' }) : formatMessage({ id: 'expired' })
    } ${expMon}/${expYr}`;
  };

  const RenderMethodType = () => {
    if (method.credit) {
      const creditCardAlias = (
        <Text color={textColor}>
          {formatMessage({ id: 'endsIn' }).toLowerCase()} {method.credit.alias}
        </Text>
      );
      let isCreditCard;
      let alias;
      let cardType: CardTypes | undefined;
      switch (method.credit.cardType) {
        case CardTypes.APPLE_PAY:
          isCreditCard = false;
          alias = formatMessage({ id: 'applePay' });
          break;
        case CardTypes.GOOGLE_PAY:
          isCreditCard = false;
          alias = formatMessage({ id: 'googlePay' });
          break;
        default:
          cardType = mapPaymentMethodCardTypes(method.credit.cardType);
          isCreditCard = true;
          alias = creditCardAlias;
      }
      return (
        <>
          {fullWidthDescription ? (
            <HStack alignItems="center" space={2}>
              {isCreditCard && (
                <MethodType color={textColor} testID="payment-method-card">
                  {cardType || 'CARD'}
                </MethodType>
              )}
              <MethodType color={textColor} textTransform="capitalize">
                {alias}
              </MethodType>
              {isCreditCard && (
                <Text
                  color={textColor}
                  testID="payment-method-expirationDate"
                  textTransform="capitalize"
                >
                  {formatExpirationDate(method.credit)}
                </Text>
              )}
            </HStack>
          ) : (
            <VStack justifyContent="center">
              <HStack space={2}>
                {isCreditCard && (
                  <MethodType color={textColor} testID="payment-method-card">
                    {cardType || 'CARD'}
                  </MethodType>
                )}
                <MethodType color={textColor} textTransform="capitalize">
                  {alias}
                </MethodType>
              </HStack>
              {isCreditCard && (
                <HStack>
                  <Text
                    color={textColor}
                    testID="payment-method-expirationDate"
                    textTransform="capitalize"
                    fontSize="xs"
                  >
                    {formatExpirationDate(method.credit)}
                  </Text>
                </HStack>
              )}
            </VStack>
          )}
        </>
      );
    }

    if (method.prepaid) {
      return (
        <PrepaidMethodBalance
          method={method}
          isDisabled={disableMethod}
          fullWidthDescription={fullWidthDescription}
        />
      );
    }

    if (method.cash) {
      return <CashMethod isDisabled={disableMethod} />;
    }

    if (method.paypal) {
      return <PayPalMethod isDisabled={disableMethod} />;
    }

    return null;
  };

  return (
    <>
      <MethodContainer
        disableHover={disableHover}
        disableMethod={disableMethod}
        hideBorder={hideBorder}
        aria-labelledby={ariaLabelledby}
        testID={`payment-method-${accountIdentifier}`}
      >
        <MethodTypeWrapper
          data-private
          onPress={onClickMethod}
          $isClickable={!!onPress}
          disableMethod={disableMethod}
          fromCheckout={fromCheckout}
          testID={`method-type-wrapper-${accountIdentifier}`}
          accessibilityRole="button"
        >
          <VStack>
            <MethodTypeInnerWrapper space={2} testID="payment-method-types">
              <Box justifyContent="center">
                {method.credit && cardIconRenderer(method.credit.cardType)}
                {method.prepaid && <PrepaidCardIcon />}
                {method.cash && <Icon variant="cash" />}
                {method.paypal && (
                  <Image
                    src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_37x23.jpg"
                    alt=""
                    width="32px"
                  />
                )}
              </Box>

              <RenderMethodType />
              {disableMethod && (
                <HStack alignItems="center">
                  <Icon
                    variant="error"
                    size="$5"
                    color={theme.token('text-error')}
                    mr="$1"
                    ml="$2"
                  />
                  <Text color={theme.token('text-error')} variant="copyTwo">
                    {formatMessage({ id: 'paymentMethodNotAvailable' })}
                  </Text>
                </HStack>
              )}
            </MethodTypeInnerWrapper>
            {revaultRequired && <UnverifiedMethod onPress={onClickRevault} />}
          </VStack>
          {showDefault && (
            <RadioIndicator
              accessibilityLabel={accountAlias}
              isSelected={selected || isDefaultPaymentMethod}
              isDisabled={disableMethod}
            />
          )}
        </MethodTypeWrapper>
        <Box width="$10" ml="$2">
          {showDeleteButton && (
            <IconButton
              variant="ghost"
              testID="remove-card-button"
              accessibilityLabel={formatMessage({ id: 'removePaymentMethod' })}
              onPress={openDeletionConfirmationDialog}
              icon={<Icon variant="remove" size="$7" />}
            />
          )}
        </Box>
      </MethodContainer>

      <DeletionConfirmationDialog
        testID="delete-confirmation-dialog"
        heading={formatMessage({ id: 'deletePaymentMethod' })}
        body={formatMessage({ id: CONFIRM_DELETE_PAYMENT_METHOD_ID })}
        modalAppearanceEventMessage="Confirmation: Delete Payment Method"
      />
    </>
  );
}

export default PaymentMethodOption;
