import Head from 'expo-router/head';
import React from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@fhs-legacy/universal-components';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { Configs } from './configs';

const Container = Box.withConfig({
  flex: 1,
  padding: '$2',
});

export default function DiagnosticsScreen() {
  const enableDiagnosticTools = useFlag(LaunchDarklyFlag.ENABLE_DIAGNOSTIC_TOOLS) || __DEV__;
  const { formatMessage } = useIntl();

  return enableDiagnosticTools ? (
    <Container>
      <Head>
        <title>{`${formatMessage({ id: 'diagnostics' })} - Firehouse Subs`}</title>
      </Head>
      <Configs />
    </Container>
  ) : null;
}
