import { HStack, Header } from '@fhs-legacy/universal-components';

export const HeaderWrapper = HStack.withConfig({
  background: Styles.color.white,
  paddingTop: '$10',
  _web: {
    paddingTop: 0,
  },
  width: 'full',
  alignItems: 'center',
});

export const Heading = Header.withConfig({
  variant: 'headerOne',
  marginY: '$3',
  marginX: 'auto',
  color: Styles.color.black,
  fontWeight: 'normal',
  fontSize: 'md',
});
