import { SupportedRegions } from '@rbi-ctg/frontend';
import { REGIONS } from 'state/intl/types';
import { getConfigValue } from 'utils/environment';

export function loadRegion(): REGIONS {
  // TODO: Load this from the buildRegion config
  const region = getConfigValue({ key: 'buildRegion' }) as SupportedRegions;
  if (region !== REGIONS.US && region !== REGIONS.CA) {
    throw new Error('Config buildRegion is improperly configured');
  }

  return REGIONS[region];
}
