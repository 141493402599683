import React, { useRef } from 'react';
import { useIntl } from 'react-intl';

import { KeyboardAvoidingView } from '@fhs-legacy/universal-components';
import { BasicInput } from 'components/basic-input';

import {
  TransactionInputFormContainer,
  TransactionInputFormTitle,
} from '../loyalty-claim-points.styled';

interface TransactionInputFormProps {
  setCodeValue: (val: string) => void;
  hasError?: boolean;
}

const TransactionInputForm = ({ setCodeValue, hasError = false }: TransactionInputFormProps) => {
  const { formatMessage } = useIntl();

  const inputsRef = useRef<{ [key: string]: any }>({});
  const fieldsValues = useRef({});

  const fields = [
    {
      name: 'first',
      maxLength: 5,
      focusNext: () => inputsRef.current.second?.focus(),
      autoFocus: true,
    },
    {
      name: 'second',
      maxLength: 5,
      focusNext: () => inputsRef.current.third?.focus(),
      focusBack: () => inputsRef.current.first?.focus(),
    },
    {
      name: 'third',
      maxLength: 5,
      focusNext: () => inputsRef.current.fourth?.focus(),
      focusBack: () => inputsRef.current.second?.focus(),
    },
    {
      name: 'fourth',
      maxLength: 6,
      focusBack: () => inputsRef.current.third?.focus(),
    },
  ];

  const handleChange = (inputName: string, newInputValue: string) => {
    fieldsValues.current = { ...fieldsValues.current, [inputName]: newInputValue };
    const transactionId = fields.map(({ name }) => fieldsValues.current[name]).join('');
    setCodeValue(transactionId);
  };

  return (
    <KeyboardAvoidingView>
      <TransactionInputFormTitle>
        {formatMessage({ id: 'enterYourValidationCode' })}
      </TransactionInputFormTitle>
      <TransactionInputFormContainer>
        {fields.map(({ name, ...field }) => (
          <BasicInput
            flex={1}
            {...field}
            key={name}
            name={name}
            type="number"
            keyboardType="numeric"
            isInvalid={hasError}
            onFieldChange={handleChange}
            setRef={(instance: any) => (inputsRef.current[name] = instance)}
          />
        ))}
      </TransactionInputFormContainer>
    </KeyboardAvoidingView>
  );
};

export default TransactionInputForm;
