import React from 'react';

import { HStack } from '@fhs-legacy/universal-components';
import { IHorizontalLinkListWidgetFragment, ILinkWidgetFragment } from 'generated/sanity-graphql';

import { Links } from './links';

type IHorizontalLinkListProps = IHorizontalLinkListWidgetFragment;

export const HorizontalLinkList = (props: IHorizontalLinkListProps) => (
  <HStack
    mb="$6"
    mx={{ base: '$4', desktop: '$0' }}
    justifyContent={{ base: 'center', desktop: 'flex-start' }}
    flexWrap="wrap"
  >
    <Links links={props.linkWidgets as ILinkWidgetFragment[]} isHorizontalLink />
  </HStack>
);
