import { forwardRef } from 'react';
import { View } from 'react-native';

import { Button, ButtonProps, IconQrScan } from '@fhs/ui';

export type ScanButtonProps = Omit<ButtonProps, 'children'>;

export const ScanButton = forwardRef<View, ScanButtonProps>(function ScanButton(props, ref) {
  return (
    <Button
      ref={ref}
      type="outline"
      aria-label="Open loyalty code"
      size="sm"
      {...props}
      testID="app-header-scan-button"
    >
      <Button.Icon>
        <IconQrScan />
      </Button.Icon>

      <Button.Text>Scan</Button.Text>
    </Button>
  );
});
