import React from 'react';
import { useIntl } from 'react-intl';

import { IBackendCart } from '@rbi-ctg/menu';
import Currency from 'components/currency';
import { theme } from 'styles/configure-theme';

import { CurrencyLineSkeleton } from '../../currency-line-skeleton';

import { GrandTotalLabel, TotalsRow } from './styled';

const GrandTotalRow = TotalsRow.withConfig({
  marginTop: '$4',
});

interface GrandTotalProps {
  isLoading?: boolean;
  cart?: IBackendCart;
  serverOrderTip?: number;
  tipAmount: number;
  pickUpTips?: number;
}

const GrandTotal: React.FC<React.PropsWithChildren<GrandTotalProps>> = ({
  cart,
  serverOrderTip,
  tipAmount = 0,
  pickUpTips = 0,
  isLoading,
}) => {
  const { formatMessage } = useIntl();
  const { totalCents = 0 } = cart || {};

  return (
    <GrandTotalRow>
      {isLoading ? (
        <CurrencyLineSkeleton />
      ) : (
        <>
          <GrandTotalLabel>
            {formatMessage({
              id: 'total',
            })}
            {'* '}
          </GrandTotalLabel>
          <Currency
            testID="grand-total"
            amount={totalCents - Number(serverOrderTip) + tipAmount + pickUpTips}
            isBold
            textProps={{
              color: theme.token('text-default'),
              margin: 0,
              flexGrow: 0,
            }}
          />
        </>
      )}
    </GrandTotalRow>
  );
};

export default GrandTotal;
