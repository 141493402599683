/* eslint-disable complexity */
import { usePropsResolution } from '@fhs-legacy/native-base';

import { addWithConfig } from '../../utils';
import { Box } from '../box';
import { HStack } from '../h-stack';
import { Header } from '../header';
import { Image } from '../image';
import { InlineAlert } from '../inline-alert';
import { Pressable } from '../pressable';
import { Text } from '../text';
import { VStack } from '../v-stack';

import type { VerticalCarouselItemProps } from './types';

export const VerticalCarouselItem = ({
  calories,
  cta,
  header,
  image,
  imageAlt,
  imageUri,
  onPress,
  price,
  rewardsBadge,
  unavailable,
  unavailableMessage,
  variant,
  ...props
}: VerticalCarouselItemProps) => {
  const resolvedProps = usePropsResolution('VerticalCarouselItem', { variant, ...props });
  const categoryVariant = variant === 'category';
  const itemFooter = () => {
    if (categoryVariant) {
      return null;
    }
    if (unavailable) {
      return (
        <InlineAlert
          borderBottomRadius="12"
          borderTopRadius="0"
          width={{ base: '150', desktop: '182'}}
          status="info"
          /* TODO: add localized version of message: "Not available at selected store" */
          message={unavailableMessage}
        />
      );
    }
    return (
      <VStack alignItems="center" px="$3" pb="$5">
        <HStack space="$2" mb="$2">
          {price && (
            <Text fontWeight={700} fontSize={[12, 14]}>
              {price}
            </Text>
          )}
          {calories && <Text fontSize={[12, 14]}>{calories}</Text>}
        </HStack>
        {rewardsBadge}
        {cta}
      </VStack>
    );
  };
  return (
    <Pressable {...resolvedProps} accessibilityRole="button" onPress={onPress} flexGrow={1} mr="$4">
      {
        // @ts-ignore
        ({ isHovered, isPressed, isFocused }) => {
          const pressed = isPressed && onPress && !unavailable;
          return (
            <Box
              rounded="12"
              justifyContent="space-between"
              flexGrow={1}
              bg={pressed || isFocused ? 'token.background-focus' : 'transparent'}
            >
              <VStack px="$3" pt="$3">
                {image || (
                  <Image
                    accessibilityIgnoresInvertColors
                    resizeMode="contain"
                    source={{
                      uri: imageUri,
                    }}
                    alt={imageAlt}
                    height="100"
                    width={{ base: '126', desktop: '156'}}
                  />
                )}
                <Header
                  variant="headerThree"
                  mb={categoryVariant ? '$5' : '$2'}
                  width={{ base: '126', desktop: '156'}}
                  textAlign="center"
                  color={isHovered ? 'token.text-active' : 'token.text-default'}
                >
                  {header}
                </Header>
              </VStack>
              {itemFooter()}
            </Box>
          );
        }
      }
    </Pressable>
  );
};

export default addWithConfig(VerticalCarouselItem);
