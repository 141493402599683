import React, { FC, useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { ItemAvailabilityStatus } from 'enums/menu';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import { Message, MessageIcon, Wrapper } from './availability-status.styled';
import { IAvailabilityStatusProps } from './types';

export const getAvailabilityStatusMessage = ({
  availabilityStatus,
  dayParts,
  formatMessage,
}: {
  availabilityStatus: ItemAvailabilityStatus;
  dayParts: string[];
  formatMessage: IntlShape['formatMessage'];
}) => {
  if (availabilityStatus === ItemAvailabilityStatus.OUT_OF_DAYPART && !dayParts.length) {
    // fallback: if the dayParts aren't around,
    // show not available instead of a broken day part message
    return formatMessage({ id: 'notAvailableAtStore' });
  }

  const messageId =
    {
      [ItemAvailabilityStatus.AVAILABLE]: 'available',
      [ItemAvailabilityStatus.OUT_OF_DAYPART]: 'outOfDayparts',
      [ItemAvailabilityStatus.LIMITED_TIME_OFFER]: 'limitedTimeOffer',
      [ItemAvailabilityStatus.CART_EXTRA]: 'addFromCheckout',
      [ItemAvailabilityStatus.OUT_OF_MENU]: 'notAvailableAtStore',
      [ItemAvailabilityStatus.UNAVAILABLE]: 'notAvailableAtStore',
      [ItemAvailabilityStatus.STORE_NOT_SELECTED]: 'notAvailableAtStore',
      [ItemAvailabilityStatus.MAX_CART_QUANTITY_MET]: 'limitReached',
    }[availabilityStatus] ?? 'notAvailableAtStore';

  const dayPartsMessage = dayParts
    .map(dayPart => (dayPart === 'main menu' ? 'daytime' : dayPart))
    .join(', ');

  return formatMessage({ id: messageId }, { dayParts: dayPartsMessage });
};

/**
 *
 * AvailabilityStatus is used to display the status of a product
 * This will give a detailed error to guests explaining exactly why a product is unavailable
 *
 */
const AvailabilityStatus: FC<React.PropsWithChildren<IAvailabilityStatusProps>> = ({
  availabilityStatus,
  dayParts = [],
  hideWhenAvailable = false,
}) => {
  const { formatMessage } = useIntl();
  const message = useMemo(
    () => getAvailabilityStatusMessage({ availabilityStatus, dayParts, formatMessage }),
    [availabilityStatus, dayParts, formatMessage]
  );

  if (availabilityStatus === ItemAvailabilityStatus.AVAILABLE && hideWhenAvailable) {
    return null;
  }

  return (
    <Wrapper>
      {availabilityStatus === ItemAvailabilityStatus.AVAILABLE ? (
        <MessageIcon
          variant="check"
          testID="item-available"
          {...hiddenAccessibilityPlatformProps}
        />
      ) : (
        <MessageIcon
          variant="error"
          testID="item-unavailable"
          {...hiddenAccessibilityPlatformProps}
        />
      )}
      <Message>{message}</Message>
    </Wrapper>
  );
};

export default AvailabilityStatus;
