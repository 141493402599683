import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { ISignupForm } from 'utils/form/types';
import SessionStorage, { SessionStorageKeys } from 'utils/session-storage';

const UpdateSessionStorage = () => {
  const { values } = useFormikContext<ISignupForm>();
  useEffect(() => {
    SessionStorage.setItem(SessionStorageKeys.USER_FORM, values);
  }, [values]);
  return null;
};

export default UpdateSessionStorage;
