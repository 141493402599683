import { useCallback } from 'react';

import { useCRMEventsContext } from 'state/crm-events';
import { useLocale } from 'state/intl';
import { useLDContext } from 'state/launchdarkly';
import { changeUser, setEmail, wipeBrazeData } from 'utils/braze';
import { brand, env } from 'utils/environment';

import { UserDetails } from './types';

export const useThirdPartyAuthentication = () => {
  const { region } = useLocale();
  const { login: launchDarklyLogin, logout: launchDarklyLogout } = useLDContext();
  const { loginCRM, logoutCRM } = useCRMEventsContext();

  const logUserInToThirdPartyServices = useCallback(
    (currentUser: UserDetails) => {
      launchDarklyLogin(currentUser);

      loginCRM({
        customerId: currentUser.cognitoId,
        rbiCognitoId: currentUser.cognitoId,
        ...currentUser.details,
        brand: brand().toUpperCase(),
        region,
        env: env() as string,
      });

      changeUser(currentUser.cognitoId);

      // Update Braze Email for the user
      setEmail(currentUser.details.email);
    },
    [launchDarklyLogin, loginCRM, region]
  );

  const logUserOutOfThirdPartyServices = useCallback(() => {
    launchDarklyLogout();
    logoutCRM();
    wipeBrazeData();
  }, [launchDarklyLogout, logoutCRM]);

  return { logUserInToThirdPartyServices, logUserOutOfThirdPartyServices };
};
