import { IIncentiveSelection } from '@fhs-legacy/frontend/src/remote/queries/order';
import { EventTypes, IEventBase } from '@rbilabs/mparticle-client';

import { SimplyOfferEventNames } from './constants';

interface IOfferAddedToCartEvent extends IEventBase {
  name: SimplyOfferEventNames.OFFER_ADDED_TO_CART;
  type: EventTypes.Other;
  attributes: {
    offerId: string;
    incentiveType: string;
    sanityId: string;
    selections?: IIncentiveSelection[];
  };
}

interface IOfferRemovedFromCartEvent extends IEventBase {
  name: SimplyOfferEventNames.OFFER_REMOVED_FROM_CART;
  type: EventTypes.Other;
  attributes: {
    offerId: string;
  };
}

interface IOfferGuideHasItemsInCartEvent extends IEventBase {
  name: SimplyOfferEventNames.OFFER_GUIDE_HAS_ITEMS_IN_CART;
  type: EventTypes.Other;
  attributes: {
    offerId: string;
    sanityId: string;
    itemIds: string[];
  };
}

interface IOfferGuideWentPrevStepEvent extends IEventBase {
  name: SimplyOfferEventNames.OFFER_GUIDE_WENT_PREV_STEP;
  type: EventTypes.Other;
  attributes: {
    offerId: string;
    sanityId: string;
  };
}

interface IOfferGuideStepSelectedEvent extends IEventBase {
  name: SimplyOfferEventNames.OFFER_GUIDE_STEP_SELECTED;
  type: EventTypes.Other;
  attributes: {
    offerId: string;
    sanityId: string;
    stepIndex: number;
  };
}

interface IOfferGuideSummaryShownEvent extends IEventBase {
  name: SimplyOfferEventNames.OFFER_GUIDE_SUMMARY_SHOWN;
  type: EventTypes.Other;
  attributes: {
    offerId: string;
    sanityId: string;
    selections: {
      selectedEntry: {
        itemId: string;
        lineId: string;
      };
      key: string;
      rank: number;
    }[];
  };
}

interface IOfferGuideExitDialogShownEvent extends IEventBase {
  name: SimplyOfferEventNames.OFFER_GUIDE_EXIT_DIALOG_SHOWN;
  type: EventTypes.Other;
  attributes: {
    offerId: string;
    sanityId: string;
  };
}

interface IOfferSwitchServiceModeEvent extends IEventBase {
  name: SimplyOfferEventNames.OFFER_SWITCH_SERVICE_MODE;
  type: EventTypes.Other;
  attributes: {
    currentServiceMode: string;
  };
}

export type SimplyOfferEvents =
  | IOfferAddedToCartEvent
  | IOfferRemovedFromCartEvent
  | IOfferGuideHasItemsInCartEvent
  | IOfferGuideSummaryShownEvent
  | IOfferGuideStepSelectedEvent
  | IOfferGuideWentPrevStepEvent
  | IOfferGuideExitDialogShownEvent
  | IOfferSwitchServiceModeEvent;
