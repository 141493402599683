import { Svg, SvgPath, type SvgProps, registerSVG } from '../../../src/components/svg';

export function PickupWindow(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgPath
        d="M19.6889 4.13625C19.4667 3.4725 18.8444 3 18.1111 3H5.88889C5.15556 3 4.54444 3.4725 4.31111 4.13625L2 10.875V19.875C2 20.4937 2.5 21 3.11111 21H4.22222C4.83333 21 5.33333 20.4937 5.33333 19.875V18.75H18.6667V19.875C18.6667 20.4937 19.1667 21 19.7778 21H20.8889C21.5 21 22 20.4937 22 19.875V10.875L19.6889 4.13625ZM5.88889 15.375C4.96667 15.375 4.22222 14.6213 4.22222 13.6875C4.22222 12.7537 4.96667 12 5.88889 12C6.81111 12 7.55556 12.7537 7.55556 13.6875C7.55556 14.6213 6.81111 15.375 5.88889 15.375ZM18.1111 15.375C17.1889 15.375 16.4444 14.6213 16.4444 13.6875C16.4444 12.7537 17.1889 12 18.1111 12C19.0333 12 19.7778 12.7537 19.7778 13.6875C19.7778 14.6213 19.0333 15.375 18.1111 15.375ZM4.22222 9.75L5.88889 4.6875H18.1111L19.7778 9.75H4.22222Z"
        fill="#01000B"
      />
    </Svg>
  );
}

registerSVG('PickupWindow', PickupWindow);
