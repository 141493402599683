import { Box, Text } from '@fhs-legacy/universal-components';
import { primitive } from 'styles/constants/primitives';

export const LayoutContainerWrapper = Box.withConfig({
  justifyContent: 'space-between',
  backgroundColor: primitive.$white,
});

export const LayoutContainer = Box.withConfig({
  height: 'full',
});

/*

// TODO: Migrate Desktop styles
export const LayoutContainer = styled.div<{ $isStatic?: boolean }>`
  display: flex;
  flex-direction: column;
  ${Styles.desktop`
    position: relative;
    width: ${(p: { $isStatic?: boolean }) => (p.$isStatic ? '100%' : '1136px')};
    margin: 0 auto;
    padding-bottom: ${(p: { $isStatic?: boolean }) => (p.$isStatic ? '2rem' : '0')};
    margin-top: ${(p: StyledProps<{}>) => p.theme.token('margin-top-product-wizard-layout')};
    ${OverviewContainer} {
      width: ${(p: { $isStatic?: boolean }) => (p.$isStatic ? 'inherit' : '60%')};
    }
    ${LayoutContainerWrapper} {
      width: 40%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      ${DrawerContainer}{
        height: 100%;
      }
    }
  `}
`;

export const CustomizationPanelFixedHeader = styled.div`
  box-shadow: ${p => p.theme.token('drop-shadow-top-nav')};
  z-index: ${Styles.zIndex.below};
`;
*/

export const ProductNoteText = Text.withConfig({
  variant: 'copyTwo',
});

export const ProductListItemSeparator = Box.withConfig({
  height: '1px',
  backgroundColor: primitive.$blackOpacity10,
  marginX: '4%',
});

export const ProductListWrapper = Box.withConfig({
  flex: 1,
  marginBottom: {
    base: 1,
    desktop: 0,
  },
});
