import { IntlFormatters } from 'react-intl';

import { SupportedRegions } from '@rbi-ctg/frontend';
import { getDobErrors, getLegacyDobErrors } from 'pages/authentication/sign-up/dobValidation';
import {
  COUNTRY_BASED_ZIP_CODE_REGEX,
  getPhoneNumberErrorMessage,
  isEmailValid,
  isNameInputValid,
  isPhoneNumberValid,
} from 'utils/form';
import { ISOs } from 'utils/form/constants';
import { validationConfig } from 'utils/form/user-profile-validation-config/validation';

import { IUserFormData } from './types';

/* ======== Helpers ======== */
export const isRequired = (
  required: boolean | { readonly locales: readonly string[] },
  region: SupportedRegions
) => required === true || (required !== false && required.locales.includes(region));

/*  ======== Form Validation ======== */
export interface INameAndEmailParams {
  formData: IUserFormData;
  formatMessage: IntlFormatters['formatMessage'];
}

export function validateEmail({ formData, formatMessage }: INameAndEmailParams) {
  if (!formData.email.trim()) {
    return { email: formatMessage({ id: 'emailRequiredError' }) };
  }

  if (!isEmailValid(formData.email)) {
    return { email: formatMessage({ id: 'notValidEmailError' }) };
  }

  return {};
}

export function validateName(nameParams: INameAndEmailParams) {
  if (!nameParams.formData.name.trim()) {
    return {
      name: nameParams.formatMessage({ id: 'nameRequiredError' }),
    };
  } else if (!isNameInputValid(nameParams.formData.name)) {
    return {
      name: nameParams.formatMessage({ id: 'notValidNameError' }),
    };
  }

  return {};
}

export interface IPhoneDobZipParams extends INameAndEmailParams {
  region: SupportedRegions;
  simpleValidation?: boolean;
}
export async function validatePhoneNumber({
  formData,
  region,
  formatMessage,
  simpleValidation,
}: IPhoneDobZipParams) {
  const phoneNumberValidation =
    isRequired(validationConfig.phoneNumber.required, region) || formData.phoneNumber
      ? await isPhoneNumberValid({
          phoneNumber: formData.phoneNumber,
          country: region,
          simpleValidation,
        })
      : { valid: true };

  if (!phoneNumberValidation.valid) {
    return {
      phoneNumber: getPhoneNumberErrorMessage(phoneNumberValidation.error, formatMessage),
    };
  }

  return {};
}

export function validateDateOfBirth(dobParams: IPhoneDobZipParams) {
  let dobError;
  // ! The typeof === object conditional may be able to be removed at this point
  if (typeof dobParams.formData.dob === 'object') {
    dobError =
      getDobErrors(dobParams.formData.dob, dobParams.formatMessage, dobParams.region) ?? undefined;
  } else {
    dobError =
      getLegacyDobErrors(dobParams.formData.dob, dobParams.formatMessage, dobParams.region) ??
      undefined;
  }

  return dobError ? { dob: dobError } : {};
}

export function validateZipCode({ formData, formatMessage, region }: IPhoneDobZipParams) {
  let zipCodeError;

  if (isRequired(validationConfig.zipcode.required, region) && !formData.zipcode) {
    zipCodeError =
      formData.isoCountryCode === ISOs.USA
        ? formatMessage({ id: 'zipCodeRequiredError' })
        : formatMessage({ id: 'postalCodeRequiredError' });
  }

  const regex = COUNTRY_BASED_ZIP_CODE_REGEX[formData.isoCountryCode];

  if (formData.zipcode && formData.isoCountryCode && !regex.test(formData.zipcode.toUpperCase())) {
    zipCodeError =
      formData.isoCountryCode === ISOs.USA
        ? formatMessage({ id: 'zipCodeInvalid' })
        : formatMessage({ id: 'postalCodeInvalid' });
  }

  return zipCodeError ? { zipcode: zipCodeError } : {};
}

export const allValidationMethods = {
  validateEmail,
  validateName,
  validatePhoneNumber,
  validateDateOfBirth,
  validateZipCode,
};
//
