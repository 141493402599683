import { ApolloError } from '@apollo/client';
import { Link, router, useLocalSearchParams } from 'expo-router';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { omit } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Box,
  Divider,
  FormikCheckbox,
  FormikFormControl,
  FormikInput,
  InlineAlert,
  Text,
} from '@fhs-legacy/universal-components';
import { FormikBirthdayInput } from 'components/formik/birthday-input';
import { FormikCountrySelect } from 'components/formik/country-select';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import {
  PromotionalEmailsLabel,
  TermsOfServiceLabel,
} from 'components/user-info-form/checkbox-labels';
import { useSignUpCompleteQuery } from 'generated/graphql-gateway';
import useAuth from 'hooks/auth/use-auth';
import useAuthRedirects from 'hooks/auth/use-auth-redirects';
import { useLoyaltyContent } from 'hooks/loyalty/use-loyalty-content';
// import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import useEffectOnce from 'hooks/use-effect-once';
import useErrorModal from 'hooks/use-error-modal';
import { useFeatureAccountForm } from 'hooks/use-feature-account-form';
import { useFeatureDisclaimers } from 'hooks/use-feature-disclaimers';
import { useFieldIsRequired } from 'hooks/use-field-is-required';
import AuthActions from 'pages/authentication/auth-actions';
import UnauthRequired from 'pages/authentication/unauth-required';
import { StoreLocatorProvider } from 'pages/store-locator/new-ui/store-locator-provider';
import { SIGN_IN_FAIL } from 'state/auth/constants';
import { IAuthRouteParams } from 'state/auth/types';
import { useCRMEventsContext } from 'state/crm-events';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  SignUpFieldsVariations,
  defaultSignUpFieldsVariation,
} from 'state/launchdarkly/variations';
import { GraphQLErrorCodes, graphQLErrorsIncludeExtensionCode } from 'utils/errors';
import { dobStringToObj, formatDateObject, sanitizePhoneNumber } from 'utils/form';
import { ISOs } from 'utils/form/constants';
import { ISignupErrors, ISignupFormData } from 'utils/form/types';
import { validationConfig } from 'utils/form/user-profile-validation-config/validation';
import { loadRegion } from 'utils/intl/region';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import logger from 'utils/logger';
import { routes } from 'utils/routing';
import SessionStorage, { SessionStorageKeys } from 'utils/session-storage';
import { useSupportLink } from 'utils/support/use-support-link';
import {
  SIGNUP_FORM_EMAIL_INPUT,
  SIGNUP_FORM_NAME_INPUT,
  SIGNUP_FORM_TERMS_AGREEMENT,
  SIGNUP_SUBMIT_BUTTON,
} from 'utils/test-ids';

import AutoSignin from './auto-signin';
import { SHOW_PHONE_INPUT_INSIDE_OPTIONAL_INFO } from './constants';
import ExistingEmailDialog from './existing-email-dialog';
import FavoriteLocationField from './favorite-location-field';
import initialFormState from './initial-form-state';
import PhoneNumberField from './phone-number-field';
import { SignupAccordion } from './signup-accordion/signup-accordion';
import { Disclaimer, ErrorMessage, FieldContainer, MarketDisclaimer } from './signup.styled';
import { IOverrides, OverridableFields } from './types';
import UpdateSessionStorage from './update-session-storage';
import { generateDisplayedErrors, validateSignupFormError } from './utils';

const LEGACY_USER_CREATE_CONFLICT = 'LEGACY_USER_CREATE_CONFLICT';
const USERNAME_EXISTS_EXCEPTION = 'UsernameExistsException';
const ZERO_BOUNCE = 'zerobounce';
const POLLING_INTERVAL = 2000;
const SIGNUP_COMPLETE_TIMEOUT = 1000 * 30;

/**
 * LaunchDarkly has a flag that decides if we return an error
 * via GraphQL or as a network error.
 *
 * @param error The error returned from graphql
 *
 * @returns {boolean}
 */
export const hasZeroBounceError = (error: ApolloError | Error): boolean => {
  const zeroBounceGraphqlValidationFailed = graphQLErrorsIncludeExtensionCode(
    error instanceof ApolloError ? error.graphQLErrors : [],
    GraphQLErrorCodes.ZERO_BOUNCE_VALIDATION_FAILED
  );

  const zeroBounceGraphqlError = graphQLErrorsIncludeExtensionCode(
    error instanceof ApolloError ? error.graphQLErrors : [],
    GraphQLErrorCodes.ZERO_BOUNCE
  );

  const zeroBounceNetworkError = error.message.toLowerCase().includes(ZERO_BOUNCE);

  return zeroBounceGraphqlValidationFailed || zeroBounceGraphqlError || zeroBounceNetworkError;
};

export const initialErrorState = (): ISignupErrors => ({});

function NameOfTheDayDisclaimer() {
  const { formatMessage } = useIntl();

  return (
    <InlineAlert
      iconCentered
      status="info"
      marginBottom={6}
      borderRadius={8}
      message={formatMessage(
        { id: 'signUpNameDisclaimer' },
        {
          promotionName: (
            <Link href="/nameoftheday" target="_blank">
              <Text underline bold>
                {formatMessage({ id: 'nameOfTheDay' })}
              </Text>
            </Link>
          ),
        }
      )}
    />
  );
}

interface ISignup {
  skipRedirect?: boolean;
  initialEmail?: string;
}

const SignUp = ({ skipRedirect, initialEmail }: ISignup) => {
  // useRumPageView('sign-up', 'Sign Up');
  useAuthRedirects({ skip: skipRedirect! });
  const { signUp, originLocation } = useAuth();

  const params = useLocalSearchParams<IAuthRouteParams>();

  const { formatMessage } = useIntl();

  const [formSuccessfullySubmitted, setFormSuccessfullySubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(initialEmail);
  const [signUpJwt, setSignUpJwt] = useState('');
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const { region } = useLocale();
  const enableNameChangeOnAccountPage = useFlag(
    LaunchDarklyFlag.ENABLE_NAME_CHANGE_ON_ACCOUNT_PAGE
  );
  const enableCustomBirthdayInput = useFlag(LaunchDarklyFlag.ENABLE_CUSTOM_BIRTHDAY_INPUT);
  const enableSignupCommunicationsPreselection = useFlag(
    LaunchDarklyFlag.ENABLE_SIGNUP_COMMUNICATIONS_PRESELECTION
  );
  const enableZeroBounceErrorModal = useFlag(LaunchDarklyFlag.ENABLE_ZERO_BOUNCE_ERROR_MODAL);
  const enableSignupAfterCart = useFlag(LaunchDarklyFlag.ENABLE_MOVE_SIGNUP_AFTER_CART);
  const signUpFieldsVariations =
    useFlag<SignUpFieldsVariations>(LaunchDarklyFlag.SIGN_UP_FIELDS_VARIATIONS) ||
    defaultSignUpFieldsVariation;
  const enableNumericPhoneValidation = useFlag(LaunchDarklyFlag.ENABLE_NUMERIC_PHONE_VALIDATION);

  const requiredOverrides = signUpFieldsVariations.phone
    ? undefined
    : { [OverridableFields.PHONE]: { required: false } };
  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Sign In Failure',
  });

  const zipCodeIsRequired = useFieldIsRequired(region, validationConfig, 'zipcode');
  const birthdayIsRequired = useFieldIsRequired(region, validationConfig, 'dob');
  const phoneNumberIsRequired = useFieldIsRequired(region, validationConfig, 'phoneNumber');
  const favoriteStoreIsRequired = useFieldIsRequired(region, validationConfig, 'favoriteStore');

  const supportLink = useSupportLink();

  const [showSignInWithExistingEmailDialog, setShowSignInWithExistingEmailDialog] = useState(false);
  const [ZeroBounceErrorDialog, openZeroBounceErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Sign In Failure [Zero Bounce]',
  });
  const [ForterSignUpErrorDialog, openForterSignUpErrorDialog] = useErrorModal({
    onConfirm: () => router.navigate(supportLink),
    modalAppearanceEventMessage: 'Error: Sign Up Failure [Forter]',
  });

  const { signUpEvent } = useCRMEventsContext();
  const { featureDisclaimers } = useFeatureDisclaimers();
  const { birthdayLegalText } = useLoyaltyContent();
  const {
    data: signUpCompleteData,
    startPolling,
    stopPolling,
  } = useSignUpCompleteQuery({
    variables: {
      jwt: signUpJwt,
    },
    skip: !signUpJwt,
  });

  const timeoutId = useRef<NodeJS.Timeout>();
  const startSignUpCompleteTimeout = useCallback(() => {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      if (!signUpCompleteData?.signUpComplete) {
        stopPolling();
        setSignUpJwt('');
        setIsLoading(false);
        openErrorDialog({ message: formatMessage({ id: 'authRetryError' }) });
      }
    }, SIGNUP_COMPLETE_TIMEOUT);
  }, [signUpCompleteData]);

  const clearSignUpCompleteTimeout = useCallback(() => {
    clearTimeout(timeoutId.current);
  }, []);

  const { featureAccountForm } = useFeatureAccountForm();
  const isSignUpCompleteLoading = !!signUpJwt && !signUpCompleteData?.signUpComplete;

  const { country, signUpDisclaimers, signUpMarketDisclaimers, phoneNumber } =
    featureDisclaimers || {};
  const birthdayDisclaimer = birthdayLegalText ?? '';
  const postalZipCodeDisclaimer = featureAccountForm?.postalOrZipDisclaimer?.locale ?? '';
  const signInLink = useRef<HTMLAnchorElement>(null);

  const defaultSignupValues =
    SessionStorage.getItem(SessionStorageKeys.USER_FORM) ||
    initialFormState({
      enableCustomBirthdayInput,
      regionIso: ISOs[region],
      enableSignupCommunicationsPreselection,
    });

  const initialUserFormState: ISignupFormData = {
    ...defaultSignupValues,
    email: initialEmail || defaultSignupValues.email,
    dob:
      typeof defaultSignupValues.dob === 'string'
        ? dobStringToObj(defaultSignupValues.dob)
        : defaultSignupValues.dob,
  };

  useEffectOnce(() => {
    // when landing on sign up page focus should be on sign in link for accessibility
    signInLink.current?.focus();

    // Clear signup complete timeout on component unmount
    return () => {
      clearSignUpCompleteTimeout();
    };
  });

  const handleAccordionInteraction = (isActive: boolean) => {
    setIsAccordionOpen(isActive);
  };
  useEffectOnUpdates(() => {
    const baseParams = { ...omit(params, '*') };
    if (isAccordionOpen) {
      baseParams.isOptionalInfoOpen = 'true';
    } else {
      baseParams.isOptionalInfoOpen = 'false';
    }

    // TODO: expo-router
    // temporarily disable until we port this page and need to turn this back on
    // router.setParams(baseParams);
  }, [isAccordionOpen]);

  const handleSubmitError = useCallback(
    (error: any, values: ISignupFormData, actions: FormikHelpers<ISignupFormData>) => {
      logger.warn(error);
      setIsLoading(false);

      // Clear signup complete timeout
      clearSignUpCompleteTimeout();

      // Determine error type
      const forterSignUpDecline = graphQLErrorsIncludeExtensionCode(
        error.graphQLErrors,
        GraphQLErrorCodes.SIGNUP_VALIDATION_ERROR
      );
      const emailAlreadyExists = graphQLErrorsIncludeExtensionCode(
        error.graphQLErrors,
        GraphQLErrorCodes.AUTH_EMAIL_ALREADY_EXIST
      );
      const legacyErrorRegexp = new RegExp(LEGACY_USER_CREATE_CONFLICT, 'g');
      const userAlreadyExists =
        error.code === USERNAME_EXISTS_EXCEPTION ||
        error.message.match(legacyErrorRegexp) ||
        emailAlreadyExists;
      const signInFailure = error.code === SIGN_IN_FAIL;
      const zeroBounceFailure = enableZeroBounceErrorModal && hasZeroBounceError(error);

      // Perform next actions based on which error has been received
      if (forterSignUpDecline) {
        openForterSignUpErrorDialog({
          error,
          message: formatMessage({ id: 'forterSignUpError' }),
          title: formatMessage({ id: 'duplicateAccounts' }),
        });
        actions.setFieldError('email', formatMessage({ id: 'accountWithEmailAlreadyExists' }));
        return;
      }
      if (userAlreadyExists) {
        setEmail(values.email);
        setShowSignInWithExistingEmailDialog(true);
        actions.setFieldError('email', formatMessage({ id: 'accountWithEmailAlreadyExists' }));
        return;
      }
      if (signInFailure) {
        router.setParams({
          errorMessage: formatMessage({ id: 'signUpSuccessSignInFail' }),
          activeRouteIsSignIn: 'true',
        });
      }
      if (zeroBounceFailure) {
        openZeroBounceErrorDialog({
          error,
          message: formatMessage({ id: 'theEmailAddressIsInvalid' }),
          title: formatMessage({ id: 'invalidEmail' }),
        });
        return;
      }
      openErrorDialog({
        error,
        message: formatMessage({ id: 'signUpError' }),
      });
    },
    [
      enableZeroBounceErrorModal,
      formatMessage,
      openErrorDialog,
      openForterSignUpErrorDialog,
      openZeroBounceErrorDialog,
      clearSignUpCompleteTimeout,
      setShowSignInWithExistingEmailDialog,
    ]
  );

  const onSubmit = useCallback(
    async (values: ISignupFormData, actions: FormikHelpers<ISignupFormData>) => {
      setIsLoading(true);
      LocalStorage.setItem(StorageKeys.LOGIN, values.email);
      // need to do this so that TH uses the correct country in a
      // case where user switches locales in the middle of signing up
      let signupCountry = values.country;
      // only if we aren't giving the user the specific country option
      if (!signUpFieldsVariations.country) {
        signupCountry = ISOs[region] || loadRegion();
      }

      const favoriteStoresInfo = !!values.favoriteLocation?.storeId &&
        !!values.favoriteLocation?.storeNumber &&
        signUpFieldsVariations.favoriteStoreSelector && {
          favoriteStores: [
            {
              storeId: values.favoriteLocation?.storeId,
              storeNumber: values.favoriteLocation?.storeNumber,
            },
          ],
        };

      // We can only send a string for DOB
      const dob = formatDateObject(values.dob);
      const signupValues = {
        ...values,
        ...favoriteStoresInfo,
        phoneNumber: await sanitizePhoneNumber(values.phoneNumber, signupCountry),
        dob,
        country: signupCountry,
        email: values.email.trim(),
        zipcode: values.zipcode?.toUpperCase(),
      };

      try {
        const { jwt } = await signUp(signupValues);

        setIsLoading(false);
        setEmail(values.email);
        setFormSuccessfullySubmitted(true);

        if (jwt) {
          setSignUpJwt(jwt);
          startPolling(POLLING_INTERVAL);
        }
      } catch (error) {
        handleSubmitError(error, values, actions);
      }
    },
    [
      signUpFieldsVariations.country,
      signUpFieldsVariations.favoriteStoreSelector,
      region,
      signUp,
      startPolling,
      handleSubmitError,
    ]
  );

  const shouldDisplayMarketDisclaimer = useMemo(() => {
    return (
      enableSignupAfterCart && signUpMarketDisclaimers?.locale && originLocation === routes.cart
    );
  }, [enableSignupAfterCart, originLocation, signUpMarketDisclaimers]);

  return (
    <>
      <UnauthRequired>
        {formSuccessfullySubmitted && (
          <VisuallyHidden
            accessibilityLabel={formatMessage({ id: 'youHaveSuccessfullySubmittedTheForm' })}
          >
            <Box accessibilityRole="alert">
              {formatMessage({ id: 'signupEmailSent' }, { email })}
            </Box>
          </VisuallyHidden>
        )}
        <Formik
          initialValues={initialUserFormState}
          onSubmit={onSubmit}
          validate={validateSignupFormError({
            formatMessage,
            region,
            overrides: requiredOverrides as IOverrides,
            signUpFieldsVariations,
            simpleValidation: SHOW_PHONE_INPUT_INSIDE_OPTIONAL_INFO && enableNumericPhoneValidation,
          })}
        >
          {({
            handleSubmit,
            errors,
            setFieldValue,
            touched,
            setTouched,
            validateForm,
            values,
            setFieldTouched,
          }: FormikProps<ISignupFormData>) => {
            const isDobInvalid = Boolean(touched.dob && errors.dob);
            const isFavoriteLocationInvalid = Boolean(
              touched.favoriteLocation && errors.favoriteLocation
            );

            const accessibilityLabel = formatMessage(
              /* @ts-expect-error TS(2322) FIXME: */
              generateDisplayedErrors(errors, touched).length === 1
                ? { id: 'thereIsAnErrorInThisForm' }
                : { id: 'thereAreErrorsInThisForm' },
              {
                /* @ts-expect-error TS(2322) FIXME: */
                numberOfErrors: generateDisplayedErrors(errors, touched).length,
              }
            );

            return (
              <>
                {/* @ts-expect-error TS(2322) FIXME: */}
                {generateDisplayedErrors(errors, touched).length ? (
                  <VisuallyHidden role="alert" accessibilityLabel={accessibilityLabel}>
                    <Text marginBottom="$2" variant="copyOne">
                      {accessibilityLabel}
                    </Text>
                  </VisuallyHidden>
                ) : null}

                {shouldDisplayMarketDisclaimer && (
                  <MarketDisclaimer testID="signup-market-disclaimer">
                    {signUpMarketDisclaimers?.locale}
                  </MarketDisclaimer>
                )}

                {signUpFieldsVariations.country && (
                  <FieldContainer>
                    <FormikCountrySelect
                      name="country"
                      testID="signup-country-input"
                      label={formatMessage({ id: 'country' })}
                      disclaimer={country?.locale || ''}
                      onChange={value => {
                        setFieldValue('country', value.toUpperCase());
                      }}
                      value={values.country}
                    />
                    <ErrorMessage>{errors.country}</ErrorMessage>
                  </FieldContainer>
                )}

                <FieldContainer>
                  <FormikFormControl
                    label={formatMessage({ id: 'firstName' })}
                    name="name"
                    isRequired
                  >
                    <FormikInput
                      inputProps={{
                        accessibilityLabel: formatMessage({ id: 'name' }),
                        placeholder: formatMessage({ id: 'firstName' }),
                        testID: SIGNUP_FORM_NAME_INPUT,
                        placeholderTextColor: '#979797',
                        autoComplete: 'name-given',
                        autoFocus: true,
                      }}
                    />
                  </FormikFormControl>
                </FieldContainer>
                {!enableNameChangeOnAccountPage && <NameOfTheDayDisclaimer />}

                <FieldContainer>
                  <FormikFormControl
                    label={formatMessage({ id: 'emailAddress' })}
                    name="email"
                    isRequired
                  >
                    <FormikInput
                      inputProps={{
                        accessibilityLabel: formatMessage({ id: 'email' }),
                        placeholder: formatMessage({ id: 'emailAddress' }),
                        testID: SIGNUP_FORM_EMAIL_INPUT,
                        placeholderTextColor: '#979797',
                        autoCapitalize: 'none',
                        autoCorrect: false,
                        keyboardType: 'email-address',
                        autoComplete: 'email',
                      }}
                    />
                  </FormikFormControl>
                </FieldContainer>

                {signUpFieldsVariations.phone && !SHOW_PHONE_INPUT_INSIDE_OPTIONAL_INFO && (
                  <>
                    <PhoneNumberField
                      isRequired={phoneNumberIsRequired}
                      hintMessage={phoneNumber?.locale}
                    />
                    {!!phoneNumber?.locale && <Disclaimer>{phoneNumber?.locale}</Disclaimer>}
                  </>
                )}
                {signUpFieldsVariations.phone && SHOW_PHONE_INPUT_INSIDE_OPTIONAL_INFO && (
                  <>
                    <PhoneNumberField
                      isRequired={phoneNumberIsRequired}
                      hintMessage={phoneNumber?.locale}
                    />
                    {!!phoneNumber?.locale && <Disclaimer>{phoneNumber?.locale}</Disclaimer>}
                  </>
                )}

                <SignupAccordion
                  title={formatMessage({ id: 'optionalInformation' })}
                  onInteract={handleAccordionInteraction}
                  isOpen={isDobInvalid || isAccordionOpen}
                  disabled={isDobInvalid}
                  id={'optional-information'}
                >
                  <Divider marginBottom={'$6'} />
                  {signUpFieldsVariations.zip && (
                    <FieldContainer>
                      <FormikFormControl
                        label={formatMessage({
                          id: values.country === ISOs.USA ? 'zipCode' : 'postalCode',
                        })}
                        name="zipcode"
                        isRequired={zipCodeIsRequired}
                      >
                        <FormikInput
                          inputProps={{
                            placeholder: formatMessage({
                              id: values.country === ISOs.USA ? 'zipCode' : 'postalCode',
                            }),
                            testID: 'signup-zipcode-input',
                            placeholderTextColor: '#979797',
                            maxLength: 10,
                            autoComplete: 'postal-code',
                          }}
                        />
                      </FormikFormControl>
                    </FieldContainer>
                  )}
                  {!!postalZipCodeDisclaimer && <Disclaimer>{postalZipCodeDisclaimer}</Disclaimer>}
                  {signUpFieldsVariations.dob && (
                    <FieldContainer>
                      <FormikFormControl name="dob">
                        <FormikBirthdayInput
                          disabled={isLoading || isSignUpCompleteLoading}
                          day={values.dob.day}
                          month={values.dob.month}
                          year={values.dob.year}
                          isRequired={birthdayIsRequired}
                          onBlur={inputName => setFieldTouched(`dob.${inputName}`, true)}
                          onChange={(inputName, inputText) =>
                            setFieldValue(`dob.${inputName}`, inputText)
                          }
                          testID="signup-dob"
                          dobErrors={errors.dob}
                          dobTouched={touched.dob}
                          hintMessage={birthdayDisclaimer}
                        />
                      </FormikFormControl>
                    </FieldContainer>
                  )}
                  {signUpFieldsVariations.favoriteStoreSelector && (
                    <StoreLocatorProvider>
                      <FavoriteLocationField
                        setFieldValue={(...args) => {
                          setFieldValue(...args);
                        }}
                        isInvalid={isFavoriteLocationInvalid}
                        disabled={isLoading || isSignUpCompleteLoading}
                        isRequired={favoriteStoreIsRequired}
                      />
                    </StoreLocatorProvider>
                  )}
                  {signUpDisclaimers?.locale && <Disclaimer>{signUpDisclaimers.locale}</Disclaimer>}
                </SignupAccordion>

                <Divider marginBottom={'$6'} />

                {signUpFieldsVariations.wantsPromotionalEmails && (
                  <FieldContainer>
                    <FormikCheckbox
                      name="wantsPromotionalEmails"
                      checkboxProps={{
                        alignItems: 'flex-start',
                        value: 'true',
                        testID: 'signup-promotional-emails',
                        accessibilityLabel: formatMessage({ id: 'signUpForPromotionalEmails' }),
                      }}
                    >
                      <PromotionalEmailsLabel />
                    </FormikCheckbox>
                  </FieldContainer>
                )}

                {signUpFieldsVariations.agreesToTermsOfService && (
                  <FieldContainer>
                    <FormikFormControl name="agreesToTermsOfService" isRequired>
                      <FormikCheckbox
                        checkboxProps={{
                          onChange: isSelected =>
                            setFieldValue('agreesToTermsOfService', isSelected),
                          alignItems: 'flex-start',
                          value: 'false',
                          testID: SIGNUP_FORM_TERMS_AGREEMENT,
                          accessibilityLabel: formatMessage({ id: 'agreeToTermsOfService' }),
                        }}
                      >
                        <TermsOfServiceLabel />
                      </FormikCheckbox>
                    </FormikFormControl>
                  </FieldContainer>
                )}

                <AuthActions
                  onPress={async () => {
                    const error = await validateForm();

                    const touchedFields = Object.entries(error).reduce((accum, [key, value]) => {
                      if (!value) {
                        return { ...accum };
                      }
                      return {
                        [key]: true,
                        ...accum,
                      };
                    }, {});
                    setTouched(touchedFields);

                    if (Object.keys(error).length !== 0) {
                      signUpEvent({ success: false });
                      return;
                    }
                    startSignUpCompleteTimeout();
                    handleSubmit();
                  }}
                  primaryActionQaId={SIGNUP_SUBMIT_BUTTON}
                  primaryActionLabel={formatMessage({ id: 'createAnAccount' })}
                  primaryActionIsLoading={isLoading || isSignUpCompleteLoading}
                  primaryActionDisabled={isLoading || isSignUpCompleteLoading || isDobInvalid}
                />

                <AutoSignin
                  signUpJwt={signUpJwt}
                  stopPolling={stopPolling}
                  signUpCompleteData={signUpCompleteData}
                />
                <UpdateSessionStorage />
              </>
            );
          }}
        </Formik>
      </UnauthRequired>

      <ErrorDialog />
      <ExistingEmailDialog
        email={email}
        showDialog={showSignInWithExistingEmailDialog}
        dismiss={() => setShowSignInWithExistingEmailDialog(false)}
      />
      <ForterSignUpErrorDialog
        testID="forter-signup-error-modal"
        buttonLabel={formatMessage({ id: 'contactSupport' })}
        showCloseButton
      />
      <ZeroBounceErrorDialog testID="zero-bounce-error-modal" />
    </>
  );
};

export default SignUp;
