import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconCopy = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M6.058 15.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.283V2.308c0-.505.175-.933.525-1.283C5.125.675 5.553.5 6.058.5h8.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v11.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H6.058Zm0-1.5h8.384a.294.294 0 0 0 .212-.096.294.294 0 0 0 .096-.212V2.308a.294.294 0 0 0-.096-.212.294.294 0 0 0-.212-.096H6.058a.294.294 0 0 0-.212.096.294.294 0 0 0-.096.212v11.384c0 .077.032.148.096.212a.294.294 0 0 0 .212.096Zm-3.5 5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.283V4.308h1.5v12.884c0 .077.032.148.096.212a.294.294 0 0 0 .212.096h9.884V19H2.558Z"
      />
    </Svg>
  );
};
