import { PropsWithChildren } from 'react';

import { Box } from '@fhs-legacy/universal-components';

export const IconContainer = ({ children }: PropsWithChildren) => (
  <Box
    borderColor="#e5e5e5"
    borderWidth="1"
    borderRadius="lg"
    height="$8"
    width="$8"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    {children}
  </Box>
);
