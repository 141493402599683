import { Box, NbModal } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

import { DrawerPosition } from './types';

export const Wrapper = NbModal.withConfig<{ drawerPosition: DrawerPosition }>(p => ({
  alignItems: p.drawerPosition === 'left' ? 'flex-start' : 'flex-end',
  justifyContent: 'flex-start',
}));

export const Content = Box.withConfig<{ contentWidth: number }>(p => ({
  background: theme.token('background-default'),
  flex: 1,
  width: p.contentWidth,
}));
