import React, { useCallback, useState } from 'react';

import { Text } from '@fhs/ui';
import { Box, Icon } from '@fhs-legacy/universal-components';
import Currency from 'components/currency';
import { CurrencyLineSkeleton } from 'pages/cart/currency-line-skeleton';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import { DonationsModal } from '../../donations-modal';
import { IconContainer } from '../styled';

import { Total, TotalsRow, totalSectionStyles } from './styled';
import { IDonationsRowProps } from './types';

const DonationsRow: React.FC<React.PropsWithChildren<IDonationsRowProps>> = ({
  isLoading,
  iconAriaLabel,
  totalCents,
  message,
  modalContent,
}) => {
  const [showDonationsInfoModal, setShowDonationsInfoModal] = useState(false);

  const onOpenCallback = useCallback(() => {
    if (!modalContent) {
      return;
    }
    setShowDonationsInfoModal(true);
  }, [setShowDonationsInfoModal, modalContent]);

  const onCloseCallback = useCallback(() => {
    setShowDonationsInfoModal(false);
  }, [setShowDonationsInfoModal]);

  return (
    <>
      <TotalsRow>
        {isLoading ? (
          <CurrencyLineSkeleton />
        ) : (
          <Box
            flexDirection="row"
            width="full"
            justifyContent="space-between"
            alignItems="center"
            maxHeight="$5"
          >
            <Box flexDirection="row" alignItems="center" maxHeight="$5">
              <Text style={totalSectionStyles.feeLabel}>{message}</Text>
              {iconAriaLabel && (
                <IconContainer
                  maxWidth={'$5'}
                  accessibilityLabel={iconAriaLabel}
                  onPress={onOpenCallback}
                >
                  <Icon
                    alignSelf={'center'}
                    variant="infoFill"
                    width="$3.5"
                    size={'xs'}
                    {...hiddenAccessibilityPlatformProps}
                  />
                </IconContainer>
              )}
            </Box>
            <Total testID="total-donation">
              <Currency amount={totalCents} textProps={totalSectionStyles.feeLabel} />
            </Total>
          </Box>
        )}
      </TotalsRow>
      {modalContent && (
        <DonationsModal
          isOpen={showDonationsInfoModal}
          onCloseCallback={onCloseCallback}
          title={modalContent.title}
          modalAppearanceEventMessage={modalContent.modalAppearanceEventMessage}
          testID="tims-camps-donation-modal"
        >
          {modalContent.body}
        </DonationsModal>
      )}
    </>
  );
};

export default DonationsRow;
