import { Box, Header, Text } from '@fhs-legacy/universal-components';

import theme from './theme';

export const Container = Box.withConfig({
  width: 'full',
  alignItems: 'center',
});

export const LoadingTitle = Header.withConfig({
  variant: 'headerTwo',
  textAlign: 'center',
  color: theme.titleColor,
  paddingX: '0',
  paddingTop: '10',
});

export const LoadingText = Text.withConfig({
  variant: 'copyOne',
  textAlign: 'center',
  color: theme.titleColor,
});
