import { useField } from 'formik';
import { Platform } from 'react-native';

import { addWithConfig } from '../../utils';
import { usePropsWithFormikFormControlName } from '../formik-form-control';
import { Select } from '../select';

import type { FormikSelectProps } from './types';

export const FormikSelect = (props: FormikSelectProps) => {
  const { name, selectProps, children, ...rest } = usePropsWithFormikFormControlName(props);
  const [field, meta, helpers] = useField(name);

  const isInvalid = Boolean(meta.error && meta.touched);
  return (
    <Select
      isInvalid={isInvalid}
      selectedValue={field.value}
      onValueChange={(nextValue) => {
        helpers.setValue(nextValue);
        if (Platform.OS !== 'web') {
          helpers.setTouched(true);
        }
      }}
      // @ts-ignore - web only
      onBlur={() => {
        if (Platform.OS === 'web') {
          helpers.setTouched(true);
        }
      }}
      {...selectProps}
      {...rest}
    >
      {children}
    </Select>
  );
};

FormikSelect.Item = Select.Item;

const FormikSelectWithConfig = addWithConfig(FormikSelect);
FormikSelectWithConfig.Item = addWithConfig(FormikSelect.Item);

export default FormikSelectWithConfig;
