import { IFeatureAccountQuery, useFeatureAccountQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

interface IFeatureAccountValue {
  featureAccountLoading: boolean;
  featureAccountData: IFeatureAccountQuery['FeatureAccount'];
}

export const useFeatureAccount = (): IFeatureAccountValue => {
  const { featureAccountId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useFeatureAccountQuery({
    variables: { featureAccountId },
    skip: !featureAccountId,
  });

  const featureAccountLoading = featureIdsLoading || loading;
  const featureAccountData = data?.FeatureAccount ?? null;

  return {
    featureAccountLoading,
    featureAccountData,
  };
};
