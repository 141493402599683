import { addWithConfig } from '../../utils';
import { Box } from '../box';
import { Header } from '../header';
import { Text } from '../text';

import type { InputLabelProps } from './types';

export const InputLabel = ({ header, subHeader, ...boxProps }: InputLabelProps) => (
  <Box ml={2} {...boxProps}>
    <Header variant="headerThree" mb={1}>
      {header}
    </Header>
    <Text variant="copyOne">{subHeader}</Text>
  </Box>
);

export default addWithConfig(InputLabel);
