import React, { memo, forwardRef } from 'react';
import { Animated } from 'react-native';
import { usePropsResolution } from '../../../hooks';
import { canUseDom } from '../../../utils';
import Box from '../../primitives/Box';
import type { ISkeletonProps } from './types';
import { useHasResponsiveProps } from '../../../hooks/useHasResponsiveProps';
import { useToken } from '../../../hooks/useToken';

const Skeleton = (props: ISkeletonProps, ref: any) => {
  const isDomUsable = canUseDom();
  const {
    children,
    startColor,
    endColor,
    ...resolvedProps
  } = usePropsResolution('Skeleton', props);
  // Setting blink Animation
  const blinkAnimRef = React.useRef(new Animated.Value(0));
  const tokenisedStartColor = useToken('colors', startColor as any);

  // Generating blink animation in a sequence
  const animationSpeed = resolvedProps.speed ?? 1;
  const animationFadeDuration = resolvedProps.fadeDuration ?? 0;
  React.useEffect(() => {
    //Check if window is loaded
    if (isDomUsable) {
      const blink = Animated.sequence([
        Animated.timing(blinkAnimRef.current, {
          toValue: 1,
          duration:
            animationFadeDuration * 10000 * (1 / (animationSpeed)),
          useNativeDriver: true,
          isInteraction: false,
        }),
        Animated.timing(blinkAnimRef.current, {
          toValue: 0,
          duration:
            animationFadeDuration * 10000 * (1 / (animationSpeed)),
          useNativeDriver: true,
          isInteraction: false,
        }),
      ]);
      Animated.loop(blink).start();
    }
  }, [blinkAnimRef, isDomUsable, animationSpeed]);

  const skeletonStyle: any = {
    skeleton: {
      height: '100%',
      width: '100%',
      backgroundColor: tokenisedStartColor,
      opacity: blinkAnimRef.current, // Bind opacity to animated value
    },
  };

  //TODO: refactor for responsive prop
  if (useHasResponsiveProps(props)) {
    return null;
  }

  return resolvedProps.isLoaded ? (
    children
  ) : (
    <Box bg={endColor} {...resolvedProps} ref={ref} overflow="hidden">
      <Animated.View style={skeletonStyle.skeleton} />
    </Box>
  );
};

export default memo(forwardRef(Skeleton));
