import { useCallback, useMemo, useState } from 'react';

import { IOffer } from '@rbi-ctg/menu';
import { isOfferAvailableForDayParts } from 'components/offers/utils';
import { useDayPartContext } from 'state/day-part';
import logger from 'utils/logger';

export interface IAttemptOfferRedemptionArgs {
  bypassCheckForAnotherOffer?: boolean;
  offer: IOffer;
}
interface IUseCartPromoCodeOfferRedemptionArgs {
  selectedOffer: IOffer | null;
  selectOfferById: (offerId: string) => void;
  clearSelectedOffer: () => void;
  onSelectMobileRedemption: () => void;
}

type IsDialogOpen = boolean;

export const useCartPromoCodeOfferRedemption = ({
  selectedOffer,
  selectOfferById,
  clearSelectedOffer,
  onSelectMobileRedemption,
}: IUseCartPromoCodeOfferRedemptionArgs) => {
  const { activeDayParts } = useDayPartContext();

  const [showConfirmSwitchOffersDialog, setShowConfirmSwitchOffersDialog] = useState(false);
  const [tentativeOfferToRedeem, setTentativeOfferToRedeem] = useState<IOffer | null>(null);

  const checkForAnotherSelectedOffer = useCallback(
    (offer: IOffer): IsDialogOpen => {
      // If an existing offer has already been selected, warn user
      if (selectedOffer && selectedOffer._id !== offer._id) {
        setShowConfirmSwitchOffersDialog(true);
        return true;
      }

      return false;
    },
    [selectedOffer]
  );

  const redeemCartPromoCodeOffer = useCallback(
    (offer: IOffer, bypassCheckForAnotherOffer: boolean) => {
      if (!bypassCheckForAnotherOffer) {
        const isDialogOpen = checkForAnotherSelectedOffer(offer);
        if (isDialogOpen) {
          return;
        }
      }
      selectOfferById(offer._id);
      onSelectMobileRedemption();
      setTentativeOfferToRedeem(null);
    },
    [checkForAnotherSelectedOffer, onSelectMobileRedemption, selectOfferById]
  );

  const attemptOfferRedemption = useCallback(
    ({ offer, bypassCheckForAnotherOffer = false }: IAttemptOfferRedemptionArgs) => {
      setTentativeOfferToRedeem(offer);
      if (!isOfferAvailableForDayParts({ offer, activeDayParts })) {
        // TODO: handle unavailable for dayparts
        logger.warn(`Attempting to redeem offer that is not available: offer ${offer._id}`);
        setTentativeOfferToRedeem(null);
        return;
      }
      redeemCartPromoCodeOffer(offer, bypassCheckForAnotherOffer);
    },
    [activeDayParts, redeemCartPromoCodeOffer]
  );

  const onConfirmSwitchOffers = useCallback(() => {
    setShowConfirmSwitchOffersDialog(false);
    if (tentativeOfferToRedeem) {
      clearSelectedOffer();
      attemptOfferRedemption({
        offer: tentativeOfferToRedeem,
        bypassCheckForAnotherOffer: true,
      });
    }
  }, [attemptOfferRedemption, clearSelectedOffer, tentativeOfferToRedeem]);

  const onCancelSwitchOffers = useCallback(() => {
    setShowConfirmSwitchOffersDialog(false);
    setTentativeOfferToRedeem(null);
  }, []);

  return useMemo(
    () => ({
      attemptOfferRedemption,
      onConfirmSwitchOffers,
      onCancelSwitchOffers,
      showConfirmSwitchOffersDialog,
    }),
    [
      attemptOfferRedemption,
      onCancelSwitchOffers,
      onConfirmSwitchOffers,
      showConfirmSwitchOffersDialog,
    ]
  );
};
