import * as React from 'react';
import { AppState } from 'react-native';
import type { AppStateStatus } from 'react-native';

export function useAppStateStatus(): AppStateStatus {
  const [appStateStatus, setAppStateStatus] = React.useState(AppState.currentState);

  React.useEffect(() => {
    const subscription = AppState.addEventListener('change', setAppStateStatus);

    return () => subscription.remove();
  }, []);

  return appStateStatus;
}
