import { useFocusEffect } from 'expo-router';
import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { IOffer } from '@rbi-ctg/menu';
import { PromotionType } from 'generated/graphql-gateway';
import { useLoyaltyIncentivesAvailability } from 'pages/loyalty/loyalty-incentives-components/use-loyalty-incentives-availability';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { getEvaluationResultMessage } from 'state/loyalty/hooks/utils/helpers';
import { useLoyaltyContext } from 'state/loyalty/loyalty-context';
import { LoyaltyOffer } from 'state/loyalty/types';
import { useOrderContext } from 'state/order';
import { blockContentToPlainText } from 'utils/sanity';

import Offer from './offer';

interface ICartOfferProps {
  selectedOffer: IOffer | LoyaltyOffer;
  onRemove: () => void;
  textOfferRequirementsNotMet: React.ReactNode;
  isValid: boolean;
}

export const CartOffer: FC<React.PropsWithChildren<ICartOfferProps>> = ({
  selectedOffer,
  onRemove,
  textOfferRequirementsNotMet,
  isValid,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Offer
      onRemove={onRemove}
      textRemove={formatMessage({ id: 'removeOffer' })}
      textOfferRequirementsNotMet={textOfferRequirementsNotMet}
      bgColor="#eae7e5"
      fill={Styles.color.grey.five}
      key={selectedOffer?._id}
      borderColor="#b6b6b6"
      selectedOffer={selectedOffer}
      isValid={isValid}
      hideTicketCircles={true}
    />
  );
};

export const LoyaltyDiscountCartOffer: FC<
  React.PropsWithChildren<{
    selectedLoyaltyOffer: LoyaltyOffer;
  }>
> = ({ selectedLoyaltyOffer }) => {
  const { logRBIEvent } = useCRMEventsContext();
  const dispatch = useAppDispatch();
  const offersFeedbackMap = useAppSelector(selectors.loyalty.selectOfferFeedbackMap);
  const isDiscountNotApplied = useAppSelector(selectors.loyalty.selectIsDiscountNotApplied);

  useFocusEffect(
    useCallback(() => {
      if (isDiscountNotApplied) {
        logRBIEvent({
          name: CustomEventNames.DISCOUNT_NOT_APPLIED,
          type: EventTypes.Other,
          attributes: {
            offer: {
              id: selectedLoyaltyOffer._id,
              loyaltyEngineId: selectedLoyaltyOffer.loyaltyEngineId ?? '',
              name: blockContentToPlainText(selectedLoyaltyOffer?.name?.localeRaw),
            },
          },
        });
      }
    }, [isDiscountNotApplied, logRBIEvent, selectedLoyaltyOffer])
  );

  const { checkLoyaltyOfferAvailability } = useLoyaltyIncentivesAvailability();
  const orderCtx = useOrderContext();
  const { setOfferValidationError } = useLoyaltyContext();
  const formatters = useIntl();

  const onRemove = () => {
    // TODO: fix this when we have multiple applied offers
    dispatch(actions.loyalty.setSelectedOffer(null));
    dispatch(actions.loyalty.resetAppliedOffers());
    // forcing offer re-fetch to have the most updated list of them on the loyalty offers page since the validation overwrites the user offers
    dispatch(actions.loyalty.setShouldRefetchOffers(true));
    setOfferValidationError(false);
  };

  const { isValid = true, textOfferRequirementsNotMet = '' } = useMemo(() => {
    const { formatMessage } = formatters;
    const available = checkLoyaltyOfferAvailability(selectedLoyaltyOffer);
    const ruleErrors = offersFeedbackMap[selectedLoyaltyOffer.loyaltyEngineId || ''];
    const hasRuleErrors = Boolean(ruleErrors?.length);
    const { isCartTotalNegative = false } = orderCtx?.calculateCartTotalWithDiscount?.() || {};

    let errorMessage: React.ReactNode | undefined;
    if (!available) {
      errorMessage = formatMessage({ id: 'offerNotAvailableInRestaurantPos' });
    } else if (isCartTotalNegative) {
      errorMessage = formatMessage({ id: 'cartOfferRequirementsNotMetError' });
    } else if (hasRuleErrors) {
      // Show first error only
      errorMessage = getEvaluationResultMessage({
        evaluationResult: ruleErrors[0],
        formatters,
        incentiveType: PromotionType.OFFER,
      });
    } else if (isDiscountNotApplied) {
      errorMessage = formatMessage({ id: 'incentiveFeedbackCartRequirement' });
    }

    return {
      isValid: available && !hasRuleErrors && !isCartTotalNegative && !isDiscountNotApplied,
      textOfferRequirementsNotMet: errorMessage,
    };
  }, [
    checkLoyaltyOfferAvailability,
    formatters,
    offersFeedbackMap,
    orderCtx,
    selectedLoyaltyOffer,
    isDiscountNotApplied,
  ]);

  return (
    <CartOffer
      selectedOffer={selectedLoyaltyOffer}
      onRemove={onRemove}
      textOfferRequirementsNotMet={textOfferRequirementsNotMet}
      isValid={isValid}
    />
  );
};
