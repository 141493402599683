import { ActionButtonVariants } from 'components/action-button';

export default {
  itemNameFontWeight: undefined,
  itemImageBackgroundColor: '#d8d8d8',
  itemBorderTopWidth: '1px',
  itemBorderTopColor: '#d8d8d8',
  itemPaddingY: '$2.5',
  itemPaddingX: '$0',
  emptyCartBtnVariant: ActionButtonVariants.PRIMARY,
  buttonContainerMarginY: undefined,
  buttonContainerMarginTop: '$16',
  buttonContainerMarginBottom: '$8',
  buttonContainerMarginX: '$0',
  headerFontSize: 'md',
  headerColor: '__legacyToken.text-default',
  headerFontWeight: 'bold',
  headerBorderBottomWidth: 1,
};
