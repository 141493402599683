import { useMemo } from 'react';

import { useField, useFormikContext } from 'formik';

import { addWithConfig } from '../../utils';
import { FormControl } from '../form-control';

import {
  FormikFormControlNameContextProvider,
  FormikFormControlStateContextProvider,
} from './context';
import type { FormikFormControlProps } from './types';

export const FormikFormControl = (props: FormikFormControlProps) => {
  const { children, name, label, helper, ...rest } = props;
  const [, meta] = useField(name);
  const { isSubmitting } = useFormikContext();
  const hasError = Boolean(meta.error);
  const isTouched = Boolean(meta.touched);
  const isInvalid = isTouched && hasError;
  const isDisabled = isSubmitting || props.isDisabled;

  const formControlState = useMemo(
    () => ({
      isInvalid,
      isDisabled,
    }),
    [isDisabled, isInvalid],
  );

  return (
    <FormControl isInvalid={isInvalid} isDisabled={isDisabled} {...rest}>
      <FormikFormControlNameContextProvider value={name}>
        <FormikFormControlStateContextProvider value={formControlState}>
          {label && <FormControl.Label>{label}</FormControl.Label>}
          {children}
          {helper && <FormControl.HelperText>{helper}</FormControl.HelperText>}
          {isInvalid && <FormControl.ErrorMessage>{meta.error}</FormControl.ErrorMessage>}
        </FormikFormControlStateContextProvider>
      </FormikFormControlNameContextProvider>
    </FormControl>
  );
};

export default addWithConfig(FormikFormControl);
