import { Box, HStack, Header, Pressable, Text } from '@fhs-legacy/universal-components';

export const Container = Pressable.withConfig<{ isSingleCardUI?: boolean }>(p => ({
  flexDir: 'row',
  background: Styles.color.cardBackground,
  borderRadius: p.isSingleCardUI ? 12 : 0,
  shadow: p.isSingleCardUI ? 1 : 'none',
}));

export const StoreCardViewContainer = Box.withConfig({
  flexDir: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  minHeight: '$24',
  padding: '$4',
  flex: 1,
});

export const StoreCardActionsContainer = HStack.withConfig({
  zIndex: '1',
});

// TODO: RN - implement fadeIn animation
export const FadeIn = Box.withConfig({});

export const PrimaryText = Text.withConfig({
  variant: 'copyTwo',
  color: Styles.color.primary,
});

export const Wrapper = Box.withConfig({
  position: 'relative',
  zIndex: Styles.zIndex.below,
});

export const HalalContainer = Box.withConfig({});

export const Subtitle2 = Header.withConfig({
  variant: 'headerThree',
  mb: '$1',
});

export const Body2 = Text.withConfig({
  variant: 'copyThree',
});
