import React from 'react';
import { useIntl } from 'react-intl';
import { GestureResponderEvent } from 'react-native';

import { Box, Icon, Pressable } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

const Container = Pressable.withConfig({
  flexDirection: 'row',
  padding: 0,
  alignItems: 'center',

  _web: {
    cursor: 'pointer',
  },
});

const Message = Box.withConfig({
  _text: {
    fontSize: 'xs',
    color: Styles.color.red,
    underline: true,
    fontWeight: 'bold',
  },

  marginLeft: '$1',
});

interface IUnverifiedMethodProps {
  onPress?: (e: GestureResponderEvent) => void;
}

export const UnverifiedMethod: React.FC<React.PropsWithChildren<IUnverifiedMethodProps>> = ({
  onPress,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Container testID="unverified-method-badge" onPress={onPress}>
      <Icon
        variant="error"
        color={theme.token('text-error')}
        width="16px"
        {...hiddenAccessibilityPlatformProps}
      />
      <Message>{formatMessage({ id: 'unverified' })}</Message>
    </Container>
  );
};
