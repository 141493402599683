import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconList = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M2 12.767h2.222v-2.222H2v2.222Zm0 4.445h2.222v-2.223H2v2.223Zm0-8.89h2.222V6.102H2v2.222Zm4.444 4.445H22v-2.222H6.444v2.222Zm0 4.445H22v-2.223H6.444v2.223Zm0-11.111v2.222H22V6.1H6.444ZM2 12.767h2.222v-2.222H2v2.222Zm0 4.445h2.222v-2.223H2v2.223Zm0-8.89h2.222V6.102H2v2.222Zm4.444 4.445H22v-2.222H6.444v2.222Zm0 4.445H22v-2.223H6.444v2.223Zm0-11.111v2.222H22V6.1H6.444Z"
      />
    </Svg>
  );
};
