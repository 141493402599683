import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconReceiptLong = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 18 20"
      fill="none"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M3 19.5C2.30133 19.5 1.71 19.258 1.226 18.774C0.742 18.29 0.5 17.7002 0.5 17.0045V14.5H3.5V0.692261L4.88475 1.88476L6.2885 0.692261L7.69225 1.88476L9.09625 0.692261L10.5 1.88476L11.9038 0.692261L13.3077 1.88476L14.7115 0.692261L16.1152 1.88476L17.5 0.692261V17C17.5 17.6987 17.258 18.29 16.774 18.774C16.29 19.258 15.6987 19.5 15 19.5H3ZM15 18C15.2833 18 15.5208 17.9042 15.7125 17.7125C15.9042 17.5208 16 17.2833 16 17V3.00001H5V14.5H14V17C14 17.2833 14.0958 17.5208 14.2875 17.7125C14.4792 17.9042 14.7167 18 15 18ZM6.19225 6.75001V5.25001H11.8652V6.75001H6.19225ZM6.19225 9.75001V8.25001H11.8652V9.75001H6.19225ZM13.9423 6.88451C13.6974 6.88451 13.4888 6.79834 13.3163 6.62601C13.1439 6.45351 13.0577 6.24484 13.0577 6.00001C13.0577 5.75518 13.1439 5.54651 13.3163 5.37401C13.4888 5.20168 13.6974 5.11551 13.9423 5.11551C14.1871 5.11551 14.3958 5.20168 14.5682 5.37401C14.7407 5.54651 14.827 5.75518 14.827 6.00001C14.827 6.24484 14.7407 6.45351 14.5682 6.62601C14.3958 6.79834 14.1871 6.88451 13.9423 6.88451ZM13.9423 9.88451C13.6974 9.88451 13.4888 9.79834 13.3163 9.62601C13.1439 9.45351 13.0577 9.24484 13.0577 9.00001C13.0577 8.75518 13.1439 8.54651 13.3163 8.37401C13.4888 8.20168 13.6974 8.11551 13.9423 8.11551C14.1871 8.11551 14.3958 8.20168 14.5682 8.37401C14.7407 8.54651 14.827 8.75518 14.827 9.00001C14.827 9.24484 14.7407 9.45351 14.5682 9.62601C14.3958 9.79834 14.1871 9.88451 13.9423 9.88451Z"
      />
    </Svg>
  );
};
