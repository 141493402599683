import { Header, Text, View } from '@fhs-legacy/universal-components';
import Link from 'components/link';
import Picture from 'components/picture';
import { WEB_APP_CTA_MAX_WIDTH, WEB_APP_DESKTOP_LG_MAX_WIDTH } from 'utils/constants';

const MAP_HEIGHT = '140px';

export const ConfirmStoreSection = View.withConfig({
  alignSelf: 'center',
  width: { base: 'full', desktop: WEB_APP_DESKTOP_LG_MAX_WIDTH },
  marginTop: { desktop: '$10' },
  paddingX: { desktop: '$4' },
});

export const StyledLink = Link.withConfig({
  marginTop: '$7',
  marginBottom: '$12',
  _text: {
    variant: 'copyOne',
    fontSize: 'sm',
    lineHeight: 'sm',
    fontWeight: 'bold',
  },
});

export const Title = Header.withConfig({
  variant: 'headerThree',
  marginBottom: '$6',
});

export const Inner = View.withConfig({
  alignItems: 'center',
  width: 'full',
  paddingTop: '$6',
  paddingX: { base: '$4', desktop: '$0' },
});

export const LocationContainer = View.withConfig({
  borderRadius: 4,
  paddingY: '$4',
  paddingX: '$4',
  width: 'full',
  backgroundColor: 'token.background-pattern',
  marginBottom: '$7',
});

export const StreetAddress = Header.withConfig({
  variant: 'headerThree',
  marginBottom: '$1',
});

export const Address = Text.withConfig({
  variant: 'copyTwo',
  fontSize: 'xs',
  textTransform: 'capitalize',
});

export const MapContainer = View.withConfig({
  height: MAP_HEIGHT,
});

export const StyledPicture = Picture.withConfig({
  height: MAP_HEIGHT,
  width: 'full',
});

export const ButtonContainer = View.withConfig({
  width: { base: 'full', desktop: WEB_APP_CTA_MAX_WIDTH },
});
