import React, { useCallback, useEffect } from 'react';

import { loadScript } from 'components/payments/integrations/hooks/utils';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { useLocale } from 'state/intl';
import { brand, env } from 'utils/environment';

import { IEProtectEncryption, IEncryptionConfig } from './types';

declare global {
  interface Window {
    eProtect: any;
  }
}

const EProtectEncryption: React.FC<IEProtectEncryption> = ({
  cardNumber,
  cvv,
  id,
  onResult,
  onError,
}) => {
  const { region } = useLocale();
  const config: IEncryptionConfig = useConfigValue({
    key: 'worldpay',
    defaultValue: null,
    isRegionalized: true,
  });

  const encryptCard = useCallback(
    async (cardNumber: string, cvv: string) => {
      if (!config || !config.eProtectUrl) {
        throw new Error('eProtect config not available');
      }

      const { eProtectUrl, paypageId } = config;
      const scriptUrl = `${eProtectUrl}/eProtect/eProtect-api3.js`;

      const merchantOrderId = id?.slice(0, 20) || 'N/A'; // eProtect allows max of 20 characters
      const merchantReportGroup = `${env()}-${brand()}-${region}`.toUpperCase();

      // Load scripts
      await loadScript(scriptUrl);

      const preRequest = {
        paypageId,
        reportGroup: merchantReportGroup,
        orderId: merchantOrderId,
        id: merchantOrderId,
        url: eProtectUrl,
      };

      const request = {
        accountNum: {
          value: cardNumber,
        },
        cvv2: {
          value: cvv,
        },
      };

      const timeout = 5000;

      new window.eProtect().sendToEprotect(
        preRequest,
        request,
        (data: any) => {
          // Success
          onResult({
            lowValueToken: data.paypageRegistrationId,
            type: data.type,
            bin: data.bin,
            last4: data.lastFour,
          });
        },
        (error: any) => {
          // Error
          onError({
            message: error.message,
          });
        },
        () => {
          // Timeout
          onError({
            message: 'eProtect Request Timeout',
          });
        },
        timeout
      );
    },
    [config, id, onError, onResult, region]
  );

  useEffect(() => {
    encryptCard(cardNumber, cvv);
  }, [cardNumber, cvv, encryptCard]);

  return null;
};

export default EProtectEncryption;
