import React from 'react';

import { ISanityBlockContent, ISanityTypographyBlock } from '@rbi-ctg/menu';

import { DescriptionBlock, DescriptionText } from './loyalty-basic-card-widget.styled';

type LoyaltyBasicCardDescrtiptionProps = {
  descriptionText?: string;
  descriptionBlockContent?: ISanityBlockContent[] | ISanityTypographyBlock[] | null;
};

const LoyaltyBasicCardDescrtiption = ({
  descriptionText,
  descriptionBlockContent,
}: LoyaltyBasicCardDescrtiptionProps) => (
  <>
    {descriptionText ? (
      <DescriptionText>{descriptionText}</DescriptionText>
    ) : (
      <DescriptionBlock content={descriptionBlockContent} />
    )}
  </>
);

export default LoyaltyBasicCardDescrtiption;
