import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';

export default {
  addItemsVariant: ActionButtonVariants.OUTLINE,
  addItemsTransparentBackground: true,
  singleBtnSize: ActionButtonSizes.LARGE,
  doubleBtnSize: ActionButtonSizes.SMALL,
  tipSelectorLabelColor: Styles.color.black,
  totalsPaddingX: '$4',
  tipSelectionBackground: Styles.color.black,
};
