import { isValidElement } from 'react';

function isObjectAndNotDateRegEx(val: unknown): any {
    return typeof val === 'object' && val && !(val instanceof RegExp) && !(val instanceof Date) && !isValidElement(val);
}

function mergeObject(target: any, obj: any, cycleMap: any, isMergeable: any) {
    if (!isMergeable(target)) {
        target = Array.isArray(obj) ? [] : {};
    }
    cycleMap.set(obj, target);
    for (var x in obj) {
        var val = obj[x];
        if(val === undefined) {
            continue;
        }
        if(isMergeable(val)) {
            val = cycleMap.get(val) ?? mergeObject(target[x], val, cycleMap, isMergeable);
        }
        target[x] = val;
    }
    cycleMap.delete(obj);
    return target;
}

export function deepMergeObjectsDeepMergeArrays(target:any, ...objs: any[]) {
    var cycleMap = new Map();
    for (var obj of objs) {
        target = mergeObject(target, obj, cycleMap, isObjectAndNotDateRegEx);
    }
    return target;
}
