import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useFormatCalories } from 'hooks/use-format-calories';
import { useFormatJoules } from 'hooks/use-format-joules';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { formatCaloriesToJoulesFromString } from 'utils/menu/calories';

export type FormatCaloriesFn = (calories: number | string | null | undefined) => string;

export const useMenuCalories = () => {
  const { formatMessage } = useIntl();
  const formatCaloriesHook = useFormatCalories();
  const formatJoules = useFormatJoules();

  const enableEUNutritionDisplay = useFlag(LaunchDarklyFlag.ENABLE_EU_NUTRITION_DISPLAY);

  const formatCalories = useCallback<FormatCaloriesFn>(
    (calories: number | string | null | undefined) => {
      if (calories === undefined || calories === null) {
        return '';
      }

      const formattedCalories = formatCaloriesHook(Number(calories));
      const formattedJoules = formatJoules(Number(calories));

      if (!enableEUNutritionDisplay) {
        return formattedCalories;
      }

      return formatMessage(
        { id: 'euNutritionValues' },
        { calories: formattedCalories, joules: formattedJoules }
      );
    },
    [enableEUNutritionDisplay, formatCaloriesHook, formatMessage, formatJoules]
  );

  const formatCalorieRange = useCallback(
    (calorieRange?: string) => {
      if (!calorieRange) {
        return '';
      }

      if (!enableEUNutritionDisplay) {
        return calorieRange;
      }

      const joulesRange = formatCaloriesToJoulesFromString(calorieRange, formatJoules);
      return formatMessage(
        { id: 'euNutritionValues' },
        { calories: calorieRange, joules: joulesRange }
      );
    },
    [enableEUNutritionDisplay, formatJoules, formatMessage]
  );
  return {
    formatCalories,
    formatCalorieRange,
  };
};
