import { OrderLocatorTab } from 'state/launchdarkly/variations';

import * as emptyStateIcons from './icons/icons';
import {
  EmptyStateActions,
  EmptyStateCategories,
  IEmptyStateContent,
  IEmptyStateTabOptions,
} from './types';

export const DEFAULT_CONTENT: IEmptyStateContent = {
  icon: emptyStateIcons.DEFAULT_ICON,
  titleId: 'storeLocatorEmptyStateDefaultTitle',
  bodyId: 'storeLocatorEmptyStateDefaultBody',
};

export const EMPTY_STATES_CONTENT: IEmptyStateTabOptions = {
  [OrderLocatorTab.NEARBY]: {
    [EmptyStateCategories.NO_STORES]: {
      icon: emptyStateIcons.NEARBY_NO_STORES_ICON,
      titleId: 'storeLocatorEmptyStateNearbyNoStoresTitle',
      bodyId: 'storeLocatorEmptyStateNearbyNoStoresBody',
    },
    [EmptyStateCategories.NO_LOCATION]: {
      icon: emptyStateIcons.NEARBY_NO_LOCATION_ICON,
      titleId: 'storeLocatorEmptyStateNearbyNoLocationTitle',
      bodyId: 'storeLocatorEmptyStateNearbyNoLocationBody',
      action: EmptyStateActions.SHARE_LOCATION,
    },
    [EmptyStateCategories.ERROR]: {
      icon: emptyStateIcons.NEARBY_ERROR_ICON,
      titleId: 'storeLocatorEmptyStateNearbyErrorTitle',
      bodyId: 'storeLocatorEmptyStateNearbyErrorBody',
    },
  },
  [OrderLocatorTab.FAVORITE]: {
    [EmptyStateCategories.NO_AUTH]: {
      icon: emptyStateIcons.FAVORITES_NO_AUTH_ICON,
      titleId: 'storeLocatorEmptyStateFavoritesNoAuthTitle',
      bodyId: 'storeLocatorEmptyStateFavoritesNoAuthBody',
      action: EmptyStateActions.LOGIN,
    },
    [EmptyStateCategories.NO_STORES]: {
      icon: emptyStateIcons.FAVORITES_NO_STORES_ICON,
      titleId: 'storeLocatorEmptyStateFavoritesNoStoresTitle',
      bodyId: 'storeLocatorEmptyStateFavoritesNoStoresBody',
    },
    [EmptyStateCategories.ERROR]: {
      icon: emptyStateIcons.FAVORITES_ERROR_ICON,
      titleId: 'storeLocatorEmptyStateFavoritesErrorTitle',
      bodyId: 'storeLocatorEmptyStateFavoritesErrorBody',
    },
  },
  [OrderLocatorTab.RECENT]: {
    [EmptyStateCategories.NO_AUTH]: {
      icon: emptyStateIcons.RECENT_NO_AUTH_ICON,
      titleId: 'storeLocatorEmptyStateRecentNoAuthTitle',
      bodyId: 'storeLocatorEmptyStateRecentNoAuthBody',
      action: EmptyStateActions.LOGIN,
    },
    [EmptyStateCategories.NO_STORES]: {
      icon: emptyStateIcons.RECENT_NO_STORES_ICON,
      titleId: 'storeLocatorEmptyStateRecentNoStoresTitle',
      bodyId: 'storeLocatorEmptyStateRecentNoStoresBody',
    },
    [EmptyStateCategories.ERROR]: {
      icon: emptyStateIcons.RECENT_ERROR_ICON,
      titleId: 'storeLocatorEmptyStateRecentErrorTitle',
      bodyId: 'storeLocatorEmptyStateRecentErrorBody',
    },
  },
};
