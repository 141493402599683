import React from 'react';
import { useIntl } from 'react-intl';

import { OptInLabel, TermsLink } from '../styled';

import {
  ACCEPT_EMAILS_AND_SPECIAL_OFFERS_MESSAGE_ID,
  ACCEPT_TERMS_AND_PRIVACY_INTRO_MESSAGE_ID,
} from './constants';
import { RewardsPolicyTermsLink } from './rewards-policy-terms-link';

export const PromotionalEmailsLabel = () => {
  const { formatMessage } = useIntl();

  return (
    <OptInLabel testID={`opt-in-label-${ACCEPT_EMAILS_AND_SPECIAL_OFFERS_MESSAGE_ID}`}>
      {formatMessage({ id: ACCEPT_EMAILS_AND_SPECIAL_OFFERS_MESSAGE_ID })}
    </OptInLabel>
  );
};

export const TermsOfServiceLabel = () => {
  const { formatMessage } = useIntl();

  const privacyPolicyLink = (
    <TermsLink
      to={{
        pathname: formatMessage({ id: 'routes.privacyPolicy' }),
        params: { title: formatMessage({ id: 'privacyPolicy' }) },
      }}
    >
      {formatMessage({ id: 'privacyPolicy' })}
    </TermsLink>
  );

  const rewardsPolicyLink = <RewardsPolicyTermsLink />;

  const termsOfServiceLink = (
    <TermsLink
      to={{
        pathname: formatMessage({ id: 'routes.termsOfService' }),
        params: { title: formatMessage({ id: 'termsOfService' }) },
      }}
    >
      {formatMessage({ id: 'termsOfService' })}
    </TermsLink>
  );

  const acceptTermsMessage = formatMessage(
    { id: ACCEPT_TERMS_AND_PRIVACY_INTRO_MESSAGE_ID },
    { termsOfServiceLink, privacyPolicyLink, rewardsPolicyLink }
  );

  return <OptInLabel>{acceptTermsMessage}</OptInLabel>;
};
