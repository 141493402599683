import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import { ResponsiveCarousel } from 'components/responsive-carousel';

import {
  UPSIZE_ITEMS_LIMIT_BEFORE_LOOP,
  UPSIZE_ITEM_SLIDER_WIDTH,
  UPSIZE_ITEM_WIDTH,
} from './constants';
import {
  Container,
  MainTitle,
  NoThanks,
  SVGHeader,
  SubTitle,
  UpsizeButton,
  UpsizeHeader,
  UpsizeItems,
} from './modal-upsize.styled';
import { UpsizeContentProps } from './types';

export const UpsizeContent = ({
  availableItems,
  swapTo,
  renderUpsizeItem,
  onUpsizePressed,
  onNoThanksPressed,
  isDisabledUpsizeButton,
}: UpsizeContentProps) => {
  const { formatMessage } = useIntl();
  const carouselShouldLoop = () =>
    availableItems.length > UPSIZE_ITEMS_LIMIT_BEFORE_LOOP ||
    availableItems.length * UPSIZE_ITEM_WIDTH > UPSIZE_ITEM_SLIDER_WIDTH;
  const selectedSwapIndex = useMemo(
    () => availableItems?.findIndex(item => item._id === swapTo?._id),
    [availableItems, swapTo?._id]
  );
  return (
    <Container>
      <UpsizeHeader>
        <SVGHeader>
          <LoyaltyPointsIcon height={50} width={50} />
        </SVGHeader>
      </UpsizeHeader>
      <MainTitle>{formatMessage({ id: 'upsizeForFree' })}</MainTitle>
      <SubTitle>{formatMessage({ id: 'upsizeEligible' })}</SubTitle>
      <UpsizeItems maxW={UPSIZE_ITEM_SLIDER_WIDTH}>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ bgColor: string; alignWithParent: true; da... Remove this comment to see the full error message */}
        <ResponsiveCarousel
          bgColor="transparent"
          alignWithParent
          data={availableItems || []}
          renderItem={renderUpsizeItem}
          itemWidth={UPSIZE_ITEM_WIDTH}
          centerCards={availableItems.length === 1 || !carouselShouldLoop()}
          loop={carouselShouldLoop()}
          inactiveSlideOpacity={1}
          inactiveSlideScale={1}
          activeItemIndex={selectedSwapIndex}
        />
      </UpsizeItems>
      <UpsizeButton
        disabled={isDisabledUpsizeButton}
        onPress={onUpsizePressed}
        aria-label="upsize-btn"
        testID="upsize-modal-accept-button"
      >
        {formatMessage({ id: 'freeUpsize' })}
      </UpsizeButton>
      <NoThanks onPress={onNoThanksPressed} aria-label={formatMessage({ id: 'noThanks' })}>
        {formatMessage({ id: 'noThanks' })}
      </NoThanks>
    </Container>
  );
};
