import { Stack, router, useRootNavigationState } from 'expo-router';
import { useEffect } from 'react';

import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';

export default function OffersLayout() {
  const rootNavigationState = useRootNavigationState();
  const enableSimplyOfferUi = useFlag(LaunchDarklyFlag.ENABLE_SIMPLY_OFFER_UI);
  useEffect(() => {
    const navigatorReady = rootNavigationState?.key != null;
    if (navigatorReady && enableSimplyOfferUi) {
      router.replace(routes.offersV2);
    }
  }, [enableSimplyOfferUi, rootNavigationState]);
  return (
    <Stack
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="index" />
      <Stack.Screen name="[incentiveId]" />
    </Stack>
  );
}
