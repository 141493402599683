import * as React from 'react';
import { useIntl } from 'react-intl';

import { Box, Text, VStack } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { LoyaltyPoints } from 'components/loyalty-points';
import Table from 'components/table';
import { selectors, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { routes } from 'utils/routing';

import { Container, Content, TableWrap } from './loyalty-history-widget.styled';

const LoyaltyHistoryWidget: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const hidePointsExpirationEnabled = useFlag(LaunchDarklyFlag.ENABLE_HIDE_POINTS_EXPIRATION);
  const loyaltyUserExpireDateFormattedText = useAppSelector(
    selectors.loyalty.selectLoyaltyUserExpireDateFormattedText
  );

  const table = React.useMemo(
    () => ({
      head: [
        {
          points: (
            <VStack alignItems="center" justifyContent="center" space={3}>
              <Box display="flex" flexDirection="row">
                {formatMessage({ id: 'rewardsPointsAvailable' }, { points: <LoyaltyPoints /> })}
              </Box>
              {!hidePointsExpirationEnabled && (
                <>
                  <Box marginBottom={3}>
                    <Text>
                      {formatMessage(
                        { id: 'rewardsPointsWillExpireDate' },
                        { date: <Text bold>{loyaltyUserExpireDateFormattedText}</Text> }
                      )}
                    </Text>
                  </Box>
                  <ActionButton to={routes.menu} fullWidth>
                    Order Now
                  </ActionButton>
                </>
              )}
            </VStack>
          ),
        },
      ],
      body: [
        {
          points: (
            <ActionButton variant={ActionButtonVariants.OUTLINE} to={routes.rewardsHistory}>
              View history
            </ActionButton>
          ),
        },
      ],
    }),
    [formatMessage, hidePointsExpirationEnabled, loyaltyUserExpireDateFormattedText]
  );

  return (
    <Container testID="history-widget">
      <Content>
        <TableWrap>
          <Table table={table} />
        </TableWrap>
      </Content>
    </Container>
  );
};

export default LoyaltyHistoryWidget;
