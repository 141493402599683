import * as React from 'react';
import { FC } from 'react';

import { IconType } from 'components/accordion/types';

import {
  InnerAccordionItem,
  StyledAccordion,
  StyledAccordionItem,
  Wrapper,
} from './expandable-content.styled';
import { IExpandableContentProps } from './types';

export const ExpandableContent: FC<React.PropsWithChildren<IExpandableContentProps>> = ({
  title,
  content,
}) => (
  <Wrapper>
    <StyledAccordion>
      <StyledAccordionItem title={title} iconType={IconType.ARROW}>
        <InnerAccordionItem>{content}</InnerAccordionItem>
      </StyledAccordionItem>
    </StyledAccordion>
  </Wrapper>
);
