import { Box, Button, HStack, Text, Divider as UCLDivider } from '@fhs-legacy/universal-components';

import theme from './theme';

export const ErrorMessage = Box.withConfig({
  _text: {
    color: Styles.color.error,
    fontSize: 'sm',
    lineHeight: '2xs',
  },

  marginBottom: '$4',
});

export const Wrapper = Box.withConfig({
  paddingTop: theme.wrapperPaddingTop,
  paddingBottom: theme.wrapperPaddingBottom,
  paddingX: theme.wrapperPaddingX,
  maxWidth: 500,
});

export const OptionsContainer = HStack;

export const PromoCTA = Button.withConfig({
  flexDirection: 'row',
  flexWrap: 'wrap',

  _text: {
    fontFamily: Styles.fontFamily.body,
    fontStyle: 'normal',
    fontWeight: Styles.fontWeight.heavy,
    fontSize: 'md',
    lineHeight: '2xs',
  },

  alignItems: 'center',
  backgroundColor: 'transparent',
  borderWidth: 0,
  borderColor: 'black',
  borderStyle: 'solid',
  padding: 0,
  margin: 0,
  overflow: 'hidden',
});

export const CallToActionHeader = Text.withConfig({
  color: Styles.color.black,
  paddingRight: '$2',
});

export const CTAButtonText = Text.withConfig({
  color: Styles.color.primary,
  underline: true,
});

export const PromoExpandToggleIcon = Box.withConfig({
  marginLeft: '$1',
  marginTop: '$1',
});

export const ExpandedContainer = Box.withConfig({
  margin: 0,
  padding: 0,
});

export const MessageContainer = Text.withConfig({
  fontFamily: Styles.fontFamily.body,
  fontWeight: Styles.fontWeight.normal,
  padding: 0,
  margin: 0,
});

export const SuccessCode = Text.withConfig({
  color: Styles.color.validated,
  fontSize: 'md',
  lineHeight: '2xs',
  fontWeight: Styles.fontWeight.normal,
  marginBottom: '5px',
});

export const SuccessMessage = Text.withConfig({
  fontFamily: Styles.fontFamily.body,
  fontWeight: Styles.fontWeight.normal,
  fontSize: 'sm',
});

export const Divider = UCLDivider.withConfig({
  marginTop: '$4',
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0,
  thickness: '1',
});
