import { Stack, router } from 'expo-router';
import * as React from 'react';
import { StyleSheet } from 'react-native';

import { Button, IconDelivery, IconLocationFill, IconStore } from '@fhs/ui';
import { Box } from '@fhs-legacy/universal-components';
import { useGetNearbyRestaurantsFromDelivery } from 'hooks/use-get-nearby-restaurants-from-delivery-context';
import { ServiceMode } from 'state/service-mode';
import { convertMilesToMeters } from 'utils/distance';

import { AddressBox } from './address-box';
import {
  AddressListTile,
  ButtonContainer,
  Container,
  HeaderTitle,
  LoadingText,
} from './delivery-unavailable.styled';
import { IDeliveryUnavailableProps } from './types';

const styles = StyleSheet.create({
  button: {
    width: '100%',
  },
});

const searchRadiusInMiles = 20;

/**
 *
 * DeliveryUnavailable informs the guest that the address they entered
 * is unable to receive delivery at this time
 *
 */

const DeliveryUnavailable: React.FC<React.PropsWithChildren<IDeliveryUnavailableProps>> = ({
  address,
  coordinates,
  onChooseNewAddress,
}) => {
  const queryCoordinates = {
    userLat: coordinates?.lat,
    userLng: coordinates?.lng,
    searchRadius: convertMilesToMeters(20),
  };
  const { restaurants, loading } = useGetNearbyRestaurantsFromDelivery(
    queryCoordinates,
    ServiceMode.DELIVERY,
    searchRadiusInMiles
  );
  const suggestions = restaurants ?? [];

  const HeaderContent = (
    <>
      <IconDelivery size={100} color={'lightgray'} />
      <HeaderTitle>Sorry, No Restaurants Nearby</HeaderTitle>
      <LoadingText fontSize="md">None found within 10 miles of your address.</LoadingText>
    </>
  );

  const YourAddress = (
    <Box width={'100%'} paddingX={6} paddingBottom={4} textAlign={'left'} flexDirection={'column'}>
      <AddressListTile>Your Address:</AddressListTile>
      <AddressBox
        icon={<IconLocationFill size={26} />}
        address={address}
        activeCoordinates={coordinates}
      />
    </Box>
  );

  const Suggestions = loading ? (
    <LoadingText>Looking for pick up options</LoadingText>
  ) : suggestions.length > 0 ? (
    <Box width={'100%'} paddingX={6} textAlign={'left'} flexDirection={'column'} gap={2}>
      <AddressListTile>Restaurants For Pick Up:</AddressListTile>
      {suggestions.slice(0, 2).map(suggestion => {
        return (
          <AddressBox
            icon={<IconStore size={26} />}
            restaurant={suggestion}
            activeCoordinates={coordinates}
          />
        );
      })}
    </Box>
  ) : null;

  return (
    <Container flex={1} testID="delivery-unavailable-for-address">
      <Stack.Screen options={{ title: 'Delivery Order' }} />
      {HeaderContent}
      {YourAddress}
      {Suggestions}
      <Box flex={1} />
      <ButtonContainer>
        <Button
          onPress={() => router.push('/store-locator/service-mode')}
          size="xl"
          style={styles.button}
        >
          <Button.Icon>
            <IconStore />
          </Button.Icon>
          <Button.Text>Pick Up Elsewhere</Button.Text>
        </Button>
        <Button
          type="outline"
          onPress={onChooseNewAddress}
          testID="use-different-address"
          size="xl"
          style={styles.button}
        >
          <Button.Text>Change Delivery Address</Button.Text>
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default DeliveryUnavailable;
