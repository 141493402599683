import * as React from 'react';

import { Svg, SvgG, SvgPath, SvgProps } from '@fhs-legacy/universal-components';

const StoreMarkerActive = (props: Omit<SvgProps, 'children'>) => (
  <Svg width={48} height={60} fill="none" viewBox="0 0 48 60" {...props}>
    <SvgG filter="url(#filter0_d_4_22760)" fillRule="evenodd" clipRule="evenodd">
      <SvgPath
        d="M37.033 50.953C46.856 47.947 54 38.808 54 28 54 14.745 43.255 4 30 4S6 14.745 6 28c0 10.347 6.548 19.165 15.726 22.536L29.5 64l7.533-13.047z"
        fill="#01000B"
      />
      <SvgPath
        d="M21.375 26.923a8.492 8.492 0 018.494-8.494c4.698 0 8.503 3.806 8.503 8.494s-3.814 8.494-8.503 8.494a8.492 8.492 0 01-8.494-8.494zm12.747-2.793c-.021-.206-.054-.335-.097-.41-.043-.076-.108-.108-.194-.108l-5.255.183v2.104l3.69-.399a.218.218 0 00.194-.097c.044-.065.076-.194.098-.4h.183v3.627h-.183c-.022-.249-.054-.4-.098-.475-.043-.076-.108-.108-.194-.108l-3.69.4v3.787c0 .097.021.183.075.248.054.065.173.108.378.14v.26l-3.335.841v-.442c.205-.033.324-.076.378-.14a.451.451 0 00.076-.249L26.126 21.8a.424.424 0 00-.065-.249c-.043-.064-.172-.118-.378-.14v-.259h8.159a.215.215 0 00.194-.119c.043-.075.075-.237.097-.485h.194v3.582h-.205z"
        fill="#fff"
      />
      <SvgPath
        d="M45 27c0-1.295-.259-3.076-1.64-5.061-1.37-1.986-1.144-4.036-1.144-4.036s0-.367-.313-.496c-.313-.12-.54.075-.54.075l-4.176 2.763c.572.636-.95-.96-.42-.443l2.751-4.165s.194-.227.075-.54c-.118-.313-.496-.313-.496-.313s-2.05.227-4.036-1.144C33.075 12.27 31.295 12 30 12s-3.076.259-5.061 1.64c-1.986 1.37-4.036 1.144-4.036 1.144s-.367 0-.496.313a.556.556 0 00.075.54l2.504 3.787c.615-.55-1.554 1.554-.583.55l-3.766-2.492s-.227-.194-.54-.075c-.313.118-.313.496-.313.496s.227 2.05-1.144 4.036C15.27 23.924 15 25.705 15 27s.259 3.076 1.64 5.061c1.37 1.986 1.144 4.036 1.144 4.036s0 .367.313.496c.313.13.54-.075.54-.075l3.766-2.493c-.907-.939 1.435 1.306.583.55l-2.504 3.788s-.194.227-.075.54c.118.313.496.313.496.313s2.05-.227 4.036 1.144C26.924 41.73 28.705 42 30 42s3.075-.259 5.061-1.64c1.986-1.37 4.036-1.144 4.036-1.144s.367 0 .496-.313c.13-.313-.075-.54-.075-.54l-2.752-4.165c-.798.788 1.22-1.328.421-.443l4.176 2.763s.227.194.54.075c.313-.118.313-.496.313-.496s-.227-2.05 1.144-4.036C44.73 30.076 45 28.295 45 27zm-15.13-9.321a9.242 9.242 0 00-9.245 9.244 9.242 9.242 0 009.244 9.244c5.102 0 9.253-4.14 9.253-9.244 0-5.103-4.141-9.244-9.253-9.244z"
        fill="#fff"
      />
    </SvgG>
  </Svg>
);

export const storeActiveMarkerSvg = `
<svg width="60" height="72" fill="none" viewBox="0 0 60 72" xmlns="http://www.w3.org/2000/svg">
  <g fill-rule="evenodd" clip-rule="evenodd">
    <path
      d="M37.033 50.953C46.856 47.947 54 38.808 54 28 54 14.745 43.255 4 30 4S6 14.745 6 28c0 10.347 6.548 19.165 15.726 22.536L29.5 64l7.533-13.047z"
      fill="#01000B"
    />
    <path
      d="M21.375 26.923a8.492 8.492 0 018.494-8.494c4.698 0 8.503 3.806 8.503 8.494s-3.814 8.494-8.503 8.494a8.492 8.492 0 01-8.494-8.494zm12.747-2.793c-.021-.206-.054-.335-.097-.41-.043-.076-.108-.108-.194-.108l-5.255.183v2.104l3.69-.399a.218.218 0 00.194-.097c.044-.065.076-.194.098-.4h.183v3.627h-.183c-.022-.249-.054-.4-.098-.475-.043-.076-.108-.108-.194-.108l-3.69.4v3.787c0 .097.021.183.075.248.054.065.173.108.378.14v.26l-3.335.841v-.442c.205-.033.324-.076.378-.14a.451.451 0 00.076-.249L26.126 21.8a.424.424 0 00-.065-.249c-.043-.064-.172-.118-.378-.14v-.259h8.159a.215.215 0 00.194-.119c.043-.075.075-.237.097-.485h.194v3.582h-.205z"
      fill="#FFFFFF"
    />
    <path
      d="M45 27c0-1.295-.259-3.076-1.64-5.061-1.37-1.986-1.144-4.036-1.144-4.036s0-.367-.313-.496c-.313-.12-.54.075-.54.075l-4.176 2.763c.572.636-.95-.96-.42-.443l2.751-4.165s.194-.227.075-.54c-.118-.313-.496-.313-.496-.313s-2.05.227-4.036-1.144C33.075 12.27 31.295 12 30 12s-3.076.259-5.061 1.64c-1.986 1.37-4.036 1.144-4.036 1.144s-.367 0-.496.313a.556.556 0 00.075.54l2.504 3.787c.615-.55-1.554 1.554-.583.55l-3.766-2.492s-.227-.194-.54-.075c-.313.118-.313.496-.313.496s.227 2.05-1.144 4.036C15.27 23.924 15 25.705 15 27s.259 3.076 1.64 5.061c1.37 1.986 1.144 4.036 1.144 4.036s0 .367.313.496c.313.13.54-.075.54-.075l3.766-2.493c-.907-.939 1.435 1.306.583.55l-2.504 3.788s-.194.227-.075.54c.118.313.496.313.496.313s2.05-.227 4.036 1.144C26.924 41.73 28.705 42 30 42s3.075-.259 5.061-1.64c1.986-1.37 4.036-1.144 4.036-1.144s.367 0 .496-.313c.13-.313-.075-.54-.075-.54l-2.752-4.165c-.798.788 1.22-1.328.421-.443l4.176 2.763s.227.194.54.075c.313-.118.313-.496.313-.496s-.227-2.05 1.144-4.036C44.73 30.076 45 28.295 45 27zm-15.13-9.321a9.242 9.242 0 00-9.245 9.244 9.242 9.242 0 009.244 9.244c5.102 0 9.253-4.14 9.253-9.244 0-5.103-4.141-9.244-9.253-9.244z"
      fill="#FFFFFF"
    />
  </g>
</svg>
`;

export default StoreMarkerActive;
