import { useCallback, useEffect, useMemo, useState } from 'react';
import { type LayoutChangeEvent } from 'react-native';

export type ScrollViewOverflowStatus = { width: boolean; height: boolean };
export type ScrollViewOverflowStatusChangeHandler = (status: ScrollViewOverflowStatus) => void;

/**
 * Track if a scroll view's content is overflowing.
 * @param handleScrollViewOverflowStatusChange Called when overflow status changes with new overflow status
 * @returns Handlers needed to be attached to the target scroll view the current overflow status of that scroll view
 *
 * @example
 * ```tsx
 * import { ScrollView, useScrollViewOverflowStatus } from '@fhs/ui';
 *
 * function Component() {
 *   const { handleLayout, handleContentSizeChange, overflowStatus } = useScrollViewOverflowStatus();
 *   return (
 *     <ScrollView
 *       onLayout={handleLayout}
 *       onContentSizeChange={handleContentSizeChange}
 *       contentContainerStyle={[
 *         styles.contentContainer,
 *         overflowStatus.height && styles.contentContainerOverflow
 *       ]}
 *     >
 *        { ... }
 *     </ScrollView>
 *   );
 * }
 * ```
 */
export const useScrollViewOverflowStatus = (
  handleScrollViewOverflowStatusChange?: ScrollViewOverflowStatusChangeHandler
) => {
  const [scrollViewSize, setScrollViewSize] = useState({ width: 0, height: 0 });
  const [scrollContentSize, setScrollContentSize] = useState({ width: 0, height: 0 });

  const handleLayout = useCallback((event: LayoutChangeEvent) => {
    setScrollViewSize({
      width: event.nativeEvent.layout.width,
      height: event.nativeEvent.layout.height,
    });
  }, []);

  const handleContentSizeChange = useCallback((width: number, height: number) => {
    setScrollContentSize({
      width,
      height,
    });
  }, []);

  const isHeightOverflow = scrollViewSize.height < scrollContentSize.height;
  const isWidthOverflow = scrollViewSize.width < scrollContentSize.width;

  const overflowStatus: ScrollViewOverflowStatus = useMemo(
    () => ({ height: isHeightOverflow, width: isWidthOverflow }),
    [isHeightOverflow, isWidthOverflow]
  );

  useEffect(() => {
    handleScrollViewOverflowStatusChange?.(overflowStatus);
  }, [handleScrollViewOverflowStatusChange, overflowStatus]);

  return useMemo(
    () => ({ handleLayout, handleContentSizeChange, overflowStatus }),
    [handleLayout, handleContentSizeChange, overflowStatus]
  );
};
