import type { ICmsOffer } from '../types';

import {
  validateIncentiveOfferBenefitField,
  validatePluAvailabilityForVendor,
  validateRequiredProperties,
} from './offer-validators';
import {
  IOfferValidationResult,
  IOfferValidatorContext,
  OfferValidationErrorsMap,
  OfferValidationRules,
  OfferValidatorErrorCode,
} from './types';

export const OFFER_VALIDATION_RESULT_MAP: OfferValidationErrorsMap = {
  requiredFields: {
    code: OfferValidatorErrorCode.REQUIRED_PROPERTIES_ERROR,
  },
  pluAvailability: {
    code: OfferValidatorErrorCode.PLU_AVAILABILITY_ERROR,
  },
  offerBenefitField: {
    code: OfferValidatorErrorCode.OFFER_BENEFIT_ERROR,
  },
};

/**
 * If the offer does not pass this validation it's consider invalid and the user should not be able to redeem it.
 *
 * @param {cmsOffer} CmsOffer validate a cms offer since the BE handles the engine offer validations.
 */
export const createOfferValidator =
  (ctx: IOfferValidatorContext) =>
  (cmsOffer: ICmsOffer): IOfferValidationResult => {
    let validationResult: IOfferValidationResult = {
      error: null,
      isValid: true,
    };

    // creating the object to validate the offer
    const rules: OfferValidationRules = {
      requiredFields: validateRequiredProperties,
      pluAvailability: validatePluAvailabilityForVendor,
      offerBenefitField: validateIncentiveOfferBenefitField,
    };

    for (const ruleKey in rules) {
      const validator = rules[ruleKey];

      if (validator && !validator(cmsOffer, ctx)) {
        validationResult = {
          error: OFFER_VALIDATION_RESULT_MAP[ruleKey],
          isValid: false,
        };
        break;
      }
    }

    return validationResult;
  };
