import React from 'react';
import { StyleSheet } from 'react-native';
import { View } from 'react-native';

import { IconStore, Skeleton, SkeletonTextBlock, tokens } from '@fhs/ui';

function RestaurantCardSkeleton() {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.iconCircle}>
          <IconStore size={24} />
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.titleContainer}>
            <SkeletonTextBlock lines={1} style={styles.titleContainer} />
          </View>
          <SkeletonTextBlock lines={1} style={styles.description} />
        </View>
        <Skeleton style={styles.button} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: tokens.colors.$blackOpacity10,
    width: '100%',
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  contentContainer: {
    flex: 1,
    alignItems: 'flex-start',
    margin: 0,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 200,
  },
  description: {
    marginTop: 4,
    marginRight: 8,
    width: 100,
  },
  button: {
    paddingLeft: 8,
    paddingVertical: 0,
    borderRadius: 800,
    width: 70,
    height: 31,
    marginLeft: 8,
  },
  buttonText: {
    fontSize: 10,
  },
  iconCircle: {
    height: 40,
    width: 40,
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: tokens.colors.$houseLight,
    marginRight: 12,
  },
});

export default RestaurantCardSkeleton;
