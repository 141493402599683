import React from 'react';
import { StyleSheet } from 'react-native';

import { PressableProps, RadioGroup, Text, tokens, useMqSelect, useRadioItem } from '@fhs/ui';

export type PickUpTipOptionProps = Omit<PressableProps, 'children'> & {
  text: string;
};

export function PickUpTipOption({ text, style, ...props }: PickUpTipOptionProps) {
  const { selected } = useRadioItem();

  // Only apply max width for Desktop
  const badgeMaxWidthStyle = useMqSelect({ $gteDesktopLg: { maxWidth: 65.2 } }, {});

  return (
    <RadioGroup.Pressable
      {...props}
      borderRadius={100}
      style={[
        styles.baseBadge,
        badgeMaxWidthStyle,
        selected ? styles.badgeSelected : styles.badgeUnselected,
        style,
      ]}
      accessibilityRole="button"
      accessibilityState={{ selected }}
    >
      <Text
        weight="semibold"
        style={[selected ? styles.badgeSelectedText : styles.badgeUnselectedText]}
      >
        {text}
      </Text>
    </RadioGroup.Pressable>
  );
}

const styles = StyleSheet.create({
  baseBadge: {
    alignItems: 'center',
    justifyContent: 'center',
    right: 2,
    flex: 1,
    height: 34,
  },
  badgeSelected: {
    backgroundColor: tokens.colors.$houseDark,
  },
  badgeUnselected: {
    backgroundColor: tokens.colors.$transparent,
    borderWidth: 1,
    borderColor: tokens.colors.$black10,
  },
  badgeSelectedText: {
    color: tokens.colors.$white,
    fontVariant: ['tabular-nums'],
    fontSize: 12,
    lineHeight: 16,
  },
  badgeUnselectedText: {
    color: tokens.colors.$black,
    fontVariant: ['tabular-nums'],
    fontSize: 12,
    lineHeight: 16,
  },
});
