import { Box } from '@fhs-legacy/universal-components';
import { WEB_APP_CTA_MAX_WIDTH } from 'utils/constants';

export {
  default as StyledRedemptionButton,
  ActionLink as StyledSignUpToRedeemLink,
} from 'components/action-button';

export const StyledRedemptionBox = Box.withConfig({
  width: { base: 'full', desktop: WEB_APP_CTA_MAX_WIDTH },
  marginTop: {
    base: 4,
    desktop: 5,
  },
});
