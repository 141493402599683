import React from 'react';

import { BoxProps } from '@fhs-legacy/universal-components';

type NativeFieldSetProps = React.ComponentPropsWithRef<'fieldset'>;
export type IFieldSetProps = Pick<
  NativeFieldSetProps,
  // Props we want to bring as-is from the native fieldset element props
  'disabled'
> &
  BoxProps & {
    /** The text describing the entire fieldset */
    label: React.ReactNode;

    /**
     * A hint message appearing at the bottom of the fieldset, for general
     * explanation about the form fields within, disclaimers, etc.
     */
    hintMessage?: string;

    /**
     * An error message appearing at the bottom of the fieldset.
     * The fieldset will use the error appearance when truthy.
     */
    errorMessage?: string;

    /** The id used for the element containing the errorMessage or hintMessage. */
    messageId?: string;

    testID?: string;
    children: React.ReactNode;
  };
