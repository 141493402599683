export * from './svgs/accessible';
export * from './svgs/account';
export * from './svgs/add';
export * from './svgs/arrow-back';
export * from './svgs/arrow-foward';
export * from './svgs/arrow-right';
export * from './svgs/arrow-up';
export * from './svgs/bookmark';
export * from './svgs/calendar';
export * from './svgs/camera';
export * from './svgs/cart-fill';
export * from './svgs/cart-outline';
export * from './svgs/catering';
export * from './svgs/check-circle-outline';
export * from './svgs/check-fill';
export * from './svgs/check';
export * from './svgs/chevron-down';
export * from './svgs/chevron-left';
export * from './svgs/chevron-right';
export * from './svgs/chevron-up';
export * from './svgs/close';
export * from './svgs/copy';
export * from './svgs/curbside';
export * from './svgs/delete';
export * from './svgs/delivery';
export * from './svgs/dialog';
export * from './svgs/dine-in';
export * from './svgs/dollar';
export * from './svgs/drive-thru';
export * from './svgs/drive';
export * from './svgs/earn';
export * from './svgs/edit';
export * from './svgs/error-fill';
export * from './svgs/error';
export * from './svgs/facebook';
export * from './svgs/favorite-fill';
export * from './svgs/favorite-outline';
export * from './svgs/filter';
export * from './svgs/fork-axe';
export * from './svgs/hat-fill';
export * from './svgs/hat-outline';
export * from './svgs/help';
export * from './svgs/home-fill';
export * from './svgs/home-outline';
export * from './svgs/image';
export * from './svgs/info-fill';
export * from './svgs/info';
export * from './svgs/instagram';
export * from './svgs/language';
export * from './svgs/laptop';
export * from './svgs/list';
export * from './svgs/location-fill';
export * from './svgs/location-outline';
export * from './svgs/locked';
export * from './svgs/mail';
export * from './svgs/mail-fill';
export * from './svgs/map';
export * from './svgs/menu';
export * from './svgs/mobile';
export * from './svgs/monitor';
export * from './svgs/more-vertical';
export * from './svgs/more';
export * from './svgs/near-me';
export * from './svgs/offer';
export * from './svgs/offer-outline';
export * from './svgs/payment-type-add-new-payment';
export * from './svgs/payment-type-amazon-pay';
export * from './svgs/payment-type-amex';
export * from './svgs/payment-type-apple-pay';
export * from './svgs/payment-type-cash';
export * from './svgs/payment-type-diners-club';
export * from './svgs/payment-type-discover';
export * from './svgs/payment-type-generic-credit-card';
export * from './svgs/payment-type-google-pay';
export * from './svgs/payment-type-jcb';
export * from './svgs/payment-type-mastercard';
export * from './svgs/payment-type-paypal';
export * from './svgs/payment-type-visa';
export * from './svgs/qr-scan';
export * from './svgs/qr';
export * from './svgs/receipt-long';
export * from './svgs/redeem';
export * from './svgs/refresh';
export * from './svgs/remove-circle-outline';
export * from './svgs/remove';
export * from './svgs/rewards';
export * from './svgs/rewards-fill';
export * from './svgs/rewards-and-offers';
export * from './svgs/rewards-outline';
export * from './svgs/rewards-trophy';
export * from './svgs/search';
export * from './svgs/settings';
export * from './svgs/sodium';
export * from './svgs/star-rate-fill';
export * from './svgs/star-rate';
export * from './svgs/store';
export * from './svgs/telephone';
export * from './svgs/tik-tok';
export * from './svgs/time';
export * from './svgs/twitter';
export * from './svgs/unlocked';
export * from './svgs/visibility-off';
export * from './svgs/visibility';
export * from './svgs/voice';
export * from './svgs/warning-fill';
export * from './svgs/you-tube';
export * from './svgs/shopping-bag';

export type { IconProps } from './types';
