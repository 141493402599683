import { ReactNode } from 'react';

import { DatetimeShow } from '@fhs/ui';

import { frenchDipCAEndDate, frenchDipCAStartDate } from '../constants';

export type LtoContentCAProps = {
  ltoContent?: ReactNode;
  default?: ReactNode;
};

export const LtoContentCA = ({ ltoContent, default: defaultContent }: LtoContentCAProps) => {
  return (
    <>
      <DatetimeShow
        after={frenchDipCAStartDate}
        before={frenchDipCAEndDate}
        default={defaultContent}
      >
        {ltoContent}
      </DatetimeShow>
    </>
  );
};
