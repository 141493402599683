// Firehouse Subs CSS

import { LayoutWidth, NAV_HEIGHT, ZIndex } from './enums';

export default {
  // Extra CSS Properties
  borderRadius: '4px',
  boxShadow: '1',
  shadowColor: 'rgba(0, 0, 0, 0.1)',
  shadowOffsetX: 0,
  shadowOffsetY: 2,
  shadowRadius: 6,

  // Color Styles
  color: {
    accent: '#2F818A',
    alert: {
      default: {
        background: '#EBA518',
        text: '#ffffff',
      },
      info: {
        background: '#EBA518',
        text: '#ffffff',
      },
      success: {
        background: '#B1080F',
        text: '#ffffff',
      },
      warning: {
        background: '#c41230',
        text: '#ffffff',
      },
      error: {
        background: '#c41230',
        text: '#ffffff',
      },
    },
    black: '#1f1f1f',
    background: '#f2f2f6',
    codeBlockBackground: '#323231',
    contrastBackground: '#01000B',
    disabledColor: '',
    disabledBorder: '',
    disabledBackground: '',
    error: '#c41230',
    errorHover: '',
    grey: {
      one: '#4a4a4a',
      two: '#716E6E',
      three: '#767676',
      four: '#dcdcdc',
      five: '#f2f2f6',
      six: '#7e7e7e',
      seven: '#6E6E6E',
      eight: '#b6b6b6',
      nine: '#d0d0d0',
      ten: '#eae7e5',
    },
    headerContrast: '#B1080F',
    highlight: '#FF7D00',
    icon: '#59BEC9',
    loyaltyHighlight: '#EBA518',
    navLinkColor: {
      active: '#B1080F',
      hover: '#767676',
    },
    mobileNavButtonColor: '#1F1F1F',
    pricing: '#c90325',
    primary: '#B1080F',
    primaryHover: '#E31837',
    secondary: '#01000B',
    secondaryHover: '',
    teal: '#00b2a9',
    tertiary: '#c41230',
    white: '#ffffff',
    cardBackground: '#fff',
    validated: '#5E8036',
    validateHover: '',
    success: '',
    red: '#d62300',
  },

  // Font Styles
  fontFamily: {
    base: 'alternative',
    body: 'body',
    alternative: 'alternative',
    brand: 'alternative',
  },

  // Font Weight Styles
  fontWeight: {
    black: 700,
    heavy: 700,
    normal: 400,
    light: 400,
  },

  // Font Transforms Styles
  textTransform: {
    headlines: 'none',
    body: 'none',
  },

  // Z Index Styles
  zIndex: {
    max: ZIndex.MAX,
    overlay: ZIndex.OVERLAY,
    top: ZIndex.TOP,
    normal: ZIndex.NORMAL,
    below: ZIndex.BELOW,
  },

  // Layout variables
  layout: {
    sectionMaxWidth: `${LayoutWidth.REGULAR}px`,
    smallerSectionMaxWidth: `${LayoutWidth.SMALL}px`,
    checkoutHeaderHeight: '57px',
    navHeight: {
      mobile: `${NAV_HEIGHT.MOBILE}px`,
      desktop: '78px',
    },
  },
};
