import { router, useFocusEffect, usePathname } from 'expo-router';
import { useCallback } from 'react';

import { useMaybeOsActionHandler } from '@fhs-legacy/frontend/src/utils/os-action';

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

export default function OsActionPage() {
  const pathname = usePathname();

  const handleMaybeOsAction = useMaybeOsActionHandler();

  useFocusEffect(
    useCallback(() => {
      (async () => {
        await handleMaybeOsAction(pathname);

        router.replace(router.canGoBack() ? '../' : '/');
      })();
    }, [handleMaybeOsAction, pathname])
  );

  return null;
}
