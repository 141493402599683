import { FieldProps } from 'formik';
import React from 'react';

import { Box, FormControl, Select, SelectItemProps } from '@fhs-legacy/universal-components';

type Props = FieldProps<string, { [key: string]: string }> & {
  options: SelectItemProps[];
  label: string;
  hintMessage?: string;
};

export const FormikSelect: React.FC<React.PropsWithChildren<Props>> = ({
  field,
  form,
  ...props
}) => {
  const { options, label, hintMessage } = props;
  const errorMessage =
    form.touched[field.name] && form.errors[field.name] ? form.errors[field.name] : undefined;

  return (
    <Box margin="2">
      <FormControl>
        {label && <FormControl.Label>{label}</FormControl.Label>}
        <Select {...field} {...props}>
          {options.map((option, optionKey) => (
            <Select.Item key={optionKey} value={option.value} label={option.label} />
          ))}
        </Select>
        {hintMessage && !errorMessage && (
          <FormControl.HelperText margin="0" padding="0">
            {hintMessage}
          </FormControl.HelperText>
        )}
        {errorMessage && <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>}
      </FormControl>
    </Box>
  );
};
