import React from 'react';

import { ILinkWidget } from 'generated/sanity-graphql';

import { LinksContainer, StyledLink } from './loyalty-links-widget.styled';

interface ILinksWidgetProps {
  links: ILinkWidget[];
}

export default function LoyaltyLinksWidget({ links }: ILinksWidgetProps) {
  if (!links.length) {
    return null;
  }

  return (
    <LinksContainer>
      {links.map(({ _key, link, text }, idx) => {
        if (!link?.en || !text?.en) {
          return null;
        }
        const isLastItem = links.length - 1 === idx;
        return (
          <StyledLink key={_key ?? idx} to={link.en} $hideBorderBottom={isLastItem}>
            {text.en}
          </StyledLink>
        );
      })}
    </LinksContainer>
  );
}
