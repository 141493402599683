import { Redirect, Stack } from 'expo-router';
import Head from 'expo-router/head';

import { _TmpScreenV2List } from '@fhs/menu/src/screens/_tmp-screen-v2-list';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { isNative } from '@fhs-legacy/frontend/src/utils/environment';

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

export default function V2IndexPage() {
  // TODO(simplified-menu): Remove when simplified menu is out of beta
  if (!useFlag(LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA)) {
    return <Redirect href="/menu" />;
  }
  return (
    <>
      <Head>
        <title>V2 - Firehouse Subs</title>
      </Head>
      <Stack.Screen options={{ headerShown: isNative }} />
      <_TmpScreenV2List />
    </>
  );
}
