import React from 'react';
import { useIntl } from 'react-intl';

import { Radio } from '@fhs-legacy/universal-components';

import { AddressContainer, AddressInfoLine, InfoContainer, InfoLine } from './styled';
import { IDeliveryAddressItemProps } from './types';

const DeliveryAddressItem: React.FC<React.PropsWithChildren<IDeliveryAddressItemProps>> = ({
  value,
  address,
}) => {
  const { formatMessage } = useIntl();

  return (
    <AddressContainer testID="delivery-address-item" borderTopWidth={value === '0' ? '1px' : '0'}>
      <Radio value={value} alignItems="flex-start">
        <InfoContainer>
          <AddressInfoLine>{address.addressLine1}</AddressInfoLine>
          {address.addressLine2 && (
            <AddressInfoLine>
              {formatMessage({ id: 'unitNumber' }, { unitNumber: address.addressLine2 })}
            </AddressInfoLine>
          )}
          <InfoLine>
            {address.city}, {address.state} {address.zip}
          </InfoLine>
          <InfoLine>{address.phoneNumber}</InfoLine>
        </InfoContainer>
      </Radio>
    </AddressContainer>
  );
};

export default DeliveryAddressItem;
