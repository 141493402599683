import React from 'react';
import { StyleSheet } from 'react-native';

import { Skeleton, SkeletonTextBlock } from '@fhs/ui';
import { Box, View } from '@fhs-legacy/universal-components';

const MenuObjectSkeleton = () => (
  <View p="$4">
    <Box p="$8" flexDir="column" alignItems="center">
      <Skeleton style={styles.menuObject} />
    </Box>
    <Skeleton style={styles.menuObjectHeadline} />
    <Box flexDir="column" alignItems="center">
      <Skeleton style={styles.menuObjectSubHeadline} />
    </Box>
    <Box flexDir="column">
      <Box alignItems="flex-start">
        <Skeleton style={styles.shortHeadText} />
      </Box>
      <SkeletonTextBlock lines={2} style={styles.textBlock} />
      <SkeletonTextBlock lines={2} style={styles.textBlock} />
      <SkeletonTextBlock lines={8} style={styles.textBlock} />
    </Box>
  </View>
);

const styles = StyleSheet.create({
  menuObject: {
    height: 192,
    width: 192,
    marginBottom: 12,
  },
  menuObjectHeadline: {
    height: 24,
    marginBottom: 24,
  },
  menuObjectSubHeadline: {
    height: 16,
    width: '40%',
    marginBottom: 32,
  },
  shortHeadText: {
    height: 16,
    width: '30%',
    marginBottom: 32,
  },
  textBlock: {
    marginBottom: 32,
  },
});

export default MenuObjectSkeleton;
