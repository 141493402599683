import { Image } from 'expo-image';
import { Link } from 'expo-router';
import { StyleSheet } from 'react-native';

export function HotSauceBarCanadaCard() {
  return (
    <Link href="/menu/section-5c3e9ca0-682f-4ee2-83f8-11ded3d7f00f" style={styles.card}>
      <Image
        alt="Hot Sauce Bar is back. Now available to purchase. Bottles starting at $11.99"
        source={require('./hot-sauce-bar-canada-card-banner.webp')}
        placeholder={{ thumbhash: 'GRoHF4gIl3hqhXiEe3aX6Ehzko92+HsD' }}
        style={[styles.image]}
      />
    </Link>
  );
}

const styles = StyleSheet.create({
  card: {
    aspectRatio: 676 / 635,
  },
  image: { width: '100%', height: '100%', display: 'flex' },
});
