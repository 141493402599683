import { router } from 'expo-router';
import Head from 'expo-router/head';
import { pick } from 'lodash';
import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ScreenContent } from '@fhs/screen-content';
import { KeyboardAvoidingView, ScrollView } from '@fhs-legacy/universal-components';
import Footer from 'components/footer';
import UserInfoForm from 'components/user-info-form';
import { VerifyPhoneMessage } from 'components/verify-phone-message';
import useAuth from 'hooks/auth/use-auth';
import { useFeatureAccount } from 'hooks/use-feature-account';
import { useToast } from 'hooks/use-toast';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { ACCOUNT_INFO_SCROLL_CONTAINER } from 'utils/test-ids';

import { OneOfCommPrefs } from './comm-preferences/types';

const Container = ScrollView.withConfig({
  flex: 1,
  marginTop: {
    base: '20px',
    desktop: '75px',
  },
  paddingX: {
    base: '5%',
    desktop: '0',
  },
  maxWidth: {
    base: 'full',
    desktop: '485px',
  },
  width: 'full',
  marginX: 'auto',
});

const AccountInfo: FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const { user, updateUserInfo, updateUserCommPrefs, useUpdateMeMutationLoading } = useAuth();
  const { logRBIEvent } = useCRMEventsContext();

  const { featureAccountData } = useFeatureAccount();

  const commPreferencesFromSanity =
    featureAccountData?.emailAndPushOptInSettings || ([] as Array<OneOfCommPrefs>);
  const commPrefOptions = commPreferencesFromSanity.reduce<string[]>(
    (accu, commPreference: any) => {
      if (
        !commPreference ||
        !commPreference._type ||
        !['optInSettingEmailAndPush', 'optInSettingBoolean'].includes(commPreference._type)
      ) {
        return accu;
      }

      const keys = Object.values(pick(commPreference, ['key', 'pushKey', 'emailKey'])).filter(
        Boolean
      ) as string[];

      return accu.concat(keys);
    },
    []
  );

  const toast = useToast();
  const handleOnUpdateUserSuccess = useCallback(() => {
    router.canGoBack() ? router.back() : router.replace('/account');
    toast.show({
      text: formatMessage({ id: 'successfullyUpdatedUserAccountInfo' }),
      variant: 'positive',
    });
  }, [formatMessage, toast]);
  const updateUser = useCallback(
    (details: any) => {
      // TODO: Merge preference and details update into a single request
      // once we merge to apollo
      const updateUserOptions = { shouldThrowException: true };
      return updateUserInfo(details, updateUserOptions)
        .then(() => {
          const prefs = commPrefOptions.map(pref => ({
            id: pref,
            value: details.promotionalEmails === true ? 'true' : 'false',
            // TODO: Re-enable below when fixing commPrefs on user details to be bool over string.
            // asBoolean: true
          }));

          logRBIEvent({
            name: CustomEventNames.UPDATE_USER_ATTRIBUTES,
            type: EventTypes.UserContent,
            attributes: {
              'Customer ID': user?.cognitoId,
              'Previous DOB': user?.details.dob,
              'New DOB': details?.dob,
              'New phone number': details?.phoneNumber,
              'New name': details?.name,
            },
          });

          return updateUserCommPrefs(Object.values(prefs), updateUserOptions);
        })
        .then(() => {
          handleOnUpdateUserSuccess();
        });
    },
    [updateUserInfo, commPrefOptions, updateUserCommPrefs, handleOnUpdateUserSuccess]
  );
  return (
    <ScreenContent after={<Footer />} testID={ACCOUNT_INFO_SCROLL_CONTAINER}>
      <Head>
        <title>{`${formatMessage({ id: 'accountDetails' })} - Firehouse Subs`}</title>
      </Head>
      <Container keyboardShouldPersistTaps="handled">
        <VerifyPhoneMessage />
        <KeyboardAvoidingView>
          <UserInfoForm user={user} onSubmit={updateUser} isLoading={useUpdateMeMutationLoading} />
        </KeyboardAvoidingView>
      </Container>
    </ScreenContent>
  );
};

export default AccountInfo;
