import { useFeatureStaticMenuQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

import { IParsedItemUnavailableModalData, IUseFeatureStaticMenu, requiredKeys } from './types';
import { parseData } from './utils';

export const useFeatureStaticMenu = (): IUseFeatureStaticMenu => {
  const { featureStaticMenuId = '', featureIdsLoading } = useFeaturesContext();
  const { data, loading } = useFeatureStaticMenuQuery({
    variables: { featureStaticMenuId },
    skip: !featureStaticMenuId,
  });

  const featureStaticMenuLoading = featureIdsLoading || loading;
  const itemUnavailableModalData = data?.FeatureStaticMenu?.itemUnavailableModal;

  const itemUnavailableModal = parseData<IParsedItemUnavailableModalData>(
    itemUnavailableModalData,
    requiredKeys
  );

  return {
    itemUnavailableModal,
    featureStaticMenuLoading,
  };
};
