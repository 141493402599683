import { useLocalSearchParams } from 'expo-router';
import { defaultTo, isEqualWith } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { IBaseProps } from '@rbi-ctg/frontend';
import { IStore } from '@rbi-ctg/store';
import { useConfigValue } from 'hooks/configs/use-config-value';
import useEffectOnce from 'hooks/use-effect-once';
import { useServiceModeStatusGenerator } from 'hooks/use-service-mode-status';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { mergeRestaurantData, useGetRestaurantFn } from 'utils/restaurant';

import {
  ISelectStoreOptions,
  IStoreContext,
  OnConfirmStoreChangeParams,
  SelectedStore,
  StorePermissions,
  createStoreProxyFromStore,
  curriedGetStoreStatusV1,
  curriedGetStoreStatusV2,
  useStore,
} from './hooks';
import { useStoreUtils } from './hooks/use-store-utils';

export * from './hooks/types';

export const StoreContext = React.createContext<IStoreContext>({} as IStoreContext);
export const useStoreContext = () => useContext<IStoreContext>(StoreContext);

export const StoreProvider = ({ children }: IBaseProps) => {
  // LD Flags
  const enableOrdering = defaultTo(useFlag(LaunchDarklyFlag.ENABLE_ORDERING), true);
  const enableStoreSelection2_0 = useFlag(LaunchDarklyFlag.ENABLE_STORE_SELECTION_2_0);
  const enableFutureOrdering = useFlag(LaunchDarklyFlag.ENABLE_FUTURE_ORDERING);

  const getRestaurant = useGetRestaurantFn();
  const { formatMessage } = useIntl();
  const params = useLocalSearchParams<{ 'store-number': string }>();
  const { serviceMode } = useServiceModeContext();

  const restaurantsConfig = useConfigValue({ key: 'restaurants', defaultValue: {} });
  const validMobileOrderingEnvs = restaurantsConfig.validMobileOrderingEnvs ?? [];

  const [onConfirmStoreChangeParams, setOnConfirmStoreChangeParams] =
    useState<OnConfirmStoreChangeParams | null>(null);
  const [isFetchingStore, setFetchingStore] = useState(false);
  const [isSwitchingStore, setIsSwitchingStore] = useState(false);

  const clearOnConfirmStoreChangeParams = () => {
    setOnConfirmStoreChangeParams(null);
  };

  const {
    resetStore,
    setStore,
    store,
    storeProxy,
    selectUnavailableStore,
    onConfirmStoreChange,
    updateUserStoreWithCallback,
    hasCalledSetStore,
  } = useStore();

  const curriedGetStoreStatus = enableStoreSelection2_0
    ? curriedGetStoreStatusV2
    : curriedGetStoreStatusV1;

  const {
    fetchStore,
    email,
    isRestaurantAvailable,
    prices,
    serviceModeStatus,
    resetLastTimeStoreUpdated,
  } = useStoreUtils({
    resetStore,
    store: storeProxy,
  });

  const { generateServiceModeStatusForStore } = useServiceModeStatusGenerator();

  const getStoreStatusFlags = useCallback<IStoreContext['getStoreStatusFlags']>(
    (storeToCheck, selectedServiceMode) => {
      if (!storeToCheck) {
        return {
          isStoreOpenAndAvailable: false,
          isStoreOpenAndUnavailable: false,
          isStoreClosed: false,
          isStoreClosedAndAvailable: false,
          isStoreAvailable: false,
          noStoreSelected: true,
        };
      }
      const serviceModeStatuses = generateServiceModeStatusForStore(
        createStoreProxyFromStore(storeToCheck)
      );
      const status = curriedGetStoreStatus({
        store: storeToCheck,
        serviceModeStatuses,
        selectedServiceMode,
      })();

      return {
        isStoreOpenAndAvailable: status === StorePermissions.OPEN_AND_AVAILABLE,
        isStoreOpenAndUnavailable: status === StorePermissions.OPEN_AND_UNAVAILABLE,
        isStoreClosed: status === StorePermissions.CLOSED,
        isStoreClosedAndAvailable: status === StorePermissions.CLOSED_AND_AVAILABLE,
        isStoreAvailable:
          status === StorePermissions.OPEN_AND_AVAILABLE ||
          (status === StorePermissions.CLOSED_AND_AVAILABLE && enableFutureOrdering),
        noStoreSelected: status === StorePermissions.NO_STORE_SELECTED,
      };
    },
    [generateServiceModeStatusForStore, curriedGetStoreStatus, enableFutureOrdering]
  );

  const {
    isStoreOpenAndAvailable,
    isStoreOpenAndUnavailable,
    isStoreClosed,
    isStoreAvailable,
    noStoreSelected,
  } = useMemo(
    () => getStoreStatusFlags(store, serviceMode),
    [getStoreStatusFlags, store, serviceMode]
  );

  // If flags are enabled, refresh restaurant availability & poll for the selected store's availability to notify the user when the store becomes unavailable.

  const fetchAndSetStore = useCallback(
    async (storeId: string, hasSelection = true, onError?: VoidFunction) => {
      try {
        setFetchingStore(true);
        const fetchedStore = await fetchStore(storeId);
        if (fetchedStore) {
          let formattedStore: SelectedStore | IStore = { ...fetchedStore };

          // add `hasSelection` if in store selection 1.0
          if (!enableStoreSelection2_0 && hasSelection) {
            formattedStore = { ...fetchedStore, hasSelection };
          }

          setStore({ ...formattedStore });
        } else {
          onError?.();
        }
      } catch {
        onError?.();
      } finally {
        setFetchingStore(false);
      }
    },
    [enableStoreSelection2_0, fetchStore, setStore]
  );

  const isAlphaBetaStoreOrderingEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_ALPHA_BETA_STORE_ORDERING
  );
  // ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS
  const enableDeliveryOutsideOpeningHours = useFlag(
    LaunchDarklyFlag.ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS
  );

  const selectStoreDialog = useCallback(
    async ({ sanityStore, callback, requestedServiceMode }: ISelectStoreOptions) => {
      // we do not care about POS if ordering is not enabled
      const rbiRestaurant = await getRestaurant(sanityStore.number);

      // we need to add the restaurantPosData._id to the sanity store because this field is not returned in the store list anymore
      const fetchedStore = await fetchStore(sanityStore.number);
      const sanityStoreWithPosData =
        fetchedStore && fetchedStore.restaurantPosData
          ? {
              ...sanityStore,
              restaurantPosData: { ...fetchedStore.restaurantPosData },
            }
          : sanityStore;

      // Operating hours now come from our gql layer so we must merge the results
      // with the sanity store.
      // Default to the passed in sanity store if we cannot get a restaurant from
      // the gql layer.
      const newStore = rbiRestaurant
        ? mergeRestaurantData({ rbiRestaurant, sanityStore: sanityStoreWithPosData })
        : sanityStoreWithPosData;

      const selectedServiceMode = requestedServiceMode ?? serviceMode;

      const { isStoreOpenAndUnavailable: isNewStoreOpenAndUnavailable } = getStoreStatusFlags(
        newStore,
        selectedServiceMode
      );

      if (isNewStoreOpenAndUnavailable) {
        return onConfirmStoreChange({ newStore, callback });
      }

      if (isEqualWith(storeProxy.physicalAddress, newStore.physicalAddress)) {
        return callback();
      }

      return onConfirmStoreChange({
        newStore,
        callback,
      });
    },
    [
      enableDeliveryOutsideOpeningHours,
      enableOrdering,
      formatMessage,
      getRestaurant,
      isAlphaBetaStoreOrderingEnabled,
      isStoreOpenAndUnavailable,
      onConfirmStoreChange,
      serviceMode,
      storeProxy.physicalAddress,
      validMobileOrderingEnvs,
    ]
  );

  const [storeNumber, setStoreNumber] = useState<string | undefined>(params['store-number']);
  useEffect(() => {
    if (
      storeNumber &&
      storeNumber.toString() !== (store as IStore)?.number &&
      isFetchingStore === false
    ) {
      void fetchAndSetStore(storeNumber.toString(), true, () => setStoreNumber(undefined));
    }
  }, [fetchAndSetStore, store, storeNumber, isFetchingStore]);

  useEffectOnce(() => {
    // Fetch store on initial render if a store is selected
    if (!storeNumber && store && 'number' in store && store.number !== null) {
      const storeHasSelection = !!store && 'hasSelection' in store && store.hasSelection;
      fetchAndSetStore(store.number, storeHasSelection);
    }
  });

  const value = useMemo(
    () => ({
      email,
      isRestaurantAvailable,
      prices,
      selectStore: selectStoreDialog,
      isFetchingStore,
      fetchStore: fetchAndSetStore,
      selectUnavailableStore,
      serviceModeStatus,
      setStore,
      resetStore,
      resetLastTimeStoreUpdated,
      getStoreStatusFlags,
      isStoreOpenAndAvailable,
      isStoreOpenAndUnavailable,
      isStoreClosed,
      isStoreAvailable,
      noStoreSelected,
      store: storeProxy,
      updateUserStoreWithCallback,
      onConfirmStoreChange,
      onConfirmStoreChangeParams,
      clearOnConfirmStoreChangeParams,
      hasCalledSetStore,
      isSwitchingStore,
      setIsSwitchingStore,
    }),
    [
      email,
      isRestaurantAvailable,
      prices,
      selectStoreDialog,
      isFetchingStore,
      fetchAndSetStore,
      selectUnavailableStore,
      serviceModeStatus,
      setStore,
      resetStore,
      resetLastTimeStoreUpdated,
      getStoreStatusFlags,
      isStoreOpenAndAvailable,
      isStoreOpenAndUnavailable,
      isStoreClosed,
      isStoreAvailable,
      noStoreSelected,
      storeProxy,
      updateUserStoreWithCallback,
      onConfirmStoreChange,
      onConfirmStoreChangeParams,
      hasCalledSetStore,
      isSwitchingStore,
      setIsSwitchingStore,
    ]
  );

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ email: st... Remove this comment to see the full error message
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};

export default StoreContext.Consumer;
