import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconHatOutline = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M23.406 16.788c-.121-.99-2.085-2.448-5.373-3.298-.167-.87-.408-1.922-.408-1.922-.478-1.62-1.832-4.489-6.082-4.568h-.137a6.49 6.49 0 0 0-3.933 1.29l-1.065-.935s-.14-.085-.306-.085a.497.497 0 0 0-.455.297c-.2.416-.66 1.48-1.125 3.341-.345 1.376-.458 2.41-.49 3.06l-1.412.471s-1.965.795-1.567 1.698c.3.679 1.245 1.852 5.485 1.852 1.4 0 3.162-.128 5.38-.445 2.38-.34 4.231-.517 5.666-.595 1.964-.113 3.445.291 3.745.422.249.108.484.202.706.27.222.068.43.113.64.114.1 0 .201-.01.303-.04a.801.801 0 0 0 .222-.106.527.527 0 0 0 .22-.28c.057-.173.027-.353-.011-.54h-.003ZM5.273 11.096c.36-1.443.716-2.382.933-2.887l1.62 1.424c.111.17.426.792-.097 1.722-.48.85-.617 1.63-.74 2.319-.1.568-.176 1.003-.412 1.37a2.518 2.518 0 0 1-1.695-.47.66.66 0 0 1-.085-.172c0-.5.06-1.639.478-3.309l-.002.003Zm16.988 5.803a8.586 8.586 0 0 1-.994-.405c-.382-.177-.805-.383-1.25-.59l-.003-.003c-1.929-.863-3.67-1.243-5.256-1.243-1.94 0-3.636.57-5.134 1.476a7.085 7.085 0 0 1-2.095.827 9.186 9.186 0 0 1-2.287.21c-1.876-.135-3.08-.577-3.448-1.282.13-.194.634-.53 1.097-.722l1.14-.38v.067s.523.62 1.512.87c.476.231 1.18.44 2.214.58.004 0 1.212-.231 2.445-.604a33.156 33.156 0 0 1-.695-.024c-1.996-.427-.827-4.572-.827-4.572.376-1.214-.275-1.997-.275-1.997l-.333-.292a5.737 5.737 0 0 1 3.335-1.04h.122c2.773.051 4.573 1.393 5.346 3.99.192.84.482 2.202.532 2.688-.024.04-.105.148-.324.287.313.02.656.06.979.13.1-.16.133-.308.122-.434-.002-.03-.005-.064-.01-.1 1.06.317 2.356.84 2.836 1.105.953.527 1.39 1.053 1.557 1.338.058.103.043.24-.306.117v.003Z"
      />
    </Svg>
  );
};
