import React from 'react';

import { useGetRecentRestaurants } from 'hooks/use-get-recent-restaurants';
import { useGeolocation } from 'state/geolocation';
import { OrderLocatorTab } from 'state/launchdarkly/variations';

import StoreCardList from './store-card-list';

type Props = {
  handleSelectStore(storeId: string): void;
};

export function RecentStoreCards({ handleSelectStore }: Props) {
  const { restaurants, loading, error } = useGetRecentRestaurants();
  const { isPermissionChecking } = useGeolocation();

  const isSearchingRecents = loading || isPermissionChecking;

  return (
    <StoreCardList
      currentTabHasError={!!error}
      isSearching={isSearchingRecents}
      onSelectStore={handleSelectStore}
      restaurants={restaurants}
      tab={OrderLocatorTab.RECENT}
    />
  );
}
