import React, { FC, useCallback } from 'react';

import { Radio, ScrollView } from '@fhs-legacy/universal-components';
import { ActionButtonVariants } from 'components/action-button';
import Modal, { ModalSize } from 'components/modal';
import { ResponsiveCarousel } from 'components/responsive-carousel';

import ModalSurpriseCard from './modal-surprise-card';
import {
  Container,
  Description,
  Prompt,
  RejectSurprise,
  StyledButton,
  StyledCloseButton,
  SurpriseButtons,
  SurpriseInfo,
  SurpriseOptions,
  Terms,
  TitleBackground,
  TitleContainer,
  TitleHeader,
} from './modal-surprise.styled';
import { ILoyaltySurpriseViewProps } from './types';

const ModalSurpriseView: FC<React.PropsWithChildren<ILoyaltySurpriseViewProps>> = ({
  offers,
  selectedOffer,
  modalContent,
  handleAddSurprise,
  handleDismiss,
  handleSelectOffer,
}) => {
  const onChangeRadio = useCallback(
    (selectedOfferId: string) => {
      const newSelectedOffer = offers.find(offer => offer._id === selectedOfferId);
      if (newSelectedOffer) {
        handleSelectOffer(newSelectedOffer);
      }
    },
    [offers, handleSelectOffer]
  );
  return (
    <Modal
      mParticleEventData={{
        modalAppearanceEventMessage: 'Loyalty Surprise & Delight',
      }}
      testID="surprise-modal"
      size={ModalSize.FULLSCREEN}
      backgroundColor={Styles.color.teal}
      onDismiss={handleDismiss}
      closeButton={props => <StyledCloseButton {...props} />}
    >
      <ScrollView>
        <Container>
          <SurpriseInfo>
            <TitleContainer>
              <TitleBackground uri={modalContent.bgImage ?? ''} />
              <TitleHeader>{modalContent.title}</TitleHeader>
            </TitleContainer>
            <Description>{modalContent.description}</Description>
            <SurpriseOptions>
              <Prompt>{modalContent.prompt}</Prompt>
              {Boolean(offers?.length) && (
                <Radio.Group
                  name="SurpriseCardRadioButton"
                  value={selectedOffer?._id}
                  onChange={onChangeRadio}
                >
                  <ResponsiveCarousel
                    bgColor={Styles.color.teal}
                    itemWidth={256}
                    centerCards
                    data={offers}
                    isLoading={false}
                    keyExtractor={item => item._id}
                    renderItem={({ item }) => (
                      <ModalSurpriseCard offer={item} handleSelectOffer={handleSelectOffer} />
                    )}
                  />
                </Radio.Group>
              )}
            </SurpriseOptions>
          </SurpriseInfo>
          <SurpriseButtons>
            <StyledButton
              variant={ActionButtonVariants.INVERSE}
              onPress={() => handleAddSurprise(selectedOffer)}
              disabled={!selectedOffer}
              testID="add-free-surprise"
            >
              {modalContent.buttonText}
            </StyledButton>
            <RejectSurprise onPress={handleDismiss} testID="reject-surprise">
              {modalContent.noThanksText}
            </RejectSurprise>
            <Terms content={modalContent.termsText} />
          </SurpriseButtons>
        </Container>
      </ScrollView>
    </Modal>
  );
};
export default ModalSurpriseView;
