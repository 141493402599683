import React from 'react';

import Picture from 'components/picture';
import { useFeatureLayout } from 'hooks/use-feature-layout';

import { useStaticContentLanguage } from '../static-content-language';

import {
  Bottom,
  Container,
  Heading,
  HeadingContainer,
  Image,
  ImageContainer,
  StyledHeroHeading,
  Tagline,
  Top,
} from './headerWidget.styled';
import { IHeaderWidget } from './types';

const Header = ({ taglineContent, headerImage, headingContent }: IHeaderWidget) => {
  const { featureLayout } = useFeatureLayout();
  const { staticContentLanguage: language } = useStaticContentLanguage();
  const { locale: defaultHeroImage } = featureLayout?.defaultHeroImage || {};

  return taglineContent?.[language] && headerImage ? (
    <Container>
      <Top>
        <HeadingContainer>
          <Heading>{headingContent?.[language]}</Heading>
          {taglineContent && <Tagline>{taglineContent?.[language]}</Tagline>}
        </HeadingContainer>
      </Top>
      <Bottom>
        <ImageContainer>
          <Image alt="" image={headerImage?.[language]} />
        </ImageContainer>
      </Bottom>
    </Container>
  ) : (
    <Picture
      image={headerImage?.[language] ?? defaultHeroImage}
      alt=""
      sizedByChildren
      skipBreakpoints
    >
      <StyledHeroHeading accessibilityLevel={1}>{headingContent?.[language]}</StyledHeroHeading>
    </Picture>
  );
};

export default Header;
