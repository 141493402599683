import React from 'react';
import { GestureResponderEvent } from 'react-native';

import { IBaseProps } from '@rbi-ctg/frontend';
import ActionButton, { ActionButtonVariants } from 'components/action-button';

import { Actions } from './actions';

interface IAuthActionsProps extends IBaseProps {
  primaryActionDisabled?: boolean;
  primaryActionIsLoading?: boolean;
  primaryActionLabel: string;
  primaryActionQaId?: string;
  onPress?: (ev?: GestureResponderEvent) => void | Promise<void>;
}

export const AuthLinkAction = ({
  primaryActionDisabled = false,
  primaryActionIsLoading = false,
  primaryActionLabel,
  primaryActionQaId,
  onPress,
}: IAuthActionsProps) => (
  <ActionButton
    variant={ActionButtonVariants.TEXT_ONLY}
    testID={primaryActionQaId}
    isLoading={primaryActionIsLoading}
    disabled={primaryActionDisabled}
    onPress={onPress}
  >
    {primaryActionLabel}
  </ActionButton>
);

const AuthActions = ({
  primaryActionDisabled = false,
  primaryActionIsLoading = false,
  primaryActionLabel,
  primaryActionQaId,
  onPress,
}: IAuthActionsProps) => (
  <Actions>
    <ActionButton
      fullWidth
      onPress={onPress}
      testID={primaryActionQaId}
      isLoading={primaryActionIsLoading}
      disabled={primaryActionDisabled}
    >
      {primaryActionLabel}
    </ActionButton>
  </Actions>
);

export default React.memo(AuthActions);
