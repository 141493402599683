import React, { VFC } from 'react';

import { HStack, Icon, Text } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

interface IStoreActionsErrorProps {
  message: string;
}

export const StoreActionsError: VFC<IStoreActionsErrorProps> = ({ message }) => {
  return (
    <HStack
      alignItems="center"
      justifyContent="center"
      background={theme.token('background-inline-alert')}
      paddingY="1"
      paddingX="1"
    >
      <Icon
        color={theme.token('text-default')}
        variant="error"
        size="14px"
        marginRight="1"
        {...hiddenAccessibilityPlatformProps}
      />
      <Text fontSize="xs" fontWeight="medium" color={theme.token('text-default')}>
        {message}
      </Text>
    </HStack>
  );
};
