import { primitive } from 'styles/constants/primitives';

export const brandFont = {
  hero: 'var(--font-hero)',
  headerOne: 'var(--font-headerOne)',
  headerTwo: 'var(--font-headerTwo)',
  headerThree: 'var(--font-headerThree)',
  headerFour: primitive.$headerFour,
  copyOne: 'var(--font-copyOne)',
  copyTwo: 'var(--font-copyTwo)',
  formUtility: primitive.$formUtility,
};
