import { AlertDialog as NbAlertDialog } from '@fhs-legacy/native-base';

import { addWithConfig } from '../../utils';

import type { AlertDialogProps, _AlertDialogComponentType } from './types';

export const AlertDialog = ((props: AlertDialogProps) => (
  <NbAlertDialog {...props} size="lg" borderLeftRadius={8} />
)) as _AlertDialogComponentType;

AlertDialog.Body = NbAlertDialog.Body;
AlertDialog.CloseButton = NbAlertDialog.CloseButton;
AlertDialog.Content = NbAlertDialog.Content;
AlertDialog.Footer = NbAlertDialog.Footer;
AlertDialog.Header = NbAlertDialog.Header;

const AlertDialogWithConfig = addWithConfig(AlertDialog);

AlertDialogWithConfig.Body = addWithConfig(AlertDialog.Body);
AlertDialogWithConfig.CloseButton = addWithConfig(AlertDialog.CloseButton);
AlertDialogWithConfig.Content = addWithConfig(AlertDialog.Content);
AlertDialogWithConfig.Footer = addWithConfig(AlertDialog.Footer);
AlertDialogWithConfig.Header = addWithConfig(AlertDialog.Header);

export default AlertDialogWithConfig;
