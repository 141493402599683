import { Box, Text } from '@fhs-legacy/universal-components';

export const Container = Box.withConfig({
  width: 'full',
  alignItems: 'center',
});

export const HeaderTitle = Text.withConfig({
  variant: 'headerTwo',
  textAlign: 'center',
  numberOfLines: 2,
  ellipsizeMode: 'tail',
  _web: {
    display: 'inline',
  },
});

export const AddressBoxAddress = Text.withConfig({
  ellipsizeMode: 'tail',
  numberOfLines: 1,

  backgroundColor: 'red',

  width: '100%',

  fontSize: 'md',
  textAlign: 'left',
  lineHeight: 'md',
});

export const AddressListTile = Text.withConfig({
  numberOfLines: 1,

  width: '100%',
  textAlign: 'left',
  fontSize: 'md',
  lineHeight: 'md',
  fontFamily: 'heading',
  paddingY: '$1',
});

export const LoadingText = Text.withConfig({
  ellipsizeMode: 'tail',
  numberOfLines: 1,

  width: '100%',
  textAlign: 'center',
  fontSize: 'md',
  lineHeight: 'md',
  fontFamily: 'body',
  paddingY: '$2',
});

export const Circle = Box.withConfig({
  gap: 4,
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 'full',
  backgroundColor: 'darkgray',
});

export const ButtonContainer = Box.withConfig({
  borderStyle: 'solid',
  borderTopWidth: '1px',
  borderTopColor: 'rgba(177, 177, 177, 0.7)',

  gap: 4,
  width: '100%',
  display: 'flex',
  paddingX: 4,
  paddingTop: 4,
  paddingBottom: 8,

  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const BorderedContainer = Box.withConfig({
  borderRadius: '8px',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'rgba(177, 177, 177, 0.7)',

  gap: 4,
  display: 'flex',
  paddingX: 4,

  width: '100%',
  minHeight: '60px',
  maxHeight: '90px',

  alignItems: 'center',
  alignContent: 'start',
  flexDirection: 'row',
  justifyContent: 'start',
});
