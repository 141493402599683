import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ServiceMode } from 'state/order';

import { StyledText } from './styled';
import { ISubheadingText } from './types';

export const SubheadingText = ({ name, pickupTime, serviceMode }: ISubheadingText) => {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    if (serviceMode === ServiceMode.EAT_IN) {
      return (
        <StyledText>
          <StyledText fontWeight="bold">
            {formatMessage({ id: 'orderConfirmationCheckoutInstructionDineIn' }, { name })}
          </StyledText>
          <StyledText maxW={'450'}>
            {formatMessage({ id: 'orderConfirmationCheckoutInstructionDineInSubtitle' })}
          </StyledText>
        </StyledText>
      );
    }
    if (serviceMode === ServiceMode.DRIVE_THRU) {
      return (
        <StyledText>{formatMessage({ id: 'driveThruPickupInstructions' }, { name })}</StyledText>
      );
    }
    if (serviceMode === ServiceMode.CURBSIDE) {
      return (
        <StyledText>
          {formatMessage({ id: 'curbsideInstructionsText' }, { time: pickupTime })}
        </StyledText>
      );
    }
    if (serviceMode === ServiceMode.PICKUP_WINDOW) {
      return (
        <StyledText fontWeight="bold">
          {formatMessage({ id: 'pickupWindowInstructionsText' })}
        </StyledText>
      );
    }

    return (
      <StyledText>
        <StyledText fontWeight="bold">
          {formatMessage({ id: 'orderConfirmationCheckoutInstruction' }, { name })}
        </StyledText>{' '}
        {formatMessage({
          id: 'noChangesToOrderTime',
        })}
      </StyledText>
    );
  }, [formatMessage, name, pickupTime, serviceMode]);
};
