import { router } from 'expo-router';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { InlineAlert } from '@fhs-legacy/universal-components';
import CreditCardFormInputs from 'components/credit-card-form-inputs';
import PaymentMethodSelect, { HoverTheme } from 'components/payment-method-select';
import { usePrepaidsPaymentMethods } from 'hooks/prepaid/use-prepaids-payment-methods';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { SupportedCardType, defaultSupportedCardTypes } from 'state/launchdarkly/variations';
import { usePaymentContext } from 'state/payment';
import { CardType } from 'state/payment/types';
import { useUIContext } from 'state/ui';
import { initialErrorState, onCCFormChange } from 'utils/payment';
import { mapPaymentMethodCardTypes } from 'utils/payment/map-payment-method-card-type';
import { routes } from 'utils/routing';

import { RELOAD_EXPLANATION_TRANSLATION_ID } from './constants';
import { InstructionText, LoadContainer, ReloadWrapper } from './styled';
import { IFullPrepaidMethodReloadProps } from './types';

const PrepaidMethodReload: React.FC<React.PropsWithChildren<IFullPrepaidMethodReloadProps>> = ({
  isDelivery,
  reloadAmount,
  reloadPaymentValues,
  setReloadPaymentValues,
}) => {
  const [errors, setErrors] = useState(initialErrorState);
  const { formatMessage } = useIntl();
  const { formatCurrencyForUser } = useUIContext();
  const { setPrepaidReloadPaymentMethodId } = usePaymentContext();
  const { nonSelectedReloadPaymentMethods, reloadPaymentMethods, selectedReloadPaymentMethod } =
    usePrepaidsPaymentMethods();

  const rawSupportedCardTypes: SupportedCardType[] =
    useFlag(LaunchDarklyFlag.SUPPORTED_CARD_BRANDS_VARIATIONS) || defaultSupportedCardTypes;
  const supportedCardTypes = rawSupportedCardTypes
    .map(cardType => mapPaymentMethodCardTypes(cardType))
    .filter(Boolean) as CardType[];

  const formattedReloadAmount = formatCurrencyForUser(reloadAmount);

  const onChange = useCallback(
    (name: string, value: string) => {
      const { state, formErrors } = onCCFormChange(
        name,
        value,
        reloadPaymentValues,
        errors,
        formatMessage,
        supportedCardTypes
      );
      setErrors(prevState => ({ ...prevState, ...formErrors }));
      setReloadPaymentValues(prevState => ({ ...prevState, ...state }));
    },
    [reloadPaymentValues, errors, formatMessage, setReloadPaymentValues, supportedCardTypes]
  );

  const reloadExplanationText = formatMessage(
    { id: RELOAD_EXPLANATION_TRANSLATION_ID },
    { reloadAmount: formattedReloadAmount }
  );
  const youWillBeChargedText = formatMessage(
    { id: 'youWillBeCharged' },
    { reloadAmount: formattedReloadAmount }
  );
  const reloadExplanationAndChargedAmountText = `${reloadExplanationText} ${youWillBeChargedText}`;

  return (
    <ReloadWrapper>
      <InlineAlert message={reloadExplanationAndChargedAmountText} status="info" />
      <LoadContainer>
        <InstructionText>
          {formatMessage({ id: reloadPaymentMethods.length ? 'loadWith' : 'addCreditCard' })}:
        </InstructionText>
        {reloadPaymentMethods.length ? (
          <PaymentMethodSelect
            onOptionClick={setPrepaidReloadPaymentMethodId}
            nonSelectedMethods={nonSelectedReloadPaymentMethods}
            selectedMethod={selectedReloadPaymentMethod}
            hoverTheme={HoverTheme.LIGHT}
            onNavigatePress={() => router.navigate(routes.cartAddCard)}
          />
        ) : (
          <CreditCardFormInputs
            ariaLabel="credit-card-form-inputs"
            isDelivery={isDelivery}
            onChange={onChange}
            paymentValues={reloadPaymentValues}
            errors={errors}
            withBorder={false}
          />
        )}
      </LoadContainer>
    </ReloadWrapper>
  );
};

export default PrepaidMethodReload;
