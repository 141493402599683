export { useLoyaltyRedeemPromoCodeMutation } from '@fhs-legacy/frontend/src/generated/graphql-gateway';
import { useIntl } from 'react-intl';

import { useAuthContext } from '@fhs-legacy/frontend/src/state/auth';
import { selectors, useAppSelector } from '@fhs-legacy/frontend/src/state/global-state';
import { useLoyaltyContext } from '@fhs-legacy/frontend/src/state/loyalty';
import { LoyaltyUser } from '@fhs-legacy/frontend/src/state/loyalty/hooks/types';
import { useLoyaltyUser } from '@fhs-legacy/frontend/src/state/loyalty/hooks/use-loyalty-user';
import { useServiceModeContext } from '@fhs-legacy/frontend/src/state/service-mode';
import { useStoreContext } from '@fhs-legacy/frontend/src/state/store';

const useLoyaltyUserExpireDateFormattedText = (loyaltyUser?: LoyaltyUser) => {
  const pointsExpiryDateKey = loyaltyUser?.pointsExpiryDateKey;

  const { formatDate, formatMessage } = useIntl();

  if (!pointsExpiryDateKey) {
    return null;
  }
  const day = `${formatDate(pointsExpiryDateKey, {
    day: '2-digit',
  })}`;

  const month = `${formatDate(pointsExpiryDateKey, {
    month: 'long',
  })}`;
  const dateTime = formatMessage(
    {
      id: 'ordinalDateTime',
      description: {},
      defaultMessage: `${month} {day, selectordinal,
      one {#st}
      two {#nd}
      few {#rd}
      other {#th}
  }`,
    },
    {
      day,
    }
  );
  return dateTime;
};

export function useLoyaltyLegacyStates() {
  const { store } = useStoreContext();
  const { serviceMode } = useServiceModeContext();
  const { loyaltyUserReady, setOfferValidationError } = useLoyaltyContext();
  const { loyaltyUser, loading } = useLoyaltyUser();
  const loyaltyId = useAppSelector(selectors.loyalty.selectLoyaltyUserId);
  const loyaltyUserExpireDateFormattedText = useLoyaltyUserExpireDateFormattedText(loyaltyUser);
  const selectedOffer = useAppSelector(selectors.loyalty.selectSelectedOffer);
  const { isAuthenticated } = useAuthContext();
  return {
    store,
    serviceMode,
    loyaltyUserReady,
    loyaltyId,
    loyaltyUser,
    loyaltyUserLoading: loading,
    loyaltyUserExpireDateFormattedText,
    selectedOffer,
    setOfferValidationError,
    isAuthenticated,
  };
}
