import { IPosDataQuery } from 'generated/sanity-graphql';

export function getBase64RestaurantPosDataPlus(
  posData: IPosDataQuery | undefined,
  isDelivery: boolean
) {
  return isDelivery && posData?.RestaurantPosData?.base64DeliveryPLUs
    ? posData?.RestaurantPosData.base64DeliveryPLUs
    : posData?.RestaurantPosData?.base64PLUs;
}
