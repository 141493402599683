import { Stack, useLocalSearchParams } from 'expo-router';

import MenuScreenV2 from '@fhs/routing/src/routes/v2/menu/(menu)';
import StoreSelected from '@fhs-legacy/frontend/src/components/store-selected';
import MenuContent from '@fhs-legacy/frontend/src/pages/menu-content';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { isNative } from '@fhs-legacy/frontend/src/utils/environment';
import { isSectionRoute } from '@fhs-legacy/frontend/src/utils/routing';

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

export default function MenuItemPage() {
  const { id } = useLocalSearchParams<{ id: string }>();
  const sectionRoute = isSectionRoute(`/menu/${id}`);

  // TODO(simplified-menu): Remove when simplified menu is out of beta
  const enableSimplifiedMenuBeta = useFlag<boolean>(LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA);
  if (enableSimplifiedMenuBeta && sectionRoute) {
    return <MenuScreenV2 />;
  }

  return (
    <StoreSelected>
      <Stack.Screen options={{ headerShown: isNative }} />
      <MenuContent />
    </StoreSelected>
  );
}
