import { useFocusEffect } from 'expo-router';
import { useCallback, useRef } from 'react';

import { Nullable } from '@rbi-ctg/frontend';

const useDebouncedFocusEffect = (debouncedEffect: () => void, delay: number) => {
  const timeoutRef = useRef<Nullable<number>>();

  useFocusEffect(
    useCallback(() => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      timeoutRef.current = window.setTimeout(debouncedEffect, delay);

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, [debouncedEffect, delay])
  );
};

export default useDebouncedFocusEffect;
