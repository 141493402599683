import { Image, ImageBackground, ImageBackgroundProps, ImageStyle } from 'expo-image';
import { StyleProp } from 'react-native';

import { YStack, createMqStyles, useMqSelect } from '@fhs/ui';

import { CtaButton } from '../shared/cta-button';

export type MontrealSmokedMeatCardProps = Omit<ImageBackgroundProps, 'children' | 'source'> & {
  pickUpHref: string;
};
export function MontrealSmokedMeatCard(props: MontrealSmokedMeatCardProps) {
  const mqStyles = useMqStyles();

  return (
    <ImageBackground
      source={useMqSelect(
        {
          $gteDesktopLg: require('./montreal-smoked-meat-background.webp'),
        },
        require('./montreal-smoked-meat-background-tablet.webp')
      )}
      placeholder={{ thumbhash: 'LzkKG4SNdpdvhnmGgHIFCGc' }}
      {...props}
      style={[mqStyles.banner, props.style]}
    >
      <YStack style={mqStyles.contentContainer}>
        <Image
          source={require('./montreal-smoked-meat-caption.webp')}
          alt="New Montreal Smoked Meat, Extra Meat!"
          placeholder={{ thumbhash: 'RjiGDIQUu3i7is+6uZv6q6n/mLaYOam2Bw' }}
          style={mqStyles.caption as StyleProp<ImageStyle>}
          contentFit="contain"
        />
        <CtaButton href={props.pickUpHref} style={mqStyles.cta} />
      </YStack>
    </ImageBackground>
  );
}

const useMqStyles = createMqStyles({
  banner: {
    $base: {
      aspectRatio: 1024 / 505,
    },
    $gteDesktopLg: {
      aspectRatio: 1920 / 757,
    },
  },
  contentContainer: {
    $base: {
      position: 'absolute',
      left: '10%',
      gap: 30,
    },
    $ltDesktopLg: {
      top: '18%',
      width: '33%',
    },
    $gteDesktopLg: {
      top: '10%',
      width: '30%',
    },
  },

  caption: {
    $base: {
      aspectRatio: 581 / 444,
    },
  },

  cta: {
    $base: {
      marginHorizontal: 'auto',
    },
  },
});
