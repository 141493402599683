import * as React from 'react';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import Dialog from 'components/dialog';

import { IDonationsModalProps } from './types';

/**
 *
 * DonationsModal purpose: to display a modal with
 * more information regarding Donations.
 *
 */
const DonationsModal: FC<React.PropsWithChildren<IDonationsModalProps>> = ({
  isOpen,
  onCloseCallback,
  title,
  modalAppearanceEventMessage,
  children,
  testID,
  buttonText,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog
      showDialog={isOpen}
      testID={testID}
      heading={title}
      bodyComponent={children}
      actions={
        <ActionButton fullWidth onPress={onCloseCallback} mt={4}>
          {buttonText ??
            formatMessage({
              id: 'okayButtonText',
              description: '',
            })}
        </ActionButton>
      }
      modalAppearanceEventMessage={modalAppearanceEventMessage}
      onDismiss={onCloseCallback}
    />
  );
};

export default DonationsModal;
