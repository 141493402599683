import { Stack } from 'expo-router';
import React from 'react';

import { LoyaltyClaimPointsProvider } from '@fhs-legacy/frontend/src/state/loyalty-claim-points';

import { getScreenTitleHeader } from '../../components/get-screen-title-header';

const ClaimPointsLayout = () => (
  <LoyaltyClaimPointsProvider>
    <Stack
      screenOptions={{
        title: 'Claim Points',
        header: getScreenTitleHeader({ backStyle: 'back', testID: 'cart-back-button' }),
      }}
    />
  </LoyaltyClaimPointsProvider>
);

export default ClaimPointsLayout;
