import { useLoyaltyOffersCooldownCountdown } from 'hooks/use-loyalty-offers-cooldown-countdown';

export function areLoyaltyOffersInCooldown(cooldownSeconds: number) {
  return cooldownSeconds > 0;
}

export function useAreLoyaltyOffersInCooldown(checkInterval?: number) {
  const seconds = useLoyaltyOffersCooldownCountdown(checkInterval);

  return areLoyaltyOffersInCooldown(seconds);
}
