import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, Header, Icon, Skeleton, Text, VStack } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import _QRCode from 'components/qrcode';
import { useShortCodeRefresh } from 'hooks/loyalty/use-short-code-refresh';
import useMaximumScreenBrightness from 'hooks/use-maximum-screen-brightness';
import { useAuthContext } from 'state/auth';
import { useLocale } from 'state/intl';
import {
  ShortCodeState,
  useInRestaurantRedemptionContext,
} from 'state/loyalty/in-restaurant-redemption';
import { parsePhoneNumber } from 'utils/libphonenumber';

const BARCODE_OPTIONS = {
  margin: 1,
  scale: 11,
  color: {
    dark: Styles.color.black,
    light: Styles.color.white,
  },
};

const QrCode = () => {
  const { formatMessage } = useIntl();

  const { region } = useLocale();
  const auth = useAuthContext();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const parseAndSetPhoneNumber = useCallback(async () => {
    if (auth.user?.details.phoneNumber) {
      const userPhoneNumber = await parsePhoneNumber(auth.user?.details.phoneNumber, region);
      setPhoneNumber(userPhoneNumber.formatNational());
    }
  }, [auth.user?.details.phoneNumber, region]);
  useEffect(() => {
    parseAndSetPhoneNumber();
  }, [parseAndSetPhoneNumber]);

  const { shortCodeState, shortCodeLoading, shortCode, generateShortCode, staticIdentifier } =
    useInRestaurantRedemptionContext();
  const barCodeData = staticIdentifier || shortCode || '';

  useShortCodeRefresh();
  useMaximumScreenBrightness();

  if (shortCodeState === ShortCodeState.Expired) {
    return (
      <VStack mt={4} alignItems="center">
        <Header variant="headerThree">{formatMessage({ id: 'loyaltyCodeExpired' })}</Header>
        <Text fontSize="md" mb={4}>
          {formatMessage({ id: 'loyaltyCodeExpiredDetails' })}
        </Text>
        <ActionButton
          isLoading={shortCodeLoading}
          disabled={shortCodeLoading}
          onPress={generateShortCode}
          startIcon={<Icon variant="refresh" />}
        >
          {formatMessage({ id: 'generateNewCode' })}
        </ActionButton>
      </VStack>
    );
  }

  return (
    <Box>
      <Box
        borderWidth={1}
        borderColor="blackOpacity.10"
        background="white"
        borderRadius={8}
        overflow="hidden"
      >
        <Box py={4} borderBottomWidth={1} borderColor="blackOpacity.10" alignItems="center">
          <Skeleton isLoaded={!!barCodeData} size={40} borderRadius="lg">
            <_QRCode barcode={barCodeData} options={BARCODE_OPTIONS} />
          </Skeleton>
        </Box>
        <Box py={4} backgroundColor="blackOpacity.5">
          <VStack alignItems="center">
            <Skeleton isLoaded={!!phoneNumber} height={5} width={40} mt={2}>
              <Text fontSize="xl" bold mt={2}>
                {phoneNumber}
              </Text>
            </Skeleton>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};

export default QrCode;
