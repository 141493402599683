import React from 'react';

import { Svg, SvgPath, SvgProps } from '@fhs-legacy/universal-components';

export const FhsHeartSvg = (props: SvgProps) => (
  <Svg {...props} viewBox="0 0 88 78" width={88} height={78}>
    <SvgPath
      d="M10.9513 38.1579C7.42693 34.6379 5.25008 29.7714 5.25008 24.3965C5.25008 13.6385 13.9712 4.91736 24.7292 4.91736C31.9698 4.91736 38.2877 8.86783 41.6449 14.731H46.7719C50.1292 8.86783 56.4468 4.91736 63.6876 4.91736C74.4456 4.91736 83.1667 13.6385 83.1667 24.3965C83.1667 29.7714 80.9896 34.6379 77.4655 38.1579L44.2084 71.4151L10.9513 38.1579ZM80.7023 41.4112C85.0569 37.0566 87.7501 31.0409 87.7501 24.3965C87.7501 11.1072 76.9769 0.333984 63.6876 0.333984C55.6777 0.333984 48.5823 4.24751 44.2084 10.2668C39.8345 4.24751 32.739 0.333984 24.7292 0.333984C11.4399 0.333984 0.666748 11.1072 0.666748 24.3965C0.666748 31.0409 3.36005 37.0566 7.71449 41.4112L44.2084 77.8991L80.7023 41.4112Z"
      fill="#000"
    />
  </Svg>
);
