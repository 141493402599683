import { Box, InlineAlert, Text } from '@fhs-legacy/universal-components';

export const IncentiveEvaluationMessagesContainer = Box;

export const IncentiveEvaluationMessagesText = Text.withConfig({
  fontSize: 'xs',
  color: Styles.color.red,
});

export const SingleMessage = InlineAlert.withConfig<{ $fitContentWidth: boolean }>(p => ({
  marginBottom: '$2',
  ...(p.$fitContentWidth && { alignSelf: 'flex-start' }),
}));
