import { router } from 'expo-router';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { PaymentMethodAddNewItemOption } from 'components/payment-method-add-new-item-option';
import PaymentMethodOption from 'components/payment-method-option';
import PrepaidCardIcon from 'components/payment-method-option/prepaid-card-icon';
import { usePathname } from 'hooks/use-pathname';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { relativeRoutes } from 'utils/routing';
import { ADD_NEW_CARD_BUTTON } from 'utils/test-ids';

import { StyledAddNewCreditCardIcon } from '../styled';

import { PaymentMethodOptionItemDivider, StyledOption } from './styled';
import { PaymentMethodOptionProps } from './types';

function PaymentMethodOptions({
  collapsed,
  setCollapsed,
  unSelectedMethods,
  handleSelect,
  setIsAddNewCardOptionSelected,
  setIsAddNewGiftCardOptionSelected,
  showAddNewGiftCardOption,
  showAddNewCardOption,
}: PaymentMethodOptionProps) {
  const { formatMessage } = useIntl();
  const pathname = usePathname();
  const enableGiftCard = useFlag(LaunchDarklyFlag.ENABLE_GIFT_CARD);

  const handleUnselectedMethodPress = useCallback(
    (newfdAccountId: any) => {
      setIsAddNewCardOptionSelected(false);
      setIsAddNewGiftCardOptionSelected(false);
      handleSelect(newfdAccountId);
      setCollapsed(true);
    },
    [handleSelect, setCollapsed, setIsAddNewGiftCardOptionSelected, setIsAddNewCardOptionSelected]
  );

  const handleAddNewCardOptionPress = useCallback(() => {
    setIsAddNewCardOptionSelected(true);
    setIsAddNewGiftCardOptionSelected(false);
    handleSelect();
    setCollapsed(true);
  }, [
    handleSelect,
    setCollapsed,
    setIsAddNewGiftCardOptionSelected,
    setIsAddNewCardOptionSelected,
  ]);

  const handleAddNewGiftCardOptionPress = useCallback(() => {
    setIsAddNewGiftCardOptionSelected(true);
    setIsAddNewCardOptionSelected(false);
    handleSelect();
    setCollapsed(true);
    router.navigate(`${pathname}/${relativeRoutes.addGiftCard}`);
  }, [
    setIsAddNewGiftCardOptionSelected,
    setIsAddNewCardOptionSelected,
    handleSelect,
    setCollapsed,
    pathname,
  ]);

  if (collapsed) {
    return null;
  }

  return (
    <>
      {unSelectedMethods.map(method => (
        <StyledOption key={method.accountIdentifier}>
          <PaymentMethodOptionItemDivider />
          <PaymentMethodOption
            method={method}
            onPress={handleUnselectedMethodPress}
            disableMethod={!!method.prepaid && !enableGiftCard}
            hideBorder
          />
        </StyledOption>
      ))}

      {showAddNewCardOption && (
        <StyledOption>
          <PaymentMethodOptionItemDivider />
          <PaymentMethodAddNewItemOption
            testID={ADD_NEW_CARD_BUTTON}
            text={formatMessage({ id: 'addNewCard' })}
            Icon={<StyledAddNewCreditCardIcon />}
            onPress={handleAddNewCardOptionPress}
          />
        </StyledOption>
      )}

      {showAddNewGiftCardOption && (
        <StyledOption>
          <PaymentMethodOptionItemDivider />
          <PaymentMethodAddNewItemOption
            testID="add-gift-card"
            text={formatMessage({ id: 'addPrepaidCard' })}
            Icon={<PrepaidCardIcon />}
            onPress={handleAddNewGiftCardOptionPress}
          />
        </StyledOption>
      )}
    </>
  );
}

export default PaymentMethodOptions;
