import React, { VFC } from 'react';

import { ListItemGroup } from 'components/list-item-group';

import { Modifier } from '../modifier';

import { SHOW_LIST_ITEM_GROUP_HEADER } from './constants';
import { IModifierGroupProps } from './types';

export const ModifierGroupBase: VFC<IModifierGroupProps> = ({
  displayGroupName,
  modifiers,
  onModifierSelectionChange,
  selectedItem,
}) => {
  const listItemHeader = SHOW_LIST_ITEM_GROUP_HEADER ? displayGroupName : undefined;

  return (
    <ListItemGroup
      header={listItemHeader}
      items={modifiers.map(modifierProps => (
        <Modifier
          key={`modifier-${modifierProps.modifier.name?.locale}`}
          {...modifierProps}
          onSelectionsChange={selections =>
            onModifierSelectionChange(selections, modifierProps.modifier)
          }
          selectedItem={selectedItem}
        />
      ))}
      isNested
    />
  );
};
