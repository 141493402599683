import { useLocalSearchParams } from 'expo-router';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

import { ErrorCard, tokens } from '@fhs/ui';

import { useOfferDetailUi } from '../../queries/loyalty.queries';
import { useIsItemInCart, useOfferCart } from '../../state/offer-guide-cart-state';

import { Content } from './content';
import { ItemInCart } from './item-in-cart';
import { Summary } from './summary';

export function ContentRenderer() {
  const { offerId } = useLocalSearchParams<{ offerId: string }>();
  const { isLoading, isError, refetch } = useOfferDetailUi(offerId);

  const { offerInfo, showSummary } = useOfferCart();

  const isItemInCart = useIsItemInCart();

  if (isLoading) {
    return (
      <View style={styles.loading}>
        <ActivityIndicator size="large" color={tokens.colors.$houseRedDarken} />
      </View>
    );
  }

  if (isError) {
    return (
      <ErrorCard
        description="We ran into an issue loading the content of this page. Please try again."
        buttonTitle="Reload"
        buttonHandle={refetch}
      />
    );
  }

  if (!offerInfo?.isAvailable) {
    return (
      <ErrorCard
        hideButton
        title="Not Available"
        description="This offer is no longer available. Please select a different offer."
      />
    );
  }

  if (isItemInCart) {
    return <ItemInCart />;
  }

  if (showSummary) {
    return <Summary />;
  }

  return <Content />;
}

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: tokens.colors.$white,
  },
});
