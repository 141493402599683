import { FormControl as NbFormControl } from '@fhs-legacy/native-base';

import { addWithConfig } from '../../utils';

import type { _FormControlComponentType } from './types';

export const FormControl: _FormControlComponentType = NbFormControl;
FormControl.ErrorMessage = NbFormControl.ErrorMessage;
FormControl.HelperText = NbFormControl.HelperText;
FormControl.Label = NbFormControl.Label;

const FormControlWithConfig = addWithConfig(FormControl);
FormControl.ErrorMessage = addWithConfig(FormControl.ErrorMessage).withConfig({
  _stack: { flexGrow: 1, flexDirection: 'column', alignItems: 'flex-start' },
});
FormControl.HelperText = addWithConfig(FormControl.HelperText);
FormControl.Label = addWithConfig(FormControl.Label);

export default FormControlWithConfig;
