import { Box, Button, Header, Pressable, ScrollView, Text } from '@fhs-legacy/universal-components';
import Picture from 'components/picture';

export const UPSIZE_ITEM_WIDTH = 150;
export const UPSIZE_ITEM_SLIDER_WIDTH = 350;

export const UpsizeHeader = Box.withConfig({
  alignItems: 'center',
  marginTop: '$0',
  marginRight: '$0',
  marginBottom: '$2',
});

export const SVGHeader = Box.withConfig({
  marginTop: '$0',
  marginRight: '$0',
  marginBottom: '$0',
  marginLeft: '$0',
});

export const Container = ScrollView.withConfig({
  paddingTop: '$0',
  paddingRight: '$3',
  paddingBottom: '$4',
  paddingLeft: '$3',
  width: 'full',
  _contentContainerStyle: {
    alignItems: 'center',
  },
});

export const ItemImage = Picture.withConfig({
  width: '125px',
  height: '125px',
});

export const MainTitle = Header.withConfig({
  variant: 'hero',
  fontSize: '4xl',
  margin: '$0',
  textAlign: 'center',
});

export const SubTitle = Text.withConfig({
  textAlign: 'center',
  fontSize: 'md',
  marginY: '$3',
  marginX: '$4',
  fontFamily: 'body',
});

export const NoThanks = Button.withConfig({
  background: 'transparent',
  borderRadius: '0',
  color: 'red',
  textDecoration: 'none',
  width: 'full',
  size: 'lg',
  variant: 'solid-reversed',
  _text: {
    fontFamily: 'body',
    fontSize: 'md',
  },
});

// Replace with PRIMARY variant of ActionButton once it becomes available
// no idea why the 50% stack width works here
export const UpsizeButton = Button.withConfig({
  variant: 'solid',
  backgroundColor: Styles.color.black,
  marginTop: '$0',
  marginX: 'auto',
  size: 'lg',
  width: 'full',
  marginBottom: '$2',
  _text: {
    fontSize: 'xl',
  },
});

export const UpsizeItems = Box.withConfig({
  marginTop: '$0',
  marginLeft: '$0',
  marginBottom: '$10',
  marginRight: '$0',
  width: 'full',
  borderRadius: Styles.borderRadius,
  alignItems: 'center',
});

export const SingleItemContent = Box.withConfig({
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
});

export const SingleItemParagraph = Text.withConfig({
  fontSize: 'sm',
  margin: '$0',
  textAlign: 'center',
});

export const SingleItemHeader = Header.withConfig({
  variant: 'headerThree',
  accessibilityLevel: 3,
  margin: '$0',
  textAlign: 'center',
});

// original colour was rgba(0, 0, 0, 0.08)
export const SingleItemWrapper = Pressable.withConfig<{ $selected: boolean; $isFirst: boolean }>(
  props => ({
    borderRadius: Styles.borderRadius,
    shadowColor: Styles.shadowColor,
    shadowOffset: {
      width: 0,
      height: 0.5,
    },
    shadowRadius: 2,
    shadowOpacity: props.$selected ? 0 : 1,
    backgroundColor: props.$selected ? 'rgba(255, 135, 50, 0.1)' : Styles.color.cardBackground,
    borderStyle: 'solid',
    borderWidth: props.$selected ? '0.5' : '0',
    borderColor: Styles.color.alert.info.background,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '$4',
    flexGrow: '1',
    marginLeft: props.$isFirst ? 0 : '$4',
  })
);
