import { IPickerAspectUiPattern } from '@rbi-ctg/menu';

export enum PickerAspectUiPattern {
  SELECTOR = 'selector',
  RADIO = 'radio',
}

export const isUiPatternRadio = (p: IPickerAspectUiPattern): boolean =>
  p.uiPattern === PickerAspectUiPattern.RADIO;

export enum SectionUiPattern {
  STANDARD = 'standard',
  STACKED = 'stacked',
  INLINE_TILE = 'inlineTile',
}
