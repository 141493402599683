import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconHatFill = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.067 13.501c3.288.85 5.252 2.308 5.373 3.298l.003.002c.038.186.068.366.01.539a.528.528 0 0 1-.218.28.807.807 0 0 1-.222.105c-.103.03-.205.04-.304.04a2.223 2.223 0 0 1-.64-.113 7.022 7.022 0 0 1-.706-.27c-.3-.13-1.78-.535-3.745-.422-1.435.078-3.286.255-5.667.595-2.217.317-3.979.445-5.38.445-4.24 0-5.184-1.173-5.484-1.852-.398-.903 1.567-1.698 1.567-1.698l1.412-.471c.032-.65.145-1.684.49-3.06.465-1.86.925-2.925 1.126-3.342a.497.497 0 0 1 .454-.297c.166 0 .306.086.306.086l1.065.935a6.49 6.49 0 0 1 3.933-1.29h.137c4.25.079 5.604 2.948 6.082 4.568 0 0 .24 1.052.408 1.922ZM6.24 8.22c-.217.505-.572 1.444-.933 2.887l.002-.003c-.418 1.67-.478 2.808-.478 3.31a.66.66 0 0 0 .086.172c.281.207.788.477 1.5.477.064 0 .129-.003.194-.007.234-.365.311-.798.41-1.36l.003-.011.001-.008c.122-.687.26-1.464.738-2.311.523-.93.208-1.552.098-1.722L6.24 8.22Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
