import * as React from 'react';
import { FC } from 'react';

import { ILinkWidgetFragment } from 'generated/sanity-graphql';

import { StyledButton, StyledWrapper } from './loyalty-button-widget.styled';

export interface ILoyaltyButtonWidgetProps {
  link: ILinkWidgetFragment;
}

const LoyaltyButtonWidget: FC<React.PropsWithChildren<ILoyaltyButtonWidgetProps>> = ({
  link: linkProp,
}) => {
  const { text, link } = linkProp;

  return (
    <StyledWrapper>
      <StyledButton testID="button-widget" to={link?.locale || ''}>
        {text?.locale}
      </StyledButton>
    </StyledWrapper>
  );
};

export default LoyaltyButtonWidget;
