import { router } from 'expo-router';

import { IBackendCartEntries, ICartEntry } from '@rbi-ctg/menu';
import { ICartEntryFragment } from 'generated/rbi-graphql';
import { usePathname } from 'hooks/use-pathname';
import { useSwitchCartMethodDialog } from 'hooks/use-switch-cart-method-dialog';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

interface UseAddToCartProps {
  cartEntry: ICartEntry | IBackendCartEntries | ICartEntryFragment | null;
}

const useAddToCartRecentItem = ({ cartEntry }: UseAddToCartProps) => {
  const pathname = usePathname();
  const {
    addItemToCart,
    recent: {
      setPendingRecentItem,
      pendingRecentItemNeedsReprice,
      setPendingRecentItemNeedsReprice,
    },
    repriceCartEntries,
  } = useOrderContext();
  const { isStoreAvailable } = useStoreContext();
  const { SwitchCartMethodDialog, openSwitchCartMethod, shouldShowSwitchToMobileDialog } =
    useSwitchCartMethodDialog();

  const handleAddToCart = () => {
    if (isStoreAvailable) {
      // If you had no store selected previously the prices on the cartEntry are zero
      // This will repair the prices before adding to cart
      if (pendingRecentItemNeedsReprice) {
        const [repricedEntry] = repriceCartEntries([cartEntry]);
        addItemToCart(repricedEntry);
        setPendingRecentItemNeedsReprice(false);
      } else {
        addItemToCart(cartEntry);
      }
    } else {
      setPendingRecentItem(cartEntry);
      setPendingRecentItemNeedsReprice(true);
      router.navigate({ pathname: routes.storeLocator, params: { back: pathname } });
    }
  };

  const addToCart = () => {
    if (shouldShowSwitchToMobileDialog) {
      openSwitchCartMethod({ onConfirm: handleAddToCart });
    } else {
      handleAddToCart();
    }
  };

  return {
    addToCart,
    SwitchCartMethodDialog,
  };
};

export default useAddToCartRecentItem;
