import React from 'react';

import { HStack, Skeleton } from '@fhs-legacy/universal-components';

// import theme from './theme';

const Container = HStack.withConfig({
  space: 4,
});

const CardSkeleton = Skeleton.withConfig({
  // width: theme.cardWidth,
  height: 32,
  borderRadius: 4,
});

export const UpsellSkeleton = () => {
  return (
    <Container testID="upsell-skeleton-loader">
      <CardSkeleton />
      <CardSkeleton />
    </Container>
  );
};
