export interface PluginListenerHandle {
  remove: () => void;
}

declare var window: any;

export interface INetworkStatus {
  connected: boolean;
  connectionType: 'wifi' | 'cellular' | 'none' | 'unknown';
}

export type NetworkStatusChangeCallback = (status: INetworkStatus) => void;

export interface NetworkPlugin {
  /**
   * Query the current network status
   */
  getStatus(): Promise<INetworkStatus>;

  /**
   * Listen for network status change events
   */
  addListener(listenerFunc: (status: INetworkStatus) => void): PluginListenerHandle;
}

export interface PluginListenerHandle {
  remove: () => void;
}

export class NetworkPluginWeb implements NetworkPlugin {
  getStatus(): Promise<INetworkStatus> {
    return new Promise((resolve, reject) => {
      if (!window.navigator) {
        reject('Network info not available');
        return;
      }

      const connected = window.navigator.onLine;
      const connection =
        window.navigator.connection ||
        window.navigator.mozConnection ||
        window.navigator.webkitConnection;
      const connectionType = connection ? connection.type || connection.effectiveType : 'wifi';

      resolve({
        connected,
        connectionType: connected ? connectionType : 'none',
      });
    });
  }

  addListener(listenerFunc: (status: INetworkStatus) => void): PluginListenerHandle {
    const getStatusWrap = () => {
      return this.getStatus().then(networkStatus => {
        listenerFunc(networkStatus);
      });
    };
    window.addEventListener('online', getStatusWrap);
    window.addEventListener('offline', getStatusWrap);

    getStatusWrap();
    return {
      remove: () => {
        window.removeEventListener('online', getStatusWrap);
        window.removeEventListener('offline', getStatusWrap);
      },
    };
  }
}

const Network = new NetworkPluginWeb();

export { Network };
