import useAuth from 'hooks/auth/use-auth';
import useUnauthRedirects from 'hooks/auth/use-unauth-redirects';

const UnauthRequired = ({ children }) => {
  const { isAuthenticated } = useAuth();

  useUnauthRedirects();

  return !isAuthenticated && children;
};

export default UnauthRequired;
