import { ICombo, IComboSlot, IItem, MenuObject } from '@rbi-ctg/menu';
import { ProductProps } from 'components/product/types';
import {
  getComboSlotOptionFromItem,
  transformItemAndVirtualItemToProductListItemProps,
  transformItemToProductListItemProps,
} from 'components/product-detail/utils';
import { IProductListItemProps } from 'components/product-list-item/types';
import { computeComboSlotCalories } from 'hooks/menu/utils';
import { ENABLE_MAIN_ITEM_DETAILED_CUSTOMIZATION } from 'state/product-wizard/constants';
import { ProductWizardUtils } from 'state/product-wizard/utils';

import { TransformUserSelectionsToSummaryItemsFn } from './types';

const transformComboSlotToProductListItem = (
  item: IComboSlot,
  product?: MenuObject,
  selections?: ProductProps['selections']
): IProductListItemProps => {
  const calories = String(computeComboSlotCalories(item));

  // use image for first comboSlot option if available, otherwise use main product image
  const image = JSON.stringify(
    item.options[0].option.image ? item.options[0].option.image : product?.image
  );

  return {
    productProps: {
      name: item.name.locale,
      calories,
      image,
      isProductNameBold: true,
      selections,
    },
  };
};

export const transformUserSelectionsToSummaryItems: TransformUserSelectionsToSummaryItemsFn = ({
  buildProductPropsSelections,
  priceForComboSlotSelection,
  selectedProduct,
  userSelections,
}) => {
  let price = 0;
  const comboSlotEntries = userSelections.comboSlot?.entries() || [];
  return Array.from(comboSlotEntries).map(([slotKey, { selectedItem, comboSlot }]) => {
    const modifierSelections = userSelections.modifiers[slotKey];
    const selections = buildProductPropsSelections(modifierSelections, selectedItem);

    if (!selectedItem && comboSlot) {
      return {
        productListItemProps: transformComboSlotToProductListItem(
          comboSlot,
          selectedProduct,
          selections
        ),
        slotKey,
      };
    }

    if (comboSlot) {
      const comboSlotOption = getComboSlotOptionFromItem(comboSlot, selectedItem as IItem);
      price = comboSlotOption?.isPremium
        ? priceForComboSlotSelection({
            combo: selectedProduct as ICombo,
            comboSlot,
            selectedOption: comboSlotOption,
          })
        : 0;
    }

    const isCustomizableItem = !!selectedItem && ProductWizardUtils.IsCustomizable(selectedItem);
    const isCustomizableComboSlot = !!comboSlot && ProductWizardUtils.IsCustomizable(comboSlot);

    const productListItemProps = ENABLE_MAIN_ITEM_DETAILED_CUSTOMIZATION
      ? transformItemAndVirtualItemToProductListItemProps(selectedItem as IItem, {
          productNote: comboSlot?.name.locale,
          isExpandable: isCustomizableItem || isCustomizableComboSlot,
          selections,
          modifierSelections,
          price,
        })
      : transformItemToProductListItemProps(selectedItem as IItem, {
          productNote: comboSlot?.name.locale,
          isExpandable: isCustomizableItem || isCustomizableComboSlot,
          selections,
          price,
        });
    return {
      productListItemProps,
      slotKey,
    };
  });
};
