import { useMemo } from 'react';

import { useLoyaltyIncentivesAvailability } from 'pages/loyalty/loyalty-incentives-components/use-loyalty-incentives-availability';
import { selectors, useAppSelector } from 'state/global-state';
import { LoyaltyOffer } from 'state/loyalty/types';
import { getCmsOffersMapByLoyaltyId } from 'state/loyalty/utils';

export const useCartHasUnavailableOffers = () => {
  const { checkLoyaltyOfferAvailability } = useLoyaltyIncentivesAvailability();
  const cmsOffers = useAppSelector(selectors.loyalty.selectCmsOffers);
  const cmsOffersMap = getCmsOffersMapByLoyaltyId(cmsOffers);
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const cartHasUnavailableOffers = useMemo(() => {
    return appliedOffers
      .filter(loyaltyOffer => !!loyaltyOffer.cartId)
      .some(loyaltyOffer => {
        const loyaltyOfferId = loyaltyOffer?.id || '';
        const cartOffer: LoyaltyOffer = cmsOffersMap[loyaltyOfferId];
        const isCartOfferAvailable = !cartOffer || checkLoyaltyOfferAvailability(cartOffer);
        return !isCartOfferAvailable;
      });
  }, [appliedOffers, checkLoyaltyOfferAvailability, cmsOffersMap]);
  return cartHasUnavailableOffers;
};
