import { TouchableWithoutFeedback, type TouchableWithoutFeedbackProps } from 'react-native';

import { useCheckbox } from './context';

export type CheckboxTouchableWithoutFeedbackProps = TouchableWithoutFeedbackProps;

export function CheckboxTouchableWithoutFeedback(
  touchableProps: CheckboxTouchableWithoutFeedbackProps
) {
  const checkbox = useCheckbox();
  return (
    <TouchableWithoutFeedback
      // Accessible set to false because the control should be the accessible element, this would be redundant
      accessible={false}
      aria-hidden
      // @ts-ignore RN does not like that we are making the touchable non-focusable
      focusable={false}
      {...touchableProps}
      onPress={checkbox.handlePress}
    />
  );
}
