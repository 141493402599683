import React from 'react';

import { ICountrySelectBaseProps } from 'components/country-select/types';
import { ISOs } from 'utils/form/constants';

import CountrySelectBase from './country-select-base';

const CountrySelect = ({ ...props }: ICountrySelectBaseProps) => (
  <CountrySelectBase {...props} countries={[ISOs.USA, ISOs.CAN]} />
);

export default CountrySelect;
