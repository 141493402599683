import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { OTPAuthDeliveryMethod, isOTPEmailEnabled, isOTPEnabled, isOTPSMSEnabled } from 'utils/otp';

/**
 * Centralizes the calculation of which "One Time Password" method we are using
 */
export const useOtpFeature = () => {
  const enableOtpFlagValue = useFlag(
    LaunchDarklyFlag.ENABLE_ONE_TIME_PASSWORD
  ) as OTPAuthDeliveryMethod;
  const smsOtpEnabled = isOTPSMSEnabled(enableOtpFlagValue);
  const emailOtpEnabled = isOTPEmailEnabled(enableOtpFlagValue);

  return {
    enableOtpFlagValue: enableOtpFlagValue ?? OTPAuthDeliveryMethod.None,
    jwtEmailEnabled: !isOTPEnabled(enableOtpFlagValue),
    smsOtpEnabled,
    emailOtpEnabled,
  };
};
