import React from 'react';

import { HStack, Icon, Text } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

interface StoreCardErrorProps {
  message: string;
}

export const StoreCardError: React.FC<StoreCardErrorProps> = ({ message }) => {
  return (
    <HStack alignItems="center" mt="$1" space={1}>
      <Icon
        size="xs"
        variant="error"
        color={theme.token('text-error')}
        {...hiddenAccessibilityPlatformProps}
      />
      <Text variant="formUtility" color={theme.token('text-error')}>
        {message}
      </Text>
    </HStack>
  );
};
