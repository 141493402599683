import React from 'react';

import { Box } from '@fhs-legacy/universal-components';

import { ListItem } from './styled';

const serializers = {
  list: ({ type, children }: { type: string; children: JSX.Element[] }) => {
    const isBulletList = type === 'bullet';
    return (
      <Box>
        {children.map((listItem, index) => {
          const listIndicator = isBulletList ? '\u2022' : `${++index}.`;
          return (
            <ListItem key={index}>
              {listIndicator} {listItem}
            </ListItem>
          );
        })}
      </Box>
    );
  },
  listItem: ({ children }: { children: JSX.Element }) => <ListItem>{children}</ListItem>,
};

export default serializers;
