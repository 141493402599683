/* eslint-disable complexity */
import { memo } from 'react';

import createIconSetFromIcoMoon from '@expo/vector-icons/createIconSetFromIcoMoon';
import { Icon as NbIcon, usePropsResolution } from '@fhs-legacy/native-base';

import { addWithConfig } from '../../utils';
import { svgLibrary } from '../svg';

import { svgAsExclusions } from './icons';
import type { IconProps, _IconPrefixes } from './types';

const IconSets = {
  mi: createIconSetFromIcoMoon(
    require('./material-icons-selection.json'),
    'MaterialIcons',
    'material-icons.ttf'
  ),
  custom: svgLibrary,
};

/**
 * ## Icon
 *
 *
 *
 * Renders an icon from one of the following sources:
 * - MaterialIcons (icon prefix `mi`)
 * - Ucl Svgs (icon prefix `custom`)
 *
 * The `variant` prop is the preferred method for accessing icons.
 * If you wish to use an icon directly from the underlying libraries, use
 * the `icon` prop, and reference the prefix list above.
 *
 * The usable icons are limited to what is defined internally.
 * If an required icon is missing from the set, it will need to be added
 * to the UCL.
 *
 * If you wish to pass a custom Svg, pass it as a children.
 */

// @ts-ignore
export const Icon = memo((props: IconProps) => {
  const resolvedProps = usePropsResolution('Icon', props);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { name, fontFamily: _fontFamily, ...filteredResolveProps } = resolvedProps;

  if (!name) {
    // @ts-ignore
    return <NbIcon {...filteredResolveProps} />;
  }

  const [iconPrefix, iconName] = name.split(':') as [_IconPrefixes, string];

  const As = iconPrefix === 'custom' ? IconSets.custom[iconName] : IconSets[iconPrefix];

  if (!As) {
    // eslint-disable-next-line no-console
    console.error(
      `Cannot find icon "${name}" in svgLibrary. You may need to import the icon as a module.`
    );
    return null;
  }

  if (iconPrefix === 'custom' && !svgAsExclusions.includes(name as any)) {
    return (
      <NbIcon {...filteredResolveProps} title={iconName}>
        <As color={filteredResolveProps.color} />
      </NbIcon>
    );
  }

  return <NbIcon {...filteredResolveProps} as={As} name={iconName} title={iconName} />;
});

export default addWithConfig(Icon);
