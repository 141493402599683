import { Box, HStack, Header as SectionHeader, Text } from '@fhs-legacy/universal-components';
import Link from 'components/link';

export const Wrapper = Box.withConfig(() => ({
  borderRadius: 16,
  flex: 1,
}));

export const ImageLink = Link.withConfig(() => ({
  width: 'full',
}));

export const TextWrapper = Box.withConfig(() => ({
  justifyContent: 'space-between',
  flex: 1,
  width: 'full',
  padding: {
    base: 4,
    legacy_sm: 5,
  },
}));

export const ButtonWrapper = HStack.withConfig(() => ({
  alignSelf: {
    base: 'flex-start',
    legacy_sm: 'flex-end',
  },
  marginTop: 4,
}));

export const Header = SectionHeader.withConfig(() => ({
  variant: 'headerTwo',
  marginBottom: 2,
}));

export const Description = Text.withConfig(() => ({
  variant: 'copyTwo',
}));

export const Terms = Link.withConfig(() => ({
  isUnderlined: true,
  isInline: true,
}));
