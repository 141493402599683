export const messageIdMappingForDialogs = {
  paymentNotSupported: {
    title: 'paymentNotSupportedHeading',
    body: 'paymentNotSupported',
    message: 'paymentNotSupported',
  },
  paymentDeclined: {
    title: 'paymentDeclinedHeading',
    body: 'paymentDeclined',
    message: 'paymentDeclined',
  },
  paymentDetailsError: {
    title: 'paymentDetailsErrorHeading',
    body: 'paymentDetailsError',
    message: 'paymentDetailsError',
  },
  validationError: {
    title: 'validationErrorHeading',
    body: 'validationError',
    message: 'validationError',
  },
  duplicateSupportFormSubmission: {
    title: 'duplicateSupportFormSubmission',
    body: 'duplicateSupportFormSubmission',
    message: 'duplicateSupportFormSubmission',
  },
  coolingPeriodError: {
    title: 'errorDialogCooldownTitle',
    body: 'errorDialogCooldownMessage',
    message: 'errorDialogCooldownMessage',
  },
  giftCardUnavailableAtLocation: {
    title: 'giftCardUnavailableAtLocationErrorHeading',
    body: 'giftCardUnavailableAtLocationError',
    message: 'giftCardUnavailableAtLocationError',
  },
};
