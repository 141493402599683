import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconCheckFill = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M12 2.1c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10Zm-2 15-5-5 1.41-1.41L10 14.27l7.59-7.59L19 8.1l-9 9Z"
      />
    </Svg>
  );
};
