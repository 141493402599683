import React, { useMemo } from 'react';

import { IItemOption, IItemOptionModifier } from '@rbi-ctg/menu';
import { ProductListItem } from 'components/product-list-item';
import { FallbackImageSvg } from 'components/svgs/fallback-image';
import { MenuObjectTypes } from 'enums/menu';
import { useMenuContext } from 'state/menu';

import { IModifierListItemProps } from './types';
import { getModifierImage, getModifierOptionImage } from './utils';

export const ModifierListItem = ({
  controls,
  content,
  isListItemClickable = false,
  onListItemClick,
  options,
  selectedOption,
  selectedItem,
}: IModifierListItemProps) => {
  const image = useMemo(
    () =>
      content._type === MenuObjectTypes.ITEM_OPTION
        ? getModifierImage(content)
        : getModifierOptionImage(content),
    [content]
  );

  const caloriesText = useMemo(() => {
    const calorieEntries =
      content._type !== MenuObjectTypes.ITEM_OPTION ? [content] : options ?? [];
    const { min, max } = calorieEntries.reduce(
      (acc, option) => {
        const calories = option.nutrition?.calories ?? 0;
        acc.min = Math.min(acc.min, calories);
        acc.max = Math.max(acc.max, calories);
        return acc;
      },
      { min: 0, max: 0 }
    );

    if (min === max) {
      return `${min} Cal`;
    }

    // If min is 0 and we have 2 or fewer entries, then max is the number of calories of
    // of the entry that the user needs information about.
    if (calorieEntries.length <= 2 && min === 0) {
      return `${max} Cal`;
    }

    // if selected option has calories, render those
    const selectedCalories = selectedOption?.nutrition?.calories ?? 0;
    if (selectedCalories) {
      return `${selectedCalories} Cal`;
    }

    // if selected option does not have calories, render the calorie range of all the options
    return `${min}–${max} Cal`;
  }, [content, options, selectedOption?.nutrition?.calories]);

  const { priceForItemOptionModifier } = useMenuContext();

  const price = options?.reduce((mostExpensivePrice: number, option) => {
    return Math.max(
      mostExpensivePrice,
      priceForItemOptionModifier({
        item: selectedItem,
        itemOption: content as IItemOption,
        modifier: option as IItemOptionModifier,
      })
    );
  }, 0);

  return (
    <ProductListItem
      isClickable={isListItemClickable}
      onProductClick={onListItemClick}
      productProps={{
        name: content.name?.locale,
        image,
        // @ts-expect-error TS(2322) FIXME: Type '(props: SvgProps) => JSX.Element' is not ass... Remove this comment to see the full error message
        fallbackImage: FallbackImageSvg,
        imageSize: 'small',
        calories: caloriesText,
        price,
      }}
      controls={controls}
    />
  );
};
