import React from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { StyleProp, View, ViewStyle } from 'react-native';

import { Button, IconStore, Text, tokens } from '@fhs/ui';

interface Props {
  description: string;
  name: string;
  onPressSelect: () => void;
  style?: StyleProp<ViewStyle>;
}

function RestaurantCard({ description, name, onPressSelect, style }: Props) {
  const { formatMessage } = useIntl();

  return (
    <View style={[styles.container, style]}>
      <View style={styles.content}>
        <View style={styles.iconCircle}>
          <IconStore size={24} />
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.titleContainer}>
            <Text.Ui size="md" weight="semibold" ellipsizeMode="tail" numberOfLines={1}>
              {name}
            </Text.Ui>
          </View>
          <Text.Ui style={styles.description} size="sm">
            {description}
          </Text.Ui>
        </View>
        <Button size="sm" type="outline" style={styles.button} onPress={onPressSelect}>
          <Button.Text style={styles.buttonText}>{formatMessage({ id: 'select' })}</Button.Text>
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: tokens.colors.$blackOpacity10,
    width: '100%',
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  contentContainer: {
    flex: 1,
    alignItems: 'flex-start',
    margin: 0,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  description: {
    marginTop: 4,
    marginRight: 8,
  },
  button: {
    paddingLeft: 8,
    paddingVertical: 0,
    borderRadius: 800,
    width: 70,
    height: 31,
    marginLeft: 8,
  },
  buttonText: {
    fontSize: 10,
  },
  iconCircle: {
    height: 40,
    width: 40,
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: tokens.colors.$houseLight,
    marginRight: 12,
  },
});

export default RestaurantCard;
