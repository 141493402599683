import { PaymentISOs } from 'utils/form/constants';

export enum TransactionType {
  PAYMENT = 'payment',
  PREPAID = 'prepaid',
}

interface IGetGooglePaymentsConfigs {
  transactionType: TransactionType;
  countryCode: PaymentISOs;
  googleConfig: {
    [key in string]: any;
  };
  migrationEnabled: boolean;
}

/**
 * This function return the Google Pay transaction configs based on the transaction type and countrycode
 * It is returning the gateway and the gateway merchant id
 */
export const getGooglePaymentsConfigs = ({
  transactionType,
  countryCode,
  googleConfig,
  migrationEnabled,
}: IGetGooglePaymentsConfigs) => {
  // Checking if it's a prepaid or a payment transaction
  const isPayment = transactionType === TransactionType.PAYMENT;

  const paymentsGateway =
    migrationEnabled && googleConfig.migrationPaymentsGateway
      ? googleConfig.migrationPaymentsGateway
      : googleConfig.paymentsGateway;

  const prepaidPaymentsGateway =
    migrationEnabled && googleConfig.migrationPrepaidPaymentsGateway
      ? googleConfig.migrationPrepaidPaymentsGateway
      : googleConfig.prepaidPaymentsGateway;

  const paymentsMerchantIdMap =
    migrationEnabled && googleConfig.migrationPaymentsGatewayMerchantId
      ? googleConfig.migrationPaymentsGatewayMerchantId
      : googleConfig.paymentsGatewayMerchantId;

  const prepaidPaymentsMerchantIdMap =
    migrationEnabled && googleConfig.migrationPrepaidPaymentsGatewayMerchantId
      ? googleConfig.migrationPrepaidPaymentsGatewayMerchantId
      : googleConfig.prepaidPaymentsGatewayMerchantId;

  // Getting the right gateway based on the transaction type
  const gateway = isPayment ? paymentsGateway : prepaidPaymentsGateway;

  // Getting the gateway merchant id based on the transaction type and the country code of the transaction
  let gatewayMerchantId;
  switch (countryCode) {
    case PaymentISOs.CA:
      if (isPayment) {
        gatewayMerchantId = paymentsMerchantIdMap.ca;
      } else {
        gatewayMerchantId = prepaidPaymentsMerchantIdMap.ca;
      }
      break;
    case PaymentISOs.CH:
    case PaymentISOs.GB:
    case PaymentISOs.US:
      if (isPayment) {
        gatewayMerchantId = paymentsMerchantIdMap.us;
      } else {
        gatewayMerchantId = prepaidPaymentsMerchantIdMap.us;
      }
      break;
    default:
      gatewayMerchantId = '';
  }

  // Returning the right conf
  return { gateway, gatewayMerchantId };
};
