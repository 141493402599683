import { createSelector } from '@reduxjs/toolkit';
import { useIntl } from 'react-intl';

import { LoyaltySecondaryIdentifierType } from 'generated/graphql-gateway';
import { RootState } from 'state/global-state';

// For more complex multiline selectors, export from this file
export const selectUser = ({ loyalty }: RootState) => loyalty.user.loyaltyUser;
export const selectLoyaltyUser = ({ loyalty }: RootState) => {
  return {
    loyaltyUser: loyalty.user.loyaltyUser || null,
    loading: loyalty.user.loyaltyLoading,
  };
};

export const selectLoyaltyLoading = ({ loyalty }: RootState) => loyalty.user.loyaltyLoading;

export const select12DigitSwipeCard = ({ loyalty }: RootState) =>
  loyalty.user.loyaltyUser?.secondaryIdentifiers?.find(
    identifier =>
      identifier?.identifierType === LoyaltySecondaryIdentifierType.LOYALTY12DIGITSWIPECARD
  );

export const selectLoyaltyUserId = createSelector(
  selectLoyaltyUser,
  loyaltyUser => loyaltyUser?.loyaltyUser?.id
);

export const selectDailyRewardTransactionCount = ({ loyalty }: RootState) => {
  const transactions = loyalty.user.loyaltyUserTransactions;
  const dailyTransactionCount = transactions.filter(transaction => {
    const { formatDate } = useIntl();
    const formatDateOptions = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    };
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ year: string; month: string; d... Remove this comment to see the full error message
    const currentDateFormatted = formatDate(new Date(), formatDateOptions);
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ year: string; month: string; d... Remove this comment to see the full error message
    const transactionDate = formatDate(transaction.createdAt, formatDateOptions);
    return currentDateFormatted === transactionDate && transaction.hasRewards;
  });
  return dailyTransactionCount.length;
};

export const selectLoyaltyUserExpireDateFormattedText = ({ loyalty }: RootState) => {
  const pointsExpiryDateKey = loyalty.user.loyaltyUser?.pointsExpiryDateKey;
  if (!pointsExpiryDateKey) {
    return null;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { formatDate, formatMessage } = useIntl();
  const day = `${formatDate(pointsExpiryDateKey, {
    day: '2-digit',
  })}`;

  const month = `${formatDate(pointsExpiryDateKey, {
    month: 'long',
  })}`;
  const dateTime = formatMessage(
    {
      id: 'ordinalDateTime',
      description: {},
      defaultMessage: `${month} {day, selectordinal,
      one {#st}
      two {#nd}
      few {#rd}
      other {#th}
  }`,
    },
    {
      day,
    }
  );
  return dateTime;
};
