import { useFormikContext } from 'formik';
import type { GestureResponderEvent } from 'react-native';

import { addWithConfig } from '../../utils';
import { Button } from '../button';

import type { FormikResetButtonProps } from './types';

export const FormikResetButton = (props: FormikResetButtonProps) => {
  const { children, onPress, ...rest } = props;
  const { isSubmitting, resetForm } = useFormikContext();

  const handleOnPress = (pressEvt: GestureResponderEvent) => {
    resetForm();
    onPress?.(pressEvt);
  };

  return (
    <Button onPress={handleOnPress} isDisabled={isSubmitting} {...rest}>
      {children}
    </Button>
  );
};

export default addWithConfig(FormikResetButton);
