import { Image } from 'expo-image';
import { Link } from 'expo-router';
import Head from 'expo-router/head';
import React from 'react';
import { StyleSheet } from 'react-native';

import { Button } from '../button';
import { YStack } from '../stack';
import { Text } from '../text';

import { NotFoundProps } from './types';

export function NotFound(props: NotFoundProps) {
  const buttonText = props.buttonText || 'Go To Homepage';
  const buttonUrl = props.buttonUrl || '/';
  return (
    <>
      {/*TODO(simplified-menu): not sure if this is a good idea  */}
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <YStack style={[styles.container]}>
        <YStack style={[styles.containerInner]}>
          <Image
            source={require('./404.webp')}
            contentFit="contain"
            style={[styles.containerImage]}
          />
          <Text.Heading style={[styles.textParagraph]} type="one">
            Page Not found
          </Text.Heading>
          <Text.Paragraph style={[styles.textParagraph]} size="md">
            Page Not found The page you are looking for might have been moved, deleted, or possibly
            never existed.
          </Text.Paragraph>
          <Link href={buttonUrl} asChild style={[styles.containerLink]}>
            <Button type="solid" size="lg">
              <Button.Text>{buttonText}</Button.Text>
            </Button>
          </Link>
        </YStack>
      </YStack>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerInner: {
    width: '90%',
    maxWidth: 370,
    alignItems: 'center',
  },
  containerImage: {
    height: 100,
    aspectRatio: 288 / 100,
    marginVertical: 16,
  },
  textHeading: {
    marginVertical: 8,
  },
  textParagraph: {
    marginVertical: 8,
  },
  containerLink: {
    marginVertical: 16,
  },
});
