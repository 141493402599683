import { IGooglePayDetails } from 'state/payment/types';
import { sanitizeAlphanumeric } from 'utils/form';
import { PaymentISOs } from 'utils/form/constants';
import { buildFormattedBillingAddress } from 'utils/native-actions';
import { parseStringifiedJSON } from 'utils/parse-string';

import { WORLDPAY_GATEWAY } from './google-pay-payment-details';

const getDecoratedPaymentData = ({
  locality,
  postalCode,
  region,
  streetAddress,
  country,
  gateway,
  token,
  countryCode,
  paymentType,
  displayName,
}: {
  paymentType: google.payments.api.CardNetwork;
  displayName: string;
  token: string;
  gateway: string;
  locality: string;
  postalCode: string;
  region: string;
  streetAddress?: string;
  country: string;
  countryCode: PaymentISOs;
}): IGooglePayDetails => {
  const formattedBillingAddress = buildFormattedBillingAddress({
    street: String(streetAddress),
    city: String(locality),
    state: String(region),
    postalCode: String(postalCode),
    country: String(country),
  });
  const billingAddress = {
    locality,
    postalCode: sanitizeAlphanumeric(postalCode),
    region,
    streetAddress,
  };

  let signature = '',
    protocolVersion = '',
    data = '';
  if (gateway !== WORLDPAY_GATEWAY) {
    const parsedToken = parseStringifiedJSON({ value: token });
    signature = parsedToken.signature;
    protocolVersion = parsedToken.protocolVersion;
    data = parsedToken.signedMessage;
  }
  const decoratedPaymentData: IGooglePayDetails = {
    signature,
    country: countryCode,
    data,
    formatted: formattedBillingAddress,
    primary: true,
    type: 'work',
    version: protocolVersion,
    billingAddress,
    paymentData: token,
    PaymentMethodData: {
      paymentType,
      displayName,
    },
  };
  return decoratedPaymentData;
};

export default getDecoratedPaymentData;
