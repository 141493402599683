import { createMqStyles } from '../../mq-styles';
import { tokens } from '../../tokens';

import { Text, type TextProps } from './text';

type UiTextSize = 'lg' | 'md' | 'sm' | 'xs';

export type UiTextProps = TextProps & {
  size: UiTextSize;
};

export function UiText({ size, ...props }: UiTextProps) {
  const styles = useMqStyles();

  return <Text {...props} style={[styles.baseline, styles[size], props.style]} />;
}

const useMqStyles = createMqStyles({
  baseline: {
    $base: {
      color: tokens.colors.$houseDark,
    },
  },
  lg: {
    $base: { fontSize: 16, lineHeight: 20 },
    $gteDesktop: { fontSize: 20, lineHeight: 24 },
  },
  md: {
    $base: { fontSize: 14, lineHeight: 18 },
    $gteDesktop: { fontSize: 16, lineHeight: 20 },
  },
  sm: {
    $base: { fontSize: 12, lineHeight: 16 },
    $gteDesktop: { fontSize: 14, lineHeight: 16 },
  },
  xs: {
    $base: { fontSize: 10, lineHeight: 12 },
    $gteDesktop: { fontSize: 12, lineHeight: 14 },
  },
});
