import { Box, Header, Text, makeUclComponent } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';

export const Container = Box.withConfig({
  paddingTop: '$4',
  paddingX: '$8',
  paddingBottom: '$8',

  _text: {
    textAlign: 'center',
  },
});

export const Title = Header.withConfig({
  margin: 0,
  fontFamily: Styles.fontFamily.base,
  variant: 'headerFour',
});

export const Body = Text.withConfig({
  margin: 0,
});

export const StyledButton = makeUclComponent(ActionButton).withConfig({
  marginTop: '$6',
  fontSize: 'lg',
  width: 'full',
});
