import React from 'react';
import { useIntl } from 'react-intl';

import { HStack, IconButton, ToggleButtonGroup, VStack } from '@fhs-legacy/universal-components';
import Picture from 'components/picture';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import { StyledDonationCard, StyledDonationSubTitle, StyledDonationTitle } from './styled';
import { IDonationAtCheckoutWithContentProps } from './types';

const DonationAtCheckout: React.FC<
  React.PropsWithChildren<IDonationAtCheckoutWithContentProps>
> = ({
  header,
  subtitle,
  donationOptions,
  logo,
  selectedDonationId,
  onChangeValue,
  onInfoPressed,
}) => {
  const { formatMessage } = useIntl();

  const handleInfoPressed = () => {
    if (!onInfoPressed) {
      return;
    }
    onInfoPressed();
  };

  const handleChange = (value: string | null) => {
    if (!onChangeValue || !value) {
      return;
    }
    onChangeValue(value);
  };

  return (
    <>
      <StyledDonationTitle>{header}</StyledDonationTitle>
      <StyledDonationCard>
        <IconButton
          onPress={handleInfoPressed}
          variant="ghost"
          iconName="info"
          size="xs"
          alignSelf="flex-end"
          padding="$0"
          {...hiddenAccessibilityPlatformProps}
        />
        <HStack space="$2" alignItems="center">
          {logo && (
            <Picture width="64px" height="64px" image={logo} alt={header} objectFitContain />
          )}
          <VStack width="full" maxWidth="80%" paddingX="$2">
            <StyledDonationSubTitle>{subtitle}</StyledDonationSubTitle>
          </VStack>
        </HStack>
        <ToggleButtonGroup
          _toggleButton={{ paddingX: '$2' }}
          options={donationOptions}
          onChangeValue={handleChange}
          value={selectedDonationId}
          accessibilityHint={formatMessage({ id: 'selectDonationAmount', description: '' })}
          accessibilityLabel={formatMessage({ id: 'selectDonationAmount', description: '' })}
        />
      </StyledDonationCard>
    </>
  );
};

export default DonationAtCheckout;
