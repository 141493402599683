import { FlatList } from 'react-native';

import { makeUclComponent } from '@fhs-legacy/universal-components';
import { ArrowLink } from 'components/arrow-link';

import { routes } from '../../../utils/routing';
import { ContentWrap } from '../support-form-v2.styled';

import { ICategoryGroupContent, ISupportV2CategoriesViewProps } from './types';

export const StyledArrowLink = makeUclComponent(ArrowLink).withConfig({
  width: '90%',
  marginX: '5',
});

const renderItem = ({ item }: { item: ICategoryGroupContent }) => {
  return (
    <StyledArrowLink to={`${routes.supportCategories}/${item._id}`} key={item._id}>
      {item.name}
    </StyledArrowLink>
  );
};

const keyExtractor = (item: ICategoryGroupContent) => item._id;

export const SupportV2CategoriesView = ({ categoryGroups }: ISupportV2CategoriesViewProps) => {
  return (
    <ContentWrap>
      <FlatList data={categoryGroups} keyExtractor={keyExtractor} renderItem={renderItem} />
    </ContentWrap>
  );
};
