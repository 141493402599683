import { useMemo } from 'react';

import { useAnchorSectionList } from './context';
import { useScrollToSection } from './use-scroll-to-section';

export function useAnchors() {
  const context = useAnchorSectionList();
  const scrollToSection = useScrollToSection();

  return useMemo(() => {
    return context.sections.map(section => {
      return {
        id: section.id,
        title: section.anchorTitle,
        image: section.image,
        onPress: () => {
          // we are manually setting the activeSectionid  because we want
          // to ensure that the section the user clicked on is the one that
          // highlights
          context.setManualActiveSectionId(section.id);

          // Web must be animated as a workaround for mobile safari
          // see https://bugs.webkit.org/show_bug.cgi?id=262287
          scrollToSection(section.id, { animated: true });
        },
        isActive: context.manualActiveSectionId
          ? // If we have manually clicked a link and it has not been cleared yet we want to continue to show it as active
            // while we scroll to it
            section.id === context.manualActiveSectionId
          : section.id === context.activeSectionId,
      };
    });
  }, [context, scrollToSection]);
}
