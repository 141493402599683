import { useCallback } from 'react';

import { ICartEntry } from '@rbi-ctg/menu';
import { selectors, useAppSelector } from 'state/global-state';
import { getParentIdFromUrl } from 'utils/cart';

import type { IUseGetAvailableRewards } from './types';

export const useGetAvailableRewards = (): IUseGetAvailableRewards => {
  const availableLoyaltyRewardsMap = useAppSelector(
    selectors.loyalty.selectAvailableLoyaltyRewardsMap
  );
  const getAvailableRewardFromCartEntry = useCallback(
    (cartEntry: ICartEntry) => {
      if (!cartEntry) {
        return;
      }

      const availableReward = availableLoyaltyRewardsMap[cartEntry._id];

      if (availableReward) {
        return availableReward;
      }

      // there may not always be a 1-1 correlation with the index of `availableLoyaltyRewardsMap` and `cartEntry._id`
      // this is the case with pickers because the cartEntry._id is the item that the picker resolved to
      // since the reward map holds the parent picker, we have to parse the parent picker id from `cartEntry.url`
      // example url - /menu/picker-$pickerId?cartId=$cartId&_id=$itemId
      const parentId = getParentIdFromUrl(cartEntry.url);
      return availableLoyaltyRewardsMap[parentId];
    },
    [availableLoyaltyRewardsMap]
  );

  return { getAvailableRewardFromCartEntry };
};
