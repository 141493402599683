import { ItemStatus } from './enums';

interface IMenuItemStatusFactors {
  showStaticMenu: boolean;
  enableOrdering: boolean;
  isItemAvailableForDaypart: boolean;
  maxCartQuantityMet: boolean;
}

export const useMenuItemStatus = ({
  showStaticMenu,
  enableOrdering,
  isItemAvailableForDaypart,
  maxCartQuantityMet,
}: IMenuItemStatusFactors) => {
  if (!enableOrdering) {
    return ItemStatus.ORDERING_DISABLED;
  }
  if (!isItemAvailableForDaypart) {
    return ItemStatus.DAYPART_UNAVAILABLE;
  }
  if (showStaticMenu) {
    return ItemStatus.CHECK_STORE_AVAILABILITY;
  }
  if (maxCartQuantityMet) {
    return ItemStatus.MAX_CART_QUANTITY_MET;
  }
  return ItemStatus.AVAILABLE_IN_STORE;
};
