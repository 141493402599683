import { StyleSheet, View, type ViewProps } from 'react-native';

export type XStackProps = ViewProps;

export function XStack({ style, ...props }: XStackProps) {
  return <View {...props} style={[styles.xstack, style]} />;
}

const styles = StyleSheet.create({
  xstack: {
    flexDirection: 'row',
  },
});
