import { useMemo } from 'react';

import { IItem, ISanityCombo } from '@rbi-ctg/menu';
import { ItemAvailabilityStatus } from 'enums/menu';
import { IAdaptableMenuCardFragment } from 'generated/sanity-graphql';
import { useAuthContext } from 'state/auth';
import { useDayPartContext } from 'state/day-part';
import { useFeaturesContext } from 'state/features';
import { selectors, useAppSelector } from 'state/global-state';
import { useMainMenuContext } from 'state/main-menu';
import { useStoreContext } from 'state/store';
import { getAvailabilityStatus } from 'utils/availability';
import { isCombo, isPicker } from 'utils/menu';

import usePosVendor from './menu/use-pos-vendor';
import { useAdaptableMenuCardsQuery } from './use-adaptable-menu-cards-query';

export const useAdaptableMenuCards = () => {
  const { isAuthenticated } = useAuthContext();
  const { featureMenuId = '' } = useFeaturesContext();
  const { response: data, menuCardsLoading } = useAdaptableMenuCardsQuery(featureMenuId);
  const { prices } = useStoreContext();
  const { vendor } = usePosVendor();
  const { activeDayParts } = useDayPartContext();
  const { getPricingAndAvailability, isStaticMenu } = useMainMenuContext();
  const offers = useAppSelector(selectors.loyalty.selectCombinedOffers);

  const availableMenuCards = useMemo(() => {
    const cards = ((isAuthenticated
      ? data?.FeatureMenu?.authAdaptableMenuCardGroup?.Cards
      : data?.FeatureMenu?.unauthAdaptableMenuCardGroup?.Cards) ??
      []) as IAdaptableMenuCardFragment[];

    return cards.filter((card: IAdaptableMenuCardFragment) => {
      if (menuCardsLoading) {
        return [];
      }
      if (!card?.menuElement) {
        // If there is no menu element, card should only be available if a path is defined
        return card?.path?.locale;
      }
      if (isStaticMenu) {
        return true;
      }
      if ('loyaltyEngineId' in card.menuElement) {
        return !!offers.find(offer => offer._id === card.menuElement?._id);
      }
      // TODO: remove this block when offers and rewards are available in Menu Service
      if ('incentives' in card.menuElement) {
        const incentive = card.menuElement.incentives?.[0] || null;

        const status = getAvailabilityStatus({
          data: getMenuElement(incentive),
          activeDayParts,
          prices,
          vendor,
          isExtra: false,
        });

        return (
          status === ItemAvailabilityStatus.AVAILABLE ||
          status === ItemAvailabilityStatus.STORE_NOT_SELECTED
        );
      }

      const pricedAndAvailable = getPricingAndAvailability(card.menuElement?._id);
      return pricedAndAvailable?.isAvailable;
    });
  }, [
    isAuthenticated,
    data?.FeatureMenu?.authAdaptableMenuCardGroup?.Cards,
    data?.FeatureMenu?.unauthAdaptableMenuCardGroup?.Cards,
    menuCardsLoading,
    isStaticMenu,
    getPricingAndAvailability,
    offers,
    activeDayParts,
    prices,
    vendor,
  ]);

  return {
    availableMenuCards,
    menuCardsLoading,
  };
};

// TODO: remove this block when offers and rewards are available in Menu Service
// Function to cast incentives to a type accepted by getAvailabilityStatus function
const getMenuElement = (element: object | null) => {
  if (isCombo(element)) {
    return element as ISanityCombo;
  }
  if (isPicker(element)) {
    return element;
  }
  return element as IItem | null;
};
