import { IOffer } from '@rbi-ctg/menu';
import { getUniqIdForOffer } from 'utils/offers';

/**
 * Creating this util function in order to more easily test that
 * Sanity is giving the client the offers in the right order
 */

const sortOffersToDisplay = (
  filteredOffers: IOffer[],
  standardOffers: IOffer[],
  promoCodeOffers: IOffer[]
) => {
  return filteredOffers.reduce((acc: Array<IOffer>, offer: IOffer) => {
    const uniqueId = getUniqIdForOffer(offer);
    const foundStandardOffer = standardOffers.find(
      standardOffer => getUniqIdForOffer(standardOffer) === uniqueId
    );
    const foundPromoOffer = promoCodeOffers.find(
      promoCodeOffer => getUniqIdForOffer(promoCodeOffer) === uniqueId
    );
    if (foundStandardOffer) {
      acc.push(foundStandardOffer);
    } else if (foundPromoOffer) {
      acc.push(foundPromoOffer);
    }
    return acc;
  }, []);
};
export default sortOffersToDisplay;
