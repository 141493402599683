import React from 'react';
import { useIntl } from 'react-intl';

import useDialogModal, { DialogCb } from 'hooks/use-dialog-modal';

interface IResetConfirmDialogHook<T> {
  openConfirmationDialog: DialogCb<T>;
  ResetConfirmDialog: JSX.Element;
}

export function useResetConfirmDialog<T extends object>(
  eventMessage: string,
  onConfirm: DialogCb<T>,
  onCancel?: DialogCb<T>
): IResetConfirmDialogHook<T> {
  const { formatMessage } = useIntl();
  const [ConfirmationDialog, openConfirmationDialog] = useDialogModal({
    onConfirm,
    showCancel: true,
    onCancel,
    modalAppearanceEventMessage: eventMessage,
  });

  const ResetConfirmDialog = (
    <ConfirmationDialog
      heading={formatMessage({ id: 'resetCustomizationsHeader' })}
      body={formatMessage({ id: 'resetCustomizationsDescription' })}
      confirmLabel={formatMessage({ id: 'continue' })}
      cancelLabel={formatMessage({ id: 'cancel' })}
    />
  );

  return {
    openConfirmationDialog,
    ResetConfirmDialog,
  };
}
