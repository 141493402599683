export const storeFavoriteMarkerSvg = `
<svg width="28" height="29" fill="none" viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg">
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 24.31c5.523 0 10-4.478 10-10 0-5.523-4.477-10-10-10s-10 4.477-10 10c0 5.522 4.477 10 10 10z"
      fill="#FFFFFF"
    />
  </g>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13.87 21.535l-5.838-6.013c-1.073-1.129-1.336-2.803-.663-4.206.573-1.22 1.797-2.001 3.144-2.006.732 0 1.445.236 2.031.675l.957.7.368.275.37-.275.962-.7c.584-.438 1.294-.675 2.025-.675 1.34.01 2.556.79 3.125 2.006.683 1.396.435 3.069-.625 4.206l-5.857 6.013z"
    fill="#B1080F"
  />
</svg>
`;
