import { useCallback, useState } from 'react';

import noop from '../../utils/noop';

import { IUseAccordionItemProps } from './types';

export const useAccordionItem = ({
  onInteract = noop,
  defaultValue = false,
}: IUseAccordionItemProps = {}) => {
  const [isActive, setIsActive] = useState(defaultValue);
  const [isFirstInteraction, setIsFirstInteraction] = useState(true);

  const handleToggle = useCallback(
    (activeState?: boolean) => {
      const newState = activeState ?? !isActive;
      setIsActive(newState);
      onInteract(newState, isFirstInteraction);
      setIsFirstInteraction(false);
    },
    [isActive, isFirstInteraction, onInteract]
  );

  return {
    handleToggle,
    isActive,
  };
};
