import { IValidationConfig } from './types';

export const validationConfig: IValidationConfig = {
  dob: {
    required: false,
  },
  phoneNumber: {
    required: true,
  },
  zipcode: {
    required: false,
  },
  favoriteStore: {
    required: false,
  },
};
