import { Link } from 'expo-router';
import React, { PropsWithChildren, ReactNode, useMemo } from 'react';
import { GestureResponderEvent } from 'react-native';

import { Button, ButtonProps } from '@fhs-legacy/universal-components';
import useLogOnLongLoading from 'hooks/use-log-on-long-loading';
import { hapticImpact } from 'utils/haptic';
import { useMaybeOsActionHandler } from 'utils/os-action';

import {
  ActionButtonSizes,
  ActionButtonVariants,
  IActionButtonProps,
  IconElementProp,
} from './types';

const resizeIcon = (icon: IconElementProp | undefined, isSmallButton: boolean) =>
  icon && React.cloneElement(icon, { size: isSmallButton ? '3' : '4' });

const ActionButton = ({
  children,
  disabled,
  eventAttributes: _eventAttributes = {},
  isLoading = false,
  onPress,
  onNonVisualPress,
  to,
  replace = false,
  linkPath,
  perceptible,
  hapticFeedbackStyle,
  variant,
  color,
  tabIndex = 0,
  fullWidth,
  rightIcon,
  leftIcon,
  startIcon,
  endIcon,
  width,
  noLogOnLongLoading,
  size = ActionButtonSizes.LARGE,
  ...rest
}: PropsWithChildren<IActionButtonProps>) => {
  const isImperceptible = !perceptible && disabled;
  const handleClick = (e: GestureResponderEvent) => {
    if (isLoading) {
      e.preventDefault();
      return;
    }
    if (isImperceptible) {
      e.preventDefault();
      return;
    } else if (perceptible && disabled) {
      e.preventDefault();
      void onNonVisualPress?.(e);
      return;
    }

    if (hapticFeedbackStyle) {
      hapticImpact({ style: hapticFeedbackStyle });
    }

    if (onPress) {
      onPress(e);
    }
  };

  const buttonName = getTextFromChildren(children) || rest.testID;
  const isLoadingOrLoggingDisabled = noLogOnLongLoading ? false : isLoading;
  useLogOnLongLoading({
    name: `BUTTON - ${buttonName}`,
    isLoading: isLoadingOrLoggingDisabled,
    additionalContext: {
      buttonName,
    },
  });

  const focusable = !(isLoading || isImperceptible || tabIndex === -1);
  const isSmallButton = size === ActionButtonSizes.SMALL;

  const iconProps: Partial<ButtonProps> = useMemo(
    () => ({
      leftIcon: resizeIcon(leftIcon, isSmallButton),
      rightIcon: resizeIcon(rightIcon, isSmallButton),
      startIcon: resizeIcon(startIcon, isSmallButton),
      endIcon: resizeIcon(endIcon, isSmallButton),
    }),
    [endIcon, isSmallButton, leftIcon, rightIcon, startIcon]
  );

  const commonProps: Partial<ButtonProps> = {
    ...rest,
    ...iconProps,
    isDisabled: !!disabled,
    onPress: handleClick,
    isLoading,
    focusable,
    width: fullWidth ? 'full' : width,
    size: isSmallButton ? 'sm' : 'lg',
  };

  const variantProps: Partial<ButtonProps> = useMemo(() => {
    switch (variant) {
      case ActionButtonVariants.BOX_SHADOW_PRIMARY:
        return {
          shadow: '4',
        };
      case ActionButtonVariants.BOX_SHADOW_INVERSE:
        return {
          shadow: '4',
          variant: 'solid-reversed',
        };
      case ActionButtonVariants.TEXT_ONLY:
        return {
          variant: 'ghost',
          color,
        };
      case ActionButtonVariants.INVERSE:
        return {
          variant: 'solid-reversed',
        };
      case ActionButtonVariants.OUTLINE:
        return {
          variant: 'outline',
        };
      case ActionButtonVariants.OUTLINE_REVERSED:
        return {
          variant: 'outline-reversed',
        };
      case ActionButtonVariants.PRIMARY:
      default:
        return {};
    }
  }, [color, variant]);

  // TODO: expo-router
  // drop the `linkPath` property
  if (to || linkPath) {
    return (
      <Link href={(to || linkPath)!} replace={replace} asChild>
        <Button {...commonProps} {...variantProps} dd-action-name={buttonName}>
          {children}
        </Button>
      </Link>
    );
  }

  return (
    <Button {...commonProps} {...variantProps} dd-action-name={buttonName}>
      {children}
    </Button>
  );
};

// TODO: This wraps the button with an anchor tag. We should instead style the link as a button.
export const ActionLink = ({
  children,
  to,
  linkPath,
  fullWidth = false,
  replace,
  onPress,
  ...props
}: React.PropsWithChildren<IActionButtonProps & { to: string }>) => {
  const handleMaybeOsAction = useMaybeOsActionHandler();

  const handlePress = async (e: GestureResponderEvent) => {
    await handleMaybeOsAction(to);
    if (onPress) {
      onPress(e);
    }
  };

  const button = (
    <ActionButton onPress={handlePress} fullWidth={fullWidth} {...props}>
      {children}
    </ActionButton>
  );

  // TODO: expo-ruter @deprecate linkPath
  if ((linkPath || to) && !props.disabled) {
    return (
      <Link href={linkPath || to} replace={replace} asChild>
        {button}
      </Link>
    );
  }

  return button;
};

export default ActionButton;

// Return the first non-false string value
export function getTextFromChildren(children?: ReactNode): string {
  try {
    if (!children) {
      return '';
    }

    /* @ts-expect-error TS(2322) FIXME */
    return React.Children.map(children || '', child => {
      if (typeof child === 'string') {
        return child;
      } else if (child && React.isValidElement(child)) {
        return getTextFromChildren((child.props as { children?: ReactNode }).children);
      }
      return '';
    }).reduce((name, curr) => name || curr, '');
  } catch (_ex) {
    return '';
  }
}

export { ActionButtonVariants, ActionButtonSizes } from './types';
export type { IActionButtonProps, IActionLinkProps } from './types';
