import { Box, Text, makeUclComponent } from '@fhs-legacy/universal-components';
import Currency from 'components/currency';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';

export {
  TransactionDate,
  TransactionDateAndTime,
  TransactionTime,
} from '../loyalty-history/loyalty-history.styled';

export const StyledCurrency = makeUclComponent(Currency).withConfig({
  textProps: { fontSize: 'xs', lineHeight: '16px' },
});

export const PurchaseAmount = Box.withConfig({
  flexDirection: 'row',
  justifyContent: 'flex-end',
});

export const Rewards = Box.withConfig({
  _text: {
    fontWeight: Styles.fontWeight.heavy,
  },
});

export const Reward = Box.withConfig({
  _text: {
    fontSize: 'xs',
    lineHeight: '16px',
    color: Styles.color.black,
  },
});

export const StyledPointsIcon = LoyaltyPointsIcon.withConfig({
  size: 3.5,
});

export const PointsText = Text.withConfig<{ $pointsEarned: boolean }>(({ $pointsEarned }) => ({
  fontSize: 'xs',
  lineHeight: '16px',
  fontWeight: 'bold',
  marginLeft: '$1',
  color: $pointsEarned ? 'token.text-default' : 'token.text-error',
}));

export const IconWrapper = Box.withConfig<{ $pointsEarned: boolean }>({
  flexDirection: 'row',
  alignItems: 'center',
});

export const PointsLineWrapper = Box.withConfig({
  flexDirection: 'column',
  width: 'full',
});

export const PointsDescription = Text.withConfig<{ $pointsEarned: boolean }>(
  ({ $pointsEarned }) => ({
    fontSize: 'xs',
    lineHeight: '16px',
    color: $pointsEarned ? 'token.text-default' : 'token.text-error',
  })
);
