import { useId } from 'react';
import { Defs, LinearGradient, Path, Rect, Stop, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconPaymentTypeJcb = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  const linearGradientAId = useId();
  const linearGradientBId = useId();
  const linearGradientCId = useId();

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Rect width={24} height={16} y={4.101} fill="#fff" rx={2} />
      <Rect width={23} height={15} x={0.5} y={4.601} stroke="#000" strokeOpacity={0.1} rx={1.5} />
      <Path
        fill={`url(#${linearGradientAId}`}
        d="M15.543 12.323c.38.009.761-.017 1.139.014.382.075.475.682.135.881-.232.131-.508.05-.76.072h-.514v-.967Zm1.358-1.094c.084.312-.202.592-.489.549h-.869c.006-.294-.012-.614.01-.893.347.01.698-.02 1.044.017.15.039.274.167.304.327ZM18.992 6.6c.016.596.002 1.223.007 1.831-.001 2.472.002 4.944-.002 7.417-.015.926-.798 1.731-1.675 1.75-.877.003-1.755 0-2.633.001v-3.737c.956-.005 1.913.01 2.87-.008.443-.03.929-.336.95-.848.052-.515-.41-.87-.85-.926-.168-.005-.163-.052 0-.073.42-.094.748-.549.625-1.004-.105-.479-.61-.664-1.029-.663-.855-.006-1.71-.001-2.566-.003.005-.698-.012-1.396.01-2.093.067-.91.87-1.66 1.734-1.644h2.56Z"
      />
      <Path
        fill={`url(#${linearGradientBId}`}
        d="M5.023 8.34c.022-.926.808-1.724 1.683-1.738.875-.003 1.75 0 2.624-.001-.002 3.095.005 6.19-.003 9.285-.034.913-.811 1.697-1.678 1.713-.876.003-1.752 0-2.629.001v-3.863c.852.21 1.744.3 2.612.16.52-.087 1.087-.354 1.263-.92.13-.482.057-.991.076-1.487v-1.152H7.468c-.007.762.014 1.525-.01 2.286-.041.468-.483.765-.903.749-.522.006-1.555-.396-1.555-.396-.002-1.428.015-3.215.023-4.638Z"
      />
      <Path
        fill={`url(#${linearGradientCId}`}
        d="M9.89 10.803c-.078.017-.015-.283-.035-.397.005-.72-.012-1.441.009-2.16.067-.914.876-1.666 1.744-1.645h2.557c-.003 3.095.004 6.19-.004 9.285-.034.913-.811 1.697-1.678 1.713-.876.003-1.752 0-2.628.001v-4.232c.598.515 1.411.595 2.157.596.562 0 1.121-.09 1.667-.227v-.775c-.615.321-1.338.526-2.02.34-.476-.123-.821-.606-.814-1.12-.055-.536.244-1.102.746-1.26.623-.206 1.302-.05 1.886.217.125.069.252.154.202-.066v-.609c-.977-.244-2.016-.333-2.997-.068-.284.084-.56.211-.791.407Z"
      />
      <Defs>
        <LinearGradient
          id={linearGradientAId}
          x1={14.659}
          x2={18.972}
          y1={11.865}
          y2={11.865}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#007B40" />
          <Stop offset={1} stopColor="#55B330" />
        </LinearGradient>
        <LinearGradient
          id={linearGradientBId}
          x1={4.929}
          x2={9.26}
          y1={12.206}
          y2={12.206}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#1D2970" />
          <Stop offset={1} stopColor="#006DBA" />
        </LinearGradient>
        <LinearGradient
          id={linearGradientCId}
          x1={9.848}
          x2={14.164}
          y1={11.972}
          y2={11.972}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#6E2B2F" />
          <Stop offset={1} stopColor="#E30138" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};
