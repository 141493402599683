import { useMemo } from 'react';
import { StyleSheet, type ViewProps } from 'react-native';

import { XStack } from '../stack';
import type { UiTextProps } from '../text';

import { QuantityContext } from './context';
import { IQuantityStepperProps } from './type';

export type QuantityProps<T> = ViewProps &
  IQuantityStepperProps<T> & {
    size?: UiTextProps['size'];
  };

export const Quantity = <T,>(props: QuantityProps<T>) => {
  const {
    accessibilityHint,
    defaultValue,
    disabled,
    value,
    onChangeValue,
    options,
    label,
    customLabelComponent,
    size = 'md',
    ...viewProps
  } = props;

  const context = useMemo(() => {
    if (__DEV__ && defaultValue != null && !options.find(opt => opt.value === defaultValue)) {
      throw new Error(
        `defaultValue: ${defaultValue} is not one of the options provided to the options array`
      );
    }

    const resolvedValue = value ?? defaultValue ?? options[0]?.value;

    return {
      accessibilityHint,
      disabled,
      value: resolvedValue,
      onChangeValue,
      options,
      label,
      customLabelComponent,
      size,
    };
  }, [
    accessibilityHint,
    defaultValue,
    disabled,
    value,
    onChangeValue,
    options,
    label,
    customLabelComponent,
    size,
  ]);

  return (
    <QuantityContext.Provider value={context}>
      <XStack {...viewProps} style={[styles.container, viewProps.style]} />
    </QuantityContext.Provider>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    gap: 16,
  },
});
