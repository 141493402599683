import { type ReactNode, forwardRef, useEffect, useRef } from 'react';
import { Platform, ScrollView, type ScrollViewProps, StyleSheet, View } from 'react-native';

import { useMenuClickEvents } from '@fhs/menu/src/analytics';

import { tokens } from '../../tokens';
import { Pressable, PressableProps, usePressableState } from '../pressable';
import { Text } from '../text';

import { useAnchors } from './use-anchors';
import { useScrollIntoFirstPositionInView } from './use-scroll-into-first-position-in-view';

const SCROLL_BUFFER = 48;

type HorizontalScrollerProps = Omit<
  ScrollViewProps,
  'horizontal' | 'showsHorizontalScrollIndicator' | 'alwaysBounceHorizontal' | 'bounces'
>;

const HorizontalScroller = forwardRef<ScrollView, HorizontalScrollerProps>(
  (props, forwardedRef) => {
    return (
      <ScrollView
        testID="anchor-list-horizontal"
        ref={forwardedRef}
        {...props}
        horizontal
        alwaysBounceHorizontal={false}
        bounces={false}
        showsHorizontalScrollIndicator={false}
        style={[horizontalScrollerStyles.scrollView, props.style]}
        contentContainerStyle={[
          horizontalScrollerStyles.scrollViewContent,
          props.contentContainerStyle,
        ]}
      >
        {props.children}

        <SpaceFiller />
      </ScrollView>
    );
  }
);

const SpaceFiller = () => <View style={horizontalScrollerStyles.spaceFiller} />;

const horizontalScrollerStyles = StyleSheet.create({
  scrollView: {
    flexGrow: 0,
    backgroundColor: tokens.colors.$white,
    minWidth: '100%',
    //@ts-ignore Web only style
    ...Platform.select({
      web: {
        overscrollBehavior: 'none',
      },
      default: {},
    }),
  },
  scrollViewContent: {
    minWidth: '100%',
  },
  spaceFiller: {
    flexGrow: 9999,
    flexShrink: 0,
    borderBottomWidth: 3,
    borderColor: tokens.colors.$blackOpacity10,
  },
});

export type AnchorListHorizontalProps = HorizontalScrollerProps & {
  contentStart?: ReactNode;
  contentEnd?: ReactNode;
};
export function AnchorListHorizontal({
  contentStart,
  contentEnd,
  ...horizontalScrollerProps
}: AnchorListHorizontalProps) {
  const { handleScrollerLayout, handleScrollerScroll, scrollerRef, scrollIntoFirstPositionInView } =
    useScrollIntoFirstPositionInView('horizontal');

  const anchors = useAnchors();
  const { logMenuTabClick } = useMenuClickEvents();

  return (
    <HorizontalScroller
      ref={scrollerRef}
      onLayout={handleScrollerLayout}
      onScroll={handleScrollerScroll}
      scrollEventThrottle={200}
      {...horizontalScrollerProps}
    >
      {contentStart ? contentStart : null}
      {anchors.map((a, index) => (
        <Anchor
          key={`${a.id}-${index}`}
          isActive={a.isActive}
          onPress={() => {
            a.onPress();
            logMenuTabClick(a.title);
          }}
          title={a.title}
          scrollIntoFirstPositionInView={scrollIntoFirstPositionInView}
        />
      ))}
      {contentEnd ? contentEnd : null}
    </HorizontalScroller>
  );
}

const Anchor = (props: {
  isActive: boolean;
  onPress: PressableProps['onPress'];
  title: string;
  scrollIntoFirstPositionInView: (opts: { position: number; offset?: number }) => void;
}) => {
  const ref = useRef<View>(null);
  const layoutRef = useRef({ x: 0, y: 0, height: 0, width: 0 });

  const { isActive, scrollIntoFirstPositionInView } = props;
  useEffect(() => {
    if (!isActive) {
      ref.current?.blur?.();
      return;
    }

    scrollIntoFirstPositionInView({
      position: layoutRef.current.x,
      offset: SCROLL_BUFFER,
    });
  }, [isActive, scrollIntoFirstPositionInView]);

  return (
    <Pressable
      ref={ref}
      onLayout={event => {
        layoutRef.current = event.nativeEvent.layout;
      }}
      onPress={props.onPress}
      borderRadius={0}
      style={[styles.pressable, props.isActive && styles.isActivePressable]}
      focusedStyle={styles.isFocusedPressable}
      hoveredStyle={styles.isHoveredPressable}
      outlineStyle={styles.hideOutline}
      outlinePressedStyle={styles.hideOutline}
      outlineFocusedStyle={styles.hideOutline}
      outlineDisabledStyle={styles.hideOutline}
      outlineHoveredStyle={styles.hideOutline}
    >
      <Title isActive={props.isActive} title={props.title} />
    </Pressable>
  );
};

const Title = ({ isActive, title }: { isActive: boolean; title: string }) => {
  const pressableState = usePressableState();
  return (
    <Text.Ui
      size="md"
      weight="semibold"
      style={[isActive && styles.isActiveText, pressableState.hovered && styles.isHoveredText]}
    >
      {title}
    </Text.Ui>
  );
};

const styles = StyleSheet.create({
  pressable: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderWidth: 0,
    borderBottomWidth: 3,
    borderColor: tokens.colors.$blackOpacity10,
  },
  isActivePressable: {
    borderColor: tokens.colors.$houseRedDarken,
  },
  isHoveredPressable: {
    borderColor: tokens.colors.$houseRedDarken,
  },
  isFocusedPressable: {
    borderColor: tokens.colors.$houseRedDarken,
  },
  isActiveText: {
    color: tokens.colors.$houseRedDarken,
  },
  isHoveredText: {
    color: tokens.colors.$houseRedDarken,
  },
  isFocusedText: {
    color: tokens.colors.$houseRedDarken,
  },
  hideOutline: {
    borderColor: tokens.colors.$transparent,
  },
});
