/// <reference types="@types/googlepay" />
import { useCallback, useMemo, useState } from 'react';

import { useConfigValue } from 'hooks/configs/use-config-value';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { IGooglePayDetails } from 'state/payment/types';
import { brand, env, isSafari } from 'utils/environment';
import logger from 'utils/logger';

import { IRequestGooglePayPayment, IUseGooglePay } from '../types';

import getDecoratedPaymentData from './get-decorated-payment-data';
import { WORLDPAY_GATEWAY, googlePayCardDetails } from './google-pay-payment-details';

export interface IGooglePayResult {
  result?: {
    paymentMethodData?: google.payments.api.PaymentMethodData;
  };
  error?: any;
}

const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
};

const getGooglePaymentDataRequest = ({
  cardPaymentMethod,
  countryCode,
  currencyCode,
  total,
  merchantName,
  merchantId,
}: {
  cardPaymentMethod: google.payments.api.PaymentMethodSpecification;
  countryCode: string;
  currencyCode: string;
  total: string;
  merchantName: string;
  merchantId: string;
}) => {
  const paymentDataRequest: google.payments.api.PaymentDataRequest = {
    ...baseRequest,
    allowedPaymentMethods: [cardPaymentMethod],
    transactionInfo: {
      countryCode,
      currencyCode,
      totalPriceStatus: 'FINAL',
      totalPrice: total,
    },
    merchantInfo: {
      merchantId,
      merchantName,
    },
  };
  return paymentDataRequest;
};

const makePaymentRequest = async ({
  tokenizationSpecification,
  countryCode,
  currencyCode,
  total,
  environment,
  networks,
  merchantId,
  merchantName,
}: {
  environment: string;
  networks: google.payments.api.CardNetwork[];
  tokenizationSpecification: google.payments.api.PaymentMethodTokenizationSpecification;
  gateway: string;
  countryCode: string;
  currencyCode: string;
  total: string;
  merchantId: string;
  merchantName: string;
}): Promise<IGooglePayResult> => {
  const env = environment === 'test' ? 'TEST' : 'PRODUCTION';
  let paymentClient: any = { environment: env };
  if (env === 'PRODUCTION') {
    paymentClient = {
      ...paymentClient,
      merchantInfo: {
        merchantName,
        merchantId,
      },
    };
  }
  const paymentsClient = new google.payments.api.PaymentsClient(paymentClient);
  const baseCardPaymentMethod: google.payments.api.IsReadyToPayPaymentMethodSpecification = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
      allowedCardNetworks: networks,
      assuranceDetailsRequired: true,
    },
  };
  const cardPaymentMethod: google.payments.api.PaymentMethodSpecification = {
    ...baseCardPaymentMethod,
    tokenizationSpecification,
  };
  const isReadyToPayRequest = {
    ...baseRequest,
    allowedPaymentMethods: [baseCardPaymentMethod],
    existingPaymentMethodRequired: false,
  };
  try {
    const isReadyToPay = await paymentsClient.isReadyToPay(isReadyToPayRequest);
    if (isReadyToPay.result) {
      const paymentDataRequest = getGooglePaymentDataRequest({
        cardPaymentMethod,
        countryCode,
        currencyCode,
        total,
        merchantId,
        merchantName,
      });
      const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest);
      return {
        result: {
          paymentMethodData: paymentData.paymentMethodData,
        },
      };
    }
  } catch (error) {
    return { error: `Error processing google pay: ${error}` };
  }
  return { error: 'Failed processing google pay' };
};

export default function useGooglePay(args: IUseGooglePay = {}) {
  const locale = useLocale();
  const isGooglePayEnable = useFlag(LaunchDarklyFlag.ENABLE_GOOGLE_PAY);
  const canUseGooglePay = isSafari() ? false : isGooglePayEnable;
  const [requestingPayment, setRequestingPayment] = useState(false);
  const googleConfig = useConfigValue({ key: 'google', defaultValue: {} });
  const googleConfigNetworks = googleConfig.paymentsNetworks;
  const networks = useMemo(() => {
    return googleConfigNetworks?.filter((i: unknown) => !!i) ?? [];
  }, [googleConfigNetworks]);
  const environment = googleConfig.paymentsEnvironment;
  const merchantId = googleConfig.googleMerchantId;
  const merchantName = googleConfig.googleMerchantName;
  const requestGooglePayPayment = useCallback(
    async ({
      countryCode,
      currencyCode,
      total,
      gateway,
      gatewayMerchantId,
    }: IRequestGooglePayPayment): Promise<IGooglePayDetails | void> => {
      try {
        setRequestingPayment(true);
        let response: IGooglePayResult;
        if (gateway === WORLDPAY_GATEWAY) {
          const merchantOrderId = args.rbiOrderId?.slice(0, 20) || '';
          const merchantTransactionId = merchantOrderId || '';
          const merchantReportGroup = `${env()}-${brand()}-${locale.region}`.toUpperCase();
          const tokenizationSpecification: google.payments.api.PaymentMethodTokenizationSpecification =
            {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway,
                'vantiv:merchantPayPageId': gatewayMerchantId,
                'vantiv:merchantOrderId': merchantOrderId,
                'vantiv:merchantTransactionId': merchantTransactionId,
                'vantiv:merchantReportGroup': merchantReportGroup,
              },
            };
          response = await makePaymentRequest({
            networks,
            environment,
            tokenizationSpecification,
            gateway,
            countryCode,
            currencyCode,
            total: (total / 100).toString(),
            merchantName,
            merchantId,
          });
        } else {
          const tokenizationSpecification: google.payments.api.PaymentMethodTokenizationSpecification =
            {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway,
                gatewayMerchantId,
              },
            };
          response = await makePaymentRequest({
            networks,
            environment,
            tokenizationSpecification,
            gateway,
            countryCode,
            currencyCode,
            total: (total / 100).toString(),
            merchantName,
            merchantId,
          });
        }

        const { result, error } = response;

        if (error) {
          throw error;
        }

        setRequestingPayment(false);

        if (!result || !result.paymentMethodData) {
          if (args.onCompleted) {
            args.onCompleted();
          }
          return;
        }

        const {
          tokenizationData: { token },
          info: cardInfo,
          description: displayName = '',
        } = result.paymentMethodData;

        if (!cardInfo) {
          throw new Error('Failed to get card info');
        }

        const {
          billingAddress: billingInformation = {
            locality: '',
            postalCode: '',
            administrativeArea: '',
            address1: '',
            countryCode: '',
          },
          cardNetwork: paymentType,
        } = cardInfo;

        const {
          locality,
          postalCode,
          administrativeArea: region,
          address1: streetAddress,
          countryCode: country,
        } = billingInformation;

        const decoratedPaymentData = getDecoratedPaymentData({
          locality,
          postalCode,
          region,
          streetAddress,
          country,
          gateway,
          token,
          countryCode,
          paymentType,
          displayName,
        });

        if (args.onCompleted) {
          args.onCompleted(decoratedPaymentData);
        }
        return decoratedPaymentData;
      } catch (error) {
        setRequestingPayment(false);
        if (args.onError) {
          args.onError(error);
        }
        logger.error({
          message: 'Device Google Payment Failed',
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          error: error.message,
        });
        return Promise.reject(error);
      }
    },
    [args, environment, locale.region, networks]
  );

  return {
    googlePayCardDetails,
    requestingPayment,
    requestGooglePayPayment,
    canUseGooglePay,
  };
}
