import * as React from 'react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { VStack } from '@fhs-legacy/universal-components';
import { ITransactionFragment } from 'generated/graphql-gateway';

import { DateAndTimeCell } from './datetime-cell';
import { parseLoyaltyTransaction } from './parseLoyaltyTransactions';
import { PointsLine } from './points-line';
import { PurchaseAmount, Rewards, StyledCurrency } from './transaction.styled';
import { TransactionChannel } from './types';
import { getTransactionMessageId } from './utils';

export const useLocalizedTransactionTransformer = () => {
  const { formatMessage } = useIntl();

  const transactionTransformer = useCallback(
    (loyaltyCardTransaction: ITransactionFragment) => {
      const {
        totalPayment,
        channel,
        pointsEarned,
        pointsUsed,
        pointsDeducted,
        bonusPointsEarned,
        timeStamp,
        rbiOrderId,
        hasRewards,
      } = parseLoyaltyTransaction(loyaltyCardTransaction);

      const purchaseAmount = (
        <PurchaseAmount>
          <StyledCurrency amount={totalPayment} />
        </PurchaseAmount>
      );

      // the transactions could have points: earned, deducted or used
      // any of those scenarios we display the points message
      const displayPointsMessage = Boolean(
        bonusPointsEarned || pointsEarned || pointsUsed || pointsDeducted || hasRewards
      );
      const pointsMessageId = getTransactionMessageId(channel);
      // if there are no points involved in the transaction means
      // that the points were expired or the user earned 0 points
      const noPointsMessage =
        channel === TransactionChannel.PointsExpiration
          ? formatMessage({ id: 'pointsExpired' }, { points: 0 })
          : formatMessage({ id: 'pointsEarnedLabel' }, { points: 0 });

      const rewards = (
        <Rewards>
          {displayPointsMessage && (
            <VStack space="$2.5">
              {!!pointsEarned && (
                <PointsLine
                  points={pointsEarned}
                  description={formatMessage({ id: pointsMessageId }, { points: pointsEarned })}
                />
              )}
              {!!pointsDeducted && (
                <PointsLine
                  points={-pointsDeducted}
                  description={formatMessage({ id: pointsMessageId }, { points: pointsDeducted })}
                />
              )}
              {!!bonusPointsEarned && (
                <PointsLine
                  points={bonusPointsEarned}
                  description={formatMessage(
                    { id: 'bonusPointsLabel' },
                    { points: bonusPointsEarned }
                  )}
                />
              )}
              {hasRewards && (
                <PointsLine
                  points={-pointsUsed}
                  description={formatMessage({ id: 'pointsUsed' }, { points: pointsUsed })}
                />
              )}
            </VStack>
          )}
          {!displayPointsMessage && <PointsLine points={0} description={noPointsMessage} />}
        </Rewards>
      );

      const dateTime = <DateAndTimeCell timeStamp={timeStamp} />;

      return { timeStamp, dateTime, rewards, purchaseAmount, rbiOrderId };
    },
    [formatMessage]
  );

  return transactionTransformer;
};
