import { Radio as NbRadio } from '@fhs-legacy/native-base';

import { addWithConfig } from '../../utils';

import type { RadioProps } from './types';

export const Radio = (props: RadioProps) => <NbRadio size="lg" {...props} />;

Radio.Group = NbRadio.Group;

const RadioWithConfig = addWithConfig(Radio);
RadioWithConfig.Group = addWithConfig(Radio.Group);

export default RadioWithConfig;
