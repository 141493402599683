import { router } from 'expo-router';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import FullPageScreen from 'components/full-page-screen';
import StaticMap from 'components/static-map';
import { usePathname } from 'hooks/use-pathname';
import useRoutes from 'hooks/use-routes';
import { useCommitOrder } from 'pages/cart/payment/order-payment/use-commit-order';
import { useOrderPayment } from 'pages/cart/payment/order-payment/use-order-payment';
import { logCartPlaceOrder } from 'pages/cart/payment/utils';
import { PlaceOrderButton } from 'pages/cart/place-order-button';
import { LoyaltyRewardsBalance } from 'pages/loyalty/loyalty-rewards-balance';
import { useCartContext } from 'state/cart';
import { useCRMEventsContext } from 'state/crm-events';
import { actions, useAppDispatch } from 'state/global-state';
import { ServiceMode } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { useUIContext } from 'state/ui';
import { getUSState } from 'utils/restaurant';
import { routes } from 'utils/routing';
import { CART_STORE_CONFIRMATION_PAGE } from 'utils/test-ids';

import {
  Address,
  ButtonContainer,
  ConfirmStoreSection,
  Inner,
  LocationContainer,
  MapContainer,
  StreetAddress,
  StyledLink,
  StyledPicture,
  Title,
} from './styled';

const serviceModeMessageMap = new Map<ServiceMode, string>([
  [ServiceMode.CATERING_DELIVERY, 'storeConfirmationCateringDelivery'],
  [ServiceMode.CATERING_PICKUP, 'storeConfirmationCateringPickup'],
  [ServiceMode.CURBSIDE, 'storeConfirmationCurbside'],
  [ServiceMode.DELIVERY, 'storeConfirmationDelivery'],
  [ServiceMode.DRIVE_THRU, 'storeConfirmationDriveThru'],
  [ServiceMode.EAT_IN, 'storeConfirmationEatIn'],
  [ServiceMode.TAKEOUT, 'storeConfirmationPickup'],
]);

const isSwitchingStoresWithinCart = (currentPathname: string) => {
  return currentPathname.includes(routes.cart);
};

const StoreConfirmationModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { store } = useStoreContext();
  const { shouldConfirmStoreOnCheckout } = useUIContext();
  const { physicalAddress, restaurantImage } = store;
  const dispatch = useAppDispatch();

  const streetAddress = physicalAddress?.address1 ?? '';
  const formattedCity = physicalAddress?.city ?? '';
  const state = getUSState(physicalAddress?.stateProvince ?? '');
  const postalCode = (physicalAddress?.postalCode ?? '').split('-')[0];
  const { formatMessage } = useIntl();
  const { getLocalizedRouteForPath } = useRoutes();
  const { logRBIEvent } = useCRMEventsContext();
  // TODO: expo-router do we need this?
  const setStoreLocatorCallbackUrl = useCallback((_: any) => {
    __DEV__ && alert('needs implementation StoreConfirmationModal');
  }, []);
  const pathname = usePathname();
  // const { setStoreLocatorCallbackUrl } = useLocationContext();
  const { serviceMode, serverOrder, tryPricing } = useCartContext();

  const orderPayment = useOrderPayment({
    serverOrder,
    onPriceOrder: tryPricing,
  });
  const { commitOrder, isCommiting, ErrorDialog } = useCommitOrder(orderPayment);

  const chooseDifferentLocation = useCallback(() => {
    if (isCommiting) {
      return;
    }
    shouldConfirmStoreOnCheckout(false);
    const cartRoute = routes.cart;
    const redirectUrl = isSwitchingStoresWithinCart(pathname) ? cartRoute : pathname;
    setStoreLocatorCallbackUrl(redirectUrl);
    router.navigate(getLocalizedRouteForPath('store-locator'));
  }, [
    isCommiting,
    shouldConfirmStoreOnCheckout,
    pathname,
    setStoreLocatorCallbackUrl,

    getLocalizedRouteForPath,
  ]);

  const handleConfirm = () => {
    logCartPlaceOrder(logRBIEvent);
    // Set shouldRefetchOffers to true on order
    dispatch(actions.loyalty.setShouldRefetchOffers(true));
    commitOrder();
  };

  const serviceModeMessage = serviceMode
    ? serviceModeMessageMap.get(serviceMode)
    : 'defaultOrderConfirmation';

  return (
    <FullPageScreen
      heading={formatMessage({ id: 'confirmStore' })}
      headerBottom={<LoyaltyRewardsBalance />}
      closeHref={routes.cart}
      testID={CART_STORE_CONFIRMATION_PAGE}
    >
      <ConfirmStoreSection>
        {restaurantImage ? (
          <StyledPicture
            image={restaurantImage}
            alt={store.name ?? 'Restaurant Name Missing'}
            // TODO: RN - Add testID to Picture props
            // testID="store-confirmation-restaurant-image"
          />
        ) : (
          <MapContainer accessibilityLabel="MapContainer">
            <StaticMap
              // TODO: RN - Add testID to StaticMap props
              // testID="store-confirmation-static-map"
              initialZoomIOS={8000}
              position={{
                lat: store.latitude ?? 0,
                lng: store.longitude ?? 0,
              }}
            />
          </MapContainer>
        )}
        <Inner accessibilityLabel="Inner">
          <Title>{formatMessage({ id: 'confirmYourStore' })}</Title>
          <LocationContainer accessibilityLabel="store-confirmation-location street-address city-state-zip-address">
            <StreetAddress nativeID="street-address">
              {formatMessage(
                { id: serviceModeMessage },
                {
                  location: streetAddress,
                }
              )}
            </StreetAddress>
            <Address nativeID="city-state-zip-address">
              {formattedCity} {state} {postalCode}
            </Address>
          </LocationContainer>
          <ButtonContainer>
            <PlaceOrderButton
              confirmation
              onPress={handleConfirm}
              isLoading={isCommiting}
              isDisabled={isCommiting}
            />
          </ButtonContainer>
          <StyledLink onPress={chooseDifferentLocation}>
            {formatMessage({ id: 'confirmationDifferentLocation' })}
          </StyledLink>
        </Inner>
        <ErrorDialog />
      </ConfirmStoreSection>
    </FullPageScreen>
  );
};

export default StoreConfirmationModal;
