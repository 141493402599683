import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconRewardsTrophy = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M19.778 4.323h-2.222V2.1H6.444v2.222H4.222C3 4.323 2 5.323 2 6.545v1.111c0 2.833 2.133 5.145 4.878 5.49a5.566 5.566 0 0 0 4.01 3.288v3.444H6.445v2.223h11.112v-2.223H13.11v-3.444a5.566 5.566 0 0 0 4.011-3.289C19.867 12.801 22 10.49 22 7.656v-1.11c0-1.223-1-2.223-2.222-2.223ZM4.222 7.656v-1.11h2.222v4.244c-1.288-.467-2.222-1.69-2.222-3.134Zm15.556 0c0 1.445-.934 2.667-2.222 3.134V6.545h2.222v1.111Z"
      />
    </Svg>
  );
};
