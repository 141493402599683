import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { InlineAlert, Skeleton } from '@fhs-legacy/universal-components';
import { PickFutureTimeModal } from 'components/pick-future-time-modal';
import { calculateFireOrderInSeconds } from 'components/pick-future-time-modal/utils';
import { useDatePickerOptions } from 'hooks/use-date-picker-options';
import { IDatePickerData, ISelectedDate } from 'hooks/use-date-picker-options/types';
import { FutureOrderTimeCard } from 'pages/cart/future-order-time-card';
import { useCartContext } from 'state/cart';
import { selectors, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyContext } from 'state/loyalty';
import { useOrderContext } from 'state/order';
import { usePaymentContext } from 'state/payment';
import { useStoreContext } from 'state/store';
import { mapPaymentMethodToLoyaltyPaymentMethods } from 'utils/payment';
import { isCatering } from 'utils/service-mode';

import { SectionContainer, SectionTitle } from './styed';

export const DayAndTimeSelector = () => {
  const { serviceMode, pricedAndAvailable, cartEntries, calculateCartTotalWithDiscount } =
    useCartContext();
  const { checkoutPaymentMethod } = usePaymentContext();
  const { isStoreOpenAndAvailable } = useStoreContext();
  const appliedLoyaltyOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const appliedRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const loyaltyId = useAppSelector(selectors.loyalty.selectLoyaltyUserId);
  const { evaluateLoyaltyUserIncentives, isOfferValidationError } = useLoyaltyContext();
  const { datePickerData, isLoadingData, firstOptionFormatted, hasError } = useDatePickerOptions();
  const orderCtx = useOrderContext();

  const [showFutureTimeModal, setShowFutureTimeModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<ISelectedDate>();
  const [updatedPickerData, setUpdatedPickerData] = useState<IDatePickerData[] | undefined>();

  const { formatMessage } = useIntl();

  const sectionTitle = formatMessage({ id: 'pickUpTimeAlt' });

  const onOptionSelected = (date: ISelectedDate | undefined) => {
    setSelectedDate(date);

    if (date) {
      const pickUpTime = new Date(
        Date.UTC(date.year, date.month, date.day, date.hour, date.minutes)
      ).toISOString();
      // Sets the updated fireOrderIn value based on the selected day and time
      orderCtx.setFireOrderIn(calculateFireOrderInSeconds(date));
      const { cartTotal } = calculateCartTotalWithDiscount();
      const paymentMethod = mapPaymentMethodToLoyaltyPaymentMethods(checkoutPaymentMethod);
      if (appliedLoyaltyOffers.length > 0) {
        evaluateLoyaltyUserIncentives({
          loyaltyId,
          appliedLoyaltyOffers,
          cartEntries,
          appliedRewards,
          subtotalAmount: cartTotal,
          paymentMethod,
          skipTimeValidation: false,
          pickUpTime,
        });
      }
    }
  };

  const enableCatering = useFlag(LaunchDarklyFlag.ENABLE_CATERING);
  const isCateringOrder = isCatering(serviceMode);
  const showCateringPickUpAlert = enableCatering && isCateringOrder;
  const showFutureOrderingWarning = !isStoreOpenAndAvailable && !isCateringOrder;

  useEffect(() => {
    if (datePickerData) {
      onOptionSelected(firstOptionFormatted);
      setUpdatedPickerData(datePickerData);
    }
  }, [datePickerData, firstOptionFormatted]);
  const shouldShowLoadingAnimation = isLoadingData || !datePickerData || !updatedPickerData;

  if (!isLoadingData && !pricedAndAvailable) {
    return null;
  }

  return (
    <SectionContainer>
      <SectionTitle>{sectionTitle}</SectionTitle>
      {shouldShowLoadingAnimation ? (
        <Skeleton h="$16" borderRadius={10} />
      ) : (
        <>
          <PickFutureTimeModal
            isOpen={showFutureTimeModal}
            onClose={() => setShowFutureTimeModal(false)}
            onPickupTimeCallback={onOptionSelected}
            availableDates={updatedPickerData}
          />
          {showFutureOrderingWarning && (
            <InlineAlert
              status="warning"
              borderRadius={8}
              marginBottom="$2"
              message={formatMessage({ id: 'futureOrderingWarning' })}
            />
          )}
          {showCateringPickUpAlert && (
            <InlineAlert
              status="warning"
              borderRadius={8}
              marginBottom="$2"
              message={formatMessage({ id: 'cateringPickUpDate' })}
            />
          )}
          <FutureOrderTimeCard
            date={selectedDate}
            onPress={() => setShowFutureTimeModal(true)}
            readOnly={false}
            hasError={hasError}
            hasOfferValidationError={isOfferValidationError}
          />
          {isOfferValidationError && (
            <InlineAlert
              status="error"
              borderRadius={8}
              marginTop="$2"
              message={formatMessage({ id: 'futureOrderingOfferValidationError' })}
            />
          )}
        </>
      )}
    </SectionContainer>
  );
};
