import type { IOffer as ILegacyOfferCartIncentive } from '@rbi-ctg/menu';
import { LoyaltyInRestaurantIncentiveType as ICartEntryType } from 'generated/graphql-gateway';
import type {
  LoyaltyOffer as IOfferCartIncentive,
  LoyaltyReward as IRewardCartIncentive,
  LoyaltyEngineReward,
} from 'state/loyalty/types';

export type { IOffer as ILegacyOfferCartIncentive } from '@rbi-ctg/menu';
export type {
  LoyaltyOffer as IOfferCartIncentive,
  LoyaltyReward as IRewardCartIncentive,
} from 'state/loyalty/types';
export { ICartEntryType };

// helper type
type ArrayElement<ArrayType extends unknown[]> = ArrayType extends (infer ElementType)[]
  ? ElementType
  : never;

export type IEngineRewardCartIncentive = NonNullable<LoyaltyEngineReward>;
export type ICartEntryDetailsReward = {
  reward: IRewardCartIncentive;
  engineReward: IEngineRewardCartIncentive;
};
export type ICartEntryDetailsOffer = {
  offer: IOfferCartIncentive;
};
export type ICartEntryDetailsLegacyOffer = {
  offer: ILegacyOfferCartIncentive;
};

export type ICartEntryDetails =
  | ICartEntryDetailsReward
  | ICartEntryDetailsLegacyOffer
  | ICartEntryDetailsOffer;

export interface ICartEntryBase {
  // a unique id to reference the cart entry
  referenceId: string;
  // cart entry quantity
  quantity: number;
  // cart entry type, it represents the redemption object that was used to create the cart entry
  type: ICartEntryType;
  // object to store all the needed data to help the item processing step
  details: ICartEntryDetails;
}

export interface ICartEntryReward extends ICartEntryBase {
  type: ICartEntryType.REWARD;
  details: ICartEntryDetailsReward;
}
export interface ICartEntryOffer extends ICartEntryBase {
  type: ICartEntryType.OFFER;
  details: ICartEntryDetailsOffer;
}
export interface ICartEntryLegacyOffer extends ICartEntryBase {
  type: ICartEntryType.LEGACY_OFFER;
  details: ICartEntryDetailsLegacyOffer;
}

export interface ICreateCartEntryRewardParams {
  reward: IRewardCartIncentive;
  engineReward: IEngineRewardCartIncentive;
}
export interface ICreateCartEntryOfferParams {
  offer: IOfferCartIncentive;
}
export interface ICreateCartEntryLegacyOfferParams {
  offer: ILegacyOfferCartIncentive;
}
export type ICreateCartEntryParams =
  | ICreateCartEntryRewardParams
  | ICreateCartEntryOfferParams
  | ICreateCartEntryLegacyOfferParams;

export interface ICreateCartEntryOptions {
  initialQuantity?: number;
}

export type ICart = (ICartEntryReward | ICartEntryOffer | ICartEntryLegacyOffer)[];
export type ICartEntry = ArrayElement<ICart>;

// type guards
export const isOffer = (entity: any): entity is IOfferCartIncentive =>
  entity?._type === 'systemwideOffer' || entity?._type === 'configOffer';
export const isLegacyOffer = (entity: any): entity is ILegacyOfferCartIncentive =>
  entity?._type === 'offer';
export const isReward = (entity: any): entity is IRewardCartIncentive =>
  entity?.__typename?.toLowerCase() === 'reward' && !!entity?._id;
export const isEngineReward = (entity: any): entity is IEngineRewardCartIncentive =>
  entity?.__typename?.toLowerCase() === 'loyaltyreward' && !!entity?.id;
