import React from 'react';

import { Box, Icon } from '@fhs-legacy/universal-components';

import { DELIVERY_PROGRESS_COLOR } from './constants';

// This adds a backfill for the icon viewbox
const backfillMargin = '3px';
const Backfill = Box.withConfig({
  position: 'absolute',
  background: Styles.color.background,
  borderRadius: 'full',
  top: backfillMargin,
  left: backfillMargin,
  right: backfillMargin,
  bottom: backfillMargin,
  zIndex: 9,
});

interface ProgressCheckpointProps {
  isActive: boolean;
}

const ProgressCheckpoint: React.VFC<ProgressCheckpointProps> = ({ isActive }) => {
  return (
    <Box marginBottom="$2">
      <Backfill />
      <Icon
        variant="checkFill"
        color={isActive ? DELIVERY_PROGRESS_COLOR : Styles.color.grey.four}
        zIndex={9}
      />
    </Box>
  );
};

export default ProgressCheckpoint;
