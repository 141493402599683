import { StyleSheet, View, ViewProps } from 'react-native';

import { tokens } from '../../tokens';
import { Text } from '../text/text';

export type QuantityBadgeProps = Omit<ViewProps, 'children'> & {
  text: string;
};
export function QuantityBadge({ text, ...props }: QuantityBadgeProps) {
  return (
    <View {...props} style={[styles.badge, props.style]}>
      <Text weight="semibold" style={styles.badgeText}>
        {text}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  badge: {
    position: 'absolute',
    backgroundColor: tokens.colors.$houseDark,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 6,
    minHeight: 20,
    minWidth: 20,
  },

  badgeText: {
    color: tokens.colors.$white,
    fontVariant: ['tabular-nums'],
    fontSize: 10,
    lineHeight: 14,
  },
});
