import { addDays, differenceInMinutes, isBefore, isYesterday } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import { IRestaurantTimeSlot, useGetRestaurantQuery } from 'generated/graphql-gateway';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { useOpenClosedText } from 'pages/store-locator/new-ui/store-card/hooks/use-open-closed-text';
import { useCartContext } from 'state/cart';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { OrderStatus } from 'state/order';
import { useStoreContext } from 'state/store';
import { isCatering } from 'utils/service-mode';

import { MINUTE_IN_MS } from './constants';
import { futureOrderingOptions, timeFiredOptions } from './options';
import {
  IDatePickerData,
  IDatePickerOptions,
  ISelectedDate,
  IServerOrderWithPickupTime,
  ITimePickerData,
} from './types';
import {
  getAvailableTimeOptionsFormatted,
  getFirstOptionFormatted,
  getIsFirstDayNotAvailable,
  getOptions,
  getSanitizedTimeSlots,
  getStartingDate,
  getStoreData,
} from './utils';

export const useDatePickerOptions = (): IDatePickerOptions => {
  const enableFutureOrdering = useFlag(LaunchDarklyFlag.ENABLE_FUTURE_ORDERING);
  const enableTodayAvailableOptionsFromBackend = useFlag(
    LaunchDarklyFlag.ENABLE_TODAY_AVAILABLE_PICKUP_OPTIONS_FROM_BACKEND
  );

  const { serverOrder, serviceMode, orderStatus } = useCartContext();
  const { store } = useStoreContext();

  const { data } = useGetRestaurantQuery({
    variables: { storeNumber: store.number },
    skip: !store,
  });

  // Use the earliestPickupTimeInSeconds only if serviceMode is not catering since catering has an special lead time (i.e 24 hours)
  const earliestPickupTimeInSeconds = isCatering(serviceMode)
    ? 0
    : (serverOrder as IServerOrderWithPickupTime)?.earliestPickupTimeInSeconds;

  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [datePickerData, setDatePickerData] = useState<IDatePickerData[]>();
  const [firstOptionFormatted, setFirstOptionFormatted] = useState<ISelectedDate>();

  const { isOpen: isRestaurantOpen } = useOpenClosedText(store as IRestaurantNode);

  const getDatePickerData = useCallback(() => {
    if (!data || data.restaurant?.futureServiceHours.length === 0) {
      return undefined;
    }

    const storeData = getStoreData(data, serviceMode);

    const options = enableFutureOrdering ? futureOrderingOptions : timeFiredOptions;

    // Early return in case there are no available time slots for the selected service mode
    if (!storeData?.timeSlots?.length) {
      return undefined;
    }

    // We make sure dates are in the correct order
    const sanitizedTimeSlots = getSanitizedTimeSlots(
      storeData?.timeSlots as IRestaurantTimeSlot[],
      options.days,
      isCatering(serviceMode)
    );

    let dateData: IDatePickerData[] = [];

    // Loop through all timeSlots to generate the available day/time options
    sanitizedTimeSlots.forEach((timeSlot, index) => {
      const openTime = timeSlot?.opens || '';
      const closeTime = timeSlot?.closes || '';
      const dateTime = timeSlot?.date || '';
      const dateTimeWithTime = timeSlot?.date + 'T12:00:00';

      const timeSlotOpens = new Date(openTime);
      let timeSlotCloses = new Date(closeTime);

      // Update timeSlotCloses if the store closes past midnight (e.g. 2 am)
      const isYesterdaySlot = isYesterday(new Date(dateTimeWithTime));
      if (!isYesterdaySlot) {
        const storeClosesPastMidnight = isBefore(timeSlotCloses, timeSlotOpens);
        timeSlotCloses = storeClosesPastMidnight ? addDays(timeSlotCloses, 1) : timeSlotCloses;
      }
      // Generate time slots from now for today (if store is open) and from the open date for the rest of the week
      const startingDate = getStartingDate(
        isCatering(serviceMode),
        isRestaurantOpen,
        timeSlotOpens,
        index === 0,
        isYesterdaySlot
      );

      // We need to disable the first day in the array in case the store is already closed for the day
      const isFirstDayNotAvailable = getIsFirstDayNotAvailable(
        index,
        startingDate,
        timeSlotCloses,
        isCatering(serviceMode)
      );

      const lastPossibleSlot =
        timeSlotCloses.getTime() - options.minutesBeforeStoreCloses * MINUTE_IN_MS;

      const offset = enableFutureOrdering ? (earliestPickupTimeInSeconds ?? 0) / 60 : 0;
      const length = enableFutureOrdering
        ? differenceInMinutes(timeSlotCloses, startingDate) / options.timeInterval
        : options.length;

      const todayAvailableTimeOptions =
        (serverOrder as IServerOrderWithPickupTime)?.availableTimeOptions || [];

      let dateOptions: ITimePickerData[] = [];

      // Get available time optins from BE for today if flag is enabled
      if (
        enableTodayAvailableOptionsFromBackend &&
        index === 0 &&
        todayAvailableTimeOptions.length > 0
      ) {
        dateOptions = getAvailableTimeOptionsFormatted({
          availableTimeOptions: todayAvailableTimeOptions,
          lastPossibleSlot,
          startingDate,
          offset,
        });
      } else {
        dateOptions = getOptions({
          timeInterval: options.timeInterval,
          length,
          offset,
          startingDate,
          lastPossibleSlot,
        }) as ITimePickerData[];
      }

      dateData = [
        ...dateData,
        {
          date: dateTime,
          isDisabled: !dateOptions.length || timeSlot?.isClosed || isFirstDayNotAvailable || false,
          options: dateOptions,
        },
      ];
    });
    return dateData;
  }, [
    data,
    enableTodayAvailableOptionsFromBackend,
    serviceMode,
    enableFutureOrdering,
    earliestPickupTimeInSeconds,
    isRestaurantOpen,
    serverOrder,
  ]);

  useEffect(() => {
    if (data && data.restaurant && orderStatus === OrderStatus.PRICE_SUCCESSFUL) {
      const options = getDatePickerData();
      setDatePickerData(options);
      const firstOption = getFirstOptionFormatted(options);
      setFirstOptionFormatted(firstOption);
    }
  }, [getDatePickerData, serviceMode, data, orderStatus]);

  useEffect(() => {
    setIsLoadingData(true);

    if (
      orderStatus &&
      [
        OrderStatus.PRICE_SUCCESSFUL,
        OrderStatus.INSERT_SUCCESSFUL,
        OrderStatus.PRICE_ERROR,
      ].includes(orderStatus)
    ) {
      setIsLoadingData(false);
    }

    if (orderStatus === OrderStatus.PRICE_ERROR) {
      setHasError(true);
    }
  }, [orderStatus]);

  return {
    datePickerData,
    firstOptionFormatted,
    isLoadingData: !!isLoadingData,
    hasError,
  };
};
