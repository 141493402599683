import { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { useUiResources } from '@fhs/ui';
import NativeAuthStorage from 'utils/cognito/storage';
import { isWeb } from 'utils/environment';
import { WebLocalStorage } from 'utils/local-storage/web-local-storage';
import logger from 'utils/logger';

/**
 * -- Global App Initialization status --
 *
 * Loading - Blocks the app from rendering
 * Initialized - All data is there and the app can load without crashing
 * Error - Prevents the app from rendering at all - TODO: RN - Add better feedback to guests to resolve the errors (ex. Network errors)
 *
 * */
export enum initStatuses {
  LOADING = 'loading',
  INITIALIZED = 'initialized',
  ERROR = 'error',
}

export const useAppBlockerInit = () => {
  const [state, setState] = useState(isWeb ? initStatuses.INITIALIZED : initStatuses.LOADING);
  const [uiResourcesLoaded, uiResourcesError] = useUiResources();

  useEffect(() => {
    const prepare = async () => {
      try {
        await Promise.all([
          NativeAuthStorage.sync(), // this is called internally by Cognito.configure() but it doesn't give us a promise!
          WebLocalStorage.sync(),
        ]);

        setState(initStatuses.INITIALIZED);
      } catch (error) {
        logger.error({ error, message: 'useAppBlockerInit encountered an error' });
        setState(initStatuses.ERROR);
      }
    };
    prepare();
  }, []);

  useEffect(() => {
    if (uiResourcesError) {
      logger.error(uiResourcesError);
    }
  }, [uiResourcesError]);

  if (uiResourcesError && Platform.OS === 'web') {
    // On web, we need to ignore the `uiResourcesLoaded` when an error occurs.
    // this means the font failed to load, but we should still be able to continue forward.
    if (Platform.OS === 'web') {
      return state;
    }
    return initStatuses.ERROR;
  }
  return uiResourcesLoaded ? state : initStatuses.LOADING;
};
