import {
  IItem,
  IItemOption,
  IItemOptionModifier,
  IItemOptionModifierWithQuantity,
} from '@rbi-ctg/menu';

export interface IModifierSelections {
  [modifierOptionKey: string]: IItemOptionModifierWithQuantity;
}
export interface IModifierListItemProps {
  content: IItemOption | IItemOptionModifier;
  selectedOption?: IItemOptionModifier;
  options?: Array<IItemOptionModifier>;
  onListItemClick?: () => void;
  isListItemClickable?: boolean;
  controls: React.ReactNode;
  selectedItem: IItem;
}

export interface IModifierTypeUIProps {
  modifier: IItemOption;
  selectedItem: IItem;
  selections: IModifierSelections;
  onSelectionsChange: (option: IModifierSelections) => void;
}

export interface IModifierData {
  modifier: IItemOption;
  selections: IModifierSelections;
}

export interface IModifierProps extends IModifierData {
  onSelectionsChange: (selections: IModifierSelections) => void;
  selectedItem: IItem;
}
