import { addSeconds } from 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';

import { IHistoricalOrder } from '@rbi-ctg/menu';
import ActionButton from 'components/action-button';
import { ServiceMode } from 'state/order';
import { DeliveryStatus, OrderStatus } from 'state/order/types';
import { useCreateOrderAriaLabel } from 'utils/accessibility/aria';

interface IOrderActionProps {
  order: IHistoricalOrder;
  loading: boolean;
  disabled: boolean;
  onTrack: () => void;
  onReorder: () => void;
  onFinish: () => void;
}

const OrderAction = ({
  order,
  loading,
  disabled,
  onTrack,
  onReorder,
  onFinish,
}: IOrderActionProps) => {
  const { formatMessage } = useIntl();
  const isEnRoute = () => {
    const { delivery, cart } = order;
    if (!delivery) {
      return false;
    }
    const isDelivery = cart.serviceMode === ServiceMode.DELIVERY;
    const notEnRouteStatus = new Set([
      DeliveryStatus.ORDER_CANCELLED,
      DeliveryStatus.ORDER_ABANDONED,
      DeliveryStatus.ORDER_DROPPED_OFF,
    ]);
    return isDelivery && !notEnRouteStatus.has(delivery.status);
  };
  const createAriaLabel = useCreateOrderAriaLabel();

  const isFinish = () => {
    const { status, cart, createdAt, fireOrderIn } = order;
    const inFinishStatus = new Set([ServiceMode.TAKEOUT, ServiceMode.CURBSIDE]);
    const isActive = !!fireOrderIn && addSeconds(new Date(createdAt), fireOrderIn) > new Date();
    return (
      inFinishStatus.has(cart.serviceMode) && isActive && status === OrderStatus.INSERT_SUCCESSFUL
    );
  };

  if (isEnRoute()) {
    return (
      <ActionButton fullWidth testID="track-order-button" onPress={onTrack}>
        {formatMessage({ id: 'trackOrder' })}
      </ActionButton>
    );
  }

  if (isFinish()) {
    return (
      <ActionButton fullWidth testID="finish-order-button" onPress={onFinish} disabled={disabled}>
        {formatMessage({ id: 'finishOrder' })}
      </ActionButton>
    );
  }

  return (
    <ActionButton
      fullWidth
      testID="reorder-button"
      onPress={onReorder}
      isLoading={loading}
      disabled={disabled}
      aria-label={createAriaLabel({
        buttonType: 'reorder',
        order,
      })}
    >
      {formatMessage({ id: 'reorder' })}
    </ActionButton>
  );
};

export default OrderAction;
