import { useFeatureLoyaltyBackgroundQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';
import { useUIContext } from 'state/ui';

import { ILoyaltyBackground } from './use-loyalty-background.types';

const useLoyaltyBackground = (): ILoyaltyBackground => {
  const { buildImageUrl } = useUIContext();
  const { featureLoyaltyUIId = '' } = useFeaturesContext();
  const { data } = useFeatureLoyaltyBackgroundQuery({
    variables: { featureLoyaltyUIId },
    skip: !featureLoyaltyUIId,
  });

  const image = data?.LoyaltyUI?.imageBackground?.image;

  return {
    image: image ? buildImageUrl(image) : '',
    color: Styles.color.contrastBackground,
  };
};

export default useLoyaltyBackground;
