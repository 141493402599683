import { Redirect, useFocusEffect, usePathname } from 'expo-router';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

import { Text } from '@fhs/ui';
import LoadingAnimation from 'components/loading-animation';
import { useStoreRequired } from 'state/store/hooks/use-store-required';

const FetchingStoreLoadingView = () => {
  const { formatMessage } = useIntl();
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <View style={{ padding: 32 }}>
        <Text.Ui size="md" style={{ textAlign: 'center' }}>
          {formatMessage({ id: 'loadingRestaurantForMenu' })}
        </Text.Ui>
      </View>
      <LoadingAnimation />
    </View>
  );
};

// Ensures that a store is selected before rendering children
const StoreSelected: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { isStoreRequired, isFetchingStore } = useStoreRequired();
  const pathname = usePathname();

  if (isStoreRequired && !isFetchingStore) {
    return (
      <Redirect
        href={{
          pathname: '/store-locator/service-mode',
          params: { back: pathname },
        }}
      />
    );
  }

  return isFetchingStore ? <FetchingStoreLoadingView /> : <>{children}</>;
};

// @ts-ignore
export const withStoreSelected = Component => props => {
  const { handleStoreLocatorRedirect, isStoreRequired, isFetchingStore, hasCalledSetStore } =
    useStoreRequired();

  useFocusEffect(
    React.useCallback(() => {
      if (isStoreRequired && !isFetchingStore) {
        handleStoreLocatorRedirect();
      }
    }, [handleStoreLocatorRedirect, isStoreRequired, isFetchingStore])
  );

  // isFetchingStore initially starts as false and right after will be true when we call fetchAndSetStore,
  // causing "loading flicking" in the screen
  // so we need to make sure that setStore was called at least once before we verify isFetchingStore
  return isFetchingStore || !hasCalledSetStore ? (
    <LoadingAnimation height={'100%'} />
  ) : (
    <Component {...props} />
  );
};

export default StoreSelected;
