import { Image } from 'expo-image';
import { Stack } from 'expo-router';
import Head from 'expo-router/head';
import { useIntl } from 'react-intl';

import { Box, HStack, ScrollView, Text } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { useRewardsFlow } from 'hooks/use-rewards-flow';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import Styles from 'styles/index';
import { routes } from 'utils/routing';

import AddedRewards from './added-rewards';
import QrCode from './qr-code';
import { Content, Header } from './styled';

const InStoreRewards = () => {
  const { formatMessage } = useIntl();
  const { inRestaurantRedemptionCart } = useInRestaurantRedemptionContext();
  const { inStoreRedemptionSearchParams } = useRewardsFlow();
  const content = inRestaurantRedemptionCart.length ? (
    <>
      <Image
        source={require('./background.jpg')}
        contentFit="cover"
        style={{ height: 50, width: '100%', position: 'absolute', left: 0, top: 0 }}
      />
      <Content zIndex={2}>
        <AddedRewards />
        <Header mt={6} mb={4}>
          {formatMessage({ id: 'inStoreRewardsAddedHeader' })}
        </Header>
        <Text pb={4}>{formatMessage({ id: 'inStoreRewardsAddedMessage' })}</Text>
        <QrCode />
      </Content>
    </>
  ) : (
    <Content>
      <Header>{formatMessage({ id: 'earnPoints' })}</Header>
      <Box mt="$4" py={4} backgroundColor="blackOpacity.5" borderRadius="lg">
        <HStack alignItems="center" pb={1}>
          <Text color="token.text-active" fontSize={28} lineHeight="32px" fontWeight={700} pl={5}>
            1
          </Text>
          <Box background="blackOpacity.10" width="1px" height="50%" mx={4} />
          <Text>{formatMessage({ id: 'inStoreRewardsStepOne' })}</Text>
        </HStack>
      </Box>
      <Box mt="$4" py={5} backgroundColor="blackOpacity.5" borderRadius="lg">
        <HStack alignItems="center" pb={3}>
          <Text color="token.text-active" fontSize={28} lineHeight="32px" fontWeight={700} pl={5}>
            2
          </Text>
          <Box background="blackOpacity.10" width="1px" height="50%" mx={4} />
          <Text>{formatMessage({ id: 'inStoreRewardsStepTwo' })}</Text>
        </HStack>
        <Box px={4}>
          <QrCode />
        </Box>
      </Box>
      <Box mt="$4" py={4} backgroundColor="blackOpacity.5" borderRadius="lg">
        <HStack alignItems="center">
          <Text color="token.text-active" fontSize={28} lineHeight="32px" fontWeight={700} pl={5}>
            3
          </Text>
          <Box background="blackOpacity.10" width="1px" height="50%" mx={4} />
          <Text>{formatMessage({ id: 'inStoreRewardsStepThree' })}</Text>
        </HStack>
      </Box>
      <HStack alignItems="center" my={6}>
        <Box flex={1} background="blackOpacity.10" height={0.4} />
        <Text mx={4} fontSize={14}>
          or
        </Text>
        <Box flex={1} background="blackOpacity.10" height={0.4} />
      </HStack>
      <Text bold mb={2} fontSize={18}>
        {formatMessage({ id: 'redeemRewards' })}
      </Text>
      <Text mb={4} variant="copyOne">
        <Text>{formatMessage({ id: 'inStoreRewardsAltOption' })}</Text>
      </Text>
      <ActionButton
        to={{ pathname: routes.loyaltyDashboard, params: inStoreRedemptionSearchParams }}
        variant={ActionButtonVariants.OUTLINE}
        mb={4}
      >
        {formatMessage({ id: 'redeemRewards' })}
      </ActionButton>
    </Content>
  );

  const title = formatMessage({
    id: inRestaurantRedemptionCart.length ? 'rewardsRedeemed' : 'rewardsInStore',
  });

  return (
    <>
      <Stack.Screen
        options={{
          title,
        }}
      />
      <Head>
        <title>{`${title} - Firehouse Subs`}</title>
      </Head>
      <ScrollView style={{ backgroundColor: Styles.color.white }}>{content}</ScrollView>
    </>
  );
};

export default InStoreRewards;
