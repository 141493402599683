import { Box, Divider } from '@fhs-legacy/universal-components';

export const PaymentMethodOptionItemDivider = Divider.withConfig({
  thickness: '1',
  margin: 0,
});

export const StyledOption = Box.withConfig({
  backgroundColor: 'transparent',
  // @ts-expect-error TS(2345) FIXME: Argument of type '{ backgroundColor: "transparent"... Remove this comment to see the full error message
  _hover: {
    backgroundColor: {
      desktop: Styles.color.grey.five,
    },
  },
});
