import { Image, ImageBackground, ImageStyle } from 'expo-image';
import { StyleProp, View } from 'react-native';

import { createMqStyles, useMqSelect } from '@fhs/ui';

export function HotSauceBarCard() {
  const mqStyles = useMqStyles();
  const backgroundSource = useMqSelect(
    {
      $gteDesktopLg: require('./hot-sauce-bar-card-background-desktop.webp'),
    },
    require('./hot-sauce-bar-card-background-tablet.webp')
  );
  return (
    <ImageBackground
      alt="Hot Sauce Bar is back"
      source={backgroundSource}
      placeholder={{ thumbhash: 'lDoHE4xgWpeyaDh7X2n4pqY' }}
      style={mqStyles.background}
    >
      <View style={mqStyles.contentContainer}>
        <Image
          source={require('./hot-sauce-bar-card-text.webp')}
          alt="Hot Sauce Bar is back. Add some flavor to any sub when you dine-in or pick-up."
          placeholder={{ thumbhash: 'PwiCAoAUh4eIh3AAAAAAADeHf4h7ipg' }}
          style={mqStyles.text as StyleProp<ImageStyle>}
          contentFit="contain"
        />
      </View>
    </ImageBackground>
  );
}

const useMqStyles = createMqStyles({
  background: {
    $base: {
      aspectRatio: 1024 / 505,
    },
    $gteDesktopLg: {
      aspectRatio: 1920 / 757,
    },
  },
  contentContainer: {
    $base: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '55%',
      top: '35%',
      left: '5%',
    },
    $gteDesktopLg: {
      top: '32%',
      width: '47%',
    },
  },
  text: {
    $base: {
      aspectRatio: 902 / 313,
      width: '100%',
    },
    $gteDesktopLg: {
      aspectRatio: 902 / 313,
    },
  },
});
