import { router, useRootNavigation } from 'expo-router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '@fhs-legacy/universal-components';
import CartLoading from 'components/cart-loading';
import FullPageScreen from 'components/full-page-screen';
import OrderUnavailableModal from 'components/modal-order-unavailable';
import ModalSurprise from 'components/modal-surprise';
import { ModalUpsize } from 'components/modal-upsize';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import useAuth from 'hooks/auth/use-auth';
import useAuthRedirects from 'hooks/auth/use-auth-redirects';
import { useIsMobileBp } from 'hooks/breakpoints';
import useDebounceEffect from 'hooks/use-debounce-effect';
import useEffectOnce from 'hooks/use-effect-once';
import useFeatureMenuAddOn from 'hooks/use-feature-menu-add-on';
import { CustomScrollViewProps } from 'pages/cart/payment/types';
import { LoyaltyRewardsBalance } from 'pages/loyalty/loyalty-rewards-balance';
import { useCartContext } from 'state/cart';
import { useCRMEventsContext } from 'state/crm-events';
import { selectors, useAppSelector } from 'state/global-state';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyContext } from 'state/loyalty';
import { useMainMenuContext } from 'state/main-menu';
import { useOffersContext } from 'state/offers';
import { OrderStatus, useOrderContext } from 'state/order';
import { OrderSuccessFailureStatuses } from 'state/order/constants';
import { usePaymentContext } from 'state/payment';
import { useRestaurantPosConnectivityStatus } from 'state/restaurant-connectivity-status';
import { useStoreContext } from 'state/store';
import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { EventName, emitEvent } from 'utils/event-hub';
import { routes } from 'utils/routing';
import { isCatering as isCateringOrder } from 'utils/service-mode';
import { CART_PAGE } from 'utils/test-ids';

import CartContent from './cart-content';
import { CartPageError } from './cart-page-error';
import { CartContentWrapper } from './styled';
import { getServiceModeHeaderText } from './types';

export const StyledBackIcon = Icon.withConfig({
  variant: 'cancel',
  color: theme.token('icon-default'),
  ...hiddenAccessibilityPlatformProps,
});

export const Cart = () => {
  const {
    cartEntries,
    serverOrder,
    unavailableCartEntries,
    orderStatus,
    cardSuccessfullyAdded,
    tryPricing,
    serviceMode,
    shouldSkipPriceOrder,
    setShouldSkipPriceOrder,
    showCartErrorModal,
  } = useCartContext();
  const { formatMessage } = useIntl();
  const { user, isAuthenticated } = useAuth();
  // TODO - FPF Cart this use of order context can be completely remove from here into the new cart context
  const {
    calculateCartTotalWithDiscount,
    isDonationZeroAmountSelected,
    setIsDonationZeroAmountSelected,
  } = useOrderContext();
  // Retrieve payment processor to determine which payment page to display
  const { checkoutPaymentMethod } = usePaymentContext();
  const offersCtx = useOffersContext();
  const { selectedOffer: selectedCBAOffer, selectedOfferCartEntry } = offersCtx;
  const enableSignupAfterCart = useFlag(LaunchDarklyFlag.ENABLE_MOVE_SIGNUP_AFTER_CART);
  const { isStoreAvailable, store } = useStoreContext();
  const { loyaltySurpriseOffersEnabled } = useLoyaltyContext();
  const { getStoreMenu, storeMenuLoading, storeMenuCalled } = useMainMenuContext();
  const { region } = useLocale();
  const { logCheckoutEvent } = useCRMEventsContext();
  const appliedLoyaltyRewardsArray = useAppSelector(
    selectors.loyalty.selectAppliedLoyaltyRewardsArray
  );

  const cartFullPageScreenRef = useRef<CustomScrollViewProps>();

  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);

  const storeId = store.number || '';

  const { loading: isRestaurantPosConnectivityStatusLoading } = useRestaurantPosConnectivityStatus({
    storeId,
  });

  const { sections: addOnSections } = useFeatureMenuAddOn();

  const rewardChanged = user?.details?.loyaltyTier;

  const isMobile = useIsMobileBp();

  useEffect(() => {
    if (storeId) {
      getStoreMenu(region, storeId);
    }
  }, [getStoreMenu, region, storeId]);

  const [showUnavailableItemsModal, setShowUnavailableItemsModal] = useState(false);

  const handleRestoreCartErrorModal = () => shouldSkipPriceOrder && setShouldSkipPriceOrder(false);

  // DO NOT USE service mode on order context. Use service mode on server order
  const orderServiceMode = serverOrder?.cart?.serviceMode;

  const serviceModeHeaderText = getServiceModeHeaderText(formatMessage);

  // Don't redirect to SignIn/SignUp if:
  // - The flag enable-signup-after-cart is enabled
  useAuthRedirects({ skip: enableSignupAfterCart });

  const { isCartTotalNegative } = calculateCartTotalWithDiscount(cartEntries);

  const handleUnavailableModalDismiss = useCallback(() => {
    setShowUnavailableItemsModal(false);
    tryPricing();
  }, [tryPricing]);

  useEffect(() => {
    setShowUnavailableItemsModal(!!unavailableCartEntries.length);
  }, [unavailableCartEntries.length]);

  useEffectOnce(() => {
    if (isStoreAvailable && serviceMode) {
      logCheckoutEvent(serviceMode, cartEntries);
    }
  });

  useEffectOnce(() => {
    emitEvent(EventName.CART_RENDER);
  });

  const initialRender = useRef(true);
  useDebounceEffect(
    () => {
      // Prevent reprice order if navigating to cart screen
      // while order has been priced previous. For example
      // navigating between cart pages. Order should still
      // be priced when loading cart for first time
      if (initialRender.current && orderStatus !== null) {
        initialRender.current = false;
        return;
      }
      initialRender.current = false;
      if (!isStoreAvailable || !serviceMode) {
        return;
      }

      handleRestoreCartErrorModal();
      tryPricing();
    },
    250,
    [
      cartEntries,
      appliedOffers,
      isStoreAvailable,
      serviceMode,
      selectedCBAOffer,
      selectedOfferCartEntry,
      storeId,
      rewardChanged,
      appliedLoyaltyRewardsArray,
    ]
  );

  // Redirect to Menu if:
  // - Cart is empty
  // - Selected offer is null
  // - The order has not been inserted or has been requested
  // Or
  // - Service mode is null
  const shouldRedirect =
    (!cartEntries.length &&
      !selectedOfferCartEntry &&
      !OrderSuccessFailureStatuses.success.pos.includes(orderStatus as OrderStatus) &&
      !OrderSuccessFailureStatuses.inProgress.pos.includes(orderStatus as OrderStatus) &&
      !OrderSuccessFailureStatuses.success.catering.includes(orderStatus as OrderStatus)) ||
    (!serviceMode && !orderServiceMode);
  const isServiceModeCatering = isCateringOrder(serviceMode);

  const isRootNavigationReady = useRootNavigation()?.isReady();

  useEffect(() => {
    if (!isRootNavigationReady) {
      return;
    }
    if (shouldRedirect) {
      if (isDonationZeroAmountSelected) {
        setIsDonationZeroAmountSelected(false);
      }
      if (isServiceModeCatering) {
        // TODO: expo-router - test this when we have catering working
        router.replace(routes.catering);
      } else {
        router.back();
      }
    }
  }, [
    isDonationZeroAmountSelected,
    isRootNavigationReady,
    isServiceModeCatering,
    setIsDonationZeroAmountSelected,
    shouldRedirect,
  ]);

  if (shouldRedirect) {
    return null;
  }

  // Show the loading cart if:
  // - User is authenticated or flag enable-signup-after-cart is disabled
  // - Is restaurant online or is pricing or is getting rewards
  // - Is the Store Menu Data loading or is menu service data disabled
  if (
    !isCartTotalNegative &&
    (isAuthenticated || !enableSignupAfterCart) &&
    isRestaurantPosConnectivityStatusLoading &&
    (!storeMenuCalled || storeMenuLoading)
  ) {
    return <CartLoading />;
  }

  const renderCartErrors = () => {
    if (showUnavailableItemsModal) {
      return (
        <OrderUnavailableModal
          isInCart
          items={unavailableCartEntries}
          onDismiss={handleUnavailableModalDismiss}
        />
      );
    }

    if (showCartErrorModal) {
      return <CartPageError />;
    }

    return null;
  };

  const hasCartErrors = isMobile && (showCartErrorModal || showUnavailableItemsModal);

  return (
    <>
      <FullPageScreen
        headerBottom={<LoyaltyRewardsBalance />}
        heading={serviceModeHeaderText[serviceMode]}
        closeHref={routes.menu}
        cartFullPageScreenRef={cartFullPageScreenRef}
        testID={CART_PAGE}
      >
        <CartContentWrapper>
          {hasCartErrors && renderCartErrors()}
          <CartContent
            hasAddOns={addOnSections.length > 0}
            checkoutPaymentMethod={checkoutPaymentMethod}
            cartFullPageScreenRef={cartFullPageScreenRef?.current}
          />
          {loyaltySurpriseOffersEnabled && (
            <>
              <ModalSurprise />
              <ModalUpsize />
            </>
          )}
        </CartContentWrapper>
      </FullPageScreen>

      <CartPageError />
      {showUnavailableItemsModal && (
        <OrderUnavailableModal
          isInCart
          items={unavailableCartEntries}
          onDismiss={handleUnavailableModalDismiss}
        />
      )}

      {cardSuccessfullyAdded && (
        <VisuallyHidden
          accessibilityLabel={formatMessage({ id: 'yourNewPaymentMethodHasBeenSuccessfullySaved' })}
        />
      )}
    </>
  );
};
