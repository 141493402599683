import { Platform, Pressable, PressableProps, StyleSheet } from 'react-native';

import { tokens } from '../../tokens';
import { IconAdd, IconRemove } from '../icon';

export type QuantityIconNames = 'ADD' | 'REMOVE';

export type QuantityPressableProps = Omit<
  PressableProps,
  'role' | 'aria-checked' | 'aria-label' | 'onPress' | 'disabled'
>;

type IQuantityStepperButtonProp = QuantityPressableProps & {
  iconName: QuantityIconNames;
  disabled: boolean;
  handlePress: () => void;
};

const iconSelector = (iconName: QuantityIconNames, disabled: boolean) => {
  if (iconName === 'REMOVE') {
    return (
      <IconRemove
        color={disabled ? tokens.colors.$blackOpacity10 : tokens.colors.$houseRedDarken}
        size={16}
      />
    );
  }

  if (iconName === 'ADD') {
    return (
      <IconAdd
        color={disabled ? tokens.colors.$blackOpacity10 : tokens.colors.$houseRedDarken}
        size={16}
      />
    );
  }

  if (__DEV__) {
    throw new Error('Must add a valid icon name. Type: QuantityIconNames');
  }

  return null;
};

export const QuantityStepperButton = ({
  disabled,
  iconName,
  handlePress,
  ...props
}: IQuantityStepperButtonProp) => (
  <Pressable
    role="button"
    disabled={disabled}
    onPress={handlePress}
    style={[styles.container, disabled && styles.disabled]}
    {...props}
  >
    {iconSelector(iconName, disabled)}
  </Pressable>
);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 34,
    height: 34,
    padding: 8,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: tokens.colors.$houseRedDarken,
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
    }),
  },
  disabled: {
    borderColor: tokens.colors.$blackOpacity10,
  },
});
