import { usePathname } from 'expo-router';
import { useEffect, useRef } from 'react';

import { useCRMEventsContext } from 'state/crm-events';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';

export default function useLogPageView() {
  const pathname = usePathname();
  const lastLoggedPathname = useRef('');
  const { logPageView } = useCRMEventsContext();
  const { store } = useStoreContext();
  const { serviceMode, deliveryFees } = useServiceModeContext();
  const fees = serviceMode === ServiceMode.DELIVERY ? deliveryFees : undefined;

  // TODO(simplified-menu): Remove when simplified menu is out of beta
  const isSimplyBetterMenuListViewEnabled = useFlag(LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA);
  const isSimplyBetterMenuDetailViewEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA_DETAIL_VIEW
  );
  const isSimplyBetterOffersUIEnabled = useFlag(LaunchDarklyFlag.ENABLE_SIMPLY_OFFER_UI);
  const isSimplyBetterCartServiceEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_SIMPLY_BETTER_CART_SERVICE
  );
  const isSimplyBetterCartServiceOffersEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_SIMPLY_BETTER_CART_SERVICE_OFFER_INTEGRATION
  );

  useEffect(() => {
    if (lastLoggedPathname.current !== pathname) {
      // TODO(simplified-menu): Remove when simplified menu is out of beta
      const featureFlags = {
        isSimplyBetterMenuListViewEnabled,
        isSimplyBetterMenuDetailViewEnabled,
        isSimplyBetterOffersUIEnabled,
        isSimplyBetterCartServiceEnabled,
        isSimplyBetterCartServiceOffersEnabled,
      };

      logPageView(pathname, store, fees ?? undefined, featureFlags);
      lastLoggedPathname.current = pathname;
    }
  }, [
    fees,
    logPageView,
    pathname,
    store,
    isSimplyBetterMenuListViewEnabled,
    isSimplyBetterMenuDetailViewEnabled,
    isSimplyBetterOffersUIEnabled,
    isSimplyBetterCartServiceEnabled,
    isSimplyBetterCartServiceOffersEnabled,
  ]);
}
