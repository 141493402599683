import { StyleSheet, View } from 'react-native';

import { Schema } from '@fhs/backend';
import { SkeletonTextBlock, Text } from '@fhs/ui';

type Props = {
  totals: Schema['CartMetaData']['type']['pricingData'];
};

function TotalLine(props: {
  title: string;
  total?: number;
  weight?: Parameters<(typeof Text)['Ui']>[0]['weight'];
}) {
  if (!props.total) {
    return (
      <>
        <SkeletonTextBlock style={{ width: 180 }} lines={1} />
        <SkeletonTextBlock style={{ width: 60, height: 20 }} lines={1} />
      </>
    );
  }

  return (
    <>
      <Text.Ui size="md" weight={props.weight}>
        {props.title}
      </Text.Ui>
      <Text.Ui size="md" weight={props.weight}>
        ${(props.total / 100).toFixed(2)}
      </Text.Ui>
    </>
  );
}

export function CheckoutOrderSummary({ totals }: Props) {
  return (
    <>
      <View style={styles.container}>
        <TotalLine title="Subtotal" total={totals?.subTotalCents} />
      </View>
      <View style={styles.container}>
        <TotalLine title="Tax" total={totals?.taxCents} />
      </View>
      <View style={[styles.container, { marginTop: 16 }]}>
        <TotalLine title="Total" total={totals?.totalCents} weight="bold" />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
