import { router } from 'expo-router';
import { useCallback, useMemo } from 'react';

import { OfferListItem } from '@fhs/backend/amplify/functions/_temp/graphql/API';
import {
  getLoyaltyIncentives,
  getOfferDetailUi,
} from '@fhs/backend/amplify/functions/_temp/graphql/queries';
import { client, useQuery } from '@fhs/client';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';

import { useApplyOffer } from '../hooks/use-apply-offer';
import { useOrderLegacyStates } from '../hooks/use-legacy-order-states';
import { useLoyaltyLegacyStates } from '../hooks/use-loyalty-legacy-states';

export const loyaltyIncentivesQueryKeys = {
  all: ['loyaltyIncentives'] as const,
  params: ({
    storeId,
    serviceMode,
    loyaltyId,
  }: {
    storeId: string;
    serviceMode: string;
    loyaltyId?: string;
  }) => [...loyaltyIncentivesQueryKeys.all, storeId, serviceMode, loyaltyId] as const,
};

export type LoyaltyIncentiveOffer = OfferListItem & {
  applyOffer: () => Promise<void>;
  seeDetails: () => void;
  isApplied: boolean;
};

export const useLoyaltyIncentives = () => {
  const { store, serviceMode, loyaltyUserReady, loyaltyId, selectedOffer } =
    useLoyaltyLegacyStates();

  const { applyOffer } = useApplyOffer();

  const { data, ...props } = useQuery({
    enabled: loyaltyUserReady && !!store.number && !!serviceMode,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: loyaltyIncentivesQueryKeys.params({
      serviceMode: serviceMode ?? '',
      storeId: store.number ?? '',
      loyaltyId,
    }),
    queryFn: async () => {
      const response = await client.graphql({
        query: getLoyaltyIncentives,
        variables: {
          storeNumber: store.number ?? '',
          serviceMode: serviceMode ?? '',
          loyaltyId,
        },
      });
      if (response.errors) {
        throw response.errors;
      }

      return response.data.getLoyaltyIncentives;
    },
  });

  const prepareOfferData = useCallback(
    (offers: OfferListItem[]) => {
      return offers.map<LoyaltyIncentiveOffer>(offer => ({
        ...offer,
        isApplied: selectedOffer?._id === offer.id,
        applyOffer: async () => {
          await applyOffer(offer);
        },
        seeDetails: () => {
          router.navigate(routes.offersV2Details + `/${offer.id}`);
        },
      }));
    },
    [selectedOffer, applyOffer]
  );

  const loyaltyIncentivesData = useMemo(
    () => ({
      ...data,
      offers: prepareOfferData(data?.offers ?? []),
    }),
    [data, prepareOfferData]
  );

  return {
    ...props,
    data: loyaltyIncentivesData,
  };
};

export const useOfferIncentive = (offerId: string) => {
  const { data, ...props } = useLoyaltyIncentives();

  const currentOffer = useMemo(
    () => data?.offers?.find(offer => offer && offer.id === offerId),
    [data?.offers, offerId]
  );
  return {
    ...props,
    data: currentOffer,
  };
};

export const offerDetailUiQueryKeys = {
  all: ['offerDetailUi'] as const,
  params: ({
    storeId,
    serviceMode,
    loyaltyId,
    offerId,
    cartEntriesV1,
  }: {
    storeId: string;
    serviceMode: string;
    loyaltyId?: string;
    offerId: string;
    cartEntriesV1: any;
  }) =>
    [
      ...offerDetailUiQueryKeys.all,
      offerId,
      storeId,
      serviceMode,
      loyaltyId,
      cartEntriesV1,
    ] as const,
};

export const useOfferDetailUi = (offerId: string) => {
  const { store, serviceMode, loyaltyUserReady, loyaltyId } = useLoyaltyLegacyStates();
  const { backendCartEntries } = useOrderLegacyStates();

  // this a temp cartEntriesV1, we need to include from state
  const cartEntriesV1 = useMemo(() => JSON.stringify(backendCartEntries), [backendCartEntries]);

  return useQuery({
    enabled: loyaltyUserReady && !!store.number && !!serviceMode,
    refetchOnWindowFocus: false,
    queryKey: offerDetailUiQueryKeys.params({
      cartEntriesV1,
      serviceMode: serviceMode ?? '',
      storeId: store.number ?? '',
      loyaltyId,
      offerId,
    }),
    queryFn: async () => {
      const response = await client.graphql({
        query: getOfferDetailUi,
        variables: {
          storeNumber: store.number ?? '',
          serviceMode: serviceMode ?? '',
          loyaltyId,
          offerId,
          cartEntriesV1,
        },
      });
      if (response.errors) {
        throw response.errors;
      }

      return response.data.getOfferDetailUi;
    },
  });
};
