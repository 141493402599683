import { IExpoGooglePayResult } from '@rbilabs/expo-googlepay';
import { IAddCreditCardFormValues } from 'components/payments/components/add-credit-card-form-inputs/types';
import {
  CartPaymentCardType,
  IAddPaymentMethodMutation,
  IAddPaymentMethodSuccessFragment,
  IDeletePaymentMethodMutation,
  IPaymentInput,
  IPrepaidStoredPaymentMethodsFragment,
  ISchemeStoredPaymentMethodResponseFragment,
  ISchemeStoredPaymentMethodsFragment,
  IStoredPaymentMethodsQuery,
  PaymentMethodType,
} from 'generated/graphql-gateway';
import { IOrderInformation } from 'pages/cart/payments/types';
import { ISOs } from 'utils/form/constants';

export type AddPaymentMethodMutationResult = IAddPaymentMethodMutation['addPaymentMethod'];
export type AddPaymentMethodSuccessResult = IAddPaymentMethodSuccessFragment;
export type StoredPaymentMethodSuccessResult =
  IAddPaymentMethodSuccessFragment['storedPaymentMethod'];
export type SchemeStoredPaymentMethodResult = ISchemeStoredPaymentMethodResponseFragment;

export enum PaymentMethodOptionTypes {
  ADD_PAYMENT_METHOD = 'addPaymentMethod',
  ADD_GIFT_CARD = 'addGiftCard',
  ADD_CREDIT_CARD = 'addCreditCard',
  EXISTING_PAYMENT_METHOD = 'existingPaymentMethod',
}

export interface ICashPaymentMethod {
  id: CartPaymentCardType.CASH;
  type: CartPaymentCardType.CASH;
}

export interface IApplePayPaymentMethod {
  id: PaymentMethodType.APPLE_PAY;
  type: PaymentMethodType.APPLE_PAY;
}

export interface IGooglePayPaymentMethod {
  id: PaymentMethodType.GOOGLE_PAY;
  type: PaymentMethodType.GOOGLE_PAY;
}

export interface ICanMakePaymentsArgs {
  networks: string[];
  environment: string;
}

export interface IGooglePayPlugin {
  canMakePayments(args: ICanMakePaymentsArgs): Promise<{ available: boolean; hasCards: boolean }>;
  makePaymentRequest(params: {
    countryCode: string;
    currencyCode: string;
    total: string;
    payeeName: string;
    itemLabel: string;
    gateway: string;
    gatewayMerchantId: string;
  }): Promise<IExpoGooglePayResult>;
}

export interface IRequestGooglePayPayment {
  total: number;
  billingCountry: ISOs;
  migrationEnabled: boolean;
  orderInformation: IOrderInformation;
  storeEmail?: string;
  rbiOrderId: string;
}

export interface IRequestApplePayPayment {
  billingCountry: ISOs;
  orderInformation: IOrderInformation;
}

export interface IRequestGooglePayReload {
  billingCountry: ISOs;
  migrationEnabled: boolean;
  reloadInformation: {
    reloadAmount: number;
    destinationPaymentMethodId: string;
  };
}

export interface IRequestApplePayReload {
  billingCountry: ISOs;
  reloadInformation: {
    reloadAmount: number;
    destinationPaymentMethodId: string;
  };
}

export interface IRequestVaultedPrepaidReload {
  billingCountry: ISOs;
  storedPaymentMethodId: string;
  reloadInformation: {
    reloadAmount: number;
    destinationPaymentMethodId: string;
  };
}

export interface IRequestOneTimePrepaidReload {
  billingCountry: ISOs;
  creditCardFormValues: IAddCreditCardFormValues;
  reloadInformation: {
    reloadAmount: number;
    destinationPaymentMethodId: string;
  };
}

export interface IUseApplePay {
  prepaid?: boolean;
}

export interface IDeletePaymentMethod {
  storedPaymentMethodId: string;
}

export type DeletePaymentMethodMutationResult = IDeletePaymentMethodMutation['deletePaymentMethod'];

export type DeletePaymentMethodHandler = (params: IDeletePaymentMethod) => Promise<boolean>;

/** This type applies to the payment method options of:
 * adding a payment method (generic method when there are zero options available)
 * adding a credit card
 * adding a gift card
 */
export interface IAddPaymentMethod {
  id: PaymentMethodOptionTypes;
  type: PaymentMethodOptionTypes;
}

export type StoredPaymentMethods = IStoredPaymentMethodsQuery['storedPaymentMethods'];
export type StoredPaymentMethod = StoredPaymentMethods[0];
export type SchemePaymentMethod = ISchemeStoredPaymentMethodsFragment;
export type PrepaidPaymentMethod = IPrepaidStoredPaymentMethodsFragment;
export type NativePaymentMethod = IApplePayPaymentMethod | IGooglePayPaymentMethod;
export type PaymentMethod =
  | IAddPaymentMethod
  | ICashPaymentMethod
  | NativePaymentMethod
  | PrepaidPaymentMethod
  | StoredPaymentMethod;

/**
 * Type guard for success add payment method.
 *
 * @param AddPaymentMethodMutationResult
 */
export const isAddPaymentMethodSuccess = (
  addPaymentMethodMutationResult: AddPaymentMethodMutationResult
): addPaymentMethodMutationResult is AddPaymentMethodSuccessResult => {
  return addPaymentMethodMutationResult.__typename === 'AddPaymentMethodSuccess';
};

/**
 * Type guard for success store scheme payment method.
 *
 * @param storedPaymentMethod
 */
export const isSchemeStoredPaymentMethodResponse = (
  storePaymentMethodSuccess: StoredPaymentMethodSuccessResult
): storePaymentMethodSuccess is SchemeStoredPaymentMethodResult => {
  return storePaymentMethodSuccess.__typename === 'SchemeStoredPaymentMethod';
};

export interface IBuildCommitOrderPayload {
  cardBrand: string;
  orderInformation: IOrderInformation;
  payment: IPaymentInput;
}

export interface IHandleCommitOrder {
  cardBrand: string;
  orderInformation: IOrderInformation;
  payment: IPaymentInput;
}

export class PaymentError extends Error {
  public failureMessageKey: string;
  constructor(message: string, failureMessageKey: string) {
    super(message);
    this.failureMessageKey = failureMessageKey;
  }
}
