import {
  createNativeBaseThemeComponents,
  createNativeBaseThemeTokens,
} from '../../src/utils/native-base';
import { ComponentVariants, ForcedNativeBaseThemeType } from '../../src/utils/native-base/types';
import { sharedNativeBaseThemeComponents } from '../shared';
import { SharedVariants } from '../shared/native-base-theme';

import { fhsPrimitives } from './primitives';

const {
  breakpoints,
  space,
  sizes,
  shadows,
  opacity,
  letterSpacings,
  lineHeights,
  fontConfig,
  fontWeights,
  fontSizes,
  borderWidths,
  fonts,
  radii,
  colors,
} = fhsPrimitives;

const tokens = createNativeBaseThemeTokens({
  breakpoints,
  space,
  sizes,
  shadows,
  opacity,
  letterSpacings,
  lineHeights,
  fontConfig,
  fontWeights,
  fontSizes,
  borderWidths,
  fonts,
  radii,
  colors,
});

type Variants = SharedVariants &
  ComponentVariants<{
    IconButton: {
      variants: {
        ghost: {};
        input: {};
        outline: {};
        solid: {};
      };
    };
    Text: {
      variants: {
        copyOne: {};
        copyTwo: {};
        formUtility: {};
        headerFour: {};
        headerOne: {};
        headerThree: {};
        headerTwo: {};
        hero: {};
      };
    };
  }>;

const components = createNativeBaseThemeComponents(sharedNativeBaseThemeComponents, {
  Button: {
    defaultProps: {
      _text: {
        fontWeight: 400,
      },
    },
  },
  Icon: {
    variants: {
      rewards: { name: 'mi:star' },
    },
  },
  Text: {
    baseStyle: {
      // @ts-ignore NB is not typing this correctly
      color: 'token.text-default',
      fontWeight: 400,
    },
    variants: {
      hero: {
        accessibilityRole: 'header',
        fontFamily: 'heading',
        fontSize: 30,
        lineHeight: 32,
        mb: 3,
      },
      headerOne: {
        accessibilityRole: 'header',
        fontFamily: 'heading',
        fontSize: 24,
        lineHeight: 28,
        mb: 3,
      },
      headerTwo: {
        accessibilityRole: 'header',
        // @ts-ignore NB is not typing this correctly
        accessibilityLevel: 2,
        fontFamily: 'heading',
        fontSize: 18,
        lineHeight: 24,
        mb: 3,
      },
      headerThree: {
        accessibilityRole: 'header',
        // @ts-ignore NB is not typing this correctly
        accessibilityLevel: 3,
        fontFamily: 'heading',
        fontSize: 16,
        lineHeight: 20,
        mb: 3,
      },
      headerFour: {
        accessibilityRole: 'header',
        // @ts-ignore NB is not typing this correctly
        accessibilityLevel: 4,
        fontFamily: 'heading',
        fontSize: 14,
        lineHeight: 16,
        mb: 3,
      },
      copyOne: {
        fontFamily: 'body',
        fontWeight: 400,
        fontSize: [15, 15, 16],
        lineHeight: [20, 20, 24],
      },
      copyTwo: {
        fontFamily: 'body',
        fontSize: 16,
        lineHeight: 20,
      },
      formUtility: {
        fontFamily: 'body',
        fontSize: 11,
        lineHeight: 12,
      },
    },
  },
});

export const fhsNativeBaseTheme = {
  ...tokens,
  config: { initialColorMode: 'light' },
  components,
} as unknown as ForcedNativeBaseThemeType<typeof tokens, Variants>;
