import { router } from 'expo-router';
import React, { FC, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import { useGetRestaurantsQuery } from 'generated/rbi-graphql';
import useDialogModal from 'hooks/use-dialog-modal';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { APPROX_MINUTES_AWAY_TRANSLATION_ID } from 'pages/order-confirmation/constants';
import { OrderStatus } from 'state/order';
import { useRestaurantPosConnectivityStatus } from 'state/restaurant-connectivity-status';
import { routes } from 'utils/routing';

import { ButtonSubText, ButtonText } from './styled';
import { ICardActionButton, ICardButton } from './type';

const ButtonContainer = Box.withConfig({
  justifyContent: 'space-between',
  margin: '$4',
});

export const CardButton: React.FC<React.PropsWithChildren<ICardButton>> = ({
  onCommit,
  isLoading,
  storeDetails,
  orderStatus,
  shouldShowImCloseText,
}) => {
  const { data } = useGetRestaurantsQuery({
    variables: {
      input: {
        first: 1,
        coordinates: {
          userLat: storeDetails.latitude,
          userLng: storeDetails.longitude,
          searchRadius: 20,
        },
      },
    },
  });

  const restaurant = data?.restaurants?.nodes[0];

  const { formatMessage } = useIntl();
  const hasCheckoutError = useRef(false);
  const onConfirmAlert = useCallback(() => {
    if (hasCheckoutError.current) {
      router.navigate(routes.cart);

      return;
    }
  }, []);
  const [AlertDialog, openAlert, alertMessage] = useDialogModal({
    onConfirm: onConfirmAlert,
    modalAppearanceEventMessage: 'Alert: Payment Failed',
  });

  useEffectOnUpdates(() => {
    if (orderStatus === OrderStatus.INSERT_ERROR) {
      // @todo - we need to evaluate the best path
      // when a user payment succeeds but insert fails
      openAlert({
        body: formatMessage({ id: 'couldntProcessPayment' }),
        buttonLabel: formatMessage({ id: 'retry' }),
        heading: formatMessage({ id: 'paymentFailed' }),
      });
    }
  }, [orderStatus]);

  const imClose = {
    enabled: shouldShowImCloseText,
    text: formatMessage({ id: 'imClose' }),
    approxMinutesAway: formatMessage({ id: APPROX_MINUTES_AWAY_TRANSLATION_ID }),
  };

  const { isRestaurantPosOnline } = useRestaurantPosConnectivityStatus({
    storeId: restaurant?.number ?? null,
    skip: !restaurant,
  });

  return (
    <ButtonContainer>
      <CardActionButton
        isLoading={isLoading}
        commitOrder={onCommit}
        imHereText={formatMessage({ id: 'imHere' })}
        imClose={imClose}
        storeUnavailable={!isRestaurantPosOnline}
      />

      <AlertDialog {...(alertMessage || {})} />
    </ButtonContainer>
  );
};

const CardActionButton: FC<React.PropsWithChildren<ICardActionButton>> = ({
  imHereText,
  isLoading,
  commitOrder,
  imClose: { enabled: enableImClose, text: imCloseText, approxMinutesAway },
  storeUnavailable,
}) => {
  const { formatMessage } = useIntl();

  let buttonText = storeUnavailable ? formatMessage({ id: 'storeUnavailable' }) : imHereText;

  if (enableImClose) {
    buttonText = imCloseText;
  }

  return (
    <ActionButton
      fullWidth
      accessibilityLabel={buttonText}
      testID="im-here-button"
      isLoading={isLoading}
      onPress={commitOrder}
      disabled={storeUnavailable}
    >
      <ButtonText>{buttonText}</ButtonText>
      {enableImClose && <ButtonSubText testID="im-close-button">{approxMinutesAway}</ButtonSubText>}
    </ActionButton>
  );
};
