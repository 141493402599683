import * as Updates from 'expo-updates';
import React, { useState } from 'react';

import { Divider, Header } from '@fhs-legacy/universal-components';
import useEffectOnce from 'hooks/use-effect-once';
import { getDeviceId } from 'utils/device-id';
import { getCurrentCommitHash } from 'utils/environment';

import { EnvItem, Inner } from './components';
import { InternalDiagnostics } from './internal-diagnostics';

export const Configs = () => {
  const [deviceId, setDeviceId] = useState<string>('');

  useEffectOnce(() => {
    getDeviceId().then(deviceIdResult => setDeviceId(deviceIdResult || ''));
  });

  return (
    <Inner>
      <Divider />
      <Header pt={4} variant="headerFour">
        Device Information
      </Header>
      <EnvItem envKey="Device Id" value={deviceId} />

      <Divider />

      <Header pt={4} variant="headerFour">
        EAS Update (Over The Air Updates)
      </Header>
      <EnvItem envKey="Runtime Version" value={Updates.runtimeVersion} />
      <EnvItem envKey="Build Commit Hash" value={getCurrentCommitHash()} />
      <EnvItem envKey="Update Id" value={Updates.updateId} />
      <EnvItem envKey="Update Channel" value={Updates.channel} />
      <Divider />

      <InternalDiagnostics />
    </Inner>
  );
};
