import { theme as brandTheme } from 'components/layout/brand-theme';

import { designTokens } from './constants/design-tokens';

export const theme = {
  ...brandTheme,
  token(tokenName: keyof typeof designTokens): any {
    return designTokens[tokenName];
  },
};
