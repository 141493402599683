import Head from 'expo-router/head';
import { useIntl } from 'react-intl';

import { PypestreamWrapper as Pypestream } from '@fhs-legacy/frontend/src/pages/pypestream';
import SupportV2CategoriesPage from '@fhs-legacy/frontend/src/pages/support-v2/support-v2-categories-page';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';

export default function SupportIndexPage() {
  const enablePypestream = useFlag(LaunchDarklyFlag.ENABLE_PYPESTREAM);
  const { formatMessage } = useIntl();

  return (
    <>
      <Head>
        <title>{`${formatMessage({ id: 'supportTopics' })} - Firehouse Subs`}</title>
      </Head>
      {enablePypestream ? <Pypestream /> : <SupportV2CategoriesPage />}
    </>
  );
}
