import { Image, ImageProps } from 'expo-image';
import { Link } from 'expo-router';
import { Pressable, StyleSheet } from 'react-native';

export type HookAndLadderCardProps = Omit<ImageProps, 'children' | 'source'> & {
  pickUpHref: string;
};

export function HookAndLadderCard(imageProps: HookAndLadderCardProps) {
  return (
    <Link href="/menu/picker-57783225-461a-409b-8983-af4b1be9cce1" asChild>
      <Pressable>
        <Image
          alt="Hook & Ladder. Order now"
          source={require('./hook-and-ladder-banner.webp')}
          placeholder={{ thumbhash: 'szgKF4b4iGeJdYd9lIiIx3h1eFAXB3kA' }}
          {...imageProps}
          style={[styles.hookAndLadder, imageProps.style]}
        />
      </Pressable>
    </Link>
  );
}

const styles = StyleSheet.create({
  hookAndLadder: {
    aspectRatio: 1354 / 1270,
  },
});
