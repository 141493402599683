import { useField } from 'formik';

import { addWithConfig } from '../../utils';
import { usePropsWithFormikFormControlName } from '../formik-form-control';
import { TextArea } from '../text-area';

import type { FormikTextAreaProps } from './types';

export const FormikTextArea = (props: FormikTextAreaProps) => {
  const { name, textAreaProps, ...rest } = usePropsWithFormikFormControlName(props);
  const [field, meta, helpers] = useField(name);
  const isInvalid = Boolean(meta.error && meta.touched);
  return (
    <TextArea
      isInvalid={isInvalid}
      value={field.value}
      {...textAreaProps}
      {...rest}
      onChangeText={(nextText) => {
        helpers.setValue(nextText);
      }}
      onBlur={() => {
        helpers.setTouched(true);
      }}
    />
  );
};

export default addWithConfig(FormikTextArea);
