import { useCallback } from 'react';

import { useCartContext } from 'state/cart';
import { ServiceMode } from 'state/order';
import { usePaymentContext } from 'state/payment';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

import { useOrderPayment } from './use-order-payment';

export const useCommitOrder = (orderPayment: ReturnType<typeof useOrderPayment>) => {
  const { serviceMode, tryPricing } = useCartContext();
  const isCurbside = serviceMode === ServiceMode.CURBSIDE;
  const { loading } = usePaymentContext();

  const {
    setIsHandlingOrderCommit,
    setCommitOrderAfterPricing,
    shouldConfirmStoreOnCheckout,
    isPlacingOrder,
    isOrderPriceLoading,
    isApplePayLoading,
    isGooglePayLoading,
    ErrorDialog,
  } = orderPayment;
  const maybeCommitOrderAfterPricing = useCallback(() => {
    shouldConfirmStoreOnCheckout(true);
    if (isCurbside && tryPricing) {
      setCommitOrderAfterPricing(true);
      return tryPricing({
        customerName: `CS-${LocalStorage.getItem(StorageKeys.VEHICLE_DESCRIPTION)}`,
      });
    }
    setIsHandlingOrderCommit(true);
  }, [
    isCurbside,
    setCommitOrderAfterPricing,
    setIsHandlingOrderCommit,
    shouldConfirmStoreOnCheckout,
    tryPricing,
  ]);
  const isProcessingPayment =
    loading || isPlacingOrder || isOrderPriceLoading || isApplePayLoading || isGooglePayLoading;

  return {
    commitOrder: maybeCommitOrderAfterPricing,
    isCommiting: isProcessingPayment,
    ErrorDialog,
  };
};
