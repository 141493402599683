import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import MenuLineItem from 'components/menu-order-wizard/menu-line-item';
import ModifierPrice from 'components/menu-order-wizard/modifier-price';
import { NumberInput } from 'components/ucl/number-input';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { curriedModifierMultiplierNameFromIndex } from 'utils/menu/modifiers';

import { IMenuOptionStepperProps } from './types';

export const MenuOptionStepper: FC<React.PropsWithChildren<IMenuOptionStepperProps>> = ({
  calories,
  image,
  imageDescription,
  name,
  index,
  itemOption,
  onChange,
  price,
  max,
}) => {
  const { formatMessage } = useIntl();

  // * Normally this logic is handled in the <Calories /> wrapper
  // * but seeing as MenuLineItem relies on boolean logic to show/hide the details
  // * its imported here so that an explicit falsy value can be provided
  // * (instead of providing a React Element)
  const hideUiCalories = useFlag(LaunchDarklyFlag.HIDE_UI_CALORIES);

  const modifierMultiplierValsFromIndex = itemOption
    ? curriedModifierMultiplierNameFromIndex(itemOption)
    : (v: number) => v;

  const maxOptions = itemOption?.options?.length ? itemOption?.options?.length - 1 : 0;

  const details = hideUiCalories ? null : calories;

  return (
    <MenuLineItem
      bottomContent={<ModifierPrice price={price} />}
      testID={name}
      name={name}
      details={details}
      highlightOnHover={false}
      // ignoreLabelClicks
      image={image}
      imageDescription={imageDescription}
      indicator={
        <NumberInput
          hideNumberInput
          displayedValue={modifierMultiplierValsFromIndex(index)}
          value={index}
          onChange={onChange}
          max={max ?? maxOptions}
          ariaLabel={formatMessage({ id: 'quantityOf' }, { name })}
          decrementAriaLabel={`decrement-${name}`}
          incrementAriaLabel={`increment-${name}`}
          testID={`item-quantity-${name}`}
        />
      }
    />
  );
};
