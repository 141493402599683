import { IRestaurantNode, useGetFavoriteRestaurantsQuery } from 'generated/graphql-gateway';

export const useGetFavoriteRestaurants = () => {
  const { data, loading, error } = useGetFavoriteRestaurantsQuery({
    variables: {
      input: {
        first: 20,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return {
    restaurants: (data?.favoriteRestaurants?.nodes as IRestaurantNode[]) || [],
    loading,
    error,
  };
};
