import React, { useEffect, useState } from 'react';

import { IBackendCartEntries, ICartEntry } from '@rbi-ctg/menu';
import { AvailabilityStatus } from 'components/availability-status';
import FavoriteEntryButton from 'components/favorite-entry-button';
import { Product } from 'components/product';
import { ItemAvailabilityStatus } from 'enums/menu';
import { ICartEntryFragment } from 'generated/rbi-graphql';
import useFoodware from 'hooks/use-foodware';
import {
  FormatRecentItemsCaloriesArgs,
  useRecentItemsCalories,
} from 'hooks/use-recent-items-calories/use-recent-items-calories';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ItemAvailability } from 'state/menu-options';
import { useOrderContext } from 'state/order';
import { useMapToFrontendCartEntry } from 'state/order/hooks/use-map-to-frontend-cart-entry';
import { useStoreContext } from 'state/store';
import { getMenuItemDayParts } from 'utils/availability';
import { getSelectionsListFromEntry } from 'utils/cart';

import AddToCart from './add-to-cart';
import { StyledButtonBox, StyledRecentOrderCard } from './styled';

interface RecentOrderCardProps {
  cartEntry: IBackendCartEntries | ICartEntryFragment;
  isOnFavoriteSection?: boolean;
}

const RecentOrderCard: React.FC<React.PropsWithChildren<RecentOrderCardProps>> = ({
  cartEntry,
  isOnFavoriteSection,
}) => {
  const [frontendEntry, setFrontendEntry] = useState<ICartEntry | null>(null);
  const [itemAvailability, setItemAvailability] = useState<ItemAvailability>({
    availabilityStatus: ItemAvailabilityStatus.UNAVAILABLE,
    data: undefined,
  });
  const [checkingAvailability, setCheckingAvailability] = useState(false);
  const mapToFrontendEntry = useMapToFrontendCartEntry();
  const enableFavorites = useFlag(LaunchDarklyFlag.ENABLE_FAVORITES);
  const enableDisplayCalories = useFlag(LaunchDarklyFlag.ENABLE_FAVORITES_DISPLAY_CALORIES);
  const enableRecentItemsWithModifiers = useFlag(
    LaunchDarklyFlag.ENABLE_RECENT_ITEMS_WITH_MODIFIERS
  );
  const { noStoreSelected } = useStoreContext();
  const orderContext = useOrderContext();
  const formatRecentItemsCalories = useRecentItemsCalories();
  const { isFoodware } = useFoodware();
  const itemIsFoodware = isFoodware(frontendEntry!);

  const isExtra = cartEntry?.isExtra;

  useEffect(() => {
    const handleEffect = async () => {
      // cart entries need to be displayed with up-to-date data (from menu)
      // prior orders could include items that are no longer available, have changed names, etc.
      // this could be considered derived state, so keeping it in sync here is problematic, however
      // getting item menu data is an asynchronous action, so needs to be treated as a side effect
      setCheckingAvailability(true);
      const {
        cartEntry: mappedEntry,
        status,
        data,
      } = await mapToFrontendEntry(cartEntry as IBackendCartEntries, orderContext?.cartEntries);
      setItemAvailability({ availabilityStatus: status, data: data ?? undefined });
      setFrontendEntry(mappedEntry);
      setCheckingAvailability(false);
    };

    handleEffect();
    //eslint-disable-next-line
  }, [cartEntry, mapToFrontendEntry, JSON.stringify(orderContext?.cartEntries)]);

  if (!cartEntry) {
    return null;
  }

  // It is only available if we were able to load the sanity data and the status is available
  // Or there is not store selected
  const isAvailable = [
    ItemAvailabilityStatus.AVAILABLE,
    ItemAvailabilityStatus.STORE_NOT_SELECTED,
  ].includes(itemAvailability.availabilityStatus);

  // Only get the selection list if modifiers are enabled for recent items
  const itemSelections =
    enableRecentItemsWithModifiers && frontendEntry?.children
      ? getSelectionsListFromEntry(frontendEntry)
      : [];

  const dayParts = itemAvailability.data ? getMenuItemDayParts(itemAvailability.data) : [];

  const showFavoritesButton = !isOnFavoriteSection && enableFavorites && !isExtra;
  const calories = enableDisplayCalories
    ? formatRecentItemsCalories({
        menuItem: itemAvailability.data as FormatRecentItemsCaloriesArgs['menuItem'],
        frontendEntry,
        quantity: cartEntry.quantity,
      })
    : undefined;

  return (
    <StyledRecentOrderCard>
      <Product
        loading={!frontendEntry}
        image={cartEntry.image ?? ''}
        name={frontendEntry?.name}
        quantity={cartEntry.quantity}
        selections={itemSelections}
        displayMode="list"
        calories={calories}
        note={
          (!noStoreSelected || itemIsFoodware) &&
          !isAvailable && (
            <AvailabilityStatus
              availabilityStatus={itemAvailability.availabilityStatus}
              dayParts={dayParts}
              hideWhenAvailable
            />
          )
        }
      />
      <StyledButtonBox>
        {frontendEntry && showFavoritesButton && <FavoriteEntryButton cartEntry={frontendEntry} />}
        {frontendEntry && (
          <AddToCart
            disabled={(!noStoreSelected && !isAvailable) || checkingAvailability || itemIsFoodware}
            cartEntry={frontendEntry}
          />
        )}
      </StyledButtonBox>
    </StyledRecentOrderCard>
  );
};

export default RecentOrderCard;
