import { Box, FlatList } from '@fhs-legacy/universal-components';

import { AdaptableMenuCard } from '../adaptable-menu-card';

import { IMenuCardsSectionContent } from './types';

export const MenuCardsSectionContent = ({
  logDisplayEvent,
  availableMenuCards,
}: IMenuCardsSectionContent) => {
  const isWindows = !!window.navigator.platform?.includes('Win');

  return (
    <>
      {availableMenuCards.length === 1 ? (
        <Box alignSelf={'center'} paddingY={'$4'} paddingX={'$0.5'}>
          <AdaptableMenuCard
            key={availableMenuCards[0]._id}
            {...availableMenuCards[0]}
            position={1}
            logDisplayEvent={logDisplayEvent}
            onlyOneItem
          />
        </Box>
      ) : (
        <FlatList
          padding={'$4'}
          paddingLeft={'$0.5'}
          backgroundColor={Styles.color.background}
          width="100%"
          horizontal
          scrollEnabled={availableMenuCards.length > 1}
          showsHorizontalScrollIndicator={isWindows}
          renderItem={({ item, index }) => (
            <AdaptableMenuCard
              key={item._id}
              {...item}
              position={index + 1}
              logDisplayEvent={logDisplayEvent}
              onlyOneItem={availableMenuCards.length === 1}
            />
          )}
          data={availableMenuCards}
        />
      )}
    </>
  );
};
