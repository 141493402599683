import { createMqStyles } from '../../mq-styles';
import { tokens } from '../../tokens';

import { Text, type TextProps } from './text';

type HeadingType = 'hero' | 'one' | 'two' | 'three' | 'four' | 'five';

export type HeadingTextProps = Omit<TextProps, 'weight'> & {
  type: HeadingType;
};

export function HeadingText({ type, selectable = true, ...props }: HeadingTextProps) {
  const styles = useMqStyles();

  return (
    <Text
      {...props}
      selectable={selectable}
      weight={type === 'hero' ? 'bold' : 'semibold'}
      style={[styles.baseline, styles[type], props.style]}
    />
  );
}

const useMqStyles = createMqStyles({
  baseline: {
    $base: {
      color: tokens.colors.$houseDark,
    },
  },
  hero: {
    $base: { fontSize: 28, lineHeight: 30 },
    $gteDesktop: { fontSize: 48, lineHeight: 52 },
  },
  one: {
    $base: { fontSize: 24, lineHeight: 28 },
    $gteDesktop: { fontSize: 30, lineHeight: 32 },
  },
  two: {
    $base: { fontSize: 18, lineHeight: 22 },
    $gteDesktop: { fontSize: 24, lineHeight: 28 },
  },
  three: {
    $base: { fontSize: 16, lineHeight: 20 },
    $gteDesktop: { fontSize: 18, lineHeight: 20 },
  },
  four: {
    $base: { fontSize: 14, lineHeight: 16 },
    $gteDesktop: { fontSize: 16, lineHeight: 18 },
  },
  five: {
    $base: { fontSize: 12, lineHeight: 14 },
    $gteDesktop: { fontSize: 14, lineHeight: 16 },
  },
});
