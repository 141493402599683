import { Box } from '@fhs-legacy/universal-components';

export const MenuTileGridContainer = Box.withConfig({
  flex: 1,
  justifyContent: 'space-between',
  marginY: {
    base: '$0',
    desktop: '$12',
  },
  padding: '$0',
  paddingTop: '$0',
});

export default MenuTileGridContainer;
