import { merge } from 'lodash';

import { sharedPrimitives } from '../shared';

const colorTokens = {
  'background-active': '#B10810',
  'background-badge': '#01000B',
  'background-badge-rewards': '#EBA518',
  'background-badge-rewards-locked': '#F5F1EF',
  'background-button-primary-default': '#B10810',
  'background-button-primary-hover': '#E31837',
  'background-button-primary-pressed': '#E31837',
  'background-button-secondary-hover': '#E318371A',
  'background-button-secondary-pressed': '#E318371A',
  'background-checked': '#01000B',
  'background-default': '#F2F2F6',
  'background-footer': '#01000B',
  'background-hero-light': '#FFFFFF',
  'background-hero-reversed': 'radial-gradient(50% 266.27% at 50% 50%, #B10810 0%, #B10810 100%)',
  'background-loader-indicator-primary': '#B10810',
  'background-skeleton-loader-cta-start': '#E318374D',
  'background-skeleton-loader-cta-end': '#E318371A',
  'background-switch-track': '#01000B',
  'border-color-active': '#B10810',
  'border-color-badge': '#01000B',
  'border-color-button-secondary': '#B10810',
  'border-color-checked': '#01000B',
  'border-color-focus': '#01000B',
  'border-color-hover': '#01000B',
  'icon-active': '#B10810',
  'icon-button-primary-reversed': '#01000B',
  'icon-button-secondary': '#B10810',
  'icon-button-tertiary-default': '#01000B',
  'icon-button-tertiary-hover': '#B10810',
  'icon-button-tertiary-pressed': '#B10810',
  'icon-default': '#01000B',
  'icon-form-input-action': '#01000B',
  'icon-reversed': '#FFFFFF',
  'icon-rewards': '#01000B',
  'icon-rewards-locked': '#01000B',
  'text-active': '#B10810',
  'text-button-primary-reversed': '#01000B',
  'text-button-secondary': '#B10810',
  'text-button-tertiary-default': '#01000B',
  'text-button-tertiary-hover': '#B10810',
  'text-default': '#01000B',
  'text-hero-light': '#01000B',
  'text-link-default': '#01000B',
  'text-link-hover': '#B10810',
  'text-link-reversed': '#FFFFFF',
  'text-reversed': '#FFFFFF',
  'text-rewards': '#01000B',
  'text-rewards-locked': '#01000B',
};

// @todo update with color from Design
// http://mcg.mbitson.com/#!?plk=%23f25600&bk=%23502314&th=%23c8102e&themename=mcgtheme
// Based on houseRed
const primary = {
  50: '#fce3e7',
  100: '#f7bac3',
  200: '#f18c9b',
  300: '#eb5d73',
  400: '#e73b55',
  500: '#e31837',
  600: '#e01531',
  700: '#dc112a',
  800: '#d80e23',
  900: '#d00816',
};

// Based on houseYellow
const secondary = {
  50: '#fdf4e3',
  100: '#f9e4ba',
  200: '#f5d28c',
  300: '#f1c05d',
  400: '#eeb33b',
  500: '#eba518',
  600: '#e99d15',
  700: '#e59311',
  800: '#e28a0e',
  900: '#dd7908',
};

// Based on crunchyGreen @TODO: make this based on a FHS color
const tertiary = {
  50: '#e3f1e7',
  100: '#badbc3',
  200: '#8cc39b',
  300: '#5eab73',
  400: '#3c9955',
  500: '#198737',
  600: '#167f31',
  700: '#12742a',
  800: '#0e6a23',
  900: '#085716',
};
// /@todo

// /@todo based on final design
export const fhsPrimitives = merge({}, sharedPrimitives, {
  colors: {
    primary,
    secondary,
    tertiary,
    houseRed: '#E31837',
    houseYellow: '#EBA518',
    houseDark: '#01000B',
    houseLight: '#F2F2F6',
    houseYellowLighten20: '#FBEDD1',
    houseRedDarken10: '#B1080F',
    houseRedOpacity10: 'rgba(227, 24, 55, 0.1)',
    white: '#FFF',
    token: colorTokens,
  },
  fontConfig: {
    Montserrat: {
      400: {
        normal: 'Montserrat',
        // Setting to the same as normal so that we don't break iOS
        italic: 'Montserrat',
      },
      700: {
        normal: 'MontserratBold',
        // Setting to the same as normal so that we don't break iOS
        italic: 'MontserratBold',
      },
    },
    'Montserrat-Bold': {
      400: {
        normal: 'MontserratBold',
        // Setting to the same as normal so that we don't break iOS
        italic: 'MontserratBold',
      },
      700: {
        normal: 'MontserratBold',
        italic: 'MontserratBold',
      },
    },
  },
  fonts: {
    heading: 'MontserratBold',
    body: 'Montserrat',
    alternative: 'MontserratBold',
  },
});
