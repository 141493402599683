import React, { createContext, useCallback, useContext, useState } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { ICartEntry } from '@rbi-ctg/menu';

// TODO: this entire global context could be removed if we could pass the cartEntries to the Upsell component
export interface IMenuUIContext {
  activeCartEntries: ICartEntry[];
  setActiveCartEntries: (cartEntries: ICartEntry[]) => void;
}

export const MenuUIContext = createContext<IMenuUIContext>({} as IMenuUIContext);
export const useMenuUIContext = () => useContext(MenuUIContext);

export default MenuUIContext.Consumer;

export const MenuUIProvider = ({ children }: IBaseProps) => {
  const [activeCartEntries, setActiveCartEntriesInternal] = useState<ICartEntry[]>([]);

  const setActiveCartEntries = useCallback((entries: any) => {
    setActiveCartEntriesInternal(entries);
  }, []);

  return (
    <MenuUIContext.Provider
      value={{
        activeCartEntries,
        setActiveCartEntries,
      }}
    >
      {children}
    </MenuUIContext.Provider>
  );
};
