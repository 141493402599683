import { Box, Checkbox, Link, Text, VStack } from '@fhs-legacy/universal-components';

export const StyledLink = Link.withConfig({
  _text: {
    color: Styles.color.black,
    underline: true,
  },
});

export const Wrapper = Box.withConfig({
  paddingX: '$4',
  paddingY: 0,
  width: '100%',
});

export const ToggleWrapper = Box.withConfig<{ show: boolean }>(p => ({
  display: p.show ? 'flex' : 'none',
}));

export const StyledCheckbox = Checkbox.withConfig({
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  width: '100%',
});

export const StyledDonationCard = VStack.withConfig({
  space: '$2',
  rounded: 'md',
  backgroundColor: Styles.color.cardBackground,
  padding: '$4',
});

export const StyledDonationTitle = Text.withConfig({
  variant: 'headerTwo',
});

export const StyledDonationSubTitle = Text.withConfig({
  flexWrap: 'wrap',
  variant: 'copyTwo',
  fontSize: 'sm',
});
