import { isPast } from 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';

import { Text } from '@fhs-legacy/universal-components';
import { useLocale } from 'state/intl';
import { useServiceModeContext } from 'state/service-mode';
import { formatLocalizedCurrentWeekDay } from 'utils/dateTime';
import { format } from 'utils/language/format-date';

import {
  ArrowRightIcon,
  CalendarIcon,
  CardWrapper,
  DateLabel,
  DateTimeWrapper,
  DateWrapper,
  DayLabel,
  SectionWrapper,
  TimeLabel,
  TimeWrapper,
} from './styled';
import { IFutureOrderTimeCard } from './types';

export const FutureOrderTimeCard = ({
  date,
  readOnly = false,
  onPress,
  hasError = false,
  hasOfferValidationError = false,
}: IFutureOrderTimeCard) => {
  const { formatMessage, formatTime } = useIntl();
  const { language } = useLocale();
  const { isDelivery } = useServiceModeContext();

  const dateConverted = date
    ? new Date(date?.year, date?.month, date?.day, date?.hour, date?.minutes)
    : undefined;

  if (hasError || !dateConverted || (!readOnly && isPast(dateConverted))) {
    return (
      <Text>
        {formatMessage({
          id: isDelivery ? 'noDeliveryOptionsAvailable' : 'noPickupOptionsAvailable',
          description: '',
        })}
      </Text>
    );
  }

  const dateFormatted = format(dateConverted, 'PP', language);
  const dateTimeFormatted = formatTime(dateConverted);
  const dayFormatted = formatLocalizedCurrentWeekDay({
    dateTime: dateConverted,
    language,
    formatMessage,
  });

  const handleCardPress = () => {
    if (readOnly) {
      return;
    }
    onPress && onPress();
  };

  return (
    <SectionWrapper onPress={handleCardPress} $readOnly={readOnly} testID="future-order-time-card">
      <CardWrapper hasError={hasOfferValidationError}>
        <CalendarIcon hasError={hasOfferValidationError} />
        <DateTimeWrapper $readOnly={readOnly}>
          <DateWrapper>
            <DayLabel hasError={hasOfferValidationError}>{dayFormatted}</DayLabel>
            <DateLabel hasError={hasOfferValidationError}>{dateFormatted}</DateLabel>
          </DateWrapper>
          <TimeWrapper>
            <TimeLabel hasError={hasOfferValidationError}>{dateTimeFormatted}</TimeLabel>
          </TimeWrapper>
        </DateTimeWrapper>
        {!readOnly && <ArrowRightIcon hasError={hasOfferValidationError} />}
      </CardWrapper>
    </SectionWrapper>
  );
};
