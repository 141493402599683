import { Image } from 'expo-image';
import { StyleSheet, View } from 'react-native';

const DEFAULT_BACKGROUND_HEIGHT = 187;
const DEFAULT_REPEAT_INTERVAL = 14;

type BackgroundProps = {
  backgroundHeight?: number;
  repeat?: number;
};

export const Background = ({
  backgroundHeight = DEFAULT_BACKGROUND_HEIGHT,
  repeat = DEFAULT_REPEAT_INTERVAL,
}: BackgroundProps) => {
  return (
    <View
      style={[
        styles.trayPatternContainer,
        {
          height: backgroundHeight,
        },
      ]}
    >
      {[...Array(repeat)].map((_, i) => {
        return (
          <Image
            key={i}
            alt=""
            source={require('./tray-pattern.webp')}
            placeholder={{ thumbhash: '+wcCB4DHxneIZ3hXR5eGeXhwhkCH93wH' }}
            style={styles.backgroundPattern}
            contentFit="cover"
          />
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  backgroundPattern: {
    height: '100%',
    aspectRatio: 751 / 706,
  },
  trayPatternContainer: {
    overflow: 'hidden',
    flexDirection: 'row',
  },
});
