/* eslint-disable max-len */

export const fhsTokens = {
  // 'background-active': fhsPrimitives.colors.houseRed,
  // 'background-button-hover-reversed': fhsPrimitives.colors.houseYellowLighten20,
  // 'background-button-primary-default': fhsPrimitives.colors.houseRed,
  // 'background-button-primary-default-reversed': fhsPrimitives.colors.white,
  // 'background-button-primary-hover': fhsPrimitives.colors.houseRed,
  // 'background-button-secondary-hover': fhsPrimitives.colors.houseRed,
  // 'background-checkbox-disabled': fhsPrimitives.colors.houseDark,
  // 'background-checked': fhsPrimitives.colors.houseRed,
  // 'background-chip': fhsPrimitives.colors.houseRed,
  // 'background-chip-rewards': fhsPrimitives.colors.houseRed,
  // 'background-chip-rewards-locked': fhsPrimitives.colors.houseLight,
  // 'background-default': fhsPrimitives.colors.houseLight,
  // 'background-dialog': fhsPrimitives.colors.white,
  // 'background-error': fhsPrimitives.$error,
  // 'background-focus': fhsPrimitives.colors.houseLight,
  // 'background-focus-reversed': fhsPrimitives.colors.whiteOpacity[4],
  // 'background-footer': fhsPrimitives.colors.houseRed,
  // 'background-hero-light': fhsPrimitives.colors.houseLight,
  // 'background-hero-reversed': fhsPrimitives.colors.houseRed,
  // 'background-icon-hover-focus': fhsPrimitives.colors.houseLight,
  // 'background-icon-hover-focus-reversed': fhsPrimitives.colors.whiteOpacity[4],
  // 'background-inline-alert': fhsPrimitives.colors.houseLight,
  // 'background-input': fhsPrimitives.colors.white,
  // 'background-input-disabled': fhsPrimitives.colors.houseLight,
  // 'background-pattern': fhsPrimitives.colors.white,
  // 'background-switch-thumb': fhsPrimitives.colors.white,
  // 'background-switch-track': fhsPrimitives.colors.houseRed,
  // 'background-switch-track-disabled': fhsPrimitives.colors.houseDark,
  // 'border-color-active': fhsPrimitives.colors.houseRed,
  // 'border-color-button-secondary': fhsPrimitives.colors.houseRed,
  // 'border-color-button-secondary-reversed': fhsPrimitives.colors.white,
  // 'border-color-checked': fhsPrimitives.colors.houseRed,
  // 'border-color-default': fhsPrimitives.colors.houseDark,
  // 'border-color-disabled': fhsPrimitives.colors.houseDark,
  // 'border-color-disabled-reversed': fhsPrimitives.colors.houseYellowLighten20,
  // 'border-color-error': fhsPrimitives.$error,
  // 'border-color-hover': fhsPrimitives.colors.houseRed,
  // 'border-color-input': fhsPrimitives.colors.houseDark,
  // 'border-color-input-default': fhsPrimitives.colors.houseDark,
  // 'border-color-options': fhsPrimitives.colors.houseDark,
  // 'border-color-reversed': fhsPrimitives.colors.houseYellowLighten20,
  // 'border-radius-button': '100%',
  // 'border-radius-chip': '4px',
  // 'border-radius-input': '4px',
  // 'border-radius-input-icon-hover-focus': '100%',
  // 'border-radius-pattern': '20px',
  // 'border-radius-pattern-interactive': '12px',
  // 'border-radius-toggle-default': '0',
  // 'border-radius-toggle-end': '0 4px 4px 0',
  // 'border-radius-toggle-start': '4px 0 0 4px',
  // 'border-width-button-secondary': '1px',
  // 'border-width-input': '1px',
  // 'border-width-tabs': '1px',
  // 'box-shadow-button': `0 0 0 4px ${fhsPrimitives.colors.houseDark}`,
  // 'box-shadow-component': `0 0 0 4px ${fhsPrimitives.colors.houseDark}`,
  // 'box-shadow-component-reversed': `0 0 0 4px ${fhsPrimitives.colors.houseYellowLighten20}`,
  // 'box-shadow-pattern': `0 0 0 4px ${fhsPrimitives.colors.houseDark}`,
  // 'box-shadow-pattern-reversed': `0 0 0 4px ${fhsPrimitives.colors.houseYellowLighten20}`,
  // 'box-shadow-toggle': `inset 0 0 0 4px ${fhsPrimitives.colors.houseDark}`,
  // 'drop-shadow-bottom-nav': `0 -1px 1px ${fhsPrimitives.colors.houseDark}`,
  // 'drop-shadow-component': `0 4px 4px ${fhsPrimitives.colors.houseDark}`,
  // 'drop-shadow-top-nav': `0 1px 1px ${fhsPrimitives.colors.houseDark}`,
  // 'height-button-large': '48px',
  // 'height-button-small': '32px',
  // 'height-checkbox-radio': '20px',
  // 'height-chip': '24px',
  // 'height-icon': '24px',
  // 'height-icon-small': '16px',
  // 'height-input': '48px',
  // 'height-tabs': '56px',
  // 'icon-active': fhsPrimitives.colors.houseRed,
  // 'icon-button-primary': fhsPrimitives.colors.white,
  // 'icon-button-primary-reversed': fhsPrimitives.colors.houseRed,
  // 'icon-button-reversed': fhsPrimitives.colors.white,
  // 'icon-button-secondary': fhsPrimitives.colors.houseRed,
  // 'icon-button-tertiary-default': fhsPrimitives.colors.houseRed,
  // 'icon-button-tertiary-hover': fhsPrimitives.colors.houseRed,
  // 'icon-default': fhsPrimitives.colors.houseRed,
  // 'icon-disabled': fhsPrimitives.colors.houseDark,
  // 'icon-disabled-reversed': fhsPrimitives.colors.houseYellowLighten20,
  // 'icon-form-checkbox': fhsPrimitives.colors.white,
  // 'icon-form-input-action': fhsPrimitives.colors.houseRed,
  // 'icon-form-input-default': fhsPrimitives.colors.houseDark,
  // 'icon-reversed': fhsPrimitives.colors.houseLight,
  // 'padding-button-icon-end': `${fhsPrimitives.spacing.spacing0} ${fhsPrimitives.spacing.spacing2} ${fhsPrimitives.spacing.spacing0} ${fhsPrimitives.spacing.spacing4}`,
  // 'padding-button-icon-start': `${fhsPrimitives.spacing.spacing0} ${fhsPrimitives.spacing.spacing4} ${fhsPrimitives.spacing.spacing0} ${fhsPrimitives.spacing.spacing2}`,
  // 'padding-button-large': `${fhsPrimitives.spacing.spacing0} ${fhsPrimitives.spacing.spacing5}`,
  // 'padding-button-small': `${fhsPrimitives.spacing.spacing0} ${fhsPrimitives.spacing.spacing4}`,
  // 'padding-input': `${fhsPrimitives.spacing.spacing0} ${fhsPrimitives.spacing.spacing4}`,
  // 'text-active': fhsPrimitives.colors.houseRed,
  // 'text-button-primary': fhsPrimitives.colors.white,
  // 'text-button-primary-reversed': fhsPrimitives.colors.houseRed,
  // 'text-button-reversed': fhsPrimitives.colors.white,
  // 'text-button-secondary': fhsPrimitives.colors.houseRed,
  // 'text-button-tertiary-default': fhsPrimitives.colors.houseRed,
  // 'text-button-tertiary-hover': fhsPrimitives.colors.houseRed,
  // 'text-default': fhsPrimitives.colors.houseRed,
  // 'text-disabled': fhsPrimitives.$disabledText,
  // 'text-disabled-reversed': fhsPrimitives.$disabledTextReversed,
  // 'text-error': fhsPrimitives.$error,
  // 'text-hero-light': fhsPrimitives.colors.houseRed,
  // 'text-hero-reversed': fhsPrimitives.colors.houseLight,
  // 'text-link-default': fhsPrimitives.colors.houseRed,
  // 'text-link-hover': fhsPrimitives.colors.houseRed,
  // 'text-link-reversed': fhsPrimitives.colors.houseLight,
  // 'text-reversed': fhsPrimitives.colors.houseLight,
  // 'text-style-back-nav': 'var(--font-copyOne)',
  // 'text-style-bottom-nav': 'var(--font-copyTwo)',
  // 'text-style-button-large': 'var(--font-headerThree)',
  // 'text-style-button-small': fhsPrimitives.colors.headerFour,
  // 'text-style-card-copy': 'var(--font-copyTwo)',
  // 'text-style-card-header': 'var(--font-headerThree)',
  // 'text-style-chip': 'var(--font-copyTwo)',
  // 'text-style-chip-rewards': fhsPrimitives.colors.headerFour,
  // 'text-style-dialog-copy': 'var(--font-copyOne)',
  // 'text-style-dialog-header': 'var(--font-headerOne)',
  // 'text-style-footer-large': 'var(--font-headerThree)',
  // 'text-style-footer-location': fhsPrimitives.colors.headerFour,
  // 'text-style-footer-small': 'var(--font-copyTwo)',
  // 'text-style-form-checkbox': 'var(--font-copyOne)',
  // 'text-style-form-checkbox-header': 'var(--font-headerThree)',
  // 'text-style-form-hint': fhsPrimitives.colors.formUtility,
  // 'text-style-form-input': 'var(--font-copyOne)',
  // 'text-style-form-label-default': 'var(--font-copyOne)',
  // 'text-style-form-label-value': fhsPrimitives.colors.formUtility,
  // 'text-style-form-legend': 'var(--font-headerThree)',
  // 'text-style-form-options': fhsPrimitives.colors.formUtility,
  // 'text-style-hero-header': 'var(--font-hero)',
  // 'text-style-hero-subheader': 'var(--font-headerThree)',
  // 'text-style-hero-terms': 'var(--font-copyTwo)',
  // 'text-style-inline-alert': 'var(--font-copyTwo)',
  // 'text-style-item-description-copy': 'var(--font-copyTwo)',
  // 'text-style-item-description-header': 'var(--font-headerThree)',
  // 'text-style-item-description-price': fhsPrimitives.colors.headerFour,
  // 'text-style-item-description-subheader': fhsPrimitives.colors.formUtility,
  // 'text-style-list-item': 'var(--font-copyOne)',
  // 'text-style-list-item-info': 'var(--font-copyTwo)',
  // 'text-style-list-item-restaurant': 'var(--font-headerThree)',
  // 'text-style-module': 'var(--font-headerOne)',
  // 'text-style-segment-header': 'var(--font-headerThree)',
  // 'text-style-tabs': 'var(--font-copyOne)',
  // 'text-style-top-nav': 'var(--font-copyOne)',
  // 'text-style-top-nav-right': fhsPrimitives.colors.headerFour,
  // 'text-tabs-default': fhsPrimitives.colors.houseRed,
  // 'text-toggle-selected': fhsPrimitives.colors.white,
  // 'transition-component': 'all .3s ease',
  // 'width-checkbox-radio': '20px',
  // 'width-icon': '24px',
  // 'width-icon-small': '16px',
};
