import { ComponentProps } from 'react';

import { Box, IconButton } from '@fhs-legacy/universal-components';

export interface INumberInputProps {
  // Required fields
  value: number;
  onChange: (value: number) => void;
  // Min/Max/Step/id
  min?: number;
  max?: number;
  step?: number;
  // Disabled props
  disabled?: boolean;
  disableIncrement?: boolean;
  disableDecrement?: boolean;
  isReadOnly?: boolean;
  // Custom UI/UX props
  hideNumberInput?: boolean;
  displayedValue?: string | number;
  onStepButtonClick?: () => void;
  valueWidth?: string;
  // Accessibility
  ariaLabel: string;
  decrementAriaLabel: string;
  incrementAriaLabel: string;
  screenReaderTextOverride?: string;
  // data-testids
  testID?: string;
  displayValueStyleProps?: (args: { disabled?: boolean }) => ComponentProps<typeof Box>;
  incrementorStyleProps?: (args: { disabled?: boolean }) => ComponentProps<typeof IconButton>;
}
