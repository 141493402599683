import { useCallback, useState } from 'react';

import {
  GetStaticPageQuery,
  IGetStaticPageQuery,
  IGetStaticPageVariables,
} from 'remote/queries/static-page';
import { useLazySanityQuery } from 'state/graphql';
import { useLocale } from 'state/intl';

import { useStaticPageRoutes } from './use-static-page-routes';

export const useGetStaticPageByRoute = () => {
  const [is404, setIs404] = useState(false);
  const { language } = useLocale();
  const {
    loadRoutes,
    routes,
    loading: routesLoading,
    loadRoutesHasBeenCalled,
  } = useStaticPageRoutes();

  const [getPage, { data: pageData, loading: pageLoading }] = useLazySanityQuery<
    IGetStaticPageQuery,
    IGetStaticPageVariables
  >(GetStaticPageQuery);

  const getStaticPageByRouteId = useCallback(
    (staticPageRoute: string) => {
      if (!loadRoutesHasBeenCalled) {
        loadRoutes();
        return;
      }
      // Getting the static page id from the static page array
      if (routes.length) {
        // Remove the / of the route if there is one before searching for the static page by route name
        const staticPageId = routes.find(
          route => route?.localePath?.[language]?.current === staticPageRoute.replace('/', '')
        )?._id;
        if (!staticPageId) {
          // If we're not finding the page, show the 404 page
          setIs404(true);
          return;
        }
        getPage({ variables: { id: staticPageId } });
      }
    },
    [getPage, language, loadRoutes, loadRoutesHasBeenCalled, routes]
  );
  return {
    currentPage: pageData?.StaticPage,
    loading: routesLoading || pageLoading,
    getStaticPageByRouteId,
    is404,
  };
};
