import React from 'react';
import { useIntl } from 'react-intl';

import { HStack, Header, Icon } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import { theme } from 'styles/configure-theme';

import {
  pointsTranslationKey,
  redeemButtonBg,
  redeemButtonBgDisabledColor,
  redeemButtonBgReversed,
  redeemButtonIconColor,
  redeemButtonTextDisabled,
} from './constants';
import { RedeemRewardActionButtonProps, RewardActions } from './types';

const RedeemRewardActionButton = ({
  action,
  disabled,
  handleRewardClick,
  points,
  locked,
  loading,
}: RedeemRewardActionButtonProps) => {
  const { formatMessage } = useIntl();
  const isAdd = action === RewardActions.ADD;

  const enabledButtonBackgroundColor = theme.token(isAdd ? redeemButtonBg : redeemButtonBgReversed);
  const buttonBackgroundColor = disabled
    ? redeemButtonBgDisabledColor
    : enabledButtonBackgroundColor;

  const enabledButtonTextColor = theme.token(isAdd ? redeemButtonBgReversed : redeemButtonBg);
  const buttonTextColor = disabled ? theme.token(redeemButtonTextDisabled) : enabledButtonTextColor;
  const buttonIconColor = redeemButtonIconColor || buttonTextColor;

  const enabledButtonVariant = isAdd ? ActionButtonVariants.PRIMARY : ActionButtonVariants.OUTLINE;
  const buttonVariant = disabled ? ActionButtonVariants.INVERSE : enabledButtonVariant;

  let icon: any = null;
  if (!disabled && !locked) {
    icon = <Icon variant={isAdd ? 'add' : 'cancel'} color={buttonTextColor} size="xs" />;
  } else if (locked) {
    icon = <Icon variant="locked" color={buttonTextColor} size="xs" />;
  }

  const accessibilityLabel = `${formatMessage(
    { id: isAdd ? 'redeemApply' : 'redeemRemove' },
    { points }
  )} ${formatMessage({ id: pointsTranslationKey })} `;

  return (
    <ActionButton
      variant={buttonVariant}
      size={ActionButtonSizes.SMALL}
      disabled={disabled}
      onPress={handleRewardClick(action)}
      testID={`redeem-${isAdd ? 'add' : 'remove'}`}
      paddingY={2}
      paddingX={loading ? 1 : 2}
      background={buttonBackgroundColor}
      isLoading={loading}
      accessibilityLabel={accessibilityLabel}
    >
      <HStack alignItems="center">
        {!loading && (
          <LoyaltyPointsIcon
            width={'27px'}
            height={'20px'}
            fill={isAdd ? buttonTextColor : buttonIconColor}
          />
        )}
        <Header variant="headerFour" color={buttonTextColor} margin={0} paddingX={1}>
          {locked
            ? points
            : formatMessage({ id: isAdd ? 'redeemApply' : 'redeemRemove' }, { points })}
        </Header>
        {icon}
      </HStack>
    </ActionButton>
  );
};

export default RedeemRewardActionButton;
