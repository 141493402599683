import type { NavigationState } from '@react-navigation/routers';

const isGroupRouteName = (routeName: string) => /\(.*\)/.test(routeName);

const sanitizeRouteName = (route: NavigationState['routes'][number], prefix: string) => {
  // Ignores `route.name` if route is routes group.
  // It shouldn't be included on pathname.
  if (isGroupRouteName(route.name)) {
    return prefix;
  }
  // Replaces params on route
  const sanitized = Object.entries(route.params ?? {}).reduce(
    (sanitizedRouteName, [paramName, paramValue]) => {
      return sanitizedRouteName.replace(`[${paramName}]`, paramValue);
    },
    route.name
  );
  // Removes "/index" suffix part from route (our routes doesn't use index suffix, it is part of expo-router)
  return `${prefix}/${sanitized.replace(/\/index$/, '')}`;
};

export const isRouteInState = (
  route?: string,
  state?: NavigationState,
  prefix: string = ''
): boolean => {
  if (!route || !state) {
    return false;
  }

  return state.routes.some(r => {
    const newRouteState: NavigationState | undefined = r.state?.key
      ? {
          ...(r.state as NavigationState),
          key: r.state?.key,
        }
      : undefined;

    const sanitizedRouteName = sanitizeRouteName(r, prefix);
    return sanitizedRouteName === route || isRouteInState(route, newRouteState, sanitizedRouteName);
  });
};
