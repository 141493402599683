import { Popover as _NbPopover } from '@fhs-legacy/native-base';

import { addWithConfig } from '../../utils';

export const NbPopover = _NbPopover;

NbPopover.Arrow = addWithConfig(_NbPopover.Arrow);
NbPopover.Body = addWithConfig(_NbPopover.Body);
NbPopover.Content = addWithConfig(_NbPopover.Content);
NbPopover.CloseButton = addWithConfig(_NbPopover.CloseButton);
NbPopover.Header = addWithConfig(_NbPopover.Header);

export default addWithConfig(NbPopover);
