import { router } from 'expo-router';
import { useContext, useEffect } from 'react';

import { AuthContext } from 'state/auth';
import { routes } from 'utils/routing';

export default function useUnauthRedirects() {
  const { loading, isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (isAuthenticated) {
      router.replace(routes.base);
    }
  }, [isAuthenticated, loading]);
}
