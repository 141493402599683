import { useState } from 'react';

import { ICartEntry, IItem } from '@rbi-ctg/menu';
import { useCartContext } from 'state/cart';
import { useMenuOptionsContext } from 'state/menu-options';
import { createCartEntry } from 'utils/cart';

type useDonationProps = {
  donationItems: IItem[];
};

function useDonation({ donationItems }: useDonationProps) {
  const {
    cartEntries,
    addItemToCart,
    removeFromCart,
  }: {
    cartEntries: ICartEntry[];
    addItemToCart: (newCartEntry: ICartEntry) => void;
    removeFromCart: ({ cartId }: { cartId: string }) => void;
  } = useCartContext();
  const { pricingFunction } = useMenuOptionsContext();

  const [selectedDonationCartEntry, setSelectedDonationCartEntry] = useState(() => {
    const preloadedDonationCartEntry = cartEntries.find(cartEntry => cartEntry.isDonation);
    if (preloadedDonationCartEntry && donationItems) {
      const isPreloadedDonationCartEntryValid = donationItems.some(
        item => item._id === preloadedDonationCartEntry._id
      );
      if (isPreloadedDonationCartEntryValid) {
        return preloadedDonationCartEntry;
      }
      removeFromCart({ cartId: preloadedDonationCartEntry.cartId });
    }
    return null;
  });

  function handleAddDonationCartEntry(donationItem: IItem) {
    if (selectedDonationCartEntry?._id === donationItem._id) {
      return;
    }

    if (selectedDonationCartEntry) {
      removeFromCart({ cartId: selectedDonationCartEntry.cartId });
    }

    const newDonationCartEntry: ICartEntry = {
      ...createCartEntry({
        item: donationItem,
        price: pricingFunction(donationItem),
      }),
      isDonation: true,
    };
    addItemToCart(newDonationCartEntry);
    setSelectedDonationCartEntry(newDonationCartEntry);
  }

  function handleRemoveDonationCartEntry({ cartId }: { cartId: string }) {
    removeFromCart({ cartId });
    setSelectedDonationCartEntry(null);
  }

  function handleOnEnableDonationChange(isEnabled: boolean) {
    if (isEnabled) {
      const defaultDonationItem = donationItems[0];
      handleAddDonationCartEntry(defaultDonationItem);
    } else {
      if (selectedDonationCartEntry) {
        handleRemoveDonationCartEntry({ cartId: selectedDonationCartEntry?.cartId });
      }
    }
  }

  return {
    selectedDonationCartEntry,
    isDonationEnabled: !!selectedDonationCartEntry,
    handleAddDonationCartEntry,
    handleRemoveDonationCartEntry,
    handleOnEnableDonationChange,
  };
}

export default useDonation;
