import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconLocationOutline = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M12 19.76c2.034-1.636 3.574-3.205 4.62-4.707 1.045-1.502 1.568-2.929 1.568-4.279 0-1.16-.209-2.144-.627-2.952-.418-.809-.941-1.47-1.569-1.983a6.069 6.069 0 0 0-2.024-1.112c-.723-.229-1.379-.343-1.968-.343-.59 0-1.245.114-1.968.343-.722.228-1.397.599-2.024 1.112-.628.514-1.15 1.174-1.569 1.983-.418.808-.627 1.792-.627 2.952 0 1.35.523 2.777 1.568 4.28 1.046 1.501 2.586 3.07 4.62 4.706Zm0 1.34a2.08 2.08 0 0 1-.342-.028.511.511 0 0 1-.285-.142c-2.32-1.826-4.045-3.571-5.176-5.235C5.066 14.03 4.5 12.39 4.5 10.775c0-1.275.228-2.388.684-3.338.457-.951 1.041-1.75 1.754-2.397a7.205 7.205 0 0 1 2.41-1.454A7.757 7.757 0 0 1 12 3.1c.874 0 1.759.161 2.652.485a7.205 7.205 0 0 1 2.41 1.454c.713.647 1.297 1.446 1.754 2.397.456.95.684 2.063.684 3.337 0 1.617-.566 3.257-1.697 4.92-1.13 1.665-2.856 3.41-5.176 5.236a.511.511 0 0 1-.285.142 2.08 2.08 0 0 1-.342.029Zm0-8.728a1.73 1.73 0 0 0 1.269-.528c.352-.352.528-.775.528-1.27 0-.494-.176-.912-.528-1.255A1.754 1.754 0 0 0 12 8.806a1.73 1.73 0 0 0-1.269.528 1.73 1.73 0 0 0-.528 1.269c0 .494.176.913.528 1.255.352.342.775.514 1.269.514Z"
      />
    </Svg>
  );
};
