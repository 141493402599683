import React from 'react';

import { Box, Skeleton } from '@fhs-legacy/universal-components';

export const CurrencyLineSkeleton = () => (
  <Box width="full">
    <Skeleton
      testID="currency-line-skeleton"
      height="15px"
      width="full"
      color={Styles.color.grey.four}
    />
  </Box>
);
