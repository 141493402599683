import { useCallback } from 'react';

import { IOffersFragment } from 'generated/graphql-gateway';
import { useLocaleSmartBlockContent } from 'hooks/use-locale-smart-block-content/use-locale-smart-block-content';
import logger from 'utils/logger';

import { LoyaltyOffer } from '../types';

import { IPersonalizedData, mergePersonalizedData } from './utils/personalized-offers';

export const useGetPersonalizedOffer = () => {
  const { transformSmartBlockContent } = useLocaleSmartBlockContent();
  const getPersonalizedOffer = useCallback(
    (
      offer: IOffersFragment,
      personalizedOfferData: IPersonalizedData
    ): LoyaltyOffer | undefined => {
      let personalizedOffer;

      try {
        personalizedOffer = mergePersonalizedData({
          engineOffer: offer,
          transformSmartBlockContent,
          ...personalizedOfferData,
        });
      } catch (error) {
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        const message = error?.message ? `Error: ${error.message}` : '';
        logger.error(`Failed processing personalized offer: ${offer.id} - ${message}`);
      }

      return personalizedOffer;
    },
    [transformSmartBlockContent]
  );
  return { getPersonalizedOffer };
};
