import { Box, Text } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

export const Disclaimer = Text.withConfig({
  variant: 'copyTwo',
  marginBottom: '$3',
});

export const MarketDisclaimer = Text.withConfig({
  variant: 'copyTwo',
  marginBottom: '$4',
  textAlign: 'center',
});

export const FieldContainer = Box.withConfig({
  marginBottom: '$1',
});

export const ErrorMessage = Text.withConfig({
  variant: 'copyOne',
  color: Styles.color.error,
  fontSize: 'xs',
});

export const StyledFavoriteStoreInput = Box.withConfig<{ hasError?: boolean; disabled?: boolean }>(
  ({ hasError, disabled }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 'sm',
    borderColor: hasError ? '#D14545' : '#b2b2b2',
    borderWidth: '1',
    paddingX: '$4',
    paddingY: '$2',
    background: disabled
      ? theme.token('background-input-disabled')
      : theme.token('background-input'),
  })
);

export const FavoriteInputText = Text.withConfig<{ disabled?: boolean }>(({ disabled }) => ({
  color: disabled ? theme.token('text-disabled') : theme.token('text-default'),
}));
