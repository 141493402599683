import { router, useLocalSearchParams } from 'expo-router';
import { useContext, useEffect } from 'react';

import { usePathname } from 'hooks/use-pathname';
import useRoutes from 'hooks/use-routes';
import { AuthContext } from 'state/auth';
import useAuthFlow from 'state/auth/hooks/use-auth-flow';
import { routes } from 'utils/routing';

interface IAuthRedirectsProps {
  skip?: boolean;
  keepParams?: boolean;
  searchParamsOverride?: Record<string, string>;
}

export const authRedirectsDefaultOpts: IAuthRedirectsProps = {
  skip: false,
  keepParams: false,
  searchParamsOverride: undefined,
};

export default function useAuthRedirects(opts = authRedirectsDefaultOpts) {
  const { loading, isAuthenticated, originLocation, setOriginLocation } = useContext(AuthContext);
  const originRouteName = usePathname();
  const params = useLocalSearchParams<Record<string, any>>();
  const { getLocalizedRouteForPath } = useRoutes();
  const { isInAuthFlow } = useAuthFlow();

  useEffect(() => {
    if (opts.skip) {
      return;
    }

    if (loading) {
      return;
    }

    if (isAuthenticated && originLocation) {
      const nextLocation = originLocation;
      setOriginLocation(null);
      router.replace(nextLocation);
    } else if (!isAuthenticated && !isInAuthFlow) {
      const originLocationURLParams = new URLSearchParams(params).toString();
      const originLocationWithParams = `${originRouteName}${
        originLocationURLParams ? `?${originLocationURLParams}` : ''
      }`;
      setOriginLocation(originLocationWithParams);

      const searchParams = opts.searchParamsOverride ?? params;
      const nextSearchParams = opts.keepParams ? searchParams : {};
      const next =
        getLocalizedRouteForPath(routes.signUp) + convertObjectToSearchParameters(nextSearchParams);
      router.replace({ pathname: next, params: nextSearchParams });
    }
  }, [
    isAuthenticated,
    loading,
    originLocation,
    setOriginLocation,
    getLocalizedRouteForPath,
    isInAuthFlow,
    originRouteName,
    params,
    opts.skip,
    opts.searchParamsOverride,
    opts.keepParams,
    opts,
  ]);
}

function convertObjectToSearchParameters(obj: object) {
  const params = Object.keys(obj)
    .map(key => `${key}=${obj[key]}`)
    .join('&');

  if (params.length) {
    return `?${params}`;
  }

  return '';
}
