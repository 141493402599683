import { Stack } from 'expo-router';

import { getScreenTitleHeader } from '../../../../components/get-screen-title-header';

export default function PaymentLayout() {
  return (
    <Stack>
      <Stack.Screen
        name="index"
        options={{
          header: getScreenTitleHeader(),
          title: 'Payment Methods',
        }}
      />
      <Stack.Screen
        name="add-card"
        options={{
          presentation: 'modal',
          header: getScreenTitleHeader(),
          title: 'Add Payment Method',
        }}
      />
    </Stack>
  );
}
