import { ScrollView } from '@fhs/ui';

import { ScreenContentProps } from './types';

export function ScreenContentMobile({ children, before, after, ...props }: ScreenContentProps) {
  return (
    <ScrollView {...props} keyboardShouldPersistTaps="always">
      {before ?? null}
      {children}
      {after ?? null}
    </ScrollView>
  );
}
