import { StyleSheet, View } from 'react-native';

import { tokens } from '../../tokens';
import { usePressableState } from '../pressable';

import { useRadioItem } from './context';

export type RadioCircleIndicatorProps = {
  size?: 'sm' | 'md';
};
/**
 * This is a non-interactive circle indicator for the wrapping radio item.
 * It is for if you want to render an indicator inside of a RadioPressable.
 * You don't want to use a RadioControl when wrapping in a RadioPressable
 * because they would duplicate accessibility functionality while also adding
 * a nested focusable component.
 */
export const RadioCircleIndicator = ({ size = 'md' }: RadioCircleIndicatorProps) => {
  const radioItem = useRadioItem();
  const pressableState = usePressableState();

  return (
    <View
      style={[
        styles.outerCircle,
        size === 'sm' && styles.outerCircleSmall,
        pressableState.hovered && styles.outerCircleHover,
        radioItem.selected && styles.outerCircleSelected,
        pressableState.disabled && radioItem.selected && styles.outerCircleDisabledAndSelected,
      ]}
    >
      <View
        style={[
          styles.innerCircle,
          size === 'sm' && styles.innerCircleSmall,
          radioItem.selected && styles.innerCircleSelected,
          radioItem.disabled && styles.disabled,
          radioItem.selected && radioItem.disabled && styles.disabledSelected,
        ]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  outerCircle: {
    borderRadius: 100,
    width: 24,
    height: 24,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  outerCircleSmall: {
    width: 16,
    height: 16,
  },
  outerCircleSelected: {
    borderColor: tokens.colors.$houseRedDarken,
  },
  outerCircleHover: {
    borderColor: tokens.colors.$houseDark,
  },
  outerCircleDisabledAndSelected: {
    borderColor: tokens.colors.$transparent,
  },
  innerCircle: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 100,
    borderColor: tokens.colors.$white,
    borderWidth: 6,
    backgroundColor: tokens.colors.$transparent,
  },
  innerCircleSmall: {
    borderWidth: 4,
  },
  innerCircleSelected: {
    borderColor: tokens.colors.$houseRedDarken,
  },
  disabledSelected: {
    borderColor: tokens.colors.$houseRed10,
  },
  disabled: {
    borderColor: tokens.colors.$white,
  },
});
