import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

export default function useStaticIdentifier() {
  const { loyaltyUser, loyaltyUserId } = useLoyaltyUser();
  const isStaticIdentifierEnabled = Boolean(
    useFlag(LaunchDarklyFlag.ENABLE_STATIC_IN_STORE_IDENTIFICATION)
  );
  const enableLoyaltyAlohaIntegration = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_ALOHA_INTEGRATION);
  const legacyLoyaltyIdMetadata = loyaltyUser?.metadata?.find(
    metadata => metadata?.key === 'legacyLoyaltyId'
  );
  const loyaltyQRCodeId = loyaltyUser?.metadata?.find(
    metadata => metadata?.key === 'loyaltyQRCodeId'
  )?.value;
  let staticIdentifier;
  if (enableLoyaltyAlohaIntegration) {
    staticIdentifier = legacyLoyaltyIdMetadata?.value || null;
  } else if (loyaltyQRCodeId) {
    staticIdentifier = loyaltyQRCodeId;
  } else {
    // The static identifier is the loyalty id but this can be changed depending on biz requirements
    staticIdentifier = isStaticIdentifierEnabled && loyaltyUserId ? loyaltyUserId : null;
  }
  return { staticIdentifier, isStaticIdentifierEnabled };
}
