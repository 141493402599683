import { Stack, useLocalSearchParams } from 'expo-router';
import Head from 'expo-router/head';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useRefundEligibility } from 'hooks/refund-eligibility/use-refund-eligibility';
import { useOrderTimeTimezone } from 'hooks/use-order-time-timezone';
import { useSupportData } from 'hooks/use-support-data';
import { serializeServiceMode } from 'state/crm-events/utils';
import { formatDateObject } from 'utils/form';

import {
  useGetSupportCategoryGroupQuery,
  useGetSupportSubcategoryQuery,
} from '../../../generated/sanity-graphql';
import useAuth from '../../../hooks/auth/use-auth';
import { useOrderDetails } from '../../../hooks/use-order-details';
import { useLocale } from '../../../state/intl';
import { REGIONS } from '../../../state/intl/types';
import { useKnowledgeForceCaseCreation } from '../../../state/knowledge-force/hooks/use-knowledge-force-case-creation';
import { ISupportFormData } from '../../../state/knowledge-force/types';
import { LaunchDarklyFlag, useFlag } from '../../../state/launchdarkly';
import { ErrorView } from '../error-view';
import { LoadingView } from '../loading-view';
import { InnerBox } from '../support-form-v2.styled';
import { ISupportV2FormPageProps } from '../types';

import { SupportV2FormView } from './support-v2-form-view';

/**
 * Displays the support form and thank-you/error messages after submission
 */
const SupportV2FormPage: FC<React.PropsWithChildren<unknown>> = () => {
  const params = useLocalSearchParams<ISupportV2FormPageProps>();
  const { orderId, subcategoryId, categoryId, alertMessage } = params;

  const { formatMessage } = useIntl();
  const { region } = useLocale();
  const { user } = useAuth();
  const useHighVolumeSupportMessage = useFlag(LaunchDarklyFlag.USE_HIGH_VOLUME_SUPPORT_MSG);

  const { caseReferenceNumber, createCaseState, createSupportCase, createCaseError } =
    useKnowledgeForceCaseCreation();

  const { supportCategoryGroups, isSupportDataLoading } = useSupportData();
  const { data: supportSubcategoryData, loading: isSupportSubcategoryloading } =
    useGetSupportSubcategoryQuery({
      variables: { id: subcategoryId?.toString()! },
      skip: !subcategoryId,
    });
  const { data: supportSupportCategoryGroupData, loading: isSupportSupportCategoryGrouploading } =
    useGetSupportCategoryGroupQuery({
      variables: { id: categoryId?.toString()! },
      skip: !categoryId,
    });

  const { order, loading: isOrderLoading } = useOrderDetails(orderId?.toString());

  const { refundIneligibilityReason } = useRefundEligibility({ orderId: orderId?.toString() });
  const refundReason = refundIneligibilityReason || '';

  const supportCategory = supportSupportCategoryGroupData?.SupportCategoryGroup;
  const primaryCategoryName = supportCategory?.name?.locale ?? '';
  const showRestaurantSelector = supportCategory?.showRestaurantSelector;

  const supportSubcategory = supportSubcategoryData?.SupportSubcategory;
  const knowledgeForceIssueId = supportSubcategory?.knowledgeForceIssueId;
  const subcategoryName = supportSubcategory?.name?.locale ?? '';
  const showServiceModeSelector = supportSubcategory?.supportSubcategoryServiceModeSelector;
  const faqs =
    supportSubcategory?.supportSubcategoryFaqs?.map(faq => {
      return {
        title: faq?.supportFaqTitle?.locale ?? '',
        content: faq?.supportFaqContent?.localeRaw,
        mParticleEventName: faq?.supportFaqMParticleEvent,
      };
    }) || [];

  const { orderTimeTimezone } = useOrderTimeTimezone(order);

  const initialValues: ISupportFormData = useMemo(
    () => ({
      comments: '',
      email: user?.details?.email ?? '',
      phoneNumber: user?.details?.phoneNumber ?? '',
      issueId: knowledgeForceIssueId || '',
      name: user?.details?.name ?? '',
      agreesToTermsOfService: false,
      serviceMode: '',
      issueSubCategory: `${primaryCategoryName}: ${subcategoryName}`,
      ...(!!showRestaurantSelector && {
        orderTime: order?.createdAt || '',
        storeId: '',
        storeAddress: '',
        orderDateTime: {
          month: '',
          day: '',
          year: '',
        },
      }),
    }),
    [
      user,
      knowledgeForceIssueId,
      order,
      primaryCategoryName,
      subcategoryName,
      showRestaurantSelector,
    ]
  );

  const handleSupportFormSubmit = (formData: ISupportFormData) => {
    const { agreesToTermsOfService, orderDateTime, ...sanitizedFormData } = formData;

    if (!agreesToTermsOfService) {
      return;
    }

    const userAccountRegion = user?.details?.isoCountryCode ?? null;

    const submissionData = {
      ...sanitizedFormData,
      ...(order?.rbiOrderId && { rbiOrderId: order.rbiOrderId }),
      ...(order?.cart?.serviceMode && {
        serviceMode: serializeServiceMode(order?.cart?.serviceMode),
      }),
      ...(showRestaurantSelector && { orderDateTime: formatDateObject(orderDateTime) }),
      userRegion: REGIONS[region],
      userAccountRegion,
      localStoreOrderTime: orderTimeTimezone,
    };

    createSupportCase(submissionData);
  };

  if (
    isSupportDataLoading ||
    isSupportSubcategoryloading ||
    isOrderLoading ||
    isSupportSupportCategoryGrouploading
  ) {
    return <LoadingView />;
  }

  if (!supportCategoryGroups || supportCategoryGroups.length === 0) {
    return <ErrorView message={formatMessage({ id: 'looksLikeWereExperiencingIssues' })} />;
  }

  return (
    <InnerBox>
      <Stack.Screen options={{ title: subcategoryName }} />
      <Head>
        <title>{`${primaryCategoryName} - Firehouse Subs`}</title>
      </Head>
      <SupportV2FormView
        alertMessage={refundReason || alertMessage}
        caseReferenceNumber={caseReferenceNumber}
        createCaseError={createCaseError}
        createCaseState={createCaseState}
        faqs={faqs}
        handleSupportFormSubmit={handleSupportFormSubmit}
        initialValues={initialValues}
        serviceMode={serializeServiceMode(order?.cart?.serviceMode)}
        useHighVolumeSupportMessage={useHighVolumeSupportMessage}
        showRestaurantSelector={showRestaurantSelector}
        showServiceModeSelector={showServiceModeSelector}
      />
    </InnerBox>
  );
};

export default SupportV2FormPage;
