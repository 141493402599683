import { useField } from 'formik';

import { addWithConfig } from '../../utils';
import {
  useFormikFormControlState,
  usePropsWithFormikFormControlName,
} from '../formik-form-control';
import { NumberInput } from '../number-input';

import type { FormikNumberInputProps } from './types';

export const FormikNumberInput = (props: FormikNumberInputProps) => {
  const { name, numberInputProps = {}, ...rest } = usePropsWithFormikFormControlName(props);
  const formControlState = useFormikFormControlState();
  const [field, meta, helpers] = useField(name);
  const isInvalid = Boolean(meta.error && meta.touched);
  return (
    <NumberInput
      value={field.value}
      {...numberInputProps}
      {...rest}
      isDisabled={numberInputProps.isDisabled ?? formControlState.isDisabled}
      isInvalid={isInvalid || formControlState.isInvalid}
      onChangeValue={(nextValue) => {
        helpers.setTouched(true, false);
        helpers.setValue(nextValue, true);
      }}
    />
  );
};

export default addWithConfig(FormikNumberInput);
