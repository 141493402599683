import React from 'react';
import { useIntl } from 'react-intl';

import { ActionLink } from 'components/action-button';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { routes } from 'utils/routing';

const SignUpToContinueActionLink = () => {
  const { formatMessage } = useIntl();
  const showSignUpToContinue = useFlag(LaunchDarklyFlag.ENABLE_SIGNUP_CART_BUTTON_TEXT);
  const { setOriginLocation } = useAuthContext();
  const handleSignInToContinuePress = () => {
    setOriginLocation(routes.cart);
  };
  return (
    <ActionLink onPress={handleSignInToContinuePress} to={routes.signIn} fullWidth>
      {formatMessage({ id: showSignUpToContinue ? 'signUpToContinue' : 'continue' })}
    </ActionLink>
  );
};

export default SignUpToContinueActionLink;
