import {
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInSeconds,
  isToday,
  isTomorrow,
} from 'date-fns';
import { isString } from 'lodash';
import { IntlFormatters } from 'react-intl';

import { SupportedLanguages } from '@rbi-ctg/frontend';
import { LANGUAGES } from 'state/intl/types';
import { format } from 'utils/language/format-date';

export const fifteenMins = 15;
export const hoursInDay = 24;
export const minsInHour = 60;
export const minsInSecond = 60;
export const msInSecond = 1000;

export const US_DATE_FORMAT = 'MM/dd/yyyy';
const LOCALIZED_MONTH_DAY_YEAR_FORMAT = 'MMMM do yyyy';

export const getDayPeriod = (formatMessage: IntlFormatters['formatMessage']) => {
  const hours = new Date().getHours();
  let id;
  if (hours < 12) {
    id = 'morning';
  } else if (hours < 17) {
    id = 'afternoon';
  } else {
    id = 'evening';
  }
  return formatMessage({ id });
};

export function getAllFifteenMinsInADay() {
  // https://stackoverflow.com/a/36125641 : including fix in comments
  const allFifteenMinsInADay = Array((hoursInDay * minsInHour) / fifteenMins)
    .fill(null)
    .map((v, i) => {
      let h = Math.floor((i * fifteenMins) / minsInHour);
      const m = i * fifteenMins - h * minsInHour;
      let mS = `${m}`;
      //convert to 12 hours time
      //pad zero to minute
      if (m < 10) {
        mS = '0' + mS;
      }
      let label = 'a.m.';
      if (h >= 12) {
        label = 'p.m.';
        h -= 12;
      }
      if (h === 0) {
        h = 12;
      }
      return h + ':' + mS + ' ' + label;
    });
  return allFifteenMinsInADay;
}

export function dateTimeSelectorToUTC(date: string) {
  return new Date(new Date(date.replace(/\./g, '')).toUTCString()).toISOString();
}
const MIDNIGHT_HOUR = '12';
export function timeSelectorTo24Hour(time: string) {
  const isPM = time.includes('p.m.');
  const [timeOnly] = time.split(' ');
  const [hour, minute] = timeOnly.split(':');
  let formattedHour = hour;
  if (isPM) {
    formattedHour = `${Number(hour) + 12}`;
  } else if (hour.length === 1) {
    formattedHour = `0${hour}`;
  } else if (hour === MIDNIGHT_HOUR) {
    formattedHour = '00';
  }
  return `${formattedHour}:${minute}`;
}

const localizedDateTimeFormats = {
  'en-US': {
    date: 'MMM d, HH:mma',
  },
  'en-CA': {
    date: 'MMM d, HH:mma',
  },
  'fr-CA': {
    date: 'd MMM, HH:mma',
  },
};

export const localizedMonthDayFormatter = (dateTime: Date, locale: string): string => {
  const formattedDate = format(
    dateTime,
    localizedDateTimeFormats[locale]?.date || 'MMMM d',
    locale === 'fr-CA' ? LANGUAGES.fr : LANGUAGES.en
  );

  // ex. February 11.
  return formattedDate;
};

export const localizedShortMonthDayFormatter = (dateTime: Date, locale: string): string => {
  const formattedDate = format(
    dateTime,
    localizedDateTimeFormats[locale]?.date || 'MMM d',
    locale === 'fr-CA' ? LANGUAGES.fr : LANGUAGES.en
  );

  // ex. Feb 11.
  return formattedDate;
};

export const formatLocalizedDate = ({
  dateTime,
  language,
}: {
  dateTime: Date;
  language: SupportedLanguages;
}): string => {
  const formattedDate = format(dateTime, LOCALIZED_MONTH_DAY_YEAR_FORMAT, language);

  // ex. October 7th 2022
  return formattedDate;
};

export const formatLocalizedCurrentWeekDay = ({
  dateTime,
  language,
  formatMessage,
}: {
  dateTime: Date;
  language: SupportedLanguages;
  formatMessage: IntlFormatters['formatMessage'];
}): string => {
  if (isToday(dateTime)) {
    return formatMessage({ id: 'todayAlt' });
  }

  if (isTomorrow(dateTime)) {
    return formatMessage({ id: 'tomorrowAlt' });
  }

  const weekDay = format(dateTime, 'EEEE', language);

  return weekDay;
};

/** From a timestamp, get the number of day, hours, minutes, seconds, screenReaderMins and screenReaderSec */
export const getCountdownValue = (timeLeft: number) => {
  if (timeLeft <= 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      screenReaderSec: 0,
      screenReaderMins: 0,
    };
  }

  // Get the time left in seconds
  let timeLeftImMs = Math.abs(timeLeft) / msInSecond;

  // Setting up the hours in seconds and days in seconds variables
  const hoursInSecond = minsInHour * minsInSecond;
  const daysInSecond = hoursInDay * hoursInSecond;

  // Get the number of whole days and substract it to the total time left
  const currentDays = Math.floor(timeLeftImMs / daysInSecond);
  timeLeftImMs -= currentDays * daysInSecond;

  // Get the number of whole hours and substract it to the total time left
  const currentHours = Math.floor(timeLeftImMs / hoursInSecond) % hoursInDay;
  timeLeftImMs -= currentHours * hoursInSecond;

  // Get the number of whole minutes and substract it to the total time left
  const currentMinutes = Math.floor(timeLeftImMs / minsInSecond) % minsInHour;
  timeLeftImMs -= currentMinutes * minsInSecond;

  // Get the number of seconds left (in theory the modulus is not required)
  const currentSeconds = Math.floor(timeLeftImMs % minsInSecond);

  // Getting some variables for the screen reader
  const ROUND_VALUE = 15; // Round up to nearest 15 seconds
  const roundedSeconds = Math.ceil(currentSeconds / ROUND_VALUE) * ROUND_VALUE;
  const screenReaderMins = roundedSeconds === minsInSecond ? currentMinutes + 1 : currentMinutes;
  const screenReaderSec = roundedSeconds % minsInSecond;

  return {
    days: currentDays,
    hours: currentHours,
    minutes: currentMinutes,
    seconds: currentSeconds,
    screenReaderMins,
    screenReaderSec,
  };
};

export enum DIFFERENCE_TIME_UNITS {
  SECONDS = 'seconds',
  MINUTES = 'minutes',
  MILLISECONDS = 'milliseconds',
}

/**
 *
 * @param dateParam an ISO8601 string (Eg: 2021-07-27T15:44:35.602Z) or a Date object
 * @param unit [SECONDS/MINUTES] represents the units you want to compare with the stringDate
 * @returns The difference in seconds between the given date and now. 0 if stringDate is an empty string, null or undefined
 */
export const getDifferenceToNow = (unit: DIFFERENCE_TIME_UNITS, dateParam: string | Date) => {
  const now = Date.now();
  const givenDate = isString(dateParam) ? new Date(dateParam) : dateParam;

  switch (unit) {
    case DIFFERENCE_TIME_UNITS.SECONDS:
      return differenceInSeconds(givenDate, now);
    case DIFFERENCE_TIME_UNITS.MINUTES:
      return differenceInMinutes(givenDate, now);
    case DIFFERENCE_TIME_UNITS.MILLISECONDS:
      return differenceInMilliseconds(givenDate, now);
    default:
      return 0;
  }
};

export const formatTime = ({
  dateTime,
  language,
}: {
  dateTime: Date | string;
  language: SupportedLanguages;
}) => {
  return format(new Date(dateTime), 'hh:mm a', language);
};
