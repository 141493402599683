import { useApolloClient } from '@apollo/client';
import { parseInitialURLAsync } from 'expo-linking';
import { router } from 'expo-router';
import { useLocalSearchParams } from 'expo-router';
import React, { useCallback, useEffect, useState } from 'react';

import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';
import useEffectOnce from 'hooks/use-effect-once';
import { useAuthContext } from 'state/auth';
import { replace } from 'utils';
import { RBIPlatform, platform } from 'utils/environment';
import logger from 'utils/logger';
import { decodeBase64String, parseStringifiedJSON } from 'utils/parse-string';
import { routes } from 'utils/routing';

import MissingLink from './missing-link';

export interface IValidateEmailForm {
  email: string;
}

// "sanitizes" ampersand characters
export const unescapeAmpersand = replace(/(&)(amp)?;?/g, '$1');

const AuthChallengeScreen = () => {
  const {
    user: authUser,
    signOut,
    validateLogin,
    originLocation,
    setOriginLocation,
  } = useAuthContext();
  const client = useApolloClient();
  const params = useLocalSearchParams<{ user: string; token: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const user = params.user || '';
  let jwt = params.token || '';
  let username = decodeBase64String({ value: user }) || '';

  // Either if we have a login, or once we validate OTP and get an update for the user being loaded
  // we can then handle the navigate.
  useEffect(() => {
    if (authUser) {
      if (originLocation === 'GO_BACK') {
        return router.back();
      }
      setOriginLocation(null);
      const pathname = originLocation || routes.base;

      router.replace({
        pathname,
        params: {
          triggerSignInAccessibility: 'true',
        },
      });
    }
  }, [authUser, originLocation, setOriginLocation]);

  const login = useCallback(
    async (email: any) => {
      setIsLoading(true);
      try {
        await validateLogin({ username: email, jwt });
        // refetch offers when user navigates to /offers page
        client.reFetchObservableQueries();
      } catch {
        setIsLoading(false);
        router.replace(routes.base);
      }
    },
    [validateLogin, jwt, client]
  );

  const handleLogin = (jwtBody: string) => {
    const currentPlatform = platform();
    const originalRequestPlatform: RBIPlatform | undefined = parseStringifiedJSON({
      value: decodeBase64String({ value: jwtBody }) || '',
      defaultValue: {},
      onError: (error: Error) =>
        logger.warn({ error, message: 'Could not parse platform from auth jwt' }),
    }).platform;

    if (currentPlatform !== RBIPlatform.APP && originalRequestPlatform === RBIPlatform.APP) {
      setIsLoading(false);
      return;
    }

    login(username);
  };

  const handleDeepLink = async () => {
    try {
      // Parse the deep link that was used to launch the app
      const { queryParams } = await parseInitialURLAsync();

      if (!queryParams?.token || !queryParams.user) {
        signOut();
        setIsLoading(false);
        return;
      }

      username = decodeBase64String({ value: queryParams.user as string }) || '';
      jwt = queryParams.token as string;

      const jwtBody = jwt.split('.')[1];
      handleLogin(jwtBody);
    } catch (err) {
      signOut();
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    if (!jwt) {
      handleDeepLink();
      return;
    }

    const jwtBody = jwt.split('.')[1];
    handleLogin(jwtBody);
  });

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingAnimation />
      </LoadingContainer>
    );
  }

  return <MissingLink username={username} />;
};

export default AuthChallengeScreen;
