import { useIntl } from 'react-intl';

export const useAuthFlowStaticPages = () => {
  const { formatMessage } = useIntl();

  const authFlowStaticPages = [
    formatMessage({ id: 'routes.termsOfService' }),
    formatMessage({ id: 'routes.privacyPolicy' }),
    formatMessage({ id: 'routes.termsConditionsRewards' }),
  ];

  return authFlowStaticPages;
};
