import { useIntl } from 'react-intl';

import { Text, TextProps } from '@fhs-legacy/universal-components';
import { DISABLE_STORE_CHANGE_DIALOG } from 'state/store/constants';

interface Props extends TextProps {}

function StoreChangeDisclaimer(props: Props) {
  const { formatMessage } = useIntl();

  if (DISABLE_STORE_CHANGE_DIALOG) {
    return (
      <Text fontSize="xs" {...props}>
        {formatMessage({ id: 'changingYourStoreDisclaimer' })}{' '}
      </Text>
    );
  }
  return null;
}

export default StoreChangeDisclaimer;
