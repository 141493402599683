import { Path, Rect, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconPaymentTypeGooglePay = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Rect width={24} height={16} y={4.101} fill="#fff" rx={2} />
      <Rect width={23} height={15} x={0.5} y={4.601} stroke="#000" strokeOpacity={0.1} rx={1.5} />
      <Path
        fill="#4285F4"
        d="M16.8 12.215c.001-.341-.028-.681-.085-1.017h-4.716v1.926H14.7a2.315 2.315 0 0 1-.998 1.52v1.249h1.611c.944-.87 1.488-2.157 1.488-3.678Z"
      />
      <Path
        fill="#34A853"
        d="M12 17.1c1.349 0 2.485-.443 3.313-1.206l-1.612-1.25c-.448.304-1.026.477-1.702.477-1.304 0-2.41-.879-2.806-2.063h-1.66v1.288a5 5 0 0 0 4.466 2.755Z"
      />
      <Path
        fill="#FBBC04"
        d="M9.193 13.058a2.996 2.996 0 0 1 0-1.914V9.856h-1.66a5 5 0 0 0 0 4.49l1.66-1.288Z"
      />
      <Path
        fill="#EA4335"
        d="M12 9.08a2.715 2.715 0 0 1 1.917.75l1.427-1.427A4.804 4.804 0 0 0 12 7.101a5 5 0 0 0-4.467 2.755l1.66 1.288C9.589 9.959 10.696 9.08 12 9.08Z"
      />
    </Svg>
  );
};
