import { PropsWithChildren, useEffect, useRef } from 'react';
import { Animated, Easing } from 'react-native';

const IN_DURATION = 400;
const OUT_DURATION = 400;

export const PulseAnimatedView = ({ children }: PropsWithChildren) => {
  const pulseAnimation = useRef(new Animated.Value(1));
  useEffect(() => {
    const animationController = Animated.loop(
      Animated.sequence([
        Animated.timing(pulseAnimation.current, {
          toValue: 1.05,
          duration: IN_DURATION,
          useNativeDriver: true,
          easing: Easing.ease,
        }),
        Animated.timing(pulseAnimation.current, {
          toValue: 1,
          duration: OUT_DURATION,
          useNativeDriver: true,
          easing: Easing.ease,
        }),
        Animated.delay(5000 - (IN_DURATION + OUT_DURATION)),
      ])
    );
    animationController.start();
    return () => {
      animationController.stop();
    };
  }, []);
  return (
    <Animated.View style={{ transform: [{ scale: pulseAnimation.current }] }}>
      {children}
    </Animated.View>
  );
};
