export const socialVariants = {
  facebookUrl: 'facebook',
  instagramUrl: 'instagram',
  tiktokUrl: 'tiktok',
  twitterUrl: 'twitter',
  youtubeUrl: 'youtube',
};

export const socialIds = {
  facebookUrl: 'followUsOnFacebook',
  instagramUrl: 'followUsOnInstagram',
  tiktokUrl: 'followUsOnTiktok',
  twitterUrl: 'followUsOnTwitter',
  youtubeUrl: 'followUsOnYoutube',
};
