import { IPaymentMethod } from 'state/payment/types';

import { PaymentMethodCategories } from '../types';

function classifyPaymentMethods(
  methods: IPaymentMethod[],
  selectedMethodId: string
): PaymentMethodCategories {
  // @ts-expect-error TS(2739) FIXME: Type 'IPaymentMethod' is missing the following pro... Remove this comment to see the full error message
  return methods.reduce(
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    (methodsCategories, method) => {
      const isSelectedMethod = method.accountIdentifier === selectedMethodId;
      const isPrepaidMethod = !!method.prepaid;
      return {
        selectedMethod: isSelectedMethod ? method : methodsCategories.selectedMethod,
        prepaidPaymentMethod: isPrepaidMethod ? method : methodsCategories.prepaidPaymentMethod,
        unSelectedMethods: isSelectedMethod
          ? methodsCategories.unSelectedMethods
          : [...methodsCategories.unSelectedMethods, method],
      };
    },
    {
      selectedMethod: undefined,
      prepaidPaymentMethod: undefined,
      unSelectedMethods: [],
    }
  );
}

export default classifyPaymentMethods;
