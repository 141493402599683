import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconCatering = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M1.167 18.1h21a1.17 1.17 0 0 1 1.166 1.167 1.17 1.17 0 0 1-1.166 1.167h-21A1.17 1.17 0 0 1 0 19.267a1.17 1.17 0 0 1 1.167-1.166ZM13.813 7.357A2.34 2.34 0 0 0 11.666 4.1 2.34 2.34 0 0 0 9.52 7.356c-4.562.945-8.038 4.83-8.353 9.578h21c-.315-4.748-3.792-8.633-8.354-9.578Z"
      />
    </Svg>
  );
};
