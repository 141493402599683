import { Image, ImageProps } from 'expo-image';
import { Link } from 'expo-router';
import { Pressable, StyleSheet } from 'react-native';

import { useClickLogs } from '@fhs-legacy/frontend/src/hooks/use-click-logs';

export type RapidRescueCardProps = Omit<ImageProps, 'children' | 'source'> & {
  pickUpHref: string;
};

export function RapidRescueCard({ pickUpHref, ...imageProps }: RapidRescueCardProps) {
  const { logOrderNowClickEvent } = useClickLogs();
  return (
    <Link href={pickUpHref} asChild>
      <Pressable onPress={logOrderNowClickEvent}>
        <Image
          alt="Avoid The Wait. Order Online. Order Now"
          source={require('./rapid-rescue-banner.webp')}
          placeholder={{ thumbhash: 'sigKHgT6aNiGZ6doh3OYflaoNKBEICk' }}
          {...imageProps}
          style={[styles.rapidRescue, imageProps.style]}
        />
      </Pressable>
    </Link>
  );
}

const styles = StyleSheet.create({
  rapidRescue: {
    aspectRatio: 1137 / 1270,
  },
});
