import React from 'react';

interface IIcon {
  width?: string;
  color?: string;
}

const Icon: React.FC<React.PropsWithChildren<IIcon>> = () => null;

export default Icon;
