import { Path, Rect, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconPaymentTypePaypal = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Rect width={24} height={16} y={4.101} fill="#fff" rx={2} />
      <Rect width={23} height={15} x={0.5} y={4.601} stroke="#000" strokeOpacity={0.1} rx={1.5} />
      <Path
        fill="#253B80"
        d="m10.408 16.762.173-1.101-.386-.01H8.352l1.28-8.122a.105.105 0 0 1 .104-.089h3.109c1.032 0 1.744.215 2.116.638.174.2.285.407.339.636.056.24.057.526.002.876l-.004.025v.224l.175.1c.146.077.263.166.352.268.15.17.246.386.287.643.042.263.028.576-.041.931-.08.409-.208.764-.382 1.055a2.17 2.17 0 0 1-.605.663c-.23.163-.504.288-.814.367-.3.079-.643.118-1.018.118h-.242a.732.732 0 0 0-.72.614l-.018.1-.306 1.94-.014.07c-.003.023-.01.034-.019.042a.051.051 0 0 1-.032.012h-1.493Z"
      />
      <Path
        fill="#179BD7"
        d="m15.638 9.641-.032.183c-.41 2.104-1.812 2.831-3.604 2.831h-.912a.443.443 0 0 0-.437.375l-.467 2.961-.132.84a.233.233 0 0 0 .23.27h1.617a.39.39 0 0 0 .385-.328l.016-.083.304-1.932.02-.106a.389.389 0 0 1 .384-.329h.242c1.567 0 2.794-.636 3.152-2.477.15-.77.073-1.412-.324-1.863a1.548 1.548 0 0 0-.442-.342Z"
      />
      <Path
        fill="#222D65"
        d="M15.209 9.47a3.217 3.217 0 0 0-.399-.088 5.065 5.065 0 0 0-.804-.059H11.57a.387.387 0 0 0-.384.33l-.518 3.282-.015.095a.443.443 0 0 1 .438-.375h.912c1.79 0 3.193-.727 3.603-2.831l.032-.182a2.181 2.181 0 0 0-.43-.171Z"
      />
      <Path
        fill="#253B80"
        d="M11.186 9.652a.388.388 0 0 1 .384-.329h2.436c.289 0 .558.02.804.06a3.704 3.704 0 0 1 .399.088l.092.028c.12.04.233.088.337.142.122-.777-.001-1.307-.422-1.787-.463-.527-1.3-.753-2.37-.753H9.736a.444.444 0 0 0-.439.375l-1.295 8.206c-.025.163.1.31.264.31h1.919l.482-3.058.518-3.282Z"
      />
    </Svg>
  );
};
