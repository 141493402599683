import { AutocompletePrediction } from '@rbilabs/expo-maps';

export interface AutocompleteDropdownProps {
  /** location predictions (these show in the options dropdown as the user types) */
  predictions: AutocompletePrediction[] | null | undefined;
  /** This is fired when one of the options from the dropdown is selected */
  onSelection: ({ address, placeId }: { address: string; placeId?: string }) => void;
  /** This is fired when the user types, when an option in selected, or when the text input is cleared via the icon */
  onInputChange: (newInputValue: string) => void;
  /** If there is an invalid address, show this error message (currently only used on delivery form) */
  errorMessage?: string;
  /** Show this text if no address predictions are found for the given string */
  noPredictionsFoundText: string;
  /** Title for the 'clear text' icon that will be read to a user via screen reader */
  clearTextIconTitle: string;
}
