import {
  IBaseComboSlot,
  IBaseItem,
  ICombo,
  IComboSlot,
  IComboSlotSelection,
  IComboSlotSelections,
  IItem,
  IItemOption,
  IItemOptionModifier,
  IMenuObjectSettings,
  IModifierSelection,
  IOffer,
  IPicker,
  ISanityItemOptionModifier,
} from '@rbi-ctg/menu';
import { LoyaltyOffer } from 'state/loyalty/types';

export enum ComboSlotUiPattern {
  SHOW = 'show',
  COLLAPSED = 'collapsed',
  HIDDEN = 'hidden',
  LOCKED = 'locked',
}

export enum CartEntryType {
  combo = 'Combo',
  comboSlot = 'ComboSlot',
  item = 'Item',
  itemOption = 'ItemOption',
  itemOptionModifier = 'ItemOptionModifier',
  offerCombo = 'OfferCombo',
  offerItem = 'OfferItem',
  offerDiscount = 'OfferDiscount',
  donation = 'Donation',
}

export const OfferEntryTypes = [
  CartEntryType.offerCombo,
  CartEntryType.offerItem,
  CartEntryType.offerDiscount,
];

export type MenuObject =
  | IItemOptionModifier
  | ISanityItemOptionModifier
  | IBaseItem
  | IOffer
  | ICombo
  | IComboSlot
  | IBaseComboSlot
  | IItemOption
  | IItem
  | IPicker;

export type ICreateCartEntryOptions = (
  | IComboCartEntryOptions
  | IComboSlotCartEntryOptions
  | IItemCartEntryOptions
  | IItemOptionCartEntryOptions
) & { item: MenuObject | LoyaltyOffer };

export interface IBaseCartEntryOptions {
  cartId?: string;
  pathname?: string;
  reorder?: boolean;
}

export interface IBasePickerSelectionCartEntryOptions extends IBaseCartEntryOptions {
  pickerSelections?: Record<string, string>;
  curriedModifierPricingFunction?: any;
  price?: number;
  quantity?: number;
  modifierSelections?: IModifierSelection[];
  menuObjectSettings?: IMenuObjectSettings;
}
export interface IComboCartEntryOptions extends IBasePickerSelectionCartEntryOptions {
  comboSlotSelections?: IComboSlotSelections;
  pricePremiumComboSlot?: any;
}

export interface IItemCartEntryOptions extends IBasePickerSelectionCartEntryOptions {}

export interface IComboSlotCartEntryOptions extends IBaseCartEntryOptions {
  curriedModifierPricingFunction?: any;
  pricePremiumComboSlot?: any;
  selections: IComboSlotSelection[];
  modifierSelections?: IModifierSelection[];
}

export interface IItemOptionCartEntryOptions extends IBaseCartEntryOptions {
  modifierPricingFunction?: any;
}

export interface IItemOptionModifierCartEntryOptions extends IBaseCartEntryOptions {
  modifierPricingFunction?: any;
}

export interface IOfferCartEntryOptions extends IBaseCartEntryOptions {}
