import { IntlShape } from 'react-intl';

import { IOffer } from '@rbi-ctg/menu';
import { IDayPartBoundary, IValidDayPart } from 'state/day-part/hooks/use-active-day-parts';
import { DAY_PART_SELECTIONS } from 'state/main-menu/types';
import { getMenuItemDayParts, isAvailableForActiveDayParts } from 'utils/availability';
import { findBurgersForBreakfastDayPart } from 'utils/daypart';

interface IIsOfferAvailableArgs {
  offer: IOffer;
  activeDayParts?: IDayPartBoundary[];
  showBurgersForBreakfast?: boolean;
}
export const isOfferAvailableForDayParts = ({
  offer,
  activeDayParts,
  showBurgersForBreakfast,
}: IIsOfferAvailableArgs): boolean => {
  const offerDayParts = getMenuItemDayParts(offer);

  // Burgers of Breakfast
  const burgersForBreakfastDayPart =
    activeDayParts && findBurgersForBreakfastDayPart(activeDayParts);
  if (
    burgersForBreakfastDayPart &&
    offerDayParts.includes(DAY_PART_SELECTIONS.BURGERS_FOR_BREAKFAST.toLowerCase())
  ) {
    return !!showBurgersForBreakfast;
  }

  return activeDayParts && activeDayParts.length
    ? isAvailableForActiveDayParts({ activeDayParts, menuData: offer })
    : true;
};

interface IMakeOfferUnavailableMessageArgs {
  offer: IOffer;
  dayParts: ReadonlyArray<IValidDayPart>;
  formatMessage: IntlShape['formatMessage'];
}
export const makeOfferUnavailableMessage = ({
  dayParts,
  offer,
  formatMessage,
}: IMakeOfferUnavailableMessageArgs) => {
  const offerDayPart = dayParts.find(
    dayPart => dayPart.key && offer?.daypart?.includes(dayPart.key)
  );

  return offerDayPart
    ? formatMessage(
        { id: 'nonAvailableOfferUntil' },
        {
          time: offerDayPart.startTime,
        }
      )
    : formatMessage({ id: 'offerNotAvailable' });
};
