import React from 'react';

// import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import UnauthRequired from 'pages/authentication/unauth-required';

import { AuthenticationTabProps } from '../auth-home/types';

import SignInForm from './sign-in-form';

function SignIn(props: AuthenticationTabProps) {
  // useRumPageView('sign-in', 'Sign In');

  return (
    <UnauthRequired>
      <SignInForm {...props} />
    </UnauthRequired>
  );
}

export default SignIn;
