import { StyleSheet, View } from 'react-native';

import { tokens, useMqSelect } from '@fhs/ui';

export function BottomButtonContainer({ children }) {
  const buttonMargin = useMqSelect({ $gteDesktop: { padding: 28 } }, { padding: 16 });

  return <View style={[styles.buttonContainer, buttonMargin]}>{children}</View>;
}

const styles = StyleSheet.create({
  buttonContainer: {
    borderTopWidth: 1,
    borderTopColor: tokens.colors.$blackOpacity10,
  },
});
