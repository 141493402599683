import { usePropsResolution } from '@fhs-legacy/native-base';

import { addWithConfig } from '../../utils';
import { Box } from '../box';
import { Icon } from '../icon';

import type { BadgeProps } from './types';

export const Badge = ({
  accessibilityLabel,
  iconName,
  children,
  variant,
  ...props
}: BadgeProps) => {
  const resolvedProps = usePropsResolution('Badge', { variant, ...props });

  const defaultVariant = ['default-solid', 'default-outline', 'default-subtle'].includes(variant);
  const showIcon = defaultVariant && iconName;

  const iconColor = resolvedProps.color;

  return (
    // eslint-disable-next-line react-native-a11y/has-accessibility-hint
    <Box {...resolvedProps} accessibilityLabel={accessibilityLabel}>
      {showIcon && <Icon variant={iconName} size="xs" mr="$1" color={iconColor} />}
      {children}
    </Box>
  );
};

export default addWithConfig(Badge);
