import React, { FC, useMemo } from 'react';

import { Box } from '@fhs-legacy/universal-components';
import { ModifierTypes } from 'state/product-wizard/types';

import { ModifierCheckbox } from './modifier-checkbox';
import { ModifierMultipleChoices } from './modifier-multiple-choices';
import { ModifierNonNumeric } from './modifier-non-numeric';
import { ModifierNumeric } from './modifier-numeric';
import { ModifierSingleChoice } from './modifier-single-choice';
import { IModifierProps } from './types';
import { areModifierSelectionsEquals, getModifierType } from './utils';

const Modifier: FC<IModifierProps> = props => {
  const { modifier, selections } = props;

  const modifierType = getModifierType(modifier);

  const ariaLabel = useMemo(() => {
    const selectionsNames = Object.values(selections).map(sel => sel.name?.locale || '');
    return `Modifier ${modifier.name?.locale}, Current selection ${selectionsNames.join(', ')}`;
  }, [modifier.name, selections]);

  const renderModifierUI = () => {
    switch (modifierType) {
      case ModifierTypes.NON_NUMERIC:
        return <ModifierNonNumeric {...props} />;
      case ModifierTypes.CHECKBOX:
        return <ModifierCheckbox {...props} />;
      case ModifierTypes.NUMERIC:
        return <ModifierNumeric {...props} />;
      case ModifierTypes.SINGLE_CHOICE:
        return <ModifierSingleChoice {...props} />;
      case ModifierTypes.MULTIPLE_CHOICE:
        return <ModifierMultipleChoices {...props} />;
      default:
        return null;
    }
  };

  return (
    <Box
      testID="modifier"
      backgroundColor={Styles.color.cardBackground}
      accessibilityLabel={ariaLabel}
    >
      {renderModifierUI()}
    </Box>
  );
};

// Only re-render the Modifier if the selections changed
export default React.memo(Modifier, (prev, next) => {
  if (prev.selectedItem._id !== next.selectedItem._id) {
    return false;
  }

  return areModifierSelectionsEquals(prev, next);
});
