import React from 'react';
import { useIntl } from 'react-intl';

import { Text, XStack } from '@fhs/ui';
import Currency from 'components/currency';
import { CurrencyLineSkeleton } from 'pages/cart/currency-line-skeleton';

import { totalSectionStyles } from './styled';

const TaxesRow: React.FC<React.PropsWithChildren<{ taxCents: number; isLoading?: boolean }>> = ({
  taxCents,
  isLoading,
}) => {
  const { formatMessage } = useIntl();

  return (
    <XStack style={totalSectionStyles.feeRow}>
      {isLoading ? (
        <CurrencyLineSkeleton />
      ) : (
        <>
          <Text style={totalSectionStyles.feeLabel}>
            {formatMessage({
              id: 'tax',
            })}
          </Text>
          <Currency amount={taxCents} testID="totalTax" textProps={totalSectionStyles.feeLabel} />
        </>
      )}
    </XStack>
  );
};

export default TaxesRow;
