import React from 'react';

import SignIn from '../sign-in';

import { SignUpPanel } from './sign-up-home.styled';
import { AuthenticationTabProps } from './types';

const SigninTab = React.memo((props: AuthenticationTabProps) => {
  return (
    <SignUpPanel>
      <SignIn {...props} />
    </SignUpPanel>
  );
});

export default SigninTab;
