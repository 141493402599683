import { StyleSheet, View, type ViewProps } from 'react-native';

import { tokens } from '../../tokens';

export type MainScreenProps = ViewProps;

export function MainScreen(props: MainScreenProps) {
  return <View {...props} style={[styles.mainScreen, props.style]} />;
}

const styles = StyleSheet.create({
  mainScreen: {
    flex: 1,
    backgroundColor: tokens.colors.$houseLight,
  },
});
