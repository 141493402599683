import { Header, IconButton } from '@fhs-legacy/universal-components';

export const HeaderTitle = Header.withConfig({
  variant: 'headerTwo',
});

export const CloseButton = IconButton.withConfig({
  position: 'absolute',
  top: '0.75rem',
  left: 0,
  variant: 'ghost',
  iconName: 'cancel',
  size: 'sm',
});
