import { useCallback, useRef } from 'react';

import useGoogleGeolocationLibrary from 'hooks/geolocation/use-google-geolocation-library';
import { useFlag } from 'state/launchdarkly';
import { IPlaceData, parsePlaceAddress } from 'utils/geolocation';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

type GoogleGeocode = google.maps.Geocoder;
type GeocoderResult = google.maps.GeocoderResult;

export default function usePlaceIdDetails() {
  const enableStreetNumberAfterStreetName = useFlag(
    LaunchDarklyFlag.ENABLE_STREET_NUMBER_AFTER_STREET_NAME
  );
  const { libraryLoaded } = useGoogleGeolocationLibrary();
  const geocoder = useRef<GoogleGeocode>();

  const getDetails = useCallback(
    (placeId?: string): Promise<IPlaceData | undefined> =>
      new Promise(resolve => {
        if (!placeId || !libraryLoaded) {
          resolve(undefined);
        } else {
          if (!geocoder.current) {
            geocoder.current = new window.google.maps.Geocoder();
          }

          geocoder.current.geocode({ placeId }, (results, status) => {
            if (status !== window.google.maps.GeocoderStatus.OK) {
              return resolve(undefined);
            }

            const firstResult: GeocoderResult | null = results?.length ? results[0] : null;
            if (!firstResult) {
              return resolve(undefined);
            }

            const { geometry, address_components } = firstResult;
            const placeData = {
              coordinates: {
                lat: geometry!.location.lat(),
                lng: geometry!.location.lng(),
              },
              address: parsePlaceAddress(address_components, {
                useISOCountry: true,
                streetNameFirst: enableStreetNumberAfterStreetName,
              }),
            };
            resolve(placeData);
          });
        }
      }),
    [enableStreetNumberAfterStreetName, libraryLoaded]
  );

  return getDetails;
}
