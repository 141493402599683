import { Box, Header, Text } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';

export const TransformedHeadline = Header.withConfig({
  variant: 'headerThree',
  textAlign: 'left',
  marginX: '0',
  marginTop: '0',
  marginBottom: '4',
  fontSize: 20,
});

export const SentEmailText = Text.withConfig({
  variant: 'copyOne',
  color: theme.token('text-default'),
});

export const CodeSentText = Text.withConfig({
  marginY: '3',
  marginX: '0',
  variant: 'copyOne',
  textAlign: 'center',
});

export const ErrorMessage = Text.withConfig({
  color: primitive.$error,
  marginTop: '0',
  marginX: '0',
  marginBottom: '6',
  variant: 'copyTwo',
  accessibilityRole: 'alert',
});

export const NotReceived = Box.withConfig({
  margin: 0,
  textAlign: 'center',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  paddingY: '1.25',
});

export const CodeText = Text.withConfig({
  variant: 'copyThree',
});
