import { useCallback } from 'react';

import { IBackendCartEntries, ICartEntry, IItem, ISanityCombo, MenuObject } from '@rbi-ctg/menu';
import { ItemAvailabilityStatus } from 'enums/menu';
import usePosVendor from 'hooks/menu/use-pos-vendor';
import { useDayPartContext } from 'state/day-part';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuContext } from 'state/menu';
import { useStoreContext } from 'state/store';
import { getAvailabilityStatus } from 'utils/availability';
import { getMenuObjectCartQuantity } from 'utils/cart';
import { isPicker, isSection, maybeMapCartEntryToMenuObjectIdentifier } from 'utils/menu';
import { mapBackendToFrontend } from 'utils/reorder';
import {
  convertModActionsToSelections,
  getSelectionsFromMenuData,
  transformMenuObjectToCartItem,
} from 'utils/wizard';

export const useMapToFrontendCartEntry = () => {
  const { activeDayParts } = useDayPartContext();
  const {
    asyncGetRawMenuObject,
    pricingFunction,
    priceForComboSlotSelection,
    priceForItemOptionModifier,
    showBurgersForBreakfast,
  } = useMenuContext();
  const { prices } = useStoreContext();
  const { vendor } = usePosVendor();

  const enableSendDefaults = useFlag(LaunchDarklyFlag.ENABLE_PRODUCT_CUSTOMIZATION_SEND_DEFAULTS);
  return useCallback(
    async (
      cartEntry: IBackendCartEntries,
      cartEntries?: ICartEntry[]
    ): Promise<{
      cartEntry: ICartEntry;
      data: MenuObject | null;
      status: ItemAvailabilityStatus;
    }> => {
      const frontendEntry = mapBackendToFrontend(cartEntry);
      const itemId = maybeMapCartEntryToMenuObjectIdentifier(frontendEntry);

      if (!itemId) {
        return { cartEntry: frontendEntry, data: null, status: ItemAvailabilityStatus.UNAVAILABLE };
      }

      const data = await asyncGetRawMenuObject(itemId);

      // Check if Sanity data exist
      if (!data) {
        return { cartEntry: frontendEntry, data: null, status: ItemAvailabilityStatus.OUT_OF_MENU };
      }

      // Pickers & Sections are not supported
      if (isSection(data) || isPicker(data)) {
        return { cartEntry: frontendEntry, data: null, status: ItemAvailabilityStatus.UNAVAILABLE };
      }

      const availabilityStatus = getAvailabilityStatus({
        data: data as IItem | ISanityCombo,
        prices,
        vendor,
        activeDayParts,
        isExtra: frontendEntry.isExtra ?? false,
        showBurgersForBreakfast,
      });

      if (availabilityStatus !== ItemAvailabilityStatus.AVAILABLE) {
        return { cartEntry: frontendEntry, data, status: availabilityStatus };
      }

      if (cartEntries?.length) {
        const { maxCartQuantityMet } = getMenuObjectCartQuantity({
          menuObject: data,
          quantityToAdd: frontendEntry.quantity,
          cartEntries,
        });
        if (maxCartQuantityMet) {
          return {
            cartEntry: frontendEntry,
            data,
            status: ItemAvailabilityStatus.MAX_CART_QUANTITY_MET,
          };
        }
      }

      // get the backend cart entry item from the menu data to ensure up to date selection
      const { modifierSelections: modSelectionActions, comboSlotSelections } =
        getSelectionsFromMenuData({
          data,
          selectionsEntry: frontendEntry,
        });

      const modifierSelections = modSelectionActions
        ? convertModActionsToSelections(modSelectionActions)
        : [];

      // create frontend cart entry from menu item, again by not going directly from
      // backend -> frontend, we avoid the reorder possibly adding out of date information to cart
      const { mainEntry } = transformMenuObjectToCartItem(
        data,
        priceForItemOptionModifier,
        pricingFunction,
        priceForComboSlotSelection,
        {
          quantity: cartEntry.quantity,
          modifierSelections,
          comboSlotSelections,
          pathname: frontendEntry.url,
          reorder: true,
          menuObjectSettings: data.menuObjectSettings,
        },
        enableSendDefaults
      );

      if (!mainEntry) {
        return { cartEntry: frontendEntry, data, status: ItemAvailabilityStatus.UNAVAILABLE };
      }

      return { cartEntry: mainEntry, data, status: availabilityStatus };
    },
    [
      activeDayParts,
      asyncGetRawMenuObject,
      priceForComboSlotSelection,
      priceForItemOptionModifier,
      prices,
      pricingFunction,
      showBurgersForBreakfast,
      vendor,
      enableSendDefaults,
    ]
  );
};
