import { Circle, Rect, Svg } from 'react-native-svg';

import { IconContainer } from './icon-container';

export const EnablePreciseLocation = () => (
  <IconContainer>
    <Svg width="22" height="11" viewBox="0 0 22 11" fill="none">
      <Rect width="22" height="11" rx="5.5" fill="#3F78FF" />
      <Circle cx="16.5" cy="5.5" r="4.125" fill="white" />
    </Svg>
  </IconContainer>
);
