import { Box, Header } from '@fhs-legacy/universal-components';
import { ModalContent as OriginalModalContent } from 'components/modal';

export const ModalHeading = Box.withConfig({
  width: 'full',
  maxWidth: 'full',
});

export const ModalContent = OriginalModalContent.withConfig({
  flex: 1,
  width: 'full',
  paddingY: '$4',
});

export const ModalTitle = Header.withConfig({
  variant: 'headerThree',
});
