import React from 'react';

import { EndOfOffers } from 'components/offers/ui-refresh/end-of-offers';
import { useLoyaltyOffersUiLoggedInQuery } from 'generated/sanity-graphql';
import { maybeLocale } from 'utils/graphql';

const LoyaltyEndOfOffersLoggedIn = () => {
  const { data } = useLoyaltyOffersUiLoggedInQuery({
    variables: {
      id: 'feature-loyalty-offers-ui-singleton',
    },
  });
  const message = maybeLocale(data?.LoyaltyOffersUI?.endOfOffersLoggedIn?.endOfOffersMessage);
  const image = maybeLocale(data?.LoyaltyOffersUI?.endOfOffersLoggedIn?.endOfOffersImage);
  if (!message || !image) {
    return null;
  }
  return <EndOfOffers message={message || ''} image={image} />;
};

export default LoyaltyEndOfOffersLoggedIn;
