import { Path, Rect, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconPaymentTypeCash = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Rect width={24} height={16} y={4.101} fill="#198737" rx={2} />
      <Path
        fill="#F5EBDC"
        d="M11.85 16.868h.468c.338 0 .546-.195.546-.546v-.468c0-.104.065-.182.169-.208 1.144-.286 1.872-1.21 1.872-2.38 0-1.507-.923-2.196-2.236-2.586-1.313-.39-1.703-.663-1.703-1.144 0-.416.429-.741 1.027-.741.585 0 .936.338 1.222.936.273.546 1.391.312 1.274-.494-.052-.377-.104-.676-.143-.923-.078-.338-.338-.572-.728-.702-.208-.065-.364-.117-.572-.17-.117-.012-.182-.09-.182-.194V6.78c0-.351-.208-.546-.546-.546h-.468c-.351 0-.559.182-.559.546v.429c0 .117-.052.195-.156.22-1.274.313-2.028 1.106-2.028 2.276 0 1.43.975 2.145 2.08 2.457 1.131.325 1.586.65 1.586 1.3 0 .455-.325.832-.871.832-.585 0-1.118-.364-1.417-1.131-.286-.78-1.586-.455-1.417.35.091.43.143.95.195 1.236.052.299.325.48.663.624.26.117.65.208 1.196.299.117.013.169.078.169.195v.455c0 .364.208.546.559.546Z"
      />
    </Svg>
  );
};
