import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconStarRate = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M7.484 20.007c-.156.12-.309.125-.457.012-.148-.112-.195-.254-.143-.427l1.697-5.57-4.412-3.134c-.156-.104-.204-.246-.143-.428a.366.366 0 0 1 .378-.272h5.482l1.749-5.802a.522.522 0 0 1 .143-.208.33.33 0 0 1 .222-.077.33.33 0 0 1 .222.077c.06.052.109.121.143.208l1.75 5.802h5.48c.192 0 .319.091.38.272.06.182.012.324-.144.428l-4.412 3.134 1.697 5.57c.052.173.005.315-.143.428-.148.112-.3.108-.457-.013L12 16.587l-4.516 3.42Zm1.227-2.487L12 15.059l3.289 2.46-1.28-4.118 3.003-1.995h-3.655L12 7.261l-1.357 4.145H6.988l3.002 1.995-1.279 4.119Z"
      />
    </Svg>
  );
};
