import { Box } from '@fhs-legacy/universal-components';

export const verticalCenteringStyles = {
  justifyContent: 'space-around',

  marginTop: {
    base: 0,
  },

  marginBottom: {
    base: 0,
  },
};

export const MapContainer = Box.withConfig({
  flexGrow: 2,
  flexShrink: 1,
  flexBasis: 0,
  width: '100%',
  ...verticalCenteringStyles,
});

export const OrderCardScroller = Box.withConfig({
  position: 'absolute',

  bottom: {
    base: 0,
    desktop: 'unset',
  },

  left: {
    base: 0,
    desktop: 0,
  },

  top: {
    desktop: 128,
  },

  width: {
    base: '100%',
    desktop: '100%',
  },
});

export const OrderCardScrollContainer = Box.withConfig({
  padding: '$5',
  display: 'inline-flex',

  width: {
    desktop: '100%',
  },

  justifyContent: {
    desktop: 'center',
  },
});

export const OrderCard = Box.withConfig({
  backgroundColor: Styles.color.white,
  borderTopLeftRadius: Styles.borderRadius,
  borderTopRightRadius: Styles.borderRadius,
  borderBottomRightRadius: Styles.borderRadius,
  borderBottomLeftRadius: Styles.borderRadius,

  style: {
    shadowColor: Styles.shadowColor,
    shadowOffset: {
      width: 0,
      height: 0.5,
    },
    shadowRadius: 1.5,
    shadowOpacity: 1,
  },

  paddingTop: '$9',
  paddingRight: '$9',
  paddingBottom: '23px',
  paddingLeft: '$9',

  marginRight: '$4',

  width: {
    base: 'calc(100% - 48px)',
    desktop: '80%',
  },

  maxWidth: {
    desktop: 500,
  },
});

export const SignupCard = OrderCard.withConfig({
  justifyContent: 'space-between',
  textAlign: 'center',
  marginRight: '$16',

  display: {
    desktop: 'none',
  },
});

export const LogoContainer = Box.withConfig({
  alignSelf: 'center',
  width: '$32',
  marginTop: {
    base: '$4',
  },
});

export const NarrowSection = Box.withConfig({
  maxWidth: Styles.layout.smallerSectionMaxWidth,
  alignSelf: 'center',

  paddingX: {
    base: '$6',
    desktop: '44px',
  },

  paddingY: {
    base: 0,
    desktop: 0,
  },
});

export const QRContainer = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '2em',
  marginBottom: '1em',
});

export const QRWrapper = Box.withConfig({
  padding: '0.75em',
  backgroundColor: 'white',
});

export const PickupPin = Box.withConfig({
  padding: '$2',

  _text: {
    textAlign: 'center',
    fontSize: '3xl',
  },
});
