import React, { useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import { syncWithStorage } from './global-actions';
import { store } from './store';

export const GlobalStateProvider = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    store.dispatch(syncWithStorage());
  }, []);

  return <ReduxProvider store={store}>{children}</ReduxProvider>;
};
