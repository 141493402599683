import { Checkbox as NbCheckbox } from '@fhs-legacy/native-base';

import { addWithConfig } from '../../utils';
import { Icon } from '../icon';

import type { CheckboxProps, _CheckboxComponentType } from './types';

export const Checkbox = (({ ...props }: CheckboxProps) => (
  <NbCheckbox
    {...props}
    size="md"
    icon={<Icon variant={props.isNullVariation ? 'remove' : 'check'} />}
  />
)) as _CheckboxComponentType;

Checkbox.Group = NbCheckbox.Group;

const CheckboxWithConfig = addWithConfig(Checkbox);
CheckboxWithConfig.Group = addWithConfig(Checkbox.Group);

export default CheckboxWithConfig;
