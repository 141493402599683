import { G, Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconCurbside = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <G fill="currentColor">
        <Path
          fillRule="evenodd"
          d="M.56 5.1h3.76c.31 0 .56.256.56.572v3.91c0 .315-.25.57-.56.57H3.2a.55.55 0 0 1 .002.053v9.35a.527.527 0 0 1-.52.533h-.483a.527.527 0 0 1-.522-.533v-9.35c0-.018.001-.035.003-.052H.56A.565.565 0 0 1 0 9.58V5.672c0-.316.25-.571.56-.571Z"
          clipRule="evenodd"
        />
        <Path d="M21.92 5.11c-.2-.59-.76-1.01-1.42-1.01h-11c-.66 0-1.21.42-1.42 1.01L6 11.1v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99ZM9.5 15.1c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5Zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5ZM8 10.1l1.5-4.5h11l1.5 4.5H8Z" />
      </G>
    </Svg>
  );
};
