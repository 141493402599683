import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../types';

export const IconRewardsAndOffers = (props: IconProps) => {
  const { size = 8, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? '#E31837'}
      {...otherProps}
    >
      <Path
        d="M5.40741 21.9267C4.82039 21.9765 4.28535 21.6047 4.15576 21.0568L3.53176 18.4179C3.46045 18.1162 3.26148 17.856 2.98197 17.699L0.578753 16.3483C0.0679215 16.0612 -0.138652 15.4565 0.0969138 14.9378L1.2194 12.4662C1.34777 12.1835 1.34842 11.863 1.22117 11.5798L0.103081 9.09195C-0.128863 8.57587 0.0757061 7.97586 0.581652 7.68824L3.0206 6.30189C3.29817 6.1441 3.49536 5.88438 3.56586 5.58381L4.1821 2.95541C4.31145 2.40382 4.85149 2.03034 5.44186 2.08423L8.21915 2.33774C8.54212 2.36723 8.86355 2.2682 9.10738 2.06409L11.2397 0.278758C11.6824 -0.0919197 12.3447 -0.0930403 12.7888 0.276136L14.9107 2.04002C15.1521 2.24072 15.469 2.33885 15.7881 2.31173L18.5926 2.07333C19.1796 2.02344 19.7146 2.39533 19.8442 2.94322L20.4682 5.58208C20.5396 5.88376 20.7385 6.14393 21.018 6.30105L23.4213 7.6517C23.9321 7.93876 24.1386 8.54349 23.9031 9.06214L22.7806 11.5338C22.6522 11.8164 22.6516 12.137 22.7789 12.4202L23.8969 14.908C24.1289 15.4241 23.9243 16.0241 23.4183 16.3117L20.9794 17.6981C20.7018 17.8559 20.5046 18.1156 20.4341 18.4162L19.8179 21.0446C19.6885 21.5962 19.1485 21.9696 18.5581 21.9158L15.7809 21.6622C15.4579 21.6328 15.1364 21.7318 14.8927 21.9359L12.7603 23.7212C12.3176 24.0919 11.6553 24.0931 11.2112 23.7238L9.08933 21.96C8.84792 21.7593 8.53101 21.6612 8.21192 21.6883L5.40741 21.9267Z"
        fill="currentColor"
      />
      <Path
        d="M15.5276 17.3793C16.1156 17.3793 16.6153 17.1832 17.0269 16.7909C17.4385 16.3987 17.6443 15.9224 17.6443 15.3621C17.6443 14.8017 17.4385 14.3254 17.0269 13.9332C16.6153 13.541 16.1156 13.3448 15.5276 13.3448C14.9397 13.3448 14.4399 13.541 14.0283 13.9332C13.6167 14.3254 13.411 14.8017 13.411 15.3621C13.411 15.9224 13.6167 16.3987 14.0283 16.7909C14.4399 17.1832 14.9397 17.3793 15.5276 17.3793Z"
        fill="#fff"
      />
      <Path
        d="M8.40154 17.3121L17.5737 8.57072L15.5982 6.68797L6.42599 15.4293L8.40154 17.3121Z"
        fill="#fff"
      />
      <Path
        d="M8.47209 10.6552C9.06005 10.6552 9.55982 10.4591 9.97139 10.0668C10.383 9.67459 10.5887 9.1983 10.5887 8.63796C10.5887 8.07762 10.383 7.60133 9.97139 7.20909C9.55982 6.81685 9.06005 6.62073 8.47209 6.62073C7.88413 6.62073 7.38437 6.81685 6.97279 7.20909C6.56122 7.60133 6.35544 8.07762 6.35544 8.63796C6.35544 9.1983 6.56122 9.67459 6.97279 10.0668C7.38437 10.4591 7.88413 10.6552 8.47209 10.6552Z"
        fill="#fff"
      />
    </Svg>
  );
};
