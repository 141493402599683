import React from 'react';

import type { TextProps } from '@fhs-legacy/universal-components';
import type { ComponentWithConfig } from '@fhs-legacy/universal-components/src/utils/with-config/with-config';
import type { ISanityBlockContent } from '@rbi-ctg/menu';

type ComponentOverrideProps = Record<string, unknown>;
export type ComponentOverride<Props extends ComponentOverrideProps = ComponentOverrideProps> =
  | React.ComponentClass<Props>
  | React.FC<React.PropsWithChildren<Props>>
  | ComponentWithConfig<React.ComponentType<React.PropsWithChildren<Props>>>;

export type SanityBlockContentLinkProps = Omit<
  ISanityBlockContent['children'][number],
  'text' | 'marks'
> & {
  children?: string[];
  mark?: { _key?: string; _type?: 'link'; href?: string };
  markKey?: string;
  linkProps?: TextProps;
};

export interface IBlockContentOverrides {
  h1?: ComponentOverride;
  h2?: ComponentOverride;
  h3?: ComponentOverride;
  h4?: ComponentOverride;
  h5?: ComponentOverride;
  h6?: ComponentOverride;
  p?: ComponentOverride;
  blockquote?: ComponentOverride;
  quote?: ComponentOverride;
  normal?: ComponentOverride;
}

export interface ITypographyBlockOverrides {
  headline1?: ComponentOverride;
  headline2?: ComponentOverride;
  headline3?: ComponentOverride;
  headline4?: ComponentOverride;
  headline5?: ComponentOverride;
  headline6?: ComponentOverride;
  subtitle1?: ComponentOverride;
  subtitle2?: ComponentOverride;
  body1?: ComponentOverride;
  body2?: ComponentOverride;
  caption?: ComponentOverride;
  normal?: ComponentOverride;
}

export interface ISerializers {
  types?: {
    block?: ComponentOverride;
  };
  marks?: {
    small?: ComponentOverride;
    em?: ComponentOverride;
    strong?: ComponentOverride;
    link?: ComponentOverride<SanityBlockContentLinkProps>;
    // It's possible to define custom marks in Sanity
    [key: string]: any;
  };
  list?: ComponentOverride;
  listItem?: ComponentOverride;
  hardBreak?: ComponentOverride;
  container?: ComponentOverride;
  imageOptions?: {
    w: string;
    h: string;
    fit: string;
  };
}
