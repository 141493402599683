import React from 'react';

import { Box } from '@fhs-legacy/universal-components';
import LoadingAnimation from 'components/loading-animation';

export default function AnimationLoader() {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" pt="50px">
      <LoadingAnimation />
    </Box>
  );
}
