import { NativeModules, Platform } from 'react-native';
/**
 * Reduces System text size multiplier by 1/2 of increase over 1.0.
 *
 * The text size multiplier was reported as being way too large for the app.
 */
const reduceTextMultiplier = (multiplier: number) =>
  parseFloat(((multiplier - 1) / 2 + 1).toFixed(3));

if (Platform.OS === 'ios') {
  NativeModules.AccessibilityManager.setAccessibilityContentSizeMultipliers({
    extraSmall: 0.823,
    small: 0.882,
    medium: 0.941,
    large: 1.0,
    extraLarge: reduceTextMultiplier(1.118),
    extraExtraLarge: reduceTextMultiplier(1.235),
    extraExtraExtraLarge: reduceTextMultiplier(1.353),
    accessibilityMedium: reduceTextMultiplier(1.786),
    accessibilityLarge: reduceTextMultiplier(2.143),
    accessibilityExtraLarge: reduceTextMultiplier(2.643),
    accessibilityExtraExtraLarge: reduceTextMultiplier(3.143),
    accessibilityExtraExtraExtraLarge: reduceTextMultiplier(3.571),
  });
}
