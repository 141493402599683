import React from 'react';
import { useIntl } from 'react-intl';

import { Badge, Box, HStack, InlineAlert, Text } from '@fhs-legacy/universal-components';
import CartItemButtom from 'components/cart-item/cart-item-button';
import BlockRenderer from 'components/sanity-block-renderer';

const ticketCircleDiameter = 15;

const TicketContainer = Box.withConfig({
  position: 'absolute',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
});

const TicketSquare = Box.withConfig(p => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  borderTopLeftRadius: Styles.borderRadius,
  borderTopRightRadius: Styles.borderRadius,
  borderBottomRightRadius: Styles.borderRadius,
  borderBottomLeftRadius: Styles.borderRadius,
  borderWidth: 1,
  borderColor: p.borderColor,
  borderStyle: 'solid',
  backgroundColor: p.fill,
}));

const TicketCircle = Box.withConfig(p => ({
  position: 'absolute',
  height: `${ticketCircleDiameter}px`,
  width: `${ticketCircleDiameter}px`,
  borderRadius: 'full',
  backgroundColor: p.bgColor,
  borderWidth: 1,
  borderColor: p.borderColor,
  borderStyle: 'solid',
  top: '50%',
  right: p.right ? 0 : 'auto',
  left: p.left ? 0 : 'auto',
  transform: [
    {
      translateX: (ticketCircleDiameter / 2) * (p.right ? 1 : -1),
    },
  ],
}));

const OfferContent = Box.withConfig({
  zIndex: Styles.zIndex.below,
  paddingX: `${ticketCircleDiameter / 2}px`,
  paddingY: 0,
});

const OfferContainer = Box.withConfig({
  minHeight: `${ticketCircleDiameter * 3}px`,
  marginBottom: '$4',
});

const OfferInner = Box.withConfig({
  paddingTop: '$3',
  paddingRight: '$2',
  paddingBottom: 0,
  paddingLeft: '$2',
});

const OfferApplied = Text.withConfig({
  fontSize: 'xs',
  textTransform: 'uppercase',
  letterSpacing: 'xl',
  marginTop: '$0.5',
});

const DiscountNotApplied = Text.withConfig({
  fontSize: 'xs',
  textTransform: 'uppercase',
  color: 'white',
  fontWeight: 'bold',
  letterSpacing: 'xl',
  marginTop: '$0.5',
});

const StyledBlockRenderer = BlockRenderer.withConfig({
  textProps: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: 'sm',
  },
});

const OfferDescriptionBlockRenderer = StyledBlockRenderer.withConfig({
  textProps: {
    fontWeight: 'light',
    fontSize: 15,
    lineHeight: 'sm',
    marginTop: '$1.5',
    // TODO: RN - migrate first letter uppercase.
    //   p::first-letter {
    //     text-transform: uppercase;
    //   }
  },
});

const ControlsContainer = Box.withConfig({
  padding: 0,
  paddingTop: '$2',
  paddingBottom: '$2',
  alignItems: 'flex-start',
  textAlign: 'center',
});

const Offer = ({
  bgColor,
  fill,
  borderColor,
  selectedOffer,
  isValid,
  onRemove,
  textRemove,
  textOfferRequirementsNotMet,
  hideTicketCircles,
}) => {
  const { formatMessage } = useIntl();
  return (
    <OfferContainer>
      <TicketContainer>
        <TicketSquare fill={fill} borderColor={borderColor} />
        {!hideTicketCircles && (
          <>
            <TicketCircle left bgColor={bgColor} borderColor={borderColor} />
            <TicketCircle right bgColor={bgColor} borderColor={borderColor} />
          </>
        )}
      </TicketContainer>

      <OfferContent>
        <OfferInner>
          <HStack space="$1" marginBottom="$2" marginTop="$0.5">
            <OfferApplied testID="cart-offer-applied">
              {formatMessage({ id: 'offerAdded' })}
            </OfferApplied>
            {!isValid && (
              <HStack space="$1">
                <Text fontSize="2xl">•</Text>
                <Badge variant="default-solid" bgColor="errorRed">
                  <DiscountNotApplied>
                    {formatMessage({ id: 'discountNotApplied' })}
                  </DiscountNotApplied>
                </Badge>
              </HStack>
            )}
          </HStack>
          <Box testID="cart-offer-title">
            <StyledBlockRenderer content={selectedOffer?.name?.localeRaw} />
            <OfferDescriptionBlockRenderer content={selectedOffer?.description?.localeRaw} />
          </Box>
          {!isValid && (
            <InlineAlert
              iconCentered
              status="error"
              borderRadius={8}
              marginTop="$3"
              message={textOfferRequirementsNotMet}
            />
          )}
        </OfferInner>
        <ControlsContainer>
          <CartItemButtom
            accessibilityLabel={`${textRemove} ${selectedOffer.name} `}
            onPress={onRemove}
          >
            {textRemove}
          </CartItemButtom>
        </ControlsContainer>
      </OfferContent>
    </OfferContainer>
  );
};

export default Offer;
