import { ApolloError } from '@apollo/client';

import { IServerOrder } from '@rbi-ctg/menu';
import { OrderStatus, ServiceMode } from 'state/order';

export interface ICartProps {
  permissionToUpdate?: boolean;
}

export enum LoyaltyBarcodePaymentType {
  TIM_CARD = 'TC',
  APPLE_PAY = 'AP',
  GOOGLE_PAY = 'GP',
  VISA = 'VISA',
  MASTERCARD = 'MC',
  AMEX = 'AMEX',
  DISCOVER = 'DISC',
}

export interface ITotalsProps {
  orderStatus: OrderStatus | null;
  serverOrder: IServerOrder | null;
  serviceMode: ServiceMode;
  rewardApplied: boolean;
}

// Cart Error List
interface IErrorsList {
  [key: string]: boolean | undefined | ApolloError;
}
export interface IErrorsListItems extends Array<IErrorsList> {}

export const getServiceModeHeaderText = (formatMessage: Function) => ({
  [ServiceMode.DELIVERY]: formatMessage({ id: 'deliveryOrder' }),
  [ServiceMode.EAT_IN]: formatMessage({ id: 'dineInOrder' }),
  [ServiceMode.DRIVE_THRU]: formatMessage({ id: 'driveThruOrder' }),
  [ServiceMode.TAKEOUT]: formatMessage({ id: 'pickUpOrder' }),
  [ServiceMode.CATERING_PICKUP]: formatMessage({ id: 'cateringOrder' }),
  [ServiceMode.CATERING_DELIVERY]: formatMessage({ id: 'cateringOrder' }),
  [ServiceMode.CURBSIDE]: formatMessage({ id: 'curbsideOrder' }),
});

// TODO: expo-router - confirm the service mode messages to be displayed
export const getServiceModeBottomCTAText = (formatMessage: Function) => ({
  [ServiceMode.DELIVERY]: formatMessage({ id: 'deliveryOrder' }),
  [ServiceMode.EAT_IN]: formatMessage({ id: 'dineInOrder' }),
  [ServiceMode.DRIVE_THRU]: formatMessage({ id: 'driveThruOrder' }),
  [ServiceMode.TAKEOUT]: 'Pick Up',
  [ServiceMode.CATERING_PICKUP]: formatMessage({ id: 'cateringOrder' }),
  [ServiceMode.CATERING_DELIVERY]: formatMessage({ id: 'cateringOrder' }),
  [ServiceMode.CURBSIDE]: formatMessage({ id: 'curbsideOrder' }),
});

export const serviceModeLoyaltyBarcodeIds = {
  [ServiceMode.DELIVERY]: 'WL',
  [ServiceMode.EAT_IN]: 'DI',
  [ServiceMode.DRIVE_THRU]: 'DT',
  [ServiceMode.TAKEOUT]: 'TO',
};

export enum MobileCartUpsellLocation {
  AboveYourCart = 'aboveYourCart',
  AbovePaymentDetails = 'abovePaymentDetails',
}

export interface DeliverDetailsErrors {
  phoneNumber?: string;
}
