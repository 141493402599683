import { Box, Pressable } from '@fhs-legacy/universal-components';

export const Container = Box.withConfig({
  backgroundColor: Styles.color.cardBackground,
  borderRadius: Styles.borderRadius,
  marginY: '$4',
  paddingX: { base: '$4', desktop: '$6' },
  paddingY: { base: '$4', desktop: '$6' },
});

export const DescriptionContainer = Pressable.withConfig({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
});
