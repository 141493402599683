import { Image, ImageProps } from 'expo-image';
import { Link } from 'expo-router';
import { Pressable, StyleSheet } from 'react-native';

export type CateringCardProps = Omit<ImageProps, 'children' | 'source'> & {
  cateringHref: string;
};

export function CateringCard({ cateringHref, ...imageProps }: CateringCardProps) {
  return (
    <Link href={cateringHref} target="_blank" asChild>
      <Pressable>
        <Image
          source={require('./catering-banner.webp')}
          placeholder={{ thumbhash: 'JHkOFgR4d/d3eIdoh4SHdXlniHCGHzc' }}
          {...imageProps}
          style={[styles.cateringCard, imageProps.style]}
        />
      </Pressable>
    </Link>
  );
}

const styles = StyleSheet.create({
  cateringCard: {
    aspectRatio: 1136 / 1270,
  },
});
