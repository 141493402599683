import delve from 'dlv';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { IServerOrder } from '@rbi-ctg/menu';
import { useAuthContext } from 'state/auth';
import { IOrder } from 'state/order/types';
import { ISOs, getCountryAndCurrencyCodes } from 'utils/form/constants';
import logger from 'utils/logger';

const useFormatApplePayCartPayload = ({
  serverOrder,
  order,
}: {
  serverOrder?: IServerOrder | null;
  order?: IOrder;
}) => {
  const auth = useAuthContext();
  const { formatMessage } = useIntl();
  const formatApplePayCartPayload = useCallback(() => {
    if (!(order && order.cartEntries && serverOrder && serverOrder.cart)) {
      logger.error('Missing values to format apple pay cart payload.');
      return null;
    }

    const billingCountry = delve(auth, 'user.details.isoCountryCode') || ISOs.USA;
    const { countryCode, currencyCode } = getCountryAndCurrencyCodes(billingCountry);

    // send the cart items info to applePay, subtotal and tax to applePay summary
    const cartLabels: string[] = [];
    const cartPrices: number[] = [];
    order.cartEntries.forEach((item: any) => {
      cartLabels.push(`${item.quantity} x ${item.name}`);
      cartPrices.push((item.price ?? 0) / 100);
    });
    const { subTotalCents, taxCents, totalCents } = serverOrder.cart;
    // Capitalize the taxes text
    const taxesText = formatMessage({ id: 'taxes' });
    const capitalizeTaxesText = taxesText[0].toUpperCase() + taxesText.slice(1);
    cartLabels.push(formatMessage({ id: 'subtotal' }), capitalizeTaxesText);
    cartPrices.push(subTotalCents / 100, taxCents / 100);

    // Show the delivery fee if applicable
    const deliveryFeeCents = serverOrder?.delivery?.feeCents ?? 0;
    const deliveryFeeDiscountCents = serverOrder?.delivery?.feeDiscountCents ?? 0;
    if (deliveryFeeCents - deliveryFeeDiscountCents) {
      cartLabels.push(formatMessage({ id: 'deliveryFee' }));
      cartPrices.push((deliveryFeeCents ?? 0) / 100);
    }
    // Add tip if applicable
    if (order?.tipAmount) {
      cartLabels.push(formatMessage({ id: 'tip' }));
      cartPrices.push((order?.tipAmount ?? 0) / 100);
    }
    return {
      countryCode,
      currencyCode,
      total: totalCents + (order?.tipAmount ?? 0),
      cartLabels,
      cartPrices,
    };
  }, [order, serverOrder, auth, formatMessage]);
  return { formatApplePayCartPayload };
};

export default useFormatApplePayCartPayload;
