import { Pressable, Text } from '@fhs-legacy/universal-components';
import { IPickerAspectUiPattern } from '@rbi-ctg/menu';

export const PickerAspectOptionContainer = Pressable.withConfig(
  ({ disabled }: { disabled: boolean }) => ({
    width: 'full',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: Styles.color.white,
    alignItems: 'center',
    borderWidth: 0,
    borderColor: 'black',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: Styles.color.grey.four,
    paddingY: '$2',
    paddingX: '$2',
    color: disabled ? Styles.color.grey.three : Styles.color.black,
    _pressed: {
      backgroundColor: Styles.color.grey.four,
    },
  })
);

export const Name = Text.withConfig({
  lineHeight: '2xs',
});

export const CaloriesContent = Text.withConfig<IPickerAspectUiPattern>({
  lineHeight: '2xs',
});
