import type { IPrices, ISanityVendorConfigs } from '@rbi-ctg/menu';
import { isDiscountOffer } from 'state/loyalty/utils';
import { itemIsAvailable } from 'utils/availability';
import { PosVendors } from 'utils/vendor-config';

import type { ICmsOffer } from '../types';

// for these vendors the discount offer plu different is not in the PLU map
// they handle the plu internally
const VENDORS_WITHOUT_PLU_DISCOUNTS_IN_MAP = [
  PosVendors.NCR,
  PosVendors.NCR_DELIVERY,
  PosVendors.QDI,
  PosVendors.QDI_DELIVERY,
];

/**
 * For the the configured vendors the discount offer plu check is skipped.
 */
export const isDiscountOfferPluAlwaysAvailable = ({
  cmsOffer,
  vendor,
}: {
  cmsOffer: ICmsOffer;
  vendor: PosVendors | null;
}) =>
  !!vendor &&
  Boolean(VENDORS_WITHOUT_PLU_DISCOUNTS_IN_MAP.includes(vendor) && isDiscountOffer(cmsOffer));

export const isOfferPluAvailable = ({
  cmsOffer,
  vendor,
  prices,
}: {
  cmsOffer: ICmsOffer;
  vendor: PosVendors | null;
  prices: IPrices;
}) => {
  let checkResult = true;

  // if no vendor or no prices the offer is consider available
  if (!!cmsOffer?.vendorConfigs && !!vendor && !!prices) {
    checkResult = itemIsAvailable(
      {
        ...cmsOffer,
        // passing undefined because the offer name is a BlockContent type
        name: undefined,
        vendorConfigs: cmsOffer.vendorConfigs as ISanityVendorConfigs,
      },
      vendor,
      prices
    );
  }

  return checkResult;
};
