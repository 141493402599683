import { router } from 'expo-router';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import ConfirmDialog from 'components/confirm-dialog';
import { ServiceMode } from 'generated/graphql-gateway';
import { usePathname } from 'hooks/use-pathname';
import {
  IIncentiveEvaluationResult,
  IncentiveEvaluationErrorCodes,
} from 'state/loyalty/hooks/types';

const isInvalidServiceModePickup = (invalidServiceMode: IIncentiveEvaluationResult) => {
  return invalidServiceMode.targetValue.some(
    (target: string) =>
      target === ServiceMode.CURBSIDE ||
      target === ServiceMode.EAT_IN ||
      target === ServiceMode.TAKEOUT ||
      target === ServiceMode.DRIVE_THRU
  );
};

const isInvalidServiceModeDelivery = (invalidServiceMode: IIncentiveEvaluationResult) => {
  return invalidServiceMode.targetValue.some((target: string) => target === ServiceMode.DELIVERY);
};

interface InvalidServiceModeModalProps {
  incentiveEvaluationResults: IIncentiveEvaluationResult[];
}

const InvalidServiceModeModal = ({ incentiveEvaluationResults }: InvalidServiceModeModalProps) => {
  const { formatMessage } = useIntl();
  // TODO: expo-router do we need this?
  const setStoreLocatorCallbackUrl = useCallback((_: any) => {
    __DEV__ && alert('needs implementation InvalidServiceModeModal');
  }, []);
  const pathname = usePathname();
  // const { setStoreLocatorCallbackUrl } = useLocationContext();
  const [invalidServiceMode, setInvalidServiceMode] = useState<IIncentiveEvaluationResult>();

  useEffect(() => {
    const serviceModeError = incentiveEvaluationResults?.find(
      ({ code }) => code === IncentiveEvaluationErrorCodes.INVALID_SERVICE_MODE
    );

    setInvalidServiceMode(serviceModeError);
  }, [incentiveEvaluationResults]);

  if (!invalidServiceMode) {
    return null;
  }

  const invalidPickup = isInvalidServiceModePickup(invalidServiceMode);
  const invalidDelivery = isInvalidServiceModeDelivery(invalidServiceMode);

  // TODO: If not pick up or delivery means it is CATERING which is going to be handle in a separate Ticket
  if (!invalidPickup && !invalidDelivery) {
    return null;
  }

  const handleConfirmSwitchServiceMode = () => {
    setStoreLocatorCallbackUrl(pathname);
    if (invalidPickup) {
      router.navigate(formatMessage({ id: 'routes.storeLocator' }));
    } else {
      router.navigate(formatMessage({ id: 'routes.storeLocator.address' }));
    }
  };

  const handleCancelSwitchServiceMode = () => {
    setInvalidServiceMode(undefined);
  };

  return (
    <ConfirmDialog
      heading={formatMessage({
        id: invalidPickup ? 'switchServiceModeToPickupHeader' : 'switchServiceModeToDeliveryHeader',
      })}
      body={formatMessage({
        id: invalidPickup ? 'switchServiceModeToPickupBody' : 'switchServiceModeToDeliveryBody',
      })}
      confirmLabel={formatMessage({
        id: invalidPickup ? 'switchServiceModeToPickupButton' : 'switchServiceModeToDeliveryButton',
      })}
      cancelLabel={formatMessage({ id: 'cancel' })}
      onConfirm={handleConfirmSwitchServiceMode}
      onCancel={handleCancelSwitchServiceMode}
      onDismiss={handleCancelSwitchServiceMode}
      modalAppearanceEventMessage="Confirm Change Service Mode"
      showDialog={!!invalidServiceMode}
    />
  );
};

export default InvalidServiceModeModal;
