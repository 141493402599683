import { Box, Header, Text, addWithConfig } from '@fhs-legacy/universal-components';
import Dialog from 'components/dialog';
import Picture from 'components/picture';

export const StyledTitle = Header.withConfig({
  variant: 'headerThree',
  marginY: '$0',
  fontFamily: 'alternative',
  fontSize: '3xl',
  lineHeight: 'xl',
});

export const StyledDescription = Text.withConfig({
  marginTop: '$2',
  marginBottom: '$0',
  fontFamily: 'body',
  fontSize: 'md',
});

export const StyledDialog = addWithConfig(Dialog).withConfig({
  paddingTop: '$6',
  textAlign: 'center',
  maxWidth: '384px',
});

export const StyledPicture = Picture.withConfig({
  height: '192px',
  width: '176px',
});

export const StyledButtonGroup = Box.withConfig({
  width: 'full',
  marginTop: '$5',
  textAlign: 'center',
});
