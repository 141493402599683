import { Maybe } from 'graphql/jsutils/Maybe';

import { IncentiveOfferHead } from './offer-head';
import { IncentiveRewardHead } from './reward-head';

interface IncentiveHeadProps {
  loyaltyOfferId?: Maybe<string>;
  loyaltyOfferCmsId?: Maybe<string>;
  rewardApplied: boolean;
  cartId: string;
}
export function IncentiveHead({
  loyaltyOfferId,
  loyaltyOfferCmsId,
  rewardApplied,
  cartId,
}: IncentiveHeadProps) {
  if (loyaltyOfferId) {
    return <IncentiveOfferHead loyaltyOfferId={loyaltyOfferId} cmsOfferId={loyaltyOfferCmsId} />;
  }

  if (rewardApplied) {
    return <IncentiveRewardHead cartId={cartId} />;
  }

  return null;
}
