import { Redirect } from 'expo-router';

import { useAuthContext } from 'state/auth';

import { AccountOrders } from './account-orders';

const AccountOrdersPage = () => {
  const { isAuthenticated } = useAuthContext();

  if (!isAuthenticated) {
    return <Redirect href="/" />;
  }

  return <AccountOrders />;
};

export default AccountOrdersPage;
