import { isEmpty } from 'lodash';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { Box, InlineAlert } from '@fhs-legacy/universal-components';
import { selectors, useAppSelector } from 'state/global-state';

import { StyledIncentiveEvaluationMessages } from '../styled';

interface ICartErrorMessagesProps {
  isOfferNotAvailable: boolean;
  isCartTotalNegative: boolean;
}

export const CartErrorMessages: FC<React.PropsWithChildren<ICartErrorMessagesProps>> = ({
  isOfferNotAvailable,
  isCartTotalNegative,
}) => {
  const { formatMessage } = useIntl();
  const offersFeedbackMap = useAppSelector(selectors.loyalty.selectOfferFeedbackMap);

  const showOffersFeedback = !isCartTotalNegative && !isEmpty(offersFeedbackMap);

  if (!isOfferNotAvailable || !showOffersFeedback || !isCartTotalNegative) {
    return null;
  }
  return (
    <Box marginTop="$4">
      {isOfferNotAvailable && (
        <InlineAlert
          status="error"
          message={formatMessage({ id: 'offerNotAvailableInRestaurantPos' })}
          testID="offerNotAvailableInRestaurantPosErrorMsg"
        />
      )}
      {showOffersFeedback &&
        Object.keys(offersFeedbackMap).map(key => (
          <StyledIncentiveEvaluationMessages
            key={key}
            incentiveEvaluationResults={offersFeedbackMap[key]}
          />
        ))}
      {isCartTotalNegative && (
        <InlineAlert status="error" message={formatMessage({ id: 'negativeCartError' })} />
      )}
    </Box>
  );
};
