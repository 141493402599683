import { router } from 'expo-router';
import * as React from 'react';
import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Table from 'components/table';
import {
  groupAndSortTransactionsByMonth,
  useTransactionDateFormatters,
} from 'pages/loyalty/transactions/utils';

import { getTransactionTableHistoryStructure } from './transaction-table-structure';
import { ILoyaltyHistoryViewProps, TransactionTable, TransactionTableRow } from './types';

export const LoyaltyHistoryView: FC<React.PropsWithChildren<ILoyaltyHistoryViewProps>> = ({
  tableData,
}) => {
  const { formatMessage } = useIntl();
  const { getMonth } = useTransactionDateFormatters();

  const tables: TransactionTable[] = useMemo(() => {
    const sortedTransactions = groupAndSortTransactionsByMonth(tableData);
    return sortedTransactions.map(([, data]) =>
      getTransactionTableHistoryStructure(formatMessage, data, getMonth(data[0]) as string)
    );
  }, [formatMessage, getMonth, tableData]);

  const handleOnPressRow = useCallback(
    ({ rbiOrderId }: TransactionTableRow | Partial<TransactionTableRow>) => {
      if (rbiOrderId) {
        router.navigate(`/receipt/${rbiOrderId}`);
      }
    },
    []
  );

  return tables.map((table, idx) => (
    <Table<TransactionTableRow>
      key={`historyTable.${idx}`}
      table={table}
      onPressRow={handleOnPressRow}
      marginTop="$4"
    />
  ));
};
