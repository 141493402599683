import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { PullToReload } from 'components/pull-to-reload';
import { useLoyaltyUserTransactionsQuery } from 'generated/graphql-gateway';
import { LoyaltyLoader } from 'pages/loyalty/loyalty-loader';
import { useLocalizedTransactionTransformer } from 'pages/loyalty/transactions/use-localized-transaction-transformer';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

import { LoyaltyBackground } from '../loyalty-background';

import {
  HistoryContainer,
  LoyaltyContainer,
  RefreshContainer,
  StyledLoyaltyNoRewardPoints,
  SubText,
} from './loyalty-history.styled';
import { LoyaltyHistoryView } from './loyalty-history.view';
import { PointsExpirationCard } from './points-expiration';

/**
 *
 * Loyalty History view container for RBI Loyalty Program
 * Displays tables with loyalty transaction history
 *
 */
const LoyaltyHistory = () => {
  const transactionTransformer = useLocalizedTransactionTransformer();
  const { loyaltyUserId, loading: loyaltyLoading } = useLoyaltyUser();
  const { formatMessage } = useIntl();

  const {
    data,
    loading: transactionsLoading,
    refetch: refetchTransactionHistory,
  } = useLoyaltyUserTransactionsQuery({
    skip: !loyaltyUserId,
    variables: { loyaltyId: loyaltyUserId || '' },
    fetchPolicy: 'network-only',
  });

  const transactions = data?.loyaltyUserV2?.transactions;

  const historyLoading = loyaltyLoading || transactionsLoading;

  const tableData = useMemo(
    () => transactions?.map(transactionTransformer) ?? [],
    [transactions, transactionTransformer]
  );

  if (!data?.loyaltyUserV2 && historyLoading) {
    return <LoyaltyLoader />;
  }

  return (
    <LoyaltyContainer>
      <PullToReload onRefresh={refetchTransactionHistory} isRefreshing={transactionsLoading}>
        <LoyaltyBackground fullHeight backgroundColor={Styles.color.background}>
          <RefreshContainer>
            <HistoryContainer>
              <SubText>{formatMessage({ id: 'viewPurchasesAndOffers' })}</SubText>
              <PointsExpirationCard />
              {tableData.length ? (
                <LoyaltyHistoryView tableData={tableData} />
              ) : (
                <StyledLoyaltyNoRewardPoints />
              )}
            </HistoryContainer>
          </RefreshContainer>
        </LoyaltyBackground>
      </PullToReload>
    </LoyaltyContainer>
  );
};

export default LoyaltyHistory;
