import { Box, Icon } from '@fhs-legacy/universal-components';

export const PaymentMethodSelect = Box.withConfig({
  backgroundColor: Styles.color.cardBackground,
  borderWidth: 1,
  borderColor: Styles.color.grey.four,
  borderRadius: Styles.borderRadius,
});

export const StyledAddNewCreditCardIcon = Icon.withConfig({
  variant: 'defaultCard',
});

export const StyledAddPaymentMethodIcon = Icon.withConfig({ variant: 'addCard' });

export const ToggleWrapper = Box.withConfig({
  position: 'absolute',
  top: '$4',
  right: '$2',
});
