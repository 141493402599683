import { useMemo } from 'react';
import { View, type ViewProps } from 'react-native';

import { radioGroupContext } from './context';

export type RadioGroupProps = ViewProps & {
  value: string;
  onChange: (selectedValue: string) => void;
};

export function RadioGroup({ value, onChange, ...viewProps }: RadioGroupProps) {
  const context = useMemo(() => ({ value: value, onChange: onChange }), [value, onChange]);

  return (
    <radioGroupContext.Provider value={context}>
      <View role="radiogroup" {...viewProps} />
    </radioGroupContext.Provider>
  );
}
