import { Path, Rect, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconPaymentTypeVisa = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Rect width={24} height={16} y={4.101} fill="#fff" rx={2} />
      <Rect width={23} height={15} x={0.5} y={4.601} stroke="#000" strokeOpacity={0.1} rx={1.5} />
      <Path
        fill="#172B85"
        fillRule="evenodd"
        d="M8.5 14.566H7.288l-.908-3.491c-.043-.161-.135-.303-.27-.37a3.923 3.923 0 0 0-1.11-.37v-.134h1.952a.51.51 0 0 1 .505.437l.471 2.518L9.14 10.2h1.178L8.5 14.565Zm2.49 0H9.846l.942-4.365h1.145l-.943 4.364Zm2.422-3.156c.034-.235.236-.37.472-.37a2.12 2.12 0 0 1 1.11.202l.202-.94a2.878 2.878 0 0 0-1.043-.201c-1.11 0-1.918.604-1.918 1.443 0 .638.572.973.976 1.175.437.202.605.336.572.537 0 .302-.337.437-.673.437-.404 0-.808-.1-1.178-.269l-.202.94a3.28 3.28 0 0 0 1.245.235c1.245.033 2.02-.57 2.02-1.477 0-1.142-1.583-1.208-1.583-1.712ZM19 14.565l-.909-4.364h-.975a.506.506 0 0 0-.472.336l-1.682 4.028h1.178l.235-.637h1.447l.135.637H19Zm-1.717-3.189.336 1.645h-.942l.606-1.645Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
