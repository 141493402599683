import { type Href, Link } from 'expo-router';
import { StyleSheet, View, type ViewProps } from 'react-native';

import { Button, ButtonProps, useMqSelect } from '@fhs/ui';

// Matches any string that begins with `//` or `http` or other native links like`maps:`, `mailto:`, `sms:` or `tel:`
const externalLinkRegex = /^(\/{2}|http|[a-z]+:)/;
const isExternalHref = (href: Href) => {
  let uri = '';

  if (typeof href === 'string') {
    uri = href;
  } else {
    uri = href.pathname;
  }

  return externalLinkRegex.test(uri);
};

export type CtaButtonProps = Omit<ViewProps, 'children'> & {
  type?: 'solid' | 'solid-inverted';
  href: Href;
  text?: string;
  onPress?: ButtonProps['onPress'];
};
export function CtaButton({
  type = 'solid',
  href,
  text = 'Order Now',
  onPress,
  ...viewProps
}: CtaButtonProps) {
  return (
    <View {...viewProps} style={[styles.wrapper, viewProps.style]}>
      <Link href={href} asChild target={isExternalHref(href) ? '_blank' : undefined}>
        <Button
          type={type}
          size={useMqSelect({ $gteDesktopLg: 'xl' }, 'lg')}
          onPress={onPress}
          style={styles.button}
        >
          <Button.Text>{text}</Button.Text>
        </Button>
      </Link>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flexGrow: 0,
    flexShrink: 0,
  },
  button: {
    paddingHorizontal: 16,
  },
});
