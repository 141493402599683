import React from 'react';

import { useAuthContext } from 'state/auth';

import EndOfOffersLoggedIn from './loyalty-end-of-offers-logged-in';
import EndOfOffersLoggedOut from './loyalty-end-of-offers-logged-out';

const LoyaltyEndOfOffers = () => {
  const { isAuthenticated } = useAuthContext();

  return isAuthenticated ? <EndOfOffersLoggedIn /> : <EndOfOffersLoggedOut />;
};

export default LoyaltyEndOfOffers;
