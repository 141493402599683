import { IFeatureDonationQuery, useFeatureDonationQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

interface IUseFeatureDonationValue {
  featureDonation: IFeatureDonationQuery['FeatureDonation'];
  featureDonationLoading: boolean;
}

export const useFeatureDonation = (): IUseFeatureDonationValue => {
  const { featureDonationId = '', featureIdsLoading } = useFeaturesContext();
  const enableFeatureDonation = useFlag(LaunchDarklyFlag.ENABLE_FEATURE_DONATION);

  const { data, loading } = useFeatureDonationQuery({
    variables: { featureDonationId },
    skip: !featureDonationId || !enableFeatureDonation,
  });

  const featureDonation = data?.FeatureDonation ?? null;
  const featureDonationLoading = featureIdsLoading || loading;

  return { featureDonationLoading, featureDonation };
};
