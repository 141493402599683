import { router } from 'expo-router';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { IOffer } from '@rbi-ctg/menu';
import { usePromotionValidatePromoCodeMutation } from 'generated/graphql-gateway';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOffersContext } from 'state/offers';
import { UIPattern } from 'state/offers/types';
import logger from 'utils/logger';
import { getFirstStringInLocaleBlockContent } from 'utils/sanity';

import { CartPromoCodesView } from './cart-promo-codes.view';
import { PromoCodeErrorMessageIds } from './types';
import { useCartPromoCodeOfferRedemption } from './use-cart-promo-code-offer-redemption';

/**
 *
 * CartPromoCodes purpose
 *  Logic for promo codes at checkout (if enabled via LD)
 *
 */
const CartPromoCodes: FC<React.PropsWithChildren<unknown>> = () => {
  const { user } = useAuthContext();
  const promoCodeAtCheckoutEnabled = useFlag(LaunchDarklyFlag.ENABLE_PROMO_CODE_AT_CHECKOUT);
  const [promotionValidatePromoCodeMutation] = usePromotionValidatePromoCodeMutation();
  const {
    cartPromoCodeOffers,
    selectedOffer,
    selectOfferById,
    clearSelectedOffer,
    onSelectMobileRedemption,
    offerValidationErrors,
  } = useOffersContext();
  const {
    attemptOfferRedemption,
    onConfirmSwitchOffers,
    showConfirmSwitchOffersDialog,
    onCancelSwitchOffers,
  } = useCartPromoCodeOfferRedemption({
    selectedOffer,
    selectOfferById,
    clearSelectedOffer,
    onSelectMobileRedemption,
  });

  // isFirstRun is helping prevent
  // mParticle event from firing on first run
  const isFirstRun = useRef(true);

  const selectedOfferIsCartPromo = useMemo(
    () => selectedOffer?.uiPattern === UIPattern.CART_PROMO,
    [selectedOffer]
  );

  const [showSavedPromotionsModal, setShowSavedPromotionsModal] = useState(false);

  const [isExpanded, setIsExpanded] = useState(selectedOfferIsCartPromo);

  const [promoCodeValidationLoading, setPromoCodeValidationLoading] = useState(false);
  const [promoCodeErrorMessageId, setPromoCodeErrorMessageId] =
    useState<PromoCodeErrorMessageIds | null>(null);

  const [promoCodeInput, setPromoCodeInput] = useState<string>('');

  const appliedPromoDescription = useMemo(
    () =>
      selectedOfferIsCartPromo
        ? getFirstStringInLocaleBlockContent(selectedOffer?.description)
        : '',
    [selectedOffer, selectedOfferIsCartPromo]
  );

  const appliedPromoName = useMemo(
    () => (selectedOfferIsCartPromo ? getFirstStringInLocaleBlockContent(selectedOffer?.name) : ''),
    [selectedOffer, selectedOfferIsCartPromo]
  );

  const cartPromoCodeOfferIds: string[] = useMemo(
    () => cartPromoCodeOffers.map((o: IOffer) => o._id),
    [cartPromoCodeOffers]
  );

  const appliedPromoError = useMemo(() => {
    if (selectedOfferIsCartPromo && offerValidationErrors.length) {
      const failingErrors = offerValidationErrors.filter(err => !err.condition);
      return !!failingErrors.length;
    }
    return false;
  }, [offerValidationErrors, selectedOfferIsCartPromo]);

  const onChangePromoCodeInput = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCodeInput(value);
  };

  const onSubmitPromoCode = useCallback(async () => {
    if (!promoCodeInput) {
      return setPromoCodeErrorMessageId(PromoCodeErrorMessageIds.required);
    }
    try {
      setPromoCodeValidationLoading(true);
      const { data } = await promotionValidatePromoCodeMutation({
        variables: {
          code: promoCodeInput,
          cognitoId: user?.cognitoId || '',
          // in this case, shouldRedeem is set to true
          // to trigger immediate redemtion in vendor
          // and enable regular offer redemption flow
          shouldRedeem: true,
          // pass in all possible cart promo code offer
          // ids to ensure promo code is valid for this
          // specific type of promotion
          offers: cartPromoCodeOfferIds,
        },
      });
      if (!data?.validatePromoCode?.isValid) {
        throw data;
      }
      const offerId = data?.validatePromoCode?.offerId || '';
      const offerToRedeem = cartPromoCodeOffers.find(o => o._id === offerId);
      if (!offerId || !offerToRedeem) {
        let offerErr = new Error('Missing or invalid offer returned from promotion service');
        offerErr = { ...data, ...offerErr };
        throw offerErr;
      }
      attemptOfferRedemption({ offer: offerToRedeem });
      // clear promo code input & error message
      setPromoCodeErrorMessageId(null);
      setPromoCodeInput('');
    } catch (error) {
      setPromoCodeErrorMessageId(
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        PromoCodeErrorMessageIds[error?.validatePromoCode?.reason] ||
          PromoCodeErrorMessageIds.default
      );
      logger.error(`Error validating promo code ${error}`);
    } finally {
      setPromoCodeValidationLoading(false);
    }
  }, [
    attemptOfferRedemption,
    cartPromoCodeOfferIds,
    cartPromoCodeOffers,
    promoCodeInput,
    promotionValidatePromoCodeMutation,
    user,
  ]);

  const updateSavedPromotionsModal = useCallback(
    (show: boolean, shouldNavigate: boolean = false) => {
      if (shouldNavigate) {
        router.navigate('./');
      }
      setShowSavedPromotionsModal(show);
    },
    []
  );

  const onSelectedSavedPromotion = useCallback(
    (savedOffer: IOffer) => {
      updateSavedPromotionsModal(false);
      attemptOfferRedemption({ offer: savedOffer });
    },
    [attemptOfferRedemption, updateSavedPromotionsModal]
  );

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
  }, []);

  // if feature not enabled via LD,
  // or if there are no available promotions
  // render nothing
  if (!promoCodeAtCheckoutEnabled || !cartPromoCodeOfferIds.length) {
    return null;
  }

  // otherwise, render CartPromoCodesView
  return (
    <CartPromoCodesView
      onSubmit={onSubmitPromoCode}
      onChangeInput={onChangePromoCodeInput}
      isLoading={promoCodeValidationLoading}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      codeInput={promoCodeInput}
      errorMessageId={promoCodeErrorMessageId}
      appliedOfferName={appliedPromoName}
      appliedOfferError={appliedPromoError}
      appliedOfferDescription={appliedPromoDescription}
      cartPromoCodeOffers={cartPromoCodeOffers}
      removeAppliedOffer={clearSelectedOffer}
      showSavedPromotionsModal={showSavedPromotionsModal}
      onSelectedSavedPromotion={onSelectedSavedPromotion}
      updateSavedPromotionsModal={updateSavedPromotionsModal}
      showConfirmSwitchOffersDialog={showConfirmSwitchOffersDialog}
      onConfirmSwitchOffers={onConfirmSwitchOffers}
      onCancelSwitchOffers={onCancelSwitchOffers}
    />
  );
};

export default CartPromoCodes;
