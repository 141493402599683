import { ImageBackground, ImageBackgroundProps } from 'expo-image';
import { StyleSheet } from 'react-native';

import { Text, YStack, tokens } from '@fhs/ui';
import { useClickLogs } from '@fhs-legacy/frontend/src/hooks/use-click-logs';

import { CtaButton } from '../shared/cta-button';

export type RapidRescueCardProps = Omit<ImageBackgroundProps, 'children' | 'source'> & {
  pickUpHref: string;
};
export function RapidRescueCard({ pickUpHref, ...props }: RapidRescueCardProps) {
  const { logOrderNowClickEvent } = useClickLogs();

  return (
    <ImageBackground
      source={require('./rapid-rescue-card-background.webp')}
      placeholder={{ thumbhash: 'MTkOK4Zvh4lnech2gIgDmSg' }}
      {...props}
      style={[styles.rapidRescueBanner, props.style]}
    >
      <YStack style={styles.contentStack}>
        <Text weight="bold" style={styles.bannerText}>
          Avoid the Wait. Order Online.
        </Text>
        <CtaButton href={pickUpHref} onPress={logOrderNowClickEvent} style={styles.ctaButton} />
      </YStack>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  rapidRescueBanner: {
    aspectRatio: 1920 / 757,
  },
  contentStack: {
    position: 'absolute',
    alignItems: 'center',
    paddingHorizontal: 16,
    left: 0,
    right: '57.25%',
    top: '55%',
    gap: 8,
  },
  button: {
    paddingHorizontal: 24,
    marginTop: 16,
  },
  bannerText: {
    color: tokens.colors.$houseDark,
    fontSize: 25,
    lineHeight: 32,
    textAlign: 'center',
    justifyContent: 'center',
  },
  ctaButton: {
    marginTop: 12,
  },
});
