import { EventName, addOnceEventListener } from 'utils/event-hub';

import { initializeHomepagePerformanceMonitors } from './homepage-performance-monitors';
import { initializeMenuPerformanceMonitors } from './menu-performance-monitors';
import { initializeNativePerformanceMonitors } from './native-performance-monitors';
import { startTrace, stopTrace } from './trace';
import { TraceName } from './types';

export const initializePerformanceMonitors = () => {
  initializeNativePerformanceMonitors();
  initializeAppFlowPerformanceMonitors();
  initializeHomepagePerformanceMonitors();
  initializeMenuPerformanceMonitors();
};

// Measure from the start of appflow download to when it is done applying
const initializeAppFlowPerformanceMonitors = () => {
  addOnceEventListener(EventName.APP_FLOW_DOWNLOAD_START, () => {
    startTrace(TraceName.APP_FLOW_DOWNLOAD_START_TO_APP_FLOW_DOWNLOAD_END);
  });

  addOnceEventListener(EventName.APP_FLOW_DOWNLOAD_END, () => {
    stopTrace(TraceName.APP_FLOW_DOWNLOAD_START_TO_APP_FLOW_DOWNLOAD_END);
  });
};
