import { Box, Header, SvgUri, Text, makeUclComponent } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import CloseButtonComponent from 'components/close-button';
import BlockRenderer from 'components/sanity-block-renderer';

export const StyledCloseButton = CloseButtonComponent.withConfig({
  backgroundColor: Styles.color.white,
});

export const Container = Box.withConfig({
  height: 'full',
  width: 'full',
  paddingX: '$8',
  justifyContent: 'space-around',
});

export const SurpriseInfo = Box;

export const TitleContainer = Box.withConfig({
  marginTop: {
    base: '$12',
    desktop: 0,
  },
  marginBottom: '$4',
  marginX: '-4',
  height: '$16',
  alignItems: 'center',
  justifyContent: 'center',
});

export const TitleHeader = Header.withConfig({
  margin: 0,
  color: Styles.color.white,
  accessibilityLabel: '2',
});

export const TitleBackground = makeUclComponent(SvgUri).withConfig({
  size: 'full',
  position: 'absolute',
});

export const Description = Text.withConfig({
  color: Styles.color.black,
  textAlign: 'center',
  fontSize: {
    base: 'lg',
    desktop: 'md',
  },
  lineHeight: {
    base: 'xs',
    desktop: '2xs',
  },
  marginTop: {
    desktop: '$4',
  },
  marginBottom: {
    desktop: 0,
  },
  marginX: {
    desktop: 'auto',
  },
  maxWidth: {
    desktop: '$80',
  },
});

export const SurpriseOptions = Box.withConfig({
  overflow: 'scroll',
  marginX: '-8',
  justifyContent: 'flex-start',
  paddingTop: '$12',
});

export const Prompt = Text.withConfig({
  color: Styles.color.white,
  fontSize: 'lg',
  textAlign: 'center',
});

export const SurpriseButtons = Box.withConfig({
  marginTop: '$12',
  minHeight: '$40',
  alignItems: 'center',
});

export const StyledButton = makeUclComponent(ActionButton).withConfig({
  minWidth: '$48',
  paddingY: '$2.5',
  backgroundColor: Styles.color.white,
  _text: {
    fontWeight: 'bold',
    fontSize: {
      base: 'md',
      desktop: 'lg',
    },
    color: Styles.color.loyaltyHighlight,
  },
});

export const RejectSurprise = Text.withConfig({
  color: Styles.color.white,
  marginTop: '$6',
  fontWeight: 'bold',
  textTransform: 'capitalize',
});

export const Terms = BlockRenderer.withConfig({
  textProps: {
    color: Styles.color.black,
    marginTop: '$8',
  },
});
