import React, { FC } from 'react';
import { IntlFormatters, useIntl } from 'react-intl';

import { Checkbox } from '@fhs-legacy/universal-components';
import { CountrySelect } from 'components/country-select';
import { TextInput } from 'components/ucl/text-input';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import useCreditCardFormProps from 'hooks/form/use-credit-card-form-props';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import { CardType } from 'state/payment/types';
import { IPaymentErrors, IPaymentState, excludeNumeric } from 'utils/payment';
import { CREDIT_CARD_FORM_NAME_INPUT } from 'utils/test-ids';

import { SAVE_CARD_DISCLAIMER } from './constants';
import FormTitle from './credit-card-form-title';
import FirstDataCreditCardInputs from './first-data-credit-card-form-inputs/first-data-credit-card-inputs';
import {
  FormContainer,
  StyledBillingAptWrapper,
  StyledBillingCityWrapper,
  StyledBillingStateWrapper,
  StyledBillingZipWrapper,
  StyledCountrySelectWrapper,
  StyledNameOnCardWrapper,
  StyledSaveCardContainer,
  StyledStreetAddressWrapper,
} from './styled';

interface GetErrorMessageOptions {
  errors: IPaymentErrors;
  formatMessage: IntlFormatters['formatMessage'];
}

export const getErrorMessage = ({
  errors,
  formatMessage,
}: GetErrorMessageOptions): string | null => {
  const errorKeys = Object.keys(errors);
  const numberOfErrors = errorKeys.filter(error => errors[error]).length;

  if (!numberOfErrors) {
    return null;
  }

  const id = numberOfErrors === 1 ? 'thereIsAnErrorInThisForm' : 'thereAreErrorsInThisForm';
  return formatMessage({ id }, { numberOfErrors });
};

interface ICreditCardFormInputs {
  errors: IPaymentErrors;
  isDelivery?: boolean;
  onChange: (name: string, value: string) => void;
  paymentValues: IPaymentState;
  showSaveCard?: boolean;
  withBorder?: boolean;
  withPadding?: boolean;
  ariaLabel?: string;
  supportedCardTypes?: CardType[];
}

const CreditCardFormInputs: FC<React.PropsWithChildren<ICreditCardFormInputs>> = ({
  errors,
  isDelivery = false,
  onChange,
  paymentValues,
  showSaveCard = true,
  withBorder = false,
  withPadding = true,
  ariaLabel,
  supportedCardTypes = [],
}) => {
  const paymentFieldVariations =
    useFlag<PaymentFieldVariations>(LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS) ||
    defaultPaymentFieldVariation;
  const { feCountryCode } = useLocale();
  // If the country field is hidden from the form, use the user's current locale
  const country = paymentFieldVariations.country ? paymentValues.billingCountry : feCountryCode;

  // retrieve additional credit card form props
  const { postalCodeInputProps, regionInputProps } = useCreditCardFormProps(country ?? undefined);
  const { formatMessage } = useIntl();

  const errorMessage = getErrorMessage({ errors, formatMessage });

  // NativeBase is having a weird behavior with the Checkbox
  // The onChange handler passed to it is always stale, unless we force a re-render by changing its key
  const checkboxKey = React.useMemo(() => JSON.stringify(paymentValues), [paymentValues]);

  return (
    <>
      {!!errorMessage && <VisuallyHidden accessibilityLabel={errorMessage} role="alert" />}
      <FormContainer
        withPadding={withPadding}
        withBorder={withBorder}
        accessibilityLabel={ariaLabel}
        marginTop="$6"
      >
        <FormTitle
          title={formatMessage({ id: 'pleaseEnterYourCreditCardInformation' })}
          creditText={formatMessage({ id: 'credit' })}
        />
        {paymentFieldVariations.name && (
          <StyledNameOnCardWrapper>
            <TextInput
              accessibilityLabel={formatMessage({ id: 'nameOnCard' })}
              onChangeText={(value: string) => onChange('nameOnCard', value)}
              label={formatMessage({ id: 'nameOnCard' })}
              value={paymentValues.nameOnCard}
              autoFocus
              required
              testID={CREDIT_CARD_FORM_NAME_INPUT}
              errorMessage={errors.nameOnCard}
              autoComplete="name"
            />
          </StyledNameOnCardWrapper>
        )}
        <FirstDataCreditCardInputs
          onChange={onChange}
          supportedCardTypes={supportedCardTypes}
          paymentValues={paymentValues}
          errors={errors}
          paymentFieldVariations={paymentFieldVariations}
        />
        {isDelivery && (
          <Checkbox
            key={checkboxKey}
            onChange={value => onChange('billingAddressSameAsDelivery', value ? 'true' : 'false')}
            value={String(paymentValues.billingAddressSameAsDelivery)}
            isChecked={paymentValues.billingAddressSameAsDelivery}
            testID="billing-same-as-delivery"
          >
            {formatMessage({ id: 'billingAddressIsSameAsDeliveryAddress' })}
          </Checkbox>
        )}
      </FormContainer>
      <FormContainer withPadding={withPadding} withBorder={false}>
        {paymentFieldVariations.addressLine1 && (
          <StyledStreetAddressWrapper>
            <TextInput
              accessibilityLabel={formatMessage({ id: 'streetAddress' })}
              onChangeText={value => onChange('billingStreetAddress', value)}
              label={formatMessage({ id: 'streetAddress' })}
              value={paymentValues.billingStreetAddress || ''}
              required
              testID="billingStreetAddress"
              errorMessage={errors.billingStreetAddress}
              autoComplete="street-address"
              isDisabled={isDelivery && paymentValues.billingAddressSameAsDelivery}
            />
          </StyledStreetAddressWrapper>
        )}
        {paymentFieldVariations.addressLine2 && (
          <StyledBillingAptWrapper>
            <TextInput
              accessibilityLabel={formatMessage({ id: 'apartment' })}
              onChangeText={value => onChange('billingApt', value)}
              label={formatMessage({ id: 'aptOrSuite' })}
              value={paymentValues.billingApt || ''}
              testID="billingApt"
              errorMessage={errors.billingApt}
              autoComplete="street-address"
              isDisabled={isDelivery && paymentValues.billingAddressSameAsDelivery}
            />
          </StyledBillingAptWrapper>
        )}
        {paymentFieldVariations.city && (
          <StyledBillingCityWrapper>
            <TextInput
              accessibilityLabel={formatMessage({ id: 'city' })}
              label={formatMessage({ id: 'city' })}
              onChangeText={value => onChange('billingCity', value)}
              value={paymentValues.billingCity || ''}
              required
              testID="billingCity"
              errorMessage={errors.billingCity}
              autoComplete="postal-address-locality"
              isDisabled={isDelivery && paymentValues.billingAddressSameAsDelivery}
            />
          </StyledBillingCityWrapper>
        )}
        {paymentFieldVariations.state && (
          <StyledBillingStateWrapper>
            <TextInput
              accessibilityLabel={formatMessage({ id: 'state' })}
              onChangeText={value => onChange('billingState', value)}
              value={excludeNumeric(paymentValues.billingState ?? '')}
              required
              testID="billingState"
              errorMessage={errors.billingState}
              autoComplete="postal-address-region"
              isDisabled={isDelivery && paymentValues.billingAddressSameAsDelivery}
              {...regionInputProps}
            />
          </StyledBillingStateWrapper>
        )}
        {paymentFieldVariations.zip && (
          <StyledBillingZipWrapper>
            {/* Additional props are spread: label, maxLength, pattern */}
            <TextInput
              accessibilityLabel={formatMessage({ id: 'zipCode' })}
              onChangeText={value => onChange('billingZip', value)}
              value={paymentValues.billingZip}
              required
              testID="billingZip"
              errorMessage={errors.billingZip}
              autoComplete="postal-code"
              maxLength={10}
              isDisabled={isDelivery && paymentValues.billingAddressSameAsDelivery}
              {...postalCodeInputProps}
            />
          </StyledBillingZipWrapper>
        )}
        {paymentFieldVariations.country && (
          <StyledCountrySelectWrapper>
            <CountrySelect
              label={formatMessage({ id: 'country' })}
              name="billingCountry"
              testID="billingCountry"
              required
              value={paymentValues.billingCountry ?? ''}
              onChange={value => onChange('billingCountry', value)}
              disabled={!!(isDelivery && paymentValues.billingCountry)}
            />
          </StyledCountrySelectWrapper>
        )}
      </FormContainer>
      {showSaveCard && (
        <StyledSaveCardContainer>
          <Checkbox
            testID="saveCard"
            key={checkboxKey}
            value={String(paymentValues.saveCard)}
            isChecked={paymentValues.saveCard}
            onChange={value => onChange('saveCard', value ? 'true' : 'false')}
            accessibilityLabel={formatMessage({ id: SAVE_CARD_DISCLAIMER })}
            alignItems={'flex-start'}
          >
            {formatMessage({ id: SAVE_CARD_DISCLAIMER })}
          </Checkbox>
        </StyledSaveCardContainer>
      )}
    </>
  );
};

export default CreditCardFormInputs;
