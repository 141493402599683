import { GestureResponderEvent, StyleSheet, View } from 'react-native';

import { tokens } from '../../tokens';
import { Pressable } from '../pressable';
import { Text } from '../text';

type RadioButtonProps = {
  label?: string;
  selected: boolean;
  onSelect: (event: GestureResponderEvent) => void;
  disabled?: boolean;
};

export const RadioButton = ({ label, selected, onSelect, disabled = false }: RadioButtonProps) => {
  return (
    <View style={styles.container}>
      <Pressable
        style={[styles.outerCircle]}
        onPress={onSelect}
        accessibilityRole="radio"
        accessibilityState={{ checked: selected }}
        disabled={disabled}
        hoveredStyle={styles.hover}
        outlineFocusedStyle={styles.focus}
      >
        {selected || disabled ? (
          <View style={[styles.innerCircle, disabled && styles.disabled]} />
        ) : null}
      </Pressable>
      {label && <Text style={styles.label}>{label}</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  outerCircle: {
    height: 24,
    width: 24,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity30,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  innerCircle: {
    height: 24,
    width: 24,
    borderRadius: 12,
    borderColor: tokens.colors.$houseRedDarken,
    borderWidth: 6,
    backgroundColor: tokens.colors.$white,
  },
  label: {
    fontSize: 16,
  },
  disabled: {
    borderColor: tokens.colors.$blackOpacity30,
  },
  hover: {
    borderColor: tokens.colors.$houseDark,
  },
  focus: {
    borderRadius: 100,
  },
});
