import { router, useLocalSearchParams } from 'expo-router';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import Dialog from 'components/dialog';
import { useToast } from 'hooks/use-toast';
import useUserPhoneVerification from 'hooks/use-user-phone-verification';
import { HttpErrorCodes } from 'remote/constants';
import { useAuthContext } from 'state/auth';
import { getStoredOtpCredentials } from 'state/auth/hooks/use-account-authentication';
import noop from 'utils/noop';
import { routes } from 'utils/routing';
import { SIGNIN_OTP_PAGE } from 'utils/test-ids';

import { OtpInputField } from './otp-input-field';

interface IUserPhoneVerificationModalProps {
  onDismiss: () => void;
  showDialog: boolean;
}

const OTP_CODE_URL_PARAM = 'code';

const UserPhoneVerificationModal: FC<React.PropsWithChildren<IUserPhoneVerificationModalProps>> = ({
  showDialog,
  onDismiss,
}) => {
  const toast = useToast();
  const { formatMessage } = useIntl();
  const { verifyPhone, loading } = useUserPhoneVerification();
  const { setOtpAuthError } = useAuthContext();
  const { sessionId } = getStoredOtpCredentials() || {};
  const [isValidatingOtp, setIsValidatingOtp] = useState(false);
  const [otpValidationError, setOtpValidationError] = useState('');
  const params = useLocalSearchParams<{ [OTP_CODE_URL_PARAM]: string }>();
  const urlOtpCode = params[OTP_CODE_URL_PARAM];

  const handleValidation = async (error: any) => {
    let errorMessage;
    setIsValidatingOtp(false);
    const errorCode = error?.graphQLErrors?.[0]?.extensions?.statusCode;

    switch (errorCode) {
      case HttpErrorCodes.InternalServerError:
        errorMessage = formatMessage({ id: 'looksLikeWereExperiencingIssues' });
        break;
      case HttpErrorCodes.TooManyRequests:
        errorMessage = `${formatMessage({
          id: 'maxAttemptsReachedPhoneVerify',
        })} ${formatMessage({
          id: 'pleaseTryAgainLater',
        })}`;
        break;
      default:
        errorMessage = formatMessage({ id: 'invalidOtpProvided' });
        break;
    }

    if (!sessionId) {
      errorMessage = formatMessage({ id: 'differentDeviceErrorPhoneVerify' });
    }

    setOtpValidationError(errorMessage);
    setOtpAuthError(errorMessage);
    throw error;
  };

  const handleVerification = async (text: string) => {
    setIsValidatingOtp(true);
    setOtpValidationError('');
    setOtpAuthError(null);
    const code = text;
    try {
      await verifyPhone({ sessionId, code });
      toast.show({
        text: formatMessage({ id: 'userPhoneVerified' }),
        variant: 'positive',
      });
      onDismiss();
      router.navigate(routes.account);
    } catch (error) {
      await handleValidation(error);
    }
  };

  const onRemove = useCallback(async () => {
    setOtpValidationError('');
    setOtpAuthError(null);
    onDismiss();
  }, [onDismiss, setOtpAuthError]);

  /**
   * Only try submission on mount or when the otpCode in the url changes
   */
  useEffect(() => {
    if (urlOtpCode) {
      handleVerification(urlOtpCode).catch(noop);
    }
  }, [urlOtpCode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog
      showDialog={showDialog}
      onDismiss={onDismiss}
      modalAppearanceEventMessage="User Phone Verification Modal"
      testID={SIGNIN_OTP_PAGE}
      heading={formatMessage({
        id: 'verifyYourPhoneNumber',
      })}
      body={formatMessage({
        id: 'verifyYourPhoneInstructions',
      })}
      actions={
        <Button.Group>
          <OtpInputField
            errorMessage={otpValidationError}
            initialCode={urlOtpCode}
            loading={isValidatingOtp}
            onSubmit={handleVerification}
          />

          <ActionButton
            disabled={loading}
            variant={ActionButtonVariants.OUTLINE}
            fullWidth
            marginTop={3}
            onPress={onRemove}
            testID="user-phone-verification-modal-cancel-button"
          >
            {formatMessage({
              id: 'cancel',
            })}
          </ActionButton>
        </Button.Group>
      }
    />
  );
};

export default UserPhoneVerificationModal;
