import { Box, HStack, Header, Text } from '@fhs-legacy/universal-components';
import Link from 'components/link';
import Picture from 'components/picture';

const MENU_TILE_MIN_WIDTH = '104px';
const CARD_WIDTH = { desktop: '310px' };
export const PriceAndCaloriesListWrapper = HStack.withConfig({
  alignItems: 'center',
  justifyContent: 'flex-start',
});

export const StyledLink = Link.withConfig({
  alignItems: 'center',
  width: CARD_WIDTH,
  marginBottom: '$3',
  marginRight: '$3',
});
export const Tile = HStack.withConfig<{ $transparentBg?: boolean }>(p => ({
  flexShrink: 1,
  flexGrow: 1,
  backgroundColor: p.$transparentBg ? 'transparent' : Styles.color.white,
  height: '80px',
  alignItems: 'center',
  paddingX: '$1',
  paddingY: '$2',
  _web: {
    cursor: 'pointer',
  },
  minHeight: {
    desktop: '320px',
  },
}));

export const TileCard = Box.withConfig({
  width: CARD_WIDTH,
  backgroundColor: 'token.background-pattern',
  borderRadius: 12,
  minH: '248px',
  paddingTop: '$2',
  justifyContent: 'center',
});

export const TileFooter = Box.withConfig<{ isMenuItem: boolean }>(p => ({
  alignItems: 'center',
  paddingTop: '$2',
  paddingX: '$4',
  height: p.isMenuItem ? '92px' : '54px',
  _text: {
    variant: 'headerThree',
    fontSize: 'lg',
    textAlign: 'center',
    marginBottom: '$1',
  },
}));

export const TileHeaderWrapper = Box.withConfig({
  justifyContent: 'space-around',
});

export const TileHeader = Header.withConfig({
  margin: 0,
  textTransform: 'none',
  numberOfLines: 1, // causes ellipsis
});

export const TileDescription = Box.withConfig({
  flexShrink: 1,
  paddingX: {
    base: '$3',
    desktop: '$8',
  },
  paddingY: {
    base: 0,
    desktop: '$5',
  },
});

export const TileImageWrapper = Box.withConfig({
  minWidth: MENU_TILE_MIN_WIDTH,
});

export const TileImage = Picture.withConfig<{ isMenuItem?: boolean }>(p => ({
  objectFitContain: true,
  width: 'full',
  height: { base: '70px', desktop: p.isMenuItem ? '148px' : '180px' },
  borderTopRadius: Styles.borderRadius,
  borderBottomRadius: {
    base: Styles.borderRadius,
    desktop: 0,
  },
}));

export const ItemPrice = Text.withConfig({
  fontWeight: 'bold',
  color: Styles.color.pricing,
});

export const StyledItemPrice = ItemPrice.withConfig({
  color: Styles.color.black,
  numberOfLines: 1,
});

export const StyledPriceAndCaloriesListWrapper = HStack.withConfig({
  alignItems: 'center',
  justifyContent: 'center',
  space: '$2',
});

export const StyledListItemCalories = Text.withConfig({
  marginBottom: 0,
  marginTop: 0,
  color: Styles.color.black,
  numberOfLines: 1,
});
