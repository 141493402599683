import { router, useFocusEffect } from 'expo-router';
import { useCallback } from 'react';

import { useStoreClosedActionSheet } from 'components/store-closed-action-sheet';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

export const StoreClosedConfirmation = ({ isCatering }: { isCatering: boolean }) => {
  const { store } = useStoreContext();
  const { requestScheduleFutureOrderingConfirmation, storeClosedActionSheet } =
    useStoreClosedActionSheet({
      restaurant: store as IRestaurantNode,
      shouldCheckSingletonDate: true,
      isCatering,
    });
  useFocusEffect(
    useCallback(() => {
      requestScheduleFutureOrderingConfirmation().then(result => {
        if (!result) {
          /**
           * ActionSheet component blocks UI interaction if we don't wait until it is fully closed.
           */
          setTimeout(() => {
            router.navigate(routes.storeLocator);
          }, 250);
        }
      });
    }, [requestScheduleFutureOrderingConfirmation])
  );
  return <>{storeClosedActionSheet}</>;
};
