import { ComponentPropsWithoutRef, useEffect } from 'react';
import {
  AvoidSoftInput as RnAvoidSoftInput,
  AvoidSoftInputView as RnAvoidSoftInputView,
} from 'react-native-avoid-softinput';
import { PreviousNextView } from 'react-native-keyboard-manager';

export function AvoidSoftInputView(props: ComponentPropsWithoutRef<typeof RnAvoidSoftInputView>) {
  useEffect(() => {
    RnAvoidSoftInput.setShouldMimicIOSBehavior(true);
    RnAvoidSoftInput.setEnabled(true);

    return () => {
      RnAvoidSoftInput.setEnabled(false);
      RnAvoidSoftInput.setShouldMimicIOSBehavior(false);
    };
  }, []);

  return (
    <PreviousNextView>
      <RnAvoidSoftInputView {...props} />
    </PreviousNextView>
  );
}
