import delv from 'dlv';
import uuid from 'uuid/v4';

import {
  ISanityBlockContent,
  ISanityImage,
  ISanityLocaleBlockContent,
  ISanityLocaleImages,
} from '@rbi-ctg/menu';
import { replaceKeyRegex } from 'utils/language';

export const getLocaleText = (content: object, type: string) => delv(content, `${type}.locale`);

export const getLocaleTextBlock = (content: object, type: string) =>
  delv(content, `${type}.localeRaw`);

export const getLocaleImage = (content: object, type: string) => ({
  image: delv(content, `${type}.locale.app`),
  alt: delv(content, `${type}.locale.imageDescription`),
});

export const parseSanityBlock = (
  sanityBlock: ISanityBlockContent[],
  key: string,
  value: string
) => {
  if (sanityBlock && sanityBlock.length) {
    return sanityBlock.map((b: ISanityBlockContent) => ({
      ...b,
      children: b.children.map(c => ({
        ...c,
        text: c.text.replace(replaceKeyRegex(key), value),
      })),
    }));
  }
  return sanityBlock;
};

export const parseSanityBlockMultipleValues = (
  sanityBlock: ISanityBlockContent[],
  values: { [key: string]: string }
): ISanityBlockContent[] =>
  Object.entries(values).reduce(
    (acc, [key, value]) => parseSanityBlock(acc, key, value),
    sanityBlock
  );

export function parseSanityObject<T>({
  doc,
  localeBlockKeys,
  variables,
}: {
  doc: T;
  localeBlockKeys: string[];
  variables: { [key: string]: string };
}) {
  const augmentedDoc = {};
  localeBlockKeys.forEach(key => {
    try {
      // Allow nested keys, separated by decimal (.) in the form 'a.b'
      if (key.includes('.')) {
        const parts = key.split('.');
        const num = parts.length;
        // only support one layer of nesting
        if (num !== 2) {
          // eslint-disable-next-line no-console
          console.warn(
            'utils/sanity: parseSanityObject only supports one level of nested `localeBlockKeys`, e.g. "a.b" '
          );

          return;
        }
        const text = getLocaleTextBlock(doc as Object, key);
        const parentKey = parts[0];
        const childKey = parts[1];
        if (text && parentKey && childKey) {
          const res = parseSanityBlockMultipleValues(text, variables);
          // If we have already copied over and replaced the `key`'s object. Use that value
          // Otherwise copy the values over.
          const rest = augmentedDoc[parentKey] || doc[parentKey];
          augmentedDoc[parentKey] = {
            ...rest,
            [childKey]: {
              localeRaw: res,
            },
          };
        }
        return;
      }

      // Parse the template vars,
      // and set the localeRaw
      augmentedDoc[key] = {
        localeRaw: parseSanityBlockMultipleValues(
          getLocaleTextBlock(doc as Object, key),
          variables
        ),
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`parseSanityObject - localeBlockKey [${key}] not found`, error);
    }
  });
  return {
    ...doc,
    ...augmentedDoc,
  };
}

export const getFirstStringInLocaleBlockContent = (
  localeBlockContent: ISanityLocaleBlockContent | undefined | null
): string => localeBlockContent?.localeRaw?.[0]?.children?.[0]?.text || '';

export const getDescriptionFromSanityLocaleImages = (
  sanityLocaleImages: ISanityLocaleImages
): string => sanityLocaleImages?.locale?.imageDescription || '';

export const getAppImageFromSanityLocaleImages = (
  sanityLocaleImages: ISanityLocaleImages
): ISanityImage => sanityLocaleImages?.locale?.app || {};

interface ICreateTextBlock {
  marks?: string[];
  text?: string;
  style?: string;
}

/** This create a sanity text block from HC strings */
export const createTextBlock = (createTextBlockArr: ICreateTextBlock[]) =>
  createTextBlockArr.map(
    ({ marks, text, style }) =>
      ({
        _key: uuid(),
        _type: 'block',
        children: [{ _key: uuid(), _type: 'span', marks, text }],
        markDefs: [],
        style,
      } as ISanityBlockContent)
  );

// TODO: This method is not handling line breaks
export const blockContentToPlainText = (contentBlocks: ISanityBlockContent[] = []): string =>
  contentBlocks
    .map(block => {
      // if it's not a text block with children,
      // return an empty string
      if (block._type !== 'block' || !block.children) {
        return '';
      }
      // loop through the children spans, and join the
      // text strings
      return block.children.map(({ text }) => text).join('');
    })
    .join(' ')
    .trim();
