import { Platform } from 'react-native';

import { create } from '@fhs/zustand';

import { mediaQueryTests } from './media-query-tests';
import type { MediaQueriesStoreState } from './types';

const getMediaQueryState = () => {
  const state = {};

  for (const [query, mqList] of Object.entries(mediaQueryTests)) {
    state[query] = mqList.matches;
  }

  return state as MediaQueriesStoreState;
};

export const useMediaQueries = create<MediaQueriesStoreState>(() => getMediaQueryState());

if (Platform.OS === 'web') {
  Object.entries(mediaQueryTests).forEach(([query, mqList]) => {
    mqList.addEventListener(
      'change',
      () => {
        useMediaQueries.setState({ [query]: mqList.matches });
      },
      { passive: true }
    );
  });
} else {
  Object.entries(mediaQueryTests).forEach(([query, mqList]) => {
    // the polyfill only adds the addListener method: https://github.com/expo/match-media/blob/master/src/MediaQueryList.ts
    // @ts-ignore
    mqList.addListener(() => {
      useMediaQueries.setState({ [query]: mqList.matches });
    });
  });
}
