import { Path, Rect, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconPaymentTypeAddNewPayment = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Rect width={24} height={16} y={4.101} fill="#fff" rx={2} />
      <Rect width={23} height={15} x={0.5} y={4.601} stroke="#000" strokeOpacity={0.1} rx={1.5} />
      <Path fill="currentColor" d="M15.5 12.6h-3v3h-1v-3h-3v-1h3v-3h1v3h3v1Z" />
    </Svg>
  );
};
