import React from 'react';

import { useIsDesktopBp } from 'hooks/breakpoints';
import { FutureOrderTimeCard } from 'pages/cart/future-order-time-card';
import Map from 'pages/order-confirmation/map';
import { IConfirmationUIProps } from 'pages/order-confirmation/order-confirmation';
import OrderIssues from 'pages/order-confirmation/order-issues';
import { useDeliveryConfirmationContext } from 'state/delivery-confirmation';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOrderTimedFire } from 'state/order/hooks/use-order-timed-fire';

import DeliveryDetails from './delivery-details';
import DeliveryDisclaimer from './delivery-disclaimer';
import {
  DeliveryConfirmationRoot,
  FixedMapWindow,
  FutureOrderTimeCardWrapper,
  ScrollContainer,
  StyledOrderIssues,
} from './styled';

const DeliveryConfirmation: React.FC<React.PropsWithChildren<IConfirmationUIProps>> = ({
  serverOrder,
}) => {
  const isShowingOrderingIssue = useFlag(LaunchDarklyFlag.SHOW_ORDERING_ISSUE);

  const { bringgUuids } = useDeliveryConfirmationContext();
  const { pickupDate: deliveryDate } = useOrderTimedFire({ serverOrder });
  const isDesktop = useIsDesktopBp();

  return (
    <DeliveryConfirmationRoot>
      <FixedMapWindow>
        <Map bringgUuids={bringgUuids} serverOrder={serverOrder} />
      </FixedMapWindow>
      <FutureOrderTimeCardWrapper mx={isDesktop ? '$0' : '$4'}>
        <FutureOrderTimeCard
          date={{
            year: deliveryDate.getFullYear(),
            month: deliveryDate.getMonth(),
            day: deliveryDate.getDate(),
            hour: deliveryDate.getHours(),
            minutes: deliveryDate.getMinutes(),
          }}
          readOnly
        />
      </FutureOrderTimeCardWrapper>
      <ScrollContainer mx={isDesktop ? '$0' : '$4'}>
        {serverOrder.delivery && <DeliveryDetails serverOrder={serverOrder} />}
        {isShowingOrderingIssue && (
          <StyledOrderIssues>
            <OrderIssues>
              <DeliveryDisclaimer />
            </OrderIssues>
          </StyledOrderIssues>
        )}
      </ScrollContainer>
    </DeliveryConfirmationRoot>
  );
};

export default DeliveryConfirmation;
