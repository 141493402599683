import { GestureResponderEvent, Pressable, StyleProp, ViewStyle } from 'react-native';

import { ArrowRight, Button, Text, createMqStyles, tokens } from '@fhs/ui';

export function SeeMoreCard({
  title,
  buttonLabel,
  onPress,
  style,
}: {
  title: string;
  buttonLabel: string;
  onPress: (event: GestureResponderEvent) => void;
  style?: StyleProp<ViewStyle>;
}) {
  const styles = useMqStyles();
  return (
    <Pressable style={[styles.container, style]} onPress={onPress}>
      <Text.Ui size="lg" style={styles.title} weight="bold">
        {title}
      </Text.Ui>
      <Button type="outline" size="xs" style={styles.button} onPress={onPress}>
        <Button.Text style={styles.buttonLabel}>{buttonLabel}</Button.Text>
        <ArrowRight style={styles.buttonArrow} size={12} />
      </Button>
    </Pressable>
  );
}

const useMqStyles = createMqStyles({
  container: {
    $base: {
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: tokens.colors.$black10,
      borderRadius: 8,
      borderWidth: 1,
      minHeight: 174,
      minWidth: 160,
      maxHeight: 400,
    },
  },
  title: {
    $base: {
      color: tokens.colors.$houseRedDarken,
      textAlign: 'center',
      width: 120,
      fontSize: 14,
      lineHeight: 19.6,
      paddingBottom: 16,
      marginRight: 20,
      paddingLeft: 20,
    },
  },
  button: {
    $base: {
      width: 120,
      color: tokens.colors.$houseRedDarken,
    },
  },
  buttonLabel: {
    $ltDesktop: {
      fontSize: 10,
      lineHeight: 14,
    },
  },
  buttonArrow: {
    $base: {
      color: tokens.colors.$houseRedDarken,
    },
  },
});
