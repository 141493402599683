import { Link } from 'expo-router';
import { useMemo } from 'react';

import { Icon, Text } from '@fhs-legacy/universal-components';
import { Box, HStack } from '@fhs-legacy/universal-components';
import { useCartLimit } from 'hooks/use-cart-limit';
import { useFeatureMenu } from 'hooks/use-feature-menu';
import { useUIContext } from 'state/ui';
import { isExternalLink } from 'utils/is-external-link';

type MenuSectionCateringAlertProps = {
  currentSectionId: string;
};

export const MenuSectionCateringAlert = ({ currentSectionId }: MenuSectionCateringAlertProps) => {
  const { featureMenu } = useFeatureMenu();
  const cateringAlertText = featureMenu?.cateringAlertText?.locale;
  const cateringLinkUrl = featureMenu?.cateringAlertLinkUrl?.locale || '';
  const cateringLinkText = featureMenu?.cateringAlertLinkText?.locale || '';
  const cateringSections = featureMenu?.cateringSections;
  const currentIsCateringSection = useMemo(
    () => !!cateringSections?.find(section => section && currentSectionId.endsWith(section?._id)),
    [cateringSections, currentSectionId]
  );

  const { maxCartTotal } = useCartLimit();
  const { formatCurrencyForLocale } = useUIContext();
  const formattedCartMaxTotal = formatCurrencyForLocale(maxCartTotal);
  return (
    currentIsCateringSection && (
      <Box
        backgroundColor="white"
        p="$4"
        borderRadius={{ desktop: 'xl' }}
        mt={{ desktop: '$8' }}
        mb={{ desktop: -4 }}
      >
        <HStack
          alignItems="center"
          backgroundColor={Styles.color.background}
          p="$4"
          borderRadius="lg"
        >
          <Icon variant="infoFill" mr="$2" />
          <Text fontSize={{ base: 'xs', desktop: 'sm' }}>
            {cateringAlertText} {formattedCartMaxTotal}{' '}
            <Link
              href={cateringLinkUrl}
              asChild
              {...(isExternalLink(cateringLinkUrl) ? { target: '_blank' } : {})}
            >
              <Text underline color="primary.500">
                {cateringLinkText}
              </Text>
            </Link>
          </Text>
        </HStack>
      </Box>
    )
  );
};
