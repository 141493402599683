import React from 'react';
import { useIntl } from 'react-intl';

import { Text as BaseText, Box, HStack, InlineAlert, Text } from '@fhs-legacy/universal-components';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode } from 'state/order';
import { useStoreContext } from 'state/store';
import { isDelivery } from 'utils/service-mode';

import SectionHeading from '../section-heading';
import { ServiceModeDetails } from '../service-mode-details';

import { getLocalizedStoreAddress } from './common';
import DeliveryDetails from './delivery-details';
import { RestaurantDetails } from './restaurant-details';
import { DetailsBox } from './styled';
import { ICartDetailsProps } from './types';

// NOTE: Not currently supporting Catering service mode

const HeadingContainer = HStack.withConfig({
  justifyContent: 'space-between',
  alignItems: 'center',
});

const PickupDetailsBox = DetailsBox.withConfig({
  padding: '$0',
});

const PickupDetailsAddress = Box.withConfig({
  paddingY: '$5',
  paddingX: '$4',
});

const method = (serviceMode: ServiceMode) => {
  switch (serviceMode) {
    case ServiceMode.EAT_IN:
      return 'Dine In';
    case ServiceMode.TAKEOUT:
      return 'Pick Up';
    case ServiceMode.DRIVE_THRU:
      return 'Drive Thru';
    case ServiceMode.CURBSIDE:
      return 'Curbside';
    default:
      return 'Other';
  }
};

const CartDetails: React.FC<React.PropsWithChildren<ICartDetailsProps>> = ({
  readOnly = false,
  serverOrder,
  serviceMode,
  setAreDeliveryDetailsValid,
  phoneNumberInputRef,
  deliveryDetailsErrors,
}) => {
  const isDeliveryOrder = isDelivery(serviceMode);
  let details;
  let sectionHeading = '';
  if (isDeliveryOrder) {
    sectionHeading = 'deliveryDetails';
    details = (
      <DeliveryDetails
        serverOrder={serverOrder}
        setAreDeliveryDetailsValid={setAreDeliveryDetailsValid}
        sectionHeading={sectionHeading}
        phoneNumberInputRef={phoneNumberInputRef}
        deliveryDetailsErrors={deliveryDetailsErrors}
      />
    );
  } else {
    sectionHeading = 'restaurantDetails';
    details = <PickupDetails serviceMode={serviceMode} readOnly={readOnly} />;
  }

  const { formatMessage } = useIntl();

  return (
    <>
      <HeadingContainer>
        <SectionHeading>
          {formatMessage({
            id: sectionHeading,
          })}
        </SectionHeading>
      </HeadingContainer>
      {details}
    </>
  );
};

const PickupDetails: React.FC<
  React.PropsWithChildren<Omit<ICartDetailsProps, 'serverOrder' | 'deliveryDetailsErrors'>>
> = ({ serviceMode }) => {
  const { store } = useStoreContext();
  const { formatMessage } = useIntl();
  const isDeliveryOrder = isDelivery(serviceMode);

  const enableServiceModeCartSelection = useFlag(
    LaunchDarklyFlag.ENABLE_SERVICE_MODE_CART_SELECTION
  );
  const localizedStoreAddress = getLocalizedStoreAddress(store, formatMessage);
  return (
    <PickupDetailsBox>
      <PickupDetailsAddress testID="pickup-details-address">
        {isDeliveryOrder ? (
          <>
            <Text>{localizedStoreAddress}</Text>
            {!enableServiceModeCartSelection && (
              <BaseText mt="$4" fontWeight={700}>
                {formatMessage({ id: 'method' }, { serviceMode: method(serviceMode) })}
              </BaseText>
            )}
          </>
        ) : (
          <Box mb="$3">
            <RestaurantDetails />
          </Box>
        )}

        <InlineAlert
          status="info"
          borderRadius={8}
          marginBottom="$2"
          marginTop="$3"
          message={formatMessage({ id: 'noRefundsForWrongLocaton' })}
          iconCentered
        />
      </PickupDetailsAddress>
      {enableServiceModeCartSelection && <ServiceModeDetails />}
    </PickupDetailsBox>
  );
};

export default CartDetails;
