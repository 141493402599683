import { ReactNode } from 'react';

import { Header, Text } from '@fhs-legacy/universal-components';

import { IncentiveContainer, IncentiveStatus } from './styled';

interface IncentinveErrorHeadProps {
  title?: string;
  description: ReactNode;
  statusMessage: string;
}
export function IncentiveErrorHead({
  title,
  description,
  statusMessage,
}: IncentinveErrorHeadProps) {
  return (
    <IncentiveContainer $isInvalid>
      <IncentiveStatus $isInvalid>{statusMessage}</IncentiveStatus>
      {title && (
        <Header variant="headerTwo" marginBottom={0}>
          {title}
        </Header>
      )}
      <Text>{description}</Text>
    </IncentiveContainer>
  );
}
