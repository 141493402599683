import React from 'react';
import { AccessibilityRole } from 'react-native';

import { View } from '@fhs-legacy/universal-components';

export const VisuallyHidden: React.FC<
  React.PropsWithChildren<{
    accessibilityLabel: string;
    role?: AccessibilityRole;
    testID?: string;
  }>
> = ({ accessibilityLabel, role, testID, children }) => (
  <View
    accessibilityRole={role}
    accessibilityLabel={accessibilityLabel}
    testID={testID}
    style={{ display: 'none' }}
  >
    {children}
  </View>
);
