import React from 'react';
import type { ComponentProps, ReactNode } from 'react';

import { Box, HStack, Icon } from '@fhs-legacy/universal-components';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import { Row, StyledLink } from './arrow-link.styled';

type ArrowLinkProps = ComponentProps<typeof StyledLink> & {
  children: ReactNode;
  labelEnd?: ReactNode;
};

export const ArrowLink = ({ children, labelEnd, ...props }: ArrowLinkProps) => (
  <StyledLink {...props}>
    <Row>
      <Box _text={{ variant: 'copyOne' }}>{children}</Box>
      <HStack>
        {labelEnd}
        <Icon variant="next" ml="$1" width="4" {...hiddenAccessibilityPlatformProps} />
      </HStack>
    </Row>
  </StyledLink>
);
