import { ServiceMode } from 'state/service-mode/types';

export const serviceModeList = [
  ServiceMode.DRIVE_THRU,
  ServiceMode.TAKEOUT,
  ServiceMode.CURBSIDE,
  ServiceMode.EAT_IN,
  ServiceMode.CATERING_PICKUP,
  ServiceMode.PICKUP_WINDOW,
];
