import React, { useRef } from 'react';
import { useIntl } from 'react-intl';

import { Text } from '@fhs-legacy/universal-components';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import {
  DescriptionContainer,
  DescriptionInput,
  ErrorMessage,
  Legend,
  RequiredLabel,
} from './styled';

declare interface IDescriptionChange {
  value: string;
  hasError: boolean;
}
interface IVehicleDescription {
  onChange: (change: IDescriptionChange) => void;
  hasError?: boolean;
  value?: string;
}

export const VehicleDescription: React.FC<React.PropsWithChildren<IVehicleDescription>> = ({
  onChange,
  value,
  hasError,
}) => {
  const { formatMessage } = useIntl();
  const curbsideVehicleDescriptionMaxLength = useFlag(
    LaunchDarklyFlag.CURBSIDE_VEHICLE_DESCRIPTION_MAX_LENGTH
  );

  const errorMessage = formatMessage(
    { id: 'descriptionLengthError' },
    {
      min: 1,
      max: curbsideVehicleDescriptionMaxLength,
    }
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (description: string) => {
    const isValid = description && description.length <= curbsideVehicleDescriptionMaxLength;
    onChange({ value: description, hasError: !isValid });
  };

  return (
    <DescriptionContainer>
      <Legend>
        {formatMessage({ id: 'describeYourVehicle' })}
        <RequiredLabel>*</RequiredLabel>
      </Legend>
      {/*TODO: RN - This was a <label> tag. This should be converted to a UL <FormControl.Label>*/}
      <Text>{formatMessage({ id: 'curbsideVehicleDescription' })}</Text>
      <DescriptionInput
        testID="vehicle-description"
        placeholder={formatMessage({ id: 'curbsideVehicleDescriptionPlaceholder' })}
        onChangeText={handleChange}
        value={value}
        ref={inputRef}
      />
      {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </DescriptionContainer>
  );
};
