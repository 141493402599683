import { router } from 'expo-router';
import React from 'react';
import { useIntl } from 'react-intl';

import ConfirmDialog from 'components/confirm-dialog';

interface IExistingModalDialogProps {
  showDialog: boolean;
  email?: string;
  dismiss: () => void;
}

const ExistingEmailDialog: React.FC<React.PropsWithChildren<IExistingModalDialogProps>> = ({
  showDialog,
  dismiss,
  email = '',
}) => {
  const { formatMessage } = useIntl();

  return (
    <ConfirmDialog
      showDialog={showDialog}
      body={formatMessage({ id: 'logInWithExistingEmail' })}
      confirmLabel={formatMessage({ id: 'logIn' })}
      heading={formatMessage({ id: 'welcomeBack' })}
      onCancel={dismiss}
      onConfirm={() => {
        dismiss();
        router.navigate({ pathname: '/signin', params: { email } });
      }}
      onDismiss={dismiss}
      modalAppearanceEventMessage="Email exists sign in prompt modal"
    />
  );
};

export default ExistingEmailDialog;
