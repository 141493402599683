import React, { createContext, useContext } from 'react';

import { IBaseProps, SetState } from '@rbi-ctg/frontend';
import { NetworkConnectionError } from 'remote/exceptions';
import { INetworkStatus } from 'utils/network/status';

import useNetwork, { Query } from './hook';

export interface NetworkCtx {
  connection: INetworkStatus;
  hasNetworkError: boolean;
  hasNotAuthenticatedError: boolean;
  query: Query;
  setHasNetworkError: SetState<boolean>;
  setHasNotAuthenticatedError: SetState<boolean>;
  defaultErrorHandler(error: Error | NetworkConnectionError): void;
  sanityEndpoints: { graphql: string; groq: string; graphqlV2: string };
}

export const NetworkContext = createContext<NetworkCtx>({} as NetworkCtx);
export const useNetworkContext = () => useContext(NetworkContext);

export function NetworkProvider({ children }: IBaseProps) {
  const value = useNetwork();

  return <NetworkContext.Provider value={value}>{children}</NetworkContext.Provider>;
}

export default NetworkContext.Consumer;
