import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconInstagram = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 3.902c2.67 0 2.986.01 4.04.059.976.044 1.505.207 1.858.344a3.1 3.1 0 0 1 1.15.748c.35.35.566.683.748 1.15.137.352.3.882.344 1.857.048 1.054.058 1.37.058 4.04s-.01 2.987-.058 4.042c-.045.974-.207 1.504-.345 1.856-.18.467-.398.8-.748 1.15-.35.35-.683.567-1.15.748-.352.137-.881.3-1.856.345-1.055.048-1.37.058-4.041.058-2.67 0-2.987-.01-4.04-.058-.976-.045-1.505-.208-1.858-.345a3.097 3.097 0 0 1-1.15-.748 3.098 3.098 0 0 1-.748-1.15c-.137-.352-.3-.882-.344-1.856-.048-1.055-.058-1.371-.058-4.041 0-2.67.01-2.987.058-4.041.045-.975.207-1.505.344-1.857.182-.467.398-.8.748-1.15.35-.35.683-.567 1.15-.748.353-.137.882-.3 1.857-.344 1.055-.048 1.37-.059 4.041-.059Zm0-1.801c2.716 0 3.056.011 4.123.06 1.064.048 1.791.217 2.427.465a4.902 4.902 0 0 1 1.772 1.153 4.903 4.903 0 0 1 1.153 1.771c.247.636.416 1.363.465 2.428.048 1.066.06 1.407.06 4.123 0 2.715-.012 3.056-.06 4.123-.049 1.064-.218 1.79-.465 2.427a4.902 4.902 0 0 1-1.153 1.771 4.903 4.903 0 0 1-1.772 1.154c-.636.247-1.363.416-2.427.464-1.067.05-1.407.06-4.123.06s-3.056-.01-4.123-.06c-1.064-.048-1.791-.217-2.427-.464a4.901 4.901 0 0 1-1.772-1.154 4.903 4.903 0 0 1-1.153-1.771c-.247-.636-.416-1.363-.465-2.427C2.011 15.157 2 14.816 2 12.1c0-2.716.011-3.057.06-4.123.049-1.065.218-1.792.465-2.428A4.902 4.902 0 0 1 3.678 3.78 4.902 4.902 0 0 1 5.45 2.626c.636-.248 1.363-.417 2.427-.465C8.944 2.112 9.284 2.1 12 2.1Zm0 4.864a5.135 5.135 0 1 0 0 10.27 5.135 5.135 0 0 0 0-10.27Zm0 8.469a3.333 3.333 0 1 1 0-6.667 3.333 3.333 0 0 1 0 6.667Zm6.538-8.672a1.2 1.2 0 1 0-2.4 0 1.2 1.2 0 0 0 2.4 0Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
