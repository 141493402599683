import { Link, useLocalSearchParams, usePathname } from 'expo-router';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';

import {
  Button,
  IconChevronRight,
  IconDelivery,
  IconStore,
  Pressable,
  Text,
  XStack,
  YStack,
  tokens,
} from '@fhs/ui';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';
import { getServiceModeBottomCTAText } from 'pages/cart/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOrderContext } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { useUIContext } from 'state/ui';

export const SelectedStoreAndServiceModeButton = () => {
  const { serviceMode, isDelivery, deliveryFees } = useServiceModeContext();
  const { store } = useStoreContext();
  const { deliveryAddress } = useOrderContext();
  const { formatMessage } = useIntl();
  const serviceModeFormattedText = getServiceModeBottomCTAText(formatMessage)[serviceMode || ''];
  const isStoreSelected = Boolean(store._id);
  const pathname = usePathname();
  const params = useLocalSearchParams();
  const { formatCurrencyForLocale: currencyFormatter } = useUIContext();
  const enableDeliveryFeeOnServiceModeBar = Boolean(
    useFlag(LaunchDarklyFlag.SHOW_DELIVERY_FEE_ON_SERVICE_MODE_BAR)
  );

  if (!isStoreSelected) {
    return null;
  }

  const storeLocatorRoute = isDelivery ? routes.address : routes.serviceMode;
  const locationText = isDelivery ? deliveryAddress?.addressLine1 : store.name;

  const renderContent = () => {
    if (isDelivery) {
      return (
        <XStack style={menuBottomCtaStyles.wrapperDeliver}>
          <IconDelivery size={24} />
          <YStack>
            <XStack>
              <Text weight="semibold" style={menuBottomCtaStyles.serviceModeText}>
                {locationText}
              </Text>
            </XStack>
            {enableDeliveryFeeOnServiceModeBar && deliveryFees?.deliveryTotalFee ? (
              <XStack>
                <Text style={menuBottomCtaStyles.deliveryFeeText}>
                  {formatMessage(
                    { id: 'deliveryFeePerOrder' },
                    { deliveryFee: currencyFormatter(deliveryFees.deliveryTotalFee) }
                  )}
                </Text>
              </XStack>
            ) : null}
          </YStack>
        </XStack>
      );
    }

    return (
      <XStack style={menuBottomCtaStyles.wrapperPickup}>
        <IconStore size={24} />
        <YStack>
          <XStack>
            <Text weight="semibold" style={menuBottomCtaStyles.serviceModeText}>
              {serviceModeFormattedText}
            </Text>
            <Text style={menuBottomCtaStyles.selectedStoreText}>{store.name}</Text>
          </XStack>
        </YStack>
      </XStack>
    );
  };

  return (
    <Link
      href={{
        pathname: storeLocatorRoute,
        params: { back: pathname, ...params },
      }}
      asChild
    >
      <Pressable style={menuBottomCtaStyles.storeLocatorContainer}>
        {renderContent()}
        <Button aria-label="chevron right" type="ghost" size="xl">
          <Button.Icon>
            <IconChevronRight />
          </Button.Icon>
        </Button>
      </Pressable>
    </Link>
  );
};

const menuBottomCtaStyles = StyleSheet.create({
  storeLocatorContainer: {
    backgroundColor: tokens.colors.$houseLight,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  wrapperDeliver: {
    paddingVertical: 12,
    alignItems: 'center',
  },
  wrapperPickup: {
    alignItems: 'center',
  },
  serviceModeText: {
    fontSize: 14,
    lineHeight: 16,
    color: tokens.colors.$blackOpacity75,
    marginHorizontal: 12,
  },
  deliveryFeeText: {
    fontSize: 12,
    lineHeight: 16,
    marginTop: 4,
    color: tokens.colors.$blackOpacity75,
    marginHorizontal: 12,
  },
  deliveryFee: {
    fontSize: 14,
    color: tokens.colors.$blackOpacity75,
    marginHorizontal: 12,
  },
  selectedStoreText: {
    fontSize: 14,
    color: tokens.colors.$blackOpacity75,
  },
});
