import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconMap = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="m21.444 2.1-.177.034-5.934 2.3-6.666-2.333L2.4 4.21a.56.56 0 0 0-.4.534v16.8a.55.55 0 0 0 .556.556l.177-.034 5.934-2.3 6.666 2.334L21.6 19.99a.56.56 0 0 0 .4-.534v-16.8a.55.55 0 0 0-.556-.555ZM9.778 4.846l4.444 1.556v12.955l-4.444-1.555V4.845Zm-5.556 1.1 3.334-1.122v13l-3.334 1.289V5.945Zm15.556 12.311-3.334 1.122V6.39l3.334-1.29v13.156Z"
      />
    </Svg>
  );
};
