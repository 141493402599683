import { Stack } from 'expo-router';
import React from 'react';

import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';

import { getScreenTitleHeader } from '../../components/get-screen-title-header';

export default () => {
  const enablePypestream = useFlag(LaunchDarklyFlag.ENABLE_PYPESTREAM);
  return (
    <Stack
      screenOptions={{ header: getScreenTitleHeader({ backStyle: 'back' }), title: 'My Order' }}
    >
      <Stack.Screen
        name="support"
        options={{ title: enablePypestream ? 'Support' : 'How can we help you?' }}
      />
      <Stack.Screen name="order-confirmation/[rbiOrderId]" />
    </Stack>
  );
};
