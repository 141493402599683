import { useApolloClient } from '@apollo/client';
import { useCallback, useState } from 'react';

import { IOffersFragment, LoyaltyOfferType } from 'generated/graphql-gateway';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import logger from 'utils/logger';
import { useSanityGqlEndpoint } from 'utils/network';

import {
  IPersonalizedData,
  fetchPersonalizedOfferData as fetchData,
} from './utils/personalized-offers';

// @ts-expect-error TS(2322) FIXME: Type '() => [(offers: IOffersFragment[]) => Promis... Remove this comment to see the full error message
export const usePersonalizedOffers: () => [
  (offers: readonly IOffersFragment[]) => Promise<IPersonalizedData | null>,
  { loading: boolean }
] = () => {
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();
  const uri = useSanityGqlEndpoint();
  const personalizedOffersTemplateEnabled = Boolean(
    useFlag(LaunchDarklyFlag.ENABLE_PERSONALIZED_OFFERS_TEMPLATE)
  );

  const fetchPersonalizedOfferData = useCallback(
    async (offers: IOffersFragment[]) => {
      let data: {
        configMap: any;
        templateMap: any;
        placeholderFieldsMap: any;
      } | null = null;

      if (!personalizedOffersTemplateEnabled) {
        return data;
      }

      const configOffersIds: string[] = [];
      const templatesIds: string[] = [];

      offers.forEach(({ type, configId, templateId }) => {
        if (type === LoyaltyOfferType.PERSONALIZED && configId) {
          configOffersIds.push(configId);
          if (templateId) {
            templatesIds.push(templateId);
          }
        }
      });

      if (!configOffersIds.length && !templatesIds.length) {
        return data;
      }

      try {
        setLoading(true);
        data = await fetchData({ client, uri, templatesIds, configOffersIds });
      } catch (e) {
        logger.error({ error: e, message: 'Error fetching personalized offers data' });
      } finally {
        setLoading(false);
      }

      return data;
    },
    [setLoading, client, uri, personalizedOffersTemplateEnabled]
  );

  return [fetchPersonalizedOfferData, { loading }];
};
