import { FieldProps } from 'formik';
import React from 'react';
import { TouchableWithoutFeedback } from 'react-native';

import { Box, FormControl, Text } from '@fhs-legacy/universal-components';
import { TextInputProps } from 'components/ucl/text-input/types';
import { theme } from 'styles/configure-theme';

type FormikMaskInputType = {
  onPress: () => void;
  placeholder: string;
};

export const FormikMaskInput = React.forwardRef<
  HTMLInputElement,
  FieldProps<string, { [key: string]: string }> & TextInputProps & FormikMaskInputType
>(({ form, field, ...props }, _ref) => {
  const { errors, touched } = form;
  const { placeholder, value, label } = props;
  const errorMessage = errors?.[field.name];
  const fieldTouched = touched?.[field.name];
  const showErrors = fieldTouched && errorMessage;

  const handleOnPress = () => {
    form.setFieldTouched(field.name, true);
    props.onPress();
  };

  return (
    <TouchableWithoutFeedback onPress={handleOnPress}>
      <FormControl isInvalid={!!errorMessage} margin="0">
        <Box margin="2">
          {label && <FormControl.Label>{label}</FormControl.Label>}
          <Box
            borderWidth={1}
            borderColor={theme.token('border-color-input')}
            minHeight="$8"
            padding={'$2.5'}
            backgroundColor="white"
            borderRadius={4}
          >
            <Text>{value || placeholder}</Text>
          </Box>
          {showErrors && errorMessage && (
            <FormControl.ErrorMessage margin="0" p="0">
              {errorMessage}
            </FormControl.ErrorMessage>
          )}
        </Box>
      </FormControl>
    </TouchableWithoutFeedback>
  );
});
