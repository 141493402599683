import Link from 'components/link';

import theme from './theme';

const ChangeLink = Link.withConfig({
  color: theme.changeLinkColor,
  marginTop: '$2',
});

export default ChangeLink;
