import { IPrices } from '@rbi-ctg/menu';
import { PosVendors } from 'utils/vendor-config';

import type { ICmsOffer } from '../types';

export enum OfferValidatorErrorCode {
  REQUIRED_PROPERTIES_ERROR = 'REQUIRED_PROPERTIES_ERROR',
  PLU_AVAILABILITY_ERROR = 'PLU_AVAILABILITY_ERROR',
  OFFER_BENEFIT_ERROR = 'OFFER_BENEFIT_ERROR',
}
// the configured validations
export type OfferValidationKeys = 'requiredFields' | 'pluAvailability' | 'offerBenefitField';

export type OfferValidationError = { code: OfferValidatorErrorCode };
export interface IOfferValidatorContext {
  vendor: PosVendors | null;
  prices: IPrices;
}
export type OfferValidator = (cmsOffer: ICmsOffer, ctx: IOfferValidatorContext) => boolean;

export type OfferValidationRules = Record<OfferValidationKeys, OfferValidator>;

export type OfferValidationErrorsMap = Record<OfferValidationKeys, OfferValidationError>;
export type IOfferValidationResult =
  | {
      isValid: false;
      error: OfferValidationError;
    }
  | {
      isValid: true;
      error: null;
    };
