import { Redirect } from 'expo-router';
import { PropsWithChildren } from 'react';

import { NotFound as NotFoundUI } from '@fhs/ui';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import useEffectOnce from 'hooks/use-effect-once';
import { usePathname } from 'hooks/use-pathname';
import logger from 'utils/logger';

interface IRedirect {
  searchPattern: string[]; // Regex patterns used to track URLs
  to: string; // Final route we should sent the user
}

/**
 * New feature to identify by patterns if a URL non exists in the app
 * need to be redirected to a different section.
 */
const NotFound = ({ children }: PropsWithChildren) => {
  const pathname = usePathname();
  const message = `Not Found: ${pathname}`;
  useRumPageView('not-found', message);

  useEffectOnce(() => {
    /**
     * In the case of React Native, we navigate the user back to the home page
     * If we encounter an unexpected route which leads us to rendering the NotFound component
     */
    logger.warn(message);
  });

  const redirects: IRedirect[] = useFlag(LaunchDarklyFlag.REDIRECT_OLD_PAGES) || [];

  const redirectSearch = redirects.find(item =>
    item.searchPattern.some(pattern => pathname.match(new RegExp(pattern)))
  );

  if (redirectSearch) {
    return <Redirect href={redirectSearch.to} />;
  }

  return children || <NotFoundUI />;
};

export default NotFound;
