import { FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';

import { OptionalProps } from '@rbi-ctg/frontend';
import { DateInput, DateInputName, IDateInputProps } from 'components/ucl';

interface IFormikBirthdayInputProps {
  year: string;
  month: string;
  day: string;
  onChange?: (inputName: DateInputName, inputText: string) => void;
  onBlur?: (inputName: DateInputName) => void;
  dobTouched?: FormikTouched<{ year: string; month: string; day: string }> | undefined;
  dobErrors?: FormikErrors<{ year: string; month: string; day: string }> | undefined;
  isRequired?: boolean;
}

export const FormikBirthdayInput = ({
  year,
  month,
  day,
  onChange,
  onBlur,
  dobTouched,
  dobErrors,
  disabled,
  hintMessage,
  isRequired = false,
}: IFormikBirthdayInputProps &
  OptionalProps<
    IDateInputProps,
    | 'label'
    | 'labelYear'
    | 'labelYearFormat'
    | 'labelMonth'
    | 'labelMonthFormat'
    | 'labelDay'
    | 'labelDayFormat'
  >) => {
  const { formatMessage } = useIntl();

  const touched =
    typeof dobTouched === 'boolean'
      ? dobTouched
      : dobTouched?.year || dobTouched?.month || dobTouched?.day;

  const errors = dobErrors ? '' + dobErrors.year + dobErrors.month + dobErrors.day : undefined;
  const errorMessage = touched ? errors : undefined;

  return (
    <DateInput
      required={isRequired}
      testID="dateOfBirth"
      autoComplete="bday"
      disabled={disabled}
      label={formatMessage({ id: 'dateOfBirth' })}
      labelYear={formatMessage({ id: 'year' })}
      labelYearFormat={formatMessage({ id: 'yearPlaceholder' })}
      labelMonth={formatMessage({ id: 'month' })}
      labelMonthFormat={formatMessage({ id: 'monthPlaceholder' })}
      labelDay={formatMessage({ id: 'day' })}
      labelDayFormat={formatMessage({ id: 'dayPlaceholder' })}
      errorMessage={errorMessage}
      onBlur={onBlur}
      onChange={onChange}
      month={month}
      day={day}
      year={year}
      hintMessage={hintMessage}
    />
  );
};
