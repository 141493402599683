import { Box, Button, Header, Text } from '@fhs-legacy/universal-components';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import { theme } from 'styles/configure-theme';

import { POINTS_ICON_FILL_COLOR, TIER_DOT } from './constants';

export const AvailablePointsCaption = Box.withConfig({
  marginLeft: '$1',
});

export const AvailablePoints = Text.withConfig({
  color: Styles.color.black,
  fontSize: 'md',
});

export const BannerContainer = Box.withConfig({
  paddingX: '$4',
  backgroundColor: 'white',
});

export const CurrentProgress = Box.withConfig<{ $progress?: number }>(p => ({
  width: p.$progress ? `${p.$progress}%` : '0',
  borderRadius: 'md',
  height: '$0.5',
  backgroundColor: TIER_DOT.BG_COLOR,
}));

export const DashboardButton = Button.withConfig({
  height: '$10',
  width: 'auto',
  paddingLeft: '$7',
  paddingRight: '$7',
  ...(Styles.color.highlight && {
    backgroundColor: Styles.color.highlight,
    borderColor: Styles.color.highlight,
  }),
});

export const DashboardButtonText = Text.withConfig({
  fontFamily: 'alternative',
  fontSize: 'lg',
  lineHeight: '2xs',
  color: theme.token('text-button-primary'),
});

export const PointsContainer = Box.withConfig({});

export const PointsProgress = Box.withConfig({
  width: 'full',
  maxWidth: '310',
  height: '$0.5',
  borderRadius: 'sm',
  alignSelf: 'center',
  marginBottom: '$4',
  backgroundColor: Styles.color.grey.eight,
});

export const PointsProgressContainer = Box.withConfig({
  height: '$16',
  marginTop: '$5',
});

export const RewardContainer = Box.withConfig({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: 'full',
  paddingTop: '$4',
});

export const UserRewardPointsContainer = Box.withConfig({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
});

export const UserRewardPoints = Header.withConfig({
  marginLeft: '$2',
  fontSize: '4xl',
  lineHeight: '2xs',
});

export const StyledLoyaltyPointsIcon = LoyaltyPointsIcon.withConfig({
  marginTop: '$0.5',
  width: '$7',
  height: '$7',
  fill: POINTS_ICON_FILL_COLOR,
});

export const TierDot = Box.withConfig<{ completed: boolean; left?: number }>(p => ({
  height: '$3',
  width: '$3',
  borderRadius: 'md',
  position: 'absolute',
  marginLeft: p.left ? `${p.left}px` : '0',
  marginTop: '-4px',
  backgroundColor: p.completed ? TIER_DOT.BG_COLOR : Styles.color.white,
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: p.completed ? TIER_DOT.BG_COLOR : TIER_DOT.SECONDARY_COLOR,
}));

export const TierDotValue = Text.withConfig<{ left?: number }>(p => ({
  height: '$4',
  width: '28px',
  position: 'absolute',
  marginLeft: p.left ? `${p.left}px` : '0',
  marginTop: '$2.5',
  fontSize: 'xs',
  fontFamily: 'body',
  color: Styles.color.black,
  fontWeight: 'bold',
}));
