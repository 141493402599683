import React from 'react';
import { useIntl } from 'react-intl';

import { CARD_ALIAS_TRANSLATION_ID } from 'utils/gift-card/constants';
import { routes } from 'utils/routing';

import { PrepaidTransferBalanceLink } from './styled';

function PrepaidTransferBalance() {
  const { formatMessage } = useIntl();
  const cardAlias = formatMessage({ id: CARD_ALIAS_TRANSLATION_ID });
  return (
    <PrepaidTransferBalanceLink
      testID="prepaid-transfer-balance"
      to={routes.cartPaymentTransferGiftCard}
    >
      {formatMessage({ id: 'transferBalanceFromAnotherCard' }, { cardAlias })}
    </PrepaidTransferBalanceLink>
  );
}
export default PrepaidTransferBalance;
