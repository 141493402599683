import { Box, Pressable } from '@fhs-legacy/universal-components';
import Picture from 'components/picture';

export const PressableWrapper = Pressable.withConfig({
  flexDirection: 'row',
  backgroundColor: Styles.color.white,
  maxHeight: 130,
  shadow: '4',
  borderRadius: 8,
});

export const ContentBox = Box.withConfig({
  flex: 1,
  alignItems: 'flex-start',
  padding: '$4',
});

export const Image = Picture.withConfig({
  width: '35%',
  height: 'full',
  borderRightRadius: 8,
});
