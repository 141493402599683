import { HStack, Pressable } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

import { BORDER_BOTTOM_VALUE } from './constants';

const listItemStyles = {
  flexGrow: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.token('background-pattern'),
  color: theme.token('text-default'),
  paddingY: 4,
  paddingX: 0,
  marginX: 4,
};

export const ListItemContent = HStack.withConfig({
  ...listItemStyles,
});

export const ListItemButton = Pressable.withConfig({
  ...listItemStyles,
  flexDirection: 'row',
  borderBottomColor: theme.token('border-color-default'),
  borderBottomWidth: BORDER_BOTTOM_VALUE,
  flexShrink: 1,
});

export const ProductControl = HStack.withConfig({
  alignItems: 'center',
  justifyContent: 'space-between',
  marginLeft: '$2',
});
