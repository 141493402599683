import { Text } from '@fhs-legacy/universal-components';

export const PriceAndCaloriesText = Text.withConfig({
  variant: 'copyTwo',
  margin: 0,
  padding: 0,
  fontSize: 'xs',
});

export const Price = Text.withConfig({
  variant: 'copyTwo',
  fontWeight: 'bold',
  marginLeft: 2,
  fontSize: 'xs',
});
