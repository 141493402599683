import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { Cognito } from 'utils/cognito';
import AuthStorage from 'utils/cognito/storage';
import { StorageKeys } from 'utils/local-storage';

interface AuthError extends Error {
  code: string | 'NetworkError';
}

let pendingCurrentSessionCall: Promise<CognitoUserSession> | null = null;

export const getCurrentSessionWrapper = () => {
  // de-dupe calls to cognito as it does not dedupe internally for errors
  if (pendingCurrentSessionCall) {
    return pendingCurrentSessionCall;
  }

  const userPool = Cognito.userPool();
  const cognitoUser = userPool.getCurrentUser();

  if (!cognitoUser) {
    return Promise.reject(null);
  }

  const result = new Promise<CognitoUserSession>((resolve, reject) => {
    cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
      if (err || !session) {
        return reject(err);
      }

      return resolve(session);
    });
  })
    // perform some retries at a delay to hopefully get a valid session
    // 2 calls in < 250ms seem to internally get debounced by cognito
    .catch((err: AuthError | null) => retryIfNetworkFailure(err, 250))
    .catch((err: AuthError | null) => retryIfNetworkFailure(err, 500))
    .catch((err: AuthError | null) => retryIfNetworkFailure(err, 1000))
    .catch((err: AuthError | null) => retryIfNetworkFailure(err, 2000))
    .finally(() => {
      pendingCurrentSessionCall = null;
    });

  pendingCurrentSessionCall = result;

  return result;
};

// cognito doesn't attempt to retry on network errors - network errors are a given in a mobile app
const retryIfNetworkFailure = (
  err: null | AuthError,
  delayMs: number
): Promise<CognitoUserSession> => {
  if (err?.code === 'NetworkError') {
    return new Promise<CognitoUserSession>((resolve, reject) => {
      setTimeout(() => {
        const userPool = Cognito.userPool();
        const cognitoUser = userPool.getCurrentUser();

        if (!cognitoUser) {
          return reject(null);
        }

        cognitoUser.getSession((sessionErr: Error | null, session: CognitoUserSession | null) => {
          if (sessionErr || !session) {
            return reject(sessionErr);
          }

          return resolve(session);
        });
      }, delayMs);
    });
  }

  // let all other errors pass though normally
  return Promise.reject(err);
};

// return the current user session
export const getCurrentSession = (): Promise<CognitoUserSession | null> =>
  getCurrentSessionWrapper().catch(() => {
    // if cognito failed to get a valid session
    // clear local storage to prevent a user with
    // no session from being "logged in" in app state
    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    AuthStorage.setItem(StorageKeys.USER_AUTH_TOKEN, null);
    return null;
  });
