import { Image } from 'expo-image';
import { Link } from 'expo-router';
import { StyleSheet } from 'react-native';

export function DonateTodayCard({ donateHref }: { donateHref: string }) {
  return (
    <Link href={donateHref} target="_blank" style={styles.card}>
      <Image
        alt="Donate Today! Your medallion donation provides lifesaving equipment for first responders."
        source={require('./donate-today-card-banner.webp')}
        placeholder={{ thumbhash: 'cxgKD4T3iJeIeHaNdjW3uHiJQ6BZB40H' }}
        style={styles.image}
      />
    </Link>
  );
}

const styles = StyleSheet.create({
  card: {
    aspectRatio: 1354 / 1270,
  },
  image: { width: '100%', height: '100%' },
});
