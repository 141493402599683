import React, { FC } from 'react';

import { HStack, Text } from '@fhs-legacy/universal-components';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import { VisuallyHidden } from 'components/ucl/visually-hidden';

import RedeemRewardActionButton from './redeem-reward-action';
import { IRedeemRewardViewProps, RewardActions } from './types';

export const RedeemRewardView: FC<React.PropsWithChildren<IRedeemRewardViewProps>> = ({
  handleRewardClick,
  points,
  rewardTitle,
  isRewardApplied,
  disableApplyButton,
  pointsApplied,
  incentiveNotInMenu,
  redeemRewardLoading,
  displayApplyButton,
}) => {
  const displayActionButtons = !incentiveNotInMenu;

  const renderRewardActionButtons = () => (
    <HStack flexDirection="column">
      {displayApplyButton && (
        <HStack marginBottom={4}>
          <RedeemRewardActionButton
            disabled={disableApplyButton || redeemRewardLoading}
            action={RewardActions.ADD}
            handleRewardClick={handleRewardClick}
            points={points}
            loading={redeemRewardLoading}
          />
        </HStack>
      )}
      {isRewardApplied && (
        <RedeemRewardActionButton
          disabled={!isRewardApplied || redeemRewardLoading}
          action={RewardActions.REMOVE}
          handleRewardClick={handleRewardClick}
          points={points}
          loading={redeemRewardLoading}
        />
      )}
    </HStack>
  );

  return (
    <>
      <HStack justifyContent="space-between" alignItems="flex-start" marginY={4}>
        {displayActionButtons && renderRewardActionButtons()}
        {!!pointsApplied && (
          <HStack testID="points-applied" alignItems="center" marginTop={3}>
            <LoyaltyPointsIcon width={18} height={18} />

            <Text fontWeight="bold" fontSize="sm" marginLeft={1}>
              -{pointsApplied}
            </Text>
          </HStack>
        )}
      </HStack>
      <VisuallyHidden accessibilityLabel={rewardTitle} />
    </>
  );
};
