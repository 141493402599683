import { forwardRef } from 'react';
import { View } from 'react-native';

import { Button, ButtonProps, IconStarRateFill } from '@fhs/ui';
import { useLoyaltyUser } from '@fhs-legacy/frontend/src/state/loyalty/hooks/use-loyalty-user';

export type PointsButtonProps = Omit<ButtonProps, 'children'>;

export const PointsButton = forwardRef<View, PointsButtonProps>(function PointsButton(
  props: PointsButtonProps,
  forwardedRef
) {
  const { loading, loyaltyUser } = useLoyaltyUser();
  const showLoading = loading || !loyaltyUser;
  return (
    <Button
      ref={forwardedRef}
      loading={showLoading}
      type="outline"
      aria-label="Go to points redemption"
      size="sm"
      {...props}
      testID="app-header-points-button"
    >
      {showLoading ? (
        <Button.Text>Points</Button.Text>
      ) : (
        <>
          <Button.Icon>
            <IconStarRateFill />
          </Button.Icon>

          <Button.Text>{loyaltyUser?.points}</Button.Text>
        </>
      )}
    </Button>
  );
});
