import { useField } from 'formik';

import { addWithConfig } from '../../utils';
import { usePropsWithFormikFormControlName } from '../formik-form-control';
import { Input } from '../input';

import type { FormikInputProps } from './types';

export const FormikInput = (props: FormikInputProps) => {
  const { name, inputProps, ...rest } = usePropsWithFormikFormControlName(props);
  const [field, meta, helpers] = useField(name);
  const isInvalid = Boolean(meta.error && meta.touched);
  return (
    <Input
      isInvalid={Boolean(isInvalid)}
      value={field.value}
      {...inputProps}
      {...rest}
      onChangeText={(nextText) => {
        helpers.setValue(nextText);
      }}
      onBlur={() => {
        helpers.setTouched(true);
      }}
    />
  );
};

export default addWithConfig(FormikInput);
