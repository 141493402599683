import { Stack } from 'expo-router';

import NotFound from '@fhs-legacy/frontend/src/pages/not-found';

import { AppHeader } from '../components/app-header';

export default () => (
  <>
    <Stack.Screen options={{ header: () => <AppHeader /> }} />
    <NotFound />
  </>
);
