import { FhsHeartSvg } from 'components/svgs/fhs-heart';
import { FhsLocationSvg } from 'components/svgs/fhs-location';
import { FhsStoreSvg } from 'components/svgs/fhs-store';

export const DEFAULT_ICON = FhsLocationSvg;
export const NEARBY_ERROR_ICON = FhsLocationSvg;
export const NEARBY_NO_STORES_ICON = FhsLocationSvg;
export const NEARBY_NO_LOCATION_ICON = FhsLocationSvg;
export const FAVORITES_ERROR_ICON = FhsHeartSvg;
export const FAVORITES_NO_AUTH_ICON = FhsHeartSvg;
export const FAVORITES_NO_STORES_ICON = FhsHeartSvg;
export const RECENT_ERROR_ICON = FhsStoreSvg;
export const RECENT_NO_AUTH_ICON = FhsStoreSvg;
export const RECENT_NO_STORES_ICON = FhsStoreSvg;
