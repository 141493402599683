import { router } from 'expo-router';
import { useIntl } from 'react-intl';
import { Linking } from 'react-native';

import { Box, Text } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import useDialogModal from 'hooks/use-dialog-modal';
import { isIOS, isWeb } from 'utils/environment';

import { EnablePreciseLocation } from './location-permissions-alert-icons/enable-precise-location';
import { SelectLocation } from './location-permissions-alert-icons/select-location';
import { SelectPermissions } from './location-permissions-alert-icons/select-permissions';
import { TapWhileUsing } from './location-permissions-alert-icons/tap-while-using';

export const useLocationPermissionsAlert = () => {
  const { formatMessage } = useIntl();
  const [AlertDialog, openAlert, , dismissDialog] = useDialogModal({
    modalAppearanceEventMessage: 'Alert: Enable Location Services',
  });

  const handleConfirmPress = () => {
    dismissDialog();
    if (isWeb) {
      router.push('/learn-more-location');
    } else {
      Linking.openSettings();
    }
  };

  const settingsSteps = isIOS()
    ? [
        [<SelectLocation />, `1. ${formatMessage({ id: 'selectLocation' })}`],
        [<TapWhileUsing />, `2. ${formatMessage({ id: 'tapAlwaysOrWileUsing' })}`],
      ]
    : [
        [<SelectPermissions />, `1. ${formatMessage({ id: 'selectPermissions' })}`],
        [<SelectLocation />, `2. ${formatMessage({ id: 'selectLocation' })}`],
        [<TapWhileUsing />, `3. ${formatMessage({ id: 'tapAllowWhileUsing' })}`],
        [<EnablePreciseLocation />, `4. ${formatMessage({ id: 'enablePreciseLocation' })}`],
      ];

  const alertHeading = isWeb
    ? formatMessage({ id: 'weNeedPermissionToUseYourLocation' })
    : formatMessage({ id: 'pleaseTurnOnYourLocation' });

  const alertContent = (
    <Box mb="$4">
      {isWeb ? (
        formatMessage({ id: 'selectLearnMore' })
      ) : (
        <Box display="flex" flexDirection="column" gap="$3">
          {settingsSteps.map((step, i) => (
            <Box key={i} display="flex" flexDirection="row" gap="$2" alignItems="center">
              {step[0]}
              <Text fontSize="sm">{step[1]}</Text>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );

  const alertConfirm = isWeb
    ? formatMessage({ id: 'learnMore' })
    : formatMessage({ id: 'goToSettings' });

  const alert = (
    <AlertDialog
      heading={alertHeading}
      bodyComponent={alertContent}
      buttonLabel={formatMessage({ id: 'close' })}
      dialogContentProps={{ maxW: 340 }}
      actions={
        <Box width="full" display="flex" flexDirection="column" gap="$2">
          <ActionButton fullWidth onPress={handleConfirmPress}>
            {alertConfirm}
          </ActionButton>
          <ActionButton fullWidth onPress={dismissDialog} variant={ActionButtonVariants.OUTLINE}>
            {formatMessage({ id: 'close' })}
          </ActionButton>
        </Box>
      }
    />
  );

  return {
    alert,
    openAlert,
  };
};
