import {
  IBackendCartEntries,
  ICartEntry,
  IItem,
  IItemOptionModifier,
  IMainMenuViewOption,
  IWithPricingProps,
  ServiceMode,
} from '@rbi-ctg/menu';
import { IDayPartBoundary } from 'state/day-part/hooks/use-active-day-parts';
import { DAY_PART_SELECTIONS } from 'state/main-menu/types';
import { CartEntryType } from 'utils/cart';

export interface IComputeItemOptionModifierPlu extends IWithPricingProps {
  item: IBackendCartEntries | ICartEntry | IItem;
  modifier: IBackendCartEntries | ICartEntry | (IItemOptionModifier & { quantity?: number });
}

export type CartEntryObject = {
  children?: CartEntryObject[];
  price?: number;
  quantity?: number;
  type?: CartEntryType;
};

export interface IGetTabbedMenuData {
  mainMenuItems: IMainMenuViewOption[];
  dayPartMenu?: DAY_PART_SELECTIONS | null;
  activeDayParts?: IDayPartBoundary[];
  showBurgersForBreakfast?: boolean;
  serviceMode: ServiceMode | null;
  showStaticMenu: boolean;
}
