import React from 'react';

import { HStack, Text } from '@fhs-legacy/universal-components';
import { IBaseProps } from '@rbi-ctg/frontend';
import { INutritionInfo } from '@rbi-ctg/menu';

import { Nutrient } from '../item-nutrition/item-nutrition.styled';

import { SanityNutritionItem } from './types';

type NutritionItemProps = IBaseProps & {
  nutritionItem: SanityNutritionItem;
  nutritionInfo: INutritionInfo;
  roundNutritionValue: (val: number) => number;
  isNested?: boolean;
};

export const NutritionItem: React.FC<React.PropsWithChildren<NutritionItemProps>> = ({
  nutritionInfo,
  nutritionItem,
  roundNutritionValue,
  isNested = false,
  ...props
}) => {
  const primaryItemValue = nutritionInfo[nutritionItem.nutritionKey ?? ''];
  const primaryItemUnit = nutritionItem.unit?.locale;
  const primaryItemName = nutritionItem.name?.locale;
  if (primaryItemValue === undefined || !primaryItemUnit || !primaryItemName) {
    return null;
  }

  const roundedPrimaryItemValue = roundNutritionValue(primaryItemValue);

  const firstAdditionalItem = nutritionItem.additionalItems?.[0];
  const additionalItemUnit = firstAdditionalItem?.unit?.locale;
  const additionalItemValue = nutritionInfo[firstAdditionalItem?.nutritionKey ?? ''];

  const roundedAdditionalItemValue =
    additionalItemValue !== undefined ? roundNutritionValue(additionalItemValue) : undefined;

  const primaryItemNutritionValue = `${roundedPrimaryItemValue} ${primaryItemUnit}`;
  const additionalItemNutritionValue = ` / ${roundedAdditionalItemValue} ${additionalItemUnit}`;

  return (
    <Nutrient {...props}>
      <Text variant="copyOne" textTransform="capitalize" marginLeft={isNested ? 3 : 0}>
        {primaryItemName}
      </Text>
      <HStack>
        <Text testID="primary-item-nutrition-value">{primaryItemNutritionValue}</Text>
        {firstAdditionalItem && (
          <Text testID="additional-item-nutrition-value">{additionalItemNutritionValue}</Text>
        )}
      </HStack>
    </Nutrient>
  );
};
