import React from 'react';

import { Center } from '@fhs-legacy/universal-components';

import Icon from './icon';

interface IBrandIcon {
  width?: string;
  color?: string;
}

const BrandIcon = ({ color, width }: IBrandIcon) => {
  const fillColor = color ? color : Styles.color.grey.three;
  const fullWidth = width ? width : '$40';
  return (
    <Center>
      <Icon width={fullWidth} color={fillColor} />
    </Center>
  );
};

export default BrandIcon;
