import { IComboSlot, IComboSlotSelection, IComboSlotSelections } from '@rbi-ctg/menu';

/*returns comboSlot selection with metadata describing how many of the selected items are
to be included in the combo and what is to be charged as a separate item*/
export function assignInclusionComboSlotSelections(
  selections: IComboSlotSelection[],
  maxAmount: number
) {
  //defensive promgramming incase any null selections slip in
  if (!selections) {
    return [];
  }

  let counter = 0;

  return selections.map(sel => {
    counter += sel.quantity;
    if (counter > maxAmount) {
      if (counter - sel.quantity < maxAmount) {
        return { ...sel, notIncluded: counter - maxAmount };
      }
      return { ...sel, notIncluded: sel.quantity };
    }
    return { ...sel, notIncluded: 0 };
  });
}

export function partitionComboSlots(
  comboSlots: IComboSlot[],
  comboSlotSelections: IComboSlotSelections
) {
  return comboSlots.map(comboSlot => ({
    id: comboSlot._id,
    data: comboSlotSelections[comboSlot._id].data,
    selections:
      assignInclusionComboSlotSelections(
        comboSlotSelections[comboSlot._id].selections,
        comboSlot.maxAmount
      ) || [],
  }));
}
