import { useCallback } from 'react';

import { useBoxRef } from './use-box-ref';

export function useMergedEventHandlers<T extends Function>(
  eventHandlerA?: T | null,
  eventHandlerB?: T | null
) {
  const eventHandlerARef = useBoxRef(eventHandlerA);
  const eventHandlerBRef = useBoxRef(eventHandlerB);

  return useCallback(
    (...args: Array<unknown>) => {
      eventHandlerARef.current?.(...args);
      eventHandlerBRef.current?.(...args);
    },
    [eventHandlerARef, eventHandlerBRef]
  );
}
