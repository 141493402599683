import { useNavigation } from 'expo-router';
import React, { VFC, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { useIsDesktopBp } from 'hooks/breakpoints';

import { SHOW_DESKTOP_CUSTOMIZATION_BACK_ON_NAVBAR } from './constants';
import { BackButtonContainer } from './product-customization-back-button.styled';
import { IProductCustomizationBackButton } from './types';

export const ProductCustomizationBackButton: VFC<IProductCustomizationBackButton> = ({
  onBackButtonClick,
}) => {
  const { formatMessage } = useIntl();
  const isDesktop = useIsDesktopBp();
  const { addListener, removeListener } = useNavigation();

  const handleBack = useCallback(
    (event: { preventDefault: VoidFunction; data?: { action?: { type: string } } }) => {
      if (event?.data?.action?.type === 'GO_BACK') {
        event.preventDefault();
        onBackButtonClick?.();
      }
    },
    [onBackButtonClick]
  );

  useEffect(() => {
    addListener('beforeRemove', handleBack);
    return () => removeListener('beforeRemove', handleBack);
  }, [addListener, handleBack, removeListener]);

  return (
    <>
      {isDesktop && !SHOW_DESKTOP_CUSTOMIZATION_BACK_ON_NAVBAR ? (
        <BackButtonContainer>
          <ActionButton
            startIcon={<Icon variant="back" />}
            color="icon-header-contrast"
            variant={ActionButtonVariants.TEXT_ONLY}
            // @ts-expect-error TS(2322) FIXME: Type '{ children: string; startIcon: Element; colo... Remove this comment to see the full error message
            onClick={onBackButtonClick}
          >
            {formatMessage({ id: 'backToSummary' })}
          </ActionButton>
        </BackButtonContainer>
      ) : null}
    </>
  );
};
