import { IServerOrder } from '@rbi-ctg/menu';
import { DeliveryStatus } from 'generated/rbi-graphql';
import { OrderStatus } from 'state/order';

type OrderAndStatuses = 'orderStatus' | 'orderDeliveryStatus' | 'serverOrder';
export type OrderStatusesAndPrevious =
  | OrderAndStatuses
  | 'prevOrderStatus'
  | 'prevOrderDeliveryStatus';

interface IOrderStatusMap {
  catering?: OrderStatus[] | OrderStatus;
  delivery: DeliveryStatus[] | DeliveryStatus;
  pos: OrderStatus[] | OrderStatus;
}

export interface IUseOrderStatusParams {
  failureStatuses: IOrderStatusMap;
  onSuccess?(serverOrder: IServerOrder): Promise<void> | void;
  orderStatusPollInterval?: number;
  rbiOrderId?: null | string;
  successStatuses: IOrderStatusMap;
  skip?: boolean;
}

export interface IOnOrderStatusChangeOptions {
  failure: boolean;
  onSuccess(serverOrder: IServerOrder): Promise<void> | void;
  orderStatus: OrderStatus | null;
  orderDeliveryStatus: DeliveryStatus | null;
  prevOrderStatus?: OrderStatus | null;
  prevOrderDeliveryStatus?: DeliveryStatus | null;
  serverOrder: null | IServerOrder;
  success: boolean;
}

export interface IUseStoreOrderStatusParams {
  storeOrderJwt: string;
}
