export type FieldsAutocomplete = {
  year: 'birthdate-year';
  month: 'birthdate-month';
  day: 'birthdate-day';
};

export type DateInputName = 'year' | 'month' | 'day';

export type DateInputLabelProps = {
  label: string;
  required?: boolean;
};

export interface IDateInputProps {
  onChange?: (inputName: DateInputName, inputText: string) => void;
  onBlur?: (inputName: DateInputName) => void;
  month: string;
  day: string;
  year: string;
  errorMessage?: string;
  hintMessage?: string;
  disabled?: boolean;
  required?: boolean;
  autoComplete?: 'bday';
  testID?: string;

  /** Label for the entire field, e.g., "Date of Birth" */
  label: string;
  /** Label for the year input, e.g., "Year" */
  labelYear: string;
  /** Label for the month input, e.g., "Month" */
  labelMonth: string;
  /** Label for the day input, e.g., "Day" */
  labelDay: string;
  /** Label for the year format, e.g., "YYYY" */
  labelYearFormat: string;
  /** Label for the month format, e.g., "MM" */
  labelMonthFormat: string;
  /** Label for the day format, e.g., "DD" */
  labelDayFormat: string;
}
