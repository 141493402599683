import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, IconClose, IconStarRateFill, Text, XStack, tokens } from '@fhs/ui';

import { removePointUsageFromItem, usePointsOnItem } from '../../api';
import { Badge } from '../cart-offer-item/badge';

export function PointsBar({ lineId, points }: { lineId: string; points: number }) {
  const [pointsAreBeingUsed, set] = useState(false); // this is a hack for now
  const { mutate: removePoints, isPending: isPendingRemoval } = useMutation({
    ...removePointUsageFromItem,
    onSettled() {
      set(false);
    },
  });

  const { mutate: utilizePoints, isPending: isPendingAdd } = useMutation({
    ...usePointsOnItem,
    onSettled() {
      set(true);
    },
  });

  const isPending = isPendingRemoval || isPendingAdd;

  if (pointsAreBeingUsed) {
    return (
      <XStack style={[styles.redeemContainer, styles.active]}>
        <Badge>Reward</Badge>
        <View style={styles.spacer} />
        <View
          style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
        >
          <Button.Icon color={tokens.colors.$houseYellow}>
            <IconStarRateFill />
          </Button.Icon>
          <Text.Ui size="sm">Using </Text.Ui>
          <Text.Ui size="sm" weight="bold">
            {points.toLocaleString()}{' '}
          </Text.Ui>
          <Text.Ui size="sm">Points</Text.Ui>
        </View>
        <View style={styles.spacer} />
        <Button
          type="outline"
          loading={isPending}
          disabled={isPending}
          onPress={() => removePoints({ lineId })}
        >
          {!isPending && (
            <Button.Icon>
              <IconClose />
            </Button.Icon>
          )}
        </Button>
      </XStack>
    );
  }

  return (
    <XStack style={styles.redeemContainer}>
      <Text.Ui size="sm" style={{ flex: 1 }}>
        Redeem as a Reward:
      </Text.Ui>

      <Button
        size="xs"
        type="outline"
        style={styles.pointsButton}
        loading={isPending}
        disabled={isPending}
        onPress={() => utilizePoints({ lineId })}
      >
        {!isPending && (
          <Button.Icon color={tokens.colors.$houseYellow}>
            <IconStarRateFill />
          </Button.Icon>
        )}
        <Button.Text style={styles.pointsButtonText}>Use {points.toLocaleString()}</Button.Text>
      </Button>
    </XStack>
  );
}
const styles = StyleSheet.create({
  spacer: {
    width: 1,
    height: '30%',
    backgroundColor: tokens.colors.$blackOpacity10,
    marginHorizontal: 16,
  },
  active: {
    backgroundColor: 'rgba(56, 127, 73, 0.04)',
  },
  redeemContainer: {
    paddingHorizontal: 16,
    margin: 16,
    height: 52,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: tokens.colors.$blackOpacity04,
  },
  pointsButton: {
    borderColor: tokens.colors.$black10,
    padding: 8,
  },
  pointsButtonText: {
    color: tokens.colors.$black,
  },
});
