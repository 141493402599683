import { Stack, router, useLocalSearchParams } from 'expo-router';
import Head from 'expo-router/head';
import * as React from 'react';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { useSupportData } from 'hooks/use-support-data';
import { InnerBox } from 'pages/support-v2/support-form-v2.styled';

import { routes } from '../../../utils/routing';
import { ErrorView } from '../error-view';
import { LoadingView } from '../loading-view';
import { ISupportPageV2Props } from '../types';

import { SupportV2CategoryView } from './support-v2-category-view';
import { ISubcategoryContent } from './types';

/**
 * Displays list of subcategories which link to the support form, or orders list, or customer initiated refund pages
 */
const SupportV2CategoryPage: FC<React.PropsWithChildren<unknown>> = () => {
  const params = useLocalSearchParams<ISupportPageV2Props>();
  const { categoryId, orderId } = params;
  const { formatMessage } = useIntl();
  const { supportCategoryGroups, isSupportDataLoading } = useSupportData();

  if (isSupportDataLoading) {
    return <LoadingView />;
  }

  const categoryGroup = supportCategoryGroups?.find(group => group && group._id === categoryId);

  const categoryName = categoryGroup?.name?.locale ?? '';

  if (!supportCategoryGroups || !categoryGroup) {
    return <ErrorView message={formatMessage({ id: 'looksLikeWereExperiencingIssues' })} />;
  }

  const pageTitleText = formatMessage(
    { id: 'supportSubcategoryTitle' },
    {
      category: categoryName,
    }
  );

  const subcategories =
    categoryGroup.supportSubcategories?.map(subcat => ({
      _id: subcat?._id ?? '',
      name: subcat?.name?.locale ?? '',
      supportSubcategoryForwardToOrders: subcat?.supportSubcategoryForwardToOrders || false,
    })) || [];

  const onSelectSubcategory = (subcategory: ISubcategoryContent) => {
    const routeParams = {
      categoryId,
      subcategoryId: subcategory._id,
      ...(orderId && { orderId }),
    };

    if (subcategory.supportSubcategoryForwardToOrders) {
      return router.navigate({ pathname: routes.supportOrders, params: routeParams });
    }

    return router.navigate({ pathname: routes.supportForm, params: routeParams });
  };

  return (
    <InnerBox>
      <Stack.Screen options={{ title: pageTitleText }} />
      <Head>
        <title>{`${pageTitleText} - Firehouse Subs`}</title>
      </Head>
      <SupportV2CategoryView
        pageTitle={pageTitleText}
        subcategories={subcategories}
        onSelectSubcategory={onSelectSubcategory}
      />
    </InnerBox>
  );
};

export default SupportV2CategoryPage;
