export default {
  addressColor: Styles.color.black,
  ammenityIconFill: Styles.color.black,
  innerAnchorColor: Styles.color.black,
  innerColor: Styles.color.black,
  innerPColor: Styles.color.black,
  innerLineHeight: 'xs',
  modalBackground: Styles.color.white,
  phoneNumberColor: Styles.color.black,
};
