import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, Header, Icon, ListItemCheckboxGroup } from '@fhs-legacy/universal-components';
import { ICartEntry, IItem } from '@rbi-ctg/menu';
import SanityBlockRenderer from 'components/sanity-block-renderer';
import usePosVendor from 'hooks/menu/use-pos-vendor';
import useFoodware from 'hooks/use-foodware';
import { useCartContext } from 'state/cart';
import { useMenuContext } from 'state/menu';
import { useStoreContext } from 'state/store';
import { itemIsAvailable } from 'utils/availability';
import { createCartEntry } from 'utils/cart';

import { Container, DescriptionContainer } from './styled';

/**
 *
 * Foodware
 *
 */
const Foodware: FC = () => {
  const { cartEntries, addItemToCart, removeFromCart } = useCartContext();
  const { pricingFunction } = useMenuContext();
  const { featureFoodware, loading } = useFoodware();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const { prices } = useStoreContext();
  const { vendor } = usePosVendor();
  const { formatMessage } = useIntl();

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleChange = (nextValues: any) => {
    // Find foodware items added
    const addItem = nextValues.find((value: string) => !selectedItems.includes(value));
    // Find foodware items removed
    const removeItem = selectedItems.find((value: string) => !nextValues.includes(value));
    if (addItem) {
      // Convert added sanity item to cart item
      const item = featureFoodware?.items?.find(item => item?._id === addItem) as IItem;
      if (item) {
        const cartEntry: ICartEntry = {
          ...createCartEntry({
            item,
            price: pricingFunction(item),
          }),
          isExtra: true,
        };
        // Add item to the cart
        addItemToCart(cartEntry);
      }
    }
    if (removeItem) {
      // Find cart entry
      const cartEntry = cartEntries.find((cartEntry: any) => cartEntry._id === removeItem);
      if (cartEntry) {
        // Remove item from the cart
        removeFromCart({ cartId: cartEntry.cartId });
      }
    }
    setSelectedItems(nextValues);
  };

  const listItems: any =
    featureFoodware?.items?.map(item => {
      //TODO: we should migrate this to the new way to check availability getPricingAndAvailability but so far this kind of item is not supported by menu service
      let isAvailable: boolean = false;
      if (!!item && !!vendor && !!prices) {
        // @ts-ignore
        isAvailable = itemIsAvailable(item, vendor, prices);
      }
      return {
        value: item?._id || '',
        label:
          (item?.name?.locale || '') +
          (isAvailable ? '' : ` (${formatMessage({ id: 'unavailable' })})`),
        accessibilityLabel: item?.name?.locale || '',
        disabled: !isAvailable,
      };
    }) || [];

  useEffect(() => {
    // Check for foodware items in the cart
    const initSelectedItems: string[] = [];
    cartEntries?.forEach(cartEntry => {
      if (featureFoodware?.items?.find(item => item?._id === cartEntry._id)) {
        initSelectedItems.push(cartEntry._id);
      }
    });
    // Select foodware items that are already in the cart
    if (initSelectedItems.length) {
      setIsExpanded(true);
      setSelectedItems(initSelectedItems);
    }
  }, [cartEntries, featureFoodware?.items]);

  if (loading) {
    return null;
  }

  return (
    <Container>
      <Header variant="headerTwo">{featureFoodware?.title?.locale}</Header>
      <DescriptionContainer onPress={handleToggle}>
        <SanityBlockRenderer maxWidth="90%" content={featureFoodware?.description?.localeRaw} />
        <Box>
          <Icon variant={isExpanded ? 'collapse' : 'select'} alignSelf={'flex-end'} />
        </Box>
      </DescriptionContainer>
      {isExpanded && (
        <ListItemCheckboxGroup
          value={selectedItems}
          onChange={handleChange}
          listItems={listItems}
        />
      )}
    </Container>
  );
};

export default Foodware;
