import { Stack } from 'expo-router';
import Head from 'expo-router/head';
import { useIntl } from 'react-intl';

import { getScreenTitleHeader } from '../../components/get-screen-title-header';
export const unstable_settings = {
  initialRouteName: 'service-mode',
};

export default function StoreLocatorLayout() {
  const { formatMessage } = useIntl();

  return (
    <>
      <Head>
        <title>{`${formatMessage({ id: 'storeLocator' })} - Firehouse Subs`}</title>
      </Head>
      <Stack initialRouteName="service-mode">
        <Stack.Screen
          name="store/[idOrNumber]/index"
          options={{
            header: getScreenTitleHeader({
              backStyle: 'close',
              cannotGoBackHref: '/store-locator/service-mode',
            }),
            presentation: 'fullScreenModal',
            title: 'Store Details',
          }}
        />
        <Stack.Screen
          name="store/[idOrNumber]/[slug]"
          options={{
            header: getScreenTitleHeader({
              backStyle: 'close',
              cannotGoBackHref: '/store-locator/service-mode',
            }),
            presentation: 'fullScreenModal',
            title: 'Store Details',
          }}
        />
        <Stack.Screen
          name="service-mode"
          options={{
            presentation: 'card',
          }}
        />
        <Stack.Screen
          name="address"
          options={{
            header: getScreenTitleHeader({
              backStyle: 'close',
              cannotGoBackHref: '/store-locator/service-mode',
            }),
            title: 'Delivery Address',
          }}
        />
      </Stack>
    </>
  );
}
