import { router } from 'expo-router';
import { keyBy } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { ListRenderItem, Platform, RefreshControl } from 'react-native';

import { View } from '@fhs-legacy/universal-components';
import { AnimatedFlatList } from 'components/animated-flat-list';
import { RefreshControl as CustomRefreshControl } from 'components/refresh-control';
import { useToast } from 'hooks/use-toast';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useAppliedOffers } from 'state/loyalty/offers/hooks/use-applied-offers';
import { useRemoveOffer } from 'state/loyalty/offers/hooks/use-remove-offer';
import { IEngineOffer, ILoyaltyOffer } from 'state/loyalty/offers/types';
import { useLoyaltyOfferList } from 'state/loyalty/offers/use-loyalty-offer-list';
import { isCmsConfigOffer, isPromoCodeOffer } from 'state/loyalty/offers/utils';
import type { LoyaltyOffer } from 'state/loyalty/types';
import logger from 'utils/logger';
import { routes } from 'utils/routing';

import { AnimatedOfferItem } from '../loyalty-incentives-components/animated-offer-item';

import { ENABLE_PULL_TO_REFRESH } from './constants';
import EndOfOffers from './end-of-offers/loyalty-end-of-offers';
import { LoyaltyOfferListItem } from './loyalty-offer-list-item';
import { LoyaltyOffersFilterButtons } from './loyalty-offers-filters';
import { LoyaltyOffersInCartList } from './loyalty-offers-in-cart-list';
import { LoyaltyIncentivesContainer, StyledLoyaltyLoader } from './loyalty-offers.styled';
import { LoyaltyPromoCode } from './loyalty-promo-code';
import type { IOffersFilters } from './types';
import { useLoyaltyOffersFilters } from './use-loyalty-offers-filters';

const shouldFilterOffer = (offer: ILoyaltyOffer, appliedFilters: IOffersFilters) => {
  const { pickUpOnly, deliveryOnly, inStoreEnabled } = appliedFilters;

  return (
    ((pickUpOnly || deliveryOnly) && !appliedFilters[offer.serviceMode || '']) ||
    (inStoreEnabled && offer?.mobileOrderOnly)
  );
};

export const LoyaltyOfferList = () => {
  // LD flags
  const enableLoyaltyPromoCodes = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_PROMO_CODES);
  const enableLoyaltyOffersFilters = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_OFFERS_FILTERS);

  const skipAnimation = useRef(true);
  const { formatMessage } = useIntl();
  const toast = useToast();
  const { addOfferToList, offers, loading, refetch } = useLoyaltyOfferList();
  const { removeOffer } = useRemoveOffer();
  const { logRBIEvent } = useCRMEventsContext();

  const { appliedFilters, toggleFilter } = useLoyaltyOffersFilters();
  const appliedOffers = useAppliedOffers();

  const { offersInCart, filteredOffers } = useMemo(() => {
    const appliedOffersMap = keyBy(appliedOffers, 'id');
    const offersInCartAccum: ILoyaltyOffer[] = [];
    const filteredOffersAccum: ILoyaltyOffer[] = [];

    offers.forEach(offer => {
      // not filtering offers in cart
      if (appliedOffersMap[offer.loyaltyEngineId]) {
        offersInCartAccum.push(offer);
      } else if (!shouldFilterOffer(offer, appliedFilters)) {
        filteredOffersAccum.push(offer);
      }
    });

    return { offersInCart: offersInCartAccum, filteredOffers: filteredOffersAccum };
  }, [appliedFilters, appliedOffers, offers]);

  const handlePress = useCallback(
    (offer: ILoyaltyOffer) => {
      const { _id: sanityId, name, loyaltyEngineId: engineId } = offer;
      logRBIEvent({
        name: CustomEventNames.OFFER_SELECTED,
        type: EventTypes.Other,
        attributes: { engineId, sanityId, name },
      });

      router.navigate(`${routes.loyaltyOfferList}/${engineId}`);
    },
    [logRBIEvent]
  );

  const handleRemove = useCallback(
    (offer: ILoyaltyOffer) => {
      const { offerRemoved } = removeOffer(offer);

      if (offerRemoved) {
        toast.show({
          text: formatMessage({ id: 'offerAppliedSuccess' }),
          variant: 'positive',
        });
      }
    },
    [formatMessage, removeOffer, toast]
  );

  const onPromoCodeRedeemSuccess = ({
    configOffer: cmsConfigOffer,
    engineOffer,
  }: {
    configOffer: LoyaltyOffer;
    engineOffer: IEngineOffer;
  }) => {
    // only cms config offers are allowed to continue with the flow.
    // this is because promo code offers are handled like personalized offers.
    if (!isCmsConfigOffer(cmsConfigOffer)) {
      logger.error(`invalid cms promo code offer id: ${cmsConfigOffer?._id}`);
      return;
    }

    skipAnimation.current = false;
    addOfferToList({ engineOffer, configOffer: cmsConfigOffer });

    toast.show({
      text: formatMessage({ id: 'promoCodeAddedToOffers' }),
      variant: 'positive',
    });
  };

  if (loading && offers.length === 0) {
    return <StyledLoyaltyLoader />;
  }

  const renderItem: ListRenderItem<ILoyaltyOffer> = ({ item: offer }: { item: ILoyaltyOffer }) => {
    const isPromoCode = isPromoCodeOffer(offer);
    const renderOfferItem = () => <LoyaltyOfferListItem onPress={handlePress} offer={offer} />;

    // Animate only if skipAnimation is disabled and offer source is Promo Code campaign
    return !skipAnimation.current && isPromoCode ? (
      <AnimatedOfferItem setSkipAnimation={(skip: boolean) => (skipAnimation.current = skip)}>
        {renderOfferItem()}
      </AnimatedOfferItem>
    ) : (
      renderOfferItem()
    );
  };

  const refreshControl = Platform.select({
    ios: <CustomRefreshControl refreshing={loading} onRefresh={refetch} />,
    android: <RefreshControl refreshing={loading} onRefresh={refetch} />,
  });

  return (
    <LoyaltyIncentivesContainer
      accessible
      accessibilityRole="header"
      accessibilityLabel={formatMessage({ id: 'offers' })}
    >
      {enableLoyaltyPromoCodes && (
        <LoyaltyPromoCode onPromoCodeRedeemSuccess={onPromoCodeRedeemSuccess} />
      )}
      {enableLoyaltyOffersFilters && (
        <LoyaltyOffersFilterButtons appliedFilters={appliedFilters} toggleFilter={toggleFilter} />
      )}
      <View flex={1} mx="$4">
        <AnimatedFlatList
          ListHeaderComponent={
            <>
              {offersInCart?.length ? (
                <LoyaltyOffersInCartList
                  offers={offersInCart}
                  onPress={handlePress}
                  onPressRemove={handleRemove}
                />
              ) : null}
            </>
          }
          ListFooterComponent={filteredOffers?.length ? <EndOfOffers /> : null}
          {...(ENABLE_PULL_TO_REFRESH ? { refreshControl } : {})}
          data={filteredOffers}
          initialNumToRender={5}
          keyExtractor={(item, index) => item.loyaltyEngineId || `${index}`}
          renderItem={renderItem}
        />
      </View>
    </LoyaltyIncentivesContainer>
  );
};
