import delve from 'dlv';
import React, { Children } from 'react';

import { Box, Button } from '@fhs-legacy/universal-components';
import {
  IModifierSelection,
  IPickerItem,
  IQuickConfig,
  ISanityItemOption,
  ISanityItemOptionModifier,
} from '@rbi-ctg/menu';
import { IUIRule, rulesForItem } from 'utils/menu/quick-configs';

import { quickConfigSelectedColor } from './constants';

const QuickConfigsList = Box.withConfig({
  flexDirection: 'row',
  borderRadius: 4,
  height: '$16',
  marginX: 0,
  marginTop: '$8',
  marginBottom: '$2',
});

interface IQuickConfigItemProps {
  modifierSelections: IModifierSelection[];
  onPress: HandleModifierSelection;
  rules: IUIRule[];
  isFirst: boolean;
  isLast: boolean;
}

const isActive = ({ modifierSelections, rules = [] }: IQuickConfigItemProps): boolean => {
  return (
    !!rules.length &&
    rules.every(({ itemOption: ruleItemOpt, modifier }) => {
      const itemOption = modifierSelections.find(({ _key }) => ruleItemOpt._key === _key);
      return !!itemOption && itemOption.modifier._key === modifier._key;
    })
  );
};

const QuickConfigItem = Button.withConfig<IQuickConfigItemProps>(props => ({
  flexDirection: 'row',
  alignItems: 'center',

  _text: {
    fontSize: 'md',
    textAlign: 'center',
    color: isActive(props) ? Styles.color.white : Styles.color.black,
  },
  _pressed: {
    bgColor: quickConfigSelectedColor,
    _text: {
      color: Styles.color.white,
    },
  },

  height: '100%',
  justifyContent: 'center',
  padding: '$1',
  backgroundColor: isActive(props) ? quickConfigSelectedColor : Styles.color.white,
  borderWidth: 0.5,
  borderColor: Styles.color.grey.four,
  borderStyle: 'solid',
  borderBottomLeftRadius: props.isFirst ? 4 : 0,
  borderTopLeftRadius: props.isFirst ? 4 : 0,
  borderBottomRightRadius: props.isLast ? 4 : 0,
  borderTopRightRadius: props.isLast ? 4 : 0,
}));

type HandleModifierSelection = (
  id: string,
  modifier: ISanityItemOptionModifier,
  itemOption: ISanityItemOption
) => void;

interface IQuickConfigsProps {
  modifierSelections: IModifierSelection[];
  handleModifierSelection: HandleModifierSelection;
  pickerSelection: IPickerItem;
  quickConfigs: IQuickConfig[];
}

const onPressQuickConfig =
  (rules: IUIRule[], item: IPickerItem, handleModifierSelection: HandleModifierSelection) => () => {
    rules.forEach(({ itemOption, modifier }) => {
      const itemOpt = item.options.find(({ _key }) => itemOption._key === _key);
      if (itemOpt) {
        return handleModifierSelection(item._id, modifier, itemOpt);
      }
    });
  };

const QuickConfigs = ({
  handleModifierSelection,
  modifierSelections,
  pickerSelection = {} as IPickerItem,
  quickConfigs = [],
}: IQuickConfigsProps) => {
  if (quickConfigs.length === 0) {
    return null;
  }

  const items = quickConfigs.reduce<React.ReactNode[]>((acc, qc, i) => {
    if (!qc.rules) {
      return acc;
    }

    const rules = rulesForItem(qc.rules, pickerSelection);

    const configName: string = delve(qc, 'name.locale') || '';

    // if a rule is not present that means 1 or more
    // modifiers are not available for this item,
    // so we cannot render this quick config
    return rules.length !== qc.rules.length
      ? acc
      : acc.concat(
          <QuickConfigItem
            key={configName.concat(String(i))}
            modifierSelections={modifierSelections}
            onPress={onPressQuickConfig(rules, pickerSelection, handleModifierSelection)}
            rules={rules}
            isFirst={i === 0}
            isLast={i === quickConfigs.length - 1}
            width={`${100 / quickConfigs.length}%`}
          >
            {configName}
          </QuickConfigItem>
        );
  }, []);

  if (Children.count(items) === 0) {
    return null;
  }

  return <QuickConfigsList>{items}</QuickConfigsList>;
};

export default QuickConfigs;
