import { IMainMenuView } from '@rbi-ctg/menu';
import { IGetMenuSectionsQuery } from 'generated/sanity-graphql';
import { IDayPartBoundary } from 'state/day-part/hooks/use-active-day-parts';
import { DAY_PART_SELECTIONS } from 'state/main-menu/types';

export interface IUseTabbedHeaderProps {
  menuData: IGetMenuSectionsQuery['Menu'] | IMainMenuView | null | undefined;
  menuDataLoading: boolean;
  dayPartMenu?: DAY_PART_SELECTIONS | null;
  activeDayParts?: IDayPartBoundary[];
}
