import { Box, ScrollView, Text, VStack } from '@fhs-legacy/universal-components';

export const ActionButtonsContainer = VStack.withConfig({
  width: 'full',
  marginBottom: '10',
  space: '$4',
});

export const Form = ScrollView.withConfig({
  width: 'full',
});

export const Message = Text.withConfig({
  textAlign: 'center',
});

export const DefaultText = Text.withConfig({
  variant: 'copyOne',
  fontSize: 'sm',
  textTransform: 'uppercase',
  textAlign: 'right',
  marginRight: '$10',
});

export const DefaultTextWrapper = Box.withConfig({
  width: 'full',
  position: 'relative',
  height: '$6',
});
