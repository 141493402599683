import { ICombo, IItem, IItemOption, IModifierSelection } from '@rbi-ctg/menu';

import { isCombo, isItem } from './is-menu-type';

const getDefaultModifiersForItem = (item: IItem): IModifierSelection[] => {
  if (!item.options || !item.options.length) {
    return [];
  }

  const { options } = item;

  const modifierSelections = (options as IItemOption[]).reduce<IModifierSelection[]>(
    (acc, itemOption) => {
      if (!itemOption.options || !itemOption.options.length) {
        return acc;
      }

      const defaultModifier = itemOption.options.find(
        itemOptionModifier => itemOptionModifier.default
      );

      if (!defaultModifier) {
        return acc;
      }

      const modifierSelection: IModifierSelection = {
        _key: itemOption._key,
        _type: itemOption._type,
        componentStyle: itemOption.componentStyle,
        itemId: item._id,
        fifoPositions: [],
        name: defaultModifier.name!,
        modifier: { ...defaultModifier, quantity: 1 },
        upsellModifier: itemOption.upsellModifier,
      };

      return acc.concat(modifierSelection);
    },
    []
  );

  return modifierSelections;
};

const getDefaultModifiersForCombo = (combo: ICombo) => {
  const { mainItem, options } = combo;

  // get main item default modifiers if main item exists
  const mainItemModifiers = mainItem ? getDefaultModifiersForItem(mainItem) : [];

  // for each combo slot, accumulate default modifiers for either the default
  // combo slot option or the first combo slot option if none exists
  const comboSlotModifiers = options.reduce<IModifierSelection[]>((acc, comboSlot) => {
    if (!comboSlot.options) {
      return acc;
    }

    // use the default combo slot option to determine modifiers
    // or the first option if there's no default
    const defaultSlotOption =
      comboSlot.options.find(({ defaultAmount }) => !!defaultAmount) || comboSlot.options[0];

    // if we have no option move on
    // @todo handle picker, section
    if (!defaultSlotOption || !defaultSlotOption.option || !isItem(defaultSlotOption.option)) {
      return acc;
    }

    return acc.concat(
      getDefaultModifiersForItem(defaultSlotOption.option).map(modifierSelection => ({
        ...modifierSelection,
        comboSlotId: comboSlot._id,
      }))
    );
  }, []);

  return mainItemModifiers.concat(comboSlotModifiers);
};

export const getDefaultModifiersForMenuItem = (menuItem: ICombo | IItem): IModifierSelection[] => {
  if (!menuItem) {
    return [];
  }

  if (isItem(menuItem)) {
    return getDefaultModifiersForItem(menuItem);
  }

  if (isCombo(menuItem)) {
    return getDefaultModifiersForCombo(menuItem);
  }

  return [];
};
