import { ApolloError } from '@apollo/client';
import { IntlFormatters } from 'react-intl';

import {
  IErrorDialog,
  getAppleOrGooglePayError,
  getErrorDialogForGraphQLCode,
  parseGraphQLErrorCodes,
  paymentErrorModalMaps,
} from 'utils/errors';
import { GraphQLError } from 'utils/network';

import { messageIdMappingForDialogs } from './message-id-mappings';

interface IGetErrorDialogOptions {
  error: ApolloError | GraphQLError;
  formatMessage: IntlFormatters['formatMessage'];
  body: React.ReactNode;
  errorMsgId: string;
}

export const ORDER_FAILURE_MESSAGE_KEY = 'orderFailureMessage';
export const CONTACT_US_ROUTE_KEY = 'contact-us';

interface IErrorDialogOptions {
  body: React.ReactNode;
  error: ApolloError | GraphQLError;
  title: string;
  message: string;
}

/**
 * If you provide a body it will be used, if not it will default to the body of the errorDialogOptions
 *
 * @param body
 * @param error
 * @param formatMessage
 * @param errorMsgId
 */
export const getErrorDialogOptions = ({
  body,
  error,
  formatMessage,
  errorMsgId,
}: IGetErrorDialogOptions): IErrorDialog | IErrorDialogOptions => {
  const [errorCode] = parseGraphQLErrorCodes(error);
  const errorDialog = errorCode && getErrorDialogForGraphQLCode(errorCode, formatMessage);

  return {
    body: body ?? errorDialog?.body,
    error,
    message: errorDialog?.message ?? formatMessage({ id: errorMsgId }),
    title: errorDialog?.title ?? formatMessage({ id: 'uhoh' }),
  };
};

/**
 * Payment error modal key
 * Returns the key for the payment error modal
 * @param fdAccountId
 * @param defaultMessage
 * @returns
 */
export const getOrderFailureMessageKey = (
  fdAccountId: string,
  error: ApolloError | GraphQLError | undefined
) => {
  const appleOrGooglePayError = getAppleOrGooglePayError(fdAccountId);

  if (appleOrGooglePayError) {
    return appleOrGooglePayError;
  }

  if (!error) {
    return ORDER_FAILURE_MESSAGE_KEY;
  }
  const errorCode = parseGraphQLErrorCodes(error)[0]?.errorCode;

  const paymentModalKey = paymentErrorModalMaps[errorCode];
  const messageIdMappingForDialog = messageIdMappingForDialogs[paymentModalKey];
  return messageIdMappingForDialog?.body ?? ORDER_FAILURE_MESSAGE_KEY;
};
