import React from 'react';
import { useIntl } from 'react-intl';

import { TermsLink } from '../styled';

import { REWARDS_POLICY_TERMS_MESSAGE_ID } from './constants';

export const RewardsPolicyTermsLink = () => {
  const { formatMessage } = useIntl();
  return (
    <TermsLink
      to={{
        pathname: formatMessage({ id: 'routes.termsConditionsRewards' }),
        params: { title: formatMessage({ id: REWARDS_POLICY_TERMS_MESSAGE_ID }) },
      }}
    >
      {formatMessage({ id: REWARDS_POLICY_TERMS_MESSAGE_ID })}
    </TermsLink>
  );
};
