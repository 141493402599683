import * as React from 'react';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

import PointsIcon from 'components/icons/loyalty-points-icon';

import getPointsLabel from './get-points-label';
import { PointsEarnedContainer, PointsEarnedLabel } from './styled';
import { IEarnedPointsProps } from './types';

const PointsEarned: FC<React.PropsWithChildren<IEarnedPointsProps>> = ({ lylTransaction }) => {
  const { formatMessage } = useIntl();
  const pointsEarned = lylTransaction.pointsEarned + lylTransaction.bonusPointsEarned;

  if (!pointsEarned) {
    return null;
  }

  return (
    <View>
      <PointsEarnedContainer>
        <PointsEarnedLabel>
          <PointsIcon mr="$1" width="$7" />
          {getPointsLabel(pointsEarned, formatMessage)}
        </PointsEarnedLabel>
      </PointsEarnedContainer>
    </View>
  );
};

export default PointsEarned;
