import * as React from 'react';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { POINTS_TRANSLATION_KEY } from './constants';
import {
  PointsContainer,
  PointsTextContainer,
  StyledPointsIcon,
  StyledRewardLockIcon,
  StyledRewardPointCost,
} from './incentive-details.styled';
import { IRewardPoints } from './types';

export const RewardPoints: FC<React.PropsWithChildren<IRewardPoints>> = ({ pointCost, locked }) => {
  const { formatMessage } = useIntl();

  return (
    <PointsContainer>
      <PointsTextContainer>
        {locked ? <StyledRewardLockIcon /> : <StyledPointsIcon />}
        <StyledRewardPointCost pointCost={pointCost}>
          {`${pointCost} ${formatMessage({ id: POINTS_TRANSLATION_KEY })}`}
        </StyledRewardPointCost>
      </PointsTextContainer>
    </PointsContainer>
  );
};
