// This function is used to create components with sub components
// for example you could have a Button component that has a sub component: Button.Text
// you could define this by using the function like so:
// export const Button = withStaticPropers(ButtonComponent, {
//  Text: TextComponent
// });
export function withStaticProperties<A extends Function, B>(component: A, staticProps: B): A & B {
  Object.assign(component, staticProps);
  return component as A & B;
}
