import React from 'react';

import { Text } from '@fhs-legacy/universal-components';
import { getUSState } from 'utils/restaurant';

export const getLocalizedStoreAddress = (store: any, formatMessage: any) =>
  formatMessage(
    { id: 'storeAddress' },
    {
      name: '',
      address1: <Text>{store?.physicalAddress?.address1}</Text>,
      city: store?.physicalAddress?.city,
      stateProvince: getUSState(store?.physicalAddress?.stateProvince),
      postalCode: store?.physicalAddress?.postalCode,
      conditionalLinebreak: null,
      linebreak: '\n',
    }
  );

export const getLocalizedDeliveryAddress = (deliveryAddress: any, formatMessage: any) => {
  const addressLine2 = deliveryAddress.addressLine2
    ? formatMessage({ id: 'unitNumber' }, { unitNumber: deliveryAddress.addressLine2 })
    : '';

  return formatMessage(
    { id: 'deliveryAddressFormatted' },
    {
      addressLine1: deliveryAddress.addressLine1,
      addressLine2,
      city: deliveryAddress.city,
      state: deliveryAddress.state,
      zip: deliveryAddress.zip,
      linebreak: '\n',
    }
  );
};

export const getLocalizedDateTime = (formattedPickupDateTime: any, formatMessage: any) =>
  formatMessage(
    { id: 'pickupDateTime' },
    {
      date: formattedPickupDateTime.date,
      time: formattedPickupDateTime.time,
      linebreak: '\n',
    }
  );
