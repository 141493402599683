import { FieldProps } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';

import { StyledInput } from 'components/otp-input/styled';
import { SIGNIN_OTP_INPUT } from 'utils/test-ids';

const NUMBER_REGEXP = new RegExp(/^[0-9]{0,}$/);

interface IFormikOTPInput {
  field: FieldProps['field'];
  form: FieldProps['form'];
  otpCodeLength: number;
  setFieldValue(name: string, value: string): void;
  submitOnFill?: boolean;
}

export const FormikOTPInput = ({
  field,
  form,
  setFieldValue,
  submitOnFill,
  otpCodeLength,
  ...props
}: IFormikOTPInput) => {
  const { formatMessage } = useIntl();

  const handleChange = (value: string) => {
    if (value.length > otpCodeLength) {
      return;
    }

    if (!NUMBER_REGEXP.test(value)) {
      return;
    }

    setFieldValue(field.name, value);

    if (submitOnFill && !form.isSubmitting && value.length === otpCodeLength) {
      form.handleSubmit();
    }
  };

  return (
    <StyledInput
      {...field}
      {...props}
      // show the big number-only keyboard on iOS < 12.2 (type="number" isn't enough)
      // TODO: RN - pattern="[0-9]*" check if this is needed
      // show the big number-only keyboard on Android 66+ (type="number" works too) and iOS >= 12.2 (type="number" shows a different keyboard)
      keyboardType="numeric"
      onChangeText={handleChange}
      label={formatMessage({ id: 'validationCode' })}
      required
      testID={SIGNIN_OTP_INPUT}
      autoFocus
    />
  );
};
