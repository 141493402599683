export const Action = {
  PullingDown: 'pullingDown',
  Refreshing: 'refreshing',
  Releasing: 'releasing',
} as const;

export type Values<T> = T[keyof T];

export type Actions = Values<typeof Action>;

export interface IPullToRefreshContent {
  action: Actions;
}
