import { Link } from 'expo-router';
import { ElementType } from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';

import {
  IconRewards,
  IconRewardsAndOffers,
  Pressable,
  Text,
  createMqStyles,
  tokens,
  usePressableState,
} from '@fhs/ui';

import { useMenuClickEvents } from '../analytics';

type MenuOffersLinkProps = {
  style?: ViewProps['style'];
};

export function MenuOffersLink(props: MenuOffersLinkProps) {
  const mqStyles = useMqStyles();
  return (
    <View style={mqStyles.menuOfferContainer}>
      <LinkContent
        link="/rewards/offers"
        text="Offers"
        style={props.style}
        Icon={IconRewardsAndOffers}
      />
      <LinkContent link="/rewards/details" text="Rewards" style={props.style} Icon={IconRewards} />
    </View>
  );
}

const LinkContent = ({
  link,
  text,
  style,
  Icon,
}: {
  link: string;
  text: string;
  style: ViewProps['style'];
  Icon: ElementType;
}) => {
  const mqStyles = useMqStyles();
  const pressableState = usePressableState();
  const { logMenuClickEvent } = useMenuClickEvents();
  return (
    <Link href={link} asChild>
      <Pressable
        borderRadius={8}
        hoveredStyle={styles.isHoveredPressable}
        style={StyleSheet.flatten([mqStyles.container, style])}
        onPress={() => {
          logMenuClickEvent({ text });
        }}
      >
        <Icon size={44} />
        <Text.Heading
          type="three"
          style={[mqStyles.offersAndRewardsText, pressableState.hovered && styles.isHoveredText]}
        >
          {text}
        </Text.Heading>
      </Pressable>
    </Link>
  );
};

const styles = StyleSheet.create({
  isHoveredPressable: {
    borderColor: tokens.colors.$houseRedOpacity30,
  },
  isHoveredText: {
    color: tokens.colors.$houseRedDarken,
  },
});

const useMqStyles = createMqStyles({
  menuOfferContainer: {
    $base: {
      flexDirection: 'row',
      flexGrow: 1,
      flexBasis: 0,
      gap: 24,
    },
    $ltDesktop: {
      gap: 12,
    },
  },
  container: {
    $base: {
      alignItems: 'center',
      height: 80,
      flexDirection: 'row',
      borderRadius: 8,
      borderColor: tokens.colors.$black10,
      borderWidth: 1,
      padding: 24,
      flex: 1,
    },
    $ltDesktop: {
      paddingVertical: 12,
      paddingHorizontal: 16,
    },
  },
  offersAndRewardsText: {
    $base: {
      paddingHorizontal: 20,
    },
    $ltDesktop: {
      paddingHorizontal: 12,
    },
  },
  iconContainerPadding: {
    $base: {
      paddingLeft: 22,
    },
    $ltDesktop: {
      paddingLeft: 16,
    },
  },
});
