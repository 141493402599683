import { Box } from '@fhs-legacy/universal-components';

const BaseAlertBox = Box.withConfig({
  marginBottom: '5',
  paddingY: '2',
  paddingX: '6',
  borderWidth: '1px',
  _text: {
    fontSize: '16px',
    textAlign: 'center',
  },
});

export const SuccessAlertBox = BaseAlertBox.withConfig({
  borderColor: Styles.color.secondary,
  _text: {
    fontFamily: 'heading',
    color: Styles.color.secondary,
  },
});

export const ErrorAlertBox = BaseAlertBox.withConfig({
  borderColor: Styles.color.primary,
  _text: {
    fontFamily: 'heading',
    color: Styles.color.primary,
  },
});
