import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { IBackendCartEntries, ICartEntry } from '@rbi-ctg/menu';
import ModalOrderUnavailable from 'components/modal-order-unavailable';
import { ItemAvailabilityStatus } from 'enums/menu';
import { useGetMeQuery } from 'generated/rbi-graphql';
import usePosVendor from 'hooks/menu/use-pos-vendor';
import useAddToCartRecentItem from 'hooks/use-add-to-cart-recent-item';
import { NUM_RECENT_PURCHASED_ITEMS } from 'state/auth/hooks/use-current-user';
import { useCRMEventsContext } from 'state/crm-events';
import { useMenuContext } from 'state/menu';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { isCartEntryInMenu } from 'utils/cart';
import AuthStorage from 'utils/cognito/storage';
import { HapticsImpactStyle, hapticImpact } from 'utils/haptic';
import { StorageKeys } from 'utils/local-storage';
import { logAddRecentItemToCartEvent } from 'utils/mParticle';
import { maybeMapCartEntryToMenuObjectIdentifier } from 'utils/menu';

import { CarouselContainer } from './carousel-container';
import RecentItemsCarouselItem from './recent-items-carousel-item';
import { Container } from './styled';
import { IRecentItemsProps } from './types';

const sanityIdKeyExtractor = ({ sanityId }: IBackendCartEntries) => sanityId;

const RecentItemsCarousel: FC<React.PropsWithChildren<IRecentItemsProps>> = ({
  locationName,
  containerHasTopPadding = true,
}) => {
  const { logRBIEvent } = useCRMEventsContext();
  const { checkItemAvailability } = useMenuContext();
  const { isStoreOpenAndAvailable, prices, store } = useStoreContext();
  const {
    recent: { pendingRecentItem, setPendingRecentItem },
  } = useOrderContext();
  const { addToCart } = useAddToCartRecentItem({ cartEntry: pendingRecentItem });
  const { vendor } = usePosVendor();
  const [selectionUnavailable, setSelectionUnavailable] = useState<ICartEntry | null>(null);

  const { data: userData } = useGetMeQuery({
    skip: !AuthStorage.getItem(StorageKeys.USER_AUTH_TOKEN),
    variables: {
      numUniquePurchasedItems: NUM_RECENT_PURCHASED_ITEMS,
    },
    fetchPolicy: 'cache-and-network',
  });

  const uniquePurchasedItems = (userData
    ? userData?.me?.uniquePurchasedItems
    : null) as unknown as IBackendCartEntries[];
  const items = useMemo(
    () =>
      (uniquePurchasedItems || []).filter(
        item => !item?.isDonation && item.lineId !== 'discount-offer' && isCartEntryInMenu(item)
      ),
    [uniquePurchasedItems]
  );

  const addAndLogItemToCart = useCallback(
    (item: IBackendCartEntries) => {
      const { name } = item;
      if (name) {
        logAddRecentItemToCartEvent(name ?? '', store ?? {}, logRBIEvent);
      }
      hapticImpact({ style: HapticsImpactStyle.Heavy });
    },
    [items.length, locationName, logRBIEvent, store]
  );

  const addToCartIfAvailable = useCallback(
    async (cartEntry: ICartEntry) => {
      const itemId = maybeMapCartEntryToMenuObjectIdentifier(cartEntry);
      if (!itemId) {
        setSelectionUnavailable(cartEntry);
        return;
      }
      const { availabilityStatus } = await checkItemAvailability({
        vendor,
        restaurantPosDataId: store.restaurantPosData?._id ?? '',
        storeNumber: store.number ?? '',
        itemId,
      });
      if (availabilityStatus !== ItemAvailabilityStatus.AVAILABLE) {
        setSelectionUnavailable(cartEntry);
      } else {
        addToCart();
      }
    },
    [addToCart, checkItemAvailability, store.number, store.restaurantPosData, vendor]
  );

  const carouselItemRenderer = useCallback(
    ({ item }: { item: IBackendCartEntries }) => (
      <RecentItemsCarouselItem cartEntry={item} onItemAdded={() => addAndLogItemToCart(item)} />
    ),
    [addAndLogItemToCart]
  );

  useEffect(() => {
    if (isStoreOpenAndAvailable && pendingRecentItem && prices) {
      addToCartIfAvailable(pendingRecentItem);
      setPendingRecentItem(null);
    }
  }, [
    addToCart,
    addToCartIfAvailable,
    isStoreOpenAndAvailable,
    pendingRecentItem,
    prices,
    setPendingRecentItem,
  ]);

  return (
    <Container $containerHasTopPadding={containerHasTopPadding}>
      <CarouselContainer
        bgColor="transparent"
        items={items}
        renderItem={carouselItemRenderer}
        keyExtractor={sanityIdKeyExtractor}
      />
      {selectionUnavailable && (
        <ModalOrderUnavailable
          items={[selectionUnavailable]}
          onDismiss={() => setSelectionUnavailable(null)}
        />
      )}
    </Container>
  );
};

export default RecentItemsCarousel;
