import React from 'react';

import { ContainerBox, ContainerLink } from './loyalty-basic-card-widget.styled';

const LoyaltyBasicCardContainer: React.FC<
  React.PropsWithChildren<{
    link?: string | null;
    children: React.ReactNode;
  }>
> = ({ link, children }) => {
  return (
    <>
      {link ? (
        <ContainerLink to={link}>
          <ContainerBox testID="loyalty-basic-card-container">{children}</ContainerBox>
        </ContainerLink>
      ) : (
        <ContainerBox testID="loyalty-basic-card-container">{children}</ContainerBox>
      )}
    </>
  );
};

export default LoyaltyBasicCardContainer;
