import React from 'react';
import { useIntl } from 'react-intl';

import SectionHeading from '../section-heading';

const YourCartHeading: React.VFC = () => {
  const { formatMessage } = useIntl();
  return (
    <SectionHeading testID="cart-section-heading-label">
      {formatMessage({ id: 'yourCart' })}
    </SectionHeading>
  );
};

export default YourCartHeading;
