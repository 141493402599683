import { Box, Pressable } from '@fhs-legacy/universal-components';
import Picture from 'components/picture';
import SanityBlockRenderer from 'components/sanity-block-renderer';

export const Container = Pressable.withConfig({
  width: '$64',
  height: '$56',
  backgroundColor: Styles.color.white,
  color: Styles.color.black,
  flexDirection: 'column',
  borderRadius: '2xl',
  _web: {
    cursor: 'pointer',
  },
});

export const ImageContainer = Picture.withConfig({
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: 0,
  borderTopLeftRadius: '2xl',
  borderTopRightRadius: '2xl',
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
});

export const Content = Box.withConfig({
  flex: 1,
  overflow: 'hidden',
  paddingY: '$2.5',
  paddingX: '$3',
  width: 'full',
});

export const TitleTextWrapper = Box.withConfig({
  margin: 0,
  overflow: 'hidden',
  maxWidth: 'full',
});

export const TitleText = SanityBlockRenderer.withConfig({
  textProps: {
    fontSize: 'xl',
    fontWeight: 'semibold',
    color: Styles.color.black,
    lineHeight: 'md',
    numberOfLines: 1,
    textAlign: 'left',
  },
});

export const RadioButtonWrapper = Box.withConfig({
  position: 'absolute',
  bottom: '$4',
  right: '$6',
});
