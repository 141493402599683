import { router, useLocalSearchParams } from 'expo-router';
import { noop } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { ScreenContent } from '@fhs/screen-content';
import { Box, Text } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import { AvoidSoftInputView } from 'components/avoid-soft-input-view';
import CreditCardFormInputs, { getErrorMessage } from 'components/credit-card-form-inputs';
import Footer from 'components/footer';
import { ModalContent } from 'components/modal';
import { SafetechEncryption } from 'components/payments/integrations/orbital/components/encryption';
import {
  IEncryptionError as IOrbitalEncryptionError,
  IEncryptionResult as IOrbitalEncryptionResult,
} from 'components/payments/integrations/orbital/components/encryption/types';
import { EProtectEncryption } from 'components/payments/integrations/worldpay/components/encryption';
import {
  IEncryptionError as IWorldpayEncryptionError,
  IEncryptionResult as IWorldpayEncryptionResult,
} from 'components/payments/integrations/worldpay/components/encryption/types';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import useEffectOnce from 'hooks/use-effect-once';
import useErrorModal from 'hooks/use-error-modal';
import { usePathname } from 'hooks/use-pathname';
import { PaymentMethodsRouteParams } from 'pages/account/types';
import { useAuthContext } from 'state/auth';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  SupportedCardType,
  defaultPaymentFieldVariation,
  defaultSupportedCardTypes,
} from 'state/launchdarkly/variations';
import { useOrderContext } from 'state/order';
import { usePaymentContext } from 'state/payment';
import { CardType } from 'state/payment/types';
import { WEB_APP_MODAL_CONTENT_MAX_WIDTH } from 'utils/constants';
import logger from 'utils/logger';
import {
  getCCFormErrors,
  initialErrorState,
  initialPaymentState,
  mapDeliveryToCCFormAddress,
  onCCFormChange,
  testCardPaymentState,
} from 'utils/payment';
import { mapPaymentMethodCardTypes } from 'utils/payment/map-payment-method-card-type';
import { routes } from 'utils/routing';

const AddPaymentMethodModal: React.FC<
  React.PropsWithChildren<{
    onCardAdded?: () => void;
  }>
> = ({ onCardAdded = noop }) => {
  const auth = useAuthContext();
  const { logRBIEvent } = useCRMEventsContext();

  const [errors, setErrors] = useState(initialErrorState);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const payment = usePaymentContext();
  const { isOrbital, isWorldpay, setEncryptionResult } = payment;
  const paymentFieldVariations =
    useFlag<PaymentFieldVariations>(LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS) ||
    defaultPaymentFieldVariation;

  const rawSupportedCardTypes: SupportedCardType[] =
    useFlag(LaunchDarklyFlag.SUPPORTED_CARD_BRANDS_VARIATIONS) || defaultSupportedCardTypes;
  const supportedCardTypes = rawSupportedCardTypes
    .map(cardType => mapPaymentMethodCardTypes(cardType))
    .filter(Boolean) as CardType[];

  const { deliveryAddress, isDelivery: isDeliveryServiceMode } = useOrderContext();

  const pathname = usePathname();
  const { accountToDelete } = useLocalSearchParams<PaymentMethodsRouteParams>();
  const isDelivery = pathname.startsWith(routes.cart) && isDeliveryServiceMode;
  const { feCountryCode: billingCountry } = useLocale();
  const userDetailsName = auth.user?.details.name;

  const [paymentValues, setPaymentValues] = useState(
    initialPaymentState({
      isDelivery,
      deliveryAddress,
      billingCountry,
      userDetailsName,
    })
  );

  const { formatMessage } = useIntl();
  const autoFill = useFlag(LaunchDarklyFlag.AUTO_FILL_TEST_CARD);
  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Vaulting Credit Card details',
  });

  useEffectOnce(() => {
    if (autoFill) {
      setPaymentValues(testCardPaymentState({ isDelivery, isWorldpay, paymentFieldVariations }));
    }
  });

  const handleChange = useCallback(
    (name: any, value: any) => {
      const { state, formErrors } = onCCFormChange(
        name,
        value,
        paymentValues,
        errors,
        formatMessage,
        supportedCardTypes
      );

      setErrors(prevState => ({ ...prevState, ...formErrors }));
      setPaymentValues(prevState => {
        return state.billingAddressSameAsDelivery
          ? {
              ...prevState,
              ...state,
              ...mapDeliveryToCCFormAddress(deliveryAddress),
            }
          : { ...prevState, ...state };
      });
    },
    [paymentValues, errors, formatMessage, deliveryAddress, supportedCardTypes]
  );

  const validateForm = useCallback((): boolean => {
    const { hasErrors, formErrors } = getCCFormErrors(
      paymentValues,
      errors,
      formatMessage,
      paymentFieldVariations,
      billingCountry
    );

    if (hasErrors) {
      const newErrorMessage = getErrorMessage({ errors, formatMessage });
      setErrorMessage(newErrorMessage);

      setErrors(prevState => ({ ...prevState, ...formErrors }));
      return true;
    }

    return false;
  }, [billingCountry, errors, formatMessage, paymentFieldVariations, paymentValues]);

  const addNewCard = useCallback(
    async (event: any, encryptionResult?: IOrbitalEncryptionResult | IWorldpayEncryptionResult) => {
      if (event) {
        event.preventDefault();
      }
      const { addPaymentMethod } = payment;

      const onAddCardSuccess = () => {
        onCardAdded();
        setPaymentValues(initialPaymentState({ isDelivery, deliveryAddress }));

        logRBIEvent({
          name: CustomEventNames.SAVE_NEW_PAYMENT_METHOD,
          type: EventTypes.UserContent,
          attributes: {
            paymentCardType: paymentValues.cardType,
          },
        });

        router.back();
        setIsLoading(false);
      };

      const onAddCardFailure = (paymentMethodError: Error, message: string) => {
        logger.error({ error: paymentMethodError, message });
        openErrorDialog({
          message: formatMessage({ id: 'paymentAddingError' }),
          error: paymentMethodError,
        });
        setIsLoading(false);
      };

      setIsLoading(true);
      const reshapedPaymentValues = payment.transformPaymentValues({
        paymentValues,
        paymentFieldVariations,
      });
      reshapedPaymentValues.accountToDelete = accountToDelete;

      try {
        await addPaymentMethod(
          reshapedPaymentValues,
          {
            skipErrorDialogOnError: true,
          },
          encryptionResult
        );
        onAddCardSuccess();
      } catch (error) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        onAddCardFailure(error, 'Error adding payment method');
      }
    },
    [
      payment,
      paymentValues,
      formatMessage,
      paymentFieldVariations,
      accountToDelete,
      onCardAdded,
      isDelivery,
      deliveryAddress,
      openErrorDialog,
    ]
  );

  const handleOnPress = useCallback(() => {
    const hasFormError = validateForm();

    if (hasFormError) {
      return setIsLoading(false);
    }

    if (isOrbital || isWorldpay) {
      setEncryptionResult(undefined);
      setIsLoading(true);
      return setIsEncryptionActive(true);
    }

    addNewCard(null);
  }, [addNewCard, isOrbital, isWorldpay, setEncryptionResult, validateForm]);

  // // Change the modal header & button text if we're re-vaulting the CC
  // const modalHeading = accountToDelete
  //   ? formatMessage({ id: 'pleaseConfirmYourCreditCardInformation' })
  //   : formatMessage({ id: 'addPaymentMethod' });

  const modalBtnText = `${
    accountToDelete ? formatMessage({ id: 'confirm' }) : formatMessage({ id: 'save' })
  } & ${formatMessage({ id: 'continue' })}`;

  const [isEncryptionActive, setIsEncryptionActive] = useState(false);

  const onEncryptionResult = useCallback(
    (data: IOrbitalEncryptionResult | IWorldpayEncryptionResult) => {
      setEncryptionResult(data);
      setIsEncryptionActive(false);
      addNewCard(null, data);
    },
    [addNewCard, setEncryptionResult]
  );

  // Handle encryption error
  const onEncryptionError = (error: IOrbitalEncryptionError | IWorldpayEncryptionError) => {
    setIsEncryptionActive(false);
    setEncryptionResult(undefined);
    setIsLoading(false);
    const errorMessage = 'error' in error ? error.error : error.message;
    logger.error({ error: new Error(errorMessage), message: errorMessage });
    openErrorDialog({
      message: formatMessage({ id: 'paymentAddingError' }),
      error: new Error(errorMessage),
    });
  };

  return (
    <AvoidSoftInputView>
      <ScreenContent after={<Footer />}>
        <ModalContent
          backgroundColor={Styles.color.background}
          py="$0"
          maxWidth={WEB_APP_MODAL_CONTENT_MAX_WIDTH}
          alignSelf="center"
          width={{ base: 'full', desktop: WEB_APP_MODAL_CONTENT_MAX_WIDTH }}
        >
          <Box>
            {!!errorMessage && (
              <VisuallyHidden role="alert" accessibilityLabel="">
                <Text>{errorMessage}</Text>
              </VisuallyHidden>
            )}
            <CreditCardFormInputs
              onChange={handleChange}
              paymentValues={paymentValues}
              errors={errors}
              isDelivery={isDelivery}
            />
            <Box marginY="$4" marginX="$0">
              <ActionButton
                fullWidth
                testID="save-and-continue"
                isLoading={isLoading}
                disabled={!paymentValues.saveCard}
                onPress={handleOnPress}
              >
                {modalBtnText}
              </ActionButton>
            </Box>
          </Box>
        </ModalContent>
      </ScreenContent>

      <ErrorDialog />

      {isOrbital && isEncryptionActive ? (
        <SafetechEncryption
          cardNumber={paymentValues.cardNumber}
          cvv={paymentValues.cvv || ''}
          onResult={onEncryptionResult}
          onError={onEncryptionError}
        />
      ) : null}

      {isWorldpay && isEncryptionActive ? (
        <EProtectEncryption
          cardNumber={paymentValues.cardNumber}
          cvv={paymentValues.cvv || ''}
          onResult={onEncryptionResult}
          onError={onEncryptionError}
        />
      ) : null}
    </AvoidSoftInputView>
  );
};

export default AddPaymentMethodModal;
