import { get }  from 'lodash';
import Color from 'color';

import type { Dict } from './utils';

export function mode(light: any, dark: any) {
  return (props: any) => (props.colorMode === 'dark' ? dark : light);
}

export const transparentize = (color: string, opacity: number) => (
  theme: Dict
) => {
  const raw = getColor(theme, color);
  return Color(raw).fade(opacity).rgb().string()
};

export const getColor = (theme: Dict, color: string, fallback: string = 'rgba(255, 255, 255, 0)') => {
  const hex = get(theme, `colors.${color}`, color);
  let colorString = fallback;
  try {
    colorString = Color(hex).toString();
  } catch(_err) {
    // noop; colorString already set to fallback
  }

  return colorString;
};

export const isDark = (color: string) => (theme: Dict) =>
  Color(getColor(theme, color)).isDark();

export const isLight = (color: string) => (theme: Dict) =>
  Color(getColor(theme, color)).isLight();
