import { MqSwitch } from '../../mq-styles';

import { OrderDisplayDesktop } from './order-display-desktop';
import { OrderDisplayMobile } from './order-display-mobile';
import { OrderDisplayProps } from './types';

export function OrderDisplay(props: OrderDisplayProps) {
  return (
    <MqSwitch
      $base={() => <OrderDisplayMobile {...props} />}
      $gteDesktop={() => <OrderDisplayDesktop {...props} />}
    />
  );
}
