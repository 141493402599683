import * as React from 'react';
import { FC } from 'react';
import { RefreshControl } from 'react-native';

import { View } from '@fhs-legacy/universal-components';
import { PullToReloadContent } from 'components/pull-to-reload/pull-to-reload-content';

/*
 * This component is intended to be a "branded" drop in replacement for `refreshControl` props on the various List components from react native.
 * The refreshControl is the mechanism for reloading a scrollable list by pulling down from the top of the list. Without this component,
 * the OS will implement the native UI.
 *
 * Example of the implementation:
 *
 *  <FlatList
 *    refreshControl={
 *      <RefreshControl
 *        refreshing={loadingFlag}
 *        onRefresh={() => refetchThing()}
 *      />
 *    }
 *  />
 */
const PullToReload: FC<React.PropsWithChildren<React.ComponentProps<typeof RefreshControl>>> = ({
  onRefresh,
  refreshing,
}) => {
  return (
    <RefreshControl
      refreshing={refreshing}
      tintColor={'transparent'}
      onRefresh={onRefresh}
      progressViewOffset={-15}
    >
      <View height={refreshing ? '66px' : '100px'} position="absolute" width="full" top={'15px'}>
        {!refreshing && <PullToReloadContent action="pullingDown" />}
        {refreshing && <PullToReloadContent action="refreshing" />}
      </View>
    </RefreshControl>
  );
};

export default PullToReload;
