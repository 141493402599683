import { Box, Text } from '@fhs-legacy/universal-components';

export const IncentiveContainer = Box.withConfig<{ $isInvalid?: boolean }>(
  ({ $isInvalid = false }) => ({
    backgroundColor: $isInvalid
      ? 'token.background-inline-alert-error'
      : 'token.background-input-disabled',
    borderRadius: Styles.borderRadius,
    borderBottomRadius: 0,
    paddingTop: 2,
    paddingBottom: 3,
    paddingX: 4,
  })
);

export const IncentiveStatus = Text.withConfig<{ $isInvalid?: boolean }>(
  ({ $isInvalid = false }) => ({
    fontSize: 'xs',
    fontWeight: $isInvalid ? 'bold' : 'normal',
    letterSpacing: 'xl',
    textTransform: 'uppercase',
    color: $isInvalid ? 'token.text-error' : Styles.color.black,
  })
);
