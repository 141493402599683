import React, { FC } from 'react';

import { ErrorAlertBox, SuccessAlertBox } from './styled';
import { ALERT_STYLE, ISupportAlertBoxProps } from './types';

export const SupportAlertBox: FC<React.PropsWithChildren<ISupportAlertBoxProps>> = ({
  children,
  alertStyle,
  testId,
}) => {
  const Alert = alertStyle === ALERT_STYLE.success ? SuccessAlertBox : ErrorAlertBox;

  return <Alert testID={testId}>{children}</Alert>;
};
