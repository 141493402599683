import { Dispatch } from 'react';
import { useIntl } from 'react-intl';

import { Button, ButtonProps, HStack } from '@fhs-legacy/universal-components';

import { IOffersFilters, OfferFilterAction, OfferFiltersActionType } from './types';

interface ILoyaltyOfferButtonProps {
  appliedFilters: IOffersFilters;
  toggleFilter: Dispatch<OfferFilterAction>;
}

const FilterButton = ({
  active,
  text,
  onPress,
}: {
  active: boolean;
  text: string;
  onPress: ButtonProps['onPress'];
}) => (
  <Button
    flex={1}
    borderColor={'__legacyToken.text-button-primary-reversed'}
    variant={active ? 'solid' : 'outline'}
    onPress={onPress}
    _text={{
      fontSize: 'xs',
      color: active
        ? '__legacyToken.text-button-primary'
        : '__legacyToken.text-button-primary-reversed',
    }}
  >
    {text}
  </Button>
);

export const LoyaltyOffersFilterButtons = ({
  appliedFilters,
  toggleFilter,
}: ILoyaltyOfferButtonProps) => {
  const { formatMessage } = useIntl();

  const onPress = (actionType: OfferFiltersActionType) => toggleFilter({ type: actionType });

  return (
    <HStack paddingBottom="$4" paddingX="$4" space={'xs'}>
      <FilterButton
        active={appliedFilters.deliveryOnly}
        onPress={() => onPress(OfferFiltersActionType.TOGGLE_DELIVERY)}
        text={formatMessage({ id: 'delivery' })}
      />

      <FilterButton
        active={appliedFilters.pickUpOnly}
        onPress={() => onPress(OfferFiltersActionType.TOGGLE_PICKUP)}
        text={formatMessage({ id: 'pickUp' })}
      />

      <FilterButton
        active={appliedFilters.inStoreEnabled}
        onPress={() => onPress(OfferFiltersActionType.TOGGLE_INSTORE)}
        text={formatMessage({ id: 'inStoreTitle' })}
      />
    </HStack>
  );
};
