import React from 'react';

import { EnvItemBoolean, EnvList, HiddenSection } from 'pages/diagnostics/components';
import { useLDContext } from 'state/launchdarkly';

// TODO: currently displaying all flags as boolean
//       even for more complex flag types.
//       should show values based on type of flag
const LDFlagsContent = () => {
  const { flags } = useLDContext();
  return (
    <EnvList
      data={flags}
      renderItem={(key, value) => <EnvItemBoolean key={key} envKey={key} value={value} />}
    />
  );
};

export const LDFlags = () => (
  <HiddenSection title="LaunchDarkly Flags" content={<LDFlagsContent />} />
);
