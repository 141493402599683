// returns whether a rule of given type in
// a ruleset will always apply.
// a rule will always apply
// by the following criteria:
//
// the rule is found at the top level
// (automatically ANDed with other top-level rules)
// OR
// the rule is found as a member of
// an AND ruleset at the top level
//
// otherwise the offer may be redeemed
// without the rule applying in at least 1 case
// (due to OR)

import { Refinement } from '@rbi-ctg/frontend';
import { IAnd, Rule } from '@rbi-ctg/offers';
import { or } from 'utils';

import { RuleType } from './types';

export const satisfiesPredicate =
  (rulePredicate: (rule: Rule) => boolean) =>
  (rule: Rule): boolean =>
    rulePredicate(rule);

export const isRuleOfType = <R extends Rule>(ruleType: RuleType) =>
  satisfiesPredicate(rule => rule.ruleSetType === ruleType) as Refinement<Rule, R>;

const isAnd = isRuleOfType<IAnd>(RuleType.And);

export function ruleOfTypeWillApply<R extends Rule>(ruleType: RuleType) {
  const isMatchingRuleType = isRuleOfType<R>(ruleType);
  function ruleOfTypeWillApplyToRuleSet(ruleSet: Rule[] | null): boolean {
    return (ruleSet || []).some(
      rule =>
        isMatchingRuleType(rule) || (isAnd(rule) && ruleOfTypeWillApplyToRuleSet(rule.ruleSet))
    );
  }

  return ruleOfTypeWillApplyToRuleSet;
}

// recurse a ruleset, finding the first rule matching the given
// type that will always apply, or undefined
export function findFirstRuleOfTypeThatApplies<R extends Rule>(
  ruleType: RuleType,
  ruleSet: Rule[] | null
): R | undefined {
  const predicate = or(isRuleOfType<R>(ruleType), isAnd);
  const rule = (ruleSet || []).find<R | IAnd>(predicate as Refinement<Rule, R | IAnd>);

  if (rule && isAnd(rule)) {
    return findFirstRuleOfTypeThatApplies(ruleType, rule.ruleSet);
  }

  return rule;
}

// recurse a ruleset and accumulate all rules of a given type
// that will always apply
export function findAllRulesOfTypeThatApply<R extends Rule>(
  ruleType: RuleType,
  ruleSet: Rule[] | null
): R[] {
  const predicate = isRuleOfType<R>(ruleType);
  return (ruleSet || []).reduce<R[]>((acc, rule) => {
    if (isAnd(rule)) {
      return acc.concat(findAllRulesOfTypeThatApply(ruleType, rule.ruleSet));
    }
    if (predicate(rule)) {
      return acc.concat(rule);
    }
    return acc;
  }, []);
}

export default ruleOfTypeWillApply;
