import performance from 'react-native-performance';

import logger from 'utils/logger';

// performance marks can be used to
// measure the duration an event takes
// via the performance api
export enum PerformanceMarks {
  COMMIT_END = 'marks.commit_order.end',
  COMMIT_START = 'marks.commit_order.start',
  PRICE_END = 'marks.price_order.end',
  PRICE_START = 'marks.price_order.start',

  // App Start Time Marks
  APP_LOADING_START = 'marks.app_loading.start',

  APP_RN_TSX_START = 'marks.app_rn_tsx.start',

  MAIN_MENU_LOADING_START = 'marks.main_menu_loading.start',
  MAIN_MENU_LOADING_FINISHED = 'marks.main_menu_loading.finished',
  MENU_SECTION_LOADING_START = 'marks.menu_section_loading.start',
  MENU_PRODUCT_LOADING_START = 'marks.menu_product_loading.start',

  CART_TOTALS_LOADING_FINISHED = 'marks.cart_totals_loading.finished',
}

export enum Measures {
  COMMIT = 'measure.commit_order',
  PRICE = 'measure.price_order',

  // App Start Measures
  APP_LOADING = 'measure.app_loading',

  MENU_LOADING = 'measure.menu_loading',
}

export const setMark = (mark: PerformanceMarks) => {
  try {
    performance.mark(mark);
  } catch (error) {
    logger.error({ error, mark, message: 'Performance.mark error' });
  }
};

export const clearMark = (mark?: PerformanceMarks) => {
  try {
    performance.clearMarks(mark);
  } catch (error) {
    logger.error({ error, mark, message: 'Performance.clearMarks error' });
  }
};

export const clearMeasures = (measure?: Measures) => {
  try {
    performance.clearMeasures(measure);
  } catch (error) {
    logger.error({ error, measure, message: 'Performance.clearMeasures error' });
  }
};

export const logMeasurement = (
  measure: Measures,
  startingMark: PerformanceMarks,
  endingMark?: PerformanceMarks
) => {
  try {
    return performance.measure(measure, startingMark, endingMark);
  } catch (error) {
    logger.error({ error, startingMark, measure, message: 'Performance.measure error' });
    return;
  }
};

export const getMeasureAndClearMarks = (
  measure: Measures,
  ...marks: PerformanceMarks[]
): number | undefined => {
  try {
    performance.measure(measure, ...marks);
  } catch (error) {
    logger.error({ error, marks, measure, message: 'Performance.measure error' });
    return;
  }

  marks.forEach(clearMark);

  try {
    const entries = performance.getEntriesByName(measure);

    clearMeasures(measure);

    if (!entries.length) {
      return;
    }

    const [{ duration }] = entries;

    return duration;
  } catch (error) {
    logger.error({ error, marks, measure, message: 'Performance.getEntriesByName error' });
    return;
  }
};
