import { router, useFocusEffect } from 'expo-router';
import React, { useCallback, useEffect } from 'react';

import { Box } from '@fhs-legacy/universal-components';
import { useIsDesktopBp } from 'hooks/breakpoints';
import { usePathname } from 'hooks/use-pathname';
import { useAuthContext } from 'state/auth';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { usePypestreamContext } from 'state/pypestream';
import { PYPESTREAM_INTERFACE_ID } from 'state/pypestream/constants';
import { routes } from 'utils/routing';

import { PypestreamNeedHelpCTA } from './components/pypestream-cta';
import { PypestreamMobileLoader } from './components/pypestream-mobile-loader';

export const Pypestream = () => {
  const isDesktop = useIsDesktopBp();
  const pathname = usePathname();
  const { logRBIEvent } = useCRMEventsContext();

  const {
    isLoading,
    isHidden,
    isMounted,
    resetAndUnmountPanel,
    handleOpenPanel,
    redirectToHomePage,
    setRedirectToHomePage,
    mountPanelAfterSignIn,
  } = usePypestreamContext();

  const { loading: userLoading, isAuthenticated, user } = useAuthContext();

  useFocusEffect(
    useCallback(() => {
      if (pathname === routes.support) {
        if (isDesktop) {
          // Navigate to home page to open the panel
          setRedirectToHomePage(true);
          return router.navigate('/');
        }
        handleOpenPanel();
      }
    }, [handleOpenPanel, isDesktop, pathname, setRedirectToHomePage])
  );

  useEffect(() => {
    // This is to open the panel if the guest pressed 'Log In' from the Pypestream panel and being redirected after signing in.
    if (isDesktop && pathname === routes.base && mountPanelAfterSignIn) {
      resetAndUnmountPanel();
      handleOpenPanel();
      return;
    }
    // We need to wait for user details to be ready before calling Pypestream.boot
    // This is to get the correct initial message, cognitoId, etc. upon mounting the panel.
    if (redirectToHomePage) {
      // We need to wait for user details to be ready before calling Pypestream.boot
      // This is to get the correct initial message, cognitoId, etc. upon mounting the panel.
      if (isAuthenticated && userLoading) {
        return;
      }
      handleOpenPanel();
    }
  }, [
    handleOpenPanel,
    isAuthenticated,
    isDesktop,
    mountPanelAfterSignIn,
    pathname,
    redirectToHomePage,
    resetAndUnmountPanel,
    userLoading,
  ]);

  useEffect(() => {
    return () => {
      if (!isDesktop) {
        // Reset default values and unmount the panel upon navigating away from support on mobile and tablets
        resetAndUnmountPanel();
      }
    };
  }, [isDesktop, resetAndUnmountPanel]);

  if (!isDesktop && pathname === routes.support) {
    return (
      <Box flex="1" alignContent="center" flexWrap="wrap" pointerEvents="box-none">
        {isLoading && <PypestreamMobileLoader />}
        <div id={PYPESTREAM_INTERFACE_ID} />
      </Box>
    );
  }

  return (
    <Box
      flex="1"
      alignContent="center"
      flexWrap="wrap"
      width="410px"
      height="656px"
      position="absolute"
      bottom="$0"
      right="$0"
      pointerEvents="box-none"
    >
      <div
        id={PYPESTREAM_INTERFACE_ID}
        style={{ display: pathname === routes.base && isDesktop && !isHidden ? 'flex' : 'none' }}
      />
      {pathname === routes.base && isDesktop && (isHidden || !isMounted) && (
        <PypestreamNeedHelpCTA
          onPress={() => {
            handleOpenPanel();
            logRBIEvent({
              name: CustomEventNames.PYPESTREAM_SUPPORT_OPEN,
              type: EventTypes.UserContent,
              attributes: {
                userCognitoId: user?.cognitoId,
              },
            });
          }}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
};
