import { Box, Pressable } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

export const HeaderWrapper = Box.withConfig({
  paddingX: '$4',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
});

export const IconWrapper = Pressable.withConfig({
  position: 'absolute',
  left: '$2',
  top: 0,
});

export const SearchContainer = Box.withConfig({
  zIndex: 1,
  flexDirection: {
    base: 'column',
    desktop: 'row',
  },
  paddingY: '$2',
  justifyContent: 'center',
  alignItems: 'center',
});

export const SearchBarWrapper = Box.withConfig({
  width: 'full',
  height: theme.token('height-input'),
});
