import React from 'react';

import { Svg, SvgPath, addWithConfig } from '@fhs-legacy/universal-components';

import { ILoyaltyPointsIconProps } from './types';

const LoyaltyPointsIcon = ({
  width = '20px',
  height = '19px',
  fill = '#FFAA00',
  ...props
}: ILoyaltyPointsIconProps) => (
  <Svg width={width} height={height} viewBox="0 0 20 19" {...props}>
    <SvgPath
      d="M10 15.27L16.18 19L14.54 11.97L20 7.24L12.81 6.63L10 0L7.19 6.63L0 7.24L5.46 11.97L3.82 19L10 15.27Z"
      fill={fill}
    />
  </Svg>
);

export default addWithConfig(LoyaltyPointsIcon);
