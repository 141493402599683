import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconCartOutline = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M5.95 21.6c-.4 0-.742-.141-1.025-.424A1.397 1.397 0 0 1 4.5 20.15V8.05c0-.4.142-.742.425-1.025A1.397 1.397 0 0 1 5.95 6.6h2.475v-.425c0-1 .346-1.846 1.037-2.538C10.155 2.946 11 2.601 12 2.601c1 0 1.846.345 2.537 1.037.692.692 1.038 1.538 1.038 2.538V6.6h2.475c.4 0 .742.141 1.025.425.283.283.425.625.425 1.025v12.1c0 .4-.142.741-.425 1.025a1.397 1.397 0 0 1-1.025.425H5.95ZM9.575 6.177V6.6h4.85v-.425c0-.684-.233-1.263-.7-1.738-.467-.475-1.042-.712-1.725-.712s-1.258.237-1.725.712a2.386 2.386 0 0 0-.7 1.738Zm-3.625 14.3h12.1a.31.31 0 0 0 .225-.1.31.31 0 0 0 .1-.225V8.05a.31.31 0 0 0-.1-.225.311.311 0 0 0-.225-.1h-2.475v2.45a.559.559 0 0 1-.575.55.559.559 0 0 1-.575-.55v-2.45h-4.85v2.45c0 .15-.054.279-.162.387a.559.559 0 0 1-.413.163.559.559 0 0 1-.575-.55v-2.45H5.95a.31.31 0 0 0-.225.1.31.31 0 0 0-.1.225v12.1c0 .083.033.158.1.225a.31.31 0 0 0 .225.1Z"
      />
    </Svg>
  );
};
