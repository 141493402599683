import * as LDClient from 'launchdarkly-js-client-sdk';
import { LDFlagChangeset, LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { isEqual, merge } from 'lodash';
import uuid from 'uuid';

import { getApiKey, getCurrentCommitHash, platform, sanityDataset } from 'utils/environment';
import { getMobileOS } from 'utils/get-mobile-os';
import { loadLanguage } from 'utils/intl/language';
import { loadRegion } from 'utils/intl/region';
import LocalStorage from 'utils/local-storage';
import { Keys } from 'utils/local-storage/constants';
import logger from 'utils/logger';

import { FlagType, LaunchDarklyFlag } from './flags';

export * from './flags';

export type LDContext = LDSingleKindContext;
export type LDFlagSet = LDClient.LDFlagSet;

const createBaseUserKeys = async (deviceId?: string) => {
  const mobileOS = await getMobileOS();
  return normalizeUserAttributes({
    kind: 'user',
    host: window.location.host,
    platform: platform(),
    mobileOS,
    device_id: deviceId || '',
    commit: getCurrentCommitHash(),
    userClient: window.navigator.userAgent || '',
    appVersion: getCurrentCommitHash(),
    language: loadLanguage(),
    sanityDataset: `${sanityDataset()}_${loadRegion().toLowerCase()}`,
    country: loadRegion(),
  });
};

const createAnonymousUserAttributes = async (deviceId?: string): Promise<LDSingleKindContext> => {
  return normalizeUserAttributes({
    ...(await createBaseUserKeys(deviceId)),
    key: deviceId || uuid(),
    anonymous: true,
  });
};

export const initLaunchDarkly = async (): Promise<Record<string, unknown>> => {
  const ldClient = await LaunchDarklyHelper.init();

  return new Promise<Record<string, unknown>>((resolve, reject) => {
    ldClient.on('initialized', () => {
      const allFlags = ldClient.allFlags();
      const instance = LaunchDarklyHelper.getInstance();
      instance.allFlagsSync = allFlags;
      const flattenedFlags = LaunchDarklyHelper.flattenFlags(allFlags);

      resolve(flattenedFlags);
    });

    ldClient.on('failed', () => reject(new Error('LD Init failed')));
    ldClient.on('error', error => reject(error));
  }).catch(error => {
    logger.error(error);
    return {};
  });
};

export type LaunchDarklyFlagsObject = { [F in LaunchDarklyFlag]?: FlagType<F> };

export type UserAttributeUpdates = LDSingleKindContext;

export type AnonymousUserAttributes = LDSingleKindContext & { anonymous: true };

export type UserAttributes = AnonymousUserAttributes & UserAttributeUpdates;

export default class LaunchDarklyHelper {
  private static instance: LaunchDarklyHelper;
  // @ts-expect-error TS(2564) FIXME: Property 'launchDarkly' has no initializer and is ... Remove this comment to see the full error message
  public launchDarkly: LDClient.LDClient;
  // @ts-expect-error TS(2564) FIXME: Property '_userAttributes' has no initializer and ... Remove this comment to see the full error message
  private _userAttributes: LDSingleKindContext;
  /** this isn't guaranteed to be up-to-date or even populated, but will be as of last call or change listener response */
  public allFlagsSync: LDFlagSet | null = null;

  public get userAttributes() {
    return this._userAttributes;
  }

  public async initUserAttributes() {
    const cachedUser = LocalStorage.getItem(Keys.LAUNCH_DARKLY_USER_ATTRIBUTES);
    if (cachedUser) {
      this._userAttributes = merge({}, cachedUser, await createBaseUserKeys());
    } else {
      this._userAttributes = await createAnonymousUserAttributes();
    }

    return this.userAttributes;
  }

  public async updateCurrentUser(changes: UserAttributeUpdates) {
    const normalized = normalizeUserAttributes(changes);
    const originalAttributes = this._userAttributes;
    const mergedAttributes = merge({}, originalAttributes, normalized);

    // Only update LD user if attributes are different via a deep comparison check
    let newFlags: LDClient.LDFlagSet | undefined;
    if (!isEqual(originalAttributes, mergedAttributes)) {
      this._userAttributes = mergedAttributes;
      const flags = await this.launchDarkly.identify(mergedAttributes);
      this.allFlagsSync = flags;
      newFlags = LaunchDarklyHelper.flattenFlags(flags);
      LocalStorage.setItem(Keys.LAUNCH_DARKLY_USER_ATTRIBUTES, this.launchDarkly.getContext());
    }

    return { newFlags, userAttributes: mergedAttributes };
  }

  public clearCurrentUser = async () => {
    const originalAttributes = this._userAttributes;

    // Persist device id across user sessions
    const deviceId = originalAttributes?.custom?.device_id as string | undefined;
    const newAttributes = await createAnonymousUserAttributes(deviceId);

    const flags = await this.launchDarkly.identify(newAttributes);
    this.allFlagsSync = flags;
    const newFlags = LaunchDarklyHelper.flattenFlags(flags);
    this._userAttributes = newAttributes;
    LocalStorage.setItem(Keys.LAUNCH_DARKLY_USER_ATTRIBUTES, this.launchDarkly.getContext());
    return { newFlags, userAttributes: newAttributes };
  };

  public static getInstance(): LaunchDarklyHelper {
    if (!LaunchDarklyHelper.instance) {
      LaunchDarklyHelper.instance = new LaunchDarklyHelper();
    }
    return LaunchDarklyHelper.instance;
  }

  public static flattenFlags(allFlags: LDFlagSet): LaunchDarklyFlagsObject {
    const flattened: LaunchDarklyFlagsObject = {};
    for (const key in allFlags) {
      // @ts-ignore
      flattened[key] = allFlags[key];
    }

    return flattened;
  }

  // Making this async because the rn varation is also async
  public evaluateFlagVariants = async () => {
    const updatedFlags = { ...this.launchDarkly.allFlags() };

    for (const flagName in updatedFlags) {
      if (typeof updatedFlags[flagName] === 'object') {
        const variationDetail = this.launchDarkly.variationDetail(flagName);

        if (variationDetail && typeof variationDetail.variationIndex === 'number') {
          updatedFlags[flagName] = `Variation ${variationDetail.variationIndex + 1}`;
        }
      }
    }

    return updatedFlags;
  };

  public addChangeListener = (callback: (flags: LaunchDarklyFlagsObject) => void) => {
    const changeCallback = (changes: LDFlagChangeset) => {
      const flattened = {};
      for (const key in changes) {
        // @ts-ignore
        flattened[key] = changes[key].current;
      }

      callback(flattened);
    };
    this.launchDarkly.on('change', changeCallback);

    return () => {
      this.launchDarkly.off('change', changeCallback);
    };
  };
  public static async init() {
    const helperInstance = this.getInstance();
    const config: LDClient.LDOptions = {
      privateAttributes: ['email', 'firstName', 'lastName', 'name', 'dateOfBirth', 'phoneNumber'],
      evaluationReasons: true,
    };

    // NOTE: cypress-v2 test suite requirement
    if (window.Cypress) {
      // Allow us to set initial values for launchDarkly.
      config.bootstrap = window._initial_cypress_feature_flags;
      // Avoid launchDarkly flag stream updates via window.EventSource.
      config.streaming = false;
      // Avoid launchDarkly event requests.
      config.sendEvents = false;
      // Opt out of diagnostic data
      config.diagnosticOptOut = true;
    }
    const initialUserAttributes = await helperInstance.initUserAttributes();

    const apiKey = getApiKey({ key: 'launchDarkly', region: loadRegion() });
    const ldClient = LDClient.initialize(apiKey, initialUserAttributes, config);
    ldClient.on('ready', () => {
      LocalStorage.setItem(Keys.LAUNCH_DARKLY_USER_ATTRIBUTES, ldClient.getContext());
    });
    helperInstance.launchDarkly = ldClient;
    return ldClient;
  }
}

const trimStringKeys = <T extends {}>(attributes: T): T =>
  Object.entries(attributes).reduce((acc, [key, value]) => {
    if (typeof value === 'object') {
      return { ...acc, [key]: value && trimStringKeys(value) };
    }

    if (typeof value === 'string') {
      return { ...acc, [key]: value.trim() };
    }

    return { ...acc, [key]: value };
  }, {} as T);

const emailAttributeLowerCase = ({ email, ...attributes }: UserAttributeUpdates) => ({
  ...attributes,
  ...(email ? { email: email.toLowerCase() } : null),
});

export const normalizeUserAttributes = (
  userAttributeUpdates: UserAttributeUpdates
): LDSingleKindContext => emailAttributeLowerCase(trimStringKeys(userAttributeUpdates));
