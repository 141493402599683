import { VStack } from '@fhs-legacy/universal-components';

export const ProductSelectionsContainer = VStack.withConfig({
  paddingTop: {
    base: 6,
    desktop: 0,
  },
  paddingBottom: {
    base: 6,
    desktop: 0,
  },
  _web: {
    display: 'block',
  },
});
