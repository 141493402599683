import React from 'react';
import { useIntl } from 'react-intl';

import { makeUclComponent } from '@fhs-legacy/universal-components';
import Link, { IInLineLinkProps } from 'components/link';
import { getDirectionsAppUri } from 'utils/native-actions';

import theme from './theme';

const DirectionsLinkStyled = Link.withConfig({
  color: theme.innerAnchorColor,
  fontWeight: 'bold',
  fontSize: 'md',
  isUnderlined: true,
});

type Props = Omit<IInLineLinkProps, 'to' | 'isInline'> & {
  latitude: number;
  longitude: number;
};

export const DirectionsLink = makeUclComponent(({ latitude, longitude, ...rest }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <DirectionsLinkStyled
      {...rest}
      isExternal
      isInline
      to={getDirectionsAppUri(latitude, longitude)}
    >
      {formatMessage({ id: 'directions' })}
    </DirectionsLinkStyled>
  );
});
