import React from 'react';

import { Box, Header, Text } from '@fhs-legacy/universal-components';
import { ProgressBar } from 'components/progress-bar';

import theme from './theme';
import { IFreeDeliveryProgressProps } from './types';

const FREE_DELIVERY_ELIGIBLE = 100;
const Container = Box.withConfig({
  alignItems: 'center',
  borderTopWidth: '1px',
  borderTopColor: '#aaaaaa',
  paddingTop: '$2.5',
  marginTop: '$2.5',
});

const Title = Header.withConfig({
  variant: 'headerThree',
  color: theme.freeDeliveryTitleColor,
  fontWeight: 'bold',
});

const SubText = Text.withConfig({
  color: theme.subTextColor,
});

const FreeDeliveryProgress: React.FC<React.PropsWithChildren<IFreeDeliveryProgressProps>> = ({
  freeDeliveryPercentageComplete,
  titleLabel,
  freeDeliveryReachedLabel,
  freeDeliveryInProgressLabel,
}) => (
  <Container testID="free-delivery-progress">
    <Title>{titleLabel}</Title>
    <ProgressBar percentage={freeDeliveryPercentageComplete} fillColor={theme.progressFillColor} />
    <SubText>
      {FREE_DELIVERY_ELIGIBLE === freeDeliveryPercentageComplete
        ? freeDeliveryReachedLabel
        : freeDeliveryInProgressLabel}
    </SubText>
  </Container>
);

export default FreeDeliveryProgress;
