import * as React from 'react';

import LoadingAnimation from '../../components/loading-animation';

import { ContentLoadingContainer } from './support-form-v2.styled';

/**
 * Full content area view with loading animation.
 */
export const LoadingView = () => (
  <ContentLoadingContainer>
    <LoadingAnimation />
  </ContentLoadingContainer>
);
