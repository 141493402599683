import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { Box, Text } from '@fhs-legacy/universal-components';
import { IServerOrder } from '@rbi-ctg/menu';
import ActionButton, { IActionButtonProps } from 'components/action-button';
import theme from 'pages/order-confirmation/theme';
import { ServiceMode, useOrderContext } from 'state/order';

import { usePrepareNow } from './use-prepare-now';

const MessageContainer = Box.withConfig({
  paddingTop: '$8',
  paddingX: '$4',
});

const ErrorMessage = Text.withConfig({
  color: Styles.color.error,
  textAlign: 'center',
});

const BoldText = Text.withConfig({
  color: theme.textCartConfirmationColor,
  fontWeight: 'bold',
  textAlign: 'center',
});

export const PrepareNowButton: FC<
  React.PropsWithChildren<IActionButtonProps & { serverOrder: IServerOrder }>
> = props => {
  const { fireOrderInXSeconds } = useOrderContext();
  const { serverOrder } = props;
  const { formatMessage } = useIntl();
  const { showPrepareNowButton, hasPrepareNowBeenPressed, showErrorMessage, loading, onClick } =
    usePrepareNow({
      fireOrderInDelay: serverOrder.fireOrderIn || 0,
      createdAtTimeString: serverOrder.createdAt,
      serviceMode: serverOrder.cart.serviceMode,
      fireOrderInXSeconds,
      rbiOrderId: serverOrder.rbiOrderId,
    });

  const buttonMessage =
    serverOrder.cart.serviceMode === ServiceMode.CURBSIDE ? 'imHere' : 'prepareNow';
  const yourOrderIsBeingPrepared =
    serverOrder.cart.serviceMode !== ServiceMode.CURBSIDE
      ? 'yourOrderIsBeingPrepared'
      : 'yourOrderIsBeingPreparedCurbside';

  return (
    <>
      {showErrorMessage && (
        <MessageContainer>
          <ErrorMessage>{formatMessage({ id: 'updateOrderErrorMessage' })}</ErrorMessage>
        </MessageContainer>
      )}
      {showPrepareNowButton && (!hasPrepareNowBeenPressed || loading) && (
        <Box marginY="$6" marginX="auto" justifyContent="center">
          <ActionButton testID="im-here-button" isLoading={loading} onPress={onClick} {...props}>
            {formatMessage({ id: buttonMessage })}
          </ActionButton>
        </Box>
      )}
      {!loading && hasPrepareNowBeenPressed && (
        <Box marginY="$6" marginX="auto">
          <BoldText>{formatMessage({ id: yourOrderIsBeingPrepared })}</BoldText>
        </Box>
      )}
    </>
  );
};
