import React, { useMemo } from 'react';
import { Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { XStack, tokens } from '@fhs/ui';

interface Props {
  totalPages: number;
  selectedPage: number;
  onPressPage?: (page: number) => void;
  dotSize?: number;
  style?: StyleProp<ViewStyle>;
}

export const PaginationDots = ({
  selectedPage,
  totalPages,
  onPressPage,
  dotSize,
  style,
}: Props) => {
  const paginationDots = useMemo(
    () =>
      [...Array(totalPages).keys()].map(page => {
        const size = dotSize ?? 12;
        const isSelected = page === selectedPage;
        const onPress = onPressPage ? () => onPressPage(page) : undefined;
        const backgroundColor = isSelected
          ? tokens.colors.$houseRedDarken
          : tokens.colors.$blackOpacity10;

        return (
          <Pressable
            onPress={onPress}
            style={{
              height: size,
              width: size,
              backgroundColor,
              borderRadius: size / 2,
            }}
          />
        );
      }),
    [selectedPage, totalPages, dotSize, onPressPage]
  );
  return <XStack style={[styles.container, style]}>{paginationDots}</XStack>;
};

const styles = StyleSheet.create({
  container: {
    gap: 12,
  },
});
