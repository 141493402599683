import { MqSwitch } from '@fhs/ui';

import { ScreenContentDesktop } from './screen-content-desktop';
import { ScreenContentMobile } from './screen-content-mobile';
import { ScreenContentProps } from './types';

export function ScreenContent(props: ScreenContentProps) {
  return (
    <MqSwitch
      $ltDesktop={() => <ScreenContentMobile {...props} />}
      $gteDesktop={() => <ScreenContentDesktop {...props} />}
    />
  );
}
