import React from 'react';

import { Box } from '@fhs-legacy/universal-components';
import LoadingAnimation from 'components/loading-animation';

const LoadingContainer = Box.withConfig({
  flexDirection: 'row',
  flexGrow: 2,
  flexShrink: 1,
  flexBasis: 0,
  backgroundColor: Styles.color.grey.four,
  justifyContent: 'center',
  alignItems: 'center',
});

const Fallback = () => {
  return (
    <LoadingContainer testID="order-confirmation-fallback">
      <LoadingAnimation />
    </LoadingContainer>
  );
};

export default Fallback;
