import * as ExpoUpdates from 'expo-updates';
import React from 'react';
import { DevSettings, Platform } from 'react-native';

import { IconErrorFill, IconRefresh, tokens } from '@fhs/ui';

import { GenericCard, IGenericCardProps } from '../generic-card';

const handleRefresh = () => {
  if (Platform.OS === 'web') {
    window.location.reload();
  } else if (__DEV__) {
    DevSettings.reload();
  } else {
    ExpoUpdates.reloadAsync();
  }
};

interface IErrorCardProps extends Partial<IGenericCardProps> {}

/**
 * A reusable card component that displays a title, description, and a customizable button.
 * It can also optionally display an icon and supports different button types.
 *
 * @param title - The title text displayed at the top of the card.
 * @param description - A brief description or message displayed below the title.
 * @param buttonTitle - The text for the button at the bottom of the card.
 * @param buttonHandle - The function to be called when the button is pressed.
 * @param buttonIcon - An optional icon to display inside the button.
 * @param buttonType="solid" - The style/type of the button. Defaults to "solid".
 * @param icon - An optional icon to display at the top of the card.
 * @param style - Additional styling for the card container.
 *
 * @returns {JSX.Element} A card component with a title, description, and button, optionally with icons.
 *
 * @example
 * <ErrorCard
 *   title="Upgrade your plan"
 *   description="Get access to premium features by upgrading your plan."
 *   buttonTitle="Upgrade Now"
 *   buttonHandle={() => console.log('Upgrade clicked')}
 *   buttonIcon={<IconUpgrade />}
 *   icon={<IconPremium />}
 * />
 */
export const ErrorCard = ({
  title = 'Oops, Something Went Wrong!',
  description = 'Brace yourself till we get the error fixed. You may refresh the page or try again later',
  buttonTitle = 'Refresh Page',
  buttonIcon = <IconRefresh color={tokens.colors.$white} />,
  icon = <IconErrorFill size={80} color={tokens.colors.$blackOpacity10} />,
  buttonHandle = handleRefresh,
  ...genericCardProps
}: IErrorCardProps) => {
  return (
    <GenericCard
      buttonTitle={buttonTitle}
      title={title}
      description={description}
      buttonIcon={buttonIcon}
      icon={icon}
      buttonHandle={buttonHandle}
      {...genericCardProps}
    />
  );
};
