import { Link } from 'expo-router';
import { useIntl } from 'react-intl';

import { Text, TextProps } from '@fhs-legacy/universal-components';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useUIContext } from 'state/ui';
import Styles from 'styles/index';
import { region } from 'utils/environment';

type MaxCartTotalAlertProps = {
  color?: TextProps['color'];
  fontSize?: TextProps['fontSize'];
  isMaxCartTotalBold?: boolean;
};

export const MaxCartTotalAlert = ({
  color = Styles.color.black,
  fontSize,
  isMaxCartTotalBold,
}: MaxCartTotalAlertProps) => {
  const maxCartTotal: number = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_LIMIT);
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale } = useUIContext();
  const formattedCartMaxTotal = formatCurrencyForLocale(maxCartTotal);
  const suggestion =
    region() === 'CA' ? (
      formatMessage({ id: 'callYourSelectedStore' })
    ) : (
      <Link href="https://www.ezcater.com/brand/pvt/firehouse-subs" asChild target="_blank">
        <Text color={color} underline>
          {formatMessage({ id: 'orderHere' }).toLowerCase()}.
        </Text>
      </Link>
    );
  return (
    <Text color={color} fontSize={fontSize}>
      {formatMessage(
        { id: 'cartTotalMaxAlert' },
        {
          maxAmount: (
            <Text bold={isMaxCartTotalBold} color={color}>
              {formattedCartMaxTotal}
            </Text>
          ),
          suggestion,
        }
      )}
    </Text>
  );
};
