import { isAfter, isToday, isTomorrow } from 'date-fns';
import { ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Box, Skeleton } from '@fhs-legacy/universal-components';
import { IRestaurantTimeSlot, useGetRestaurantQuery } from 'generated/graphql-gateway';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { useDefaultServiceMode } from 'hooks/store-card';
import { getSanitizedTimeSlots } from 'hooks/use-date-picker-options/utils';
import { useLocale } from 'state/intl';
import { format } from 'utils/language/format-date';
import { readableHour } from 'utils/restaurant';

export const useNextOpenDay = ({
  restaurant,
  isModalOpen,
}: {
  restaurant: IRestaurantNode;
  isModalOpen: boolean;
}): ReactNode => {
  const { language } = useLocale();
  const { formatMessage } = useIntl();
  const serviceMode = useDefaultServiceMode({ restaurant });
  const { data, loading } = useGetRestaurantQuery({
    variables: { storeNumber: restaurant.number },
    skip: !restaurant.number || !isModalOpen,
  });
  const result = useMemo(() => {
    if (!data || data.restaurant?.futureServiceHours.length === 0) {
      return null;
    }
    const storeData = data.restaurant.futureServiceHours.find(
      item => item.serviceMode === serviceMode
    );
    if (!storeData?.timeSlots?.length) {
      return null;
    }
    const sanitizedTimeSlots = getSanitizedTimeSlots(
      storeData?.timeSlots as IRestaurantTimeSlot[],
      7
    );
    const nextOpenDayTimeSlot = sanitizedTimeSlots.find(timeSlot => {
      const opensDate = new Date(timeSlot.opens);
      return !timeSlot.isClosed && isAfter(opensDate, new Date());
    });
    if (!nextOpenDayTimeSlot) {
      return null;
    }
    const nextOpenDate = new Date(nextOpenDayTimeSlot.opens);
    const hour = readableHour({ timeString: nextOpenDayTimeSlot.opens });
    if (isToday(nextOpenDate)) {
      return formatMessage({ id: 'restaurantOpensAt' }, { hour });
    }
    if (isTomorrow(nextOpenDate)) {
      return formatMessage({ id: 'restaurantOpensTomorrowAt' }, { hour });
    }
    const day = format(nextOpenDate, 'MMMM do', language);
    return formatMessage({ id: 'restaurantOpensOnDayAt' }, { day, hour });
  }, [data, formatMessage, language, serviceMode]);
  if (loading) {
    return <Skeleton h="$6" w="$56" mx="auto" />;
  }
  return <Box h="$6">{result}</Box>;
};
