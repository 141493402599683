import { router } from 'expo-router';
import { useIntl } from 'react-intl';

import { Text, useDropdownSetters } from '@fhs/ui';
import { useIsDesktopBp } from '@fhs-legacy/frontend/src/hooks/breakpoints';
import { usePathname } from '@fhs-legacy/frontend/src/hooks/use-pathname';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { usePypestreamContext } from '@fhs-legacy/frontend/src/state/pypestream';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';

export const SubmitFeedbackHeaderLink = () => {
  const isDesktop = useIsDesktopBp();
  const enablePypestream = useFlag(LaunchDarklyFlag.ENABLE_PYPESTREAM);
  const { setRedirectToHomePage } = usePypestreamContext();
  const { setIsExpanded } = useDropdownSetters();
  const { formatMessage } = useIntl();
  const pathname = usePathname();

  return (
    <Text.Paragraph
      size="md"
      onPress={() => {
        if (isDesktop && enablePypestream) {
          setIsExpanded(false);
          setRedirectToHomePage(true);
          if (pathname !== routes.base) {
            router.navigate(routes.base);
          }
        } else {
          router.navigate(routes.support);
        }
      }}
    >
      {formatMessage({ id: 'submitFeedback' })}
    </Text.Paragraph>
  );
};
