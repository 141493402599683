import { Box } from '@fhs-legacy/universal-components';

const AuthContainer = Box.withConfig({
  borderRadius: 0,
  minHeight: 'full',
  marginX: 'auto',
  paddingX: '$10',

  marginBottom: {
    base: '$4',
    desktop: 0,
  },

  paddingY: {
    base: '$4',
    desktop: 0,
  },

  maxWidth: {
    desktop: '600px',
  },
});

export default AuthContainer;
