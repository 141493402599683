import { useFeatureLoyaltyDashboardQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

export const useFeaturesLoyaltyDashboard = () => {
  const { featureLoyaltyUIId = '', featureIdsLoading } = useFeaturesContext();
  const { data, loading } = useFeatureLoyaltyDashboardQuery({
    variables: { featureLoyaltyUIId },
    skip: !featureLoyaltyUIId,
  });
  return {
    data: data?.LoyaltyUI?.loyaltyDashboard,
    loading: featureIdsLoading || loading,
  };
};
