import { excludeDisabledMenuSections, separateBreakfastMenu } from 'pages/main-menu/utils';
import { DAY_PART_SELECTIONS } from 'state/main-menu/types';

import { IGetTabbedMenuData } from './types';

export function getTabbedMenuData({
  dayPartMenu,
  mainMenuItems,
  activeDayParts = [],
  showBurgersForBreakfast,
  serviceMode,
  showStaticMenu,
}: IGetTabbedMenuData) {
  const activeMenuOptions = activeDayParts?.length
    ? excludeDisabledMenuSections({
        menuData: mainMenuItems,
        activeDayParts,
        showBurgersForBreakfast,
        serviceMode,
        showStaticMenu,
      })
    : mainMenuItems;

  if (dayPartMenu === DAY_PART_SELECTIONS.ALL_KEY || !dayPartMenu) {
    return activeMenuOptions;
  }

  const { menuData, breakfastMenuData } = separateBreakfastMenu(activeMenuOptions);

  const shouldRenderBreakfast =
    !!breakfastMenuData?.length && dayPartMenu === DAY_PART_SELECTIONS.BREAKFAST_KEY;
  return shouldRenderBreakfast ? breakfastMenuData : menuData;
}
