import { memoize } from 'lodash';

import en from 'state/translations/en.json';
import en_CA from 'state/translations/en_CA.json';
import fr from 'state/translations/fr.json';
import fr_CA from 'state/translations/fr_CA.json';

const locales = {
  en,
  en_CA,
  fr,
  fr_CA,
} as const;

export const getMessagesForLanguage = memoize((language: 'en' | 'fr', region: 'US' | 'CA') => {
  return {
    ...(locales[language] ?? null),
    ...(locales[`${language}_${region}`] ?? null),
  } as Readonly<typeof en>;
});
