import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { FormControl, Select, Text } from '@fhs-legacy/universal-components';
import { ISOs } from 'utils/form/constants';

import { ICountrySelectBaseProps } from './types';

const messageIdMap = {
  [ISOs.USA]: 'unitedStates',
  [ISOs.CAN]: 'canada',
} as const;

export const Disclaimer = Text.withConfig({
  variant: 'copyTwo',
  textAlign: 'center',
});

const CountrySelectBase = ({
  countries,
  label,
  value,
  onChange,
  testID,
  disclaimer,
}: ICountrySelectBaseProps) => {
  const { formatMessage } = useIntl();
  const [selectedValue, setSelectedValue] = useState(value);

  const setSelectedCountry = (countryCode: string) => {
    if (onChange) {
      onChange(countryCode);
    }
    setSelectedValue(countryCode);
  };

  return (
    <FormControl>
      <FormControl.Label>{label}</FormControl.Label>
      <Select
        testID={testID}
        placeholder={
          selectedValue
            ? formatMessage({ id: messageIdMap[selectedValue] })
            : formatMessage({ id: 'selectACountry' })
        }
        selectedValue={selectedValue}
        onValueChange={setSelectedCountry}
      >
        {countries?.map(countryCode => (
          <Select.Item
            key={countryCode}
            value={countryCode}
            label={formatMessage({ id: messageIdMap[countryCode] })}
          />
        ))}
      </Select>
      {disclaimer && <Disclaimer>{disclaimer}</Disclaimer>}
    </FormControl>
  );
};

export default CountrySelectBase;
