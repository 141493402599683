import { IFeatureLayoutQuery, useFeatureLayoutQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

interface IUseFeatureLayoutValue {
  featureLayout: IFeatureLayoutQuery['FeatureLayout'];
  featureLayoutLoading: boolean;
}

export const useFeatureLayout = (): IUseFeatureLayoutValue => {
  const { featureLayoutId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useFeatureLayoutQuery({
    variables: { featureLayoutId },
    skip: !featureLayoutId,
  });

  const featureLayout = data?.FeatureLayout ?? null;

  const featureLayoutLoading = featureIdsLoading || loading;

  return {
    featureLayout,
    featureLayoutLoading,
  };
};
