import { Redirect } from 'expo-router';
import Head from 'expo-router/head';
import { useIntl } from 'react-intl';

import { ScreenContent } from '@fhs/screen-content';
import Footer from 'components/footer';
import { PaymentMethodFlatListWithButton } from 'components/payment-method-flat-list-with-button';
import { useAuthContext } from 'state/auth';
import { routes } from 'utils/routing';

const PaymentMethods = () => {
  const { isAuthenticated } = useAuthContext();
  const { formatMessage } = useIntl();

  if (!isAuthenticated) {
    return <Redirect href={routes.base} />;
  }

  return (
    <>
      <Head>
        <title>{`${formatMessage({ id: 'paymentMethods' })} - Firehouse Subs`}</title>
      </Head>
      <ScreenContent after={<Footer />}>
        <PaymentMethodFlatListWithButton />
      </ScreenContent>
    </>
  );
};

export default PaymentMethods;
