import React, { VFC } from 'react';

import { IBackendCartEntries } from '@rbi-ctg/menu';
import { ItemWidth, ResponsiveCarousel } from 'components/responsive-carousel';
import { IResponsiveCarouselProps } from 'components/responsive-carousel/types';
import { useIsMobileBp } from 'hooks/breakpoints';

export interface ICardCarouselProps
  extends Pick<IResponsiveCarouselProps<IBackendCartEntries>, 'renderItem' | 'keyExtractor'> {
  items: IBackendCartEntries[];
  bgColor?: string;
}

const CarouselContainer: VFC<ICardCarouselProps> = ({
  bgColor,
  items,
  renderItem,
  keyExtractor,
}) => {
  const isMobile = useIsMobileBp();
  const itemWidth = isMobile
    ? ItemWidth.VERTICAL_CAROUSEL_ITEM_MOBILE
    : ItemWidth.VERTICAL_CAROUSEL_ITEM_WEB;
  return (
    <>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ itemWidth: number; bgColor: string | undef... Remove this comment to see the full error message */}
      <ResponsiveCarousel
        itemWidth={itemWidth}
        bgColor={bgColor}
        data={items}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        loop={false}
        inactiveSlideOpacity={1}
        inactiveSlideScale={0.9}
      />
    </>
  );
};

export default CarouselContainer;
