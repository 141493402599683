import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { IBaseItem, ICartEntry } from '@rbi-ctg/menu';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import SectionHeading from 'pages/cart/section-heading';
import { useCartContext } from 'state/cart';
import { ICartContext } from 'state/cart/types';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { useMenuContext } from 'state/menu';
import { useOrderContext } from 'state/order';
import { createCartEntry } from 'utils/cart';
import { routes } from 'utils/routing';

import { UpsellItems, UpsellSectionContainer, UpsellTitle } from './styles';
import { IUpsellProps } from './types';
import UpsellItem from './upsell-item';
import { UpsellSkeleton } from './upsell.skeleton';

export const Upsell: React.FC<IUpsellProps> = ({
  isInCart,
  items,
  loading,
  recommender,
  recommendationToken,
}) => {
  const { logRBIEvent } = useCRMEventsContext();
  const { pricingFunction } = useMenuContext();

  const useClosestCtx = isInCart ? useCartContext : useOrderContext;
  const { addItemToCart }: { addItemToCart: ICartContext['addItemToCart'] } = useClosestCtx();

  const { formatMessage } = useIntl();

  const [orderAddedAlertMessage, setOrderAddedAlertMessage] = useState('');

  const handleAddToCart = useCallback(
    (item: IBaseItem) => {
      const entry: ICartEntry = {
        ...createCartEntry({
          item,
          price: pricingFunction(item),
          pathname: `${routes.menu}/${item._type}-${item._id}`,
        }),
        isUpsell: true,
        recommendationToken,
        recommender,
      };
      addItemToCart(entry);

      const upsellItemIndex = items.findIndex(upsellItem => upsellItem._id === item._id);
      const upsellItemPosition = upsellItemIndex >= 0 ? upsellItemIndex + 1 : undefined;

      if (entry.isUpsell) {
        logRBIEvent({
          name: CustomEventNames.UPSELL_ADDED,
          type: EventTypes.Other,
          attributes: {
            name: entry.name,
            sanityId: entry._id,
            ...(entry.price && { price: entry.price / 100 }),
            upsellItemPosition,
          },
        });
      }

      setOrderAddedAlertMessage(
        formatMessage({ id: 'itemAddedToCart' }, { itemName: item.name?.locale })
      );
    },
    [
      addItemToCart,
      formatMessage,
      items,
      logRBIEvent,
      pricingFunction,
      recommendationToken,
      recommender,
    ]
  );

  if (!items.length && !loading) {
    return null;
  }

  const title = formatMessage({ id: 'addToOrderHeader' });

  return (
    <UpsellSectionContainer testID="upsell">
      {isInCart ? (
        <SectionHeading testID="add-to-order-header">{title}</SectionHeading>
      ) : (
        <UpsellTitle>{title}</UpsellTitle>
      )}
      {loading && !items.length ? (
        <UpsellSkeleton />
      ) : (
        <UpsellItems>
          {items.map(item => (
            <UpsellItem key={item._id} item={item} addToCard={handleAddToCart} />
          ))}
        </UpsellItems>
      )}
      {orderAddedAlertMessage.length > 0 && (
        <VisuallyHidden role="alert" accessibilityLabel={orderAddedAlertMessage} />
      )}
    </UpsellSectionContainer>
  );
};
