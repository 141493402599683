import { ICommitOrderOptions } from 'hooks/commit-order';
import { ICommitOrderMutationInput } from 'remote/queries/order';
import { ServiceMode } from 'state/service-mode/types';
import { splitExpiry } from 'utils/payment';

export const buildCommitOrderInput = ({
  applePayDetails,
  billingAddress,
  ccBin,
  ccExpiry,
  ccLast4,
  creditType,
  fdAccessToken,
  fdAccountId,
  fdNonce,
  fireOrderIn,
  fullName,
  googlePayDetails,
  order,
  payment,
  rbiOrderId,
  chaseProfileId,
  storeEmail,
  pickUpTipsCents,
}: ICommitOrderOptions): ICommitOrderMutationInput => {
  const input: ICommitOrderMutationInput = {
    applePayDetails,
    billingAddress,
    creditType,
    fireOrderIn,
    fullName,
    payment,
    googlePayDetails,
    rbiOrderId,
    pickUpTipsCents,
  };

  if (fdAccountId) {
    input.fdAccountId = fdAccountId;
  } else if (fdAccessToken) {
    input.fdNonce = fdNonce;
    input.fdAccessToken = fdAccessToken;
    input.ccBin = ccBin;
    input.ccLast4 = ccLast4;
  }

  if (chaseProfileId) {
    // Send the chaseProfileId we're using TH direct pay
    input.chaseProfileId = chaseProfileId;
  }

  if (ccExpiry) {
    const { expiryMonth, expiryYear } = splitExpiry(ccExpiry);
    input.ccExpiryMonth = expiryMonth;
    input.ccExpiryYear = expiryYear;
    if (input.payment && input.payment.ccMetadata) {
      input.payment.ccMetadata.ccExpiryMonth = expiryMonth;
      input.payment.ccMetadata.ccExpiryYear = expiryYear;
    }
  }

  if (
    storeEmail &&
    (order.serviceMode === ServiceMode.CATERING_PICKUP || ServiceMode.CATERING_DELIVERY)
  ) {
    input.storeEmail = storeEmail;
  }

  return input;
};
