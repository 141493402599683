import { router } from 'expo-router';
import React from 'react';

import { Box, VStack } from '@fhs-legacy/universal-components';
import { OnClick } from '@rbi-ctg/frontend';
import CloseButton, { CloseButtonIconProps } from 'components/close-button';
import { useIsMobileBp } from 'hooks/breakpoints';

import { HeaderWrapper, Heading } from './styled';

const CloseButtonWrapper = Box.withConfig({
  position: 'absolute',
  left: '$3',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '$10',
  _web: {
    paddingTop: '$1',
  },
});

interface IFullPageScreenHeaderProps {
  heading: string;
  closeHref?: string;
  onClose?: OnClick;
  disableClose?: boolean;
  skipGoBackOnClose?: boolean;
  closeIcon?: CloseButtonIconProps;
}

const FullPageScreenHeader: React.FC<React.PropsWithChildren<IFullPageScreenHeaderProps>> = ({
  heading,
  closeHref,
  onClose,
  disableClose,
  skipGoBackOnClose = false,
  closeIcon = 'cancel',
}) => {
  return (
    <VStack alignItems="stretch">
      <HeaderWrapper>
        {!disableClose && (
          <CloseButtonWrapper>
            <CloseButton
              onClick={e => {
                if (onClose) {
                  onClose(e);
                  return;
                }

                if (closeHref) {
                  router.navigate(closeHref);
                  return;
                }

                if (!skipGoBackOnClose) {
                  // TODO: RN - Check if this is ok
                  router.back();
                }
              }}
              icon={closeIcon}
            />
          </CloseButtonWrapper>
        )}
        <Heading>{heading}</Heading>
      </HeaderWrapper>
    </VStack>
  );
};

export default function PortaledHeader(props: IFullPageScreenHeaderProps) {
  const isMobile = useIsMobileBp();

  return isMobile ? null : <FullPageScreenHeader {...props} />;
}
