import { Image } from 'expo-image';
import { type ReactNode, forwardRef, useEffect } from 'react';
import { useRef } from 'react';
import { ScrollView, type ScrollViewProps, StyleSheet, View } from 'react-native';

import { useMenuClickEvents } from '@fhs/menu/src/analytics';

import { tokens } from '../../tokens';
import type { ImageAssetWithAltText } from '../../types';
import { Pressable, PressableProps, usePressableState } from '../pressable';
import { Text } from '../text';

import { useAnchors } from './use-anchors';
import { useScrollIntoFirstPositionInView } from './use-scroll-into-first-position-in-view';

type VerticalScrollerProps = ScrollViewProps;

const VerticalScroller = forwardRef<ScrollView, VerticalScrollerProps>((props, forwardedRef) => {
  return (
    <ScrollView
      testID="anchor-list-vertical"
      ref={forwardedRef}
      {...props}
      style={[scrollerStyles.sidebarScroller, props.style]}
      contentContainerStyle={[scrollerStyles.sidebarContent, props.contentContainerStyle]}
    >
      {props.children}
    </ScrollView>
  );
});

const SCROLLER_ITEM_GAP = 12;

const scrollerStyles = StyleSheet.create({
  sidebarScroller: {
    height: '100%',
    width: 312,
    flexGrow: 0,
    borderRightWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    backgroundColor: tokens.colors.$white,
  },
  sidebarContent: {
    padding: 32,
    gap: SCROLLER_ITEM_GAP,
  },
});

export type AnchorListVerticalProps = VerticalScrollerProps & {
  contentStart?: ReactNode;
  contentEnd?: ReactNode;
};

export function AnchorListVertical({
  contentStart,
  contentEnd,
  ...verticalScrollerProps
}: AnchorListVerticalProps) {
  const { scrollerRef, scrollIntoFirstPositionInView, handleScrollerLayout, handleScrollerScroll } =
    useScrollIntoFirstPositionInView('vertical');
  const anchors = useAnchors();
  const { logMenuTabClick } = useMenuClickEvents();

  return (
    <VerticalScroller
      ref={scrollerRef}
      onLayout={handleScrollerLayout}
      onScroll={handleScrollerScroll}
      scrollEventThrottle={250}
      {...verticalScrollerProps}
    >
      {contentStart ? contentStart : null}
      {anchors.map((a, index) => (
        <Anchor
          key={`${a.id}-${index}`}
          isActive={a.isActive}
          image={a.image}
          onPress={() => {
            a.onPress();
            logMenuTabClick(a.title);
          }}
          title={a.title}
          scrollIntoFirstPositionInView={scrollIntoFirstPositionInView}
        />
      ))}
      {contentEnd ? contentEnd : null}
    </VerticalScroller>
  );
}

const Anchor = (props: {
  isActive: boolean;
  onPress: PressableProps['onPress'];
  image: ImageAssetWithAltText;
  title: string;
  scrollIntoFirstPositionInView: (opts: { position: number; offset?: number }) => void;
}) => {
  const ref = useRef<View>(null);
  const layoutRef = useRef({ x: 0, y: 0, height: 0, width: 0 });

  const { isActive, scrollIntoFirstPositionInView } = props;
  useEffect(() => {
    if (!isActive) {
      ref.current?.blur?.();
      return;
    }

    scrollIntoFirstPositionInView({
      position: layoutRef.current.y,
      offset: SCROLLER_ITEM_GAP,
    });
  }, [isActive, scrollIntoFirstPositionInView]);

  return (
    <Pressable
      ref={ref}
      onLayout={event => {
        layoutRef.current = event.nativeEvent.layout;
      }}
      onPress={props.onPress}
      style={[styles.pressable, props.isActive && styles.isActivePressable]}
      focusedStyle={styles.isFocusedPressable}
      hoveredStyle={styles.isHoveredPressable}
    >
      {props.image?.asset && (
        <Image
          // TODO: all images should be square.
          // Remove this when that's true
          contentFit="contain"
          source={props.image.asset}
          alt={props.image.altText}
          style={styles.image}
        />
      )}
      <Title isActive={props.isActive} title={props.title} />
    </Pressable>
  );
};

const Title = ({ isActive, title }: { isActive: boolean; title: string }) => {
  const pressableState = usePressableState();

  return (
    <Text.Paragraph
      size="sm"
      weight={isActive || pressableState.hovered ? 'semibold' : 'normal'}
      style={[isActive && styles.isActiveText, pressableState.hovered && styles.isHoveredText]}
    >
      {title}
    </Text.Paragraph>
  );
};

const styles = StyleSheet.create({
  pressable: {
    paddingVertical: 4,
    paddingHorizontal: 16,
    gap: 8,
    minHeight: 52,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  },
  isActivePressable: {
    backgroundColor: tokens.colors.$houseRedOpacity04,
  },
  isHoveredPressable: {
    backgroundColor: tokens.colors.$houseRedOpacity04,
  },
  isFocusedPressable: {},
  isActiveText: {
    color: tokens.colors.$houseRedDarken,
  },
  isHoveredText: {
    color: tokens.colors.$houseRedDarken,
  },
  isFocusedText: {
    color: tokens.colors.$houseRedDarken,
  },
  image: {
    width: 40,
    height: 40,
  },
});
