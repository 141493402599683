import { theme } from 'styles/configure-theme';

export const incrementorStyleProps = ({ disabled }: { disabled?: boolean }) => ({
  padding: 1,
  borderRadius: 20,
  borderWidth: 1,
  margin: 0.5,
  borderColor: theme.token(disabled ? 'icon-disabled' : 'text-active'),
  _icon: {
    color: theme.token(disabled ? 'icon-disabled' : 'text-active'),
    size: 'xs',
  },
});
