import { Platform } from 'react-native';

export const getDirectionsAppUri = (latitude: number | string, longitude: number | string) => {
  const latlng = (delimiter: string) => `${latitude}${delimiter}${longitude}`;

  if (
    Platform.OS === 'web' &&
    (navigator.platform.includes('iPhone') ||
      navigator.platform.includes('iPad') ||
      navigator.platform.includes('iPod'))
  ) {
    return `maps://app?daddr=${latlng(',')}`;
  }

  return `http://maps.google.com/maps?daddr=${latlng('+')}`;
};
