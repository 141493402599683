import { Box } from '@fhs-legacy/universal-components';

import { LoyaltyBackground } from '../loyalty-background';

// TODO: RN migrate to RN
const IncentivesGridDefault = {
  width: 'full',
  flex: 1,
  marginBottom: { desktop: '$10' },
  // TODO: RN migrate to RN
  // ${Styles.desktop`
  //   grid-template-columns: 450px 1fr;
  // `}
};

const IncentivesGridWidget = {
  display: 'flex',
};

export const IncentivesGrid = LoyaltyBackground.withConfig<{
  incentiveSelected: boolean;
  isWidget: boolean;
}>(p => (p.isWidget ? IncentivesGridWidget : IncentivesGridDefault));

export const IncentiveItemsContainer = Box.withConfig({
  padding: 0,
});

export const IncentiveList = Box.withConfig({
  flexGrow: 0,
  padding: 0,
  margin: 0,
  maxHeight: '100%',
  // TODO: RN migrate to RN
  // transition: 0.25s;
  // scroll-behavior: smooth;
});

export const IncentiveDetails = Box.withConfig({
  borderLeftWidth: '1px',
  borderLeftStyle: 'solid',
  borderLeftColor: 'Styles.color.grey.four',
});
