import {
  Text as RnText,
  type TextProps as RnTextProps,
  type TextStyle as RnTextStyle,
  StyleProp,
  StyleSheet,
} from 'react-native';

import { tokens } from '../../tokens';

type TextWeight = 'normal' | 'semibold' | 'bold';

export type TextProps = Omit<RnTextProps, 'style'> & {
  style?: StyleProp<Omit<RnTextStyle, 'fontWeight' | 'fontFamily'>>;
  weight?: TextWeight;
};

export function Text({ weight = 'normal', ...props }: TextProps) {
  return <RnText {...props} style={[textStyles.baseline, textStyles[weight], props.style]} />;
}

const textStyles = StyleSheet.create({
  baseline: {
    color: tokens.colors.$houseDark,
  },
  normal: {
    fontFamily: 'Montserrat',
  },
  semibold: {
    fontFamily: 'MontserratSemiBold',
  },
  bold: {
    fontFamily: 'MontserratBold',
  },
});
