import { Box, Text, Header as UclHeader } from '@fhs-legacy/universal-components';
import { LogoContainer as CommonLogoContainer } from 'pages/order-confirmation/common';

export const Container = Box.withConfig({
  backgroundColor: Styles.color.background,
  paddingTop: { base: '$6', desktop: '$12' },
  marginBottom: '$4',
});

export const LogoContainer = CommonLogoContainer.withConfig({
  marginTop: '$0',
  marginBottom: '$4',
});

export const Header = UclHeader.withConfig({
  fontSize: '3xl',
  lineHeight: '32px',
  textAlign: 'center',
});

export const InfoLine = Text.withConfig({
  variant: 'copyFour',
  textTransform: 'capitalize',
});

export const InfoWrapper = Box.withConfig({
  paddingY: '$4',
  alignSelf: 'center',
});

export const ActionButtonWrapper = Box.withConfig({
  width: { base: 'full', desktop: '340px' },
  alignSelf: 'center',
  marginBottom: '$8',
});

export const FutureOrderTimeCardWrapper = Box.withConfig({
  marginBottom: '$6',
});
