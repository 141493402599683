import React from 'react';

import { Text } from '@fhs-legacy/universal-components';
import Link from 'components/link';

export const TermsLink = Link.withConfig({
  isInline: true,
  isUnderlined: true,
  _text: {
    color: 'primary.500',
  },
});

const StyledText = Text.withConfig({
  variant: 'body',
  marginX: 0,
  marginLeft: '$2',
});

export const OptInLabel: React.FC<React.PropsWithChildren<{ testID?: string }>> = ({
  testID,
  children,
}) => <StyledText testID={testID}>{children}</StyledText>;
