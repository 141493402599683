import type { ReactNode } from 'react';

import { useControllableState } from '../../hooks';
import { addWithConfig } from '../../utils';
import { Box } from '../box';
import { Pressable } from '../pressable';
import { Text } from '../text';

import { type ToggleButtonGroupProps, type _ToggleButtonProps } from './types';

const BORDER_RADIUS = 'sm';
const BORDER_WIDTH_PX = 1;

function getStringLabelForOption(option?: {
  accessibilityLabel?: string;
  label: ReactNode;
  value: unknown;
}): string {
  if (!option) {
    return '';
  }

  if (option.accessibilityLabel) {
    return option.accessibilityLabel;
  }

  if (typeof option.label === 'string') {
    return option.label;
  }

  return '';
}

// eslint-disable-next-line complexity
function ToggleButton({
  label,
  accessibilityLabel,
  isFirst,
  isLast,
  isSelected,
  isDisabled,
  isInvalid,
  growToFit,
  ...props
}: _ToggleButtonProps) {
  return (
    <Pressable
      accessibilityRole="radio"
      accessibilityLabel={accessibilityLabel}
      accessibilityHint=""
      accessibilityState={{ disabled: isDisabled, selected: isSelected }}
      backgroundColor="token.background-input"
      minHeight="$12"
      paddingX="$4"
      justifyContent="center"
      flexGrow={growToFit ? '1' : '0'}
      flexShrink={1}
      borderWidth={`${BORDER_WIDTH_PX}px`}
      marginLeft={isFirst ? '0' : `-${BORDER_WIDTH_PX}px`}
      borderColor="token.border-color-input"
      _hover={{
        borderColor: 'token.border-color-hover',
        backgroundColor: 'blackOpacity.5',
        zIndex: 1,
      }}
      _pressed={{
        borderColor: 'token.border-color-hover',
        backgroundColor: 'blackOpacity.5',
        zIndex: 1,
      }}
      {...(isFirst && {
        borderTopLeftRadius: BORDER_RADIUS,
        borderBottomLeftRadius: BORDER_RADIUS,
      })}
      {...(isLast && {
        borderTopRightRadius: BORDER_RADIUS,
        borderBottomRightRadius: BORDER_RADIUS,
      })}
      {...(isInvalid && {
        borderColor: 'token.border-color-error',
        _hover: {
          backgroundColor: 'blackOpacity.5',
        },
      })}
      {...(isSelected && {
        backgroundColor: 'token.background-checked',
        borderColor: 'token.border-color-checked',
        zIndex: 1,
        _hover: {
          borderColor: 'token.border-color-hover',
        },
        _pressed: {
          borderColor: 'token.border-color-hover',
        },
      })}
      {...(isDisabled && {
        disabled: true,
        backgroundColor: 'token.background-input-disabled',
        borderColor: 'token.border-color-disabled',
        zIndex: 1,
        _hover: {},
        _pressed: {},
      })}
      {...props}
    >
      <Text
        variant="copyOne"
        textAlign="center"
        color="token.text-default"
        {...(isSelected && { color: 'token.text-reversed' })}
        {...(isDisabled && {
          color: 'token.text-disabled',
        })}
      >
        {label}
      </Text>
    </Pressable>
  );
}

/* eslint-disable-next-line complexity */
export function ToggleButtonGroup<ValueType extends string>({
  value,
  onChangeValue,
  options,
  isDisabled,
  isInvalid,
  accessibilityLabel,
  accessibilityHint,
  growToFit = true,
  _toggleButton,
  ...boxProps
}: ToggleButtonGroupProps<ValueType>) {
  const [selectedOptionValue, setSelectedOptionValue] = useControllableState({
    defaultValue: null,
    value,
    handleValueChange: onChangeValue,
  });

  const selectedOption = selectedOptionValue
    ? options.find((opt) => opt.value === selectedOptionValue)
    : undefined;

  return (
    <Box
      accessibilityLabel={accessibilityLabel}
      accessibilityHint={accessibilityHint}
      accessibilityRole="radiogroup"
      display="flex"
      flexDirection="row"
      flexWrap="nowrap"
      accessibilityValue={{
        text: getStringLabelForOption(selectedOption) ?? undefined,
      }}
      maxWidth="100%"
      {...boxProps}
    >
      {options.map((option, index, arr) => (
        <ToggleButton
          {..._toggleButton}
          key={option.value}
          isFirst={index === 0}
          isLast={index === arr.length - 1}
          isSelected={selectedOptionValue === option.value}
          onPress={() => setSelectedOptionValue(option.value)}
          isDisabled={isDisabled || option.isDisabled}
          isInvalid={isInvalid}
          label={option.label}
          accessibilityLabel={getStringLabelForOption(option)}
          accessibilityHint=""
          growToFit={growToFit}
        />
      ))}
    </Box>
  );
}

export default addWithConfig(ToggleButtonGroup);
