import * as React from 'react';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonSizes } from 'components/action-button';
import ItemActions from 'components/item-actions';
import { ListItemGroup } from 'components/list-item-group';
import { RecentOrderCard } from 'components/recent-order-card-list-item';
import { useReorder } from 'state/order/hooks/use-reorder';

import { IFavoriteCardProps } from './types';

/**
 *
 * FavoriteCard purpose
 *
 */

const FavoriteCard: FC<React.PropsWithChildren<IFavoriteCardProps>> = ({
  id,
  entries,
  name,
  onEditFavorite,
  onRemoveFavorite,
}) => {
  const favoriteOrder = {
    cart: {
      cartEntries: entries,
      reorderCartEntries: entries,
      serviceMode: null,
      cartVersion: 1,
    },
  };

  const { formatMessage } = useIntl();

  const { handleReorderClick, disabled } = useReorder(favoriteOrder);

  const handleReorder = () => {
    handleReorderClick();
  };

  const filterFavoriteEntries = () => {
    return entries.reduce((acc: React.ReactNode[], entry) => {
      if (!entry.isExtra && !entry.isDonation) {
        acc.push(<RecentOrderCard key={entry?.lineId} cartEntry={entry} isOnFavoriteSection />);
      }
      return acc;
    }, []);
  };

  if (!entries.length) {
    return null;
  }
  return (
    <ListItemGroup
      header={name || ''}
      headerAction={
        <ActionButton
          testID="reorder-button"
          onPress={handleReorder}
          disabled={disabled}
          size={ActionButtonSizes.SMALL}
        >
          {formatMessage({ id: 'orderNow' })}
        </ActionButton>
      }
      items={filterFavoriteEntries()}
      footer={
        <ItemActions
          onEdit={() => onEditFavorite(id ?? '')}
          onRemove={() => onRemoveFavorite(id ?? '')}
        />
      }
      key={id}
    />
  );
};

export default FavoriteCard;
