import { addDays, format, isBefore, isPast, isToday, isYesterday } from 'date-fns';

import { IGetRestaurantQuery, IRestaurantTimeSlot } from 'generated/graphql-gateway';
import { FutureOrderingOptions } from 'state/launchdarkly/variations';
import { ServiceMode } from 'state/service-mode';

import { MINUTE_IN_MS } from './constants';
import { futureOrderingOptions } from './options';
import { IDatePickerData, ITimePickerData } from './types';

/**
 * Dynamically generates an array of options for time picker
 * based on a time interval, length, offset, startingDate and lastPossibleSlot
 **/
export const getOptions = ({
  timeInterval,
  length,
  offset = 0,
  startingDate,
  lastPossibleSlot,
}: FutureOrderingOptions): ITimePickerData[] => {
  return Array.from({ length }, (a, i) => {
    const minutes = i * timeInterval + offset;
    return {
      time: getDateRoundUpToNearestFiveMinutes(
        new Date(startingDate.getTime() + minutes * MINUTE_IN_MS)
      ),
    };
    // Filter options that are after the last possible date for that day
  }).filter(option => option.time < lastPossibleSlot);
};

/**
 * Format time options based on availableTimeOptions and lastPossibleSlot
 **/
export const getAvailableTimeOptionsFormatted = ({
  availableTimeOptions,
  lastPossibleSlot,
  startingDate,
  offset = 0,
}: {
  availableTimeOptions: string[];
  lastPossibleSlot: Date | number;
  startingDate: Date;
  offset: number;
}): ITimePickerData[] => {
  const adjustedTime = new Date(startingDate.getTime() + offset * MINUTE_IN_MS);

  const formattedAvailableTimeOptions = availableTimeOptions
    .map(item => ({ time: new Date(item) }))
    .filter(option => option.time >= adjustedTime && option.time < lastPossibleSlot);

  return formattedAvailableTimeOptions;
};

export const getDateRoundUpToNearestFiveMinutes = (date = new Date()) => {
  const ms = 1000 * 60 * 5; // convert 5 minutes to ms
  const roundedDate = new Date(Math.ceil(date.getTime() / ms) * ms);
  return roundedDate;
};

// Find an expected day in the timeSlots array.
// In case it is not found then we create that day and presume the store is close that specific date.
export const findDayInTimeSlots = (slots: IRestaurantTimeSlot[], day: string) => {
  const dayToFind = slots.find(slot => slot?.date === day);
  if (dayToFind) {
    return dayToFind;
  }
  // Generate missing date and presume the store is closed that day
  const openAndCloseHours = new Date(day);
  openAndCloseHours.setHours(0);
  openAndCloseHours.setMinutes(0);
  openAndCloseHours.setSeconds(0);
  const formattedOpenAndCloseHours = format(new Date(openAndCloseHours), 'yyyy-MM-dd HH:mm:ss');
  const formattedDate = format(new Date(openAndCloseHours), 'yyyy-MM-dd');
  return {
    date: formattedDate,
    isClosed: true,
    opens: formattedOpenAndCloseHours,
    closes: formattedOpenAndCloseHours,
  };
};

// Method to map the timeSlots from GetRestaurant and generate a new array that contains
// all days in order from Today until Today + days - 1. (e.g. [today, ..., today + 6] for days = 7)
export const getSanitizedTimeSlots = (
  slots: IRestaurantTimeSlot[],
  days: number,
  isCatering = false
): IRestaurantTimeSlot[] => {
  let timeSlots: IRestaurantTimeSlot[] = [];
  const initialIndex = isCatering ? futureOrderingOptions.cateringDaysAhead : 0;
  for (let index = initialIndex; index < days; ++index) {
    const day = format(addDays(new Date(), index), 'yyyy-MM-dd');
    const timeSlot = findDayInTimeSlots(slots, day);
    timeSlots = [...timeSlots, timeSlot];
  }

  // Add yesterday as an option to show past midnight available options for an open store.
  // e.g. store closes 2 am. and it is currently 1 am.
  const yesterdaySlot = slots.find(slot => isYesterday(new Date(slot.date)));
  if (
    yesterdaySlot &&
    isToday(new Date(timeSlots[0].date)) &&
    isBefore(new Date(yesterdaySlot.closes), new Date(yesterdaySlot.opens))
  ) {
    timeSlots = [
      {
        date: yesterdaySlot.date,
        closes: format(addDays(new Date(yesterdaySlot.closes), 1), 'yyyy-MM-dd hh:mm:ss'),
        opens: format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
        isClosed: false,
      },
      ...timeSlots,
    ];
  }
  return timeSlots;
};

export const getFirstOptionFormatted = (options: IDatePickerData[] | undefined) => {
  if (options?.length && options?.length > 0) {
    const firstAvailableDate = options.find(
      item => !item.isDisabled && item.options.length > 0 && !isPast(item.options[0].time)
    );
    if (!firstAvailableDate) {
      return;
    }
    const [firstTimeOption] = firstAvailableDate.options;

    return {
      year: firstTimeOption.time.getFullYear(),
      month: firstTimeOption.time.getMonth(),
      day: firstTimeOption.time.getDate(),
      hour: firstTimeOption.time.getHours(),
      minutes: firstTimeOption.time.getMinutes(),
    };
  }
  return undefined;
};

export const getStoreData = (data: IGetRestaurantQuery, serviceMode: ServiceMode) =>
  data.restaurant.futureServiceHours.find(item => item.serviceMode === serviceMode);

export const getStartingTimeForCatering = (timeSlotOpens: Date) => {
  // This is to convert the first day for catering (e.g. tomorrow) to have the same hour and minutes as the current time
  const startingTime = timeSlotOpens;
  startingTime.setHours(new Date().getHours());
  startingTime.setMinutes(new Date().getMinutes());
  return startingTime;
};

export const getIsFirstDayNotAvailable = (
  index: number,
  startingDate: Date,
  timeSlotCloses: Date,
  isCatering: boolean
) => {
  if (isCatering) {
    return index === 0 && startingDate.getTime() > timeSlotCloses.getTime();
  }
  return isToday(timeSlotCloses) && new Date().getTime() > timeSlotCloses.getTime();
};

export const getStartingDate = (
  isCatering: boolean,
  isRestaurantOpen: boolean,
  timeSlotOpens: Date,
  isFirstDayInArray: boolean,
  isYesterdaySlot: boolean
) => {
  if (isCatering) {
    return isFirstDayInArray ? getStartingTimeForCatering(timeSlotOpens) : timeSlotOpens;
  }
  // Always start from now if the current slot is yesterday since this is for past midnight options
  if (isYesterdaySlot) {
    return new Date();
  }
  return isToday(timeSlotOpens) && isRestaurantOpen && isBefore(timeSlotOpens, new Date())
    ? new Date() // Start options from now - store is already open
    : timeSlotOpens;
};
