import React from 'react';

import { useUIContext } from 'state/ui';

import {
  StyledBody,
  StyledButton,
  StyledDialog,
  StyledHeader,
  StyledOutlineButton,
  StyledPicture,
  StyledTextOnlyButton,
} from './actions-modal.styled';
import { IActionsModalProps } from './types';

const ActionsModal = ({
  actions,
  bodyText,
  testID,
  headerText,
  image,
  imageAlt = '',
  modalAppearanceEventMessage,
}: IActionsModalProps) => {
  const { buildImageUrl } = useUIContext();
  const Header = () => (headerText ? <StyledHeader>{headerText}</StyledHeader> : null);
  const Body = () => <StyledBody>{bodyText}</StyledBody>;
  const Actions = () => (
    <>
      {actions.map(
        ({
          dataTestId: actionDataTestId,
          actionLabel,
          actionHandler,
          textOnly = false,
          outline = false,
        }) => {
          let BtnComponent = StyledButton;
          if (textOnly) {
            BtnComponent = StyledTextOnlyButton;
          } else if (outline) {
            BtnComponent = StyledOutlineButton;
          }
          return (
            <BtnComponent
              key={actionLabel}
              testID={actionDataTestId}
              onPress={actionHandler}
              width="full"
              marginTop="$2"
            >
              {actionLabel}
            </BtnComponent>
          );
        }
      )}
    </>
  );

  return (
    <StyledDialog
      testID={testID}
      headingComponent={<Header />}
      image={
        !!image && <StyledPicture image={image} alt={imageAlt} buildImageUrl={buildImageUrl} />
      }
      bodyComponent={<Body />}
      actions={<Actions />}
      modalAppearanceEventMessage={modalAppearanceEventMessage}
      showDialog
    />
  );
};

export default ActionsModal;
