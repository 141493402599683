import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, FormControl, HStack, Icon, InlineAlert } from '@fhs-legacy/universal-components';
import { TextInput } from 'components/ucl/text-input';
import { useLocale } from 'state/intl';
import { LANGUAGES } from 'state/intl/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOrderContext } from 'state/order';
import { getPhoneNumberErrorMessage, isPhoneNumberValid, requiredString } from 'utils/form';
import { IPlaceAddress } from 'utils/geolocation';
import { formatDistanceToNow } from 'utils/language/format-date';

import { FreeDeliveryProgressContainer } from '../free-delivery-progress';

import { getLocalizedDeliveryAddress } from './common';
import { ITU_COUNTRY_CODES, PHONE_NUMBER_LENGTH } from './constants';
import PhoneAddressInformationToggle from './phone-address-information-toggle';
import SaveAddressSelection from './save-address-selection';
import { DetailsBox, DetailsEtaText, FieldContainer, InstructionsTextarea, Text } from './styled';
import { ICartDetailsProps } from './types';

const DeliveryDetailsForm: React.FC<
  React.PropsWithChildren<Omit<ICartDetailsProps, 'serviceMode'>>
> = ({
  serverOrder,
  setAreDeliveryDetailsValid,
  sectionHeading,
  phoneNumberInputRef,
  deliveryDetailsErrors,
}) => {
  const {
    deliveryAddress,
    deliveryInstructions,
    orderPhoneNumber,
    setDeliveryAddress,
    setDeliveryInstructions,
    setOrderPhoneNumber,
    updateShouldSaveDeliveryAddress,
  } = useOrderContext();
  const addressLine2Error = useRef<boolean>(false);
  const [editingPhoneNumber, setEditingPhoneNumber] = useState(orderPhoneNumber);
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const hasPhoneNumberErrors = !!deliveryDetailsErrors.phoneNumber || !!phoneNumberError;
  const enableFreeDeliveryCheckoutProgress = useFlag(
    LaunchDarklyFlag.ENABLE_FREE_DELIVERY_CHECKOUT_PROGRESS_BAR
  );
  const enableRequireAddress2 = useFlag(
    LaunchDarklyFlag.ENABLE_DELIVERY_DETAILS_FORM_REQUIRE_ADDRESS_2
  );

  const enableNumericPhoneValidation = useFlag(LaunchDarklyFlag.ENABLE_NUMERIC_PHONE_VALIDATION);

  const maxDeliveryTime = serverOrder?.delivery?.dropoff.beforeTime || '';
  const ETA = useMemo(
    () => (maxDeliveryTime ? formatDistanceToNow(new Date(maxDeliveryTime), LANGUAGES.en) : ''),
    [maxDeliveryTime]
  );

  const { region } = useLocale();
  const { formatMessage } = useIntl();
  const localizedDeliveryAddress = getLocalizedDeliveryAddress(deliveryAddress, formatMessage);

  useEffect(() => {
    const addressL2 = deliveryAddress.addressLine2;
    if (enableRequireAddress2 && addressL2?.length <= 0) {
      addressLine2Error.current = true;
    } else {
      addressLine2Error.current = false;
    }
  }, [deliveryAddress, enableRequireAddress2]);

  const handleOrderPhoneNumberChange = useCallback(
    async (input: string) => {
      const newPhoneNumber = input.replace(/[^+\d]/, '');
      setEditingPhoneNumber(newPhoneNumber);

      const { valid, error } = await isPhoneNumberValid({
        phoneNumber: newPhoneNumber,
        simpleValidation: enableNumericPhoneValidation,
        country: region,
      });

      if (valid) {
        setPhoneNumberError('');
        const countryCodePhoneNumber =
          newPhoneNumber.length === PHONE_NUMBER_LENGTH
            ? `${ITU_COUNTRY_CODES[region]}${newPhoneNumber}`
            : newPhoneNumber;
        setOrderPhoneNumber(countryCodePhoneNumber);
      } else {
        setPhoneNumberError(getPhoneNumberErrorMessage(error, formatMessage));
      }

      if (setAreDeliveryDetailsValid) {
        setAreDeliveryDetailsValid(valid);
      }
    },
    [
      enableNumericPhoneValidation,
      formatMessage,
      region,
      setAreDeliveryDetailsValid,
      setOrderPhoneNumber,
    ]
  );

  const handleUnitNumberChange = (value: string) => {
    updateShouldSaveDeliveryAddress(false);
    setDeliveryAddress((previousDeliveryAddress: IPlaceAddress) => ({
      ...previousDeliveryAddress,
      addressLine2: value,
    }));
    if (enableRequireAddress2) {
      addressLine2Error.current = !requiredString(value);
    }
  };

  return (
    <DetailsBox accessibilityLabel={sectionHeading}>
      {maxDeliveryTime && (
        <HStack testID="delivery-eta">
          <DetailsEtaText accessibilityLabel={formatMessage({ id: 'etaFull' })}>
            {`${formatMessage({ id: 'eta' })}: `}
          </DetailsEtaText>
          <DetailsEtaText>{ETA}</DetailsEtaText>
        </HStack>
      )}
      <Text fontSize="xs" testID="delivery-address" data-private>
        {localizedDeliveryAddress}
      </Text>
      <InlineAlert
        status="info"
        borderRadius={8}
        marginBottom="$4"
        marginTop="$3"
        message={formatMessage({ id: 'noRefundsForWrongLocaton' })}
        iconCentered
      />
      <FieldContainer>
        <TextInput
          required={enableRequireAddress2}
          label={formatMessage({
            id: enableRequireAddress2 ? 'unitNumberLabelRequired' : 'unitNumberLabel',
          })}
          testID="delivery-address-line2"
          value={deliveryAddress.addressLine2}
          onChangeText={handleUnitNumberChange}
          placeholder={formatMessage({ id: 'enterUnitPlaceholder' })}
          placeholderTextColor={'__legacyToken.icon-form-input-default'}
          InputRightElement={
            !addressLine2Error && deliveryAddress.addressLine2?.length > 0 ? (
              <Box paddingX="$2.5">
                <Icon variant="check" color={Styles.color.validated} />
              </Box>
            ) : undefined
          }
        />
      </FieldContainer>
      <FieldContainer>
        <FormControl.Label>
          {formatMessage({ id: 'deliveryDetailsInstructions' })}
        </FormControl.Label>
        <InstructionsTextarea
          onChangeText={text => setDeliveryInstructions(text)}
          placeholder={formatMessage({ id: 'deliveryInstructionsPlaceholder' })}
          placeholderTextColor={'__legacyToken.icon-form-input-default'}
          value={deliveryInstructions}
          returnKeyType="done"
          testID="deliveryInstructions"
          maxLength={512}
          date-private
          numberOfLines={1}
        />
      </FieldContainer>
      <FieldContainer>
        <TextInput
          onChangeText={handleOrderPhoneNumberChange}
          testID="delivery-phone-number"
          label={formatMessage({ id: 'phoneNumberRequired' })}
          labelProps={{
            _text: {
              color: hasPhoneNumberErrors ? 'error.600' : undefined,
            },
          }}
          value={editingPhoneNumber}
          keyboardType="phone-pad"
          errorMessage={deliveryDetailsErrors.phoneNumber || phoneNumberError}
          _focus={{
            borderColor: hasPhoneNumberErrors ? 'token.border-color-error' : undefined,
          }}
          required
          placeholder={formatMessage({ id: 'phoneNumberPlaceholder' })}
          placeholderTextColor={'__legacyToken.icon-form-input-default'}
          ref={phoneNumberInputRef}
          InputRightElement={
            !phoneNumberError && orderPhoneNumber ? (
              <Box paddingX="$2.5">
                <Icon variant="check" color={Styles.color.validated} />
              </Box>
            ) : (
              <Box /> // We need this empty <Box /> to prevent a bigger rerender, avoiding the keyboard for being dismissed during the process
            )
          }
        />
      </FieldContainer>
      {/* TODO: RN - Revisit and see how/if we should still handle country select (not being used currently) */}
      {/* {enableNumericPhoneValidation ? (
        <TextInput
          onChange={(e: NativeSyntheticEvent<TextInputChangeEventData>) => {
            handleOrderPhoneNumberChange(e.nativeEvent.text.replace(/[^+0-9]/, ''));
          }}
          testID="delivery-phone-number"
          label={formatMessage({ id: 'phoneNumberRequired' })}
          value={orderPhoneNumber}
          // validation={
          //   phoneNumberError || !orderPhoneNumber
          //     ? FormValidationState.INVALID
          //     : FormValidationState.VALID
          // }
          errorMessage={phoneNumberError}
        />
      ) : (
        <FloatingLabelPhoneInput
          hideCountrySelector
          testID="delivery-phone-number"
          label={formatMessage({ id: 'phoneNumberRequired' })}
          onChange={handleOrderPhoneNumberChange}
          value={orderPhoneNumber}
          placeholder={formatMessage({ id: 'phoneNumberPlaceholder' })}
          // validation={
          //   phoneNumberError || !orderPhoneNumber
          //     ? FormValidationState.INVALID
          //     : FormValidationState.VALID
          // }
          message={phoneNumberError}
        />
      )} */}

      <PhoneAddressInformationToggle />
      <SaveAddressSelection />
      {enableFreeDeliveryCheckoutProgress && <FreeDeliveryProgressContainer />}
    </DetailsBox>
  );
};

export default DeliveryDetailsForm;
