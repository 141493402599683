import { FC, PropsWithChildren } from 'react';

import { MqSwitch } from '@fhs/ui';
import { Drawer } from 'components/drawer';
import noop from 'utils/noop';

import { DESKTOP_PRODUCT_CUSTOMIZATION_WIDTH } from '../constants';

export const ProductCustomizationContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MqSwitch
      $base={() => children}
      $gteDesktop={() => (
        <Drawer
          isOpen
          position="right"
          onClose={noop}
          contentWidth={DESKTOP_PRODUCT_CUSTOMIZATION_WIDTH}
        >
          {children}
        </Drawer>
      )}
    />
  );
};
