import * as React from 'react';
import { Dimensions } from 'react-native';
import { TabView as RNTabView } from 'react-native-tab-view';

import TabBar from './tab-bar';
import { ITabBarProps, ITabViewProps } from './types';

const TabView: React.FC<ITabViewProps> = ({
  activeTabIndex,
  handleTabIndexChange,
  renderScene,
  tabs,
  keyboardDismissMode,
  color = 'primary',
  tabBarBackgroundColor = 'transparent',
  tabBarRoundTopCorners,
  ...props
}) => {
  return (
    <RNTabView
      animationEnabled={false}
      swipeEnabled={false}
      navigationState={{ index: activeTabIndex, routes: tabs }}
      renderScene={renderScene}
      renderTabBar={React.useCallback(
        (tabBarProps: ITabBarProps) => (
          <TabBar
            {...tabBarProps}
            color={color}
            roundTopCorners={tabBarRoundTopCorners}
            tabBarBackgroundColor={tabBarBackgroundColor}
          />
        ),
        [color, tabBarRoundTopCorners, tabBarBackgroundColor]
      )}
      onIndexChange={handleTabIndexChange}
      initialLayout={{ width: Dimensions.get('window').width }}
      keyboardDismissMode={keyboardDismissMode}
      {...props}
    />
  );
};

export default TabView;
