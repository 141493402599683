import { Link } from 'expo-router';
import { Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';

import {
  Box,
  FormikFormControl,
  FormikInput,
  InlineAlert,
  Text,
} from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import { CountrySelect } from 'components/country-select';
import { useLoyaltyContent } from 'hooks/loyalty/use-loyalty-content';
import { useFieldIsRequired } from 'hooks/use-field-is-required';
import { UserDetails } from 'state/auth/hooks/use-current-user';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  MyAccountFieldsVariations,
  defaultMyAccountFieldsVariation,
} from 'state/launchdarkly/variations';
import {
  POSSIBLE_DEFAULT_OR_EMPTY_DOB_VALUES,
  formatDateObject as dobObjToString,
  dobStringToObj,
  sanitizePhoneNumber,
} from 'utils/form';
import { ISOs as IsoCountryCode, postalCodeInputProperties } from 'utils/form/constants';
import { IDateOfBirth } from 'utils/form/types';
import { validationConfig } from 'utils/form/user-profile-validation-config/validation';

import { FormikBirthdayInput } from '../formik/birthday-input';

import { getFormErrors } from './get-form-errors';
import PromotionalEmailsCheckbox from './promotional-email-checkbox';
import ReloadText from './reload-text';
import { TermsSection } from './terms-section';
import { IUserFormData, IUserInfoSubmitData } from './types';

const InputContainer = Box.withConfig({
  marginBottom: '$3',
});

function NameOfTheDayDisclaimer() {
  const { formatMessage } = useIntl();

  return (
    <InlineAlert
      iconCentered
      status="info"
      backgroundColor="transparent"
      borderColor="blackOpacity.10"
      borderWidth={1}
      marginBottom={6}
      borderRadius={8}
      message={formatMessage(
        { id: 'accountInfoNameDisclaimer' },
        {
          promotionName: (
            <Link href="/nameoftheday" target="_blank">
              <Text underline bold>
                {formatMessage({ id: 'nameOfTheDay' })}
              </Text>
            </Link>
          ),
          support: (
            <Link href="/support" target="_blank">
              <Text underline bold>
                {formatMessage({ id: 'supportReachOut' })}
              </Text>
            </Link>
          ),
          jumpLine: '\n',
        }
      )}
    />
  );
}

interface IUserInfoFormProps {
  readOnly?: boolean;
  user: UserDetails | null;
  /**
   * TODO: this onSubmit requires that a promise be passed
   * because there's a `.then` chain after it's called.
   *
   * We should either move the after submit functionality up to the account-info page
   * or move the submit logic into this form.
   */
  onSubmit: (data: IUserInfoSubmitData) => Promise<any>;
  isLoading?: boolean;
}

function UserInfoForm({ onSubmit, readOnly = false, user, isLoading }: IUserInfoFormProps) {
  const enablePhoneNumberEditing = useFlag(
    LaunchDarklyFlag.ENABLE_CUSTOMER_CAN_EDIT_OWN_PHONE_NUMBER
  );
  const enableNameChangeOnAccountPage = useFlag(
    LaunchDarklyFlag.ENABLE_NAME_CHANGE_ON_ACCOUNT_PAGE
  );
  const myAccountFieldsVariations =
    useFlag<MyAccountFieldsVariations>(LaunchDarklyFlag.MY_ACCOUNT_FIELDS_VARIATIONS) ||
    defaultMyAccountFieldsVariation;
  const enableNumericPhoneValidation = useFlag(LaunchDarklyFlag.ENABLE_NUMERIC_PHONE_VALIDATION);
  const { formatMessage } = useIntl();
  const { region } = useLocale();

  const { birthdayLegalText } = useLoyaltyContent();

  const DOB_DEFAULT_OR_EMPTY = useMemo(
    () => POSSIBLE_DEFAULT_OR_EMPTY_DOB_VALUES.some(v => v === user?.details?.dob || ''),
    [user]
  );

  const initialValues: IUserFormData = useMemo(() => {
    // dob will come in as string so let's convert it
    const dob = dobStringToObj(user?.details?.dob);
    return {
      agreesToTermsOfService: true,
      dob,
      email: user?.details?.email || '',
      name: user?.details?.name || '',
      phoneNumber: user?.details?.phoneNumber || '',
      promotionalEmails: !!user?.details?.promotionalEmails || false,
      isoCountryCode: (user?.details?.isoCountryCode as IsoCountryCode) || IsoCountryCode.USA,
      zipcode: user?.details?.zipcode || '',
      defaultReloadAmt: user?.details?.defaultReloadAmt || 500,
      defaultReloadPaymentMethodId: user?.details?.defaultPaymentAccountId || '',
      defaultCheckoutPaymentMethodId:
        user?.details?.defaultAccountIdentifier || user?.details?.defaultFdAccountId || '',
      autoReloadEnabled: user?.details?.autoReloadEnabled || false,
      autoReloadThreshold: user?.details?.autoReloadThreshold || 500,
    };
  }, [user]);

  const disablePhoneNumberField = readOnly || !enablePhoneNumberEditing;

  const handleSubmit = useCallback(
    async (formData: IUserFormData) => {
      const dob = dobObjToString(formData.dob as IDateOfBirth);

      const phoneNumber = await sanitizePhoneNumber(formData.phoneNumber, formData.isoCountryCode);
      await onSubmit({
        ...formData,
        phoneNumber,
        dob,
      });
    },
    [onSubmit]
  );

  const birthdayIsRequired = useFieldIsRequired(region, validationConfig, 'dob');
  const zipcodeIsRequired = useFieldIsRequired(region, validationConfig, 'zipcode');
  const phoneNumberIsRequired = useFieldIsRequired(region, validationConfig, 'phoneNumber');
  const nameIsReadOnly = !enableNameChangeOnAccountPage;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={getFormErrors({
        formatMessage,
        region,
        myAccountFieldsVariations,
        simplePhoneValidation: enableNumericPhoneValidation,
      })}
      enableReinitialize
    >
      {({
        handleChange,
        handleBlur,
        submitForm,
        values,
        setFieldValue,
        isValid,
        isSubmitting,
        setFieldTouched,
        errors,
        touched,
      }) => (
        <>
          <InputContainer>
            {myAccountFieldsVariations.email && (
              <FormikFormControl
                name="email"
                label={formatMessage({ id: 'yourEmail' })}
                isReadOnly
                testID="email"
              >
                <FormikInput
                  name="email"
                  inputProps={{
                    isDisabled: true,
                    isRequired: true,
                    testID: 'account-info-email-input',
                  }}
                />
              </FormikFormControl>
            )}
            {myAccountFieldsVariations.name && (
              <>
                <FormikFormControl
                  name="name"
                  label={formatMessage({ id: 'name' })}
                  isReadOnly={nameIsReadOnly}
                  isRequired
                >
                  <FormikInput
                    name="name"
                    inputProps={{
                      isRequired: true,
                      isDisabled: readOnly || nameIsReadOnly,
                      testID: 'account-info-name-input',
                    }}
                  />
                </FormikFormControl>
                {nameIsReadOnly && <NameOfTheDayDisclaimer />}
              </>
            )}
            {myAccountFieldsVariations.phone && (
              <FormikFormControl
                isRequired={phoneNumberIsRequired}
                name="phoneNumber"
                label={formatMessage({ id: 'phoneNumber' })}
              >
                <FormikInput
                  name="phoneNumber"
                  inputProps={{
                    placeholder: `${formatMessage({ id: 'phoneNumber' })}`,
                    testID: 'signup-phone-input',
                    autoComplete: 'tel',
                    isDisabled: disablePhoneNumberField,
                    keyboardType: 'phone-pad',
                  }}
                />
              </FormikFormControl>
            )}
            {myAccountFieldsVariations.dob && (
              <>
                <FormikFormControl name="dob">
                  <FormikBirthdayInput
                    disabled={readOnly || !DOB_DEFAULT_OR_EMPTY}
                    hintMessage={birthdayLegalText ?? ''}
                    isRequired={birthdayIsRequired}
                    year={values.dob.year}
                    month={values.dob.month}
                    day={values.dob.day}
                    onChange={(inputName, inputText) =>
                      setFieldValue(`dob.${inputName}`, inputText)
                    }
                    testID="account-info-dob"
                    onBlur={inputName => setFieldTouched(`dob.${inputName}`, true)}
                    dobErrors={errors.dob}
                    dobTouched={touched.dob}
                  />
                </FormikFormControl>
              </>
            )}
            {myAccountFieldsVariations.country && (
              <CountrySelect
                name="isoCountryCode"
                label={formatMessage({ id: 'country' })}
                value={values.isoCountryCode}
                onChange={handleChange}
                disabled
                testID="account-info-country"
              />
            )}
            {myAccountFieldsVariations.zip && (
              <FormikFormControl
                name="zipcode"
                isRequired={zipcodeIsRequired}
                label={formatMessage({
                  id: values.isoCountryCode === IsoCountryCode.USA ? 'zipCode' : 'postalCode',
                })}
              >
                <FormikInput
                  name="zipcode"
                  inputProps={{
                    placeholder: formatMessage({
                      id: values.isoCountryCode === IsoCountryCode.USA ? 'zipCode' : 'postalCode',
                    }),
                    isDisabled: readOnly,
                    testID: 'account-info-zip-input',
                    onBlur: () => handleBlur('zipcode'),
                    onChange: (event: NativeSyntheticEvent<TextInputChangeEventData>) => {
                      setFieldValue('zipcode', event.target.toString().toUpperCase());
                    },
                    maxLength: postalCodeInputProperties[values.isoCountryCode]?.maxLength || 10,
                    autoComplete: 'postal-code',
                    ...(values.isoCountryCode === IsoCountryCode.USA && {
                      keyboardType: 'phone-pad',
                    }),
                  }}
                />
              </FormikFormControl>
            )}
            <ReloadText />
            <PromotionalEmailsCheckbox
              checked={values.promotionalEmails}
              onChange={isSelected => setFieldValue('promotionalEmails', isSelected)}
              disabled={readOnly}
            />
          </InputContainer>
          <Box>
            <ActionButton
              testID="account-info-form-submit-button"
              fullWidth
              onPress={submitForm}
              disabled={readOnly || !isValid || isLoading || isSubmitting}
              isLoading={isLoading || isSubmitting}
            >
              {formatMessage({ id: 'update' })}
            </ActionButton>
          </Box>
          <TermsSection />
        </>
      )}
    </Formik>
  );
}

export default UserInfoForm;
