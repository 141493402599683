import { ActivityIndicator } from 'react-native';

import { tokens } from '../../tokens';

import type { ButtonSize, ButtonType } from './types';

type ButtonActivityIndicatorProps = {
  size: ButtonSize;
  type: ButtonType;
};

const buttonSizeToIndicatorSize = {
  xs: 15,
  sm: 18,
  md: 21,
  lg: 24,
  xl: 24,
};

export function ButtonActivityIndicator(props: ButtonActivityIndicatorProps) {
  return (
    <ActivityIndicator
      size={buttonSizeToIndicatorSize[props.size]}
      color={props.type === 'solid' ? tokens.colors.$white : tokens.colors.$houseRedDarken}
    />
  );
}
