import * as React from 'react';
import { FC, ReactNode } from 'react';
import { RefreshControl, ScrollView } from 'react-native';

import { Box } from '@fhs-legacy/universal-components';
import { PullToReloadContent } from 'components/pull-to-reload/pull-to-reload-content';
import { useIsMobileBp } from 'hooks/breakpoints';
import noop from 'utils/noop';

interface IPullToReload {
  onRefresh(): Promise<any>;
  isRefreshing: boolean;
  minimumRefreshTime?: number;
  loadingAnimation?: ReactNode;
}

interface RefreshHeaderProps {
  height: string;
  isReleasing: boolean;
  refreshing: boolean;
  loadingAnimation?: ReactNode;
}

export const RefreshHeader = ({
  height,
  isReleasing,
  refreshing,
  loadingAnimation,
}: RefreshHeaderProps) => {
  return (
    <Box height={height} position={'absolute'} width={'full'}>
      {!isReleasing && !refreshing && <PullToReloadContent action="pullingDown" />}
      {!refreshing && isReleasing && <PullToReloadContent action="releasing" />}
      {refreshing &&
        (loadingAnimation ? loadingAnimation : <PullToReloadContent action="refreshing" />)}
    </Box>
  );
};

const PullToReload: FC<React.PropsWithChildren<IPullToReload>> = ({
  children,
  onRefresh,
  minimumRefreshTime,
  loadingAnimation,
  isRefreshing,
}) => {
  const [pullLength, setPullLength] = React.useState(0);
  const [isReleasing, setReleasing] = React.useState(false);
  const [isRefreshingMinimal, setRefreshingMinimal] = React.useState(false);

  const minimumRefreshTimePromise = async () => {
    const minimumRefreshDuration = new Promise(res => {
      setRefreshingMinimal(true);
      setTimeout(res, minimumRefreshTime || 3000);
    }).then(_ => setRefreshingMinimal(false));
    // Mimicking Promise.allSettled so that this resolves even if refresh fails
    return Promise.all([onRefresh(), minimumRefreshDuration].map(promise => promise.catch(noop)));
  };

  const isMobile = useIsMobileBp();

  return isMobile ? (
    <ScrollView
      showsVerticalScrollIndicator={false}
      onScroll={({ nativeEvent }) => {
        const y = nativeEvent.contentOffset.y;
        setPullLength(y);
        setReleasing(y <= -117);
      }}
      scrollEventThrottle={10}
      refreshControl={
        <RefreshControl
          refreshing={isRefreshing}
          tintColor={'transparent'}
          onRefresh={minimumRefreshTimePromise}
        >
          <RefreshHeader
            height={`${pullLength * -1}px`}
            isReleasing={isReleasing}
            refreshing={isRefreshing || isRefreshingMinimal}
            loadingAnimation={loadingAnimation}
          />
        </RefreshControl>
      }
      style={{
        height: '100%',
      }}
    >
      {children}
    </ScrollView>
  ) : (
    <>{children}</>
  );
};

export default PullToReload;
