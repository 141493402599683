import { useCallback } from 'react';

import { actions, useAppDispatch } from 'state/global-state';
import {
  ICartEntryType,
  useInRestaurantRedemptionContext,
} from 'state/loyalty/in-restaurant-redemption';
import { useOrderContext } from 'state/order';

import type { ILoyaltyOffer } from '../types';
import { isDiscountOffer } from '../utils';

import type { IRemoveOffer } from './types';
import { useAppliedOffers } from './use-applied-offers';

export const useRemoveOffer = () => {
  const dispatch = useAppDispatch();
  const { removeFromCart } = useOrderContext();
  const appliedOffers = useAppliedOffers();
  const { inRestaurantRedemptionCart, removeInRestaurantRedemptionEntry } =
    useInRestaurantRedemptionContext();

  const removeOffer = useCallback(
    (offerToRemove: ILoyaltyOffer): IRemoveOffer => {
      // the offer should always be applied
      const appliedOfferToRemove = appliedOffers.find(
        offer => offer.id === offerToRemove.loyaltyEngineId
      );

      if (!appliedOfferToRemove) {
        return { offerRemoved: false };
      }

      const appliedInRestaurantOffer = inRestaurantRedemptionCart.find(
        cartEntry =>
          cartEntry.type === ICartEntryType.OFFER &&
          cartEntry.details.offer._id === offerToRemove._id
      );

      if (appliedInRestaurantOffer) {
        removeInRestaurantRedemptionEntry(appliedInRestaurantOffer);
      } else if (isDiscountOffer(offerToRemove)) {
        // no cart entry for discount offers
        dispatch(actions.loyalty.removeAppliedOffer(appliedOfferToRemove));
      } else {
        removeFromCart(appliedOfferToRemove);
      }

      return { offerRemoved: true };
    },
    [
      appliedOffers,
      dispatch,
      inRestaurantRedemptionCart,
      removeFromCart,
      removeInRestaurantRedemptionEntry,
    ]
  );

  return { removeOffer };
};
