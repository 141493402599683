import { Link } from 'expo-router';
import React from 'react';

import { HeaderIconButton, IconMenu } from '@fhs/ui';

export const HeaderLeftMobile = () => {
  return (
    <Link href="/account" asChild>
      <HeaderIconButton>
        <IconMenu />
      </HeaderIconButton>
    </Link>
  );
};
