import { Image, ImageBackground, ImageBackgroundProps, ImageStyle } from 'expo-image';
import { StyleProp } from 'react-native';

import { YStack, createMqStyles } from '@fhs/ui';

import { CtaButton } from '../shared/cta-button';

export type BbqCubanCardProps = Omit<ImageBackgroundProps, 'children' | 'source'> & {
  pickUpHref: string;
};
export function BbqCubanCard(props: BbqCubanCardProps) {
  const mqStyles = useMqStyles();

  return (
    <ImageBackground
      source={require('./bbq-cuban-card-background.webp')}
      placeholder={{ thumbhash: '9CgOI4awjKiaeNdqT3byZDY' }}
      {...props}
      style={[mqStyles.banner, props.style]}
    >
      <YStack style={mqStyles.contentContainer}>
        <Image
          source={require('./bbq-cuban-card-logo.webp')}
          alt="BBQ Cuban"
          placeholder={{ thumbhash: 'j1mGGoYlp5iAiXingHcKeAZ3inOJV2c' }}
          style={mqStyles.logo as StyleProp<ImageStyle>}
          contentFit="contain"
        />
        <Image
          source={require('./bbq-cuban-card-subtitle.webp')}
          alt="Southern Roots. Cuban Twist."
          placeholder={{ thumbhash: 'gQeCAoAURm0Gm5iO+/qyxTlIe5qPdic' }}
          style={mqStyles.subtitle as StyleProp<ImageStyle>}
          contentFit="contain"
        />
        <CtaButton href="/menu/picker-9b56faf3-2b97-453b-ae34-0dd57e853083" />
      </YStack>
    </ImageBackground>
  );
}

const useMqStyles = createMqStyles({
  banner: {
    $base: {
      aspectRatio: 1920 / 757,
    },
  },
  contentContainer: {
    $base: {
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '58%',
      right: 0,
    },
    $ltDesktopLg: {
      top: 32,
      bottom: 32,
      gap: 24,
    },
    $gteDesktopLg: {
      top: 64,
      bottom: 64,
      gap: 32,
    },
  },

  logo: {
    $base: {
      aspectRatio: 546 / 253,
    },
    $ltDesktopLg: {
      width: '65%',
    },
    $gteDesktopLg: {
      width: '70%',
    },
  },

  subtitle: {
    $base: {
      aspectRatio: 732 / 216,
    },
    $ltDesktopLg: {
      width: '60%',
    },
    $gteDesktopLg: {
      width: '60%',
    },
  },

  copyrightText: {
    $base: {
      aspectRatio: 203 / 34,
      width: '10%',
      position: 'absolute',
      right: '3%',
      bottom: '6%',
    },
  },
});
