import { ReactNode } from 'react';
import { Modal, Pressable as RnPressable, StyleSheet, View, ViewProps } from 'react-native';
import { Path, Svg, type SvgProps } from 'react-native-svg';

import { useBoxRef } from '@fhs/utils';

import { tokens } from '../../tokens';

import { useDropdownSetters, useDropdownState } from './context';

type ArrowProps = Omit<SvgProps, 'width' | 'height'>;

const Arrow = (props: ArrowProps) => {
  return (
    <Svg height={18} fill="none" viewBox="0 0 36 18" {...props}>
      <Path fill="#fff" stroke="#000" strokeOpacity=".1" d="M2 17 17.667 1 34 17H2Z" />
      <Path fill="#fff" d="M0 16h36v2H0z" />
    </Svg>
  );
};

const VERTICAL_GAP = 16;

export type DropdownPaneProps = Omit<ViewProps, 'children'> & {
  children:
    | (({ setIsExpanded }: { setIsExpanded: (isExpanded: boolean) => void }) => ReactNode)
    | ReactNode;
};
export function DropdownPane({ children, ...viewProps }: DropdownPaneProps) {
  const { setIsExpanded } = useDropdownSetters();
  const { triggerLayout, isExpanded } = useDropdownState();

  const handleRequestCloseRef = useBoxRef(() => {
    setIsExpanded(false);
  });
  const handlePressOutsideRef = handleRequestCloseRef;

  return (
    <Modal transparent visible={isExpanded} onRequestClose={handleRequestCloseRef.current}>
      <RnPressable
        aria-label="Close dropdown"
        accessible={false}
        focusable={false}
        style={StyleSheet.absoluteFill}
        onPress={handlePressOutsideRef.current}
      />

      <View
        {...viewProps}
        style={[
          styles.paneView,
          {
            top: VERTICAL_GAP + triggerLayout.pageY + triggerLayout.height,
            left: triggerLayout.pageX + triggerLayout.width / 2,
          },
          viewProps.style,
        ]}
      >
        <View style={styles.arrowContainer}>
          <Arrow />
        </View>

        {typeof children === 'function' ? children({ setIsExpanded }) : children}
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  paneView: {
    position: 'absolute',
    transform: 'translateX(-50%)',
    backgroundColor: tokens.colors.$white,
    borderRadius: 12,
    borderColor: tokens.colors.$blackOpacity10,
    borderWidth: 1,
  },
  arrowContainer: {
    position: 'absolute',
    top: -16,
    left: 0,
    right: 0,
    flexDirection: 'row',
    justifyContent: 'center',
  },
});
