export enum TraceName {
  // bottom tab nav interaction events
  BOTTOM_TAB_PRESS_TO_HOME_SCREEN_RENDER = 'bottom_tab_press_to_home_screen_render',
  BOTTOM_TAB_PRESS_TO_RECENT_ITEMS_SCREEN_RENDER = 'bottom_tab_press_to_recent_items_screen_render',
  BOTTOM_TAB_PRESS_TO_MENU_SCREEN_RENDER = 'bottom_tab_press_to_menu_screen_render',
  BOTTOM_TAB_PRESS_TO_OFFERS_SCREEN_RENDER = 'bottom_tab_press_to_offers_screen_render',
  BOTTOM_TAB_PRESS_TO_REWARDS_LIST_SCREEN_RENDER = 'bottom_tab_press_to_rewards_list_screen_render',
  BOTTOM_TAB_PRESS_TO_SCAN_SCREEN_RENDER = 'bottom_tab_press_to_scan_screen_render',
  BOTTOM_TAB_PRESS_TO_IN_RESTAURANT_REDEMPTION_SCREEN_RENDER = 'bottom_tab_press_to_in_restaurant_redemption_screen_render',

  // events that start in native code
  JAVASCRIPT_START = 'javascript_start',

  // other
  APP_FLOW_DOWNLOAD_START_TO_APP_FLOW_DOWNLOAD_END = 'app_flow_download_start_to_app_flow_download_end',
  JAVASCRIPT_START_TO_FIRST_MAIN_HERO_IMAGE_VISIBLE = 'javascript_start_to_first_main_hero_image_visible',
  JAVASCRIPT_START_TO_FIRST_MARKETING_TILE_VISIBLE = 'javascript_start_to_first_marketing_tile_visible',
  JAVASCRIPT_START_TO_OFFERS_CAROUSEL_RENDER = 'javascript_start_to_offers_carousel_render',
  REACT_START = 'react_start',
  MENU_SECTIONS_LOAD = 'menu_sections_load',
  MENU_SECTION_LOAD = 'menu_section_load',
  MENU_ITEM_LOAD = 'menu_item_load',
  STORE_LOCATOR_RESTAURANT_ORDER = 'store_locator_restaurant_order',
  STORE_LOCATOR_RESTAURANT_OFFERS = 'store_locator_restaurant_offers',
  STORE_LOCATOR_RESTAURANTS_SEARCHING = 'store_locator_restaurants_searching',
  CART_ON_MOUNT_CONTINUE_LOAD = 'cart_on_mount_continue_load',
  CART_ON_UPDATE_CONTINUE_LOAD = 'cart_on_update_continue_load',
  CART_STEP_2_LOAD = 'cart_step_2_load',
  PLACE_PICKUP_ORDER_BUTTON_PRESS = 'place_pickup_order_button_press',
}

export enum AttributeName {
  COGNITO_ID = 'cognito_id',
  ENVIRONMENT = 'environment',
  RESTAURANT_ID = 'restaurant_id',
  EXPO_UPDATE_ID = 'expo_update_id',
  CART_UPDATE_SOURCE = 'cart_update_source',
  CART_STEP_2_END_SOURCE = 'cart_step_2_end_source',
  PLACE_PICKUP_ORDER_END = 'place_pickup_order_end',
  SIMPLY_MENU = 'simply_menu',
}

export type TraceAttribute = { name: AttributeName; value: string };

// Args for events related to menu performance
export type MenuPerformanceArgs = {
  sanityId?: string;
  isOnMainMenu?: boolean;
  selectedStaticMenuItemId?: string;
  isStoreChange?: boolean;
  attributes: TraceAttribute[];
};

export type CartContentUpdateArgs = {
  attributes: TraceAttribute[];
};

export type MenuPerformanceSanityItemTraceInfo = {
  shouldTrace: boolean;
  isSection: boolean;
};

// Enum for the steps that we are measuring during menu selection.
export enum MenuPerformanceStep {
  NOT_STARTED = 0,
  MENU_SECTIONS_START,
  MENU_SECTIONS_END,
  MENU_SECTION_START,
  MENU_SECTION_END,
  MENU_ITEM,
}
