import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconDelete = (props: IconProps) => {
  const { size = 16, color = 'black', ...otherProps } = props;

  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 16 16" color={color} {...otherProps}>
      <Path
        fill={color}
        d="M4.85 14a.963.963 0 0 1-.706-.294A.963.963 0 0 1 3.85 13V3.5h-.683v-1H6.3V2h4.4v.5h3.133v1h-.683V13c0 .267-.1.5-.3.7-.2.2-.433.3-.7.3h-7.3Zm7.3-10.5h-7.3V13h7.3V3.5Zm-5.533 8.067h1v-6.65h-1v6.65Zm2.766 0h1v-6.65h-1v6.65Z"
      />
    </Svg>
  );
};
