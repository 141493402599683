export {
  getDeviceId,
  getSessionId,
  identify,
  Identify,
  init,
  revenue,
  Revenue,
  add,
  setDeviceId,
  setOptOut,
  setSessionId,
  setUserId,
  track,
  Types as AmplitudeTypes,
} from '@amplitude/analytics-react-native';
