import React from 'react';

import { Box, Text, View } from '@fhs-legacy/universal-components';
import {
  IncentiveEvaluationMessage,
  IncentiveEvaluationMessages,
} from 'components/incentive-evaluation-messages/incentive-evaluation-messages';
import { WEB_APP_CTA_MAX_WIDTH } from 'utils/constants';

export const SectionContainer = Box;

export const EnsureBottomPadding = Box;

export const HeadingSuperText: React.FC<React.PropsWithChildren<unknown>> = () => null;

export const Heading: React.FC<React.PropsWithChildren<unknown>> = () => null;

export const OfferErrorFeedback = Text.withConfig({
  fontSize: 'md',
});

export const CartContentWrapper = Box.withConfig({
  marginX: 'auto',
  width: 'full',
});

export const StyledIncentiveEvaluationMessages = IncentiveEvaluationMessages.withConfig({
  borderWidth: 'px',
  borderColor: Styles.color.red,
  padding: '$4',
  alignItems: 'center',
});

export const StyledIncentiveEvaluationMessage = IncentiveEvaluationMessage.withConfig({
  borderWidth: 'px',
  borderColor: Styles.color.red,
  padding: '$4',
});

export const CtaWrapper = View.withConfig({
  width: { base: 'full', desktop: WEB_APP_CTA_MAX_WIDTH },
  maxW: WEB_APP_CTA_MAX_WIDTH,
  alignSelf: 'center',
});
