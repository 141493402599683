import React from 'react';

import { useGetFavoriteRestaurants } from 'hooks/use-get-favorite-restaurants';
import { OrderLocatorTab } from 'state/launchdarkly/variations';

import StoreCardList from './store-card-list';

type Props = {
  handleSelectStore(storeId: string): void;
};

export function FavoriteStoreCards({ handleSelectStore }: Props) {
  const { restaurants, loading, error } = useGetFavoriteRestaurants();

  return (
    <StoreCardList
      currentTabHasError={!!error}
      isSearching={loading}
      onSelectStore={handleSelectStore}
      restaurants={restaurants}
      tab={OrderLocatorTab.FAVORITE}
    />
  );
}
