import React from 'react';
import { useIntl } from 'react-intl';

import { IconButton } from '@fhs-legacy/universal-components';
import { ICartEntry } from '@rbi-ctg/menu';
import { FavoriteIcon } from 'components/favorite-icon';
import {
  GetUserFavoritesRefsDocument,
  ICartEntryInput,
  IGetUserFavoritesRefsQuery,
  useCreateFavoriteMutation,
  useDeleteFavoriteMutation,
  useGetUserFavoritesRefsQuery,
} from 'generated/rbi-graphql';
import { useToast } from 'hooks/use-toast';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { useFavoritesContext } from 'state/favorites';
import { isOfferType } from 'state/order/utils';
import { remappedCartForBackEnd } from 'utils/cart';
import { getCartEntryRef } from 'utils/menu/get-cart-entry-ref';

interface IFavoriteEntryButtonProps {
  cartEntry: ICartEntry;
}

const FavoriteEntryButton: React.FC<React.PropsWithChildren<IFavoriteEntryButtonProps>> = ({
  cartEntry,
}) => {
  const { data } = useGetUserFavoritesRefsQuery();
  const toast = useToast();
  const [createFavorite, { loading: createLoading }] = useCreateFavoriteMutation();
  const [deleteFavorite, { loading: deleteLoading }] = useDeleteFavoriteMutation();

  const ref = getCartEntryRef(cartEntry);
  const refFavorite = data?.userFavorites.favorites?.find(favorite => favorite?.ref === ref);
  const isFavorite = !!refFavorite;
  const { formatMessage } = useIntl();
  const { logRBIEvent } = useCRMEventsContext();
  const { setShouldRefetchFavorites } = useFavoritesContext();

  const shouldDisplayHeart = !isOfferType(cartEntry.type);

  const addEvent = (favoriteItemName: string) => {
    logRBIEvent({
      name: CustomEventNames.ADD_ITEMS_TO_FAVORITE,
      type: EventTypes.Other,
      attributes: {
        productName: favoriteItemName,
      },
    });
  };

  const onClick = () => {
    if (isFavorite) {
      // Delete favorite
      deleteFavorite({
        variables: {
          favoriteId: refFavorite?._id ?? '',
        },
        update: (cache, { data: resultData }) => {
          const favs = cache.readQuery<IGetUserFavoritesRefsQuery>({
            query: GetUserFavoritesRefsDocument,
          });

          const newQuery = {
            userFavorites: {
              ...favs?.userFavorites,
              count: favs?.userFavorites.count ? favs.userFavorites.count - 1 : 0,
              favorites: favs?.userFavorites.favorites?.filter(
                item => item?._id !== resultData?.deleteFavorite._id
              ),
            },
          };
          cache.writeQuery({
            query: GetUserFavoritesRefsDocument,
            data: newQuery,
          });
        },
      })
        .then(() => {
          setShouldRefetchFavorites(true);
          toast.show({
            text: formatMessage({ id: 'removeFavoriteSucess' }),
            variant: 'positive',
          });
        })
        .catch(() => {
          toast.show({
            text: formatMessage({ id: 'itemFavoritedError' }),
            variant: 'negative',
          });
        });
    } else {
      // Map the entries to ICartEntryInput
      const entries = remappedCartForBackEnd([cartEntry]) as unknown as ICartEntryInput[];
      // Create favorite
      createFavorite({
        variables: {
          input: {
            entries,
            name: cartEntry.name,
            ref,
          },
        },
        update: (cache, { data: resultData }) => {
          const favs = cache.readQuery<IGetUserFavoritesRefsQuery>({
            query: GetUserFavoritesRefsDocument,
          });

          const newQuery = {
            userFavorites: {
              ...favs?.userFavorites,
              count: (favs?.userFavorites.count ?? 0) + 1,
              favorites: [
                {
                  ref: resultData?.createFavorite.ref ?? '',
                  _id: resultData?.createFavorite._id ?? '',
                  __typename: resultData?.createFavorite.__typename,
                },
              ].concat(
                favs?.userFavorites.favorites as ConcatArray<{
                  ref: string;
                  _id: string;
                  __typename: 'Favorite' | undefined;
                }>
              ),
            },
          };
          cache.writeQuery({
            query: GetUserFavoritesRefsDocument,
            data: newQuery,
          });
        },
      })
        .then(() => {
          addEvent(cartEntry.name);
          setShouldRefetchFavorites(true);
          toast.show({
            text: formatMessage({ id: 'itemFavorited' }),
            variant: 'positive',
          });
        })
        .catch(() => {
          toast.show({
            text: formatMessage({ id: 'itemFavoritedError' }),
            variant: 'negative',
          });
        });
    }
  };

  const loading = createLoading || deleteLoading;
  const accessibilityLabel = isFavorite
    ? formatMessage({ id: 'removeFavorite' })
    : formatMessage({ id: 'addFavorite' });

  return shouldDisplayHeart ? (
    <IconButton
      variant="ghost"
      onPress={onClick}
      testID="favorite-icon"
      disabled={loading}
      icon={<FavoriteIcon isFavorite={isFavorite} disabled={loading} loading={loading} />}
      accessibilityLabel={accessibilityLabel}
      accessibilityState={{ checked: true }}
    />
  ) : null;
};

export default FavoriteEntryButton;
