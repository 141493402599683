import { ReactNode } from 'react';

import { BoxProps } from '@fhs-legacy/universal-components';

export type DialogStateReturn = {
  /**
   * Whether it's visible or not.
   */
  visible: boolean;
  /**
   * Changes the `visible` state to `true`
   */
  show: () => void;
  /**
   * Changes the `visible` state to `false`
   */
  hide: () => void;
  /**
   * Toggles the `visible` state
   */
  toggle: () => void;
};

export interface IBottomDrawerProps {
  /**
   * Pass in the bottomDrawer object like so:
   * ```tsx
   * const bottomDrawer = useBottomDrawerUIState().
   * ```
   * This object controls the state of the bottomDrawer -> bottomDrawer.show() or bottomDrawer.hide()
   */
  dialogState: DialogStateReturn;
  /**
   * The header for the drawer
   */
  header: string;
  /**
   * The body for the drawer
   */
  children: NonNullable<ReactNode>;
  /**
   * used purely for testing purposes
   */
  testID?: string;
  /**
   * allow user to close BottomDrawer on backdrop click
   */
  hideOnClickOutside?: boolean;
  /**
   * label for screen-readers
   */
  accessibilityLabel?: string;
  /**
   * Horizontal alignment
   */
  horizontalAlignment?: BoxProps['alignItems'];
  /**
   * Content Width
   */
  contentWidth?: BoxProps['maxWidth'];
}
