import { Text } from '@fhs-legacy/universal-components';
import { SectionHeading } from 'pages/support-v2/support-form-v2.styled';

export const RecentOrdersDescriptionText = Text.withConfig({
  marginBottom: '8',
});

export const HeadingStyled = SectionHeading.withConfig({
  lineHeight: '32px',
});
