import { useIntl } from 'react-intl';

import { IServerOrder } from '@rbi-ctg/menu';
import { DeliveryStatus } from 'generated/rbi-graphql';

import { IErrorsListItems } from '../types';

export const useDeliveryErrorModalCopy = (
  serverOrder?: IServerOrder | null,
  errorsList?: IErrorsListItems
) => {
  const { formatMessage } = useIntl();
  const errList = errorsList || [];
  const getErrorMsgList = (arr: IErrorsListItems) => {
    const detailedMsg = arr?.filter((item, i) => {
      return arr[i][Object.keys(item)[0]] || false;
    });
    if (detailedMsg.length >= 1) {
      return (
        formatMessage({ id: 'problemsDetected' }) +
          ': \n' +
          ' ' +
          detailedMsg
            ?.map(item => {
              return '-' + formatMessage({ id: 'error.' + Object.keys(item)[0] });
            })
            .join('\n') || ''
      );
    }
    return '';
  };

  const issues = getErrorMsgList(errList);
  const isDeliveryError = serverOrder?.delivery?.status === DeliveryStatus.QUOTE_UNAVAILABLE;
  const { errorModalBody, errorModalTitle }: { errorModalBody?: string; errorModalTitle?: string } =
    isDeliveryError
      ? {
          errorModalBody: formatMessage({ id: 'pleaseTryAgainLater' }) + issues,
          errorModalTitle: formatMessage({ id: 'yourLocationCannotAcceptDelivery' }),
        }
      : { errorModalBody: issues };
  return { errorModalBody, errorModalTitle };
};
