import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import {
  ActionSheet,
  Button,
  FormControlTextInput,
  IconClose,
  IconOffer,
  IconOfferOutline,
  Text,
  createMqStyles,
  tokens,
} from '@fhs/ui';

import { useRedeemPromoCode } from './use-redeem-promo-code';
export interface PromoCodeDrawerProps {
  isOpen: boolean;
  onDismiss: () => void;
  promoCode?: string;
}

export enum PromoCodeErrorReason {
  redeemedCode = 'REDEEMED_CODE',
  expiredCode = 'EXPIRED_CODE',
  invalidCode = 'INVALID_CODE',
}

export class PromoCodeError extends Error {
  public message: PromoCodeErrorReason;

  constructor(message: PromoCodeErrorReason) {
    super();
    this.message = message;
  }
}

export const PromoCodeDrawer = ({ isOpen, onDismiss, promoCode }: PromoCodeDrawerProps) => {
  const [promoCodeValue, setPromoCodeValue] = useState(promoCode ?? '');
  const mqStyles = useMqStyles();
  const { redeemPromoCode, errorMessage, isRedeeming } = useRedeemPromoCode({ onDismiss });
  return (
    <ActionSheet isVisible={isOpen} onClose={onDismiss}>
      <View style={styles.modalContainer}>
        <View style={styles.headerContainer}>
          <View style={styles.iconCircle}>
            <IconOfferOutline size={40} color={tokens.colors.$black} />
          </View>
          <View>
            <Text.Ui size="lg" weight="semibold" style={styles.headerTitle}>
              Enable Exclusive Offer
            </Text.Ui>
          </View>
          <View>
            <Text.Ui
              size="lg"
              numberOfLines={2}
              ellipsizeMode="tail"
              style={styles.headerDescription}
            >
              If you received a promo code, you can enable an exclusive offer.
            </Text.Ui>
          </View>
        </View>
        <View style={styles.formContainer}>
          <View style={styles.inputContainer}>
            <Text.Ui size={'sm'} weight="bold">
              Promo Code
            </Text.Ui>
            <FormControlTextInput
              placeholder={'Enter your code'}
              onChangeText={setPromoCodeValue}
              value={promoCodeValue}
              leftIcon={<IconOffer size={24} style={mqStyles.inputLeftIcon} />}
              style={errorMessage ? styles.errorInputBox : null}
            />
            {errorMessage && (
              <Text.Ui size={'sm'} style={styles.errorMessage}>
                {errorMessage}
              </Text.Ui>
            )}
          </View>
          <View>
            <Button
              style={styles.applyButton}
              loading={isRedeeming}
              disabled={isRedeeming}
              size="lg"
              onPress={() => {
                redeemPromoCode(promoCodeValue);
              }}
            >
              <Button.Text>Apply Promo Code</Button.Text>
            </Button>
          </View>
        </View>
        <Button style={styles.closeButton} type={'unstyled'} onPress={() => onDismiss()}>
          <Button.Icon size={32}>
            <IconClose />
          </Button.Icon>
        </Button>
      </View>
    </ActionSheet>
  );
};

const styles = StyleSheet.create({
  applyButton: {
    height: 48,
    marginTop: 24,
  },
  closeButton: {
    position: 'absolute',
    right: 16,
    borderColor: tokens.colors.$transparent,
    borderWidth: 0,
  },
  formContainer: {
    width: '100%',
    flexDirection: 'column',
    margin: 0,
  },
  inputContainer: {
    gap: 4,
  },
  headerContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
  headerDescription: {
    textAlign: 'center',
    fontSize: 20,
    marginBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  headerTitle: {
    padding: 16,
    fontSize: 24,
  },
  iconCircle: {
    height: 64,
    width: 64,
    borderRadius: 200,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: tokens.colors.$houseLight,
    marginRight: 12,
    gap: 10,
  },
  modalContainer: {
    borderRadius: 12,
    padding: 24,
  },
  errorInputBox: {
    borderColor: tokens.colors.$error,
  },
  errorMessage: {
    color: tokens.colors.$error,
  },
});

const useMqStyles = createMqStyles({
  inputLeftIcon: {
    $base: {
      marginRight: 8,
      color: tokens.colors.$blackOpacity30,
    },
    $ltDesktop: {
      color: tokens.colors.$blackOpacity75,
    },
  },
});
