import { maybeLocale } from 'utils/graphql';

const typeParser = (data: { [key: string]: any }) => {
  const parsingFunction = {
    LocaleString: maybeLocale,
    LocaleImage: maybeLocale,
  }[data?.__typename];

  return parsingFunction ? parsingFunction(data) : null;
};

const isValidData = <U extends {}>(data: {}, keysToValidate: readonly (keyof U)[]): data is U =>
  // @ts-expect-error TS(2536) Type 'keyof U' cannot be used to index type '{}'.
  keysToValidate.every(key => data[key]);

export const parseData = <U extends {}>(
  data: {} | null | undefined,
  keysToValidate: readonly (keyof U)[]
) => {
  if (!data) {
    return null;
  }
  const parsedData = Object.fromEntries(
    Object.entries(data)
      .map(([key, val]) => [key, typeParser(val as any)])
      .filter(([_, v]) => v)
  );

  return isValidData<U>(parsedData, keysToValidate) ? parsedData : null;
};
