import React, { VFC } from 'react';
import { useIntl } from 'react-intl';

import { theme } from 'styles/configure-theme';

import { MethodType } from './styled';

interface IPayPalMethod {
  isDisabled: boolean;
}

const PayPalMethod: VFC<IPayPalMethod> = ({ isDisabled }) => {
  const { formatMessage } = useIntl();

  return (
    <MethodType color={isDisabled ? theme.token('text-disabled') : theme.token('text-default')}>
      {formatMessage({ id: 'payWithPayPal' })}
    </MethodType>
  );
};

export default PayPalMethod;
