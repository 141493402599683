import { router } from 'expo-router';
import React from 'react';
import { Linking } from 'react-native';

import { Header, Text, VStack } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import Picture from 'components/picture';
import { isExternalLink } from 'utils/is-external-link';

import { useStaticContentLanguage } from './static-content-language';

const CallToActionHeaderContainer = VStack;

const CallToActionHeading = Header.withConfig({
  variant: 'headerTwo',
  textAlign: 'center',
  marginTop: '$4',
});

const CallToActionSubheading = Header.withConfig({
  variant: 'headerThree',
  textAlign: 'center',
  color: Styles.color.primary,
  fontWeight: 'normal',
});

const CallToActionBody = Text.withConfig({
  marginBottom: '$8',
});

const Image = Picture.withConfig({
  width: '66%',
  objectFitContain: true,
  marginX: 'auto',
  skipBreakpoints: true,
});

const CallsToAction = VStack.withConfig({
  width: '90%',
  maxWidth: '920px',
  marginTop: '0',
  marginX: 'auto',
  space: '$16',
  marginBottom: '$16',
});

const CallToAction = VStack.withConfig({
  width: '100%',
});

export default function CallToActionWidget(props) {
  const { staticContentLanguage: language } = useStaticContentLanguage();
  const navigateToUrl = url => {
    if (isExternalLink(url)) {
      Linking.openURL(url);
    } else {
      router.navigate(url);
    }
  };

  return (
    <CallsToAction>
      {props.callToActionContent.map((cta, index) => {
        return (
          <CallToAction key={index}>
            {cta.image && <Image objectFitContain image={cta.image?.[language]} alt="" />}
            <CallToActionHeaderContainer>
              {cta.heading && <CallToActionHeading>{cta.heading?.[language]}</CallToActionHeading>}
              {cta.subheading?.[language] && (
                <CallToActionSubheading>{cta.subheading?.[language]}</CallToActionSubheading>
              )}
              {cta.body && <CallToActionBody>{cta.body?.[language]}</CallToActionBody>}
              {cta.buttonText && (
                <ActionButton
                  w={{ base: 'full', desktop: '50%' }}
                  mx={{ desktop: 'auto' }}
                  variant="solid"
                  color="primary"
                  onPress={() => navigateToUrl(cta.link?.[language])}
                  wrapText
                >
                  {cta.buttonText?.[language]}
                </ActionButton>
              )}
            </CallToActionHeaderContainer>
          </CallToAction>
        );
      })}
    </CallsToAction>
  );
}
