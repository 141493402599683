import { WebViewMessageEvent } from 'react-native-webview';

import {
  IEncryptionConfig,
  IEncryptionError,
  IEncryptionResult,
  ISafetechEncryptionHtmlConfig,
} from './types';

export const ensureSafetechConfig = (config: IEncryptionConfig) => {
  if (!config || !config.baseUrl) {
    throw new Error('Safetech encryption config not available');
  }
};

export const generateWebViewHtml = (htmlConfig: ISafetechEncryptionHtmlConfig) => {
  const { baseUrl, pieFormat, subscriberId } = htmlConfig.config;
  const getKeyScriptUrl = `${baseUrl}/pie/v1/${pieFormat}${subscriberId}/getkey.js`;
  const encryptionScriptUrl = `${baseUrl}/pie/v1/encryption.js`;

  let encryptCallInBody = '';
  switch (htmlConfig.type) {
    case 'SAFETECH_ENCRYPTION':
      encryptCallInBody = `
        encrypt(
          "${htmlConfig.cardNumber}",
          "${htmlConfig.cvv}",
          ${htmlConfig.config.mode === 'eFPE'}
        );
      `;
      break;
    default:
      break;
  }

  return `
    <body>

      <script src="${getKeyScriptUrl}"></script>
      <script src="${encryptionScriptUrl}"></script>

      <script>
        // validate input parameters
        async function validate(cardNumber, embed) {
          // Check MOD 10 digit, since PIE embedded encryption
          // requires that the MOD 10 checksum is valid.
          if (!ValidatePANChecksum(cardNumber)) {
            throw new Error("PAN has invalid checksum");
          }
        }

        // Perform encryption and post results back to component
        async function encrypt(cardNumber, cvv, embed) {
          try {
            await validate(cardNumber, embed);
            const result = ProtectPANandCVV(cardNumber, cvv, !embed);
            if (!result) {
              throw new Error('Encryption failed');
            }
            const  [cryptCard, cryptCvv, integrityCheck] = result;
            window.ReactNativeWebView.postMessage(JSON.stringify({
              cryptCard,
              cryptCvv,
              integrityCheck,
              keyId: window.PIE.key_id,
              phase: window.PIE.phase,
              mode: embed ? 'eFPE' : 'FPE',
            })); 
          } catch (err) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
              error: err.message
            }));
          }
        }

        ${encryptCallInBody}

      </script>
    </body>
  `;
};

export const createMessagehandler = (
  subscriberId: string,
  pieFormat: string,
  onError: (err: IEncryptionError) => void,
  onResult: (result: IEncryptionResult) => void
) => {
  return (event: WebViewMessageEvent) => {
    const data = JSON.parse(event.nativeEvent.data);
    if ('error' in data) {
      return onError(data as IEncryptionError);
    }
    return onResult({ ...data, subscriberId, pieFormat } as IEncryptionResult);
  };
};
