import { REGIONS } from '../../state/intl/types';

import {
  DEFAULT_PAYMENT_COUNTRY,
  DEFAULT_PAYMENT_CURRENCY,
} from './default-codes/default-payment-codes';

export enum ISOs {
  USA = 'USA',
  CAN = 'CAN',
  US = 'USA',
  CA = 'CAN',
}

export { ISOs as IsoCountryCode };

export enum RegionToISOs {
  US = ISOs.USA,
  CA = ISOs.CAN,
}

export enum ISOsToRegions {
  USA = REGIONS.US,
  CAN = REGIONS.CA,
}

export enum PaymentISOs {
  US = 'US',
  CA = 'CA',
  CH = 'CH',
  DE = 'DE',
  GB = 'GB',
  ZA = 'ZA',
}

export enum Currencies {
  USD = 'USD',
  CAD = 'CAD',
}

export {
  DEFAULT_PAYMENT_COUNTRY,
  DEFAULT_PAYMENT_CURRENCY,
} from './default-codes/default-payment-codes';

export const getCountryAndCurrencyCodes = (
  billingCountry: ISOs
): { countryCode: PaymentISOs; currencyCode: Currencies } => {
  const defaultCodes = {
    countryCode: DEFAULT_PAYMENT_COUNTRY,
    currencyCode: DEFAULT_PAYMENT_CURRENCY,
  };
  return (
    {
      [ISOs.USA]: { countryCode: PaymentISOs.US, currencyCode: Currencies.USD },
      [ISOs.CAN]: { countryCode: PaymentISOs.CA, currencyCode: Currencies.CAD },
    }[billingCountry] || defaultCodes
  );
};

export const postalCodeStringLocaleKey = {
  [ISOs.USA]: 'zipCode',
  [ISOs.CAN]: 'postalCode',
};

export const postalCodeInputProperties = {
  [ISOs.USA]: { maxLength: 5, pattern: '[0-9]*' },
  [ISOs.CAN]: { maxLength: 7 },
};

export const regionStringLocaleKey = {
  [ISOs.USA]: 'state',
  [ISOs.CAN]: 'province',
};

export const regionInputProperties = {
  [ISOs.USA]: { maxLength: 2 },
  [ISOs.CAN]: { maxLength: 2 },
};
