import { IconCatering, IconDelivery, IconLocationFill, IconStore } from '../icon';

export const IconByType = {
  catering: IconCatering,
  delivery: IconDelivery,
  deliveryWithFees: IconDelivery,
  pickup: IconStore,
  location: IconLocationFill,
} as const;

export const ServiceModeTextByType = {
  catering: 'Catering',
  delivery: 'Delivery',
  deliveryWithFees: '',
  pickup: 'Pick Up',
  location: 'Choose Location',
} as const;

export const LabelByType = {
  catering: 'Pick up at:',
  delivery: 'Deliver at:',
  deliveryWithFees: 'Deliver at:',
  pickup: 'Pick up at:',
  location: '',
} as const;
