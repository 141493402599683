import { router } from 'expo-router';
import { ExecutionResult } from 'graphql';
import React, { memo, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Wordmark } from '@fhs/ui';
import { Box, Header } from '@fhs-legacy/universal-components';
import { IServerOrder } from '@rbi-ctg/menu';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import { ICommitOrderInput, useCommitOrder } from 'hooks/commit-order';
import useDialogModal from 'hooks/use-dialog-modal';
import { ICommitOrderMutationResponse } from 'remote/queries/order';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { OrderStatus, useOrderContext } from 'state/order';
import { usePaymentContext } from 'state/payment';
import { CASH_ACCOUNT_IDENTIFIER } from 'state/payment/constants';
import { IPaymentDetails } from 'state/payment/types';
import { ServiceMode } from 'state/service-mode';
import {
  GraphQLErrorCodes,
  getErrorDialogForGraphQLCode,
  parseGraphQLErrorCodes,
} from 'utils/errors';
import { IFraudPreventionValues, splitExpiry } from 'utils/payment';
import { routes } from 'utils/routing';

import { CardButton } from './card-button';
import { LogoContainer } from './common';
import { TAP_IM_CLOSE_CASH_PAYMENT_TRANSLATION_ID, TAP_IM_CLOSE_TRANSLATION_ID } from './constants';
import { StoreCard } from './store-card';
import { Heading } from './styled';
import theme from './theme';

type onCommitType = (
  input: ICommitOrderInput
) => Promise<ExecutionResult<ICommitOrderMutationResponse>>;

const PickupInstructions = Header.withConfig({
  textAlign: 'center',
  color: theme.pickupInstructionsColor,
  fontSize: 'md',
});

const handleCommitOrder = (
  onCommit: onCommitType,
  payment: {
    clearPaymentDetails(): void;
    paymentDetails: IPaymentDetails;
  },
  serviceMode: ServiceMode,
  skipCoolingPeriod?: boolean
) => {
  const { paymentDetails, clearPaymentDetails } = payment;
  const {
    accountIdentifier,
    creditType,
    fdAccessToken,
    fdNonce,
    fraudPreventionValues = {} as IFraudPreventionValues,
    applePayDetails,
    googlePayDetails,
  } = paymentDetails;

  let commitInput;

  if (accountIdentifier === CASH_ACCOUNT_IDENTIFIER) {
    commitInput = {
      creditType: CASH_ACCOUNT_IDENTIFIER,
      order: { serviceMode },
      skipCoolingPeriod,
      payment: {
        cashPayment: true,
        fullName: fraudPreventionValues.fullName,
      },
    };

    return onCommit(commitInput).then(() => {
      clearPaymentDetails();
    });
  }

  const { expiryMonth, expiryYear } = splitExpiry(fraudPreventionValues.expiry ?? '');

  return onCommit({
    applePayDetails,
    creditType,
    fdAccountId: accountIdentifier,
    fullName: fraudPreventionValues.fullName,
    order: { serviceMode },
    payment: {
      billingAddress: fraudPreventionValues.billingAddress,
      ccMetadata: {
        ccBin: fraudPreventionValues.ccBin,
        ccExpiryMonth: expiryMonth,
        ccExpiryYear: expiryYear,
        ccLast4: fraudPreventionValues.ccLast4,
      },
      firstDataInput: {
        accountIdentifier,
        fdAccessToken,
        fdNonce,
      },
      fullName: fraudPreventionValues.fullName,
    },
    googlePayDetails,
    skipCoolingPeriod,
  })
    .then(() => {
      clearPaymentDetails();
    })
    .catch(err => {
      throw err;
    });
};

const PickupCard = ({
  confirmed = false,
  serverOrder,
}: {
  confirmed?: boolean;
  serverOrder: IServerOrder;
}) => {
  const { emptyCart, onCommitSuccess } = useOrderContext();

  const { serviceMode } = serverOrder.cart;

  const { commitOrder, loading, orderStatus, commitResult } = useCommitOrder({
    rbiOrderId: serverOrder.rbiOrderId,
  });

  const { clearPaymentDetails, paymentDetails } = usePaymentContext();

  const onCommitOrderSkipCooldown = useCallback(() => {
    const payment = {
      clearPaymentDetails,
      paymentDetails,
    };
    handleCommitOrder(commitOrder, payment, serviceMode, true);
    onCommitSuccess(serverOrder);
    return emptyCart();
  }, [
    clearPaymentDetails,
    commitOrder,
    emptyCart,
    onCommitSuccess,
    paymentDetails,
    serverOrder,
    serviceMode,
  ]);

  const onCommitOrder = useCallback(() => {
    const payment = {
      clearPaymentDetails,
      paymentDetails,
    };
    handleCommitOrder(commitOrder, payment, serviceMode, undefined);
    onCommitSuccess(serverOrder);
    return emptyCart();
  }, [
    clearPaymentDetails,
    commitOrder,
    emptyCart,
    onCommitSuccess,
    paymentDetails,
    serverOrder,
    serviceMode,
  ]);

  const onCancelModal = () => router.navigate(routes.orders);
  const [OrderCooldownErrorDialog, openOrderCooldownErrorDialog] = useDialogModal({
    onConfirm: onCommitOrderSkipCooldown,
    onCancel: onCancelModal,
    showCancel: true,
    modalAppearanceEventMessage: 'Confirmation Modal: Store Location',
  });

  const { formatMessage } = useIntl();
  const orderIsLoading = loading || orderStatus === OrderStatus.INSERT_REQUESTED;
  const enableImCloseDisplay = useFlag(LaunchDarklyFlag.ENABLE_IM_CLOSE_DISPLAY);

  const shouldShowImCloseText = enableImCloseDisplay && serviceMode !== ServiceMode.CURBSIDE;

  const screenReaderButtonName = shouldShowImCloseText ? 'imClose' : 'imHere';

  const isCashPayment = paymentDetails.accountIdentifier === CASH_ACCOUNT_IDENTIFIER;
  useEffect(() => {
    if (!commitResult.error) {
      return;
    }

    const [parsedGqlError] = parseGraphQLErrorCodes(commitResult.error);
    if (!parsedGqlError) {
      return;
    }

    const mappedErrorCode = getErrorDialogForGraphQLCode(parsedGqlError, formatMessage);
    const isCommitOrderCoolingPeriodError =
      parsedGqlError.errorCode === GraphQLErrorCodes.COMMIT_ORDER_COOLING_PERIOD;

    if (mappedErrorCode && isCommitOrderCoolingPeriodError) {
      const { message, title = '' } = mappedErrorCode;
      openOrderCooldownErrorDialog({ message, title });
    }
  }, [commitResult, formatMessage, openOrderCooldownErrorDialog]);

  return (
    <>
      <Box testID="pickup-card">
        <VisuallyHidden
          role="alert"
          accessibilityLabel={formatMessage(
            { id: 'pickUpConfirmationScreenReaderInstructions' },
            {
              screenReaderButtonName,
            }
          )}
        />
        {!confirmed && (
          <>
            <LogoContainer>
              <Wordmark />
            </LogoContainer>

            <Heading>{formatMessage({ id: 'savedOrder' })}</Heading>
            <PickupInstructions>
              {formatMessage({
                id: isCashPayment
                  ? shouldShowImCloseText
                    ? TAP_IM_CLOSE_CASH_PAYMENT_TRANSLATION_ID
                    : 'tapImHereCashPayment'
                  : shouldShowImCloseText
                  ? TAP_IM_CLOSE_TRANSLATION_ID
                  : 'tapImHere',
              })}
            </PickupInstructions>
          </>
        )}
      </Box>
      <StoreCard
        latitude={serverOrder.cart.storeDetails.latitude}
        longitude={serverOrder.cart.storeDetails.longitude}
        address={serverOrder.cart.storeAddress.addressLine1}
        city={serverOrder.cart.storeAddress.city}
        state={serverOrder.cart.storeAddress.state}
      />

      {!confirmed && (
        <CardButton
          isLoading={orderIsLoading}
          onCommit={onCommitOrder}
          orderStatus={orderStatus}
          storeDetails={{
            latitude: serverOrder.cart.storeDetails.latitude,
            longitude: serverOrder.cart.storeDetails.longitude,
          }}
          shouldShowImCloseText={shouldShowImCloseText}
        />
      )}

      <OrderCooldownErrorDialog
        confirmLabel={formatMessage({ id: 'placeOrder' })}
        cancelLabel={formatMessage({ id: 'recentOrders' })}
      />
    </>
  );
};

export default memo(PickupCard);
