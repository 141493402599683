import { extendTheme } from '@fhs-legacy/native-base';

import { NativeBaseThemeTokens, UclThemeComponentOptions } from './types';

/**
 * Helper for creating NativeBase theme tokens.
 *
 * Must be separated from theme components due to circular type dependency.
 */
export const createNativeBaseThemeTokens = <T extends NativeBaseThemeTokens>(theme: T) => theme;

/**
 * Helper for creating NativeBase theme components.
 *
 * Enables access to all prop types of components.
 *
 * Must be separated from theme tokens due to a circular type dependency.
 */
export const createNativeBaseThemeComponents: (...args: UclThemeComponentOptions[]) => any = (
  themeComponents: UclThemeComponentOptions,
  ...rest: UclThemeComponentOptions[]
) => {
  const { components } = extendTheme(
    { components: themeComponents },
    ...rest.map((input) => ({
      components: input,
    })),
  );

  return components;
};
