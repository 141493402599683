import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconHomeFill = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M5.625 20.7c-.3 0-.563-.112-.787-.337a1.08 1.08 0 0 1-.338-.787v-9.55c0-.184.037-.355.112-.513a.946.946 0 0 1 .338-.387l6.375-4.8a.884.884 0 0 1 .325-.175 1.269 1.269 0 0 1 .713 0 .904.904 0 0 1 .312.175l6.375 4.8c.15.1.262.229.337.387.075.158.113.33.113.513v9.55c0 .3-.113.562-.337.787a1.08 1.08 0 0 1-.788.338H13.9V14h-3.8v6.7H5.625Z"
      />
    </Svg>
  );
};
