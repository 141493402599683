import { Text } from '@fhs-legacy/universal-components';
import { Box } from '@fhs-legacy/universal-components';
import Styles from 'styles/index';

export const Content = Box.withConfig({
  padding: '$4',
});

export const BorderedContent = Box.withConfig({
  borderWidth: 1,
  borderColor: 'blackOpacity.10',
  borderRadius: 'md',
  padding: '$4',
  backgroundColor: Styles.color.white,
});

export const Header = Text.withConfig({
  bold: true,
  fontSize: 'lg',
});

export const RewardBadge = Box.withConfig({});
