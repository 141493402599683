import { Box, Header, Text } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

export const LayoutContainer = Box.withConfig({
  marginX: '1/5',
  marginY: 0,
  paddingY: 0,

  margin: {
    base: 0,
  },
});

export const Guide = Box.withConfig({
  padding: '$6',
  margin: 0,
  width: 'full',
  height: '100%',
});

export const DescriptionTitle = Box.withConfig({
  justifyContent: 'center',
  width: 'full',
});

export const CustomizationContainer = Box.withConfig({
  width: 'full',
  maxWidth: 485,
  alignSelf: 'center',
});

export const Information = Header.withConfig({
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0,
  variant: 'headerThree',
  color: theme.token('text-default'),
});

export const Description = Box.withConfig({
  _text: {
    color: Styles.color.secondary,
    fontFamily: Styles.fontFamily.body,
  },

  margin: 0,
});

export const fixedButtonCSS = {
  color: theme.token('text-button-primary'),
};

export const regularButtonCSS = {
  marginBottom: '$8',
};

export const InformationContainer = Box.withConfig({
  paddingX: '$3.5',
  paddingTop: '$8',
});

export const OptionTile = Box.withConfig({
  marginTop: 0,
  marginRight: 0,
  marginBottom: '$2',
  marginLeft: 0,
  paddingX: '$5',
  paddingY: '$4',

  _text: {
    color: Styles.color.black,
    fontSize: 'md',
    lineHeight: 'xl',
  },

  backgroundColor: Styles.color.white,
  borderTopLeftRadius: Styles.borderRadius,
  borderTopRightRadius: Styles.borderRadius,
  borderBottomRightRadius: Styles.borderRadius,
  borderBottomLeftRadius: Styles.borderRadius,

  // TODO: RN - unsupported CSS
  // outline: '__3substitution__',
});

export const Title = Header.withConfig({
  width: 'full',
  textAlign: 'center',
  variant: 'headerOne',
  color: Styles.color.black,
  margin: 0,
  accessibilityLevel: 1,
});

export const ImageDisclaimer = Text.withConfig({
  fontSize: 'xs',
  color: Styles.color.black,
  marginBottom: '$4',
});
