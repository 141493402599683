import { Box } from '@fhs-legacy/universal-components';

import { DASHBOARD_PADDING_SIZE } from '../../loyalty-dashboard/constants';

export const Container = Box.withConfig({
  width: 'full',
  marginTop: '$4',
  paddingX: DASHBOARD_PADDING_SIZE,
  paddingBottom: '$4',
  borderTopRadius: {
    desktop: Styles.borderRadius,
  },
});

export const Content = Box.withConfig({
  alignItems: 'center',
});

export const TableWrap = Box.withConfig({
  borderRadius: 10,
  backgroundColor: Styles.color.white,
  width: 'full',
  maxWidth: 620,
});
