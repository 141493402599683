import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { makeUclComponent } from '@fhs-legacy/universal-components';
import { PromotionType } from 'generated/graphql-gateway';
import { IIncentiveEvaluationResult } from 'state/loyalty/hooks/types';
import { getEvaluationResultMessage } from 'state/loyalty/hooks/utils/helpers';

import {
  IncentiveEvaluationMessagesContainer,
  SingleMessage,
} from './incentive-evaluation-messages.styled';

interface IIncentiveEvaluationMessageContentProps {
  message?: React.ReactNode;
  hideIcon?: boolean;
  isSmall?: boolean;
  fitContentWidth?: boolean;
}

const IncentiveEvaluationMessageContent = ({
  message,
  isSmall,
  fitContentWidth = false,
}: IIncentiveEvaluationMessageContentProps) => {
  return (
    <SingleMessage
      message={message}
      status="info"
      size={isSmall ? 'sm' : 'md'}
      $fitContentWidth={fitContentWidth}
    />
  );
};

interface IIncentiveEvaluationMessageProps {
  message?: React.ReactNode;
  hideIcon?: boolean;
  isSmall?: boolean;
  fitContentWidth?: boolean;
}

const _IncentiveEvaluationMessage: FC<
  React.PropsWithChildren<IIncentiveEvaluationMessageProps>
> = ({ message = '', hideIcon = false, isSmall = false, fitContentWidth, ...rest }) => {
  return (
    <IncentiveEvaluationMessagesContainer {...rest}>
      <IncentiveEvaluationMessageContent
        message={message}
        hideIcon={hideIcon}
        isSmall={isSmall}
        fitContentWidth={fitContentWidth}
      />
    </IncentiveEvaluationMessagesContainer>
  );
};

interface IIncentiveEvaluationMessagesProps {
  incentiveEvaluationResults: IIncentiveEvaluationResult[];
  hideIcon?: boolean;
  isSmall?: boolean;
  fitContentWidth?: boolean;
}

const _IncentiveEvaluationMessages: FC<
  React.PropsWithChildren<IIncentiveEvaluationMessagesProps>
> = ({
  incentiveEvaluationResults = [],
  hideIcon = false,
  isSmall = false,
  fitContentWidth,
  ...rest
}) => {
  const formatters = useIntl();

  return (
    <IncentiveEvaluationMessagesContainer {...rest}>
      {incentiveEvaluationResults.map(evaluationResult => {
        const message = getEvaluationResultMessage({
          evaluationResult,
          formatters,
          incentiveType: PromotionType.OFFER,
        });

        return (
          <IncentiveEvaluationMessageContent
            key={evaluationResult.code}
            hideIcon={hideIcon}
            message={message}
            isSmall={isSmall}
            fitContentWidth={fitContentWidth}
          />
        );
      })}
    </IncentiveEvaluationMessagesContainer>
  );
};

export const IncentiveEvaluationMessage = makeUclComponent(_IncentiveEvaluationMessage);
export const IncentiveEvaluationMessages = makeUclComponent(_IncentiveEvaluationMessages);
