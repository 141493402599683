import { type XStackProps } from '@fhs/ui';

export type HomeAlertLaunchDarklyPayload =
  | {
      text: undefined;
      link: undefined;
    }
  | {
      text: string;
      link?: {
        text: string;
        href: string;
      };
    };

export type HomeAlertProps = XStackProps & {
  text: string;
  link?: {
    text: string;
    href: string;
  };
};
