import { useIntl } from 'react-intl';

import {
  ISOs,
  postalCodeStringLocaleKey,
  regionInputProperties,
  regionStringLocaleKey,
} from 'utils/form/constants';
import { loadRegion } from 'utils/intl/region';

const useCreditCardFormProps = (isoCountryCode: ISOs = ISOs[loadRegion()]) => {
  // construct postal code props based on users country
  const { formatMessage } = useIntl();
  const postalCodeInputProps = {
    label: formatMessage({ id: postalCodeStringLocaleKey[isoCountryCode] }),
  };
  const regionInputProps = {
    label: formatMessage({ id: regionStringLocaleKey[isoCountryCode] }),
    ...regionInputProperties[isoCountryCode],
  };

  return { postalCodeInputProps, regionInputProps };
};

export default useCreditCardFormProps;
