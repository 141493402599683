import { DeliveryStatus, OrderStatus } from '../types';

export * from './tip-amounts';

export const OrderSuccessFailureStatuses = {
  success: {
    catering: [OrderStatus.CATERING_PLACED, OrderStatus.CATERING_SUCCESSFUL],
    delivery: [
      DeliveryStatus.ORDER_CREATED,
      DeliveryStatus.DRIVER_ASSIGNED,
      DeliveryStatus.DRIVER_STARTING,
    ],
    pos: [OrderStatus.INSERT_SUCCESSFUL],
  },
  inProgress: {
    pos: [
      OrderStatus.PRICE_REQUESTED,
      OrderStatus.PAYMENT_REQUESTED,
      OrderStatus.INSERT_REQUESTED,
      OrderStatus.UPDATE_REQUESTED,
      OrderStatus.PAYMENT_SUCCESSFUL,
    ],
  },
  failures: {
    catering: [OrderStatus.CATERING_DECLINED, OrderStatus.CATERING_ERROR],
    delivery: [
      DeliveryStatus.QUOTE_ERROR,
      DeliveryStatus.ORDER_ERROR,
      DeliveryStatus.ORDER_TIMEOUT,
    ],
    pos: [OrderStatus.INSERT_ERROR, OrderStatus.PAYMENT_ERROR, OrderStatus.VALIDATION_ERROR],
  },
};
