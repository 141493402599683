import { ActivityIndicator, StyleSheet, View } from 'react-native';

import { useMutation } from '@fhs/client';
import { Button, IconOfferOutline, Text, tokens } from '@fhs/ui';

import { removeOfferfromCart, useCart } from '../../api';

type Props = {
  id: string;
  onClose(): void;
};

export function CartActionRemoveOffer(props: Props) {
  const { data: cart, isPending } = useCart();
  const { isPending: removingOffer, mutate } = useMutation({
    ...removeOfferfromCart,
    onSuccess() {
      props.onClose();
    },
  });

  if (isPending || removingOffer) {
    return <ActivityIndicator size="large" />;
  }

  const incentive = cart?.appliedIncentives.find(({ offerId }) => offerId === props.id);

  return (
    <View style={styles.container}>
      <Text.Heading type="one">Remove Offer</Text.Heading>
      <Text.Ui size="md" style={styles.copy}>
        Are you sure you want to remove this item from your cart?
      </Text.Ui>

      {incentive && (
        <View style={styles.offerContainer}>
          <IconOfferOutline size={24} />
          <Text.Ui size="md" weight="semibold" numberOfLines={1}>
            {incentive.title}
          </Text.Ui>
        </View>
      )}

      <View style={styles.buttonGroup}>
        <Button size="lg" onPress={() => mutate({ offerId: props.id })}>
          <Button.Text>Remove</Button.Text>
        </Button>
        <Button size="lg" type="outline" onPress={props.onClose}>
          <Button.Text>Cancel</Button.Text>
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  offerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 16,
    gap: 12,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: tokens.colors.$black10,
    height: 48,
    width: '100%',
    marginBottom: 20,
  },
  copy: {
    paddingTop: 12,
    paddingBottom: 24,
  },
  buttonGroup: {
    gap: 8,
  },
  container: {
    maxWidth: 334,
    marginHorizontal: 'auto',
  },
});
