import { registerTheme } from '..';
import type { TBrand } from '../types';

import { fhsCachedFonts } from './cached-fonts';
import { fhsComponentTokens } from './components';
import { fhsNativeBaseTheme } from './native-base-theme';
import { fhsPrimitives } from './primitives';
import { fhsTokens } from './tokens';

export const fhsTheme = {
  key: 'fhs' as TBrand,
  primitives: fhsPrimitives,
  tokens: fhsTokens,
  components: fhsComponentTokens,
  nativeBaseTheme: fhsNativeBaseTheme,
  cachedFonts: fhsCachedFonts,
};

registerTheme(fhsTheme);
