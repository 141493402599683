import { router, useLocalSearchParams } from 'expo-router';
import { isFunction, isNil } from 'lodash';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { IStore } from '@rbi-ctg/store';
import { IRestaurant, IRestaurantNode } from 'generated/rbi-graphql';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { serviceModeList } from 'pages/cart/service-mode-details/service-mode-list';
import { NotFoundMenuItemError } from 'remote/exceptions';
import { useGeolocation } from 'state/geolocation';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuContext } from 'state/menu';
import { useOrderContext } from 'state/order';
import { useRestaurantPosConnectivityStatus } from 'state/restaurant-connectivity-status';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { useStaticMenuRedirect } from 'state/static-menu/use-static-menu-redirect';
import {
  checkIfWithinOneHourOfCloseToday,
  getDateNowByTimezone,
  milesBetweenCoordinates,
  readableCloseHourToday,
  readableDistanceFromStore,
  useGetRestaurantAvailabilityFn,
  useIsMobileOrderingAvailable,
} from 'utils/restaurant';
import { TWELVE_HOUR_TIME_PARSE_FORMAT } from 'utils/restaurant/constants';
import { routes } from 'utils/routing';
import { getStoreSEOSlug } from 'utils/slugify';

interface IDefaultServiceModeOptions {
  restaurant: IStore | IRestaurantNode;
}

export function useDefaultServiceMode({ restaurant }: IDefaultServiceModeOptions) {
  const defaultServiceModeInStore = useFlag(LaunchDarklyFlag.DEFAULT_SERVICE_MODE_IN_STORE);
  const { serviceModeStatus } = useServiceModeStatus(restaurant);

  // get default service mode from ld
  const defaultAvailableServiceMode = serviceModeList.find(key => {
    return (
      key === defaultServiceModeInStore &&
      serviceModeStatus[key].available &&
      !serviceModeStatus[key].disabled
    );
  });
  // default service mode to:
  // 1) ld flag defaultServiceModeInStore
  // 2) the first available shown in cart when choosing a store
  // 3) the first service mode
  return (
    defaultAvailableServiceMode ||
    serviceModeList.find(key => {
      return serviceModeStatus[key].available && !serviceModeStatus[key].disabled;
    }) ||
    serviceModeList[0]
  );
}

interface IUseSelectRestaurantOptions {
  restaurant: IStore | IRestaurantNode;
}

interface ISelectRestaurantOptions {
  callback?: () => void;
  requestedServiceMode?: ServiceMode;
}
export function useSelectRestaurant({ restaurant }: IUseSelectRestaurantOptions) {
  const params = useLocalSearchParams<{ back: string }>();
  const {
    selectStore,
    reorder: { pendingReorder, setShouldHandleReorder },
  } = useOrderContext();
  const { serviceMode, setServiceMode } = useServiceModeContext();

  const [checkingItemAvailability, setCheckingItemAvailability] = useState(false);
  const { checkStaticMenuItemAvailability, selectedStaticMenuItemId } = useMenuContext();
  const staticMenuRedirect = useStaticMenuRedirect();

  const enableServiceModeCartSelection = useFlag(
    LaunchDarklyFlag.ENABLE_SERVICE_MODE_CART_SELECTION
  );
  const enableOrderingFlag = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const enableOrdering = isNil(enableOrderingFlag) || enableOrderingFlag;
  const defaultServiceMode = useDefaultServiceMode({ restaurant });

  const navigateBack = useCallback(() => {
    router.navigate(params?.back || routes.menu);
  }, [params]);

  const defaultSetRestaurantCallback = useCallback(
    (requestedServiceMode: ServiceMode) => async () => {
      if (!enableOrdering) {
        router.navigate(routes.offers);

        return;
      }

      // set service mode to ensure we aren't taken back to static menu
      // need to do this after previous checks so that we don't set service mode
      // when enableOrdering is false and/or when a catering mode has already
      //  been selected
      setServiceMode(requestedServiceMode);

      // if user has a pending reorder return and let the useEffect handle it below
      if (pendingReorder) {
        setShouldHandleReorder(true);
        return;
      }

      // If showing static menu and user has selected item redirect to the item if
      // available after store selection, otherwise route to main menu
      if (selectedStaticMenuItemId) {
        try {
          setCheckingItemAvailability(true);
          await checkStaticMenuItemAvailability(restaurant, serviceMode);
        } catch (e) {
          throw new NotFoundMenuItemError(selectedStaticMenuItemId);
        } finally {
          setCheckingItemAvailability(false);
        }
        staticMenuRedirect({ restaurant, serviceMode });
      } else if (enableServiceModeCartSelection) {
        navigateBack();
      } else {
        // if the enableServiceModeCartSelection is false,
        // redirecting to this route will pop a modal
        // enabling user to select their preferred pickup
        // method (Drive Thru, Curbside, etc.)
        router.replace(routes.pickupMode);
      }
    },
    [
      checkStaticMenuItemAvailability,
      enableOrdering,
      enableServiceModeCartSelection,

      navigateBack,
      pendingReorder,
      restaurant,
      selectedStaticMenuItemId,
      serviceMode,
      setServiceMode,
      setShouldHandleReorder,
      staticMenuRedirect,
    ]
  );

  const selectRestaurant = useCallback(
    ({ requestedServiceMode = defaultServiceMode, callback }: ISelectRestaurantOptions = {}) => {
      const selectStoreCallback = isFunction(callback)
        ? callback
        : defaultSetRestaurantCallback(requestedServiceMode);

      selectStore(restaurant, selectStoreCallback, requestedServiceMode);
    },
    [defaultServiceMode, defaultSetRestaurantCallback, selectStore, restaurant]
  );

  return {
    selectRestaurant,
    checkingItemAvailability,
  };
}

interface IUseStoreCardOptions {
  restaurant: IStore | IRestaurantNode;
  shouldGetRemoteAvailabilityData?: boolean;
}

export function useStoreCard({
  restaurant,
  shouldGetRemoteAvailabilityData = true,
}: IUseStoreCardOptions) {
  const { activeCoordinates } = useGeolocation();
  const { region } = useLocale();
  const { formatMessage } = useIntl();
  const { serviceModeStatus } = useServiceModeStatus(restaurant);
  const driveThruOpen =
    serviceModeStatus.DRIVE_THRU.available && !serviceModeStatus.DRIVE_THRU.disabled;
  const dineInOpen = serviceModeStatus.EAT_IN.available && !serviceModeStatus.EAT_IN.disabled;
  const takeoutOpen = serviceModeStatus.TAKEOUT.available && !serviceModeStatus.TAKEOUT.disabled;
  const diningRoomOpen = dineInOpen || takeoutOpen;
  const curbsideOpen = serviceModeStatus.CURBSIDE.available && !serviceModeStatus.CURBSIDE.disabled;
  const openHours = driveThruOpen ? restaurant.driveThruHours : restaurant.diningRoomHours;
  const enableOrderingFlag = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const enableOrdering = isNil(enableOrderingFlag) || enableOrderingFlag;
  const areDiagnosticToolsEnabled = useFlag(LaunchDarklyFlag.ENABLE_INTERNAL_DIAGNOSTIC_TOOLS);
  const checkAvailability = useGetRestaurantAvailabilityFn();
  const timeFormatConfig = useConfigValue({
    key: 'timeFormat',
    defaultValue: TWELVE_HOUR_TIME_PARSE_FORMAT,
  });

  const closeHourTodayText = readableCloseHourToday(openHours, timeFormatConfig);

  const dateNow = getDateNowByTimezone({
    timeZone: restaurant.timezone || undefined,
  });

  // @ts-expect-error TS(2345) FIXME: Argument of type 'Maybe<IOperatingHours> | IStoreH... Remove this comment to see the full error message
  const isWithinOneHourOfCloseToday = checkIfWithinOneHourOfCloseToday(openHours, dateNow);
  const restaurantConnectivityStatus = useRestaurantPosConnectivityStatus({
    // @ts-expect-error TS(2322) FIXME: Type 'Maybe<string> | undefined' is not assignable... Remove this comment to see the full error message
    storeId: restaurant.number,
    skip: !shouldGetRemoteAvailabilityData,
  });

  // use the just-fetched restaurant if queried from backend
  const { isRestaurantPosOnline } = shouldGetRemoteAvailabilityData
    ? restaurantConnectivityStatus
    : {
        isRestaurantPosOnline: checkAvailability(restaurant as unknown as IRestaurant),
      };

  let distanceText;
  if (activeCoordinates && restaurant.latitude && restaurant.longitude) {
    const distance = milesBetweenCoordinates(activeCoordinates, {
      lat: restaurant.latitude,
      lng: restaurant.longitude,
    });
    distanceText = readableDistanceFromStore(distance, region, formatMessage);
  }

  const isMobileOrderingAvailable = useIsMobileOrderingAvailable(restaurant);

  const storeSEOSlug = getStoreSEOSlug(restaurant);

  const { selectRestaurant, checkingItemAvailability } = useSelectRestaurant({ restaurant });

  return {
    storeSEOSlug,
    distanceText,
    curbsideOpen,
    dineInOpen,
    takeoutOpen,
    diningRoomOpen,
    driveThruOpen,
    openHours,
    selectRestaurant,
    isMobileOrderingAvailable,
    isWithinOneHourOfCloseToday,
    closeHourTodayText,
    isRestaurantPosOnline: !!isRestaurantPosOnline,
    enableOrdering,
    areDiagnosticToolsEnabled,
    checkingItemAvailability,
  };
}
