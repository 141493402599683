import { Stack, View } from '@fhs-legacy/universal-components';

// ./row
export const RowContainer = Stack.withConfig({
  flexDir: { base: 'column', desktop: 'row' },
  width: 'full',
  borderBottomWidth: '1',
  borderBottomColor: 'whiteOpacity.30',
  py: '$10',
});

// ./column
export const ColumnContainer = Stack.withConfig({
  flexDir: 'column',
  flex: {
    base: 'none',
    desktop: 1,
  },
});

// ./vertical-link-list
export const VerticalListLinkContainer = Stack.withConfig({
  flexDir: 'column',
  paddingRight: '$10',
  marginTop: {
    base: '$8',
    desktop: '$0',
  },
});

// ./wordmark-and-social-icons
export const WordmarkSocialIconsContainer = Stack.withConfig({
  flexDir: {
    base: 'column',
    desktop: 'row',
  },
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '$24',
  marginBottom: '$6',
});

// ./links
export const Divisor = View.withConfig({
  marginX: '$4',
  borderLeftWidth: '1',
  borderLeftColor: 'whiteOpacity.30',
});
