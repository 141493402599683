import React from 'react';
import { useIntl } from 'react-intl';

import { Box, Center, Text } from '@fhs-legacy/universal-components';
import { CreateCaseState } from 'state/knowledge-force/types';
import { theme } from 'styles/configure-theme';

import { ICreateSupportCaseFeedbackProps } from './types';

export const CreateSupportCaseFeedback = ({
  createCaseState,
  createCaseErrorMessage: createCaseErrorMessageProp,
  useHighVolumeSupportMessage,
}: ICreateSupportCaseFeedbackProps) => {
  const { formatMessage } = useIntl();
  const createCaseErrorMessage =
    createCaseErrorMessageProp || formatMessage({ id: 'errorCreatingSupportCase' });

  const createCaseStateSuccessMessage = (
    <Box px="$6" pb="$2" _android={{ px: '$4' }}>
      <Box>{formatMessage({ id: 'successfullyCreatedSupportCase' })}</Box>
      <Box>
        {useHighVolumeSupportMessage
          ? formatMessage({ id: 'highVolumeMessage' })
          : formatMessage({ id: 'waitForReply' })}
      </Box>
    </Box>
  );

  const isCreateCaseStateSuccess = createCaseState === CreateCaseState.SUCCESS;
  const isCreateCaseStateError = createCaseState === CreateCaseState.ERROR;
  return (
    <Center mb="$6" testID="after-submission-message">
      {isCreateCaseStateError && (
        <Text ml="$4" mr="$4" variant="copyOne" color={theme.token('text-error')}>
          {createCaseErrorMessage}
        </Text>
      )}
      {isCreateCaseStateSuccess && createCaseStateSuccessMessage}
    </Center>
  );
};
