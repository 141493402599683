import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconAdd = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path fill="currentColor" d="M19 13.1h-6v6h-2v-6H5v-2h6v-6h2v6h6v2Z" />
    </Svg>
  );
};
