import React, { PropsWithChildren } from 'react';

import LoadingAnimation from 'components/loading-animation';
import { useLoyaltyContext } from 'state/loyalty';

const LoyaltyReady = ({ children }: PropsWithChildren) => {
  const { loyaltyUserReady } = useLoyaltyContext();

  return !loyaltyUserReady ? <LoadingAnimation height={'100%'} /> : children;
};

export default LoyaltyReady;
