import { useCallback, useRef } from 'react';

import useGoogleGeolocationLibrary from 'hooks/geolocation/use-google-geolocation-library';
import { IPlaceAddress, parsePlaceAddress } from 'utils/geolocation';

type GoogleGeocode = google.maps.Geocoder;
type GeocoderResult = google.maps.GeocoderResult;

export default function useSearchAddress() {
  const { libraryLoaded } = useGoogleGeolocationLibrary();
  const geocoder = useRef<GoogleGeocode>();

  const searchAddress = useCallback(
    (addressString: string): Promise<IPlaceAddress | undefined> => {
      return new Promise(resolve => {
        if (!libraryLoaded) {
          return resolve(undefined);
        }

        if (!geocoder.current) {
          geocoder.current = new window.google.maps.Geocoder();
        }

        geocoder.current.geocode({ address: addressString }, (results, status) => {
          if (status !== window.google.maps.GeocoderStatus.OK) {
            return resolve(undefined);
          }

          const firstResult: GeocoderResult | null = results?.length ? results[0] : null;
          if (!firstResult) {
            return resolve(undefined);
          }

          const { address_components } = firstResult;
          const address = parsePlaceAddress(address_components, {
            useISOCountry: true,
          });

          resolve(address);
        });
      });
    },
    [libraryLoaded]
  );

  return searchAddress;
}
