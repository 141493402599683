import { Stack } from 'expo-router';
import React from 'react';

import { tokens } from '@fhs/ui';

import { AppHeader } from '../../../components/app-header';

function header() {
  return <AppHeader />;
}
export default function AuthLayout() {
  return (
    <Stack
      initialRouteName={'(signin)'}
      screenOptions={{
        title: 'SignIn',
        header,
        contentStyle: { backgroundColor: tokens.colors.$white },
      }}
    >
      <Stack.Screen name="(signin)" />
      <Stack.Screen name="(signup)" />
      <Stack.Screen name="(continuewithemail)" />
      <Stack.Screen name="(verify)" />
    </Stack>
  );
}
