import { AttributeName, TraceName } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const startTrace = async (traceName: TraceName) => {
  // No web implementation
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const stopTrace = (traceName: TraceName) => {
  // No web implementation
};

export const setAttribute = async (
  traceName: TraceName, // eslint-disable-line @typescript-eslint/no-unused-vars
  attributeName: AttributeName, // eslint-disable-line @typescript-eslint/no-unused-vars
  attributeValue: string // eslint-disable-line @typescript-eslint/no-unused-vars
) => {
  // No web implementation
};

export const setAttributes = async (
  traceName: TraceName, // eslint-disable-line @typescript-eslint/no-unused-vars
  _attributes: { name: AttributeName; value: string }[]
) => {
  // No web implementation
};

export const getAttribute = async (
  traceName: TraceName, // eslint-disable-line @typescript-eslint/no-unused-vars
  attributeName: AttributeName // eslint-disable-line @typescript-eslint/no-unused-vars
) => {
  return null;
};
