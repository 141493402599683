import { Box, Header, Text } from '@fhs-legacy/universal-components';

export const ReceiptHeadInfoContainer = Box;

export const ReceiptInfoLabel = Text.withConfig({
  variant: 'copyTwo',
  marginRight: '$1',
});

export const ReceiptInfoDetail = Box.withConfig({
  flexShrink: 1,
});

export const ReceiptInfo = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

export const Title = Header.withConfig({
  variant: 'headerFour',
  margin: 0,
});

export const ReceiptDate = ReceiptInfoLabel;

export const ReceiptInfoDetailLabel = Text.withConfig({
  variant: 'copyTwo',
});
