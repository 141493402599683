import { Box, Header } from '@fhs-legacy/universal-components';

export const PointsEarnedContainer = Box.withConfig({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignContent: 'center',
});

export const PointsEarnedLabel = Header.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  margin: 0,
  variant: 'headerFour',
  color: '__legacyToken.text-default',
  lineHeight: 'xs',
});
