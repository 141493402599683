import { type ReactNode, useMemo, useRef } from 'react';
import { Platform, type View } from 'react-native';

import { CheckboxContext } from './context';
import type { CheckedState } from './types';

export type CheckboxProps = {
  children: ReactNode;
  onChange: (checked: CheckedState) => void;
  checked: CheckedState;
  customLabelComponent?: React.ReactNode;
  disabled?: boolean;
  label: string;
};

export function Checkbox({
  children,
  onChange,
  checked,
  disabled = false,
  label,
  customLabelComponent,
}: CheckboxProps) {
  const pressableRef = useRef<View | null>(null);
  const contextValue = useMemo(
    () => ({
      label,
      customLabelComponent,
      handlePress: () => {
        if (disabled) {
          return;
        }

        if (checked === 'mixed') {
          onChange(true);
        } else {
          onChange(!checked);
        }

        if (Platform.OS === 'web') {
          pressableRef.current?.focus?.();
        }
      },
      checked,
      disabled,
      pressableRef,
    }),
    [label, onChange, checked, disabled, pressableRef, customLabelComponent]
  );

  return <CheckboxContext.Provider value={contextValue}>{children}</CheckboxContext.Provider>;
}
