import { differenceInYears, isExists, isFuture, isValid } from 'date-fns';
import { Link } from 'expo-router';
import React, { ReactNode } from 'react';
import { IntlFormatters } from 'react-intl';
import { StyleSheet } from 'react-native';

import { Text } from '@fhs/ui';
import { SupportedRegions } from '@rbi-ctg/frontend';
import { formatDateObject } from 'utils/form';
import { validationConfig } from 'utils/form/user-profile-validation-config/validation';
import formatListOfWords, { JoinType } from 'utils/intl/formatListOfWords';

import { LONGEST_RECORDED_HUMAN_LIFE_IN_YEARS, MINIMUM_SIGNUP_AGE } from './constants';

const styles = StyleSheet.create({
  text: {
    lineHeight: 24,
    color: Styles.color.error,
  },
  link: {
    textDecorationLine: 'underline',
    lineHeight: 24,
    color: Styles.color.error,
  },
});

const getBelowMinimumMessage = (
  formatMessage: IntlFormatters['formatMessage'],
  _region: SupportedRegions
) => {
  return (
    <Text.Paragraph size="sm" style={styles.text}>
      {formatMessage(
        { id: 'dateOfBirthIsBelowMinimum' },
        {
          termsLink: (
            <Link href={formatMessage({ id: 'routes.termsOfService' })} style={styles.link}>
              {formatMessage({ id: 'termsOfService' })}
            </Link>
          ),
          privacyPolicyLink: (
            <Link href={formatMessage({ id: 'routes.privacyPolicy' })} style={styles.link}>
              {formatMessage({ id: 'privacyPolicy' })}
            </Link>
          ),
        }
      )}
    </Text.Paragraph>
  );
};

const checkDob = (
  dobDate: Date,
  dobRawInput: { month: string; day: string; year: string },
  formatMessage: IntlFormatters['formatMessage'],
  region: SupportedRegions
): ReactNode | null => {
  // JS Date object sucks...
  dobDate.setTime(dobDate.getTime() + dobDate.getTimezoneOffset() * 60000);

  if (
    !isValid(dobDate) ||
    !isExists(
      parseInt(dobRawInput.year, 10),
      parseInt(dobRawInput.month, 10) - 1,
      parseInt(dobRawInput.day, 10)
    )
  ) {
    return formatMessage({ id: 'invalidDateOfBirthProvided' });
  }
  if (isFuture(dobDate)) {
    return formatMessage({ id: 'futureDateOfBirthProvided' });
  }
  if (differenceInYears(Date.now(), dobDate) > LONGEST_RECORDED_HUMAN_LIFE_IN_YEARS) {
    return formatMessage({ id: 'invalidDateOfBirthProvided' });
  }
  if (differenceInYears(Date.now(), dobDate) < MINIMUM_SIGNUP_AGE) {
    return getBelowMinimumMessage(formatMessage, region);
  }

  return null;
};

export const getDobErrors = (
  dob = { month: '', day: '', year: '' },
  formatMessage: IntlFormatters['formatMessage'],
  region: SupportedRegions
): ReactNode | null => {
  const isRequired = (() => {
    const req = validationConfig.dob.required;

    if (req === false) {
      return false;
    }

    return req === true || req.locales.includes(region);
  })();

  if (isRequired && !Object.values(dob).every(Boolean)) {
    return formatMessage({ id: 'dateOfBirthIsRequired' });
  }

  // Empty DOB (valid)
  if (!dob.month && !dob.day && !dob.year) {
    return null; // dob is not required
  }

  // Partial DOB
  if (!dob.month || !dob.day || !dob.year) {
    return getInvalidDateOfBirthMessage(dob, formatMessage);
  }

  return checkDob(new Date(formatDateObject(dob)), dob, formatMessage, region);
};

export const getLegacyDobErrors = (
  dob: string | undefined,
  formatMessage: IntlFormatters['formatMessage'],
  region: SupportedRegions
): null | ReactNode => {
  // Empty DOB (valid)
  if (!dob) {
    return null; // dob is not required
  }

  const [month, day, year] = dob.split('/');

  return checkDob(new Date(dob), { year, month, day }, formatMessage, region);
};

function getInvalidDateOfBirthMessage(
  dob = { month: '', day: '', year: '' },
  formatMessage: IntlFormatters['formatMessage']
) {
  const missingFieldList: any[] = [];
  if (!dob.year) {
    missingFieldList.push(formatMessage({ id: 'year' }));
  }
  if (!dob.month) {
    missingFieldList.push(formatMessage({ id: 'month' }));
  }
  if (!dob.day) {
    missingFieldList.push(formatMessage({ id: 'day' }));
  }

  const missingFields = formatListOfWords({
    formatMessage,
    list: missingFieldList,
    joinType: JoinType.CONJUCTION,
  });
  return formatMessage(
    { id: 'invalidDateMissingFields' },
    {
      count: missingFieldList.length,
      missingFields,
    }
  );
}
