import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Animated } from 'react-native';

import { useStoreClosedActionSheet } from 'components/store-closed-action-sheet';
import { SwitchServiceModeDialog } from 'components/switch-service-mode-dialog';
import { StoreCardButtonOptions } from 'state/launchdarkly/variations';
import { useMainMenuContext } from 'state/main-menu';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { isNative } from 'utils/environment';
import { isCatering } from 'utils/service-mode';

import { useMessage } from './hooks/use-message';
import { useStoreActionOptions } from './hooks/use-store-action-options';
import { StoreActionsView } from './store-actions.view';
import { IActionOption, IStoreActionsProps } from './types';

/**
 * StoreCardActions container component. This component is intended to be used
 * on the store locator. Data orchestration should happen at this level while
 * keeping the view layer more presentational.
 */
const StoreActions: FC<React.PropsWithChildren<IStoreActionsProps>> = ({ restaurant }) => {
  const { serviceMode } = useServiceModeContext();

  const message = useMessage({ restaurant });

  const [showChangeToPickUpDialog, setShowChangeToPickUpDialog] = useState<boolean>(false);

  const { requestScheduleFutureOrderingConfirmation, storeClosedActionSheet } =
    useStoreClosedActionSheet({
      restaurant,
      isCatering: isCatering(serviceMode),
    });

  /**
   * CREATE STORE ACTION OPTIONS
   */
  const actionOptions: IActionOption[] = useStoreActionOptions({
    restaurant,
    setShowChangeToPickUpDialog,
    showChangeToPickUpDialog,
    validateRestaurantSelection: requestScheduleFutureOrderingConfirmation,
  });

  /**
   * FADE-IN ReactNative ANIMATION
   */
  const fadeAnim = useRef(new Animated.Value(0));
  useEffect(() => {
    Animated.timing(fadeAnim.current, {
      toValue: 1,
      duration: 200,
      useNativeDriver: isNative,
    }).start();
  }, []);

  const orderAction = actionOptions.find(item => item.id === StoreCardButtonOptions.ORDER);
  const { clearStoreMenuCache } = useMainMenuContext();
  const storeId = useStoreContext().store._id;
  const updatedActionOptions = useMemo(
    () =>
      actionOptions.map(option =>
        option.id === StoreCardButtonOptions.ORDER
          ? {
              ...option,
              onClick: () => {
                // Only clear the store menu cache when selecting a different store
                if (storeId !== restaurant._id) {
                  clearStoreMenuCache();
                }
                option.onClick?.();
              },
            }
          : option
      ),
    [actionOptions, storeId, restaurant._id, clearStoreMenuCache]
  );

  /**
   * RENDER VIEW
   */
  return (
    <>
      <Animated.View style={[{ opacity: fadeAnim.current }]}>
        <StoreActionsView options={updatedActionOptions} message={message} />
      </Animated.View>
      {storeClosedActionSheet}
      {showChangeToPickUpDialog && (
        <SwitchServiceModeDialog
          showDialog
          onConfirm={orderAction?.onClick}
          onDismiss={() => setShowChangeToPickUpDialog(false)}
        />
      )}
    </>
  );
};

export default StoreActions;
