import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import { useLocale } from 'state/intl';

export type StaticContentLanguageType = 'en' | 'fr';
type StaticContentLanguageContextType = {
  staticContentLanguage: StaticContentLanguageType;
  setStaticContentLanguage: Dispatch<SetStateAction<StaticContentLanguageType>>;
};

const staticContentLanguageContext = createContext<StaticContentLanguageContextType>({
  staticContentLanguage: 'en',
  setStaticContentLanguage: () => {},
});

export function StaticContentLanguageProvider({
  children,
  defaultLanguage,
}: {
  children: ReactNode;
  defaultLanguage?: StaticContentLanguageType;
}) {
  const appLanguage = useLocale().language;
  const [staticContentLanguage, setStaticContentLanguage] = useState<StaticContentLanguageType>(
    defaultLanguage ?? appLanguage
  );

  return (
    <staticContentLanguageContext.Provider
      value={useMemo(
        () => ({
          staticContentLanguage,
          setStaticContentLanguage,
        }),
        [staticContentLanguage]
      )}
    >
      {children}
    </staticContentLanguageContext.Provider>
  );
}

export const useStaticContentLanguage = () => useContext(staticContentLanguageContext);
