import React from 'react';

import SignUp from '../sign-up';

import { SignUpPanel } from './sign-up-home.styled';
import { AuthenticationTabProps } from './types';

const SignupTab = React.memo(({ initialEmail }: AuthenticationTabProps) => {
  return (
    <SignUpPanel>
      <SignUp skipRedirect={false} initialEmail={initialEmail} />
    </SignUpPanel>
  );
});

export default SignupTab;
