import { Button } from '@fhs-legacy/universal-components';

import theme from './theme';

export const CartItemButton = Button.withConfig({
  variant: theme.cartItemButtonVariant,
  padding: 3,
  size: 'md',
  _text: {
    color: theme.cartItemButtonColor,
  },
});

export default CartItemButton;
