import enCA from 'date-fns/locale/en-CA';
import enUS from 'date-fns/locale/en-US';
import frCA from 'date-fns/locale/fr-CA';

import { CURRENCY_CODE_FORMAT, LANGUAGES, LOCALES, MONEY_FORMAT, REGIONS } from 'state/intl/types';

export const LOCALE_DATA = {
  [LOCALES.en_us]: {
    code: LOCALES.en_us,
    region: REGIONS.US,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.en_us,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.en_us,
    dateFormat: enUS,
  },
  [LOCALES.en_ca]: {
    code: LOCALES.en_ca,
    region: REGIONS.CA,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.en_ca,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.en_ca,
    dateFormat: enCA,
  },
  [LOCALES.fr_ca]: {
    code: LOCALES.fr_ca,
    region: REGIONS.CA,
    language: LANGUAGES.fr,
    moneyFormat: MONEY_FORMAT.fr_ca,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.fr_ca,
    dateFormat: frCA,
  },
};
