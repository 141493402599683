import React, { FC } from 'react';

import { Box } from '@fhs-legacy/universal-components';
import { Product } from 'components/product';

import Container from './container';
import { ProductControl } from './product-list-item.styled';
import { IProductListItemProps } from './types';

/**
 * ProductListItem is a component used to display a product in a given list view.
 * It is a container to display a <Product /> component on the left,
 * AND an optional action on the right which could be either -
 * modifier style, favorite/add, carrot to view more.
 */
const ProductListItem: FC<React.PropsWithChildren<IProductListItemProps>> = ({
  controls,
  isClickable,
  onProductClick,
  productProps,
}) => {
  return (
    <Box>
      <Container isClickable={isClickable} onProductClick={onProductClick}>
        <Product {...productProps} />
        {controls && <ProductControl>{controls}</ProductControl>}
      </Container>
    </Box>
  );
};

export default ProductListItem;
