import * as React from 'react';
import { FC } from 'react';

import { BlankScreen } from 'components/blank-screen';
import { FeatureEmptyState } from 'components/features/components/empty-state';
import LoadingAnimation from 'components/loading-animation';
import { useIsDesktopBp } from 'hooks/breakpoints';

import { SpinnerContainer } from './loyalty-not-enrolled.styled';
import { useFeaturesLoyaltyNotEnrolled } from './use-features-loyalty-not-enrolled';

const LoyaltyNotEnrolled: FC<React.PropsWithChildren<unknown>> = () => {
  const { data, loading } = useFeaturesLoyaltyNotEnrolled();
  const isDesktop = useIsDesktopBp();

  if (loading) {
    return (
      <SpinnerContainer>
        <LoadingAnimation />
      </SpinnerContainer>
    );
  }
  if (!data) {
    return (
      <BlankScreen
        context={{
          reason: 'LoyaltyNotEnrolled no data after loading',
        }}
      />
    );
  }
  return <FeatureEmptyState content={data} showCTA={isDesktop} />;
};

export default LoyaltyNotEnrolled;
