import React from 'react';

import { Svg, SvgPath } from '@fhs-legacy/universal-components';

const IconSpill = () => (
  <Svg width="110" height="110" viewBox="0 0 110 110" fill="none">
    <SvgPath
      d="M52.7087 13.7505C76.756 13.7505 96.2503 33.2447 96.2503 57.2922C96.2503 81.3395 76.756 100.834 52.7087 100.834C28.6612 100.834 9.16699 81.3395 9.16699 57.2922C9.16699 33.2447 28.6612 13.7505 52.7087 13.7505ZM52.7087 18.3338C31.1925 18.3338 13.7503 35.7761 13.7503 57.2922C13.7503 78.8082 31.1926 96.2505 52.7087 96.2505C74.2247 96.2505 91.667 78.8082 91.667 57.2922C91.667 35.7761 74.2247 18.3338 52.7087 18.3338ZM50.417 77.9172V68.7505H55.0003V77.9172H50.417ZM50.417 59.5838V36.6672H55.0003V59.5838H50.417Z"
      fill="black"
    />
  </Svg>
);

export default IconSpill;
