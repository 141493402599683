import React from 'react';
import { Rect } from 'react-native-svg';

import { Svg, SvgPath } from '@fhs-legacy/universal-components';

const CourierImage = () => (
  <Svg width={54} height={54} fill="none">
    <Rect width={54} height={54} fill="#B1080F" rx={27} />
    <SvgPath
      fill="#fff"
      fillRule="evenodd"
      d="M35.75 20.75c0-1.375-1.125-2.5-2.5-2.5H29.5v2.5h3.75v3.313L28.9 29.5h-4.4v-6.25h-5c-2.762 0-5 2.238-5 5V32H17a3.745 3.745 0 0 0 3.75 3.75A3.745 3.745 0 0 0 24.5 32h5.6l5.65-7.063V20.75Zm-15 12.5c-.688 0-1.25-.563-1.25-1.25H22c0 .688-.563 1.25-1.25 1.25ZM24.5 19.5h-6.25V22h6.25v-2.5ZM32 32a3.745 3.745 0 0 1 3.75-3.75A3.745 3.745 0 0 1 39.5 32a3.745 3.745 0 0 1-3.75 3.75A3.745 3.745 0 0 1 32 32Zm2.5 0c0 .688.563 1.25 1.25 1.25.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25-.688 0-1.25.563-1.25 1.25Z"
      clipRule="evenodd"
    />
  </Svg>
);

export default CourierImage;
