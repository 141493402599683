import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { Box, addWithConfig } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';

export interface IItemActionsProps {
  showEdit?: boolean;
  onEdit: () => void;
  onRemove: () => void;
}

const Layout = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
});

const StyledActionButton = addWithConfig(ActionButton).withConfig({
  _text: {
    color: Styles.color.primary,
    fontSize: 'sm',
  },
  style: {
    marginRight: 16,
  },
});

const ItemActions: FC<React.PropsWithChildren<IItemActionsProps>> = ({
  showEdit = true,
  onEdit,
  onRemove,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Layout testID="item-actions">
      {showEdit && (
        <StyledActionButton
          aria-label={formatMessage({ id: 'edit' })}
          testID="edit-item-action"
          color="primary"
          variant={ActionButtonVariants.TEXT_ONLY}
          size={ActionButtonSizes.SMALL}
          onPress={onEdit}
        >
          {formatMessage({ id: 'edit' })}
        </StyledActionButton>
      )}
      <StyledActionButton
        aria-label={formatMessage({ id: 'remove' })}
        testID="remove-item-action"
        color="primary"
        variant={ActionButtonVariants.TEXT_ONLY}
        size={ActionButtonSizes.SMALL}
        onPress={onRemove}
      >
        {formatMessage({ id: 'remove' })}
      </StyledActionButton>
    </Layout>
  );
};

export default ItemActions;
