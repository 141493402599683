// This file is derived from @rbilabs/common
// We took only the pieces needed in our app.
// This is to reduce bundle size and transient dependencies that metro can't figure out that we don't need.

export { Geography } from './geography';

export enum ServiceMode {
  cateringDelivery = 'CATERING_DELIVERY',
  cateringPickup = 'CATERING_PICKUP',
  curbside = 'CURBSIDE',
  delivery = 'DELIVERY',
  driveThru = 'DRIVE_THRU',
  eatIn = 'EAT_IN',
  tableService = 'TABLE_SERVICE',
  takeout = 'TAKEOUT',
}

export enum PaymentProcessor {
  ADYEN = 'ADYEN',
  CHASE = 'CHASE',
  FIRSTDATA = 'FIRSTDATA',
  FIRSTPAY = 'FIRSTPAY',
  VRPAYMENT = 'VRPAYMENT',
  ORBITAL = 'ORBITAL',
  PAYMARK = 'PAYMARK',
  WORLDPAY = 'WORLDPAY',
  PAYCOMET = 'PAYCOMET',
}
