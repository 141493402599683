import useStaticIdentifier from 'state/loyalty/in-restaurant-redemption/hooks/use-static-identifier';
import { ServiceMode } from 'state/service-mode/types';

export const useIsServiceModeValid = (serviceMode: ServiceMode | null) => {
  const { isStaticIdentifierEnabled } = useStaticIdentifier();

  // We should bypass the validation if the flag is enabled
  if (isStaticIdentifierEnabled) {
    return true;
  }

  const isServiceModeValid = serviceMode !== ServiceMode.DELIVERY;

  return isServiceModeValid;
};
