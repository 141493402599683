import React from 'react';
import { useWindowDimensions } from 'react-native';
import { WebView as ReactWebView } from 'react-native-webview';

interface IWebViewProps {
  url: { locale: string };
}

export const WebView: React.FC<IWebViewProps> = ({ url }) => {
  const { height } = useWindowDimensions();

  return (
    <ReactWebView
      style={{ height }}
      source={{
        uri: url.locale,
      }}
      scalesPageToFit={true}
    />
  );
};
