import { add } from 'date-fns';
import * as React from 'react';
import { useIntl } from 'react-intl';
import type { GestureResponderEvent } from 'react-native';

import { Pressable, Text } from '@fhs-legacy/universal-components';
import { DIFFERENCE_TIME_UNITS, getDifferenceToNow } from 'utils/dateTime';

import { CodeText, NotReceived } from './otp.styled';

type DidNotReceiveCodeSendNewOneProps = {
  isValidatingOtp: boolean;
  isRequestingNewCode: boolean;
  sendNewOtp: (ev: GestureResponderEvent) => Promise<void>;
  lastRequestedCode: Date | null;
};

function useCountdownSeconds(countdownUntil: Date | null, checkIntervalMs = 100): number {
  const timeoutRef = React.useRef<null | ReturnType<typeof setTimeout>>(null);
  const [, triggerRender] = React.useReducer((count: number) => count + 1, 0);

  const differenceInSeconds = countdownUntil
    ? getDifferenceToNow(DIFFERENCE_TIME_UNITS.SECONDS, countdownUntil)
    : -1;

  React.useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    if (differenceInSeconds > 0) {
      // Will become available in the future
      timeoutRef.current = setTimeout(() => triggerRender(), checkIntervalMs);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  });

  return differenceInSeconds;
}

export function DidNotReceiveCodeSendNewOne({
  sendNewOtp,
  isRequestingNewCode,
  isValidatingOtp,
  lastRequestedCode,
}: DidNotReceiveCodeSendNewOneProps) {
  const secondsUntilCanRequestNewCode = useCountdownSeconds(
    React.useMemo(
      () => (lastRequestedCode ? add(lastRequestedCode, { minutes: 1 }) : null),
      [lastRequestedCode]
    )
  );

  const { formatMessage } = useIntl();
  return (
    <NotReceived>
      <Pressable
        disabled={isRequestingNewCode || isValidatingOtp || secondsUntilCanRequestNewCode > 0}
        onPress={sendNewOtp}
        testID="otp-send-new-form"
      >
        <CodeText>
          <Text>{formatMessage({ id: 'didNotReceiveCodeHeading' })} </Text>
          {isRequestingNewCode || isValidatingOtp ? (
            <Text>{formatMessage({ id: 'sending' })}</Text>
          ) : secondsUntilCanRequestNewCode > 0 ? (
            <Text>
              {formatMessage(
                { id: 'didNotReceiveCodeSendANewOne' },
                {
                  countdownSeconds: (
                    <Text fontWeight="bold">
                      {formatMessage(
                        { id: 'didNotReceiveCodeSendANewOneCountDownSeconds' },
                        { number: secondsUntilCanRequestNewCode }
                      )}
                    </Text>
                  ),
                }
              )}
            </Text>
          ) : (
            <Text textDecorationLine="underline">{formatMessage({ id: 'sendNewCode' })}</Text>
          )}
        </CodeText>
      </Pressable>
    </NotReceived>
  );
}
