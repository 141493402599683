import { useState } from 'react';
import { useMaskedInputProps } from 'react-native-mask-input';

import { FormControlTextInput } from '../form-control-text-input';
import { IconMobile } from '../icon';

import { loginPhoneNumberMask } from './masks';

import { MaskInputProps } from '.';

export function PhoneNumberMaskInput(props: MaskInputProps) {
  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState(props.value);
  const DEFAULT_PHONE = '(000) 000-0000';

  const { onChangeText, value: maskedValue } = useMaskedInputProps({
    value: maskedPhoneNumber,
    onChangeText: (masked, _unmasked) => {
      setMaskedPhoneNumber(masked);
      props.onChange(_unmasked);
    },
    mask: loginPhoneNumberMask,
  });

  return (
    <>
      <FormControlTextInput
        label={'Mobile Phone Number'}
        placeholder={DEFAULT_PHONE}
        readOnly={props.readOnly}
        onChangeText={onChangeText}
        value={maskedValue}
        keyboardType="number-pad"
        leftIcon={<IconMobile size={24} />}
      />
    </>
  );
}
