import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconMonitor = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M21 3.1H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v1c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-1h5c1.1 0 1.99-.9 1.99-2l.01-12c0-1.1-.9-2-2-2Zm-1 14H4c-.55 0-1-.45-1-1v-10c0-.55.45-1 1-1h16c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1Z"
      />
    </Svg>
  );
};
