import {
  Box,
  HStack,
  Header,
  Text as TextBase,
  VStack,
  makeUclComponent,
} from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import Link from 'components/link';
import Picture from 'components/picture';
import SanityBlockRenderer from 'components/sanity-block-renderer';
import { theme } from 'styles/configure-theme';

import { LoyaltyLoader } from '../loyalty-loader';

export const LoadingAnimation = LoyaltyLoader.withConfig({
  height: '50%',
  width: '50%',
});

export const DesktopBackButton = makeUclComponent(ActionButton).withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  position: 'absolute',
  left: '48px',
  top: '12px',
  paddingTop: '$2.5',
  paddingRight: '$5',
  paddingBottom: '$2.5',
  paddingLeft: '$5',
  borderStyle: 'solid',
  borderWidth: '2px',
  borderColor: theme.token('border-color-button-secondary'),
  color: theme.token('text-button-secondary'),
  fontSize: 'sm',
  lineHeight: '2xs',
});

export const MainImage = Picture.withConfig({
  height: 170,
  width: 300,
  alignSelf: 'center',
  objectFitContain: true,
});

const TextBlock = SanityBlockRenderer.withConfig({
  textProps: {
    fontSize: 'md',
    lineHeight: 'md',
  },
  linkProps: {
    fontSize: 'md',
    lineHeight: 'md',
    underline: true,
  },
});

export const Text = TextBlock.withConfig({
  marginTop: '$2',
  textProps: {
    fontSize: 14,
    lineHeight: 24,
  },
});

export const ParagraphTextBlock = Text.withConfig({
  textProps: {
    textAlign: 'center',
  },
});

export const ParagraphText = TextBase.withConfig({
  marginTop: '$2',
  fontSize: 14,
  lineHeight: 24,
});

export const InstructionDateText = TextBlock;

export const Title = Header.withConfig({
  variant: 'headerTwo',
  fontSize: '2xl',
  lineHeight: 'lg',
  margin: 0,
  textAlign: 'center',
});

export const Button = makeUclComponent(ActionButton).withConfig({
  paddingBottom: '$2.5',
  paddingX: '$12',
  marginX: '$5',
});

export const SubmitButton = makeUclComponent(ActionButton).withConfig({
  marginTop: '$2',
  marginBottom: '$8',
});

export const StyledLink = makeUclComponent(Link).withConfig({
  alignSelf: 'center',
  color: Styles.color.red,
  isUnderlined: true,
  isInline: true,
});

export const TransactionInputFormTitle = TextBase.withConfig({
  variant: 'copyTwo',
  fontWeight: 'bold',
  marginY: '$2',
});

export const TransactionInputFormContainer = HStack.withConfig({
  justifyContent: 'space-between',
  width: 'full',
});

export const TransactionDateInputFormContainer = VStack.withConfig({
  marginTop: '$6',
  marginBottom: '$10',
  space: '$2',
});

export const EarnedPointsWrapper = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '$2',
  marginBottom: '$6',
});

export const EarnedPointsText = TextBase.withConfig({
  fontFamily: 'heading',
  fontSize: 'lg',
});

export const StyledLoyaltyPointsIcon = LoyaltyPointsIcon.withConfig({
  width: '$8',
  height: '$8',
  marginTop: 0,
  marginRight: '$1.5',
  marginBottom: 0,
  marginLeft: '$2',
});

export const Container = VStack.withConfig({
  width: 'full',
  height: 'full',
  justifyContent: 'center',
  space: '$4',
  maxWidth: '496px',
  paddingX: '$3.5',
  marginX: 'auto',
});
