import { WatchQueryFetchPolicy } from '@apollo/client';

import { IHistoricalOrder } from '@rbi-ctg/menu';
import {
  IGetUserOrdersQueryVariables,
  RbiOrderStatus,
  useGetUserOrdersQuery,
} from 'generated/rbi-graphql';
import { useAuthContext } from 'state/auth';

export const UserOrdersDefaultLimit = 5;

export const UserOrdersDefaultVariables = {
  orderStatuses: [
    RbiOrderStatus.INSERT_SUCCESSFUL,
    RbiOrderStatus.UPDATE_SUCCESSFUL,
    RbiOrderStatus.CATERING_PLACED,
    RbiOrderStatus.CATERING_SUCCESSFUL,
    RbiOrderStatus.REFUND_SUCCESSFUL,
    RbiOrderStatus.REFUND_ERROR,
    RbiOrderStatus.REFUND_REQUESTED,
  ],
  shouldFilterByRegion: true,
};

export const useUserOrders = (
  variables: IGetUserOrdersQueryVariables,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
  skip?: boolean
) => {
  const { isAuthenticated } = useAuthContext();
  const { data, error, loading, refetch, fetchMore } = useGetUserOrdersQuery({
    variables: {
      ...UserOrdersDefaultVariables,
      ...variables,
    },
    context: { shouldNotBatch: true },
    skip: !isAuthenticated || skip,
    fetchPolicy,
  });

  const resumeAfter = data?.userOrders.resumeAfter;
  const count = data?.userOrders.count || 0;
  const orders = (data?.userOrders.orders || []) as unknown as IHistoricalOrder[];

  return { error, loading, orders, count, resumeAfter, refetch, fetchMore };
};
