import { StyleSheet } from 'react-native';

import { tokens } from '../../tokens';
import { Text, TextProps } from '../text/text';

import { useFormFieldContext } from './form-field';

export type FormFieldLabelProps = TextProps;

export function FormFieldLabel(props: FormFieldLabelProps) {
  const formFieldContext = useFormFieldContext();
  return (
    <Text weight="semibold" {...props} style={[styles.label, props.style]}>
      {formFieldContext.labelText}
    </Text>
  );
}

const styles = StyleSheet.create({
  label: {
    color: tokens.colors.$houseDark,
    fontSize: 16,
    lineHeight: 20,
  },
});
