import { RadioCircleIndicator } from './radio-circle-indicator';
import { RadioPressable, type RadioPressableProps } from './radio-pressable';

export type RadioControlProps = RadioPressableProps;

export const RadioControl = (props: RadioControlProps) => {
  return (
    <RadioPressable
      borderRadius={100}
      borderWidth={0}
      {...props}
      style={props.style}
      hoveredStyle={props.hoveredStyle}
      disabledStyle={props.disabledStyle}
    >
      <RadioCircleIndicator />
    </RadioPressable>
  );
};
