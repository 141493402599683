import { IFeatureMenuQuery, useFeatureMenuQuery } from 'generated/sanity-graphql';
import { useAdaptableMenuCardsQuery } from 'hooks/use-adaptable-menu-cards-query';
import { useFeaturesContext } from 'state/features';
import { useServiceModeContext } from 'state/service-mode';
import { isCatering } from 'utils/service-mode';

interface IUseFeatureMenuValue {
  menuId: string;
  featureMenu: IFeatureMenuQuery['FeatureMenu'];
  featureMenuLoading: boolean;
}

export const useFeatureMenu = (): IUseFeatureMenuValue => {
  const { featureMenuId = '', featureIdsLoading } = useFeaturesContext();
  const { serviceMode } = useServiceModeContext();

  const { data, loading } = useFeatureMenuQuery({
    variables: { featureMenuId },
    skip: !featureMenuId,
  });
  // Querying to cache data when feature menu loads
  // This query will skip if feature is not enable in Amplitude Experiments to avoid
  // querying data
  useAdaptableMenuCardsQuery(featureMenuId);

  const featureMenu = data?.FeatureMenu ?? null;
  const { cateringMenu, defaultMenu } = featureMenu || {};
  const featureMenuLoading = featureIdsLoading || loading;

  const menuId: string = (isCatering(serviceMode) ? cateringMenu : defaultMenu)?._id || '';

  return {
    menuId,
    featureMenu,
    featureMenuLoading,
  };
};
