import { IStore } from '@rbi-ctg/store';
import { ServiceMode } from 'state/service-mode';

import {
  ICartEntry,
  ICreateCartEntryParams,
  IUseInRestaurantCart,
} from './hooks/use-in-restaurant-redemption-cart';
import { IUserShortCode } from './hooks/use-short-code';

export { ShortCodeState } from './hooks/use-short-code/types';
export type {
  ICartEntry,
  ICart,
  ICartEntryDetailsLegacyOffer,
  ICartEntryDetailsOffer,
  ICartEntryDetailsReward,
  ICartEntryDetails,
} from './hooks/use-in-restaurant-redemption-cart/types';
export { ICartEntryType } from './hooks/use-in-restaurant-redemption-cart/types';

export interface InRestaurantRedemptionFlagsState {
  inRestaurantLoyaltyEnabledAtRestaurantLoading: boolean;
  inRestaurantLoyaltyEnabledAtRestaurant: boolean;
}

export interface IInRestaurantRedemptionContext
  extends Pick<IUserShortCode, 'shortCode' | 'shortCodeState' | 'shortCodeLoading'> {
  inRestaurantLoyaltyEnabledAtRestaurant: boolean;
  inRestaurantLoyaltyEnabledAtRestaurantLoading: boolean;
  inRestaurantRedemptionEnabled: boolean;
  generateShortCode: () => void;
  resetInRestaurantRedemption: (shouldResetAvailableRewardsMap?: boolean) => void;
  addInRestaurantRedemptionEntry: (item: ICreateCartEntryParams) => void;
  inRestaurantRedemptionCart: IUseInRestaurantCart['inRestaurantCart'];
  isInRestaurantRedemptionCartEmpty: boolean;
  removeInRestaurantRedemptionEntry: (cartEntry: ICartEntry) => void;
  updateInRestaurantRedemptionEntryQuantity(cartEntry: ICartEntry, quantity: number): void;
  existEntryTypeInRestaurantRedemptionCart: IUseInRestaurantCart['existEntryTypeInRestaurantCart'];
  clearInRestaurantRedemptionAllRewards: IUseInRestaurantCart['clearInRestaurantCartAllRewards'];
  isInRestaurantRedemptionEnabledAtStore: (
    store: IStore,
    serviceMode?: ServiceMode | null
  ) => Promise<boolean>;
  inRestaurantRedemptionLastModificationDate: Date | undefined;
  enableOfferRedemption: boolean;
  enableRewardRedemption: boolean;
  staticIdentifier: string | null;
  isStaticIdentifierEnabled: boolean;
  scanSucceeded: boolean;
  setScanSuccess: (success: boolean) => void;
  shouldForceRestaurantSelection: boolean;
  canRedeemLoyaltyIncentives: boolean;
}

export interface IInRestaurantFlagVariations {
  enableOfferRedemption: boolean;
  enableRewardRedemption: boolean;
}

export type IsServiceModeValid = (params: { serviceMode: ServiceMode | null }) => boolean;

export interface IsRedemptionOnCurrentDevice {
  isMobile: boolean;
}
