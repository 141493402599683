import React, { useRef } from 'react';
import { TextInput as RNTextInput } from 'react-native';

import { DayAndTimeSelector } from 'components/day-and-time-selector';
import Details from 'pages/cart/details';
import { useCartContext } from 'state/cart';
import { ServiceMode } from 'state/service-mode/types';

export const CartDetailsWithDayAndTimeSelector = () => {
  const phoneNumberInputRef = useRef<RNTextInput>(null);

  const { serverOrder, serviceMode, setAreDeliveryDetailsValid, deliveryDetailsErrors } =
    useCartContext();

  const shouldShowDayAndTimeSelector = serviceMode !== ServiceMode.DELIVERY;

  return (
    <>
      <Details
        serverOrder={serverOrder}
        serviceMode={serviceMode}
        setAreDeliveryDetailsValid={setAreDeliveryDetailsValid}
        phoneNumberInputRef={phoneNumberInputRef}
        deliveryDetailsErrors={deliveryDetailsErrors}
      />
      {shouldShowDayAndTimeSelector && <DayAndTimeSelector />}
    </>
  );
};
