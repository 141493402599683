import { router } from 'expo-router';
import { useCallback } from 'react';

import { IBackendCartEntries, IHistoricalOrder, ServiceMode } from '@rbi-ctg/menu';
import useFoodware from 'hooks/use-foodware';
import { usePathname } from 'hooks/use-pathname';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { OrderStatus, useOrderContext } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';
import { isCatering } from 'utils/service-mode';

export interface IFavoriteOrder {
  cart: {
    cartEntries: IBackendCartEntries[];
    /* reorderCartEntries are needed to handleReorder */
    reorderCartEntries: IBackendCartEntries[];
    serviceMode: ServiceMode | null;
    cartVersion: number;
  };
  status?: OrderStatus;
}

export const useReorder = (order?: IHistoricalOrder | IFavoriteOrder | null) => {
  const {
    reorder: {
      handleReorder,
      pendingReorder,
      setPendingReorder,
      reordering,
      setShouldHandleReorder,
    },
  } = useOrderContext();
  const { isStoreAvailable, isSwitchingStore } = useStoreContext();
  const { serviceMode: selectedServiceMode, setServiceMode } = useServiceModeContext();

  const pathname = usePathname();

  const enableReorder = useFlag(LaunchDarklyFlag.ENABLE_REORDER);
  const { isFoodware } = useFoodware();

  const handleReorderClick = useCallback(
    async ({ skipStoreLocatorRedirect = false, switchingStore = false } = {}) => {
      if (!order) {
        return;
      }

      const filteredReorderCartEntries = order.cart.reorderCartEntries?.filter(
        item => !(item.isDonation || isFoodware(item))
      );

      const filteredOrder = {
        ...order,
        cart: {
          ...order.cart,
          reorderCartEntries: filteredReorderCartEntries,
        },
      };

      if (isStoreAvailable && !switchingStore) {
        handleReorder(filteredOrder);
      } else {
        setPendingReorder(filteredOrder);
        setShouldHandleReorder(true);

        if (skipStoreLocatorRedirect) {
          const orderServiceMode = order?.cart.serviceMode;

          // We need to have a service mode selected before trying to reorder an order
          if (orderServiceMode && !selectedServiceMode) {
            setServiceMode(orderServiceMode);
          }

          return;
        }

        const route = isCatering(filteredOrder.cart.serviceMode)
          ? routes.serviceModeCatering
          : routes.storeLocator;

        router.navigate({ pathname: route, params: { back: pathname } });
      }
    },
    [
      setServiceMode,
      selectedServiceMode,
      order,
      isStoreAvailable,
      isFoodware,
      handleReorder,
      setPendingReorder,
      pathname,
      setShouldHandleReorder,
    ]
  );

  const serviceMode = order?.cart.serviceMode ?? null;
  const isCateringWithWrongStatus =
    isCatering(serviceMode) && order?.status !== OrderStatus.CATERING_SUCCESSFUL;

  const disabled =
    !enableReorder || isCateringWithWrongStatus || isCatering(serviceMode) || reordering;

  return {
    handleReorderClick,
    disabled,
    reordering: Boolean(pendingReorder || reordering || isSwitchingStore),
  };
};
