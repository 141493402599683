import { isValid, parse, parseISO } from 'date-fns';
import dlv from 'dlv';
import { camelCase, isNil, isNumber } from 'lodash';
import { IntlShape } from 'react-intl';
import uuid from 'uuid';

import { ISanityBlockContentChild, ISanityLocaleBlockContent } from '@rbi-ctg/menu';

import {
  BlockContentItem,
  BlockContentText,
  FormattersMap,
  InterpolatePlaceholdersArgs,
  InterpolationsMap,
  PlaceholderFormatter,
  isBlockContentReference,
  isBlockContentText,
  isSanityLocaleBlockContent,
} from './types';

/**
 * Transform the LocaleSmartBlockContent blocks with placeholders to formatted texts.
 */
export const interpolatePlaceholders = ({
  blocks,
  interpolationSourceObject,
  placeholderFieldsMap,
  formattersMap,
}: InterpolatePlaceholdersArgs) => {
  const interpolations: InterpolationsMap = {};
  blocks.forEach(block => {
    if (!isBlockContentReference(block)) {
      return;
    }

    const placeholderField = placeholderFieldsMap[block._ref];
    if (!placeholderField) {
      throw new Error(`Missing placeholder field with ID: ${block._ref}`);
    }
    const { path, formatter } = placeholderField;
    const formatterMethods = formattersMap[formatter ?? PlaceholderFormatter.TO_STRING];
    if (!formatterMethods) {
      throw new Error(`Unsupported formatter type: ${formatter}`);
    }

    const interpolationValue = dlv(interpolationSourceObject, path);
    if (isNil(interpolationValue)) {
      throw new Error(
        `Missing property ${path} in object ${JSON.stringify(interpolationSourceObject)}`
      );
    }

    const { isValidType, formatMethod } = formatterMethods;
    if (!isValidType(interpolationValue)) {
      throw new Error(`Wrong variable type on path ${path}.
          Expected: ${formatter}
          Value: ${interpolationValue}
          ValueType: ${typeof interpolationValue}`);
    }

    interpolations[block._ref] = formatMethod(interpolationValue);
  });
  return interpolations;
};

export const buildInlineTextBlock = (text: string): ISanityBlockContentChild => ({
  _key: uuid(),
  _type: 'span',
  marks: [],
  text,
});

export const buildBlockContentText = (children: ISanityBlockContentChild[]): BlockContentText => ({
  _key: uuid(),
  _type: 'block',
  markDefs: [],
  style: 'normal',
  children,
});

export const isBlockContentNewLine = (block: BlockContentItem) =>
  isBlockContentText(block) && block.children?.length === 1 && block.children[0].text === '';

const appendTextsToLastBlock = (
  blocks: BlockContentText[],
  inlineTextsToAppend: ISanityBlockContentChild[]
) => {
  const lastBlock = blocks[blocks.length - 1];
  if (lastBlock && !isBlockContentNewLine(lastBlock)) {
    lastBlock.children = [...lastBlock.children, ...inlineTextsToAppend];
  } else {
    blocks.push(buildBlockContentText(inlineTextsToAppend));
  }
  return blocks;
};

/**
 * Append block contents texts inline unless it finds a newLine character.
 * If block is a placeholder it replaces it with the formatted interpolation.
 */
export const composeBlockContents = ({
  blocks,
  interpolationsMap,
}: {
  blocks: BlockContentItem[];
  interpolationsMap: InterpolationsMap;
}) =>
  blocks.reduce((acc, block) => {
    // All `newLine` characters creates a new block
    if (isBlockContentNewLine(block)) {
      return acc.concat(block as BlockContentText);
    }

    if (isBlockContentText(block)) {
      return appendTextsToLastBlock(acc, block.children);
    }

    const interpolationValue = interpolationsMap[block._ref];
    if (!interpolationValue) {
      return acc;
    }

    if (isSanityLocaleBlockContent(interpolationValue)) {
      return acc.concat(interpolationValue.localeRaw);
    }

    return appendTextsToLastBlock(acc, [buildInlineTextBlock(interpolationValue)]);
  }, [] as BlockContentText[]);

export const buildFormattersMap = (
  formatters: IntlShape,
  formatCurrencyForLocale: (amount: number) => string
): FormattersMap => ({
  [PlaceholderFormatter.CURRENCY]: {
    isValidType: (value: any): value is number => isNumber(value),
    formatMethod: (value: number): string => formatCurrencyForLocale(value),
  },
  [PlaceholderFormatter.LOCALIZED]: {
    isValidType: (value: any): value is string | string[] => {
      const values = Array.isArray(value) ? value : [value];
      return Boolean(values.length) && values.every(val => typeof val === 'string');
    },
    formatMethod: (keys: string | string[]) => {
      const localizedKeys: string[] = Array.isArray(keys) ? keys : [keys];
      return localizedKeys.map(key => formatters.formatMessage({ id: camelCase(key) })).join(', ');
    },
  },
  [PlaceholderFormatter.DATE]: {
    isValidType: (value: any): value is string | number | Date => {
      const dateToCheck = typeof value === 'string' ? parseISO(value) : value;
      return isValid(dateToCheck);
    },
    formatMethod: (value: any) => formatters.formatDate(value),
  },
  [PlaceholderFormatter.TIME]: {
    isValidType: (value: any): value is string => {
      const parsedTime = parse(value, 'HH:mm', new Date());
      return isValid(parsedTime);
    },
    formatMethod: (time: string) => formatters.formatTime(parse(time, 'HH:mm', new Date())),
  },
  [PlaceholderFormatter.TO_STRING]: {
    isValidType: () => true,
    formatMethod: (value: any) => value?.toString() ?? '',
  },
  [PlaceholderFormatter.SANITY_BLOCK_CONTENT]: {
    isValidType: (value: any) => isSanityLocaleBlockContent(value),
    formatMethod: (value: ISanityLocaleBlockContent) => value,
  },
});
