import { useCallback, useMemo } from 'react';

import { ICombo, IModifierSelection } from '@rbi-ctg/menu';
import { useRedeemReward } from 'components/cart-item/redeem-reward/use-redeem-reward';
import {
  transformUserSelectionsComboToComboSlotSelections,
  transformUserSelectionsModifierToModifierSelection,
} from 'components/product-detail/utils';
import { selectors, useAppSelector } from 'state/global-state';
import { useGetAvailableRewards } from 'state/loyalty/hooks/use-get-available-rewards';
import { useMenuContext } from 'state/menu';
import { useMenuUIContext } from 'state/menu-ui';
import { useOffersContext } from 'state/offers';
import { useOrderContext } from 'state/order';
import { useProductWizardContext } from 'state/product-wizard';
import useProductWizardFlow from 'state/product-wizard/hooks/use-product-wizard-flow';
import { useUIContext } from 'state/ui';
import {
  getCartEntryBenefit,
  getIndividualItemPrice,
  getItemLoyaltyRewardDiscount,
} from 'utils/cart/helper';
import { IWithVendorConfig, getOfferVendorConfigForPricing } from 'utils/vendor-config';

interface ProductPriceConditions {
  shouldIncludeModifications: boolean;
  shouldIncludeQuantity: boolean;
}

export const useProductPrice = (conditions?: ProductPriceConditions) => {
  const { getAvailableRewardFromCartEntry } = useGetAvailableRewards();
  const { isOffer, pricingFunction } = useMenuContext();
  const { activeCartEntries } = useMenuUIContext();
  const { selectedOffer: selectedLegacyOffer } = useOffersContext();
  const { cartIdEditing } = useOrderContext();
  const { productQuantity, selectedProduct, userSelections } = useProductWizardContext();
  const { formatCurrencyForLocale } = useUIContext();

  const { incentiveNotInMenu } = useRedeemReward();
  const { rewardBenefitId } = useProductWizardFlow();

  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const selectedLoyaltyOffer = useAppSelector(selectors.loyalty.selectSelectedOffer);

  const selectedOffer = selectedLoyaltyOffer || selectedLegacyOffer;

  const { shouldIncludeModifications = false, shouldIncludeQuantity = false } = conditions || {};

  const totalPrice = useCallback(
    (modifiersInjected?: IModifierSelection[]) => {
      const quantity = shouldIncludeQuantity ? productQuantity : 1;
      const baseTotal = pricingFunction(selectedProduct, quantity);

      let total = baseTotal;
      if (isOffer) {
        const selectedProductVendorConfigs = selectedProduct.vendorConfigs;
        const offerVendorConfigs = getOfferVendorConfigForPricing(selectedOffer);
        const selectedProductPrice = selectedProductVendorConfigs
          ? pricingFunction(
              { vendorConfigs: selectedProductVendorConfigs } as IWithVendorConfig,
              quantity,
              modifiersInjected
            )
          : 0;
        const selectedOfferPrice = offerVendorConfigs
          ? pricingFunction(
              { vendorConfigs: offerVendorConfigs } as IWithVendorConfig,
              quantity,
              modifiersInjected
            )
          : 0;
        total = selectedProductPrice || selectedOfferPrice || selectedOffer?.offerPrice || 0;
      }

      if (shouldIncludeModifications) {
        const comboSlotSelections = transformUserSelectionsComboToComboSlotSelections(
          userSelections.comboSlot,
          selectedProduct as ICombo
        );
        const modifierSelections =
          transformUserSelectionsModifierToModifierSelection(userSelections);
        const extraTotal =
          pricingFunction(selectedProduct, quantity, modifierSelections, comboSlotSelections) -
          baseTotal;

        total += extraTotal;
      }
      return total;
    },
    [
      isOffer,
      pricingFunction,
      productQuantity,
      selectedOffer,
      selectedProduct,
      shouldIncludeModifications,
      shouldIncludeQuantity,
      userSelections,
    ]
  );

  const discountPrice = useCallback(() => {
    // TODO: Verify if we need to use activeCartEntries here rather than just the menu item
    const cartEntry = getCartEntryBenefit(
      activeCartEntries,
      getAvailableRewardFromCartEntry,
      rewardBenefitId
    );

    if (!cartEntry) {
      return 0;
    }

    let discount = 0;
    // from loyalty redemption
    if (rewardBenefitId) {
      discount = incentiveNotInMenu ? cartEntry.price ?? 0 : getIndividualItemPrice({ cartEntry });
    } else if (cartIdEditing) {
      discount = getItemLoyaltyRewardDiscount({ cartEntry, appliedLoyaltyRewards });
    }

    return discount;
  }, [
    activeCartEntries,
    appliedLoyaltyRewards,
    cartIdEditing,
    getAvailableRewardFromCartEntry,
    incentiveNotInMenu,
    rewardBenefitId,
  ]);

  const formattedProductPrice = useMemo(() => {
    const totalWithDiscount = incentiveNotInMenu ? 0 : Math.max(totalPrice() - discountPrice(), 0);
    return formatCurrencyForLocale(totalWithDiscount);
  }, [discountPrice, formatCurrencyForLocale, incentiveNotInMenu, totalPrice]);

  return {
    totalPrice,
    formattedProductPrice,
  };
};
