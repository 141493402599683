import React, { useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import useDialogModal, { UseDialogHook } from 'hooks/use-dialog-modal';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { useOrderContext } from 'state/order';

interface IDialogCallback {
  onConfirm?: Function;
  onCancel?: Function;
}

interface IUseSwitchCartMethodDialog<T extends object, P = {}> {
  SwitchCartMethodDialog: UseDialogHook<T, P>[0];
  openSwitchCartMethod: (callbacks?: IDialogCallback) => void;
  shouldShowSwitchToStoreDialog: boolean;
  shouldShowSwitchToMobileDialog: boolean;
}

const useSwitchCartMethodDialog = <T extends object, P = {}>(): IUseSwitchCartMethodDialog<
  T,
  P
> => {
  const {
    resetInRestaurantRedemption,
    inRestaurantRedemptionCart,
    inRestaurantRedemptionEnabled,
    enableOfferRedemption,
    enableRewardRedemption,
  } = useInRestaurantRedemptionContext();
  const { emptyCart, numCartPreviewEntries } = useOrderContext();
  const isCartEmpty = numCartPreviewEntries === 0;

  const isInRestaurantRedemptionEmpty = !inRestaurantRedemptionCart?.length;

  const dialogCallbackRef = useRef<
    { onConfirm?: Function; onCancel?: Function } | null | undefined
  >(null);

  // on confirm the switch clear the non empty cart
  const onConfirmSwitchCartMethodDialog = useCallback(() => {
    if (!isInRestaurantRedemptionEmpty) {
      resetInRestaurantRedemption(false);
    } else if (!isCartEmpty) {
      emptyCart();
    }

    if (typeof dialogCallbackRef.current?.onConfirm === 'function') {
      dialogCallbackRef.current.onConfirm();
    }

    dialogCallbackRef.current = null;
  }, [emptyCart, isCartEmpty, isInRestaurantRedemptionEmpty, resetInRestaurantRedemption]);

  // on confirm the switch clear the non empty cart
  const onCancelSwitchCartMethodDialog = useCallback(() => {
    if (typeof dialogCallbackRef.current?.onCancel === 'function') {
      dialogCallbackRef.current.onCancel();
    }

    dialogCallbackRef.current = null;
  }, []);

  // in restaurant redemption and mobile order carts can not be with items at the same time
  const [DialogComponent, openDialog] = useDialogModal<T, P>({
    onConfirm: onConfirmSwitchCartMethodDialog,
    onCancel: onCancelSwitchCartMethodDialog,
    showCancel: true,
    allowDismiss: false,
    modalAppearanceEventMessage: 'Switching redemption method',
  });

  // should allow switching store if there is item in cart and brand enables offer or reward redemption
  const shouldShowSwitchToStoreDialog = Boolean(
    inRestaurantRedemptionEnabled &&
      !isCartEmpty &&
      (enableOfferRedemption || enableRewardRedemption)
  );
  const shouldShowSwitchToMobileDialog = Boolean(
    inRestaurantRedemptionEnabled && !isInRestaurantRedemptionEmpty
  );

  const SwitchCartMethodDialog: UseDialogHook<T, P>[0] = useCallback(
    function SwitchCartMethodDialog(props: any) {
      const { formatMessage } = useIntl();

      return (
        <DialogComponent
          {...props}
          heading={formatMessage({
            id: isInRestaurantRedemptionEmpty
              ? 'changeRedemptionToInStore'
              : 'changeRedemptionToPickUp',
          })}
          body={formatMessage({
            id: isInRestaurantRedemptionEmpty
              ? 'changeRedemptionToInStoreDescription'
              : 'changeRedemptionToPickUpDescription',
          })}
          confirmLabel={formatMessage({
            id: isInRestaurantRedemptionEmpty
              ? 'changeRedemptionToInStoreConfirmLabel'
              : 'changeRedemptionToPickUpConfirmLabel',
          })}
          cancelLabel={formatMessage({ id: 'noThankYou' })}
        />
      );
    },
    [isInRestaurantRedemptionEmpty, DialogComponent]
  );

  const openSwitchCartMethod = useCallback(
    (callbacks?: IDialogCallback) => {
      dialogCallbackRef.current = callbacks;
      openDialog();
    },
    [openDialog]
  );

  return useMemo(
    () => ({
      SwitchCartMethodDialog,
      openSwitchCartMethod,
      shouldShowSwitchToStoreDialog,
      shouldShowSwitchToMobileDialog,
    }),
    [
      SwitchCartMethodDialog,
      openSwitchCartMethod,
      shouldShowSwitchToStoreDialog,
      shouldShowSwitchToMobileDialog,
    ]
  );
};

export default useSwitchCartMethodDialog;
