import { Link, useLocalSearchParams } from 'expo-router';
import Head from 'expo-router/head';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Box, InlineAlert } from '@fhs-legacy/universal-components';
// import useUserInSameRegion from 'hooks/use-user-in-same-region';
import { useLoyaltyIncentivesAvailability } from 'pages/loyalty/loyalty-incentives-components/use-loyalty-incentives-availability';
import { useCartContext } from 'state/cart';
import { selectors, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { IncentiveEvaluationErrorCodes } from 'state/loyalty/hooks/types';
import { useOffersContext } from 'state/offers';
import useRewardDiscount from 'state/order/hooks/use-reward-discount';
import { IPaymentMethod } from 'state/payment/types';
import { useStoreContext } from 'state/store';
import { isCatering as isCateringOrder } from 'utils/service-mode';

import CartPageContainer from './cart-page-container';
import Content from './content';
import { useCartHasUnavailableOffers } from './hooks/use-cart-has-unavailable-offers';
import Payment from './payment/payment-new';
import { CustomScrollViewProps } from './payment/types';
import { MobileCartUpsellLocation } from './types';
import { Upsell } from './upsell';
import YourCart from './your-cart';
import { CartErrorMessages } from './your-cart/cart-error-messages';

interface ICartContent {
  checkoutPaymentMethod?: IPaymentMethod | null;
  hasAddOns?: boolean;
  cartFullPageScreenRef?: CustomScrollViewProps;
}

const CartContent = ({ hasAddOns = false, checkoutPaymentMethod }: ICartContent) => {
  const { reorderHasOffers } = useLocalSearchParams<{ reorderHasOffers?: string }>();
  const shouldShowOffersWarning = reorderHasOffers && reorderHasOffers === 'true';

  const {
    cartEntries,
    serverOrder,
    orderStatus,
    shouldSkipPriceOrder,
    calculateCartTotalWithDiscount,
  } = useCartContext();

  const upsellEnabled = useFlag(LaunchDarklyFlag.ENABLE_CHECKOUT_UPSELL_ITEMS_2);
  const mobileCartUpsellLocation = useFlag(LaunchDarklyFlag.MOBILE_CART_UPSELL_LOCATION);
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);

  const orderServiceMode = serverOrder?.cart?.serviceMode;
  const isCatering = orderServiceMode && isCateringOrder(orderServiceMode);
  // const { isInSameRegion } = useUserInSameRegion();
  const offersCtx = useOffersContext();
  const { formatMessage } = useIntl();
  const filteredOfferErrorList = useAppSelector(state =>
    selectors.loyalty.selectIncentiveErrorsFilteredByCode(
      state,
      IncentiveEvaluationErrorCodes.INVALID_PAYMENT_METHOD
    )
  );
  const appliedRewardsFeedbackMap = useAppSelector(
    selectors.loyalty.selectAppliedRewardsFeedbackMap
  );

  const hasAppliedRewardErrors = !isEmpty(appliedRewardsFeedbackMap);

  const { redeemableItemsSet } = useRewardDiscount({
    serverOrder,
    cartEntries,
  });

  const selectedLoyaltyOffer = useAppSelector(selectors.loyalty.selectSelectedOffer);
  const { checkLoyaltyOfferAvailability } = useLoyaltyIncentivesAvailability();
  const isSelectedLoyaltyOfferAvailable =
    !selectedLoyaltyOffer || checkLoyaltyOfferAvailability(selectedLoyaltyOffer);
  const cartHasUnavailableOffers = useCartHasUnavailableOffers();

  const { store, resetStore } = useStoreContext();

  const { isCartTotalNegative = false } = calculateCartTotalWithDiscount();

  const legacyOfferIsValid = !offersCtx.selectedOffer || !offersCtx.offerValidationErrors.length;

  const isSelectedOfferOrCartOffersUnavailable =
    !isSelectedLoyaltyOfferAvailable || cartHasUnavailableOffers;

  const offerIsValid = legacyOfferIsValid && !isSelectedOfferOrCartOffersUnavailable;
  const isDiscountNotApplied = useAppSelector(selectors.loyalty.selectIsDiscountNotApplied);

  const invalidIncentives = !!filteredOfferErrorList.length || hasAppliedRewardErrors;
  const isPaymentDisabled =
    invalidIncentives ||
    isSelectedOfferOrCartOffersUnavailable ||
    isCartTotalNegative ||
    isDiscountNotApplied ||
    !cartEntries.length;

  useEffect(() => {
    if (!enableOrdering) {
      resetStore();
    }
  }, [enableOrdering, store]);

  return (
    <>
      <Head>
        <title>{`${formatMessage({ id: 'cart' })} - Firehouse Subs`}</title>
      </Head>
      <CartPageContainer>
        {/* TODO: RN - Commenting out isInSameRegion check until region native issue is solved */}
        {/* {isMobile && isInSameRegion ? ( */}
        <Content
          isCateringOrder={!!isCatering}
          upsellEnabled={upsellEnabled}
          mobileCartUpsellLocation={mobileCartUpsellLocation}
        >
          {/* rendering in two distinct places for a11y flow */}
          {upsellEnabled && mobileCartUpsellLocation === MobileCartUpsellLocation.AboveYourCart && (
            <Box mb="$6">
              <Upsell isInCart />
            </Box>
          )}
          <Box>
            {shouldShowOffersWarning && (
              <InlineAlert
                status="warning"
                borderRadius={8}
                marginBottom="$2"
                marginTop="$3"
                iconCentered
                message={formatMessage(
                  { id: 'offersWarningOnCart' },
                  {
                    offersPageLink: (
                      <Link
                        href={formatMessage({ id: 'routes.loyaltyOfferList' })}
                        style={{ textDecorationLine: 'underline' }}
                        replace // Using replace to make sure we unmount the cart for syncing order/cart contexts
                      >
                        offers
                      </Link>
                    ),
                  }
                )}
              />
            )}
            <YourCart
              checkoutPaymentMethod={checkoutPaymentMethod}
              redeemableItemsSet={redeemableItemsSet}
              showAddExtrasBtn={hasAddOns}
            />
          </Box>

          {/* rendering in two distinct places for a11y flow */}
          {upsellEnabled &&
            mobileCartUpsellLocation === MobileCartUpsellLocation.AbovePaymentDetails && (
              <Upsell isInCart />
            )}
          <Box>
            <CartErrorMessages
              isOfferNotAvailable={isSelectedOfferOrCartOffersUnavailable}
              isCartTotalNegative={Boolean(isCartTotalNegative)}
            />
            <Payment
              disabled={Boolean(isPaymentDisabled)}
              offerIsValid={offerIsValid}
              orderStatus={orderStatus}
              serverOrder={serverOrder}
              shouldSkipPriceOrder={shouldSkipPriceOrder}
            />
          </Box>
        </Content>
      </CartPageContainer>
    </>
  );
};

export default React.memo(CartContent);
