import { PayloadAction } from '@reduxjs/toolkit';
import { isEqual, omit, uniqBy } from 'lodash';

import { ICartEntry, IOfferDiscount } from '@rbi-ctg/menu';
import {
  IIncentiveSelection,
  IOffersFragment,
  LoyaltyOfferRedemptionType,
} from 'generated/graphql-gateway';
import { SYNC_WITH_STORAGE_ACTION } from 'state/global-state/global-actions';
import { createAppSlice } from 'state/global-state/utils';
import { IncentiveEligibleItem, IncentiveEvaluationMap } from 'state/loyalty/hooks/types';
import { LoyaltyAppliedOffer, LoyaltyOffer } from 'state/loyalty/types';
import { isAppliedOfferSwap } from 'state/loyalty/utils';
import { isOfferDiscount } from 'utils/cart/helper';

import { userSlice } from '../user/user.slice';

import { IOffersState } from './offers.types';
import {
  getAppliedOffersFromStorage,
  getSelectedOfferFromStorage,
  handleGlobalDiscountOfferIncentivesError,
  removeSelectedOfferInStorage,
  updateAppliedOffersInStorage,
  updateSelectedOfferInStorage,
} from './offers.utils';

export const initialState: IOffersState = {
  appliedOffers: [],
  cmsOffers: [],
  cmsOffersV2: [],
  incentivesIds: [],
  offers: [],
  offersFeedbackMap: {},
  offersEligibleItems: [],
  offersLoading: false,
  offerRedemptionAvailableAfter: null,
  personalizedOffers: [],
  selectedOffer: null,
  selectedOfferSelections: [],
  surpriseAvailable: false,
  upsizeAvailable: false,
  userOffers: [],
  shouldRefetchOffers: false,
  isDiscountNotApplied: false,
};

export const offersSlice = createAppSlice({
  name: 'offers',
  initialState,
  reducers: {
    applyOffer: (state, { payload: newOffer }: PayloadAction<LoyaltyAppliedOffer>) => {
      const isSwapOffer = isAppliedOfferSwap(newOffer);
      const isStackableOffer = newOffer.isStackable;
      const updatedOffers = state.appliedOffers
        .filter((appliedOffer: LoyaltyAppliedOffer) => {
          if (isSwapOffer) {
            // filters out already applied swap offer
            return !isAppliedOfferSwap(appliedOffer);
          } else if (isStackableOffer) {
            // Ensures we are not adding the same offer
            return appliedOffer.id !== newOffer.id;
          }
          // filters out non-stackable offers
          return isAppliedOfferSwap(appliedOffer) || appliedOffer.isStackable;
        })
        .concat(newOffer);
      updateAppliedOffersInStorage(updatedOffers);
      state.appliedOffers = updatedOffers;
    },
    unshiftPersonalizedOffer: (state, { payload }: PayloadAction<LoyaltyOffer>) => {
      state.personalizedOffers = [payload, ...state.personalizedOffers];
    },
    resetAppliedOffers: state => {
      updateAppliedOffersInStorage([]);
      state.appliedOffers = [];
      state.offersFeedbackMap = {};
      state.selectedOffer = null;
      state.isDiscountNotApplied = false;
      state.offersEligibleItems = [];
      state.selectedOfferSelections = [];
      removeSelectedOfferInStorage();
    },
    resetOfferFeedbackMap: state => {
      state.offersFeedbackMap = {};
    },
    resetOffersEligibleItems: state => {
      state.offersEligibleItems = [];
    },
    resetSurpriseAvailability: state => {
      state.surpriseAvailable = false;
    },
    resetUpsizeAvailability: state => {
      state.upsizeAvailable = false;
    },
    setAppliedOffers: (state, { payload }: PayloadAction<LoyaltyAppliedOffer[]>) => {
      updateAppliedOffersInStorage(payload);
      state.appliedOffers = payload;
    },
    setCmsOffers: (state, { payload }: PayloadAction<LoyaltyOffer[]>) => {
      const newCmsOffers = uniqBy(payload, 'loyaltyEngineId');
      if (!isEqual(state.cmsOffers, newCmsOffers)) {
        state.cmsOffers = newCmsOffers;
      }
    },
    setCmsOffersV2: (state, { payload }: PayloadAction<LoyaltyOffer[]>) => {
      const newCmsOffers = uniqBy(payload, 'loyaltyEngineId');
      if (!isEqual(state.cmsOffersV2, newCmsOffers)) {
        state.cmsOffersV2 = newCmsOffers;
      }
    },
    unshiftCmsOffers: (state, { payload }: PayloadAction<LoyaltyOffer[]>) => {
      const newCmsOffers = uniqBy([...payload, ...state.cmsOffers], 'loyaltyEngineId');
      if (!isEqual(state.cmsOffers, newCmsOffers)) {
        state.cmsOffers = newCmsOffers;
      }
    },
    setIncentivesIds: (state, { payload }: PayloadAction<string[]>) => {
      state.incentivesIds = payload;
    },
    setIsDiscountNotApplied: (state, { payload }: PayloadAction<boolean>) => {
      state.isDiscountNotApplied = payload;
    },
    setOffers: (state, { payload }: PayloadAction<IOffersFragment[]>) => {
      state.offers = payload;
    },
    setOffersFeedbackMap: (state, { payload }: PayloadAction<IncentiveEvaluationMap>) => {
      const newOffersFeedbackMap = Object.assign({}, payload);

      for (const incentiveId in newOffersFeedbackMap) {
        const loyaltyOfferWithOfferDiscount = state.cmsOffers.find(
          cmsOffer =>
            cmsOffer.loyaltyEngineId === incentiveId && cmsOffer.incentives?.some(isOfferDiscount)
        );
        const offerDiscount = loyaltyOfferWithOfferDiscount?.incentives?.find(
          isOfferDiscount
        ) as IOfferDiscount;

        const incentiveEvaluations = newOffersFeedbackMap[incentiveId];
        if (offerDiscount && incentiveEvaluations?.length > 0) {
          newOffersFeedbackMap[incentiveId] = handleGlobalDiscountOfferIncentivesError(
            incentiveEvaluations,
            offerDiscount
          );
        }
      }

      state.offersFeedbackMap = newOffersFeedbackMap;
    },
    setOffersEligibleItems: (state, { payload }: PayloadAction<IncentiveEligibleItem[]>) => {
      if (!isEqual(state.offersEligibleItems, payload)) {
        state.offersEligibleItems = payload;
      }
    },
    setOffersLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.offersLoading = payload;
    },
    // TODO: remove this function and get the value from the user
    setOfferRedemptionAvailableAfter: (state, { payload }: PayloadAction<string | null>) => {
      state.offerRedemptionAvailableAfter = payload;
    },
    setPersonalizedOffers: (state, { payload }: PayloadAction<LoyaltyOffer[]>) => {
      state.personalizedOffers = payload;
    },
    setSelectedOffer: (state, { payload }: PayloadAction<LoyaltyOffer | null>) => {
      updateSelectedOfferInStorage(payload);
      state.selectedOffer = payload;
      state.selectedOfferSelections = [];
    },
    selectedOfferV2: (
      state,
      { payload }: PayloadAction<{ offer: LoyaltyOffer | null; selections?: IIncentiveSelection[] }>
    ) => {
      updateSelectedOfferInStorage(payload.offer, payload.selections);
      state.selectedOffer = payload.offer;
      state.selectedOfferSelections = payload.selections ?? [];
    },
    setSurpriseOfferIfAvailable: (state, { payload: offer }: PayloadAction<IOffersFragment>) => {
      if (offer.redemptionType === LoyaltyOfferRedemptionType.SURPRISE) {
        state.surpriseAvailable = true;
      }
    },
    setUpsizeAvailable: (state, { payload }: PayloadAction<boolean>) => {
      state.upsizeAvailable = payload;
    },
    setUserOffers: (state, { payload }: PayloadAction<IOffersFragment[]>) => {
      state.userOffers = payload;
    },
    removeAppliedOffer: (state, { payload: appliedOffer }: PayloadAction<LoyaltyAppliedOffer>) => {
      const updatedOffers = state.appliedOffers.filter(offer => {
        return offer.cartId === appliedOffer.cartId;
      });

      updateAppliedOffersInStorage(updatedOffers);

      state.appliedOffers = updatedOffers;
    },
    removeAppliedOfferByCartEntry: (state, { payload: cartEntry }: PayloadAction<ICartEntry>) => {
      const updatedOffers = state.appliedOffers.filter(offer => {
        if (
          cartEntry.cartId === offer.cartId &&
          offer.id === state.selectedOffer?.loyaltyEngineId
        ) {
          removeSelectedOfferInStorage();
        }
        // If cart entry has children, cartIds could match
        // otherwise, cartId should check against Swap cartId
        const shouldKeepOffer =
          offer?.swap && !cartEntry.children.length
            ? offer?.swap?.cartId !== cartEntry?.cartId
            : offer?.cartId !== cartEntry?.cartId;

        if (!shouldKeepOffer && offer?.id && state.offersFeedbackMap[offer.id]) {
          state.offersFeedbackMap = omit(state.offersFeedbackMap, offer.id);
        }
        return shouldKeepOffer;
      });

      updateAppliedOffersInStorage(updatedOffers);

      state.appliedOffers = updatedOffers;
    },
    setShouldRefetchOffers: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldRefetchOffers = payload;
    },
    setResetAppliedOffersAndRefetch: state => {
      state.selectedOffer = null;
      state.offersEligibleItems = [];
      state.selectedOfferSelections = [];
      removeSelectedOfferInStorage();
      updateAppliedOffersInStorage([]);
      state.offersFeedbackMap = {};
      state.appliedOffers = [];
      state.shouldRefetchOffers = true;
      state.isDiscountNotApplied = false;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(SYNC_WITH_STORAGE_ACTION, (state: IOffersState) => {
        state.appliedOffers = getAppliedOffersFromStorage();
        const { offer, selections } = getSelectedOfferFromStorage();
        state.selectedOffer = offer;
        state.selectedOfferSelections = selections;

        // Load selected offer v2 and set as part of the CmsOffersV2
        if (state.selectedOffer?._type === 'simplyOffer') {
          state.cmsOffersV2 = [state.selectedOffer];
        }
      })
      .addCase(userSlice.actions.setUser, (state: IOffersState, action) => {
        const loyaltyUser = action.payload;
        // If loyalty user is null, reset user offers
        // This handles the logout case
        if (!loyaltyUser) {
          state.personalizedOffers = [];
          state.userOffers = [];
        }
        // When loyaltyUser changes, offers must be updated
        state.shouldRefetchOffers = true;
      }),
});
