export enum TransactionChannel {
  App = 'APP',
  Campaign = 'CAMPAIGN',
  Mobile = 'MOBILE',
  PointsExpiration = 'POINTS_EXPIRATION',
  Receipt = 'RECEIPT',
  Restaurant = 'RESTAURANT',
  Support = 'SUPPORT',
  ThirdParty = 'THIRD-PARTY',
  Web = 'WEB',
}
