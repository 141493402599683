import { Svg, SvgPath, type SvgProps, registerSVG } from '../../../src/components/svg';

export function Twitter(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgPath
        d="m23.149 5.1307c-0.8149 0.36147-1.6907 0.60566-2.6098 0.71552 0.938-0.56236 1.6586-1.4528
      1.9979-2.514-0.8781 0.52073-1.8505 0.89895-2.8857
      1.1026-0.8288-0.8832-2.0097-1.4349-3.3168-1.4349-2.5095 0-4.5441 2.0345-4.5441 4.544 0
      0.35615 0.0402 0.70301 0.1177 1.0356-3.7767-0.18949-7.125-1.9986-9.366-4.7479-0.39115
      0.67111-0.6153 1.4516-0.6153 2.2844 0 1.5766 0.8022
      2.9674 2.0216 3.7824-0.74487-0.0237-1.4456-0.22802-2.0582-0.56845-4.4e-4 0.01904-4.4e-4
      0.03798-4.4e-4 0.05725 0 2.2016 1.5664 4.0381 3.6452 4.4557-0.38129 0.1037-0.78277
      0.1593-1.1972 0.1593-0.29281 0-0.57753-0.0284-0.85507-0.0815 0.57831 1.8053 2.2564
      3.1191 4.2449 3.1557-1.5552 1.2189-3.5145 1.9454-5.6435 1.9454-0.36679
      0-0.72848-0.0216-1.084-0.0637 2.011 1.2894 4.3996 2.0418 6.9657 2.0418 8.358 0 12.929-6.9243
      12.929-12.929 0-0.19702-0.0046-0.39303-0.0133-0.58794 0.8879-0.64066 1.6583-1.4409
      2.2674-2.3522"
        fillRule="evenodd"
      />
    </Svg>
  );
}

registerSVG('Twitter', Twitter);
