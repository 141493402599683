import { Path, Rect, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconPaymentTypeAmazonPay = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Rect width={24} height={16} y={4.101} fill="#fff" rx={2} />
      <Rect width={23} height={15} x={0.5} y={4.601} stroke="#000" strokeOpacity={0.1} rx={1.5} />
      <Path
        fill="#F90"
        d="M15.512 15.333c-4.128 1.964-6.69.32-8.33-.678-.1-.063-.273.015-.123.187.546.662 2.336 2.259 4.673 2.259 2.338 0 3.73-1.276 3.904-1.499.172-.22.05-.342-.124-.27Zm1.159-.64c-.111-.145-.674-.172-1.029-.128-.355.042-.887.259-.841.39.024.048.072.026.316.004.245-.024.93-.11 1.072.076.144.188-.218 1.083-.284 1.227-.064.144.024.182.144.085.118-.096.332-.345.476-.697.143-.354.23-.848.146-.958Z"
      />
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.736 11.324c0 .516.013.946-.247 1.403-.21.373-.544.601-.916.601-.508 0-.804-.387-.804-.958 0-1.127 1.01-1.332 1.967-1.332v.286Zm1.334 3.225a.276.276 0 0 1-.312.032c-.44-.365-.518-.534-.76-.882-.725.74-1.239.962-2.18.962-1.113 0-1.98-.687-1.98-2.062 0-1.074.582-1.805 1.41-2.162.719-.316 1.722-.372 2.488-.46v-.17c0-.315.024-.688-.16-.96-.162-.243-.47-.343-.742-.343-.505 0-.955.258-1.065.794-.022.12-.11.236-.229.242l-1.283-.138c-.108-.024-.227-.111-.198-.277.296-1.555 1.701-2.024 2.959-2.024.644 0 1.485.17 1.993.658.644.601.582 1.403.582 2.276v2.062c0 .62.257.89.499 1.226.085.119.104.262-.004.351-.27.226-.75.644-1.014.879l-.004-.004"
        clipRule="evenodd"
      />
      <Path
        fill="#F90"
        d="M15.512 15.333c-4.128 1.964-6.69.32-8.33-.678-.1-.063-.273.015-.123.187.546.662 2.336 2.259 4.673 2.259 2.338 0 3.73-1.276 3.904-1.499.172-.22.05-.342-.124-.27Zm1.159-.64c-.111-.145-.674-.172-1.029-.128-.355.042-.887.259-.841.39.024.048.072.026.316.004.245-.024.93-.11 1.072.076.144.188-.218 1.083-.284 1.227-.064.144.024.182.144.085.118-.096.332-.345.476-.697.143-.354.23-.848.146-.958Z"
      />
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.736 11.324c0 .516.013.946-.247 1.403-.21.373-.544.601-.916.601-.508 0-.804-.387-.804-.958 0-1.127 1.01-1.332 1.967-1.332v.286Zm1.334 3.225a.276.276 0 0 1-.312.032c-.44-.365-.518-.534-.76-.882-.725.74-1.239.962-2.18.962-1.113 0-1.98-.687-1.98-2.062 0-1.074.582-1.805 1.41-2.162.719-.316 1.722-.372 2.488-.46v-.17c0-.315.024-.688-.16-.96-.162-.243-.47-.343-.742-.343-.505 0-.955.258-1.065.794-.022.12-.11.236-.229.242l-1.283-.138c-.108-.024-.227-.111-.198-.277.296-1.555 1.701-2.024 2.959-2.024.644 0 1.485.17 1.993.658.644.601.582 1.403.582 2.276v2.062c0 .62.257.89.499 1.226.085.119.104.262-.004.351-.27.226-.75.644-1.014.879l-.004-.004"
        clipRule="evenodd"
      />
    </Svg>
  );
};
