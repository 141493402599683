import React from 'react';

import { ILinkWidgetFragment, IVerticalLinkListWidgetFragment } from 'generated/sanity-graphql';

import { Links } from './links';
import { VerticalListLinkContainer } from './styled';

type IVerticalLinkListProps = IVerticalLinkListWidgetFragment;

export const VerticalLinkList = (props: IVerticalLinkListProps) => (
  <VerticalListLinkContainer>
    <Links links={props.linkWidgets as ILinkWidgetFragment[]} />
  </VerticalListLinkContainer>
);
