import { Button } from '@fhs/ui';
import { Box, Text } from '@fhs-legacy/universal-components';

const SuggestionBox = Box.withConfig({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  paddingY: 2,
});

const InnerBox = Box.withConfig({
  flex: 1,
  display: 'flex',
  alignItems: 'start',
  alignContent: 'start',
  flexDirection: 'column',
  justifyItems: 'start',
  justifyContent: 'center',
});

const ButtonBox = Box.withConfig({
  display: 'flex',
});

export const PickUpAt = Text.withConfig({
  textAlign: 'left',

  fontSize: 'xs',
  lineHeight: 'xs',
  fontFamily: 'body',
});

export const StoreName = Text.withConfig({
  textAlign: 'left',

  fontSize: 'sm',
  lineHeight: 'sm',
  fontFamily: 'heading',
  paddingY: '$1',
});

export const DistanceAndAddress = Text.withConfig({
  ellipsizeMode: 'tail',
  numberOfLines: 1,
  textAlign: 'left',

  fontSize: 'xs',
  lineHeight: 'xs',
  fontFamily: 'body',
});

export type AddressBoxSuggestionProps = {
  pickStoreAction: () => void;
  storeName: string;
  distance: string;
  address: string;
};

export const AddressBoxSuggestion: React.FC<React.PropsWithChildren<AddressBoxSuggestionProps>> = ({
  pickStoreAction,
  storeName,
  distance,
  address,
}) => {
  return (
    <SuggestionBox>
      <InnerBox>
        <PickUpAt>Pick Up:</PickUpAt>
        <StoreName>{storeName}</StoreName>
        <DistanceAndAddress>{`${distance} · ${address}`}</DistanceAndAddress>
      </InnerBox>
      <ButtonBox>
        <Button
          type="outline"
          size="xs"
          testID={`store-suggestion-select-${storeName}`}
          onPress={pickStoreAction}
        >
          <Button.Text>Select</Button.Text>
        </Button>
      </ButtonBox>
    </SuggestionBox>
  );
};
