import * as React from 'react';

import { StoreSelector as StoreLocatorRefresh } from './new-ui';
import { IStoreSelectorProps } from './types';

/**
 *
 * StoreSelectorContainer purpose
 *
 */
export const StoreSelectorContainer: React.FC<React.PropsWithChildren<IStoreSelectorProps>> = ({
  isOpen,
  onDismiss,
  updateStore,
}) => {
  return <StoreLocatorRefresh onDismiss={onDismiss} updateStore={updateStore} isOpen={isOpen} />;
};

export default StoreSelectorContainer;
