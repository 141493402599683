import { useIntl } from 'react-intl';

import { ICombo, IItem } from '@rbi-ctg/menu';
import { useFormatCalories } from 'hooks/use-format-calories';
import { useFormatJoules } from 'hooks/use-format-joules';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMainMenuContext } from 'state/main-menu';
import { isItem, isPicker } from 'utils/menu';
import { formatCalorieRange, formatCaloriesToJoulesFromString } from 'utils/menu/calories';

interface IUseCalories {
  (): (comboOrItem: ICombo | IItem) => string;
}

export const useCalories: IUseCalories = () => {
  const { formatMessage } = useIntl();
  const { getCalories } = useMainMenuContext();
  const formatCalories = useFormatCalories();
  const formatJoules = useFormatJoules();

  const displayNutritionWithModifiersFromSanity = useFlag(
    LaunchDarklyFlag.DISPLAY_NUTRITION_WITH_MODIFIERS_FROM_SANITY
  );
  const enabledFormattedCaloriesForDefault = useFlag(
    LaunchDarklyFlag.ENABLE_FORMATTED_CALORIES_FOR_DEFAULT
  );
  const enableEUNutritionDisplay = useFlag(LaunchDarklyFlag.ENABLE_EU_NUTRITION_DISPLAY);

  return comboOrItem => {
    let formattedCalories: string | null | undefined = '';
    const calories = getCalories(comboOrItem);

    if (!calories) {
      return '';
    }

    if (enabledFormattedCaloriesForDefault && (isItem(comboOrItem) || isPicker(comboOrItem))) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null | undefined' is no... Remove this comment to see the full error message
      formattedCalories = formatCalories(calories.default);
    } else {
      formattedCalories = formatCalorieRange({
        min: Number(calories.min),
        max: Number(calories.max),
        // @ts-expect-error TS(2322) FIXME: Type 'IUseFormatCalories' is not assignable to typ... Remove this comment to see the full error message
        formatFn: formatCalories,
      });
    }

    if (displayNutritionWithModifiersFromSanity && isItem(comboOrItem)) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null | undefined' is no... Remove this comment to see the full error message
      formattedCalories = formatCalories(calories.default);
    }

    if (enableEUNutritionDisplay) {
      const caloriesInJoules = formatCaloriesToJoulesFromString(formattedCalories, formatJoules);
      formattedCalories = formatMessage(
        { id: 'euNutritionValues' },
        { calories: formattedCalories, joules: caloriesInJoules }
      ) as string;
    }

    return String(formattedCalories);
  };
};
