import { Reference } from '@apollo/client';
import { CanReadFunction, ToReferenceFunction } from '@apollo/client/cache/core/types/common';

export const readCmsOffers = ({
  toReference,
  canRead,
  offerEngineIds,
  typename,
}: {
  toReference: ToReferenceFunction;
  canRead: CanReadFunction;
  offerEngineIds: string[] | undefined;
  typename: 'OfferTemplate' | 'ConfigOffer';
}) => {
  // checks the references of offer details with the ids passed to the query
  const refs = offerEngineIds?.map((id: string | undefined) =>
    toReference({ __typename: typename, loyaltyEngineId: id })
  );

  // if every reference exists, returns the data from the cache
  if (refs?.every((ref: Reference | undefined) => canRead(ref))) {
    return refs;
  }

  // if the reference does not exist, it executes the query over the network
  return undefined;
};
