/**
 * Style Primitives - USE DIRECTLY IN CSS ONLY WHEN DESIGN TOKENS ARE NOT APPROPRIATE
 */
export const primitive = {
  // colors
  $black: '#000',
  $white: '#fff',
  $error: 'rgb(209,69,69)',
  $disabledText: 'rgba(0,0,0,0.55)',
  $disabledTextReversed: 'rgba(255,255,255,0.51)',
  $blackOpacity70: 'rgba(0,0,0,0.7)',
  $blackOpacity30: 'rgba(0,0,0,0.3)',
  $blackOpacity10: 'rgba(0,0,0,0.1)',
  $blackOpacity4: 'rgba(0,0,0,0.04)',
  $whiteOpacity30: 'rgba(255,255,255,0.3)',
  $whiteOpacity10: 'rgba(255,255,255,0.1)',
  $whiteOpacity4: 'rgba(255,255,255,0.04)',

  // colors
  $alwaysRed: 'rgb(200,16,46)',
  $houseRed: '#E31837',
  $houseYellow: '#EBA518',
  $houseDark: '#01000B',
  $houseLight: '#F2F2F6',
  $houseYellowLighten20: '#FBEDD1',
  $houseRedDarken10: '#B1080F',
  $houseRedOpacity10: 'rgba(227, 24, 55, 0.1)',

  // Ported from other brands. Maybe rename?
  $burntOrangeOpacity10: 'rgba(242,86,0,0.1)',
  $nolaPurple: 'rgb(145,25,135)',
  $nolaTeal: 'rgb(0,166,158)',
  $coffeeBrown: 'rgb(63,32,33)',

  // spacing
  $spacing0: '0',
  $spacing1: '4px',
  $spacing2: '8px',
  $spacing3: '12px',
  $spacing4: '16px',
  $spacing5: '20px',
  $spacing6: '24px',
  $spacing7: '32px',
  $spacing8: '40px',
  $spacing9: '48px',
  $spacing10: '56px',
  $spacing11: '80px',

  $headerFour: '13px/16px "chicken_sans_bold", sans-serif',
  $formUtility: '11px/12px "chicken_sans", sans-serif',
} as const;
