import React, { FC } from 'react';

import { HStack } from '@fhs-legacy/universal-components';
import { designTokens } from 'styles/constants/design-tokens';

import { FIXED_STICKY_FOOTER_HEIGHT } from './constants';
import { StickyBoxChild } from './sticky-footer.styled';
import { IStickyFooter } from './types';

const Footer: FC<React.PropsWithChildren<IStickyFooter>> = ({ boxProps, elements, padding }) => (
  <HStack
    width="full"
    testID="sticky-footer-banner"
    minHeight={`${FIXED_STICKY_FOOTER_HEIGHT}px`}
    padding={padding}
    background={designTokens['background-pattern']}
    {...boxProps}
  >
    {elements.map(({ element, spaces }, idx) => (
      <StickyBoxChild key={idx} lastChild={idx === elements.length - 1} spaces={spaces}>
        {element}
      </StickyBoxChild>
    ))}
  </HStack>
);

/**
 * StickyFooter is a fixed bottom banner component by using a PortalSlot to
 * ultimately render within the LayoutBottomContainer.
 *
 * IT takes a set of element(s) and renders them using flex.
 * It will do this depending on the number of spaces specified for the element.
 * Else, it'll default to take up the whole width (i.e. 'flex: 1').
 *
 * It can also take in additional Box props if any style needs overriding.
 * This makes the component more flexible for various use cases.
 */
export const StickyFooter: FC<React.PropsWithChildren<IStickyFooter>> = Footer;

// Adding this extra export to integrate with footers in the app that currently use the StickyFooter to hug the bottom of the page.
// Those situations need to be refactored to use flexbox appropriately. But exporting this as the legacy implementation to keep
// the app working for now.
export const DialogFooter = Footer;
