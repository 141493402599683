import React from 'react';

import { ICombo, IItem, MenuObject } from '@rbi-ctg/menu';
import { useMainMenuContext } from 'state/main-menu';
import { useUIContext } from 'state/ui';

interface IFormatMenuPriceProps {
  menuItem: ICombo | IItem | MenuObject;
}

const FormatMenuPrice: React.FC<React.PropsWithChildren<IFormatMenuPriceProps>> = ({
  menuItem,
}) => {
  const { getPricingAndAvailability } = useMainMenuContext();
  const { formatCurrencyForLocale } = useUIContext();

  if (!menuItem) {
    return null;
  }

  const pricingAndAvailability = getPricingAndAvailability(menuItem._id);

  if (!pricingAndAvailability) {
    return null;
  }

  const { price } = pricingAndAvailability;

  if (price?.default) {
    return <>{formatCurrencyForLocale(price.default)}</>;
  }

  if (!price?.default && price?.min) {
    return <>{formatCurrencyForLocale(price.min)}</>;
  }

  return null;
};

export default FormatMenuPrice;
