import { type MutableRefObject, createContext, useContext } from 'react';
import type { View } from 'react-native';

import type { CheckedState } from './types';

export type CheckboxContext = {
  label: string;
  handlePress: () => void;
  checked: CheckedState;
  customLabelComponent?: React.ReactNode;
  disabled: boolean;
  pressableRef: MutableRefObject<View | null>;
};

export const CheckboxContext = createContext<CheckboxContext | null>(null);

export const useCheckbox = () => {
  const context = useContext(CheckboxContext);

  if (__DEV__) {
    if (context == null) {
      throw new Error('useCheckbox usage must be wrapped within a Checkbox.');
    }
  }

  return context as CheckboxContext;
};
