import { router } from 'expo-router';

export const relativeRoutes = {
  address: 'address',
  addCard: 'add-card',
  addExtra: 'extras',
  addGiftCard: 'add-gift-card',
  favorites: 'favorites',
  loadPrepaidCard: 'load-prepaid-card',
  locationService: 'location-service',
  noDeliveryStores: 'no-delivery-stores',
  deliveryStoresClosed: 'delivery-stores-closed',
  orderPayment: 'payment',
  storeConfirmation: 'store-confirmation',
  pickupMode: 'pickup-mode',
  recentItems: 'recent-items',
  serviceMode: 'service-mode',
  serviceModeCatering: 'service-mode-catering',
  serviceModeCateringDelivery: 'service-mode-catering-delivery',
  serviceModeCateringPickup: 'service-mode-catering-pickup',
  store: 'store',
  sharedRolls: 'send',
  successGiftCard: 'success-gift-card',
  successTransferGiftCard: 'success-transfer-gift-card',
  transferBalance: 'transfer-balance',
  transferGiftCard: 'transfer-gift-card',
};

export const routes = {
  aboutApp: '/about-app',
  accountMain: '/account/main',
  accountRequestInfo: '/account/request-information',
  accountDelete: '/account/delete',
  addCard: `/account/payment/${relativeRoutes.addCard}`,
  addGiftCard: `/account/payment/${relativeRoutes.addGiftCard}`,
  address: `/store-locator/${relativeRoutes.address}`,
  authChallengeJwt: '/auth-challenge-jwt',
  base: '/',
  baseMain: 'home',
  browseMenu: '/browse-menu',
  bkRewards: '/bkrewards',
  cart: '/cart',
  cartMain: '/cart/main',
  cartAddCard: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.addCard}`,
  cartAddExtras: `/cart/${relativeRoutes.addExtra}`,
  cartPayment: `/cart/${relativeRoutes.orderPayment}`,
  cartStoreConfirmation: `/cart/${relativeRoutes.storeConfirmation}`,
  cardPaymentAddGiftCard: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.addGiftCard}`,
  cartPaymentSuccessGiftCard: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.successGiftCard}`,
  cartPaymentTransferGiftCard: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.transferGiftCard}`,
  cartPaymentTransferSuccessGiftCard: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.successTransferGiftCard}`,
  cartLoadPrepaidCard: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.loadPrepaidCard}`,
  cartTransferBalance: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.transferBalance}`,
  catering: '/catering',
  claimPointsAccepted: '/claim-points/accepted',
  claimPointsBase: '/claim-points',
  claimPointsProcessing: '/claim-points/claim',
  claimPointsSurvey: '/claim-points/survey',
  communication: '/communication-preferences',
  confirmJwt: '/confirm-jwt',
  confirmOtp: '/confirm-otp',
  delivery: '/delivery',
  deliveryStoresClosed: `/store-locator/${relativeRoutes.deliveryStoresClosed}`,
  diagnostics: '/diagnostics',
  drawer: 'Drawer',
  favoritesMain: `/menu/${relativeRoutes.favorites}/main`,
  favorites: `/menu/${relativeRoutes.favorites}`,
  favoriteEditor: `/menu/${relativeRoutes.favorites}/:favoriteId/edit`,
  info: '/account/info',
  issueCollector: '/issue-collector',
  languageSelector: '/language-selector',
  loading: '/loading',
  locationService: `/store-locator/${relativeRoutes.locationService}`,
  loyaltyDashboard: '/rewards/details',
  loyaltyHome: '/rewards',
  loyaltyRewardsDashboard: '/rewards/dashboard',
  loyaltyRewardsList: '/rewards/list',
  loyaltyOfferList: '/rewards/offers',
  menu: '/menu',
  menuEmpty: '/menu-empty',
  menuFavorites: '/menu/favorites',
  menuMain: '/menu/main',
  menuContent: '/menu-content',
  menuRecent: '/menu/recent-items',
  missingItems: '/missing-items',
  missingOrder: '/missing-order',
  noDeliveryStores: `/store-locator/${relativeRoutes.noDeliveryStores}`,
  notFound: '/not-found',
  notFound404: '/not-found/404',
  offers: '/rewards/offers',
  offersList: 'OffersList',
  offersV2: '/v2/offers',
  offersV2Details: '/v2/offers/details',
  offersV2Guide: '/v2/offers/guide',
  orderConfirmation: '/order-confirmation',
  orders: '/account/orders',
  ordersDetails: '/account/orders/details',
  payment: '/account/payment',
  accountPaymentSuccessGiftCard: `/account/${relativeRoutes.orderPayment}/${relativeRoutes.successGiftCard}`,
  accountPaymentTransferSuccessGiftCard: `/account/${relativeRoutes.orderPayment}/${relativeRoutes.successTransferGiftCard}`,
  pickupMode: `/store-locator/${relativeRoutes.pickupMode}`,
  linkCard: '/link-card',
  accountLoadPrepaidCard: `/account/${relativeRoutes.orderPayment}/${relativeRoutes.loadPrepaidCard}`,
  productDetail: '/product-detail',
  redeem: '/redeem',
  reportOrderIssue: '/digitalorderhelp',
  rewards: '/rewards/details',
  rewardsHistory: '/rewards/history',
  rewardsList: '/rewards-list',
  scanMain: '/scan-main',
  scaryPlaces: '/scaryplaces',
  serviceMode: `/store-locator/${relativeRoutes.serviceMode}`,
  serviceModeCatering: `/store-locator/${relativeRoutes.serviceModeCatering}`,
  serviceModeCateringDelivery: `/store-locator/${relativeRoutes.serviceModeCateringDelivery}`,
  serviceModeCateringPickup: `/store-locator/${relativeRoutes.serviceModeCateringPickup}`,
  signOut: '/signout',
  staticPage: '/static-page',
  store: `/store-locator/${relativeRoutes.store}`,
  support: '/support',
  supportOrderConfirmation: '/order-confirmation/support',
  supportCategories: '/support/categories',
  supportCategory: '/support/categories/:categoryId',
  supportOrders: '/support/orders',
  supportForm: '/support/form',
  supportPage: 'SupportPage',
  tabs: 'Tabs',
  transferGiftCard: `/account/${relativeRoutes.orderPayment}/${relativeRoutes.transferGiftCard}`,
  accountTransferBalance: `/account/${relativeRoutes.orderPayment}/${relativeRoutes.transferBalance}`,
  trending: '/trending',
  contactUs: 'contact-us',
  offerActivate: '/activate-offer',
  modalStaticPage: '/modal-static-page',
  favoriteStoreSearch: '/favorite-store-search',

  //TODO: this route is a static page in sanity. Ensure it exists in data set

  foundation: '/foundation',
  acceptGift: '/accept-gift',
  account: '/account',
  sendGift: '/gift',
  exploreMenu: '/explore-menu',
  signUp: '/signup',
  signIn: '/signin',
  storeLocator: '/store-locator',
  scan: '/scan',
};

export const isSectionRoute = pathname => pathname.startsWith(`${routes.menu}/section-`);

export const isRecentItemsRoute = pathname =>
  pathname.startsWith(`${routes.menu}/${relativeRoutes.recentItems}`);
export const isFavoritesRoute = pathname => pathname.startsWith(routes.favorites);

export const isItemPickerOrComboRoute = pathname =>
  new RegExp(`^${routes.menu}/(item|picker|combo)-`).test(pathname);

export const isOrderConfirmationRoute = pathname =>
  new RegExp(`^${routes.orderConfirmation}`).test(pathname);

export const isCartRoute = pathname => new RegExp(`^${routes.cart}`).test(pathname);

export const isMenuTabRoute = pathname =>
  [routes.menu, routes.menuRecent, routes.menuFavorites].includes(pathname);

export function goBack({ location }) {
  const pathParts = location.pathname.split('/');
  pathParts.length = pathParts.length - 1;
  router.navigate(pathParts.join('/'));
}

export function goToBrowseMenu() {
  router.navigate(routes.browseMenu);
}

export function extractRefFromUrl({ '*': splat }, subtrahend = -1) {
  const paths = (splat || '').split('/');
  const ref = paths[paths.length + subtrahend];
  return ref;
}

export {
  getAllLocalizedVersionsOfRoute,
  getInCodeLocalizedRouteForPath,
  getStaticPagesLocalizedRouteForPath,
} from './intl';

// These are routes that are safe for appUpdates

export const UPDATE_ALLOWED_ROUTES = [routes.menu, routes.about];
export const PERMISSION_TO_UPDATE_ROUTES = [routes.cart];
