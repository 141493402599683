import { Platform } from 'react-native';

import { platform } from 'utils/environment';

/**
 * Keys for headers to be sent with each GraphQL request
 */
export const enum Header {
  LANGUAGE = 'x-ui-language',
  UI_PLATFORM = 'x-ui-platform',
  PLATFORM_FRAMEWORK = 'x-platform-framework',
  UI_VERSION = 'x-ui-version',
  UI_COMMIT_SHA = 'x-ui-sha',
  REGION = 'x-ui-region',
  SESSION_ID = 'X-Session-Id',
  GATEWAY_FLAGS = 'x-gateway-flags',
  CONTENT_TYPE = 'content-type',
}

const framework = Platform.select({
  native: 'react-native',
  default: 'react-dom',
});

type HttpHeaders = Record<Header, string>;

/**
 * HTTP headers to be sent with each GraphQL request
 *
 * Since this value is only consumed by the Apollo client, it is not necessary to
 * trigger a re-render when the value changes. Therefore, we can use a plain object.
 */
export const headers: Partial<HttpHeaders> = {
  [Header.UI_PLATFORM]: platform(),
  /** This header is used to identify when to send forter the cookie vs the mobile token for fraud protection */
  [Header.PLATFORM_FRAMEWORK]: framework,
  [Header.CONTENT_TYPE]: 'application/json',
};
/** Setter for dynamic header values to be sent with each GraphQL request */
export const setHeaders = (key: Header, value: string) => {
  headers[key] = value;
};
