import { StyleSheet, View, type ViewProps } from 'react-native';

import { tokens } from '../../tokens';
import { Text } from '../text';

import { useQuantity } from './context';

export type QuantityCounterBoxProps = ViewProps & {
  value: string;
  showBorder?: boolean;
};

export const QuantityCounterBox = ({
  value,
  showBorder,
  ...viewProps
}: QuantityCounterBoxProps) => {
  const textSize = useQuantity().size;
  return (
    <View
      {...viewProps}
      style={[styles.container, showBorder && styles.showBorder, viewProps.style]}
    >
      <Text.Ui size={textSize} numberOfLines={1} ellipsizeMode="tail">
        {value}
      </Text.Ui>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 46,
    minHeight: 46,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
  },
  showBorder: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
  },
});
