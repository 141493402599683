import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconFavoriteOutline = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M16.5 3.1a5.97 5.97 0 0 0-3.654 1.28 1.4 1.4 0 0 1-1.692 0A5.97 5.97 0 0 0 7.5 3.1C4.42 3.1 2 5.52 2 8.6c0 3.78 3.4 6.86 8.55 11.54l.777.703a1 1 0 0 0 1.344-.003l.779-.71C18.6 15.46 22 12.38 22 8.6c0-3.08-2.42-5.5-5.5-5.5Zm-4.4 15.55a.141.141 0 0 1-.2 0C7.14 14.34 4 11.49 4 8.6c0-2 1.5-3.5 3.5-3.5 1.301 0 2.573.707 3.257 1.752.226.346.585.609.998.609h.495c.415 0 .776-.266 1.003-.614.678-1.042 1.948-1.746 3.247-1.746 2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05Z"
      />
    </Svg>
  );
};
