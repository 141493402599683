import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Button, Text, YStack } from '@fhs/ui';

import { ButtonType } from '../button/types';

export interface IGenericCardProps {
  buttonHandle?: () => void;
  buttonIcon?: ReactNode;
  buttonTitle?: string;
  buttonType?: ButtonType;
  description: string;
  hideButton?: boolean;
  icon?: ReactNode;
  style?: StyleProp<ViewStyle>;
  title: string;
}

/**
 * A reusable card component that displays a title, description, and a customizable button.
 * It can also optionally display an icon and supports different button types.
 *
 * @param title - The title text displayed at the top of the card.
 * @param description - A brief description or message displayed below the title.
 * @param buttonTitle - The text for the button at the bottom of the card.
 * @param buttonHandle - The function to be called when the button is pressed.
 * @param buttonIcon - An optional icon to display inside the button.
 * @param buttonType="solid" - The style/type of the button. Defaults to "solid".
 * @param icon - An optional icon to display at the top of the card.
 * @param style - Additional styling for the card container.
 *
 * @returns {JSX.Element} A card component with a title, description, and button, optionally with icons.
 *
 * @example
 * <GenericCard
 *   title="Upgrade your plan"
 *   description="Get access to premium features by upgrading your plan."
 *   buttonTitle="Upgrade Now"
 *   buttonHandle={() => console.log('Upgrade clicked')}
 *   buttonIcon={<IconUpgrade />}
 *   icon={<IconPremium />}
 * />
 */
export const GenericCard = ({
  buttonHandle,
  buttonIcon,
  buttonTitle,
  buttonType = 'solid',
  description,
  hideButton,
  icon,
  style,
  title,
}: IGenericCardProps) => {
  return (
    <YStack style={[styles.container, style]}>
      {icon}
      <Text.Ui size="lg" weight="bold">
        {title}
      </Text.Ui>
      <Text.Ui size="md" style={{ textAlign: 'center' }}>
        {description}
      </Text.Ui>
      {!hideButton && (
        <View style={{ padding: 16 }}>
          <Button
            type={buttonType}
            style={styles.button}
            onPress={buttonHandle}
            aria-label={buttonTitle}
            size="lg"
          >
            {buttonIcon}
            <Button.Text>{buttonTitle ?? ''}</Button.Text>
          </Button>
        </View>
      )}
    </YStack>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    gap: 12,
  },
  button: {
    padding: 8,
  },
});
