import { ApolloError } from '@apollo/client';
import React from 'react';

import CartError, { SelectNewRestaurantActionButton } from 'components/cart-error';
import { useCartContext } from 'state/cart';
import { useRestaurantPosConnectivityStatus } from 'state/restaurant-connectivity-status';
import { useStoreContext } from 'state/store';
import { GraphQLErrorCodes, graphQLErrorsIncludeExtensionCode } from 'utils/errors';

import { useDeliveryErrorModalCopy } from './hooks/use-delivery-error-modal-copy';
import { IErrorsListItems } from './types';

export const CartPageError = () => {
  const { isStoreOpenAndAvailable, store } = useStoreContext();
  const {
    serverOrder,
    unavailableCartEntries,
    priceOrderFailure,
    priceMutationError,
    shouldSkipPriceOrder,
    setShouldSkipPriceOrder,
    showCartErrorModal,
    calculateCartTotalWithDiscount,
  } = useCartContext();
  const { isCartTotalNegative } = calculateCartTotalWithDiscount();
  const storeId = store.number || '';
  const { isRestaurantPosOnline } = useRestaurantPosConnectivityStatus({
    storeId,
  });

  const restaurantUnavailableError = graphQLErrorsIncludeExtensionCode(
    priceMutationError instanceof ApolloError ? priceMutationError.graphQLErrors : [],
    GraphQLErrorCodes.RESTAURANT_UNAVAILABLE
  );

  const handleDismissCartErrorModal = () => setShouldSkipPriceOrder(true);
  const unavailableCartItems = unavailableCartEntries.length > 0;
  const errorsList: IErrorsListItems = [
    {
      priceMutationError: !restaurantUnavailableError && Boolean(priceMutationError),
    },
    { priceOrderFailure },
    { unavailableCartItems },
    { isRestaurantPosOnline: !isRestaurantPosOnline },
    { isStoreOpenAndAvailable: !isStoreOpenAndAvailable || restaurantUnavailableError },
  ];
  const { errorModalBody, errorModalTitle } = useDeliveryErrorModalCopy(serverOrder, errorsList);
  if (!isRestaurantPosOnline && !shouldSkipPriceOrder) {
    return (
      <CartError
        body={errorModalBody}
        title={errorModalTitle}
        actionButtons={<SelectNewRestaurantActionButton onDismiss={handleDismissCartErrorModal} />}
        onDismiss={handleDismissCartErrorModal}
        additionalLoggingContext={{ isRestaurantPosOnline, shouldSkipPriceOrder }}
      />
    );
  }
  if (!showCartErrorModal) {
    return null;
  }
  return (
    <CartError
      body={errorModalBody}
      title={errorModalTitle}
      actionButtons={<SelectNewRestaurantActionButton onDismiss={handleDismissCartErrorModal} />}
      onDismiss={handleDismissCartErrorModal}
      additionalLoggingContext={{
        priceMutationError,
        priceOrderFailure,
        unavailableCartEntriesLength: unavailableCartEntries.length,
        isCartTotalNegative,
        shouldSkipPriceOrder,
      }}
    />
  );
};
