import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { useStoreContext } from '@fhs-legacy/frontend/src/state/store';
import { Box } from '@fhs-legacy/universal-components';
import EmptyState from 'components/empty-state';
import { routes } from 'utils/routing';

import { Wrap } from './styled';

interface IOrdersEmptyProps {
  children?: ReactNode;
}

const OrdersEmpty: FC<React.PropsWithChildren<IOrdersEmptyProps>> = ({ children }) => {
  const { formatMessage } = useIntl();
  const { noStoreSelected } = useStoreContext();

  const route = noStoreSelected ? routes.serviceMode : routes.menu;

  return (
    <Box>
      <Wrap>
        <EmptyState
          header={formatMessage({ id: 'noOrdersItemsYet' })}
          subheader={formatMessage({ id: 'recentOrdersEmptySubHeader' })}
          linkTo={route}
          linkText={formatMessage({ id: 'startOrder' })}
          linkFullWidth={true}
        />
        {children}
      </Wrap>
    </Box>
  );
};

export default OrdersEmpty;
