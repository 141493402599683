import { Stack } from 'expo-router';
import Head from 'expo-router/head';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FlatList, FlatListProps } from 'react-native';

import { ScreenContent } from '@fhs/screen-content';
import { Header } from '@fhs-legacy/universal-components';
import type { IHistoricalOrder } from '@rbi-ctg/menu';
import Footer from 'components/footer';
import LoadingAnimation from 'components/loading-animation';
import ModalOrderUnavailable from 'components/modal-order-unavailable';
import { RecentItemsCarousel } from 'components/recent-items-carousel';
import { RefreshButton } from 'components/refresh-button/';
import { ResponsiveCarouselWrapper } from 'components/responsive-carousel';
import { useRecentItemsAvailable } from 'hooks/use-recent-items-available';
import { useUserOrders } from 'hooks/use-user-orders';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOrderContext } from 'state/order';

import Order from './order';
import OrdersEmpty from './orders-empty';
import { OfferDisclaimer, OrdersContainer, OrdersEmptyWrapper } from './styled.base';
import { useUnavailableItemsOverrideProps } from './use-unavailable-items-override-props';

function historicalOrderKeyExtractor(item: IHistoricalOrder, index: number) {
  return item?.rbiOrderId || index + '_order';
}

export const AccountOrders = () => {
  const { unavailableCartEntries } = useOrderContext();
  const { loading, orders, refetch } = useUserOrders({}, 'no-cache');

  const { formatMessage } = useIntl();
  const showItems = useRecentItemsAvailable();
  const onRefreshPress = useCallback(() => {
    refetch();
  }, [refetch]);
  const enableRecentItemsAddToCart = useFlag(LaunchDarklyFlag.ENABLE_RECENT_ITEMS_ADD_TO_CART);
  const [showOrderUnavailableModal, setShowOrderUnavailableModal] = useState(false);
  const handleUnavailableModalDismiss = useCallback(() => {
    setShowOrderUnavailableModal(false);
  }, []);
  const modalOrderUnavailableOverrideProps = useUnavailableItemsOverrideProps();

  useEffect(() => {
    if (unavailableCartEntries.length) {
      setShowOrderUnavailableModal(true);
    }
  }, [unavailableCartEntries.length]);

  const refreshButton = (
    <RefreshButton onPress={onRefreshPress}>{formatMessage({ id: 'refresh' })}</RefreshButton>
  );
  const hasOrders = !!orders.length;

  const renderHistoricalOrder = useCallback<
    NonNullable<FlatListProps<IHistoricalOrder>['renderItem']>
  >(({ item }) => <Order order={item} />, []);

  if (showOrderUnavailableModal) {
    return (
      <ScreenContent after={<Footer />}>
        <Head>
          <title>{`${formatMessage({ id: 'recentOrders' })} - Firehouse Subs`}</title>
        </Head>
        <ModalOrderUnavailable
          items={unavailableCartEntries}
          onDismiss={handleUnavailableModalDismiss}
          {...modalOrderUnavailableOverrideProps}
        />
      </ScreenContent>
    );
  }

  return (
    <ScreenContent after={<Footer />}>
      <Stack.Screen options={{ title: formatMessage({ id: 'recentOrders' }) }} />
      <Head>
        <title>{`${formatMessage({ id: 'recentOrders' })} - Firehouse Subs`}</title>
      </Head>
      {showItems && (
        <OrdersContainer>
          {enableRecentItemsAddToCart && (
            <ResponsiveCarouselWrapper header={formatMessage({ id: 'recentItems' })}>
              <RecentItemsCarousel
                locationName="Recent Orders Page"
                containerHasTopPadding={false}
              />
            </ResponsiveCarouselWrapper>
          )}
        </OrdersContainer>
      )}
      {hasOrders && (
        <Header variant="headerOne" textAlign="center">
          {formatMessage({ id: 'recentOrders' })}
        </Header>
      )}
      {loading ? (
        <LoadingAnimation mt="$4" />
      ) : (
        <OrdersContainer>
          {hasOrders ? (
            <>
              <OfferDisclaimer>{formatMessage({ id: 'recentOrdersDisclaimer' })}</OfferDisclaimer>
              {refreshButton}
              <FlatList
                keyExtractor={historicalOrderKeyExtractor}
                data={orders}
                renderItem={renderHistoricalOrder}
                scrollEnabled={false}
              />
            </>
          ) : (
            <OrdersEmptyWrapper>
              <OrdersEmpty>{refreshButton}</OrdersEmpty>
            </OrdersEmptyWrapper>
          )}
        </OrdersContainer>
      )}
    </ScreenContent>
  );
};
