import * as React from 'react';
import { FC } from 'react';

import { Box } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';

import {
  Body,
  Header,
  ItemText,
  StyledModal,
  StyledPicture,
} from './modal-item-unavailable.styled';
import theme from './theme';
import { IModalItemUnavailableViewProps } from './types';

export const ModalItemUnavailableView: FC<
  React.PropsWithChildren<IModalItemUnavailableViewProps>
> = ({
  handleContinue,
  handleChangeStore,
  unavailableItemName,
  header,
  image,
  selectNewBtnText,
  continueBtnText,
  body,
  imageAlt,
  hideSelectNewBtn,
}) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; mParticleEventData: {... Remove this comment to see the full error message
  <StyledModal
    mParticleEventData={{
      modalAppearanceEventMessage: 'Static menu item unavailable',
    }}
    testID="item-unavailable-static-menu"
    allowsDismiss={false}
  >
    <Header>{header}</Header>
    <Body>{body}</Body>
    <StyledPicture alt={imageAlt} image={image} />
    <ItemText>{unavailableItemName}</ItemText>
    <Box flexDirection="column" margin={`${theme.buttonContainerMarginTop} 0 0`}>
      <Box width="100%" justifyContent="center" alignItems="center" margin="0 0 1rem">
        <ActionButton fullWidth onPress={handleContinue}>
          {continueBtnText}
        </ActionButton>
      </Box>
      {!hideSelectNewBtn && (
        <Box width="100%" justifyContent="center" alignItems="center" margin="0">
          <ActionButton
            fullWidth
            variant={theme.changeStoresButtonVariant}
            onPress={handleChangeStore}
          >
            {selectNewBtnText}
          </ActionButton>
        </Box>
      )}
    </Box>
  </StyledModal>
);
