import React, { createContext, useCallback, useContext } from 'react';

import { Center } from '@fhs-legacy/universal-components';
import LoadingAnimation from 'components/loading-animation';
import useErrorModal from 'hooks/use-error-modal';
import { useLoyaltyClaimPoints } from 'pages/loyalty/loyalty-claim-points/use-loyalty-claim-points';
import { mapStatusCodeToSanityContent } from 'pages/loyalty/loyalty-claim-points/utils';
import { getFirstStringInLocaleBlockContent } from 'utils/sanity';

import { ILoyaltyClaimPointsContext, ILoyaltyClaimPointsContextArgs } from './types';

const LoyaltyClaimPointsContext = createContext<ILoyaltyClaimPointsContext>(
  {} as ILoyaltyClaimPointsContext
);

export const LoyaltyClaimPointsProvider: React.FC<
  React.PropsWithChildren<ILoyaltyClaimPointsContextArgs>
> = ({ children }) => {
  const { data, loading } = useLoyaltyClaimPoints();
  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Unable to Claim Points',
  });

  const openModalWithStatus = useCallback(
    (status: string) => {
      const errorDialogContent = mapStatusCodeToSanityContent(status, data);
      openErrorDialog({
        title: errorDialogContent?.screenTitle?.locale || '',
        message: getFirstStringInLocaleBlockContent(errorDialogContent?.failureTextBlock),
        dismissText: errorDialogContent?.buttonTitle?.locale || '',
      });
    },
    [data, openErrorDialog]
  );

  return (
    <LoyaltyClaimPointsContext.Provider
      value={{
        data,
        loading,
        openModalWithStatus,
      }}
    >
      {loading ? (
        <Center height="full">
          <LoadingAnimation />
        </Center>
      ) : (
        children
      )}

      <ErrorDialog />
    </LoyaltyClaimPointsContext.Provider>
  );
};

export const useLoyaltyClaimPointsContext = () => useContext(LoyaltyClaimPointsContext);
