import { Field, FieldProps, Formik } from 'formik';
import * as React from 'react';
import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { superstruct } from 'superstruct';

import { Box, FormikSubmitButton } from '@fhs-legacy/universal-components';
import { Accordion, AccordionItem } from 'components/accordion';
import { CreateSupportCaseFeedback } from 'components/create-support-case-feedback';
import { FormikDateInput } from 'components/formik/date-input';
import { FormikCheckbox } from 'components/formik-V2/checkbox';
import { FormikMaskInput } from 'components/formik-V2/mask-input';
import { FormikSelect } from 'components/formik-V2/select';
import { FormikTextInput } from 'components/formik-V2/text-input';
import SanityBlockRenderer from 'components/sanity-block-renderer';
import { ALERT_STYLE, SupportAlertBox } from 'components/support-alert-box';
import { TermsOfServiceLabel } from 'components/user-info-form/checkbox-labels';
import { IRestaurantFragment, useGetRestaurantsQuery } from 'generated/sanity-graphql';
import { simplifiedServiceModesForSupport } from 'pages/support-v2/constants';
import { serializeServiceMode } from 'state/crm-events/utils';
import { CreateCaseState, ISupportFormData } from 'state/knowledge-force/types';
import { useScrollContext } from 'state/scroll';
import {
  getFormErrorsForStruct,
  isEmailValid,
  requiredBoolean,
  requiredString,
  requiredValidDateObject,
} from 'utils/form';
import noop from 'utils/noop';

import { StoreSelector } from '../store-selector';
import { ContentWrap, MainWrap, NeedHelpHeading, SectionHeading } from '../support-form-v2.styled';

import { IFaq, ISupportV2FormViewProps } from './types';

const getAddress = (store: IRestaurantFragment) => {
  const addressFields = ['address1', 'address2', 'city', 'state', 'country', 'zip'];
  const address = store.physicalAddress ?? {};
  return addressFields
    .map(field => address[field])
    .filter(Boolean)
    .join(', ');
};

export const SupportV2FormView: FC<React.PropsWithChildren<ISupportV2FormViewProps>> = ({
  alertMessage,
  caseReferenceNumber,
  createCaseError,
  createCaseState,
  faqs,
  handleSupportFormSubmit,
  initialValues,
  onFaqInteraction = noop,
  serviceMode,
  useHighVolumeSupportMessage,
  showRestaurantSelector,
  showServiceModeSelector,
}) => {
  const { formatMessage } = useIntl();
  const { scrollRef } = useScrollContext();

  const errorMessageByType = useMemo(
    () => ({
      requiredString: formatMessage({ id: 'requiredField' }),
      requiredBoolean: formatMessage({ id: 'requiredField' }),
      requiredValidDateObject: formatMessage({ id: 'requiredDate' }),
      optionalString: 'this should never render, but still needs to be part of the struct',
    }),
    [formatMessage]
  );

  const struct = useMemo(
    () =>
      superstruct({
        types: {
          requiredBoolean,
          requiredString,
          requiredValidDateObject,
          optionalString: (value: string) => !value || value.length > 0,
        },
      }),
    []
  );

  const valueValidationObject = useMemo(
    () =>
      struct({
        comments: 'requiredString',
        email: 'requiredString',
        issueId: 'optionalString',
        issueSubCategory: 'requiredString',
        name: 'requiredString',
        phoneNumber: 'requiredString',
        agreesToTermsOfService: 'requiredBoolean',
        serviceMode: 'optionalString',
        ...(!!showRestaurantSelector && {
          storeId: 'requiredString',
          storeAddress: 'requiredString',
          orderDateTime: 'requiredValidDateObject',
          orderTime: 'requiredString',
        }),
      }),
    [showRestaurantSelector, struct]
  );

  const getFormErrors = getFormErrorsForStruct<ISupportFormData, typeof errorMessageByType>(
    valueValidationObject,
    errorMessageByType
  );

  const [isRestaurantSelectorShowing, setIsRestaurantSelectorShowing] = useState(false);
  const { refetch: fetchRestaurants } = useGetRestaurantsQuery({ skip: true });

  const updateStore = useCallback(
    async (storeId: string, setField: (field: string, value: string) => void) => {
      const { data: stores } = await fetchRestaurants({ filter: { _id: storeId }, limit: 1 });
      const newStore = stores?.allRestaurants?.[0];
      if (newStore) {
        const newAddress = getAddress(newStore);
        setField('storeId', newStore.number ?? '');
        setField('storeAddress', newAddress);
      }
    },
    [fetchRestaurants]
  );

  const validateForm = (values: ISupportFormData) => {
    const invalidFormatEmailError: { email?: string; agreesToTermsOfService?: string } = {};
    if (!isEmailValid(values.email)) {
      invalidFormatEmailError.email = formatMessage({ id: 'notValidEmailError' });
    }
    if (!values.agreesToTermsOfService) {
      invalidFormatEmailError.agreesToTermsOfService = formatMessage({ id: 'requiredField' });
    }
    return { ...invalidFormatEmailError, ...getFormErrors(values) };
  };

  const serviceModeOptions = simplifiedServiceModesForSupport.map((mode, key) => ({
    index: key,
    value: formatMessage({ id: mode }),
    label: formatMessage({ id: mode }),
  }));
  const noServiceModeFromOrder = serviceMode === serializeServiceMode(null);

  return (
    <KeyboardAwareScrollView
      ref={ref => (scrollRef.current = ref)}
      contentContainerStyle={{ flexGrow: 1, paddingTop: 16 }}
      extraScrollHeight={200}
      keyboardShouldPersistTaps="handled"
    >
      <MainWrap>
        {alertMessage && (
          <SupportAlertBox alertStyle={ALERT_STYLE.error} testId="ineligible-for-refund">
            {alertMessage}
          </SupportAlertBox>
        )}
      </MainWrap>

      {faqs.length > 0 && (
        <>
          <SectionHeading>{formatMessage({ id: 'faq' })}</SectionHeading>
          <Accordion>
            {faqs.map((faq: IFaq, i) => (
              <AccordionItem
                activeHighlight={true}
                title={faq.title}
                key={i}
                onInteract={(isExpanded, isFirstInteraction) =>
                  onFaqInteraction(isExpanded, isFirstInteraction, faq)
                }
              >
                <SanityBlockRenderer content={faq.content as any} />
              </AccordionItem>
            ))}
          </Accordion>
        </>
      )}

      <ContentWrap>
        <NeedHelpHeading>{formatMessage({ id: 'needMoreHelpSendUsAMessage' })}</NeedHelpHeading>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSupportFormSubmit}
          validate={validateForm}
          enableReinitialize
          validateOnMount
        >
          {({ setFieldValue, values }) => {
            return (
              <MainWrap>
                <Field
                  name="name"
                  id="name"
                  component={FormikTextInput}
                  value={values.name}
                  label={formatMessage({ id: 'name' })}
                  autoFocus
                  testID="name-input"
                />
                <Field
                  name="email"
                  value={values.email}
                  component={FormikTextInput}
                  label={formatMessage({ id: 'yourEmail' })}
                  isDisabled={initialValues.email}
                  testID="email-input"
                />
                <Field
                  name="phoneNumber"
                  id="phoneNumber"
                  value={values.phoneNumber}
                  component={FormikTextInput}
                  label={formatMessage({ id: 'phoneNumber' })}
                  isDisabled={initialValues.phoneNumber}
                  testID="phoneNumber-input"
                />
                {showRestaurantSelector && (
                  <>
                    <Field name="storeAddress">
                      {(props: FieldProps) => (
                        <FormikMaskInput
                          {...props}
                          label={formatMessage({ id: 'location' })}
                          onPress={() => setIsRestaurantSelectorShowing(true)}
                          placeholder={formatMessage({ id: 'clickToSelectLocation' })}
                          value={values.storeAddress}
                        />
                      )}
                    </Field>
                    <Field name="orderDateTime">
                      {(props: FieldProps) => {
                        return (
                          <Box margin="2">
                            <FormikDateInput
                              {...props}
                              day={values.orderDateTime?.day || ''}
                              month={values.orderDateTime?.month || ''}
                              year={values.orderDateTime?.year || ''}
                              testID="order-date-time-input"
                              label={formatMessage({ id: 'orderDate' })}
                              onChange={(inputName, inputText) => {
                                setFieldValue(`orderDateTime.${inputName}`, inputText);
                              }}
                            />
                          </Box>
                        );
                      }}
                    </Field>
                    <Field
                      name="orderTime"
                      component={FormikTextInput}
                      testID="order-date-field"
                      label={formatMessage({ id: 'orderTime' })}
                      isDisabled={initialValues.orderTime}
                      value={values.orderTime}
                    />
                  </>
                )}
                {isRestaurantSelectorShowing && (
                  <StoreSelector
                    isOpen={isRestaurantSelectorShowing}
                    onDismiss={() => {
                      setIsRestaurantSelectorShowing(false);
                    }}
                    updateStore={(storeId: string) => updateStore(storeId, setFieldValue)}
                  />
                )}
                <Field
                  name="comments"
                  testID="comments-input"
                  component={FormikTextInput}
                  multiline
                  textAlignVertical="center"
                  label={formatMessage({ id: 'describeIssue' })}
                  required
                  minHeight={'74px'}
                />
                {noServiceModeFromOrder && showServiceModeSelector && (
                  <Field
                    name="serviceMode"
                    testID="service-mode-dropdown"
                    component={FormikSelect}
                    label={formatMessage({ id: 'pleaseSelectOne' })}
                    options={serviceModeOptions}
                    required
                  />
                )}
                <Field
                  component={FormikCheckbox}
                  name="agreesToTermsOfService"
                  aria-label={formatMessage({ id: 'agreeToTermsOfService' })}
                  label={<TermsOfServiceLabel />}
                  testID="faq-support-tos"
                />
                <Box>
                  <FormikSubmitButton
                    testID="submit-button"
                    isLoading={createCaseState === CreateCaseState.LOADING}
                    width="full"
                    disabled={!!caseReferenceNumber}
                  >
                    {formatMessage({ id: 'submit' })}
                  </FormikSubmitButton>
                </Box>
              </MainWrap>
            );
          }}
        </Formik>

        <CreateSupportCaseFeedback
          createCaseState={createCaseState}
          createCaseErrorMessage={createCaseError}
          useHighVolumeSupportMessage={useHighVolumeSupportMessage}
        />
      </ContentWrap>
    </KeyboardAwareScrollView>
  );
};
