import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconSodium = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.008 2.88c.43 0 .819.22 1.029.6l8 13.94c.21.37.21.82 0 1.19-.22.37-.6.6-1.029.6H3.968c-.43 0-.82-.22-1.03-.6-.219-.37-.219-.82 0-1.19L10.98 3.47c.21-.37.6-.59 1.029-.59Zm0-.77c-.66 0-1.318.33-1.698.98L2.27 17.03c-.75 1.31.19 2.94 1.697 2.94h16.041c1.509 0 2.448-1.63 1.698-2.93l-8-13.95a1.953 1.953 0 0 0-1.698-.98Zm-.02 6.03c-.15 0-.28.05-.28.12 0 .07.12.12.28.12.15 0 .28-.05.28-.12 0-.07-.13-.12-.28-.12Zm-.48-.27c.16 0 .28.06.28.12s-.13.12-.28.12c-.16 0-.28-.06-.28-.12 0-.07.13-.12.28-.12Zm-.449.27c-.15 0-.28.05-.28.12 0 .07.12.12.28.12.15 0 .28-.05.28-.12 0-.07-.13-.12-.28-.12Zm2.018.12c0 .067-.126.12-.28.12-.155 0-.28-.053-.28-.12 0-.066.126-.12.28-.12.154 0 .28.054.28.12Zm-.36-.27c0-.06-.12-.12-.28-.12-.15 0-.28.05-.28.12 0 .06.12.12.28.12.16 0 .28-.06.28-.12Zm.13 2.79c-.01-.05.02-.09.07-.1.05 0 .09.03.1.08.09.53.55 3.43.19 5.96-.01.04-.05.08-.1.07-.04-.01-.08-.05-.07-.1.36-2.51-.1-5.39-.19-5.91Zm-1.848-.1c-.05-.01-.09.02-.1.07-.09.53-.55 3.43-.19 5.96.01.05.05.08.1.07.05-.01.08-.05.07-.1-.36-2.51.1-5.39.19-5.91.01-.03-.02-.08-.07-.09Zm1.728-7.01 8 13.94c.31.55-.08 1.24-.709 1.24H3.968a.827.827 0 0 1-.72-1.24l8.04-13.94a.833.833 0 0 1 1.44 0Zm1.009 4.89c-.18-.54-.929-.94-1.818-.94s-1.638.4-1.818.94c.51.15 1.139.24 1.818.24.68 0 1.309-.09 1.818-.24Zm-.01.24c-1.868.57-3.636 0-3.636 0v.5s1.778.76 3.636 0v-.5Zm-.73 8.73 1.27-.76c.189-1.47.269-2.46.239-3.69-.05-1.98-.71-3.57-.71-3.57-1.927.77-3.765 0-3.765 0s-.649 1.6-.699 3.57c-.03 1.23.05 2.21.23 3.69l1.238.76h2.198Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
