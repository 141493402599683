import { Box, Header } from '@fhs-legacy/universal-components';
import { ArrowLink } from 'components/arrow-link';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import LoyaltyRewardsLogo from 'pages/loyalty/loyalty-rewards-logo';

export const StyledPointsIcon = LoyaltyPointsIcon.withConfig({
  marginY: 0,
  marginX: '$1',
});

export const StyledRewardsLogo = LoyaltyRewardsLogo.withConfig({
  top: '$0.5',
  justifyContent: 'center',
});

export const StyledArrowLink = ArrowLink;

export const Points = Header.withConfig({
  variant: 'headerThree',
  margin: 0,
  alignItems: 'center',
});

export const PointsContainer = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: '$2',
});

export const PointsLabel = Header.withConfig({
  color: '__legacyToken.text-default',
  variant: 'headerFour',
  margin: 0,
});
