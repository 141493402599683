import Head from 'expo-router/head';
import { useIntl } from 'react-intl';

import { InlineAlert } from '@fhs-legacy/universal-components';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { isWeb } from 'utils/environment';

import { LoyaltyHistoryWidget, LoyaltyRewardsListWidget } from '../loyalty-widgets';

export const LoyaltyDashboardView = () => {
  const { formatMessage } = useIntl();
  const enableCateringRewardsOnlyAvailableOnWebsite = useFlag(
    LaunchDarklyFlag.ENABLE_CATERING_REWARDS_ONLY_AVAILABLE_ON_WEBSITE
  );

  return (
    <>
      <Head>
        <title>{`${formatMessage({ id: 'rewards' })} - Firehouse Subs`}</title>
      </Head>
      {enableCateringRewardsOnlyAvailableOnWebsite && !isWeb && (
        <InlineAlert
          status="info"
          message={formatMessage({ id: 'cateringRewardsMessage' })}
          mx="$4"
          mt="$6"
          borderRadius={8}
        />
      )}
      <LoyaltyHistoryWidget />
      <LoyaltyRewardsListWidget />
    </>
  );
};
