import { router } from 'expo-router';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { KeyboardAvoidingView, Text } from '@fhs-legacy/universal-components';
import useAuth from 'hooks/auth/use-auth';
import useErrorModal from 'hooks/use-error-modal';
import { useOtpFeature } from 'hooks/use-otp-feature';
import useUserSendPhoneVerification from 'hooks/use-user-send-phone-verification';
import { HttpErrorCodes } from 'remote/constants';

import UserPhoneVerificationModal from '../user-phone-verification-modal';

import { BordersAndBackground, StyledLink, VerifyNowText } from './verify-phone-message.styled';

interface IVerifyPhoneMessageProps {
  redirectUrl?: string;
  showModalOnLoad?: boolean;
}

const VerifyPhoneMessage = ({ redirectUrl, showModalOnLoad = false }: IVerifyPhoneMessageProps) => {
  const { formatMessage } = useIntl();
  const { phoneVerification } = useUserSendPhoneVerification();
  const [showVerificationModal, setShowVerificationModal] = useState(showModalOnLoad);
  const [ErrorDialog, openErrDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Phone Verification Failure',
  });
  const { smsOtpEnabled } = useOtpFeature();
  const { user } = useAuth();
  const hasPhoneNumber = Boolean(user?.details?.phoneNumber?.length);

  const handleModalDismiss = useCallback(() => {
    if (redirectUrl) {
      router.navigate(redirectUrl);
    } else {
      setShowVerificationModal(false);
    }
  }, [redirectUrl]);

  const handleValidation = async (error: any) => {
    let message;
    setShowVerificationModal(false);

    switch (error?.graphQLErrors?.[0]?.extensions?.statusCode) {
      case HttpErrorCodes.TooManyRequests:
        message = `${formatMessage({ id: 'maxAttemptsReachedPhoneVerify' })} ${formatMessage({
          id: 'pleaseTryAgainLater',
        })}`;
        break;
      case HttpErrorCodes.Conflict:
        message = formatMessage({ id: 'existingUserVerifiedPhone' });
        break;
      default:
        message = formatMessage({ id: 'looksLikeWereExperiencingIssues' });
        break;
    }

    openErrDialog({
      error,
      message,
    });
  };

  const onSend = async () => {
    try {
      await phoneVerification();
      setShowVerificationModal(true);
    } catch (error) {
      await handleValidation(error);
    }
  };

  return (
    <>
      {smsOtpEnabled && hasPhoneNumber && user?.details?.phoneVerified === false && (
        <KeyboardAvoidingView>
          <BordersAndBackground testID="verify-phone-banner">
            <VerifyNowText>
              <Text>{formatMessage({ id: 'pleaseVerifyYourPhoneNumber' })}</Text>
            </VerifyNowText>
            <StyledLink testID="verify-phone-link" onPress={onSend}>
              <Text>{formatMessage({ id: 'verifyNow' })}</Text>
            </StyledLink>
          </BordersAndBackground>

          <ErrorDialog />
          {showVerificationModal && (
            <UserPhoneVerificationModal showDialog onDismiss={handleModalDismiss} />
          )}
        </KeyboardAvoidingView>
      )}
    </>
  );
};

export default VerifyPhoneMessage;
