import { Link } from 'expo-router';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Platform, StyleSheet } from 'react-native';

import { Text, YStack } from '@fhs/ui';
import { IDeliveryFee } from 'generated/graphql-gateway';
import { useUIContext } from 'state/ui';

import { BaseFee } from './base-fee';
import { DELIVERY_DETAILED_FEES, SUPPORTED_BREAKDOWN_FEES } from './constants';
import { LongDistanceFee } from './long-distance-fee';

type DeliveryFeeModalProps = {
  deliveryTotalFee?: number;
  deliveryFees: IDeliveryFee[];
  onTermsRedirect: () => void;
};

export const DeliveryFeeDialogContent = ({
  deliveryFees,
  onTermsRedirect,
}: DeliveryFeeModalProps) => {
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale: currencyFormatter } = useUIContext();

  const breakdownFees = deliveryFees?.filter(fee => SUPPORTED_BREAKDOWN_FEES.includes(fee.name));

  const handleRedirectCallback = () => {
    // For iOS, we don't want to close the modal when redirecting to terms page
    // because the full screen modal navigation behavior is different
    if (Platform.OS === 'ios') {
      return;
    }

    onTermsRedirect();
  };

  const { baseFee, longDistance } = useMemo(
    () => ({
      baseFee: breakdownFees.find(fee => fee.name === DELIVERY_DETAILED_FEES.BASE_FEE),
      longDistance: breakdownFees.find(
        fee => fee.name === DELIVERY_DETAILED_FEES.LONG_DISTANCE_FEE
      ),
    }),
    [breakdownFees]
  );

  return (
    <YStack>
      <BaseFee
        style={styles.feeSection}
        formattedFee={currencyFormatter(baseFee?.totalValueCents || 0)}
      />
      {!!longDistance?.totalValueCents && (
        <LongDistanceFee
          style={styles.feeSection}
          isDynamic
          formattedFee={currencyFormatter(longDistance.totalValueCents)}
        />
      )}
      <Text.Paragraph size="sm" style={styles.textItem}>
        {formatMessage(
          { id: 'deliveryFeeFullTermsLink' },
          {
            link: (
              <Link
                href={formatMessage({ id: 'routes.deliveryTerms' })}
                onPress={handleRedirectCallback}
                style={styles.link}
              >
                {formatMessage({ id: 'clickHere' })}
              </Link>
            ),
          }
        )}
      </Text.Paragraph>
    </YStack>
  );
};

const styles = StyleSheet.create({
  feeBreakdown: {
    marginVertical: 18,
  },
  textItem: {
    lineHeight: 24,
  },
  link: {
    textDecorationLine: 'underline',
  },
  feeSection: {
    marginBottom: 12,
  },
});
