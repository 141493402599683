import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconMenu = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M2 18.434h20v-2.222H2v2.222Zm0-5.556h20v-2.222H2v2.222Zm0-7.777v2.222h20V5.1H2Z"
      />
    </Svg>
  );
};
