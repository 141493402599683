import { IOffer } from '@rbi-ctg/menu';
import { ServiceMode } from 'state/service-mode/types';
import { ServiceModeCategory, determineServiceModeCategory } from 'utils/service-mode';

import {
  getAllowedServiceModes,
  offerIsAvailableForCatering,
  offerIsAvailableForDelivery,
  offerIsAvailableForPickup,
} from './rule-sets';

/**
 * @name checkOfferIsAvailableForServiceModeCategory
 * @description finds all allowed service modes for
 *  a given offer and returns whether or not that
 *  offer is available for the given service mode
 *  category.
 */
export const checkOfferIsAvailableForServiceModeCategory = (
  offer: IOffer,
  selectedServiceModeCategory: ServiceModeCategory
) => {
  const allowedServiceModesForOffer = getAllowedServiceModes(offer.ruleSet);
  // determine if offer is available for selected service mode
  let isAvailableForSelectedServiceMode = true;
  if (
    selectedServiceModeCategory === ServiceModeCategory.CATERING &&
    !offerIsAvailableForCatering(offer, allowedServiceModesForOffer)
  ) {
    isAvailableForSelectedServiceMode = false;
  }
  if (
    selectedServiceModeCategory === ServiceModeCategory.DELIVERY &&
    !offerIsAvailableForDelivery(offer, allowedServiceModesForOffer)
  ) {
    isAvailableForSelectedServiceMode = false;
  }
  if (
    selectedServiceModeCategory === ServiceModeCategory.PICKUP &&
    !offerIsAvailableForPickup(offer, allowedServiceModesForOffer)
  ) {
    isAvailableForSelectedServiceMode = false;
  }
  return isAvailableForSelectedServiceMode;
};

/**
 * @name sortOffersForSelectedServiceMode
 * @description if we have a selected service mode,
 *  push offers NOT available for that service mode
 *  to the bottom of the list or remove them from the
 *  final result when filterUnavalilableOffers is set.
 *
 * NOTE: this is explicitly different / intentional
 *  rather than pushing offers available to the top
 *  because we want to preserve the Sanity order as
 *  much as possible, while not presenting unavailable
 *  offers to the user.
 *  @example some offers are available for BOTH pickup
 *    and delivery. By pushing delivery only offers to
 *    the bottom for pickup service mode we avoid affecting
 *    the order of these offers available for both SMs.
 *
 * NOTE: Added a new optional boolean parameter 'filterUnavalilableOffers'
 * to remove unavailble offers from the list when set to true.
 */

export const sortOffersForSelectedServiceMode = (
  offers: IOffer[],
  selectedServiceMode?: ServiceMode,
  removeUnavailableOffers: boolean = false
): IOffer[] => {
  // if we don't have a selected service mode,
  // just return offers in their current order
  if (!selectedServiceMode || !offers.length) {
    return offers;
  }

  // get category for selected service mode
  const selectedServiceModeCategory = determineServiceModeCategory(selectedServiceMode);

  // sort offers into available and unavailable lists
  // preserving current order within each list
  const sortedOffers = offers.reduce(
    (acc, cur) => {
      const isAvailableForSelectedServiceMode = checkOfferIsAvailableForServiceModeCategory(
        cur,
        selectedServiceModeCategory
      );
      if (isAvailableForSelectedServiceMode) {
        acc.available.push(cur);
      } else {
        acc.unavailable.push(cur);
      }
      return acc;
    },
    { available: [] as IOffer[], unavailable: [] as IOffer[] }
  );

  // return only available offers
  if (removeUnavailableOffers) {
    return sortedOffers.available;
  }

  // merge the unavailable list to the end of the available list
  return sortedOffers.available.concat(sortedOffers.unavailable);
};
