import { Button, HStack, Text } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

export const EditButton = Button.withConfig({
  variant: 'ghost',
  padding: 0,
  _web: {
    cursor: 'pointer',
  },
});

export const ButtonContent = HStack.withConfig({
  alignItems: 'center',
  justifyContent: 'flex-end',
});

export const Label = Text.withConfig({
  variant: 'copyTwo',
  marginRight: '$3',
  numberOfLines: 1,
  color: theme.token('icon-default'),
  fontSize: 'xs',
});
