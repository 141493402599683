import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconCheck = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path fill="currentColor" d="M9 16.27 4.83 12.1l-1.42 1.41L9 19.1l12-12-1.41-1.41L9 16.27Z" />
    </Svg>
  );
};
