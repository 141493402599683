import React from 'react';

import { MENU_PRODUCT_PAGE } from 'utils/test-ids';

import { ProductLayout } from './product-layout/product-layout';
import { LayoutContainer } from './styled';

const ProductDetail: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <LayoutContainer testID={MENU_PRODUCT_PAGE}>
      <ProductLayout />
    </LayoutContainer>
  );
};

export default ProductDetail;
