import { ISection } from '@rbi-ctg/menu';
import useIsMenuDataAvailableByDayPart from 'hooks/use-is-menu-data-available-by-day-part';
import { useDayPartContext } from 'state/day-part';

export interface IMenuByDayPartProps {
  data: Partial<ISection> | null;
  loading?: boolean;
}

const useMenuByDayPart = (menuObject: IMenuByDayPartProps): IMenuByDayPartProps => {
  const isMenuDataAvailableByDayPart = useIsMenuDataAvailableByDayPart();
  const { dayParts } = useDayPartContext();

  if (!menuObject?.data?.options?.length || !dayParts?.length) {
    return menuObject;
  }

  const filteredOptionsByDayPart = menuObject.data.options.filter(item =>
    isMenuDataAvailableByDayPart(item)
  );

  return {
    ...menuObject,
    data: { ...menuObject.data, options: filteredOptionsByDayPart },
  };
};

export default useMenuByDayPart;
