import { useCallback } from 'react';
import type { SectionListScrollParams } from 'react-native';

import { useAnchorSectionList } from './context';

export function useScrollToSection() {
  const context = useAnchorSectionList();

  return useCallback(
    (
      sectionId: string,
      opts?: Pick<SectionListScrollParams, 'animated' | 'viewOffset' | 'viewPosition'>
    ) => {
      const sectionIndex = context.flashListData.findIndex(
        datum => datum.type === 'section' && datum.section.id === sectionId
      );

      if (sectionIndex < 0) {
        return;
      }

      context.flashListRef.current?.scrollToIndex({ ...opts, index: sectionIndex });
    },
    [context.flashListData, context.flashListRef]
  );
}
