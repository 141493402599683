import React from 'react';

import { Svg, SvgPath, SvgProps } from '@fhs-legacy/universal-components';

export const FhsStoreSvg = (props: SvgProps) => (
  <Svg {...props} viewBox="0 0 84 82" width={84} height={82}>
    <SvgPath
      d="M5.10469 19.0285L0 41V46.3787H4.4982V82H52.5126V46.3787H72.9314V82H78.8953V46.3787H84V41L78.8953 19.0285H5.10469ZM45.9422 76.6213H9.8556V46.3787H45.9422V76.6213ZM6.77458 41L9.8374 25.625H74.7691L77.8319 41H6.77458ZM83.1408 2.99381C83.1408 4.64725 81.8004 5.98762 80.147 5.98762H4.45952C2.80608 5.98762 1.4657 4.64725 1.4657 2.99381C1.4657 1.34038 2.80608 0 4.45952 0H80.147C81.8004 0 83.1408 1.34038 83.1408 2.99381Z"
      fill="#000"
    />
  </Svg>
);
