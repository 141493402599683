import { StyleSheet, View, type ViewProps } from 'react-native';

export type FlexProps = ViewProps;

export function Flex(props: FlexProps) {
  return <View {...props} style={[styles.flex, props.style]} />;
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
});
