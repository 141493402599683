import { useMemo } from 'react';

import { Quantity, type QuantityProps } from './quantity';

export type QuantityCounterProps = Omit<QuantityProps<number>, 'options'> & {
  maxValue: number;
  minValue: number;
};

export const QuantityCounter = (props: QuantityCounterProps) => {
  const { maxValue, minValue, ...rest } = props;

  if (__DEV__ && maxValue < minValue) {
    throw new Error('maxValue prop must be greater than or equal to minValue prop');
  }

  const options = useMemo(() => {
    return Array.from({ length: maxValue - minValue + 1 }, (_val, index) => {
      const val = index + minValue;
      return { label: val.toString(), value: val };
    });
  }, [maxValue, minValue]);

  return <Quantity {...rest} options={options} />;
};
