import { IntlFormatters } from 'react-intl';

import { SupportedLanguages } from '@rbi-ctg/frontend';
import { IDatePickerData, ITimePickerData } from 'hooks/use-date-picker-options/types';
import {
  DIFFERENCE_TIME_UNITS,
  formatLocalizedCurrentWeekDay,
  formatTime,
  getDifferenceToNow,
  localizedShortMonthDayFormatter,
} from 'utils/dateTime';

import { ISelectedDate } from './types';

export const calculateFireOrderInSeconds = (dateParam: ISelectedDate) => {
  const formattedDate = new Date(
    dateParam.year,
    dateParam.month,
    dateParam.day,
    dateParam.hour,
    dateParam.minutes
  );

  return getDifferenceToNow(DIFFERENCE_TIME_UNITS.SECONDS, formattedDate);
};

export const formatAvailableDates = ({
  availableDates,
  language,
  formatMessage,
}: {
  availableDates: IDatePickerData[];
  language: SupportedLanguages;
  formatMessage: IntlFormatters['formatMessage'];
}) => {
  const formattedAvailableDates = availableDates.map(({ date, isDisabled }) => {
    const [year, month, day] = date.split('-');
    const currentDate = new Date(Number(year), Number(month) - 1, Number(day)) as Date;

    return {
      day: formatLocalizedCurrentWeekDay({
        dateTime: currentDate,
        language,
        formatMessage,
      }),
      date: localizedShortMonthDayFormatter(currentDate, language),
      value: date,
      isDisabled,
    };
  });

  return formattedAvailableDates;
};

export const formatAvailableTimes = ({
  availableTimes,
  language,
}: {
  availableTimes: ITimePickerData[] | undefined;
  language: SupportedLanguages;
}) => {
  return availableTimes?.map(item => ({
    accessibilityLabel: formatTime({ dateTime: item.time, language }),
    time: formatTime({ dateTime: item.time, language }),
    value: item.time.toString(),
  }));
};
