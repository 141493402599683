import {
  ApolloLink,
  gql as gqlFunc /* do not change this `as` as it makes it so the babel-plugin-graphql-tag transform works properly */,
} from '@apollo/client';
import { print } from 'graphql';

import { LANGUAGES } from 'state/intl/types';

import { Header } from '../headers';

const DEPRECATED_LANGUAGE_INTERPOLATION_REGEX = /_LANG_/g;

const LANGUAGE_INTERPOLATION_REGEX = /locale(Raw)?: en(Raw)?/g;

export const withLocalizedQueries = () =>
  new ApolloLink((operation, next) => {
    const context = operation.getContext();
    const language = context.headers[Header.LANGUAGE] || LANGUAGES.en;

    if (operation.query) {
      const rawQuery = print(operation.query);

      const replacedRawQuery = rawQuery
        .replace(DEPRECATED_LANGUAGE_INTERPOLATION_REGEX, language) // TODO migrate queries to the new regex below
        .replace(LANGUAGE_INTERPOLATION_REGEX, `locale$1: ${language}$2`);

      if (rawQuery === replacedRawQuery) {
        // nothing changed - skip recreating gql DocumentNode if not needed since its CPU expensive
        return next(operation);
      }

      const interpolated = gqlFunc(replacedRawQuery);
      operation.query = interpolated;

      return next(operation);
    }

    return next(operation);
  });
