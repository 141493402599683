import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Pressable } from 'react-native';

import { Box, HStack, VStack } from '@fhs-legacy/universal-components';
import { IncentiveHead } from 'components/cart-item/incentive-head';
import FavoriteEntryButton from 'components/favorite-entry-button';
import { useCartItemEditingTools } from 'hooks/use-cart-item-editing-tools';
import { useComposeDescription } from 'hooks/use-compose-description';
import useFoodware from 'hooks/use-foodware';
import { useIsEditCartEnabled } from 'hooks/use-is-edit-cart-enabled';
import { selectors, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useGetAvailableRewards } from 'state/loyalty/hooks/use-get-available-rewards';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useOrderContext } from 'state/order';

import CartItemButton from './cart-item-button';
import CartItemEditContainer from './cart-item-edit-container';
import CartItemInfoContainer from './cart-item-info-container';
import CartItemName from './cart-item-name';
import CartItemPrice from './cart-item-price';
import CartItemQuantity from './cart-item-quantity';
import Counter from './cart-item-quantity/counter';
import { PriceAndQuantity } from './price-and-quantity';
import { RedeemReward } from './redeem-reward';
import {
  CartItemDescription,
  CartItemEditButtonContainer,
  Item,
  SeeMoreDescription,
} from './styled';
import { ICartItemProps } from './types';

const CartItem: React.FC<React.PropsWithChildren<ICartItemProps>> = ({
  item,
  readOnly = false,
  rewardApplied = false,
  showReadOnlyQuantity = false,
  selectedOfferId,
}) => {
  const hasSelectedOffer = !!selectedOfferId;
  const appliedLoyaltyOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const selectedOfferSelections = useAppSelector(selectors.loyalty.selectSelectedOfferSelections);

  const favoriteEnabled = useFlag(LaunchDarklyFlag.ENABLE_FAVORITES);
  const isFHSLoyaltyUpdateEnabled = useFlag(LaunchDarklyFlag.ENABLE_FHS_LOYALTY_UPDATE);

  const [isModifierMapClosed, setIsModifierMapClosed] = useState(true);

  const dailyRewardTransactionCount = useAppSelector(
    selectors.loyalty.selectDailyRewardTransactionCount
  );

  const maximumDailyRewardRedemptionLimit = useAppSelector(
    selectors.loyalty.selectMaxDailyRewardRedemptionLimit
  );

  const hasReachedMaxRedemptionLimit =
    maximumDailyRewardRedemptionLimit <= dailyRewardTransactionCount;

  const { formatMessage } = useIntl();
  // TODO: check if we need to evaluate LoyaltyOffer as cartHasIncentive
  const isEditCartEnabled = useIsEditCartEnabled({
    cartHasIncentive: hasSelectedOffer || rewardApplied,
  });
  const { handleEditItem, handleRemoveItem, handleQuantityChange } = useCartItemEditingTools({
    item,
  });

  const description = useComposeDescription(item, {
    includeQuantity: showReadOnlyQuantity,
  });
  const itemKey = useMemo(() => item.cartId || item._id, [item._id, item.cartId]);
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { getAvailableRewardFromCartEntry } = useGetAvailableRewards();
  const { isCartEntryInSwaps } = useOrderContext();
  const loyaltyRewardAvailable = getAvailableRewardFromCartEntry(item);
  const { isFoodware } = useFoodware();

  if (item.isDonation || isFoodware(item)) {
    return null;
  }

  const isSwap = isCartEntryInSwaps(item);
  const loyaltyOffer = appliedOffers.find(
    lylOffer => lylOffer.cartId === item.cartId || lylOffer.swap?.cartId === item.cartId
  );
  const isASelectionFromOffer = selectedOfferSelections?.find(
    selection => selection.lineId === item.cartId
  );

  const isExtra = item.isExtra;
  const isItemEditableInline =
    !hasSelectedOffer && !isSwap && !isExtra && !loyaltyOffer && !isASelectionFromOffer;
  const isSelectedOffer = hasSelectedOffer && selectedOfferId === item.sanityId;
  const hasOffer = isSelectedOffer || loyaltyOffer;
  const showFavorite = favoriteEnabled && !hasOffer && !isSwap && !isExtra;
  const allowAddRewardWithoutOffer =
    !isFHSLoyaltyUpdateEnabled || appliedLoyaltyOffers.length === 0;

  /**
   * The whole order discounts should not show in the list of cart items.
   * The details for this is explicitly shown outside of the cart item list.
   * The offer must be applied to the cart items, so we're simply removing it
   * visually from the list here.
   */
  if (loyaltyOffer?.cartId === 'discount-offer') {
    return null;
  }

  return (
    <Item testID="cart-item">
      <IncentiveHead
        loyaltyOfferId={loyaltyOffer?.id}
        loyaltyOfferCmsId={loyaltyOffer?.cmsId}
        cartId={item.cartId}
        rewardApplied={rewardApplied}
      />
      <Box>
        <CartItemInfoContainer
          accessibilityLabel={`cart-section-heading-label item-quantity-${item.quantity} cart-item-${itemKey} item-price-${itemKey} cart-price-${itemKey}`}
        >
          <Box testID={`cart-item-${itemKey}`}>
            <HStack justifyContent="space-between" alignItems="center">
              <CartItemName testID="cart-item-label" flexShrink={1}>
                {item.name}
              </CartItemName>
              {showFavorite && <FavoriteEntryButton cartEntry={item} />}
            </HStack>
            {description?.length > 0 && (
              <VStack testID="cart-item-description" marginTop={1}>
                {_resizeArray(description, isModifierMapClosed).map((descriptionItem, index) => (
                  <CartItemDescription key={`descriptionItem-${index}`}>
                    {descriptionItem}
                  </CartItemDescription>
                ))}
                {description.length > 3 && (
                  <Pressable onPress={() => setIsModifierMapClosed(s => !s)}>
                    <SeeMoreDescription>
                      See {isModifierMapClosed ? 'More' : 'Less'}
                    </SeeMoreDescription>
                  </Pressable>
                )}
              </VStack>
            )}
          </Box>

          {!favoriteEnabled && (
            <PriceAndQuantity
              item={item}
              isOffer={hasSelectedOffer}
              rewardApplied={rewardApplied}
            />
          )}
          {!hasReachedMaxRedemptionLimit &&
            allowAddRewardWithoutOffer &&
            loyaltyEnabled &&
            loyaltyRewardAvailable &&
            isItemEditableInline && (
              <RedeemReward
                isRewardApplied={rewardApplied}
                item={item}
                reward={loyaltyRewardAvailable}
              />
            )}
          {favoriteEnabled && (
            <CartItemPrice>
              <CartItemName variant="headerTwo" testID="cart-price-desc">
                {formatMessage({ id: 'itemTotal' })}
              </CartItemName>
              <PriceAndQuantity
                item={item}
                isOffer={hasSelectedOffer}
                rewardApplied={rewardApplied}
              />
            </CartItemPrice>
          )}
        </CartItemInfoContainer>
        {!readOnly && (
          <CartItemEditContainer>
            <CartItemEditButtonContainer>
              {isEditCartEnabled && !loyaltyOffer && !isSwap && (
                <CartItemButton
                  accessibilityLabel={`${formatMessage({ id: 'edit' })} ${item.name} `}
                  testID="edit-button"
                  onPress={handleEditItem}
                >
                  {formatMessage({ id: 'edit' })}
                </CartItemButton>
              )}
              <CartItemButton
                accessibilityLabel={`${formatMessage({ id: 'remove' })} ${item.name} `}
                testID="remove-button"
                onPress={handleRemoveItem}
              >
                {formatMessage({ id: 'remove' })}
              </CartItemButton>
            </CartItemEditButtonContainer>

            <CartItemEditButtonContainer marginRight={3}>
              {isItemEditableInline && (
                <CartItemQuantity item={item} updateQuantity={handleQuantityChange} />
              )}
              {isExtra && <Counter>{`${item.quantity}x`}</Counter>}
            </CartItemEditButtonContainer>
          </CartItemEditContainer>
        )}
      </Box>
    </Item>
  );
};

export default React.memo(CartItem);

function _resizeArray<T>(arry: Array<T>, shouldResize: boolean): Array<T> {
  if (!shouldResize) {
    return arry;
  }
  const copy = Array.from(arry);
  copy.length = 3;
  return copy;
}
