import {
  AuthIdentifiers,
  AuthOptInType,
  AuthV2ChallengeType,
  useCreateOtpv2Mutation,
  useSignUpV2Mutation,
  useValidateOtpV2Mutation,
} from '@fhs-legacy/frontend/src/generated/graphql-gateway';

export type CreateOtpInput = {
  loginIdentifier: string;
  identifierType: AuthIdentifiers;
};

export type ValidatedIdentifiers = {
  phoneNumber?: string;
  emailAddress?: string;
};

export type IdentifyAuthChoice = {
  redactedName: string;
  redactedEmail: string;
};

export type IdentifyAuthJwt = {
  cognitoId: string;
  loginIdentifier: string;
  redactedIdentity: IdentifyAuthChoice;
  jwt: string;
};

export type LoginJwt = {
  identifierType: AuthIdentifiers;
  loginIdentifier: string;
  deviceId: string;
  validatedIdentifiers: ValidatedIdentifiers;
};

export {
  AuthIdentifiers,
  AuthOptInType,
  AuthV2ChallengeType,
  useCreateOtpv2Mutation,
  useSignUpV2Mutation,
  useValidateOtpV2Mutation,
};
