import { Path, Rect, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconPaymentTypeDinersClub = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Rect width={24} height={16} y={4.101} fill="#fff" rx={2} />
      <Rect width={23} height={15} x={0.5} y={4.601} stroke="#000" strokeOpacity={0.1} rx={1.5} />
      <Path
        fill="#145095"
        fillRule="evenodd"
        d="M10.972 16.1h1.454c2.268 0 4.076-1.773 4.147-3.964V12.1a4.078 4.078 0 0 0-4.147-4h-1.63a3.917 3.917 0 0 0-3.794 4.035c0 2.156 1.773 3.93 3.97 3.965Zm-3.261-4c0-1.808 1.524-3.303 3.367-3.303 1.844 0 3.368 1.495 3.368 3.304 0 1.808-1.524 3.304-3.367 3.304-1.844 0-3.368-1.496-3.368-3.304Zm3.9 2.227V9.91c1.24.313 2.02 1.53 1.7 2.747a2.287 2.287 0 0 1-1.7 1.67Zm-2.73-2.783c-.32 1.218.425 2.47 1.666 2.783V9.91a2.31 2.31 0 0 0-1.666 1.634Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
