import React from 'react';
import { useIntl } from 'react-intl';

import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

import { UnauthenticatedEmptyStateImage } from './images';
import {
  EmptyStateWrapper,
  HeaderText,
  ImageWrapper,
  StyledActionButtonLink,
  SubText,
} from './styled';

const NoRecentOrdersEmptyState = () => {
  const { formatMessage } = useIntl();
  const { isStoreOpenAndAvailable } = useStoreContext();

  return (
    <EmptyStateWrapper>
      <ImageWrapper>
        <UnauthenticatedEmptyStateImage />
      </ImageWrapper>
      <HeaderText testID="text-no-recent-items">
        {formatMessage({ id: 'noRecentItemsHeader' })}
      </HeaderText>
      <SubText>{formatMessage({ id: 'noRecentItemsSubheader' })}</SubText>
      <StyledActionButtonLink
        to={isStoreOpenAndAvailable ? `${routes.menu}` : `${routes.storeLocator}`}
        replace
        style={{ marginTop: '10%' }}
      >
        {formatMessage({ id: 'orderNow' })}
      </StyledActionButtonLink>
    </EmptyStateWrapper>
  );
};

export default NoRecentOrdersEmptyState;
