import { G, Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconRewardsFill = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <G fill="currentColor">
        <Path
          fillRule="evenodd"
          d="M4 11.105V7.917c.228-.725.705-.886 1.316-.878 1.063.014 2.125.011 3.187.008l1.293-.002h.165a.354.354 0 0 0-.133-.094c-.065-.038-.133-.072-.2-.107-.161-.082-.32-.164-.45-.282-.511-.462-.68-1.057-.516-1.72.163-.662.596-1.08 1.246-1.276l.202-.057.034-.009h.465c.018.011.037.02.058.027.556.101.994.38 1.235.898.144.309.264.628.384.948l.148.388c.013.033.024.068.038.114l.028.089a10.415 10.415 0 0 0 .068-.204c.045-.118.09-.237.133-.356.108-.29.215-.58.335-.863.159-.379.419-.678.8-.848.123-.055.253-.096.384-.137.058-.018.116-.036.174-.056h.465a.627.627 0 0 0 .089.031 1.775 1.775 0 0 1 1.258 2.54c-.22.439-.591.689-1.021.876a2.468 2.468 0 0 0-.102.048l-.045.022.014.028h4.814c.581 0 .94.272 1.107.831a.167.167 0 0 0 .027.04v3.189a.782.782 0 0 0-.03.076c-.152.51-.506.796-1.023.819H13V7.046h-1V12H5.001a1.03 1.03 0 0 1-.877-.574 2.608 2.608 0 0 1-.124-.32Zm9.113-4.223c.084-.02.159-.037.23-.063.172-.06.345-.117.519-.175.434-.144.868-.288 1.283-.476.58-.262.722-1.032.326-1.542-.476-.616-1.43-.547-1.76.158-.17.36-.297.738-.424 1.116l-.12.356c-.05.137-.087.28-.126.433l-.06.225.132-.032Zm-1.126.028a.391.391 0 0 1 .01-.028c.004-.01.007-.017.005-.022-.186-.722-.38-1.442-.72-2.11-.21-.409-.63-.6-1.1-.521a1.065 1.065 0 0 0-.859.895c-.067.429.142.873.547 1.058.395.18.808.326 1.219.469.214.074.434.133.654.192l.244.067Z"
          clipRule="evenodd"
        />
        <Path d="M12 13H5.42v5.654c.001.851.458 1.508 1.22 1.762.069.022.138.04.207.059l.109.03h11.089l.018-.005c.085-.023.167-.044.25-.068.715-.205 1.25-.868 1.26-1.617.011-.9.01-1.801.008-2.702V13H13v7.5h-1V13Z" />
      </G>
    </Svg>
  );
};
