import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconDineIn = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M11 9.1H9v-7H7v7H5v-7H3v7c0 2.12 1.66 3.84 3.75 3.97v9.03h2.5v-9.03C11.34 12.94 13 11.22 13 9.1v-7h-2v7Zm5-3v8h2.5v8H21v-20c-2.76 0-5 2.24-5 4Z"
      />
    </Svg>
  );
};
