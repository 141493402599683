import {
  ClickEventComponentNames,
  CustomEventNames,
  EventTypes,
  useCRMEventsContext,
} from 'state/crm-events';

export const useClickLogs = () => {
  const { logRBIEvent } = useCRMEventsContext();

  const logSimpleClickEvent = (text: string, component: ClickEventComponentNames) => {
    logRBIEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Navigation,
      attributes: { text, component },
    });
  };

  const logOrderNowClickEvent = () =>
    logSimpleClickEvent('Order Now Button', ClickEventComponentNames.RAPID_RESCUE_CARD);

  const logPickUpOrderClickEvent = () =>
    logSimpleClickEvent('Pick Up Order Button', ClickEventComponentNames.BUTTON);

  const logDeliveryOrderClickEvent = () =>
    logSimpleClickEvent('Delivery Order Button', ClickEventComponentNames.BUTTON);

  return {
    logOrderNowClickEvent,
    logPickUpOrderClickEvent,
    logDeliveryOrderClickEvent,
  };
};
