import { HStack, Icon, Text } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

export const Wrapper = HStack.withConfig({
  alignItems: 'center',
  marginBottom: '$1',
});

export const Message = Text.withConfig({
  marginLeft: '1.5',
  textTransform: 'capitalize',
  fontSize: 'xs',
  color: theme.token('text-default'),
  letterSpacing: 'sm',
});

export const MessageIcon = Icon.withConfig({
  color: theme.token('text-default'),
});
