import {
  Box,
  HStack,
  Header,
  Text,
  VStack,
  makeUclComponent,
} from '@fhs-legacy/universal-components';
import Picture from 'components/picture';

export const Section = Box.withConfig({
  marginX: '$6',
  width: '90%',

  paddingY: {
    desktop: '$10',
    base: '$6',
  },

  paddingX: '$0',
});

export const CardTitle = Header.withConfig({
  variant: 'headerTwo',
  margin: '$0',
  padding: '$0',
  fontWeight: 'bold',
});

export const CardContent = VStack.withConfig({
  width: {
    base: '70%',
    desktop: '100%',
  },

  paddingY: {
    desktop: '$6',
    base: '$0',
  },

  paddingRight: {
    base: '$6',
    desktop: '$4',
  },

  paddingLeft: {
    desktop: '$4',
    base: '$0',
  },
});

export const Card = HStack.withConfig({
  color: Styles.color.black,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 'sm',
  borderBottomLeftRadius: 'sm',
  padding: '$0',
  flexDirection: 'row-reverse',
  background: {
    desktop: Styles.color.grey.five,
  },
  marginBottom: '$13.5',
});

export const CardImageContainer = Box.withConfig({
  width: {
    desktop: '100%',
    base: '30%',
  },

  justifyContent: 'center',

  paddingBottom: {
    desktop: '75%',
    base: '$0',
  },

  borderTopRadius: 'sm',

  borderBottomRadius: {
    desktop: 0,
    base: 'sm',
  },
});

export const CardImage = makeUclComponent(Picture).withConfig({
  marginBottom: '$4',
});

export const CardBody = Text.withConfig({
  marginY: '$2',
  marginX: '$0',
});
