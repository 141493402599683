export interface IReplaceIncentiveModal {
  onDismiss: () => void;
  onConfirm: (data: any) => void;
  isOpen: boolean;
  incentiveType: IncentiveType;
}

export const enum IncentiveType {
  REWARD = 'REWARD',
  OFFER = 'OFFER',
  INCENTIVE = 'INCENTIVE',
}
