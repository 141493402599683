import React from 'react';
import { useIntl } from 'react-intl';

import { Text } from '@fhs-legacy/universal-components';
import { IBaseProps } from '@rbi-ctg/frontend';
import { AllergenIncludes } from 'enums/menu';

import { AllergenGroup } from './types';

const AllergensDisclaimerContainer = Text.withConfig({
  variant: 'copyTwo',
  marginBottom: '$4',
  fontSize: 'xs',
});

const AllergenNames = Text.withConfig({
  variant: 'copyTwo',
  fontWeight: 'bold',
});

// List of allergen disclaimer message IDs. With 3 allergen group types, we have
// 2^3 possible combinations:
// [{}, {1}, {2}, {3}, {1, 2}, {1, 3}, {2, 3}, {1, 2, 3}]
type DisclaimerId =
  | 'allergensDisclaimerUnknown' // 000
  | 'allergensDisclaimerContains' // 001
  | 'allergensDisclaimerMayContain' // 010
  | 'allergensDisclaimerMayBeCookedOn' // 100
  | 'allergensDisclaimerMayContainAndMayBeCookedOn' // 110
  | 'allergensDisclaimerContainsAndMayBeCookedOn' // 101
  | 'allergensDisclaimerContainsAndMayContain' // 011
  | 'allergensDisclaimerContainsAndMayContainAndMayBeCooked'; // 111

// Set of possible parameter IDs to be leveraged in localizable allergen messages.
type AllergenParameters = 'allergens' | 'maybeAllergens' | 'maybeCookedOnAllergens';

// Mapping from an allergen group to the parameter IDs used in localizable strings.
const ALLERGEN_GROUP_TYPE_TO_PARAM_ID: {
  [k in AllergenIncludes]: AllergenParameters | null;
} = {
  [AllergenIncludes.FreeFrom]: null,
  [AllergenIncludes.Contains]: 'allergens',
  [AllergenIncludes.MayContain]: 'maybeAllergens',
  [AllergenIncludes.CookedInSameEquipment]: 'maybeCookedOnAllergens',
};

// Mapping from a combination of allergen groups to the message ID to be used for
// localizable strings.
const COMBINATION_TO_DISCLAIMER_ID: { [k: string]: DisclaimerId } = {
  '000': 'allergensDisclaimerUnknown',
  '001': 'allergensDisclaimerContains',
  '010': 'allergensDisclaimerMayContain',
  '100': 'allergensDisclaimerMayBeCookedOn',
  '110': 'allergensDisclaimerMayContainAndMayBeCookedOn',
  '101': 'allergensDisclaimerContainsAndMayBeCookedOn',
  '011': 'allergensDisclaimerContainsAndMayContain',
  '111': 'allergensDisclaimerContainsAndMayContainAndMayBeCooked',
};

interface IAllergensDisclaimerProps extends IBaseProps {
  allergenGroups?: AllergenGroup[];
}

export const AllergensDisclaimer: React.FC<React.PropsWithChildren<IAllergensDisclaimerProps>> = ({
  allergenGroups = [],
}) => {
  const { formatMessage } = useIntl();
  if (!allergenGroups.length) {
    return null;
  }

  // Build parameters object while determining the string combination to use
  const parameters: Partial<{ [key in AllergenParameters]: React.ReactNode }> = {};
  const combination = [0, 0, 0];
  for (const allergenGroup of allergenGroups) {
    const parameterId = ALLERGEN_GROUP_TYPE_TO_PARAM_ID[allergenGroup.allergenIncludes];
    if (parameterId) {
      const idx = allergenGroup.allergenIncludes - 1;
      combination[idx] = 1;
      parameters[parameterId] = (
        <AllergenNames>{allergenGroup.allergenNames.join(', ')}</AllergenNames>
      );
    }
  }

  // Retrieve string ID from combination of allergen groups.
  const combinationId = combination.join('');
  const id = COMBINATION_TO_DISCLAIMER_ID[combinationId] || 'allergensDisclaimerUnknown';

  return (
    <AllergensDisclaimerContainer testID="allergens-disclaimer">
      {formatMessage({ id }, parameters)}
    </AllergensDisclaimerContainer>
  );
};
