import React from 'react';

import { useBreakpointValue } from '@fhs-legacy/universal-components';
import { FhsProductBgMobileSvg, FhsProductBgWebSvg } from 'components/svgs/fhs-product-bg';

import { BackgroundImageColor } from './product-overview.styled';

export const ProductBackground = () => {
  const FhsProductBgSvg = useBreakpointValue({
    base: FhsProductBgMobileSvg,
    desktop: FhsProductBgWebSvg,
  });
  return (
    <BackgroundImageColor>
      <FhsProductBgSvg />
    </BackgroundImageColor>
  );
};
