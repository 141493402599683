import React from 'react';

import { useMqSelect } from '@fhs/ui';
import { makeUclComponent } from '@fhs-legacy/universal-components';
import LoadingAnimation from 'components/loading-animation';

import { LoyaltyLoaderContainer } from './loyalty-loader.styled';

const LoyaltyLoader = ({ ...props }) => {
  return (
    <LoyaltyLoaderContainer {...props} width={useMqSelect({ $gteDesktop: 180 }, 140)}>
      <LoadingAnimation />
    </LoyaltyLoaderContainer>
  );
};

export default makeUclComponent(LoyaltyLoader);
