import { StyleProp, ViewStyle } from 'react-native';

import { IOffersFragment } from 'generated/graphql-gateway';
import { LoyaltyOffer } from 'state/loyalty/types';

export interface ILoyaltyPromoCodeDrawerProps {
  isOpen: boolean;
  onDismiss: () => void;
  promoCode?: string;
  onPromoCodeRedeemSuccess?: (param: {
    configOffer: LoyaltyOffer;
    engineOffer: IOffersFragment;
    personalizedOffer: LoyaltyOffer;
  }) => void;
  onNavigate: () => void;
}

export type IPromoCodeProps = Pick<ILoyaltyPromoCodeDrawerProps, 'onPromoCodeRedeemSuccess'> & {
  buttonLabel?: string;
  style?: StyleProp<ViewStyle>;
};

export enum PromoCodeErrorReason {
  redeemedCode = 'REDEEMED_CODE',
  expiredCode = 'EXPIRED_CODE',
  invalidCode = 'INVALID_CODE',
}

export class PromoCodeError extends Error {
  public message: PromoCodeErrorReason;

  constructor(message: PromoCodeErrorReason) {
    super();
    this.message = message;
  }
}
