import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { HStack, Text } from '@fhs-legacy/universal-components';
import { Calories } from 'components/price-and-calories';
import useEffectOnce from 'hooks/use-effect-once';
import { useFormatCalories } from 'hooks/use-format-calories';
import { useFormatJoules } from 'hooks/use-format-joules';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuContext } from 'state/menu';
import { useUIContext } from 'state/ui';
import { HapticsImpactStyle, hapticImpact } from 'utils/haptic';

import {
  UpsellItemButton,
  UpsellItemCalories,
  UpsellItemDetails,
  UpsellItemIcon,
  UpsellItemName,
  UpsellItemPicture,
  UpsellItemPrice,
} from './styles';
import { IUpsellItemProps } from './types';

const UpsellItem: React.FC<React.PropsWithChildren<IUpsellItemProps>> = ({ item, addToCard }) => {
  const { logRBIEvent } = useCRMEventsContext();
  const { formatMessage } = useIntl();
  const formatCalories = useFormatCalories();
  const { pricingFunction } = useMenuContext();
  const { formatCurrencyForLocale } = useUIContext();
  const enableEUNutritionDisplay = useFlag(LaunchDarklyFlag.ENABLE_EU_NUTRITION_DISPLAY);
  const formatJoules = useFormatJoules();
  const itemCalories = item.nutrition?.calories;

  const price = useMemo(
    () => formatCurrencyForLocale(pricingFunction(item)),
    [item, pricingFunction, formatCurrencyForLocale]
  );

  const caloriesInJoules = formatJoules(itemCalories);

  const calories = useMemo(() => formatCalories(itemCalories), [formatCalories, itemCalories]);

  const euFormattedNutritionText = formatMessage(
    { id: 'euNutritionValues' },
    { calories, joules: caloriesInJoules }
  );

  const globalizedCalorieFormat = calories ? `${euFormattedNutritionText}` : '';
  const finalCalories = enableEUNutritionDisplay ? globalizedCalorieFormat : calories;

  useEffectOnce(() => {
    logRBIEvent({
      name: CustomEventNames.UPSELL_DISPLAYED,
      type: EventTypes.Other,
      attributes: {
        name: item.name?.locale,
        sanityId: item._id,
        price: pricingFunction(item) / 100,
      },
    });
  });

  return (
    <UpsellItemButton
      testID="upsell-add-to-order-btn"
      accessibilityLabel={formatMessage({ id: 'addItemLabel' }, { itemName: item.name?.locale })}
      onPress={() => {
        hapticImpact({ style: HapticsImpactStyle.Heavy });

        addToCard(item);
      }}
    >
      <UpsellItemPicture image={item.image} alt={item.name?.locale} />
      <UpsellItemDetails
        accessibilityLabel={formatMessage(
          { id: 'upsellItemLabel' },
          {
            itemName: item.name?.locale,
            calories: formatCalories(itemCalories),
            price,
          }
        )}
      >
        <UpsellItemName testID={`upsell-name-${item._id}`}>{item.name?.locale}</UpsellItemName>
        <HStack>
          <UpsellItemPrice testID={`upsell-price-${item._id}`}>
            <Text testID={`upsell-price-text-${item._id}`}>{price}</Text>
          </UpsellItemPrice>
          <Calories>
            <Text variant="copyOne"> | </Text>
            <UpsellItemCalories testID={`upsell-calories-${item._id}`}>
              {finalCalories}
            </UpsellItemCalories>
          </Calories>
        </HStack>
      </UpsellItemDetails>
      <UpsellItemIcon variant="add" flex={0} />
    </UpsellItemButton>
  );
};

export default UpsellItem;
