import Constants from 'expo-constants';

import { SupportedRegions } from '@rbi-ctg/frontend';

interface IGetConfigValueProps {
  key: string;
  defaultValue?: any;
  region?: SupportedRegions;
}
/**
 * @note
 * !!DO NOT USE THIS FUNCTION DIRECTLY!!
 * should use `useConfigValue` hook instead where possible
 * this function should only be used directly by `useConfigValue`
 *  and in cases where LocaleProvider is not yet available
 *  (ex. LDProvider)
 */
export const getConfigValue = ({ key, region, defaultValue = '' }: IGetConfigValueProps): any => {
  const configDataLocation = Constants.expoConfig?.extra?.eas;
  let actualValue = null;
  if (region) {
    actualValue = configDataLocation?.[region]?.[key];
  } else {
    actualValue = configDataLocation?.[key];
  }
  return actualValue || defaultValue;
};
