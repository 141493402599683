import { HStack, Header, Text } from '@fhs-legacy/universal-components';

export const OrderIssuesContainer = HStack.withConfig({
  padding: '$4',
  borderRadius: 10,
  backgroundColor: Styles.color.white,
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const OrderIssuesHeading = Header.withConfig({
  variant: 'headerThree',
  marginBottom: '$1',
});

export const OrderIssuesText = Text.withConfig({
  variant: 'copyThree',
});
