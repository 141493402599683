import { useMediaQueries } from './store';
import { UseMqSelectQueries } from './types';

export function useMqSelect<T>(queries: UseMqSelectQueries<T>, defaultValue: T): T {
  // take query precedence from order query props were passed in
  // i.e., the last prop takes precedence over the props that come before it
  const queriesInPrecedenceOrder = Object.keys(queries).reverse() as Array<keyof typeof queries>;

  // Out of the queries given via props return the active query with the highest precedence
  // Only trigger rerender when active query with highest precedence changes
  const activeQueryWithHighestPrecedence = useMediaQueries(mqState => {
    for (const query of queriesInPrecedenceOrder) {
      if (mqState[query]) {
        return query;
      }
    }
    return null;
  });

  return (
    activeQueryWithHighestPrecedence === null
      ? defaultValue
      : queries[activeQueryWithHighestPrecedence]
  ) as T;
}
