import { Image, ImageProps } from 'expo-image';
import { Link } from 'expo-router';
import { Pressable, StyleSheet } from 'react-native';

export type ChickenParmesanCardProps = Omit<ImageProps, 'children' | 'source'> & {
  pickUpHref: string;
};

export function ChickenParmesanCard({ pickUpHref, ...imageProps }: ChickenParmesanCardProps) {
  return (
    <Link href={pickUpHref} asChild>
      <Pressable>
        <Image
          alt="Chicken Parmesan Meatball Sub. Limited Time Only. Savory chicken meatballs, bright marinara, shaved Parmesan, melted provolone, and crispy Italian-seasoned breadcrumbs on a toasted garlic bread roll. Order now"
          source={require('./chicken-parmesan-banner.webp')}
          placeholder={{ thumbhash: 'digKFgS6mGdJmIeHeGp5f2W4cD1agNc' }}
          {...imageProps}
          style={[styles.kingsHawaiian, imageProps.style]}
        />
      </Pressable>
    </Link>
  );
}

const styles = StyleSheet.create({
  kingsHawaiian: {
    aspectRatio: 1136 / 1270,
  },
});
