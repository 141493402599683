import { Box, HStack, Text } from '@fhs-legacy/universal-components';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import LoyaltyRewardsLogo from 'pages/loyalty/loyalty-rewards-logo';
import { theme } from 'styles/configure-theme';
import { isWeb } from 'utils/environment';

import { LOYALTY_POINTS_ICON_COLOR, SHOW_REWARDS_LOGO } from './constants';

export const Wrapper = Box.withConfig({
  width: 'full',
  backgroundColor: SHOW_REWARDS_LOGO
    ? theme.token('background-contrast-loyalty')
    : Styles.color.white,
  alignItems: 'center',
  _web: {
    position: {
      base: 'relative',
      desktop: 'sticky',
    },
    zIndex: {
      desktop: 2,
    },
    top: {
      desktop: 0,
    },
    left: 0,
  },
});

export const Container = HStack.withConfig({
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: Styles.layout.sectionMaxWidth,
  minHeight: '$10',
  width: 'full',
  borderStyle: 'solid',
  borderColor: 'transparent',
  paddingX: '$4',
  borderWidth: {
    base: '0 12px',
    desktop: '0 74px',
  },
  _web: {
    paddingX: '0',
    justifyContent: 'center',
  },
});

export const Content = HStack.withConfig<{ $border?: boolean }>(props => ({
  _web: {
    borderRightWidth: props.$border ? '1px' : 0,
    borderRightColor: Styles.color.grey.four,
    paddingX: '$6',
  },
  justifyContent: 'center',
  alignItems: 'center',
  marginY: '$2',
}));

export const PointsText = Text.withConfig({
  variant: 'copyOne',
  color: SHOW_REWARDS_LOGO ? theme.token('text-reversed') : theme.token('text-default'),
  fontSize: 'xs',
  margin: 0,
});

export const PointsNumber = Text.withConfig({
  variant: 'copyOne',
  color: SHOW_REWARDS_LOGO ? theme.token('text-reversed') : theme.token('text-default'),
  fontSize: 'sm',
  fontWeight: 'bold',
  marginLeft: '$1',
  textTransform: 'lowercase',
});

export const StyledPointsIcon = (
  <LoyaltyPointsIcon
    fill={LOYALTY_POINTS_ICON_COLOR}
    width={isWeb ? '12px' : '18px'}
    ml="$1"
    testID="points-icon"
  />
);

export const StyledRewardsLogo = (
  <LoyaltyRewardsLogo w="101px" testID="rewards-logo" top="$0.5" minH="$0" />
);
