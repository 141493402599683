/**
 * For documentation on masks, visit:
 *
 * https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#readme
 */

const nDigits = (n: number) => new Array(n).fill(/\d/);

export const loginPhoneNumberMask = [
  '(',
  ...nDigits(3),
  ')',
  ' ',
  ...nDigits(3),
  '-',
  ...nDigits(4),
];

const EMAIL_REGEX =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/i;

/**
 * Validate that a string is an email address.
 *
 * The same function exists on the backend.
 * Changes here should be duplicated in common/src/utils/index.ts
 *
 * @param {string} email
 * The string to test.
 *
 * @returns {boolean}
 * True if the string provided is a valid email address.
 */
export const isEmailValid = (email = '') => {
  return EMAIL_REGEX.test(email) && email[0] !== '.' && !email.includes('..');
};
