import { format } from 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';

import { Wordmark } from '@fhs/ui';
import { HStack, ScrollView, Text, View } from '@fhs-legacy/universal-components';
import { IServerOrder } from '@rbi-ctg/menu';
import ActionButton from 'components/action-button';
import QRCode from 'components/qrcode';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import { useIsMobileBp } from 'hooks/breakpoints';
import { useConfigValue } from 'hooks/configs/use-config-value';
import useDialogModal from 'hooks/use-dialog-modal';
import useOrderNumber from 'hooks/use-order-number';
import { FutureOrderTimeCard } from 'pages/cart/future-order-time-card';
import { CartConfirmation } from 'pages/order-confirmation/cart-confirmation';
import { PickupPin, QRContainer, QRWrapper } from 'pages/order-confirmation/common';
import OrderIssues from 'pages/order-confirmation/order-issues';
import PickupCard from 'pages/order-confirmation/pickup-card';
import { PrepareNowButton } from 'pages/order-confirmation/prepare-now';
import { SubheadingText } from 'pages/order-confirmation/subheading-text';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode } from 'state/order';
import { useOrderTimedFire } from 'state/order/hooks/use-order-timed-fire';
import { useStoreContext } from 'state/store';
import { primitive } from 'styles/constants/primitives';
import { fullBrandName } from 'utils/environment';
import { checkIfDateIsWithinCloseTimeAndMinutes, readableCloseHourToday } from 'utils/restaurant';
import { TWELVE_HOUR_TIME_PARSE_FORMAT } from 'utils/restaurant/constants';

import {
  ActionButtonWrapper,
  Container,
  FutureOrderTimeCardWrapper,
  Header,
  InfoLine,
  InfoWrapper,
  LogoContainer,
} from './styled';

const qrCodeOptions = {
  margin: 1,
  scale: 4,
  color: {
    dark: primitive.$black,
    light: primitive.$white,
  },
};

function PickupConfirmed({ serverOrder }: { serverOrder: IServerOrder }) {
  const { user } = useAuthContext();
  const timeFormatConfig = useConfigValue({
    key: 'timeFormat',
    defaultValue: TWELVE_HOUR_TIME_PARSE_FORMAT,
  });
  const { formatMessage } = useIntl();
  const { store } = useStoreContext();
  const isMobile = useIsMobileBp();
  const isShowingOrderingIssue = useFlag(LaunchDarklyFlag.SHOW_ORDERING_ISSUE);
  const enableClosingTimeDialog = useFlag(LaunchDarklyFlag.ENABLE_CLOSING_TIME_DIALOG);

  const { pickupDate } = useOrderTimedFire({ serverOrder });
  const serverOrderServiceMode = serverOrder.cart.serviceMode;

  const isPickupServiceMode =
    serverOrderServiceMode === ServiceMode.TAKEOUT ||
    serverOrderServiceMode === ServiceMode.DRIVE_THRU;
  const showPickupClosingTimeDialog =
    enableClosingTimeDialog &&
    isPickupServiceMode &&
    !!store.driveThruHours &&
    checkIfDateIsWithinCloseTimeAndMinutes(store.driveThruHours, new Date(Date.now()), 60);

  const [PickupClosingTimeDialog] = useDialogModal({
    modalAppearanceEventMessage: 'Store closing hour dialog',
    init: showPickupClosingTimeDialog,
  });

  // String.fromCharCode(160) puts a non-breaking space in to prevent "10:03" and "pm" from breaking onto different lines in this heading
  const pickupTime = format(pickupDate, 'h:mm aaaa').replace(' ', String.fromCharCode(160));

  const orderNumber = useOrderNumber(serverOrder);

  const name = (user && user.details.name) || '';

  const { fulfillmentDetails } = serverOrder;

  const getOrderModeText = orderNumber => {
    switch (serverOrderServiceMode) {
      case ServiceMode.PICKUP_WINDOW:
        return formatMessage({ id: 'orderScheduledForRapidRescuePickUpLane' });
      case ServiceMode.EAT_IN:
        return formatMessage(
          { id: 'orderScheduledForDineIn' },
          {
            orderNumber: orderNumber ? `#${orderNumber}` : '',
            linebreak: '\n',
          }
        );
      default:
        return formatMessage(
          { id: 'orderScheduledForPickup' },
          {
            orderNumber: orderNumber ? `#${orderNumber}` : '',
            linebreak: '\n',
          }
        );
    }
  };

  const orderNumberText = formatMessage({
    id: 'orderNumber',
  });

  const pickupOrderAlert = formatMessage(
    { id: 'pickUpOrderSuccessfulAlert' },
    {
      orderNumber,
      brand: fullBrandName(),
      address: `${serverOrder.cart.storeAddress.addressLine1} ${serverOrder.cart.storeAddress.addressLine2}`,
    }
  );

  return (
    <View px={{ base: '$4', desktop: '$0' }} justifyContent="space-between" gap="$4" flex={1}>
      <ScrollView flex={{ base: 1, desktop: 0 }}>
        <Container>
          {isMobile && (
            <LogoContainer>
              <Wordmark />
            </LogoContainer>
          )}
          <Header testID="order-preparation-confirmation">{getOrderModeText(orderNumber)}</Header>

          {!!fulfillmentDetails?.pickupQrCode && (
            <QRContainer>
              <QRWrapper>
                <QRCode
                  barcode={fulfillmentDetails.pickupQrCode}
                  options={qrCodeOptions}
                  testID="qr-code"
                />
              </QRWrapper>
            </QRContainer>
          )}
          <SubheadingText
            name={name}
            pickupTime={pickupTime}
            serviceMode={serverOrderServiceMode}
          />
          {!!fulfillmentDetails?.pickupPin && <PickupPin>{fulfillmentDetails.pickupPin}</PickupPin>}
          <InfoWrapper>
            {!!orderNumber && (
              <HStack>
                <InfoLine testID="order-number" mr="$1" fontWeight="bold">
                  {orderNumberText}:
                </InfoLine>
                <Text>{orderNumber}</Text>
              </HStack>
            )}
          </InfoWrapper>
          <PrepareNowButton serverOrder={serverOrder} />
          <FutureOrderTimeCardWrapper>
            <FutureOrderTimeCard
              date={{
                year: pickupDate.getFullYear(),
                month: pickupDate.getMonth(),
                day: pickupDate.getDate(),
                hour: pickupDate.getHours(),
                minutes: pickupDate.getMinutes(),
              }}
              readOnly
            />
          </FutureOrderTimeCardWrapper>
          <PickupCard confirmed serverOrder={serverOrder} />

          <CartConfirmation
            rbiOrderId={serverOrder.rbiOrderId}
            totalPrice={serverOrder.cart.totalCents}
            entries={serverOrder.cart.cartEntries}
          />
        </Container>
        {isShowingOrderingIssue && <OrderIssues />}

        <VisuallyHidden role="alert" accessibilityLabel={pickupOrderAlert} />

        <PickupClosingTimeDialog
          testID="store-closing-hour-dialog"
          heading={formatMessage({ id: 'orderPickup' })}
          body={formatMessage(
            { id: 'dontForgetToPickYourOrder' },
            { time: readableCloseHourToday(store.driveThruHours, timeFormatConfig) }
          )}
        />
      </ScrollView>

      <ActionButtonWrapper>
        <ActionButton
          fullWidth
          padding="$2"
          to={`/receipt/${serverOrder.rbiOrderId}`}
          accessibilityRole="button"
          accessibilityLabel={formatMessage({ id: 'viewReceipt', description: '' })}
        >
          {formatMessage({ id: 'viewReceipt', description: '' })}
        </ActionButton>
      </ActionButtonWrapper>
    </View>
  );
}

export default React.memo(PickupConfirmed);
