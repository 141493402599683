import { useFocusEffect } from 'expo-router';
import { useCallback, useMemo, useRef } from 'react';

import LoadingAnimation from 'components/loading-animation';
import { useAdaptableMenuCards } from 'hooks/use-adaptable-menu-cards';
import { useDebounce } from 'hooks/use-debounce';
import { usePathname } from 'hooks/use-pathname';
import {
  ClickEventComponentNames,
  CustomEventNames,
  EventTypes,
  useCRMEventsContext,
} from 'state/crm-events';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { useLoyaltyContext } from 'state/loyalty';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { isSectionRoute } from 'utils/routing';

import { MenuCardsSectionContent } from './menu-cards-section-content';

export const MenuCardsSection = () => {
  const offersLoadingState = useAppSelector(selectors.loyalty.selectOffersLoading);
  const offersLoading = useDebounce(offersLoadingState);
  const offersLoadingStartedRef = useRef<boolean>(false);

  const { queryLoyaltyOffers, queryLoyaltyUserStandardOffers } = useLoyaltyContext();
  const { loyaltyUser } = useLoyaltyUser();
  const dispatch = useAppDispatch();

  const pathname = usePathname();
  const isNotMainMenu = isSectionRoute(pathname);

  useFocusEffect(
    useCallback(() => {
      const loyaltyId = loyaltyUser?.id;
      if (loyaltyId) {
        queryLoyaltyUserStandardOffers({ loyaltyId });
      } else {
        queryLoyaltyOffers({});
      }
      dispatch(actions.loyalty.setShouldRefetchOffers(false));
    }, [dispatch, loyaltyUser?.id, queryLoyaltyOffers, queryLoyaltyUserStandardOffers])
  );

  const offersLoadingStarted = useMemo(() => {
    if (offersLoading) {
      offersLoadingStartedRef.current = true;
    }
    return offersLoadingStartedRef.current;
  }, [offersLoading]);

  if (isNotMainMenu) {
    return null;
  }

  return offersLoadingStarted ? (
    <MenuCardsSectionMainContent offersLoading={offersLoading} />
  ) : (
    <LoadingAnimation height={130} />
  );
};

export const MenuCardsSectionMainContent = ({ offersLoading }: { offersLoading: boolean }) => {
  const { availableMenuCards, menuCardsLoading } = useAdaptableMenuCards();
  const { logRBIEvent } = useCRMEventsContext();

  const logDisplayEvent = (activeIndex: number, name: string, id: string) => {
    logRBIEvent({
      name: CustomEventNames.MENU_CARD_DISPLAYED,
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.ADAPTABLE_MENU_CARD,
        position: `${activeIndex}`,
        sanityId: id,
        name,
      },
    });
  };

  if (menuCardsLoading || offersLoading) {
    return <LoadingAnimation height={130} />;
  }

  if (availableMenuCards.length === 0) {
    return null;
  }

  return (
    <MenuCardsSectionContent
      logDisplayEvent={logDisplayEvent}
      availableMenuCards={availableMenuCards}
    />
  );
};
