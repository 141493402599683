import { useControllableState } from '../../hooks';
import { addWithConfig } from '../../utils';
import { Box } from '../box';
import { FlatList } from '../flat-list';
import { Header } from '../header';
import { Pressable } from '../pressable';
import { Text } from '../text';

import { DatePickerProps, _DatePickerPressableProps } from './types';

const backgroundColor = ({
  isDisabled,
  isSelected,
}: {
  isDisabled: boolean | undefined;
  isSelected: boolean | undefined;
}) => {
  if (isDisabled) {
    return 'token.background-input-disabled';
  }
  if (isSelected) {
    return 'token.background-button-secondary-hover';
  }
  return 'token.background-pattern';
};

const DatePickerPressable = ({
  isSelected,
  isDisabled,
  accessibilityLabel,
  day,
  date,
  ...props
}: _DatePickerPressableProps) => {
  return (
    <Pressable
      testID="date-picker-pressable"
      accessibilityRole="radio"
      accessibilityLabel={accessibilityLabel || ''}
      accessibilityHint=""
      accessibilityState={{ selected: isSelected, disabled: isDisabled }}
      isDisabled={isDisabled}
      borderColor={isSelected ? 'token.border-color-active' : 'token.border-color-default'}
      bg={backgroundColor({ isDisabled, isSelected })}
      borderWidth={1}
      borderRadius={8}
      p="$4"
      minW="104px"
      mr="$2"
      _pressed={{ bg: 'token.background-hover' }}
      _hover={{ bg: 'token.background-hover' }}
      {...props}
    >
      <Text
        fontSize="14px"
        lineHeight="16px"
        color={isSelected ? 'token.text-active' : 'token.text-input-placeholder'}
      >
        {day}
      </Text>
      <Text
        fontSize="16px"
        lineHeight="20px"
        color={isDisabled ? 'token.text-disabled' : 'token.text-default'}
      >
        {date}
      </Text>
    </Pressable>
  );
};

export const DatePicker = <ValueType extends string>({
  accessibilityHint,
  accessibilityLabel,
  data,
  defaultValue,
  header,
  onChangeValue,
  value,
  ...boxProps
}: DatePickerProps<ValueType>) => {
  const [selectedDate, setSelectedDate] = useControllableState({
    defaultValue: defaultValue || data[0].value,
    value,
    handleValueChange: onChangeValue,
  });

  return (
    <Box
      accessibilityHint={accessibilityHint}
      accessibilityLabel={accessibilityLabel}
      ml="$4"
      {...boxProps}
    >
      <Header variant="headerTwo">{header}</Header>
      <Box height="72px" mb="$8">
        <FlatList
          accessibilityRole="radiogroup"
          horizontal
          showsHorizontalScrollIndicator={false}
          data={data}
          keyExtractor={(item) => item.value}
          renderItem={({ item }) => {
            return (
              <DatePickerPressable
                key={item.value}
                isSelected={selectedDate === item.value}
                onPress={() => setSelectedDate(item.value)}
                isDisabled={item.isDisabled || false}
                day={item.day}
                date={item.date}
                accessibilityLabel={item.accessibilityLabel}
                accessibilityHint=""
              />
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default addWithConfig(DatePicker);
