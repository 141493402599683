import { Operation, createHttpLink, from, split } from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';
import * as Crypto from 'expo-crypto';
import { isNil } from 'lodash';

import { getGraphqlApiUrl, getGraphqlGatewayApiUrl } from 'remote/constants';

import {
  AUTH_REQUIRED_DIRECTIVE,
  isRequestAuthProtected,
  isUserLoggedIn,
  stripAuthDirective,
} from './auth-required-directive';
import { cancelRequestLink } from './cancel-request-link';
import { sanityFetch } from './sanity-fetch';
import { isGatewayRequest, stripGatewayDirective } from './strip-gateway-directive';
import { removeNestedTypenameFromMenuQueriesLink } from './strip-typename';
import { isCacheRequest, stripUseCacheDirective } from './strip-useCache-directive';
import { withAuthHeaders } from './with-auth-headers';
import { withClientInfo } from './with-client-info-headers';
import { withForterHeaders } from './with-forter-headers';
import { withLocalizedQueries } from './with-localized-queries';
import { withDateTimeHeaders } from './with-user-datetime-headers';

const isSanityRequest = (context?: Record<string, any>) => !isNil(context && context.uri);
const operationIsGatewayRequest = (operation: Operation) => isGatewayRequest(operation.query);
const operationIsSanityRequest = (operation: Operation) => isSanityRequest(operation.getContext());
const operationIsCacheRequest = (operation: Operation) => isCacheRequest(operation.query);

const fetchOptions = {
  referrerPolicy: 'no-referrer',
};

const sanityHttpLink = from([
  withLocalizedQueries(),
  new RetryLink({ attempts: { max: 3 } }),
  // sanity requests have the uri set in context
  from([
    removeNestedTypenameFromMenuQueriesLink,
    createHttpLink({ credentials: 'omit', fetch: sanityFetch, fetchOptions }),
  ]),
]);

const withRBIGraphQLLinks = from([
  withAuthHeaders,
  withDateTimeHeaders,
  withForterHeaders,
  withClientInfo,
]);

const gatewayHttpLink = createHttpLink({
  uri: getGraphqlGatewayApiUrl(),
  credentials: 'omit',
});

const sha256 = async (buffer: string) =>
  await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA256, buffer);

const gatewaySplit = split(
  operationIsGatewayRequest,
  split(
    operationIsCacheRequest,
    from([
      stripGatewayDirective,
      stripUseCacheDirective,
      createPersistedQueryLink({ sha256, useGETForHashedQueries: true }),
      gatewayHttpLink,
    ]),
    from([stripGatewayDirective, gatewayHttpLink])
  ),
  from([
    stripGatewayDirective,
    // Batching was removed because it was causing issues in React Native.
    // Please do not add back.
    //
    // It was causing intermittent issues with some GraphQL queries never being dispatched
    // and their components stuck in a loading state.
    createHttpLink({ uri: getGraphqlApiUrl(), credentials: 'omit' }),
  ])
);

/**
 * The lambdaHttpLink composes the link chain for requests going out to our
 * lambda backend. It can be difficult to visualize the chain with all the
 * splits, so here's a diagram:
 *
 *                         ...withRBIGraphQLLinks
 *                      is auth required on this request?
 *                         /                 \
 *                       yes                  no
 *                      /                      \
 *                 is user logged in?       gateway split
 *                  /           \
 *                yes           no
 *               /                \
 *          gateway split     invalid request link
 *
 * we split out the gatewaySplit into a variable so that it can be re-used,
 * because there are now two different places where we might want to use it.
 * after composing the "base" links (`withRBIGraphQLLinks`), we first ask if
 * we're dealing with a query that required auth. if not, we send the request
 * down the pre-existing chain, which just has the gateway split as the next
 * step. if the query does require auth, we send it to this new step, which
 * then asks if the user is logged in. if not, we cancel the request and
 * return an error. if they are logged in, then we treat it as a "normal"
 * request and send it down the rest of the link chain, where the first
 * step is the gateway split.
 */
const lambdaHttpLink = from([
  withRBIGraphQLLinks,
  split(
    isRequestAuthProtected,
    split(
      isUserLoggedIn,
      from([stripAuthDirective, gatewaySplit]),
      cancelRequestLink(
        `@${AUTH_REQUIRED_DIRECTIVE} directive present on query but user is not authorized.`
      )
    ),
    gatewaySplit
  ),
]);

export const httpLink = split(operationIsSanityRequest, sanityHttpLink, lambdaHttpLink);
