import { Redirect } from 'expo-router';
import Head from 'expo-router/head';
import React, { useMemo } from 'react';

import { Box } from '@fhs-legacy/universal-components';
import useMenuByDayPart, { IMenuByDayPartProps } from 'hooks/use-menu-by-day-part';
import NotFound from 'pages/not-found';
import { useMainMenuContext } from 'state/main-menu';
import { useServiceModeContext } from 'state/service-mode';

import { reduceMenuSectionOptions } from '../main-menu/utils';

import MenuItemUnavailable from './item-unavailable';
import { MenuSectionCateringAlert } from './menu-section-catering-alert';
import { RenderInlineTileGrid, RenderStandard } from './menu-section-content-tiles';
import { IMenuContentProps } from './types';
import { useLogMenuContent } from './use-log-menu-content';

export const MenuSectionContent = ({ id }: IMenuContentProps) => {
  const { getMenuObject, isStaticMenu, isOffer } = useMainMenuContext();
  const { serviceMode } = useServiceModeContext();

  // TODO: There is a type mismatch here, this need to be fixed in a future refactor
  const { data: menuSection } = useMenuByDayPart(getMenuObject(id) as IMenuByDayPartProps);

  const redirectLink = menuSection?.redirectLink?.locale;

  const { inlineTileSections, standardSections, totalSectionsLength } = useMemo(
    () =>
      reduceMenuSectionOptions({
        // TODO: There is a type mismatch here, this need to be fixed in a future refactor
        // @ts-ignore
        menuSectionOptions: menuSection?.options,
        isStaticMenu,
        serviceMode,
      }),
    [isStaticMenu, menuSection, serviceMode]
  );

  useLogMenuContent({
    loggedValues: {
      id: menuSection?._id || '',
      name: menuSection?.name?.locale || '',
      menuType: menuSection?._type || '',
    },
    pathname: `/menu/${id}`,
  });

  if (redirectLink) {
    return <Redirect href={redirectLink} />;
  }

  if (!menuSection || totalSectionsLength <= 0) {
    return (
      <NotFound>
        <MenuItemUnavailable isOffer={isOffer} />
      </NotFound>
    );
  }

  return (
    <Box flex="1">
      <Head>
        <title>
          {menuSection?.name?.locale
            ? `${menuSection?.name?.locale} - Firehouse Subs`
            : 'Firehouse Subs'}
        </title>
      </Head>
      <MenuSectionCateringAlert currentSectionId={id} />
      <RenderStandard sections={standardSections} />
      <RenderInlineTileGrid sections={inlineTileSections} />
    </Box>
  );
};
