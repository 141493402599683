import { useLocalSearchParams } from 'expo-router';
import React, { PropsWithChildren, useState } from 'react';
import { useIntl } from 'react-intl';
import Crash from 'react-native-crash-tester';

import ActionButton from 'components/action-button';
import IconError from 'components/error-boundary-static/icon';
import { useReload } from 'components/error-boundary-static/use-reload';
import useEffectOnce from 'hooks/use-effect-once';
import { usePathname } from 'hooks/use-pathname';
import logger from 'utils/logger';

import { Container, Header, Inner } from './styles';

type BlankScreenLoggerProps = PropsWithChildren<{
  context: {
    reason: string;
    [key: string]: any;
  };
  onReload?: () => void;
}>;

export const BlankScreen = ({ context, children, onReload }: BlankScreenLoggerProps) => {
  const { formatMessage } = useIntl();
  const params = useLocalSearchParams();
  const pathname = usePathname();
  const [forceReloadApp, setForceReloadApp] = useState(false);
  const message = `[BlankScreen] Screen pathname: ${pathname}`;

  const handleReload = useReload(() => {
    if (!forceReloadApp && onReload) {
      onReload();
      setForceReloadApp(true);
      return;
    }

    logger.info(message);
    Crash.nativeCrash('ForcedCrash BlankScreen');
  });

  useEffectOnce(() => {
    logger.warn({
      message,
      context: {
        ...context,
        route: {
          pathname,
          path: pathname,
          params,
        },
      },
    });
  });

  if (children) {
    return <>children</>;
  }

  return (
    <Container>
      <Inner>
        <IconError />
        <Header>
          {formatMessage({ id: 'loyaltyUnavailableInRegionOops' })}{' '}
          {formatMessage({ id: 'somethingWrong' })}
        </Header>
        <ActionButton onPress={handleReload}>{formatMessage({ id: 'reload' })}</ActionButton>
      </Inner>
    </Container>
  );
};
