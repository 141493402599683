import { Svg, SvgPath, type SvgProps, registerSVG } from '../../../src/components/svg';

export function Catering(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgPath d="M1.16667 18H22.1667C22.8083 18 23.3333 18.525 23.3333 19.1667C23.3333 19.8083 22.8083 20.3333 22.1667 20.3333H1.16667C0.525 20.3333 0 19.8083 0 19.1667C0 18.525 0.525 18 1.16667 18ZM13.8133 7.255C13.93 6.975 14 6.66 14 6.33333C14 5.05 12.95 4 11.6667 4C10.3833 4 9.33333 5.05 9.33333 6.33333C9.33333 6.66 9.40333 6.975 9.52 7.255C4.95833 8.2 1.48167 12.085 1.16667 16.8333H22.1667C21.8517 12.085 18.375 8.2 13.8133 7.255Z" />
    </Svg>
  );
}

registerSVG('Catering', Catering);
