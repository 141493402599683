import * as React from 'react';
import { FC } from 'react';

import { ISanityLocaleImage } from '@rbi-ctg/menu';

import { StyledPicture } from './loyalty-image-widget.styled';

interface IImageWidgetProps {
  altText: string;
  image: ISanityLocaleImage;
}

const LoyaltyImageWidget: FC<React.PropsWithChildren<IImageWidgetProps>> = ({
  altText,
  image,
}: IImageWidgetProps) => {
  return <StyledPicture alt={altText} image={image.locale} />;
};

export default LoyaltyImageWidget;
