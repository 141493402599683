import { Header } from '@fhs-legacy/universal-components';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';

export const StyledPointsIcon = LoyaltyPointsIcon.withConfig({
  marginY: 0,
  marginX: '$1',
});

export const Points = Header.withConfig({
  variant: 'headerThree',
  margin: 0,
  alignItems: 'center',
  fontWeight: 'bold',
});
