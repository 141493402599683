import * as React from 'react';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonSizes } from 'components/action-button';
import { useGeolocation } from 'state/geolocation';
import { isIOS } from 'utils/environment';
import DEFAULT_MAP_ZOOM from 'utils/restaurant/default-map-zoom';

import { ResetLocationButton } from '../../reset-location-button';

import { IMapButtonsProps } from './types';

/**
 *
 * MapButtons handles logic & UI for the Search This Area
 *  and Reset Location buttons within the map container.
 *
 */
export const MapButtons: FC<React.PropsWithChildren<IMapButtonsProps>> = ({
  currentViewIsMap,
  hasPanned,
  isDesktop,
  onPressSearch,
  zoom,
  resetZoom,
}) => {
  const { formatMessage } = useIntl();
  const { resetCoordsAndLoadCurrentPosition, loadingUserCoordinates } = useGeolocation();

  const displayButton = hasPanned;

  const isSearchButton = isIOS() ? zoom <= DEFAULT_MAP_ZOOM : zoom >= DEFAULT_MAP_ZOOM;

  if (!currentViewIsMap) {
    return null;
  }

  const handlePress = () => {
    if (isSearchButton) {
      onPressSearch();
      return;
    }

    resetZoom();
  };

  const ctaButtonText = isSearchButton
    ? formatMessage({ id: 'searchThisArea' })
    : formatMessage({ id: 'zoomInToSearch' });

  return (
    <>
      <Box
        position="absolute"
        alignSelf="center"
        bottom="$4"
        display={displayButton ? 'flex' : 'none'}
        _android={{
          // Android ignores Display: none if Position: absolute
          // RN-bug: https://github.com/facebook/react-native/issues/18415
          position: displayButton ? 'absolute' : 'relative',
        }}
        marginX="$0"
        marginTop="$0"
        testID="map-buttons"
      >
        <ActionButton
          size={isDesktop ? ActionButtonSizes.LARGE : ActionButtonSizes.SMALL}
          onPress={handlePress}
          testID="search-this-area-button"
        >
          {ctaButtonText}
        </ActionButton>
      </Box>

      <Box position="absolute" bottom={isDesktop ? '$8' : '$4'} right={isDesktop ? '$8' : '$4'}>
        <ResetLocationButton
          onButtonPress={resetCoordsAndLoadCurrentPosition}
          loading={loadingUserCoordinates}
        />
      </Box>
    </>
  );
};

export default MapButtons;
