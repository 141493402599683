import React, { forwardRef } from 'react';
import { TextInput as RNTextInput } from 'react-native';

import { Box, FormControl, Input, Text, addWithConfig } from '@fhs-legacy/universal-components';

import { TextInputProps } from './types';

const EndIconWrapper = Box.withConfig({
  position: 'absolute',
  height: 'full',
  justifyContent: 'center',
  right: '$3',
});

const RequiredLabelWrapper = Text.withConfig({
  color: 'errorRed',
  fontWeight: 'bold',
  fontSize: '21',
});

const TextInput = forwardRef<RNTextInput, TextInputProps>(
  ({ label, errorMessage, required, hintMessage, endIcon, labelProps, ...rest }, ref) => {
    return (
      <FormControl isInvalid={!!errorMessage} margin="0">
        {label && (
          <FormControl.Label {...labelProps}>
            {label}
            {required && <RequiredLabelWrapper> {'*'}</RequiredLabelWrapper>}
          </FormControl.Label>
        )}
        <Box position="relative">
          <Input {...rest} ref={ref} />
          {!!endIcon && <EndIconWrapper>{endIcon}</EndIconWrapper>}
        </Box>
        {hintMessage && !errorMessage && (
          <FormControl.HelperText margin="0" p="0">
            {hintMessage}
          </FormControl.HelperText>
        )}
        {!!errorMessage && (
          <FormControl.ErrorMessage margin="0" p="0">
            {errorMessage}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
    );
  }
);

export default addWithConfig(TextInput);
