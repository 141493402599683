import Version from './version';

export enum Keys {
  AMPLITUDE_VARIANTS = 'RBI::AMPLITUDE_VARIANTS',
  APPLIED_LOYALTY_OFFERS = 'RBI::APPLIED_LOYALTY_OFFERS',
  APPLIED_LOYALTY_REWARDS = 'RBI::APPLIED_LOYALTY_REWARDS',
  AUTH_REDIRECT = 'RBI::AUTH_REDIRECT',
  BRAND = 'RBI::BRAND',
  DETERMINING_LOCALE_COMPLETE = 'RBI::DETERMINING_LOCALE_COMPLETE',
  DISABLE_CHICKEN_SANDWICH = 'RBI::DISABLE_CHICKEN_SANDWICH',
  FIRST_DATA = 'RBI::FIRST_DATA',
  GEO_PERMISSION = 'RBI::GEO_PERMISSION',
  HOSTNAME = 'RBI::HOSTNAME',
  IN_RESTAURANT_REDEMPTION = 'RBI::LOYALTY_IN_RESTAURANT_REDEMPTION',
  IN_RESTAURANT_REDEMPTION_LAST_MOD = 'RBI::IN_RESTAURANT_REDEMPTION_LAST_MOD',
  IN_RESTAURANT_REDEMPTION_SHORT_CODE = 'RBI::LOYALTY_IN_RESTAURANT_REDEMPTION_SHORT_CODE',
  LAST_COOKIE_VERSION = 'RBI::COOKIE_VERSION',
  LAST_TIME_COOKIES_ACCEPTED = 'RBI::LAST_TIME_COOKIES_ACCEPTED',
  LAST_TIME_COOKIES_IGNORED = 'RBI::LAST_TIME_COOKIES_IGNORED',
  LAST_TIME_STORE_UPDATED = 'RBI::LAST_TIME_STORE_UPDATED',
  LAUNCH_DARKLY_USER_ATTRIBUTES = 'RBI::LAUNCH_DARKLY_USER_ATTRIBUTES',
  LAUNCH_DARKLY_FLAGS = 'RBI::LAUNCH_DARKLY_FLAGS',
  LOGIN = 'RBI::LOGIN',
  LOYALTY_CARD = 'RBI::LOYALTY',
  LOYALTY_DATA = 'RBI::LOYALTY_DATA',
  LOYALTY_OFFERS_APPLIED_FILTERS = 'RBI::LOYALTY_OFFERS_APPLIED_FILTERS',
  OFFER = 'RBI::OFFER',
  OFFER_REDEMPTION = 'RBI::OFFER_REDEMPTION',
  OFFERS_REDEEMED_NO_AUTH = 'RBI::OFFERS_REDEEMED_NO_AUTH',
  ONETRUST_COOKIE_CONSENT_GROUPS = 'RBI::ONETRUST_COOKIE_CONSENT_GROUPS',
  ORDER = 'RBI::ORDER',
  ORDER_LAST_UPDATE = 'RBI::ORDER_LAST_UPDATE',
  IN_RESTAURANT_CART_LAST_UPDATE = 'RBI::IN_RESTAURANT_CART_LAST_UPDATE',
  OTP = 'RBI::OTP',
  SELECTED_LOYALTY_OFFER = 'RBI::SELECTED_LOYALTY_OFFER',
  SELECTED_LOYALTY_OFFER_SELECTIONS = 'RBI::SELECTED_LOYALTY_OFFER_SELECTIONS',
  SERVICE_MODE = 'RBI::SERVICE_MODE',
  DELIVERY_SURCHARGE_FEE_CONFIRM = 'RBI::DELIVERY_SURCHARGE_FEE_CONFIRM',
  DELIVERY_SURCHARGE_FEES = 'RBI::DELIVERY_SURCHARGE_FEES',
  STORAGE_VERSION = 'RBI::STORAGE_VERSION',
  STORE = 'RBI::STORE',
  STORE_NUMBER_PRESET = 'RBI::STORE_PRESET',
  TABBED_MENU_PREVIOUS_ACTIVE_TAB = 'RBI::TABBED_MENU_PREVIOUS_ACTIVE_TAB',
  TABBED_MENU_PREVIOUS_TAB_POSITION = 'RBI::TABBED_MENU_PREVIOUS_TAB_POSITION',
  TIMCARD = 'RBI::TIMCARD',
  USER = 'RBI::USER',
  USER_AUTH_TOKEN = 'RBI::USER_AUTH_TOKEN',
  USER_SIGNED_IN_SUCCESSFULLY = 'RBI::USER_SIGNED_IN_SUCCESSFULLY',
  VEHICLE_DESCRIPTION = 'RBI::VEHICLE_DESCRIPTION',
  PAYBACK_TOKEN = 'RBI::PAYBACK_TOKEN',
  PICK_UP_TIPS_SELECTED = 'RBI::PICK_UP_TIPS_SELECTED',
  OTHER_PICK_UP_TIPS_SELECTED = 'RBI::OTHER_PICK_UP_TIPS_SELECTED',
  REDEEM_LAST_CODE_STATE = 'RBI::REDEEM_LAST_CODE_STATE',
  REDEEM_SHOULD_REFRESH = 'RBI::REDEEM_SHOULD_REFRESH',
  TEMP_FUTURE_ORDERING_TIME = 'RBI::TEMP_FUTURE_ORDERING_TIME',
  CONFIRM_LOCATION_DRAWER_DISPLAYED = 'RBI:CONFIRM_LOCATION_DRAWER_DISPLAYED',
}

export const LOCAL_STORAGE_VERSION = Version.localStorage;
export const COOKIE_VERSION = Version.cookie;
