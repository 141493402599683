import { onError } from '@apollo/client/link/error';
import { getOperationName } from '@apollo/client/utilities';

import {
  DeleteLoyaltyCardDocument,
  GetLoyaltyCardDetailsDocument,
  GetLoyaltyCardDetailsFromBarcodeDocument,
  GetLoyaltyCardTransactionsDocument,
  MergeLoyaltyCardsDocument,
} from 'generated/rbi-graphql';
import logger from 'utils/logger';

import { REQUEST_CANCELLED_KEY } from './cancel-request-link';

export const withErrorLogger = onError(({ operation, graphQLErrors }) => {
  if (graphQLErrors && graphQLErrors.length > 0) {
    const loggableErrors = graphQLErrors.filter(
      error => !error.extensions?.[REQUEST_CANCELLED_KEY]
    );

    if (!loggableErrors || loggableErrors.length === 0) {
      return;
    }

    const logError = (errorMeta?: any) => {
      for (const error of loggableErrors) {
        error.message = `GraphQL error calling ${operation.operationName}: ${error.message}`;
        logger.error({
          error,
          context: { ...errorMeta },
          operationName: operation.operationName,
        });
      }
    };

    /**
     * Custom error cases
     */
    switch (operation.operationName) {
      case getOperationName(GetLoyaltyCardTransactionsDocument):
      case getOperationName(DeleteLoyaltyCardDocument):
      case getOperationName(GetLoyaltyCardDetailsDocument):
        logError({ cardId: operation.variables.cardId });
        break;
      case getOperationName(GetLoyaltyCardDetailsFromBarcodeDocument):
        logError({ barcode: operation.variables.barcode });
        break;
      case getOperationName(MergeLoyaltyCardsDocument):
        logError({
          cardId: operation.variables.cardId,
          physicalCardId: operation.variables.existingCardId,
        });
        break;
      default:
        logError();
    }
  }
});
