import { Stack } from 'expo-router';

import { useAuthContext } from '@fhs-legacy/frontend/src/state/auth';

import { getScreenTitleHeader } from '../../components/get-screen-title-header';

export default function RedeemLayout() {
  const { isAuthenticated } = useAuthContext();
  return (
    <Stack>
      <Stack.Screen
        name="index"
        options={{
          header: getScreenTitleHeader({ backStyle: isAuthenticated ? 'back' : 'close' }),
          presentation: isAuthenticated ? undefined : 'fullScreenModal',
          animation: isAuthenticated ? 'none' : 'slide_from_left',
        }}
      />
    </Stack>
  );
}
