import { useLocalSearchParams } from 'expo-router';
import { useCallback, useEffect } from 'react';

import { useOfferCart } from '@fhs/loyalty/src/state/offer-guide-cart-state';
import {
  IProductWizardFlow,
  ProductWizardParams,
  ProductWizardStep,
} from 'state/product-wizard/types';

import { useProductWizardContext } from '..';

const stringToBooleanParamMap = {
  true: true,
  false: false,
};

export const useProductWizardFlow = (): IProductWizardFlow => {
  const { setOnBackAttempt } = useOfferCart();
  const {
    _wizardFlowStep,
    _setWizardFlowStep,
    _slotKeyToCustomize,
    _setSlotKeyToCustomize,
    isSimplyOffer,
  } = useProductWizardContext();
  const routerParams = useLocalSearchParams<ProductWizardParams>();
  const {
    id = '',
    rewardBenefitId,
    rewardId,
    isEdit = 'false',
    isFavorite = 'false',
    isFromCart = 'false',
    isFromSurprise = 'false',
  } = routerParams;

  const navigateToStep = useCallback(
    (step: ProductWizardStep, slotKey?: string) => {
      _setWizardFlowStep(step);
      _setSlotKeyToCustomize(slotKey ?? '');
    },
    [_setSlotKeyToCustomize, _setWizardFlowStep]
  );

  const goToHome = useCallback(() => {
    navigateToStep(ProductWizardStep.ProductHome);
  }, [navigateToStep]);

  const goToCustomizeProduct = useCallback(
    (slotKey: string) => {
      navigateToStep(ProductWizardStep.ProductCustomization, slotKey);
    },
    [navigateToStep]
  );

  useEffect(() => {
    if (isSimplyOffer) {
      if (_wizardFlowStep === ProductWizardStep.ProductCustomization) {
        setOnBackAttempt(() => {
          goToHome();
          return true;
        });
      } else {
        setOnBackAttempt(undefined);
      }
    }
    return () => setOnBackAttempt(undefined);
  }, [_wizardFlowStep, isSimplyOffer, goToHome, setOnBackAttempt]);

  return {
    id,
    isEdit: stringToBooleanParamMap[isEdit],
    isFavorite: stringToBooleanParamMap[isFavorite],
    isFromCart: stringToBooleanParamMap[isFromCart],
    isFromSurprise: stringToBooleanParamMap[isFromSurprise],
    rewardBenefitId,
    rewardId,
    currentStep: Number(_wizardFlowStep),
    slotKeyToCustomize: _slotKeyToCustomize,
    goToHome,
    goToCustomizeProduct,
  };
};

export default useProductWizardFlow;
