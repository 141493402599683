import { useMqSelect } from '@fhs/ui';

export function useIsMobileBp(): boolean {
  return useMqSelect({ $ltDesktop: true }, false);
}

export function useIsDesktopBp(): boolean {
  return useMqSelect({ $gteDesktop: true }, false);
}

export function useIsLgDesktopBp(): boolean {
  return useMqSelect({ $gteDesktopLg: true }, false);
}
