import { router, useLocalSearchParams } from 'expo-router';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, Icon } from '@fhs-legacy/universal-components';
import { IBackendCartEntries, ICartEntry } from '@rbi-ctg/menu';
import ActionButton, {
  ActionButtonSizes,
  ActionButtonVariants,
  ActionLink,
} from 'components/action-button';
import FullPageScreen from 'components/full-page-screen';
import ItemActions from 'components/item-actions';
import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';
import { Product } from 'components/product';
import { SaveFavoriteOrderUnsaved } from 'components/save-favorite-order-unsaved';
import { TextInput } from 'components/ucl';
import { ItemAvailabilityStatus } from 'enums/menu';
import {
  GetUserFavoritesDocument,
  ICartEntryInput,
  useDeleteFavoriteMutation,
  useGetUserFavoriteQuery,
  useUpdateFavoriteMutation,
} from 'generated/rbi-graphql';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import useDialogModal from 'hooks/use-dialog-modal';
import { useToast } from 'hooks/use-toast';
import { useFavoritesContext } from 'state/favorites';
import { useMapToFrontendCartEntry } from 'state/order/hooks/use-map-to-frontend-cart-entry';
import { theme } from 'styles/configure-theme';
import { frontendToBackendCartEntryMap, getSelectionsListFromEntry } from 'utils/cart';
import { mapBackendToFrontend } from 'utils/reorder';
import { routes } from 'utils/routing';

const Layout = Box.withConfig({
  width: 'full',
});

const StyledCard = Box.withConfig({
  width: 'full',
  padding: '$4',
  backgroundColor: theme.token('background-pattern'),
  borderBottomWidth: '1px',
  borderBottomColor: Styles.color.grey.four,
});

const StyledItemActions = Box.withConfig({
  marginTop: '$2',
  marginLeft: '$0',
  marginBottom: '$0',
  marginRight: '$16',
});

const FavoriteEditorPage: FC<React.PropsWithChildren<unknown>> = () => {
  useRumPageView('favorite-editor', 'Favorite Editor');
  const {
    setCurrentEditingFavorite,
    cancelEditing,
    tempFavorite: favorite,
    isPendingSave,
    editName,
    editEntry,
    removeEntry,
    setShouldRefetchFavorites,
  } = useFavoritesContext();
  const toast = useToast();
  const { formatMessage } = useIntl();
  const params = useLocalSearchParams<{ favoriteId: string }>();

  const favoriteId = params.favoriteId || '';
  const [unSavedChangesModalOpen, setUnSavedChangesModalOpen] = useState<boolean>(false);

  const [deleteFavorite] = useDeleteFavoriteMutation({
    onCompleted: () => {
      toast.show({
        text: formatMessage({ id: 'removeFavoriteOrderSucess' }),
        variant: 'positive',
      });

      setShouldRefetchFavorites(true);
      router.replace(routes.favorites);
    },
  });
  const [updateFavorite] = useUpdateFavoriteMutation({
    onCompleted: () => {
      cancelEditing();
      setShouldRefetchFavorites(true);
      router.replace(routes.favorites);
    },
  });

  const { loading } = useGetUserFavoriteQuery({
    variables: {
      favoriteId,
    },
    onCompleted: data => setCurrentEditingFavorite(data?.userFavorite),
  });

  const [RemoveFavoriteModal, openRemoveFavoriteModal] = useDialogModal({
    onConfirm: () => {
      deleteFavorite({
        variables: {
          favoriteId,
        },
        refetchQueries: [{ query: GetUserFavoritesDocument }],
      });
    },
    showCancel: true,
    modalAppearanceEventMessage: 'Remove Favorite',
  });

  const handleCloseEditor = () => {
    if (isPendingSave) {
      return setUnSavedChangesModalOpen(true);
    }
    cancelEditing();
    router.replace(routes.favorites);
  };

  const handleSaveEdits = async () => {
    // Cast favorite entries to backend entries
    const cartEntries = favorite?.entries as unknown as IBackendCartEntries[];
    // Convert backend entries to cart entry input
    const entries = cartEntries?.map(cartEntry =>
      frontendToBackendCartEntryMap(mapBackendToFrontend(cartEntry))
    ) as unknown as ICartEntryInput[];
    // Update the favorite
    return await updateFavorite({
      variables: {
        favoriteId,
        input: {
          ref: favorite?.ref,
          entries,
          name: favorite?.name,
        },
      },
    });
  };

  // @TODO: Figure out how to infer the type from the favoritesFragment
  const handleEditItem = (entry: any) => {
    /**
     * Redirects to wizard
     *
     * If you click Save Changes in wizard it will:
     *  - Set `isPendingSave` to true
     *  - then will update the tempFavorite
     */
    editEntry(entry);
  };

  const handleRemoveItem = (lineId?: string) => {
    // Not sure when this would ever happen? Here for type safety
    if (!lineId) {
      return;
    }

    // If there is one favorite item remaining, then ask the user to remove the current favorite
    if (favorite?.entries && favorite.entries.length <= 1) {
      return openRemoveFavoriteModal();
    }

    /**
     * Removes a line item from favorite order given the lineId
     *
     *  - Sets `isPendingSave` to true
     *  - then will update the tempFavorite
     */
    removeEntry(lineId);
  };

  const discardChanges = () => {
    router.replace(routes.favorites);
    cancelEditing();
  };

  return (
    <FullPageScreen
      heading={formatMessage({ id: 'editOrder' })}
      skipGoBackOnClose={true}
      onClose={handleCloseEditor}
    >
      {loading ? (
        <LoadingContainer minHeight="$96">
          <LoadingAnimation />
        </LoadingContainer>
      ) : (
        <Layout testID="favorite-editor">
          <Box margin="$4">
            <TextInput
              aria-label={formatMessage({ id: 'orderName' })}
              autoFocus
              testID="favorite-editor-name-input"
              label={formatMessage({ id: 'orderName' })}
              onChangeText={value => editName(value)}
              required
              value={favorite?.name || ''}
            />
          </Box>
          {favorite?.entries?.map((entry: any, i: number) => (
            <FavoriteEntry
              key={'favEntry' + i}
              entry={entry}
              handleEditItem={handleEditItem}
              handleRemoveItem={handleRemoveItem}
            />
          ))}
          <Box margin="$4">
            <ActionLink
              to={routes.menu}
              replace
              startIcon={<Icon variant="add" aria-hidden />}
              variant={ActionButtonVariants.OUTLINE}
              size={ActionButtonSizes.SMALL}
              width="40%"
              marginLeft="29%"
            >
              {formatMessage({ id: 'addItems' })}
            </ActionLink>
          </Box>
          <Box margin="$4" justifyContent="center">
            <ActionButton fullWidth onPress={handleSaveEdits}>
              {formatMessage({ id: 'update' })}
            </ActionButton>
          </Box>
        </Layout>
      )}
      {unSavedChangesModalOpen && (
        <SaveFavoriteOrderUnsaved
          onSave={handleSaveEdits}
          onDiscard={discardChanges}
          onDismiss={() => setUnSavedChangesModalOpen(false)}
        />
      )}

      <RemoveFavoriteModal
        testID="remove-favorite-order-modal"
        buttonLabel={formatMessage({ id: 'deleteButtonText' })}
        heading={formatMessage({ id: 'favoriteRemoveAllModalHeader' })}
        body={formatMessage({ id: 'favoriteRemoveAllModalBody' })}
      />
    </FullPageScreen>
  );
};

interface IFavoriteEntry {
  entry: IBackendCartEntries;
  handleEditItem: (entry: any) => void;
  handleRemoveItem: (lineId?: string) => void;
}

const FavoriteEntry = ({ entry, handleEditItem, handleRemoveItem }: IFavoriteEntry) => {
  const [itemAvailability, setItemAvailability] = useState<ItemAvailabilityStatus>(
    ItemAvailabilityStatus.UNAVAILABLE
  );

  const mapToFrontendEntry = useMapToFrontendCartEntry();

  useEffect(() => {
    let isSubscribed = true;

    const handleEffect = async () => {
      const { status } = await mapToFrontendEntry(entry);
      if (isSubscribed) {
        setItemAvailability(status);
      }
    };

    handleEffect();

    return () => {
      isSubscribed = false;
    };
  }, [entry, mapToFrontendEntry]);

  return (
    <StyledCard key={entry?.lineId}>
      <Product
        quantity={entry?.quantity}
        name={entry?.name}
        image={entry?.image}
        selections={getSelectionsListFromEntry(entry as unknown as ICartEntry)}
      />
      <StyledItemActions>
        <ItemActions
          showEdit={itemAvailability === ItemAvailabilityStatus.AVAILABLE}
          onEdit={() => handleEditItem(entry)}
          onRemove={() => handleRemoveItem(entry?.lineId)}
        />
      </StyledItemActions>
    </StyledCard>
  );
};

export default FavoriteEditorPage;
