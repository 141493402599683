import { Image, ImageBackground, ImageBackgroundProps, ImageStyle } from 'expo-image';
import { StyleProp } from 'react-native';

import { YStack, createMqStyles } from '@fhs/ui';

import { CtaButton } from '../shared/cta-button';

export type PastramiReubenCardProps = Omit<ImageBackgroundProps, 'children' | 'source'> & {
  pickUpHref: string;
};
export function PastramiReubenCard({ pickUpHref, ...props }: PastramiReubenCardProps) {
  const mqStyles = useMqStyles();

  return (
    <ImageBackground
      source={require('./pastrami-reuben-card-background.webp')}
      placeholder={{ thumbhash: 'qygSG4bAaZmcdpiIX4n0hTg' }}
      {...props}
      style={[mqStyles.pastramiReubenBanner, props.style]}
    >
      <YStack style={mqStyles.contentContainer}>
        <Image
          source={require('./pastrami-reuben-logo.webp')}
          alt="New! Pastrami Reuben"
          placeholder={{ thumbhash: 'AhiCAoIl/83u3c7/7v7v7lhomYqUaQg' }}
          style={mqStyles.logo as StyleProp<ImageStyle>}
          contentFit="contain"
        />
        <Image
          source={require('./limited-time-only.webp')}
          alt="Limited time only"
          placeholder={{ thumbhash: 'GYqDAYAj4PVHaa5viLej+Yh4ineAZ1g' }}
          style={mqStyles.limitedTimeOnly as StyleProp<ImageStyle>}
          contentFit="contain"
        />
        <Image
          source={require('./text-desktop.webp')}
          alt="Pastrami, melted swiss cheese, sweet & tangy slaw, thousand island dressing & mayo"
          placeholder={{ thumbhash: 'AAiCAoAD/v7u7u4AAAAAABlJj3yKtOg' }}
          style={mqStyles.textDesktop as StyleProp<ImageStyle>}
          contentFit="contain"
        />
        <CtaButton href={pickUpHref} />
      </YStack>
    </ImageBackground>
  );
}

const useMqStyles = createMqStyles({
  pastramiReubenBanner: {
    $base: {
      aspectRatio: 3840 / 1514,
    },
  },
  contentContainer: {
    $base: {
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      right: 0,
      gap: 20,
      top: 32,
      bottom: 32,
      left: '60%',
    },
  },

  logo: {
    $base: {
      aspectRatio: 1186 / 494,
      width: '80%',
    },
  },

  limitedTimeOnly: {
    $base: {
      aspectRatio: 692 / 136,
      marginTop: -20,
    },
    $ltDesktopLg: {
      width: '50%',
    },
    $gteDesktopLg: {
      width: '60%',
    },
  },
  textDesktop: {
    $base: {
      aspectRatio: 935 / 280,
      width: '60%',
    },
  },
});
