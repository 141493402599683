import { Box, Header, Text } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

export const Container = Box.withConfig({
  paddingY: '$5',
  borderTopWidth: '1px',
  borderColor: Styles.color.grey.four,
});

export const DescriptionText = Text.withConfig<{ unavailable: boolean }>(p => ({
  color: p.unavailable ? Styles.color.grey.one : theme.token('text-default'),
}));

export const HeaderText = Header.withConfig<{ unavailable: boolean }>(p => ({
  color: p.unavailable ? Styles.color.grey.one : theme.token('text-default'),
}));
