/**
 * Turns a 12-digit phone number into a well-formatted 10-digit phone number
 */
export function formatPhoneNumber(phoneNumber?: string) {
  if (phoneNumber && phoneNumber.length === 12) {
    const [a, b, c] = [
      phoneNumber.substring(2, 5),
      phoneNumber.substring(5, 8),
      phoneNumber.substring(8, 12),
    ];

    return `(${a}) ${b}-${c}`;
  }
  return phoneNumber ?? '';
}

/**
 * Remove all non-digits from a phone-number
 */
export function stripPhoneNumber(phoneNumberStr: string) {
  return phoneNumberStr.replaceAll(/\D/g, '');
}
