import {
  Box,
  HStack,
  Header,
  Icon,
  Image,
  Text,
  VStack,
  View,
} from '@fhs-legacy/universal-components';
import Link from 'components/link';
import { WEB_APP_CTA_MAX_WIDTH } from 'utils/constants';

import theme from './theme';

export const Background = Box.withConfig({
  background: theme.modalBackground,
  width: 'full',
});

export const Inner = Box.withConfig({
  maxWidth: '485px',
  width: '90%',
  marginX: 'auto',
  background: theme.modalBackground,
  paddingTop: '$5',
});

export const InnerText = Text.withConfig({
  color: theme.innerColor,
  lineHeight: theme.innerLineHeight,
});

export const HeaderContainer = Box.withConfig({
  height: {
    base: '140px',
    desktop: '170px',
  },
});

export const InnerHeader = Header.withConfig({
  color: Styles.color.black,
  textTransform: 'capitalize',
  lineHeight: 'lg',
});

export const Address = Text.withConfig({
  color: theme.addressColor,
  fontSize: 'md',
  textTransform: 'capitalize',
  lineHeight: 'md',
  marginY: '$1',
});

export const Amenity = HStack.withConfig({
  marginBottom: '$3.5',
  alignItems: 'center',
});

export const AmenityIcon = Icon.withConfig({
  marginRight: '$1.5',
  width: '$8',
});

export const Container = VStack.withConfig({
  width: 'full',
  height: 'full',
});

export const SpacingLine = Box.withConfig({
  borderStyle: 'solid',
  borderTopWidth: '1px',
  borderTopColor: 'rgba(177, 177, 177, 0.7)',
});

export const ButtonContainer = HStack.withConfig({
  borderStyle: 'solid',
  borderTopWidth: '1px',
  borderTopColor: 'rgba(177, 177, 177, 0.7)',
  width: 'full',
  height: '$20',
  alignItems: 'center',
  background: theme.modalBackground,
  paddingX: '$5',
});

export const StoreHourContainer = HStack.withConfig({
  marginY: '$2',
  justifyContent: 'space-between',
});

export const FeatureIcon = Image.withConfig({
  marginRight: '$1',
  height: '$6',
  width: '$8',
});

export const PhoneNumber = Link.withConfig({
  color: theme.phoneNumberColor,
  fontSize: 'md',
  fontWeight: '400',
  isInline: true,
  isUnderlined: true,
});

export const CtaWrapper = View.withConfig({
  flex: 1,
  width: { desktop: WEB_APP_CTA_MAX_WIDTH },
  maxW: WEB_APP_CTA_MAX_WIDTH,
  marginX: 'auto',
});
