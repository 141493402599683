import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Text } from '@fhs-legacy/universal-components';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOrderContext } from 'state/order';
import { useUIContext } from 'state/ui';

import FreeDeliveryProgress from './free-delivery-progress';

const FreeDeliveryProgressContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const { calculateCartTotal } = useOrderContext();
  const { formatCurrencyForLocale } = useUIContext();
  const discountedDeliveryThreshold =
    useFlag(LaunchDarklyFlag.DISCOUNTED_DELIVERY_THRESHOLD_CENTS) || 0;

  const shouldShowFreeDeliveryBar = !!discountedDeliveryThreshold;

  const freeDeliveryPercentageComplete = useMemo(
    () => Math.min(calculateCartTotal() / discountedDeliveryThreshold, 1) * 100,
    [calculateCartTotal, discountedDeliveryThreshold]
  );

  const amountRemaining = useMemo(
    () => discountedDeliveryThreshold - calculateCartTotal(),
    [calculateCartTotal, discountedDeliveryThreshold]
  );

  return shouldShowFreeDeliveryBar ? (
    <FreeDeliveryProgress
      freeDeliveryPercentageComplete={freeDeliveryPercentageComplete}
      titleLabel={formatMessage(
        { id: 'freeDeliveryOverAmount' },
        {
          amount: formatCurrencyForLocale(discountedDeliveryThreshold),
        }
      )}
      freeDeliveryReachedLabel={formatMessage({ id: 'youQualifyFreeDelivery' })}
      freeDeliveryInProgressLabel={formatMessage(
        { id: 'youreXAwayFromFreeDelivery' },
        {
          amount: <Text fontWeight="bold">{formatCurrencyForLocale(amountRemaining)}</Text>,
        }
      )}
    />
  ) : null;
};

export default FreeDeliveryProgressContainer;
