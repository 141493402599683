import type { NavigationState } from '@react-navigation/routers';
import { useMutation } from '@tanstack/react-query';
import { router, useLocalSearchParams, useRootNavigation } from 'expo-router';
import { omit } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { selectStore } from '@fhs/cart';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { useDefaultServiceMode, useSelectRestaurant } from 'hooks/store-card';
import { useFeatureMenu } from 'hooks/use-feature-menu';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import useStoreCardButtonOptions from 'hooks/use-store-card-button-options';
import { useToast } from 'hooks/use-toast';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { StoreCardButtonOptions } from 'state/launchdarkly/variations';
import { IncentiveEvaluationErrorCodes } from 'state/loyalty/hooks/types';
import { useLoyaltyOffersEvaluation } from 'state/loyalty/hooks/use-loyalty-offers-evaluation';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { useMenuContext } from 'state/menu';
import { useOrderContext } from 'state/order';
import { useUserRecentOrder } from 'state/order/hooks/use-user-recent-order';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { EventName, emitEvent } from 'utils/event-hub';
import { AttributeName } from 'utils/performance';
import { useIsMobileOrderingAvailable } from 'utils/restaurant';
import { isMenuTabRoute, routes } from 'utils/routing';
import { isCatering } from 'utils/service-mode';

import { ACTION_BUTTON_ICONS } from '../constants/action-button-icons';
import { IActionOption } from '../types';

import { isRouteInState } from './is-route-in-state';

interface IUseStoreActionOptions {
  (options: {
    restaurant: IRestaurantNode;
    setShowChangeToPickUpDialog: (showModal: boolean) => void;
    showChangeToPickUpDialog: boolean;
    validateRestaurantSelection?: () => Promise<boolean>;
  }): IActionOption[];
}

const getSanitizedBack = (back?: string, cateringSectionIds: string[] = []) => {
  /**
   * if back route is a link inside of the menu `routes.menu` (no tabs), go back to the main menu root because stores have different sections / items inside of them
   */
  if (cateringSectionIds.some(sectionId => back?.endsWith(sectionId))) {
    return back;
  }
  if (back && back.startsWith(routes.menu) && !isMenuTabRoute(back)) {
    return routes.menu;
  }
  return back || routes.menu;
};

const getBackNavigationFn = (back?: string, state?: NavigationState) =>
  isRouteInState(back, state) ? router.navigate : router.replace;

export const useStoreActionOptions: IUseStoreActionOptions = ({
  restaurant,
  setShowChangeToPickUpDialog,
  showChangeToPickUpDialog,
  validateRestaurantSelection,
}) => {
  const toast = useToast();
  const { back, reorderOrderId, ...otherParams } = useLocalSearchParams<{
    back?: string;
    reorderOrderId?: string;
  }>();
  const useCartService = useFlag(LaunchDarklyFlag.ENABLE_SIMPLY_BETTER_CART_SERVICE);
  const { isPending: currentlyMutatingStoreSelection, mutateAsync: selectStoreWithCartService } =
    useMutation(selectStore);
  const { selectRestaurant } = useSelectRestaurant({
    restaurant,
  });
  const { featureMenu } = useFeatureMenu();
  const cateringSectionIds = featureMenu?.cateringSections
    ?.map(section => section?._id)
    .filter(Boolean) as string[];
  const { selectedStaticMenuItemId } = useMenuContext();
  const { fetchingPosData, cartEntries } = useOrderContext();
  const storeCardButtonOptions = useStoreCardButtonOptions();
  const { logRBIEvent } = useCRMEventsContext();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const storeSelection2_0Enabled = useFlag(LaunchDarklyFlag.ENABLE_STORE_SELECTION_2_0);
  const isMobileOrderingAvailable = useIsMobileOrderingAvailable(restaurant);
  const { store: currentStore } = useStoreContext();

  const { availablePickupServiceModes } = useServiceModeStatus(restaurant);

  const { setServiceMode, serviceMode, isDelivery } = useServiceModeContext();
  const defaultServiceMode = useDefaultServiceMode({ restaurant });
  const [selectedButtonOption, setSelectedButtonOption] = useState(StoreCardButtonOptions.INFO);
  const isRestaurantOpen = Boolean(availablePickupServiceModes.length);

  const appliedLoyaltyOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const { evaluateLoyaltyOffers } = useLoyaltyOffersEvaluation();
  const { loyaltyUserId } = useLoyaltyUser();

  // Callback to add reorder items to the cart after selecting a store
  const { handleReorderAfterStoreSelect, orderDetailsLoading } = useUserRecentOrder({
    singleOrderId: reorderOrderId,
  });

  const isCacheEnabled = useFlag(LaunchDarklyFlag.ENABLE_RESTAURANT_QUERY_CACHE);

  const logRBIStoreSelected = useCallback(() => {
    logRBIEvent({
      name: CustomEventNames.BUTTON_CLICK_STORE_SELECTED,
      type: EventTypes.Other,
      attributes: {
        restaurantId: restaurant._id || '',
        restaurantAddress: restaurant.physicalAddress?.address1 || '',
        restaurantCity: restaurant.physicalAddress?.city || '',
        restaurantZip: restaurant.physicalAddress?.postalCode || '',
        restaurantState: restaurant.physicalAddress?.stateProvince || '',
        restaurantCountry: restaurant.physicalAddress?.country || '',
        storeId: restaurant.storeId || '',
        isCacheEnabled,
      },
    });
  }, [logRBIEvent, restaurant, isCacheEnabled]);

  /**
   * DEFINE BUTTON CLICK HANDLERS
   */
  const onInfoClick = useCallback(() => {
    setSelectedButtonOption(StoreCardButtonOptions.INFO);
    router.navigate(`${routes.store}/${restaurant._id}`);
  }, [restaurant._id]);

  const onOffersClick = useCallback(() => {
    setSelectedButtonOption(StoreCardButtonOptions.OFFERS);
    emitEvent(EventName.RESTAURANT_SELECTED_ON_OFFERS, {
      isStoreChange: currentStore?._id !== restaurant?._id,
      attributes: [
        {
          name: AttributeName.RESTAURANT_ID,
          value: restaurant.id || '',
        },
      ],
    });

    selectRestaurant({
      callback: () => {
        setServiceMode(defaultServiceMode);
        router.replace(routes.offers);
      },
    });
  }, [
    currentStore?._id,
    defaultServiceMode,
    restaurant?._id,
    restaurant.id,
    selectRestaurant,
    setServiceMode,
  ]);

  const rootNavigation = useRootNavigation();

  const onOrderClick = useCallback(async () => {
    if (!showChangeToPickUpDialog && isDelivery && cartEntries?.length > 0) {
      setShowChangeToPickUpDialog(true);
      return;
    }

    const validated = validateRestaurantSelection ? await validateRestaurantSelection() : true;
    if (!validated) {
      return;
    }
    setSelectedButtonOption(StoreCardButtonOptions.ORDER);

    // Set shouldRefetchOffers to true on restaurant selection
    dispatch(actions.loyalty.setShouldRefetchOffers(true));

    emitEvent(EventName.RESTAURANT_SELECTED_ON_ORDER, {
      selectedStaticMenuItemId,
      isStoreChange: currentStore?._id !== restaurant?._id,
      attributes: [
        {
          name: AttributeName.RESTAURANT_ID,
          value: restaurant.id || '',
        },
      ],
    });

    logRBIStoreSelected();

    if (isCatering(serviceMode)) {
      selectRestaurant({
        requestedServiceMode: ServiceMode.CATERING_PICKUP,
        callback: () => {
          setServiceMode(ServiceMode.CATERING_PICKUP);
          router.replace(back || routes.menu);
        },
      });
      return;
    }

    selectRestaurant({
      callback: async () => {
        if (loyaltyUserId) {
          const { incentiveFeedbackMap: invalidAppliedOffers } = await evaluateLoyaltyOffers({
            loyaltyId: loyaltyUserId,
            appliedOffers: appliedLoyaltyOffers,
            storeId: restaurant?.storeId,
          });
          if (
            invalidAppliedOffers &&
            Object.values(invalidAppliedOffers).some(invalidOfferEvaluation =>
              invalidOfferEvaluation.some(
                evaluation => evaluation.code === IncentiveEvaluationErrorCodes.OFFER_NOT_AVAILABLE
              )
            )
          ) {
            dispatch(actions.loyalty.setResetAppliedOffersAndRefetch());
            toast.show({
              text: formatMessage({ id: 'changeStoreSelectedOfferNotAvailableDisclaimer' }),
              variant: 'negative',
            });
          }
        }

        setServiceMode(defaultServiceMode);

        const rootState = rootNavigation?.getRootState();

        const sanitizedBack = getSanitizedBack(back, cateringSectionIds);
        const navigationFn = getBackNavigationFn(back, rootState);

        if (useCartService) {
          await selectStoreWithCartService({
            storeNumber: restaurant.number!,
            serviceMode: serviceMode || defaultServiceMode,
          });
        }

        // Add reorder items to the cart if there's a reorderOrderId specified
        if (reorderOrderId) {
          handleReorderAfterStoreSelect();
        }

        if (sanitizedBack) {
          navigationFn({
            pathname: sanitizedBack,
            params: omit(otherParams, ['screen', 'params']),
          });
        }
      },
    });
  }, [
    useCartService,
    selectStoreWithCartService,
    reorderOrderId,
    handleReorderAfterStoreSelect,
    showChangeToPickUpDialog,
    isDelivery,
    cartEntries?.length,
    validateRestaurantSelection,
    dispatch,
    restaurant,
    setShowChangeToPickUpDialog,
    selectedStaticMenuItemId,
    currentStore?._id,
    logRBIStoreSelected,
    serviceMode,
    selectRestaurant,
    setServiceMode,
    back,
    cateringSectionIds,
    loyaltyUserId,
    appliedLoyaltyOffers,
    evaluateLoyaltyOffers,
    defaultServiceMode,
    rootNavigation,
    otherParams,
    toast,
    formatMessage,
  ]);

  /**
   * MAP BUTTON OPTIONS TO CLICK HANDLER
   */
  const actionButtonClickHandlers = useMemo(
    () => ({
      [StoreCardButtonOptions.INFO]: onInfoClick,
      [StoreCardButtonOptions.OFFERS]: onOffersClick,
      [StoreCardButtonOptions.ORDER]: onOrderClick,
    }),
    [onInfoClick, onOffersClick, onOrderClick]
  );

  /**
   * MAP BUTTON OPTIONS TO TEXT
   */
  const actionButtonText = useMemo(() => {
    const shouldUseMenuText =
      storeSelection2_0Enabled &&
      restaurant.hasMobileOrdering &&
      isRestaurantOpen &&
      !isMobileOrderingAvailable;

    return {
      [StoreCardButtonOptions.INFO]: formatMessage({ id: 'info' }),
      [StoreCardButtonOptions.OFFERS]: formatMessage({ id: 'offers' }),
      [StoreCardButtonOptions.ORDER]: shouldUseMenuText
        ? formatMessage({ id: 'menu' })
        : formatMessage({ id: 'order' }),
    };
  }, [
    formatMessage,
    isMobileOrderingAvailable,
    isRestaurantOpen,
    restaurant.hasMobileOrdering,
    storeSelection2_0Enabled,
  ]);

  /**
   * CREATE STORE ACTION OPTIONS
   */
  return useMemo(() => {
    const getDisabled = (option: StoreCardButtonOptions) => {
      switch (option) {
        case StoreCardButtonOptions.ORDER:
        case StoreCardButtonOptions.OFFERS:
          return true;
        default:
          return false;
      }
    };

    const getLoading = (option: StoreCardButtonOptions) => {
      if (
        option === StoreCardButtonOptions.ORDER &&
        (orderDetailsLoading || currentlyMutatingStoreSelection)
      ) {
        return true;
      }

      return fetchingPosData && selectedButtonOption === option;
    };

    return storeCardButtonOptions.map(option => {
      return {
        id: option,
        text: actionButtonText[option],
        onClick: actionButtonClickHandlers[option],
        icon: ACTION_BUTTON_ICONS[option],
        disabled: getDisabled(option),
        loading: getLoading(option),
      };
    });
  }, [
    actionButtonClickHandlers,
    actionButtonText,
    fetchingPosData,
    currentlyMutatingStoreSelection,
    selectedButtonOption,
    storeCardButtonOptions,
  ]);
};
