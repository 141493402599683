import { useRefundEligibility } from 'hooks/refund-eligibility';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { routes } from 'utils/routing';
import { useSupportLink } from 'utils/support/use-support-link';

export const useHelpLinkUrlGenerator = (rbiOrderId: string) => {
  const enablePypestream = useFlag(LaunchDarklyFlag.ENABLE_PYPESTREAM);
  const supportLink = useSupportLink();

  const enableGuestAndMultiStepSupportForm = useFlag(
    LaunchDarklyFlag.ENABLE_GUEST_AND_MULTI_STEP_SUPPORT_PAGE
  );

  const { isOrderEligibleForRefund } = useRefundEligibility({ orderId: rbiOrderId });

  //  CIR or the generic support form
  const supportV1Link = isOrderEligibleForRefund
    ? `${routes.reportOrderIssue}/${rbiOrderId}`
    : supportLink;

  const needHelpLinkUrl = enableGuestAndMultiStepSupportForm ? supportLink : supportV1Link;

  const pypestreamUrl = rbiOrderId ? `${routes.support}?orderId=${rbiOrderId}` : supportLink;

  return enablePypestream ? pypestreamUrl : needHelpLinkUrl;
};
