import React, { useMemo } from 'react';

import { Box, Icon } from '@fhs-legacy/universal-components';
import { ICombo } from '@rbi-ctg/menu';
import { useProductPropsSelections } from 'components/product-detail/use-product-props-selections';
import {
  getComboSlotOptionFromItem,
  transformItemAndVirtualItemToProductListItemProps,
  transformItemToProductListItemProps,
} from 'components/product-detail/utils';
import { ProductListItem } from 'components/product-list-item';
import { useMenuCalories } from 'hooks/menu/use-menu-calories';
import { useMenuContext } from 'state/menu';
import { useProductWizardContext } from 'state/product-wizard';
import { ENABLE_MAIN_ITEM_DETAILED_CUSTOMIZATION } from 'state/product-wizard/constants';

import { ProductNoteText } from '../styled';

import { IProductCustomizationHeaderProps } from './types';

export const ProductCustomizationHeader = ({
  comboSlot,
  isClickable,
  isCollapsed,
  selectedItem,
  toggleCollapse,
  modifierSelections,
}: IProductCustomizationHeaderProps) => {
  const { selectedProduct } = useProductWizardContext();
  const { priceForComboSlotSelection } = useMenuContext();
  const { buildProductPropsSelections } = useProductPropsSelections();
  const { formatCalories } = useMenuCalories();

  const productProps = useMemo(() => {
    let price = 0;
    if (comboSlot) {
      const selectedOption = getComboSlotOptionFromItem(comboSlot, selectedItem);
      if (selectedOption?.isPremium) {
        price = priceForComboSlotSelection({
          combo: selectedProduct as ICombo,
          comboSlot,
          selectedOption,
        });
      }
    }

    const selections = buildProductPropsSelections(modifierSelections);

    const listItemProps = ENABLE_MAIN_ITEM_DETAILED_CUSTOMIZATION
      ? transformItemAndVirtualItemToProductListItemProps(selectedItem, {
          price,
          productNote: comboSlot?.name.locale,
          selections,
          modifierSelections,
        })
      : transformItemToProductListItemProps(selectedItem, {
          price,
          productNote: comboSlot?.name.locale,
          selections,
        });

    return listItemProps.productProps;
  }, [
    buildProductPropsSelections,
    comboSlot,
    modifierSelections,
    priceForComboSlotSelection,
    selectedItem,
    selectedProduct,
  ]);

  const controls = isClickable && (
    <Box style={{ transform: [{ rotate: isCollapsed ? '270deg' : '90deg' }] }}>
      <Icon variant="next" testID="next-icon" />
    </Box>
  );

  return (
    <ProductListItem
      isClickable={isClickable}
      onProductClick={toggleCollapse}
      productProps={{
        ...productProps,
        calories: formatCalories(productProps.calories),
        note: !!productProps.note && <ProductNoteText>{productProps.note}</ProductNoteText>,
      }}
      controls={controls}
    />
  );
};
