import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconCalendar = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M19 3.1h-1v-2h-2v2H8v-2H6v2H5c-1.11 0-1.99.9-1.99 2L3 19.1a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2Zm0 16H5v-11h14v11Zm-12-9h5v5H7v-5Z"
      />
    </Svg>
  );
};
