import { router } from 'expo-router';
import React, { useMemo } from 'react';

import { Button, Icon, Text } from '@fhs-legacy/universal-components';
import { useIsMobileBp } from 'hooks/breakpoints';
import useEffectOnce from 'hooks/use-effect-once';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { ClickEventComponentNames } from 'state/crm-events/constants';
import { selectors, useAppSelector } from 'state/global-state';
import { routes } from 'utils/routing';

import { ContentBox, Image, PressableWrapper } from './adaptable-menu-card.styled';
import { IAdaptableMenuCardProps } from './types';

export const AdaptableMenuCard = ({
  header,
  description,
  ctaButtonText,
  image,
  menuElement,
  path,
  position,
  _id: cardId,
  logDisplayEvent,
  onlyOneItem,
}: IAdaptableMenuCardProps) => {
  const offers = useAppSelector(selectors.loyalty.selectCombinedOffers);
  const { logRBIEvent } = useCRMEventsContext();
  const isMobile = useIsMobileBp();

  const headerText = header?.locale;
  const descriptionText = description?.locale;
  const buttonText = ctaButtonText?.locale;
  const menuImage = image?.locale?.app;

  const pathUrl = useMemo(() => {
    if (!menuElement) {
      return path?.locale;
    }
    const configOffer = offers.find(offer => offer._id === menuElement._id);
    return 'loyaltyEngineId' in menuElement
      ? `${routes.loyaltyOfferList}/${configOffer?.loyaltyEngineId}`
      : `${routes.menu}/${menuElement._type}-${menuElement._id}`;
  }, [menuElement, offers, path?.locale]);
  useEffectOnce(() => {
    logDisplayEvent?.(position, headerText ?? '', cardId);
  });

  const cardWidth = useMemo(() => {
    if (isMobile) {
      return 380;
    }
    return onlyOneItem ? 750 : 500;
  }, [isMobile, onlyOneItem]);

  const cardMarginLeft = useMemo(() => {
    if (onlyOneItem || position === 1) {
      return '$0';
    }
    return isMobile ? '$2' : '$4';
  }, [isMobile, onlyOneItem, position]);

  if (!headerText || !menuImage || !pathUrl) {
    return null;
  }

  const logMenuCardPressEvent = () => {
    logRBIEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.ADAPTABLE_MENU_CARD,
        text: header?.locale ?? '',
        position: `${position}`,
        componentId: cardId,
      },
    });
  };

  const handlePress = () => {
    logMenuCardPressEvent();
    const isConfigOffer = menuElement?.__typename === 'ConfigOffer';
    return router.navigate({
      pathname: pathUrl,
      params: { isConfigOffer: isConfigOffer.toString() },
    });
  };

  return (
    <PressableWrapper onPress={handlePress} _web={{ width: cardWidth, marginLeft: cardMarginLeft }}>
      <ContentBox>
        <Text variant="headerThree" mb="0" noOfLines={3}>
          {headerText}
        </Text>
        {descriptionText && (
          <Text variant="copyOne" noOfLines={3} marginTop="$2">
            {descriptionText}
          </Text>
        )}
        {buttonText && (
          <Button
            variant="outline"
            onPress={handlePress}
            size="sm"
            marginTop="$4"
            rightIcon={<Icon variant="forward" size="4" />}
          >
            {buttonText}
          </Button>
        )}
      </ContentBox>
      <Image image={menuImage} alt={header.locale} />
    </PressableWrapper>
  );
};
