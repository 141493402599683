import { isEqual, pick, pickBy } from 'lodash';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Box, Checkbox, Text } from '@fhs-legacy/universal-components';
import { IDeliveryAddress } from 'generated/rbi-graphql';
import { useAuthContext } from 'state/auth';
import { useOrderContext } from 'state/order';

/**
 * @name getNormalizedAddress
 * @description
 * Picks specific address values we want to compare when determining equality and removes
 * any properties that have `null`, `undefined` or `''` values.
 * @param {IDeliveryAddress & { shouldSave?: boolean }} address
 * @example
 * getNormalizedAddress({
 *  addressLine1: '123 Drury Ln',
 *  addressLine2: '',
 *  city: null,
 *  zip: undefined
 * }); // { addressLine1: '123 Drury Ln' }
 */
const getNormalizedAddress = (address: IDeliveryAddress & { shouldSave?: boolean }) => {
  return pickBy<string>(
    pick(address, 'addressLine1', 'addressLine2', 'city', 'state', 'zip', 'country', 'phoneNumber')
  );
};

export const deliveryAddressIsSaved = (
  addresses: IDeliveryAddress[],
  address: IDeliveryAddress & { shouldSave?: boolean }
): boolean => {
  const foundAddress = addresses.find(userAddress => {
    return isEqual(getNormalizedAddress(address), getNormalizedAddress(userAddress));
  });

  return Boolean(foundAddress);
};

const SaveAddressSelection: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const { user } = useAuthContext();
  const { deliveryAddress, orderPhoneNumber, updateShouldSaveDeliveryAddress } = useOrderContext();

  const addresses = user?.details?.deliveryAddresses || [];

  // if the user selected a saved address, show the option to save as disabled and already checked
  const isUsingSavedAddress = deliveryAddressIsSaved(addresses, {
    ...deliveryAddress,
    phoneNumber: orderPhoneNumber,
  });

  const SaveAddressLabel = (
    <Box paddingX="$2" flex="1">
      <Text variant="copyTwo">
        {formatMessage(
          { id: 'saveAddressLabel' },
          { disclaimer: formatMessage({ id: 'saveAddressDisclaimer' }) }
        )}
      </Text>
    </Box>
  );

  useEffect(() => {
    if (isUsingSavedAddress) {
      // if a previously saved address is used, send shouldSave as true
      // in order to ensure the latest address value is updated
      updateShouldSaveDeliveryAddress(true);
    }
  }, [isUsingSavedAddress, updateShouldSaveDeliveryAddress]);

  const handleSaveAddressClick = () => {
    updateShouldSaveDeliveryAddress(!deliveryAddress.shouldSave);
  };

  return (
    <Checkbox
      testID="save-address-selection"
      isChecked={deliveryAddress.shouldSave}
      onChange={handleSaveAddressClick}
      name="checkbox-save-address-selection"
      value="checkbox-save-address-selection"
      isDisabled={isUsingSavedAddress}
      alignItems="flex-start"
    >
      {SaveAddressLabel}
    </Checkbox>
  );
};

export default SaveAddressSelection;
