import { IServerOrder } from '@rbi-ctg/menu';
import { isDelivery as isDeliveryOrder } from 'utils/service-mode';

import { DeliveryStatus, OrderStatus } from './types';

interface IOrderPollSuccessfulOptions {
  order: IServerOrder;
  orderSuccessStatus: OrderStatus | OrderStatus[];
  deliverySuccessStatus: DeliveryStatus | DeliveryStatus[];
}

interface IOrderPollFailureOptions {
  order: IServerOrder;
  orderFailureStatus: OrderStatus | OrderStatus[];
  deliveryFailureStatus: DeliveryStatus | DeliveryStatus[];
}

export function orderPollSuccessful({
  order,
  orderSuccessStatus,
  deliverySuccessStatus,
}: IOrderPollSuccessfulOptions): boolean {
  if (!Array.isArray(orderSuccessStatus)) {
    orderSuccessStatus = [orderSuccessStatus];
  }

  if (!Array.isArray(deliverySuccessStatus)) {
    deliverySuccessStatus = [deliverySuccessStatus];
  }

  const orderSuccessful = orderSuccessStatus.includes(order.status);
  const isDelivery = isDeliveryOrder(order.cart.serviceMode);

  if (isDelivery) {
    const deliverySuccessful =
      order.delivery && deliverySuccessStatus.includes(order.delivery.status);
    return Boolean(orderSuccessful && deliverySuccessful);
  }
  return orderSuccessful;
}

export function orderPollFailure({
  order,
  orderFailureStatus,
  deliveryFailureStatus,
}: IOrderPollFailureOptions): boolean {
  if (!Array.isArray(orderFailureStatus)) {
    orderFailureStatus = [orderFailureStatus];
  }

  if (!Array.isArray(deliveryFailureStatus)) {
    deliveryFailureStatus = [deliveryFailureStatus];
  }

  const orderFailure = orderFailureStatus.includes(order.status);
  const isDelivery = isDeliveryOrder(order.cart.serviceMode);

  if (isDelivery) {
    const deliveryFailure = order.delivery && deliveryFailureStatus.includes(order.delivery.status);
    return Boolean(orderFailure || deliveryFailure);
  }
  return orderFailure;
}
