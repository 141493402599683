import * as React from 'react';

import { IStoreDiagnosticData } from 'components/store-diagnostic-modal';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { useStoreCard } from 'hooks/store-card';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

interface IUseDiagnosticsOptions {
  restaurant: IRestaurantNode;
}

export const useDiagnostics = ({ restaurant }: IUseDiagnosticsOptions) => {
  const isEnabled = useFlag(LaunchDarklyFlag.ENABLE_INTERNAL_DIAGNOSTIC_TOOLS);
  const [showModal, setShowModal] = React.useState(false);

  const onClick = React.useMemo(() => {
    return isEnabled ? () => setShowModal(true) : undefined;
  }, [isEnabled]);

  const onDismiss = React.useCallback(() => setShowModal(false), []);

  const { driveThruOpen, diningRoomOpen, isMobileOrderingAvailable, isRestaurantPosOnline } =
    useStoreCard({ restaurant, shouldGetRemoteAvailabilityData: false });

  const { restaurantCanBeSelected } = useServiceModeStatus(restaurant);

  const data: IStoreDiagnosticData = {
    driveThruOpen,
    diningRoomOpen,
    isRestaurantOpen: restaurantCanBeSelected,
    isMobileOrderingAvailable,
    isRestaurantPosOnline,
    restaurantName: restaurant.name || '',
    restaurantHasMobileOrdering: Boolean(restaurant.hasMobileOrdering),
    restaurantCountry: restaurant?.physicalAddress?.country || '',
    restaurantStreetAddress: restaurant?.physicalAddress?.address1 || '',
  };

  return {
    data,
    onClick,
    onDismiss,
    showModal,
  };
};
