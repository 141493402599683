import { Box } from '@fhs-legacy/universal-components';

export const MapContainer = Box.withConfig({
  height: 'full',
  _web: {
    height: {
      base: 'full',
      desktop: '600px',
    },
  },
});
