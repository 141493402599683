import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Box,
  Button,
  HStack,
  Header,
  Icon,
  Link,
  Text,
  addWithConfig,
} from '@fhs-legacy/universal-components';
import { IDeliveryDriver } from '@rbi-ctg/menu';
import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import CourierMarkerSvg from 'pages/order-confirmation/delivery/icons/courier-image';

import theme from './theme';

const Main = HStack.withConfig({
  marginBottom: '$3.5',
  justifyContent: 'space-around',
  alignItems: 'center',
});

const Heading = Header.withConfig({
  color: Styles.color.black,
  fontSize: 'lg',
  lineHeight: 'md',
  margin: 0,
  marginBottom: '$4',
  textTransform: 'uppercase',
  variant: 'headerFour',
});

const CourierInfoContainer = HStack.withConfig({});

const CourierImg = Box.withConfig({
  alignItems: 'center',
  marginRight: '$2',
  borderRadius: 'full',
});

const CourierInfo = Box.withConfig({
  justifyContent: 'center',
  _text: {
    color: theme.courierInfoColor,
  },
});

const CourierName = Text.withConfig({
  fontSize: 'md',
  marginRight: '$2',
  isTruncated: true,
  maxWidth: {
    desktop: '100%',
    base: '$20',
  },
});

// TODO: uncomment once make and model become available
// const VehicleInfo = Text.withConfig({
//   fontSize: 'xs',
// });

const ContactCourier = Box;

const CourierContactInfoContainer = HStack.withConfig({
  justifyContent: 'space-between',
  borderTopWidth: 1,
  borderTopColor: Styles.color.grey.four,
  paddingTop: '$2',
});

const CourierContactInfo = Box;

const ContactPrompt = Text.withConfig({
  fontSize: 'xs',
  color: theme.contactPromptColor,
});

const ContactInfo = Link.withConfig({
  fontSize: 'sm',
  color: theme.contactInfoColor,
  isUnderlined: false,
});

const CloseContact = Button.withConfig({
  alignItems: 'center',
  padding: '$2',
  backgroundColor: Styles.color.white,
  _pressed: {
    background: Styles.shadowColor,
  },
});

const ContactButton = addWithConfig(ActionButton).withConfig({
  maxWidth: '$32',
});

interface ICourierDetailProps {
  driver: IDeliveryDriver;
}

const CourierDetail: React.FC<React.PropsWithChildren<ICourierDetailProps>> = ({ driver }) => {
  const [isContactVisible, setIsContactVisible] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  return (
    <>
      <Heading>{formatMessage({ id: 'yourCourier' })}</Heading>
      <Main>
        <CourierInfoContainer>
          <CourierImg testID="driver-img">
            {/* TODO: RN - Figure out how we want to handle image url sources not from sanity */}
            {/* <Picture image={driver?.profileImageUrl} alt="courier" /> */}
            <CourierMarkerSvg />
          </CourierImg>
          <CourierInfo>
            <CourierName testID="driver-name" data-private>
              {driver?.name || formatMessage({ id: 'noCourierName' })}
            </CourierName>
            {/* {TODO: uncomment this line once make and model become available} */}
            {/* <VehicleInfo>Make & Model</VehicleInfo> */}
          </CourierInfo>
        </CourierInfoContainer>
        <ContactCourier>
          <ContactButton
            testID="driver-contact"
            variant={ActionButtonVariants.OUTLINE}
            fullWidth
            disabled={isContactVisible}
            onPress={() => setIsContactVisible(true)}
            size={ActionButtonSizes.SMALL}
          >
            {formatMessage({ id: 'contact' })}
          </ContactButton>
        </ContactCourier>
      </Main>
      {isContactVisible && (
        <CourierContactInfoContainer>
          <CourierContactInfo>
            <ContactPrompt>{formatMessage({ id: 'contactCourier' })}</ContactPrompt>
            <ContactInfo href={`tel:${driver?.phoneNumber}`} testID="driver-phone" data-private>
              {driver?.phoneNumber || formatMessage({ id: 'noCourierPhone' })}
            </ContactInfo>
          </CourierContactInfo>
          <CloseContact onPress={() => setIsContactVisible(false)} testID="driver-contact-close">
            <Icon variant="cancel" color={theme.iconXFill} width="14px" />
          </CloseContact>
        </CourierContactInfoContainer>
      )}
    </>
  );
};

export default CourierDetail;
