import { isEmpty } from 'lodash';

export function appendObjectToQueryString(path: string, object: object): string {
  if (!object || isEmpty(object)) {
    return path;
  }

  const appendedParams = Object.keys(object)
    .map(key => {
      const encodedKey = encodeURIComponent(key);
      const value = object[key];
      const processedValue = typeof value === 'object' ? JSON.stringify(value) : value;
      const encodedValue = encodeURIComponent(processedValue);
      return `${encodedKey}=${encodedValue}`;
    })
    .join('&');
  const concatChar = path.includes('?') ? '&' : '?';
  return `${path}${concatChar}${appendedParams}`;
}
