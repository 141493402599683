import { ApolloQueryResult, QueryHookOptions, useApolloClient } from '@apollo/client';
import { remove } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import { MenuObjectTypes } from 'enums/menu';
import {
  ILoyaltyBenefitSwap,
  ILoyaltyOffersQuery,
  ILoyaltyUserOffersEligibleItemsQuery,
  ILoyaltyUserOffersQuery,
  IOffersFragment,
  LoyaltyOfferRedemptionType,
  LoyaltyOfferType,
  LoyaltyServiceMode,
  LoyaltyUserOffersDocument,
  LoyaltyUserOffersEligibleItemsDocument,
  OfferRedemptionType,
  useLoyaltyOffersLazyQuery,
  useLoyaltyUserOffersQuery,
} from 'generated/graphql-gateway';
import {
  useFeatureSortedLoyaltyOffersQuery,
  useLoyaltySystemwideOffersByIdsQuery,
} from 'generated/sanity-graphql';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { IEntriesIdsMap } from 'state/global-state/models/loyalty/offers/offers.types';
import {
  flattenEntriesToMap,
  parseEntry,
  parseOffers,
} from 'state/global-state/models/loyalty/offers/offers.utils';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  IEvaluateEligibleItemsOptions,
  IncentiveEligibleItem,
  IncentiveEvaluationMap,
} from 'state/loyalty/hooks/types';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import logger from 'utils/logger';

import { LoyaltyOffer } from '../types';

import {
  IEvaluateLoyaltyUserIncentivesOptions,
  IQueryLoyaltyUserOffersOptions,
  isSwap,
} from './types';
import { useGetPersonalizedOffer } from './use-get-personalized-offer';
import { useLoyaltyOffersEvaluation } from './use-loyalty-offers-evaluation';
import { usePersonalizedOffers } from './use-personalized-offers';
import { createIncentiveEligibleItems } from './utils/helpers';

export const useLoyaltyOffers = () => {
  // This flag is parent to the next ones
  const loyaltyOffersEnabled = Boolean(useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_OFFERS));
  // This flag enables surprise offers including swaps
  const loyaltySurpriseOffersEnabled = Boolean(
    useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_SURPRISE_OFFERS)
  );

  const client = useApolloClient();
  const [evaluating, setEvaluating] = useState(false);
  const [isQueryingUserOffers, setIsQueryingUserOffers] = useState(false);
  const [isOfferValidationError, setOfferValidationError] = useState(false);
  const dispatch = useAppDispatch();
  const loyaltyUserData = useAppSelector(selectors.loyalty.selectUser);
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const userOffers = useAppSelector(selectors.loyalty.selectUserOffers);
  const discountAppliedCmsOffers = useAppSelector(selectors.loyalty.selectDiscountAppliedCmsOffer);
  const { serviceMode } = useServiceModeContext();
  const { store } = useStoreContext();
  const sortedOffersRef = useRef<LoyaltyOffer[]>([]);
  const prevPersonalizedOffersRef = useRef<LoyaltyOffer[]>([]);
  const cartEntriesIdsMap = useRef<IEntriesIdsMap>({});

  const [fetchPersonalizedOfferData, { loading: personalizedDataLoading }] =
    usePersonalizedOffers();
  const storeId = store?.number;

  const { getPersonalizedOffer } = useGetPersonalizedOffer();
  const { loading: cmsOffersLoading, refetch: refetchCmsOffers } =
    useFeatureSortedLoyaltyOffersQuery({
      variables: {
        id: 'feature-loyalty-offers-ui-singleton',
      },
      onCompleted(data) {
        if (data?.LoyaltyOffersUI?.sortedSystemwideOffers) {
          sortedOffersRef.current = sortedOffersRef.current.concat(
            data.LoyaltyOffersUI.sortedSystemwideOffers as LoyaltyOffer[]
          );
        }
      },
    });
  const getValidOfferWithSwap = useCallback(
    (offer: IOffersFragment) => {
      const isValidSwapOffer = (singleBenefit: ILoyaltyBenefitSwap) => {
        // Checking swap from value is in cartEntries
        const isValidSwap = cartEntriesIdsMap.current[singleBenefit.value.from];

        if (isValidSwap) {
          dispatch(actions.loyalty.setUpsizeAvailable(true));
        }
        return isValidSwap;
      };
      const isSwapApplied = appliedOffers.some(entry => !!entry.swap && entry.id === offer.id);

      // Filtering valid benefits from offer
      const benefits = offer?.benefits?.filter(benefit => {
        // filter out all the benefits if the swap was applied
        if (isSwap(benefit) && !isSwapApplied) {
          return isValidSwapOffer(benefit);
        }
        // Offer is not a swap so don't filter it
        return true;
      });

      return { ...offer, ...(benefits && { benefits }) };
    },
    [appliedOffers, dispatch]
  );

  const onUserOffersQueryComplete = useCallback(
    async (data: ILoyaltyUserOffersQuery) => {
      const loyaltyUser = data?.loyaltyUserV2;
      const offerRedemptionAvailableAfter =
        loyaltyUser?.offerRedemptionAvailability?.availableAfter;

      if (offerRedemptionAvailableAfter) {
        dispatch(actions.loyalty.setOfferRedemptionAvailableAfter(offerRedemptionAvailableAfter));
      }

      const loyaltyUserOffers = loyaltyUser?.offers;
      if (!Array.isArray(loyaltyUserOffers)) {
        return;
      }

      // Fetch data needed to build personalized offer
      const personalizedOfferData = await fetchPersonalizedOfferData(loyaltyUserOffers);

      const personalizedUserOffers: LoyaltyOffer[] = [];
      const systemWideOffers: IOffersFragment[] = [];

      loyaltyUserOffers.forEach(offer => {
        let validOffer = offer;

        // Do not validate surprise offers if feature is not enabled
        if (loyaltySurpriseOffersEnabled) {
          validOffer = getValidOfferWithSwap(offer);
          dispatch(actions.loyalty.setSurpriseOfferIfAvailable(offer));
        }

        if (validOffer.type === LoyaltyOfferType.PERSONALIZED && personalizedOfferData) {
          const personalizedOffer = getPersonalizedOffer(validOffer, personalizedOfferData);
          if (personalizedOffer) {
            personalizedUserOffers.push(personalizedOffer);
          }
        }

        if (validOffer.type === LoyaltyOfferType.GLOBAL) {
          systemWideOffers.push(validOffer);
        }
      });

      // Remove all previous personalized offers first since it might have already been redeemed
      prevPersonalizedOffersRef.current.forEach(personalizedOffer => {
        remove(sortedOffersRef.current, sortedOffer => sortedOffer._id === personalizedOffer._id);
      });
      sortedOffersRef.current = sortedOffersRef.current.concat(personalizedUserOffers);
      if (discountAppliedCmsOffers) {
        sortedOffersRef.current = sortedOffersRef.current.concat([discountAppliedCmsOffers]);
      }
      // Save snapshot of new personalized offer
      prevPersonalizedOffersRef.current = personalizedUserOffers;
      dispatch(actions.loyalty.setCmsOffers(sortedOffersRef.current));
      dispatch(actions.loyalty.setUserOffers(systemWideOffers));
      dispatch(actions.loyalty.setPersonalizedOffers(personalizedUserOffers));
    },
    [
      discountAppliedCmsOffers,
      dispatch,
      fetchPersonalizedOfferData,
      getPersonalizedOffer,
      getValidOfferWithSwap,
      loyaltySurpriseOffersEnabled,
    ]
  );

  const [queryEngineOffers, { loading: engineOffersLoading }] = useLoyaltyOffersLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      serviceMode: LoyaltyServiceMode[serviceMode || ''] || undefined,
      storeId,
      omitInvalids: false,
    },
    onCompleted: (data: ILoyaltyOffersQuery) => {
      if (data?.loyaltyOffersV2?.length) {
        dispatch(actions.loyalty.setCmsOffers(sortedOffersRef.current));
        dispatch(actions.loyalty.setOffers(data.loyaltyOffersV2 as IOffersFragment[]));
      }
    },
  });

  // Find all surprise offers ids for the current user
  const surpriseOffersEngineIds = userOffers.reduce((acc: string[], offer) => {
    if (offer.redemptionType === LoyaltyOfferRedemptionType.SURPRISE && offer.sanityId) {
      acc.push(offer.sanityId);
    }
    return acc;
  }, []);

  // Attach applied offers ids to get the full collection of offers from CMS
  const appliedLoyaltyOffersIds = surpriseOffersEngineIds.concat(
    appliedOffers.map(({ cmsId }) => cmsId || '')
  );

  const loyaltyUserId = loyaltyUserData?.id || '';
  const { loading: queryUserAppliedOffersLoading } = useLoyaltyUserOffersQuery({
    fetchPolicy: 'no-cache',
    skip: !loyaltyUserId || !appliedLoyaltyOffersIds.length || userOffers.length > 0,
    variables: {
      loyaltyId: loyaltyUserId,
    },
    onCompleted: onUserOffersQueryComplete,
  });

  const { loading: swoffersLoading } = useLoyaltySystemwideOffersByIdsQuery({
    variables: {
      ids: appliedLoyaltyOffersIds,
    },
    skip: !appliedLoyaltyOffersIds.length,
    onCompleted(data) {
      if (data?.allSystemwideOffers) {
        sortedOffersRef.current = sortedOffersRef.current.concat(
          data.allSystemwideOffers as LoyaltyOffer[]
        );

        dispatch(actions.loyalty.unshiftCmsOffers(sortedOffersRef.current));

        const upsizeAndDiscountIds = appliedOffers
          .filter(({ swap, cartId }) => !!swap || cartId === 'discount-offer')
          .map(({ id }) => id || '');

        if (upsizeAndDiscountIds.length) {
          queryUserOffers({
            variables: {
              loyaltyId: loyaltyUserId,
              where: {
                omitInvalids: false,
                ids: upsizeAndDiscountIds,
              },
            },
          });
        }
      }
    },
  });

  const queryUserOffers = useCallback(
    async (options: QueryHookOptions) => {
      if (!options?.variables?.loyaltyId) {
        return;
      }
      setIsQueryingUserOffers(true);
      dispatch(actions.loyalty.setOffersLoading(true));

      try {
        const { data } = await client.query<ILoyaltyUserOffersQuery>({
          ...options,
          fetchPolicy: 'no-cache',
          query: LoyaltyUserOffersDocument,
        });

        if (data) {
          await onUserOffersQueryComplete(data);
        }
      } catch (e) {
        logger.error(`Error fetching user offers: ${e}`);
      } finally {
        setIsQueryingUserOffers(false);
        dispatch(actions.loyalty.setOffersLoading(false));
      }
    },
    [client, dispatch, onUserOffersQueryComplete]
  );

  const queryLoyaltyUserOffers = useCallback(
    ({
        redemptionTypes,
        omitInvalids = false,
      }: {
        redemptionTypes: OfferRedemptionType[];
        omitInvalids?: boolean;
      }) =>
      ({
        loyaltyId,
        cartEntries,
        appliedRewards,
        subtotalAmount,
        appliedLoyaltyOffers = [],
      }: IQueryLoyaltyUserOffersOptions) => {
        const parsedEntries = cartEntries?.reduce(parseEntry(appliedRewards), []);

        cartEntriesIdsMap.current = (parsedEntries || []).reduce(flattenEntriesToMap, {});

        queryUserOffers({
          variables: {
            loyaltyId,
            where: {
              appliedIncentives: parseOffers(appliedLoyaltyOffers),
              cartEntries: parsedEntries,
              serviceMode: serviceMode || undefined,
              redemptionTypes,
              storeId,
              subtotalAmount,
              omitInvalids,
              skipTimeValidation: true,
            },
          },
        });
      },
    [queryUserOffers, serviceMode, storeId]
  );

  // Offers without loyaltyId should always be of redemption type STANDARD
  const queryLoyaltyOffers = useCallback(
    ({ subtotalAmount = 0 }: any) => {
      queryEngineOffers({
        variables: {
          serviceMode: LoyaltyServiceMode[serviceMode || ''],
          storeId,
          subtotalAmount,
          redemptionTypes: [LoyaltyOfferRedemptionType.STANDARD],
        },
      });
    },
    [queryEngineOffers, serviceMode, storeId]
  );

  const queryLoyaltyUserStandardOffers = useCallback(
    queryLoyaltyUserOffers({ redemptionTypes: [OfferRedemptionType.STANDARD] }),
    [queryLoyaltyUserOffers]
  );

  const queryLoyaltyUserSurpriseOffers = useCallback(
    queryLoyaltyUserOffers({
      redemptionTypes: [OfferRedemptionType.SURPRISE, OfferRedemptionType.SWAP],
      omitInvalids: true,
    }),
    [queryLoyaltyUserOffers]
  );

  const { evaluateLoyaltyOffers } = useLoyaltyOffersEvaluation();

  const evaluateLoyaltyUserIncentives = useCallback(
    async ({
      loyaltyId,
      appliedLoyaltyOffers,
      cartEntries,
      appliedRewards,
      subtotalAmount,
      paymentMethod,
      skipTimeValidation,
      pickUpTime,
      appliedLoyaltyDiscountOffer,
      incentiveSelections,
    }: IEvaluateLoyaltyUserIncentivesOptions) => {
      const parsedEntries = cartEntries?.reduce(parseEntry(appliedRewards), []);

      cartEntriesIdsMap.current = (parsedEntries || []).reduce(flattenEntriesToMap, {});

      setEvaluating(true);

      let evaluationResult: IncentiveEvaluationMap | null = null;
      try {
        const { incentiveFeedbackMap, incentiveEligibleItems } = await evaluateLoyaltyOffers({
          loyaltyId,
          appliedOffers: appliedLoyaltyOffers,
          cartEntries: parsedEntries,
          subtotalAmount,
          paymentMethod,
          serviceMode,
          storeId,
          skipTimeValidation,
          pickUpTime,
          incentiveSelections,
        });
        evaluationResult = incentiveFeedbackMap;

        dispatch(actions.loyalty.setOffersEligibleItems(incentiveEligibleItems));

        if (evaluationResult) {
          dispatch(actions.loyalty.setOffersFeedbackMap(evaluationResult));
          const hasOfferError = appliedLoyaltyOffers.some(offer => {
            if (evaluationResult && offer.id && evaluationResult[offer.id]) {
              if (evaluationResult[offer.id].length > 0) {
                return true;
              }
            }
            return false;
          });
          setOfferValidationError(hasOfferError);
        }

        let isDiscountNotApplied = false;
        if (appliedLoyaltyDiscountOffer) {
          const isOfferWithEligibleItems = !!appliedLoyaltyDiscountOffer?.incentives?.some(
            incentive => incentive?._type === MenuObjectTypes.ITEM_GROUP_DISCOUNT
          );
          isDiscountNotApplied = isOfferWithEligibleItems && incentiveEligibleItems.length === 0;
        }
        dispatch(actions.loyalty.setIsDiscountNotApplied(isDiscountNotApplied));
      } finally {
        setEvaluating(false);
      }

      return evaluationResult;
    },
    [dispatch, evaluateLoyaltyOffers, serviceMode, storeId]
  );

  const evaluateEligibleItems = useCallback(
    async ({
      loyaltyId,
      appliedLoyaltyOffers,
      cartEntries,
      appliedRewards,
      selectedOfferSelections,
    }: IEvaluateEligibleItemsOptions) => {
      let offersEligibleItems: IncentiveEligibleItem[] = [];
      const parsedEntries = cartEntries?.reduce(parseEntry(appliedRewards), []);
      const offerIds = parseOffers(appliedLoyaltyOffers).map(parsedOffer => parsedOffer.id);

      try {
        const { data }: ApolloQueryResult<ILoyaltyUserOffersEligibleItemsQuery> =
          await client.query({
            query: LoyaltyUserOffersEligibleItemsDocument,
            fetchPolicy: 'no-cache',
            variables: {
              loyaltyId,
              where: {
                cartEntries: parsedEntries,
                ids: offerIds,
                serviceMode,
                storeId,
                omitInvalids: false,
                incentiveSelections: selectedOfferSelections,
              },
            },
          });

        const offers = data?.loyaltyUserV2?.offers;

        if (offers) {
          offersEligibleItems = createIncentiveEligibleItems(offers);
        }
      } catch (error) {
        logger.error(`Error fetching offers eligible items: ${error}`);
      }

      dispatch(actions.loyalty.setOffersEligibleItems(offersEligibleItems));

      return offersEligibleItems;
    },
    [dispatch, serviceMode, storeId, client]
  );

  useEffect(() => {
    dispatch(
      actions.loyalty.setOffersLoading(
        engineOffersLoading ||
          personalizedDataLoading ||
          cmsOffersLoading ||
          swoffersLoading ||
          evaluating ||
          queryUserAppliedOffersLoading ||
          isQueryingUserOffers
      )
    );
  }, [
    dispatch,
    engineOffersLoading,
    personalizedDataLoading,
    cmsOffersLoading,
    swoffersLoading,
    evaluating,
    queryUserAppliedOffersLoading,
    isQueryingUserOffers,
  ]);

  return {
    evaluateLoyaltyUserIncentives,
    loyaltyOffersEnabled,
    loyaltySurpriseOffersEnabled,
    queryLoyaltyOffers,
    queryLoyaltyUserStandardOffers,
    queryLoyaltyUserSurpriseOffers,
    refetchCmsOffers,
    cmsOffersLoading,
    isOfferValidationError,
    setOfferValidationError,
    isOfferEvaluating: evaluating,
    evaluateEligibleItems,
  };
};
