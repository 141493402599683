import * as React from 'react';

import { ContentLoadingContainer, SectionHeading } from 'pages/support-v2/support-form-v2.styled';

interface IErrorViewProps {
  message: string;
}

/**
 * Full content area view with error message.
 */

export const ErrorView = ({ message }: IErrorViewProps) => (
  <ContentLoadingContainer>
    <SectionHeading testID="error-message">{message}</SectionHeading>
  </ContentLoadingContainer>
);
