import React from 'react';

import { Svg, SvgRect } from '@fhs-legacy/universal-components';

interface IProgressBarProps {
  percentage: number;
  width?: number | string;
  height?: number | string;
  fillColor?: string;
}

export const ProgressBar: React.FC<React.PropsWithChildren<IProgressBarProps>> = ({
  percentage,
  width = '100%',
  height = '25px',
  fillColor = Styles.color.primary,
}) => {
  return (
    <Svg width={width} height={height}>
      <SvgRect
        x="0"
        y="10"
        rx="5"
        ry="5"
        width="100%"
        height="10"
        fill={Styles.color.grey.six}
        fillOpacity="0.5"
      />
      <SvgRect x="0" y="10" rx="5" ry="5" width={`${percentage}%`} height="10" fill={fillColor} />
    </Svg>
  );
};
