import React, { ComponentProps, useState } from 'react';
import { useIntl } from 'react-intl';
import { FlatList, Keyboard, StyleSheet } from 'react-native';

import { Box, FormControl, Icon, Input } from '@fhs-legacy/universal-components';
import { AutocompletePrediction } from '@rbilabs/expo-maps';
import { styleToNumber } from 'utils/style/style-to-number';
import { STORE_LOCATOR_SUGGESTION_AT } from 'utils/test-ids';

import {
  Container,
  MainPredictionText,
  OptionsListWrapper,
  StyledAddressInput,
  StyledInputIcon,
  StyledItemText,
  StyledOptionsListItem,
} from './autocomplete-options-dropdown.styles';
import { AutocompleteDropdownProps } from './types';

type ITextInputProps = ComponentProps<typeof Input> & { label: string };

const AutocompleteOptionsDropdown = React.forwardRef<
  HTMLInputElement,
  ITextInputProps & AutocompleteDropdownProps & { initialFocus?: boolean }
>(
  (
    {
      testID,
      clearTextIconTitle,
      errorMessage,
      label,
      noPredictionsFoundText,
      predictions,
      onInputChange,
      onSelection,
      value,
      onBlur,
      initialFocus = false,
    },
    ref
  ) => {
    const [isItemSelected, setIsItemSelected] = useState(false);
    const [isInputFocus, setIsInputFocus] = useState(initialFocus);
    const { formatMessage } = useIntl();

    const handleOnSelectItem = (item: AutocompletePrediction) => {
      onSelection({
        address: item.description || '',
        placeId: item.placeId || '',
      });
      setIsItemSelected(true);
      Keyboard.dismiss();
    };

    const RenderListItems = ({ item, index }: { item: AutocompletePrediction; index: number }) => (
      <StyledOptionsListItem
        key={item.placeId}
        onPress={() => {
          handleOnSelectItem(item);
          setIsInputFocus(false);
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          testID={STORE_LOCATOR_SUGGESTION_AT(index)}
          _web={{
            flexDirection: 'column',
          }}
        >
          <MainPredictionText fontSize="xs">{item.mainText}</MainPredictionText>
          <StyledItemText fontSize="xs">{item.secondaryText}</StyledItemText>
        </Box>
      </StyledOptionsListItem>
    );

    const InputRightIcon = () => {
      const accessibilityLabel = formatMessage({ id: 'searchLocation' });
      return value ? (
        <StyledInputIcon
          variant="cancel"
          title={clearTextIconTitle}
          // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
          onPress={() => handleOnSelectItem({})}
          _web={{ cursor: 'pointer' }}
          accessibilityLabel={accessibilityLabel}
        />
      ) : (
        <StyledInputIcon variant="search" accessibilityLabel={accessibilityLabel} />
      );
    };

    const handleInputChange = (inputValue: string) => {
      onInputChange(inputValue);
      setIsItemSelected(false);
    };

    const handleInputFocus = () => {
      setIsInputFocus(true);
    };

    return (
      <Container>
        <FormControl isInvalid={!!errorMessage} margin={0}>
          <StyledAddressInput
            testID={testID}
            placeholder={label}
            value={value}
            InputRightElement={InputRightIcon()}
            onChangeText={handleInputChange}
            wrapperRef={ref}
            onFocus={handleInputFocus}
            onBlur={onBlur}
          />
          <FormControl.ErrorMessage leftIcon={<Icon variant="error" size="xs" />}>
            {errorMessage}
          </FormControl.ErrorMessage>
        </FormControl>
        {isInputFocus && (
          <OptionsListWrapper>
            {value &&
              value.length > 2 &&
              !isItemSelected &&
              (!predictions?.length ? (
                <StyledOptionsListItem disabled>
                  <StyledItemText fontSize="2xs">{noPredictionsFoundText}</StyledItemText>
                </StyledOptionsListItem>
              ) : (
                <FlatList
                  style={styles.flatList}
                  data={predictions}
                  renderItem={RenderListItems}
                  keyExtractor={(item: AutocompletePrediction) => item.placeId}
                  scrollEnabled={false}
                  keyboardShouldPersistTaps="always"
                />
              ))}
          </OptionsListWrapper>
        )}
      </Container>
    );
  }
);

const styles = StyleSheet.create({
  flatList: {
    borderBottomLeftRadius: styleToNumber(Styles.borderRadius),
    borderBottomRightRadius: styleToNumber(Styles.borderRadius),
    overflow: 'hidden',
  },
});

export default AutocompleteOptionsDropdown;
