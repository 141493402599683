import * as React from 'react';
import { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { SceneRendererProps } from 'react-native-tab-view';

import { TabView as TabViewComponent } from 'components/tab-view';
import { ITab } from 'components/tab-view/types';
import { OrderLocatorTab } from 'state/launchdarkly/variations';

import { IStoreSelectorProps } from '../types';

import { FavoriteStoreCards } from './favorite-store-cards';
import { NearbyStoreCards } from './nearby-store-cards';
import { RecentStoreCards } from './recent-store-cards';

export const TabView: FC<React.PropsWithChildren<IStoreSelectorProps>> = React.memo(
  ({ onDismiss, updateStore }) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const { formatMessage } = useIntl();

    const orderedTabs = [
      OrderLocatorTab.NEARBY,
      OrderLocatorTab.RECENT,
      OrderLocatorTab.FAVORITE,
    ].map(tab => ({
      key: tab,
      title: formatMessage({ id: tab }),
    }));

    const handleSelectStore = useCallback(
      (storeId: string) => {
        onDismiss();
        updateStore(storeId);
      },
      [onDismiss, updateStore]
    );

    const renderScene = ({ route }: SceneRendererProps & { route: ITab }) => {
      if (route.key === OrderLocatorTab.NEARBY) {
        return <NearbyStoreCards handleSelectStore={handleSelectStore} />;
      } else if (route.key === OrderLocatorTab.RECENT) {
        return <RecentStoreCards handleSelectStore={handleSelectStore} />;
      }

      return <FavoriteStoreCards handleSelectStore={handleSelectStore} />;
    };

    return (
      <TabViewComponent
        activeTabIndex={currentTabIndex}
        handleTabIndexChange={setCurrentTabIndex}
        tabs={orderedTabs}
        renderScene={renderScene}
        lazy
      />
    );
  }
);
