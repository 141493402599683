import { useWindowDimensions } from 'react-native';
import { isNil } from 'lodash';

type QueryKeys = 'maxWidth' | 'minWidth' | 'maxHeight' | 'minHeight' | 'orientation';

type SubQuery = {
  [queryKey in QueryKeys]?: number | string;
};
type Query = Array<SubQuery>;

export function useMediaQuery(query: SubQuery | Query) {
  const dims = useWindowDimensions();
  const height = dims?.height;
  const width = dims?.width;

  return iterateQuery(query, height, width);
}

function queryResolver(query: any, width?: number, height?: number) {
  for (const queryKey in query) {
    if (!calculateQuery(queryKey, query[queryKey], height, width)) {
      return false;
    }
  }
  return true;
}

function iterateQuery(query: SubQuery | Query, height?: number, width?: number) {
  const queryResults: boolean[] = [];
  if (Array.isArray(query)) {
    query.forEach((subQuery: SubQuery) => {
      queryResults.push(queryResolver(subQuery, width, height));
    });
  } else {
    queryResults.push(queryResolver(query, width, height));
  }
  return queryResults;
}

function calculateQuery(key: string, val?: number | string, height?: number, width?: number) {
  if (isNil(width) || isNil(height) || isNil(val)) {
    return false;
  }

  const valAsNum = typeof val === 'string' ? parseFloat(val) : val;

  switch (key) {
    case 'maxWidth':
      return width <= valAsNum;

    case 'minWidth':
      return width >= valAsNum;

    case 'maxHeight':
      return height <= valAsNum;

    case 'minHeight':
      return height >= valAsNum;

    case 'orientation': {
      if (width > height) {
        return val === 'landscape';
      }

      return val === 'portrait';
    }
    default:
      return false;
  }
}
