import { router } from 'expo-router';
import { keyBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Platform, StatusBar } from 'react-native';

import withAddIncentiveToCart from 'components/with-add-incentive-to-cart';
import { RedemptionMethod } from 'components/with-add-incentive-to-cart/types';
import { usePathname } from 'hooks/use-pathname';
import { LoyaltyLoader } from 'pages/loyalty/loyalty-loader';
import MenuItemUnavailable from 'pages/menu-content/item-unavailable';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { useLoyaltyContext } from 'state/loyalty';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { LoyaltyOffer } from 'state/loyalty/types';
import { useOrderContext } from 'state/order';
import { useUIContext } from 'state/ui';
import { maybeLocale, maybeLocaleRaw } from 'utils/graphql';
import { routes } from 'utils/routing';

import ModalSurpriseView from './modal-surprise.view';
import { IModalContent, ModalSurpriseProps } from './types';
import { useFeatureSurpriseModalContent } from './use-feature-surprise-modal-content';

const ModalSurprise = ({ addIncentiveToCart }: ModalSurpriseProps) => {
  const [selectedIncentive, setSelectedIncentive] = useState<LoyaltyOffer>();
  const { loyaltySurpriseOffersEnabled } = useLoyaltyContext();
  const { loading: loyaltyLoading } = useLoyaltyUser();
  const { cartEntries } = useOrderContext();
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const offersLoading = useAppSelector(selectors.loyalty.selectOffersLoading);
  const cmsOffers = useAppSelector(selectors.loyalty.selectCmsSurpriseOffers);
  const userOffers = useAppSelector(selectors.loyalty.selectUserOffers);
  const surpriseAvailable = useAppSelector(selectors.loyalty.selectSurpriseAvailable);
  const dispatch = useAppDispatch();
  const { buildImageUrl } = useUIContext();
  const pathname = usePathname();

  const { data: modalContentData, loading: modalContentLoading } = useFeatureSurpriseModalContent();

  const appliedSurprise = appliedOffers.find(offer => Boolean(offer.isSurprise));
  const isCartRoute = pathname.startsWith(routes.cart);

  // Loyalty Engine offers map by id
  const userOffersMap = keyBy(userOffers, 'id');

  // Mapping CMS offers to Loyalty Engine offers
  const offers = useMemo(
    () => cmsOffers.filter(offer => userOffersMap[offer?.loyaltyEngineId || '']),
    [cmsOffers, userOffersMap]
  );

  const selectedOfferFromApplied = appliedSurprise
    ? offers.find(offer => offer._id === appliedSurprise?.cmsId)
    : offers[0];

  useEffect(() => {
    if (offers.length) {
      setSelectedIncentive(selectedOfferFromApplied);
    }
  }, [offers.length, selectedOfferFromApplied]);

  const handleDismiss = useCallback(() => {
    dispatch(actions.loyalty.resetSurpriseAvailability());
    if (!isCartRoute) {
      router.navigate(routes.base);
    }
  }, [isCartRoute, dispatch]);

  if (loyaltyLoading || modalContentLoading || offersLoading) {
    // Do not render loading animation in cart
    return !isCartRoute ? <LoyaltyLoader /> : null;
  }
  const hideModal =
    !offers?.length || !cartEntries?.length || appliedSurprise || !surpriseAvailable;

  if (!loyaltySurpriseOffersEnabled || (isCartRoute && hideModal)) {
    return null;
  }

  // Do not render offer unavailable in cart
  if (!isCartRoute && hideModal) {
    return <MenuItemUnavailable isOffer />;
  }

  const modalContent: IModalContent = {
    bgImage: buildImageUrl(
      modalContentData?.surpriseAndDelightModalContent?.backgroundImage?.locale || ''
    ),
    noThanksText: maybeLocale(modalContentData?.surpriseAndDelightModalContent?.noThanksText) || '',
    buttonText: maybeLocale(modalContentData?.surpriseAndDelightModalContent?.buttonText) || '',
    description: maybeLocale(modalContentData?.surpriseAndDelightModalContent?.description) || '',
    prompt: maybeLocale(modalContentData?.surpriseAndDelightModalContent?.prompt) || '',
    termsText: maybeLocaleRaw(modalContentData?.surpriseAndDelightModalContent?.termsText) ?? [],
    title: maybeLocale(modalContentData?.surpriseAndDelightModalContent?.title) || '',
  };

  const handleAddSurprise = (incentive: LoyaltyOffer) => {
    const surpriseIncentive = {
      ...incentive,
      isStackable: !!userOffersMap[incentive.loyaltyEngineId || '']?.isStackable,
    };

    addIncentiveToCart({
      incentive: surpriseIncentive,
      method: RedemptionMethod.MOBILE,
    });
    dispatch(actions.loyalty.resetSurpriseAvailability());
  };

  return (
    <>
      <StatusBar backgroundColor={Platform.OS === 'android' ? Styles.color.teal : ''} />
      <ModalSurpriseView
        offers={offers}
        selectedOffer={selectedIncentive}
        modalContent={modalContent}
        handleAddSurprise={handleAddSurprise}
        handleSelectOffer={setSelectedIncentive}
        handleDismiss={handleDismiss}
      />
    </>
  );
};

export default withAddIncentiveToCart(ModalSurprise);
