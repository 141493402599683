import React from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { MenuObject, SanityMenuObject } from '@rbi-ctg/menu';
import { ItemAvailabilityStatus } from 'enums/menu';
import { PosVendors } from 'utils/vendor-config';

import usePricingFunction from './hooks/use-pricing-function';
import {
  MenuOptionsProvider as LegacyMenuOptionsProvider,
  useMenuOptionsContext as legacyUseMenuOptionsContext,
} from './legacy-menu-options';
import {
  MenuOptionsProvider as NewMenuOptionsProvider,
  useMenuOptionsContext as newUseMenuOptionsContext,
} from './new-menu-options';

interface ICheckItemAvailability {
  vendor: PosVendors | null;
  itemId: string;
  restaurantPosDataId: string;
  storeNumber: string;
}

export type ItemAvailability = {
  availabilityStatus: ItemAvailabilityStatus;
  data?: MenuObject;
};

export type IMenuOptionsContext = {
  getMenuObject(id: string): IState;
  asyncGetMenuObject(id: string, customFilter?: ICustomFilter): Promise<IState>;
  asyncGetRawMenuObject(id: string): Promise<IState['data']>;
  filterForAvailability(
    menuData: SanityMenuObject[] | SanityMenuObject
  ): MenuObject | MenuObject[] | null;
  checkItemAvailability(args: ICheckItemAvailability): Promise<ItemAvailability>;
} & ReturnType<typeof usePricingFunction>;

export interface IState {
  data: MenuObject | null;
  loading: boolean;
}
interface ICustomFilter {
  (data: SanityMenuObject): MenuObject | MenuObject[] | null;
}
export const useMenuOptionsContext = legacyUseMenuOptionsContext;
export const useNewMenuOptionsContext = newUseMenuOptionsContext;

export const MenuOptionsProvider = ({ children }: IBaseProps) => (
  <LegacyMenuOptionsProvider>
    <NewMenuOptionsProvider>{children}</NewMenuOptionsProvider>
  </LegacyMenuOptionsProvider>
);
