import { format } from 'date-fns';
import { groupBy, orderBy } from 'lodash';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { TransactionChannel } from './types';

interface IDate {
  timeStamp: string;
  [key: string]: any;
}
interface IDateAndTime {
  date: string;
  time: string;
}

export const groupAndSortTransactionsByMonth = <T extends IDate>(tableData: T[]) => {
  const getTableGrouping = ({ timeStamp }: T) => format(new Date(timeStamp), 'yyyy-MM');

  const groupedTableData = Object.entries(groupBy(tableData, getTableGrouping));

  const groupedTableDataSortedByMonth = orderBy(groupedTableData, 0, 'desc');

  return groupedTableDataSortedByMonth;
};

const formatDateOptions = {
  year: '2-digit',
  month: 'numeric',
  day: 'numeric',
};

export const useTransactionDateFormatters = () => {
  const { formatDate, formatDateToParts, formatTime } = useIntl();

  const getMonth = useCallback(
    <T extends IDate>(t: T) => {
      const parts = formatDateToParts(t.timeStamp, { month: 'long' });
      const month = parts.find(x => x.type === 'month' || 'literal');
      return month && month.value;
    },
    [formatDateToParts]
  );

  const formatTransactionDate = useCallback(
    (timestamp: string): IDateAndTime => ({
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ year: string; month: string; d... Remove this comment to see the full error message
      date: formatDate(timestamp, formatDateOptions),
      time: formatTime(timestamp),
    }),
    [formatDate, formatTime]
  );

  return { getMonth, formatTransactionDate };
};

export const getTransactionMessageId = (channel: string) => {
  switch (channel) {
    case TransactionChannel.Support: {
      return 'serviceVisit';
    }
    case TransactionChannel.PointsExpiration: {
      return 'pointsExpired';
    }
    default: {
      return 'pointsEarnedLabel';
    }
  }
};
