import { IOffer } from '@rbi-ctg/menu';

export enum PromoCodeErrorMessageIds {
  default = 'promoCodeOfferUnknownException',
  expiredCode = 'promoCodeOfferExpiredCode',
  invalidCode = 'promoCodeOfferInvalidCode',
  redeemedCode = 'promoCodeCartRedeemedCode',
  unknownException = 'promoCodeOfferUnknownException',
  required = 'promoCodeOfferRequired',
}

export interface ICartPromoCodesViewProps {
  codeInput: string;
  isLoading: boolean;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  errorMessageId: PromoCodeErrorMessageIds | null;
  onSubmit: () => void;
  onChangeInput: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => void;
  appliedOfferName: string;
  appliedOfferError: boolean;
  appliedOfferDescription: string;
  cartPromoCodeOffers: IOffer[];
  removeAppliedOffer: () => void;
  showSavedPromotionsModal: boolean;
  onSelectedSavedPromotion: (selectedOffer: IOffer) => void;
  updateSavedPromotionsModal: (show: boolean, shouldNavigate?: boolean) => void;
  showConfirmSwitchOffersDialog: boolean;
  onConfirmSwitchOffers: () => void;
  onCancelSwitchOffers: () => void;
}
