import { Box, Header, Text, addWithConfig } from '@fhs-legacy/universal-components';
import ActionButtonLink from 'components/action-button';
import { theme } from 'styles/configure-theme';

export const EmptyStateWrapper = Box.withConfig({
  justifyContent: 'center',
  alignItems: 'center',
  margin: '$8',
  color: theme.token('text-default'),
});

export const StyledActionButtonLink = addWithConfig(ActionButtonLink).withConfig({
  style: { marginTop: '10%' },
});

export const HeaderText = Header.withConfig({
  variant: 'headerOne',
  textAlign: 'center',
  fontSize: '2xl',
  margin: 0,
  lineHeight: 'xl',
  marginTop: '$5',
});

export const SubText = Text.withConfig({
  variant: 'copyOne',
  marginTop: '$1',
  marginX: '$8',
  marginBottom: '$0',
  textAlign: 'center',
  fontWeight: Styles.fontWeight.black,
});

export const ImageWrapper = Box.withConfig({
  height: '152px',
  width: 'full',
  alignItems: 'center',
  marginTop: '$2',
});
