import { useCallback, useMemo, useState } from 'react';

import { IOffer } from '@rbi-ctg/menu';
import { IOfferFeedbackEntryFragment } from 'generated/rbi-graphql';
import { useFlag } from 'state/launchdarkly';
import { UIPattern } from 'state/offers/types';
import { BooleanFlags } from 'utils/launchdarkly';
import { RuleType, getUniqIdForOffer } from 'utils/offers';

export interface IUsePromoCodeOfferProps {
  sortedOffers: IOffer[];
  offersFeedback: Record<string, IOfferFeedbackEntryFragment> | undefined;
  uiPattern: UIPattern;
  launchDarklyFlag: BooleanFlags;
}

/**
 * @name shouldIncludePromoCodeOffer
 * @description
 *    promo code offers will be marked as isRedeemable = false until the user has unlocked
 *     the offer using a valid promo code
 *    unlike non-promo code offers, we still sometimes want to include these unredeemable offers
 *    -> ex. so that the user can enter the promo code to unlock the offer
 *
 *    WHEN WE WANT TO INCLUDE:
 *    -> ONLY ruleset is for requires-assignment
 *    -> has ruleset(s) other than requires-assignment, AND all all these other rulesets are passing
 * @returns {boolean}
 */
const shouldIncludePromoCodeOffer = (
  offer: IOffer,
  offersFeedback?: Record<string, IOfferFeedbackEntryFragment>
) => {
  const offerFeedback = offersFeedback?.[offer._id]?.redemptionEligibility?.evaluationFeedback;
  if (!offerFeedback) {
    return true;
  }
  return offerFeedback
    .filter(curRule => curRule && curRule.ruleSetType !== RuleType.RequiresAssignment)
    .every(curRule => curRule?.condition);
};

/**
 * @name usePromoCodeOffers
 * @description
 *   promo code offers are displayed on the offers page and are separate
 *   from cart promo code offers (ui pattern: CART_PROMO) because they
 *   have a completely separate UX.
 */
export default function usePromoCodeOffers({
  sortedOffers,
  offersFeedback,
  launchDarklyFlag,
  uiPattern,
}: IUsePromoCodeOfferProps) {
  const enablePromoCodeOffers = useFlag(launchDarklyFlag);
  const [redeemedOfferUniqIDs, setRedeemedOfferUniqIDs] = useState<string[]>([]);

  /**
   * allows setting isRedeemable to false upon redemption so that we don't have to re-fetch offers
   * unless absolutely necessary
   */
  const togglePromoCodeOfferRedeemable = useCallback((offerUniqID: string) => {
    setRedeemedOfferUniqIDs(offerUniqIDs => [...offerUniqIDs, offerUniqID]);
  }, []);

  // update promo code offers when sortedOffers changes
  const promoCodeOffers = useMemo(
    () =>
      sortedOffers
        .filter(
          offer =>
            enablePromoCodeOffers &&
            offer.uiPattern === uiPattern &&
            shouldIncludePromoCodeOffer(offer, offersFeedback)
        )
        .map(offer => ({
          ...offer,
          isRedeemable:
            !!offersFeedback?.[offer._id]?.redemptionEligibility?.isRedeemable &&
            !redeemedOfferUniqIDs.includes(getUniqIdForOffer(offer)),
        })),
    [enablePromoCodeOffers, offersFeedback, redeemedOfferUniqIDs, sortedOffers, uiPattern]
  );

  return {
    promoCodeOffers,
    togglePromoCodeOfferRedeemable,
  };
}
