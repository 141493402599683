import React, { useEffect } from 'react';

import { MenuItemCustomization } from 'components/menu-order-wizard/menu-item-customization';
import SelectionContainer from 'components/selection-container';
import { useFormatCalories } from 'hooks/use-format-calories';
// import Upsell from 'pages/cart/upsell/upsell';
// import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuContext } from 'state/menu';
import {
  formattedCalorieMapForPickerAspectCalorieMap,
  pickerAspectOptHasResolution,
  reducePickerCalorieMapToRanges,
} from 'utils/menu';

import useModalCta from '../hooks/use-modal-cta';
import { PickerDetail } from '../picker-detail';
import { useWizard } from '../wizard-provider';

// const StyledUpsell = Box.withConfig({
//   marginBottom: '$8',
// });

function ParallelUI({
  addToOrder,
  addToOrderText,
  calorieMap = {},
  currentPrice,
  excludesForPickerAspects = [],
  handleModifierQuantityUpdate,
  handleModifierSelection,
  handlePickerSelection,
  modifierSelections,
  pickerAspects,
  pickerOptions,
  pickerSelection,
  aspectPromotionMap,
  quickConfigs = [],
  selectedPickerIdentifiers,
  setModalCta,
}) {
  // TODO: RN - does TH use this?
  // const useEnableMenuUpsellItems = useFlag(LaunchDarklyFlag.ENABLE_MENU_UPSELL_ITEMS);
  const { showStaticMenu } = useMenuContext();
  const formatCalories = useFormatCalories();
  const {
    actions: { incrementStep },
    state: { currentStep, isFinalStep },
  } = useWizard();

  useModalCta({
    addToOrder,
    addToOrderText,
    currentPrice,
    incrementStep,
    isFinalStep,
    setModalCta,
  });

  useEffect(
    () => {
      tagAspectOptionsForAvailabilty();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPickerIdentifiers]
  );

  const tagAspectOptionsForAvailabilty = () => {
    pickerAspects.map(pickerAspect => {
      return pickerAspect.pickerAspectOptions.map(option => {
        const otherSelections = Object.values({
          ...selectedPickerIdentifiers,
          [pickerAspect._id]: undefined,
        }).filter(filteredOption => filteredOption);
        option.disabled = !pickerAspectOptHasResolution(
          option.identifier,
          otherSelections,
          pickerOptions
        );
        return option;
      });
    });
  };

  const MappedPickerAspects = pickerAspects.map((aspect, index) => {
    const selectedPickerAspectOption = (aspect.pickerAspectOptions || []).find(
      option => option.identifier === selectedPickerIdentifiers[aspect._id]
    );

    const calorieMapForPickerAspect = Object.values(selectedPickerIdentifiers)
      .slice(0, index)
      .reduce((map, identifier) => (map ? map[identifier] : null), calorieMap);

    const calorieRangesForCurrentPickerAspect =
      reducePickerCalorieMapToRanges(calorieMapForPickerAspect);

    return (
      <SelectionContainer
        focusOnMount={index === 0}
        key={aspect._id}
        required={!showStaticMenu}
        title={aspect.name?.locale}
        uiPattern={aspect.uiPattern}
        selection={selectedPickerAspectOption?.name?.locale ?? ''}
      >
        <PickerDetail
          aspect={aspect}
          aspectPromotionMap={aspectPromotionMap}
          calorieMap={
            formattedCalorieMapForPickerAspectCalorieMap(
              calorieRangesForCurrentPickerAspect,
              formatCalories
            ) || {}
          }
          handleSelection={handlePickerSelection}
          selectedPickerIdentifiers={selectedPickerIdentifiers}
          isInStaticMenu={showStaticMenu}
        />
      </SelectionContainer>
    );
  });

  // Only show the pickerAspects on the first step for parallel.
  return (
    <>
      {currentStep === 0 ? MappedPickerAspects : null}
      {pickerSelection ? (
        <>
          <MenuItemCustomization
            excludesForPickerAspects={excludesForPickerAspects}
            handleModifierQuantityUpdate={handleModifierQuantityUpdate}
            handleModifierSelection={handleModifierSelection}
            modifierSelections={modifierSelections}
            menuItem={pickerSelection}
            quickConfigs={quickConfigs}
            // TODO: remove prop since it's always true for TH
            showModifiers={true}
          />
          {/* TODO: RN - make this work for TH if needed */}
          {/* {useEnableMenuUpsellItems && (
            <StyledUpsell>
              <Upsell />
            </StyledUpsell>
          )} */}
        </>
      ) : null}
    </>
  );
}

export default ParallelUI;
