import { CardTypes, IPaymentMethod } from 'state/payment/types';

export const googlePayCardDetails = {
  credit: {
    cardType: CardTypes.GOOGLE_PAY,
  },
  accountIdentifier: CardTypes.GOOGLE_PAY,
  fdAccountId: CardTypes.GOOGLE_PAY,
} as IPaymentMethod;

export const WORLDPAY_GATEWAY = 'vantiv';
