import React from 'react';

import { Foodware } from 'components/foodware';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

const FoodwareContainer = () => {
  const enableFoodwareItems = useFlag(LaunchDarklyFlag.ENABLE_FOODWARE_ITEMS);

  if (!enableFoodwareItems) {
    return null;
  }

  return <Foodware />;
};

export default FoodwareContainer;
