import { RewardTier } from './types';

export const getProgressBarValue = (userPoints: number, tiers: RewardTier[]): number => {
  if (userPoints === 0) {
    return 0;
  }

  // Adding 0 Tier (default min value) to the list.
  const allTiers: RewardTier[] = [{ id: '0', pointsLabel: 0, topLabel: '0' }, ...tiers];

  // Distance between Tiers (in %)
  const tierWidth = 100 / tiers.length;

  let progressBarValue = 0;
  allTiers.forEach((tier: RewardTier) => {
    const currentTier = allTiers.indexOf(tier);
    const lastTierIndex = allTiers.length - 1;
    const nextTier = currentTier < lastTierIndex ? currentTier + 1 : lastTierIndex;
    const currentNumber = tier.pointsLabel;
    const nextNumber = allTiers[nextTier].pointsLabel;

    // Exact match with a tier
    if (userPoints === currentNumber) {
      progressBarValue = tierWidth * currentTier;
    }
    if (userPoints === nextNumber) {
      progressBarValue = tierWidth * nextTier;
    }

    // UserPoints are between tiers
    if (userPoints > currentNumber && userPoints < nextNumber) {
      const pointDiff = userPoints - currentNumber;
      const diffBetweenTiers = nextNumber - currentNumber;

      // Calculates the percentage between one tier and another
      const aux = pointDiff / diffBetweenTiers;
      progressBarValue = (currentTier + aux) * tierWidth;
    }

    // Handles exceeding max tier
    if (currentTier === allTiers.length - 1 && userPoints > currentNumber) {
      progressBarValue = tierWidth * currentTier;
    }
  });

  // If percentage is too low, show at least 2% of progress
  return userPoints > 0 && progressBarValue < 2 ? 2 : progressBarValue;
};

export const showTiersDynamically = (userPoints: number, tiers: RewardTier[]): RewardTier[] => {
  const MAX_TIERS_TO_DISPLAY = 6;
  const rewardPoints = tiers[MAX_TIERS_TO_DISPLAY]?.pointsLabel || 0;
  let displayedTiers = tiers;
  if (userPoints < rewardPoints) {
    displayedTiers = tiers.slice(0, MAX_TIERS_TO_DISPLAY + 1);
  }

  return displayedTiers;
};
