import * as React from 'react';
import isEqual from 'react-fast-compare';

type Compare<T extends any = any> = (a: T, b: T) => boolean;

const useMemoizedDependencies = <T extends any[]>(dependencies: T, compare: Compare<T>) => {
  const memoizedDeps = React.useRef(dependencies);

  if (!compare(dependencies, memoizedDeps.current)) {
    memoizedDeps.current = dependencies;
  }

  return memoizedDeps.current;
};

export const useMemoWithCompare = <T, D extends any[]>(
  callback: () => T,
  dependencies: D,
  compare: Compare<D> = isEqual
) => {
  const memoizedDeps = useMemoizedDependencies(dependencies, compare);

  return React.useMemo(callback, memoizedDeps);
};
