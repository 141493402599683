import * as React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { FlatList } from 'react-native';

import { Box } from '@fhs-legacy/universal-components';
import { StoreLocatorEmptyStates } from 'components/store-locator-empty-states';
import { IRestaurantNode } from 'generated/rbi-graphql';
import LoadingIndicator from 'pages/store-locator/loading-indicator';
import { StoreCard } from 'pages/store-locator/new-ui/store-card';
import { StoreCardWrapper } from 'pages/store-locator/new-ui/store-card-list/store-card-list.styled';
import { useGeolocation } from 'state/geolocation';
import { OrderLocatorTab } from 'state/launchdarkly/variations';

import { StoreSelectAction } from './store-select-action';

interface IStoreCardListProps {
  currentTabHasError?: boolean;
  isSearching?: boolean;
  onSelectStore: (storeId: string) => void;
  restaurants: IRestaurantNode[];
  tab: OrderLocatorTab;
}

export const StoreCardList: React.VFC<IStoreCardListProps> = ({
  currentTabHasError = false,
  isSearching,
  onSelectStore,
  restaurants = [],
  tab,
}) => {
  const { formatMessage } = useIntl();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const { coordinatesAvailable, isPermissionGranted } = useGeolocation();

  const handleCardClick = (index: number) => {
    if (selectedIndex === index) {
      setSelectedIndex(null);
    } else {
      setSelectedIndex(index);
    }
  };

  if (isSearching) {
    return <LoadingIndicator ariaLabel={formatMessage({ id: 'locationSearch' })} />;
  }

  if (restaurants.length === 0) {
    return (
      <StoreLocatorEmptyStates
        tab={tab}
        locationPermissionsGranted={isPermissionGranted}
        coordinatesAvailable={coordinatesAvailable}
        currentTabError={currentTabHasError}
      />
    );
  }

  return (
    <FlatList
      data={restaurants}
      keyExtractor={restaurant => `${restaurant._id}`}
      renderItem={({ index, item: restaurant }) => {
        return (
          <>
            <StoreCardWrapper>
              <StoreCard restaurant={restaurant} onCardClick={() => handleCardClick(index)} />
              {index === selectedIndex ? (
                <Box width="full" alignItems="center">
                  <StoreSelectAction onPress={() => onSelectStore(restaurant?._id ?? '')} />
                </Box>
              ) : null}
            </StoreCardWrapper>
          </>
        );
      }}
    />
  );
};

export default StoreCardList;
