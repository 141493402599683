import { isValid } from 'date-fns';
import { merge } from 'lodash';
import { IntlFormatters } from 'react-intl';
import { superstruct } from 'superstruct';

import { SupportedRegions } from '@rbi-ctg/frontend';
import { REGIONS } from 'state/intl/types';
import { ParseError, parsePhoneNumber } from 'utils/libphonenumber';

import { maxLengthWithDefaultValue, minLengthWithDefaultValue } from '..';

import { ISOs, ISOsToRegions } from './constants';
import { IDateOfBirth } from './types';

export enum FormValidationState {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PRISTINE = 'PRISTINE',
}

export enum PhoneNumberValidationError {
  EMPTY = 'EMPTY',
  UNKNOWN = 'UNKNOWN',
  INVALID_COUNTRY = 'INVALID_COUNTRY',
  NOT_A_NUMBER = 'NOT_A_NUMBER',
  TOO_LONG = 'TOO_LONG',
  TOO_SHORT = 'TOO_SHORT',
}

// To get message keys based on field names
export enum MapInputNameWithTranslationKey {
  agreeToTermsOfService = 'agreesToTermsOfService',
  emailAddress = 'email',
  creditCardNumber = 'cardNumber',
  cardExpiration = 'expiry',
  billingStreetAddress = 'streetAddress',
  billingApt = 'apartment',
  billingCity = 'city',
  billingState = 'state',
  billingZip = 'zipCode',
}

export const VALID_NAME_REGEX =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõőøùúûüųūűÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕŐØÙÚÛÜŲŪŰŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/;

export const EMAIL_REGEX =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/i;

// Regex to allow a zip/postal code from any country
export const ALL_COUNTRIES_ZIP_CODE_REGEX = /^([a-zA-Z0-9\s_-]){4,10}$/;

export const ZIP_CODE_REGEX = /^\d{5}$|^\d{5}-\d{4}$/;

export const CA_POSTAL_REGEX =
  /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/;

export const COUNTRY_BASED_ZIP_CODE_REGEX = {
  [ISOs.USA]: ZIP_CODE_REGEX,
  [ISOs.CAN]: CA_POSTAL_REGEX,
};

/**
 * Validate that a string is valid.
 *
 * @param {string} name
 * The string to test.
 *
 * @returns {boolean}
 * True if the string provided is a valid string.
 */
export const isNameInputValid = (name: string): boolean => {
  if (!name || !VALID_NAME_REGEX.test(name)) {
    return false;
  }

  return name.includes('.') ? name.includes(' ') : true;
};

/**
 * Validate that a string is an email address.
 *
 * The same function exists on the backend.
 * Changes here should be duplicated in common/src/utils/index.ts
 *
 * @param {string} email
 * The string to test.
 *
 * @returns {boolean}
 * True if the string provided is a valid email address.
 */
export const isEmailValid = (email = '') => {
  return EMAIL_REGEX.test(email) && email[0] !== '.' && !email.includes('..');
};

/**
 * Validate that a string is a phone number.
 *
 *
 * @param {string} phoneNumber
 * The string to test.
 * @param country
 * @param simpleValidation
 * @returns {Object} validation
 * @returns {boolean} validation.valid True if the string provided is a valid phone number
 * @returns {PhoneNumberValidationError} validation.error Error description if the string provided is not a valid phone number
 *
 */

export const isPhoneNumberValid = async ({
  phoneNumber,
  country = REGIONS.US,
  simpleValidation,
}: {
  phoneNumber: string;
  country?: SupportedRegions;
  simpleValidation?: boolean;
}): Promise<{ valid: boolean; error?: PhoneNumberValidationError }> => {
  try {
    if (!phoneNumber.trim()) {
      return {
        valid: false,
        error: PhoneNumberValidationError.EMPTY,
      };
    }

    if (simpleValidation) {
      const numericValidation = new RegExp(/^\+?[0-9]{5,15}$/);
      return { valid: numericValidation.test(phoneNumber) };
    }
    const parsedPhoneNumber = await parsePhoneNumber(phoneNumber, country);
    return { valid: parsedPhoneNumber.isValid() };
  } catch (error) {
    if (error instanceof ParseError) {
      return {
        valid: false,
        error: error.message as PhoneNumberValidationError,
      };
    }
    return {
      valid: false,
      error: PhoneNumberValidationError.UNKNOWN,
    };
  }
};

export const getPhoneNumberErrorMessage = (
  error: PhoneNumberValidationError | undefined,
  formatMessage: IntlFormatters['formatMessage']
): string => {
  switch (error) {
    case PhoneNumberValidationError.EMPTY:
      return formatMessage({ id: 'phoneNumberRequiredError' });
    case PhoneNumberValidationError.TOO_LONG:
      return formatMessage({ id: 'phoneNumberTooLongError' });
    case PhoneNumberValidationError.TOO_SHORT:
      return formatMessage({ id: 'phoneNumberTooShortError' });
    default:
      return formatMessage({ id: 'notValidPhoneNumberError' });
  }
};

export const IPV4_REGEX =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const requiredString = (s = '') => s.length > 0;

export const requiredBoolean = (b = false) => b === true;

export const requiredValidDateObject = ({
  day,
  month,
  year,
}: {
  day: string;
  month: string;
  year: string;
}) => {
  if (!day || !month || !year) {
    return false;
  }
  const date = new Date(formatDateObject({ day, month, year }));
  return isValid(date);
};

export const structWithRequiredString = (opts: superstruct.Config = {}) =>
  superstruct(
    merge(
      {
        types: {
          requiredString,
          requiredBoolean,
        },
      },
      opts
    )
  );

/**
 * Cleans a given string to only contain digits with no spaces
 * @param number
 * @returns a string of digits where all other characters/spaces have been removed
 */
export const sanitizeNumber = (number = '') => number.replace(/[^\d]/g, '');

export const sanitizePhoneNumber = async (
  rawNumber = '',
  country: ISOs | string = ISOs.US
): Promise<string> => {
  let result = '';

  if (rawNumber) {
    const region = ISOsToRegions[country];
    try {
      const { number, nationalNumber, countryCallingCode } = await parsePhoneNumber(
        rawNumber,
        region
      );
      // return either the valid number (which already includes area code) or add it based on passed in region
      result = `${number}` || `+${countryCallingCode}${nationalNumber}`;
    } catch {
      // Replace all non-numbers and non-pluses with empty string and
      // replaces pluses only if they are not at the start of the string
      return rawNumber.replace(/[^\d+]/g, '').replace(/(?!^)\+/g, '');
    }
  }

  return result;
};

export const sanitizeAlphanumeric = (v = '') => v.replace(/[^A-Za-z0-9]/g, '');

/*
 * This value was previously set to users DOB as a side effect of this PR:
 * - https://github.com/rbilabs/EngineeringMonoRepo/pull/8426
 *
 * It is added to the possible list of values that will allow
 * users to edit their DOB in account info
 */
const LEGACY_DOB_OBJECT_BLANK_FORMATTED_DOB = '0000-00-00';

// List all current and previous default/empty dob values
// NOTE: We send the `SignUp` mutation an empty string "" when
// users leave DOB empty, but the `GetMe` query returns `null`
export const POSSIBLE_DEFAULT_OR_EMPTY_DOB_VALUES = [
  undefined,
  null,
  '',
  LEGACY_DOB_OBJECT_BLANK_FORMATTED_DOB,
];

export const formatDateObject = (date = { month: '', day: '', year: '' }): string | '' => {
  const { month, day, year } = date;

  if (!month || !day || !year) {
    return '';
  }

  const yyyy = year.padStart(4, '0');
  const dd = day.padStart(2, '0');
  const mm = month.padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
};

export const dobStringToObj = (dateString: string = ''): IDateOfBirth => {
  const dobObj = { month: '', day: '', year: '' };
  if (dateString) {
    const split = dateString.split('-');
    dobObj.year = split[0];
    dobObj.month = split[1];
    dobObj.day = split[2];
  }
  return dobObj;
};

export const minLength = (len: number) => minLengthWithDefaultValue(len, '');
export const maxLength = (len: number) => maxLengthWithDefaultValue(len, '');

/**
 * Validates form data against struct and returns an object
 * of error messages mapped to the field name
 *
 * @param Struct The Struct to validate against.
 * @param extraMessages Messages used to swap out Struct errors.
 * @param formatMessage
 */
export const getFormErrorsForStruct =
  <FormDataType extends object, extraMessagesType extends object>(
    Struct: superstruct.Struct,
    extraMessages: extraMessagesType,
    formatMessage?: IntlFormatters['formatMessage']
  ) =>
  (
    formState: FormDataType
  ): { [key in keyof FormDataType]?: (typeof extraMessages)[keyof extraMessagesType] } => {
    const [structErrors] = Struct.validate(formState);
    if (structErrors) {
      return structErrors.errors.reduce(
        (errors, { path: [fieldName], type }) => ({
          ...errors,
          [fieldName]: formatMessage
            ? formatMessage({ id: extraMessages[type] })
            : extraMessages[type],
        }),
        {}
      );
    }

    return {};
  };
