import { ISupportCategoryGroup, ISupportSubcategory } from 'generated/sanity-graphql';
import { REGIONS } from 'state/intl/types';

export interface ISupportFormData {
  comments: string;
  email: string;
  issueId: string;
  name: string;
  phoneNumber?: string;
  orderTime?: string;
  orderDateTime?: { day: string; month: string; year: string };
  agreesToTermsOfService?: boolean;
  serviceMode?: string;
  storeId?: string;
  storeAddress?: string;
}

export interface ISupportFormDefaultValues {
  email: string | null;
  supportCategoryGroup: string | null;
  supportSubcategory: string | null;
}

export enum CreateCaseState {
  ERROR,
  LOADING,
  SUCCESS,
  DEFAULT,
}

export interface ICreateSupportCaseInput extends Omit<ISupportFormData, 'orderDateTime'> {
  orderDateTime?: string;
  userRegion: REGIONS;
  userAccountRegion: string | null;
}

export interface IUseKnowledgeForceCaseCreation {
  caseReferenceNumber: string | null;
  createCaseState: CreateCaseState;
  createSupportCase(caseInfo: ICreateSupportCaseInput): Promise<string | null>;
  createCaseError: string;
}

export interface ISubcategory {
  _id: string;
  name?: { locale: string | null } | null;
  knowledgeForceIssueId: ISupportSubcategory['knowledgeForceIssueId'];
  supportSubCategoryServiceModeSelector?: ISupportSubcategory['supportSubcategoryServiceModeSelector'];
}

export interface ICategoryGroup extends ISupportCategoryGroup {
  name: ISupportCategoryGroup['name'] & { locale: string };
}
