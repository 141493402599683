export const futureOrderingOptions = {
  startNow: false,
  timeInterval: 15,
  offset: 0,
  days: 7,
  length: 0,
  minutesBeforeStoreCloses: 15,
  cateringDaysAhead: 1,
};

export const timeFiredOptions = {
  startNow: true,
  timeInterval: 15,
  offset: 0,
  days: 1,
  length: 5,
  minutesBeforeStoreCloses: 15,
  cateringDaysAhead: 1,
};
