import React from 'react';

import { Calories } from 'components/price-and-calories';

import { CaloriesContent, Name } from './picker-aspect-option.styled';
import { INameAndCalories } from './types';

export default function NameAndCalories(props: INameAndCalories) {
  return (
    <>
      <Name>{props.name}</Name>
      <Calories>
        <CaloriesContent>{props.calories}</CaloriesContent>
      </Calories>
    </>
  );
}
