import React from 'react';

import { Box } from '@fhs-legacy/universal-components';
import LoadingAnimation from 'components/loading-animation';

const LoadingGraphic = Box.withConfig({
  height: '$64',
  width: '1/2',
});

export default () => (
  <LoadingGraphic testID="cart-loading-graphic">
    <React.Suspense fallback={null}>
      <Box h="100%" display="flex" alignItems="center" justifyContent="center">
        <LoadingAnimation />
      </Box>
    </React.Suspense>
  </LoadingGraphic>
);
