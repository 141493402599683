import { isNil } from 'lodash';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Box, IconVariant, ScrollView, VStack } from '@fhs-legacy/universal-components';
import { usePathname } from 'hooks/use-pathname';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { getUSState, useIsMobileOrderingAvailable } from 'utils/restaurant';
import { routes } from 'utils/routing';
import { slugify } from 'utils/slugify';

import { DirectionsLink } from './directions-link';
import { Hours } from './hours';
import StoreMeta from './meta';
import {
  Address,
  Amenity,
  AmenityIcon,
  Background,
  FeatureIcon,
  Inner,
  InnerHeader,
  InnerText,
  PhoneNumber,
  SpacingLine,
} from './styled';
import { IModalBody } from './types';

export const ModalBody = ({ store }: IModalBody) => {
  const { phoneNumber, restaurantImage, latitude, longitude, physicalAddress } = store;

  const enableShowAddress2BeforeCity = useFlag(LaunchDarklyFlag.ENABLE_SHOW_ADDRESS2_BEFORE_CITY);
  const enableOrderingFlag = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const enableOrdering = isNil(enableOrderingFlag) || enableOrderingFlag;

  const { formatMessage } = useIntl();

  const pathname = usePathname();
  const isStoreLocatorPage = pathname.includes(routes.store);

  const storeSlug = slugify(store.name);
  const streetAddress = physicalAddress?.address1;
  const formattedCity = physicalAddress?.city;
  const state = getUSState(physicalAddress?.stateProvince);
  const postalCode = physicalAddress?.postalCode?.split('-')[0];
  const address2 = physicalAddress?.address2;
  const showAddress2 = enableShowAddress2BeforeCity && address2;

  const { allServiceModesUnavailable, serviceModeStatus } = useServiceModeStatus(store);
  const shouldShowWaysToOrderSection =
    useIsMobileOrderingAvailable(store) || !allServiceModesUnavailable;
  const hasCatering =
    serviceModeStatus.CATERING_DELIVERY.available || serviceModeStatus.CATERING_PICKUP.available;
  const amenitiesBreakdown: [IconVariant, boolean, string, string][] = [
    ['driveThru', serviceModeStatus.DRIVE_THRU.available, '16px', 'driveThru'],
    ['mobile', useIsMobileOrderingAvailable(store), '22px', 'mobileOrdering'],
    ['delivery', serviceModeStatus.DELIVERY.available, '22px', 'delivery'],
    ['curbside', serviceModeStatus.CURBSIDE.available, '22px', 'curbside'],
    ['catering', hasCatering, '20px', 'catering'],
    ['pickupWindow', serviceModeStatus.PICKUP_WINDOW.available, '24px', 'pickupWindow'],
  ];

  const enableAmenitiesSection = useFlag(LaunchDarklyFlag.ENABLE_AMENITIES_SECTION);
  const amenitiesSection = useMemo(() => {
    if (!store.amenities?.length || !enableAmenitiesSection) {
      return null;
    }

    const amenitiesWithIconSrc = store.amenities.filter(amenity => amenity?.icon?.asset?.url);

    if (!amenitiesWithIconSrc.length) {
      return null;
    }

    return (
      <VStack testID="restaurant-amenities">
        <InnerHeader variant="headerThree" testID="in-store-amenities">
          {formatMessage({ id: 'inStoreAmenities' })}
        </InnerHeader>
        {amenitiesWithIconSrc.map(amenity => {
          const name = amenity?.name?.locale ?? '';
          return (
            <Amenity key={name}>
              <FeatureIcon src={amenity?.icon?.asset?.url} alt={name} />
              <InnerText>{name}</InnerText>
            </Amenity>
          );
        })}
      </VStack>
    );
  }, [enableAmenitiesSection, formatMessage, store]);

  const shouldShowDineIn = serviceModeStatus.EAT_IN.capable && !serviceModeStatus.EAT_IN.disabled;
  const shouldShowTakeout =
    serviceModeStatus.TAKEOUT.capable && !serviceModeStatus.TAKEOUT.disabled;
  const shouldShowDriveThru =
    serviceModeStatus.DRIVE_THRU.capable && !serviceModeStatus.DRIVE_THRU.disabled;
  const shouldShowCurbside =
    serviceModeStatus.CURBSIDE.capable && !serviceModeStatus.CURBSIDE.disabled;
  const shouldShowPickupWindow =
    serviceModeStatus.PICKUP_WINDOW.capable && !serviceModeStatus.PICKUP_WINDOW.disabled;
  const storeHoursBreakdown = [
    [shouldShowDineIn || shouldShowTakeout, 'dineInHours', 'diningRoomHours'],
    [shouldShowDriveThru, 'driveThruHours'],
    [shouldShowCurbside, 'curbsideHours'],
    [shouldShowPickupWindow, 'pickupWindowHours'],
  ];

  return (
    <>
      {isStoreLocatorPage && (
        <StoreMeta
          atCopy={formatMessage({ id: 'at' })}
          streetAddress={streetAddress}
          city={formattedCity}
          state={state}
          postalCode={postalCode}
          phoneNumber={phoneNumber}
          image={restaurantImage}
          storeSlug={storeSlug}
        />
      )}

      <ScrollView>
        <Background>
          <Inner>
            <InnerHeader variant="headerTwo" testID="address" nativeID="modal-heading">
              {streetAddress.toLowerCase()}
            </InnerHeader>
            <Box>
              <DirectionsLink latitude={latitude} longitude={longitude} />
              <Address>
                {showAddress2 && <Address testID="address2">{address2}, </Address>}
                {`${formattedCity}${state ? ` ${state}` : ''}, ${postalCode}`}
              </Address>
              {phoneNumber && (
                <PhoneNumber testID="phoneNumber" to={`tel:${phoneNumber}`}>
                  {phoneNumber}
                </PhoneNumber>
              )}
            </Box>
            <SpacingLine marginTop="$6" bottom="$3" />
            {enableOrdering && (
              <>
                {shouldShowWaysToOrderSection && (
                  <InnerHeader variant="headerThree">
                    {formatMessage({ id: 'waysToOrder' })}
                  </InnerHeader>
                )}
                {amenitiesBreakdown.map(
                  ([icon, shouldShow, height, messageId]) =>
                    shouldShow && (
                      <Amenity key={messageId}>
                        <AmenityIcon
                          variant={icon}
                          width="40px"
                          height={height}
                          {...hiddenAccessibilityPlatformProps}
                        />
                        <InnerText>{formatMessage({ id: messageId })}</InnerText>
                      </Amenity>
                    )
                )}
                {shouldShowWaysToOrderSection && <SpacingLine marginTop="$1" marginBottom="$4" />}
              </>
            )}

            {amenitiesSection}
            {storeHoursBreakdown.map(
              // @ts-expect-error TS(2345) FIXME: Argument of type '([shouldShow, messageId, hours]:... Remove this comment to see the full error message
              ([shouldShow, messageId, hours = messageId]: [boolean, string, string]) =>
                shouldShow && (
                  <Hours
                    key={messageId}
                    title={formatMessage({ id: messageId })}
                    hours={store[hours]}
                  />
                )
            )}
          </Inner>
        </Background>
      </ScrollView>
    </>
  );
};
