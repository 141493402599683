import React from 'react';
import { useIntl } from 'react-intl';

import { useCartLoadingImage } from 'hooks/use-cart-loading-image';

import LoadingAnimation from './loading-animation';
import { Container, LoadingText, LoadingTitle } from './styled';

const CartLoading = () => {
  const { formatMessage } = useIntl();

  const { alt, text } = useCartLoadingImage();

  return (
    <Container testID="cart-loading" accessibilityLabel={formatMessage({ id: 'checkForPricing' })}>
      <LoadingAnimation />
      <LoadingTitle variant="headerTwo">{alt}</LoadingTitle>
      <LoadingText variant="copyOne">{text}</LoadingText>
    </Container>
  );
};

export default CartLoading;
