export interface AuthenticationTabProps {
  attemptedSignUpWithExistingEmail?: boolean;
  initialEmail?: string;
}

export interface AuthHomeProps {
  method: AuthMethod;
}

export enum AuthMethod {
  SIGN_IN,
  SIGN_UP,
}
