import { HStack, Icon, Pressable, Text, VStack } from '@fhs-legacy/universal-components';
import { primitive } from 'styles/constants/primitives';

export const SectionWrapper = Pressable.withConfig<{ $readOnly: boolean }>(props => ({
  _web: { cursor: props.$readOnly ? 'default' : 'pointer' },
}));

export const CardWrapper = HStack.withConfig<{ hasError?: boolean }>(props => ({
  backgroundColor: Styles.color.white,
  borderWidth: '1px',
  borderRadius: '10px',
  borderStyle: 'solid',
  paddingX: '$5',
  paddingY: '$3.5',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderColor: props.hasError ? primitive.$error : 'transparent',
}));

export const CalendarIcon = Icon.withConfig<{ hasError?: boolean }>(props => ({
  variant: 'calendar',
  color: props.hasError ? primitive.$error : primitive.$black,
  size: 'md',
}));

export const ArrowRightIcon = Icon.withConfig<{ hasError?: boolean }>(props => ({
  variant: 'back',
  size: '$4',
  style: {
    transform: [{ rotate: '180deg' }],
  },
  color: props.hasError ? primitive.$error : primitive.$black,
}));

export const DateTimeWrapper = HStack.withConfig<{ $readOnly: boolean }>(props => ({
  flexGrow: 1,
  paddingLeft: '$4',
  paddingRight: props.$readOnly ? '$0' : '$4',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const DateWrapper = VStack.withConfig({});

const TextLabel = Text.withConfig<{ hasError?: boolean }>(props => ({
  variant: 'copyOne',
  fontSize: 'sm',
  color: props.hasError ? primitive.$error : primitive.$black,
}));

export const DayLabel = TextLabel.withConfig<{ hasError?: boolean }>(props => ({
  fontWeight: 'bold',
  textTransform: 'capitalize',
  color: props.hasError ? primitive.$error : primitive.$black,
}));

export const DateLabel = TextLabel.withConfig<{ hasError?: boolean }>(props => ({
  textTransform: 'capitalize',
  color: props.hasError ? primitive.$error : primitive.$black,
}));

export const TimeWrapper = HStack.withConfig({
  alignItems: 'center',
  justifyContent: 'flex-end',
});

export const TimeLabel = TextLabel.withConfig({
  fontSize: 'md',
  fontWeight: 'bold',
});
