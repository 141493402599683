import { StyleSheet, View } from 'react-native';

import { XStack, YStack, tokens } from '@fhs/ui';

export const OrderDisplaySkeleton = () => {
  return (
    <YStack style={styles.container}>
      <XStack style={styles.gap16}>
        <YStack style={styles.headerWrapper}>
          <View style={styles.headerTitle} />
          <View style={styles.headerSubtitle} />
        </YStack>
        <View style={styles.buttonWrapper} />
      </XStack>
      <XStack style={styles.item} />
    </YStack>
  );
};

const styles = StyleSheet.create({
  gap16: {
    gap: 16,
  },
  container: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    backgroundColor: tokens.colors.$white,
    gap: 12,
    padding: 16,
    flex: 1,
  },
  headerWrapper: {
    flexGrow: 1,
    flexShrink: 1,
    gap: 7,
    justifyContent: 'center',
  },
  headerTitle: {
    height: 19,
    width: 156,
    borderRadius: 12,
    backgroundColor: tokens.colors.$blackOpacity04,
  },
  headerSubtitle: {
    height: 11,
    width: 156,
    borderRadius: 12,
    backgroundColor: tokens.colors.$blackOpacity04,
  },
  buttonWrapper: {
    height: 36,
    width: 82,
    borderRadius: 12,
    backgroundColor: tokens.colors.$blackOpacity04,
  },
  item: {
    position: 'relative',
    paddingVertical: 4,
    paddingHorizontal: 8,
    flexGrow: 1,
    flexShrink: 1,
    backgroundColor: tokens.colors.$blackOpacity04,
    borderRadius: 12,
    gap: 8,
    minHeight: 44,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
