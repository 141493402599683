import { ReactNode } from 'react';

export interface IInputMessageProps {
  id: string;
  children?: ReactNode;
  hasError?: boolean;
  disabled?: boolean;
  disableScreenReaderAlert?: boolean;
  testID?: string;
}
