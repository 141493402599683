import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as React from 'react';

export { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

export const queryClient = new QueryClient();

export const ClientProvider = (props: React.PropsWithChildren) => {
  return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
};
