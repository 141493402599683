import { Link } from 'expo-router';

import { Box, HStack, VStack } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { getDirectionsAppUri } from 'utils/native-actions';

export const Container = HStack.withConfig({
  height: '74px',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: Styles.color.white,
  borderRadius: '10px',
  paddingX: '$5',
  paddingY: '$3.5',
  flex: 1,
});

type Props = {
  latitude: number;
  longitude: number;
  address: string | null;
  city: string | null;
  state: string | null;
};

export function StoreCard(props: Props) {
  return (
    <Container>
      <VStack>
        <Box>{props.address}</Box>
        <Box>{[props.city, props.state].join(', ')}</Box>
      </VStack>
      <HStack>
        <Link href={getDirectionsAppUri(props.latitude, props.longitude)} target="_blank">
          <ActionButton size={ActionButtonSizes.SMALL} variant={ActionButtonVariants.OUTLINE}>
            Directions
          </ActionButton>
        </Link>
      </HStack>
    </Container>
  );
}
