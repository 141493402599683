import { Box, Text } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

export const ReceiptDetail = Box.withConfig<{ $total?: boolean }>(p => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginY: p.$total ? '$3' : 0,
}));

export const FeeDeliveryItem = Text.withConfig({
  margin: 0,
  fontFamily: 'heading',
});

export const RewardsDiscountDetail = ReceiptDetail.withConfig({
  _text: {
    color: theme.token('text-default'),
    fontFamily: 'brand',
  },
});

export const SDiscount = Text.withConfig({
  textDecoration: 'line-through',
});

export const ReceiptDetailsText = Text.withConfig<{ $total?: boolean }>(p => ({
  variant: p.$total ? 'copyOne' : 'copyTwo',
  fontWeight: p.$total ? 'bold' : 'normal',
}));
