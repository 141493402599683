import { IAddress, IBackendCartEntries, IServerOrder, ServiceMode } from '@rbi-ctg/menu';
import { RbiOrderStatus } from 'generated/rbi-graphql';
import { ICommitOrderOptions } from 'hooks/commit-order';

export enum OrderStatus {
  CATERING_PLACED = 'CATERING_PLACED',
  CATERING_DECLINED = 'CATERING_DECLINED',
  CATERING_ERROR = 'CATERING_ERROR',
  CATERING_SUCCESSFUL = 'CATERING_SUCCESSFUL',
  INSERT_ERROR = 'INSERT_ERROR',
  INSERT_REQUESTED = 'INSERT_REQUESTED',
  INSERT_SUCCESSFUL = 'INSERT_SUCCESSFUL',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  /** used for cash payments before the order is inserted */
  PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
  PAYMENT_REQUESTED = 'PAYMENT_REQUESTED',
  PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL',
  PRICE_ERROR = 'PRICE_ERROR',
  PRICE_REQUESTED = 'PRICE_REQUESTED',
  PRICE_SUCCESSFUL = 'PRICE_SUCCESSFUL',
  UPDATE_ERROR = 'UPDATE_ERROR',
  UPDATE_REQUESTED = 'UPDATE_REQUESTED',
  UPDATE_SUCCESSFUL = 'UPDATE_SUCCESSFUL',
  REFUND_SUCCESSFUL = 'REFUND_SUCCESSFUL',
  REFUND_REQUESTED = 'REFUND_REQUESTED',
  REFUND_ERROR = 'REFUND_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
}

export enum CateringStatus {
  STORE_APPROVED = 'STORE_APPROVED',
  STORE_DECLINED = 'STORE_DECLINED',
  STORE_PENDING = 'STORE_PENDING',
}

export enum CartPaymentType {
  APPLE_PAY = 'APPLE_PAY',
  CREDIT_ANONYMOUS = 'CREDIT_ANONYMOUS',
  GOOGLE_PAY = 'GOOGLE_PAY',
  VAULTED_ACCOUNT = 'VAULTED_ACCOUNT',
  CASH = 'CASH',
}

export enum DeliveryStatus {
  DRIVER_ASSIGNED = 'DRIVER_ASSIGNED',
  DRIVER_UNASSIGNED = 'DRIVER_UNASSIGNED',
  DRIVER_AT_CUSTOMER = 'DRIVER_AT_CUSTOMER',
  DRIVER_AT_STORE = 'DRIVER_AT_STORE',
  DRIVER_STARTING = 'DRIVER_STARTING',
  ORDER_ABANDONED = 'ORDER_ABANDONED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_DROPPED_OFF = 'ORDER_DROPPED_OFF',
  ORDER_ERROR = 'ORDER_ERROR',
  ORDER_PICKED_UP = 'ORDER_PICKED_UP',
  ORDER_TIMEOUT = 'ORDER_TIMEOUT',
  QUOTE_ERROR = 'QUOTE_ERROR',
  QUOTE_REQUESTED = 'QUOTE_REQUESTED',
  QUOTE_SUCCESSFUL = 'QUOTE_SUCCESSFUL',
  QUOTE_UNAVAILABLE = 'QUOTE_UNAVAILABLE',
}

export interface ILimitProps {
  maxLimit: number;
  maxCateringLimit: number;
  isCatering: boolean;
}
export interface IMinimumProps {
  minLimit: number;
  minCateringLimit: number;
  isCatering: boolean;
}
export interface IOrder {
  cartEntries: IBackendCartEntries[];
  cartPriceLimitExceeded: boolean;
  commit: (input: ICommitOrderOptions) => Promise<RbiOrderStatus>;
  serverOrder: IServerOrder;
  cateringPickupDateTime: string;
  serviceMode: ServiceMode;
  deliveryAddress: IAddress;
  deliveryInstructions: string;
  setDeliveryInstructions: (arg: string | null) => void;
  isDelivery: boolean;
  isCatering: boolean;
  fireOrderIn: number;
  setFireOrderIn: (fireOrderIn: number) => void;
  selectServiceMode: (mode: ServiceMode | null) => Promise<boolean>;
  tipAmount: number;
  tipSelection: ITipSelection;
  emptyCart: () => void;
  curbsidePickupOrderId: string;
  setCurbsidePickupOrderId: (arg: string) => void;
  curbsidePickupOrderTimePlaced: string;
  setCurbsidePickupOrderTimePlaced: (arg: string) => void;
}

export interface ITipSelection {
  percentAmount: number | null;
  dollarAmount: number | null;
  otherAmount: number | null;
  isOtherSelected: boolean;
}
