import React from 'react';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native';

import { tokens } from '../../tokens';

import { TabbedHeaderLink } from './tabbed-header-link';
import { TabbedHeaderProps } from './types';

export function TabbedHeader(props: TabbedHeaderProps) {
  const { items, onSelect } = props;
  return (
    <ScrollView
      style={styles.container}
      testID="tabbed-menu-header"
      horizontal
      showsHorizontalScrollIndicator={false}
    >
      {items.map((item, idx) => (
        <TabbedHeaderLink
          key={item.slug}
          item={item}
          isActive={idx === props.activeIndex}
          onSelect={onSelect}
        />
      ))}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: '100%',
    backgroundColor: tokens.colors.$white,
  },
});
