import { Box, Text, makeUclComponent } from '@fhs-legacy/universal-components';
import List from 'components/list';

import theme from './theme';

export const SelectionContainer = Box.withConfig({
  borderBottomWidth: 1,
  borderBottomColor: Styles.color.grey.four,
  borderBottomStyle: 'solid',
  paddingX: 0,
  paddingY: '$3',
});

export const Name = Box.withConfig({
  _text: {
    fontWeight: Styles.fontWeight.heavy,
    color: theme.nameColor,
    fontSize: 'md',
    textTransform: 'uppercase',
    textAlign: 'left',
  },

  marginBottom: '5px',
});

export const Description = Text.withConfig({
  fontSize: 'sm',
  fontWeight: Styles.fontWeight.normal,
});

export const ExpiryText = Text.withConfig({
  marginTop: '$2',
  fontSize: 'sm',
  fontWeight: Styles.fontWeight.normal,
  color: theme.ExpiryText,
  marginLeft: '30px',
});

export const StyledList = makeUclComponent(List).withConfig({
  width: '100%',
});

export const NotFoundContainer = Box.withConfig({
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
});

export const NotFoundName = Text.withConfig({
  fontFamily: Styles.fontFamily.base,
  fontSize: '2xl',
  textTransform: 'uppercase',
  paddingBottom: '$3',
});

export const NotFoundDescription = Box.withConfig({
  _text: {
    fontSize: 'xs',
  },
});

export const Wrapper = Box.withConfig({
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
});

export const ButtonContainer = Box.withConfig({
  flexDirection: 'row',
  position: 'absolute',
  bottom: 0,
  borderTopWidth: 1,
  borderTopColor: Styles.color.grey.four,
  borderTopStyle: 'solid',
  width: '100%',
  height: '87px',
  alignItems: 'center',
  backgroundColor: Styles.color.grey.five,
});
