import * as Localization from 'expo-localization';
import { Platform } from 'react-native';

import { SupportedLanguages, SupportedRegions } from '@rbi-ctg/frontend';
import { supportedLanguagesByRegion } from 'state/intl/constants';
import { LANGUAGES } from 'state/intl/types';
import { isSSG } from 'utils/environment';
import { loadRegion } from 'utils/intl/region';

import { getMessagesForLanguage } from './get-messages-for-language';

export { getMessagesForLanguage };

export function loadLanguage(): SupportedLanguages {
  if (isSSG) {
    return LANGUAGES.en;
  }

  const inferredUrlParamLanguage = _inferLanguageFromUrlParams();
  if (inferredUrlParamLanguage) {
    return inferredUrlParamLanguage;
  }

  const supportedDeviceLanguage = _inferLanguageFromLocale();
  if (supportedDeviceLanguage) {
    return supportedDeviceLanguage;
  }

  return LANGUAGES.en;
}

// ensures the given region (us or ca) supports this language
// and returns the type narrowed language if it's supported
function _deriveSupportedLanguage(
  requestedLanguage: string | null | undefined
): SupportedLanguages | null {
  if (requestedLanguage !== LANGUAGES.en && requestedLanguage !== LANGUAGES.fr) {
    return null;
  }

  const region = loadRegion().toLowerCase() as Lowercase<SupportedRegions>;
  const isLanguageSupportedInRegion =
    supportedLanguagesByRegion[region].includes(requestedLanguage);
  if (isLanguageSupportedInRegion) {
    return requestedLanguage;
  }
  return null;
}

function _inferLanguageFromLocale(): SupportedLanguages | null {
  const deviceLanguage = Localization.getLocales()[0]?.languageCode;
  return _deriveSupportedLanguage(deviceLanguage);
}

function _inferLanguageFromUrlParams(): SupportedLanguages | null {
  return Platform.select({
    web: () => {
      const params = new URL(window.location.href).searchParams;

      const uriLang = params.get('lang');
      return uriLang ? _deriveSupportedLanguage(uriLang.toLowerCase()) : null;
    },
    default: () => null,
  })();
}
