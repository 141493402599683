import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconImage = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M19 5.1v14H5v-14h14Zm0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2Zm-4.86 8.86-3 3.87L9 13.24 6 17.1h12l-3.86-5.14Z"
      />
    </Svg>
  );
};
