import { FieldProps } from 'formik';
import React from 'react';

import { Box } from '@fhs-legacy/universal-components';
import { TextInput } from 'components/ucl/text-input';
import { TextInputProps } from 'components/ucl/text-input/types';

export const FormikTextInput = React.forwardRef<
  HTMLInputElement,
  FieldProps<string, { [key: string]: string }> & TextInputProps
>(({ field, form, ...props }, ref) => {
  const { errors, touched } = form;

  const fieldTouched = touched?.[field.name];
  const errorMessage = errors?.[field.name];
  const { onBlur, onChange } = field;

  return (
    <Box margin="2">
      <TextInput
        errorMessage={fieldTouched && errorMessage ? errorMessage : undefined}
        {...props}
        wrapperRef={ref}
        onChangeText={onChange(field.name)}
        onBlur={onBlur(field.name)}
      />
    </Box>
  );
});
