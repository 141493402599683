import { Path, Rect, Svg } from 'react-native-svg';

import { IconLocationOutline } from '@fhs/ui';
import { isIOS } from 'utils/environment';

import { IconContainer } from './icon-container';

export const SelectLocation = () => {
  return isIOS() ? (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <Rect width="32" height="32" rx="8" fill="#3F88EE" />
      <Path d="M25 7L7 14.53V15.51L13.84 18.16L16.48 25H17.46L25 7Z" fill="white" />
    </Svg>
  ) : (
    <IconContainer>
      <IconLocationOutline size={20} />
    </IconContainer>
  );
};
