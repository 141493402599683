import dlv from 'dlv';

import { IStaticPageRoute } from 'remote/queries/static-page';

export interface LocaleRoutes {
  acceptGift: string;
  account: string;
  sendGift: string;
  exploreMenu: string;
  signUp: string;
  signIn: string;
  storeLocator: string;
  scan: string;
}

const localeRoutes: Record<string, LocaleRoutes> = {
  en: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    scan: '/scan',
  },
  // @FIXME: GST-10538 -  Localized routes are broken
  // fr: {
  //   acceptGift: '/accepter-un-cadeau',
  //   account: '/compte',
  //   sendGift: '/cadeau',
  //   exploreMenu: '/nouveaux-gouts',
  //   signUp: '/inscrivez-vous',
  //   signIn: '/connectez-vous',
  //   storeLocator: '/localisateur-de-magasin',
  //   scan: '/numeriser',
  // },
};

export function getAllLocalizedVersionsOfRoute(routeKey: keyof LocaleRoutes) {
  const routes = Object.values(localeRoutes).map(routeMaps => routeMaps[routeKey]);
  return routes.filter(Boolean);
}

export const getStaticPagesLocalizedRouteForPath = (
  path: string,
  staticRoutes: IStaticPageRoute[],
  language: string
) => {
  if (!staticRoutes || !Array.isArray(staticRoutes)) {
    return null;
  }

  let localizedRelativePath: string | null = null;

  staticRoutes.forEach((route: any) => {
    if (dlv(route, 'path.current') === path) {
      const localizedPathWithFallback =
        dlv(route, `localePath.${language}.current`) || route.path.current;
      localizedRelativePath = `/${localizedPathWithFallback}`;
    }
  });

  return localizedRelativePath;
};

export const getInCodeLocalizedRouteForPath = (relativePath: string) => {
  const defaultLanguageLocaleRoutes = localeRoutes.en;

  const defaultLanguageRouteKey = Object.keys(defaultLanguageLocaleRoutes).find(
    key => defaultLanguageLocaleRoutes[key] === relativePath
  );

  if (defaultLanguageRouteKey) {
    return (
      // @FIXME: GST-10538 - Localized routes are broken
      // dlv(localeRoutes, `${language}.${defaultLanguageRouteKey}`) ||
      defaultLanguageLocaleRoutes[defaultLanguageRouteKey]
    );
  }

  return null;
};
