import { View } from 'react-native';

import { Skeleton, SkeletonTextBlock, createMqStyles, tokens } from '@fhs/ui';

export function RewardCardSkeleton() {
  const styles = useMqStyles();
  return (
    <View style={styles.rewardContainer}>
      <View style={styles.blockReward} />
      <Skeleton style={styles.imageContainer} />
      <View style={styles.textContainer}>
        <Skeleton style={styles.skeletonButton} />
      </View>
      <View style={styles.buttonContainer}>
        <SkeletonTextBlock lines={1} style={styles.title} />
        <SkeletonTextBlock lines={1} style={styles.points} />
      </View>
    </View>
  );
}
const useMqStyles = createMqStyles({
  rewardContainer: {
    $base: {
      alignItems: 'center',
      borderRadius: 8,
    },
    $ltDesktop: {
      height: 206,
      width: 160,
    },
    $gteDesktop: {
      height: 211,
      width: 215,
    },
  },
  blockReward: {
    $base: {
      borderColor: tokens.colors.$black10,
      borderRadius: 8,
      borderWidth: 1,
      margin: 16,

      position: 'absolute',
    },
    $ltDesktop: {
      // lt container heigh = marginTop + height
      marginTop: 32,
      height: 174,
      width: 160,
    },
    $gteDesktop: {
      // gte container heigh = marginTop + height
      marginTop: 36,
      height: 175,
      width: 215,
    },
  },
  buttonContainer: {
    $base: {
      alignItems: 'center',
      marginTop: 10,
      width: '100%',
    },
  },
  title: {
    $base: {
      alignItems: 'center',
      width: '90%',
    },
  },
  points: {
    $base: {
      alignItems: 'center',
      marginTop: 8,
      width: '65%',
    },
  },
  imageContainer: {
    $base: {
      borderRadius: 12,
      marginTop: 4,
      height: 70,
      width: 102.67,
    },
  },
  textContainer: {
    $base: {
      marginTop: 24,
      height: 46,
      width: '40%',
    },
  },
  skeletonButton: {
    $base: {
      height: 25,
    },
  },
});
