import React, { ComponentProps, ReactNode, useRef } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import Dialog from 'components/dialog';
import { IDialogProps } from 'components/dialog/types';
import { GENERIC_DIALOG_CANCEL_BUTTON, GENERIC_DIALOG_CONFIRM_BUTTON } from 'utils/test-ids';

export type IConfirmProps = Omit<IDialogProps, 'actions'> &
  ComponentProps<typeof Dialog> & {
    onConfirm: () => void;
    onCancel: () => void;
    confirmLabel?: string | ReactNode;
    cancelLabel?: string | ReactNode;
    modalAppearanceEventMessage: string;
  };

const ConfirmDialog = ({
  heading,
  body,
  bodyComponent,
  onDismiss,
  onConfirm,
  onCancel,
  image,
  confirmLabel,
  cancelLabel,
  showDialog,
  testID: dataTestId,
  /**
   * A small string sent to mParticle describing the purpose of the modal.
   */
  modalAppearanceEventMessage,
  ...props
}: IConfirmProps) => {
  const { formatMessage } = useIntl();
  const confirmText = confirmLabel || formatMessage({ id: 'okay' });
  const cancelText = cancelLabel || formatMessage({ id: 'cancel' });
  const confirmButtonRef = useRef<typeof Button>(null);

  return (
    <Dialog
      showDialog={showDialog}
      testID={dataTestId}
      body={body}
      bodyComponent={bodyComponent}
      heading={heading}
      image={image}
      onDismiss={onDismiss}
      initialFocusRef={confirmButtonRef}
      actions={
        <Button.Group>
          <ActionButton
            ref={confirmButtonRef}
            fullWidth
            onPress={onConfirm}
            testID={GENERIC_DIALOG_CONFIRM_BUTTON}
            mb="$2"
          >
            {confirmText}
          </ActionButton>
          <ActionButton
            fullWidth
            onPress={onCancel}
            testID={GENERIC_DIALOG_CANCEL_BUTTON}
            variant={ActionButtonVariants.OUTLINE}
          >
            {cancelText}
          </ActionButton>
        </Button.Group>
      }
      modalAppearanceEventMessage={modalAppearanceEventMessage}
      {...props}
    />
  );
};

export default ConfirmDialog;
