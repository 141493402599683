export enum SimplyOfferEventNames {
  OFFER_ADDED_TO_CART = 'Offer Added to Cart',
  OFFER_REMOVED_FROM_CART = 'Offer Removed from Cart',
  OFFER_GUIDE_HAS_ITEMS_IN_CART = 'Offer Guide Has Items In Cart',
  OFFER_GUIDE_SUMMARY_SHOWN = 'Offer Guide Summary Shown',
  OFFER_GUIDE_STEP_SELECTED = 'Offer Guide Step Selected',
  OFFER_GUIDE_WENT_PREV_STEP = 'Offer Guide Went Previous Step',
  OFFER_GUIDE_EXIT_DIALOG_SHOWN = 'Offer Guide Exit Dialog Shown',
  OFFER_SWITCH_SERVICE_MODE = 'Offer Switch Service Mode',
}

export enum SimplyOfferClickEventLabels {
  OFFER_GUIDE_ADD_ITEM = 'Offer Guide Add Item',
  OFFER_GUIDE_SELECT_FROM_SCRATCH = 'Select From Scratch',
  OFFER_GUIDE_USE_CART_ITEMS = 'Use These Items',
  OFFER_GUIDE_LEAVE_OFFER = 'Leave',
  OFFER_GUIDE_STAY_ON_OFFER = 'Stay Here',
}
