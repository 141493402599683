import { Variants } from '@amplitude/experiment-react-native-client';
import React from 'react';

import { EnvList, HiddenSection } from 'pages/diagnostics/components';
import { RunningExperiments } from 'state/experiments';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

const ExperimentFlagsContent = () => {
  const variants = (LocalStorage.getItem(StorageKeys.AMPLITUDE_VARIANTS) || {}) as Variants;

  const flags = Object.values(RunningExperiments)
    .filter(s => typeof s === 'string')
    .reduce((acc, flagKey) => {
      acc[flagKey] = variants[flagKey]?.value || 'OFF';
      return acc;
    }, {});

  return <EnvList data={flags} />;
};

export const ExperimentFlags = () => (
  <HiddenSection title="Experiments" content={<ExperimentFlagsContent />} />
);
