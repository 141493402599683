import { ApolloError } from '@apollo/client';
import { router } from 'expo-router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  NativeSyntheticEvent,
  TextInput as RNTextInput,
  TextInputChangeEventData,
} from 'react-native';

import { Box, Checkbox } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import { TextInput } from 'components/ucl/text-input';
import { TermsOfServiceLabel } from 'components/user-info-form/checkbox-labels';
import { AGREE_TO_TERMS_OF_SERVICE_ERROR } from 'components/user-info-form/checkbox-labels/constants';
import useErrorModal from 'hooks/use-error-modal';
import { useOtpFeature } from 'hooks/use-otp-feature';
import { HttpErrorCodes } from 'remote/constants';
import { useAuthContext } from 'state/auth';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { useLocale } from 'state/intl';
import { REGIONS } from 'state/intl/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { parseGraphQLErrorCodes } from 'utils/errors';
import { GraphQLErrorCodes } from 'utils/errors/types';
import { isEmailValid, isPhoneNumberValid } from 'utils/form';
import formatListOfWords, { JoinType } from 'utils/intl/formatListOfWords';
import logger from 'utils/logger';
import { OTPAuthDeliveryMethod } from 'utils/otp';
import { SIGNIN_FORM_EMAIL_INPUT, SIGNIN_SUBMIT_BUTTON } from 'utils/test-ids';

import { AuthenticationTabProps } from '../auth-home/types';

import {
  CheckBoxWrapper,
  StyledBoxActionButton,
  StyledButtonWidthLimit,
  TextInputWrapper,
} from './styled';

const { All, SMS, Email, None } = OTPAuthDeliveryMethod;

// we want to use the OTP flag value to adjust our messaging
const useFormattedMessages = () => {
  const { formatMessage } = useIntl();
  return (flagValue: OTPAuthDeliveryMethod) => {
    switch (flagValue) {
      case All:
        const combined = [
          formatMessage({ id: 'emailAddress' }),
          formatMessage({ id: 'phoneNumber' }),
        ];
        const combinedTitle = formatListOfWords({
          formatMessage,
          list: combined,
          joinType: JoinType.DISJUNCTION,
        });
        return [
          combinedTitle,
          formatMessage({ id: 'emailOrPhoneNumberRequiredError' }),
          formatMessage({ id: 'notValidEmailOrPhoneNumberError' }),
        ];
      case SMS:
        return [
          formatMessage({ id: 'phoneNumber' }),
          formatMessage({ id: 'phoneNumberRequiredError' }),
          formatMessage({ id: 'notValidPhoneNumberError' }),
        ];
      case Email:
      case None:
      default:
        return [
          formatMessage({ id: 'emailAddress' }),
          formatMessage({ id: 'emailRequiredError' }),
          formatMessage({ id: 'notValidEmailError' }),
        ];
    }
  };
};

const SignInForm = ({
  attemptedSignUpWithExistingEmail,
  initialEmail: defaultEmail = '',
}: AuthenticationTabProps) => {
  const { signIn } = useAuthContext();
  const chooseMessages = useFormattedMessages();
  const { formatMessage } = useIntl();
  const { region } = useLocale() as { region: REGIONS };
  const { enableOtpFlagValue, emailOtpEnabled, jwtEmailEnabled, smsOtpEnabled } = useOtpFeature();

  const { logRBIEvent } = useCRMEventsContext();

  const emailInputEl = useRef<RNTextInput>(null);
  const enableNumericPhoneValidation = useFlag(LaunchDarklyFlag.ENABLE_NUMERIC_PHONE_VALIDATION);
  const enableAcceptTermsOnSignInTemp = useFlag(
    LaunchDarklyFlag.ENABLE_ACCEPT_TERMS_ON_SIGN_IN_TEMP
  );
  const [emailOrPhone, setEmailOrPhone] = useState(defaultEmail);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAcceptTermsOnSignInTempSelected, setIsAcceptTermsOnSignInTempSelected] = useState(false);

  const [ErrorDialog, openErrDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Sign In Failure',
  });

  const aimToSignIn = useCallback(
    async ({ email = '', phoneNumber = '' }: any) => {
      try {
        setIsLoading(true);

        await signIn({
          email,
          phoneNumber,
          ...(email && { navigateOnSuccessParams: { email } }),
        });
        logRBIEvent({
          name: CustomEventNames.SIGN_IN_OTP_REQUEST,
          type: EventTypes.Other,
          attributes: {
            signUpType: email ? 'Email' : 'Phone Number',
          },
        });
        setIsLoading(false);
      } catch (error) {
        if (error instanceof ApolloError) {
          setIsLoading(false);
          if (
            parseGraphQLErrorCodes(error).some(
              parsedError => parsedError.errorCode === GraphQLErrorCodes.AUTH_EMAIL_NOT_REGISTERED
            )
          ) {
            //Route to Sign Up Page here.
            router.navigate({ pathname: '/signup', params: { email } });
            return setErrorMessage(formatMessage({ id: 'userDoesNotExist' }));
          }

          let message = formatMessage({ id: 'authError' });
          if (
            error?.graphQLErrors?.[0]?.extensions?.statusCode === HttpErrorCodes.TooManyRequests
          ) {
            message = `${formatMessage({ id: 'maxAttemptsReached' })} ${formatMessage({
              id: 'pleaseTryAgainLater',
            })}`;
          }

          openErrDialog({
            error,
            message,
          });
        }
        logger.warn(error as any);
      }
    },
    [formatMessage, logRBIEvent, openErrDialog, signIn]
  );

  useEffect(() => {
    if (attemptedSignUpWithExistingEmail && !isLoading) {
      setEmailOrPhone(defaultEmail);
      aimToSignIn({ email: defaultEmail });
    }
  }, [defaultEmail, attemptedSignUpWithExistingEmail, aimToSignIn, isLoading]);

  const [labelMessage, requiredErrorMessage, invalidErrorMessage] =
    chooseMessages(enableOtpFlagValue);

  const handleEmailOrPhoneChange = (ev: NativeSyntheticEvent<TextInputChangeEventData>) => {
    setErrorMessage('');
    setEmailOrPhone(ev.nativeEvent.text);
  };

  const handleAcceptTermsOnSignInTempChange = () => {
    setIsAcceptTermsOnSignInTempSelected(!isAcceptTermsOnSignInTempSelected);
  };

  const parse = useCallback(
    async (input: string) => {
      const trimmedInput = input.trim();
      if (!trimmedInput) {
        setErrorMessage(requiredErrorMessage);
        return {};
      }

      let isValidPhone;
      const noLetters = /^[\0-9]{2,}$/.test(trimmedInput);

      const isEmail = isEmailValid(trimmedInput);
      const isEmailEnabled = jwtEmailEnabled || emailOtpEnabled;

      if (noLetters && smsOtpEnabled) {
        isValidPhone = await isPhoneNumberValid({
          phoneNumber: trimmedInput,
          country: region,
          simpleValidation: enableNumericPhoneValidation,
        });
      }

      // Will accept "All Enabled", "SMS Enabled", and "Email Enabled"
      if (OTPAuthDeliveryMethod.All) {
        // Case for "Email Enabled"
        if (isEmailEnabled && isEmail) {
          return { email: trimmedInput };
          // Case for "SMS Enabled"
        } else if (smsOtpEnabled && isValidPhone?.valid) {
          return { phoneNumber: trimmedInput };
          // Case for "All Enabled"
        } else if (isEmail) {
          return { email: trimmedInput };
          // Case for "All Enabled"
        } else if (isValidPhone?.valid) {
          return { phoneNumber: trimmedInput };
        }
      }

      setErrorMessage(invalidErrorMessage);
      // If there was an error, try to focus the input after an attempted sign in
      // this will force screen readers to read the alert even if it never left the screen
      if (emailInputEl.current) {
        emailInputEl.current.focus();
      }
      return {};
    },
    [
      emailOtpEnabled,
      enableNumericPhoneValidation,
      invalidErrorMessage,
      jwtEmailEnabled,
      region,
      requiredErrorMessage,
      smsOtpEnabled,
    ]
  );

  const shouldBlockSignIn = !isAcceptTermsOnSignInTempSelected && enableAcceptTermsOnSignInTemp;
  const handleSubmit = useCallback(async () => {
    const { email, phoneNumber } = await parse(emailOrPhone);

    if (shouldBlockSignIn) {
      setErrorMessage(formatMessage({ id: AGREE_TO_TERMS_OF_SERVICE_ERROR }));
      return;
    }
    if (email || phoneNumber) {
      setErrorMessage('');
      aimToSignIn({ email, phoneNumber });
    }
  }, [aimToSignIn, emailOrPhone, formatMessage, parse, shouldBlockSignIn]);

  return (
    <>
      <Box>
        <TextInputWrapper>
          <TextInput
            accessibilityLabel={errorMessage ? errorMessage : labelMessage}
            testID={SIGNIN_FORM_EMAIL_INPUT}
            errorMessage={errorMessage}
            label={labelMessage}
            required
            type="email"
            onChange={handleEmailOrPhoneChange}
            ref={emailInputEl}
            autoComplete="email"
            value={emailOrPhone}
            autoCapitalize="none"
            keyboardType="email-address"
            returnKeyType="done"
            onSubmitEditing={handleSubmit}
            autoFocus
          />
        </TextInputWrapper>
        {enableAcceptTermsOnSignInTemp && (
          <CheckBoxWrapper>
            <Checkbox
              testID="checkbox-accept-terms-on-sign-in-temp"
              value="checkbox-accept-terms-on-sign-in-temp"
              isChecked={isAcceptTermsOnSignInTempSelected}
              onChange={handleAcceptTermsOnSignInTempChange}
            >
              <TermsOfServiceLabel />
            </Checkbox>
          </CheckBoxWrapper>
        )}
        <StyledButtonWidthLimit>
          <StyledBoxActionButton>
            <ActionButton
              fullWidth
              testID={SIGNIN_SUBMIT_BUTTON}
              isLoading={isLoading}
              disabled={isLoading || shouldBlockSignIn}
              onPress={handleSubmit}
            >
              {formatMessage({ id: 'signInSignUp' })}
            </ActionButton>
          </StyledBoxActionButton>
        </StyledButtonWidthLimit>
      </Box>
      <ErrorDialog />
    </>
  );
};

export default SignInForm;
