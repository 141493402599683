import { Stack } from 'expo-router';

import { getScreenTitleHeader } from '../../components/get-screen-title-header';

export default function RewardsLayout() {
  return (
    <Stack
      screenOptions={{
        header: getScreenTitleHeader(),
      }}
    >
      <Stack.Screen name="details" options={{ title: 'Rewards' }} />
      <Stack.Screen name="history" options={{ title: 'Rewards History' }} />
      <Stack.Screen name="list/[rewardId]" options={{ title: 'Rewards Details' }} />
      <Stack.Screen name="offers" options={{ title: 'Offers' }} />
    </Stack>
  );
}
