import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconBookmark = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M5 21.1V4.477c0-.4.15-.75.45-1.05.3-.3.65-.45 1.05-.45h11c.4 0 .75.15 1.05.45.3.3.45.65.45 1.05V21.1l-7-3-7 3Z"
      />
    </Svg>
  );
};
