import { ReactNode, useMemo, useState } from 'react';

import { DropdownSetterContext, DropdownStateContext } from './context';

export type DropdownProps = {
  children: ReactNode;
};
export function Dropdown({ children }: DropdownProps) {
  const [triggerLayout, setTriggerLayout] = useState({ pageX: 0, width: 0, pageY: 0, height: 0 });
  const [isExpanded, setIsExpanded] = useState(false);

  const stateValue = useMemo(
    () => ({
      triggerLayout,
      isExpanded,
    }),
    [triggerLayout, isExpanded]
  );

  const setterValue = useMemo(
    () => ({
      setTriggerLayout,
      setIsExpanded,
    }),
    [setTriggerLayout, setIsExpanded]
  );

  return (
    <DropdownStateContext.Provider value={stateValue}>
      <DropdownSetterContext.Provider value={setterValue}>
        {children}
      </DropdownSetterContext.Provider>
    </DropdownStateContext.Provider>
  );
}
