import { CognitoUser, CognitoUserAttribute, CognitoUserSession } from 'amazon-cognito-identity-js';
import { isNil } from 'lodash';

import { Cognito } from 'utils/cognito';

import { getCurrentSession } from './get-current-session';
import normalizeString from './util';

interface IValidateLogin {
  username: string;
  code: string;
  sessionId: string;
}

interface IUpdateUserAttributes {
  name?: string;
  phoneNumber?: string;
  dob?: string;
}

////////////////////////////////////////////////////////////
// Sign In Moved to GQL queries
////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////
// Sign Out
////////////////////////////////////////////////////////////

export const signOut = async (shouldSignOutGlobally: boolean = false): Promise<void> => {
  const userPool = Cognito.userPool();
  const cognitoUser = userPool.getCurrentUser();

  if (!cognitoUser) {
    // Should this reject instead? Signing out when no current user is currently a noop;
    // We could reject but it seems harmless to just do nothing.
    return Promise.resolve();
  }

  if (shouldSignOutGlobally) {
    return new Promise((resolve, reject) =>
      cognitoUser.globalSignOut({ onSuccess: () => resolve(), onFailure: reject })
    );
  }

  return new Promise((resolve, _reject) => cognitoUser.signOut(() => resolve()));
};

////////////////////////////////////////////////////////////
// Confirm Login
////////////////////////////////////////////////////////////

export const validateLogin = ({
  username,
  code,
  sessionId,
}: IValidateLogin): Promise<CognitoUserSession | null> => {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUserForUsername(username);
    // @ts-expect-error TS(2339) FIXME: Property 'Session' does not exist on type 'Cognito... Remove this comment to see the full error message
    cognitoUser.Session = sessionId;

    cognitoUser.sendCustomChallengeAnswer(code, {
      onSuccess: session => {
        resolve(session);
      },
      onFailure: err => {
        reject(err);
      },
    });
  });
};

////////////////////////////////////////////////////////////
// Update User Attributes
////////////////////////////////////////////////////////////

export const updateUserAttributes = async ({ name, phoneNumber, dob }: IUpdateUserAttributes) => {
  const userPool = Cognito.userPool();
  const cognitoUser = userPool.getCurrentUser();
  const session = await getCurrentSession();

  if (!cognitoUser) {
    return Promise.reject('No signed in user');
  }

  if (!session) {
    return Promise.reject('User does not have a valid session');
  }
  if (session) {
    cognitoUser?.setSignInUserSession(session);
  }
  const attributesToUpdate: Array<CognitoUserAttribute> = [];

  if (!isNil(name)) {
    attributesToUpdate.push(new CognitoUserAttribute({ Name: 'name', Value: name }));
  }

  if (!isNil(phoneNumber)) {
    attributesToUpdate.push(new CognitoUserAttribute({ Name: 'phone_number', Value: phoneNumber }));
  }

  if (!isNil(dob)) {
    attributesToUpdate.push(new CognitoUserAttribute({ Name: 'birthdate', Value: dob }));
  }

  return new Promise((resolve, reject) => {
    cognitoUser.updateAttributes(attributesToUpdate, (err, result) => {
      if (err) {
        return reject(err);
      }

      return resolve(result);
    });
  });
};

////////////////////////////////////////////////////////////
// Helper
////////////////////////////////////////////////////////////

const getCognitoUserForUsername = (username: string): CognitoUser => {
  const normalizedUsername = normalizeString(username);
  return new CognitoUser({
    Username: normalizedUsername,
    Pool: Cognito.userPool(),
    Storage: Cognito.storage,
  });
};
