import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, Icon, Pressable, Text } from '@fhs-legacy/universal-components';
import { theme as configureTheme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import theme from './theme';

const PhoneNumberInformation = Text.withConfig({
  variant: 'copyTwo',
  color: theme.phoneNumberInformationColor,
  fontSize: 'xs',
  marginTop: '$3',
});

const PhoneNumberToggle = Pressable.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
});

const PhoneAddressInformationToggle = () => {
  const [visible, setVisible] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <Box marginBottom="$3">
      <PhoneNumberToggle onPress={() => setVisible(!visible)}>
        <Text fontSize="sm">{formatMessage({ id: 'whyDoYouNeedMyPhoneNumber' })}</Text>
        <Icon
          variant={!visible ? 'select' : 'collapse'}
          color={configureTheme.token('icon-default')}
          width="24px"
          {...hiddenAccessibilityPlatformProps}
        />
      </PhoneNumberToggle>
      {visible && (
        <PhoneNumberInformation testID="phoneNumberDisclaimer">
          {formatMessage({ id: 'deliveryPhoneDisclosure' })}
        </PhoneNumberInformation>
      )}
    </Box>
  );
};

export default PhoneAddressInformationToggle;
