import * as React from 'react';
import { useIntl } from 'react-intl';

import { ErrorRemovingLastFavoriteStoreModal } from 'components/modal-error-removing-last-favorite-store';
import { InternalStoreDiagnosticModal } from 'components/store-diagnostic-modal';
import { useFavoriteStore } from 'hooks/favorite-stores';

import { useDiagnostics } from './hooks/use-diagnostics';
import { useDistanceText } from './hooks/use-distance-text';
import { useErrorMessage } from './hooks/use-error-message';
import { useOpenClosedText } from './hooks/use-open-closed-text';
import { useServiceModeText } from './hooks/use-service-mode-text';
import { Wrapper } from './store-card.styled';
import { StoreCardView } from './store-card.view';
import type { StoreCardProps } from './types';

/**
 * StoreCard container component. This component is intended to be used on the
 * store locator. Data orchestration should happen at this level while keeping
 * the view layer more presentational.
 */
const StoreCard: React.VFC<StoreCardProps> = ({
  testID,
  restaurant,
  onCardClick,
  isSelected,
  isSingleCardUI,
}) => {
  const distanceText = useDistanceText({ restaurant });
  const errorMessage = useErrorMessage({ restaurant });
  const serviceModeText = useServiceModeText({ restaurant });
  const { formatMessage } = useIntl();

  const {
    data: diagnosticsData,
    showModal: showDiagnosticsModal,
    onClick: onDiagnosticsClick,
    onDismiss: showDiagnosticsModalDismiss,
  } = useDiagnostics({ restaurant });

  const {
    ErrorFavoriteStoreDialog,
    favStoreLoading,
    isFavorite,
    toggleFavorite: onFavoriteClick,
    hasErrorRemovingLastFavStore,
    onDismissErrorRemovingLastFavStoreModal,
  } = useFavoriteStore(restaurant._id ?? '', restaurant.number ?? '');

  const { isOpen, text } = useOpenClosedText(restaurant);

  return (
    <Wrapper testID={testID}>
      <StoreCardView
        onCardClick={onCardClick}
        onDiagnosticsClick={onDiagnosticsClick}
        onFavoriteClick={onFavoriteClick}
        isFavorite={isFavorite}
        isUpdatingFavorites={favStoreLoading}
        isSelected={isSelected}
        isSingleCardUI={isSingleCardUI}
        storeDetails={{
          body: serviceModeText,
          distanceText,
          errorMessage,
          ...(isOpen ? { openText: text } : { closedText: text }),
          title: restaurant.name,
          address: restaurant.physicalAddress?.address1,
          isHalal: Boolean(restaurant.isHalal),
        }}
      />
      {showDiagnosticsModal && (
        <InternalStoreDiagnosticModal
          onModalDismiss={showDiagnosticsModalDismiss}
          storeDiagnosticData={diagnosticsData}
        />
      )}
      {hasErrorRemovingLastFavStore && (
        <ErrorRemovingLastFavoriteStoreModal
          isOpen={hasErrorRemovingLastFavStore}
          onDismiss={onDismissErrorRemovingLastFavStoreModal}
        />
      )}
      <ErrorFavoriteStoreDialog buttonLabel={formatMessage({ id: 'signUp' })} />
    </Wrapper>
  );
};

export default StoreCard;
