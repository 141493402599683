import { LANGUAGES, REGIONS } from 'state/intl/types';
import { StorageKeys } from 'utils/local-storage';

export const LOCALE_SELECTED_QUERY_PARAM = 'locale-selected';

export const LOCALE_SEPARATOR = '-';

/* @TODO: let's just use formatMessage here instead of redefining for every language */
export const READABLE_REGIONS = {
  [LANGUAGES.en]: {
    [REGIONS.CA]: 'Canada',
    [REGIONS.US]: 'USA',
  },
  [LANGUAGES.fr]: {
    [REGIONS.CA]: 'Canada',
    [REGIONS.US]: 'États Unis',
  },
};

export const regionSpecificLocalStorageKeys = [
  StorageKeys.STORE,
  StorageKeys.AUTH_REDIRECT,
  StorageKeys.ORDER,
  StorageKeys.SERVICE_MODE,
];

export const supportedLanguagesByRegion = {
  ca: [LANGUAGES.en, LANGUAGES.fr],
  us: [LANGUAGES.en],
};
