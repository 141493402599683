import * as React from 'react';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Box, Text } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import Dialog from 'components/dialog';

import { ISaveFavoriteOrderUnsavedProps } from './types';

/**
 *
 * Save Favorite Order Unsaved Changes
 *
 *
 */

const ButtonContainer = Box.withConfig({
  margin: '$2',
  width: 'full',
});

const TextStyled = Text.withConfig({
  margin: '$2',
});

const SaveFavoriteOrderUnsaved: FC<React.PropsWithChildren<ISaveFavoriteOrderUnsavedProps>> = ({
  onSave,
  onDiscard,
  onDismiss,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog
      showDialog
      testID="save-favorite-order-unsaved"
      onDismiss={() => null}
      modalAppearanceEventMessage="Save Favorite Order"
      heading={formatMessage({ id: 'saveFavoriteOrderUnsaved' })}
      bodyComponent={
        <TextStyled>{formatMessage({ id: 'saveFavoriteOrderUnsavedBody' })}</TextStyled>
      }
      actions={
        <>
          <ButtonContainer>
            <ActionButton fullWidth onPress={onSave} testID="save-favorite-order-save-changes">
              {formatMessage({ id: 'saveChanges' })}
            </ActionButton>
          </ButtonContainer>
          <ButtonContainer>
            <ActionButton
              fullWidth
              onPress={onDiscard}
              testID="save-favorite-order-discard-changes"
            >
              {formatMessage({ id: 'discardChanges' })}
            </ActionButton>
          </ButtonContainer>
          <ButtonContainer>
            <ActionButton
              fullWidth
              onPress={onDismiss}
              testID="save-favorite-order-cancel"
              variant={ActionButtonVariants.OUTLINE}
            >
              {formatMessage({ id: 'cancel' })}
            </ActionButton>
          </ButtonContainer>
        </>
      }
    />
  );
};

export default SaveFavoriteOrderUnsaved;
