import { Stack, useLocalSearchParams } from 'expo-router';

import { getScreenTitleHeader } from '../../components/get-screen-title-header';

export default function StaticPageLayout() {
  const { title = '' } = useLocalSearchParams<{ title: string; staticPage: string }>();
  return (
    <Stack
      screenOptions={{
        header: getScreenTitleHeader({ backStyle: 'close', cannotGoBackHref: '/' }),
        title,
      }}
    />
  );
}
