import { PropsWithChildren } from 'react';

import { IServerOrder } from '@rbi-ctg/menu';
import { OrderStatus } from 'state/order/types';
import { ServiceMode } from 'state/service-mode';

import DeliveryConfirmation from './delivery/delivery-confirmation';
import Fallback from './fallback';
import PickupConfirmed from './pickup-confirmed';

interface IOrderConfirmationContentProps {
  loading: boolean;
  serverOrder: IServerOrder | null;
}

export const OrderConfirmationPageContent = (
  props: PropsWithChildren<IOrderConfirmationContentProps>
) => {
  const { loading, serverOrder } = props;

  if (!serverOrder || loading) {
    return <Fallback />;
  }

  const {
    cart: { serviceMode },
    status: orderStatus,
  } = serverOrder;

  if (serviceMode === ServiceMode.DELIVERY) {
    return <DeliveryConfirmation serverOrder={serverOrder} />;
  }

  switch (orderStatus) {
    case OrderStatus.INSERT_SUCCESSFUL:
    case OrderStatus.UPDATE_REQUESTED:
    case OrderStatus.UPDATE_ERROR:
    case OrderStatus.UPDATE_SUCCESSFUL:
      return <PickupConfirmed serverOrder={serverOrder} />;

    default:
      return <Fallback />;
  }
};
