import { ICartProperty, Rule } from '@rbi-ctg/offers';
import { RBIPlatform } from 'utils/environment';

import { findAllRulesOfTypeThatApply } from './rule-of-type-will-apply';
import { RuleType } from './types';
import { valueSatisfiesCartPropertyRule } from './value-satisfies-cart-property-rule';

export const PLATFORMS = [RBIPlatform.APP, RBIPlatform.KIOSK, RBIPlatform.WEB];

export function getRequiredPlatforms(ruleSet: Rule[] | null): string[] {
  const cartPropertyRules = findAllRulesOfTypeThatApply<ICartProperty>(
    RuleType.CartProperty,
    ruleSet
  ).filter(({ key }) => key === 'platform');

  return cartPropertyRules.reduce<string[]>((acc, rule) => {
    return acc.filter(valueSatisfiesCartPropertyRule(rule));
  }, PLATFORMS);
}
