import { useState } from 'react';

import useDialogModal from 'hooks/use-dialog-modal';
import useErrorModal from 'hooks/use-error-modal';

export default function useSearchStores() {
  const [activeStoreId, setActiveStoreId] = useState('');

  const [Dialog] = useDialogModal({
    modalAppearanceEventMessage: 'Notify user to enable location services',
  });

  const [ErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Fetching Restaurants Error',
  });

  return {
    activeStoreId,
    ErrorDialog,
    Dialog,
    setActiveStoreId,
  };
}
