import delv from 'dlv';
import React from 'react';

import { FormControl, Radio } from '@fhs-legacy/universal-components';
import {
  IItem,
  IItemOption,
  IModifierSelection,
  IQuickConfig,
  ISanityItemOption,
} from '@rbi-ctg/menu';
import SelectionContainer from 'components/selection-container';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import { useFormatCalories } from 'hooks/use-format-calories';
import { useMenuContext } from 'state/menu';
import { filterExcludedItemOptions } from 'utils/menu';
import { getItemCalories } from 'utils/menu/calories';
import {
  ModifierComponentStyle,
  getModifierSelectionsText,
  getSelectorSelectionIndex,
  getStepperSelectionIndex,
  groupItemOptionsUsingDisplayGroups,
  isModifierSelected,
  modifierSelectedQuantity,
  shouldDisplayItemOption,
  shouldDisplayItemOptionGroup,
} from 'utils/menu/modifiers';

import { MenuOptionSelector } from '../menu-option-selector';
import { MenuOptionStepper } from '../menu-option-stepper';
import { HandleModifierSelection, HandleQuantityUpdate } from '../types';

interface IModifiersProps {
  item: IItem | null;
  modifiers: (ISanityItemOption | IItemOption)[] | null;
  modifierSelections: IModifierSelection[];
  excludesForPickerAspects?: { value: string }[];
  radioGroupParentId: string;
  quickConfigs?: IQuickConfig[];
  handleModifierSelection: HandleModifierSelection;
  handleModifierQuantityUpdate: HandleQuantityUpdate;
}

function Modifiers({
  item,
  modifiers,
  excludesForPickerAspects = [],
  radioGroupParentId,
  modifierSelections,
  quickConfigs,
  handleModifierSelection,
  handleModifierQuantityUpdate,
}: IModifiersProps) {
  const { priceForItemOptionModifier, showStaticMenu } = useMenuContext();
  const filterExcluded = filterExcludedItemOptions(excludesForPickerAspects);
  const formatCalories = useFormatCalories();

  // @ts-ignore FIXME: Type '(ISanityItemOption | IItemOption)[]' is not assignable to type 'ISanityItemOption[]'.
  const filteredModifiers: ISanityItemOption[] = filterExcluded(modifiers || []);

  return (
    <>
      {groupItemOptionsUsingDisplayGroups(filteredModifiers).map(group => {
        const selectedSelectorOptionModifierIndex = getSelectorSelectionIndex(
          group,
          modifierSelections
        );

        const options: ISanityItemOption[] = delv(group, 'options') || [];
        const selectedSelectorOptionModifierName =
          selectedSelectorOptionModifierIndex >= 0
            ? delv(options, `0.options.${selectedSelectorOptionModifierIndex}.name.locale`, '')
            : getModifierSelectionsText(options, modifierSelections, quickConfigs);

        const selectedSelectorOptionModifierKey =
          selectedSelectorOptionModifierIndex >= 0
            ? delv(options, `0.options.${selectedSelectorOptionModifierIndex}._key`, '')
            : '';

        const isModifierRequired = () =>
          options.every(
            option =>
              (!option.componentStyle ||
                option.componentStyle === ModifierComponentStyle.Selector) &&
              option.minAmount > 0
          );

        return shouldDisplayItemOptionGroup(options) ? (
          <SelectionContainer
            title={group.title}
            key={group.title}
            selection={selectedSelectorOptionModifierName}
            required={!showStaticMenu && isModifierRequired()}
          >
            <VisuallyHidden accessibilityLabel={group.title} />
            {/* TODO: RN - add proper accessibility rold */}
            <FormControl testID="modifier-detail" accessibilityLabel={group.title} /*role="group"*/>
              {group.options.map((option: ISanityItemOption) => {
                if (!shouldDisplayItemOption(option)) {
                  return null;
                }

                if (option.componentStyle === ModifierComponentStyle.Stepper) {
                  const selectionIndex = getStepperSelectionIndex(option, modifierSelections);
                  const modifier = option.options[selectionIndex];

                  if (!modifier) {
                    return null;
                  }

                  const optionName = delv(option, 'name.locale');
                  const calories = getItemCalories(modifier);
                  const caloriesString = formatCalories(calories);

                  return (
                    <MenuOptionStepper
                      key={optionName}
                      name={optionName}
                      calories={caloriesString}
                      itemOption={option}
                      index={selectionIndex}
                      onChange={index => {
                        handleModifierSelection(radioGroupParentId, option.options[index], option);
                      }}
                      price={priceForItemOptionModifier({ item, itemOption: option, modifier })}
                      image={modifier.image}
                      imageDescription={modifier?.imageDescription?.locale}
                    />
                  );
                }
                // default to selector
                const SelectorOptions = option.options.map(optionModifier => {
                  const calories = getItemCalories(optionModifier);
                  const caloriesString = calories > 0 ? formatCalories(calories) : '';
                  const hasSelection = isModifierSelected(
                    optionModifier,
                    modifierSelections,
                    // NOTE: expects comboSlotId, but not used for TH
                    undefined,
                    option
                  );
                  const modifierPrice = hasSelection
                    ? priceForItemOptionModifier({
                        item,
                        itemOption: option,
                        modifier: optionModifier,
                      })
                    : 0;
                  const optionModifierName = delv(optionModifier, 'name.locale');
                  // TODO: RN - clean up props now that we're using UCL radio
                  return (
                    <MenuOptionSelector
                      itemOption={option}
                      image={
                        optionModifier.image || optionModifier.modifierMultiplier.modifier.image
                      }
                      imageDescription={optionModifier?.imageDescription?.locale}
                      name={optionModifierName}
                      inputName={group.title}
                      subtitle={caloriesString}
                      key={optionModifier._key}
                      value={optionModifier._key}
                      itemId={radioGroupParentId}
                      checked={hasSelection}
                      quantity={modifierSelectedQuantity(optionModifier, modifierSelections)}
                      onQuantityUpdate={handleModifierQuantityUpdate}
                      price={modifierPrice}
                      testId={`item-option-${optionModifierName}`}
                    />
                  );
                });

                const onSelectedOptionModifier = (value: string) => {
                  const selection = option.options.find(
                    optionModifier => optionModifier._key === value
                  );

                  if (!selection) {
                    return;
                  }

                  handleModifierSelection(radioGroupParentId, selection, option);
                };

                return (
                  <Radio.Group
                    key={option._key}
                    name={selectedSelectorOptionModifierName}
                    value={selectedSelectorOptionModifierKey}
                    onChange={onSelectedOptionModifier}
                  >
                    {SelectorOptions}
                  </Radio.Group>
                );
              })}
            </FormControl>
          </SelectionContainer>
        ) : null;
      })}
    </>
  );
}

export default Modifiers;
