import { TextArea } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

export const InstructionsTextarea = TextArea.withConfig({
  width: 'full',
  fontSize: 'md',
  borderWidth: '1px',
  borderColor: theme.token('border-color-disabled'),
  borderRadius: Styles.borderRadius,
  paddingY: '$4',
  paddingLeft: '$4',
  paddingRight: '$2',
  marginRight: '$0',
  _focus: {
    outline: 'none',
    borderColor: Styles.color.primary,
  },
});

/* TODO: RN - migrate to Text Area from UCL */
// export const StyledFloatingLabelTextArea = styled(FloatingLabelTextArea)`
//   & > textarea {
//     line-height: 1.5;
//     padding: 1rem 2rem 1rem 1rem;
//     min-height: auto;
//   }
// `;
