import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconChevronDown = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path fill="currentColor" d="M7.41 8.69 12 13.27l4.59-4.58L18 10.1l-6 6-6-6 1.41-1.41Z" />
    </Svg>
  );
};
