import * as React from 'react';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import useOrderNumber from 'hooks/use-order-number';
import { useLocale } from 'state/intl';
import { useOrderTimedFire } from 'state/order/hooks/use-order-timed-fire';
import { format } from 'utils/language/format-date';
import { getLocalizedServiceMode } from 'utils/service-mode';

import {
  ReceiptDate,
  ReceiptHeadInfoContainer,
  ReceiptInfo,
  ReceiptInfoDetail,
  ReceiptInfoDetailLabel,
  ReceiptInfoLabel,
} from './receipt-head-info.styled';
import { IOrderHeaderProps } from './types';

const ReceiptHeadInfo: FC<React.PropsWithChildren<IOrderHeaderProps>> = ({ order }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const { pickupDate } = useOrderTimedFire({ serverOrder: order });
  const { formattedDate, formattedTime } = useMemo(() => {
    const orderDate = new Date(order.createdAt);
    const formattedOrderDate = orderDate.toLocaleString(formatMessage({ id: 'localeDateTime' }), {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
    const formattedOrderTime = orderDate.toLocaleString('en-us', {
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    });

    return { formattedDate: formattedOrderDate, formattedTime: formattedOrderTime };
  }, [formatMessage, order.createdAt]);

  const { storeAddress, serviceMode } = order.cart;
  const orderNumber = useOrderNumber(order);
  const localizedServiceMode = getLocalizedServiceMode(serviceMode, formatMessage);
  const pickupDateFormatted = format(pickupDate, 'PP - h:mm a', language);

  return (
    <ReceiptHeadInfoContainer>
      <ReceiptInfo>
        <ReceiptDate>{formattedDate}</ReceiptDate>
      </ReceiptInfo>
      {orderNumber ? (
        <ReceiptInfo>
          <ReceiptInfoLabel>{formatMessage({ id: 'orderNumber' })}:</ReceiptInfoLabel>
          <ReceiptInfoDetail>{orderNumber}</ReceiptInfoDetail>
        </ReceiptInfo>
      ) : null}
      <ReceiptInfo>
        <ReceiptInfoLabel>{formatMessage({ id: 'orderTime' })}:</ReceiptInfoLabel>
        <ReceiptInfoDetail>
          <ReceiptInfoDetailLabel>{formattedTime}</ReceiptInfoDetailLabel>
        </ReceiptInfoDetail>
      </ReceiptInfo>

      <ReceiptInfo>
        <ReceiptInfoLabel>
          {formatMessage(
            { id: 'method' },
            {
              serviceMode: '', // set service mode below for proper formatting in grid
            }
          )}
        </ReceiptInfoLabel>
        <ReceiptInfoDetail>
          <ReceiptInfoDetailLabel>{localizedServiceMode}</ReceiptInfoDetailLabel>
        </ReceiptInfoDetail>
      </ReceiptInfo>

      {!!order.fireOrderIn && (
        <ReceiptInfo>
          <ReceiptInfoLabel>{formatMessage({ id: 'pickUpTime' })}:</ReceiptInfoLabel>
          <ReceiptInfoDetail>
            <ReceiptInfoDetailLabel>{pickupDateFormatted}</ReceiptInfoDetailLabel>
          </ReceiptInfoDetail>
        </ReceiptInfo>
      )}

      <ReceiptInfo>
        <ReceiptInfoLabel>{formatMessage({ id: 'location' })}:</ReceiptInfoLabel>
        <ReceiptInfoDetail>
          <ReceiptInfoDetailLabel testID="receipt-address">
            {storeAddress.addressLine1}
            {', '}
            {storeAddress.addressLine2 &&
              `${formatMessage({ id: 'unitNumber' }, { unitNumber: storeAddress.addressLine2 })}, `}
            {storeAddress.city}
            {', '}
            {storeAddress.state}
            {', '}
            {storeAddress.zip}
          </ReceiptInfoDetailLabel>
        </ReceiptInfoDetail>
      </ReceiptInfo>
    </ReceiptHeadInfoContainer>
  );
};

export default ReceiptHeadInfo;
