// @react-native-firebase/analytics crashes web so we replace called methods with noops

const analytics = () => {
  return {
    logEvent() {
      // Noop for web
    },
  };
};

export default analytics;
