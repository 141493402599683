import { useMemo } from 'react';

import { useOrderContext } from '@fhs-legacy/frontend/src/state/order';
import { frontendToBackendCartEntryMap } from '@fhs-legacy/frontend/src/utils/cart';
import { IBackendCartEntries, ICartEntry } from '@rbi-ctg/menu';

export function useOrderLegacyStates() {
  const { cartEntries, addItemToCart, removeFromCart, updateQuantity } = useOrderContext();

  const backendCartEntries: IBackendCartEntries[] = useMemo(
    () => cartEntries?.map(frontendToBackendCartEntryMap) ?? [],
    [cartEntries]
  );

  return {
    backendCartEntries,
    cartEntries: cartEntries as ICartEntry[],
    addItemToCart,
    removeFromCart,
    updateQuantity,
  };
}
