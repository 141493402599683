const TORONTO_COORDINATES = {
  lat: 43.6531,
  lng: -79.3839,
  zoom: 5.2,
};

const mapDefaults = {
  CA: TORONTO_COORDINATES,
  US: {
    lat: 39.8283459,
    lng: -98.5794797,
    zoom: 5.2,
  },
  default: TORONTO_COORDINATES,
};

export default mapDefaults;
