import { useCallback } from 'react';

import { IServerOrder } from '@rbi-ctg/menu';
import { loadScript } from 'components/payments/integrations/hooks/utils';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { useAuthContext } from 'state/auth';
import { useLocale } from 'state/intl';
import { IApplePayDetails } from 'state/payment/types';
import { brand, env } from 'utils/environment';

import { IEProtectPayPageResponse } from './types';

const useRequestWorldpayLowValueToken = ({
  serverOrder,
}: {
  serverOrder?: IServerOrder | null;
}) => {
  const worldpayConfig = useConfigValue({ key: 'worldpay', defaultValue: {} });
  const { region } = useLocale();
  const auth = useAuthContext();
  const requestWorldpayLowValueToken = useCallback(
    async ({ applePayDetails }: { applePayDetails: IApplePayDetails }): Promise<string> => {
      try {
        const { eProtectUrl, paypageId } = worldpayConfig;

        if (!eProtectUrl) {
          throw new Error('Worldpay eProtectUrl not available');
        }

        if (!paypageId) {
          throw new Error('Worldpay paypageId not available');
        }

        const scriptUrl = `${eProtectUrl}/eProtect/eProtect-api3.js`;
        await loadScript(scriptUrl);
        const merchantOrderId = serverOrder?.rbiOrderId?.slice(0, 20);
        const merchantReportGroup = `${env()}-${brand()}-${region}`.toUpperCase();
        const preRequest = {
          paypageId,
          reportGroup: merchantReportGroup,
          orderId: merchantOrderId || 'N/A',
          id: merchantOrderId || 'N/A',
          accountNumber: auth.user?.cognitoId || 'N/A',
          url: eProtectUrl,
          applepay: {
            data: applePayDetails.data,
            signature: applePayDetails.signature,
            version: applePayDetails.version,
            header: {
              applicationData: '',
              ephemeralPublicKey: applePayDetails.ephemeralPublicKey,
              publicKeyHash: applePayDetails.publicKeyHash,
              transactionId: applePayDetails.transactionId,
            },
          },
        };
        const request = {};
        return new Promise(resolve => {
          window.eProtect().sendToEprotect(
            preRequest,
            request,
            (data: IEProtectPayPageResponse) => {
              // Success
              return resolve(data.paypageRegistrationId);
            },
            () => {
              // Error
              throw new Error('Could not retrieve low value token: eProtect request failed');
            },
            () => {
              // Timeout
              throw new Error('Timeout retrieve low value token: eProtect request failed');
            },
            5000
          );
        });
      } catch (error) {
        throw new Error('Unexpected error requesting Worldpay low value token');
      }
    },
    [worldpayConfig, serverOrder?.rbiOrderId, region, auth.user?.cognitoId]
  );
  return { requestWorldpayLowValueToken };
};

export default useRequestWorldpayLowValueToken;
