import React from 'react';

import { ScrollView } from '@fhs-legacy/universal-components';
import Modal, { ModalContent } from 'components/modal';

import DiagnosticModalContents from './diagnostic-modal-contents';
import { IInternalStoreDiagnosticsModalProps } from './types';

export const InternalStoreDiagnosticModal: React.FC<
  React.PropsWithChildren<IInternalStoreDiagnosticsModalProps>
> = ({ onModalDismiss, storeDiagnosticData }) => (
  <Modal
    onDismiss={onModalDismiss}
    mParticleEventData={{
      modalAppearanceEventMessage: 'Store Diagnostics',
    }}
  >
    <ModalContent flex={1}>
      <ScrollView>
        <DiagnosticModalContents storeDiagnosticData={storeDiagnosticData} />
      </ScrollView>
    </ModalContent>
  </Modal>
);
