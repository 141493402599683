import { Link, router, useNavigation, usePathname } from 'expo-router';
import { StyleSheet } from 'react-native';

import {
  DesktopHeaderServiceModeLocation,
  Dropdown,
  HeaderDivider,
  HeaderFrame,
  HeaderText,
  IconAccount,
  IconChevronDown,
  MqSwitch,
  Text,
  XStack,
  YStack,
  tokens,
} from '@fhs/ui';
import { removeRouteFromStack } from '@fhs/utils/src/navigation';
import { useAuthContext } from '@fhs-legacy/frontend/src/state/auth';
import { ServiceMode, useServiceModeContext } from '@fhs-legacy/frontend/src/state/service-mode';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';

import { useServiceModeLocation } from '../../utils/use-get-service-mode-location-type';
import { SubmitFeedbackHeaderLink } from '../submit-feedback-header-link';

import { HeaderCartButton } from './header-cart-button';
import { HeaderLeftDesktop } from './header-left-desktop';
import { PointsButton } from './points-button';
import { SignUpButton } from './sign-up-button';
import { AppHeaderProps } from './types';

function AppHeaderRightDesktop() {
  const { isAuthenticated } = useAuthContext();
  const pathname = usePathname();
  const navigation = useNavigation();
  const { serviceMode } = useServiceModeContext();
  const serviceModeLocationProps = useServiceModeLocation();
  const isDelivery = serviceMode === ServiceMode.DELIVERY;

  return (
    <XStack style={styles.gap24}>
      {isAuthenticated ? (
        <XStack style={styles.tabsContainer}>
          <YStack>
            <Link href={isDelivery ? routes.address : routes.storeLocator} asChild>
              <DesktopHeaderServiceModeLocation {...serviceModeLocationProps} />
            </Link>
          </YStack>
          <HeaderDivider />
          <XStack style={styles.dropdownWrapper}>
            <Dropdown>
              <Dropdown.Trigger>
                {({ isExpanded }) => (
                  <XStack style={styles.dropdownTrigger}>
                    <IconAccount
                      size={20}
                      color={isExpanded ? tokens.colors.$houseRedDarken : undefined}
                    />
                    <XStack style={styles.textWithCaret}>
                      <MqSwitch
                        $base={() => null}
                        $gteDesktopLg={() => (
                          <HeaderText isHighlighted={isExpanded}>Account</HeaderText>
                        )}
                      />
                      <IconChevronDown
                        size={20}
                        color={isExpanded ? tokens.colors.$houseRedDarken : undefined}
                      />
                    </XStack>
                  </XStack>
                )}
              </Dropdown.Trigger>
              <Dropdown.Pane>
                <YStack style={styles.dropdownPane}>
                  <Dropdown.ClosePaneOnChange value={pathname} />
                  <Link href="/account/info">
                    <Text.Paragraph size="md">Account Info</Text.Paragraph>
                  </Link>
                  <Link href="/redeem/">
                    <Text.Paragraph size="md">Earn Points</Text.Paragraph>
                  </Link>
                  <Link href="/account/payment">
                    <Text.Paragraph size="md">Payment Methods</Text.Paragraph>
                  </Link>
                  <Link href="/account/orders">
                    <Text.Paragraph size="md">Recent Orders</Text.Paragraph>
                  </Link>
                  <SubmitFeedbackHeaderLink />
                </YStack>
              </Dropdown.Pane>
            </Dropdown>
          </XStack>
          <HeaderDivider />
        </XStack>
      ) : (
        <XStack style={styles.tabsContainer}>
          <YStack>
            <Link href={isDelivery ? routes.address : routes.storeLocator} asChild>
              <DesktopHeaderServiceModeLocation {...serviceModeLocationProps} />
            </Link>
          </YStack>
          <HeaderDivider />
        </XStack>
      )}
      <XStack style={styles.rightCornerButtons}>
        {isAuthenticated ? (
          <Link href="/rewards/details" asChild>
            <PointsButton />
          </Link>
        ) : (
          <Link href="/signin/" asChild>
            <SignUpButton />
          </Link>
        )}
        <HeaderCartButton
          onPress={async () => {
            /*
              This is a hack to force remounting the cart when navigating to it through this button.
              With this trick, we are removing the cart route from stack before navigating to it again, which will force the page to be mounted.
            */
            await removeRouteFromStack(navigation, 'cart');
            router.navigate('/cart/');
          }}
        />
      </XStack>
    </XStack>
  );
}

export function AppHeaderDesktop({
  left = <HeaderLeftDesktop />,
  right = <AppHeaderRightDesktop />,
}: AppHeaderProps) {
  return (
    <HeaderFrame style={styles.frame}>
      <HeaderFrame.Left>{left}</HeaderFrame.Left>
      <HeaderFrame.Right>{right}</HeaderFrame.Right>
    </HeaderFrame>
  );
}

const styles = StyleSheet.create({
  tabsContainer: {
    alignItems: 'center',
    gap: 32,
  },
  frame: {
    height: 68,
    borderBottomWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
  },
  textWithCaret: {
    gap: 4,
    height: '100%',
    alignItems: 'center',
  },
  rightCornerButtons: {
    gap: 8,
    alignItems: 'center',
  },
  gap24: {
    gap: 24,
  },
  dropdownTrigger: {
    gap: 8,
    height: '100%',
    alignItems: 'center',
  },
  dropdownWrapper: {
    alignItems: 'center',
  },
  dropdownPane: {
    padding: 32,
    gap: 8,
    minWidth: 228,
  },
});
