import React, { useState } from 'react';

import { Input, InputProps } from '@fhs-legacy/universal-components';

const StyledInput = Input.withConfig(props => ({
  maxWidth: '84px',
  minHeight: '50px',
  borderRadius: Styles.borderRadius,
  borderWidth: '1px',
  borderColor: Styles.color.grey.five,
  textAlign: 'center',
  color: Styles.color.black,
  ...props,
}));

interface FieldProps extends InputProps {
  autoFocus?: boolean;
  focusNext?: () => void;
  focusBack?: () => void;
  initialValue?: string;
  maxLength?: number;
  name: string;
  onFieldChange?: (inputName: string, inputText: string) => void;
  setRef?: (instance: any) => void;
}

const BasicInput = ({
  autoFocus = false,
  name,
  focusNext,
  focusBack,
  initialValue = '',
  keyboardType = 'default',
  maxLength,
  onFieldChange,
  setRef,
  type = 'text',
  ...props
}: FieldProps) => {
  const [value, setValue] = useState(initialValue);

  const onInputChange = (inputText: string) => {
    if (!maxLength || inputText.length <= maxLength) {
      setValue(inputText);
      onFieldChange?.(name, inputText);
    }

    if (inputText.length === maxLength && focusNext) {
      focusNext();
    }

    if (!inputText.length && focusBack) {
      focusBack();
    }
  };

  return (
    <StyledInput
      value={value}
      onChangeText={onInputChange}
      type={type}
      keyboardType={keyboardType}
      maxLength={maxLength}
      autoFocus={autoFocus}
      accessibilityRole="text"
      ref={setRef}
      {...props}
    />
  );
};

export default BasicInput;
