import { primitive } from './primitives';

import Styles from './index';

export const designTokens = {
  'border-color-input': primitive.$blackOpacity30,
  'border-color-input-default': primitive.$blackOpacity30,
  'border-color-options': primitive.$blackOpacity10,
  'border-color-reversed': primitive.$whiteOpacity10,
  'border-radius-button': '100%',
  'border-radius-chip': '4px',
  'border-radius-input': '4px',
  'border-radius-input-icon-hover-focus': '100%',
  'border-radius-pattern': '20px',
  'border-radius-pattern-interactive': '12px',
  'border-radius-toggle-default': '0',
  'border-radius-toggle-end': '0 4px 4px 0',
  'border-radius-toggle-start': '4px 0 0 4px',
  'border-width-button-secondary': '1px',
  'border-width-input': '1px',
  'border-width-tabs': '1px',
  'box-shadow-button': `0 0 0 4px ${primitive.$blackOpacity10}`,
  'box-shadow-component': `0 0 0 4px ${primitive.$blackOpacity10}`,
  'box-shadow-component-reversed': `0 0 0 4px ${primitive.$whiteOpacity30}`,
  'box-shadow-pattern': `0 0 0 4px ${primitive.$blackOpacity10}`,
  'box-shadow-pattern-reversed': `0 0 0 4px ${primitive.$whiteOpacity30}`,
  'box-shadow-toggle': `inset 0 0 0 4px ${primitive.$blackOpacity10}`,
  'drop-shadow-bottom-nav': `0 -1px 1px ${primitive.$blackOpacity10}`,
  'drop-shadow-component': `0 4px 4px ${primitive.$blackOpacity10}`,
  'drop-shadow-top-nav': `0 1px 1px ${primitive.$blackOpacity10}`,
  'height-button-large': '48px',
  'height-button-small': '32px',
  'height-checkbox-radio': '20px',
  'height-chip': '24px',
  'height-icon': '24px',
  'height-icon-small': '16px',
  'height-input': '48px',
  'height-tabs': '56px',
  Styles,

  'background-active': primitive.$houseRedDarken10,
  'background-appbar-navbar': primitive.$white,
  'background-button-hover-reversed': primitive.$whiteOpacity10,
  'background-button-primary-default': primitive.$houseRedDarken10,
  'background-button-primary-default-reversed': primitive.$white,
  'background-button-primary-hover': primitive.$houseRed,
  'background-button-secondary-hover': primitive.$burntOrangeOpacity10,
  'background-checkbox-disabled': primitive.$blackOpacity30,
  'background-checked': primitive.$houseDark,
  'background-chip': primitive.$houseDark,
  'background-chip-rewards': primitive.$nolaPurple,
  'background-chip-rewards-locked': primitive.$houseLight,
  'background-default': primitive.$houseLight,
  'background-dialog': primitive.$white,
  'background-error': primitive.$error,
  'background-fixed-bottom': primitive.$white,
  'background-focus': primitive.$blackOpacity4,
  'background-focus-reversed': primitive.$whiteOpacity4,
  'background-footer': primitive.$houseDark,
  'background-hero-light': primitive.$white,
  'background-hero-reversed': primitive.$white,
  'background-icon-hover-focus': primitive.$blackOpacity4,
  'background-icon-hover-focus-reversed': primitive.$blackOpacity4,
  'background-inline-alert': primitive.$blackOpacity4,
  'background-input': primitive.$white,
  'background-input-disabled': primitive.$blackOpacity4,
  'background-pattern': primitive.$white,
  'background-switch-thumb': primitive.$white,
  'background-switch-track': primitive.$houseDark,
  'background-switch-track-disabled': primitive.$blackOpacity30,
  'border-color-active': primitive.$houseRedDarken10,
  'border-color-button-secondary': primitive.$houseRedDarken10,
  'border-color-button-secondary-reversed': primitive.$white,
  'border-color-checked': primitive.$houseDark,
  'border-color-default': primitive.$blackOpacity10,
  'border-color-disabled': primitive.$blackOpacity30,
  'border-color-disabled-reversed': primitive.$whiteOpacity30,
  'border-color-error': primitive.$error,
  'border-color-hover': primitive.$houseDark,
  'icon-active': primitive.$houseRedDarken10,
  'icon-button-primary': primitive.$white,
  'icon-button-primary-reversed': primitive.$houseDark,
  'icon-button-reversed': primitive.$white,
  'icon-button-secondary': primitive.$houseRedDarken10,
  'icon-button-tertiary-default': primitive.$houseDark,
  'icon-button-tertiary-hover': primitive.$houseRedDarken10,
  'icon-default': primitive.$houseDark,
  'icon-disabled': primitive.$blackOpacity30,
  'icon-disabled-reversed': primitive.$whiteOpacity30,
  'icon-form-checkbox': primitive.$white,
  'icon-form-input-action': primitive.$houseDark,
  'icon-form-input-default': primitive.$blackOpacity30,
  'icon-reversed': primitive.$houseDark,
  'padding-button-icon-end': `${primitive.$spacing0} ${primitive.$spacing2} ${primitive.$spacing0} ${primitive.$spacing4}`,
  'padding-button-icon-start': `${primitive.$spacing0} ${primitive.$spacing4} ${primitive.$spacing0} ${primitive.$spacing2}`,
  'padding-button-large': `${primitive.$spacing0} ${primitive.$spacing5}`,
  'padding-button-small': `${primitive.$spacing0} ${primitive.$spacing4}`,
  'padding-input': `${primitive.$spacing0} ${primitive.$spacing4}`,
  'text-active': primitive.$houseRedDarken10,
  'text-button-primary': primitive.$white,
  'text-button-primary-reversed': primitive.$houseDark,
  'text-button-reversed': primitive.$white,
  'text-button-secondary': primitive.$houseRedDarken10,
  'text-button-tertiary-default': primitive.$houseDark,
  'text-button-tertiary-hover': primitive.$houseRedDarken10,
  'text-default': primitive.$houseDark,
  'text-disabled': primitive.$disabledText,
  'text-disabled-reversed': primitive.$disabledTextReversed,
  'text-error': primitive.$error,
  'text-hero-light': primitive.$houseDark,
  'text-hero-reversed': primitive.$white,
  'text-link-default': primitive.$houseDark,
  'text-link-hover': primitive.$houseRedDarken10,
  'text-link-reversed': primitive.$white,
  'text-reversed': primitive.$white,
  'text-style-back-nav': 'var(--font-copyOne)',
  'text-style-bottom-nav': 'var(--font-copyTwo)',
  'text-style-weight-bottom-nav': 'normal',
  'text-style-button-large': 'var(--font-headerThree)',
  'text-style-button-small': primitive.$headerFour,
  'text-style-card-copy': 'var(--font-copyTwo)',
  'text-style-card-header': 'var(--font-headerThree)',
  'text-style-chip': 'var(--font-copyTwo)',
  'text-style-chip-rewards': primitive.$headerFour,
  'text-style-dialog-copy': 'var(--font-copyOne)',
  'text-style-dialog-header': 'var(--font-headerOne)',
  'text-style-footer-large': 'var(--font-headerThree)',
  'text-style-footer-location': primitive.$headerFour,
  'text-style-footer-small': 'var(--font-copyTwo)',
  'text-style-form-checkbox': 'var(--font-copyOne)',
  'text-style-form-checkbox-header': 'var(--font-headerThree)',
  'text-style-form-hint': primitive.$formUtility,
  'text-style-form-input': 'var(--font-copyOne)',
  'text-style-form-label-default': 'var(--font-copyOne)',
  'text-style-form-label-value': primitive.$formUtility,
  'text-style-form-legend': 'var(--font-headerThree)',
  'text-style-form-options': primitive.$formUtility,
  'text-style-hero-header': 'var(--font-hero)',
  'text-style-hero-subheader': 'var(--font-headerThree)',
  'text-style-hero-terms': 'var(--font-copyTwo)',
  'text-style-inline-alert': 'var(--font-copyTwo)',
  'text-style-item-description-copy': 'var(--font-copyTwo)',
  'text-style-item-description-header': 'var(--font-headerThree)',
  'text-style-item-description-price': primitive.$headerFour,
  'text-style-item-description-subheader': primitive.$formUtility,
  'text-style-list-item': 'var(--font-copyOne)',
  'text-style-list-item-info': 'var(--font-copyTwo)',
  'text-style-list-item-restaurant': 'var(--font-headerThree)',
  'text-style-module': 'var(--font-headerOne)',
  'text-style-segment-header': 'var(--font-headerThree)',
  'text-style-tabs': 'var(--font-copyOne)',
  'text-style-top-nav': 'var(--font-copyOne)',
  'text-style-top-nav-right': primitive.$headerFour,
  'text-tabs-default': primitive.$houseDark,
  'text-toggle-selected': primitive.$white,
  'transition-component': 'all .3s ease',
  'width-checkbox-radio': '20px',
  'width-icon': '24px',
  'width-icon-small': '16px',
  // Whitelabel-app-specific tokens to be added here, e.g.:
  // 'my-token-name': primitive.$black,
  'background-contrast-loyalty': primitive.$houseRedDarken10,
  'background-contrast-loyalty-reversed': primitive.$nolaTeal,
  'background-badge-cart-entry': primitive.$blackOpacity30,
  'background-icon-header-contrast': primitive.$blackOpacity4,
  'background-menu-customization-image': 'transparent',
  'border-default': `1px solid ${primitive.$blackOpacity10}`,
  'icon-header-contrast': primitive.$houseDark,
  'text-accent-order-number': primitive.$nolaTeal,
  'text-loyalty-highlight': primitive.$nolaPurple,
  'pickup-timed-fire-selector-button-background': primitive.$white,
  'tims-rewards-text-color': primitive.$coffeeBrown,
  'padding-list-item-group-header': `${primitive.$spacing6} ${primitive.$spacing4} ${primitive.$spacing2}`,
  'margin-top-product-wizard-layout': '0',

  // Global App Colors
  'top-nav-bar-background-color': primitive.$white,
  'bottom-nav-bar-background-color': primitive.$white,
  'main-menu-carousel-background': primitive.$white,
  'bsm-default-background-color': primitive.$houseLight,
  'bsm-in-progress-background-color': primitive.$black,
  'bsm-drop-shadow': '0 0 3px rgb(0 0 0 / 10%)',
} as const;

export type DesignTokens = typeof designTokens;
