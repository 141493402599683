import { router } from 'expo-router';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, Text } from '@fhs-legacy/universal-components';
import { ArrowButton } from 'components/arrow-button';
import { ArrowLink } from 'components/arrow-link';
import ConfirmDialog from 'components/confirm-dialog';
import { useConfigValue } from 'hooks/configs/use-config-value';
import useRoutes from 'hooks/use-routes';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { usePageManagerContext } from 'state/static-page-manager';
import { ACCOUNT_INFO_SIGNOUT_BUTTON, ACCOUNT_INFO_SIGNOUT_DIALOG_CONTAINER } from 'utils/test-ids';

const LinkContainer = Box.withConfig({
  marginTop: '$1',
});

export const TermsSection = () => {
  const enableAccountDeleteOneTrust = useFlag(LaunchDarklyFlag.ENABLE_ACCOUNT_DELETE_ONE_TRUST);
  const { loadRoutes } = usePageManagerContext();
  const enableAccountDeleteOneTrustStaticPage = useFlag(
    LaunchDarklyFlag.ENABLE_ACCOUNT_DELETE_ONE_TRUST_STATIC_PAGE
  );
  const oneTrust = useConfigValue({ key: 'oneTrust' });
  const [showSignOutDialog, setShowSignOutDialog] = useState(false);
  const deleteAccountUrl =
    oneTrust?.deleteAccountUrl ||
    'https://privacyportal-eu-cdn.onetrust.com/dsarwebform/7ae425dd-1c76-46b0-a1b4-2422a364fae3/draft/1d4481aa-204a-4cb5-a40a-82e4369d16b2.html';

  const { formatMessage } = useIntl();
  const { getLocalizedRouteForPath, doesPageExist } = useRoutes();

  const doesTermOfServicePageExist = doesPageExist('terms-of-service');
  useEffect(loadRoutes, [loadRoutes]);

  return (
    <LinkContainer>
      <ArrowLink
        to={{
          pathname: getLocalizedRouteForPath('privacy-policy'),
          params: { title: formatMessage({ id: 'privacyPolicy' }) },
        }}
      >
        {formatMessage({ id: 'privacyPolicy' })}
      </ArrowLink>
      {doesTermOfServicePageExist && (
        <ArrowLink
          to={{
            pathname: getLocalizedRouteForPath('terms-of-service'),
            params: { title: formatMessage({ id: 'termsOfService' }) },
          }}
        >
          {formatMessage({ id: 'termsOfService' })}
        </ArrowLink>
      )}

      <ArrowButton onPress={() => setShowSignOutDialog(true)} testID={ACCOUNT_INFO_SIGNOUT_BUTTON}>
        <Text>{formatMessage({ id: 'signOut' })}</Text>
      </ArrowButton>
      {/* TODO Remove one legal approves the use of `enableAccountDeletePage` */}
      {enableAccountDeleteOneTrust && (
        <ArrowLink
          isExternal
          to={
            enableAccountDeleteOneTrustStaticPage
              ? formatMessage({ id: 'routes.deleteMyAccount' })
              : deleteAccountUrl
          }
        >
          {formatMessage({ id: 'deleteAccount' })}
        </ArrowLink>
      )}

      <ConfirmDialog
        showDialog={showSignOutDialog}
        onCancel={() => setShowSignOutDialog(false)}
        onConfirm={() => {
          setShowSignOutDialog(false);
          while (router.canGoBack()) {
            router.back();
          }

          router.replace('signout');
        }}
        modalAppearanceEventMessage="Confirmation: Sign Out"
        heading={formatMessage({ id: 'signOut' })}
        body={formatMessage({ id: 'signOutConfirmSubheader' })}
        confirmLabel={formatMessage({ id: 'yes' })}
        cancelLabel={formatMessage({ id: 'no' })}
        testID={ACCOUNT_INFO_SIGNOUT_DIALOG_CONTAINER}
      />
    </LinkContainer>
  );
};
