import { Image } from 'expo-image';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { Text } from '@fhs-legacy/universal-components';
import BonusPoints from 'components/bonus-points/bonus-points';
import Link from 'components/link';
import { Calories } from 'components/price-and-calories';
import { useIsMobileBp } from 'hooks/breakpoints';
import { usePathname } from 'hooks/use-pathname';
import { useRewardsFlow } from 'hooks/use-rewards-flow';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyUserPromotions } from 'state/loyalty/hooks/use-loyalty-promotions';
import { EventName, emitEvent } from 'utils/event-hub';
import { isSectionRoute, routes } from 'utils/routing';

import { Separator } from './separator';
import {
  ItemPrice,
  PriceAndCaloriesListWrapper,
  StyledLink,
  Tile,
  TileCard,
  TileDescription,
  TileFooter,
  TileHeader,
  TileHeaderWrapper,
  TileImage,
  TileImageWrapper,
} from './styled';
import { IMenuTileProps } from './types';

const StyledListItemCalories = Text.withConfig({
  marginBottom: 0,
  marginTop: 0,
  color: Styles.color.black,
  numberOfLines: 1,
});

const StyledItemPrice = ItemPrice.withConfig({
  color: Styles.color.black,
  numberOfLines: 1,
});

const SeparatorStyle = Text.withConfig({
  color: Styles.color.black,
  marginY: 0,
  marginX: 1,
  numberOfLines: 1,
});

const StyledPriceAndCaloriesListWrapper = PriceAndCaloriesListWrapper.withConfig<{
  $transparentBg?: boolean;
}>(p => ({
  marginTop: 1,
  textAlign: p.$transparentBg ? 'center' : 'left',
  justifyContent: p.$transparentBg ? 'center' : 'flex-start',
}));

export const OfferTile = () => {
  const pathname = usePathname();
  const isNotMainMenu = isSectionRoute(pathname);
  const isMobile = useIsMobileBp();
  const { formatMessage } = useIntl();
  if (isNotMainMenu) {
    return null;
  }
  if (!isMobile) {
    return (
      <StyledLink to={routes.loyaltyOfferList}>
        <TileCard alignItems="center">
          <Image
            source={require('./images/offers-tile.png')}
            alt="offers-tile"
            contentFit="contain"
            style={{
              height: 180,
              width: '100%',
            }}
          />
          <TileFooter isMenuItem={false}>{formatMessage({ id: 'offers' })}</TileFooter>
        </TileCard>
      </StyledLink>
    );
  }

  return (
    <>
      <Link to={routes.loyaltyOfferList} dd-action-name="offers">
        <Tile>
          <TileImageWrapper>
            <Image
              source={require('./images/offers-tile.png')}
              alt="offers-tile"
              contentFit="contain"
              style={{
                height: 65,
                width: '100%',
              }}
            />
          </TileImageWrapper>
          <TileDescription>
            <TileHeaderWrapper>
              <TileHeader variant="headerThree" testID={'tile-header-offers'}>
                {formatMessage({ id: 'offers' })}
              </TileHeader>
            </TileHeaderWrapper>
          </TileDescription>
        </Tile>
      </Link>
      <Separator highlighted={false} />
    </>
  );
};

export const RewardsTile = () => {
  const pathname = usePathname();
  const isNotMainMenu = isSectionRoute(pathname);
  const isMobile = useIsMobileBp();
  const { formatMessage } = useIntl();
  const { mobileOrderRedemptionSearchParams } = useRewardsFlow();
  const href = {
    pathname: routes.loyaltyDashboard,
    params: mobileOrderRedemptionSearchParams,
  };
  if (isNotMainMenu) {
    return null;
  }
  if (!isMobile) {
    return (
      <StyledLink to={href}>
        <TileCard alignItems="center">
          <Image
            source={require('./images/rewards-tile.png')}
            alt="rewards-tile"
            contentFit="contain"
            style={{
              height: 180,
              width: '100%',
            }}
          />
          <TileFooter isMenuItem={false}>{formatMessage({ id: 'rewards' })}</TileFooter>
        </TileCard>
      </StyledLink>
    );
  }

  return (
    <>
      <Link to={href} dd-action-name="rewards">
        <Tile>
          <TileImageWrapper>
            <Image
              source={require('./images/rewards-tile.png')}
              alt="rewards-tile"
              contentFit="contain"
              style={{
                height: 65,
                width: '100%',
              }}
            />
          </TileImageWrapper>
          <TileDescription>
            <TileHeaderWrapper>
              <TileHeader variant="headerThree" testID={'tile-header-rewards'}>
                {formatMessage({ id: 'rewards' })}
              </TileHeader>
            </TileHeaderWrapper>
          </TileDescription>
        </Tile>
      </Link>
      <Separator highlighted={false} />
    </>
  );
};

const MenuTile = React.memo<IMenuTileProps>(
  ({
    showStaticMenu,
    pathname,
    image,
    imageDescription,
    name,
    type,
    price,
    calories,
    hideCalories,
    id,
    promotion,
  }) => {
    const enablePriceAndCaloriesInMenu = useFlag(
      LaunchDarklyFlag.ENABLE_PRICE_AND_CALORIES_IN_MENU
    );
    const enablePlkTransparentBackgroundMenuTile = useFlag(
      LaunchDarklyFlag.ENABLE_PLK_TRANSPARENT_BACKGROUND_MENU_TILE
    );
    const { availablePromotionsMap } = useLoyaltyUserPromotions();
    const shouldShowBonusPointsBadge =
      !!promotion?.loyaltyEngineId && !!availablePromotionsMap[promotion.loyaltyEngineId];

    const shouldRenderPriceAndCalories =
      enablePriceAndCaloriesInMenu &&
      type !== 'section' &&
      ((!showStaticMenu && price) || calories);

    const handleOnImageLoaded = useCallback(() => {
      emitEvent(EventName.MENU_TILE_IMAGE_LOADED, {
        sanityId: id,
        isOnMainMenu: pathname === routes.menu,
        attributes: [],
      });
    }, [id, pathname]);

    return (
      <Tile $transparentBg={enablePlkTransparentBackgroundMenuTile}>
        <TileImageWrapper>
          <TileImage
            image={image}
            alt={imageDescription ?? 'menu item image'}
            onImageLoaded={handleOnImageLoaded}
          />
        </TileImageWrapper>
        <TileDescription>
          <TileHeaderWrapper>
            <TileHeader
              variant="headerThree"
              testID={`tile-header-${name?.toLowerCase().replace(/\s/g, '')}`}
            >
              {name}
            </TileHeader>
            {shouldShowBonusPointsBadge && (
              <BonusPoints bonusPoints={promotion?.bonusPoints} hidePoints />
            )}
          </TileHeaderWrapper>
          {shouldRenderPriceAndCalories && (
            <StyledPriceAndCaloriesListWrapper
              $transparentBg={enablePlkTransparentBackgroundMenuTile}
            >
              {!showStaticMenu && (
                <>
                  <StyledItemPrice>{price}</StyledItemPrice>
                  {hideCalories ? null : <SeparatorStyle>•</SeparatorStyle>}
                </>
              )}
              {hideCalories ? null : (
                <Calories>
                  <StyledListItemCalories>{calories}</StyledListItemCalories>
                </Calories>
              )}
            </StyledPriceAndCaloriesListWrapper>
          )}
        </TileDescription>
      </Tile>
    );
  }
);

export default MenuTile;
