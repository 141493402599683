import React from 'react';
import { useIntl } from 'react-intl';

import { DeliveryTermsText, TermsLink } from './styled';

const DeliveryTerms: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <DeliveryTermsText>
      {formatMessage(
        { id: 'deliveryTerms' },
        {
          link: (
            <TermsLink to={formatMessage({ id: 'routes.deliveryTerms' })} isInline>
              {formatMessage({ id: 'clickHere' })}
            </TermsLink>
          ),
        }
      )}
    </DeliveryTermsText>
  );
};

export default DeliveryTerms;
