import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Pressable } from '@fhs-legacy/universal-components';
import { PaymentMethodAddNewItemOption } from 'components/payment-method-add-new-item-option';
import PaymentMethodOption from 'components/payment-method-option';
import PrepaidCardIcon from 'components/payment-method-option/prepaid-card-icon';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import noop from 'utils/noop';
import {
  ADD_NEW_CARD_BUTTON,
  CART_PAYMENT_PAGE_PAYMENT_METHODS_TOGGLE_BUTTON,
} from 'utils/test-ids';

import { DisplayToggle } from './display-toggle';
import { PaymentMethodOptions } from './payment-method-options';
import {
  PaymentMethodSelect,
  StyledAddNewCreditCardIcon,
  StyledAddPaymentMethodIcon,
  ToggleWrapper,
} from './styled';
import { CurrentSelectedMethodType, IPaymentMethodProps } from './types';
import { classifyPaymentMethods, getCurrentSelectedMethodType } from './utils';

function PaymentMethod({
  methods,
  selectedMethodId,
  handleSelect,
  isAddCardOpen = false,
  openAddCardOnPress,
  isAddGiftCardMethodSelected = false,
  setIsAddGiftCardMethodSelected = noop,
}: IPaymentMethodProps) {
  const { formatMessage } = useIntl();
  const enableGiftCard = useFlag(LaunchDarklyFlag.ENABLE_GIFT_CARD);

  const [collapsed, setCollapsed] = useState(true);

  const { selectedMethod, unSelectedMethods, prepaidPaymentMethod } = classifyPaymentMethods(
    methods,
    selectedMethodId
  );

  const showAddNewGiftCardOption =
    !isAddGiftCardMethodSelected && enableGiftCard && !prepaidPaymentMethod;
  const showAddNewCardOption = !isAddCardOpen;

  const isCurrentSelectedMethodOptionClickable =
    showAddNewGiftCardOption || showAddNewCardOption || !!unSelectedMethods.length;

  function toggleCollapsed() {
    setCollapsed(currentCollapsed => !currentCollapsed);
  }
  function getSelectedMethodClickHandler() {
    return isCurrentSelectedMethodOptionClickable ? toggleCollapsed : undefined;
  }

  const SelectedPaymentMethodOptionItem = selectedMethod ? (
    <PaymentMethodOption
      method={selectedMethod}
      onPress={getSelectedMethodClickHandler()}
      disableHover={collapsed}
      disableMethod={!!selectedMethod.prepaid && !enableGiftCard}
      hideBorder
      fromCheckout
    />
  ) : null;
  const SelectedAddNewPaymentMethodOptionItem = (
    <PaymentMethodAddNewItemOption
      testID="add-new-payment-method"
      text={formatMessage({ id: 'addNewPaymentMethod' })}
      Icon={<StyledAddPaymentMethodIcon />}
      onPress={getSelectedMethodClickHandler()}
      selected
    />
  );
  const SelectedAddNewCardOptionItem = (
    <PaymentMethodAddNewItemOption
      testID={ADD_NEW_CARD_BUTTON}
      text={formatMessage({ id: 'addNewCard' })}
      Icon={<StyledAddNewCreditCardIcon />}
      onPress={getSelectedMethodClickHandler()}
      selected
    />
  );
  const SelectedAddNewGiftCardOptionItem = (
    <PaymentMethodAddNewItemOption
      testID="add-gift-card"
      text={formatMessage({ id: 'addPrepaidCard' })}
      Icon={<PrepaidCardIcon />}
      onPress={getSelectedMethodClickHandler()}
      selected
    />
  );
  const currentSelectedMethodType = getCurrentSelectedMethodType({
    isAddGiftCardMethodSelected,
    isAddNewCardMethodSelected: isAddCardOpen,
    selectedMethodExist: !!selectedMethod,
  });
  const CurrentSelectedMethodMap = {
    [CurrentSelectedMethodType.SELECTED_PAYMENT_METHOD_OPTION]: SelectedPaymentMethodOptionItem,
    [CurrentSelectedMethodType.ADD_NEW_PAYMENT_METHOD]: SelectedAddNewPaymentMethodOptionItem,
    [CurrentSelectedMethodType.ADD_NEW_CARD]: SelectedAddNewCardOptionItem,
    [CurrentSelectedMethodType.ADD_NEW_GIFT_CARD]: SelectedAddNewGiftCardOptionItem,
  };
  const CurrentSelectedMethod = CurrentSelectedMethodMap[currentSelectedMethodType];

  return (
    <>
      <PaymentMethodSelect testID="payment-methods">
        <Pressable
          onPress={toggleCollapsed}
          testID={CART_PAYMENT_PAGE_PAYMENT_METHODS_TOGGLE_BUTTON}
        >
          {CurrentSelectedMethod}
          {isCurrentSelectedMethodOptionClickable && (
            <ToggleWrapper>
              <DisplayToggle collapsed={collapsed} onPress={toggleCollapsed} />
            </ToggleWrapper>
          )}
        </Pressable>

        <PaymentMethodOptions
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          unSelectedMethods={unSelectedMethods}
          handleSelect={handleSelect}
          setIsAddNewCardOptionSelected={openAddCardOnPress}
          setIsAddNewGiftCardOptionSelected={setIsAddGiftCardMethodSelected}
          showAddNewGiftCardOption={showAddNewGiftCardOption}
          showAddNewCardOption={showAddNewCardOption}
        />
      </PaymentMethodSelect>
    </>
  );
}

export default PaymentMethod;
