import { Link } from 'expo-router';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import { tokens } from '../../tokens';
import { Text } from '../text';

import { TabbedHeaderLinkProps } from './types';

export function TabbedHeaderLink(props: TabbedHeaderLinkProps) {
  const { item, isActive, onSelect } = props;
  return (
    <View style={[styles.container, isActive && styles.isActiveLink]}>
      <Link
        testID={`tabbed-menu-${item.slug}`}
        onPress={e => {
          e.preventDefault();
          onSelect(item);
        }}
        href={item.to}
        style={styles.link}
      >
        <Text style={[styles.text, isActive && styles.isActiveText]}>{item.displayName}</Text>
      </Link>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // set a border-bottom even when not selected to avoid
    // a shift in the layout when the link is selected
    borderBottomWidth: 4,
    borderColor: tokens.colors.$transparent,
  },
  link: {
    paddingTop: 8,
    paddingBottom: 12,
    paddingHorizontal: 16,
  },
  isActiveLink: {
    borderColor: tokens.colors.$houseRedDarken,
  },
  text: {
    fontSize: 16,
    fontWeight: '700',
  },
  isActiveText: {
    color: tokens.colors.$houseRedDarken,
  },
});
