import { router, useLocalSearchParams } from 'expo-router';
import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import withAddIncentiveToCart from 'components/with-add-incentive-to-cart';
import { RedemptionMethod } from 'components/with-add-incentive-to-cart/types';
import { useActivateAlohaRewardMutation } from 'generated/graphql-gateway';
import { useAreLoyaltyOffersInCooldown } from 'hooks/use-are-loyalty-offers-in-cooldown';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { removeAppliedRewardsInStorage } from 'state/global-state/models/loyalty/rewards/rewards.utils';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { isLoyaltyOffer, isReward, isValidOfferIncentive } from 'state/loyalty/types';
import { findRewardInAppliedRewards } from 'state/loyalty/utils';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

import { SHOULD_SELECT_STORE } from '../constants';
import { parseIncentiveData } from '../incentive-card/parse-incentive-data';
import { InvalidServiceModeModal } from '../invalid-service-mode-modal';
import { useLoyaltyIncentivesAvailability } from '../use-loyalty-incentives-availability';

import ConfirmRedemptionDialog from './confirm-redemption-dialog';
import { ONLY_SHOW_REDEEM_REWARD_IN_RESTAURANT_CTA } from './constants';
import ErrorInStoreRedemptionDialog from './error-in-store-redemption-dialog';
import { IncentiveDetailsView } from './incentive-details.view';
import { IIncentiveDetailsProps } from './types';

/**
 *
 * IncentiveDetails displays an expanded view of a incentive when selected from the incentive list
 *
 */
const IncentiveDetails: FC<React.PropsWithChildren<IIncentiveDetailsProps>> = ({
  selectedIncentive,
  engineIncentivesMap,
  addIncentiveToCart,
}) => {
  const incentiveLoyaltyEngineId = selectedIncentive?.loyaltyEngineId || '';
  const selectedIncentiveId = selectedIncentive?._id || '';
  const engineReward =
    engineIncentivesMap[incentiveLoyaltyEngineId] || engineIncentivesMap[selectedIncentiveId];

  const {
    pointCost,
    locked,
    redemptionPercentage,
    remainingPointsNeededForRedemption,
    rewardBenefitId,
    limitPerOrder,
  } = engineReward || {};

  const { loyaltyUser, loyaltyUserId } = useLoyaltyUser();
  const [activateAlohaRewardMutation, { loading: activateAlohaRewardLoading }] =
    useActivateAlohaRewardMutation();
  const { logRBIEvent } = useCRMEventsContext();
  const params = useLocalSearchParams();
  const { removeFromCart } = useOrderContext();
  const {
    inRestaurantLoyaltyEnabledAtRestaurant,
    clearInRestaurantRedemptionAllRewards,
    enableRewardRedemption,
  } = useInRestaurantRedemptionContext();
  const rewardData = parseIncentiveData(selectedIncentive);
  const { formatMessage } = useIntl();
  const [checkingAvailability, setCheckingAvailability] = useState(false);
  const [showRemoveRewardButton, setShowRemoveRewardButton] = useState(false);
  const [openConfirmRedemptionDialog, setOpenConfirmRedemptionDialog] = useState(false);
  const [openErrorInStoreRedemptionDialog, setOpenErrorInStoreRedemptionDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const enableMobileOffer = useFlag(LaunchDarklyFlag.ENABLE_OFFERS_MOBILE);
  const enableSwitchServiceModeModal = useFlag(LaunchDarklyFlag.ENABLE_SWITCH_SERVICE_MODE_MODAL);
  const enableLoyaltyAlohaIntegration = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_ALOHA_INTEGRATION);
  const { incentiveId, incentiveType } =
    useMemo(() => {
      const incentive = isReward(selectedIncentive)
        ? selectedIncentive.incentives?.find(benefit => benefit?._id === rewardBenefitId)
        : selectedIncentive.incentives?.[0];
      return {
        incentiveId: isValidOfferIncentive(incentive) ? incentive?._id : '',
        incentiveType: incentive?._type,
      };
    }, [rewardBenefitId, selectedIncentive]) || {};
  const dispatch = useAppDispatch();
  const appliedLoyaltyRewardsArray = useAppSelector(
    selectors.loyalty.selectAppliedLoyaltyRewardsArray
  );
  const offersFeedbackMap = useAppSelector(selectors.loyalty.selectOfferFeedbackMap);
  const loyaltyOffersInCooldown = useAreLoyaltyOffersInCooldown();

  const { checkIncentiveAvailability, incentiveUnavailableMessage } =
    useLoyaltyIncentivesAvailability(selectedIncentive, pointCost, locked);
  const exceedsBalanceError = formatMessage({ id: 'exceedsRewardsBalance' });
  const { noStoreSelected } = useStoreContext();

  useEffect(() => {
    if (incentiveUnavailableMessage) {
      setErrorMessage(incentiveUnavailableMessage);
      setShowRemoveRewardButton(incentiveUnavailableMessage === exceedsBalanceError);
      return;
    }
    // Hides the "Remove from cart" button since there is no incentive unavailable message
    setShowRemoveRewardButton(false);

    if (noStoreSelected) {
      setErrorMessage('');
      return;
    }

    // Check incentive item availability in selected store
    (async () => {
      setErrorMessage('');
      setCheckingAvailability(true);
      try {
        const isAvailable = await checkIncentiveAvailability(`${incentiveType}-${incentiveId}`);
        const storeError = isAvailable ? '' : formatMessage({ id: 'nonAvailableItemInStore' });
        setErrorMessage(storeError);
        setCheckingAvailability(false);
      } catch {
        setCheckingAvailability(false);
      }
    })();
  }, [
    incentiveId,
    incentiveType,
    checkIncentiveAvailability,
    exceedsBalanceError,
    formatMessage,
    noStoreSelected,
    incentiveUnavailableMessage,
  ]);

  const incentiveEvaluationResults = offersFeedbackMap[incentiveLoyaltyEngineId];
  const handleRemoveRewardsPress = () => {
    if (appliedLoyaltyRewardsArray?.length) {
      appliedLoyaltyRewardsArray.forEach(appliedReward => {
        removeFromCart(appliedReward);
        dispatch(
          actions.loyalty.removeAppliedReward({
            cartId: appliedReward.cartId,
            rewardBenefitId: appliedReward.rewardId,
          })
        );
      });
      clearInRestaurantRedemptionAllRewards();
      removeAppliedRewardsInStorage();

      setShowRemoveRewardButton(false);
    }
  };

  const onlyShowRedeemRewardInRestaurantCTA =
    String(params[ONLY_SHOW_REDEEM_REWARD_IN_RESTAURANT_CTA]) === 'true';

  const canAddToInRestaurantOrder =
    (!SHOULD_SELECT_STORE || inRestaurantLoyaltyEnabledAtRestaurant) &&
    ((isLoyaltyOffer(selectedIncentive) && !selectedIncentive.mobileOrderOnly) ||
      (isReward(selectedIncentive) &&
        enableRewardRedemption &&
        !selectedIncentive.mobileOrderOnly));

  const canAddToMobileOrder =
    !onlyShowRedeemRewardInRestaurantCTA &&
    ((isLoyaltyOffer(selectedIncentive) && enableMobileOffer) || isReward(selectedIncentive));

  const logOfferAndRewardAddedToOrderEvent = (redemptionModeString: 'Online' | 'In-Store') => {
    const eventName = isLoyaltyOffer(selectedIncentive)
      ? CustomEventNames.OFFER_ADDED_TO_ORDER
      : CustomEventNames.REWARD_ADDED_TO_ORDER;
    let incentiveName = '';

    if (isLoyaltyOffer(selectedIncentive)) {
      incentiveName = selectedIncentive.name?.localeRaw[0]?.children[0]?.text;
    } else {
      if (selectedIncentive.name?.locale) {
        incentiveName = selectedIncentive.name.locale ?? '';
      }
    }

    logRBIEvent({
      name: eventName,
      type: EventTypes.Other,
      attributes: {
        engineId: selectedIncentive.loyaltyEngineId ?? '',
        sanityId: selectedIncentive._id,
        name: incentiveName,
        redemptionMode: redemptionModeString,
      },
    });
  };

  const handleIncentive = () => {
    if (enableLoyaltyAlohaIntegration) {
      if (loyaltyUser?.id) {
        activateAlohaRewardMutation({
          variables: {
            loyaltyId: loyaltyUserId,
            rewardSanityId: selectedIncentive._id,
          },
          onCompleted: ({ activateAlohaReward: { userPointBalance } }) => {
            dispatch(
              actions.loyalty.setUser({
                ...loyaltyUser,
                points: userPointBalance,
              })
            );
            router.replace(routes.redeem);
          },
          onError: () => {
            setOpenErrorInStoreRedemptionDialog(true);
          },
        });
      }
    } else {
      addIncentiveToCart({
        incentive: selectedIncentive,
        method: RedemptionMethod.IN_RESTAURANT,
      });
    }
    logOfferAndRewardAddedToOrderEvent('In-Store');
  };

  const { limitPerOrderMet } = findRewardInAppliedRewards(
    appliedLoyaltyRewardsArray || [],
    selectedIncentive.loyaltyEngineId || '',
    limitPerOrder
  );

  return (
    <>
      {enableSwitchServiceModeModal &&
        !checkingAvailability &&
        Boolean(incentiveEvaluationResults?.length) && (
          <InvalidServiceModeModal incentiveEvaluationResults={incentiveEvaluationResults} />
        )}
      {openErrorInStoreRedemptionDialog && (
        <ErrorInStoreRedemptionDialog
          showDialog={openErrorInStoreRedemptionDialog}
          dismiss={() => setOpenErrorInStoreRedemptionDialog(false)}
        />
      )}
      {openConfirmRedemptionDialog && (
        <ConfirmRedemptionDialog
          showDialog={openConfirmRedemptionDialog}
          onConfirm={() => {
            setOpenConfirmRedemptionDialog(false);
            handleIncentive();
          }}
          dismiss={() => setOpenConfirmRedemptionDialog(false)}
        />
      )}
      <IncentiveDetailsView
        {...rewardData}
        pointCost={pointCost}
        locked={locked}
        redemptionPercentage={redemptionPercentage}
        handleRedeemRewardPress={() => {
          addIncentiveToCart({ incentive: selectedIncentive, method: RedemptionMethod.MOBILE });
          logOfferAndRewardAddedToOrderEvent('Online');
        }}
        handleInRestaurantRedemptionPress={() => {
          if (enableLoyaltyAlohaIntegration) {
            setOpenConfirmRedemptionDialog(true);
          } else {
            handleIncentive();
          }
        }}
        remainingPointsNeededForRedemption={remainingPointsNeededForRedemption}
        errorMessage={errorMessage}
        key={rewardData.id}
        checkingAvailability={checkingAvailability || activateAlohaRewardLoading}
        canAddToInRestaurantOrder={canAddToInRestaurantOrder}
        canAddToMobileOrder={canAddToMobileOrder}
        showRemoveRewardButton={showRemoveRewardButton}
        handleRemoveRewardsPress={handleRemoveRewardsPress}
        incentiveEvaluationResults={incentiveEvaluationResults}
        offersInCooldown={loyaltyOffersInCooldown}
        limitPerOrderMet={limitPerOrderMet}
      />
    </>
  );
};

export default memo(withAddIncentiveToCart(IncentiveDetails));
