import { Link } from 'expo-router';
import { Image, Pressable, StyleSheet, View } from 'react-native';

import { useMutation } from '@fhs/client';
import { Button, IconInfoFill, Text, tokens } from '@fhs/ui';

import { setDonationAmount } from '../../api';

const background = require('./background.png');
const logo = require('./logo.png');

type Props = {
  donationAmount: number;
};

const donationValues = [
  { label: 'None', value: 0 },
  { label: '$0.50', value: 50 },
  { label: '$1.00', value: 100 },
  { label: '$5.00', value: 500 },
];

export function CartDonationSwitch(props: Props) {
  const { mutate: updateDonationAmount, variables, data } = useMutation(setDonationAmount);
  const amount = _getFirstValue(variables?.amount, data?.donationAmount, props.donationAmount);

  return (
    <View style={styles.container}>
      <Image source={background} style={styles.backgroundImage} />
      <View style={styles.backgroundImageFade} />
      <View style={styles.top}>
        <Image source={logo} style={styles.logo} />
        <Text.Heading type="two" style={styles.heading}>
          DONATE TO SAVE LIVES
        </Text.Heading>
        <Link href="#" asChild>
          <Pressable>
            <IconInfoFill size={20} color={tokens.colors.$white} />
          </Pressable>
        </Link>
      </View>

      <SegmentedControl
        options={donationValues}
        value={amount}
        onSelected={newAmount => {
          updateDonationAmount({ amount: newAmount });
        }}
      />
    </View>
  );
}

type Option<V> = { label: string; value: V };
function SegmentedControl<V extends any>(props: {
  options: Option<V>[];
  value: V;
  onSelected(value: V): void;
}) {
  return (
    <View style={styles.segmentedContainer}>
      {props.options.map(v => (
        <Button
          key={v.value + v.label}
          style={[
            styles.segmentedButton,
            v.value !== props.value && styles.unselectedSegmentButton,
          ]}
          type="solid"
          onPress={() => props.onSelected(v.value)}
        >
          <Button.Text style={v.value !== props.value && styles.unselectedSegmentedButtonText}>
            {v.label}
          </Button.Text>
        </Button>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  learnMore: {
    color: tokens.colors.$white,
    textDecorationLine: 'underline',
  },
  heading: {
    color: tokens.colors.$white,
    padding: 8,
    flex: 1,
  },
  top: {
    height: 40,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    width: 40,
    height: 40,
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  backgroundImageFade: {
    top: 0,
    left: 0,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.6)',
  },
  container: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    gap: 8,
    height: 106,
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },

  // Segmented Controls
  segmentedContainer: {
    width: '100%',
    height: 34,
    gap: 8,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },

  segmentedButton: {
    height: 34,
    minHeight: 34,
    borderWidth: 0,
    flex: 1,
  },
  unselectedSegmentButton: {
    backgroundColor: tokens.colors.$white,
  },
  unselectedSegmentedButtonText: {
    color: tokens.colors.$black,
  },
});

function _getFirstValue(v1: any, v2: any, v3: any): number {
  if (typeof v1 === 'number') {
    return v1;
  }
  if (typeof v2 === 'number') {
    return v2;
  }
  if (typeof v3 === 'number') {
    return v3;
  }
  return 0;
}
