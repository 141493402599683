import { router } from 'expo-router';
import { useCallback } from 'react';

import { IStore } from '@rbi-ctg/store';
import { IRestaurantNode } from 'generated/rbi-graphql';
import {
  getRewardIdFromSearch,
  makeLoyaltyRewardDetailsPath,
} from 'pages/loyalty/loyalty-incentives-components/incentive-details/utils';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { ServiceMode } from 'state/order';
import { StoreProxy } from 'state/store';
import { routes } from 'utils/routing';

import { useStaticMenuContext } from './static-menu';

type IRedirectCallbackArgs = {
  restaurant: StoreProxy | IRestaurantNode;
  serviceMode?: ServiceMode | null;
};

export const useStaticMenuRedirect = () => {
  const { isInRestaurantRedemptionEnabledAtStore } = useInRestaurantRedemptionContext();

  const {
    resetState,
    selectedStaticMenuItemId,
    selectedStaticMenuItemSearch: search,
  } = useStaticMenuContext();

  return useCallback(
    async ({ restaurant, serviceMode }: IRedirectCallbackArgs) => {
      const rewardId = getRewardIdFromSearch(search);
      const shouldNavigateToRewardDetails =
        rewardId &&
        (await isInRestaurantRedemptionEnabledAtStore(restaurant as IStore, serviceMode));

      if (rewardId && shouldNavigateToRewardDetails) {
        router.replace(makeLoyaltyRewardDetailsPath({ rewardId, search }));
      } else {
        router.replace(`${routes.menu}/${selectedStaticMenuItemId}${search}`);
      }
      resetState();
    },
    [isInRestaurantRedemptionEnabledAtStore, resetState, search, selectedStaticMenuItemId]
  );
};
