import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconProfile = (props: IconProps) => {
  const { size = 50, color, ...otherProps } = props;

  return (
    <Svg width={size} height={size} color={color} fill="none" {...otherProps}>
      <Path
        fill="#000"
        fillOpacity={0.1}
        d="M25 23.974c-3.208 0-5.955-1.142-8.24-3.427-2.284-2.285-3.427-5.031-3.427-8.24 0-3.208 1.143-5.954 3.428-8.239C19.045 1.783 21.79.641 25 .641c3.208 0 5.955 1.142 8.24 3.427 2.284 2.285 3.427 5.031 3.427 8.24 0 3.208-1.143 5.954-3.428 8.24-2.284 2.284-5.03 3.426-8.239 3.426ZM0 49.36v-7.41c0-1.633.443-3.145 1.33-4.536a8.877 8.877 0 0 1 3.555-3.208c3.294-1.615 6.618-2.826 9.97-3.634A43.163 43.163 0 0 1 25 29.359c3.41 0 6.791.404 10.144 1.212 3.353.808 6.677 2.019 9.971 3.634a8.878 8.878 0 0 1 3.555 3.208A8.276 8.276 0 0 1 50 41.948v7.411H0Z"
      />
    </Svg>
  );
};
