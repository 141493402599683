import { router } from 'expo-router';
import { useIntl } from 'react-intl';
import { SafeAreaView, StyleSheet } from 'react-native';

import { Button, Text, XStack, tokens } from '@fhs/ui';
import {
  ClickEventComponentNames,
  CustomEventNames,
  EventTypes,
  useCRMEventsContext,
} from '@fhs-legacy/frontend/src/state/crm-events';
import { useOrderContext } from '@fhs-legacy/frontend/src/state/order';
import { useStoreContext } from '@fhs-legacy/frontend/src/state/store';
import {
  isFavoritesRoute,
  isRecentItemsRoute,
  isSectionRoute,
  routes,
} from '@fhs-legacy/frontend/src/utils/routing';
import { SelectedStoreAndServiceModeButton } from 'components/selected-store-and-service-mode-button';
import { usePathname } from 'hooks/use-pathname';

const menuBottomCtaStyles = StyleSheet.create({
  safeAreaView: {
    backgroundColor: tokens.colors.$white,
  },
  cartContainer: {
    backgroundColor: tokens.colors.$white,
    padding: 20,
    flexDirection: 'row',
  },
  button: {
    width: '100%',
  },
  buttonDisabled: {
    backgroundColor: tokens.colors.$white,
    borderColor: tokens.colors.$black10,
    borderWidth: 2,
  },
  buttonLabel: {
    fontSize: 18,
    color: tokens.colors.$white,
  },
  buttonLabelDisabled: {
    fontSize: 18,
    color: tokens.colors.$blackOpacity75,
  },
});

export function MenuBottomCTA() {
  const { logRBIEvent } = useCRMEventsContext();
  const { store } = useStoreContext();
  const { formatMessage } = useIntl();
  const isStoreSelected = Boolean(store._id);
  const pathname = usePathname();

  const { numCartPreviewEntries } = useOrderContext();

  const handlePress = () => {
    logRBIEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.BUTTON,
        text: 'View Cart',
      },
    });

    const routeToRedirect = isStoreSelected ? routes.cart : routes.serviceMode;

    router.navigate(routeToRedirect);
  };

  const isCartEmpty = numCartPreviewEntries === 0;
  const labelStyle = isCartEmpty
    ? menuBottomCtaStyles.buttonLabelDisabled
    : menuBottomCtaStyles.buttonLabel;

  const recentItemsRoute = isRecentItemsRoute(pathname);
  const favoritesRoute = isFavoritesRoute(pathname);
  const sectionRoute = isSectionRoute(pathname);
  const isMenuRoute = pathname === '/menu' || pathname === '/menu/';

  if (!recentItemsRoute && !favoritesRoute && !sectionRoute && !isMenuRoute) {
    return null;
  }
  return (
    <SafeAreaView style={menuBottomCtaStyles.safeAreaView}>
      <SelectedStoreAndServiceModeButton />
      <XStack style={menuBottomCtaStyles.cartContainer}>
        <Button
          type="solid"
          testID="view-cart-button"
          disabled={isCartEmpty}
          disabledStyle={menuBottomCtaStyles.buttonDisabled}
          style={menuBottomCtaStyles.button}
          onPress={handlePress}
        >
          <Text style={labelStyle}>
            {formatMessage({ id: 'menuCheckoutButtonCta' }, { count: numCartPreviewEntries })}
          </Text>
        </Button>
      </XStack>
    </SafeAreaView>
  );
}
