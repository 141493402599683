import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { IItem, ISanityImage } from '@rbi-ctg/menu';
import { useFeatureDonation } from 'hooks/use-feature-donation';
import { useCartContext } from 'state/cart';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuOptionsContext } from 'state/menu-options';
import { useOrderContext } from 'state/order';
import useDonation from 'state/order/hooks/use-donation';
import { useUIContext } from 'state/ui';

import DonationAtCheckout from '../donation-at-checkout/donation-at-checkout';
import { IDonationAtCheckoutWithContentProps } from '../donation-at-checkout/types';
import { DonationsModal } from '../donations-modal';

const DonationContainer = () => {
  const { featureDonation } = useFeatureDonation();
  const { pricingFunction } = useMenuOptionsContext();
  const { formatCurrencyForLocale } = useUIContext();

  const { isDonationZeroAmountSelected, setIsDonationZeroAmountSelected } = useOrderContext();
  const { cartEntries } = useCartContext();
  const enableDefaultDonationsToNonZeroAmount = useFlag(
    LaunchDarklyFlag.ENABLE_DEFAULT_DONATIONS_TO_NON_ZERO_AMOUNT
  );

  const donationItems = useMemo(
    () => featureDonation?.donationItemList?.map(item => ({ ...item } as IItem)) || [],
    [featureDonation?.donationItemList]
  );

  const { handleAddDonationCartEntry, handleRemoveDonationCartEntry, selectedDonationCartEntry } =
    useDonation({
      donationItems,
    });
  const [showDonationsInfoModal, setShowDonationsInfoModal] = useState(false);

  const ZERO_AMOUNT_DONATION = '0';
  const allDonationOptions: IDonationAtCheckoutWithContentProps['donationOptions'] = useMemo(
    () => [
      {
        label: formatCurrencyForLocale(Number(ZERO_AMOUNT_DONATION)),
        value: ZERO_AMOUNT_DONATION,
      },
      ...(donationItems.map(item => ({
        label: formatCurrencyForLocale(pricingFunction(item)),
        value: item._id,
        isDisabled: false,
      })) as IDonationAtCheckoutWithContentProps['donationOptions']),
    ],
    [donationItems, formatCurrencyForLocale, pricingFunction]
  );

  const handleDonationToggleChange = useCallback(
    (newSelectedDonationId: string) => {
      // user removed donation, remove previous donation cart entry
      if (ZERO_AMOUNT_DONATION === newSelectedDonationId && selectedDonationCartEntry) {
        setIsDonationZeroAmountSelected(true);
        handleRemoveDonationCartEntry({ cartId: selectedDonationCartEntry.cartId });
        return;
      }
      const optionItem = donationItems.find(item => item?._id === newSelectedDonationId);
      if (!optionItem) {
        return;
      }
      setIsDonationZeroAmountSelected(false);
      handleAddDonationCartEntry(optionItem);
    },
    [
      donationItems,
      handleAddDonationCartEntry,
      handleRemoveDonationCartEntry,
      selectedDonationCartEntry,
      setIsDonationZeroAmountSelected,
    ]
  );

  const handleInfoOnPress = () => {
    setShowDonationsInfoModal(true);
  };

  const handleInfoOnDismiss = () => {
    setShowDonationsInfoModal(false);
  };

  // We need this to be a useLayoutEffect to avoid race conditions when updating the Donation toggle
  useLayoutEffect(() => {
    // Defaults to the second option only if zero amount was not previously selected in the cart context
    if (
      enableDefaultDonationsToNonZeroAmount &&
      selectedDonationCartEntry === null &&
      !isDonationZeroAmountSelected &&
      !!cartEntries.length
    ) {
      handleDonationToggleChange(allDonationOptions[1]?.value || ZERO_AMOUNT_DONATION);
    }
  }, [
    allDonationOptions,
    cartEntries.length,
    enableDefaultDonationsToNonZeroAmount,
    handleDonationToggleChange,
    isDonationZeroAmountSelected,
    selectedDonationCartEntry,
    setIsDonationZeroAmountSelected,
  ]);

  if (!donationItems.length) {
    return null;
  }

  return (
    <>
      {showDonationsInfoModal && (
        <DonationsModal
          isOpen={showDonationsInfoModal}
          onCloseCallback={handleInfoOnDismiss}
          title={featureDonation?.dialogInformation?.header?.locale ?? ''}
          modalAppearanceEventMessage="Donations Modal"
          buttonText={featureDonation?.dialogInformation?.buttonAction?.actionText?.locale ?? ''}
        >
          {featureDonation?.dialogInformation?.subtitle?.locale ?? ''}
        </DonationsModal>
      )}
      <DonationAtCheckout
        header={featureDonation?.header?.locale ?? ''}
        subtitle={featureDonation?.subtitle?.locale ?? ''}
        donationOptions={allDonationOptions}
        selectedDonationId={selectedDonationCartEntry?._id ?? ZERO_AMOUNT_DONATION}
        onChangeValue={handleDonationToggleChange}
        onInfoPressed={handleInfoOnPress}
        logo={featureDonation?.logoImage?.locale as ISanityImage}
      />
    </>
  );
};

export default DonationContainer;
