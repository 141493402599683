import { ICartEntry } from '@rbi-ctg/menu';
import { IRewardFragment } from 'generated/graphql-gateway';

/** Redeem reward button actions */
export enum RewardActions {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}
export interface IRedeemRewardProps {
  /** Is the reward currently applied to cart item */
  isRewardApplied: boolean;
  /** Cart entry item */
  item: ICartEntry;
  reward: IRewardFragment;
}

export interface RedeemRewardActionButtonProps {
  /** Type of reward action */
  action: RewardActions;
  /** Whether the button should be disabled or not */
  disabled?: boolean;
  /** Click handler for redeeming / removing reward */
  handleRewardClick(action: RewardActions): () => void;
  /** Point value of current reward */
  points: number;
  /** If a locked state be displayed  */
  locked?: boolean;
  /** Loading flag  */
  loading?: boolean;
}

export interface IRedeemRewardViewProps {
  displayApplyButton?: boolean;
  /** Click handler for redeeming / removing reward */
  handleRewardClick(action: RewardActions): () => void;
  /** Is reward applied */
  isRewardApplied: boolean;
  /** Point value of current reward */
  points: number;
  /** Title of reward */
  rewardTitle: string;
  /** Controls whether reward can be applied */
  disableApplyButton: boolean;
  /** Current number of points being used for rewards */
  pointsApplied: number;
  /** Do not show remove buttons if incentive is not a regular menu item */
  incentiveNotInMenu?: boolean;
  /** Reward redemption eligibility loading */
  redeemRewardLoading?: boolean;
}
