import { offersSlice } from './offers/offers.slice';
import { rewardsSlice } from './rewards/rewards.slice';
import { userSlice } from './user/user.slice';

type LoyaltyActions = typeof offersSlice.actions &
  typeof userSlice.actions &
  typeof rewardsSlice.actions;

// Exporting all actions from slices in one file mimics exported variables from a context and encourages more explicit naming
// As an added benefit, we are able to see which actions come from which slice in a central location
const { setUser, setLoyaltyLoading, setUserTransactions } = userSlice.actions;

const {
  applyOffer,
  removeAppliedOfferByCartEntry,
  resetAppliedOffers,
  resetOfferFeedbackMap,
  resetOffersEligibleItems,
  resetSurpriseAvailability,
  resetUpsizeAvailability,
  setAppliedOffers,
  unshiftCmsOffers,
  setCmsOffers,
  setCmsOffersV2,
  setIncentivesIds,
  setIsDiscountNotApplied,
  setOffers,
  setOffersFeedbackMap,
  setOffersEligibleItems,
  setOffersLoading,
  setOfferRedemptionAvailableAfter,
  setPersonalizedOffers,
  setSelectedOffer,
  setSurpriseOfferIfAvailable,
  setUpsizeAvailable,
  setUserOffers,
  setShouldRefetchOffers,
  unshiftPersonalizedOffer,
  removeAppliedOffer,
  setResetAppliedOffersAndRefetch,
  selectedOfferV2,
} = offersSlice.actions;

const {
  applyReward,
  createAvailableLoyaltyRewardsMap,
  rehydrateAppliedReward,
  removeAppliedReward,
  removeAppliedRewards,
  resetLoyaltyRewardsState,
  setAvailableLoyaltyRewardsMap,
  setIsPricingRewardApplication,
  setShouldRefetchRewards,
  setStagedCartPoints,
  unApplyReward,
  setAppliedLoyaltyRewardsFeedbackMap,
  resetAppliedLoyaltyRewardsFeedbackMap,
  setIsEvaluatingAppliedLoyaltyRewards,
  setMaxDailyRewardRedemptionLimit,
} = rewardsSlice.actions;

export const loyaltyActions: LoyaltyActions = {
  // Offers slice actions
  applyOffer,
  removeAppliedOfferByCartEntry,
  resetAppliedOffers,
  resetOfferFeedbackMap,
  resetOffersEligibleItems,
  resetSurpriseAvailability,
  resetUpsizeAvailability,
  setAppliedOffers,
  unshiftCmsOffers,
  setCmsOffers,
  setCmsOffersV2,
  setIncentivesIds,
  setIsDiscountNotApplied,
  setOffers,
  setOffersFeedbackMap,
  setOffersEligibleItems,
  setOffersLoading,
  setOfferRedemptionAvailableAfter,
  setPersonalizedOffers,
  setSelectedOffer,
  setSurpriseOfferIfAvailable,
  setUpsizeAvailable,
  setUserOffers,
  setShouldRefetchOffers,
  unshiftPersonalizedOffer,
  removeAppliedOffer,
  setResetAppliedOffersAndRefetch,
  selectedOfferV2,
  // Rewards slice actions
  applyReward,
  createAvailableLoyaltyRewardsMap,
  rehydrateAppliedReward,
  removeAppliedReward,
  removeAppliedRewards,
  resetLoyaltyRewardsState,
  setAvailableLoyaltyRewardsMap,
  setIsPricingRewardApplication,
  setShouldRefetchRewards,
  setStagedCartPoints,
  unApplyReward,
  setAppliedLoyaltyRewardsFeedbackMap,
  resetAppliedLoyaltyRewardsFeedbackMap,
  setIsEvaluatingAppliedLoyaltyRewards,
  setMaxDailyRewardRedemptionLimit,
  // User slice actions
  setUser,
  setLoyaltyLoading,
  setUserTransactions,
};
