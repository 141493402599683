import { useMutation } from '@apollo/client';
import { noop } from 'lodash';
import { useCallback, useRef, useState } from 'react';

import { FireOrderIn, IServerOrder } from '@rbi-ctg/menu';
import {
  CommitOrderDocument,
  GetOrderDocument,
  GetUserOrdersDocument,
} from 'generated/rbi-graphql';
import { useOrderStatus } from 'hooks/order-status';
import { ICommitOrderMutationResponse, ICommitOrderMutationVariables } from 'remote/queries/order';
import { useAuthContext } from 'state/auth';
import { useOrderContext } from 'state/order';
import { OrderSuccessFailureStatuses } from 'state/order/constants';
import { useOrderTimedFireLazy } from 'state/order/hooks/use-order-timed-fire';
import { ServiceMode } from 'state/service-mode';
import { buildCommitDeliveryInput, buildCommitOrderInput } from 'utils/cart';
import logger from 'utils/logger';
import { PerformanceMarks, setMark } from 'utils/timing';

import { mapCardType } from './map-card-type';
import { ICommitOrderInput, IUseCommitOrderParams } from './types';

// default order status polling interval, in ms
const DEFAULT_POLL_INTERVAL = 1500;

export const useCommitOrder = ({
  onCompleted = noop,
  orderStatusPollInterval = DEFAULT_POLL_INTERVAL,
  rbiOrderId,
  skipOrderStatusPolling = true,
}: IUseCommitOrderParams) => {
  const [skipPolling, setSkipPolling] = useState(skipOrderStatusPolling);
  const { onCommitSuccess } = useOrderContext();
  const auth = useAuthContext();
  const commitOptions = useRef({} as ICommitOrderInput);
  const fireOrderInByServerOrderIdRef = useRef(new Map());

  const [fireMutation, mutationResult] = useMutation<
    ICommitOrderMutationResponse,
    ICommitOrderMutationVariables
  >(CommitOrderDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GetOrderDocument, variables: { rbiOrderId } },
      { query: GetUserOrdersDocument },
    ],
  });

  const getOrderTimedFire = useOrderTimedFireLazy({});
  const onSuccess = useCallback(
    (rbiOrder: IServerOrder) => {
      onCompleted();
      onCommitSuccess(rbiOrder);
      setMark(PerformanceMarks.COMMIT_END);
      const { pickupDate } = getOrderTimedFire({ serverOrder: rbiOrder });
      const rbiOrderId = rbiOrder.rbiOrderId;
      logger.info({
        message: 'Order committed',
        context: {
          fireOrderIn: fireOrderInByServerOrderIdRef.current.get(rbiOrderId),
          rbiOrderId,
          pickupDate,
        },
      });
    },
    [getOrderTimedFire, onCommitSuccess, onCompleted]
  );

  const queryResult = useOrderStatus({
    failureStatuses: OrderSuccessFailureStatuses.failures,
    onSuccess,
    orderStatusPollInterval,
    rbiOrderId,
    skip: skipPolling,
    successStatuses: OrderSuccessFailureStatuses.success,
  });

  const { orderStatus, serverOrder } = queryResult;

  const commitOrder = useCallback(
    async (options: ICommitOrderInput) => {
      commitOptions.current = options;
      const delivery = buildCommitDeliveryInput(options.order, auth.user);

      // If using Apple or Google Pay, get the underlying credit card used and set this as the card type used
      options.creditType = mapCardType(options);
      let fireOrderIn = (options.order.fireOrderIn || 0) as FireOrderIn;
      fireOrderInByServerOrderIdRef.current.set(rbiOrderId, fireOrderIn);

      const isDriveThrough = options.order.serviceMode === ServiceMode.DRIVE_THRU;
      // for backwards compatibility purposes, we need to convert 0 to 1 for ASAP drive thru orders
      if (isDriveThrough && options.order.fireOrderIn === 0) {
        fireOrderIn = 1;
      }

      const input = buildCommitOrderInput({
        ...options,
        fireOrderIn,
        rbiOrderId: rbiOrderId!,
      });

      const skipCoolingPeriod = !!options.skipCoolingPeriod;
      setMark(PerformanceMarks.COMMIT_START);

      const result = await fireMutation({
        variables: {
          delivery,
          input,
          skipCoolingPeriod,
        },
      });

      if (result.data) {
        setSkipPolling(false);
      }
      return result;
    },
    [auth.user, fireMutation, rbiOrderId]
  );

  const forceCommitOrder = useCallback(() => {
    return commitOrder({ ...commitOptions.current, skipCoolingPeriod: true });
  }, [commitOrder]);

  return {
    commitOrder,
    commitResult: mutationResult,
    failure: queryResult.failure,
    forceCommitOrder,
    loading: mutationResult.loading || queryResult.loading,
    order: queryResult,
    orderStatus,
    serverOrder,
    success: queryResult.success,
  };
};
