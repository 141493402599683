import { Link } from 'expo-router';
import { StyleSheet, View } from 'react-native';

import { Schema } from '@fhs/backend';
import { Button, IconClose, Text, tokens } from '@fhs/ui';

import { CartItem } from '../cart-item';

import { Badge } from './badge';

type Props = {
  incentive: Schema['CartIncentive']['type'];
  entries: (Schema['CartItemEntry']['type'] & { quantity: number })[];
};

export function CartOfferItem(props: Props) {
  return (
    <View>
      <View style={styles.offer}>
        <View style={styles.row}>
          <Badge>Reward</Badge>
          <View style={styles.seperator} />
          <Text.Ui size="md" weight="bold">
            {props.incentive.title}
          </Text.Ui>
          <View style={styles.seperator} />
          <Link asChild href={`/cart?action=remove-offer&id=${props.incentive.offerId}`}>
            <Button type="outline" style={styles.removeOfferButton}>
              <Button.Icon size={16}>
                <IconClose />
              </Button.Icon>
            </Button>
          </Link>
        </View>
        <View style={styles.row}>
          <Text.Ui size="sm">{props.incentive.description}</Text.Ui>
        </View>
      </View>
      {props.entries.map(entry => (
        <CartItem
          item={entry}
          appliedSavings={props.incentive.savingsAmount}
          canUsePoints={false}
          key={entry.lineId}
          showQuantitySelectors={false}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  uiBlock: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    backgroundColor: tokens.colors.$blackOpacity30,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
  },
  rewardHeaderText: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  seperator: {
    height: 16,
    width: 1,
    backgroundColor: tokens.colors.$black10,
  },
  removeOfferButton: {
    // resets the padding size so it can scale properly
    paddingLeft: 0,
    paddingRight: 0,
    // resets a base value, needed to get a true circle
    minHeight: 'auto',
    height: 28,
    width: 28,
  },
  row: {
    flexDirection: 'row',
    borderBottomColor: tokens.colors.$black10,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    paddingHorizontal: 16,
    paddingVertical: 14,
  },
  offer: {
    backgroundColor: tokens.colors.$houseLight,
  },
});
