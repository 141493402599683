import { useEffect, useRef, useState } from 'react';

import { AutocompletePrediction } from '@rbilabs/expo-maps';
import useGoogleGeolocationLibrary from 'hooks/geolocation/use-google-geolocation-library';
import { useLocale } from 'state/intl';

const LOCATION_LENGTH_THRESHOLD_FOR_PREDICTIONS: number = 3;

function useLocationPredictions(
  location: string,
  {
    addressesOnly = false,
  }: {
    addressesOnly?: boolean;
  } = {}
) {
  const { libraryLoaded } = useGoogleGeolocationLibrary();
  const { region } = useLocale();
  const [predictions, setPredictions] = useState<AutocompletePrediction[]>([]);
  const autocompleteServiceRef = useRef<google.maps.places.AutocompleteService>();

  if (libraryLoaded && !autocompleteServiceRef.current) {
    autocompleteServiceRef.current = new window.google.maps.places.AutocompleteService();
  }

  useEffect(() => {
    if (!libraryLoaded) {
      return;
    }

    if (!location || location.length < LOCATION_LENGTH_THRESHOLD_FOR_PREDICTIONS) {
      setPredictions(() => []);
      return;
    }

    const request = {
      input: location,
      componentRestrictions: { country: region },
      ...(addressesOnly && { types: ['address'] }),
    };

    autocompleteServiceRef?.current?.getPlacePredictions(
      request,
      (results, status: google.maps.places.PlacesServiceStatus) => {
        const mappedResults =
          status === window.google.maps.places.PlacesServiceStatus.OK && results?.length
            ? results.map(prediction => ({
                placeId: prediction.place_id,
                description: prediction.description,
                mainText: prediction.structured_formatting.main_text,
                secondaryText: prediction.structured_formatting.secondary_text,
              }))
            : [];
        setPredictions(() => mappedResults);
      }
    );
  }, [addressesOnly, libraryLoaded, location, region]);

  return predictions;
}

export default useLocationPredictions;
