import { Text } from '@fhs-legacy/universal-components';
import { primitive } from 'styles/constants/primitives';

export const StyledText = Text.withConfig<{ newUI?: boolean }>(p => ({
  fontSize: p.newUI ? 'xs' : 'sm',
  lineHeight: 'xs',
  textAlign: 'center',
  marginBottom: '$4',
  marginX: 'auto',
  maxWidth: p.newUI ? '92%' : '$72',
  color: p.newUI ? primitive.$blackOpacity30 : null,
}));
