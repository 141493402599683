import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useCartContext } from 'state/cart';
import { ServiceMode } from 'state/service-mode/types';
import { useStoreContext } from 'state/store';
import { isCatering } from 'utils/service-mode';

import { PickupServiceModeOption } from './pickup-service-mode-option';
import { serviceModeList } from './service-mode-list';
import { Container } from './styled';

export const ServiceModeDetails = () => {
  const { serviceMode: selectedServiceMode, selectServiceMode } = useCartContext();
  const { serviceModeStatus } = useStoreContext();
  const { formatMessage } = useIntl();

  const handleSetServiceMode = useCallback(
    (mode: ServiceMode) => {
      const isUnavailable = serviceModeStatus[mode]?.disabled;
      if (isUnavailable) {
        return;
      }

      selectServiceMode(mode);
    },
    [serviceModeStatus, selectServiceMode]
  );

  /*
   * This will validate the currently selected service mode is still available otherwise
   * default to the first available service mode
   */
  useEffect(() => {
    if (
      serviceModeStatus[selectedServiceMode]?.available &&
      !serviceModeStatus[selectedServiceMode]?.disabled
    ) {
      return;
    }

    const firstAvailableServiceMode = serviceModeList.find(serviceMode => {
      return serviceModeStatus[serviceMode]?.available && !serviceModeStatus[serviceMode]?.disabled;
    });

    if (firstAvailableServiceMode) {
      selectServiceMode(firstAvailableServiceMode);
    }
  }, [selectedServiceMode, serviceModeStatus, selectServiceMode]);

  const CateringServiceModeComponents = {
    [ServiceMode.CATERING_PICKUP]: (
      <PickupServiceModeOption
        key={ServiceMode.CATERING_PICKUP}
        serviceMode={ServiceMode.CATERING_PICKUP}
        header={formatMessage({ id: 'serviceModeDetailsCateringPickupHeader' })}
        description={formatMessage({ id: 'serviceModeDetailsCateringPickupDescription' })}
        unavailable={serviceModeStatus.CATERING_PICKUP.disabled}
        hidden={!serviceModeStatus.CATERING_PICKUP.available}
        isSelected={selectedServiceMode === ServiceMode.CATERING_PICKUP}
        onSelect={() => handleSetServiceMode(ServiceMode.CATERING_PICKUP)}
      />
    ),
  };

  const PickupServiceModeComponents = {
    [ServiceMode.TAKEOUT]: (
      <PickupServiceModeOption
        key={ServiceMode.TAKEOUT}
        serviceMode={ServiceMode.TAKEOUT}
        header={formatMessage({ id: 'serviceModeDetailsPickupHeader' })}
        description={formatMessage({ id: 'serviceModeDetailsPickupDescription' })}
        unavailable={serviceModeStatus.TAKEOUT.disabled}
        hidden={!serviceModeStatus.TAKEOUT.available}
        isSelected={selectedServiceMode === ServiceMode.TAKEOUT}
        onSelect={() => handleSetServiceMode(ServiceMode.TAKEOUT)}
      />
    ),
    [ServiceMode.CURBSIDE]: (
      <PickupServiceModeOption
        key={ServiceMode.CURBSIDE}
        serviceMode={ServiceMode.CURBSIDE}
        header={formatMessage({ id: 'serviceModeDetailsCurbsideHeader' })}
        description={formatMessage({ id: 'serviceModeDetailsCurbsideDescription' })}
        unavailable={serviceModeStatus.CURBSIDE.disabled}
        hidden={!serviceModeStatus.CURBSIDE.available}
        isSelected={selectedServiceMode === ServiceMode.CURBSIDE}
        onSelect={() => handleSetServiceMode(ServiceMode.CURBSIDE)}
      />
    ),
    [ServiceMode.DRIVE_THRU]: (
      <PickupServiceModeOption
        key={ServiceMode.DRIVE_THRU}
        serviceMode={ServiceMode.DRIVE_THRU}
        header={formatMessage({ id: 'serviceModeDetailsDriveThruHeader' })}
        description={formatMessage({ id: 'serviceModeDetailsDriveThruDescription' })}
        unavailable={serviceModeStatus.DRIVE_THRU.disabled}
        hidden={!serviceModeStatus.DRIVE_THRU.available}
        isSelected={selectedServiceMode === ServiceMode.DRIVE_THRU}
        onSelect={() => handleSetServiceMode(ServiceMode.DRIVE_THRU)}
      />
    ),
    [ServiceMode.EAT_IN]: (
      <PickupServiceModeOption
        key={ServiceMode.EAT_IN}
        serviceMode={ServiceMode.EAT_IN}
        header={formatMessage({ id: 'serviceModeDetailsDineInHeader' })}
        description={formatMessage({ id: 'serviceModeDetailsDineInDescription' })}
        unavailable={serviceModeStatus.EAT_IN.disabled}
        hidden={!serviceModeStatus.EAT_IN.available}
        isSelected={selectedServiceMode === ServiceMode.EAT_IN}
        onSelect={() => handleSetServiceMode(ServiceMode.EAT_IN)}
      />
    ),
    [ServiceMode.PICKUP_WINDOW]: (
      <PickupServiceModeOption
        key={ServiceMode.PICKUP_WINDOW}
        serviceMode={ServiceMode.PICKUP_WINDOW}
        header={formatMessage({ id: 'serviceModeDetailsPickupWindowHeader' })}
        description={formatMessage({ id: 'serviceModeDetailsPickupWindowDescription' })}
        unavailable={serviceModeStatus.PICKUP_WINDOW.disabled}
        hidden={!serviceModeStatus.PICKUP_WINDOW.available}
        isSelected={selectedServiceMode === ServiceMode.PICKUP_WINDOW}
        onSelect={() => handleSetServiceMode(ServiceMode.PICKUP_WINDOW)}
      />
    ),
  };

  const ServiceModeComponents = isCatering(selectedServiceMode)
    ? CateringServiceModeComponents
    : PickupServiceModeComponents;

  return (
    <Container testID="service-mode-options">
      {serviceModeList.map(key => ServiceModeComponents[key] || null)}
    </Container>
  );
};
