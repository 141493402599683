import { Stack } from 'expo-router';

import { getScreenTitleHeader } from '../../components/get-screen-title-header';

export default function DrawerLayout() {
  return (
    <Stack
      screenOptions={{
        header: getScreenTitleHeader({ backStyle: 'back', cannotGoBackHref: '/account' }),
      }}
    >
      <Stack.Screen
        name="account/index"
        options={{
          title: 'My Account',
          header: getScreenTitleHeader({ backStyle: 'dynamic', cannotGoBackHref: '/' }),
        }}
      />
      <Stack.Screen
        name="account/info"
        options={{
          title: 'My Account',
        }}
      />
      <Stack.Screen
        name="account/orders"
        options={{
          title: 'Recent Orders',
        }}
      />
      <Stack.Screen
        name="account/payment"
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="diagnostics"
        options={{
          title: 'Diagnostics',
        }}
      />
    </Stack>
  );
}
