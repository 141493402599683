import React from 'react';

import { Box, Icon, Text } from '@fhs-legacy/universal-components';

interface IWarningMessage {
  message: string;
  iconFillColor?: string;
}

const Container = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  alignSelf: 'stretch',
  padding: 0,
});

const IconContainer = Box.withConfig({
  flexDirection: 'row',
  marginTop: 0,
  marginRight: '$1',
  marginBottom: 0,
  marginLeft: 0,
});

const Message = Text.withConfig({
  margin: 0,
  fontSize: 'xs',
  fontStyle: 'normal',
  textAlign: 'left',
  lineHeight: 'xs',
  fontWeight: 'normal',
});

const WarningMessage: React.FC<React.PropsWithChildren<IWarningMessage>> = ({
  message,
  iconFillColor,
}) => (
  <Container>
    <IconContainer testID="error-icon">
      <Icon variant="error" color={iconFillColor} size="16px" aria-hidden />
    </IconContainer>
    <Message>{message}</Message>
  </Container>
);

export default WarningMessage;
