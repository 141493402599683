import React from 'react';
import { useIntl } from 'react-intl';

import { Text } from '@fhs-legacy/universal-components';
import useDialogModal from 'hooks/use-dialog-modal';

import { IReplaceIncentiveModal, IncentiveType } from './types';

const ReplaceIncentiveModal = ({
  onDismiss,
  onConfirm,
  isOpen,
  incentiveType,
}: IReplaceIncentiveModal) => {
  const { formatMessage } = useIntl();
  const [ReplaceIncentiveDialog] = useDialogModal({
    modalAppearanceEventMessage: 'Confirm Replace Incentive',
    showCancel: true,
    init: true,
    onDismiss: onDismiss,
    onConfirm: onConfirm,
    onCancel: onDismiss,
  });
  const replaceTextId = {
    [IncentiveType.INCENTIVE]: 'replaceIncentive',
    [IncentiveType.OFFER]: 'replaceOffer',
    [IncentiveType.REWARD]: 'replaceReward',
  };
  const formattedMessage = formatMessage(
    {
      id: replaceTextId[incentiveType],
      description: {},
    },
    {
      oneReward: <Text bold={true}>{formatMessage({ id: 'oneReward' })}</Text>,
      oneOffer: <Text bold={true}>{formatMessage({ id: 'oneOffer' })}</Text>,
    }
  );
  return (
    <ReplaceIncentiveDialog
      showDialog={isOpen}
      heading={formatMessage({
        id: 'promotionalOrderLimitExceeded',
      })}
      bodyComponent={<Text mb="$4">{formattedMessage}</Text>}
      buttonLabel={formatMessage({ id: 'replace' })}
      showCloseButton
    />
  );
};

export default ReplaceIncentiveModal;
