import { isModifierSelected as modernIsModifierSelected } from 'utils/menu/modifiers';
import { convertModActionToSelection } from 'utils/wizard';

import {
  fifoAdjustment,
  isItemOptionSelection,
  isModifierSelected,
  removeDefaultsAndInapplicableSelections,
  selectionWillExceedMax,
} from './utils';

export const modifierSelectionsReducer = (state, action) => {
  switch (action.type) {
    // When an item option has a `componentStyle === selector`, the logic is simple.
    // the user has a checkbox, that lets them add their selection (when checked).
    // This case is to handle that.
    // The additional complexity here is that we have to factor the option.maxAmount and
    // ensure we dont exceed that.
    // Also we have to consider fifo.
    // FIFO is that if the item has a max of 2 items, the moment you click a third option,
    // we deselect the first option.
    case 'ADD_SELECTION': {
      const adjState = selectionWillExceedMax(state, action)
        ? fifoAdjustment(state, action)
        : state;
      const newSel = convertModActionToSelection(action, { fifoPositions: [action.fifoCounter] });

      return action.option.allowMultipleSelections
        ? [...adjState, newSel]
        : [...adjState.filter(sel => !isItemOptionSelection(sel, action)), newSel];
    }

    // When an item option has a `componentStyle === selector`, the logic is simple.
    // the user has a checkbox, that lets them remove their selection (when unchecked).
    // This case is to handle that.
    case 'REMOVE_SELECTION': {
      return state.filter(sel => !isModifierSelected(sel, action));
    }

    // When an item is a `componentStyle === stepper`, any stepper movement means to
    // take out the previous selection from the itemOption.options, and add the new selection.
    // a stepper componentStyle can only resolve to one of the itemOption.options based on the index
    // the user increment/decrements to.
    // It is expected that the itemOptions maxAmount is 1 if you use a stepper componentStyle
    case 'MOVE_STEPPER_SELECTION': {
      let previousIndex = state.findIndex(({ _key }) => _key === action.option._key);

      // if the stepper was not found in state, we are effectively pushing in a new value.
      if (previousIndex === -1) {
        previousIndex = state.length;
      }

      state[previousIndex] = convertModActionToSelection(action, {
        fifoPositions: [],
        quantityOverride: 1,
      });

      return [...state];
    }

    // When a item option has a `componentStyle === 'boolean'`, it is expected that
    // options[0] === off
    // options[1] === on
    // This toggle logic does the following:
    //   - if the selection is not found in state at all, it is obviously "off",
    //     inject selection with options[1]
    //   - else inject selection with options[!!prevOptionIndex]
    case 'TOGGLE_BOOLEAN_SELECTION': {
      const stateOptionIndex = state.findIndex(({ _key }) => _key === action.option._key);
      const stateContainsSelection = stateOptionIndex !== -1;

      if (!stateContainsSelection) {
        const newSel = convertModActionToSelection(action, {
          modifierOverride: action.option.options[1],
          quantityOverride: 1,
        });
        return [...state, newSel];
      }

      const currentlySelected = modernIsModifierSelected(action.option, state);

      state[stateOptionIndex].modifier = {
        ...action.option.options[currentlySelected ? 0 : 1],
        quantity: 1,
      };

      return [...state];
    }

    // TODO: Document this case
    case 'ADD_QUANTITY': {
      const adjState = selectionWillExceedMax(state, action)
        ? fifoAdjustment(state, action)
        : state;
      return adjState.map(sel =>
        isModifierSelected(sel, action)
          ? {
              ...sel,
              fifoPositions: [...sel.fifoPositions, action.fifoCounter],
              modifier: { ...sel.modifier, quantity: action.quantity },
            }
          : sel
      );
    }

    // TODO: Document this case
    case 'REMOVE_QUANTITY': {
      return state.map(sel =>
        isModifierSelected(sel, action)
          ? { ...sel, modifier: { ...sel.modifier, quantity: action.quantity } }
          : sel
      );
    }

    // Clears all exisiting selections
    // used when we inject derived state (defaults or user selections)
    case 'CLEAR_ALL_SELECTIONS': {
      return [];
    }

    // When the wizard loads up, we have to preselect all the default modifiers
    // This case is just used to inject default data.
    case 'DEFAULT_SELECTION': {
      const adjState = selectionWillExceedMax(state, action)
        ? fifoAdjustment(state, action)
        : state;
      const newSel = convertModActionToSelection(action, {
        fifoPositions: Array(action.option.maxAmount)
          .fill(0)
          .map((_, i) => i + (action.fifoCounter ?? 0)),
      });

      return [...adjState, newSel];
    }

    // When a user changes picker options, we have to reset the default selections
    // We retain the selections that were not defaults, so if user added/removed
    // modifiers the choices will carry over if they still apply to the new item
    // TODO: RN - remove bc only used on combos and TH doesn't have these?
    case 'CLEAR_INAPPLICABLE_SELECTIONS': {
      const { item, comboSlotId } = action;
      if (!item) {
        return [];
      }
      return state.reduce(removeDefaultsAndInapplicableSelections(item, comboSlotId), []);
    }

    // TODO: RN - remove bc only used on combos and TH doesn't have these?
    case 'CLEAR_COMBO_SLOT_SELECTIONS': {
      const { comboSlotId } = action;

      if (!comboSlotId) {
        return state;
      }

      return state.filter(selection => selection.comboSlotId !== comboSlotId);
    }

    default: {
      return state;
    }
  }
};
