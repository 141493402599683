import * as React from 'react';
import { FC, useCallback } from 'react';

import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useGetAvailableRewards } from 'state/loyalty/hooks/use-get-available-rewards';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { HapticsNotificationType, hapticNotification } from 'utils/haptic';

import { RedeemRewardView } from './redeem-reward.view';
import { IRedeemRewardProps, RewardActions } from './types';
import { useRedeemReward } from './use-redeem-reward/use-redeem-reward';

/**
 *
 * RedeemReward card displays below cart items that are eligible for reward redemptions
 * Users can redeem rewards using points from their loyalty balance in order to receive discounts on their order
 *
 */
const RedeemReward: FC<React.PropsWithChildren<IRedeemRewardProps>> = ({
  isRewardApplied,
  item,
  reward,
}) => {
  const enableMultipleRewards = useFlag(LaunchDarklyFlag.ENABLE_MULTIPLE_REWARDS_PER_ORDER);
  const { getAvailableRewardFromCartEntry } = useGetAvailableRewards();
  const { rewardRedeemable, incentiveNotInMenu, redeemRewardLoading } = useRedeemReward(item);
  const dispatch = useAppDispatch();
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const totalTimesRewardApplied = useAppSelector(selectors.loyalty.selectTotalTimesRewardApplied);
  const timeRewardApplied = Object.values(totalTimesRewardApplied).reduce(
    (total, time) => total + time,
    0
  );
  const displayAddButton = enableMultipleRewards ? true : timeRewardApplied < 1;
  const { loyaltyUser } = useLoyaltyUser();
  const { pointCost, name } = reward;

  const handleRewardClick = useCallback(
    (action: RewardActions) => () => {
      const { applyReward, setIsPricingRewardApplication, unApplyReward } = actions.loyalty;
      dispatch(setIsPricingRewardApplication(true));

      const cartEntryReward = getAvailableRewardFromCartEntry(item);
      if (!cartEntryReward) {
        return;
      }

      const handleAction = {
        [RewardActions.ADD]: applyReward,
        [RewardActions.REMOVE]: unApplyReward,
      }[action];

      dispatch(
        handleAction({
          cartId: item.cartId,
          rewardBenefitId: cartEntryReward.rewardBenefitId,
          loyaltyUser,
        })
      );
      hapticNotification({ type: HapticsNotificationType.SUCCESS });
    },
    [dispatch, loyaltyUser, getAvailableRewardFromCartEntry, item]
  );

  const numberOfTimesRewardApplied = appliedLoyaltyRewards[item.cartId]?.timesApplied;

  const pointsApplied = (numberOfTimesRewardApplied || 0) * pointCost;

  if (pointCost === 0) {
    return null;
  }

  return (
    <RedeemRewardView
      displayApplyButton={(item.quantity > 1 || !isRewardApplied) && displayAddButton}
      handleRewardClick={handleRewardClick}
      isRewardApplied={isRewardApplied}
      points={pointCost}
      rewardTitle={name}
      disableApplyButton={!rewardRedeemable}
      pointsApplied={pointsApplied}
      incentiveNotInMenu={incentiveNotInMenu}
      redeemRewardLoading={redeemRewardLoading}
    />
  );
};

export default RedeemReward;
