import { useLocalSearchParams } from 'expo-router';
import { type ComponentProps, useMemo } from 'react';
import { PixelRatio, StyleSheet, View } from 'react-native';

import { AnchorSectionList, MqSwitch, XStack, createMqStyles } from '@fhs/ui';

import { MenuErrorFallback } from '../components/menu-error-fallback';
import { MenuListLegalText } from '../components/menu-list-legal-text';
import { MenuListSkeletonLoading } from '../components/menu-list-skeleton';
import { MenuOffersLink } from '../components/menu-offers-link';
import { useMenuListQuery } from '../queries/use-menu-list-query';
import type { ScreenMenuListProps } from '../types';

export function ScreenMenuList(props: ScreenMenuListProps) {
  const mqStyles = useMqStyles();

  const sections: ComponentProps<typeof AnchorSectionList>['sections'] = useMemo(
    () => [
      {
        id: 'offers-and-rewards',
        title: '',
        anchorTitle: 'Offers & Rewards',
        image: {
          asset: require('../assets/img/offers-and-rewards-icon.webp'),
          altText: 'A placeholder image',
        },
        items: <MenuOffersLink style={mqStyles.offersLink} />,
      },
      ...props.sections.map(rawSection => {
        return {
          id: rawSection.slug,
          title: rawSection.displayName,
          anchorTitle: rawSection.displayName,
          image: {
            asset: {
              uri: rawSection.image?.asset?.uri,
              blurHash: rawSection.image?.asset?.blurHash,
            },
            altText: rawSection.image?.altText,
          },
          items: rawSection.items.map(rawItem => {
            const imageUrl = new URL(rawItem.image?.asset?.uri);
            imageUrl.searchParams.set('h', '104');
            imageUrl.searchParams.set('dpr', Math.min(PixelRatio.get(), 2.5).toString());
            imageUrl.searchParams.set('format', 'webp');

            return {
              id: rawItem.slug,
              // TODO(simplified-menu): remove this after 100% rollout
              href: props.linkToLegacyMenuDetailView ? rawItem._legacyUrl : rawItem.url,
              title: rawItem.displayName,
              description: rawItem.descriptionShort,
              badge: rawItem.badge,
              image: {
                asset: { uri: imageUrl.toString(), blurHash: rawItem.image?.asset?.blurHash },
                altText: rawItem.image?.altText,
              },
              isAvailable: rawItem.isAvailable,
            };
          }),
        };
      }),
    ],
    [mqStyles, props.sections, props.linkToLegacyMenuDetailView]
  );

  const { section: defaultSectionId } = useLocalSearchParams<{ section: string }>();

  return (
    <AnchorSectionList sections={sections} defaultSectionId={defaultSectionId}>
      <MqSwitch
        $base={() => (
          <View style={styles.container}>
            <AnchorSectionList.AnchorListHorizontal />
            <AnchorSectionList.SectionList footer={<MenuListLegalText />} />
          </View>
        )}
        $gteDesktop={() => (
          <View style={styles.container}>
            <XStack style={styles.xStack}>
              <AnchorSectionList.AnchorListVertical />
              <AnchorSectionList.SectionList footer={<MenuListLegalText />} />
            </XStack>
          </View>
        )}
      />
    </AnchorSectionList>
  );
}
type ScreenMenuListWithQueryProps = {
  selectedRestaurantId?: string;
  region: 'us' | 'ca';
  serviceMode: 'delivery' | 'pickup';

  // TODO(simplified-menu-rollout): remove this after 100% rollout
  // Temporary prop to link to the legacy menu detail view
  linkToLegacyMenuDetailView: boolean;
};

export function ScreenMenuListWithQuery({
  selectedRestaurantId: restaurantId,
  serviceMode,
  region,

  // TODO: remove this after 100% rollout
  linkToLegacyMenuDetailView,
}: ScreenMenuListWithQueryProps) {
  const { data, isLoading, isError } = useMenuListQuery({
    restaurantId,
    serviceMode,
    region,
  });

  if (isLoading) {
    return <MenuListSkeletonLoading />;
  }

  // TODO: add refresh logic
  if (isError) {
    return (
      <View style={{ flex: 1 }}>
        <MenuErrorFallback />
      </View>
    );
  }

  if (!data) {
    // XXX: we should log an error, this should never happen in production
    // @ts-ignore
    // console.error(
    //   'No data found for menu list. If you are seeing this, you many need to sync store data'
    // );
    return (
      <View style={{ flex: 1 }}>
        <MenuErrorFallback />
      </View>
    );
  }

  return (
    <ScreenMenuList
      sections={data.sections}
      // TODO: remove this after 100% rollout
      linkToLegacyMenuDetailView={linkToLegacyMenuDetailView}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
  },
  xStack: {
    height: '100%',
  },
  offersLink: {
    marginBottom: 20,
  },
});

const useMqStyles = createMqStyles({
  offersLink: {
    $base: {
      marginBottom: 0,
    },
    $gteDesktop: {
      marginBottom: 0,
      marginTop: 18,
    },
  },
});
