import { router } from 'expo-router';

import { routes } from 'utils/routing';

export const useUnavailableItemsOverrideProps = () => {
  return {
    onPrimaryCTAClick: () => {
      router.navigate(routes.cart);
    },
    secondaryCTALabelText: undefined,
    onSecondaryCTAClick: () => {
      router.navigate(routes.cart);
    },
  };
};
