import React from 'react';

import { MenuObject } from '@rbi-ctg/menu';
import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';

import Wizard from './wizard';
import { WizardProvider } from './wizard-provider';
import { LayoutContainer } from './wizard.styled';

const MenuOrderWizard: React.FC<
  React.PropsWithChildren<{
    data: MenuObject;
    loading: boolean;
  }>
> = props => {
  return (
    <WizardProvider>
      <LayoutContainer>
        {props.loading ? (
          <LoadingContainer>
            <LoadingAnimation />
          </LoadingContainer>
        ) : (
          <Wizard {...props} />
        )}
      </LayoutContainer>
    </WizardProvider>
  );
};
export default MenuOrderWizard;
