import { Link } from 'expo-router';
import { ScrollView, StyleSheet } from 'react-native';

import { Button, Text } from '@fhs/ui';

export function _TmpScreenV2List() {
  return (
    <ScrollView style={styles.container}>
      <Text.Heading type="one" style={styles.header}>
        Simply Better
      </Text.Heading>
      <Text.Paragraph size="md" style={styles.description}>
        This is a temporary screen for V2. It will be removed once the V2 menu is out of beta.
      </Text.Paragraph>
      <Text.Heading type="two" style={styles.subHeader}>
        V2 Menu
      </Text.Heading>
      <Link href="/v2/menu" asChild>
        <Button type="solid" size="lg">
          <Button.Text>Menu</Button.Text>
        </Button>
      </Link>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    // TODO(simplified-menu): Update to use tokens
    maxWidth: 620,
    paddingVertical: 36,
    paddingHorizontal: 8,
  },
  header: {
    paddingVertical: 24,
  },
  subHeader: {
    paddingVertical: 16,
  },
  description: {},
});
