import React, { ReactNode } from 'react';

interface ErrorBoundaryProps {
  fallback: ReactNode;
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

/**
 * A React component that acts as an error boundary, catching JavaScript errors anywhere in the child component tree,
 * and rendering a fallback UI when an error is encountered. This helps prevent the entire application from crashing.
 *
 * @param {ReactNode} props.fallback - The UI to render when an error occurs (fallback UI).
 * @param {ReactNode} props.children - The child components to render. If no error occurs, these will be rendered.
 *
 * @returns {JSX.Element} The rendered error boundary or the fallback UI when an error is caught.
 *
 * @example
 * <ErrorBoundary fallback={<ErrorFallback />}>
 *   <YourComponent />
 * </ErrorBoundary>
 *
 * @see https://reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(): void {
    /** Here we can insert a proper logic if we want to handled the error in more detail way
     * @example
     * componentDidCatch(error: Error, info: ErrorInfo): void {
     *  console.error('Error catched by ErrorBoundary:', error, info);
     * }
     * */
    return;
  }

  render() {
    const { children, fallback } = this.props;

    if (this.state.hasError) {
      return fallback;
    }

    return children;
  }
}
