import { useRef } from 'react';

import { IUseRewards } from './types';

interface ICalculateReloadAmount {
  totalCents: number;
  currentPrepaidBalance: number;
  defaultReloadAmt: number;
}

const ROUND_TO_NEAREST_CENTS = 500;

export const calculateReloadAmount = ({
  totalCents,
  currentPrepaidBalance,
  defaultReloadAmt,
}: ICalculateReloadAmount) => {
  let amountToReload = 0;

  if (totalCents > currentPrepaidBalance) {
    const minimumReloadAmount = totalCents - currentPrepaidBalance;

    if (!currentPrepaidBalance || minimumReloadAmount > defaultReloadAmt) {
      amountToReload =
        Math.ceil(minimumReloadAmount / ROUND_TO_NEAREST_CENTS) * ROUND_TO_NEAREST_CENTS;
    } else {
      amountToReload = defaultReloadAmt;
    }
  }
  return amountToReload;
};

const useRewards = ({ user, prepaidCard, serverOrder, order }: IUseRewards) => {
  // order gets cleared on repricing
  // preserving the previous value prevents flashes of content as repricing loads
  const reloadAmount = useRef(0);
  const defaultReloadAmt = user?.details?.defaultReloadAmt ?? ROUND_TO_NEAREST_CENTS;
  const currentPrepaidBalance = prepaidCard?.prepaid?.feFormattedCurrentBalance ?? 0;

  if (!serverOrder || !serverOrder.cart || !user) {
    return { reloadAmount: reloadAmount.current };
  }

  const {
    delivery,
    cart: { totalCents },
  } = serverOrder;

  const tipAmount = Number(order?.tipAmount || 0);

  // delivery.tipCents is null until the user advances to PAYMENT_REQUESTED
  // at the same time, totalCents increases by the tip amount
  // we needed to do this to render the correct grand total/tip as user advances
  const serverOrderTip = Number(delivery?.tipCents || 0);

  reloadAmount.current = calculateReloadAmount({
    totalCents: totalCents - serverOrderTip + tipAmount,
    currentPrepaidBalance:
      currentPrepaidBalance !== null
        ? currentPrepaidBalance
        : prepaidCard?.prepaid?.currentBalance ?? 0,
    defaultReloadAmt,
  });

  return { reloadAmount: reloadAmount.current };
};

export default useRewards;
