import React from 'react';

import { IUpsellStrategyProps } from './types';
import { Upsell } from './upsell';
import { useMenuFeatureUpsell } from './use-menu-feature-upsell';

export const UpsellStrategy: React.FC<IUpsellStrategyProps> = props => {
  const upsellData = useMenuFeatureUpsell();
  return <Upsell {...upsellData} {...props} />;
};
