import { getConfigValue } from 'utils/environment';

interface IFeatureIds {
  // add new feature ids here
  featureAccountId?: string;
  featureAccountDeletePageId?: string;
  featureAccountFormId?: string;
  featureAccountRequestInfoPageId?: string;
  featureDisclaimersId?: string;
  featureFooterId?: string;
  featureGeolocationMapMarkersId?: string;
  featureGeolocationModalId?: string;
  featureHomePageId?: string;
  featureLayoutId?: string;
  featureLinkPhysicalCardUIId?: string;
  featureMenuId?: string;
  featureNavigationId?: string;
  featureOffersId?: string;
  featureSupportDataId?: string;
  featureStaticMenuId?: string;
  featureLoyaltyUIId?: string;
  featureClaimPointsUIId?: string;
  featureNutritionId?: string;
  featureLoyaltyRewardListCategoriesId?: string;
  featureBeeperId?: string;
  featureDonationId?: string;
  featureFoodwareId?: string;
  featureIdsLoading: false;
}

export const useFeaturesContext = (): IFeatureIds => ({
  featureAccountId: getConfigValue({ key: 'sanityFeaturesIds' }).featureAccounts,
  featureAccountDeletePageId: getConfigValue({
    key: 'sanityFeaturesIds',
  }).featureAccountDeletePages,
  featureAccountFormId: getConfigValue({ key: 'sanityFeaturesIds' }).featureAccountForms,
  featureAccountRequestInfoPageId: getConfigValue({
    key: 'sanityFeaturesIds',
    defaultValue: '',
  }).featureAccountRequestInfoPages,
  featureDisclaimersId: getConfigValue({ key: 'sanityFeaturesIds' }).featureDisclaimers,
  featureFooterId: getConfigValue({ key: 'sanityFeaturesIds' }).featureFooters,
  featureGeolocationMapMarkersId: getConfigValue({
    key: 'sanityFeaturesIds',
    defaultValue: '',
  }).featureGeolocationMapMarkers,
  featureGeolocationModalId: getConfigValue({ key: 'sanityFeaturesIds' }).featureGeolocationModals,
  featureHomePageId: getConfigValue({ key: 'sanityFeaturesIds' }).featureHomePages,
  featureLayoutId: getConfigValue({ key: 'sanityFeaturesIds' }).featureLayouts,
  featureLinkPhysicalCardUIId: getConfigValue({
    key: 'sanityFeaturesIds',
    defaultValue: '',
  }).allLinkPhysicalCardUis,
  featureMenuId: getConfigValue({ key: 'sanityFeaturesIds' }).featureMenus,
  featureNavigationId: getConfigValue({ key: 'sanityFeaturesIds' }).featureNavigations,
  featureOffersId: getConfigValue({ key: 'sanityFeaturesIds' }).featureOffers,
  featureSupportDataId: getConfigValue({ key: 'sanityFeaturesIds' }).allSupportData,
  featureStaticMenuId: getConfigValue({ key: 'sanityFeaturesIds' }).featureStaticMenus,
  featureLoyaltyUIId: getConfigValue({ key: 'sanityFeaturesIds' }).allLoyaltyUis,
  featureClaimPointsUIId: getConfigValue({ key: 'sanityFeaturesIds' }).allClaimPointsUis,
  featureNutritionId: getConfigValue({ key: 'sanityFeaturesIds' }).featureNutritions,
  featureLoyaltyRewardListCategoriesId: getConfigValue({
    key: 'sanityFeaturesIds',
  }).allRewardLists,
  featureBeeperId: getConfigValue({ key: 'sanityFeaturesIds' }).featureBeeperModals,
  featureDonationId: getConfigValue({ key: 'sanityFeaturesIds' }).featureDonations,
  featureFoodwareId: getConfigValue({ key: 'sanityFeaturesIds' }).featureFoodwares,

  // Legacy interop key:
  featureIdsLoading: false,
});

export const HOMEPAGE_ID = 'feature-home-page-singleton';
