import { useLocalSearchParams } from 'expo-router';
import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';

import { MqSwitch, ScrollView } from '@fhs/ui';
import { MenuBottomCTA } from '@fhs-legacy/frontend/src/components/menu-bottom-cta';
import { Box } from '@fhs-legacy/universal-components';
import FavoritesCardList from 'components/favorites-card-list';
import Footer from 'components/footer';
import { MenuListLoading } from 'components/menu-list-loading';
import RecentOrderCardList from 'components/recent-order-card-list';
import { TabbedHeader } from 'components/tabbed-header';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import { useFeatureMenu } from 'hooks/use-feature-menu';
import { useRenderTabbedMenuHeader } from 'hooks/use-tabbed-menu-header';
import NotFound from 'pages/not-found';
import { useLocale } from 'state/intl';
import { useMainMenuContext } from 'state/main-menu';
import { useStoreContext } from 'state/store';
import { WEB_APP_DESKTOP_LG_MAX_WIDTH } from 'utils/constants';
import {
  isFavoritesRoute,
  isItemPickerOrComboRoute,
  isRecentItemsRoute,
  isSectionRoute,
} from 'utils/routing';

import { MenuObjectContent } from './menu-object-content';
import { MenuSectionContent } from './menu-section-content';

const styles = StyleSheet.create({
  contentContainer: {
    flexShrink: 0,
    width: '100%',
    maxWidth: WEB_APP_DESKTOP_LG_MAX_WIDTH,
    marginHorizontal: 'auto',
  },
  productDetailsContentContainer: {
    flex: 1,
    maxWidth: '100%',
  },
});

const MenuContent = () => {
  useRumPageView('menu-content', 'Menu Content');

  const { id } = useLocalSearchParams<{ id: string }>();

  const { region } = useLocale();
  const { loading: mainMenuLoading, data: mainMenuData, getMainMenu } = useMainMenuContext();
  const { menuId, featureMenuLoading } = useFeatureMenu();
  const { store } = useStoreContext();
  const storeId = store.number || '';

  const isLoading = featureMenuLoading || mainMenuLoading;

  useEffect(() => {
    getMainMenu(menuId, region, storeId);
  }, [getMainMenu, menuId, region, storeId]);

  const shouldRenderTabbedHeader = useRenderTabbedMenuHeader();

  const recentItemsRoute = isRecentItemsRoute(`/menu/${id}`);
  const favoritesRoute = isFavoritesRoute(`/menu/${id}`);
  const sectionRoute = isSectionRoute(`/menu/${id}`);
  const itemPickerOrComboRoute = isItemPickerOrComboRoute(`/menu/${id}`);

  const isNotHandledMenuRoute =
    !recentItemsRoute && !favoritesRoute && !sectionRoute && !itemPickerOrComboRoute;

  if (isNotHandledMenuRoute) {
    return <NotFound />;
  }

  if (isLoading) {
    return <MenuListLoading />;
  }

  const content = (
    <>
      {shouldRenderTabbedHeader && (
        <TabbedHeader menuData={mainMenuData?.Menu} menuDataLoading={mainMenuLoading} />
      )}
      {recentItemsRoute && <RecentOrderCardList />}
      {favoritesRoute && <FavoritesCardList />}
      {sectionRoute && <MenuSectionContent id={`${id}`} />}
      {itemPickerOrComboRoute && <MenuObjectContent id={`${id}`} />}
    </>
  );

  const DesktopContainer = itemPickerOrComboRoute ? Box : ScrollView;

  return (
    <MqSwitch
      $ltDesktop={() => (
        <Box
          style={[
            styles.contentContainer,
            itemPickerOrComboRoute ? styles.productDetailsContentContainer : undefined,
          ]}
          h="full"
        >
          {content}
          <MenuBottomCTA />
        </Box>
      )}
      $gteDesktop={() => (
        <DesktopContainer flex={1}>
          <View
            style={[
              styles.contentContainer,
              itemPickerOrComboRoute ? styles.productDetailsContentContainer : undefined,
            ]}
          >
            {content}
          </View>
          {!itemPickerOrComboRoute ? <Footer /> : null}
        </DesktopContainer>
      )}
    />
  );
};

export default MenuContent;
