import React from 'react';

import { ICartEntry } from '@rbi-ctg/menu';
import { ProductDetail } from 'components/product-detail';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import { ProductWizardProvider } from 'state/product-wizard';
import { ProductMenuObject } from 'state/product-wizard/types';

export interface IMenuWizardProps {
  product: ProductMenuObject;
  isSimplyOffer?: boolean;
  addItemToSimplyOfferCart?: (cartEntry: ICartEntry) => void;
}
export const ProductWizard = React.memo<IMenuWizardProps>(
  ({ product, isSimplyOffer = false, addItemToSimplyOfferCart = () => {} }) => {
    useRumPageView('product-wizard', 'Product Wizard');
    return (
      <ProductWizardProvider
        product={product}
        isSimplyOffer={isSimplyOffer}
        addItemToSimplyOfferCart={addItemToSimplyOfferCart}
      >
        <ProductDetail />
      </ProductWizardProvider>
    );
  }
);
