import { ApolloError } from '@apollo/client';
import { router } from 'expo-router';
import React, { useState } from 'react';

import { KeyboardSpacing } from 'components/keyboard-spacing';
import {
  IProcessSmgCodeMutation,
  SmgCodeStatus,
  useProcessSmgCodeMutation,
} from 'generated/graphql-gateway';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { useLoyaltyClaimPointsContext } from 'state/loyalty-claim-points';
import { routes } from 'utils/routing';

import LoyaltyClaimPointsContainer from './loyalty-claim-points-container';
import LoyaltyClaimPointsDialog from './loyalty-claim-points-dialog';
import {
  InstructionDateText,
  MainImage,
  ParagraphText,
  ParagraphTextBlock,
  Text as StyledText,
  SubmitButton,
  TransactionDateInputFormContainer,
} from './loyalty-claim-points.styled';
import TransactionDateInputForm from './transaction-form/transaction-date-input-form';
import TransactionInputForm from './transaction-form/transaction-input-form';
import { ClaimPointsUIData } from './types';
import { generateTransactionDateOptions } from './utils';

const TRANSACTION_ID_MIN_LENGTH = 20;

export const getSanityError = (statusCode?: string, data?: ClaimPointsUIData) => {
  switch (statusCode) {
    case SmgCodeStatus.TRANSACTION_CLAIMED:
      return data?.claimPointsAlreadyClaimedContent;
    case SmgCodeStatus.NOT_FOUND:
      return data?.claimPointsTransactionNotFoundContent;
    case SmgCodeStatus.LIMIT_EXCEEDED:
      return data?.claimPointsDailyAmountExceededContent;
    default: {
      return data?.claimPointsInvalidCodeContent;
    }
  }
};

const defaultErrorState = { title: '', message: null };

const LoyaltyClaimPointsSimplified = () => {
  const { loyaltyUserId } = useLoyaltyUser();
  const { data } = useLoyaltyClaimPointsContext();

  const [codeValue, setCodeValue] = useState('');
  const [transactionDate, setTransactionDate] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [error, setError] = useState(defaultErrorState);
  const [hasError, setHasError] = useState(false);

  const content = data?.claimPointsSimplified;

  const expirationTimeAvailable = Boolean(
    content?.expirationTimeReceipts && content.inputBoxExpirationDatePlaceholder?.locale
  );

  const onMutationCompleted = (mutationData: IProcessSmgCodeMutation) => {
    const result = mutationData?.loyaltyProcessSmgCode;

    if ((result?.status as string) === SmgCodeStatus.ACCEPTED && result?.pointsEarned) {
      setShowSuccessDialog(true);

      const successMessage =
        content?.successDialogMessage?.localeRaw[0]?.children?.[0]?.text.replace(
          '{{pointsEarned}}',
          result?.pointsEarned
        );

      setSuccessMessage(successMessage || '');
    } else {
      const claimPointsError = getSanityError(result.status, data);

      setError({
        title: claimPointsError?.screenTitle?.locale || '',
        message: claimPointsError?.failureTextBlock?.localeRaw[0] || '',
      });

      setShowErrorDialog(true);
    }
  };

  const onMutationError = (error: ApolloError) => {
    if (error?.graphQLErrors?.length) {
      setShowErrorDialog(true);
      setHasError(true);

      const [err] = error.graphQLErrors;
      const status = err.extensions?.details?.status;

      const claimPointsError = getSanityError(status, data);

      setError({
        title: claimPointsError?.screenTitle?.locale || '',
        message: claimPointsError?.failureTextBlock?.localeRaw[0] || '',
      });
    }
  };

  const [processCode, { loading: processingSmgCode }] = useProcessSmgCodeMutation({
    onError: onMutationError,
    onCompleted: onMutationCompleted,
  });

  const submitCode = () => {
    setError(defaultErrorState);
    processCode({
      variables: {
        loyaltyId: loyaltyUserId,
        smgCode: codeValue,
        ...(transactionDate && { transactionDate }),
      },
    });
  };

  const isSubmitDisable =
    codeValue.length < TRANSACTION_ID_MIN_LENGTH ||
    (expirationTimeAvailable && !transactionDate) ||
    processingSmgCode;

  return (
    <>
      <LoyaltyClaimPointsContainer>
        {content?.rewardsIcon && (
          <MainImage alt={content.screenTitle?.locale || ''} image={content.rewardsIcon.locale} />
        )}
        <ParagraphTextBlock content={content?.instructionsTextBlock?.localeRaw} />
        <ParagraphTextBlock content={content?.secondInstructionsTextBlock?.localeRaw} />
        {content?.secondImage && (
          <MainImage alt={content.screenTitle?.locale || ''} image={content.secondImage.locale} />
        )}
        <TransactionInputForm setCodeValue={setCodeValue} hasError={hasError} />
        {expirationTimeAvailable && (
          <TransactionDateInputFormContainer>
            <InstructionDateText
              content={content?.expirationDateInstructionsTextBlock?.localeRaw}
            />
            <TransactionDateInputForm
              title={content?.inputBoxExpirationDatePlaceholder?.locale || ''}
              options={generateTransactionDateOptions(content?.expirationTimeReceipts || 0)}
              onChange={setTransactionDate}
            />
          </TransactionDateInputFormContainer>
        )}
        <SubmitButton disabled={isSubmitDisable} isLoading={processingSmgCode} onPress={submitCode}>
          {content?.buttonTitle?.locale}
        </SubmitButton>
        <KeyboardSpacing />
      </LoyaltyClaimPointsContainer>
      {showSuccessDialog && (
        <LoyaltyClaimPointsDialog
          eventMessage={content?.successDialogTitle?.locale || 'success dialog'}
          title={content?.successDialogTitle?.locale || 'success dialog'}
          message={<ParagraphText>{successMessage}</ParagraphText>}
          confirmText={content?.successDialogButtonText?.locale}
          onDismiss={() => setShowSuccessDialog(false)}
          onConfirm={() => {
            setShowSuccessDialog(false);
            router.navigate(routes.rewardsHistory);
          }}
        />
      )}
      {showErrorDialog && (
        <LoyaltyClaimPointsDialog
          eventMessage={error.title}
          title={error.title}
          message={<StyledText content={error.message} />}
          confirmText={content?.errorDialogButtonText?.locale}
          onDismiss={() => setShowErrorDialog(false)}
          onConfirm={() => setShowErrorDialog(false)}
        />
      )}
    </>
  );
};

export default LoyaltyClaimPointsSimplified;
