import { withStaticProperties } from '@fhs/utils';

import { ButtonFrame } from './button';
import { ButtonBadge } from './button-badge';
import { ButtonIcon } from './button-icon';
import { ButtonText } from './button-text';

export const Button = withStaticProperties(ButtonFrame, {
  Text: ButtonText,
  Icon: ButtonIcon,
  Badge: ButtonBadge,
});

export { useButtonContext } from './button';

export type { ButtonProps, ButtonContextType } from './button';
export type { ButtonTextProps } from './button-text';
export type { ButtonIconProps } from './button-icon';
export type { ButtonBadgeProps } from './button-badge';
