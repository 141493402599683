import { Text } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

const BaseInputMessageText = Text.withConfig(() => ({
  paddingTop: '2',
  textAlign: 'left',
}));

export const InputMessageText = BaseInputMessageText.withConfig<{
  $hasError?: boolean;
  $disabled?: boolean;
}>(({ $hasError, $disabled }) => {
  if ($disabled) {
    return { color: theme.token('text-disabled') };
  }
  if ($hasError) {
    return { color: theme.token('text-error') };
  }
  return { color: theme.token('text-default') };
});
