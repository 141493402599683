import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

interface IState {
  totalSteps: number;
  currentStep: number;
  isFinalStep: boolean;
}
interface ISelectors {}
interface IActions {
  incrementStep: () => void;
  setTotalSteps: Dispatch<SetStateAction<number>>;
}
export interface IWizard {
  selectors: ISelectors;
  actions: IActions;
  state: IState;
}

// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
export const WizardContext = createContext<IWizard>();
export const useWizard = (): IWizard => useContext(WizardContext);

// TODO: RN - TH Menu - doesn't need this provider bc there are no "steps" in TH Menu
export const WizardProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [totalSteps, setTotalSteps] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  const incrementStep = useCallback(() => {
    // don't increment if the current step is equal to the total steps
    // update the isFinalStep once we hit the totalSteps
    setCurrentStep(currStep => {
      if (currStep === totalSteps) {
        return currStep;
      }

      return currStep + 1;
    });
  }, [totalSteps]);

  const isFinalStep = useMemo(() => currentStep === totalSteps, [currentStep, totalSteps]);

  const value: IWizard = {
    state: {
      totalSteps,
      currentStep,
      isFinalStep,
    },
    actions: {
      incrementStep,
      setTotalSteps,
    },
    selectors: {},
  };

  return <WizardContext.Provider value={value}>{children}</WizardContext.Provider>;
};
