import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, VerticalCarouselItem } from '@fhs-legacy/universal-components';
import { IBackendCartEntries, ICartEntry } from '@rbi-ctg/menu';
import { getAvailabilityStatusMessage } from 'components/availability-status';
import Picture from 'components/picture';
import { ResponsiveCarouselSkeletonItem } from 'components/responsive-carousel/responsive-carousel.skeleton';
import { ItemAvailabilityStatus } from 'enums/menu';
import { ICartEntryFragment } from 'generated/rbi-graphql';
import useAddToCartRecentItem from 'hooks/use-add-to-cart-recent-item';
import {
  FormatRecentItemsCaloriesArgs,
  useRecentItemsCalories,
} from 'hooks/use-recent-items-calories/use-recent-items-calories';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ItemAvailability } from 'state/menu-options';
import { useOrderContext } from 'state/order';
import { useMapToFrontendCartEntry } from 'state/order/hooks/use-map-to-frontend-cart-entry';
import { getMenuItemDayParts } from 'utils/availability';
import { parseStringifiedJSON } from 'utils/parse-string';

interface RecentItemsCarouselItemProps {
  cartEntry: IBackendCartEntries | ICartEntryFragment | null;
  onItemAdded: () => void;
}

const RecentItemsCarouselItem = React.memo<RecentItemsCarouselItemProps>(
  ({ cartEntry, onItemAdded }) => {
    const { formatMessage } = useIntl();
    const [frontendEntry, setFrontendEntry] = useState<ICartEntry | null>(null);
    const [itemAvailability, setItemAvailability] = useState<ItemAvailability>({
      availabilityStatus: ItemAvailabilityStatus.UNAVAILABLE,
      data: undefined,
    });
    const [checkingAvailability, setCheckingAvailability] = useState(false);
    const { addToCart, SwitchCartMethodDialog } = useAddToCartRecentItem({
      cartEntry: frontendEntry,
    });
    const mapToFrontendEntry = useMapToFrontendCartEntry();
    const orderContext = useOrderContext();

    const enableDisplayCalories = useFlag(LaunchDarklyFlag.ENABLE_FAVORITES_DISPLAY_CALORIES);
    const formatRecentItemsCalories = useRecentItemsCalories();

    useEffect(() => {
      const handleEffect = async () => {
        setCheckingAvailability(true);
        const {
          cartEntry: mappedEntry,
          status,
          data,
        } = await mapToFrontendEntry(cartEntry as IBackendCartEntries, orderContext?.cartEntries);
        setItemAvailability({ availabilityStatus: status, data: data ?? undefined });
        setFrontendEntry(mappedEntry);
        setCheckingAvailability(false);
      };
      handleEffect();
      //eslint-disable-next-line
    }, [cartEntry, mapToFrontendEntry, JSON.stringify(orderContext?.cartEntries)]);

    if (!cartEntry || itemAvailability?.availabilityStatus === ItemAvailabilityStatus.OUT_OF_MENU) {
      return null;
    }

    const dayParts = itemAvailability.data ? getMenuItemDayParts(itemAvailability.data) : [];

    const availabilityStatus = itemAvailability.availabilityStatus;
    const message = getAvailabilityStatusMessage({ availabilityStatus, dayParts, formatMessage });

    const isAvailable = [
      ItemAvailabilityStatus.AVAILABLE,
      ItemAvailabilityStatus.STORE_NOT_SELECTED,
    ].includes(availabilityStatus);

    const loading = !frontendEntry;

    const handleAddToCart = () => {
      addToCart();
      onItemAdded();
    };

    const calories = enableDisplayCalories
      ? formatRecentItemsCalories({
          menuItem: itemAvailability.data as FormatRecentItemsCaloriesArgs['menuItem'],
          frontendEntry,
          quantity: cartEntry.quantity,
        })
      : undefined;

    return (
      <>
        {loading ? (
          <ResponsiveCarouselSkeletonItem />
        ) : (
          <VerticalCarouselItem
            header={frontendEntry?.name || ''}
            image={
              <Picture
                objectFitContain
                image={parseStringifiedJSON({ value: cartEntry.image ?? '' })}
                alt=""
                width="126px"
                alignSelf="center"
              />
            }
            variant="item"
            calories={calories}
            testID="product"
            unavailable={!isAvailable}
            unavailableMessage={message}
            borderWidth="1"
            cta={
              <Button
                variant="outline"
                size="sm"
                onPress={handleAddToCart}
                testID="recent-item-add-btn"
                disabled={!isAvailable || checkingAvailability}
              >
                {formatMessage({ id: 'addToCart' })}
              </Button>
            }
          />
        )}

        <SwitchCartMethodDialog />
      </>
    );
  }
);

export default RecentItemsCarouselItem;
