import { IntlFormatters } from 'react-intl';

export const getNativePaymentDisclaimer = (
  allowApplePay: boolean,
  allowGooglePay: boolean,
  formatMessage: IntlFormatters['formatMessage']
): string | undefined => {
  let nativePaymentDisclaimerId;
  if (!allowApplePay && !allowGooglePay) {
    nativePaymentDisclaimerId = formatMessage({ id: 'nativePaymentNotAvailable' });
  } else if (allowApplePay && !allowGooglePay) {
    nativePaymentDisclaimerId = formatMessage({ id: 'googlePayPaymentNotAvailable' });
  } else if (!allowApplePay && allowGooglePay) {
    nativePaymentDisclaimerId = formatMessage({ id: 'applePayPaymentNotAvailable' });
  }
  return nativePaymentDisclaimerId;
};
