import {
  ClickEventComponentNames,
  CustomEventNames,
  EventTypes,
  useCRMEventsContext,
} from 'state/crm-events';

const REORDER_INTERACTIVE_ELEMENTS = {
  HOMEPAGE_CTA: 'Homepage Reorder CTA',
  HOMEPAGE_CARD: 'Homepage Reorder Card',
  RECENT_ORDER_CTA: 'Recent Order CTA',
  RECENT_ORDER_VIEW_DETAILS: 'Recent Order View Details',
};

export const useReorderLogs = () => {
  const { logRBIEvent } = useCRMEventsContext();

  const logReorderClickEvent = (elementName: string) => {
    logRBIEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.BUTTON,
        text: elementName,
      },
    });
  };

  const logReorderHomepageCtaPress = () => {
    logReorderClickEvent(REORDER_INTERACTIVE_ELEMENTS.HOMEPAGE_CTA);
  };

  const logReorderHomepageCardPress = () => {
    logReorderClickEvent(REORDER_INTERACTIVE_ELEMENTS.HOMEPAGE_CARD);
  };

  const logReorderRecentsCtaPress = () => {
    logReorderClickEvent(REORDER_INTERACTIVE_ELEMENTS.RECENT_ORDER_CTA);
  };

  const logReorderRecentsViewDetailsPress = () => {
    logReorderClickEvent(REORDER_INTERACTIVE_ELEMENTS.RECENT_ORDER_VIEW_DETAILS);
  };

  return {
    logReorderHomepageCtaPress,
    logReorderHomepageCardPress,
    logReorderRecentsCtaPress,
    logReorderRecentsViewDetailsPress,
  };
};
