import { memo } from 'react';
import { GestureResponderEvent, StyleSheet, View } from 'react-native';

import {
  ActionSheet,
  ActionSheetRow,
  IconAdd,
  IconDelivery,
  IconStore,
  ScrollView,
  tokens,
} from '@fhs/ui';

type LocationOption = {
  isDelivery: boolean;
  storeName: string;
  storeAddress: string;
  onPress: (event: GestureResponderEvent) => void;
};

type OtherOption = {
  label: string;
  onPress: (event: GestureResponderEvent) => void;
};

type ConfirmLocationActionSheetProps = {
  option1: LocationOption;
  option2: LocationOption;
  option3: OtherOption;
  option4: OtherOption;
  isVisible: boolean;
  onClose: () => void;
};

function _ConfirmLocationActionSheet({
  isVisible = false,
  option1,
  option2,
  option3,
  option4,
  onClose,
}: ConfirmLocationActionSheetProps) {
  const title = 'Select Your Location';

  return (
    <ActionSheet
      isVisible={isVisible}
      headerTitle={title}
      onClose={onClose}
      grabber={<View style={styles.grabber} />}
    >
      <ScrollView>
        <ActionSheetRow
          type="radio"
          icon={option1.isDelivery ? <IconDelivery size={24} /> : <IconStore size={24} />}
          onPress={option1.onPress}
        >
          <ActionSheetRow.Item firstLine={option1.storeAddress} secondLine={option1.storeName} />
        </ActionSheetRow>
        <ActionSheetRow
          type="radio"
          icon={option2.isDelivery ? <IconDelivery size={24} /> : <IconStore size={24} />}
          onPress={option2.onPress}
        >
          <ActionSheetRow.Item firstLine={option2.storeAddress} secondLine={option2.storeName} />
        </ActionSheetRow>
        <ActionSheetRow type="radio" icon={<IconAdd size={24} />} onPress={option3.onPress}>
          <ActionSheetRow.Item firstLine={option3.label} />
        </ActionSheetRow>
        <ActionSheetRow type="radio" icon={<IconAdd size={24} />} onPress={option4.onPress}>
          <ActionSheetRow.Item firstLine={option4.label} />
        </ActionSheetRow>
      </ScrollView>
    </ActionSheet>
  );
}

export const ConfirmLocationActionSheet = memo(_ConfirmLocationActionSheet);

const styles = StyleSheet.create({
  grabber: {
    width: 40,
    height: 4,
    borderRadius: 100,
    backgroundColor: tokens.colors.$black10,
    alignSelf: 'center',
  },
});
