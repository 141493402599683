export enum DateSingletonInstanceKey {
  cateringConfirmation,
  storeClosedConfirmation,
}

/**
 * This is a Singleton instance that maintains a record of the most recent date/time
 * for a given key. Useful to store the datetime when some ActionSheet was shown to the user.
 * Date expiration is set in 1 hour, use should call ```saveDate``` if date expired is true.
 */
class DateSingleton {
  private static instances = {} as Record<DateSingletonInstanceKey, DateSingleton>;

  private currentDate: number | null = null;

  private constructor() {}

  static getInstance(key: DateSingletonInstanceKey): DateSingleton {
    const instance = DateSingleton.instances[key];
    if (!instance) {
      DateSingleton.instances[key] = new DateSingleton();
    }
    return DateSingleton.instances[key];
  }

  saveDate = (): void => {
    this.currentDate = Date.now();
  };

  getDate = (): number | null => {
    return this.currentDate;
  };

  isDateExpired = (): boolean => {
    if (!this.currentDate) {
      return true;
    }
    const oneHourAgo = Date.now() - 60 * 60 * 1000;
    return this.currentDate < oneHourAgo;
  };
}

export default DateSingleton;
