import { router } from 'expo-router';
import { useMemo } from 'react';

import { Text, createMqStyles } from '@fhs/ui';
import { InlineAlert } from '@fhs-legacy/universal-components';

import { useLoyaltyLegacyStates } from '../../hooks/use-loyalty-legacy-states';
import { IncentiveEvaluationErrorCodes } from '../../types';

export function ErrorRenderer({ error }: { error: IncentiveEvaluationErrorCodes }) {
  const styles = useMqStyles();

  const { serviceMode } = useLoyaltyLegacyStates();

  const { actionText, changeServiceModePath, description } = useMemo(() => {
    switch (error) {
      case IncentiveEvaluationErrorCodes.INVALID_SERVICE_MODE: {
        const SERVICE_MODE_ERROR_CONFIG = {
          TAKEOUT: {
            description:
              "This offer isn't available for pickup, please switch to delivery to use it.",
            actionText: 'Switch to Delivery',
            changeServiceModePath: '/store-locator/address',
          },
          DELIVERY: {
            description:
              "This offer isn't available for delivery, please switch to pickup to use it.",
            actionText: 'Switch to Pickup',
            changeServiceModePath: '/store-locator/service-mode',
          },
          default: {
            description:
              "This offer isn't available for the current service mode. Please try a different option.",
            actionText: 'Change Service Mode',
            changeServiceModePath: '/store-locator/service-mode',
          },
        } as const;
        return (
          SERVICE_MODE_ERROR_CONFIG[serviceMode as keyof typeof SERVICE_MODE_ERROR_CONFIG] ??
          SERVICE_MODE_ERROR_CONFIG.default
        );
      }

      case IncentiveEvaluationErrorCodes.INVALID_STORE_ID: {
        return {
          description:
            "This offer isn't available at the currently selected restaurant. Please choose a different location to use it.",
          actionText: 'Change Restaurant',
          changeServiceModePath: '/store-locator/service-mode',
        };
      }

      default: {
        return {
          description: "This offer isn't available right now. Please try again later.",
          actionText: '',
          changeServiceModePath: '',
        };
      }
    }
  }, [error, serviceMode]);

  return (
    <InlineAlert
      iconCentered
      status="info"
      borderRadius={8}
      style={{ marginHorizontal: 16 }}
      message={
        <Text.Ui style={[styles.inlineText]} size="md">
          {description}{' '}
          {actionText && (
            <Text.Ui
              size="md"
              style={[styles.textHighlighted]}
              onPress={() => {
                router.dismiss();
                router.navigate(changeServiceModePath);
              }}
            >
              {actionText}
            </Text.Ui>
          )}
        </Text.Ui>
      }
    />
  );
}

const useMqStyles = createMqStyles({
  inlineText: {
    $base: {
      fontSize: 12,
      flex: 1,
    },
    $gteDesktop: {
      fontSize: 14,
    },
  },
  textHighlighted: {
    $base: {
      textDecorationLine: 'underline',
      fontSize: 12,
    },
    $gteDesktop: {
      fontSize: 14,
    },
  },
});
