import { Link, Stack } from 'expo-router';
import React from 'react';
import { useIntl } from 'react-intl';

import { ScreenContent } from '@fhs/screen-content';
import {
  HeaderFrame,
  HeaderIconButton,
  HeaderTitleText,
  IconClose,
  IconList,
  IconMap,
} from '@fhs/ui';
import { FormControl, View } from '@fhs-legacy/universal-components';
import Footer from 'components/footer';
import { StoreChangeDisclaimer } from 'pages/store-locator/store-change-disclaimer';

import { SearchBar } from '../search-bar';
import { StoreLocatorViews } from '../types';

import { useAutoPromptLocationPermission } from './hooks/use-auto-prompt-location-permission';
import { ListView } from './list-view';
import { MapView } from './map-view';
import { useStoreLocatorContext } from './store-locator-provider';
import { MaxWidthFullHeight, SearchContainer, TopSelectionContainer } from './store-locator.styled';

export const StoreLocatorView: React.FC<{}> = () => {
  const { formatMessage } = useIntl();

  const { ErrorDialog, Dialog, currentView, updateStoreLocatorView } = useStoreLocatorContext();

  // Automatically open native dialog asking for location permission if not already answered
  useAutoPromptLocationPermission();

  return (
    <>
      <Stack.Screen
        options={{
          header: ({ navigation }) => (
            <HeaderFrame>
              <HeaderFrame.Left>
                <Link href={navigation.canGoBack() ? '../' : '/'} asChild>
                  <HeaderIconButton aria-label="Go back">
                    <IconClose />
                  </HeaderIconButton>
                </Link>
              </HeaderFrame.Left>
              <HeaderFrame.Middle>
                <HeaderTitleText text="Locations" />
              </HeaderFrame.Middle>
              <HeaderFrame.Right>
                <HeaderIconButton
                  aria-label={`Change to ${
                    currentView === StoreLocatorViews.LIST_VIEW ? 'map' : 'list'
                  } view`}
                  onPress={() =>
                    updateStoreLocatorView(
                      currentView === StoreLocatorViews.LIST_VIEW
                        ? StoreLocatorViews.MAP_VIEW
                        : StoreLocatorViews.LIST_VIEW
                    )
                  }
                >
                  {currentView === StoreLocatorViews.LIST_VIEW ? <IconMap /> : <IconList />}
                </HeaderIconButton>
              </HeaderFrame.Right>
            </HeaderFrame>
          ),
        }}
      />
      <ScreenContent after={<Footer />}>
        <View testID="store-locator-wrapper" flexGrow={1} height="full">
          <TopSelectionContainer>
            <SearchContainer>
              <FormControl paddingX="$4" mb="$0" mt="$2">
                <FormControl.Label>
                  {formatMessage({ id: 'enterYourDeliveryAddress' })}
                </FormControl.Label>
                <SearchBar />
              </FormControl>
            </SearchContainer>
          </TopSelectionContainer>

          <View>
            <StoreChangeDisclaimer marginX="$4" marginTop="$2" marginBottom="$4" />
          </View>
          {currentView === StoreLocatorViews.LIST_VIEW ? (
            <ListView />
          ) : (
            <MaxWidthFullHeight>
              <MapView />
            </MaxWidthFullHeight>
          )}
        </View>
        <ErrorDialog />
        <Dialog />
      </ScreenContent>
    </>
  );
};
