import { HStack, Pressable, Text } from '@fhs-legacy/universal-components';

export interface IMethodContainer {
  disableHover?: boolean;
  disableMethod?: boolean;
  hideBorder?: boolean;
  fromCheckout?: boolean;
  $isClickable?: boolean;
}
export const MethodContainer = HStack.withConfig<IMethodContainer>({
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '$13.5',
  width: 'full',
  padding: '$2',
});

export const MethodTypeWrapper = Pressable.withConfig<IMethodContainer>({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexGrow: 1,
  flexShrink: 1,
});

export const MethodTypeInnerWrapper = HStack.withConfig<IMethodContainer>({
  flex: 1,
  flexWrap: 'nowrap',
  alignItems: 'center',
});

export const MethodType = Text;
