import React from 'react';

import { Text } from '@fhs-legacy/universal-components';
import Link from 'components/link';
import { useAnchorContext } from 'utils/static-page/hooks/useAnchorContext';

import { SanityBlockContentLinkProps } from './types';

/**
 * This is a useful override for removing unwanted component hierarchy.
 * E.g. serializers.marks.em by default adds an <em /> wrapper around text content.
 * If you want to only render the container component and text, override
 * the serializers where serializers.marks.em = PassthroughComponent
 */
export const PassthroughComponent: React.FC<React.PropsWithChildren<unknown>> = props => (
  <>{props.children}</>
);

/**
 * This function returns a formated sanity link
 */
export const FormatSanityLink = ({
  children,
  mark,
  linkProps,
  ...rest
}: SanityBlockContentLinkProps) => {
  const href = mark?.href ?? '';
  const anchorContext = useAnchorContext();
  const isAnchorLink = href.startsWith('#');

  return (
    <Link
      to={href}
      {...rest}
      testID="sanity-link"
      isInline
      {...(isAnchorLink
        ? {
            onPress: () => anchorContext?.scrollToTarget(href.replace(/#/g, ''), true),
          }
        : null)}
    >
      <Text textDecorationLine="none" ellipsizeMode="tail" numberOfLines={1} {...linkProps}>
        {children}
      </Text>
    </Link>
  );
};
