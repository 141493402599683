import React, { FC } from 'react';

import { CustomRadio, RadioWrapper } from './transaction-date-input-form.styled';
import { IRadioOptionProps } from './types';

const RadioOption: FC<React.PropsWithChildren<IRadioOptionProps>> = ({ value, label }) => (
  <RadioWrapper>
    <CustomRadio testID="radio-option" value={value}>
      {label}
    </CustomRadio>
  </RadioWrapper>
);

export default RadioOption;
