import { Svg, SvgPath, type SvgProps, registerSVG } from '../../../src/components/svg';

export function Next(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgPath d="M7.5 20.23L9.30459 22L19.5 12L9.30459 2L7.5 3.77L15.8908 12L7.5 20.23Z" />
    </Svg>
  );
}

registerSVG('Next', Next);
