import * as React from 'react';
import { FC, memo } from 'react';

import Picture from 'components/picture';

import { ILoyaltyRewardsViewProps } from '../../loyalty-rewards/types';
import { incentiveCardImageDefaultProps } from '../constants';
import { ParsedRewardData } from '../incentive-details/types';

import {
  BackgroundImage,
  CardContainer,
  ImageWrapper,
  InfoContainer,
  RewardText,
  StyledClickableContainer,
} from './incentive-card.styled';

export interface IIncentiveCard {
  incentiveData: ParsedRewardData;
  handlePress: ILoyaltyRewardsViewProps['handleOnPress'];
  children?: React.ReactNode;
  locked?: boolean;
  hasMessage?: boolean;
}

export const IncentiveCard: FC<React.PropsWithChildren<IIncentiveCard>> = memo(
  ({ incentiveData, handlePress, children, hasMessage = false }) => {
    const { image, imageDescription, name, id, loyaltyEngineId, backgroundImage } = incentiveData;

    // Offers will use the EngineId as the URL identifier because the SanityId might be the same for multiple PersonalizedOffers.
    // Rewards will use the SanityId until we are sure we don't have any deep links to specific Rewards.
    const incentiveSanityId = id;
    const incentiveId = loyaltyEngineId;
    const incentiveName = name;

    return (
      <CardContainer>
        <StyledClickableContainer
          onPress={() => handlePress(incentiveId, incentiveName, incentiveSanityId)}
        >
          {image && (
            <ImageWrapper>
              {backgroundImage && <BackgroundImage alt={name} image={backgroundImage} />}
              <Picture image={image} alt={imageDescription} {...incentiveCardImageDefaultProps} />
            </ImageWrapper>
          )}
          <InfoContainer>
            <RewardText $hasMessage={hasMessage}>{name}</RewardText>
            {children}
          </InfoContainer>
        </StyledClickableContainer>
      </CardContainer>
    );
  }
);
