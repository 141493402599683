{
  "about": "À propos",
  "aboutTheApp": "À propos de l’appli",
  "above": "ci-dessus",
  "accept": "Accepter",
  "acceptAllCookies": "Accepter tous les témoins",
  "acceptedCateringOrder": "Ayez cette commande prête pour {serviceMode} à {time}, {date}",
  "acceptEmailsAndSpecialOffers.bk": "Je souhaite recevoir par courriel des informations et des offres spéciales de Burger King",
  "acceptEmailsAndSpecialOffers.fhs": "Je souhaite recevoir par courriel des informations et des offres spéciales de Firehouse Subs",
  "acceptEmailsAndSpecialOffers": "Je souhaite recevoir des informations et des offres spéciales par courriel",
  "acceptEmailsAndSpecialOffersPopeyes": "Oui! Je souhaite recevoir des courriels promotionnels concernant des offres spéciales et d’autres renseignements sur Popeyes®. (Optionnel)",
  "acceptEmailsAndSpecialOffersPopeyesRewards": "Oui ! Je veux recevoir les e-mails Popeyes® Perks pour avoir accès aux offres exclusives et être le premier au courant des dernières promotions Popeyes.",
  "acceptGiftError": "Veuillez réessayer plus tard ou communiquez avec le service de soutien à {supportLink}, ou par téléphone au 1-888-601-1616.",
  "acceptGiftSuccess": "{fullName} vous a envoyé le cadeau suivant : {gift}. Le cadeau a été ajouté à votre compte et apparaîtra avec les autres offres. N’oubliez pas de l’activer.",
  "acceptGiftSuccessHeading": "Vous avez reçu un cadeau!",
  "acceptOrDecline": "Accepter ou Rejeter cette commande",
  "acceptOrder": "Accepter la commande",
  "acceptOrderModal": "Ayez cette commande prête pour {serviceMode} à {time} le {date}. Vous ne serez pas en mesure de rejeter une commande après l’avoir acceptée ici.",
  "acceptPrivacyPolicyAndTerms": "J’accepte ce qui suit : {privacyPolicyLink} et {termsOfServiceLink}",
  "acceptRollsCongrats": "Félicitations!",
  "acceptRollsModalHeader": "Vous avez reçu {numberOfRollsShared, plural, one {un} other {{numberOfRollsShared}}} REBORD{numberOfRollsShared, plural, one {} other {S}}!",
  "acceptTermsAndPrivacyIntro": "J’accepte la: {privacyPolicyLink}, {rewardsPolicyLink}, {termsOfServiceLink}",
  "acceptTermsAndPrivacyIntro.fhs": "J’accepte la: {privacyPolicyLink}, {rewardsPolicyLink}, {termsOfServiceLink}",
  "accessibility": "Accessibilité",
  "accessibilityMode": "Mode d’accessibilité",
  "account": "Compte",
  "accountDefault": "défaut",
  "accountDetails": "Détails du compte",
  "accountInfo": "Infos sur le compte",
  "accountWithEmailAlreadyExists": "Un compte avec cette adresse courriel existe déjà ",
  "activate": "Activer",
  "activated": "Activée",
  "activateOffer": "Activer l’offre",
  "activationFailed": "Activation échouée",
  "add": "Ajouter",
  "addCreditCard": "Ajouter  une Carte de crédit",
  "addCreditCardVerification": "Ajoutez le code de vérification de la carte de crédit",
  "addExtras": "Ajouter quelque chose",
  "addFavorite": "Ajoutez aux favoris",
  "addFromCheckout": "Ajoutez au panier d’achats",
  "addItem": "Ajoutez un produit",
  "addItemLabel": "Ajouter 1 {itemName} à votre panier",
  "addItems": "Ajouter des produits",
  "addItemsLowerCase": "Ajoutez des produits",
  "addItemToCart": "Ajouter {name} au panier",
  "addItemToCartWithModifiers": "Ajouter {name} avec  {modifiers} au panier",
  "additionalAddressInformation": "Jusqu’à peut être renseigné au moment du paiement",
  "additionalInfoAdditives": "Additifs",
  "additionalInfoIngredients": "Ingrédients",
  "additionalInfoProducerDetails": "Détails du producteur",
  "additionalInfoTitle": "Renseignements supplémentaires sur le produit",
  "additionalInstructions": "Directives additionnelles",
  "addMobileOrder": "Ajouter à la commande mobile",
  "addNewCard": "Ajouter une nouvelle carte",
  "addNewCreditCard": "Ajouter une nouvelle carte de crédit",
  "addNewPaymentMethod": "Ajouter un mode de paiement",
  "addNewPaymentMethodGiftCard.bk": "Ajouter une Carte royale",
  "addNewPaymentMethodGiftCard": "Ajoutez carte BK Crown",
  "addOne": "Ajouter un",
  "addOneItemToCart": "Ajouter 1 {item name} to cart",
  "addOneName": "Ajouter un {name}",
  "addPaymentMethod": "Ajouter carte",
  "addPaymentMethodOrReload": "Ajouter une nouvelle carte",
  "addPhoneNumber": "Ajouter un numéro de téléphone",
  "addPrepaidCard": "Ajoutez une carte-cadeau",
  "addQuantityAndItemToCart": "Ajouter {quantity} {itemName} au panier",
  "addQuantityAndItemWithPrice": "Ajouter {quantity} {itemName} à la commande pour {price}",
  "addReloadMethod": "Ajouter une méthode de réapprovisionnement",
  "addressError": "Oups! Adresse non reconnue",
  "addressFieldIsRequired": "Vous devez entrer une adresse",
  "addressRequiredError": "Vous devez entrer une adresse",
  "addReward": "Ajoutez une récompense",
  "addSomethingExtra": "Ajoutez un produit pour compléter votre commande",
  "addSpecificInstructions": "Ajoutez toute information supplémentaire ici",
  "addTag": "Ajouter une étiquette",
  "addToAppleWalletAlt": "Ajouter à Apple Wallet",
  "addToCart": "Ajouter",
  "addToCartError": "Impossible d’ajouter {itemName} au panier. Veuillez réessayer.",
  "addToCartMultipleSuccess": "{itemName} et {quantity} ajoutés au panier",
  "addToCartSuccess": "{itemName} ajouté au panier.",
  "addToGooglePayAlt": "Ajouter à Google Pay",
  "addToInRestaurantRedemptionCartSuccess": "{type} sauvegardée pour réclamer en restaurant.",
  "addToOrder": "Ajouter à la commande",
  "addToOrderHeader": "Ajouter à la commande",
  "adjustQuantityOfItem": "Ajuster la quantité de {item name}",
  "afterDiscount": "Après la réduction",
  "afternoon": "Après-midi",
  "afterSevenPurchases": "Après chaque série de sept achats, vous pouvez obtenir votre récompense.",
  "agreeToTermsOfService": "J’accepte les modalités d’utilisation",
  "agreeToTermsOfServiceError": "Vous devez accepter la politique de confidentialité et les conditions d’utilisation avant de vous inscrire.",
  "agreeToTheFollowing": "J’accepte ce qui suit :",
  "allergenInformation": "Renseignements sur les allergènes",
  "allergensDisclaimer": "Ce produit {allergenContents}. Les modifications ne sont pas reflétées dans cette liste.",
  "allergensDisclaimerContains": "Ce produit contient {allergens}. Les modifications ne figurent pas dans cette liste.",
  "allergensDisclaimerContainsAndMayBeCookedOn": "Ce produit contient {allergens} et être préparé sur les mêmes appareils que {maybeCookedOnAllergens}. Les modifications ne sont pas représentées dans cette liste.",
  "allergensDisclaimerContainsAndMayContain": "Cet article contient {allergens} et peut contenir des traces de {maybeAllergens}. Les modifications ne figurent pas dans cette liste.",
  "allergensDisclaimerContainsAndMayContainAndMayBeCooked": "Cet article contient {allergens}, peut contenir des traces de {maybeAllergens} et être préparé sur les mêmes appareils que {maybeCookedOnAllergens}. Les modifications ne figurent pas dans cette liste.",
  "allergensDisclaimerMayBeCookedOn": "Ce produit peut être cuit sur l’équipement utilisé pour cuire {maybeCookedOnAllergens}. Les modifications ne sont pas indiquées sur cette liste.",
  "allergensDisclaimerMayContain": "Ce produit peut contenir {maybeAllergens}. Les modifications ne sont pas reflétées dans cette liste.",
  "allergensDisclaimerMayContainAndMayBeCookedOn": "Ce produit peut contenir {maybeAllergens} et peut être cuit sur l’équipement utilisé pour cuire {maybeCookedOnAllergens}. Les modifications ne sont pas indiquées sur cette liste.",
  "allergensDisclaimerUnknown": "Ce produit peut contenir des allergènes",
  "allergensDownloadPDF": "Allergènes",
  "allergensViewInBrowser": "Allergènes - Consulter dans le navigateur",
  "allergicTo": "Je suis allergique à",
  "allMenu": "Menu Tout",
  "allowLocationServices": "Autoriser les services de localisation",
  "allowLocationServicesToFind": "Autoriser les services de localisation à trouver",
  "allRightsReserved": "Tous droits réservés.",
  "allSetCustomer": "C’est tout, {firstName}!",
  "alreadyHaveAnAccount": "Vous avez déjà un compte?",
  "alreadyHaveAPhysicalCard": "Vous avez déjà une carte physique?",
  "alreadyLinkedACard": "Avez-vous déjà associé une carte?",
  "amount": "quantité",
  "amountMore": "+{amountMore} autre(s)",
  "and": "et",
  "andConjunction": "{first} et {second}",
  "answer": "Réponse",
  "anyQuestionsHeading": "Des questions?",
  "apartment": "Appartement",
  "appflowCompleted": "Mise à jour terminée!",
  "appflowDownloading": "Téléchargement de la mise à jour.",
  "appflowError": "Un problème est survenu lors du téléchargement de la mise à jour.",
  "appflowExtracting": "Extraction de la mise à jour.",
  "appflowProgress": "{progress} terminé.",
  "appImage": "Image de l’appli",
  "applePay": "Paiement Apple",
  "applePayPaymentNotAvailable": "Nous n’acceptons pas Apple Pay actuellement sur nos sites Web. Sélectionnez ou ajoutez un nouveau mode de paiement.",
  "apply": "Appliquer",
  "applyAReward": "Utiliser une récompense",
  "applyPromoCode": "Appliquez le code promotionnel",
  "approve": "Confirmer",
  "approxMinutesAway": "(Dans 5 minutes environ)",
  "appUpdateRecommended": "Mise à jour de l’appli recommandée",
  "appUpdateSubtext": "Nous vous recommandons d’installer la plus récente version de notre appli avant de passer votre commande. Voulez-vous mettre l’appli à jour maintenant?",
  "april": "Avril",
  "aptOrSuite": "Appartement ou bureau",
  "areDeliveryDetailsValid": "Nous ne sommes pas en mesure de confirmer votre numéro de téléphone. Veuillez confirmer que votre numéro de téléphone est correct ou en essayer un autre.",
  "asap": "Dès que possible",
  "asapOrder": "Commande URGENTE : {serviceMode}",
  "at": "au",
  "atARestaurant": "À un restaurant",
  "atRestaurantDetails": "Cela inclut les commandes à emporter, à manger en restaurant, et plus encore",
  "august": "Août",
  "authContinueSubHeader": "Voulez-vous continuer dans l’appli ou sur le site Web?",
  "authContinueWeb": "Continuer sur le site Web",
  "authEmailCheckYourEmail": "Vérifiez votre boîte de courriel",
  "authEmailSentEmail": "Nous avons envoyé un courriel contenant des instructions de connexion à",
  "authEmailTimeToReceive": "Le courriel peut prendre jusqu’à 3 minutes avant d’arriver dans votre boîte.{separator}Cliquez sur le lien fourni dans le message pour ouvrir une session.",
  "authEnterCode": "Entrez Le Code De Vérification Ci-Dessous:",
  "authError": "Une erreur s’est produite lors de la connexion",
  "authExpiredLink": "Votre lien est peut-être expiré ou vous utilisez un navigateur différent. Demandez un nouveau lien en appuyant sur le bouton «{sendNewLinkText}» ci-dessous.",
  "authMissingLink": "Nous sommes désolés. Nous ne trouvons pas ce lien.",
  "authNewLink": "Envoyer un nouveau lien",
  "authPhoneSentSms": "Nous avons envoyé un message texte contenant des directives de connexion à",
  "authRegionErrorDetails": "Votre compte a été enregistré dans une région différente. Vous devez créer un nouveau compte dans cette région pour avoir la meilleure expérience possible lorsque vous utilisez cette application. Vous serez maintenant automatiquement déconnecté.",
  "authRegionErrorTitle": "Erreur de région de compte",
  "authRetryError": "Une erreur s’est produite. Appuyez ci-dessous pour réessayer.",
  "authVerifyWithCode": "Vérifiez avec un code",
  "autoReload": "Réapprovisionnement automatique",
  "autoReloadDefaultAmount": "Utilisez la carte {cardType} se terminant par {alias} pour la réapprovisionner de ce montant :",
  "autoReloadPleaseSelectAFundingSource": "Veuillez sélectionner une source de fonds",
  "autoSignInSuccess": "Félicitations! Votre compte a été créé.",
  "available": "Disponible",
  "availableCrowns": "Couronnes disponibles",
  "availablePoints": "Points disponibles",
  "back": "Précedent",
  "backgroundImage": "Image d’arrière-plan",
  "backToEarn": "Retourner à Obtenir",
  "backToMenu": "Retourner au menu",
  "backToOffers": "Retourner aux offres",
  "backToSavedAddresses": "Retour aux adresses sauvegardées",
  "backToSummary": "Retournez à Sommaire",
  "backToTopics": "Retour aux sujets",
  "backwardButton": "Bouton Précédent",
  "bagEmpty": "Votre sac est vide",
  "balanceReloadAmount": "Montant du rechargement de solde",
  "balanceTransfer": "Transfer de solde",
  "bankingPoints": "Sauvegarder mes points",
  "bankMyRewards": "Sauvegarder mes points",
  "bankOrRedeemPoints": "Accumulez ou échangez des points",
  "bankYourPointsForLater": "Vous pouvez changer le niveau de récompenses en tout temps.",
  "beforeDiscount": "Avant la réduction",
  "below": "ci-dessous",
  "billingAddressIsSameAsDeliveryAddress": "L’adresse de facturation est la même que l’adresse de livraison.",
  "billingCountryIsARequiredField": "L’adresse de facturation est requise",
  "bk_aboutBK": "À propos de BK",
  "bk_allRightsReserved": "Tous droits réservés",
  "bk_bkApp": "Appli BK",
  "bk_bkCares": "BK se sent concerné",
  "bk_bkcInfo": "Info BKC",
  "bk_bkCrownsExpirationText": "Les Couronnes BK n’expirent jamais dans un compte actif. Maintenez le solde en passant une commande au moins une fois tous les 6 mois.",
  "bk_bkMcLAMOREFoundation": "Fondation BK McLAMORE",
  "bk_bkTrademarkAndCopyright": "TM et droits d’auteur 2021 Burger King Corporation.",
  "bk_bringItUpAtBKC": "Apportez-le @ BK",
  "bk_giftOrCrownCard": "Cartes cadeau/Carte Crown",
  "bk_logo_title": "Burger King",
  "bk_myBK": "Mon  BK",
  "bk_myBKRewards": "Mes récompenses BK®",
  "bk_pointsBalance": "Récompenses BK Crown :",
  "bk.payWithMyPrepaidCard": "Payez avec la carte BK Crown",
  "bk.pypestreamLoadingMessage": "Veuillez patienter un moment, de l’aide s’en vient.",
  "bk.routes.privacyPolicy": "/politique-confidentialite",
  "bk.routes.termsOfService": "/conditions-d-utilisation",
  "bkCareers": "Emplois BK",
  "bkKingdom": "BK Kingdom",
  "bkRewards": "Récompenses BK",
  "bonus": "Bonus",
  "bonusPoints": "{points} Points en prime {points, plural, one {} other {s}}",
  "bonusPointsIncentiveDescription": "Vous avez accumulé des {points} en prime en relevant ce défi!",
  "bonusPointsLabel": "Points en prime{points, plural, one {} other {s}}",
  "breakfastDisclaimer": "Produits offerts à la période du déjeuner seulement",
  "breakfastIsAvailableFromOpen": "Les déjeuners sont offerts de l’ouverture à - {openTime}",
  "breakfastMenu": "Menu déjeuner",
  "brewingNewDownload": "Nous infusons de nouveaux téléchargements",
  "browseOpportunities": "Voir les occasions ",
  "browseOurMenu": "Parcourir notre menu",
  "browserSettings": "Paramètres du navigateur",
  "bsmConfirmationModalBody": "Vous devrez choisir un nouveau mode de service et un nouveau restaurant, ou une nouvelle adresse de livraison.",
  "bsmConfirmationModalHeading": "Voulez-vous vraiment changer votre restaurant?",
  "button": "bouton",
  "byDefault": "Par défault",
  "cal": "cal",
  "callYourSelectedStore": "call your selected store",
  "calculatingEta": "Calcul de l’emplacement du livreur",
  "calories": "Calories",
  "cameraUnavailable": "La caméra n’est pas disponible, veuillez revoir vos paramètres",
  "canada": "Canada",
  "cancel": "Annuler",
  "cancelCateringClearCart": "Votre panier sera vidé si vous annulez votre commande du service de traiteur",
  "cancelCurbsideOrder": "Annulez la commande",
  "cancelCurbsideOrderText": "Il semble qu’une livraison au stationnement est déjà en cours.",
  "cancelCurbsideOrderTitle": "Annuler la livraison au stationnement?",
  "cancellationPolicy": "Aucun changement ne peut être apporté à votre commande après l’avoir passée.",
  "cancellationPolicyTitle": "Politique d’annulation",
  "cancelledOrderAction": "Nous sommes désolés, mais il semblerait que votre commande a été annulée et remboursée. Si vous voulez passer votre commande à nouveau, vous pouvez utiliser le bouton ci-dessous",
  "cancelledOrderTitle": "Oh non! Votre commande a été annulée.",
  "cancelOrder": "Annuler la commande",
  "cannotDeliveryTo": "Actuellement nous ne pouvons pas faire de livraison à ",
  "cannotPlaceOrder": "Nous n’avons pas pu passer votre commande. Fermez ce message pour trouver d’autres restaurants à proximité.",
  "cannotRemoveStoreFromFavorites": "Au moins un restaurant favori est requis afin d’optimiser l’expérience de commande.",
  "cannotRemoveStoreFromFavoritesHeader": "Impossible de retirer le restaurant des favoris",
  "carbohydrates": "Glucides",
  "carbs": "Glucides",
  "card": "CARTE",
  "cardAlias.bk": "Couronne",
  "cardAlias": "Cadeau",
  "cardDeactivationError": "Nous n’avons pas pu traiter votre demande. Veuillez réessayer ou appuyer sur {link} pour obtenir de l’aide.",
  "cardExpiration": "Date d’expiration de la carte",
  "cardNumber": "Numéro de carte",
  "cardNumberIsRequired": "Vous devez entrer le numéro de la carte",
  "cardNumberRequiredError": "Vous devez entrer le numéro de la carte",
  "cardSuccessfullyLinked": "La carte {cardId} a été liée à votre compte avec succès. Vos points s’afficheront dans votre compte dans quelques minutes.",
  "cardXHasABalanceOfY": "La carte {cardNumber} a un solde de {balance}.",
  "careers": "Carrières",
  "careersHome": "Accueil Emplois",
  "cart": "Panier",
  "cartLoyaltyPoints": "Vous avez {points} points",
  "cartOfferRequirementsNotMetError": "Les articles de votre panier ne répondent pas aux exigences de cette offre",
  "cartPromotionOfferRequirementsNotMetError": "Votre commande ne satisfait pas aux exigences de cette promotion.",
  "cartTotal": "Total du panier",
  "cartTotalMaxAlert": "We only accept orders up to {maxAmount} in our app. For larger orders, please {suggestion}",
  "cash": "Argent",
  "cashPayementsAcceptedAtTheCounter": "Les paiements en argent seront effectués au comptoir",
  "catering": "Service de traiteur",
  "cateringChangeServiceModeDescription": "Si vous quittez le mode de service de traiteur, votre panier sera réinitialisé. Voulez-vous quand même changer de mode de service?",
  "cateringDashDelivery": "Restauration - Livraison",
  "cateringDashPickUp": "Restauration - Cueillette",
  "cateringDelivery": "Livraison pour les commandes de traiteur",
  "cateringDeliveryUnavailable": "Livraison pour les commandes de traiteur – Non disponible",
  "cateringMinimumNotReached": "Désolé! La commande minimum pour le service de traiteur est de {min}.",
  "cateringOrder": "Commande de traiteur",
  "cateringOrderPendingApproval": "Votre commande est en attente d’approbation. Vous recevrez un courriel quand elle aura été acceptée.",
  "cateringOrdersDisclaimer": "Il faut 24 heures pour confirmer les commandes de repas. Votre carte sera débitée.",
  "cateringOrderTimeout": "La commande de repas a expiré",
  "cateringPickup": "Cueillette en restaurant",
  "cateringPickUp": "Service de traiteur - Pickup",
  "cateringPickUpAt": "Pickup de la commande du service de traiteur à",
  "ccNumberIsNotValid": "Le numéro de la carte n’est pas valide",
  "celery": "céleri",
  "challenge": "Contestation",
  "challengeCardPayment": "Contester un paiement par carte",
  "challengeComplete": "Défi complété",
  "challengeCompleted": "Défi complété",
  "challengeOrderPayment": "Contester le paiement d’une commande",
  "challenges": "Contestations",
  "challengeStarted": "Contestation commencée",
  "change": "Changer",
  "changeAddress": "Changer Adresse",
  "changeAddressAriaLabel": "Lien pour changer l’adresse de l’emplacement",
  "changeFundingSource": "{method} Source des fonds",
  "changeLanguage": "Sélectionner de Langue et Région",
  "changePaymentMethod": "Ce restaurant n’accepte pas ce type de carte. Changez votre mode de paiement et réessayez.",
  "changeRedemptionToInStore": "Souhaitez-vous vraiment changer à une réclamation en restaurant?",
  "changeRedemptionToInStoreConfirmLabel": "Changer à en restaurant",
  "changeRedemptionToInStoreDescription": "Changer à réclamation en restaurant videra votre panier. Souhaitez-vous vraiment procéder?",
  "changeRedemptionToPickUp": "Souhaitez-vous vraiment changer à commande mobile?",
  "changeRedemptionToPickUpConfirmLabel": "Changer à commande mobile",
  "changeRedemptionToPickUpDescription": "Changer à réclamation en restaurant à commande mobile effacera vos récompenses actuelles. Souhaitez-vous vraiment procéder?",
  "changeRegionConfirmation": "Veuillez confirmer votre région pour continuer.",
  "changeRegionDisclaimer": "Veuillez noter que vous devrez sélectionner votre restaurant à nouveau.",
  "changeRegionOnOrder": "Nous devons mettre à jour votre pays pour que vous puissiez commander de ce restaurant. Veuillez sélectionner la langue appropriée",
  "changeServiceMode": "Modifier le mode de service",
  "changeStoreAriaLabel": "Lien pour changer de restaurant",
  "changeStoreItemDisclaimer": "Les prix et la disponibilité des produits peuvent varier d’un restaurant à l’autre.",
  "changeStoreMessage": "Changer le restaurant pourrait entraîner des changements aux prix et produits disponibles. Voulez-vous poursuivre avec le changement de restaurant?",
  "changeStores": "Changer le restaurant",
  "changeStoreSelectedOfferAndItemDisclaimer": "L’offre que vous avez sélectionnée n’est pas disponible pour la {serviceMode}. Les prix et la disponibilité des produits peuvent varier d’un restaurant à l’autre.",
  "changeStoreSelectedOfferDisclaimer": "L’offre que vous avez sélectionnée n’est pas disponible pour la {serviceMode}.",
  "changeStoreSelectedOfferNotAvailableDisclaimer": "Oops! Il semble que l'offre que vous avez utilisée n'est pas disponible à l'emplacement que vous avez sélectionné.",
  "changingYourStoreDisclaimer": "Les changements de restaurants peuvent entraîner des différences dans les prix et la disponibilité des produits.",
  "chatWithSupport": "Clavardez avec le service de soutien",
  "chatWithUs": "clavarder avec nous",
  "checkAvailability": "Vérifier la disponibilité",
  "checkCardBalance": "Vérifier le solde de la carte",
  "checkForPricing": "Un moment s’il-vous-plaît! Nous vérifions auprès du restaurant.",
  "checkingDeliveryAvailability": "Nous vérifions la disponibilité du service de livraison.",
  "checkingDeliveryAvailabilityText": "Nous cherchons quel restaurant livrera à votre adresse. Cela pourrait prendre quelques instants.",
  "checkingPricesAvailability": "Vérifier les prix et la disponibilité",
  "checkNetworkSettings": "Veuillez vérifier vos paramètres réseau et réessayer",
  "checkout": "Caisse",
  "checkoutPaymentMethodNeedRevaultError": "Une erreur s’est produite lors du traitement de votre mode de paiement. Veuillez rajouter votre mode de paiement.",
  "cholesterol": "Cholestérol",
  "chooseADifferentPaymentMethodByPressingCancel": "Vous pouvez choisir un mode de paiement différent en appuyant sur le bouton Annuler sur le clavier NIP.",
  "chooseALocation": "Choisir un restaurant",
  "chooseAnOption": "Choisissez une option...",
  "chooseAnotherStore": "Choisissez un autre restaurant",
  "chooseATime": "Choisissez une heure",
  "chooseDifferentItem": "Veuillez sélectionner un autre produit.",
  "chooseDifferentOffer": "Veuillez sélectionner une autre offre.",
  "chooseDrink": "Ensuite, choisissez une boisson",
  "chooseItemAmount": "Choisissez {amount} {itemName}",
  "chooseLocation": "Choisir la localisation",
  "chooseMyRewardLevel": "Choisir mon niveau de récompense",
  "choosePaymentMethod": "Choisir la méthode de paiement",
  "choosePromotion": "Choisissez une promotion",
  "chooseSide": "Ensuite, choisissez un accompagnement",
  "chooseYourLocation": "Choisissez un emplacement",
  "chooseYourRewardLevel": "Choisissez votre niveau de récompenses",
  "city": "Ville",
  "cityRequiredError": "Vous devez entrer une ville",
  "claimPoints": "réclamer les points",
  "clearFilter": "effacer le filtre",
  "clearSearchBar": "Supprimer le contenu de la barre de recherche",
  "clearText": "Effacer le texte",
  "clickHere": "Cliquez ici",
  "clickToSelectACloserStore": "{clickHere} to select a closer store",
  "clickToSelectLocation": "Sélectionner le restaurant",
  "close": "Fermer",
  "closed": "Fermé",
  "closeDialog": "Fermer la fenêtre de dialogue",
  "closeMessageToFindNearbyRestaurants": "Fermez ce message pour trouver d’autres restaurants à proximité",
  "closeMessageToFindNearbyStores": "Revenez en arrière pour trouver des restaurants qui proposent la vente sur place (Click&Collect), la vente à emporter ou via le Drive.",
  "closesAt": "Ferme à {time}",
  "closeWindow": "Fermer la fenêtre",
  "codeErrorMessage": "Impossible de récupérer votre code.",
  "codeExpiredMessage": "Désolés, votre code promotionnel est expiré.",
  "codeExpiredMessageDetail": "Appuyez sur Actualiser pour en obtenir un nouveau.",
  "coffee": "Café",
  "combobox": "zone de trios",
  "comboOfferSavings": "Économies",
  "comeJoinTheTeam": "Joignez-vous à notre équipe",
  "comingSoon": "Bientôt disponible!",
  "comments": "Commentaires",
  "communicationPreferences": "Préférences de communication",
  "community": "Communauté",
  "complete": "Complet",
  "completed": "Terminé",
  "confettiRight": "Confettis à droite",
  "confirm": "confirmez",
  "confirmAllItemsAreMissing": "Confirmer qu’il manque tous les articles",
  "confirmAndOrder": "Confirmez et commandez",
  "confirmArrival": "Confirmer l’arrivée",
  "confirmationDifferentLocation": "Choisissez une autre adresse",
  "confirmationPageInstructions": "Félicitations, nous avons sauvegardé votre commande! Appuyez sur « Je suis ici » quand vous arrivez au restaurant",
  "confirmDeletePaymentMethod": "Êtes-vous sûr de vouloir supprimer ce mode de paiement?",
  "confirmDeletePaymentMethodDeletionUnlink": "Souhaitez-vous vraiment supprimer ce mode de paiement? Si oui, notez que cela entraînera aussi la suppression du lien lié à votre carte au compte fidélité et à vos avantages.",
  "confirmDialogBody": "Veuillez continuer si vous êtes prêt à réclamer au restaurant",
  "confirmDialogHeader": "Ce bon d’achat expire {br} dans 15 minutes",
  "confirmOnlySomeItemsAreMissing": "Confirmer qu’il ne manque que quelques articles",
  "confirmStore": "Confirmez le restaurant",
  "confirmYourPickupAddress": "Confirmez votre adresse de cueillette",
  "confirmYourStore": "Confirmez votre restaurant",
  "congratulations": "Félicitations",
  "connectCard": "Connecter la carte",
  "connectWithUs": "Connecte-toi avec nous",
  "contact": "Coordonnées",
  "contactCourier": "Vous pouvez contacter votre livreur au:",
  "contactRestaurantAt": "Veuillez contacter votre restaurant au:",
  "contactSupport": "Contacter Le Service D’Assistance",
  "contactUs": "Contactez-nous",
  "containsAllergens": "Contient: {allergens}",
  "containsAllergensDisclaimer": "Contient cet ou ces ingrédients : {allergens}",
  "containsSelectedOption": " contient le niveau sélectionné {rewardTier}",
  "contentinfo": "information sur le contenu",
  "continue": "Continuer",
  "continueChallenge": "Poursuivez la contestation",
  "continueInWeb": "Dirigez-moi vers le site Web et envoyez-moi un nouveau code",
  "continueOrder": "Continuez la commande",
  "continueOrdering": "Oui, poursuivre la commande",
  "continueQuest": "Poursuivez l’épreuve",
  "continueWithEmail": "Accès par courriel ",
  "continueWithOffer": "Continuer avec cette offre",
  "continueWithoutOffer": "Continuer sans l’offre",
  "cookieNotice": "Notice Cookie",
  "cookiePolicy": "En cliquant sur \"{acceptCookies}\", vous acceptez la sauvegarde de témoins sur votre appareil dans le but de rehausser la navigation sur le site et d’analyser l’utilisation du site, en plus de nous aider dans nos efforts de marketing.",
  "cookiePolicyiOS14": "En cliquant sur “Accepter les Cookies”, vous acceptez que les cookies soient sauvegardés sur votre appareil afin d’activer de nouvelles fonctionnalités et nous permettre un meilleur suivi de votre activité. Nous ne suivrons pas votre activité sans votre consentement.",
  "copy": "Copier",
  "couldNotLinkCard": "Nous ne pouvons pas lier votre carte",
  "couldNotLinkCardMessage": "Nous sommes désolés, nous ne sommes pas parvenus à lier votre carte. Veuillez vérifier si votre carte a déjà été liée à un autre compte. Sinon, vérifiez votre connexion et réessayez. Ou clavardez avec nous pour obtenir de l’aide.",
  "couldNotLocateStore": "Il est impossible de localiser le restaurant pour compléter votre commande. Veuillez réessayer plus tard.",
  "couldntProcessPayment": "Malheureusement, nous n’avons pas réussi à traiter votre paiement. Veuillez revoir les renseignements sur votre carte. Si tout est exact, essayez de soumettre votre commande à nouveau. ",
  "country": "Pays",
  "countryRequiredError": "Le pays est un champ requis.",
  "couponApplied": "Coupon Applied",
  "couponEntryComingSoon": "Bon d’achat à venir sous peu",
  "couponErrorBody": "Votre commande contient un bon qui n’est plus offert. Vous pouvez compléter votre commande, mais nous supprimerons le bon du panier.",
  "couponErrorConfirm": "Compléter la commande",
  "couponErrorHeading": "Erreur relative au bon",
  "couponOnlyAvailableOnPlatform": "Nous sommes désolés. Ce bon d’achat {br} n’est disponible que sur notre plateforme {platformName}",
  "couponWillExpire": "Ce bon expirera dans 15 minutes",
  "createAccountHeadline": "Créez un compte pour commencer",
  "createAnAccount": "Créer un compte",
  "credit": "Crédit",
  "creditCardExpired": "Le numéro de carte de crédit est expiré",
  "creditCardNumber": "Numéro de carte ",
  "crownCard": "Carte Crown",
  "crownCardBalance": "Solde de la Carte royale",
  "crownPoints": "Nombre de Couronnes",
  "crowns": "Couronnes",
  "crownsApplied": "Couronnes appliquées",
  "crownsEarned": "{points} Couronnes{points, plural, one {} other {s}} accumulées",
  "crownsPerDollar": "Obtenez des {pointsPerDollar} Couronnes{pointsPerDollar, plural, one {} other {s}} pour chaque dollar dépensé",
  "curbside": "Pickup à l’extérieur",
  "curbsideAt": "Pickup à l’extérieur au",
  "curbsideHours": "Heures d’ouverture pickup à l’extérieur",
  "curbsideInstructionsText": "Annoncez-nous votre arrivée et nous vous apporterons votre commande à votre véhicule dès qu’elle sera prête. Sinon, nous préparerons votre commande à {time}",
  "curbsideInstructionsTextHere": "Nous avons commencé à préparer votre commande. Dès qu’elle sera prête, nous l’apporterons à votre véhicule.",
  "curbsideOrder": "Pickup à l’extérieur",
  "curbsideOrderError": "Une erreur s’est produite lors du traitement de votre commande de pickup. Veuillez confirmer vos coordonnées ou essayer un autre mode de service.",
  "curbsideUnavailable": "Pickup à l’extérieur n’est pas disponible",
  "curbsideVehicleDescription": "Aidez-nous à vous trouver en décrivant votre véhicule; p. ex. VUS rouge. À votre arrivée au restaurant, trouvez un endroit pour vous stationner. Dès que votre commande sera prête, nous vous l’apporterons.",
  "curbsideVehicleDescriptionPlaceholder": "Description du véhicule",
  "curbsideVehicleTitle": "Décrivez votre véhicule",
  "currencyInfo": "Le format de votre devise est déterminé par vos paramètres régionaux. Modifiez vos paramètres régionaux pour mettre à jour votre format.",
  "currentlyHaveXRewards": "Vous avez actuellement {availableRedemptions} récompenses",
  "currentPage": "page actuelle",
  "currentServiceModeUnavailable": "Le restaurant n’accepte pas de commandes {serviceMode} en ce moment. Changez le mode de service ou sélectionnez un autre établissement",
  "customize": "Personnaliser",
  "customizeYour": "Personnalisez votre {name}",
  "cvv": "CVV",
  "cvvIsRequired": "Le numéro CVV est requis",
  "cvvMustBeAtLeast3Digits": "Le code CVV doit comporter au moins 3 chiffres",
  "dashboard": "Tableau de bord",
  "date": "Date",
  "dateOfBirth": "Date de naissance",
  "dateOfBirthIsBelowMinimum": "Les informations renseignées ne sont pas en conformité avec nos {termsLink} et notre {privacyPolicyLink}",
  "dateOfBirthIsRequired": "La date de naissance est un champ obligatoire.",
  "datePlaceholder": "mm/jj/aaaa",
  "day": "Jour",
  "daypartAfterEndTime": "C’est l’heure du {dayPart}!",
  "daypartBeforeStartTime": "Ce n’est pas encore l’heure du {dayPart}!",
  "daypartChallengedCompleted": "Défi complété",
  "daypartEndsAt": "Le {dayPart} se termine à {endTime}",
  "daypartStartsAt": "Le {dayPart} débute à {startTime}",
  "dayPlaceholder": "JJ",
  "days": "Jours",
  "daysLeft": "{daysCount} jours restants",
  "daytime": "Pendant la journée",
  "daytimeMenu": "Menu du jour",
  "daytimeMenuHeader": "De {daytimeStartTime} à {daytimeEndTime}",
  "daytimeMenuHeaderHours": "De {daytimeStartTime} à la fermeture",
  "deactivateCard": "Désactivez votre carte",
  "debit": "Débit",
  "december": "Décembre",
  "decline": "Rejeter",
  "declineCateringOrderModal": "Veuillez choisir le motif du rejet de la commande",
  "declineOrder": "Rejeter la commande",
  "declineReason": "Decline reason",
  "decrementName": "Diminution {name}",
  "defaultReloadMethod": "Méthode de rechargement par défaut",
  "delete": "supprimer",
  "deleteAccount": "Supprimer le compte",
  "deleteAndDisable": "Surrpimez et désactivez",
  "deleteButtonText": "Supprimer",
  "deletePaymentMethod": "Supprimer le mode de paiement",
  "delivered": "Livré",
  "deliverHere": "Livrer ici",
  "deliveringTo": "Livrer à",
  "deliveringToYourDoorAt": "Livraison à votre porte au",
  "deliverTo": "Livrer à",
  "delivery": "Livraison",
  "deliveryAddNewAddress": "Ajouter une nouvelle adresse",
  "deliveryAddress": "Adresse de livraison",
  "deliveryAddressFormatted": "{addressLine1}{linebreak}{city}, {state} {zip}",
  "deliveryChangeRegionDisclaimer": "Veuillez noter que vous devrez entrer votre adresse de livraison de nouveau. La disponibilité peut varier en fonction des changements de régions.",
  "deliveryChangeRegionHeading": "Utiliser cette adresse de livraison changera la région sélectionnée",
  "deliveryChangeRegionPrimaryText": "{userRegion} est la région que vous avez sélectionnée, et l’adresse de livraison que vous avez entrée est à {restaurantRegion}.",
  "deliveryCheckingMessage": "Nous cherchons quel restaurant livrera à votre adresse. Attend quelques instants...",
  "deliveryComingSoon": "Livraison BIENTÔT DISPONIBLE",
  "deliveryDelivered": "Livrée",
  "deliveryDetails": "Détails de la livraison",
  "deliveryDetailsInstructions": "Instructions spéciales pour la livraison ?",
  "deliveryDriveEnroute": "Votre livreur arrivera bientôt!",
  "deliveryDriverArrived": "Chauffeur arrivé",
  "deliveryDriverEnroute": "Livreur en route",
  "deliveryFee": "Frais de livraison",
  "deliveryFeeDescription": "Les frais de livraison varient pour chaque restaurant en fonction de votre emplacement et d’autres facteurs.",
  "deliveryFees": "Frais de commande de livraison",
  "deliveryImage": "Image de livraison",
  "deliveryInfo": "Renseignements sur la livraison",
  "deliveryInstructions": "Instructions de livraison",
  "deliveryIsUnavailable": "La livraison n’est pas offerte dans votre région; mais, cela ne vous empêche pas de savourer nos délicieux produits. Cliquez sur le bouton ci-dessous pour trouver les restaurants dans votre région et rappelez-vous de vérifier nos offres!",
  "deliveryLogoAlt": "Logo du fournisseur du service de livraison",
  "deliveryMinumumNotReached": "Nous sommes désolés! Le montant minimal pour une commande de livraison est de {min}",
  "deliveryNotAvailable": "Livraison indisponible",
  "deliveryOnly": "Livraison seulement",
  "deliveryOrder": "Commande en Livraison",
  "deliveryOrderBeingPrepared": "En préparation",
  "deliveryOrderPlaced": "Passée",
  "deliveryPhoneDisclosure": "Votre numéro de téléphone est nécessaire afin de nous permettre de vous acheminer les mises à jour liées à votre commande et de faciliter la communication entre vous et votre livreur. Notez que votre numéro de téléphone ne pourra être vu par le livreur.",
  "deliveryPhoneNumberError": "Veuillez mettre à jour votre numéro de téléphone lié à votre compte pour continuer",
  "deliveryPlaced": "Merci. Nous avons reçu votre commande.",
  "deliveryPrepared": "Votre commande est en cours.",
  "deliverySelectYourAddress": "Sélectionnez votre adresse de livraison",
  "deliveryStatusDriverAssigned": "Votre commande est en cours",
  "deliveryStatusDriverAtCustomer": "Votre livreur n’est pas loin!",
  "deliveryStatusDriverAtStore": "Votre livreur vient de ramasser votre commande ",
  "deliveryStatusDriverStarting": "Votre livreur ramasse votre commande",
  "deliveryStatusDriverUnassigned": "Votre commande est en cours",
  "deliveryStatusOrderAbandoned": "Votre commande a été livrée",
  "deliveryStatusOrderCancelled": "Votre commande a été annulée",
  "deliveryStatusOrderCreated": "Votre commande est en cours",
  "deliveryStatusOrderDroppedOff": "Votre commande a été livrée",
  "deliveryStatusOrderError": "Erreur lors du traitement de votre commande",
  "deliveryStatusOrderPickedUp": "Votre livreur est en route!",
  "deliveryStatusOrderTimeout": "Temps de traitement de la commande expiré",
  "deliveryStatusQuoteError": "Erreur lors du traitement de votre commande",
  "deliveryStatusQuoteRequested": "On est en train de traiter votre commande",
  "deliveryStatusQuoteSuccessful": "Nous avons reçu votre commande",
  "deliveryStatusQuoteUnavailable": "On ne peut pas traiter votre commande",
  "deliverySurcharge": "Frais de livraison supplémentaires",
  "deliverySurchargeMessage": "{feeSurcharge} de frais supplémentaires seront ajoutés à vos frais de livraison en raison de la distance entre l’emplacement de votre livraison et le restaurant le plus près.",
  "deliveryTerms": "Des modalités et des frais s’appliquent à la livraison. Cliquez {link} pour plus de détails.",
  "deliveryTime": "Heure de livraison",
  "deliveryUnavailable": "Livraison | Non Disponible",
  "depositFee": "Frais de consigne",
  "describeIssue": "Décrivez votre problème",
  "describeIssueAndIncludePhoneNumber": "Décrivez votre problème (veuillez inclure votre numéro de téléphone)",
  "describeYourVehicle": "Décrivez votre véhicule",
  "descriptionLengthError": "La longueur de la description doit être entre {min} et {max} caractères.",
  "details": "Détails",
  "deviceSettings": "Paramètres du dispositif",
  "diagnostics": "Diagnostique",
  "dialog": "dialogue",
  "didNotReceiveCodeHeading": "N’a pas reçu un code?",
  "didNotReceiveCodeSendANewOne": "Envoi d’un nouveau code dans {countdownSeconds}...",
  "didNotReceiveCodeSendANewOneCountDownSeconds": "{number} seconde(s)",
  "differentDeviceError": "Nous avons remarqué que cet appareil n’est pas celui duquel vous avez demandé ce code. Pour ouvrir une session sur cet appareil, veuillez demander un nouveau code ci-dessous.",
  "differentDeviceErrorPhoneVerify": "Nous avons remarqué que cet appareil n’est pas celui qui a été utilisé pour demander ce code. Veuillez appuyer sur le lien de nouveau pour vérifier votre numéro de téléphone.",
  "differentRegion": "Région inconnue",
  "digitalWalletsAndCreditCards": "Portefeuilles numérique et cartes de crédit",
  "dineIn": "En restaurant",
  "dineInAt": "Dîner à",
  "dineInComingSoon": "En restaurant BIENTÔT DISPONIBLE",
  "dineInHours": "Heures d’ouverture de la salle à manger",
  "dineInOrder": "Commande en restaurant",
  "dineInUnavailable": "L’option En restaurant n’est pas disponible pour le moment",
  "directions": "Directions",
  "discard": "Annuler",
  "discardChanges": "Annuler les modifications.",
  "dismiss": "Supprimer",
  "dismissDeliveryModal": "Retour",
  "distance": "Milles",
  "diversity": "Diversité",
  "documentTitle": "Firehouse Subs - Subs, Sub Sandwiches, Salads, Catering",
  "doItManually": "Je vais le faire manuellement",
  "donateTo": "Faites un don sur {link}?",
  "donation": "Don",
  "done": "Terminé",
  "dontForgetToOrderYourReward": "N’oubliez pas de commander votre récompense!",
  "dontForgetToPickYourOrder": "N’oubliez pas de récupérer votre commande bientôt ! Le restaurant ferme à {time}",
  "dontHaveAnAccount": "Vous n’avez pas de compte?",
  "dontHaveAPromoCode": "Vous n’avez pas de code promo?",
  "downArrowChevronIcon": "Flèche descendante",
  "downloadApp": "Téléchargez notre appli",
  "downloadOnAppStore": "Télécharger au App Store",
  "downloadOurApp": "Téléchargez notre appli. Entrez votre numéro de cellulaire et nous vous enverrons un lien de téléchargement.",
  "downloadTheApp": "Télécharger l’appli",
  "doYouHaveACouponAlready": "Disposez-vous déjà d’un coupon ?",
  "drawerHeading": "titre du tiroir",
  "driveThru": "Service au volant",
  "driveThruAt": "Service au volant à",
  "driveThruComingSoon": "Au service au volant BIENTÔT DISPONIBLE",
  "driveThruHours": "Heures d’ouverture du service au volant",
  "driveThruOrder": "Commande au service au volant",
  "driveThruPickupInstructions": "Avancez à l’écran des commandes et avisez-nous que vous avez commandé en ligne pour {name}.",
  "driveThruUnavailable": "L’option Au service au volant n’est pas disponible pour le moment",
  "duplicateAccounts": "Compte en duplicata",
  "duplicateSupportFormSubmission": "Une fiche a déjà été créée concernant votre problème. Veuillez patienter jusqu’à 48 heures pour recevoir une réponse.",
  "earned": "Vous avez obtenu",
  "earnedPoints": "Historique des transactions",
  "earnedReward": "Vous vous méritez une récompense",
  "earnPoints": "Gagnez des points",
  "earnPointsForEveryPurchase": "Obtenez 10 points lors de chaque achat admissible",
  "earnPointsToRedeem": "Obtenez des points à réclamer",
  "earnUsingRewardsCard": "Obtenez des points en utilisant la carte de récompenses",
  "eatIn": "En restaurant",
  "edit": "Modifier",
  "editOrder": "Modifier la commande.",
  "eggs": "œufs",
  "email": "Courriel",
  "emailAddress": "Adresse électronique",
  "emailOrPhoneNumberRequiredError": "Il faut fournir une adresse courriel ou un numéro de téléphone",
  "emailRequiredError": "L’adresse courriel est un champ obligatoire.",
  "emailSuccessfullySubmitted": "Courriel soumis avec succès",
  "emailVerificationSent": "Courriel envoyé! Veuillez vérifier votre e-mail pour le lien de vérification.",
  "emptyCart": "Vider le panier",
  "emptyCartMessage": "Vous n’avez rien dans votre panier pour le moment!",
  "emptyMenuHeading": "Sélectionnez un emplacement pour consulter le menu",
  "emptyMenuMessage": "Aidez-nous à trouver le restaurant le plus près de chez vous en partageant votre emplacement ou en sélectionnant un emplacement manuellement",
  "emptyMyCodeHeading": "Sélectionnez un emplacement pour consulter Mon code",
  "emptyMyCodeMessage": "Partagez votre emplacement ou sélectionnez un emplacement manuellement pour consulter Mon code",
  "enableCameraPermission": "Veuillez activer la permission de caméra",
  "enableLocationServices": "Activez les services de localisation",
  "enablePreciseLocation": "Enable Precise Location",
  "ends": "se terminant",
  "endsIn": "••••",
  "english": "English",
  "enjoyYourOrder": "PROFITEZ BIEN DE VOTRE COMMANDE. À BIENTÔT!",
  "enterACode": "Saisissez un code",
  "enterAddressOrDrag": "Entrez une adresse ou faites glisser la carte pour trouver des restaurants près de vous.",
  "enterANameForThisOrder": "Entrez un nom pour cette commande",
  "enterAPromoCode": "Entrez un code promotionnel",
  "enterCardNumber": "Entrez le numéro de la carte",
  "enterFourDigitPin": "Saisissez le code à 4 chiffres indiqué sur le coupon, BK.com, ou l’appli mobile ici :",
  "enterManually": "Entrer le numéro manuellement",
  "enterPromoCode": "Entrez le code promotionnel",
  "enterVerificationCode": "Entrez le code de vérification",
  "enterYourDeliveryAddress": "Saisis ton adresse",
  "enterYourLovedOnesDeliveryAddress": "Saisir votre adresse de livraison (ou l’adresse de vos bien-aimés)",
  "error.isRestaurantPosOnline": "PDV hors ligne du restaurant",
  "error.isStoreOpenAndAvailable": "Le restaurant n’est pas ouvert ni disponible",
  "error.priceMutationError": "Erreur de prix",
  "error.priceOrderFailure": "Échec de prix pour la commande",
  "error.unavailableCartItems": "Produits au panier non disponibles",
  "error404": "Erreur 404",
  "errorBoundaryBody": "Nous éprouvons un problème. Consultez notre menu et nos offres pendant que nous tentons de le résoudre.",
  "errorBoundaryTitle": "Dommage!",
  "errorCreateOrder": "Une erreur s’est produite durant la création de la commande.",
  "errorCreatingSupportCase": "Une erreur s’est produite lors de la soumission de votre dossier d’assistance. Veuillez réessayer",
  "errorDialogCooldownMessage": "Nous voyons que vous avez récemment passé une commande à ce restaurant. Veuillez attendre 5 minutes avant de passer une autre commande à ce restaurant.",
  "errorDialogCooldownTitle": "Commandes en double",
  "errorDialogFavoriteStoreMessage": "Pour programmer votre restaurant favori, inscrivez-vous ou ouvrez une session dans votre compte.",
  "errorDialogFavoriteStoreTitle": "Ouverture de session requise",
  "errorLoadingUserOrders": "Erreur de chargement des commandes de l’utilisateur",
  "errorLoadOffers": "Une erreur s’est produite durant le chargement de la réclamation de l’offre.",
  "errorProcessingRequest": "Il semble qu’une erreur s’est produite lors du traitement de votre demande.",
  "errorRetrievingFavoriteRestaurants": "Restaurants préférés introuvables",
  "errorRetrievingRecentRestaurants": "Restaurants récents introuvables",
  "errorRetrievingRestaurants": "Impossible de trouver les restaurants",
  "eta": "Délai de livraison prévu",
  "etaFull": "Délai de livraison prévu",
  "eu-calories": "Énergie",
  "eu-carbohydrates": "Glucides",
  "eu-cholesterol": "Cholestérol",
  "eu-fat": "Acides gras",
  "eu-fiber": "Fibre",
  "eu-proteins": "Protéines",
  "eu-salt": "Sel",
  "eu-saturatedFat": "Dont saturés",
  "eu-sodium": "Sodium",
  "eu-sugar": "Dont sucres",
  "eu-transFat": "Acide gras trans",
  "eu-weight": "Poids",
  "euNutritionValues": "{calories} / {joules}",
  "evening": "Soirée",
  "exampleInstructions": "Par exemple, veuillez laisser la livraison à la porte",
  "exceedsBalanceLimit": "Ce montant dépasse votre solde",
  "exceedsRewardsBalance": "Solde de points atteint! Veuillez retirer les récompenses ajoutées à votre panier pour ajouter ce produit.",
  "existingUserVerifiedPhone": "Un utilisateur a déjà vérifié ce numéro de téléphone. Veuillez en essayer un autre.",
  "expirationDateIsRequired": "La date d’expiration est requise",
  "expire": "exp.",
  "expired": "Expirée",
  "expires": "Expire",
  "expiresIn": "Expire dans {expiryDays} {expiryHours}",
  "expiresWithDate": "Expire {endDateText}",
  "expiringPoints": "Points qui expirent",
  "exploreOurMenu": "Explorez Notre Menu",
  "faq": "Foire aux questions",
  "fat": "Gras",
  "favorite": "Favoris",
  "favoriteLocationLabel": "Chercher un emplacement préféré",
  "favoriteLocationPlaceholder": "Sélectionnez votre emplacement",
  "favoriteLocationRequiredError": "Un emplacement préféré est requis.",
  "favoriteOrders": "Commandes favorites",
  "favoriteRemoveAllModalBody": "Retirer tous les produits de cette commande supprimera votre favori. Voulez-vous vraiment supprimer cette commande?",
  "favoriteRemoveAllModalHeader": "Attendez! Vous n’avez sélectionné aucun produit!",
  "featured": "En vedette",
  "february": "Février",
  "fees": "Frais",
  "feet": "pieds",
  "fiber": "Fibre",
  "fifteenMin": "15 min",
  "filter": "filtre",
  "filterByMonth": "Filtrer par mois",
  "filters": "filtres",
  "findARestaurant": "Trouver un restaurant",
  "findAStoreNearMe": "Trouver un restaurant près de chez moi",
  "findRestaurants": "Trouver des Restaurants",
  "findRewardYouWantToRedeem": "Trouvez la récompense que vous souhaitez réclamer et cliquez sur Réclamer en restaurant",
  "finish": "Terminer",
  "finishOrder": "Terminer la commande",
  "firstName": "Prénom",
  "fish": "poisson",
  "fiveMin": "5 min",
  "followUsOnFacebook": "Suivez-nous sur Facebook",
  "followUsOnInstagram": "Suivez-nous sur Instagram",
  "followUsOnTiktok": "Suivez-nous sur TikTok",
  "followUsOnTwitter": "Suivez-nous sur Twitter",
  "followUsOnYoutube": "Suivez-nous sur YouTube",
  "foo": "foie",
  "foodQuality": "Qualité des aliments",
  "forItemAvailability": "Pour savoir si le produit est disponible",
  "forterSignUpError": "Il semble que vous avez déjà un compte avec nous. Veuillez essayer de vous connecter à votre compte ou contactez le service de soutien pour obtenir de l’aide.",
  "forwardButton": "Bouton Suivant",
  "foundation": "Fondation",
  "franchising": "Franchisage",
  "free": "Gratuit",
  "freeCoffeeTeOrBakedGood": "Obtenez GRATUITEMENT un café, un thé ou une pâtisserie.",
  "freeDeliveryOnAllOrders": "Livraison gratuite pour toutes les commandes",
  "freeDeliveryOverAmount": "Livraison gratuite pour les commandes de plus de {amount}",
  "freeRewardsUpsize": "Récompenses Passez au grand format",
  "freeUpsize": "Mettre à jour le panier",
  "french": "Français",
  "frequencyChallengedCompleted": "Défi complété",
  "freshlyExtracting": "En cours de transmission…",
  "freshNewDownload": "Nouveau téléchargement",
  "friday": "Vendredi",
  "from": "De",
  "fromStoreAddress": "de {storeAddress}",
  "fullMenu": "Menu complet",
  "fundingSource": "Source des fonds",
  "futureDateOfBirthProvided": "La date de naissance ne peut pas être ultérieure à la date d’aujourd’hui.",
  "generateNewCode": "Générez un nouveau code",
  "geographicalFee": "Frais géographiques",
  "geographicalFeeCO": "Frais de livraison CO???",
  "geographicalFeeDescription": "Frais additionnels facturés dans votre région par le service de livraison",
  "geolocationDeliveryImageAlt": "Image de géolocalisation de livraison",
  "geolocationImageAlt": "Image de géolocalisation",
  "germany": "Allemagne",
  "getDirections": "Obtenir l’itinéraire",
  "getOnGooglePlay": "Téléchargez sur Google Play",
  "getRewards": "Obtenez les récompenses",
  "gift": "Cadeau",
  "giftCard": "CARTE CADEAU",
  "giftCardAddingError": "Il se peut que les données de la carte cadeau soient inexactes ou qu’il y ait un problème avec votre carte, veuillez réessayer plus tard ou contacter l’émetteur.",
  "giftCardAddingSuccess": "Votre Carte royale BK a été ajoutée!",
  "giftCardExists": "La carte cadeau existe déjà dans les méthodes de paiement. Vous ne pouvez avoir qu’une seule carte cadeau dans vos méthodes de paiement.",
  "giftCardNumber.bk": "Numéro de la Carte royale",
  "giftCardNumber": "Numéro de la carte Crown",
  "giftCardNumberIsNotValid": "Le numéro de la carte cadeau n’est pas valide",
  "giftCardNumberIsRequired": "Le numéro de la carte cadeau est requis",
  "giftCardUnavailableAtLocationError": "Les cartes prépayées ne sont pas offertes à cet emplacement. Veuillez sélectionner un autre mode de paiement.",
  "giftCardUnavailableAtLocationErrorHeading": "Échec du paiement",
  "giftSent": "Votre cadeau est en route!",
  "giftSentError": "Malheureusement, nous n’avons pas pu envoyer votre cadeau {gift} à {email}. Veuillez réessayer plus tard ou communiquez avec {supportLink}.",
  "giftSentSuccess": "Vous avez envoyé un cadeau {gift} à {email}.",
  "gluten": "gluten",
  "goodDayPeriodX": "Bon {dayPeriod}, {name}",
  "googlePay": "Google Pay",
  "googlePayPaymentNotAvailable": "Nous n’acceptons pas Google Pay actuellement sur nos sites Web. Veuillez sélectionner ou ajouter un nouveau mode de paiement.",
  "googlePlay": "Google Play",
  "gotIt": "Excellent!",
  "goToHomepage": "Go To Homepage",
  "goToMenu": "Allez au Menu",
  "goToPaymentMethods": "Modes de paiement",
  "goToPopeyes": "Allez à Popeyes",
  "goToScanPage": "Numérisez",
  "goToSettings": "Go To Settings",
  "haveFreeReward": "Vous avez une récompense gratuite",
  "haveNotReceivedAuthCode": "N’a pas reçu un code?",
  "haveNotReceivedAuthEmail": "Vous n’avez pas reçu de courriel? Si vous n’avez pas reçu de courriel après 3 minutes, vérifiez dans votre boîte de courrier indésirable, ou cliquez sur ce lien : {link}.",
  "headsUp": "À tête levée",
  "headsUpModalTitle": "Un p’tit avant-goût",
  "headToRestaurant": "Aller en direction du restaurant",
  "help": "Aide",
  "here": "ici",
  "highVolumeMessage": "Le volume des commandes que nous recevons à présent est plus grand que prévu. Nous nous excusons pour les délais d’attente et faisons de notre mieux pour vous servir dès que possible. Merci de votre compréhension.",
  "history": "historique",
  "hiX": "Bonjour {name}",
  "hockey1": "Hockey – 1 Choix",
  "hockey2": "Hockey – 2 Choix",
  "hockey3": "Hockey – 3 Choix",
  "home": "Accueil",
  "homeMainFabSignInUp": "Se connecter / S’inscrire",
  "homepage": "Page D’accueil",
  "hour": "Heure",
  "hours": "Heures",
  "hoursAndInfo": "Heures & Info",
  "hoursOfOperationFallbackMessage": "Fermé",
  "howItWorks": "Comment ça fonctionne",
  "howToRedeem": "Comment réclamer l’offre",
  "howWouldYouLikeToReceiveThisOrder": "Comment souhaitez-vous recevoir cette commande?",
  "iAmHereButtonText": "Je suis là",
  "ifYouArePayingInRestaurant": "si vous payez en restaurant",
  "iHaveArrived": "Je suis arrivé",
  "image": "image",
  "imClose": "Je suis à proximité",
  "imCraving": "J’ai une fringale de",
  "imHere": "Je suis ici",
  "incentiveFeedbackCartRequirement": "Ajoutez les produits requis dans votre panier pour les réclamer",
  "incentiveFeedbackDefault": "Votre panier ne satisfait pas aux exigences de réclamation de ce {incentiveType}",
  "incentiveFeedbackExpired": "Cette offre expire le {endDate}",
  "incentiveFeedbackInvalidDay": "Cette offre n’est valide que le {targetValue}",
  "incentiveFeedbackInvalidStore": "Ce {incentiveType} n’est pas offert au restaurant que vous avez sélectionné",
  "incentiveFeedbackInvalidTime": "Ce {incentiveType} n’est valide qu’entre {startTime} et {endTime}, vérifiez de nouveau à {startTime} pour réclamer",
  "incentiveFeedbackMaxRedemptions": "Vous avez dépassé le nombre de réclamations permis pour ce {incentiveType}",
  "incentiveFeedbackNotAvailableInStore": "Non disponible à ce restaurant",
  "incentiveFeedbackOfferNotAvailable": "Désolés, votre offre n’est plus disponible.",
  "incentiveFeedbackOutOfDayPart": "Non offert actuellement. Veuillez vérifier de nouveau plus tard!",
  "incentiveFeedbackOutOfDayPartTime": "Offert à partir de {time}",
  "incentiveFeedbackPaymentMethod": "Vous devez payer avec {targetValue} afin de réclamer ce {incentiveType}",
  "incentiveFeedbackServiceMode": "Ce {incentiveType} n’est offert que pour {targetValue}",
  "incentiveFeedbackSubtotal": "Votre total partiel doit s’élever à {targetValue} $ pour réclamer cette {incentiveType}. Ajoutez {calculatedValue} $ pour réclamer",
  "incentiveFeedbackWithinCooldown": "Vous avez récemment utilisé une offre. Vous pourrez en utiliser une autre dans {calculatedValue} minutes.",
  "incidentInvolvingYourSafety": "Un problème concernant votre sécurité.",
  "included": "Inclus",
  "includeRewardItem": "Veuillez inclure le produit en récompense au moment de passer votre commande. Balayez avant de payer afin d’utiliser vos points.",
  "incomplete": "Incomplet",
  "incrementName": "Augmentation {name}",
  "incrementRollText": "{rollsToShare} REBORD{rollsToShare, plural, =0 {} one {} other {S}}",
  "info": "Info",
  "information": "Information",
  "initializationNetworkRedirectNote": "<Bold>Veuillez noter:</Bold> Tout changement au niveau de récompenses et aux points pourrait ne pas être sauvegardé.",
  "initializationNetworkRedirectOffline": "Réseau non disponible. <Bold>Vous ne recevrez des points que lorsque vous numérisez.</Bold>",
  "inRestaurantOrder": "Commande en restaurant",
  "inStoreAmenities": "Commodités en restaurant",
  "inStoreTitle": "Code en restaurant",
  "instructions": "Instructions",
  "insufficientBalanceReloadAgain": "Solde prépayé insuffisant – veuillez réapprovisionner votre carte pour passer au paiement.",
  "internalError": "Erreur interne",
  "international": "International",
  "invalidAddressLine2": "Le nom est manquant. Veuillez entrer votre nom.",
  "invalidCardNumber": "Numéro de carte non valide",
  "invalidCode": "Code non valide !",
  "invalidDateMissingFields": "Date non valide: {missingFields} {count, plural, one {field is} other {fields are}} obligatoires",
  "invalidDateOfBirthProvided": "La date de naissance fournie n’est pas valide",
  "invalidEmail": "Courriel Non Valide",
  "invalidEmailProvided": "E-mail non valide fourni",
  "invalidLink": "Lien non valide",
  "invalidOfferError": "Votre offre n’est pas valide.",
  "invalidOffersRemoved": "Les offres non valides ont été retirées",
  "invalidOtpProvided": "Le code que vous avez entré ne correspond pas à celui que nous vous avons envoyé. Veuillez vérifier vos messages et réessayer.",
  "invalidPaymentCardTypeWithOffer": "Le mode de paiement que vous avez sélectionné ne peut pas être appliqué à votre offre actuelle. Mettez à jour votre mode de paiement pour continuer.",
  "invalidPaymentMethodError": "Votre mode de paiement n’est pas valide. Veuillez essayer un autre mode de paiement.",
  "invalidQrCode": "Code QR non valide, veuillez en essayer un autre",
  "invalidReloadError": "Nous n’avons pas pu réapprovisionner votre carte.",
  "invalidTipAmountError": "Veuillez soumettre un montant de pourboire valide.",
  "invalidVerification": "Vérification non valide. Entrez le code à nouveau ci-dessous :",
  "inventory": "Inventaire",
  "investorRelations": "Relations avec les investisseurs",
  "investors": "Investisseurs",
  "inWithLove": "avec amour au",
  "isHalalTitle": "restaurant halal",
  "issuesDuringExperience": "Si vous avez eu des problèmes durant le processus de commande,",
  "issueWithMyItems": "Un problème avec mon/mes article(s)",
  "item": "Produit",
  "itemAddedToCart": "1 {itemName} a été ajouté(e) à votre panier.",
  "itemFavorited": "Élément sauvegardé dans les favoris!",
  "itemFavoritedError": "Oups! Une erreur s’est produite avec votre favori. Veuillez réessayer plus tard.",
  "itemFavoritedRemove": "Élément retiré des favoris.",
  "itemModifiers": "Modificateurs de produits",
  "itemNotAvailable": "Produit non disponible",
  "items": "Produits",
  "itemsInThisOrder": "Produits dans cette commande",
  "itemsNeverArrived": "Un ou plusieurs produits ne sont jamais arrivés",
  "itemsUnavailable": "Produits non disponibles",
  "itemsWithNutrients": "Je veux des produits avec",
  "itemTotal": "Produit Total",
  "itsUsNotYou": "Ce n’est pas vous, c’est nous…",
  "january": "Janvier",
  "joinNow": "Inscription",
  "july": "Julliet",
  "june": "Juin",
  "keepCurbsideOrder": "Non, conservez la commande",
  "keepPaymentMethod": "Conservez le mode de paiement",
  "keepPurchasingItemsToEarnRewards": "Continuer d’acheter des produits pour obtenir des récompenses et les échanger",
  "keepPurchasngItemsToEarnRewards": "Continuez à acheter des articles pour gagner des récompenses et en profiter.",
  "kilometers": "km",
  "languageAndRegion": "Langue et Région",
  "languageAndRegionSelector": "Sélection de la langue et de la région",
  "learnMore": "En savoir plus",
  "left": "Reste",
  "legacyImportWelcomeMessageHeader": "Bonjour! Welcome! ",
  "legacyImportWelcomeMessageText": "Nous avons mis à jour notre appli. Veuillez suivre le lien ci-dessous pour ouvrir une nouvelle session",
  "legal": "Légal",
  "legalNotice": "Mention Légale",
  "lessOptions": "Moins d’options",
  "letsEat": "On vous sert?",
  "letsLoadX": "Commençons par verser {amount} sur votre carte pour débuter.",
  "letTheCashierKnowYouPlacedAKioskOrderFor": "Aviser le caissier que vous avez passé une commande pour",
  "letUsKnowYouOrderedForX": "Présentez-vous au service au volant et dites-nous que vous avez passé une commande en ligne au nom de {name}.",
  "limitedTimeOffer": "Offre d’une durée limitée",
  "limitReached": "Limite atteinte",
  "linkARewardsCardToYourAccountAndRedeem": "Liez une carte de récompenses à votre compte et réclamez tous les points qui y sont conservés.",
  "linkMyCard": "Associer ma carte",
  "linkOpensInNewTab": "Le lien s’ouvre dans un nouvel onglet",
  "list": "Liste",
  "listbox": "zone de listes",
  "listOfWordsLayout": "{position, select, first {} last { and } other {, }}{word}",
  "listOfWordsLayoutOr": "{position, select, first {} last { or } other {, }}{word}",
  "loadCard": "Approvisionner la carte",
  "loading": "Un moment s’il-vous-plaît!",
  "loadingMenu": "Menu en cours de chargement",
  "loadingRestaurantMenu": "Un moment s’il-vous-plaît! Nous procédons au chargement de notre menu.",
  "loadingYourExperience": "Chargement de votre expérience personnalisée",
  "loadLoyaltyError": "Une erreur s’est produite lors du chargement de votre carte FidéliTimMC",
  "loadMore": "Charger plus",
  "loadNow": "Approvisionner maintenant",
  "loadWith": "Approvisionner avec",
  "localeDateTime": "fr-CA",
  "location": "Emplacement",
  "locationClosed": "Restaurant fermé. Les commandes mobiles ne sont pas disponibles pour le moment.",
  "locationDisclaimer": "Nous utilisons votre emplacement pour vous aider à trouver un restaurant tout près et vous offrir de la publicité et des offres plus pertinentes. ",
  "locations": "Emplacements",
  "locationSearch": "Nous cherchons des restaurants.",
  "locatorChangeRegionDisclaimer": "S’il vous plait sélectionner à nouveau votre restaurant. La modification des régions peut affecter la disponibilité des produits.",
  "locatorChangeRegionHeading": "La sélection de cet emplacement changera votre région",
  "locatorChangeRegionPrimaryText": "Vous avez {userRegion} comme région, alors que ce restaurant est dans {restaurantRegion} .",
  "locatorChangeRegionSelection": "Sélectionner une nouvelle région pour continuer.",
  "locked": "Verrouillé",
  "lockedOfferExpires": "Expire dans {days} jours et {hours} heures",
  "lockedOfferImage": "Image d’offre bloquée",
  "lockedPromoCodeOffer": "Utilisez le code pour réclamer",
  "logIn": "Connexion",
  "logInDisclaimer": "Vous n’êtes pas sur votre ordinateur? Assurez-vous de fermer votre session avant de quitter.",
  "logInToRedeem": "Connectez-vous pour obtenir des récompenses",
  "logInToRedeemOnline": "Ouvrez une session pour échanger en ligne",
  "logInWithExistingEmail": "Un compte est déjà associé à cette adresse courriel. Aimeriez-vous plutôt ouvrir une session?",
  "logo": "Logo de BK",
  "logOut": "Quitter",
  "looksLikeThereWasAnIssue": "Une erreur semble s’être produite durant le traitement de votre demande",
  "looksLikeWereExperiencingIssues": "Il semble qu’un problème s’est produit. Veuillez réessayer!",
  "lostNetworkConnectivity": "Il semble que vous ayez perdu votre connexion réseau",
  "loyaltyBannerDashboardButtonLabel": "Tableau de bord",
  "loyaltyBannerDetailsButtonLabel": "Détails",
  "loyaltyBannerLoggedOutMessage": "Bienvenue! Vous pouvez accumuler des Couronnes lors de chaque achat et les utiliser pour réclamer des récompenses.",
  "loyaltyCardAlreadyLinked": "Votre carte est déjà associée à ce compte!",
  "loyaltyCardImage": "Image de la carte FidéliTim",
  "loyaltyCardMergeFailed": "Nous sommes désolés, mais nous ne parvenons pas à associer votre carte pour le moment. Veuillez vous assurer que votre carte n’est pas déjà associée à un autre compte. Si elle ne l’est pas, veuillez vérifier votre connexion et essayer à nouveau.",
  "loyaltyCardValidationError": "Cela ne semble pas être un numéro de carte valide. Veuillez entrer un numéro de carte qui ressemble à ABCD-1234-EFGH.",
  "loyaltyCodeExpired": "Code fidélité expiré",
  "loyaltyCodeExpiredDetails": "Appuyez sur le bouton ci-dessous pour en générer un nouveau",
  "loyaltyCreateAccountHeadline": "Créez un compte pour commencer avec",
  "loyaltyDetailsHeroImage": "Obtenez plus de vos choses préférées gratuitement",
  "loyaltyDropdownPoints": "{points} Points",
  "loyaltyEarnMorePointsForReward": "Accumulez plus de {points} Couronnes pour votre prochaine récompense.",
  "loyaltyHeaderGreeting": "Bonjour {firstName},",
  "loyaltyHeaderPointsMessage": "Vous avez {points} points",
  "loyaltyHelp": "Mon niveau de récompenses",
  "loyaltyLinkCardAlreadyUsed": "La carte est déjà utilisée",
  "loyaltyLinkCardAlreadyUsedMessage": "Cette carte est déjà liée à un compte. Veuillez entrer un autre numéro de carte.",
  "loyaltyLinkCardInvalidNumber": "Ce numéro de carte ne semble pas être valide. Veuillez entrer un numéro de carte qui ressemble à ABCD-1234-EFGH",
  "loyaltyLinkCardSuccessMessage": "Vous avez ajouté {points} points en prime à votre compte de Récompenses Popeyes. {br} Ces points s’afficheront à votre compte dans quelques minutes.",
  "loyaltyLinkCardSuccessTitle": "Points récompenses ajoutés!",
  "loyaltyNotAvailableAtThisRestaurant": "Le Programme de fidélité n’est pas offert à ce restaurant",
  "loyaltyOfflineNoticeBlock": "Vous ne <Bold>recevrez des points</Bold> que lorsque vous numérisez. Tout changement au niveau de récompenses et aux points pourrait ne pas être sauvegardé.",
  "loyaltyPoints": "Vous avez {points} points",
  "loyaltyRewards": "Récompenses Fidélité",
  "loyaltyTerms": "Conditions du Programme de fidélité",
  "loyaltyUnavailableInRegionOops": "Oups!",
  "loyaltyUnlinkCardConfirmationAction": "Désactivez la carte",
  "loyaltyUnlinkCardConfirmationMessage": "Lorsque vous désactivez votre carte, vous ne pourrez plus l’utiliser pour accumuler des points ni la réactiver.",
  "loyaltyUnlinkCardConfirmationTitle": "Souhaitez-vous vraiment désactiver cette carte?",
  "loyaltyUseMyCrowns": "Utilisez mes Couronnes",
  "loyaltyUseYourPoints": "Utilisez vos Couronnes aujourd’hui",
  "lunch": "Dîner",
  "lupin": "lupin",
  "made": "Préparé",
  "main": "accueil",
  "mainMenu": "Menu",
  "maintainYourPoints": "Faites un achat pour les conserver",
  "makeAdditionalSelections": "Veuillez faire {quantity} sélection{quantity, plural, one {} other {s}} dans la section « {name} » pour ajouter ce produit à votre panier.",
  "makeATrueDifference": "Posez un geste qui compte vraiment",
  "makeThisChange": "Effectuer ce changement?",
  "map": "Carte",
  "march": "Mars",
  "marketingTile": "Fenêtre marketing",
  "maxAttemptsReached": "Vous avez atteint le nombre maximal de tentatives de connexion permises.",
  "maxAttemptsReachedPhoneVerify": "Vous avez atteint le nombre d’essais maximal permis pour vérifier votre numéro de téléphone",
  "maxAttemptsReachedRequestNewCode": "Vous avez atteint la limite de tentatives d’ouverture de session; veuillez demander un nouveau code ci-dessous.",
  "maximumPriceError": "La valeur de votre panier dépasse la somme maximale pour une commande mobile.",
  "may": "Mai",
  "mayBeCookedOnSameEquipmentAs": "Pourrait avoir été préparé avec le même équipement que cet ou ces ingrédients : {allergens}",
  "maybeLater": "Peut-être plus tard",
  "mayContainAllergens": "Peut contenir: {allergens}",
  "mayContainAllergensDisclaimer": "Pourrait contenir cet ou ces ingrédients : {allergens}",
  "mayContainAllergensViaSharedEquipment": "Peut contenir via un équipement partagé: {allergens}",
  "media": "Médias",
  "menu": "Menu",
  "menuCategoryLinks": "liens-catégories-menu",
  "menuModifierAddOption": "Ajoutez {modifierName}",
  "menuModifierRemoveOption": "Retirez {modifierName}",
  "menuTileUnavailableBody": "Aucun article n’est disponible en ce moment. Veuillez vérifier à nouveau plus tard.",
  "menuTileUnavailableButtonText": "Retour au Menu",
  "menuTileUnavailableHeading": "Désolé aucun article trouvé.",
  "message": "Message",
  "messageAllowLocation": "Veuillez autoriser la communication de votre emplacement dans les paramètres",
  "meters": "m",
  "method": "Mode: {serviceMode}",
  "miles": "milles",
  "milk": "lait",
  "min": "reste",
  "minimumPointsRequired": "Points minimums requis 50",
  "minimumPriceError": "La valeur de votre panier est inférieure au total requis pour la commande.",
  "minimumPriceForCateringError": "La valeur de votre panier est inférieure au total requis pour une commande du service de traiteur.",
  "minOrderMessage": "Pour profiter de l’offre, le sous-total de votre panier peut ne pas suffire à couvrir l’offre appliquée.",
  "minutes": "minutes",
  "missingItems": "Attendez! Il vous manque des produits.",
  "mobile": "Cellulaire",
  "mobileOrder": "Commande mobile",
  "mobileOrdering": "Commandes mobiles",
  "mobileOrderingAndPaymentTerms": "Modalites des Commandes et Paiements Mobiles",
  "mobileOrderingComingSoon": "Les commandes mobiles seront bientôt disponibles!",
  "mobileOrderingUnavailable": "La commande mobile n’est pas disponible",
  "mobileOrderingUnavailableAtStore": "La commande mobile n’est pas disponible pour ce restaurant",
  "modalHeading": "titre du contenu modal",
  "mode": "Mode",
  "modTakeoutInstructionsText": "Annoncez-nous votre arrivée et nous vous apporterons votre commande. Sinon, nous préparerons votre commande à {time}",
  "modTakeoutInstructionsTextHere": "Nous avons commencé à préparer votre commande. Nous vous l’apporterons dès qu’elle sera prête.",
  "monday": "Lundi",
  "month": "Mois",
  "monthPlaceholder": "MM",
  "monthYearShort": "MM/AA",
  "more": "Plus",
  "moreInfo": "Autres renseignements",
  "moreItems": "{remainingItems, plural, =0 {} other {{remainingItems}}} {remainingItems, plural, =0 {} one {autre produit…} other {autres produits…}}",
  "moreLeft": "il reste plus{linebreak}",
  "moreOptions": "Plus d’options",
  "moreOrdersToGetYouAFreeItem": "plus de commandes pour obtenir un article gratuit!",
  "morning": "Matin",
  "mustard": "moutarde",
  "mustBeLoggedInToRedeemCoupon": "Nous sommes désolés. Vous devez être connecté {br} pour réclamer ce bon d’achat.",
  "myAccount": "Mon compte",
  "myBalance": "Mon Solde",
  "myCateringOrder": "votre commande du service de traiteur",
  "myChallenges": "Mes défis",
  "myCode": "Mon code",
  "myOrder": "Ma commande",
  "myPoints": "Mes points :",
  "myRewards": "FidéliTim",
  "name": "Nom",
  "nameOnCard": "Nom sur la carte",
  "nameOnCardIsRequired": "Vous devez entrer le nom apparaissant sur la carte",
  "nameRequiredError": "Le nom est un champ obligatoire.",
  "nameYourFavorite": "Nommez le favori",
  "nativePaymentNotAvailable": "Nous n’acceptons pas Google Pay ni Apple Pay sur nos sites Web à l’heure actuelle. Veuillez choisir ou ajouter un autre mode de paiement.",
  "navLinkReorder": "Refaire la commande",
  "nearby": "À proximité",
  "needHelp": "Vous avez besoin d’aide?",
  "needMoreHelpSendUsAMessage": "Encore besoin d’aide ? Envoyez-nous un message",
  "negativeCartError": "Votre panier ne satisfait pas aux exigences minimales de prix. Veuillez ajouter des produits dans votre panier pour réclamer cette offre et continuer.",
  "newCodeSent": "Un nouveau code a été envoyé à votre courriel!",
  "newCodeSentPhone": "Un nouveau code a été envoyé à votre numéro de téléphone!",
  "newExclame": "Nouveauté!",
  "newOffer": "Nouvelle offre",
  "newsAndPress": "Actualités et communiqués",
  "newUpdateRequired": "Une nouvelle mise à jour est requise pour continuer",
  "next": "Suivant",
  "nextPage": "Page suivante",
  "no": "Non",
  "noAppDriving": "N’utilisez pas l’appli en conduisant",
  "noAppDrving": "Veuillez ne pas utiliser l’appli en conduisant",
  "noChangesToOrderTime": "Aucun changement de l’heure de commande ne peut être effectuée à ce stade.",
  "noCourierName": "Nom indisponible",
  "noCourierPhone": "Non disponible",
  "noCurrentRewardsAvailable": "Aucune récompense disponible en ce moment",
  "noDeliveryOptionsAvailable": "Désolés, aucune option de livraison n’est offerte actuellement.",
  "noDirectPayAndNoPrepaidMethodError": "Nous ne sommes pas en mesure d’accepter votre paiement pour le moment. Veuillez réessayer plus tard.",
  "noFavoritesHeader": "Favoris",
  "noFavoritesSubheader": "Sauvegardez un produit de votre panier ou des récents dans les favoris.",
  "noItemsFitCriteria": "Aucun produit ne correspond à vos critères de recherche",
  "noLocationsMatchYourSearch": "Aucun emplacement ne correspond à votre recherche.",
  "nonAvailableItemInStore": "Ce produit n’est pas disponible actuellement à votre restaurant sélectionné.",
  "nonAvailableOfferUntil": "Ce bon n’est pas valable avant {time}. Veuillez réessayer plus tard!",
  "nonAvailableReward": "Cette récompense n’est pas admissible à une réclamation.",
  "nonAvailableRewardUntilX": "Cette récompense ne sera pas offerte avant {time}. Veuillez vérifier de nouveau plus tard!",
  "noneFound": "Aucun élément trouvé",
  "noNewOffers": "Aucune nouvelle offre",
  "nonRedeemableOffer": "Cette offre ne peut pas être échangée",
  "noOrdersItemsYet": "LES COMMANDES RÉCENTES ET LES PRODUITS SERONT AFFICHÉS ICI",
  "noOrderYet": "Les commandes seront affichées ici",
  "noPaymentMethodOnFile": "Vous n’avez pas de mode de paiement en dossier.",
  "noPaymentMethodSaved": "Vous n’avez pas de mode de paiement enregistré.",
  "noPickupOptionsAvailable": "Désolés, aucune option de cueillette n’est offerte actuellement.",
  "noPointExpiry": "Vous n’avez pas encore gagné de points, mais ne vous inquiétez pas, une fois que vous en aurez gagné, vous verrez quand ils expireront.",
  "noRecentItemsHeader": "Aucun élément récent pour le moment",
  "noRecentItemsSubheader": "Passez une commande pour voir vos articles récents ici.",
  "noRestaurantDeliveryCta": "Trouver des Restaurants",
  "noSavedPromotions": "Vous n’avez pas de promotions sauvegardées",
  "noStoresAreOpenForDelivery": "Aucun magasin n’est ouvert pour la livraison.",
  "notApplicable": "S. O.",
  "notAvailableAtStore": "Non disponible au restaurant sélectionné",
  "notEnoughPoints": "Vous n’avez pas assez de points",
  "noThanks": "Non merci",
  "noThankYou": "Non merci",
  "nothingInCart": "Vous n’avez pas encore de produit dans votre panier",
  "notifications": "Notifications",
  "notifyDescription": "Modifier les types de communications que vous recevez de notre part et comment vous les recevez :",
  "notValidEmailError": "Cela ne ressemble pas à une adresse courriel valide.",
  "notValidEmailOrPhoneNumberError": "Cela ne semble pas être une adresse courriel ou un numéro de téléphone valide",
  "notValidNameError": "Ce nom ne semble pas valide.",
  "notValidPhoneNumberError": "Cela ne semble pas être un numéro de téléphone valide.",
  "notValidPhoneNumberPatternError": "Le numéro de téléphone n’est pas dans un format valide, comme : 888-888-8888",
  "notValidRewardsErrorBody": "Le code à barres que vous avez numérisé n’est pas un code à barres FidéliTim. Vous voulez essayer un code à barres différent?",
  "notValidRewardsErrorHeading": "Cela ne ressemble pas à une carte FidéliTim",
  "november": "Novembre",
  "now": "Maintenant",
  "numberOfCrowns": "{crowns} crown{crowns, plural, one {} other {s}}",
  "nutritionalInformation": "Renseignements nutritionnels",
  "nutritionInfoDownloadPDF": "Renseignements nutritionnels",
  "october": "Octobre",
  "of": "de",
  "offer": "Offre",
  "offerActivationError": "Erreur d’activation de l’offre. Veuillez réessayer.",
  "offerActivationSuccess": "Offre activée",
  "offerAddedToCart": "L’offre a été ajoutée au panier",
  "offerApplied": "Offre appliquée",
  "offerAppliedSuccess": "L’offre a été retirée de votre commande",
  "offerConfirmDialogBody": "Si vous fermez cet écran, le bon sera considéré comme ayant été échangé. Êtes-vous sûr?",
  "offerConfirmDialogConfirmLabel": "Continuer",
  "offerConfirmDialogHeading": "Vous avez terminé?",
  "offerExpiresDays": "Il vous reste {days} jours",
  "offerExpiresHours": "Il vous reste {hours}",
  "offerFeedbackDefault": "Votre panier ne satisfait pas aux exigences de réclamation de ce {incentiveType}",
  "offerFeedbackSubtotal": "Votre total partiel doit être de {targetValue} pour réclamer ce {incentiveType}. Ajoutez {calculatedValue}$ pour réclamer",
  "offerNotApplied": "Offre non appliquée",
  "offerNotAvailable": "Offre non disponible",
  "offerNotAvailableForMobileRedemption": "Il n’est pas possible de réclamer cette offre en ligne",
  "offerNotAvailableInRestaurantPos": "Un produit dans votre offre n’est pas admissible à ce restaurant.",
  "offerPicture": "Image de l’offre",
  "offerRedeemableAgainIn": "Vous pouvez réclamer cette offre à nouveau {redeemableIn}",
  "offerRedeemableIn": "Vous pouvez réclamer cette offre {redeemableIn}",
  "offerRedeemableOn": "Vous pouvez réclamer cette offre le {redeemableOn}",
  "offerRequirements": "Les offres dans votre panier ont des conditions qui n’ont pas encore été respectées. Appuyez sur « Réessayer » pour revenir au menu et ajouter les produits requis à votre panier.",
  "offers": "Des offres",
  "offersAddedToCart": "Offres ajoutées au panier",
  "offersAreCurrentlyNotAvailable": "Vos offres arriveront bientôt.",
  "offersCooldownDescription": "Les offres seront de nouveau disponibles dans {minutes} minutes.",
  "offersCooldownInfo": "Limite d’une offre par visite. Toutes les autres offres seront non disponibles pendant {period} minutes",
  "offersCooldownTitle": "Vous venez de réclamer une offre",
  "offersDisclaimer": "Un(e) par client. Pas de substitutions. N’est pas valable pour des livraisons ou avec d’autres coupons ou offres. Disponible uniquement auprès des établissements qui participent à US Popeyes® (sauf AK & HI). Annuler si interdit ou reproduit ou modifié ou transféré.  Sujet à taxes. Montant liquide de 1/100¢. TM & © 2021 PLKI. Tous droits réservés. Expire le {deactivationDate}",
  "offerSelected": "Offre sélectionnée",
  "offersEmptyStateText": "Il n’y a aucune offre disponible. Revenez plus tard pour les offres et les mises à jour.",
  "offersNotAvailableOnCatering": "Nous n’avons pas d’offres disponibles pour le service de traiteur en ce moment.",
  "offersNotAvailableOnDelivery": "Des offres de livraison seront bientôt prêtes pour vous ! Veuillez vérifier bientôt les mises à jour",
  "offersNotAvailableOnDeliveryChangeOrderType": "Modifies ta commande pour le mode « Pickup » pour voir les offres disponibles.",
  "offersNotAvailableOnDeliveryChangeServiceMode": "Votre panier contient une offre qui ne peut être utilisée pour le moment pour les commandes de livraison. Si vous souhaitez passer une commande de livraison, appuyez sur « Continuer » et nous supprimerons cette offre de votre panier.",
  "offersNotAvailableOnDeliveryChangeServiceModeContinue": "Continuer avec la livraison",
  "offerTerms": "Modalités de l’offre",
  "offerUnavailableForDelivery": "Cette offre n’est pas admissible pour la livraison",
  "offerUnavailableForPickup": "Cette offre n’est pas admissible pour une cueillette",
  "offerUpsellDiscard": "Non merci.",
  "offerUpsellTitle": "Vous avez encore faim?",
  "okay": "Ok",
  "okayButtonText": "OK",
  "oneCouponPerOrder": "Limite d’un bon par commande",
  "oneOfferPerOrder": "Une seule offre par commande",
  "onlineSoon": "En ligne bientôt",
  "only": "seulement",
  "open": "Ouvert",
  "open24Hours": "Ouvert 24 heures",
  "openStoreDiagnosticModal": "Ouvrir un modal diagnostique pour ce restaurant",
  "opportunities": "Occasions",
  "option": "option",
  "optional100PercentGoesToDriver": "optionnel, la totalité est remise au livreur",
  "optionalInformation": "Informations facultatives",
  "optionsLoaded": "Les options ont été téléchargées.",
  "or": "ou",
  "orAddNew": "ou Ajouter un(e) autre",
  "order": "Commander",
  "orderAccepted": "Commande acceptée !",
  "orderAtThisStore": "Commandez dans ce magasin",
  "orderCannotBeRefundedMessage": "L’émission de votre remboursement semble avoir échoué. Veuillez signaler le problème en cliquant sur le lien suivant : {link}. Si vous avez déjà demandé un remboursement, la somme apparaîtra dans votre compte d’ici 3 à 5 jours ouvrables.",
  "orderConfirmation": "Confirmation de la commande",
  "orderConfirmedHeading": "Commande confirmée!",
  "orderDate": "Date de la commande",
  "orderDeclined": "Commande refusée!",
  "orderDeliveryMinimumHeading": "Commande inférieure au montant minimal",
  "orderDetailsHeading": "Détails de la Commande",
  "orderFailureMessage": "Une erreur s’est produite lors de la création de votre commande. Nous sommes au courant du problème et travaillons à le résoudre. Veuillez réessayer ou contacter {link}",
  "orderFailureMessageForApplePay": "Votre paiement effectué avec Apple Pay a échoué. Votre carte ne sera pas débitée et toute transaction en attente est généralement libérée dans les 24 à 48 heures.",
  "orderFailureMessageForGooglePay": "Votre paiement effectué avec Google Pay a échoué. Votre carte ne sera pas débitée et toute transaction en attente est généralement libérée dans les 24 à 48 heures.",
  "orderHasBeenRefundedMessage": "Nous vous avons délivré un remboursement. Vous devriez voir le dépôt dans votre compte d’ici 3 à 5 jours ouvrables.",
  "orderHere": "Commandez ici",
  "orderHistory": "Historique des Commandes",
  "orderingFrom": "Commande de",
  "orderingIssues": "Problèmes de commande?",
  "orderingOptions": "Options de commande",
  "orderInRestaurant": "Faire une commande dans un restaurant",
  "orderLimitHeading": "Limite atteinte pour la commande",
  "orderLimitMessage": "Il semble que votre commande excédera le montant maximal de {maxLimit}. Supprimez un ou deux produits pour ajouter celui-ci à votre commande.",
  "orderLimitWarningX": "La commande ne peut pas dépasser {limit}",
  "orderMinimumMessage": "Le montant de votre commande est inférieur au montant minimal de {minLimit}. Ajoutez d’autres produits pour pouvoir passer votre commande.",
  "orderMinimumWarningX": "La commande doit être de plus de {min}",
  "orderMissingItemscontent": "Veuillez revenir à la page précédente et sélectionner « La commande n’est jamais arrivée » s’il manque la commande au complet.",
  "orderMissingItemsheading": "Problème touchant un ou plusieurs produits",
  "orderMissingItemssubheading": "Confirmez que tous les produits suivants sont manquants",
  "orderName": "Nom de commande",
  "orderNeverArrived": "La commande n’est jamais arrivée",
  "orderNeverArrivedcontent": "Veuillez revenir à la page précédente et sélectionner « Problème touchant un ou plusieurs produits » s’il ne manque qu’une partie de votre commande.",
  "orderNeverArrivedheading": "La commande n’est jamais arrivée",
  "orderNeverArrivedsubheading": "Confirmez que tous les produits suivants sont manquants",
  "orderNotCompleted": "Votre commande n’a pas pu être exécutée",
  "orderNow": "Commandez Maintenant",
  "orderNowWithRewards": "Commander avec des récompenses",
  "orderNumber": "Numéro de commande",
  "orderPayment": "Paiement de la commande",
  "orderPickup": "Pickup au restaurant",
  "orderPlaced": "Commande effectuée",
  "orderPlacedAt": "Commande passée au",
  "orderPlacedBy": "Commande passée par ",
  "orderProcessingDisclaimer": "Nous ne commencerons pas à préparer ta commande et ta carte ne sera pas débitée tant que tu n’auras pas appuyé sur «Je suis ici» sur l’écran suivant.",
  "orderProcessingDisclaimerCashPayment": "Nous commencerons seulement à préparer votre commande lorsque vous appuierez sur « Je suis ici » à l’écran suivant.",
  "orderProcessingDisclaimerImClose": "Votre commande ne sera pas préparée et votre carte ne sera pas débitée avant que vous n’appuyiez sur « Je suis à proximité ».",
  "orderProcessingDisclaimerImCloseCashPayment": "Nous commencerons seulement à préparer votre commande lorsque vous appuierez sur « Je suis tout près » à l’écran suivant.",
  "orderRedemptionOrderType": "Réclamation de l’offre : sélectionnez le type de commande",
  "orderScheduledForPickup": "La commande {orderNumber} {linebreak}est planifiée pour une cueillette",
  "orderScheduledForPickupMessage": "Nous avons planifié votre commande et elle sera déposée au comptoir avant lorsqu’elle sera prête.",
  "ordersMustBePlaced24HrsAhead": "!Les commandes doivent être passées 24 heures à l’avance",
  "ordersMustBePlacedHrsAhead": "!Les commandes doivent être passées {hrs} heures à l’avance",
  "orderTime": "Heure de la commande",
  "orderWillBeReadyName": "Votre commande sera prête bientôt {name}",
  "orderXWillBeReadyAtY": "La commande {orderNumber} sera prête à {readyAtTime}",
  "orderXWillBeReadySoon": "La commande {orderNumber} sera{linebreak}bientôt prête",
  "otaDownloadAndRestart": "Téléchargez et redémarrez",
  "otaDownloading": "Téléchargement en cours",
  "otaNotNow": "Pas maintenant",
  "otaUpdateAvailable": "Mise à jour disponible",
  "otaUpdateAvailableDescription": "Une mise à jour importante est disponible. Souhaitez-vous la télécharger et l’appliquer maintenant?",
  "otaUpdateRequired": "Mise à jour requise",
  "otaUpdateRequiredDescription": "Une mise à jour essentielle doit être appliquée afin d’assurer le bon fonctionnement de cette appli.",
  "other": "Autre",
  "otherDiscounts": "Autres réductions",
  "ourMenu": "Notre Menu",
  "ourOffers": "Nos offres",
  "ourRestaurantsNearYou": "Nos restaurants près de vous",
  "outdatedCartVersionMessage": "Bonnes nouvelles! Nous avons actualisé notre expérience. Malheureusement, nous avons dû supprimer tout produit antérieurement dans votre panier.",
  "outOfDayparts": "{dayParts} seulement",
  "outOfRangeHeading": "Vous êtes trop loin",
  "outOfRangeMessage": "Malheureusement, nous ne pouvons pas livrer à cette adresse en ce moment. Indiquez votre adresse courriel ci-dessous, et nous vous aviserons lorsque la livraison sera disponible dans votre région.",
  "outOfRangeMessageAuthenticated": "Nous ne pouvons malheureusement livrer à cette adresse actuellement. Souhaitez-vous voir les ailes offertes chez Popeyes?",
  "outOfRangeSuccessMessage": "Merci de nous envoyer un courriel. Souhaitez-vous voir les ailes offertes chez Popeyes?",
  "pageChangedReaderCarousel": "Affichage des produits de {firstItemSlide} à {lastItemSlide} pour {totalCards}",
  "pageMoved": "La page que vous cherchiez a été déplacée ou n’existe pas",
  "pageNotFound": "Page Not found",
  "pageNotFoundDescription": "The page you are looking for might have been moved, deleted, or possibly never existed.",
  "payEarnReward": "Payez, accumulez, réclamez",
  "paymentAddingError": "Les détails de votre carte de crédit sont peut-être erronés ou nous éprouvons des difficultés avec votre carte. Veuillez réessayer ou vérifier auprès de votre banque.",
  "paymentDeclined": "Nous ne pouvons pas terminer cette transaction. Vérifiez auprès de l’émetteur de votre carte pour en savoir plus.",
  "paymentDeclinedDisclaimer": "Votre commande demeure dans le panier, vous pouvez revenir en arrière pour réessayer",
  "paymentDeclinedHeading": "Oups! Quelque chose n’a pas bien fonctionné et il nous est impossible de compléter cette transaction.",
  "paymentDeletingError": "Une erreur s’est produite lors de la suppression de votre mode de paiement.",
  "paymentDetails": "Détails du paiement",
  "paymentDetailsError": "Vérifiez votre carte et essayez à nouveau.",
  "paymentDetailsErrorHeading": "Oups! Les détails de la carte sont incorrects.",
  "paymentFailed": "Votre paiement a échoué",
  "paymentLoadingError": "Une erreur s’est produite lors du chargement de vos mode de paiement.",
  "paymentMethod": "Moyen de Paiement",
  "paymentMethodAdded": "Un nouveau mode de paiement a été ajouté.",
  "paymentMethodNotAvailable": "Non disponible",
  "paymentMethods": "Modes de paiement",
  "paymentNotSupported": "Veuillez choisir un autre mode de paiement ou un autre restaurant à proximité.",
  "paymentNotSupportedHeading": "Oups! Le restaurant ne supporte pas votre type de carte.",
  "paymentProcessingError": "Une erreur semble s’être produite durant le traitement de votre paiement. Vous voulez réessayer?",
  "paymentVerification": "Vérification du paiement",
  "paymentXEndingInY": "Paiement : {cardType} se terminant par {ccLast4}",
  "payWith": "Vous payez comment? ",
  "payWithCash": "Payez en argent comptant",
  "payWithMyPrepaidCard": "Payer Avec Ma Carte {cardAlias}",
  "payWithPayPal": "Payez avec PayPal",
  "payWithYour": "Payez avec votre",
  "peanuts": "arachides",
  "people": "Personnes",
  "perks": "Récompenses",
  "perksReadyToUse": "Vos récompenses sont prêtes à être utilisées",
  "perOneHundredGOrMl": "Par 100g ou 100ml",
  "perServing": "Par portion",
  "personalized": "Personnalisé",
  "personalMessageToRecipient": "Message personnel au destinataire.",
  "phoneAppStoresDisclaimers": "Apple et le logo Apple sont des marques de commerce d’Apple Inc., déposées aux États-Unis et dans d’autres pays. L’App Store est une marque de service d’Apple Inc. Google Play est une marque de commerce de Google Inc. Certaines conditions s’appliquent.",
  "phoneNumber": "Numéro de téléphone",
  "phoneNumberDisclaimer": "Le numéro de téléphone enregistré servira aux chauffeurs pour vous rejoindre. En continuant, vous consentez à partager vos coordonnées avec les chauffeurs Door Dash ou Postmates qui livreront vos commandes.",
  "phoneNumberError": "Veuillez entrer un numéro de téléphone valide",
  "phoneNumberPlaceholder": "+13050000000",
  "phoneNumberRequired": "Numéro de téléphone (requis)",
  "phoneNumberRequiredError": "Le numéro de téléphone est un champ obligatoire.",
  "phoneNumberTooLongError": "Le numéro de téléphone indiqué est trop long.",
  "phoneNumberTooShortError": "Le numéro de téléphone indiqué est trop court.",
  "phoneNumberUpdatedSuccess": "Votre numéro de téléphone a été mis à jour!",
  "phoneNumberWillBeSaved": "Le numéro de téléphone sera sauvegardé dans votre compte.",
  "pickUp": "Pickup",
  "pickUpAt": "Pickup à",
  "pickUpAtSelectALocation": "Ramasser à: sélectionnez un lieu",
  "pickUpBefore": "<pickup>Pickup</pickup> avant {pickupTime}",
  "pickUpBeforeDayPart": "<pickup>Cueillir</pickup> avant {dayPartEndTime}",
  "pickupComingSoon": "Pickup BIENTÔT DISPONIBLE",
  "pickUpConfirmationScreenReaderInstructions": "Votre commande a été sauvegardée. Avant d’arriver au restaurant, appuyez sur le bouton \"{screenReaderButtonName}\" pour que nos membres d’équipe préparent votre commande.",
  "pickupDateTime": "{date}{linebreak}{time}",
  "pickupDetailsBackButton": "Retour",
  "pickupDetailsHeading": "Quand cueillerez-vous votre commande?",
  "pickupDetailsNextButton": "Continuer",
  "pickupDetailsSubheading": "Veuillez commander au moins 24 heures à l’avance",
  "pickupInstructions": "Entrez dans le restaurant ou au service au volant et mentionnez que vous avez passé une commande mobile pour {name}.",
  "pickupModeContentHeading": "Commandez maintenant",
  "pickupModeCurbsideContent": "Passez nous voir et nous vous apporterons votre commande à votre véhicule.",
  "pickupModeCurbsideHeading": "Pickup à l’extérieur",
  "pickupModeDineInContent": "Entrez et consommez votre commande dans le restaurant.",
  "pickupModeDineInHeading": "En restaurant",
  "pickupModeDriveThruContent": "Passez au service au volant et indiquez que vous avez passé une commande mobile.",
  "pickupModeDriveThruHeading": "Service au volant",
  "pickupModeTakeoutContent": "Passez et cueillez votre commande à emporter.",
  "pickupModeTakeoutHeading": "À emporter",
  "pickUpOnly": "Cueillette seulement",
  "pickUpOrder": "Pickup de la commande",
  "pickUpOrderAt": "Heure à laquelle je cueillerai ma commande...",
  "pickUpOrderIn": "Nous commencerons la préparation de votre commande dans...",
  "pickUpOrderRestaurantClosing": "Le restaurant n’accepte pas de commande numérique en ce moment",
  "pickUpOrderSuccessfulAlert": "Votre commande a bien été prise en compte, profitez-en. Le numéro de votre commande est {orderNumber} chez {brand} à {address}.",
  "pickupTime": "Heure de cueillette",
  "pickUpTime": "Heure de pickup",
  "pickUpTimeAlt": "Heure de cueillette",
  "pickupTimeChanged": "L’heure de la cueillette a changé",
  "pickupTimeChangedMessage": "L’heure de votre cueillette a changé en fonction des modifications apportées à votre commande. Veuillez passer en revue et confirmer.",
  "pickupUnavailable": "Cueillette - non disponible",
  "pickUpUnavailable": "L’option pickup n’est pas disponible pour le moment",
  "pickupWindow": "Rapid Rescue Lane",
  "pickupWindowHours": "Rapid Rescue Lane Hours",
  "pin": "NIP",
  "placeOrder": "Passer la commande",
  "placeOrderDescription": "Passer une commande de {serviceMode} de {price}",
  "placeSecureOrder": "{secureOrderIcon} Passer la commande",
  "placingOrder": "Nous transmettons votre commande au restaurant.",
  "platformOnly": "{platform} seulement",
  "playNow": "Jouer maintenant",
  "pleaseCheckBackNextWeek": "Restez à l’affût pour profiter de nouvelles offres délicieuses.",
  "pleaseConfirmYourCreditCardInformation": "Veuillez confirmer les détails de votre carte de crédit",
  "pleaseEnterYourCreditCardInformation": "Veuillez saisir les informations de votre carte de crédit",
  "pleaseGoToTheFrontCounterToScanRewards": "Veuillez vous rendre au comptoir avant pour scanner les récompenses",
  "pleaseHaveYourCashReady": "Veuillez avoir votre argent en main",
  "pleaseHeadToTheCounterToPayForYourOrder": "Veuillez aller au comptoir pour payer votre commande.",
  "pleaseMakeSelectionInXHours": "Veuillez faire votre choix dans les {hour} heures.",
  "pleaseOrder24InAdvance": "Veuillez commander 24 heures á l’advance",
  "pleasePayForYourOrderUsingTheTerminal": "Veuillez payer pour votre commande sur le lecteur",
  "pleaseProvideYourPhoneNumberForTheOrder": "Veuillez fournir votre numéro de téléphone pour la commande",
  "pleaseSelect": "Veuillez sélectionner",
  "pleaseSelectAPaymentOption": "Sélectionnez un mode de paiement",
  "pleaseSelectOne": "Veuillez en sélectionner un",
  "pleaseTryAgainLater": "Veuillez réessayer plus tard",
  "pleaseTryToRefresh": "Veuillez essayer d’actualiser.",
  "pleaseTurnOnYourLocation": "Please Turn on Your Location Settings",
  "pleaseVerifyEmail": "Veuillez vérifier votre courriel.",
  "pleaseVerifyYourPhoneNumber": "Veuillez vérifier votre numéro de téléphone",
  "plk_logo_title": "Logo Popeyes",
  "plk.pypestreamLoadingMessage": "Votre expérience soutien est en cours.",
  "plkLogoTitle": "Popeyes",
  "plkPointsApplied": "Appliqué",
  "plkPointsEarned": "{points} Point{points, plural, one {} other {s}} accumulé(s)",
  "plkRewards": "Récompenses Popeyes",
  "pluralDays": "Jour{days, plural, one {} other {s}}",
  "pluralHours": "Heure{hours, plural, one {} other {s}}",
  "pluralMins": "Min{minutes, plural, one {} other {s}}",
  "points": "Points",
  "pointsApplied": "Points appliqués",
  "pointsEarned": "{pointsEarned, plural, one {You’ve earned {pointsEarned} point!} other {You’ve earned {pointsEarned} points!}}",
  "pointsEarnedLabel": "Point{points, plural, one {} other {s}} accumulé(s)",
  "pointsExpired": "Point{points, plural, one {} other {s}} Expiré(s)",
  "pointsExpireMonthly": "Les points obtenus il y a plus de 12 mois expireront à la fin de chaque mois.",
  "pointsNumber": "{points} pts",
  "pointsUsed": "Point{points, plural, one {} other {s}} Utilisé(s)",
  "policies": "Politiques",
  "popeyesCatering": "Restauration Popeyes",
  "popeyesFoundation": "La Fondation Popeyes",
  "popeyesRewards": "Récompenses Popeyes",
  "postalCode": "Code Postal",
  "postalCodeInvalid": "Code Postal Invalide",
  "postalCodeRequiredError": "Le code postal est un champ obligatoire.",
  "poweredByThirdPartyDeliveryProviders": "Exécuté par des fournisseurs de livraison tiers",
  "preferences": "Préférences",
  "premiumOptions": "Options Premium",
  "preorder": "Précommande : {serviceMode}",
  "prepaidCardBalance": "Solde la carte {cardAlias}",
  "preparedOnEquipment": "Préparé avec un équipement utilisé pour produits qui contiennent {preparedOnEquipmentAllergens}.",
  "preparedToOrder": "Préparé sur commande",
  "prepareIn10MinLabel": "Commencez à préparer ma commande dans dix minutes",
  "prepareIn15MinLabel": "Commencez à préparer ma commande dans quinze minutes",
  "prepareIn20MinLabel": "Commencez à préparer ma commande dans vingt minutes",
  "prepareIn30MinLabel": "Commencez à préparer ma commande dans trente minutes",
  "prepareIn45MinLabel": "Commencez à préparer ma commande dans quarante cinq minutes",
  "prepareIn5MinLabel": "Commencez à préparer ma commande dans cinq minutes",
  "prepareIn60MinLabel": "Commencez à préparer ma commande dans une heure",
  "prepareInMinLabel": "Commencez à préparer ma commande dans {minutes} minutes",
  "prepareNow": "Préparez maintenant",
  "prepareNowLabel": "Commencez à préparer ma commande maintenant",
  "prepDeliveryTime": "Heure de préparation et heure prévue",
  "prepPickUpTime": "Heure de préparation et de cueillette",
  "presentQRCodeToEarnPoints": "Présentez ce code QR à la caisse avant de payer afin d’accumuler des points sur votre commande.",
  "pressOkToEnableCameraPermissions": "Appuyez sur OK afin d’activer les permissions de caméra dans les paramètres de l’appli",
  "previous": "Précédent",
  "previousPage": "Page précédente",
  "price": "Prix: {price}",
  "pricedAndAvailableError": "Ce restaurant n’est pas disponible actuellement.",
  "pricesDiscountsAndAvailabilityAreSbujectToChangeWhenYouReorder": "Les prix, les rabais et la disponibilité des produits peuvent changer lorsque vous commandez à nouveau.",
  "primaryNavLinks": "Navigation Primaire",
  "privacy": "Confidentialité",
  "privacyPolicy": "Politique de Confidentialité",
  "prizes": "Prix",
  "problemsDetected": "Problèmes détectés",
  "product": "Produit",
  "productChallengedCompleted": "Défi complété",
  "productDetailSummaryCustomizableTitle": "Personnalisez votre commande",
  "productDetailSummaryTitle": "Votre commande",
  "profile": "Profil",
  "progressbar": "barre de progression",
  "promoCodeAddedToOffers": "Code promotionnel ajouté aux offres",
  "promoCodeApplied": "Le code promotionnel a bien été appliqué! Consultez la section {page} sur la page d’accueil",
  "promoCodeAppliedPage": "Récompenses et offres",
  "promoCodeBadgeLabel": "Code promotionnel",
  "promoCodeBodyDetails": "Veuillez entrer le code promotionnel de 12 chiffres ci-dessous",
  "promoCodeCartRedeemedCode": "Le code promotionnel a déjà été utilisé ou est enregistré dans vos codes promotionnels. Cliquez sur le lien ci-dessous pour consulter les promotions sauvegardées.",
  "promoCodeCTA": "Vous avez un code promotionnel?",
  "promoCodeLabel": "Entrez le code promotionnel",
  "promoCodeOfferExpiredCode": "Le code que vous avez entré est expiré",
  "promoCodeOfferInvalidCode": "Code promotionnel entré non valide",
  "promoCodeOfferInvalidOfferId": "Le code que vous avez entré n’est pas valide pour cette offre",
  "promoCodeOfferRedeemedCode": "Le code que vous avez entré a déjà été réclamé",
  "promoCodeOfferRequired": "Code promotionnel requis pour déverrouiller l’offre",
  "promoCodeOfferUnknownException": "Une erreur est survenue. Veuillez réessayer de nouveau.",
  "promoCodeSubmitBtnText": "Soumettre le code",
  "promotionEnded": "La période promotionnelle est terminée",
  "proteins": "Protéines",
  "province": "Province",
  "pts": "{points}pts",
  "pullToRefresh": "Déroulez un peu plus bas pour actualiser",
  "purchaseAdded": "Cet achat a été ajouté à votre nombre de visites",
  "purchaseAmount": "Montant",
  "purchaseDidNotCountTowardsReward": "Cet achat n’a pas été compté pour votre prochaine récompense. Veuillez consulter le {link} pour en savoir plus.",
  "purchaseNecessary": "Achat requis",
  "purchaseNecessaryDescription": "La réduction sera appliquée au moment d’acheter un produit admissible",
  "purchaseSevenTimes": "Effectuez 7 achats",
  "push": "Notifications poussées",
  "putThisCodeUnderTheScanner": "pour gagnez des points et récompenses",
  "pypestreamWelcomeAnon": "Bonjour! Je suis l’assistant numérique de {brand}, je suis là pour vous aider à résoudre des problèmes concernant vos commandes et répondre à vos questions. Veuillez patienter pendant que j’accède à votre dossier.",
  "pypestreamWelcomeUser": "{name}! Je suis l’assistant numérique de {brand}, je suis là pour vous aider à résoudre des problèmes concernant vos commandes et répondre à vos questions. Veuillez patienter pendant que j’accède à l’historique de vos commandes.",
  "qrScannerUnavailable": "Le lecteur QR n’est pas disponible. Veuillez réessayer la mise à jour à l’aide de la dernière version de l’appli.",
  "qty": "qté",
  "quantity": "Quantité",
  "quantityItemInYourCart": "{quantity} {itemName} dans votre panier",
  "quantityOf": "Quantité de {name}",
  "quantityUpdatedAlert": "Vous avez maintenant {quantity} {itemName} dans votre panier.",
  "question": "Question",
  "questionsAboutOrder": "Vous avez des questions sur votre commande?",
  "quickNote": "Remarque",
  "readLess": "Voir moins",
  "readMore": "Voir plus",
  "receipt": "Reçu",
  "recent": "Récentes",
  "recentHistory": "Historique récent",
  "recentItems": "Produits récents",
  "recentItemsFailed": "Oups! Le chargement des articles récents a échoué",
  "recentOrders": "Commandes récentes",
  "recentOrdersDisclaimer": "Les prix, les rabais et la disponibilité des produits peuvent changer lorsque vous commandez à nouveau.",
  "recentOrdersEmptySubHeader": "Allez passer une nouvelle commande dès maintenant!",
  "recents": "Récents",
  "recipientsEmail": "Email du destinataire",
  "recyclingFee": "Frais de recyclage",
  "redeem": "Réclamez",
  "redeemACoupon": "Échanger un coupon",
  "redeemApply": "Utilisez {points}",
  "redeemAtRestaurant": "Utilisez en restaurant",
  "redeemedRewards": "{redeemedRewards, plural, one {You’ve redeemed {redeemedRewards} reward.} autre {You’ve redeemed {redeemedRewards} rewards.}}",
  "redeemInNext": "À réclamer d’ici",
  "redeemInRestaurant": "Réclamer au Restaurant",
  "redeemInRestaurantShortCodeInstruction": "Présentez ce code à la caisse au moment de commander afin d’obtenir l’offre et d’accumuler des points",
  "redeemMyPoints": "Réclamer mes points",
  "redeemNow": "Réclamez maintenant",
  "redeemOfferConfirmation": "Pour profiter de l’offre, le sous-total de votre panier doit être égal ou supérieur à la valeur de l’offre. Si vous choisissez de continuer, nous supprimerons l’offre de la commande.",
  "redeemPointsDisclaimer": "Vos points seront seulement utilisés pour les objets sélectionnés",
  "redeemPointsForRewards": "Échangez des points contre des récompenses",
  "redeemRemove": "Retirez {points}",
  "redeemReward": "Réclamez votre récompense",
  "redeemRewards": "Réclamez vos récompenses",
  "redeemThisCoupon": "Réclamer ce bon d’achat",
  "redemptionCode": "Code de réclamation",
  "refresh": "Actualiser",
  "refreshCodeTextExpired": "Actualisez pour en générer une nouvelle.",
  "refreshCodeTitleExpired": "Actualisez cette page pour continuer.",
  "refreshOffers": "Actualiser mes offres",
  "refreshPlease": "Actualisez",
  "refundClickHere": "cliquez ici",
  "refundEligibilityInfo": "Les commandes sont admissibles à un remboursement partiel ou complet. Sélectionnez parmi vos commandes ci-dessous pour soumettre une demande de remboursement.",
  "refundNetworkError": "Une erreur semble s’être produite dans la préparation de votre remboursement. Veuillez {link} pour signaler le problème. Si vous avez déjà demandé un remboursement, veuillez allouer de 3 à 5 jours ouvrables pour voir le dépôt dans votre compte.",
  "register": "S’inscrire",
  "registerCardDescription": "Entrez le NIP au dos de la carte pour protéger votre solde, continuer à recevoir des récompenses et en faire le suivi. Vos transactions et récompenses précédentes seront ajoutées à votre compte.",
  "registerYourPhysicalCard": "Associer ma carte FidéliTim ",
  "releaseToRefresh": "Relâchez pour actualiser!",
  "reload": "Réapprovisionner",
  "reloadAndContinue": "Réapprovisionnez {amount} et continuez",
  "reloadAndPlaceOrder": "Réapprovisionner {amount} et passer la commande",
  "reloadCard": "Réapprovisionner la carte",
  "reloadCardError": "Une erreur s’est produite durant l’approvisionnement de votre Carte Tim.",
  "reloadCardWithAmount": "Réapprovisionner {amount}",
  "reloadCrownCardCardError": "Une erreur est survenue lors du téléchargement de votre Carte royale BK.",
  "reloadExplanation.bk": "Votre solde actuel sur la carte  BK Crown ne couvre pas le coût de la commande d’aujourd’hui. Quand vous faites une commande nous facturons {reloadAmount} sur votre carte Crown.",
  "reloadExplanation": "Le solde actuel de votre Carte Tim n’est pas suffisant pour couvrir votre commande actuelle. Lorsque vous passerez votre commande, nous réapprovisionnerons votre Carte Tim de {reloadAmount}.",
  "reloadMethods": "Méthodes de réapprovisionnement",
  "reloadNow": "Réapprovisionner maintenant",
  "reloadYourBalance": "Réapprovisionnez votre solde",
  "reloadYourCard": "Réapprovisionner la carte",
  "remaining": "{hoursAndMinutes} restantes",
  "remainingTags": "{remainingTags, plural, one {filter} autre {filters}}",
  "rememberToMakeAPaymentDelivery": "N’oubliez pas de payer votre commande lors de la livraison",
  "rememberToMakeAPaymentPickup": "N’oubliez pas de payer votre commande lors de pickup",
  "remove": "Retirer",
  "removeAppliedCartPromoCodeAriaLabel": "Cliquez ici pour retirer la promotion actuelle du panier",
  "removeCustomizations": "Cette action annulera toutes les modifications apportées à ce/cette {itemType}.",
  "removeFavorite": "Retirer des favoris?",
  "removeFavoriteConfirm": "Voulez-vous vraiment retirer ce favori?",
  "removeFavoriteOrderSucess": "Commande retirée des favoris.",
  "removeFavoriteSucess": "Votre favori a été retiré.",
  "removeItem": "Retirer un produit",
  "removeItemFavorites": "Retirer cet élément des favoris?",
  "removeItemFavoritesConfirm": "Voulez-vous vraiment retirer ce favori?",
  "removeItemFromCart": "Êtes-vous sûr de vouloir retirer ce produit, {item}, de votre panier?",
  "removeItemFromCartOfferText": "Veuillez noter que la suppression de cet article supprimera également l’offre ci-jointe.",
  "removeItems": "Retirer des produits",
  "removeOne": "Supprimer un",
  "removeOneItemFromCart": "Retirer 1 {item name} du panier",
  "removeOneName": "Supprimer un {name}",
  "removePaymentMethod": "Supprimer le mode de paiement",
  "removeRestaurantFromFavorites": "Retirer ce restaurant des favoris",
  "removeReward": "Retirer la récompense",
  "removeUnavailableEntries": "Retirer les éléments non disponibles?",
  "removeUnavailableItems": "Supprimer les produits non disponibles",
  "reorder": "Commander",
  "reorderWithOrderDescription": "Nouvelle commande {orderDesc}",
  "reorderWithOrderNumber": "Numéro de la nouvelle commande {ticketNumber}",
  "replace": "Remplacer",
  "replaceCoupon": "Souhaitez-vous remplacer le bon qui était déjà appliqué à votre panier?",
  "replaceItem": "Remplacez le produit",
  "replaceOffer": "Vous souhaitez remplacer l’offre déjà présente dans votre panier ?",
  "replaceReward": "Remplacez la récompense",
  "replaceRewardText": "Une récompense est déjà sélectionnée. Souhaitez-vous vraiment la remplacer?",
  "reportAnIssue": "Signaler un problème",
  "reportIssue": "Signaler un problème",
  "reportOrderIssuePageTitle": "Signaler un problème avec la commande",
  "requestMyInfo": "Demander mes info",
  "requestMyInformation": "Demander mes informations",
  "requestNewLinkAuthEmail": "demander un nouveau lien",
  "required": "Requis",
  "requiredDate": "Une date valide est requise",
  "requiredField": "Il s’agit d’un champ obligatoire",
  "resend": "Envoyer à nouveau",
  "resendCode": "Renvoyer le code",
  "reset": "Réinitialiser",
  "resetCustomizationsDescription": "Sélectionner cette option effacera les paramètres de personnalisation appliqués à votre commande. Souhaitez-vous continuer?",
  "resetCustomizationsHeader": "Réinitialisez vos préférences?",
  "resetLocationButtonAria": "Cliquez ici pour réinitialiser la carte pour votre emplacement actuel.",
  "responsibility": "Responsabilité",
  "restaurantDetails": "Détails du restaurant",
  "restaurantOrder": "Commande restaurant",
  "restaurantOrderComingSoon": "Commande en restaurant à venir sous peu!",
  "restaurantRedemption": "Déjà à un restaurant et prêt à réclamer? {clickHere}",
  "restaurantRedemptionClickHere": "Cliquez ici",
  "restaurants": "Restaurants",
  "restaurantUnavailableErrorMessage": "Le restaurant où vous tentez de commander n’est pas disponible en ce moment.",
  "restaurantUnavailableErrorTitle": "Ce restaurant n’est pas disponible",
  "resultsLeft": "Il reste {count} résultats",
  "retry": "Réesayer",
  "retryMessage": "Nous éprouvons des difficultés à afficher l’état de cette offre. Veuillez actualiser ou réessayer plus tard.",
  "returnToMenu": "Retour au menu",
  "returnToRewards": "Retournez aux Récompenses",
  "review": "Revoir",
  "reviewYourOrder": "Vérifiez votre commande",
  "reward": "Récompense",
  "rewardAfterXPurchases": "Recevez une récompense après {pointsUntil} achats.",
  "rewardApplied": "Récompense réclamée",
  "rewardCategory": " Niveaux de récompense {selectedOptionText}-  {categoryName}",
  "rewardInfo2": "Tous les achats de plus de 0,50 $ effectués à au moins 30 minutes d’intervalle seront admissibles.",
  "rewardInfo3": "Vous pouvez accumuler jusqu’à 5 récompenses à la fois.",
  "rewardInfo4": "Lorsque vous échangez une récompense, l’article admissible le plus dispendieux de votre commande sera GRATUIT.",
  "rewardRedeemed": "Récompenses réclamées",
  "rewardRedemptionListEmptyState": "Ajoutez les {offers} et les {rewards} à votre commande",
  "rewards": "Récompenses",
  "rewardsAndOffers": "Récompenses et offres",
  "rewardSavings": "Économies de récompense",
  "rewardsHistory": "Historique FidéliTim",
  "rewardsLevelChange": "Votre niveau de récompenses est mis à jour",
  "rewardsMainImage": "L’image principale des récompenses",
  "rewardsManagementChangeRewardLevel": "Modifier le niveau de récompense",
  "rewardsManagementChoosenLevel": "Niveau de récompenses",
  "rewardsManagementPoints": "{points} Points",
  "rewardsPointsAmount": "Récompenses, {points} Points",
  "rewardsPointsBalanceIsNow": "Votre solde de points FidéliTim est de {remainingPoints}.",
  "rewardsPointsDetailsMessage": "Vous avez {points} points",
  "rewardsPointsTitle": "Points de FidéliTim",
  "rewardsPolicy": "Politique de récompenses",
  "rewardUnlocked": "Joli ! Récompense débloquée",
  "rewardsPointsAvailable": "Vous avez {points} disponible",
  "rollsAddedToAccountText": "REBORD{numberOfRollsShared, plural, one { a} other {s ont}} été ajouté{numberOfRollsShared, plural, one {} other {s}} à votre compte.",
  "rollsClaimedMessage": "Vous venez de réclamer {numberOfRollsShared, plural, one {un} other {{numberOfRollsShared}}} rebord{numberOfRollsShared, plural, one {} other {s}} à déRRRouler!",
  "routes.accepted": "/accepted",
  "routes.account": "/account",
  "routes.accountDelete": "/account/delete",
  "routes.accountRequestInfo": "/account/request-information",
  "routes.bkRewards": "/bkrecompenses",
  "routes.catering": "/catering",
  "routes.claimPointsBase": "/claim-points",
  "routes.claimPointsProcessing": "/claim",
  "routes.claimPointsSurvey": "/survey",
  "routes.deleteMyAccount": "/delete-my-account",
  "routes.deliveryTerms": "/conditions-livraison",
  "routes.exploreMenu": "/explorez-menu",
  "routes.favoriteStoreSearch": "/favorite-store-search",
  "routes.iCampaign": "/icampaign",
  "routes.linkPhysicalCard": "/link-card",
  "routes.loyaltyBannerAuthenticated": "/rewards/details",
  "routes.loyaltyBannerUnauthenticated": "/rewards/details",
  "routes.loyaltyDashboard": "/rewards/details",
  "routes.loyaltyHome": "/rewards",
  "routes.loyaltyOfferList": "/rewards/offers",
  "routes.loyaltyRewardList": "/rewards/list",
  "routes.mobileOrderingAndPaymentTerms": "/mobile-ordering-and-payment-terms",
  "routes.popeyesRewards": "/popeyes-rewards",
  "routes.privacyPolicy": "/politique-de-confidentialite",
  "routes.redemptionInRestaurant": "/redeem",
  "routes.reusables": "/reusable",
  "routes.rewards": "/rewards",
  "routes.rewardsHistory": "/rewards/history",
  "routes.rewardsPolicy": "/rewards-policy",
  "routes.roadtrip": "/roadtrip",
  "routes.scan": "scan",
  "routes.sendGift": "/gift",
  "routes.signIn": "/signin",
  "routes.signUp": "/signup",
  "routes.storeLocator.address": "/store-locator/address",
  "routes.storeLocator.serviceModeCatering": "/store-locator/service-mode-catering",
  "routes.storeLocator": "/store-locator",
  "routes.termsConditionsRewards": "/conditions-recompenses",
  "routes.termsOfService": "/conditions-dutilisation",
  "royalPerks": "Royal Perks",
  "ruleSetsCannotRedeemAgain": "Vous avez atteint la limite des réclamations pour cette offre. {redeemableInString}",
  "ruleSetsCannotRedeemNow": "Vous ne pouvez pas réclamer cette offre aujourd’hui. {redeemableInString}",
  "ruleSetsCartPropertyViolation": "Votre panier ne respecte pas les exigences pour cette offre.",
  "ruleSetsDayOfWeekViolation": "Vous ne pouvez pas réclamer cette offre aujourd’hui. {redeemableInString}",
  "ruleSetsFirstOrderOnlyViolation": "Vous ne pouvez réclamer cette offre que dans votre première commande. ",
  "ruleSetsPurchaseViolation": "Votre panier ne contient pas les produits requis pour réclamer cette offre. ",
  "ruleSetsRequiresAssignmentViolation": "Vous n’êtes pas admissible à cette offre.",
  "rutrYouHave": "Vous avez",
  "safteyIssue": "Problème de sécurité",
  "salt": "Sel",
  "saturatedFat": "Lipides saturés",
  "saturday": "Samedi",
  "save": "Sauvegarder",
  "saveAddressDisclaimer": "Veuillez sauvegarder mon numéro de téléphone et mon adresse de livraison afin de me permettre de régler la facture plus rapidement lors de ma prochaine commande.",
  "saveAddressLabel": "Sauvegarder mes données. {disclaimer}",
  "saveCardDisclaimer": "Enregistrer la carte - En enregistrant votre carte, vous consentez à ce qu’elle soit conservée en dossier et utilisée pour vos achats.",
  "saveChanges": "Enregistrer les modifications",
  "savedAddresses": "Adresses sauvegardées",
  "savedOrder": "Votre commande a été sauvegardée.",
  "savedOrderTapImClose": "Commande enregistrée. Appuyez sur « Je suis à proximité » lorsque vous êtes à 5 minutes. Votre carte ne sera pas débitée d’ici là.",
  "savedOrdertapImHere": "Votre commande a été enregistrée. Appuyez sur « Je suis ici » quand vous arrivez afin que nous puissions préparer votre commande. Votre carte ne sera pas débitée d’ici là.",
  "saveFavoriteOrderButton": "Sauvegarder dans les favoris.",
  "saveFavoriteOrderNameError": "Veuillez entrer un nom de commande.",
  "saveFavoriteOrderSucess": "Commande sauvegardée dans les favoris!",
  "saveFavoriteOrderText": "Quel nom voulez-vous donner à cette commande?",
  "saveFavoriteOrderUnsaved": "Vous avez des changements non sauvegardés.",
  "saveFavoriteOrderUnsavedBody": "Voulez-vous continuer sans sauvegarder les modifications apportées dans votre commande favorite?",
  "saveInformationToMyAccount": "Se souvenir de mon numéro de téléphone et de mon adresse pour les futures commandes de livraison.",
  "saveMyPointsForLater": "Sauvegarder mes points pour plus tard",
  "scan": "Numérisez",
  "scanAndPay": "Numérisez et payez",
  "scanAndPayHasBeenDisabled": "Numérisez & payez est desactivée",
  "scanAndPayPaymentMethodUpdated": "Méthode de paiement est mise à jour",
  "scanBarcode": "Numérisez le code à barres",
  "scanMoreRewards": "Numérisez pour obtenir d’autres récompenses",
  "scanQr": "Balayez le code QR",
  "scanQRCode": "Numérisez votre code QR si vous payez au restaurant.",
  "scanQrTitle": "Balayez le code QR avant de commander",
  "scanRewards": "2.\tNumériser FidéliTim",
  "scanRewardsCard": "Numérisez une carte FidéliTim",
  "scanSuccessful": "Balayage réussi!",
  "scanToEarnPoints": "Balayez pour accumuler des points",
  "scanToEarnPointsPayAndUseRewards": "payer, gagner des points et utiliser des récompenses",
  "scanToEarnRewardsAndPay": "Numérisez votre carte Fidélitim et payer",
  "scheduleDelivery": "Planifier la livraison",
  "scheduleForDineIn": "Dîner sur place",
  "scheduleForDelivery": "Planifier la livraison",
  "scheduleForPickup": "Planifier la cueillette",
  "schedulePickup": "Planifier la cueillette",
  "school": "École",
  "searchLocation": "Cherchez l’emplacement",
  "searchLocations": "Cherchez les emplacements",
  "searchThisArea": "Rechercher ce secteur",
  "seasonalTreats": "Friandises de Saison",
  "seconds": "secondes",
  "seeMoreOptions": "Plus d’options",
  "select": "Sélectionner",
  "selectACountry": "Sélectionnez un pays",
  "selectAFilter": "Sélectionnez un filtre...",
  "selectBestRewardLevel": "Lorsque vous aurez suffisamment de points, ceux-ci seront automatiquement échangés contre les articles sélectionnés.",
  "selectDonationAmount": "Sélectionnez le montant du don",
  "selectLearnMore": "Select \"Learn More\" to access instructions on how to activate location permissions and find your nearest Firehouse Subs restaurant.",
  "selected": "Sélectionné",
  "selectedRestaurant": "Restaurant sélectionné",
  "selectedRewardLevelAndBankMyRewards": "Niveau de récompense sélectionné et déposer mes récompenses",
  "selectGift": "Sélectionnez un cadeau",
  "selectGiftError": "Veuillez sélectionner un cadeau.",
  "selectingPaymentMethod": "Sélectionnez un mode de paiement",
  "sending": "Envoi en cours…",
  "selectingStartOver": "En choisissant de recommencer, vous videz votre panier et commencez une nouvelle commande.",
  "selectionNotAvailable": "Nous sommes désolés, votre choix n’est pas disponible.",
  "selectLanguage": "Sélectionner une langue",
  "selectLocation": "Sélectionnez un emplacement",
  "selectManually": "Sélectionner manuellement",
  "selectNewRestaurantButton": "Sélectionnez un nouveau restaurant",
  "selectPaymentMethod": "Sélectionner le mode de paiement",
  "selectPaymentMethodToAddFunds": "Veuillez sélectionner le mode de paiement qui sera utilisé pour ajouter des fonds sur votre compte.",
  "selectPermissions": "Select Permissions",
  "selectPickup": "Choisir la méthod de collecte",
  "selectQuantity": "Sélectionner la quantité",
  "selectRestaurant": "Sélectionner le resto",
  "selectRestaurantToViewOffers": "Sélectionnez un restaurant pour voir les offres locales",
  "selectStore": "Sélectionnez un restaurant",
  "selectTheCorrectAnswer": "Sélectionnez la bonne réponse",
  "selectThisStore": "Sélectionner ce restaurant",
  "sendAnotherGift": "Envoyer un autre cadeau",
  "sendGift": "Envoyer un cadeau",
  "sendGiftEmailRequiredError": "Veuillez entrer l’adresse courriel du destinataire.",
  "sendGiftImage": "Envoyez une image du cadeau",
  "sendGiftNameRequiredError": "Veuillez entrer votre prénom et votre nom de famille.",
  "sendNewCode": "Envoyer un nouveau code",
  "september": "Septembre",
  "serviceFee": "Frais de service",
  "serviceFeeDescription": "Les frais de service représentent {percent}% de votre commande avant taxes.",
  "serviceModeDetailsCurbsideDescription": "Stationnez-vous et nous apporterons votre commande à votre voiture.",
  "serviceModeDetailsCurbsideHeader": "Pickup à l’extérieur",
  "serviceModeDetailsDineInDescription": "Entrez et assoyez-vous. Nous vous appellerons par votre nom lorsque votre commande sera prête.",
  "serviceModeDetailsDineInHeader": "En Restaurant",
  "serviceModeDetailsDriveThruDescription": "Approchez votre voiture et faites-nous savoir que vous avez passé une commande en ligne.",
  "serviceModeDetailsDriveThruHeader": "Service Au Volant",
  "serviceModeDetailsPickupDescription": "Votre commande sera prête au comptoir à votre arrivée.",
  "serviceModeDetailsPickupHeader": "À Emporter",
  "serviceModeDetailsUnavailable": "Indisponible",
  "serviceModeDetailsPickupWindowHeader": "Rapid Rescue Lane",
  "serviceModeDetailsPickupWindowDescription": "We will have your order ready inside. Drive up through rapid rescue lane and say your name at the window.",
  "serviceModePrompt": "Comment voulez-vous recevoir votre commande?",
  "serviceModePromptAlternative": "De quel type de commande s’agit-il?",
  "serviceVisit": "Points versés (soutien)",
  "sesame": "sésame",
  "setPickup": "Programmez votre mode de cuillette",
  "setRestaurantAsFavorite": "Régler ce restaurant en tant que favori",
  "setRewardsTierError": "Nous sommes désolés, mais nous n’avons pas réussi à fixer votre niveau de récompenses. Veuillez vérifier votre connexion et réessayer.",
  "share": "Partager",
  "sharedLandingButtonText": "Dérrroulez",
  "shareLocation": "Share location",
  "shareLocationToFindClosesStore": "{shareLocation} to find the closest store",
  "shareMyLocation": "Partager ma position",
  "shareRollsPageButtonText": "Partagez {rollsToShare} REBORD{rollsToShare, plural, =0 {} one {} other {S}}",
  "shareRollsPageRollToText": "REBORD{rollsRemaining, plural, =0 {} one {} other {S}} à",
  "shareYourLocation": "Partagez votre position",
  "shareYourRoll": "Partagez vos rebords et Déroulez pour gagner🅪!",
  "shellfish": "mollusques et crustacés",
  "shoppingCartPreview": "Aperçu du panier",
  "shortCodeConfirmationBody": "Vos Récompenses King ont été ajoutées à la commande.",
  "shortCodeConfirmationHeader": "Tout est prêt!",
  "showDetails": "Afficher les détails",
  "showLess": "Afficher moins",
  "showMeDeals": "Montrez-moi des offres",
  "showMore": "Afficher plus",
  "signIn": "Connexion",
  "signInCodeSentToEmailAgain": "Votre code de connexion a été envoyé à votre adresse courriel! Veuillez vérifier votre dossier de courriels indésirables",
  "signInCodeSentToPhoneAgain": "Votre code de connexion a été envoyé à votre numéro de téléphone!",
  "signInIfYouAlreadyHaveAccount": "Connectez-vous si vous avez déjà un compte",
  "signInOrCreateAccount": "Ouvrez une session ou créez un compte",
  "signinToRedeem": "Ouvrez une session pour réclamer votre récompense",
  "signInToViewOffers": "Connectez-vous pour consulter les offres",
  "signInToViewOrders": "Connectez-vous pour Voir les commandes.",
  "signOut": "Déconnexion",
  "signOutConfirmSubheader": "Voulez-vous vraiment vous déconnecter?",
  "signUp": "Inscription",
  "signupEmailSent": "Nous venons d’envoyer un courriel à {email}. Entrez le code de vérification ci-dessous",
  "signUpError": "Une erreur s’est produite lors de l’inscription",
  "signUpForBkRewards": "Inscrivez-vous aux BK Rewards",
  "signUpForPromotionalEmails": "Inscrivez-vous pour recevoir des courriels promotionnels",
  "signUpForPromotionalEmailsPopeyesRewards": "Oui! Je veux recevoir des courriels du Programme de récompenses Popeyes® afin d’accéder à des offres exclusives et aux dernières nouvelles sur les promotions Popeyes",
  "signUpNow": "Inscrivez-vous maintenant",
  "signUpSuccessSignInFail": "Votre compte a été créé. Veuillez ouvrir une session pour continuer.",
  "signUpToContinue": "Inscrivez-vous pour continuer",
  "signUpToContinueDisclaimer": "Les taxes et les frais seront calculés à l’étape finale. Connectez-vous ou inscrivez-vous pour vérifier et passer votre commande.",
  "signUpToCreateAccount": "Inscrivez-vous pour créer un compte.",
  "signUpToGetStartedWith": "Inscrivez-vous pour profiter des avantages {rewardsLink}",
  "signUpToRedeem": "Inscrivez-vous pour obtenir des offres",
  "signUpToStartEarning": "Inscrivez-vous aux Récompenses King afin de commencer à accumuler et à échanger des Couronnes BK en restaurants et en ligne.",
  "singleUseCupFee": "Frais tasse jetable",
  "sitemap": "Plan du site",
  "skip": "Passer",
  "skipTheLinePart1": "Sautez",
  "skipTheLinePart2": "la",
  "skipTheLinePart3": "file",
  "skipToContent": "Passer au contenu",
  "smallCartFee": "Frais pour petit panier",
  "smallCartFeeDescription": "Ces frais ne s’appliquent qu’aux commandes de moins de {amount}. Ajoutez des articles pour annuler ces frais.",
  "sodium": "Sodium",
  "someItemsAreUnavailableInSelectedStore": "Certains produits dans votre panier ne sont pas offerts à ce restaurant. Veuillez retirer ces produits afin de poursuivre votre sélection ou annulez pour conserver le restaurant actuel.",
  "someItemsAreUnavailableMessage": "Certains produits dans votre panier ne sont pas disponibles en ce moment au restaurant sélectionné. Veuillez supprimer ces sélections et réessayer.",
  "somethingMissingHere": "Il manque quelque chose ici",
  "somethingWentWrong": "Quelque chose n’a pas bien fonctionné",
  "somethingWrong": "Quelque chose n’a pas fonctionné",
  "sorrySomethingWentWrong": "Désolé, une erreur est survenue!",
  "soy": "soja",
  "specialOffers": "Offres Spéciales",
  "staffing": "Personnel",
  "standardOptions": "Options standards",
  "standardsOfBusinessPractices": "Normes de pratiques commerciales",
  "startCateringOrderNow": "Commencez maintenant votre commande du service de traiteur",
  "startChallenge": "Commencez le défi",
  "startEarningRewards": "Commencez à accumuler des récompenses",
  "startOrder": "Débuter une commande",
  "startOver": "Vous voulez recommencer?",
  "startOverDisclaimer": "Si vous choisissez de recommencer, votre panier sera vidé et vous commencerez une nouvelle commande.",
  "startOverInX": "Recommencez dans {timeRemaining}",
  "startPlayingMessage": "Vous pouvez maintenant commencer à jouer!",
  "startYourCateringOrderNow": "Commencez votre commande du service de traiteur maintenant",
  "startYourOrder": "Commencez à commander",
  "startYourOrderHerePart1": "Commencez votre",
  "startYourOrderHerePart2": "commande ici",
  "state": "État",
  "stateIsARequiredField": "Vous devez entrer un État",
  "stillThere": "Toujours là?",
  "storeAddress": "{name}{conditionalLinebreak}{address1}{linebreak}{city}, {stateProvince} {postalCode}",
  "storeClosed": "LE RESTAURANT QUE VOUS AVEZ SÉLECTIONNÉ N’EST PAS OUVERT EN CE MOMENT.",
  "storeConfirmationCateringDelivery": "Livraison de commande traiteur à {location}",
  "storeConfirmationCateringPickup": "Retirer la commande à {location}",
  "storeConfirmationCurbside": "Bordure à {location}",
  "storeConfirmationDefault": "Passez une commande à {location}",
  "storeConfirmationDelivery": "Livraison à {location}",
  "storeConfirmationDriveThru": "Drive Thru à {location}",
  "storeConfirmationEatIn": "Dîner à {location}",
  "storeConfirmationPickup": "Retirer à {location}",
  "storeConfirmationTakeout": "Commande à emporter à {location}",
  "storeDoesNotHaveMobileOrdering": "Ce restaurant n’a pas de commande mobile.",
  "storeInfo": "Infos et photos",
  "storeInfoAssistive": "Voir les informations pour ce restaurant",
  "storeIsClosingError": "Ce restaurant ferme bientôt et n’accepte pas les commandes mobiles.",
  "storeLocations": "Emplacement des restaurants",
  "storeLocator": "Localisateur de restaurant",
  "storeLocatorEmptyStateDefaultBody": "Rechercher un restaurant à proximité.",
  "storeLocatorEmptyStateDefaultTitle": "Trouver un restaurant",
  "storeLocatorEmptyStateFavoritesErrorBody": "Il semble qu’il y a eu un problème de notre côté. Essayez de recharger la page ou recherchez l’adresse dans l’onglet À proximité.",
  "storeLocatorEmptyStateFavoritesErrorTitle": "Oh ! Désolé.",
  "storeLocatorEmptyStateFavoritesNoAuthBody": "Pour enregistrer un restaurant dans vos favoris, ouvrez d’abord une session ou créez un compte.",
  "storeLocatorEmptyStateFavoritesNoAuthTitle": "Connectez-vous pour créer des favoris",
  "storeLocatorEmptyStateFavoritesNoStoresBody": "Appuie sur le cœur d’un restaurant pour l’enregistrer en tant que favori.",
  "storeLocatorEmptyStateFavoritesNoStoresTitle": "Nous n’avons pas trouvé de favoris",
  "storeLocatorEmptyStateNearbyErrorBody": "Il semble qu’il y a eu un problème de notre côté. Essayez de saisir l’adresse ou de trouver votre emplacement sur la carte.",
  "storeLocatorEmptyStateNearbyErrorTitle": "Oh ! Désolé.",
  "storeLocatorEmptyStateNearbyNoLocationBody": "Partage ta position pour que nous puissions te recommander des restaurants à proximité.",
  "storeLocatorEmptyStateNearbyNoLocationTitle": "Oups!",
  "storeLocatorEmptyStateNearbyNoStoresBody": "Aucun restaurant à proximité n’est disponible pour le moment. Essayez avec une autre adresse ou une autre ville.",
  "storeLocatorEmptyStateNearbyNoStoresTitle": "Nous n’avons pas trouvé de restaurants à proximité",
  "storeLocatorEmptyStateRecentErrorBody": "Il semble qu’il y a eu un problème de notre côté. Essayez de recharger la page ou recherchez l’adresse dans l’onglet À proximité.",
  "storeLocatorEmptyStateRecentErrorTitle": "Oh ! Désolé.",
  "storeLocatorEmptyStateRecentNoAuthBody": "Ouvrez d’abord une session ou créez un compte.",
  "storeLocatorEmptyStateRecentNoAuthTitle": "Ouvrez une session pour voir les restaurants récents",
  "storeLocatorEmptyStateRecentNoStoresBody": "Nous n’avons pas trouvé de restaurants récents. Changeons ça! Essaie d’entrer une adresse et de passer une commande.",
  "storeLocatorEmptyStateRecentNoStoresTitle": "Oh! Un moment",
  "storeNoOrderingBody": "Sélectionnez un autre emplacement ou venez nous voir en personne pour passer cette commande",
  "storeNoOrderingHeading": "LE RESTAURANT SÉLECTIONNÉ N’ACCEPTE PAS LES COMMANDES MOBILES EN CE MOMENT",
  "storeUnavailable": "Restaurant non disponible",
  "streetAddress": "Addresse",
  "submit": "Soumettre",
  "submitAnswer": "Dérrrouler maintenant",
  "submitEmail": "Soumettez votre adresse courriel",
  "submitFeedback": "Soumettre un commentaire",
  "subtotal": "Sous-total",
  "success": "C’est fait!",
  "successfulCardDeactivation": "Votre carte a bien été désactivée et votre carte numérique a été mise à jour",
  "successfullyCreatedSupportCase": "Votre demande de soutien a bien été soumise!",
  "successfullyUpdatedUserAccountInfo": "Les informations du compte ont été mises à jour.",
  "successfullyUpdatedCommunicationPreferences": "Préférences de communication ont été mises à jour",
  "sugar": "Sucres",
  "sulphurDioxide": "dioxyde de soufre",
  "sunday": "Dimanche",
  "support": "Soutien",
  "supportFormDeliveryActive": "La commande semble ne pas avoir encore été livrée; si vous avez encore besoin d’aide, veuillez nous contacter ci-dessous.",
  "supportFormOrderAlreadyRefunded": "Vous semblez avoir déjà reçu un remboursement pour cette commande; si vous avez encore besoin d’aide, veuillez nous contacter ci-dessous.",
  "supportFormRefundGenericError": "Nous sommes vraiment désolés qu’un problème soit survenu avec votre commande; veuillez décrire le problème ci-dessous.",
  "supportFormSelectIssue": "Sélectionnez votre problème",
  "supportSubcategoryTitle": "Problème : {category}",
  "supportTopics": "TODO",
  "supportTopicsAll": "Tous les sujets de soutien",
  "surprise": "surprise",
  "swipe": "Glisser la carte",
  "switchServiceModeToDeliveryBody": "Changez à Livraison pour passer une commande",
  "switchServiceModeToDeliveryButton": "Changez à Livraison",
  "switchServiceModeToDeliveryHeader": "Offre valable seulement pour la livraison",
  "switchServiceModeToPickupBody": "Changez à Cueillette pour passer une commande",
  "switchServiceModeToPickupButton": "Changez à Cueillette",
  "switchServiceModeToPickupHeader": "Offre valable seulement pour la cueillette",
  "switzerland": "Suisse",
  "tapAllowWhileUsing": "Tap Allow While Using",
  "tapAlwaysOrWileUsing": "Tap Always or While Using",
  "takeAdvantageOfLoyaltyProgram": "Profitez de notre Programme de récompenses",
  "takeout": "À emporter",
  "takeOut": "À emporter",
  "takeOutComingSoon": "À emporter BIENTÔT DISPONIBLE",
  "takeOutUnavailable": "L’option à emporter n’est pas disponible pour le moment",
  "tapImClose": "Appuyez sur « Je suis à proximité » quand vous êtes à 5 minutes pour que nous puissions préparer votre commande. Votre carte ne sera pas débitée d’ici là.",
  "tapImCloseCashPayment": "Appuyez sur « Je suis tout près » 5 minutes avant d’arriver afin que nous puissions préparer votre commande.",
  "tapImHere": "Appuyez sur « Je suis ici » à votre arrivée pour que nous puissions préparer votre commande. Votre carte ne sera facturée qu’à ce moment-là.",
  "tapImHereCashPayment": "Appuyez sur « Je suis ici » lorsque vous arrivez afin que nous puissions préparer votre commande.",
  "tapToOrder": "Tapez pour commander",
  "tastySwaps": "Échanges savoureux",
  "tax": "Taxes",
  "taxes": "Les taxes",
  "taxesAndFees": "Taxes et Frais",
  "taxesAndFeesButtonAriaLabel": "Afficher les taxes et les frais",
  "taxesAndFeesNextStep": "Calculés à l’étape finale",
  "tenMin": "10 min",
  "termsAccessibilityLabel": "Consultez les conditions pour {cardTitle}",
  "termsConditions": "Conditions",
  "termsConditionsRewards": "Modalites et Conditions FideliTim",
  "termsOfService": "Conditions de Service",
  "termsOfUse": "Conditions d’utilisation",
  "thankYou": "Merci!",
  "theCardBalanceIs": "La carte {cardNumber} indique un solde de",
  "theEmailAddressIsInvalid": "L’adresse courriel que vous avez indiquée n’est pas valide ou n’existe pas. Veuillez réessayer avec une adresse courriel valide, ou contactez le service d’assistance.",
  "thereAreErrorsInThisForm": "Il y a {numberOfErrors} erreurs dans ce formulaire",
  "thereIsAnErrorInThisForm": "Il y a une erreur dans ce formulaire",
  "theseAreOurFavorite": "Ce sont nos préférés",
  "thisIsScary": "C’est inquiétant. Il semble que vos services de localisation ne sont pas activés.",
  "thursday": "Jeudi",
  "time": "Heure",
  "timeline": "Historique",
  "timing": "Timing",
  "tip": "Pourboire",
  "tipAmountError": "Veuillez utiliser un caractère numérique.",
  "tipAmountMaxError": "Ne peut pas dépasser 50 $.",
  "tipDisclaimer": "* Le pourboire est calculé en fonction du sous-total avant toute remise, offre ou récompense applicable.",
  "tipField": "Entrez un montant pour le pourboire (dollars)",
  "titleAllowLocation": "Votre emplacement n’est pas disponible",
  "to": "à",
  "toAddress": "à {address}",
  "today": "aujourd’hui",
  "todayAlt": "Aujourd’hui",
  "toEarnRolls": "pour obtenir des rebords",
  "toggleMenu": "Basculer le menu",
  "togglePromoCode": "Entrez le code ici",
  "togglePromoCodeAria": "Cliquez ici pour ajouter un code promotionnel",
  "togglePromotionModal": "Consultez les promotions sauvegardées",
  "togglePromotionModalAria": "Cliquez ici pour consulter les promotions sauvegardées",
  "tomorrow": "demain",
  "tomorrowAlt": "Demain",
  "tooManyAddAccountAttempts": "Vous avez essayé d’ajouter un mode de paiement trop de fois en peu de temps. Veuillez réessayer plus tard.",
  "total": "Total",
  "totalAmountAdded": "Montant total ajouté",
  "totalChoices": "Sélectionnez jusqu’à concurrence de {amount}",
  "totalFat": "Total des lipides",
  "track": "Suivre",
  "trackOrder": "Suivre la commande",
  "trademarkAndCopyright": "TM et droits d’auteur 2021 Burger King Corporation.",
  "trademarkAndCopyrightPopeyes": "TM & © 2021 Popeyes Louisiana Kitchen, Inc.",
  "trademarks": "Marques de commerce",
  "transFat": "Lipides trans",
  "transfer": "Transférez",
  "transferBalance": "Transférer le solde",
  "transferBalanceCompletedMessage": "Félicitations! Votre solde a été transféré.",
  "transferBalanceDirections": "Commencez par entrer les chiffres au dos de la carte pour consulter votre solde.",
  "transferBalanceError": "Nous n’avons pas pu traiter votre demande. Veuillez réessayer ou appuyer sur {link} pour obtenir de l’aide.",
  "transferBalanceFromAnotherCard": "Transférez le solde d’une autre carte {cardAlias}",
  "transferBalanceFromAnotherCrownCard": "Transférez le solde d’une autre Carte royale",
  "transferBalanceFromCrownCard": "Transférez le solde de la Carte Royale",
  "transferBalanceFromPrepaidCard": "Transférez le solde de la carte {cardAlias}",
  "transferNow": "Transférez maintenant",
  "transferSuccessful": "Transfert réussi",
  "treatYourself": "Offrez-vous",
  "treeNuts": "noix",
  "trending": "Choix populaires",
  "troubleLoadingOrder": "Nous semblons avoir eu de la difficulté à traiter votre commande. Veuillez réessayer ou recommencer.",
  "tryAgain": "Essayer à nouveau",
  "tryEnablingYourLocation": "Essayez d’activer vos services de localisation dans les paramètres et essayez de nouveau afin d’obtenir votre bon pour un Whopper® gratuit.",
  "tryingToEnterPromoCode": "Vous voulez entrer un code promotionnel? Entrez-le {below}.",
  "tuesday": "Mardi",
  "twentyMin": "20 min",
  "uhoh": "Oh non...",
  "unableToPlaceOrder": "Impossible de passer la commande",
  "unableToReloadCardError": "Nous n’avons pas pu réapprovisionner votre carte.",
  "unauthenticatedFavoritesHeader": "Connectez-vous pour sauvegarder des favoris.",
  "unauthenticatedFavoritesSubtext": "Inscrivez-vous ou connectez-vous pour sauvegarder vos favoris.",
  "unauthenticatedRecentItemsHeader": "Connectez-vous pour voir les articles récents.",
  "unauthenticatedRecentItemsSubtext": "Connectez-vous ou créez un compte pour voir vos articles récents.",
  "unavailable": "Non disponible",
  "unavailableAtLocation": "Non disponible à cet emplacement",
  "unit": "No d’appartement",
  "unitedKingdom": "Royaume-Uni",
  "unitedStates": "États Unis",
  "unitNumber": "Unité {unitNumber}",
  "unitNumberLabel": "Bâtiment/Code porte",
  "unitNumberLabelRequired": "Bâtiment (Requis)",
  "unlock": "Déverrouiller",
  "unlockedPromoCodeOffer": "Offre déverrouillée",
  "unregisterYourCard": "Vous pouvez supprimer votre carte à tout moment dans les paramètres du compte.",
  "unsavedChangesModalDescription": "Vous avez apporté des changements au produit. Souhaitez-vous les sauvegarder?",
  "unsavedChangesModalHeader": "Les changements n’ont pas été sauvegardés",
  "unsupportedCardEntered": "La carte introduite n’est pas valide",
  "unsupportedCardType": "Désolé, nous n’acceptons pas {cardType} pour le moment.",
  "until": "jusqu’à",
  "unverified": "Non vérifié",
  "unwrapping": "Déroulement en cours…",
  "upArrowChevronIcon": "Flèche orientée vers le haut",
  "update": "Mettre à jour",
  "updateApp": "Mettre à jour l’appli",
  "updateBrowserLocationSettings": "Veuillez autoriser la géolocalisation dans les paramètres de votre navigateur et de votre système d’exploitation.",
  "updateCart": "Mettre à jour votre commande",
  "updateCartSuccess": "{itemName} mis à jour dans le panier.",
  "updateDeviceLocationSettings": "Dans les paramètres de votre dispositif veuillez autoriser l’accès de l’appli {brand} à votre localisation.",
  "updateInfoError": "Une erreur s’est produite durant la mise à jour de vos renseignements.",
  "updateItem": "Mettre à jour le produit",
  "updateLater": "Mettre à jour plus tard",
  "updateNow": "Mettre à jour maintenant",
  "updateOrderErrorMessage": "Une erreur s’est produite au moment de mettre à jour votre commande. Veuillez réessayer ou vous présenter au comptoir pour en discuter avec nous.",
  "updateQuantityAndItemWithPrice": "Mettre à jour {quantity} {itemName} pour {price}",
  "updateRewardLevel": "Mettre à jour",
  "uponOrderDelivery": "Lors de la livraison de la commande",
  "uponOrderPickup": "Lors de pickup de la commande",
  "upsellItemLabel": "{itemName} {calories} {price}",
  "upsizeEligible": "Vous êtes admissible à un format plus grand.",
  "upsizeForFree": "Un format plus grand gratuit!",
  "upsizeSelection": "Veuillez sélectionner votre format plus grand",
  "us-calories": "Calories",
  "us-carbohydrates": "Glucides",
  "us-cholesterol": "Cholestérol",
  "us-fat": "Acide gras",
  "us-fiber": "Fibre",
  "us-proteins": "Protéines",
  "us-salt": "Sel",
  "us-saturatedFat": "Acide gras saturé",
  "us-sodium": "Sodium",
  "us-sugar": "Sucre",
  "us-transFat": "Acide gras trans",
  "us-weight": "Poids",
  "useCodeAtCashier": "Utiliser le code à la caisse",
  "usedAllCoupons": "On dirait que vous avez utilisé tous les coupons actuels.\\nNos mises à jour sont récurrentes alors faites attention aux nouveaux.\\nMerci pour votre fidélité.",
  "useDifferentAddress": "Utiliser une adresse différente",
  "usePointsToday": "Utilisez vos points aujourd’hui",
  "userDoesNotExist": "Cet utilisateur n’existe pas",
  "useRewards": "Mon niveau de récompenses",
  "userPhoneVerified": "Vous avez vérifié votre numéro de téléphone avec succès",
  "useSavedAddressPhone": "Utiliser l’adresse et le numéro de téléphone sauvegardés",
  "validationCode": "Code de validation",
  "validationError": "Une erreur s’est produite lors de la création de votre commande Veuillez réessayer",
  "validationErrorHeading": "Erreur de commande",
  "verified": "Vérifié",
  "verify": "Vérifier",
  "verifyEmailForRewards": "Vérifiez votre courriel pour lier une carte physique",
  "verifyNow": "Vérifiez maintenant",
  "verifyYourEmail": "Confirmer courriel",
  "verifyYourEmailInstructions": "Pour avoir accès à toutes les fonctionnalités de votre compte, veuillez consulter le courriel.",
  "verifyYourPhoneInstructions": "Vérifiez votre numéro de téléphone pour vous connecter à l’aide de votre numéro de téléphone.",
  "verifyYourPhoneNumber": "Vérifiez votre numéro de téléphone",
  "view": "Afficher",
  "viewAll": "Afficher tout",
  "viewAllOffers": "Voir toutes les offres",
  "viewAllPurchases": "Consultez tous vos achats.",
  "viewAllRecentItems": "Consultez tous les produits récents.",
  "viewAllRewards": "Consultez toutes les récompenses",
  "viewCart": "Consultez le panier",
  "viewDetails": "Voir les détails",
  "viewDetailsWithOrderNumber": "Voir les détails de la commande numéro {ticketNumber}",
  "viewMenu": "Voir le menu",
  "viewOffers": "Voir les offres",
  "viewOrder": "Passer à la caisse",
  "viewOrderWithDescription": "Voir la commande {orderDesc}",
  "viewPurchasesAndOffers": "Consultez tous vos achats ici, y compris les points obtenus, échangés et ceux qui expireront bientôt.",
  "viewReceipt": "Voir le reçu",
  "viewRewards": "Consultez les récompenses",
  "viewTerms": "Consultez les conditions",
  "visits": "Visites",
  "visitsAwayFromReward": " visites avant votre prochaine récompense",
  "visitSupport": "veuillez consulter notre page de soutien",
  "visitSupportForHelpWithYourCurrentOrder": "Consultez le service de soutien afin d’obtenir de l’aide pour votre commande actuelle.",
  "visuallyHiddenOpenInAppOrBrowser": "Veuillez cliquer sur le bouton Ouvrir l’appli pour accéder à l’application mobile. Si vous voulez accéder au site Web, cliquez sur le bouton Continuer sur le site Web. Cela enverra un autre code d’ouverture de session que vous pourrez utiliser pour accéder au site Web.",
  "visuallyHiddenSelectItem": "Sélectionner {itemName}",
  "visuallyHiddenSignInCodeSentToEmail": "Nous avons envoyé un code de connexion à 6 chiffres à votre adresse courriel. Veuillez saisir le code dans le champ approprié.",
  "visuallyHiddenSignInCodeSentToPhone": "Nous avons envoyé un code de connexion de 6 chiffres à votre numéro de téléphone. Veuillez entrer ce code dans la fenêtre de saisie à l’écran.",
  "visuallyHiddenSignInSuccess": "Vous avez ouvert une session avec succès!",
  "visuallyHiddenVerifyingOTP": "Nous vérifions",
  "voucherCodeError": "Veuillez entrer un code promotionnel",
  "voucherNotFound": "Code promotionnel non valide ou expiré. Vous voulez associer votre carte physique? Entrez son numéro {above}.",
  "voucherUsed": "Ce code promotionnel a déjà été utilisé",
  "waitForReply": "Surveillez votre boîte de réception – nous répondons généralement dans les 24 à 48 heures.",
  "waysToOrder": "Façons de commander",
  "weArePreparingYourOrder": "Nous préparons votre commande et la placerons sur le comptoir avant lorsqu’elle sera prête.",
  "weCantWaitToServeYou": "Nous avons hâte de vous servir",
  "wednesday": "Mercredi",
  "weight": "Poids",
  "welcomeBack": "C’est un plaisir de vous revoir!",
  "welcomeToBkLoyalty": "Bienvenue à la nouvelle BK Loyalty",
  "welcomeToYour": "Bienvenue sur votre",
  "weNeedPermissionToUseYourLocation": "We Need Permission To Use Your Location",
  "weWillCallNameWhenYourOrderIsReady": "Nous appellerons {name} lorsque votre commande sera prête. ",
  "weWillEmailYouWithOrderStatus": "Nous vous enverrons un e-mail avec l’état de la commande une fois que nous aurons reçu des nouvelles du restaurant.",
  "weWillPrepareYourOrderAtTime": "Nous préparerons votre commande à {time}.",
  "howCanWeHelpYou": "En quoi pouvons-nous vous aider ?",
  "whatIsNew": "Quoi de neuf",
  "whatIsTheIssue": "Quel est le problème?",
  "whatWouldYouLikeToContactUsAbout": "À quel sujet aimeriez-vous nous contacter?",
  "wheat": "blé",
  "whoops": "Oups!",
  "whyDoYouNeedMyPhoneNumber": "Pourquoi avez-vous besoin de mon numéro de téléphone ?",
  "willPickUpOrder": "Comment vas-tu récupérer ta commande?",
  "willSendConfirmationTo": "Nous enverrons un courriel de confirmation à",
  "willSendYourRequestedInfoTo": "Nous vous enverrons l’information que vous avez demandée par courriel à {email}. Veuillez allouer environ une semaine pour le déroulement complet de ce processus.",
  "winners": "Gagnants",
  "work": "Travail",
  "wouldYouKikeToAdd": "Souhaitez-vous ajouter {amount} à votre Carte royale?",
  "wouldYouLikeToAddADifferentCard": "Souhaitez-vous ajouter une carte {cardAlias} différente?",
  "wouldYouLikeToAddToPrepaidCard": "Souhaitez-vous ajouter {amount} à votre {cardAlias} carte?",
  "wouldYouLikeToUseOneNowFreeItem": "Voulez-vous en réclamer une maintenant pour obtenir l’un de vos produits gratuitement?",
  "wow": "OUAH !",
  "xItems": "{xItems, plural, zero {} one {# produit} two {} few {} many {} other {# produits}}",
  "xMoreOrders": "Vous êtes sur la bonne voie! Encore {pointsUntil} visites avant d’obtenir un produit gratuit.",
  "xPointsUntilReward": "{points} {points, plural, un {point} autres {points}} avant votre prochaine récompense",
  "yallReady": "Vous êtes prêt?",
  "yay": "Bien!",
  "year": "Année",
  "yearPlaceholder": "AAAA",
  "yes": "Oui",
  "yesAddNewCard": "Oui, ajoutez une nouvelle {cardAlias} carte",
  "yesTransfer": "Oui, transférez {amount}",
  "youCanPayWithACardRightHere": "Vous pouvez payer avec une carte ici même!",
  "youCurrentlyHaveXRewards": "Vous avez actuellement {availableRedemptions} récompenses",
  "youHaveNumAvailableRewards": "Vous avez {availableRedemptions} récompenses.",
  "youHaveQuantityItemInCart": "Vous avez maintenant {itemQuantity}-{itemName} dans votre panier.",
  "youHaveSuccessfullySubmittedTheForm": "Votre formulaire a bien été envoyé",
  "youHaveXPoints": "VOUS AVEZ {pointsNumber} POINTS",
  "youMightBeFarAway": "You might be far away",
  "youQualifyFreeDelivery": "Vous avez droit à la livraison gratuite!",
  "youSeemFarAway": "You seem far away",
  "yourAddress": "Ton Adresse",
  "yourBalanceIs": "Votre solde actuel est de {currentBalance}.",
  "yourCardHasBeenCharged": "Votre carte a été débitée. Vous recevrez une confirmation par e-mail dans les 24 heures.",
  "yourCardHasBeenSuccessfullyRegistered": "Votre carte a été enregistrée avec succès.",
  "yourCardIsLinked": "Votre carte est liée!",
  "yourCart": "Ton panier",
  "yourCartWillBeClearedIfStartCateringOrder": "Votre panier sera vidé si vous commencez une commande du service de traiteur",
  "yourClosestStore": "Votre Restaurant le Plus Près",
  "yourClosestStoreX": "Le restaurant le plus près de chez vous est {storeAddress}",
  "yourCourier": "Votre service de livraison",
  "yourCrownOrders": "Les commandes avec vos Couronnes",
  "yourEmail": "Votre courriel",
  "youreXAwayFromFreeDelivery": "Il vous manque {amount} pour obtenir la livraison gratuite!",
  "yourFavorites": "Favoris",
  "yourFullName": "Votre Nom et Prénom",
  "yourLeastExpensiveItemFree": "Votre produit le moins dispendieux dans cette commande sera gratuit!",
  "yourLocationCannotAcceptDelivery": "Nous sommes désolés, votre restaurant ne peut pas accepter les commandes de livraison en ce moment.",
  "yourLocationHasBeenUpdated": "Votre emplacement a été mis à jour.",
  "yourLoyaltyHistory": "Les commandes avec vos récompenses",
  "yourName": "votre nom",
  "yourNewCardBalance": "Le nouveau {cardAlias} solde de votre carte est {amount}",
  "yourNewPaymentMethodHasBeenSuccessfullySaved": "Votre nouvelle méthode de paiement a bien été sauvegardée",
  "yourOrder": "Votre commande",
  "yourOrderHasBeenPlaced": "Votre commande a été passée en ligne avec celles d’autres clients et sera prête dès que possible!",
  "yourOrderIsBeingConfirmed": "Votre commande est en cours de confirmation!",
  "yourOrderIsBeingPrepared": "Votre commande est en cours de préparation!",
  "yourOrderIsBeingPreparedCurbside": "Votre commande est en cours de préparation. Nous vous l’apporterons sous peu.",
  "yourOrderIsConfirmed": "Votre commande est confirmée!",
  "yourPointsWillExpire": "Vos points expireront le",
  "yourRewards": "Vos récompenses",
  "yourRewardsCard": "Votre carte Récompenses",
  "yourSelectedRestaurantIs": "Vous avez sélectionné le restaurant suivant : {storeAddress}",
  "yourSelectedStore": "Votre restaurant sélectionné",
  "yourUpdateIsntSavedYetBody": "Votre mise à jour du niveau de récompense vers {selectedTierName} n’a pas encore été enregistrée. Sélectionnez mettre à jour le niveau de récompense.",
  "yourUpdateIsntSavedYetHeading": " Votre mise à jour n’a pas encore été enregistrée.",
  "yourXPointsAway": "Il vous manque {points} ",
  "youveEarnedAFreeItem": "Vous avez obtenu un produit gratuit!",
  "youWillBeCharged": "Vous serez facturé {reloadAmount}",
  "zipCode": "Code Postal",
  "zipCodeError": "Veuillez entrer un code postal canadien, américain ou britannique valide",
  "zipCodeInvalid": "Code postal non valide",
  "zipCodeRequiredError": "Vous devez entrer un code postal"
}
