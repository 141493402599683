import {
  ExtrinsicBoundaryDimensions,
  IntrinsicBoundaryDimensions,
  MeasuredDimensions,
} from './types';

export const isSVG = (format: string | undefined) => format === 'svg';

function deriveHeight({ aspectRatio, width }: { aspectRatio: number; width: number }): string {
  return Math.round(width / aspectRatio) + 'px';
}

function deriveWidth({ aspectRatio, height }: { aspectRatio: number; height: number }): string {
  return Math.round(aspectRatio * height) + 'px';
}

export function getBoundaryDimensions({
  extrinsicDimensions,
  intrinsicDimensions,
  measuredDimensions,
}: {
  extrinsicDimensions: ExtrinsicBoundaryDimensions;
  intrinsicDimensions: IntrinsicBoundaryDimensions;
  measuredDimensions: MeasuredDimensions;
}): { width?: string | number; height?: string | number } {
  if (extrinsicDimensions.width && extrinsicDimensions.height) {
    // Both dimensions are explicitly provided. Use them.
    return { width: extrinsicDimensions.width, height: extrinsicDimensions.height };
  }

  if (extrinsicDimensions.height) {
    // Height is explicitly provided. Derive the width.
    return {
      width: deriveWidth({
        aspectRatio: intrinsicDimensions.aspectRatio,
        height:
          typeof extrinsicDimensions.height === 'number'
            ? extrinsicDimensions.height
            : measuredDimensions.height,
      }),
      height: extrinsicDimensions.height,
    };
  }

  return {
    width: extrinsicDimensions.width || measuredDimensions.width || '100%',
    height: deriveHeight({
      aspectRatio: intrinsicDimensions.aspectRatio,
      width:
        typeof extrinsicDimensions.width === 'number'
          ? extrinsicDimensions.width
          : measuredDimensions.width,
    }),
  };
}
