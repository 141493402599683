import { useCallback, useMemo, useState } from 'react';

import { useApiKey } from 'hooks/configs/use-api-key';
import useEffectOnce from 'hooks/use-effect-once';
import { geolocationLibraryIsLoaded, loadGeolocationLibrary } from 'utils/geolocation';

export default function useGoogleGeolocationLibrary() {
  const googleMapsApiKey = useApiKey({ key: 'googleMaps', isRegionalized: false });
  const [libraryLoaded, setLibraryLoaded] = useState(geolocationLibraryIsLoaded());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const demandLibrary = useCallback(async () => {
    if (!libraryLoaded) {
      try {
        setLoading(true);
        await loadGeolocationLibrary(googleMapsApiKey);
      } catch (e) {
        setError(true);
      } finally {
        setLibraryLoaded(geolocationLibraryIsLoaded());
        setLoading(false);
      }
    }
  }, [googleMapsApiKey, libraryLoaded]);

  useEffectOnce(() => {
    demandLibrary();
  });

  return useMemo(
    () => ({
      demandLibrary,
      error,
      libraryLoaded,
      loading,
    }),
    [demandLibrary, error, libraryLoaded, loading]
  );
}
