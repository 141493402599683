import { useMemo } from 'react';

import { IMenuByDayPartProps } from '@fhs-legacy/frontend/src/hooks/use-menu-by-day-part';
import { IMainMenuContext, IMenuObjectState } from '@fhs-legacy/frontend/src/state/main-menu';
import { useMenuContext } from '@fhs-legacy/frontend/src/state/menu';
import { ProductMenuObject } from '@fhs-legacy/frontend/src/state/product-wizard/types';

function curriedTryGetMenuObject(
  getMenuObject: IMainMenuContext['getMenuObject']
): (id: string) => IMenuObjectState {
  return (id: string) => {
    try {
      return getMenuObject(id);
    } catch (err) {
      return {
        loading: false,
        data: null,
      };
    }
  };
}

export function useLegacyMenuStates({ productId }: { productId?: string }) {
  const { getMenuObject } = useMenuContext();
  const tryGetMenuObject = useMemo(() => curriedTryGetMenuObject(getMenuObject), [getMenuObject]);
  const menuObjectData = productId
    ? (tryGetMenuObject(productId) as IMenuByDayPartProps)
    : undefined;
  const product = (menuObjectData as IMenuObjectState)?.data as ProductMenuObject;

  return {
    getMenuObject,
    tryGetMenuObject,
    product,
    loading: menuObjectData?.loading,
  };
}
