import { Svg, SvgPath, type SvgProps, registerSVG } from '../../../src/components/svg';

export function Curbside(props: SvgProps) {
  return (
    <Svg {...props} data-legacy-icon viewBox="0 0 24 24">
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.559162 5H4.32079C4.62961 5 4.87996 5.25584 4.87996 5.57143V9.48052C4.87996 9.79611 4.62961 10.0519 4.32079 10.0519H3.20003C3.20165 10.069 3.20248 10.0864 3.20248 10.1039V19.4546C3.20248 19.7486 2.9692 19.987 2.68144 19.987H2.19853C1.91077 19.987 1.67749 19.7486 1.67749 19.4546V10.1039C1.67749 10.0864 1.67832 10.069 1.67994 10.0519H0.559162C0.250345 10.0519 0 9.79611 0 9.48052V5.57143C0 5.25584 0.250345 5 0.559162 5Z"
      />
      <SvgPath d="M21.92 5.01C21.72 4.42 21.16 4 20.5 4H9.5C8.84 4 8.29 4.42 8.08 5.01L6 11V19C6 19.55 6.45 20 7 20H8C8.55 20 9 19.55 9 19V18H21V19C21 19.55 21.45 20 22 20H23C23.55 20 24 19.55 24 19V11L21.92 5.01ZM9.5 15C8.67 15 8 14.33 8 13.5C8 12.67 8.67 12 9.5 12C10.33 12 11 12.67 11 13.5C11 14.33 10.33 15 9.5 15ZM20.5 15C19.67 15 19 14.33 19 13.5C19 12.67 19.67 12 20.5 12C21.33 12 22 12.67 22 13.5C22 14.33 21.33 15 20.5 15ZM8 10L9.5 5.5H20.5L22 10H8Z" />
    </Svg>
  );
}

registerSVG('Curbside', Curbside);
