import * as React from 'react';

import { IBackendCartEntries } from '@rbi-ctg/menu';
import { CartEntryType } from 'utils/cart/types';

import { EntryDescriptionRow } from './cart-entry.styled';

const resolveEntryChild = (
  entry: IBackendCartEntries,
  currencyFormatter: Function,
  showAllPrices: boolean
) => {
  if (!entry.children) {
    return null;
  }

  const resolveChild = (child: IBackendCartEntries, i: number): React.ReactNode =>
    child?.children?.length ? (
      child.children.map(resolveChild)
    ) : (
      <EntryDescriptionRow key={child.name! + i}>
        {child.name}
        {(child.type === CartEntryType.itemOptionModifier || showAllPrices) &&
          Boolean(child?.price) &&
          ` - ${currencyFormatter(child.price)}`}
      </EntryDescriptionRow>
    );

  return entry.children.map(resolveChild);
};

export default resolveEntryChild;
