import { gql } from '@apollo/client';

export default gql`
  fragment VideoFileFragment on File {
    asset {
      assetId
      path
      url
    }
  }
`;
