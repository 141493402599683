import React, { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';

import { useServiceModeContext } from 'state/service-mode';

import useSearchRestaurants from '../hooks/use-search-restaurants';
import useStoreLocatorView from '../hooks/use-store-locator-view';

import { IStoreLocatorContext } from './types';

export const StoreLocatorContext = createContext<IStoreLocatorContext>({} as IStoreLocatorContext);

export const useStoreLocatorContext = () => useContext<IStoreLocatorContext>(StoreLocatorContext);

export const StoreLocatorProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}: PropsWithChildren<{}>) => {
  const { ErrorDialog, Dialog } = useSearchRestaurants();
  const { currentView, updateStoreLocatorView, currentViewIsMap } = useStoreLocatorView();
  const { serviceMode } = useServiceModeContext();

  const value = useMemo(
    () => ({
      ErrorDialog,
      Dialog,
      currentView,
      updateStoreLocatorView,
      serviceMode,
      currentViewIsMap,
    }),
    [ErrorDialog, Dialog, currentView, updateStoreLocatorView, serviceMode, currentViewIsMap]
  );

  return <StoreLocatorContext.Provider value={value}>{children}</StoreLocatorContext.Provider>;
};
