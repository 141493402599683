import React from 'react';

import CartErrorHeading from '../cart-error-heading';

import { Heading } from './styled';

interface ICartHeading {
  modalHeading?: string;
}

const HeadingContainer = ({ modalHeading }: ICartHeading = {}) => (
  <Heading>
    <CartErrorHeading>{modalHeading}</CartErrorHeading>
  </Heading>
);

export default HeadingContainer;
