import { router } from 'expo-router';
import { useCallback, useRef } from 'react';

import { ISection, MenuObject } from '@rbi-ctg/menu';
import { selectors, useAppSelector } from 'state/global-state';
import { isLegacyOffer, isLoyaltyOffer } from 'state/loyalty/types';
import { useOffersContext } from 'state/offers';
import { isCombo, isPicker } from 'utils/menu';
import { routes } from 'utils/routing';

export const useSelectedOffer = () => {
  const { selectedOffer: selectedLegacyOffer } = useOffersContext();
  const selectedLoyaltyOffer = useAppSelector(selectors.loyalty.selectSelectedOffer);

  return selectedLoyaltyOffer || selectedLegacyOffer;
};

export const useHandleMenuItemInOffer = (setIsOffer: (isOffer: boolean) => void) => {
  const selectedOffer = useSelectedOffer();

  const menuObjectIdRef = useRef<string | undefined>();

  return useCallback(
    (menuItem: MenuObject | Partial<ISection>) => {
      const alreadyChecked = !!menuItem._id && menuObjectIdRef.current === menuItem._id;
      if (alreadyChecked) {
        return;
      }
      menuObjectIdRef.current = menuItem._id;

      const isOfferBenefit = Boolean(
        (isCombo(menuItem) || isPicker(menuItem)) && menuItem?.isOfferBenefit
      );
      setIsOffer(isOfferBenefit);

      const isSelectedOfferBenefit = () => {
        let result = false;
        const menuItemId = menuItem._id;

        if (isLoyaltyOffer(selectedOffer)) {
          result = Boolean(
            selectedOffer?.incentives?.some(
              incentive => incentive?.__typename !== 'SwapMapping' && incentive?._id === menuItemId
            )
          );
        } else if (isLegacyOffer(selectedOffer)) {
          result = selectedOffer?.option?._id === menuItemId;
        }

        return result;
      };

      // This will prevent the case of a user going back to the product wizard for an offer item
      // without having the offer context causing price to show 0 and the item not adding to cart
      if (isOfferBenefit && (!selectedOffer || (selectedOffer && !isSelectedOfferBenefit()))) {
        router.navigate(routes.menu);
      }
    },
    [selectedOffer, setIsOffer]
  );
};
