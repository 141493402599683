import { useCallback, useEffect, useRef } from 'react';

import { useBoxRef } from '@fhs/utils';

export function useDebouncedCallback<ArgT>(cb: (args: ArgT) => void, waitMs = 100) {
  const cbRef = useBoxRef(cb);
  const timeoutRef = useRef<number>();

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return useCallback(
    (arg: ArgT) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = window.setTimeout(() => cbRef.current(arg), waitMs);

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    },
    [waitMs, cbRef]
  );
}
