import { router, useLocalSearchParams } from 'expo-router';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import ItemUnavailableMessage from 'components/item-unavailable-message';
import { useIsDesktopBp } from 'hooks/breakpoints';
import { appendObjectToQueryString } from 'hooks/navigation/utils';
import { useMenuContext } from 'state/menu';
import { useProductWizardContext } from 'state/product-wizard';
import { routes } from 'utils/routing';

import { useProductAvailability } from '../use-product-availability';

import { PRODUCT_CTA_LABEL_ID } from './constants';
import { ProductCtaWrapper } from './product-cta-wrapper';

export const ProductStaticMenuCta: React.FC<
  React.PropsWithChildren<{
    showStaticPickers?: boolean;
  }>
> = ({ showStaticPickers }) => {
  const params = useLocalSearchParams();
  const { setSelectedStaticMenuItem } = useMenuContext();
  const { menuObject } = useProductWizardContext();

  const { formatMessage } = useIntl();
  const { dayParts, isItemAvailableForDaypart, itemDayParts } = useProductAvailability();
  const isDesktop = useIsDesktopBp();

  const handleFindRestaurantClick = useCallback(() => {
    const { _type, _id, name } = menuObject;
    const menuObjectId = `${_type}-${_id}`;
    setSelectedStaticMenuItem({
      itemId: menuObjectId,
      itemName: name?.locale,
      search: appendObjectToQueryString('', params),
    });
    router.replace(routes.storeLocator);
  }, [menuObject, params, setSelectedStaticMenuItem]);

  return (
    <ProductCtaWrapper
      elements={[
        {
          element: isItemAvailableForDaypart ? (
            <ActionButton onPress={handleFindRestaurantClick} fullWidth>
              {formatMessage({ id: PRODUCT_CTA_LABEL_ID })}
            </ActionButton>
          ) : (
            <ItemUnavailableMessage
              dayParts={dayParts}
              itemDayParts={itemDayParts}
              marginAndBorders={isDesktop && !showStaticPickers}
            />
          ),
        },
      ]}
    />
  );
};
