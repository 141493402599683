import { Box, HStack, Header, ScrollView, Text } from '@fhs-legacy/universal-components';
import Picture from 'components/picture';
import SanityBlockRenderer from 'components/sanity-block-renderer';

export const Container = ScrollView.withConfig<{ hasSignUpButton?: boolean }>(
  ({ hasSignUpButton = false }) => ({
    backgroundColor: 'token.background-default',
    maxWidth: '520px',
    width: 'full',
    marginX: 'auto',
    marginBottom: hasSignUpButton ? { base: '$20', desktop: '$10' } : '$10',
    paddingX: { base: '$4', desktop: '$0' },
    paddingTop: { base: '$6', desktop: '$10' },
  })
);

export const HeadingText = Header.withConfig({
  accessibilityLevel: 1,
  margin: 0,
  color: Styles.color.secondary,
  fontSize: '5xl',
  lineHeight: '2xs',
  textAlign: 'center',
  textTransform: Styles.textTransform.headlines,
});

export const LogoContainer = Picture.withConfig({
  width: '164px',
  alignSelf: 'center',
  marginBottom: '$1',
});

export const ImageContainer = Picture.withConfig<{ $windowHeight?: number }>(
  ({ $windowHeight }) => ({
    width: 'full',
    maxWidth: '80%',
    height: $windowHeight ? { base: 0.2 * $windowHeight, legacy_sm: 0.28 * $windowHeight } : 'auto',
    marginY: '$1',
    marginX: 'auto',
  })
);

export const TitleText = Header.withConfig<{ $fontColor?: string }>({
  variant: 'headerOne',
  accessibilityLevel: 2,
  textTransform: Styles.textTransform.headlines,
  textAlign: 'center',
  marginBottom: '$2',
});

export const SubtitleText = Text.withConfig({
  variant: 'copyThree',
  textAlign: 'center',
  marginBottom: '$6',
});

export const DescriptionTextBlock = SanityBlockRenderer.withConfig({
  textProps: {
    marginX: 'auto',
    marginBottom: '$4',
    fontSize: 'md',
    fontFamily: 'body',
    maxWidth: '95%',
  },
});

export const ButtonWrapper = Box.withConfig({
  marginX: 'auto',
  alignItems: 'center',
  flex: 1,
  width: 'full',
  position: 'absolute',
  bottom: 0,
  height: '$20',
  paddingX: '$4',
  justifyContent: 'center',
  borderTopColor: 'token.border-color-default',
  borderBottomColor: 'token.border-color-default',

  background: { base: 'token.background-fixed-bottom', desktop: 'transparent' },
  borderTopWidth: { base: '1', desktop: 0 },
  borderBottomWidth: { base: '1', desktop: 0 },
});

export const ButtonContainer = Box.withConfig({
  marginBottom: '$6',
  alignItems: 'center',
});

export const DesktopBar = HStack.withConfig({
  width: '100%',
  height: '75px',
  position: 'absolute',
  bottom: 0,
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingY: '$1',
  paddingX: '$4',
  backgroundColor: Styles.color.white,
});

export const FullPageImage = Picture.withConfig({
  height: '100%',
  width: '100%',
});

export const Terms = SanityBlockRenderer.withConfig({
  paddingTop: '$4',
  textAlign: 'center',
  textProps: {
    textAlign: 'center',
    marginTop: '$4',
    marginBottom: '$8',
    marginX: 'auto',
    fontSize: 'sm',
  },
});
