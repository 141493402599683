import { usePathname } from 'expo-router';
import React, { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { SceneRendererProps } from 'react-native-tab-view';

import { TabView } from 'components/tab-view';
import { ITab } from 'components/tab-view/types';
import useEffectOnce from 'hooks/use-effect-once';
import { useGetFavoriteRestaurants } from 'hooks/use-get-favorite-restaurants';
import { useGetNearbyRestaurants } from 'hooks/use-get-nearby-restaurants';
import { useGetRecentRestaurants } from 'hooks/use-get-recent-restaurants';
import { useAuthContext } from 'state/auth';
import { useCRMEventsContext } from 'state/crm-events';
import { OrderLocatorTab } from 'state/launchdarkly/variations';

import { StoreCardList } from '../store-card-list';

import { StoreCardListContainer } from './list-view.styled';

const intlIdMap: { [K in OrderLocatorTab]: string } = {
  [OrderLocatorTab.NEARBY]: 'nearby',
  [OrderLocatorTab.RECENT]: 'recents',
  [OrderLocatorTab.FAVORITE]: 'favorite',
  [OrderLocatorTab.DELIVERY]: 'delivery',
};

interface IListViewProps {
  mapView__experiment?: ReactNode;
}

const ListView: React.FC<IListViewProps> = () => {
  const { formatMessage } = useIntl();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const { isAuthenticated } = useAuthContext();
  const { logStoreLocatorTabView } = useCRMEventsContext();
  const pathname = usePathname();

  const availableTabs = isAuthenticated
    ? [OrderLocatorTab.NEARBY, OrderLocatorTab.FAVORITE, OrderLocatorTab.RECENT]
    : [OrderLocatorTab.NEARBY];

  const tabs = availableTabs.map(key => ({
    key,
    title: formatMessage({ id: intlIdMap[key] }),
  }));

  const favoriteTabIndex = tabs.findIndex(tab => tab.key === OrderLocatorTab.FAVORITE);

  useEffectOnce(() => {
    // Logs the first tab title when opening the Store Locator
    logStoreLocatorTabView(pathname, tabs[0].title);
  });

  // As we need to pass additional props, we have to use a custom renderScene function instead of SceneMap
  // Do not return inline functions because  If you pass inline functions, it'll re-create the component every render, which will cause the entire route to unmount and remount every change. It's very bad for performance and will also cause any local state to be lost.
  const renderScene = ({ route }: SceneRendererProps & { route: ITab }) => {
    if (route.key === OrderLocatorTab.NEARBY) {
      return (
        <StoreCardListContainer>
          <NearbyList />
        </StoreCardListContainer>
      );
    } else if (route.key === OrderLocatorTab.RECENT) {
      return (
        <StoreCardListContainer>
          <RecentsListView />
        </StoreCardListContainer>
      );
    }

    // This makes sure favorites tab gets re-rendered every time
    // This is necessary to get the updated list in case users favorited a store on Nearby, then selected favorite tab
    if (currentTabIndex !== favoriteTabIndex) {
      return null;
    }

    return (
      <StoreCardListContainer>
        <FavoritesListView />
      </StoreCardListContainer>
    );
  };

  return (
    <TabView
      activeTabIndex={currentTabIndex}
      handleTabIndexChange={(index: number) => {
        const tabName = tabs[index]?.title || '';

        setCurrentTabIndex(index);
        logStoreLocatorTabView(pathname, tabName);
      }}
      tabs={tabs}
      renderScene={renderScene}
      lazy
    />
  );
};

const NearbyList = () => {
  const { restaurants, loading, error } = useGetNearbyRestaurants();

  return (
    <StoreCardList
      currentTabHasError={!!error}
      isSearching={loading}
      restaurants={restaurants}
      tab={OrderLocatorTab.NEARBY}
    />
  );
};

const RecentsListView = () => {
  const { restaurants, loading, error } = useGetRecentRestaurants();

  return (
    <StoreCardList
      currentTabHasError={!!error}
      isSearching={loading}
      restaurants={restaurants}
      tab={OrderLocatorTab.RECENT}
    />
  );
};

const FavoritesListView = () => {
  const { restaurants, loading, error } = useGetFavoriteRestaurants();

  return (
    <StoreCardList
      currentTabHasError={!!error}
      isSearching={loading}
      restaurants={restaurants}
      tab={OrderLocatorTab.FAVORITE}
    />
  );
};

export default ListView;
