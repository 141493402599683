import { brand as brandShort, env, fullBrandName } from 'utils/environment';

export const PYPESTREAM_SCRIPT_ID = 'pypestream-script';
export const PYPESTREAM_INTERFACE_ID = 'pypestream-interface';

export const REDIRECT_TO_LOGIN = 'REDIRECT_TO_LOGIN';
export const PYPESTREAM_MOUNTED = 'PYPESTREAM_MOUNTED';

export const BRAND = fullBrandName();
export const BRAND_SHORT = brandShort();
export const STAGE = env();
