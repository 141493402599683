import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';

import { Button, IconLocationFill } from '@fhs/ui';
import { Spinner } from '@fhs-legacy/universal-components';
import { GeolocationDeniedModal } from 'components/geolocation-denied-modal';
import { useGeolocation } from 'state/geolocation';

import { IResetLocationButtonProps } from './types';

export const ResetLocationButton: React.FC<React.PropsWithChildren<IResetLocationButtonProps>> = ({
  onButtonPress,
  loading,
}) => {
  const { formatMessage } = useIntl();

  const {
    isPermissionGranted,
    isPermissionPrompt,
    isPermissionDenied,
    setPermissionChecking,
    loadingUserCoordinates,
  } = useGeolocation();

  const [geolocationDeniedModalOpen, setGeolocationDeniedModalOpen] = useState(false);

  const dismissModal = () => setGeolocationDeniedModalOpen(false);

  const handlePress = useCallback(() => {
    if (isPermissionDenied) {
      setGeolocationDeniedModalOpen(true);
    } else if (isPermissionPrompt) {
      setPermissionChecking();
    } else {
      onButtonPress();
    }
  }, [isPermissionDenied, isPermissionPrompt, setPermissionChecking]);

  const getStartIcon = () => {
    if (loading || loadingUserCoordinates) {
      return <Spinner size="sm" />;
    }

    return <IconLocationFill size={24} />;
  };

  useEffect(() => {
    if (isPermissionDenied) {
      setGeolocationDeniedModalOpen(true);
    }
  }, [isPermissionDenied]);

  useEffect(() => {
    if (isPermissionGranted) {
      setGeolocationDeniedModalOpen(false);
    }
  }, [isPermissionGranted]);

  return (
    <>
      <GeolocationDeniedModal isOpen={geolocationDeniedModalOpen} dismissModal={dismissModal} />
      <Button
        type="ghost"
        size="xl"
        accessibilityLabel={formatMessage({ id: 'resetLocationButtonAria' })}
        testID="reset-location-button"
        onPress={handlePress}
        disabled={loading}
        style={styles.bgWhite}
        focusedStyle={styles.bgWhite}
        pressedStyle={styles.bgWhite}
      >
        <Button.Icon>{getStartIcon()}</Button.Icon>
      </Button>
    </>
  );
};

export default ResetLocationButton;

const styles = StyleSheet.create({
  bgWhite: {
    backgroundColor: 'white',
  },
});
