import React, { createContext, useContext, useMemo, useState } from 'react';

import { IBaseProps, IBringgUuids } from '@rbi-ctg/frontend';

interface IDeliveryConfirmationContext {
  bringgUuids: IBringgUuids;
  setBringgUuids: (bringgUuids: IBringgUuids) => void;
}

export const DeliveryConfirmationContext = createContext<IDeliveryConfirmationContext>(
  {} as IDeliveryConfirmationContext
);

export const useDeliveryConfirmationContext = () => useContext(DeliveryConfirmationContext);

export const DeliveryConfirmationProvider = ({ children }: IBaseProps) => {
  const [bringgUuids, setBringgUuids] = useState<IBringgUuids>({});
  const value = useMemo(() => ({ bringgUuids, setBringgUuids }), [bringgUuids]);

  return (
    <DeliveryConfirmationContext.Provider value={value}>
      {children}
    </DeliveryConfirmationContext.Provider>
  );
};

export default DeliveryConfirmationContext.Consumer;
