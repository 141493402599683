import { ComponentProps } from 'react';

import { Icon, Text } from '@fhs-legacy/universal-components';

interface BaseChipProps {
  /** The text on the Chip should use sentence case, e.g., "Not available" */
  children: string;

  /** Used to locate this view in end-to-end tests. */
  testID?: string;

  /** Overrides the label that's read by the screen reader when a user interacts with the element. */
  accessibilityLabel?: string;

  /** UCL/NB Text component props */
  _text?: ComponentProps<typeof Text>;
}

type ChipIconProps = {
  /** UCL Icon component props */
  icon?: ComponentProps<typeof Icon>;

  /** To enable rounding the corners of the chip */
  roundedCorners?: boolean;
};

type ChipCornerProps = { icon?: never; roundedCorners?: boolean };

export type ChipProps = BaseChipProps & (ChipIconProps | ChipCornerProps);
