import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { AutocompleteAddressInput } from 'components/autocomplete-address-input';
import { useIsDesktopBp } from 'hooks/breakpoints';
import { useGeolocation } from 'state/geolocation';
import { STORE_LOCATOR_SEARCH_INPUT } from 'utils/test-ids';

import { ISearchBarProps } from './types';

export const SearchBar = ({ defaultValue }: ISearchBarProps) => {
  const { formatMessage } = useIntl();
  const isDesktop = useIsDesktopBp();
  const { setManualAddress, updateManualCoordinates, manualAddress, isPermissionGranted } =
    useGeolocation();

  const [inputValue, setInputValue] = useState<string | undefined>(
    defaultValue || manualAddress || ''
  );

  const onInputValueChange = useCallback(
    (address: string | undefined | null) => {
      setManualAddress(address || '');
    },
    [setManualAddress]
  );

  return (
    <AutocompleteAddressInput
      autoFocus={!manualAddress && isPermissionGranted && isDesktop}
      required
      value={inputValue}
      onInputValueChange={setInputValue}
      onAddressSelected={(address, placeId) => {
        setInputValue(address);
        updateManualCoordinates(address, placeId);
      }}
      onBlur={() => {
        onInputValueChange(inputValue);
      }}
      label={formatMessage({ id: 'yourAddress' })}
      testID={STORE_LOCATOR_SEARCH_INPUT}
    />
  );
};

export default SearchBar;
