import { Box } from '@fhs-legacy/universal-components';
import Picture from 'components/picture';
import { WEB_APP_CTA_MAX_WIDTH, WEB_APP_DESKTOP_LG_MAX_WIDTH } from 'utils/constants';

export * from './styled.base';

export const TotalsContainer = Box.withConfig({
  paddingTop: '$1.5',
  paddingBottom: '22px',
  marginY: '$6',
  gap: '$1',
});

export const WarningContainer = Box.withConfig({
  paddingBottom: '$3',
  paddingTop: 0,
  marginRight: '$3',
});

export const CtaWrapper = Box.withConfig({
  flex: 1,
  width: { base: 'full', desktop: WEB_APP_CTA_MAX_WIDTH },
  maxW: WEB_APP_CTA_MAX_WIDTH,
  alignSelf: 'center',
});

export const CartDetailsContainer = Box.withConfig({
  width: '100%',
  maxWidth: WEB_APP_DESKTOP_LG_MAX_WIDTH,
  alignSelf: 'center',
  paddingX: '$4',
  paddingY: 0,
});

const mainContainerStyles = {
  maxWidth: WEB_APP_DESKTOP_LG_MAX_WIDTH,
  alignSelf: 'center',
  paddingX: {
    desktop: '$4',
  },
  width: 'full',
  marginTop: {
    desktop: '$10',
  },
};

export const MapContainer = Box.withConfig({
  height: '280px',
  margin: '0',
  ...mainContainerStyles,
});

export const StyledPicture = Picture.withConfig({
  height: '140px',
  ...mainContainerStyles,
});
