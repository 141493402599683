import React, { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@fhs-legacy/universal-components';
import { IOffer } from '@rbi-ctg/menu';
import ActionButton from 'components/action-button';
import Modal, { ModalContent, ModalHeading } from 'components/modal';
import { countDown } from 'components/offer/utils';
// import SanityBlockRenderer, {
//   IBlockContentOverrides,
//   ISerializers,
//   PassthroughComponent,
// } from 'components/sanity-block-renderer';

import {
  ButtonContainer,
  // Description,
  ExpiryText,
  // Name,
  NotFoundContainer,
  NotFoundDescription,
  NotFoundName,
  SelectionContainer,
  StyledList,
} from './saved-promotions-modal.styled';
import { ISavedPromotionsModalProps } from './types';

// const nameBlockContentOverrides: IBlockContentOverrides = {
//   normal: Name,
// };

// const descriptionBlockContentOverrides: IBlockContentOverrides = {
//   normal: Description,
// };

// const nameBlockSerializers: ISerializers = {
//   marks: {
//     em: PassthroughComponent,
//   },
//   container: PassthroughComponent,
// };

// const descriptionBlockSerializers: ISerializers = {
//   container: PassthroughComponent,
// };

const StyledModalHeading = ModalHeading.withConfig({
  marginTop: '$10',
});

const SavedPromotionsModal: FC<React.PropsWithChildren<ISavedPromotionsModalProps>> = ({
  onDismiss,
  onSelectPromotion,
  cartPromoCodeOffers,
}) => {
  const [selectedOffer /*, setSelectedOffer*/] = useState<IOffer | null>(null);
  const { formatMessage } = useIntl();

  // only display cart promotion offers redeemable by the user
  const redeemableCartPromoCodeOffers = cartPromoCodeOffers.filter(o => o.isRedeemable);

  const shouldDisplayOptions = !!redeemableCartPromoCodeOffers.length;
  const shouldDisableButton = !selectedOffer || !shouldDisplayOptions;
  const handleOnSelect = useCallback(() => {
    onSelectPromotion(selectedOffer);
  }, [selectedOffer, onSelectPromotion]);

  return (
    <Modal
      onDismiss={onDismiss}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Saved Promotions',
      }}
    >
      <ModalContent>
        <StyledModalHeading testID="saved-promotions">
          {formatMessage({ id: 'choosePromotion' })}
        </StyledModalHeading>
        <StyledList>
          {shouldDisplayOptions ? (
            redeemableCartPromoCodeOffers.map((offer, index) => {
              return (
                <SelectionContainer key={index}>
                  {/*
                  TODO: RN - need to find a Radio migration for UCL

                  <RadioButton
                    checked={selectedOffer === offer}
                    onChange={() => setSelectedOffer(offer)}
                    disabled={false}
                    id={`promotion-${index}`}
                    accessibilityLabel={formatMessage({ id: 'choosePromotion' })}
                    testID="promotion-select"
                    labelHeader={
                      <SanityBlockRenderer
                        blockContentOverrides={nameBlockContentOverrides}
                        serializers={nameBlockSerializers}
                        content={offer?.name?.localeRaw}
                      />
                    }
                    label={
                      <SanityBlockRenderer
                        blockContentOverrides={descriptionBlockContentOverrides}
                        serializers={descriptionBlockSerializers}
                        content={offer?.description?.localeRaw}
                      />
                    }
                  /> */}
                  {offer?.expiresOnDatetime && (
                    <ExpiryText>{countDown(offer.expiresOnDatetime, formatMessage)}</ExpiryText>
                  )}
                </SelectionContainer>
              );
            })
          ) : (
            <NotFoundContainer testID={'promotion-not-found'}>
              <NotFoundName>{formatMessage({ id: 'noneFound' })}</NotFoundName>
              <NotFoundDescription>
                {formatMessage({ id: 'noSavedPromotions' })}
              </NotFoundDescription>
            </NotFoundContainer>
          )}
        </StyledList>
      </ModalContent>
      <ButtonContainer>
        <Box maxWidth="300px" margin="0 auto">
          <ActionButton
            fullWidth
            disabled={shouldDisableButton}
            onPress={handleOnSelect}
            testID="apply-promotion-button"
          >
            {formatMessage({ id: 'apply' })}
          </ActionButton>
        </Box>
      </ButtonContainer>
    </Modal>
  );
};

export default SavedPromotionsModal;
