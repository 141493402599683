import { Stack } from 'expo-router';
import { useIntl } from 'react-intl';

import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';

import { getScreenTitleHeader } from '../../../components/get-screen-title-header';
export const unstable_settings = {
  initialRouteName: 'index',
};

export default function SupportLayout() {
  const enablePypestream = useFlag(LaunchDarklyFlag.ENABLE_PYPESTREAM);
  const { formatMessage } = useIntl();

  return (
    <>
      {enablePypestream ? (
        <Stack
          initialRouteName="index"
          screenOptions={{
            title: formatMessage({ id: 'support' }),
            header: getScreenTitleHeader({ backStyle: 'back' }),
          }}
        >
          <Stack.Screen
            name="pypestream"
            options={{
              headerShown: false,
              presentation: 'modal',
            }}
          />
        </Stack>
      ) : (
        <Stack
          initialRouteName="index"
          screenOptions={{
            header: getScreenTitleHeader(),
          }}
        >
          <Stack.Screen
            name="index"
            options={{
              title: 'How can we help you?',
            }}
          />
          <Stack.Screen name="categories/[categoryId]" />
          <Stack.Screen name="form" options={{ title: '' }} />
          <Stack.Screen name="orders" />
        </Stack>
      )}
    </>
  );
}
