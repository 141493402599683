import { Path, Svg } from 'react-native-svg';

import { tokens } from '../../..';
import { type IconProps } from '../types';

export const ArrowRight = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 11 12"
      color={color ?? tokens.colors.$black}
      {...otherProps}
    >
      <Path
        d="M5.5.667l-.94.94 3.72 3.726H.165v1.334h8.113l-3.72 3.726.94.94L10.833 6 5.499.667z"
        fill="#B1080F"
      />
    </Svg>
  );
};
