import React from 'react';

import { IBringgUuids } from '@rbi-ctg/frontend';
import { IServerOrder } from '@rbi-ctg/menu';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useStoreContext } from 'state/store';

import useOrderConfirmationMap from './use-order-confirmation-map';

interface IOrderConfirmationMapProps {
  bringgUuids?: IBringgUuids;
  serverOrder: IServerOrder;
}

const Map: React.FC<React.PropsWithChildren<IOrderConfirmationMapProps>> = ({
  bringgUuids = {},
  serverOrder,
}) => {
  const { store } = useStoreContext();

  const enableDeliveryWatchDriverLocation = useFlag(
    LaunchDarklyFlag.ENABLE_DELIVERY_WATCH_DRIVER_LOCATION
  );

  const map = useOrderConfirmationMap({
    bringgUuids,
    enableDriverLocation: enableDeliveryWatchDriverLocation,
    serverOrder,
    store,
  });

  return <>{map}</>;
};

export default Map;
