import { useCallback } from 'react';

import { IRestaurantNode } from 'generated/rbi-graphql';
import { useOrderContext } from 'state/order';

type UseStoreLocatorPrefetchHook = () => {
  prefetchRestaurantValuesForOrder: (restaurant: IRestaurantNode) => void;
};

export const useStoreLocatorPrefetch: UseStoreLocatorPrefetchHook = () => {
  const { prefetchRestaurantPosData } = useOrderContext();
  const prefetchRestaurantValuesForOrder = useCallback(
    (restaurant: IRestaurantNode) => {
      prefetchRestaurantPosData(restaurant);
    },
    [prefetchRestaurantPosData]
  );
  return {
    prefetchRestaurantValuesForOrder,
  };
};
