import { forwardRef } from 'react';
import { StyleSheet, View } from 'react-native';

import { HEADER_HAMBURGER_BUTTON } from '@fhs-legacy/frontend/src/utils/test-ids';

import { IconButton, type IconButtonProps } from '../icon-button';

export type HeaderIconButtonProps = Omit<IconButtonProps, 'type'>;

export const HeaderIconButton = forwardRef<View, HeaderIconButtonProps>(function HeaderIconButton(
  props,
  ref
) {
  return (
    <IconButton
      ref={ref}
      type="ghost"
      size="xl"
      {...props}
      style={[styles.headerIconButton, props.style]}
      testID={HEADER_HAMBURGER_BUTTON}
    />
  );
});

const styles = StyleSheet.create({
  headerIconButton: {
    // This adjustment is to provide visual alignment with icon inside button and actual 16px gutter
    marginHorizontal: -12,
  },
});
