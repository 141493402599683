import { ApolloError } from '@apollo/client';

import { FireOrderIn, IDelivery } from '@rbi-ctg/menu';
import {
  IAddPaymentMethod,
  IBuildCommitOrderPayload,
  ICashPaymentMethod,
  IGooglePayPaymentMethod,
  NativePaymentMethod,
  PaymentMethod,
  PaymentMethodOptionTypes,
  PrepaidPaymentMethod,
  SchemePaymentMethod,
} from 'components/payments/integrations/hooks/types';
import {
  CartPaymentCardType,
  ICommitDeliveryInput,
  IMutationCommitOrderArgs,
  PaymentMethodType,
} from 'generated/graphql-gateway';
import { parseGraphQLErrorCodes, paymentErrorModalMaps } from 'utils/errors';
import { GraphQLError } from 'utils/network';
import { isDriveThru } from 'utils/service-mode';

import { ORDER_FAILURE_MESSAGE_KEY } from './constants';
import { messageIdMappingForDialogs } from './message-id-mappings';

/**
 * Type guard for Scheme Payment Method (i.e. credit card).
 * @param method
 * @returns {boolean} whether a payment method is a credit card
 */
export const isCreditCardMethod = (method: PaymentMethod): method is SchemePaymentMethod =>
  method.type === PaymentMethodType.SCHEME;

/**
 * Type guard for Prepaid Payment Method (i.e. gift card).
 * @param method
 * @returns {boolean} whether a payment method is a gift card
 */
export const isGiftCardMethod = (method: PaymentMethod): method is PrepaidPaymentMethod =>
  method.type === PaymentMethodType.PREPAID;

/**
 * Type guard for Native Payment Method (i.e. apple and google pay).
 * @param method
 * @returns {boolean} whether a payment method is a apple or google pay
 */
export const isNativeMethod = (method: PaymentMethod): method is NativePaymentMethod =>
  method.type === PaymentMethodType.APPLE_PAY || method.type === PaymentMethodType.GOOGLE_PAY;

/**
 * Type guard for Google Pay payment method
 * @param method
 * @returns {boolean} whether a payment method is google pay
 */
export const isGooglePayMethod = (method: PaymentMethod): method is IGooglePayPaymentMethod =>
  method.type === PaymentMethodType.GOOGLE_PAY;

/**
 * Type guard for Apple Pay payment method
 * @param method
 * @returns {boolean} whether a payment method is apple pay
 */
export const isApplePayMethod = (method: PaymentMethod): method is IGooglePayPaymentMethod =>
  method.type === PaymentMethodType.APPLE_PAY;

/**
 * Type guard for Cash Payment Method.
 * @param method
 * @returns {boolean} whether a payment method is cash
 */
export const isCashMethod = (method: PaymentMethod): method is ICashPaymentMethod =>
  method.type === CartPaymentCardType.CASH;

/**
 * Type guard for a new credit card.
 * @param method
 * @returns {boolean} whether a payment method is cash
 */
export const isNewCreditCard = (method: PaymentMethod): method is IAddPaymentMethod =>
  method.type === PaymentMethodOptionTypes.ADD_CREDIT_CARD;

/**
 * Adds a payment method to an array if its type is enabled. This can be used when conditionally spreading
 * an array into another array.
 * @param condition boolean value that states whether the payment method is enabled/disabled
 * @param paymentMethod payment method that may or may not be added to the array
 * @returns an array with the enabled payment method or an empty array
 */
export const addMethodWhen = (condition: boolean, paymentMethod: PaymentMethod) =>
  condition ? [paymentMethod] : [];

export const buildDeliveryInput = (delivery: IDelivery): ICommitDeliveryInput => ({
  instructions: delivery.instructions ?? undefined,
  tipCents: delivery.tipCents ?? undefined,
  pushNotification: true,
  dropoff: {
    addressLine1: delivery.dropoff?.addressLine1,
    addressLine2: delivery.dropoff?.addressLine2,
    city: delivery.dropoff?.city,
    state: delivery.dropoff?.state,
    zip: delivery.dropoff?.zip,
    country: null,
    phoneNumber: delivery.dropoff?.phoneNumber || '',
  },
});

export const buildCommitOrderPayload = ({
  cardBrand,
  orderInformation,
  payment,
}: IBuildCommitOrderPayload): IMutationCommitOrderArgs => {
  let fireOrderIn = (orderInformation.fireOrderIn || 0) as FireOrderIn;
  const isDriveThruOrder = isDriveThru(orderInformation.serviceMode);
  // for backwards compatibility purposes, we need to convert 0 to 1 for ASAP drive thru orders
  if (isDriveThruOrder && orderInformation.fireOrderIn === 0) {
    fireOrderIn = 1;
  }

  const delivery = orderInformation.delivery ? buildDeliveryInput(orderInformation.delivery) : null;

  const input = {
    billingAddress: undefined,
    creditType: CartPaymentCardType[cardBrand],
    fireOrderIn,
    payment,
    rbiOrderId: orderInformation.rbiOrderId,
    storeEmail: orderInformation.storeEmail,
  };

  return {
    delivery,
    input,
    skipCoolingPeriod: true,
  };
};

/**
 * useful for excluding certain payment methods from being used to reload prepaid cards
 * @param {PaymentMethod} paymentMethod
 * @returns {boolean} whether this can be used for reloading a prepaid card
 */
export const isAllowedReloadMethod = (paymentMethod: PaymentMethod) =>
  !isGiftCardMethod(paymentMethod) && !isCashMethod(paymentMethod);

/**
 * Digital Wallet payment error modal key
 * Returns the key for the digital wallet payment error modal
 * @param fdAccountId
 * @param defaultMessage
 * @returns
 */
export const getAppleOrGooglePayError = (
  fdAccountId: string,
  defaultMessage?: string
): string | undefined => {
  // Send custom message if it's apple pay or google pay
  // Otherwise, show default message
  const messageMapping = {
    [CartPaymentCardType.APPLE_PAY]: 'orderFailureMessageForApplePay',
    [CartPaymentCardType.GOOGLE_PAY]: 'orderFailureMessageForGooglePay',
  };

  return messageMapping[fdAccountId] || defaultMessage;
};

/**
 * Payment error modal key
 * Returns the key for the payment error modal
 * @param fdAccountId
 * @param defaultMessage
 * @returns
 */
export const getOrderFailureMessageKey = (
  fdAccountId: string,
  error: ApolloError | GraphQLError | undefined
): string => {
  const appleOrGooglePayError = getAppleOrGooglePayError(fdAccountId);

  if (appleOrGooglePayError) {
    return appleOrGooglePayError;
  }

  if (!error) {
    return ORDER_FAILURE_MESSAGE_KEY;
  }
  const errorCode = parseGraphQLErrorCodes(error)[0]?.errorCode;

  const paymentModalKey = paymentErrorModalMaps[errorCode];
  const messageIdMappingForDialog = messageIdMappingForDialogs[paymentModalKey];
  return messageIdMappingForDialog?.body ?? ORDER_FAILURE_MESSAGE_KEY;
};

/**
 * finds a prepaid payment method from a list of available methods.
 * returns undefined if no method is found.
 * @param paymentMethods list of payment methods
 * @returns a (possibly undefined) prepaid payment method
 */
export const getGiftCardMethod = (
  paymentMethods: PaymentMethod[]
): PrepaidPaymentMethod | undefined => {
  const prepaid = paymentMethods.find(isGiftCardMethod);
  return prepaid ? (prepaid as PrepaidPaymentMethod) : undefined;
};

export const loadScript = (url: string) => {
  return new Promise((resolve, reject) => {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onload = resolve;
    script.onerror = reject;
    head.appendChild(script);
  });
};
