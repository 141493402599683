import React, { VFC } from 'react';
import { useIntl } from 'react-intl';

import {
  LOYALTY_REWARDS_POINTS_TEXT_ID,
  POINTS_TRANSLATION_KEY,
  SHOW_REWARDS_LOGO,
} from './constants';
import {
  Container,
  Content,
  PointsNumber,
  PointsText,
  StyledPointsIcon,
  StyledRewardsLogo,
  Wrapper,
} from './loyalty-rewards-balance.styled';
import { ILoyaltyRewardsBalanceViewProps } from './types';

export const LoyaltyRewardsBalanceView: VFC<ILoyaltyRewardsBalanceViewProps> = ({
  availablePoints,
  appliedPoints,
}) => {
  const { formatMessage } = useIntl();
  const availablePointsLabel = `${availablePoints} ${formatMessage({
    id: POINTS_TRANSLATION_KEY,
  })} ${formatMessage({ id: 'available' })}
  `;

  const pointsAppliedLabel = formatMessage({ id: LOYALTY_REWARDS_POINTS_TEXT_ID });
  const myPointsLabel = formatMessage({ id: 'myPoints' });
  const pointsAppliedAccessibilityLabel = `${appliedPoints} ${pointsAppliedLabel}`;
  return (
    <Wrapper>
      <Container>
        <Content $border accessible accessibilityLabel={availablePointsLabel}>
          {SHOW_REWARDS_LOGO ? StyledRewardsLogo : <PointsText>{myPointsLabel}</PointsText>}
          {StyledPointsIcon}
          <PointsNumber testID="points">{availablePoints}</PointsNumber>
        </Content>
        <Content accessible accessibilityLabel={pointsAppliedAccessibilityLabel}>
          <PointsText testID="points-applied-text">{pointsAppliedLabel}</PointsText>
          <PointsNumber testID="points-applied">{appliedPoints}</PointsNumber>
        </Content>
      </Container>
    </Wrapper>
  );
};
