import React from 'react';
import { Animated } from 'react-native';

import { makeUclComponent } from '@fhs-legacy/universal-components';

import { IFadeInAnimatedViewProps } from './types';
import useFadeInAnimation from './use-fade-in-animation';

function FadeInAnimatedView({ isVisible = false, children }: IFadeInAnimatedViewProps) {
  const fadeInAnimation = useFadeInAnimation({
    isVisible,
  });

  return (
    <Animated.View
      style={[
        {
          opacity: fadeInAnimation,
          ...(!isVisible && { height: 0 }),
        },
      ]}
    >
      {children}
    </Animated.View>
  );
}

export default makeUclComponent(FadeInAnimatedView);
