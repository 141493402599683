export { Platform as RBIPlatform } from 'generated/graphql-gateway';
export { Platform } from 'generated/graphql-gateway';
export enum RBIEnv {
  DEV = 'dev',
  PROD = 'prod',
  QA = 'qa',
  SANDBOX = 'sandbox',
  STAGING = 'staging',
  TEST = 'test',
}

export enum GraphQLEnv {
  DEV = 'dev',
  PROD = 'prod',
  QA = 'qa',
  SANDBOX = 'sandbox',
  STAGING = 'staging',
  TEST = 'test',
  LOCAL = 'local',
}

// Only use for logging universal attributes in mParticle
export enum RBIExpandedPlatform {
  IOS = 'iOS',
  ANDROID = 'Android',
  WEB = 'Web',
}
