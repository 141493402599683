import { createContext, useContext } from 'react';

export type RadioGroupContext = {
  value: string;
  onChange: (nextValue: string) => void;
};

export const radioGroupContext = createContext<RadioGroupContext | null>(null);

export const useRadioGroup = () => {
  const context = useContext(radioGroupContext);

  if (__DEV__) {
    if (context == null) {
      throw new Error('Radio usage must be wrapped within a RadioGroup');
    }
  }

  return context as RadioGroupContext;
};

export type RadioItemContext = {
  value: string;
  selected: boolean;
  disabled: boolean;
  label: string;
  handlePress: () => void;
};

export const radioItemContext = createContext<RadioItemContext | null>(null);

export const useRadioItem = () => {
  const context = useContext(radioItemContext);

  if (__DEV__) {
    if (context == null) {
      throw new Error('Must have RadioGroup.Item ancestor.');
    }
  }

  return context as RadioItemContext;
};
