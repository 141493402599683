import React, { useCallback, useMemo } from 'react';
import { useWindowDimensions } from 'react-native';

import { Box, Header, VStack } from '@fhs-legacy/universal-components';
import BonusPoints from 'components/bonus-points';
import { DeliveryFeesApply } from 'components/delivery-fees-apply';
import { ReadMoreAccordion } from 'components/read-more-accordion';
import BlockRenderer from 'components/sanity-block-renderer';
import { MenuObjectTypes } from 'enums/menu';
import { useIsDesktopBp } from 'hooks/breakpoints';
import { useFeatureDisclaimers } from 'hooks/use-feature-disclaimers';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyUserPromotions } from 'state/loyalty/hooks/use-loyalty-promotions';
import { useProductWizardContext } from 'state/product-wizard';
import { isWeb } from 'utils/environment';
import { hasExcessSodium } from 'utils/menu/sodium';
import { getSelectionsFromMenuData } from 'utils/wizard';

import { SodiumFlag } from '../nutrition-information';
import { PriceAndCalories } from '../price-and-calories';

import { ProductBackground } from './product-background';
import {
  ImageDisclaimerText,
  ProductImageContainer,
  ProductImageWrapper,
  ProductInformationContainer,
  StyledProductPicture,
  Title,
} from './product-overview.styled';

const ProductOverview: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { menuObject, selectedProduct, userSelections } = useProductWizardContext();

  const isDesktop = useIsDesktopBp();
  const { height } = useWindowDimensions();
  const { featureDisclaimers } = useFeatureDisclaimers();
  const { availablePromotionsMap } = useLoyaltyUserPromotions();
  const shouldShowBonusPointsBadge =
    !!selectedProduct?.promotion?.loyaltyEngineId &&
    !!availablePromotionsMap[selectedProduct.promotion.loyaltyEngineId];

  const enableProductImageDisclaimer = useFlag(LaunchDarklyFlag.ENABLE_PRODUCT_IMAGE_DISCLAIMER);

  const { images: imagesDisclaimer } = featureDisclaimers || {};

  const isOverSodiumLimit = useMemo(() => {
    const { comboSlotSelections, modifierSelections } = getSelectionsFromMenuData({
      data: menuObject,
      pickerSelectionOverrides: userSelections.pickerAspects,
    });

    return hasExcessSodium({
      selectedOpt: selectedProduct,
      comboSlotSelections,
      modifierSelections,
    });
  }, [menuObject, selectedProduct, userSelections.pickerAspects]);

  const BlockRendererWrapper = useCallback(
    (props: { isCollapsed?: boolean }) => {
      return (
        <BlockRenderer
          content={menuObject.description?.localeRaw}
          textProps={{
            variant: 'CopyTwo',
            numberOfLines: props.isCollapsed ? 2 : 0,
            ellipsizeMode: 'tail',
            textAlign: 'center',
          }}
          collapsable
        />
      );
    },
    [menuObject]
  );

  return (
    <VStack>
      <ProductImageContainer>
        <ProductImageWrapper>
          <ProductBackground />
          <StyledProductPicture
            image={menuObject?.image}
            alt={menuObject?.imageDescription?.locale || ''}
            maxW={isDesktop ? height / 2 : null}
          />
        </ProductImageWrapper>
        {enableProductImageDisclaimer && (
          <ImageDisclaimerText>{imagesDisclaimer?.locale}</ImageDisclaimerText>
        )}
      </ProductImageContainer>
      <ProductInformationContainer>
        {shouldShowBonusPointsBadge && (
          <Box minW="auto" marginX="auto" marginTop="$8">
            <BonusPoints
              bonusPoints={selectedProduct.promotion?.bonusPoints}
              hidePoints={selectedProduct._type === MenuObjectTypes.COMBO}
            />
          </Box>
        )}
        <Box px={3} minH={16} mt={3}>
          <Header textAlign="center">
            <Title margin={0}>{selectedProduct?.name?.locale ?? ''} </Title>
            {isOverSodiumLimit && <SodiumFlag size={5} position="relative" top={0.5} />}
          </Header>
        </Box>
        <PriceAndCalories />
        <DeliveryFeesApply />
        {menuObject.description?.localeRaw && (
          // Accordion default collapsedHeight is 25. This causes top of the second line to be slightly visible. Using 23 fixes this.
          <Box maxW="$96">
            <ReadMoreAccordion collapsedHeight={isWeb ? 38 : 23}>
              <BlockRendererWrapper />
            </ReadMoreAccordion>
          </Box>
        )}
      </ProductInformationContainer>
    </VStack>
  );
};

export default ProductOverview;
