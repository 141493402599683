import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconHomeOutline = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M6.125 19.575h4.069v-5.858h4.603v5.854h4.075V9.869L12.5 5.16 6.125 9.87v9.705Zm.281 1.126c-.39 0-.722-.138-.996-.412A1.366 1.366 0 0 1 5 19.288v-9.183c0-.22.05-.432.152-.636a1.34 1.34 0 0 1 .41-.494l6.094-4.592c.125-.094.258-.165.399-.212.14-.047.289-.07.445-.07.156 0 .305.023.445.07.14.047.274.118.399.212l6.094 4.592c.171.125.308.29.41.494.101.204.152.416.152.636v9.183c0 .392-.137.726-.41 1a1.353 1.353 0 0 1-.996.413h-4.922v-5.857h-2.344v5.857H6.406Z"
      />
    </Svg>
  );
};
