import { extendTheme } from '@fhs-legacy/universal-components/themes';
import { fhsTheme } from '@fhs-legacy/universal-components/themes/fhs';

import { designTokens } from '../constants/design-tokens';

export const theme = extendTheme(fhsTheme, {
  nativeBaseTheme: {
    colors: {
      // @ts-ignore
      __legacyToken: designTokens,
    },
  },
});
