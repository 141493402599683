export const styles: { background: string; color: string }[] = [
  {
    background: 'transparent',
    color: Styles.color.black,
  },
  {
    background: Styles.color.orange,
    color: Styles.color.black,
  },
  {
    background: Styles.color.tertiary,
    color: Styles.color.white,
  },
  {
    background: Styles.color.red,
    color: Styles.color.white,
  },
  {
    background: Styles.color.validated,
    color: Styles.color.black,
  },
];
