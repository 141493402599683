import React, { RefObject, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { AutocompleteAddressInput } from 'components/autocomplete-address-input';
import { FormValidationState } from 'utils/form';
import { IPlaceAddress } from 'utils/geolocation';

import { IPhoneAddressValidation } from '../types';

interface IDeliveryAddressFormInputProps {
  addressString: string;
  deliveryAddressInputRef?: React.RefObject<HTMLInputElement>;
  message: string;
  setAddressComponents: (address: IPlaceAddress | null) => void;
  setAddressString: (address: string) => void;
  setCoordinates: (coordinates: undefined) => void;
  setMessage: (message: string) => void;
  setPlaceId: (placeId: string) => void;
  setValidation: (validation: IPhoneAddressValidation) => void;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  unitRef: RefObject<HTMLInputElement>;
  validation: IPhoneAddressValidation;
  initialFocus?: boolean;
}

const DeliveryAddressFormInput: React.FC<
  React.PropsWithChildren<IDeliveryAddressFormInputProps>
> = ({
  setAddressString,
  setPlaceId,
  setValidation,
  setMessage,
  unitRef,
  validation,
  setCoordinates,
  setAddressComponents,
  addressString,
  deliveryAddressInputRef,
  message,
  setValue,
  initialFocus = false,
}) => {
  const { formatMessage } = useIntl();

  const defaultAddressInputRef = useRef<HTMLInputElement>(null);

  const AddressInputRef = deliveryAddressInputRef ?? defaultAddressInputRef;

  const handleAddressChange = (newAddressString: string, placeId?: string) => {
    setAddressString(newAddressString);
    setMessage('');

    if (!placeId) {
      setValidation({
        ...validation,
        address: FormValidationState.INVALID,
      });
      setMessage(formatMessage({ id: 'addressError' }));
      setAddressComponents(null);
      return;
    }

    if (unitRef.current) {
      unitRef.current.focus();
    }

    setValidation({
      ...validation,
      address: FormValidationState.PRISTINE,
    });
    setPlaceId(placeId);
    setCoordinates(undefined);
  };

  useEffect(() => {
    if (!addressString && AddressInputRef.current) {
      AddressInputRef.current.focus();
    }
  }, [addressString, AddressInputRef]);

  return (
    <AutocompleteAddressInput
      autoFocus
      data-private="redact"
      testID="delivery-address-input"
      label={formatMessage({ id: 'deliveryAddress' })}
      addressesOnly
      message={message}
      onAddressSelected={handleAddressChange}
      // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<string>>' is not ass... Remove this comment to see the full error message
      onInputValueChange={setValue}
      ref={AddressInputRef}
      required
      // @ts-expect-error TS(2322) FIXME: Type '(message: string) => void' is not assignable... Remove this comment to see the full error message
      setMessage={setMessage}
      value={addressString}
      initialFocus={initialFocus}
    />
  );
};

export default DeliveryAddressFormInput;
