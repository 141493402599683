import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconSearch = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M15.5 14.1h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.6a6.5 6.5 0 1 0-6.5 6.5c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99 1.49-1.49-4.99-5Zm-6 0C7.01 14.1 5 12.09 5 9.6s2.01-4.5 4.5-4.5S14 7.11 14 9.6s-2.01 4.5-4.5 4.5Z"
      />
    </Svg>
  );
};
