import StoreSelected from '@fhs-legacy/frontend/src/components/store-selected';
import FavoriteEditor from '@fhs-legacy/frontend/src/pages/favorite-editor';
export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

export default function FavoriteEditorPage() {
  return (
    <StoreSelected>
      <FavoriteEditor />
    </StoreSelected>
  );
}
