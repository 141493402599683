/* eslint-disable complexity */
import { addWithConfig } from '../../utils';
import { Badge } from '../badge';
import { Box } from '../box';
import { Button } from '../button';
import { HStack } from '../h-stack';
import { Header } from '../header';
import { Image } from '../image';
import { InlineAlert } from '../inline-alert';
import { Pressable } from '../pressable';
import { VStack } from '../v-stack';

import type { OfferListItemProps } from './types';

export const OfferListItem = ({
  description,
  serviceModeStyle,
  serviceModeText,
  handleRemove,
  header,
  image,
  imageAlt,
  imageUri,
  offerApplied,
  offerText,
  onPress,
  promoCodeLabel,
  removeOfferText,
  unavailable,
  unavailableMessage,
  ...buttonProps
}: OfferListItemProps) => (
  <Pressable
    accessibilityRole="button"
    dd-action-name={header}
    onPress={onPress}
    {...buttonProps}
    mb="$2.5"
    marginX={{ desktop: 'auto' }}
    w="100%"
    maxWidth={{ desktop: 932 }}
  >
    {
      // @ts-ignore
      ({ isHovered, isPressed, isFocused }) => {
        const pressed = isPressed && onPress;
        const offerIsApplied = offerApplied && offerText && handleRemove && removeOfferText;
        return (
          <Box
            bg="token.background-pattern"
            rounded={12}
            borderWidth={offerApplied ? 2 : 0}
            borderColor={offerApplied ? 'token.border-color-active' : 'transparent'}
          >
            <VStack
              p={['$3', '$4']}
              pt={offerIsApplied ? '$1' : '$3'}
              borderTopRadius={12}
              borderBottomRadius={unavailable ? 0 : 12}
              bg={pressed || isFocused ? 'token.background-focus' : 'transparent'}
            >
              {offerIsApplied && (
                <HStack justifyContent="space-between" alignItems="center" pl="$2" mb="$2">
                  <Badge variant="default-subtle" iconName="checkFill">
                    {offerText}
                  </Badge>
                  <Button variant="ghost" onPress={handleRemove} size="md">
                    {removeOfferText}
                  </Button>
                </HStack>
              )}
              <HStack mr="$2">
                {image || (
                  <Image
                    accessibilityIgnoresInvertColors
                    resizeMode="contain"
                    source={{
                      uri: imageUri,
                    }}
                    alt={imageAlt}
                    height={['$24', '122']}
                    width={['$24', '122']}
                  />
                )}
                <VStack flexShrink={1} ml="$3" alignItems="flex-start" justifyContent="center">
                  <Header
                    variant="headerThree"
                    color={isHovered ? 'token.text-active' : 'token.text-default'}
                    ellipsizeMode="tail"
                    numberOfLines={2}
                  >
                    {header}
                  </Header>
                  {description}
                  <HStack>
                    {promoCodeLabel && (
                      <Badge variant="default-outline" iconName="offer" mr="$2">
                        {promoCodeLabel}
                      </Badge>
                    )}
                    {serviceModeText && (
                      <Badge variant={serviceModeStyle ?? 'default-solid'}>{serviceModeText}</Badge>
                    )}
                  </HStack>
                </VStack>
              </HStack>
            </VStack>
            {unavailable && !offerApplied && (
              <InlineAlert
                borderBottomRadius={10}
                borderTopRadius="0"
                width="full"
                status="info"
                message={unavailableMessage}
                w="full"
              />
            )}
          </Box>
        );
      }
    }
  </Pressable>
);

export default addWithConfig(OfferListItem);
