export const DELIVERY_DETAILED_FEES = {
  BASE_FEE: 'baseDeliveryFeeCents',
  GEOGRAPHICAL_FEE: 'geographicalFeeCents',
  LONG_DISTANCE_FEE: 'deliverySurchargeFeeCents',
  SERVICE_FEE: 'serviceFeeCents',
  SMALL_CART_FEE: 'smallCartFeeCents',
};

export const SUPPORTED_BREAKDOWN_FEES = [
  DELIVERY_DETAILED_FEES.BASE_FEE,
  DELIVERY_DETAILED_FEES.GEOGRAPHICAL_FEE,
  DELIVERY_DETAILED_FEES.LONG_DISTANCE_FEE,
  DELIVERY_DETAILED_FEES.SERVICE_FEE,
  DELIVERY_DETAILED_FEES.SMALL_CART_FEE,
];

export const FEES_MESSAGE_IDS = {
  [DELIVERY_DETAILED_FEES.BASE_FEE]: 'deliveryFeeBreakdownBase',
  [DELIVERY_DETAILED_FEES.GEOGRAPHICAL_FEE]: 'deliveryFeeBreakdownGeographical',
  [DELIVERY_DETAILED_FEES.LONG_DISTANCE_FEE]: 'deliveryFeeBreakdownLongDistance',
  [DELIVERY_DETAILED_FEES.SERVICE_FEE]: 'deliveryFeeBreakdownService',
  [DELIVERY_DETAILED_FEES.SMALL_CART_FEE]: 'deliveryFeeBreakdownSmallCartFee',
};
