import { Stack } from 'expo-router';
import Head from 'expo-router/head';
import React from 'react';

import { AppHeader } from '../../components/app-header';

export default function IndexLayout() {
  return (
    <>
      <Head>
        {/* TODO(SEO): This is the fallback. We need to improve this for SEO */}
        <title>Firehouse Subs - Subs, Sub Sandwiches, Salads, Catering -- Firehouse Subs</title>
      </Head>
      <Stack>
        <Stack.Screen name="index" options={{ header: () => <AppHeader /> }} />
      </Stack>
    </>
  );
}
