import React, { VFC } from 'react';
import { useIntl } from 'react-intl';

import { HStack, VStack } from '@fhs-legacy/universal-components';
import Currency from 'components/currency';
import { usePaymentContext } from 'state/payment';
import { IPaymentMethod } from 'state/payment/types';
import { theme } from 'styles/configure-theme';
import { CARD_ALIAS_TRANSLATION_ID } from 'utils/gift-card/constants';

import { MethodType } from './styled';

interface IPrepaidMethodBalance {
  method: IPaymentMethod;
  isDisabled: boolean;
  fullWidthDescription?: boolean;
}

const PrepaidMethodBalance: VFC<IPrepaidMethodBalance> = ({
  method,
  isDisabled,
  fullWidthDescription = true,
}) => {
  const { getBalanceFromPaymentMethods } = usePaymentContext();
  const { formatMessage } = useIntl();

  const renderMethodType = () => (
    <MethodType color={isDisabled ? theme.token('text-disabled') : theme.token('text-default')}>
      {formatMessage(
        { id: 'payWithMyPrepaidCard' },
        { cardAlias: formatMessage({ id: CARD_ALIAS_TRANSLATION_ID }) }
      )}
      {fullWidthDescription && ' - '}
    </MethodType>
  );

  if (fullWidthDescription) {
    return (
      <HStack alignItems="center">
        {renderMethodType()}
        <Currency
          amount={getBalanceFromPaymentMethods(method)}
          textProps={{
            margin: 0,
          }}
        />
      </HStack>
    );
  }

  return (
    <VStack>
      <HStack alignItems="center">{renderMethodType()}</HStack>
      <HStack alignItems="center">
        <Currency
          amount={getBalanceFromPaymentMethods(method)}
          textProps={{
            margin: 0,
            fontSize: 'xs',
          }}
        />
      </HStack>
    </VStack>
  );
};

export default PrepaidMethodBalance;
