import { ISanityBlockContent, ISanityTextNode } from '@rbi-ctg/menu';
import type { IOffer as ILegacyOffer } from '@rbi-ctg/menu';
import {
  ILoyaltyEngineRewardsQuery,
  IOffersFragment,
  Maybe,
  OfferRedemptionType,
} from 'generated/graphql-gateway';
import { IAppliedOffer } from 'generated/rbi-graphql';
import {
  ICmsConfigRewardsQuery,
  IConfigOfferFragment,
  ILoyaltyRewardsByEngineIdQuery,
  ISystemwideOffersFragment,
} from 'generated/sanity-graphql';
import { OffersServiceMode } from 'pages/loyalty/loyalty-offers/types';

export enum UserCardFormat {
  Digital = 'digital',
}

export enum UserCardType {
  AccumulateRedemptions = 'AccumulateRedemptions',
}

export interface ILoyaltyCard {
  barcode: string;
  cardFormat: UserCardFormat;
  cardId: string;
  cardType: UserCardType;
  name: string;
}

export interface IActiveCardDetails {
  barcode: string;
  donationUpcharge: boolean;
  collectedPoints: number;
  totalPoints: number;
  availableRedemptions: number;
  percentage: number;
  pointsUntil: number;
  canRedeemReward: boolean;
  points?: number;
  scanAndPay: boolean;
}

export interface ILoyaltyCards {
  count: number;
  cards: ILoyaltyCard[];
}

export interface IGetLoyaltyCardsResponse {
  getLoyaltyCards: ILoyaltyCards;
}

export interface IGetCardTransaction {
  getCardTransactions: ILoyaltyCardTransaction;
}

export interface ILoyaltyCardDetails {
  availableRedemptions: number;
  cardType: UserCardType;
  canEarnVisit: boolean;
  canRedeemReward: boolean;
  periodEndTimestamp: string;
  discountActiveUntil: string;
  periodStartTimestamp: string;
  numberOfTransactionsMadeTowardsNextReward: number;
  numberOfTransactionsNeeded: number;
  numberOfTransactionsInTimePeriod: number;
}

export interface IGetCardDetails {
  getLoyaltyCardDetails: ILoyaltyCardDetails;
}

export type GetCardDetailsRequest = (cardId: string) => Promise<IGetCardDetails>;

export interface ILoyaltyCardTransaction {
  bonusPoints: number;
  basketTaxes: number;
  basketTotal: number;
  city: string;
  discountAmount: number;
  isCustomerServiceVisit: boolean;
  isVisit: boolean;
  offerId: number;
  rewardEarned: boolean;
  rewardRedeemed: boolean;
  stateOrProvince: string;
  timestamp: string;
  transactionId: string;
  visitType: string;
  wasDiscountActive: string;
  pointsEarned: number;
  pointsUsed: number;
  tag: string;
}

export type SelectedRewardTierIdType = string | null | undefined;
export interface ILoyaltyCategoryOption {
  name: ISanityTextNode;
  description: ISanityBlockContent;
  points: number;
  _id: string;
}
export interface ILoyaltyCategory {
  _id: string;
  name: ISanityTextNode;
  description: ISanityBlockContent;
  options: Array<ILoyaltyCategoryOption>;
}

export interface ILoyaltyCategories {
  allLoyaltyConfigurations: {
    categories: ILoyaltyCategory;
  };
  errors?: Error[];
}

export interface ILoyaltyTier {
  name: ISanityTextNode;
  description: ISanityBlockContent;
  points: number;
  _id: string;
}

export interface ILoyaltyTiers {
  allLoyaltyConfigurations: {
    bankMyRewardTier: ILoyaltyTier;
  };
  errors?: Error[];
}

export type LoyaltyRewards =
  | ILoyaltyRewardsByEngineIdQuery['allRewards']
  | ICmsConfigRewardsQuery['allConfigRewards'];

export type LoyaltyReward = LoyaltyRewards[0];

export type LoyaltyEngineRewards = ILoyaltyEngineRewardsQuery['loyaltyRewardsV2'];

export type LoyaltyEngineReward = NonNullable<LoyaltyEngineRewards>[0];

export type LoyaltyOffer = (ISystemwideOffersFragment | IConfigOfferFragment) &
  Pick<IOffersFragment, 'isStackable' | 'metadata'> & {
    offerServiceMode?: OffersServiceMode;
  };

export enum IncentiveSource {
  SUPPORT = 'SUPPORT',
  PROMOTION_CODE = 'PROMOTION_CODE',
}

export type LoyaltyAppliedOffer = IAppliedOffer & {
  isStackable?: Maybe<boolean>;
  isSurprise?: Maybe<boolean>;
  cmsId?: Maybe<string>;
};

export function isReward(incentive: LoyaltyReward | LoyaltyOffer): incentive is LoyaltyReward {
  return incentive.__typename === 'Reward' || incentive.__typename === 'ConfigReward';
}

export function isLoyaltyOffer(
  incentive: LoyaltyReward | LoyaltyOffer | ILegacyOffer | null
): incentive is LoyaltyOffer {
  return incentive?.__typename === 'SystemwideOffer' || incentive?.__typename === 'ConfigOffer';
}

export function isLoyaltyOfferV2(__typename: string): boolean {
  return __typename === 'SystemwideOfferV2' || __typename === 'ConfigOfferV2';
}

export const isLegacyOffer = (entity: any): entity is ILegacyOffer => entity?._type === 'offer';

export function isSurpriseOffer(offer: LoyaltyOffer): offer is LoyaltyOffer {
  return offer.redemptionType ? offer.redemptionType === OfferRedemptionType.SURPRISE : false;
}

// Offer Incentives must have an _id to be valid
export function isValidOfferIncentive(obj: any): obj is { _id: string } {
  return !!obj?._id;
}

export type IEngineRewardsMap = {
  [sanityId: string]: LoyaltyEngineReward;
};
export type IPersonalizedRewardMap = {
  [rewardId: string]: LoyaltyEngineReward;
};
export type ISanityRewardsMap = Record<LoyaltyReward['_id'], LoyaltyReward>;
