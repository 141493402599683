import { Box, HStack, Text } from '@fhs-legacy/universal-components';
import { AccordionActivatorButton } from 'components/accordion/styled';
import { theme } from 'styles/configure-theme';

export const SignupAccordionItem = Box.withConfig({
  width: '100%',
  margin: 0,
});

export const SignupAccordionActivatorButton = AccordionActivatorButton.withConfig({
  paddingLeft: '$0',
  paddingRight: '$0',
  paddingTop: '$0',
  paddingBottom: '$0',
});

export const SignupAccordionListItemContainer = HStack.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '$5',
});

export const SignupAccordionTitle = Text.withConfig({
  variant: 'copyTwo',
  color: theme.token('text-default'),
  //fontWeight: Styles.fontWeight.black,
  alignSelf: 'flex-start',
  margin: 0,
  marginTop: '$1',
});

export const ContentContainer = Box;
