import React, { VFC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@fhs-legacy/universal-components';
import { ICombo, IComboSlotOption } from '@rbi-ctg/menu';
import { ListItemGroup } from 'components/list-item-group';
import { ProductListItemSeparator } from 'components/product-detail/styled';
import { useProductPropsSelections } from 'components/product-detail/use-product-props-selections';
import {
  transformItemAndVirtualItemToProductListItemProps,
  transformItemToProductListItemProps,
} from 'components/product-detail/utils';
import { ProductListItem } from 'components/product-list-item';
import { RadioIndicator } from 'components/radio-indicator';
import { MenuObjectTypes } from 'enums/menu';
import { useMenuCalories } from 'hooks/menu/use-menu-calories';
import { useMenuContext } from 'state/menu';
import { useProductWizardContext } from 'state/product-wizard';
import { ENABLE_MAIN_ITEM_DETAILED_CUSTOMIZATION } from 'state/product-wizard/constants';

import { IProductCustomizationOptionsProps } from './types';

type ComboSlotOptionWithPrice = IComboSlotOption & { price?: number };
type OptionsGroups = { header: string; options: ComboSlotOptionWithPrice[] }[];

export const ProductCustomizationOptions: VFC<IProductCustomizationOptionsProps> = ({
  comboSlot,
  comboSlotOptions,
  selectedItem,
  onSelectComboSlot,
  modifierSelections,
}) => {
  const { buildProductPropsSelections } = useProductPropsSelections();
  const selections = buildProductPropsSelections(modifierSelections);

  const { priceForComboSlotSelection } = useMenuContext();
  const { selectedProduct } = useProductWizardContext();

  const { formatMessage } = useIntl();
  const { formatCalories } = useMenuCalories();

  const optionsGroups = useMemo<OptionsGroups>(() => {
    const regularGroup: ComboSlotOptionWithPrice[] = [];
    const premiumGroup: ComboSlotOptionWithPrice[] = [];
    comboSlotOptions.forEach(option => {
      if (option.isPremium) {
        const premiumUpsellPrice = comboSlot
          ? priceForComboSlotSelection({
              combo: selectedProduct as ICombo,
              comboSlot,
              selectedOption: option,
            })
          : 0;
        premiumGroup.push({
          ...option,
          price: premiumUpsellPrice,
        });
      } else {
        regularGroup.push(option);
      }
    });
    return [
      { header: formatMessage({ id: 'premiumOptions' }), options: premiumGroup },
      { header: formatMessage({ id: 'standardOptions' }), options: regularGroup },
    ];
  }, [comboSlot, comboSlotOptions, formatMessage, priceForComboSlotSelection, selectedProduct]);

  const handleSelectComboSlot = useCallback(
    (comboSlotOption: IComboSlotOption) => {
      const item = comboSlotOption.option;
      if (item._type !== MenuObjectTypes.ITEM) {
        return;
      }
      onSelectComboSlot(comboSlotOption);
    },
    [onSelectComboSlot]
  );

  return (
    <Box testID="product-customization-options">
      {optionsGroups.map(group => (
        <ListItemGroup
          key={group.header}
          header={group.header}
          items={group.options.map(comboSlotOption => {
            const productListItemProps = ENABLE_MAIN_ITEM_DETAILED_CUSTOMIZATION
              ? transformItemAndVirtualItemToProductListItemProps(comboSlotOption.option, {
                  selections,
                  modifierSelections,
                })
              : transformItemToProductListItemProps(comboSlotOption.option);
            return (
              <ProductListItem
                key={comboSlotOption.option._id}
                isClickable
                onProductClick={() => handleSelectComboSlot(comboSlotOption)}
                productProps={{
                  ...productListItemProps.productProps,
                  calories: formatCalories(comboSlotOption.option.nutrition?.calories),
                  price: comboSlotOption.price,
                }}
                controls={
                  <RadioIndicator
                    isSelected={selectedItem._id === comboSlotOption.option._id}
                    accessibilityLabel={comboSlotOption.option.name.locale}
                  />
                }
              />
            );
          })}
          ItemSeparator={<ProductListItemSeparator />}
        />
      ))}
    </Box>
  );
};
