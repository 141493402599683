import { useEffect } from 'react';

import { useLoyaltyUserQuery } from 'generated/graphql-gateway';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { usePathname } from 'hooks/use-pathname';
import { useAuthContext } from 'state/auth';
import { actions, useAppDispatch } from 'state/global-state';
import { routes } from 'utils/routing';

import type { IUseLoyaltyUserState } from './types';
import { useIsLoyaltyEnabled } from './use-is-loyalty-enabled';

const ROUTE_LIST_REFETCH_LOYALTY_USER = new Set([routes.account, routes.base]);

// helper hook to encapsulate logic to force refetching the user on certain urls
const useForceRefetchUserOnRoute = ({ refetch, skip }: { refetch: () => void; skip: boolean }) => {
  const pathname = usePathname();

  // Refetching loyaltyUser based on certain routes until subscriptions are available
  useEffectOnUpdates(() => {
    if (skip) {
      return;
    }

    if (ROUTE_LIST_REFETCH_LOYALTY_USER.has(pathname)) {
      refetch();
    }
  }, [pathname]);
};

/**
 * Fetch and set the initial user state.
 */
export const useLoyaltyUserState = (): IUseLoyaltyUserState => {
  const dispatch = useAppDispatch();
  const { user, loading: loadingUser } = useAuthContext();
  const loyaltyEnabled = useIsLoyaltyEnabled();

  const loyaltyId = user?.loyaltyId;
  const skipLoyaltyUserQuery = !loyaltyId || !loyaltyEnabled;

  // load init state
  const {
    loading,
    data,
    refetch: refetchLoyaltyUser,
    error,
    called,
  } = useLoyaltyUserQuery({
    skip: skipLoyaltyUserQuery,
    variables: { loyaltyId: loyaltyId || '' },
    fetchPolicy: 'network-only',
  });

  // TODO: move this functionality to the routes components
  useForceRefetchUserOnRoute({
    refetch: refetchLoyaltyUser,
    skip: skipLoyaltyUserQuery,
  });

  const loyaltyUser = data?.loyaltyUserV2 || null;

  useEffect(() => {
    if (!loadingUser && !loading) {
      dispatch(actions.loyalty.setUser(loyaltyUser));

      // TODO: move this to offer's context or remove the set and select the value from the user
      const offerRedemptionAvailableAfter =
        loyaltyUser?.offerRedemptionAvailability?.availableAfter;
      if (offerRedemptionAvailableAfter) {
        dispatch(actions.loyalty.setOfferRedemptionAvailableAfter(offerRedemptionAvailableAfter));
      }
    }
  }, [dispatch, loading, loyaltyUser, loadingUser]);

  useEffect(() => {
    dispatch(actions.loyalty.setLoyaltyLoading(loading));
  }, [loading, dispatch]);

  return {
    loyaltyUser: data?.loyaltyUserV2 || null,
    error,
    refetch: refetchLoyaltyUser,
    loading,
    loyaltyUserReady:
      !loadingUser && ((!skipLoyaltyUserQuery && called && !loading) || skipLoyaltyUserQuery),
  };
};
