import { Box, Text } from '@fhs-legacy/universal-components';
import { ModalHeading } from 'components/modal';

import theme from './theme';

export const Container = Box.withConfig({
  alignSelf: 'center',
  flex: 1,
  paddingTop: 4,
  width: {
    base: 280,
    desktop: 500,
  },
});

export const Header = ModalHeading.withConfig({
  width: 'full',
  maxWidth: {
    base: 280,
    desktop: 500,
  },
  marginTop: '$16',
  marginBottom: '$4',
  marginX: 'auto',
  borderBottomWidth: undefined,
  padding: 0,
  _text: {
    fontSize: theme.headerFontSize,
    color: theme.headerColor,
    fontWeight: theme.headerFontWeight,
  },
});

export const ModalMessage = Text.withConfig({
  textAlign: 'center',
  fontSize: 'md',
  lineHeight: 'md',
});

export const ItemContainer = Box.withConfig({
  marginY: '$6',
});

export const Item = Box.withConfig<{ $isFirstChild?: boolean }>(p => ({
  flexDirection: 'row',
  alignItems: 'center',
  paddingY: theme.itemPaddingY,
  paddingX: theme.itemPaddingX,
  ...(!p.$isFirstChild && { borderTopWidth: theme.itemBorderTopWidth }),
  ...(!p.$isFirstChild && { borderTopColor: theme.itemBorderTopColor }),
}));

export const TileImageWrapper = Box.withConfig({
  height: '$20',
  width: '$20',
  borderRadius: 10,
  backgroundColor: theme.itemImageBackgroundColor,
  marginRight: '$5',
});

export const ItemName = Text.withConfig({
  flex: 1,
  fontFamily: 'heading',
  fontWeight: theme.itemNameFontWeight,
  fontSize: 'md',
  lineHeight: 'md',
});
