import { Box, Header } from '@fhs-legacy/universal-components';
import Link from 'components/link';
import { theme } from 'styles/configure-theme';

export const BordersAndBackground = Box.withConfig({
  width: '100%',
  marginBottom: '$7',
  padding: '$2',
  backgroundColor: 'transparent',
  borderStyle: 'solid',
  textAlign: 'center',
  borderWidth: 1,
  borderRightWidth: {
    base: '0',
    desktop: '1px',
  },
  borderLeftWidth: {
    base: '0',
    desktop: '1px',
  },
  borderColor: '#d0d0d0',
  borderRadius: 10,
  _web: {
    _text: {
      backgroundColor: theme.token('background-default'),
    },
  },
});

export const StyledLink = Link.withConfig({
  color: theme.token('text-link-default'),
  textDecoration: 'none',
  textTransform: 'uppercase',
  _text: {
    variant: 'copyTwo',
  },
});

export const VerifyNowText = Header.withConfig({
  variant: 'headerFour',
  color: theme.token('text-default'),
  margin: 0,
});
