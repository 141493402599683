import { router, useLocalSearchParams } from 'expo-router';
import React from 'react';
import { useIntl } from 'react-intl';

import { Button, VStack } from '@fhs-legacy/universal-components';
import { useIsDesktopBp } from 'hooks/breakpoints';
import useEffectOnce from 'hooks/use-effect-once';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { usePypestreamContext } from 'state/pypestream';
import { routes } from 'utils/routing';

import { OrderIssuesContainer, OrderIssuesHeading, OrderIssuesText } from './styled';

const OrderIssues: React.FC<React.PropsWithChildren<{ onSupportClick?: VoidFunction }>> = ({
  children,
  onSupportClick,
}) => {
  const { formatMessage } = useIntl();
  const params = useLocalSearchParams<{ showToast?: string }>();
  const enablePypestream = useFlag(LaunchDarklyFlag.ENABLE_PYPESTREAM);
  const { setRedirectToHomePage } = usePypestreamContext();
  const isDesktop = useIsDesktopBp();

  useEffectOnce(() => {
    /**
     * Only update showToast param if is present, otherwise
     * let params as is in order to avoid unnecessary new
     * history state push
     */
    if (params.showToast === 'true') {
      // Timeout to avoid race condition which the toast was displayed twice
      setTimeout(() => {
        router.setParams({ ...params, showToast: 'false' });
      }, 300);
    }
  });

  return (
    <OrderIssuesContainer>
      <VStack flex={1} mr="$1">
        <OrderIssuesHeading>{formatMessage({ id: 'needHelp' })}</OrderIssuesHeading>
        <OrderIssuesText>{formatMessage({ id: 'issuesDuringExperience' })}</OrderIssuesText>
        {children}
      </VStack>
      <Button
        variant="outline"
        size="xs"
        onPress={() => {
          /**
           * TODO: When we navigate from cartError to static page, there is no navigation because of that modal
           * appears over staticPage, this callback help us to close the cartError modal in order
           * to make the correct navigation to staticPage. Maybe move away from modal UI to ReactNavigation Modal
           * could help to not use this callback
           */
          onSupportClick?.();
          if (isDesktop && enablePypestream) {
            setRedirectToHomePage(true);
            return router.navigate(routes.base);
          }
          router.navigate(routes.support);
        }}
        _text={{ textTransform: 'capitalize' }}
      >
        {formatMessage({ id: 'visitSupport' })}
      </Button>
    </OrderIssuesContainer>
  );
};
export default OrderIssues;
