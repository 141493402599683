import React from 'react';

import { Text } from '@fhs-legacy/universal-components';
import {
  IHorizontalLinkListWidgetFragment,
  ITextWidgetFragment,
  IVerticalLinkListWidgetFragment,
  IVerticalLinkListWithHeaderWidgetFragment,
  IWordmarkAndSocialIconsFragment,
} from 'generated/sanity-graphql';
import logger from 'utils/logger';

import { HorizontalLinkList } from './horizontal-link-list';
import { ColumnContainer } from './styled';
import { IColumn as IColumnProps, IOption as IOptionProps, OptionTypes } from './types';
import { VerticalLinkList } from './vertical-link-list';
import { VerticalLinkListWithHeader } from './vertical-link-list-with-header';
import { WordmarkAndSocialIcons } from './wordmark-and-social-icons';

export const getComponentForColumnOption = (option: IOptionProps) => {
  switch (option._type) {
    case OptionTypes.HorizontalLinkListWidget:
      return <HorizontalLinkList {...(option as IHorizontalLinkListWidgetFragment)} />;
    case OptionTypes.TextWidget:
      return (
        <Text
          color="token.text-reversed"
          variant="copyThree"
          textAlign={{ base: 'center', desktop: 'start' }}
        >
          {(option as ITextWidgetFragment).text?.locale}
        </Text>
      );
    case OptionTypes.VerticalLinkListWidget:
      return <VerticalLinkList {...(option as IVerticalLinkListWidgetFragment)} />;
    case OptionTypes.VerticalLinkListWithHeaderWidget:
      return (
        <VerticalLinkListWithHeader {...(option as IVerticalLinkListWithHeaderWidgetFragment)} />
      );
    case OptionTypes.WordmarkAndSocialIcons:
      return <WordmarkAndSocialIcons {...(option as IWordmarkAndSocialIconsFragment)} />;
    // @TODO add rest of the Feature Footer widgets:
    // https://github.com/rbilabs/ctg-whitelabel-app/tree/master/workspaces/frontend/src/components/footer/shared
    // CopyrightAndLanguageSelector
    // HighlightedVerticalLinkListWidget
    // ImageWidget
    // SocialIconsWithHeaderWidget:
    default:
      logger.warn(`Unknown column option: ${option._type}`);
      return null;
  }
};

export const Column: React.FC<IColumnProps> = props => (
  <ColumnContainer>
    {(props.options ?? []).map(
      (option: IOptionProps, index: number) =>
        // these don't change position between renders, so `index` as key here is fine
        option && <React.Fragment key={index}>{getComponentForColumnOption(option)}</React.Fragment>
    )}
  </ColumnContainer>
);
