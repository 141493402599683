import React from 'react';
import { useIntl } from 'react-intl';

import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';

export const MenuListLoading = () => {
  const { formatMessage } = useIntl();
  return (
    <LoadingContainer accessibilityLabel={formatMessage({ id: 'loading' })}>
      <LoadingAnimation />
    </LoadingContainer>
  );
};
