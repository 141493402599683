export enum PaymentMethodType {
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  SCHEME = 'SCHEME',
}

export type IApplePayPaymentMethod = {
  type: PaymentMethodType.APPLE_PAY;
};

export type IGooglePayPaymentMethod = {
  type: PaymentMethodType.GOOGLE_PAY;
};

export type SchemePaymentMethod = {
  type: PaymentMethodType.SCHEME;
  brand: 'VISA';
};

export type PaymentMethod = IApplePayPaymentMethod | IGooglePayPaymentMethod | SchemePaymentMethod;
