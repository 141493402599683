import { ISOs } from 'utils/form/constants';
import { ISignupForm } from 'utils/form/types';
import { loadRegion } from 'utils/intl/region';

const initialFormState = ({
  enableCustomBirthdayInput,
  regionIso,
  phoneNumber,
  enableSignupCommunicationsPreselection,
}: {
  enableCustomBirthdayInput: boolean;
  regionIso: ISOs;
  phoneNumber?: string;
  enableSignupCommunicationsPreselection: boolean;
}): ISignupForm => ({
  email: '',
  name: '',
  phoneNumber: phoneNumber || '',
  zipcode: '',
  dob: enableCustomBirthdayInput
    ? {
        day: '',
        month: '',
        year: '',
      }
    : '',
  country: regionIso || loadRegion(),
  agreesToTermsOfService: false,
  wantsPromotionalEmails: enableSignupCommunicationsPreselection,
});

export default initialFormState;
