import { useRef } from 'react';

// Box a value in a ref and make sure the ref is always up-to-date
// This can be useful for functions. You can maintain referential equality without
// worrying about a stale closure.
export function useBoxRef<BoxedValueType>(value: BoxedValueType) {
  const ref = useRef(value);
  ref.current = value;
  return ref;
}
