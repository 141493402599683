import { Stack } from 'expo-router';
import React from 'react';
import { useIntl } from 'react-intl';

import { useRumPageView } from '@fhs-legacy/frontend/src/hooks/rum/use-rum-page-view';
import { getServiceModeHeaderText } from '@fhs-legacy/frontend/src/pages/cart/types';
import { CartProvider, useCartContext } from '@fhs-legacy/frontend/src/state/cart';
import { PaymentProvider } from '@fhs-legacy/frontend/src/state/payments';

import { getScreenTitleHeader } from '../../components/get-screen-title-header';

export const unstable_settings = {
  initialRouteName: 'index',
};

const CartStack = () => {
  const { formatMessage } = useIntl();
  const { serviceMode } = useCartContext();

  const serviceModeFormattedText = getServiceModeHeaderText(formatMessage)[serviceMode];

  return (
    <Stack screenOptions={{ title: serviceModeFormattedText }}>
      <Stack.Screen
        name="index"
        options={{
          header: getScreenTitleHeader({ backStyle: 'back', testID: 'cart-back-button' }),
        }}
      />
      <Stack.Screen
        name="(checkout)/index"
        options={{
          header: getScreenTitleHeader({ backStyle: 'back', testID: 'checkout-back-button' }),
        }}
      />
      <Stack.Screen
        name="payment/index"
        options={{
          header: getScreenTitleHeader({
            backStyle: 'back',
          }),
        }}
      />
    </Stack>
  );
};

const CartPage = () => {
  useRumPageView('cart', 'Cart');

  return (
    <CartProvider>
      <PaymentProvider>
        <CartStack />
      </PaymentProvider>
    </CartProvider>
  );
};

export default CartPage;
