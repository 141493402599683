import { router } from 'expo-router';
import * as React from 'react';
import { FC } from 'react';

import { ISanityTextNode } from '@rbi-ctg/menu';
import ActionButton, { ActionButtonVariants } from 'components/action-button';

import { DoubleButtonContainer } from './loyalty-double-button-widget.styled';

interface ILoyaltyDoubleButtonWidget {
  leftButtonPath: ISanityTextNode;
  leftButtonText: ISanityTextNode;
  rightButtonPath: ISanityTextNode;
  rightButtonText: ISanityTextNode;
}

/**
 * Renders a double-sided button each navigating the user to a sanity specified loyalty tab
 */
const LoyaltyDoubleButtonWidget: FC<React.PropsWithChildren<ILoyaltyDoubleButtonWidget>> = ({
  leftButtonPath,
  leftButtonText,
  rightButtonPath,
  rightButtonText,
}) => {
  const handleClick = (path: string) => () => {
    router.navigate(path);
  };

  return (
    <DoubleButtonContainer>
      <ActionButton
        variant={ActionButtonVariants.INVERSE}
        onPress={handleClick(leftButtonPath?.locale)}
      >
        {leftButtonText?.locale}
      </ActionButton>
      <ActionButton
        variant={ActionButtonVariants.INVERSE}
        onPress={handleClick(rightButtonPath?.locale)}
      >
        {rightButtonText?.locale}
      </ActionButton>
    </DoubleButtonContainer>
  );
};

export default LoyaltyDoubleButtonWidget;
