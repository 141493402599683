import React from 'react';

import { Box } from '@fhs-legacy/universal-components';
import { INutritionInfo } from '@rbi-ctg/menu';

import { NutritionItem } from './nutrition-item';
import { SanityNutritionItemGroup } from './types';

type NutritionItemGroupProps = {
  nutritionItemGroup: SanityNutritionItemGroup;
  nutritionInfo: INutritionInfo;
  roundNutritionValue: (val: number) => number;
};

export const NutritionItemGroup: React.FC<React.PropsWithChildren<NutritionItemGroupProps>> = ({
  nutritionInfo,
  nutritionItemGroup,
  roundNutritionValue,
}) => {
  if (!nutritionItemGroup.mainItem) {
    return null;
  }

  return (
    <Box testID="nutrition-item-group">
      <NutritionItem
        testID="nutrition-item-group-main-item"
        nutritionInfo={nutritionInfo}
        nutritionItem={nutritionItemGroup.mainItem}
        roundNutritionValue={roundNutritionValue}
      />
      {nutritionItemGroup.lineItems?.map(
        (item, index) =>
          item && (
            <NutritionItem
              testID="nutrition-item-group-line-item"
              key={item?.nutritionKey || index}
              nutritionInfo={nutritionInfo}
              nutritionItem={item}
              roundNutritionValue={roundNutritionValue}
              isNested
            />
          )
      )}
    </Box>
  );
};
