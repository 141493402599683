const RBI_FEATURE = 'rbiFeature';

/**
 * Retrieve feature flag overrides passed into the app as query parameters
 *
 * NOTE:
 * all feature flag overrides must be prepended with `rbiFeature.`
 * ex: rbiFeature.enableFunkyFreshRhymes=true
 *
 * @returns { [featureFlag: string]: boolean; } an object where the keys are launch darkly flags and the values are booleans
 */
export const getFeatureFlagOverridesFromQueryParameters = (): { [id: string]: boolean } => {
  const searchParams = new URLSearchParams(window.location.search);
  const featureFlagOverrides = {};

  Array.from(searchParams).forEach(([key, value]) => {
    const [prefix, featureFlagName] = key.split('.');

    if (prefix === RBI_FEATURE) {
      let parsedValue: string | boolean | number = value;

      if (value === 'true') {
        parsedValue = true;
      } else if (value === 'false') {
        parsedValue = false;
      } else if (!Number.isNaN(+value)) {
        parsedValue = +value;
      }
      featureFlagOverrides[featureFlagName] = parsedValue;
    }
  });

  return featureFlagOverrides;
};
