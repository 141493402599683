import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  EnablePrepaidPaymentMethodsVariation,
  defaultPrepaidPaymentMethodsVariation,
} from 'state/launchdarkly/variations';
import { usePaymentContext } from 'state/payment';
import { IPaymentMethod } from 'state/payment/types';
import { extractPaymentTypeForLD, isAllowedReloadMethod } from 'utils/payment';

export const usePrepaidsPaymentMethods = () => {
  let nonSelectedReloadPaymentMethods: IPaymentMethod[] = [];
  let reloadPaymentMethods: IPaymentMethod[] = [];
  let selectedReloadPaymentMethod;

  const enablePrepaidPaymentMethods =
    useFlag<EnablePrepaidPaymentMethodsVariation>(
      LaunchDarklyFlag.ENABLE_PREPAID_PAYMENT_METHODS
    ) || defaultPrepaidPaymentMethodsVariation;

  const {
    canUseApplePay,
    canUseGooglePay,
    paymentMethods,
    prepaidReloadPaymentMethodId,
    setPrepaidReloadPaymentMethodId,
  } = usePaymentContext();

  if (enablePrepaidPaymentMethods.EXISTING_METHOD) {
    // Filter payment methods to only use those that are enabled for prepaid purchases/reloads.
    reloadPaymentMethods = paymentMethods.filter(paymentMethod => {
      const isValidPaymentMethod = isAllowedReloadMethod(paymentMethod);
      const paymentType = extractPaymentTypeForLD(paymentMethod);
      if (paymentType) {
        const paymentTypeIsEnabled = enablePrepaidPaymentMethods[paymentType];
        return paymentTypeIsEnabled && isValidPaymentMethod;
      }
      return isValidPaymentMethod;
    });

    // Obtain the currently selected prepaid payment method
    selectedReloadPaymentMethod = reloadPaymentMethods.find(method => {
      const accountIdentifier = method.accountIdentifier ?? method.fdAccountId ?? '';
      return accountIdentifier === prepaidReloadPaymentMethodId;
    });

    // If there is no selected method, select the first available in the enabled methods list.
    if (!selectedReloadPaymentMethod) {
      const accountIdentifier =
        reloadPaymentMethods[0]?.accountIdentifier ?? (reloadPaymentMethods[0]?.fdAccountId || '');
      setPrepaidReloadPaymentMethodId(accountIdentifier);
    }

    // Obtain a list of all non-selected prepaid payment methods
    nonSelectedReloadPaymentMethods = reloadPaymentMethods.filter(method => {
      const accountIdentifier = method.accountIdentifier ?? method.fdAccountId ?? '';
      const isNonSelectedPaymentMethod = prepaidReloadPaymentMethodId !== accountIdentifier;
      return isNonSelectedPaymentMethod;
    });
  }

  const allowPrepaidApplePay = canUseApplePay && enablePrepaidPaymentMethods.APPLE_PAY;
  const allowPrepaidGooglePay = canUseGooglePay && enablePrepaidPaymentMethods.GOOGLE_PAY;

  // This is used to enable/disable using existing payment methods for prepaid reload/purchase
  const allowReloadPaymentMethodSelection =
    enablePrepaidPaymentMethods.EXISTING_METHOD &&
    (!!reloadPaymentMethods.length || allowPrepaidApplePay || allowPrepaidGooglePay);

  // This is used to enable/disable using one time payments for prepaid reload/purchase.
  const allowOneTimePaymentMethod = enablePrepaidPaymentMethods.ONE_TIME_METHOD;

  return {
    allowOneTimePaymentMethod,
    allowPrepaidApplePay,
    allowPrepaidGooglePay,
    allowReloadPaymentMethodSelection,
    nonSelectedReloadPaymentMethods,
    reloadPaymentMethods,
    selectedReloadPaymentMethod,
  };
};
