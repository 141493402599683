import { withStaticProperties } from '@fhs/utils/src';

import { type FormFieldProps, FormField as _FormField } from './form-field';
import { FormFieldControl, type FormFieldControlProps } from './form-field-control';
import { FormFieldLabel, type FormFieldLabelProps } from './form-field-label';

export type { FormFieldControlProps, FormFieldProps, FormFieldLabelProps };
export const FormField = withStaticProperties(_FormField, {
  Control: FormFieldControl,
  Label: FormFieldLabel,
});
