import { Box, Pressable } from '@fhs-legacy/universal-components';

export const StyledPressable = Pressable.withConfig({
  width: { desktop: 932 },
  margin: '$4',
  marginX: { desktop: 'auto' },
  padding: '$3',
  backgroundColor: Styles.color.cardBackground,
  borderRadius: 8,
  borderColor: Styles.color.grey.four,
  borderWidth: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const IconContainer = Box.withConfig({
  padding: '$1.5',
  marginRight: '$3',
  backgroundColor: '__legacyToken.icon-active',
  borderRadius: 'full',
});

export const PlusIconContainer = Box.withConfig({
  padding: '$1.5',
  marginLeft: 'auto',
});
