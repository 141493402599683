import * as React from 'react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { HapticsNotificationType, hapticNotification } from 'utils/haptic';

import AnimationLoader from './animation-loader';
import {
  PullDownMessage,
  PullToRefreshContainer,
  ReleaseMessage,
} from './pull-to-reload-content.styled';
import { Action, IPullToRefreshContent } from './types';

const PullToRefreshContent = ({ action }: IPullToRefreshContent) => {
  const isPullingDown = action === Action.PullingDown;
  const isRefreshing = action === Action.Refreshing;
  const isReleasing = action === Action.Releasing;
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (isReleasing) {
      hapticNotification({ type: HapticsNotificationType.SUCCESS });
    }
  }, [isReleasing]);

  return (
    <PullToRefreshContainer isRefreshing={isRefreshing}>
      {isPullingDown && <PullDownMessage>{formatMessage({ id: 'pullToRefresh' })}</PullDownMessage>}
      {isReleasing && <ReleaseMessage>{formatMessage({ id: 'releaseToRefresh' })}</ReleaseMessage>}
      {isRefreshing && <AnimationLoader />}
    </PullToRefreshContainer>
  );
};

export default PullToRefreshContent;
