import React from 'react';

import LoyaltyReady from 'components/loyalty-user-ready';
import { withStoreSelected } from 'components/store-selected';
import { useFlag } from 'state/launchdarkly';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

import { LoyaltyOfferList } from './loyalty-offer-list';
import { LoyaltyOffersBase } from './loyalty-offers-base';

// Temporary flag so we can swap the different implementations no introducing issues to the current implementation.
// Once the refactor has finished remove this flag.
const LoyaltyOffers = () => {
  const isOffersV2Enabled = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_OFFERS_V2_FE);

  return (
    <LoyaltyReady>{isOffersV2Enabled ? <LoyaltyOfferList /> : <LoyaltyOffersBase />}</LoyaltyReady>
  );
};

export default withStoreSelected(LoyaltyOffers);
