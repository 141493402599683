import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconTikTok = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.483 2.1c.33 2.839 1.914 4.53 4.668 4.71v3.193c-1.596.156-2.994-.366-4.62-1.35v5.97c0 7.584-8.268 9.954-11.593 4.518-2.136-3.498-.828-9.636 6.025-9.882v3.366a9.914 9.914 0 0 0-1.59.39c-1.524.516-2.388 1.482-2.148 3.186.462 3.264 6.45 4.23 5.952-2.148V2.107h3.306V2.1Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
