import { useSelectedStep } from '@fhs/loyalty/src/state/offer-guide-cart-state';
import { ICartEntry } from '@rbi-ctg/menu';
import { useIsEditCartEnabled } from 'hooks/use-is-edit-cart-enabled';
import { useFavoritesContext } from 'state/favorites';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuContext } from 'state/menu';
import { useOrderContext } from 'state/order';

import useProductWizardFlow from './use-product-wizard-flow';

export const useEditingCartEntry = (isSimplyOffer?: boolean): ICartEntry => {
  const selectedStep = useSelectedStep();

  const enableFavorites = useFlag(LaunchDarklyFlag.ENABLE_FAVORITES);
  const { isOffer } = useMenuContext();
  const { isEdit, isFavorite } = useProductWizardFlow();
  const enableEditCart = useIsEditCartEnabled({ cartHasIncentive: isOffer });
  const { getCurrentCartEntry, cartIdEditing } = useOrderContext();
  const { currentCartEntry: currentFavoriteEntry, tempFavorite: currentFavorite } =
    useFavoritesContext();
  const isEditing =
    isFavorite && currentFavorite
      ? enableFavorites
      : isEdit && ((enableEditCart && cartIdEditing) || isSimplyOffer);
  const editingCartEntry = currentFavorite
    ? currentFavoriteEntry
    : isSimplyOffer
    ? selectedStep?.selectedEntry
    : getCurrentCartEntry(isOffer);

  return isEditing ? editingCartEntry : null;
};
