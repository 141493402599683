function parseStringValue(value: string) {
  let result: number | object | string | boolean;
  try {
    // Numbers with more than 16 chars, possibly are codes (like in loyalty) so we don't parse them
    result = value.length >= 16 && !isNaN(value as any) ? value : JSON.parse(value);
  } catch (error) {
    result = value;
  }
  return result;
}

export function convertQueryStringToObject<T extends object>(queryString: String): T {
  const params = new URLSearchParams(queryString.replace('?', ''));

  const result = {} as T;
  Array.from(params).forEach(([key, value]) => {
    const normalizedKey = key.replace('[]', '');
    const parsedValue = parseStringValue(value);

    const previousValue = result[normalizedKey];
    if (previousValue) {
      // Handle array like parameters like ?someParam[]=123&someParam[]=text
      result[normalizedKey] = [
        Array.isArray(previousValue) ? [...previousValue] : previousValue,
        parsedValue,
      ];
    } else {
      result[normalizedKey] = parsedValue;
    }
  });
  return result;
}
