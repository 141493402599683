import { View } from 'react-native';

import { Text, YStack, createMqStyles, tokens } from '@fhs/ui';

export const MenuListLegalText = () => {
  const mqStyles = useMqStyles();
  return (
    <YStack style={mqStyles.footer}>
      <View style={mqStyles.divider} />
      <YStack style={mqStyles.legalTextArea}>
        <Text.Paragraph size="sm" style={mqStyles.legalText}>
          Calories will vary based on modifications made to an item. Product availability, prices,
          offers and discounts may vary from in-restaurant.
        </Text.Paragraph>
        <Text.Paragraph size="sm" style={mqStyles.legalText}>
          2,000 calories a day is used for general nutrition advice, but calorie needs vary.
        </Text.Paragraph>
      </YStack>
    </YStack>
  );
};

const useMqStyles = createMqStyles({
  footer: {
    $base: {
      marginTop: 18,
      marginBottom: 8,
    },
  },
  legalTextArea: {
    $base: {
      paddingHorizontal: 16,
      paddingTop: 24,
      gap: 8,
      // Make space for the scroll to top button
      // so it doesn't cover legal text
      marginRight: 48,
    },
    $gteDesktop: {
      paddingTop: 44,
      paddingBottom: 16,
      paddingHorizontal: 32,
      marginRight: 0,
    },
  },
  divider: {
    $base: {
      height: 1,
      backgroundColor: tokens.colors.$blackOpacity10,
    },
  },
  legalText: {
    $base: {
      color: tokens.colors.$disabledText,
    },
    $gteDesktop: {
      textAlign: 'center',
    },
  },
});
