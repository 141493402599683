import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { DatePicker, TimePicker } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import { ActionsheetHeader, ActionsheetWrapper } from 'components/action-sheet';
import { IDatePickerData, IServerOrderWithPickupTime } from 'hooks/use-date-picker-options/types';
import { useCartContext } from 'state/cart';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { ServiceMode } from 'state/service-mode/types';

import { ActionButtonContainer, StyledActionSheetContent } from './pick-future-time-modal.styled';
import { CloseButton, HeaderTitle } from './styled';
import { IPickFutureTimeModalProps, ISelectedDate } from './types';
import { formatAvailableDates, formatAvailableTimes } from './utils';

export const PickFutureTimeModal: React.FC<IPickFutureTimeModalProps> = ({
  isOpen,
  onClose,
  onPickupTimeCallback,
  availableDates,
}) => {
  const { language } = useLocale();
  const { formatMessage } = useIntl();
  const { serviceMode } = useServiceModeContext();
  const { serverOrder } = useCartContext();
  const enableTodayAvailableOptionsFromBackend = useFlag(
    LaunchDarklyFlag.ENABLE_TODAY_AVAILABLE_PICKUP_OPTIONS_FROM_BACKEND
  );

  const firstDate = availableDates?.find(date => !date.isDisabled && date.options.length);
  const firstTimeOption = firstDate?.options[0];

  const [selectedDate, setSelectedDate] = useState<IDatePickerData | undefined>(firstDate);

  const [date, setDate] = useState<string | undefined | null>(
    firstDate ? firstDate.date : undefined
  );
  const [time, setTime] = useState<string | undefined>(
    firstTimeOption?.time ? firstTimeOption.time.toString() : undefined
  );

  const formattedTimes = useMemo(
    () => formatAvailableTimes({ availableTimes: selectedDate?.options, language }),
    [language, selectedDate?.options]
  );

  if (!date || !time || !availableDates || !selectedDate || !formattedTimes) {
    return null;
  }

  const isTodaySelected = selectedDate.date === firstDate?.date;

  const sectionTitle =
    serviceMode === ServiceMode.DELIVERY
      ? formatMessage({ id: 'scheduleDelivery' })
      : formatMessage({ id: 'schedulePickup' });

  const handleFutureTimePick = () => {
    const selectedDateTime = new Date(time);

    const futureTimePicked: ISelectedDate = {
      year: selectedDateTime.getFullYear(),
      month: selectedDateTime.getMonth(),
      day: selectedDateTime.getDate(),
      hour: selectedDateTime.getHours(),
      minutes: selectedDateTime.getMinutes(),
    };
    onPickupTimeCallback(futureTimePicked);
    onClose();
  };

  const formattedDates = formatAvailableDates({ availableDates, language, formatMessage });

  const shouldRenderDatePicker = formattedDates?.length > 1;

  if (!selectedDate) {
    return null;
  }

  // Just show the message for Today if the flag is enabled
  const warningMessage =
    enableTodayAvailableOptionsFromBackend &&
    isTodaySelected &&
    (serverOrder as IServerOrderWithPickupTime)?.warningMessage
      ? (serverOrder as IServerOrderWithPickupTime).warningMessage
      : '';

  return (
    <ActionsheetWrapper
      onOpenEventMessage="Pickup schedule order time"
      isOpen={isOpen}
      onClose={onClose}
    >
      <StyledActionSheetContent>
        <ActionsheetHeader width="full">
          <CloseButton onPress={onClose} />
          <HeaderTitle>{sectionTitle}</HeaderTitle>
        </ActionsheetHeader>
        {shouldRenderDatePicker && (
          <DatePicker
            accessibilityLabel="Select Date"
            accessibilityHint=""
            header="Select Date"
            ml="0"
            data={formattedDates}
            value={date}
            onChangeValue={selectedDate => {
              const currentSelectedDate = availableDates.find(item => item.date === selectedDate);

              setDate(selectedDate);
              setSelectedDate(currentSelectedDate as IDatePickerData);
            }}
          />
        )}
        <TimePicker
          accessibilityHint=""
          accessibilityLabel="Select time"
          header="Select time"
          data={formattedTimes}
          value={time}
          warningMessage={warningMessage}
          onChangeValue={selectedTime => {
            setTime(selectedTime as string);
          }}
        />
      </StyledActionSheetContent>
      <ActionButtonContainer>
        <ActionButton onPress={handleFutureTimePick}>
          {formatMessage({ id: 'continue' })}
        </ActionButton>
      </ActionButtonContainer>
    </ActionsheetWrapper>
  );
};
