import { CartScreen } from '@fhs/cart';
import { Cart as LegacyCart } from '@fhs-legacy/frontend/src/pages/cart/cart';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

export default function CartPage() {
  const enableCartService = useFlag(LaunchDarklyFlag.ENABLE_SIMPLY_BETTER_CART_SERVICE);
  return enableCartService ? <CartScreen /> : <LegacyCart />;
}
