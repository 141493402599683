import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { IRestaurantNode } from 'generated/rbi-graphql';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { useServiceModeContext } from 'state/service-mode';
import { ServiceMode } from 'state/service-mode/types';
import { useGetRestaurantAvailabilityFn, useIsMobileOrderingAvailable } from 'utils/restaurant';
import { isCatering } from 'utils/service-mode';

interface UseErrorMessageOptions {
  restaurant: IRestaurantNode;
}

export const useErrorMessage = ({ restaurant }: UseErrorMessageOptions) => {
  const { formatMessage } = useIntl();

  const getRestaurantAvailability = useGetRestaurantAvailabilityFn();
  const isMobileOrderingAvailable = useIsMobileOrderingAvailable(restaurant);

  const isRestaurantAvailable = getRestaurantAvailability(restaurant);

  const { serviceModeStatus } = useServiceModeStatus(restaurant);
  const { serviceMode } = useServiceModeContext();

  const isNotAvailable = useCallback(
    (serviceMode: ServiceMode): boolean => {
      const status = serviceModeStatus[serviceMode];

      if (!status) {
        return true;
      }

      return !status.available && !status.disabled;
    },
    [serviceModeStatus]
  );

  return useMemo(() => {
    const isCateringNotAvailableAndEnabled =
      isCatering(serviceMode) && isNotAvailable(ServiceMode.CATERING_PICKUP);
    if (!restaurant.hasMobileOrdering) {
      return formatMessage({ id: 'mobileOrderingUnavailableAtStore' });
    } else if (
      !isMobileOrderingAvailable ||
      !isRestaurantAvailable ||
      !restaurant.restaurantPosData
    ) {
      return formatMessage({ id: 'mobileOrderingUnavailable' });
    } else if (isCateringNotAvailableAndEnabled) {
      return formatMessage({ id: 'storeDoesNotHaveCateringOrders' });
    }

    return;
  }, [
    serviceMode,
    isNotAvailable,
    restaurant.hasMobileOrdering,
    restaurant.restaurantPosData,
    isMobileOrderingAvailable,
    isRestaurantAvailable,
    formatMessage,
  ]);
};
