/*
 * This MenuLineItem component is used as a composition method for the rows
 * that are rendered for comboslots, modifiers, and sometimes picker aspects.
 * The composition is to allow different UX patterns to exist with a consistent
 * UI of the name/image/padding etc.
 */
import React from 'react';
import { useIntl } from 'react-intl';

import { Box, FormControl } from '@fhs-legacy/universal-components';
import { ISanityImage } from '@rbi-ctg/menu';

// import Image from './image';
import {
  LineItemDescription,
  LineItemDescriptionText,
  OptionContainer,
  SelectionContainer,
} from './menu-line-item.styled';

interface IProps {
  testID?: string;
  bottomContent?: React.ReactNode;
  disabled?: boolean;
  highlightOnHover?: boolean;
  ignoreLabelClicks?: boolean;
  image?: ISanityImage;
  imageDescription?: string;
  indicator: React.ReactNode;
  name: string;
  selected?: boolean;
  details?: string | React.ReactNode;
}

const MenuLineItem: React.FC<React.PropsWithChildren<IProps>> = ({
  testID: dataTestId,
  bottomContent,
  disabled,
  // highlightOnHover = true,
  // ignoreLabelClicks,
  // image,
  imageDescription,
  indicator,
  name,
  selected,
  details,
}) => {
  const truthyName = imageDescription || name || '';
  const formattedName = truthyName.toLowerCase().replace(/\s/g, '');
  const { formatMessage } = useIntl();

  /**
   * This component (a memoized callback) is created so that `details` is supposed as both a string (in
   * which case it'll be wrapped in <Details />) and as a React Element if a custom wrapper
   * is required.
   */
  const ItemDetails = React.useCallback(() => {
    return typeof details === 'string' ? (
      <LineItemDescriptionText testID={`modifier-option-details-${formattedName}`}>
        {details}
      </LineItemDescriptionText>
    ) : (
      <Box>{details}</Box>
    );
  }, [details, formattedName]);

  return (
    <OptionContainer
      testID={dataTestId}
      disabled={disabled}
      // TODO: RN - do we need to support this?
      // highlightOnHover={highlightOnHover}
      selected={selected}
    >
      <FormControl.Label
        accessibilityLabel={truthyName || formatMessage({ id: 'option' })}
        // TODO: RN - add proper role accessibilty
        // role="option"
        // TODO: RN - support this via pressable prop
        // ignoreClicks={ignoreLabelClicks}
        width="full"
        marginBottom="0"
      >
        {/* TODO: RN - do we need this for TH? previously was wrapped in a
            component that returned null so don't think we need this anymore */}
        {/* <Image image={image} alt={truthyName} /> */}
        <SelectionContainer>
          <LineItemDescription>
            <LineItemDescriptionText testID={`modifier-option-${formattedName}`}>
              {name}
            </LineItemDescriptionText>
            <ItemDetails />
          </LineItemDescription>
          {indicator}
        </SelectionContainer>
      </FormControl.Label>
      {bottomContent}
    </OptionContainer>
  );
};

export default MenuLineItem;
