import { ActivityIndicator, FlatList, Image, StyleSheet, View } from 'react-native';

import { tokens } from '@fhs/ui';

import { useCart } from '../api';
import { OrderCartInformation } from '../components/order-cart-information';
import { OrderPickupInformationSection } from '../components/order-pickup-information-section';
import { OrderStatus } from '../components/order-status';

// TODO: What happens when the cart goes into an Error state?
export function OrderScreen() {
  const { data: cart, isPending } = useCart();

  const sections = [
    { key: 'OrderStatus', data: [void 0] },
    { key: 'OrderPickupInformation', data: [void 0] },
    { key: 'OrderDetails', data: [void 0] },
  ];

  if (isPending) {
    return <ActivityIndicator size="large" />;
  }

  return (
    <View style={styles.page}>
      <Image
        source={{
          uri: 'https://s3-alpha-sig.figma.com/img/6150/634e/aa353790746305dbb86363af6e3a3d51?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=qAozTtViFH0QNUwAqdL7rBTA7DEFt0Wl-z-IyUvDWq3Wi9IuiZN2cco08iHkcbmDipkHyx9L4lgFhL2V~ltk4UlndwZZcOL0lyLILXHT3wVDllInRcK9XTan78soixJ5tsd6R0nG9AwW3GpDZ732xP9YuQgFrvXXm4PDF13gDDRa4gjDprUYkmxx38HZ2SlXL7M2o3NCp-vvJG2O5Nwvaf3d3xp8OBcKdbVGPz2aKnYkZKrT4V8WkEPiX3DCklCYx7C0AdAcOKpi5or8Vi-m6YEvL831oZoXE5OZ2oDuqJkPqV2uahwCkmuOjCnDhNPLWRvIh1odIVJpCj1OU9ASgg__',
        }}
        style={styles.image}
      />
      <FlatList
        style={styles.container}
        contentContainerStyle={styles.item}
        data={sections}
        renderItem={({ item }) => {
          switch (item.key) {
            case 'OrderStatus':
              return <OrderStatus status={cart?.state} />;
            case 'OrderPickupInformation':
              return <OrderPickupInformationSection />;
            case 'OrderDetails':
              return <OrderCartInformation />;
            default:
              return null;
          }
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 62,
    width: '100%',
  },
  page: {
    position: 'relative',
    backgroundColor: 'white',
    borderTopColor: tokens.colors.$black10,
    borderTopWidth: 1,
    flex: 1,
  },
  item: {
    gap: 24,
    width: 375,
    marginHorizontal: 'auto',
    padding: 16,
  },
  container: {
    flex: 1,
  },
});
