import React from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@fhs-legacy/universal-components';
import Picture from 'components/picture';
import { useFeatureDisclaimers } from 'hooks/use-feature-disclaimers';

const DeliveryDisclaimerContainer = Box.withConfig({
  _text: {
    textAlign: 'center',
    fontSize: '2xs',
  },

  marginTop: '$4',
});

const DeliveryLogoContainer = Box.withConfig({
  width: '$40',
  alignSelf: 'center',
});

const DeliveryDisclaimer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const { featureDisclaimers } = useFeatureDisclaimers();
  const { deliveryDisclaimer, deliveryLogo } = featureDisclaimers || {};

  // The second condition is a safeguard against a Sanity bug with `localeString`
  // Refs. https://rbidigital.slack.com/archives/CCHFM51A8/p1620400033200600
  if (!deliveryDisclaimer || !deliveryDisclaimer.locale?.trim()) {
    return null;
  }

  return (
    <DeliveryDisclaimerContainer>
      {deliveryDisclaimer.locale}
      {deliveryLogo && (
        <DeliveryLogoContainer>
          <Picture
            image={deliveryLogo.locale}
            alt={formatMessage({ id: 'deliveryLogoAlt' })}
            objectFitContain
          />
        </DeliveryLogoContainer>
      )}
    </DeliveryDisclaimerContainer>
  );
};

export default DeliveryDisclaimer;
