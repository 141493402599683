import { useRef } from 'react';
import { Animated, Easing } from 'react-native';

import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { isNative } from 'utils/environment';

const calculateTransitionDuration = (toDeg: number, fromDeg: number) => {
  return (Math.abs(toDeg - fromDeg) / 800) * 1000; // in milliseconds
};

export default function useRotateAnimation({
  isRotated,
  fromDeg,
  toDeg,
}: {
  isRotated: boolean;
  fromDeg: number;
  toDeg: number;
}) {
  const rotateAnimation = useRef(new Animated.Value(isRotated ? 1 : 0));

  const rotateData = useRef(
    rotateAnimation.current.interpolate({
      inputRange: [0, 1],
      outputRange: [`${fromDeg}deg`, `${toDeg}deg`],
    })
  );

  useEffectOnUpdates(() => {
    const rotatedValues = {
      from: 0,
      to: 1,
    };
    const defaultValues = {
      from: 1,
      to: 0,
    };
    const { from, to } = isRotated ? rotatedValues : defaultValues;
    rotateAnimation.current.setValue(from);
    Animated.timing(rotateAnimation.current, {
      toValue: to,
      duration: calculateTransitionDuration(toDeg, fromDeg),
      useNativeDriver: isNative,
      easing: Easing.linear,
    }).start();
  }, [isRotated]);

  return { rotateData: rotateData.current };
}
