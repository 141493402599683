import { omit } from 'lodash';
import { forwardRef } from 'react';
import { StyleSheet, View } from 'react-native';

import { SkeletonTextBlock } from '@fhs/ui';

import { tokens } from '../../tokens';
import { HeaderText } from '../header/header-text';
import { Pressable, PressableProps } from '../pressable';
import { XStack, YStack } from '../stack';
import { Text } from '../text/text';

import { LabelByType } from './constants';
import type { ServiceModeLocationProps } from './types';

export const DesktopHeaderServiceModeLocation = forwardRef<View, ServiceModeLocationProps>(
  function DesktopHeaderServiceModeLocation(props: ServiceModeLocationProps, forwardedRef) {
    const pressableProps = omit(props, [
      'type',
      'storeText',
      'serviceModeSubtitle',
    ]) satisfies PressableProps;
    const serviceModeLabel = LabelByType[props.type];

    return (
      <Pressable
        {...pressableProps}
        style={[styles.pressable, pressableProps.style]}
        ref={forwardedRef}
      >
        <YStack>
          {props.loading ? (
            <SkeletonTextBlock lines={2} style={styles.skeleton} />
          ) : (
            <>
              <Text numberOfLines={1} ellipsizeMode="tail" style={styles.bodyText}>
                {serviceModeLabel}
              </Text>

              {props.storeText ? (
                <XStack>
                  <HeaderText numberOfLines={1} ellipsizeMode="tail" style={styles.headerText}>
                    {props.storeText}
                  </HeaderText>
                </XStack>
              ) : (
                <HeaderText>Locations</HeaderText>
              )}
            </>
          )}
        </YStack>
      </Pressable>
    );
  }
);

const styles = StyleSheet.create({
  pressable: {
    alignItems: 'center',
    gap: 8,
    flexDirection: 'row',
    flexGrow: 1,
    flexShrink: 1,
  },
  skeleton: {
    width: 120,
  },
  headerText: {
    maxWidth: 150,
    textDecorationLine: 'underline',
  },
  bodyText: {
    fontSize: 12,
    lineHeight: 16,
    color: tokens.colors.$disabledText,
  },
});
