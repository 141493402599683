import { Image, ImageBackground, ImageStyle } from 'expo-image';
import { StyleProp, View } from 'react-native';

import { createMqStyles, useMqSelect } from '@fhs/ui';

import { CtaButton } from '../shared/cta-button';

export function HotSauceBarCanadaCard() {
  const mqStyles = useMqStyles();
  const backgroundSource = useMqSelect(
    {
      $gteDesktopLg: require('./hot-sauce-bar-canada-card-background-desktop.webp'),
    },
    require('./hot-sauce-bar-canada-card-background-tablet.webp')
  );
  return (
    <ImageBackground
      alt={'Hot Sauce Bar. Bottles starting at $11.99. Order Now'}
      source={backgroundSource}
      placeholder={{ thumbhash: 'VzoHC44IeGiCekhqDYT2lHY' }}
      style={mqStyles.background}
    >
      <View style={mqStyles.contentContainer}>
        <Image
          source={require('./hot-sauce-bar-canada-card-logo.webp')}
          alt="Hot Sauce Bar"
          placeholder={{ thumbhash: 'PwiCAYAVh4eIeHAAAAAAADepf42LZmg' }}
          style={mqStyles.logo as StyleProp<ImageStyle>}
          contentFit="contain"
        />
        <Image
          source={require('./hot-sauce-bar-canada-card-text.webp')}
          alt="Bottles starting at"
          placeholder={{ thumbhash: 'PwiCAYAVh4eIeHAAAAAAADepf42LZmg' }}
          style={mqStyles.text as StyleProp<ImageStyle>}
          contentFit="contain"
        />
        <Image
          source={require('./hot-sauce-bar-canada-card-price.webp')}
          alt="Hot Sauce Bar"
          placeholder={{ thumbhash: 'PwiCAYAVh4eIeHAAAAAAADepf42LZmg' }}
          style={mqStyles.price as StyleProp<ImageStyle>}
          contentFit="contain"
        />
        <CtaButton
          type="solid-inverted"
          href="/menu/section-5c3e9ca0-682f-4ee2-83f8-11ded3d7f00f"
        />
      </View>
    </ImageBackground>
  );
}

const useMqStyles = createMqStyles({
  background: {
    $base: {
      aspectRatio: 1024 / 505,
    },
    $gteDesktopLg: {
      aspectRatio: 1920 / 757,
    },
  },
  contentContainer: {
    $base: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '47%',
      top: '16%',
      left: '8%',
    },
    $gteDesktopLg: {
      width: '50%',
      top: '10%',
      left: '3%',
    },
  },
  logo: {
    $base: {
      aspectRatio: 422 / 103,
      width: '100%',
      marginBottom: '5%',
    },
  },
  text: {
    $base: {
      aspectRatio: 270 / 36,
      width: '49%',
    },
    $gteDesktopLg: {
      width: '34%',
    },
  },
  price: {
    $base: {
      aspectRatio: 382 / 172,
      width: '61%',
      marginBottom: '3%',
    },
    $gteDesktopLg: {
      width: '48%',
    },
  },
});
