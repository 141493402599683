import React from 'react';

import { Box, Text } from '@fhs-legacy/universal-components';
import { NutritionDisclaimer } from 'components/nutrition/nutrition-disclaimer';
import { useFeatureDisclaimers } from 'hooks/use-feature-disclaimers';
import { useStoreContext } from 'state/store';

import SodiumDisclaimerUS from './sodium-disclaimer';

interface ILegalDisclaimers {
  hideNutritionLegalDisclaimer?: boolean;
}

const LegalDisclaimers = ({ hideNutritionLegalDisclaimer = false }: ILegalDisclaimers) => {
  const { noStoreSelected } = useStoreContext();
  const { featureDisclaimers } = useFeatureDisclaimers();
  const { superUpsell, calorie, itemAvailability } = featureDisclaimers || {};

  return (
    <Box marginBottom="$5">
      {noStoreSelected ? (
        <Text variant="copyTwo" marginBottom="$4">
          {itemAvailability?.locale}
        </Text>
      ) : null}
      {!hideNutritionLegalDisclaimer ? (
        <Box testID="wizard-nutrition-disclaimer">
          <NutritionDisclaimer content={superUpsell?.localeRaw} />
          <NutritionDisclaimer content={calorie?.localeRaw} />
        </Box>
      ) : null}
      <SodiumDisclaimerUS />
    </Box>
  );
};

export default LegalDisclaimers;
