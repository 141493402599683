import { useField } from 'formik';

import { addWithConfig } from '../../utils';
import {
  useFormikFormControlState,
  usePropsWithFormikFormControlName,
} from '../formik-form-control';
import { ToggleButtonGroup } from '../toggle-button-group';

import type { FormikToggleButtonGroupProps } from './types';

export function FormikToggleButtonGroup<ValueType extends string>(
  props: FormikToggleButtonGroupProps<ValueType>,
) {
  const { name, toggleButtonGroupProps, ...rest } = usePropsWithFormikFormControlName(props);
  const formControlState = useFormikFormControlState();
  const [field, meta, helpers] = useField(name);

  const isInvalid = Boolean(meta.error && meta.touched);
  return (
    <ToggleButtonGroup
      value={field.value}
      onChangeValue={(nextValue) => helpers.setValue(nextValue)}
      isDisabled={toggleButtonGroupProps.isDisabled ?? formControlState.isDisabled}
      isInvalid={isInvalid || formControlState.isInvalid}
      {...toggleButtonGroupProps}
      {...rest}
    />
  );
}

export default addWithConfig(FormikToggleButtonGroup);
