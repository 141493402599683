import { createMqStyles } from '../../mq-styles';
import { tokens } from '../../tokens';

import { Text, type TextProps } from './text';

type ParagraphTextSize = 'lg' | 'md' | 'sm' | 'xs';

export type ParagraphTextProps = TextProps & {
  size: ParagraphTextSize;
};

export function ParagraphText({ size, selectable = true, ...props }: ParagraphTextProps) {
  const styles = useMqStyles();

  return (
    <Text {...props} selectable={selectable} style={[styles.baseline, styles[size], props.style]} />
  );
}

const useMqStyles = createMqStyles({
  baseline: {
    $base: {
      color: tokens.colors.$houseDark,
    },
  },
  lg: {
    $base: { fontSize: 16, lineHeight: 20 },
    $gteDesktop: { fontSize: 20, lineHeight: 24 },
  },
  md: {
    $base: { fontSize: 14, lineHeight: 18 },
    $gteDesktop: { fontSize: 16, lineHeight: 20 },
  },
  sm: {
    $base: { fontSize: 12, lineHeight: 16 },
    $gteDesktop: { fontSize: 14, lineHeight: 16 },
  },
  xs: {
    $base: { fontSize: 10, lineHeight: 12 },
    $gteDesktop: { fontSize: 12, lineHeight: 14 },
  },
});
