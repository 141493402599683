import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useUIContext } from 'state/ui';

import {
  buildFormattersMap,
  composeBlockContents,
  interpolatePlaceholders,
} from './locale-smart-block-content-helpers';
import { FormattersMap, TransformSmartBlockContentFn } from './types';

export const useLocaleSmartBlockContent = () => {
  const formatters = useIntl();
  const { formatCurrencyForLocale } = useUIContext();

  const formattersMap = useMemo<FormattersMap>(
    () => buildFormattersMap(formatters, formatCurrencyForLocale),
    [formatters, formatCurrencyForLocale]
  );

  const transformSmartBlockContent = useCallback<TransformSmartBlockContentFn>(
    ({ blockContent, interpolationSourceObject, placeholderFieldsMap }) => {
      const blocks = blockContent?.localeRaw ?? [];

      const interpolationsMap = interpolatePlaceholders({
        blocks,
        interpolationSourceObject,
        placeholderFieldsMap,
        formattersMap,
      });

      return {
        ...blockContent,
        localeRaw: composeBlockContents({
          blocks,
          interpolationsMap,
        }),
      };
    },
    [formattersMap]
  );

  return {
    transformSmartBlockContent,
  };
};
