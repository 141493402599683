import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconCartFill = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M9 10.726c.167 0 .304-.05.412-.15a.518.518 0 0 0 .163-.4v-2.45h-1.15v2.45c0 .166.054.3.162.4.109.1.246.15.413.15Zm.575-4.55V6.6h4.85v-.425c0-.684-.233-1.263-.7-1.738-.467-.475-1.042-.712-1.725-.712s-1.258.237-1.725.712a2.386 2.386 0 0 0-.7 1.738ZM15 10.726c.167 0 .304-.05.412-.15a.518.518 0 0 0 .163-.4v-2.45h-1.15v2.45c0 .166.054.3.162.4.109.1.246.15.413.15ZM5.95 21.6c-.4 0-.742-.142-1.025-.425A1.397 1.397 0 0 1 4.5 20.15V8.05c0-.4.142-.742.425-1.025A1.397 1.397 0 0 1 5.95 6.6h2.475v-.425c0-1 .346-1.846 1.037-2.538C10.155 2.946 11 2.601 12 2.601c1 0 1.846.345 2.537 1.037.692.692 1.038 1.538 1.038 2.538V6.6h2.475c.4 0 .742.141 1.025.425.283.283.425.625.425 1.025v12.1c0 .4-.142.741-.425 1.025a1.397 1.397 0 0 1-1.025.425H5.95Z"
      />
    </Svg>
  );
};
