import { router } from 'expo-router';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import useAuth from 'hooks/auth/use-auth';
import useDialogModal from 'hooks/use-dialog-modal';
import { useAmplitudeContext } from 'state/amplitude';
import { routes } from 'utils/routing';

import { ALLOW_REMOVE_LAST_FAVORITE_STORE } from './constants';

export function useFavoriteStores() {
  const { user } = useAuth();
  const existingFavoriteStores = useMemo(() => {
    const userFavoriteStores = user?.details?.favoriteStores || [];
    const trimmedFavoriteStores = userFavoriteStores.map(store => ({
      storeId: store.storeId,
      storeNumber: store.storeNumber || '',
    }));
    // We need to remove falsy ids that were previously saved from bad sanity data
    return trimmedFavoriteStores.filter(store => !!store.storeId);
  }, [user]);

  return {
    validFavStores: existingFavoriteStores,
  };
}
export const useFavoriteStore = (storeId: string, storeNumber: string) => {
  const { formatMessage } = useIntl();
  const { updateUserFavStores, isAuthenticated } = useAuth();
  const { validFavStores } = useFavoriteStores();
  const { updateAmplitudeUserAttributes } = useAmplitudeContext();

  const [favStoreLoading, setFavStoreLoading] = useState(false);
  const [hasErrorRemovingLastFavStore, setHasErrorRemovingLastFavStore] = useState(false);

  const [ErrorFavoriteStoreDialog, openErrorDialog] = useDialogModal({
    showCancel: true,
    onConfirm: () => router.navigate(routes.signIn),
    modalAppearanceEventMessage: 'Error: Sign In to Set Favorite Stores',
  });

  const isFavorite = useMemo(
    () => !!validFavStores.find(store => store.storeId === storeId),
    [storeId, validFavStores]
  );

  const onDismissErrorRemovingLastFavStoreModal = () => {
    setHasErrorRemovingLastFavStore(false);
  };

  const toggleFavorite = async () => {
    if (!isAuthenticated) {
      openErrorDialog({
        title: formatMessage({ id: 'errorDialogFavoriteStoreTitle' }),
        message: formatMessage({ id: 'errorDialogFavoriteStoreMessage' }),
      });
      return;
    }

    // Show error dialog when trying to remove the last favorite store if not allowed
    if (isFavorite && validFavStores.length === 1 && !ALLOW_REMOVE_LAST_FAVORITE_STORE) {
      setHasErrorRemovingLastFavStore(true);
      return;
    }

    const allFavoriteStores = isFavorite
      ? validFavStores.filter(store => store.storeId !== storeId)
      : [{ storeId, storeNumber }, ...validFavStores];
    // We need to remove falsy ids before we save to clean up bad sanity data
    const allValidFavoriteStores = allFavoriteStores.filter(store => !!store.storeId);
    setFavStoreLoading(true);
    await updateUserFavStores(allValidFavoriteStores);
    updateAmplitudeUserAttributes({
      favoriteStores: JSON.stringify(allValidFavoriteStores.map(store => store?.storeNumber)),
    });
    setFavStoreLoading(false);
  };

  return {
    toggleFavorite,
    isFavorite,
    ErrorFavoriteStoreDialog,
    favStoreLoading,
    hasErrorRemovingLastFavStore,
    onDismissErrorRemovingLastFavStoreModal,
  };
};
