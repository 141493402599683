import { Redirect } from 'expo-router';

import { AllOffersScreen } from '@fhs/loyalty';
import { useMqSelect } from '@fhs/ui';
import { withStoreSelected } from '@fhs-legacy/frontend/src/components/store-selected';

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

function AllOffers() {
  const isDesktop = useMqSelect({ $gteDesktop: true }, false);

  if (isDesktop) {
    return <Redirect href="/v2/offers" />;
  }

  return <AllOffersScreen />;
}

export default withStoreSelected(AllOffers);
