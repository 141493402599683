import { useControllableState, useHandledAccessibilityActions } from '../../hooks';
import { Box } from '../box';
import { HStack } from '../h-stack';
import { IconButton } from '../icon-button';

import type { ValueStepperButtonProps, ValueStepperProps } from './types';

const ValueStepperButton = ({
  isDisabled,
  testID,
  iconName,
  onPress,
  ...rest
}: ValueStepperButtonProps) => (
  <IconButton
    testID={testID}
    isDisabled={isDisabled}
    variant="outline"
    // This button is redundant a11y-wise since we registered an accessibility action with
    // the same purpose
    accessible={false}
    iconName={iconName}
    height="8"
    width="8"
    padding="0"
    justifyContent="center"
    alignItems="center"
    onPress={onPress}
    {...rest}
  />
);

export function ValueStepper<OptionValue>({
  accessibilityLabel,
  accessibilityHint,
  defaultValue,
  isDisabled,
  onChangeValue,
  options,
  value,
  testID,
  _selectedOptionLabel,
  _incrementButton,
  _decrementButton,
  ...rest
}: ValueStepperProps<OptionValue>) {
  const [currentValue, setCurrentValue] = useControllableState({
    defaultValue,
    value,
    handleValueChange: onChangeValue,
  });

  const selectedOptionIndex = options.findIndex((opt) => opt.value === currentValue);
  const selectedOption = options[selectedOptionIndex];

  const isDecrementDisabled = isDisabled || selectedOptionIndex <= 0;
  const isIncrementDisabled = isDisabled || selectedOptionIndex >= options.length - 1;

  const handleIncrement = () => {
    if (!isIncrementDisabled) {
      const nextSelectedOption = options[selectedOptionIndex + 1];
      setCurrentValue(nextSelectedOption.value);
    }
  };
  const handleDecrement = () => {
    if (!isDecrementDisabled) {
      const nextSelectedOption = options[selectedOptionIndex - 1];
      setCurrentValue(nextSelectedOption.value);
    }
  };

  const [accessibilityActions, handleAccessibilityActions] = useHandledAccessibilityActions([
    {
      name: 'increment',
      label: 'increment',
      handler: handleIncrement,
    },
    {
      name: 'decrement',
      label: 'decrement',
      handler: handleDecrement,
    },
  ]);

  return (
    <HStack
      alignItems="center"
      space="2"
      testID={testID}
      accessible
      accessibilityLabel={accessibilityLabel}
      accessibilityHint={accessibilityHint}
      accessibilityRole="spinbutton"
      accessibilityValue={{ text: `${selectedOption.value}` }}
      accessibilityState={{ disabled: isDisabled }}
      accessibilityActions={accessibilityActions}
      onAccessibilityAction={handleAccessibilityActions}
      {...rest}
    >
      <ValueStepperButton
        testID={`${testID}-decrement-button`}
        isDisabled={isDecrementDisabled}
        iconName="remove"
        {..._decrementButton}
        onPress={() => {
          handleDecrement();
        }}
      />

      <Box {..._selectedOptionLabel}>{selectedOption.label}</Box>

      <ValueStepperButton
        testID={`${testID}-increment-button`}
        isDisabled={isIncrementDisabled}
        iconName="add"
        {..._incrementButton}
        onPress={() => {
          handleIncrement();
        }}
      />
    </HStack>
  );
}
