import React from 'react';

import { Text } from '@fhs-legacy/universal-components';

import { Body2 } from '../store-card.styled';

interface StoreCardCaptionProps {
  prefix?: string;
  suffix: string | JSX.Element;
}

export const StoreCardCaption: React.VFC<StoreCardCaptionProps> = ({ prefix, suffix }) => {
  return (
    <>
      {prefix ? <Body2>{prefix}</Body2> : null}
      {prefix && suffix ? <Text> &bull; </Text> : null}
      {suffix}
    </>
  );
};
