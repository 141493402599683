import { Box, Header } from '@fhs-legacy/universal-components';

export const SectionTitle = Header.withConfig({
  variant: 'headerTwo',
  marginBottom: '$4',
});

export const SectionContainer = Box.withConfig({
  marginTop: '$8',
  marginBottom: '$4',
});
