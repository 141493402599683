import { router } from 'expo-router';
import { isUndefined } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@fhs-legacy/universal-components';
import { useLoyaltyFeaturedRewardsQuery } from 'generated/sanity-graphql';
import { useIsMobileBp } from 'hooks/breakpoints';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { routes } from 'utils/routing';

import {
  AvailablePoints,
  AvailablePointsCaption,
  BannerContainer,
  CurrentProgress,
  DashboardButton,
  DashboardButtonText,
  PointsContainer,
  PointsProgress,
  PointsProgressContainer,
  RewardContainer,
  StyledLoyaltyPointsIcon,
  TierDot,
  TierDotValue,
  UserRewardPoints,
  UserRewardPointsContainer,
} from './loyalty-tiers-widget.styled';
import { NullableRewardTier, RewardTier } from './types';
import { getProgressBarValue, showTiersDynamically } from './utils';

const LoyaltyTiersWidget = () => {
  const { formatMessage } = useIntl();
  const { loyaltyUser } = useLoyaltyUser();
  const isMobile = useIsMobileBp();
  const { data, loading: featuredRewardsLoading } = useLoyaltyFeaturedRewardsQuery();
  const [featuredRewards] = data?.allRewardsSections || [];
  const { engineRewardsMap, loading: rewardsListLoading } = useLoyaltyRewardsList();
  const isLoading = featuredRewardsLoading || rewardsListLoading;
  const userPoints = loyaltyUser?.points ?? 0;
  const MAX_TIERS_POSITION = 7;
  const rewardTiersData = useMemo(() => {
    if (isLoading) {
      return [];
    }
    return featuredRewards?.rewardsList?.reduce((acc: RewardTier[], featuredReward) => {
      const { reward, category } = featuredReward || {};
      const engineReward = engineRewardsMap[reward?.loyaltyEngineId || ''];
      const { label: categoryLabel } = category || {};
      const { pointCost } = engineReward || {};

      const rewardTier = {
        topLabel: categoryLabel?.locale || '',
        pointsLabel: pointCost,
        id: reward?._id,
      };

      const allDataAvailable = (
        nullableRewardTier: NullableRewardTier
      ): nullableRewardTier is RewardTier => !Object.values(nullableRewardTier).some(isUndefined);

      if (allDataAvailable(rewardTier)) {
        acc.push(rewardTier);
      }
      return acc;
    }, []);
  }, [engineRewardsMap, featuredRewards, isLoading]);

  const handleNavigation = useCallback(() => {
    router.navigate(routes.rewardsHistory);
  }, []);

  const renderTierDotColumn = (
    value: string,
    completed: boolean,
    left?: number,
    leftValue?: number
  ) => (
    <>
      <TierDot completed={completed} left={left} />
      <TierDotValue left={leftValue}>{value}</TierDotValue>
    </>
  );

  const renderTiersDot = (initialValue: string, rewardTiersArray: RewardTier[]) => (
    <PointsProgressContainer>
      <PointsProgress>
        {renderTierDotColumn(initialValue, userPoints > 0)}

        {rewardTiersArray.map((tier: RewardTier, index) => {
          const increaseWidthTierDot = 1.54;
          const increaseWidthValue = 1.5;
          const left = (100 / rewardTiersArray.length) * (index + 1) * increaseWidthTierDot;
          const leftValue = (100 / rewardTiersArray.length) * (index + 1) * increaseWidthValue;
          const completed = tier.pointsLabel <= userPoints;
          const rewardKey = tier.id || index;

          return (
            <Box key={rewardKey}>
              {renderTierDotColumn('' + tier.pointsLabel, completed, left, leftValue)}
            </Box>
          );
        })}
        <CurrentProgress $progress={getProgressBarValue(userPoints, rewardTiersArray)} />
      </PointsProgress>
    </PointsProgressContainer>
  );

  if (!rewardTiersData?.length) {
    return null;
  }

  const displayedRewardsData = showTiersDynamically(userPoints, rewardTiersData);

  const checkTiersSizeOnMobile = displayedRewardsData.length > MAX_TIERS_POSITION && isMobile;

  const displayedSplittedRewardsData = checkTiersSizeOnMobile
    ? displayedRewardsData.slice(MAX_TIERS_POSITION, displayedRewardsData.length)
    : null;

  const displayedOriginalSizeTiers = checkTiersSizeOnMobile
    ? displayedRewardsData.slice(0, MAX_TIERS_POSITION)
    : displayedRewardsData;

  return (
    <BannerContainer testID="loyalty-tiers-widget">
      <RewardContainer>
        <PointsContainer>
          <UserRewardPointsContainer>
            <StyledLoyaltyPointsIcon />
            <UserRewardPoints testID="user-points">{userPoints}</UserRewardPoints>
          </UserRewardPointsContainer>
          <AvailablePointsCaption>
            <AvailablePoints>{formatMessage({ id: 'availablePoints' })}</AvailablePoints>
          </AvailablePointsCaption>
        </PointsContainer>
        <DashboardButton onPress={handleNavigation}>
          <DashboardButtonText>{formatMessage({ id: 'rewardsHistory' })}</DashboardButtonText>
        </DashboardButton>
      </RewardContainer>
      {displayedSplittedRewardsData
        ? renderTiersDot(
            '' + displayedRewardsData[MAX_TIERS_POSITION - 1]?.pointsLabel,
            displayedSplittedRewardsData
          )
        : renderTiersDot('0', displayedOriginalSizeTiers)}
    </BannerContainer>
  );
};

export default LoyaltyTiersWidget;
