import { useCallback, useEffect, useState } from 'react';

import {
  LoyaltyOstType,
  OrderSelectionStatus,
  useOrderSelectionByTypeQuery,
} from 'generated/graphql-gateway';

import useStaticIdentifier from '../use-static-identifier';

import {
  IUseShortCodePollStatus,
  IUseShortCodePollStatusParams,
  ShortCodePollingStatus,
} from './types';

export const SHORT_CODE_NOT_FOUND_ERROR_CODE = 'HashSetNotFoundError';

export const useShortCodePollStatus = ({
  loyaltyId,
  skipPolling,
  shortCode,
  pollInterval,
}: IUseShortCodePollStatusParams): IUseShortCodePollStatus => {
  const [pollingStatus, setPollingStatus] = useState<ShortCodePollingStatus>(
    ShortCodePollingStatus.None
  );
  const { isStaticIdentifierEnabled } = useStaticIdentifier();

  const resetPollingStatus = useCallback(() => {
    setPollingStatus(ShortCodePollingStatus.None);
  }, []);

  // setting pending code state
  useEffect(() => {
    if (!skipPolling) {
      setPollingStatus(ShortCodePollingStatus.Pending);
    }
  }, [skipPolling]);

  const skip =
    skipPolling || !shortCode || !loyaltyId || pollingStatus === ShortCodePollingStatus.None;
  // short code polling
  const { error, startPolling, stopPolling } = useOrderSelectionByTypeQuery({
    variables: {
      key: shortCode!,
      ostType: isStaticIdentifierEnabled ? LoyaltyOstType.LOYALTY_ID : LoyaltyOstType.OTP,
    },
    fetchPolicy: 'no-cache',
    skip,
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const pollShortCodeStatus = data?.loyaltyOrderSelectionByType?.status;

      if (pollShortCodeStatus === OrderSelectionStatus.CLAIMED) {
        setPollingStatus(ShortCodePollingStatus.Confirmed);
      }
    },
  });

  useEffect(() => {
    if (skip || pollingStatus === ShortCodePollingStatus.Confirmed) {
      stopPolling();
    } else {
      startPolling(pollInterval);
    }
  }, [pollInterval, skip, pollingStatus, startPolling, stopPolling]);

  useEffect(() => {
    const isNotFoundError = error?.graphQLErrors?.some(
      err => err?.extensions?.code === SHORT_CODE_NOT_FOUND_ERROR_CODE
    );
    if (isNotFoundError) {
      setPollingStatus(ShortCodePollingStatus.NotFound);
    }
  }, [error]);

  return {
    pollingShortCodeStatus: pollingStatus,
    resetPollingStatus,
  };
};
