import React from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';

import { Text } from '@fhs/ui/src/components/text/text';
import { Box } from '@fhs-legacy/universal-components';
import useEffectOnce from 'hooks/use-effect-once';
import useAuthFlow from 'state/auth/hooks/use-auth-flow';
import { EventName, emitEvent } from 'utils/event-hub';

import { CloseButton } from '../close-button';
import SignUpHeadline from '../sign-up-headline/sign-up-headline';

import SignInTab from './sign-in-tab';
import { SignUpHomeContainer } from './sign-up-home.styled';
import SignUpTab from './sign-up-tab';
import { AuthHomeProps, AuthMethod } from './types';

const AuthHome = (props: AuthHomeProps) => {
  const { method } = props;
  const { formatMessage } = useIntl();

  const { attemptedSignUpWithExistingEmail, email } = useAuthFlow();
  useEffectOnce(() => {
    emitEvent(EventName.SCREEN_RENDER, {
      screenId: 'signupHome',
    });
  });
  return (
    <SignUpHomeContainer testID="signup-home-container">
      <CloseButton style={styles.closeButton} />
      <Box alignItems="center" paddingBottom="$6">
        <SignUpHeadline />
        <Text style={styles.header}>{formatMessage({ id: 'enterSignInEmail' })}</Text>
        <Text style={styles.header}>{formatMessage({ id: 'proceedToLogin' })}</Text>
      </Box>
      {method === AuthMethod.SIGN_UP ? (
        <SignUpTab
          attemptedSignUpWithExistingEmail={attemptedSignUpWithExistingEmail}
          initialEmail={email}
        />
      ) : (
        <SignInTab
          attemptedSignUpWithExistingEmail={attemptedSignUpWithExistingEmail}
          initialEmail={email}
        />
      )}
    </SignUpHomeContainer>
  );
};

const styles = StyleSheet.create({
  closeButton: {
    marginRight: 'auto',
    marginTop: 8,
    marginLeft: 8,
  },
  header: {
    fontSize: 16,
  },
  container: {
    borderTopStartRadius: 30,
    borderTopEndRadius: 30,
    borderRadius: 30,
  },
});

export default AuthHome;
