import React from 'react';

import MenuTileGrid from 'components/menu-tile-grid';
import { MainMenuObject } from 'components/menu-tile-grid/menu-tile/types';

interface IMenuSectionContentTilesProps {
  sections: MainMenuObject[];
}

export const RenderInlineTileGrid = React.memo<IMenuSectionContentTilesProps>(({ sections }) => {
  return (
    <>
      {sections.map(section => (
        <MenuTileGrid
          key={section._id}
          // TODO: There is a type mismatch here, this need to be fixed in a future refactor
          // @ts-ignore
          data={section.options || []}
          gridTitle={section.name?.locale}
          objectFitContain={true}
        />
      ))}
    </>
  );
});

export const RenderStandard = React.memo<IMenuSectionContentTilesProps>(({ sections }) =>
  sections.length > 0 ? <MenuTileGrid data={sections} objectFitContain /> : null
);
