import React from 'react';
import { useIntl } from 'react-intl';

import { TextProps, makeUclComponent } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import SanityBlockRenderer from 'components/sanity-block-renderer';

import { StyledLink } from './loyalty-claim-points.styled';
import { useLoyaltyClaimPoints } from './use-loyalty-claim-points';

interface ILoyaltyClaimPointsLinkProps {
  className?: string;
  renderAsButton?: boolean;
  _textProps?: TextProps;
}

const pointsLinkTextStyles: TextProps = {
  margin: 0,
  textDecorationLine: 'underline',
};

const LoyaltyClaimPointsLink: React.FC<React.PropsWithChildren<ILoyaltyClaimPointsLinkProps>> = ({
  renderAsButton,
  _textProps,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const { data } = useLoyaltyClaimPoints();

  const content = data?.claimOnHistory;
  if (!content?.linkText) {
    return null;
  }

  const sharedProps = {
    ...props,
    to: formatMessage({ id: 'routes.claimPointsBase' }),
    children: (
      <SanityBlockRenderer
        content={content.linkText.locale}
        textProps={{ ...pointsLinkTextStyles, ..._textProps }}
      />
    ),
  };

  if (renderAsButton) {
    return <ActionButton {...sharedProps} variant={ActionButtonVariants.OUTLINE} />;
  }

  return <StyledLink {...sharedProps} />;
};

export default makeUclComponent(LoyaltyClaimPointsLink);
