import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { CreateSupportCaseDocument } from 'generated/rbi-graphql';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { getErrorDialogForGraphQLCode, parseGraphQLErrorCodes } from 'utils/errors';
import logger from 'utils/logger';

import { CreateCaseState, ICreateSupportCaseInput, IUseKnowledgeForceCaseCreation } from '../types';

export const useKnowledgeForceCaseCreation = (): IUseKnowledgeForceCaseCreation => {
  const [caseReferenceNumber, setCaseReferenceNumber] = useState<string | null>(null);
  const [createCaseState, setCreateCaseState] = useState<CreateCaseState>(CreateCaseState.DEFAULT);
  const [createCaseError, setCreateCaseError] = useState('');
  const [executeCreateKnowledgeForceSupportCase] = useMutation(CreateSupportCaseDocument);
  const { formatMessage } = useIntl();
  const { logRBIEvent } = useCRMEventsContext();

  const createSupportCase = useCallback(
    async (caseInfo: ICreateSupportCaseInput) => {
      setCreateCaseState(CreateCaseState.LOADING);
      try {
        const { data } = await executeCreateKnowledgeForceSupportCase({
          variables: {
            caseInfo,
          },
        });
        const { caseId } = data.createSupportCase;

        setCaseReferenceNumber(caseId);
        setCreateCaseState(CreateCaseState.SUCCESS);

        logRBIEvent({
          name: CustomEventNames.SUPPORT_FORM_SUBMITTED,
          type: EventTypes.UserContent,
          attributes: {
            caseId,
            userEmail: caseInfo.email,
            orderDateTime: caseInfo?.orderDateTime,
            userRegion: caseInfo.userRegion,
            userAccountRegion: caseInfo.userAccountRegion ?? undefined,
          },
        });

        return caseId;
      } catch (error) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        const [parsedApolloError] = parseGraphQLErrorCodes(error);

        if (parsedApolloError) {
          const mappedErrorMessage = getErrorDialogForGraphQLCode(parsedApolloError, formatMessage);
          if (mappedErrorMessage) {
            setCreateCaseError(mappedErrorMessage.message);
          }
        }

        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        logger.error(`Failed to create knowledge force support case: ${error.message}`);
        setCreateCaseState(CreateCaseState.ERROR);
        return null;
      }
    },
    [executeCreateKnowledgeForceSupportCase, formatMessage]
  );

  return {
    caseReferenceNumber,
    createCaseState,
    createSupportCase,
    createCaseError,
  };
};
