import { StyleSheet } from 'react-native';

import { Flex, ScrollView } from '@fhs/ui';

import { ScreenContentProps } from './types';

export function ScreenContentDesktop({ children, before, after, ...props }: ScreenContentProps) {
  return (
    <ScrollView {...props}>
      {before ?? null}
      <Flex style={styles.contentContainer}>{children}</Flex>
      {after ?? null}
    </ScrollView>
  );
}

const WEB_APP_DESKTOP_LG_MAX_WIDTH = 962;

const styles = StyleSheet.create({
  contentContainer: {
    flexShrink: 0,
    width: '100%',
    maxWidth: WEB_APP_DESKTOP_LG_MAX_WIDTH,
    marginHorizontal: 'auto',
  },
});
