import React from 'react';
import { useIntl } from 'react-intl';

import { LoggedOutEmptyStateImage } from './images';
import { EmptyStateWrapper, HeaderText, ImageWrapper, SubText } from './styled';

const NoFavoritesEmptyState = () => {
  const { formatMessage } = useIntl();

  return (
    <EmptyStateWrapper>
      <ImageWrapper>
        <LoggedOutEmptyStateImage />
      </ImageWrapper>
      <HeaderText>{formatMessage({ id: 'noFavoritesHeader' })}</HeaderText>
      <SubText>{formatMessage({ id: 'noFavoritesSubheader' })}</SubText>
    </EmptyStateWrapper>
  );
};

export default NoFavoritesEmptyState;
