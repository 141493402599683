import { ApolloError } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useCallback } from 'react';

import {
  IPrepaidsMergeInput,
  PrepaidsMergeDocument,
  UserAccountsDocument as UserAccountsQuery,
  usePrepaidsMergeMutation,
} from 'generated/rbi-graphql';
import { useLocale } from 'state/intl';
import logger from 'utils/logger';

type Variables = IPrepaidsMergeInput;

const MutationName = getOperationName(PrepaidsMergeDocument)!;

export const usePrepaidsMerge = ({
  destinationFdAccountId,
}: Pick<Variables, 'destinationFdAccountId'>) => {
  const { feCountryCode } = useLocale();

  const onError = useCallback((error: ApolloError) => {
    const { graphQLErrors: errors } = error;
    if (errors.length) {
      logger.error({
        errors,
        message: 'Error merging prepaid balances.',
        queryName: MutationName,
      });
    }
  }, []);

  const [fireMutation, mutationResult] = usePrepaidsMergeMutation({
    onError,
    refetchQueries: [{ query: UserAccountsQuery, variables: { feCountryCode } }],
  });

  const mergePrepaids = useCallback(
    ({ sourceCardNumber, sourcePin }: Omit<Variables, 'destinationFdAccountId'>) => {
      return fireMutation({
        variables: {
          input: {
            destinationFdAccountId,
            sourceCardNumber: sourceCardNumber.replace(/\s/g, ''),
            sourcePin,
          },
        },
      });
    },
    [destinationFdAccountId, fireMutation]
  );

  const { data } = mutationResult;
  const destinationAccountBalance =
    data && data.prepaidsMerge ? data.prepaidsMerge.currentBalance : null;

  return { ...mutationResult, destinationAccountBalance, mergePrepaids };
};
