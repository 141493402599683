import delv from 'dlv';

import {
  ICombo,
  IComboSlot,
  IComboSlotOption,
  IItem,
  IItemOption,
  IPicker,
  IPickerOption,
  ISanityCombo,
  ISanityComboSlot,
  ISanityComboSlotOption,
  ISanityItem,
  ISanityItemOption,
  ISanityPickerOption,
  IWithPricingProps,
  MenuObject,
  SanityMenuObject,
} from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import { IDayPartBoundary, IValidDayPart } from 'state/day-part/hooks/use-active-day-parts';
import {
  comboIsAvailable,
  comboSlotIsAvailable,
  comboSlotOptionIsAvailable,
  isAvailableForActiveDayParts,
  itemIsAvailable,
  itemOptionIsAvailable,
  itemOptionModifierIsAvailable,
} from 'utils/availability';
import { ComboSlotUiPattern } from 'utils/cart';
import { modifiersForItem } from 'utils/menu/modifiers';

interface IUseFilterForAvailabilityProps extends IWithPricingProps {
  activeDayParts?: IDayPartBoundary[];
  dayParts?: ReadonlyArray<IValidDayPart>;
  enableCompositeComboSlotPlus?: boolean;
}

export const applyFiltersForAvailability = ({
  activeDayParts = [],
  dayParts = [],
  prices,
  vendor,
  enableCompositeComboSlotPlus = false,
}: IUseFilterForAvailabilityProps) => {
  const filterForAvailability = (
    menuData: SanityMenuObject[] | SanityMenuObject
  ): MenuObject | MenuObject[] | null => {
    if (!menuData) {
      return null;
    }
    const reducePickerChildren = (children: ISanityPickerOption[]): IPickerOption[] => {
      return children.reduce<IPickerOption[]>((availableOpts, option: ISanityPickerOption) => {
        if (!option.option) {
          return availableOpts;
        }

        if (
          option.option._type === MenuObjectTypes.COMBO &&
          comboIsAvailable(option.option, vendor, prices)
        ) {
          const remappedOption = {
            ...option.option,
            options: reduceComboSlots(option.option),
          } as ICombo;

          if (delv(option, 'option.mainItem.options')) {
            const remappedItem = modifiersForItem(option.option.mainItem!);
            remappedOption.mainItem = {
              ...remappedItem,
              options: reduceItemOptions(remappedItem),
            };
          }

          return availableOpts.concat({
            ...option,
            option: remappedOption,
          });
        }

        if (
          option.option._type === MenuObjectTypes.ITEM &&
          itemIsAvailable(option.option, vendor, prices)
        ) {
          const itemOptions: ISanityItemOption[] = delv(option, 'option.options');

          const remappedItem: IItem = {
            ...option.option,
            options: itemOptions ? reduceItemOptions(modifiersForItem(option.option)) : [],
          };

          return availableOpts.concat({
            ...option,
            option: remappedItem,
          });
        }

        return availableOpts;
      }, []);
    };

    const reduceComboSlots = (combo: ISanityCombo): IComboSlot[] => {
      const comboSlots = combo?.options || [];
      return comboSlots.reduce<IComboSlot[]>((availableOpts, comboSlot: ISanityComboSlot) => {
        if (
          comboSlotIsAvailable(
            comboSlot,
            vendor,
            prices,
            enableCompositeComboSlotPlus ? combo : undefined
          )
        ) {
          return availableOpts.concat({
            ...comboSlot,
            options: reduceItems(comboSlot, enableCompositeComboSlotPlus ? combo : undefined),
          });
        }
        return availableOpts;
      }, []);
    };

    const reduceItemOptions = (item: IItem) => {
      return item.options.reduce<IItemOption[]>((availableOpts, option) => {
        if (itemOptionIsAvailable({ item, itemOption: option, vendor, prices })) {
          return availableOpts.concat({
            ...option,
            options: option.options.filter(itemOptionModifier =>
              itemOptionModifierIsAvailable({
                item,
                itemOption: option,
                itemOptionModifier,
                vendor,
                prices,
              })
            ),
          });
        }
        return availableOpts;
      }, []);
    };

    const reduceItems = (comboSlot: ISanityComboSlot, combo?: ISanityCombo): IComboSlotOption[] => {
      // If UI is collapsed we want to return all options so that proper nutrition can be displayed.
      if (comboSlot.uiPattern === ComboSlotUiPattern.COLLAPSED) {
        return comboSlot.options as IComboSlotOption[];
      }

      return comboSlot.options.reduce<IComboSlotOption[]>(
        (availableOpts, option: ISanityComboSlotOption) => {
          if (
            comboSlotOptionIsAvailable(
              option.option as ISanityItem,
              vendor,
              prices,
              option.isPremium ? combo : undefined
            )
          ) {
            const { option: comboSlotOption } = option;

            // If combo was passed in, for premium items we don't want to filter the item itself
            if (option.isPremium && combo) {
              const itemWithOptionsRemapped = modifiersForItem(comboSlotOption as ISanityItem);

              const filteredItem = {
                ...itemWithOptionsRemapped,
                options: reduceItemOptions(itemWithOptionsRemapped),
              };

              return availableOpts.concat({
                ...option,
                option: filteredItem as IItem | IPicker,
              });
            }

            // If combo wasn't passed in maintain original filtering logic
            // filterForAvailability will filter out the item itself if its unavailable
            // for premium comboSlots we don't want to look at the item by itself for availability
            const filteredOption = filterForAvailability(comboSlotOption);

            return filteredOption
              ? availableOpts.concat({
                  ...option,
                  option: filteredOption as IItem | IPicker,
                })
              : availableOpts;
          }

          return availableOpts;
        },
        []
      );
    };

    // if an array filter each item in the array for availability
    if (Array.isArray(menuData)) {
      return menuData.map(filterForAvailability) as MenuObject[];
    }

    if (
      dayParts.length &&
      activeDayParts.length &&
      !isAvailableForActiveDayParts({ activeDayParts, menuData })
    ) {
      return null;
    }

    switch (menuData._type) {
      case MenuObjectTypes.SECTION: {
        const filteredChildren = (filterForAvailability(menuData.options!) as MenuObject[]).filter(
          option => {
            if (!option) {
              return false;
            }
            return option._type === MenuObjectTypes.PICKER ? option?.options?.length > 0 : true;
          }
        );

        return { ...menuData, options: filteredChildren };
      }
      case MenuObjectTypes.PICKER: {
        if (menuData.pickerAspects) {
          const options = reducePickerChildren(menuData.options);

          return {
            ...menuData,
            options,
          };
        }
        return null;
      }
      case MenuObjectTypes.COMBO: {
        if (comboIsAvailable(menuData, vendor, prices)) {
          const mainItem = menuData.mainItem
            ? (filterForAvailability(menuData.mainItem) as IItem | null)
            : null;
          return {
            ...menuData,
            mainItem,
            options: reduceComboSlots(menuData),
          };
        }
        return null;
      }
      case MenuObjectTypes.ITEM:
        if (itemIsAvailable(menuData, vendor, prices)) {
          const itemWithOptionsRemapped = modifiersForItem(menuData);

          return {
            ...itemWithOptionsRemapped,
            options: reduceItemOptions(itemWithOptionsRemapped),
          };
        }
        return null;

      default:
        return null;
    }
  };

  return filterForAvailability;
};
