import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { GestureResponderEvent, ScrollView, View } from 'react-native';

import { Divider } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import LoadingAnimation from 'components/loading-animation';
import PaymentMethodOption from 'components/payment-method-option';
import UserVerificationModal from 'components/user-verification-modal';
import useUserHasToVerify from 'hooks/use-user-has-to-verify';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { usePaymentContext } from 'state/payment';
import { PAYPAL_PAYMENT_METHOD_PLACEHOLDER } from 'state/payment/constants';
import { IPaymentMethod } from 'state/payment/types';
import { isApplePay, isGooglePay } from 'utils/payment/native-payment';
import { routes } from 'utils/routing';

import AddGiftCardLink from './add-gift-card-link';
import { ADD_PAYMENT_METHOD_OR_RELOAD } from './constants';
import { filterPaymentMethods } from './filter-payment-methods';
import { getPaymentMethodOptionProps } from './get-payment-method-options-props';
import { ActionButtonsContainer, DefaultText, DefaultTextWrapper, Form, Message } from './styled';

function filterOutUnsupportedPaymentMethods(paymentMethods: IPaymentMethod[]) {
  // Exclude PayPal if it is not actually stored in the user account
  return paymentMethods.filter(
    n => n.accountIdentifier !== PAYPAL_PAYMENT_METHOD_PLACEHOLDER.accountIdentifier
  );
}

const PaymentMethodFlatListWithButton: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    deletePaymentMethod,
    loading,
    paymentMethods,
    setDefaultReloadPaymentMethodId,
    prepaidReloadPaymentMethodId,
    setDefaultPaymentMethodId,
    defaultPaymentMethodId,
  } = usePaymentContext();
  const { formatMessage } = useIntl();
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const enableDirectPay = useFlag(LaunchDarklyFlag.ENABLE_TH_DIRECT_PAY);
  const enableGiftCard = useFlag(LaunchDarklyFlag.ENABLE_GIFT_CARD);
  const enableThDirectPayApplePay = useFlag(LaunchDarklyFlag.ENABLE_TH_DIRECT_PAY_APPLE_PAY);
  const enableThDirectPayGooglePay = useFlag(LaunchDarklyFlag.ENABLE_TH_DIRECT_PAY_GOOGLE_PAY);
  const enableDoubleVaultingFromPaymentMethods = useFlag(
    LaunchDarklyFlag.ENABLE_DOUBLE_VAULTING_FROM_PAYMENT_METHODS
  );
  const enableUnverifiedSavedPayments = useFlag(LaunchDarklyFlag.ENABLE_UNVERIFIED_SAVED_PAYMENTS);
  const userHasToVerify = useUserHasToVerify(enableUnverifiedSavedPayments);

  // Filter the payment methods per brands
  const filteredPaymentMethods = filterPaymentMethods({
    paymentMethods: filterOutUnsupportedPaymentMethods(paymentMethods),
    enableDirectPay,
    enableThDirectPayApplePay,
    enableThDirectPayGooglePay,
  });

  const hasPaymentMethods = filteredPaymentMethods.length > 0;

  const mappedPaymentMethods = useMemo(() => {
    return filteredPaymentMethods.map(method => {
      const accountIdentifier = method.accountIdentifier ?? method.fdAccountId ?? '';
      const isNativePayment = isApplePay(accountIdentifier) || isGooglePay(accountIdentifier);

      const isCashPayment = !!method.cash;

      const paymentMethodOptionProps = getPaymentMethodOptionProps({
        isNativePayment,
        enableDirectPay,
        prepaidReloadPaymentMethodId,
        setDefaultReloadPaymentMethodId,
        defaultPaymentMethodId,
        setDefaultPaymentMethodId,
        accountIdentifier,
        enableDoubleVaultingFromPaymentMethods,
        method,
      });

      return (
        <>
          <PaymentMethodOption
            key={accountIdentifier}
            method={method}
            showDefault
            removable={!method.prepaid && !isNativePayment && !isCashPayment}
            readonly={isNativePayment}
            deletePaymentMethod={deletePaymentMethod}
            isDefaultPaymentMethod={accountIdentifier === defaultPaymentMethodId}
            fullWidthDescription={false}
            {...paymentMethodOptionProps}
          />
          <Divider />
        </>
      );
    });
  }, [
    defaultPaymentMethodId,
    deletePaymentMethod,
    enableDirectPay,
    enableDoubleVaultingFromPaymentMethods,
    filteredPaymentMethods,
    prepaidReloadPaymentMethodId,
    setDefaultPaymentMethodId,
    setDefaultReloadPaymentMethodId,
  ]);

  const handleAddCard = (event: GestureResponderEvent) => {
    if (userHasToVerify) {
      event?.stopPropagation();
      setShowVerificationModal(true);
    }
  };

  return (
    <View
      testID="account-payment-methods-page"
      style={{ padding: 16, flex: 1, justifyContent: 'space-between' }}
    >
      {loading ? (
        <LoadingAnimation />
      ) : (
        <>
          <ScrollView style={{ flex: 1 }}>
            {!hasPaymentMethods ? (
              <Message>{formatMessage({ id: 'noPaymentMethodOnFile' })}</Message>
            ) : (
              <>
                <DefaultTextWrapper>
                  <DefaultText>{formatMessage({ id: 'accountDefault' })}</DefaultText>
                </DefaultTextWrapper>
                <Form testID="account-saved-payments">{mappedPaymentMethods}</Form>
              </>
            )}
          </ScrollView>

          <View>
            <ActionButtonsContainer>
              <ActionButton
                fullWidth
                variant={ActionButtonVariants.OUTLINE}
                to={!userHasToVerify ? routes.addCard : undefined}
                onPress={handleAddCard}
                testID="add-new-payment-method"
              >
                {formatMessage({
                  id: enableDirectPay ? 'addNewPaymentMethod' : ADD_PAYMENT_METHOD_OR_RELOAD,
                })}
              </ActionButton>
              {enableGiftCard && <AddGiftCardLink />}
            </ActionButtonsContainer>
            {showVerificationModal && (
              <UserVerificationModal showDialog onDismiss={() => setShowVerificationModal(false)} />
            )}
          </View>
        </>
      )}
    </View>
  );
};

export default PaymentMethodFlatListWithButton;
