import { FieldProps } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';

import { OptionalProps } from '@rbi-ctg/frontend';
import { DateInput, IDateInputProps } from 'components/ucl';

export const FormikDateInput = ({
  field,
  form,
  label,
  autoComplete,
  onChange,
  ...props
}: FieldProps &
  OptionalProps<
    IDateInputProps,
    | 'label'
    | 'labelYear'
    | 'labelYearFormat'
    | 'labelMonth'
    | 'labelMonthFormat'
    | 'labelDay'
    | 'labelDayFormat'
  >) => {
  const { formatMessage } = useIntl();

  const errors = form.touched[field.name] ? (form.errors?.[field.name] as string) : undefined;

  return (
    <DateInput
      autoComplete={autoComplete}
      testID={field.name}
      {...field}
      label={label ?? ''}
      labelYear={formatMessage({ id: 'year' })}
      labelYearFormat={formatMessage({ id: 'yearPlaceholder' })}
      labelMonth={formatMessage({ id: 'month' })}
      labelMonthFormat={formatMessage({ id: 'monthPlaceholder' })}
      labelDay={formatMessage({ id: 'day' })}
      labelDayFormat={formatMessage({ id: 'dayPlaceholder' })}
      {...props}
      errorMessage={errors}
      onBlur={form.handleBlur}
      onChange={onChange}
      month={form.values[field.name].month}
      day={form.values[field.name].day}
      year={form.values[field.name].year}
    />
  );
};
