import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconOffer = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="m21.41 11.68-9-9c-.36-.36-.86-.58-1.41-.58H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42ZM5.5 7.1C4.67 7.1 4 6.43 4 5.6s.67-1.5 1.5-1.5S7 4.77 7 5.6s-.67 1.5-1.5 1.5Z"
      />
    </Svg>
  );
};
