import * as React from 'react';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';

import { Text } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import Dialog from 'components/dialog';
import { TextInput } from 'components/ucl';

import { ISaveFavoriteOrderProps } from './types';

/**
 *
 * Save Favorite Order
 *
 *
 */

const SaveFavoriteOrder: FC<React.PropsWithChildren<ISaveFavoriteOrderProps>> = ({
  showDialog,
  onSave,
  onDismiss,
}) => {
  const { formatMessage } = useIntl();
  const [favoriteName, setFavoriteName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleSave = () => {
    if (!favoriteName) {
      return setErrorMessage(formatMessage({ id: 'saveFavoriteOrderNameError' }));
    }
    onSave(favoriteName);
  };

  return (
    <Dialog
      showDialog={showDialog}
      testID="save-favorite-order"
      showCloseButton
      onDismiss={onDismiss}
      modalAppearanceEventMessage="Save Favorite Order"
      heading={formatMessage({ id: 'nameYourFavorite' })}
      actions={
        <ActionButton
          fullWidth
          onPress={handleSave}
          testID="save-favorite-order-button"
          marginTop="$3"
        >
          {formatMessage({ id: 'saveFavoriteOrderButton' })}
        </ActionButton>
      }
    >
      <Text>{formatMessage({ id: 'saveFavoriteOrderText' })}</Text>
      <TextInput
        accessibilityLabel={errorMessage ? errorMessage : formatMessage({ id: 'orderName' })}
        autoFocus
        testID="save-favorite-order-name"
        errorMessage={errorMessage}
        label={formatMessage({ id: 'orderName' })}
        onFocus={() => setErrorMessage('')}
        onChangeText={value => setFavoriteName(value)}
        onBlur={() => {
          if (!favoriteName) {
            return setErrorMessage(formatMessage({ id: 'saveFavoriteOrderNameError' }));
          }
        }}
        required
        type="text"
        value={favoriteName}
      />
    </Dialog>
  );
};

export default SaveFavoriteOrder;
