import { useIntl } from 'react-intl';

import { useUIContext } from 'state/ui';

import { IPlaceOrderTextProps } from './types';
import { showPlaceOrderText } from './utils';

export const usePlaceOrderText = ({ confirmation, reloadAmount }: IPlaceOrderTextProps) => {
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale } = useUIContext();

  return {
    text: formatMessage(
      {
        id: showPlaceOrderText({
          reloadAndPlaceOrder: reloadAmount,
          confirmation,
          secureOrderText: true,
        }),
      },
      {
        amount: formatCurrencyForLocale(reloadAmount || 0),
        secureOrderIcon: '',
      }
    ),
    showIcon: !reloadAmount && confirmation,
  };
};
