import { useIsMobileBp } from 'hooks/breakpoints';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

export const useRenderTabbedMenuHeader = () => {
  const enableTabbedMenuBar = useFlag(LaunchDarklyFlag.ENABLE_TABBED_MENU_BAR);

  const isMobile = useIsMobileBp();

  return enableTabbedMenuBar && isMobile;
};
