import { FC, PropsWithChildren } from 'react';

import { Content, Wrapper } from './styled';
import { IDrawerProps } from './types';

export const Drawer: FC<PropsWithChildren<IDrawerProps>> = ({
  isOpen,
  onClose,
  position = 'left',
  children,
  contentWidth = 360,
}) => {
  return (
    <Wrapper isOpen={isOpen} onClose={onClose} drawerPosition={position}>
      <Content contentWidth={contentWidth}>{children}</Content>
    </Wrapper>
  );
};
