import { Box } from '@fhs-legacy/universal-components';

export const Container = Box.withConfig({
  marginTop: '$4',
});

export const LoadingContainer = Box.withConfig({
  height: 'full',
});

export const Padding = Box.withConfig({
  minHeight: '16px',
  width: '1px',
});
