import * as React from 'react';
import { FC } from 'react';

import { ArrowButton } from 'components/arrow-button';
import { ContentWrap, ListStyled } from 'pages/support-v2/support-form-v2.styled';

import noop from '../../../utils/noop';

import { ISupportV2CategoryViewProps } from './types';

export const SupportV2CategoryView: FC<React.PropsWithChildren<ISupportV2CategoryViewProps>> = ({
  subcategories,
  onSelectSubcategory = noop,
}) => (
  <ContentWrap>
    <ListStyled>
      {subcategories.map(item => (
        <ArrowButton onPress={() => onSelectSubcategory(item)} key={item._id}>
          {item.name}
        </ArrowButton>
      ))}
    </ListStyled>
  </ContentWrap>
);
