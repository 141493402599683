import React, { useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import { Text, XStack, YStack } from '@fhs/ui';
import { Icon } from '@fhs-legacy/universal-components';
import Currency from 'components/currency';
import { OfferDiscountTypes } from 'enums/menu';
import { geographicalFeeLabelIdMap } from 'pages/account/account-orders/constants';
import { CurrencyLineSkeleton } from 'pages/cart/currency-line-skeleton';
import { useCRMEventsContext } from 'state/crm-events';
import { selectors, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import { IconContainer } from '../styled';

import { totalSectionStyles } from './styled-base';
import TaxesRow from './taxes-row';

interface TaxesAndFeesProps {
  deliveryFee: number | null;
  deliveryFeeDiscount: number | null;
  donationCents: number;
  dropoffState?: string | null;
  serviceFeeCents: number;
  smallCartFeeCents: number;
  geographicalFeeCents: number;
  baseDeliveryFeeCents: number;
  deliverySurchargeFeeCents: number;
  taxCents: number;
  tipCents: number;
  serviceMode: ServiceMode | null;
  subTotalCents: number;
  isLoading?: boolean;
  isCartPage?: boolean;
}

const BreakdownFee = ({
  isDiscounted,
  originalAmount,
  fee,
  label,
  testId,
}: {
  isDiscounted?: boolean;
  originalAmount?: number;
  fee: number;
  label: string;
  testId: string;
}) => {
  return (
    <XStack style={totalSectionStyles.feeBreakdownRow}>
      <Text style={totalSectionStyles.feeBreakdownLabel}>{label}</Text>
      <XStack testID={testId}>
        <Currency
          isDiscounted={isDiscounted}
          originalAmount={originalAmount}
          amount={fee}
          isFree={fee === 0}
          textProps={totalSectionStyles.feeBreakdownLabel}
        />
      </XStack>
    </XStack>
  );
};

const TaxesAndFees: React.FC<React.PropsWithChildren<TaxesAndFeesProps>> = ({
  isLoading,
  deliveryFee,
  donationCents,
  dropoffState,
  serviceFeeCents,
  smallCartFeeCents,
  geographicalFeeCents,
  baseDeliveryFeeCents,
  deliverySurchargeFeeCents,
  taxCents,
  tipCents,
  isCartPage,
}) => {
  const hasFiredAmplitudeEventRef = useRef(false);
  const enableHideTaxLine = useFlag(LaunchDarklyFlag.ENABLE_HIDE_TAX_LINE);
  const enableDeliveryFeesInfoButton = useFlag(LaunchDarklyFlag.SHOW_DELIVERY_FEES_INFO);
  const shouldShowTaxLine = !enableHideTaxLine && !isCartPage;
  const { formatMessage } = useIntl();
  const { setShowFeeWarning } = useServiceModeContext();
  const { logDeliveryFeesSectionDisplayed } = useCRMEventsContext();
  const selectedOffer = useAppSelector(selectors.loyalty.selectSelectedOffer);

  const showDeliveryFeesBreakdown =
    serviceFeeCents || smallCartFeeCents || geographicalFeeCents || baseDeliveryFeeCents;

  // Use a custom label for geographical delivery fees as required
  const geographicalFeeLabelId =
    dropoffState && geographicalFeeLabelIdMap.hasOwnProperty(dropoffState)
      ? geographicalFeeLabelIdMap[dropoffState]
      : 'geographicalFee';

  const onPressDeliveryFees = () => {
    setShowFeeWarning(true);
  };

  const isOfferFeesDiscount =
    selectedOffer?.incentives?.[0] &&
    selectedOffer?.incentives?.find(
      incentive =>
        incentive?._type === 'feesV1Discount' ||
        // TODO: This is a hack for casting type to check offer v2 new type. Cart service will remove this
        (incentive as any)?.incentiveType === 'FEES_DISCOUNT'
    );
  const savingsFees = useMemo(() => {
    const fees = {
      baseDeliveryFeeCents: 0,
      deliverySurchargeFeeCents: 0,
      geographicalFeeCents: 0,
    };
    if (isOfferFeesDiscount) {
      const { discountValue, discountType, discountFees } = selectedOffer.incentives[0] as any;
      const calculateSavingAmount = (amount: number): number => {
        if (discountType === OfferDiscountTypes.AMOUNT) {
          return Math.min(amount, discountValue);
        }
        if (discountType === OfferDiscountTypes.PERCENTAGE) {
          return Math.floor((amount * discountValue) / 100);
        }
        return 0;
      };
      discountFees?.forEach(fee => {
        switch (fee) {
          case 'baseDeliveryFeeCents':
            fees.baseDeliveryFeeCents = calculateSavingAmount(baseDeliveryFeeCents || 0);
            break;
          case 'deliverySurchargeFeeCents':
            fees.deliverySurchargeFeeCents = calculateSavingAmount(deliverySurchargeFeeCents || 0);
            break;
          case 'geographicalFeeCents':
            fees.geographicalFeeCents = calculateSavingAmount(geographicalFeeCents || 0);
            break;
        }
      });
    }
    return fees;
  }, [
    baseDeliveryFeeCents,
    deliverySurchargeFeeCents,
    geographicalFeeCents,
    isOfferFeesDiscount,
    selectedOffer,
  ]);

  const isFreeBaseDeliveryFee =
    baseDeliveryFeeCents - savingsFees.baseDeliveryFeeCents === 0 && !isLoading;
  const renderFeesBreakdown = () => {
    if (!hasFiredAmplitudeEventRef.current) {
      hasFiredAmplitudeEventRef.current = true;
      logDeliveryFeesSectionDisplayed({
        breakdownFeesEnabled: Boolean(showDeliveryFeesBreakdown),
        donationCents,
        fees: {
          deliveryTotalFee: deliveryFee ?? 0,
        },
        taxCents: taxCents,
        tipCents: tipCents,
      });
    }

    if (!showDeliveryFeesBreakdown) {
      return;
    }

    if (isLoading) {
      return <CurrencyLineSkeleton />;
    }

    return (
      <YStack style={totalSectionStyles.feeBreakdown}>
        <XStack style={totalSectionStyles.feeBreakdownRow}>
          <Text style={totalSectionStyles.feeBreakdownLabel}>
            {formatMessage({
              id: 'deliveryFeeBase',
            })}
          </Text>
          <XStack style={totalSectionStyles.baseFeeRow} testID="base-fee-amount">
            <Currency
              amount={baseDeliveryFeeCents - savingsFees.baseDeliveryFeeCents}
              textProps={totalSectionStyles.feeBreakdownLabel}
              originalAmount={baseDeliveryFeeCents}
              isDiscounted={savingsFees.baseDeliveryFeeCents > 0}
              isFree={!!isFreeBaseDeliveryFee}
            />
          </XStack>
        </XStack>

        {deliverySurchargeFeeCents > 0 && (
          <BreakdownFee
            isDiscounted={savingsFees.deliverySurchargeFeeCents > 0}
            originalAmount={deliverySurchargeFeeCents}
            fee={deliverySurchargeFeeCents - savingsFees.deliverySurchargeFeeCents}
            label={formatMessage({
              id: 'deliveryFeeLongDistance',
            })}
            testId='long-distance-fee-amount"'
          />
        )}
        {geographicalFeeCents > 0 && (
          <BreakdownFee
            isDiscounted={savingsFees.geographicalFeeCents > 0}
            originalAmount={geographicalFeeCents}
            fee={geographicalFeeCents - savingsFees.geographicalFeeCents}
            label={formatMessage({
              id: geographicalFeeLabelId,
            })}
            testId="geographical-fee-amount"
          />
        )}
        {serviceFeeCents > 0 && (
          <BreakdownFee
            fee={serviceFeeCents}
            label={formatMessage({
              id: 'serviceFee',
            })}
            testId="service-fee-amount"
          />
        )}

        {smallCartFeeCents > 0 && (
          <BreakdownFee
            fee={smallCartFeeCents}
            label={formatMessage({
              id: 'smallCartFee',
            })}
            testId="small-cart-fee-amount"
          />
        )}
      </YStack>
    );
  };

  const deliveryFeesLabelId = showDeliveryFeesBreakdown ? 'deliveryFees' : 'deliveryFee';

  return (
    <>
      <XStack style={totalSectionStyles.container}>
        {isLoading ? (
          <CurrencyLineSkeleton />
        ) : (
          <>
            <XStack style={totalSectionStyles.feeRow}>
              <XStack style={{ alignItems: 'center' }}>
                <Text style={totalSectionStyles.feeBreakdownLabel}>
                  {formatMessage({
                    id: deliveryFeesLabelId,
                  })}
                </Text>
                {enableDeliveryFeesInfoButton && (
                  <IconContainer
                    accessibilityLabel={formatMessage({
                      id: 'taxesAndFeesButtonAriaLabel',
                    })}
                    onPress={onPressDeliveryFees}
                  >
                    <Icon variant="info" size="$5" {...hiddenAccessibilityPlatformProps} />
                  </IconContainer>
                )}
              </XStack>
              {/* We just show the total delivery fee if the breakdown fees are not displayed */}
              {!showDeliveryFeesBreakdown && (
                <XStack aria-labelledby="delivery-fee-amount-label">
                  <Currency
                    amount={deliveryFee || 0}
                    textProps={totalSectionStyles.feeBreakdownLabel}
                  />
                </XStack>
              )}
            </XStack>
          </>
        )}
      </XStack>
      {renderFeesBreakdown()}
      {isLoading ? (
        <CurrencyLineSkeleton />
      ) : (
        shouldShowTaxLine && <TaxesRow taxCents={taxCents} isLoading={isLoading} />
      )}
    </>
  );
};

export default TaxesAndFees;
