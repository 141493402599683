import React, { VFC } from 'react';

import { selectors, useAppSelector } from 'state/global-state';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

import { LoyaltyRewardsBalanceView } from './loyalty-rewards-balance.view';

/**
 *
 * <LoyaltyRewardsBalance /> displays at the top of the checkout page
 * and reflects current rewards balance - applied balance in cart
 *
 */
const LoyaltyRewardsBalance: VFC = () => {
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser } = useLoyaltyUser();

  const hasContent = loyaltyEnabled && loyaltyUser;

  const availablePoints = useAppSelector(selectors.loyalty.selectStagedCartPoints);
  const appliedPoints = useAppSelector(selectors.loyalty.selectAppliedPoints);

  if (!hasContent) {
    return null;
  }

  return (
    <LoyaltyRewardsBalanceView availablePoints={availablePoints} appliedPoints={appliedPoints} />
  );
};

export default LoyaltyRewardsBalance;
