import {
  INavigationButtonFragment,
  INavigationLinksFragment,
  INavigationSectionFragment,
  INavigationTabsFragment,
  useFeatureNavigationQuery,
} from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

interface IUseFeatureNavigationValue {
  featureNavigationLoading: boolean;
  featureNavigationDesktop: NonNullable<INavigationLinksFragment['navigationLinks']>;
  featureNavigationMobile: NonNullable<INavigationTabsFragment['navigationTabs']>;
  featureAboutMenuEntriesDesktop: INavigationSectionFragment | null;
  featureAboutMenuEntriesMobile: INavigationSectionFragment | null;
  featureNavigationMobileHeaderButton: NonNullable<
    INavigationButtonFragment['navigationMobileHeaderButton']
  > | null;
}

export const useFeatureNavigation = (): IUseFeatureNavigationValue => {
  const { featureNavigationId = '', featureIdsLoading } = useFeaturesContext();

  // TODO: RN - If we need desktop isDesktop will need to come from somewhere
  const { data, loading } = useFeatureNavigationQuery({
    variables: { featureNavigationId, isDesktop: false },
    skip: !featureNavigationId,
  });

  const { navigationDesktop, navigationMobile, navigationMobileHeaderButton } =
    data?.FeatureNavigation ?? {};
  const featureNavigationLoading = featureIdsLoading || loading;

  return {
    featureNavigationDesktop: navigationDesktop?.navigationLinks ?? [],
    featureNavigationMobile: navigationMobile?.navigationTabs ?? [],
    featureAboutMenuEntriesDesktop: navigationDesktop?.aboutMenuEntries ?? null,
    featureAboutMenuEntriesMobile: navigationMobile?.aboutMenuEntries ?? null,
    featureNavigationMobileHeaderButton: navigationMobileHeaderButton ?? null,
    featureNavigationLoading,
  };
};
