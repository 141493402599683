import { IntlFormatters } from 'react-intl';

import { SupportedRegions } from '@rbi-ctg/frontend';
import { MyAccountFieldsVariations } from 'state/launchdarkly/variations';

import { IUserFormData } from './types';
import {
  validateDateOfBirth,
  validateEmail,
  validateName,
  validatePhoneNumber,
  validateZipCode,
} from './utils';

interface IGetFormErrorsParams {
  formatMessage: IntlFormatters['formatMessage'];
  region: SupportedRegions;
  myAccountFieldsVariations: MyAccountFieldsVariations;
  simplePhoneValidation?: boolean;
}
export const getFormErrors =
  ({
    formatMessage,
    region,
    myAccountFieldsVariations,
    simplePhoneValidation,
  }: IGetFormErrorsParams) =>
  async (formData: IUserFormData) => {
    const emailErrors = myAccountFieldsVariations.email
      ? validateEmail({ formData, formatMessage })
      : undefined;

    const nameErrors = myAccountFieldsVariations.name
      ? validateName({ formData, formatMessage })
      : undefined;

    const phoneNumberErrors = myAccountFieldsVariations.phone
      ? await validatePhoneNumber({
          formData,
          formatMessage,
          region,
          simpleValidation: simplePhoneValidation,
        })
      : undefined;

    const dateOfBirthErrors = myAccountFieldsVariations.dob
      ? validateDateOfBirth({ formData, formatMessage, region })
      : undefined;

    const zipCodeErrors = myAccountFieldsVariations.zip
      ? validateZipCode({ formData, formatMessage, region })
      : undefined;

    return {
      ...emailErrors,
      ...nameErrors,
      ...phoneNumberErrors,
      ...dateOfBirthErrors,
      ...zipCodeErrors,
    };
  };
