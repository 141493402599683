import { MqSwitch } from '@fhs/ui';

import { AppHeaderDesktop } from './app-header-desktop';
import { AppHeaderMobile } from './app-header-mobile';
import { AppHeaderProps } from './types';

export function AppHeader(props: AppHeaderProps) {
  return (
    <MqSwitch
      $ltDesktop={() => <AppHeaderMobile {...props} />}
      $gteDesktop={() => <AppHeaderDesktop {...props} />}
    />
  );
}
