import React, { FC } from 'react';

import { HStack, Header, Icon, Text } from '@fhs-legacy/universal-components';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import {
  HeaderLink,
  HeaderTextWrapper,
  IconWrapper,
  SectionContainer,
} from './responsive-carousel-wrapper.styled';
import { IResponsiveCarouselWrapperProps } from './types';

export const ResponsiveCarouselWrapper: FC<
  React.PropsWithChildren<IResponsiveCarouselWrapperProps>
> = ({ header, linkText, linkPath, linkAccessibilityLabel, children, ...rest }) => {
  return (
    <SectionContainer {...rest}>
      <HeaderTextWrapper>
        <Header accessible={true} variant="headerOne" accessibilityLabel={header}>
          {header}
        </Header>
        {linkPath && linkText && (
          <HeaderLink
            to={linkPath}
            accessibilityLabel={linkAccessibilityLabel}
            accessibilityRole="link"
          >
            <HStack alignItems="center">
              <Text>{linkText}</Text>
              <IconWrapper>
                <Icon variant="forward" size="$5" {...hiddenAccessibilityPlatformProps} />
              </IconWrapper>
            </HStack>
          </HeaderLink>
        )}
      </HeaderTextWrapper>
      {children}
    </SectionContainer>
  );
};
