import { Pressable, PressableProps } from '../pressable';

import { useCheckbox } from './context';

export type CheckboxPressableProps = Omit<
  PressableProps,
  'role' | 'aria-checked' | 'aria-label' | 'onPress' | 'disabled'
>;

export function CheckboxPressable(props: CheckboxPressableProps) {
  const checkbox = useCheckbox();

  return (
    <Pressable
      ref={checkbox.pressableRef}
      role="checkbox"
      aria-checked={checkbox.checked}
      disabled={checkbox.disabled}
      onPress={checkbox.handlePress}
      aria-label={checkbox.label}
      {...props}
    />
  );
}
