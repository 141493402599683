import { useFeatureLoyaltyUiNotEnrolledQuery } from 'generated/sanity-graphql';
import { useIsDesktopBp } from 'hooks/breakpoints';
import { useFeaturesContext } from 'state/features';

export const useFeaturesLoyaltyNotEnrolled = () => {
  const isDesktop = useIsDesktopBp();
  const { featureLoyaltyUIId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useFeatureLoyaltyUiNotEnrolledQuery({
    variables: { featureLoyaltyUIId },
    skip: !featureLoyaltyUIId,
  });

  const { LoyaltyUI } = data || {};

  const getNotEnrolledData = () => {
    const [desktopState, mobileState] = [
      LoyaltyUI?.notEnrolledState,
      LoyaltyUI?.notEnrolledStateMobile,
    ];

    if (isDesktop) {
      return desktopState || mobileState;
    }

    return mobileState || desktopState;
  };

  return {
    data: getNotEnrolledData(),
    loading: featureIdsLoading || loading,
  };
};
