import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconQrScan = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M9.5 6.6v3h-3v-3h3ZM11 5.1H5v6h6v-6Zm-1.5 9.5v3h-3v-3h3Zm1.5-1.5H5v6h6v-6Zm6.5-6.5v3h-3v-3h3ZM19 5.1h-6v6h6v-6Zm-6 8h1.5v1.5H13v-1.5Zm1.5 1.5H16v1.5h-1.5v-1.5Zm1.5-1.5h1.5v1.5H16v-1.5Zm-3 3h1.5v1.5H13v-1.5Zm1.5 1.5H16v1.5h-1.5v-1.5Zm1.5-1.5h1.5v1.5H16v-1.5Zm1.5-1.5H19v1.5h-1.5v-1.5Zm0 3H19v1.5h-1.5v-1.5ZM22 7.1h-2v-3h-3v-2h5v5Zm0 15v-5h-2v3h-3v2h5Zm-20 0h5v-2H4v-3H2v5Zm0-20v5h2v-3h3v-2H2Z"
      />
    </Svg>
  );
};
