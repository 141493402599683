import { Link } from 'expo-router';
import { StyleSheet } from 'react-native';

import { Button, Favicon, HeaderFrame, XStack } from '@fhs/ui';
import { useAuthContext } from '@fhs-legacy/frontend/src/state/auth';

import { HeaderLeftMobile } from './header-left-mobile';
import { ScanButton } from './scan-button';
import { SignUpButton } from './sign-up-button';
import { AppHeaderProps } from './types';

function AppHeaderMiddle() {
  return (
    <Link href="/" asChild>
      <Button type="ghost" size="xl" aria-label="Go home">
        <Favicon size={40} />
      </Button>
    </Link>
  );
}

function AppHeaderRight() {
  const { isAuthenticated } = useAuthContext();

  return (
    <XStack style={styles.gap24}>
      <XStack style={styles.gap8}>
        {isAuthenticated ? (
          <Link href="/redeem/" asChild>
            <ScanButton />
          </Link>
        ) : (
          <Link href="/signin/" asChild>
            <SignUpButton />
          </Link>
        )}
      </XStack>
    </XStack>
  );
}

export function AppHeaderMobile({
  left = <HeaderLeftMobile />,
  middle = <AppHeaderMiddle />,
  right = <AppHeaderRight />,
}: AppHeaderProps) {
  return (
    <HeaderFrame style={styles.frame}>
      <HeaderFrame.Left>{left}</HeaderFrame.Left>
      <HeaderFrame.Middle>{middle}</HeaderFrame.Middle>
      <HeaderFrame.Right>{right}</HeaderFrame.Right>
    </HeaderFrame>
  );
}

const styles = StyleSheet.create({
  frame: {
    height: 56,
  },
  gap8: {
    gap: 8,
  },
  gap24: {
    gap: 24,
  },
});
