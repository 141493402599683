import { IHistoricalOrder } from '@rbi-ctg/menu';
import { useGetRestaurantsQuery } from 'generated/sanity-graphql';

export const useOrderTimeTimezone = (order?: IHistoricalOrder) => {
  const storeId = order?.cart?.storeId;
  const { data: sanityRestaurantData } = useGetRestaurantsQuery({
    skip: !storeId,
    variables: {
      filter: {
        number: String(storeId),
      },
      limit: 1,
    },
  });

  if (!order?.createdAt) {
    return { orderTimeTimezone: null };
  }

  const timezone = sanityRestaurantData?.allRestaurants?.[0].timezone ?? 'UTC';
  const date = new Date(order?.createdAt);
  let orderDateTime = date.toLocaleString();
  try {
    orderDateTime = date.toLocaleString('en-US', { timeZone: timezone });
  } catch (e) {
    orderDateTime = date.toLocaleString('en-US', { timeZone: 'UTC' });
  }
  const orderTime = orderDateTime.split(' ')[1];
  const timePeriod = orderDateTime.split(' ')[2];
  const orderTimeTimezone = [orderTime, timePeriod, timezone].join(' ');

  return { orderTimeTimezone };
};
