import React from 'react';

import {
  EnvListContainer,
  HiddenEnvItem,
  HiddenSection,
  getConfigValues,
} from 'pages/diagnostics/components';

const ConfigSettingsContent = () => {
  return (
    <EnvListContainer>
      {Object.entries(getConfigValues()).map(([key, value]) => (
        <HiddenEnvItem key={key} envKey={key} value={JSON.stringify(value)} />
      ))}
    </EnvListContainer>
  );
};

export const ConfigSettings = () => (
  <HiddenSection title="Config Settings" content={<ConfigSettingsContent />} />
);
