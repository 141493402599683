import { Box } from '@fhs-legacy/universal-components';
import Link from 'components/link';
import { useGetMaybeCcpaLinkProps } from 'hooks/one-trust/use-get-maybe-ccpa-link-props';
import { isExternalLink } from 'utils/is-external-link';

import SubmitFeedbackLink from './submit-feedback-link';

interface NavigationPage {
  title: string;
  path: null | { current: string };
  localeTitle: null | { locale: string };
  localePath: null | { locale: { current: string } };
  redirectUrl: null | { locale: string };
  __typename: 'StaticPage';
}
interface NavigationSection {
  name: { locale: string };
  pages: NavigationPage[] | null;
  __typename: 'NavigationSection';
}

interface IListProps {
  entries: (NavigationSection | NavigationPage)[];
}

const addPathSlash = (path: string): string =>
  isExternalLink(path) || path.startsWith('/') ? path : `/${path}`;

const Section = Box.withConfig<{ hasBorder: boolean }>(p => ({
  borderTopWidth: p.hasBorder ? '1' : '0',
  borderTopColor: 'black',
}));

const SectionGroup = Box.withConfig({
  paddingTop: '4',
  paddingBottom: '4',
});

const SectionItem = Box.withConfig({
  marginTop: '1',
  marginBottom: 1,
});

function PageLink(page: NavigationPage) {
  const getMaybeLinkProps = useGetMaybeCcpaLinkProps();
  const title = page?.localeTitle?.locale || page?.title || '';
  const path = addPathSlash(page?.localePath?.locale?.current || page?.path?.current || '');

  return (
    <SectionItem marginTop="2" key={`${page.title}: ${path}`}>
      <Link
        {...getMaybeLinkProps({
          to: { pathname: path, params: { title: title } },
          isExternal: isExternalLink(path),
          children: title,
        })}
      />
    </SectionItem>
  );
}

export default function ListOfLinks({ entries }: IListProps) {
  return (
    <Box>
      {entries.map((entry, i: number) => (
        <Section hasBorder={i > 0} key={`path-group-${i}`}>
          <SectionGroup>
            {entry.__typename === 'NavigationSection' ? (
              (entry.pages ?? []).map((p, index) => (
                <Box key={`${p.path}:${p.localePath?.locale?.current}:${p.title}`}>
                  {entry.name.locale === 'Support' && index === 0 ? <SubmitFeedbackLink /> : null}
                  <PageLink {...p} />
                </Box>
              ))
            ) : (
              <PageLink {...entry} />
            )}
          </SectionGroup>
        </Section>
      ))}
    </Box>
  );
}
