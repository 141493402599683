import React from 'react';

import { Upsell } from 'pages/cart/upsell';

import { UpsellCarouselWrapper } from './product-upsell-styled';

const ProductUpsell: React.VFC = () => (
  <UpsellCarouselWrapper>
    <Upsell />
  </UpsellCarouselWrapper>
);

export default ProductUpsell;
