import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconQr = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M3 11.1h8v-8H3v8Zm2-6h4v4H5v-4Zm-2 16h8v-8H3v8Zm2-6h4v4H5v-4Zm8-4v-8h8v8h-8Zm2-2h4v-4h-4v4Zm6 10h-2v-2h2v-2h-2v-2h-2v2h-2v-2h-2v2h2v2h-2v2h2v2h2v-2h2v2h2v-2Zm-4-2h2v-2h-2v2Zm0 0v2h-2v-2h2Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
