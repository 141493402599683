import { UseRewardDiscount } from './types';

const redeemableItemsSet = new Set('');

const useRewardDiscount = (() => {
  return {
    cartHasRewardEligibleItem: false,
    redeemableItemsSet,
  };
}) as UseRewardDiscount;

export default useRewardDiscount;
