import React from 'react';
import { GestureResponderEvent } from 'react-native';

import { HStack, Pressable, Text } from '@fhs-legacy/universal-components';
import { MethodContainer } from 'components/payment-method-option/styled';

import { IPaymentMethodAddNewItemOptionProps } from './types';

const PaymentMethodAddNewItemOption = ({
  text,
  Icon,
  onPress,
  testID,
}: IPaymentMethodAddNewItemOptionProps) => {
  const handleOnPress = (e: GestureResponderEvent) => {
    if (!onPress) {
      return;
    }
    onPress(e);
  };

  return (
    <Pressable
      onPress={handleOnPress}
      testID={testID}
      accessibilityRole="button"
      accessibilityLabel={text}
    >
      <MethodContainer>
        <HStack space={2}>
          {Icon}
          <Text>{text}</Text>
        </HStack>
      </MethodContainer>
    </Pressable>
  );
};

export default PaymentMethodAddNewItemOption;
