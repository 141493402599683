import React, { useRef } from 'react';

import { Header, NbModal, withConfig } from '@fhs-legacy/universal-components';

import { IBottomDrawerProps } from './types';

const DrawerCloseButton = withConfig(NbModal.CloseButton, {
  background: Styles.color.cardBackground,
  width: '28px',
});

const DrawerContent = withConfig(NbModal.Content, {
  background: Styles.color.cardBackground,
  width: '100%',
  padding: 5,
  paddingBottom: 10,
});

const DrawerHeader = Header.withConfig({
  textAlign: 'center',
});

export const BottomDrawer = ({
  children,
  dialogState,
  header,
  testID,
  horizontalAlignment,
  contentWidth,
}: IBottomDrawerProps) => {
  /**
   * Workaround for nested modals: When bottom drawer is opened from another modal,
   * an autoscroll happens in the first modal because of FocusScope in NbModal component.
   * This ref help us to prevent that unexpected autoscroll.
   */
  const helperFocusRef = useRef();
  return (
    <NbModal
      isOpen={dialogState.visible}
      onClose={dialogState.hide}
      testID={testID}
      justifyContent="flex-end"
      alignItems={horizontalAlignment ?? 'flex-end'}
      initialFocusRef={helperFocusRef}
      finalFocusRef={helperFocusRef}
    >
      {dialogState.visible && (
        <>
          <DrawerContent maxWidth={contentWidth}>
            <DrawerCloseButton onPress={dialogState.hide} />
            <DrawerHeader variant="headerThree">{header}</DrawerHeader>
            {children}
          </DrawerContent>
        </>
      )}
    </NbModal>
  );
};
