import * as React from 'react';

import { FeatureEmptyState } from 'components/features/components/empty-state';

import { LoyaltyLoader } from '../loyalty-loader';

import { Container, LoadingContainer, Padding } from './loyalty-no-reward-points.styled';
import { useFeaturesLoyaltyNoReward } from './use-features-loyalty-no-reward';

const LoyaltyNoRewardPoints = () => {
  const { data, loading } = useFeaturesLoyaltyNoReward();
  if (loading) {
    return (
      <LoadingContainer>
        <LoyaltyLoader />
      </LoadingContainer>
    );
  }
  if (!data) {
    return null;
  }
  return (
    <Container>
      <FeatureEmptyState content={data} showCTA={false} />
      {/* explicit padding element needed for safari */}
      <Padding />
    </Container>
  );
};

export default LoyaltyNoRewardPoints;
