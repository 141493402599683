import { ReactNode } from 'react';

import { DatetimeShow } from '@fhs/ui';

import { frenchDipUSEndDate, frenchDipUSStartDate } from '../constants';

export type LtoContentUSProps = {
  ltoContent?: ReactNode;
  default?: ReactNode;
};

export const LtoContentUS = ({ ltoContent, default: defaultContent }: LtoContentUSProps) => {
  return (
    <>
      <DatetimeShow
        after={frenchDipUSStartDate}
        before={frenchDipUSEndDate}
        default={defaultContent}
      >
        {ltoContent}
      </DatetimeShow>
    </>
  );
};
