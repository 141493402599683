import React, { FC, useState } from 'react';

import { Radio } from '@fhs-legacy/universal-components';
import { IconType } from 'components/accordion/types';

import RadioOption from './radio-option';
import {
  StyledAccordion,
  StyledAccordionItem,
  TransactionDateOptionWrapper,
} from './transaction-date-input-form.styled';
import { ITransactionDateInputFormProps } from './types';

const TransactionDateInputForm: FC<React.PropsWithChildren<ITransactionDateInputFormProps>> = ({
  onChange,
  options,
  title,
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const handleOptionClick = (value: string) => {
    setSelectedOption(value);
    onChange(value);
  };

  return (
    <StyledAccordion>
      <StyledAccordionItem
        title={title}
        iconType={IconType.ARROW}
        contentWrapper={TransactionDateOptionWrapper}
      >
        <Radio.Group
          name="transactionDateRadio"
          accessibilityLabel="transaction date input"
          onChange={handleOptionClick}
          value={selectedOption}
          paddingX="$4"
        >
          {options.map(({ value: optionValue, label }) => (
            <RadioOption key={optionValue} label={label} value={optionValue} />
          ))}
        </Radio.Group>
      </StyledAccordionItem>
    </StyledAccordion>
  );
};

export default TransactionDateInputForm;
