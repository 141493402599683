import { ReactNode } from 'react';

export interface ISupportAlertBoxProps {
  children: ReactNode;
  alertStyle: ALERT_STYLE;
  testId?: string;
}

export enum ALERT_STYLE {
  success = 'success',
  error = 'error',
}
