import { MqSwitch } from '@fhs/ui';

import { ScreenHomeDesktop } from './screen-home-desktop';
import { ScreenHomeMobile } from './screen-home-mobile';
import { ScreenHomeProps } from './types';
export { ScreenHomeProps } from './types';

export function ScreenHome(props: ScreenHomeProps) {
  return (
    <MqSwitch
      $ltDesktop={() => <ScreenHomeMobile {...props} />}
      $gteDesktop={() => <ScreenHomeDesktop {...props} />}
    />
  );
}
