import { useEffect } from 'react';

import useEffectOnce from 'hooks/use-effect-once';
import { useLocalStorageState } from 'hooks/use-local-storage-state';
import {
  ShortCodeState,
  useInRestaurantRedemptionContext,
} from 'state/loyalty/in-restaurant-redemption';
import { StorageKeys } from 'utils/local-storage';

export const useShortCodeRefresh = () => {
  const { shortCodeState, generateShortCode } = useInRestaurantRedemptionContext();

  const [lasShortCodeState, setLastShortCodeState] = useLocalStorageState<ShortCodeState | null>({
    key: StorageKeys.REDEEM_LAST_CODE_STATE,
    defaultReturnValue: null,
  });
  const [shouldRefresh, setShouldRefresh] = useLocalStorageState<boolean | null>({
    key: StorageKeys.REDEEM_SHOULD_REFRESH,
    defaultReturnValue: true,
  });

  useEffect(() => {
    setLastShortCodeState(shortCodeState);
  }, [setLastShortCodeState, shortCodeState]);

  useEffectOnce(() => {
    if (lasShortCodeState === ShortCodeState.Expired && shouldRefresh) {
      generateShortCode();
      setShouldRefresh(false);
    }

    return () => {
      setLastShortCodeState(shortCodeState);
      setShouldRefresh(true);
    };
  });
};
