/*
 * This enum maintains a list of currently running a/b experiments.
 * There are strong expectations with this last so please do read this and follow the expectations.
 *
 * 1. These experiments have a defined lifetime coordinated within amplitude.
 *      When an experiment ends, the enum should be removed, and all the coordinated code should be removed
 *      only keeping around the winner of the a/b experiment.
 * 2. The naming of these keys should exactly match the name of the test within amplitude.
 *      This will keep consistency, and improve searchability while tracking down experiments from amplitude to the code.
 * 3. Each key should be surround by a comment block, explaining the experiment and any information related to the code
 *      that a developer may benefit from when trying to trace logic through the codebase. Remember experiments can introduce
 *      bugs to code outside of the experiment, and its important that we keep the business logic changes as thoroughly explained
 *      as possible.
 */

export enum RunningExperiments {
  // The checkout flow includes an additional confirmation step that was built to solve a
  // problem that affects 2% of users, and the solution has a 5% funnel drop. This test
  // drops the confirmation screen in the checkout flow to validate that it should be removed.
  // @see https://experiment.amplitude.com/restaurantbrandsintl/253543/config/8672/run
  RemoveConfirmYourStore = 'growth-remove-confirm-your-store',

  // The homepage is complicated, filled with many CTAs and distractions.
  // The growth team is testing simplifying the home page and removing as much
  // distractions as possible.
  // @see https://experiment.amplitude.com/restaurantbrandsintl/231923/config/8770/plan
  SimpleHomePage = 'growth-simple-hp',

  // The store locator is defaulting to a list view currently.
  // The growth team is testing a hypothesis that list views are more difficult
  // for users to choose a store than a map view. This experiment is testing
  // defaulting to the map view.
  // @see https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3854991458/Default+to+Map+View+on+Store+Locator
  DefaultMapView = 'growth-default-to-map-view',

  // Experimenting with a cleaner sign up form similar to the sign in flow.
  // @see https://experiment.amplitude.com/restaurantbrandsintl/224166/config/10717/plan
  TwoStepSignUp = 'brand-two-step-sign-up',

  // Experimenting Bottom service mode vs Top service mode
  // @see https://experiment.amplitude.com/restaurantbrandsintl/253543/config/11484/plan
  ServiceMode = 'growth-bsm-vs-tsm',

  // Experimenting to analyze if users try to go back without saving changes to comboslots in menu wizard
  // @see https://experiment.amplitude.com/restaurantbrandsintl/224166/config/11437/plan
  AutoSaveComboSlotChanges = 'brand-auto-save-comboslot-changes',
  // Experimenting to enable confirm location when the user click on start order button
  // @see https://experiment.amplitude.com/restaurantbrandsintl/253543/config/11633/plan
  EnableConfirmLocationDrawerAtHome = 'enable-confirm-location-drawer-at-home',

  // Experimenting with static menu.
  // @see https://experiment.amplitude.com/restaurantbrandsintl/253543/config/11634/plan
  StaticMenu = 'brand-static-menu',
  // Experimenting with a single flow for sign up and sign in
  // @see https://experiment.amplitude.com/restaurantbrandsintl/253543/config/12222/plan
  SingleAuthFlow = 'brand-single-auth-flow',
  // Experimenting to enable Redeem in restaurant for unauthenticated users
  // @see https://experiment.amplitude.com/restaurantbrandsintl/253543/config/13598/plan
  EnableRedeemInRestaurantForUnauthenticatedExperiment = 'enable-redeem-in-restaurant-for-unauthenticated',

  //  rGuest experiments 👇

  // Experimenting to enable Single Select CTA in Locator page
  // @see https://experiment.amplitude.com/restaurantbrandsintl/438963/config/31671/plan
  SingleSelectCTAinLocator = 'single-select-cta-in-locator',
  // Experimenting to enable Map View Under Nearby Stores
  // @see https://experiment.amplitude.com/restaurantbrandsintl/438963/config/31673/plan
  MapViewUnderNearbyStores = 'map-view-under-nearby-stores',
  // Experimenting with a simplified landing page
  // @see https://experiment.amplitude.com/restaurantbrandsintl/438963/config/31674/plan
  SimplifiedLandingPage = 'simplified-landing-page',
}
