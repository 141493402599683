import { ITotals } from 'pages/cart/your-cart/totals/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { OrderStatus } from 'state/order';
import { computeTotalDiscountAmount } from 'utils/cart/helper';
import { isDelivery } from 'utils/service-mode';

export const useTotalTipCalculations = ({ serverOrder, serviceMode }: ITotals) => {
  const loading = !serverOrder || serverOrder.status === OrderStatus.PRICE_REQUESTED;
  const hideTipAmount = useFlag(LaunchDarklyFlag.HIDE_TIP_AMOUNT_CHECKOUT);
  const isDeliveryOrder = isDelivery(serviceMode);

  const { cart, delivery } = serverOrder || {};
  const deliveryFee = delivery ? delivery.feeCents : null;
  const deliveryFeeDiscount = delivery ? delivery.feeDiscountCents : null;
  const allDiscounts = cart?.discounts || [];

  const totalDiscountAmount = computeTotalDiscountAmount(allDiscounts);

  // delivery.tipCents is null until the user advances to PAYMENT_REQUESTED
  // at the same time, totalCents increases by the tip amount
  // we needed to do this to render the correct grand total/tip as user advances
  const serverOrderTip = Number(delivery?.tipCents || 0);

  const { subTotalCents, taxCents } = cart || {};

  return {
    hideTipAmount,
    subTotalCents,
    taxCents,
    rewardDiscountAmount: 0,
    serverOrderTip,
    serverOrderPickUpTips: serverOrder?.cart.pickUpTipsCents || 0,
    additionalDiscountAmount: totalDiscountAmount,
    deliveryFee,
    deliveryFeeDiscount,
    loading,
    isDeliveryOrder,
    cart,
    delivery,
  };
};
