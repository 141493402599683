import LocalStorage, { StorageKeys } from 'utils/local-storage';

import { getCurrentSessionWrapper } from './get-current-session';

export { getCurrentSession } from './get-current-session';

// Return if there is an unexpected signs out tracking
export const checkForUnexpectedSignOut = (): Promise<Error> =>
  getCurrentSessionWrapper()
    .then(() => {
      // Add a marker when a user is successfully sign in to track unexpected signs out
      LocalStorage.setItem(StorageKeys.USER_SIGNED_IN_SUCCESSFULLY, true);
      return null;
    })
    .catch(error => {
      // First check if the user has been logged in successfully
      const userSignedInSuccessfully = LocalStorage.getItem(
        StorageKeys.USER_SIGNED_IN_SUCCESSFULLY
      );
      if (userSignedInSuccessfully) {
        // Remove the marker and return that there has been an unexpected signs out
        LocalStorage.removeItem(StorageKeys.USER_SIGNED_IN_SUCCESSFULLY);
        return error;
      }
      return null;
    });
