import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconChevronRight = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="m8.295 16.986 4.58-4.59-4.58-4.59 1.41-1.41 6 6-6 6-1.41-1.41Z"
      />
    </Svg>
  );
};
