import { withStaticProperties } from '@fhs/utils';

import { Quantity as QuantityDefault } from './quantity';
import { QuantityCounter as QuantityCounterWrapper } from './quantity-counter';
import { QuantityLabel as Label } from './quantity-label';
import { QuantityStepper as Stepper } from './quantity-stepper';

export const Quantity = withStaticProperties(QuantityDefault, {
  Label,
  Stepper,
});

export const QuantityCounter = withStaticProperties(QuantityCounterWrapper, {
  Label,
  Stepper,
});

export { QuantityContext, useQuantity } from './context';
export type { QuantityCounterProps } from './quantity-counter';
export type { IQuantityStepperProps } from './type';
