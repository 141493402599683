import { ICartEntry } from '@rbi-ctg/menu';
import { IAppliedOffer } from 'generated/rbi-graphql';
import { IIncentiveSelection, IOrderAddressInput } from 'remote/queries/order';
import { IParsedLoyaltyReward } from 'state/loyalty/hooks/types';
import { CardTypes } from 'state/payment/types';
import { ServiceMode } from 'state/service-mode';
import { StoreProxy } from 'state/store';

export interface IUsePriceOrderParams {
  cartEntries: ICartEntry[];
  orderStatusPollInterval?: number;
  skipOrderStatusPolling?: boolean;
}

export interface IPriceOrderParams {
  appliedOffers?: IAppliedOffer[];
  rewardsApplied?: IParsedLoyaltyReward[] | null;
  cardType?: CardTypes | null;
  cartEntries: ICartEntry[];
  cartVersion: number;
  customerLocale?: string;
  customerName?: string | null;
  deliveryAddress?: IOrderAddressInput | null;
  deliveryInstructions?: string;
  orderPhoneNumber?: string | null;
  loyaltyBarcode?: string | null;
  redeemReward?: boolean | null;
  requestedAmountCents: number;
  serviceMode: ServiceMode;
  store: StoreProxy;
  tipCents?: number;
  isStoreAvailable?: boolean;
  vatNumber?: string;
  pickUpTipsCents?: number;
  incentiveSelections?: IIncentiveSelection[];
}
