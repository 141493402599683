import { Image, ImageProps } from 'expo-image';
import { Link } from 'expo-router';
import { Pressable, StyleSheet } from 'react-native';

export type PublicSafetyFoundationCardProps = Omit<ImageProps, 'children' | 'source'> & {
  foundationHref: string;
};

export function PublicSafetyFoundationCard({
  foundationHref,
  ...imageProps
}: PublicSafetyFoundationCardProps) {
  return (
    <Link href={foundationHref} target="_blank" asChild>
      <Pressable>
        <Image
          alt="Learn more about the Firehouse Subs Public Safety Foundation"
          source={require('./public-safety-foundation-banner.webp')}
          placeholder={{ thumbhash: 'axiGDYQPPzUrgb5D6U+196JZAAB3d4h4hw' }}
          {...imageProps}
          style={[styles.publicSafetyFoundation, imageProps.style]}
        />
      </Pressable>
    </Link>
  );
}

const styles = StyleSheet.create({
  publicSafetyFoundation: {
    aspectRatio: 1354 / 1270,
  },
});
