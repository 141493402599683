import { Image, ImageBackground, ImageBackgroundProps } from 'expo-image';
import { StyleSheet } from 'react-native';

import { XStack, YStack, tokens } from '@fhs/ui';

import { CtaButton } from '../shared/cta-button';

export type PoweradeVictoryBundleCardProps = Omit<ImageBackgroundProps, 'children' | 'source'> & {
  pickUpHref: string;
};
export function PoweradeVictoryBundleCard({
  pickUpHref,
  ...props
}: PoweradeVictoryBundleCardProps) {
  return (
    <ImageBackground
      imageStyle={{}}
      alt="Powerade Victory Splash. New! Discover the winning combination of strawberry, lemon, and peach flavors. Powerade. Olympics Worldwide Partner."
      source={require('./powerade-victory-bundle-card-background.webp')}
      placeholder={{ thumbhash: 'HBoHI4pfIZiIeCd1aZtA9gs' }}
      {...props}
      style={[styles.poweradeVictoryBundleBanner, props.style]}
    >
      <YStack style={styles.contentStack}>
        <YStack style={styles.imgStack}>
          <Image
            source={require('./powerade.webp')}
            alt="Powerade"
            placeholder={{ thumbhash: 'PwiCAYAZoLaHeIcAAAAAAJSIrqhPysg' }}
            style={styles.poweradeImg}
            contentFit="contain"
          />
          <Image
            source={require('./victory-bundle.webp')}
            alt="Victory Bundle"
            placeholder={{ thumbhash: 'PwiCAYAIoLZ5iIcAAAAAAIMN3HWKe4s' }}
            style={styles.victoryBundleImg}
            contentFit="contain"
          />
          <XStack style={[styles.justifyCenter, { paddingTop: 10 }]}>
            <Image
              source={require('./only-available-at-fhs.webp')}
              alt="Only available at Firehouse Subs"
              placeholder={{ thumbhash: 'PwiCAYAllZeHh38AAAAAALSGeYhwl2Y' }}
              style={styles.onlyAvailableAtFhsImg}
              contentFit="contain"
            />
          </XStack>
        </YStack>
        <XStack style={styles.justifyCenter}>
          <CtaButton type="solid-inverted" href={pickUpHref} />
        </XStack>
      </YStack>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  poweradeVictoryBundleBanner: {
    aspectRatio: 1920 / 757,
    userSelect: 'none',
  },
  contentStack: {
    position: 'absolute',
    gap: 25,
    left: '55%',
    right: '13%',
    top: '20%',
    bottom: 0,
  },
  imgStack: {
    gap: 8,
  },
  poweradeImg: {
    width: '100%',
    aspectRatio: 618 / 83,
  },
  victoryBundleImg: {
    width: '100%',
    aspectRatio: 618 / 89,
  },
  onlyAvailableAtFhsImg: {
    width: '82%',
    aspectRatio: 504 / 35,
  },
  button: {
    paddingHorizontal: 24,
    marginTop: 16,
    backgroundColor: tokens.colors.$white,
    borderColor: tokens.colors.$transparent,
  },
  buttonHovered: {
    backgroundColor: tokens.colors.$whiteOpacity90,
  },
  buttonText: {
    color: tokens.colors.$houseRedDarken,
  },
  justifyCenter: {
    justifyContent: 'center',
  },
});
