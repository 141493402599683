import { HStack, Header, Text } from '@fhs-legacy/universal-components';

export const Wrapper = HStack.withConfig({
  justifyContent: 'center',
  margin: 0,
  alignItems: 'center',
});

export const GreetingLabel = Header.withConfig({
  fontSize: 'sm',
  color: Styles.color.white,
  margin: 0,
});

export const GreetingUserNameText = Text.withConfig({
  color: Styles.color.white,
  variant: 'headerOne',
  fontWeight: 'bold',
  fontFamily: 'heading',
  fontSize: 'md',
});
