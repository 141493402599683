import React from 'react';

import { Svg, SvgPath, SvgProps } from '@fhs-legacy/universal-components';

export const FhsProductBgWebSvg = (props: SvgProps) => (
  <Svg {...props} viewBox="0 0 768 101">
    <SvgPath
      d="M0 0H768V101C768 101 544.42 84.6602 382.976 84.6247C222.277 84.5894 0 101 0 101V0Z"
      fill="#B1080F"
    />
  </Svg>
);

export const FhsProductBgMobileSvg = (props: SvgProps) => (
  <Svg {...props} viewBox="0 0 375 101" preserveAspectRatio="xMinYMin meet">
    <SvgPath
      d="M0 0H375V101C375 101 265.83 84.6602 187 84.6247C108.534 84.5894 0 101 0 101V0Z"
      fill="#B1080F"
    />
  </Svg>
);
