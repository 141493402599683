import { Slot, Stack } from 'expo-router';
import { Platform } from 'react-native';

export default Platform.select({
  native: Slot,
  web: function MenuLayout() {
    return <Stack screenOptions={{ headerShown: false }} />;
  },
});

export const unstable_settings = {
  initialRouteName: 'index',
};
