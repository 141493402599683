import { Header, Text } from '@fhs-legacy/universal-components';

import theme from './theme';

export const Heading = Header.withConfig({
  color: theme.headingColor,
  textTransform: Styles.textTransform.headlines,
  variant: 'headerTwo',
  fontFamily: 'heading',
  textAlign: 'center',
  fontSize: '2xl',
});

export const CenteredText = Text.withConfig({
  textAlign: 'center',
  fontSize: 'md',
  color: theme.centeredTextColor,
  padding: '$4',
});
