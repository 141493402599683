import { useCallback, useEffect } from 'react';

import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { getRestaurantsByStoreNumber } from 'remote/api/restaurants';
import { useNetworkContext } from 'state/network';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import logger, { addLoggingContext } from 'utils/logger';
import { mergeRestaurantData, useGetRestaurantFn } from 'utils/restaurant';

import { IStoreContext, StoreProxy } from './types';
import { usePosDataQuery } from './use-pos-data-query';
import useRefreshStore from './use-refresh-store';

export const resetLastTimeStoreUpdated: IStoreContext['resetLastTimeStoreUpdated'] = currentTime =>
  LocalStorage.setItem(StorageKeys.LAST_TIME_STORE_UPDATED, currentTime || Date.now());

export const useStoreUtils = ({
  resetStore: _resetStore,
  store,
}: {
  resetStore: IStoreContext['resetStore'];
  store: StoreProxy;
}) => {
  const { connection, sanityEndpoints } = useNetworkContext();
  const getRestaurant = useGetRestaurantFn();

  const { data, refetch: refetchPosData } = usePosDataQuery({
    storeNumber: store.number,
    restaurantPosDataId: store.restaurantPosData?._id ?? '',
  });

  const prices = store?._id && data?.posData ? data.posData : null;

  const storeEmail = store.email?.[0] ?? '';

  const { serviceModeStatus, isRestaurantAvailable } = useServiceModeStatus(store);

  const fetchStore = useCallback(
    async (storeId: string) => {
      const rbiRestaurant = await getRestaurant(storeId);

      if (!rbiRestaurant) {
        return;
      }

      const [sanityStore] = await getRestaurantsByStoreNumber(sanityEndpoints.groq, {
        connection,
        storeNumber: storeId.toString(),
      });

      return mergeRestaurantData({ rbiRestaurant, sanityStore });
    },
    [connection, sanityEndpoints.groq]
  );

  useRefreshStore({
    getRestaurantPosData: () => {
      refetchPosData();
    },
    store,
  });

  useEffect(() => {
    const storeId = store.number ?? 'No store selected';

    addLoggingContext({
      storeId,
    });

    if (!store._id) {
      return;
    }

    if (!store.restaurantPosData) {
      return logger.error({ message: 'No POS data for selected store', storeId: store._id });
    }
  }, [store]);

  return {
    email: storeEmail,
    fetchStore,
    isRestaurantAvailable,
    prices,
    resetLastTimeStoreUpdated,
    serviceModeStatus,
  };
};
