import React, { createContext, useContext, useState } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { IAddCreditCardFormValues } from 'components/payments/components/add-credit-card-form-inputs/types';
import { IEncryptionResult as IOrbitalEncryptionResult } from 'components/payments/integrations/orbital/components/encryption/types';
import { IEncryptionResult as IWorldpayEncryptionResult } from 'components/payments/integrations/worldpay/components/encryption/types';
import { useUserPaymentInformationQuery } from 'generated/graphql-gateway';
import { IOrderInformation, IPaymentMethodDetails } from 'pages/cart/payments/types';

import { IPaymentContext } from './types';

export const PaymentContext = createContext<IPaymentContext>({} as IPaymentContext);

export const PaymentProvider = (props: IBaseProps) => {
  const [creditCardFormValues, setCreditCardFormValues] = useState<
    IAddCreditCardFormValues | undefined
  >(undefined);
  const [encryptionResult, setEncryptionResult] = useState<
    IOrbitalEncryptionResult | IWorldpayEncryptionResult | undefined
  >(undefined);
  const [orderInformation, setOrderInformation] = useState<IOrderInformation | undefined>(
    undefined
  );
  const [orderNeedsUserConfirmation, setOrderNeedsUserConfirmation] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    IPaymentMethodDetails | undefined
  >(undefined);

  const { data, loading } = useUserPaymentInformationQuery();
  const paymentIntegration = data?.paymentConfig.frontendIntegration;

  return (
    <PaymentContext.Provider
      value={{
        creditCardFormValues,
        encryptionResult,
        loading,
        orderInformation,
        orderNeedsUserConfirmation,
        paymentIntegration,
        selectedPaymentMethod,
        setCreditCardFormValues,
        setEncryptionResult,
        setOrderInformation,
        setSelectedPaymentMethod,
        setOrderNeedsUserConfirmation,
      }}
    >
      {props.children}
    </PaymentContext.Provider>
  );
};

export const usePaymentContext = () => useContext(PaymentContext);
