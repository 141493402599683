import { Box, Header, Pressable, ScrollView, Text } from '@fhs-legacy/universal-components';
import { brandFont } from 'components/layout/brand-font';
import Link from 'components/link';
import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';

export const ScrollContainer = ScrollView.withConfig({
  flex: '1',
  paddingTop: '$32',
});

export const TrackOrderLink = Link.withConfig(() => ({
  borderWidth: '1',
  borderColor: theme.token('border-color-button-secondary'),
  color: theme.token('text-button-secondary'),
  borderRadius: '$8',
  marginY: '$1',
  marginX: '$0',
  paddingY: '$1',
  paddingX: '$3',
  fontSize: 'xs',
  fontWeight: '500',
  textDecoration: 'none',
  textTransform: 'uppercase',
  position: {
    desktop: 'absolute',
  },
  top: {
    desktop: '$6',
  },
  right: {
    desktop: '$8',
  },
  _hover: {
    background: theme.token('background-button-primary-default'),
    color: theme.token('text-link-reversed'),
  },
}));

export const Tile = Box.withConfig(() => ({
  background: theme.token('background-pattern'),
  shadow: '1',
  borderRadius: '2xl',
  alignItems: 'center',
  minHeight: {
    desktop: '$40',
    base: '$48',
  },
  paddingX: {
    desktop: '$10',
    base: '$4',
  },
  paddingTop: {
    desktop: '$7',
    base: '$1',
  },
  paddingBottom: {
    desktop: '$7',
    base: '$4',
  },
  margin: '$4',
  justifyContent: {
    desktop: 'space-between',
  },
  flexDirection: {
    desktop: 'row',
  },
}));

export const Col = Box.withConfig(() => ({
  width: {
    desktop: '48%',
    base: 'full',
  },
  marginBottom: {
    desktop: '$0',
  },
}));

export const MoreItems = Pressable.withConfig({
  paddingTop: '$0.5',
  background: 'transparent',
});

export const MoreItemsText = Text.withConfig({
  color: theme.token('text-accent-order-number'),
  fontSize: 'sm',
});

export const FormattedDate = Text.withConfig({
  textTransform: Styles.textTransform.headlines,
  marginBottom: '$6',
});

export const SubtitleContainer = Text.withConfig(() => ({
  color: theme.token('text-default'),
}));

export const CateringPendingApproval = Box.withConfig(() => ({
  font: brandFont.copyTwo,
  color: primitive.$error,
  margin: '$0',
}));

export const NumItems = Text.withConfig(() => ({
  marginLeft: '$1',
}));

export const Items = Box.withConfig(() => ({
  lineHeight: '16',
  paddingRight: '$4',
  margin: '$0',
}));

export const ItemNameContainer = Box.withConfig(() => ({
  alignItems: 'flex-start',
  marginBottom: '$6',
  color: theme.token('text-default'),
}));

export const Wrap = Box.withConfig(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: {
    desktop: 'auto',
  },
}));

export const RecentOrderTitle = Header.withConfig(() => ({
  marginTop: '$7',
  marginRight: '$0',
  marginBottom: '$5',
  color: theme.token('text-default'),
  textAlign: 'center',
  lineHeight: '2xs',
  fontSize: {
    base: '4xl',
    desktop: '6xl',
  },
}));

export const OrderNumberWrapper = Box.withConfig(() => ({
  flexDirection: 'row',
  marginX: '$0',
  marginTop: '$4',
}));

export const OrderNumberText = Text.withConfig({
  fontSize: 'sm',
  fontWeight: '400',
});

export const OrderNumber = Text.withConfig({
  color: theme.token('text-accent-order-number'),
  fontSize: 'md',
  margin: '$0',
  paddingLeft: '$1',
});

export const OrderBox = Box.withConfig(() => ({
  marginTop: '$4',
  width: 'full',
}));

export const OrdersContainer = Box.withConfig({
  maxWidth: Styles.layout.sectionMaxWidth,
  paddingY: '$0',
});

export const OrdersEmptyWrapper = Box.withConfig({
  minHeight: '2/3',
  paddingTop: '1/6',
  justifyContent: 'center',
});

export const OfferDisclaimer = Text.withConfig({
  textAlign: 'center',
  marginTop: '$3',
  paddingX: '$4',
});
