import React from 'react';

import { useUIContext } from 'state/ui';

import { Price, PriceAndCaloriesText } from './price-and-calories.styled';
import { PriceAndCaloriesProps } from './types';

export const PriceAndCalories: React.FC<React.PropsWithChildren<PriceAndCaloriesProps>> = ({
  price,
  calories,
}) => {
  const { formatCurrencyForLocale } = useUIContext();

  if (!price && !calories) {
    return null;
  }

  const formattedPrice = price && `+${formatCurrencyForLocale(price)}`;

  return (
    <PriceAndCaloriesText>
      {calories}
      {'  '}
      {!!formattedPrice && <Price>{formattedPrice}</Price>}
    </PriceAndCaloriesText>
  );
};

export default PriceAndCalories;
