import { useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';

import { Button } from '@fhs/ui';
import Dialog from 'components/dialog';

type LoyaltyClaimPointsDialogProps = {
  title: string;
  message: React.ReactNode;
  eventMessage: string;
  confirmText?: string | null;
  onDismiss: () => void;
  onConfirm: () => void;
};

const LoyaltyClaimPointsDialog = ({
  title,
  message,
  eventMessage,
  confirmText,
  onDismiss,
  onConfirm,
}: LoyaltyClaimPointsDialogProps) => {
  const { formatMessage } = useIntl();

  const buttonText = confirmText ?? formatMessage({ id: 'continue' });

  return (
    <Dialog
      showDialog
      modalAppearanceEventMessage={eventMessage}
      heading={title}
      onDismiss={onDismiss}
      bodyComponent={<View style={styles.messageContainer}>{message}</View>}
      actions={
        <Button style={styles.button} onPress={onConfirm}>
          <Button.Text>{buttonText}</Button.Text>
        </Button>
      }
    />
  );
};

const styles = StyleSheet.create({
  messageContainer: {
    width: '100%',
  },
  button: {
    width: '100%',
    marginTop: 20,
  },
});

export default LoyaltyClaimPointsDialog;
