import { IconCheck } from '@fhs/ui';
import { isIOS } from 'utils/environment';

import { IconContainer } from './icon-container';

export const TapWhileUsing = () => {
  return (
    <IconContainer>
      <IconCheck color={isIOS() ? '#3F88EE' : undefined} size={20} />
    </IconContainer>
  );
};
