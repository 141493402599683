import { StyleSheet, View } from 'react-native';

import { Text, tokens } from '@fhs/ui';

export function LegalDisclaimer() {
  return (
    <View style={styles.legalDisclaimers}>
      <Text>
        {
          'You will a receive an authentication code via a one-time text at this number. Msg&Data rates may apply.'
        }
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  legalDisclaimers: {
    padding: 16,
    marginVertical: 16,
    borderWidth: 1,
    alignItems: 'center',
    borderColor: tokens.colors.$disabledText,
    color: tokens.colors.$disabledText,
  },
});
