import React, { FC, createContext, useContext, useMemo } from 'react';

import { useFeatureMenu } from 'hooks/use-feature-menu';

import useActiveDayParts, { IValidDayPart, isValidDayPart } from './hooks/use-active-day-parts';

export interface IDayPartCtx {
  activeDayParts: ReturnType<typeof useActiveDayParts>;
  dayParts: ReadonlyArray<IValidDayPart>;
}

export const DayPartContext = createContext<IDayPartCtx>({ activeDayParts: [], dayParts: [] });

export const useDayPartContext = () => useContext(DayPartContext);

export const DayPartProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { featureMenu } = useFeatureMenu();
  const dayParts: Array<IValidDayPart> = useMemo(() => {
    const validDayParts: Array<IValidDayPart> = [];
    featureMenu?.dayParts?.forEach(dayPart => {
      if (dayPart && isValidDayPart(dayPart as IValidDayPart)) {
        validDayParts.push(dayPart as IValidDayPart);
      }
    });
    return validDayParts;
  }, [featureMenu]);

  const activeDayParts = useActiveDayParts({ dayParts });

  return (
    <DayPartContext.Provider value={{ activeDayParts, dayParts }}>
      {children}
    </DayPartContext.Provider>
  );
};
