import { router } from 'expo-router';
import { useIntl } from 'react-intl';

import { Box, Icon, Text } from '@fhs-legacy/universal-components';
import { StoreIcon } from 'components/icons/store-icon';
import { PulseAnimatedView } from 'components/ucl/pulse-animated-view';
import { useIsMobileBp } from 'hooks/breakpoints';
import { useGeolocation } from 'state/geolocation';
import { useStoreContext } from 'state/store';
import { Geography } from 'utils/geography';
import { routes } from 'utils/routing';

import ChangeLink from './change-link';
import { getLocalizedStoreAddress } from './common';
import { useLocationPermissionsAlert } from './use-location-permissions-alert';

export const RestaurantDetails = () => {
  const { store } = useStoreContext();
  const { formatMessage } = useIntl();
  const isMobile = useIsMobileBp();

  const localizedStoreAddress = getLocalizedStoreAddress(store, formatMessage);

  const { userCoordinates, isPermissionGranted } = useGeolocation();

  const distanceInMiles =
    userCoordinates && store.latitude && store.longitude
      ? Geography.coordinateDistance(
          { latitude: userCoordinates.lat, longitude: userCoordinates.lng },
          { latitude: store.latitude, longitude: store.longitude },
          true
        )
      : 0;

  const maxDistanceInMiles = 10;
  const showAlert = maxDistanceInMiles < distanceInMiles || !isPermissionGranted;

  const { alert, openAlert } = useLocationPermissionsAlert();

  const inlineAlertTitle = formatMessage({
    id: isPermissionGranted ? 'youSeemFarAway' : 'youMightBeFarAway',
  });
  const inlineAlertContent = isPermissionGranted ? (
    <Text>
      {formatMessage(
        { id: 'clickToSelectACloserStore' },
        {
          clickHere: (
            <Text underline onPress={() => router.replace(routes.storeLocator)}>
              {formatMessage({ id: 'clickHere' })}
            </Text>
          ),
        }
      )}
    </Text>
  ) : (
    <Text>
      {formatMessage(
        { id: 'shareLocationToFindClosesStore' },
        {
          shareLocation: (
            <Text underline onPress={openAlert}>
              {formatMessage({ id: 'shareLocation' })}
            </Text>
          ),
        }
      )}
    </Text>
  );

  return (
    <Box borderWidth={1} borderColor="#e5e5e5" p="$4" borderRadius="md">
      <Box alignItems="center" flexDirection="row">
        <Box
          borderRadius="full"
          height={10}
          width={10}
          backgroundColor="#f5f5f5"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <StoreIcon height={24} width={24} />
        </Box>
        <Box pl={4} pr={2} flex={1}>
          <Text fontSize="sm" bold isTruncated={isMobile} maxWidth={isMobile ? '$40' : '100%'}>
            {store.name}
          </Text>
          <Text fontSize="xs" mt="$1">
            {localizedStoreAddress}
          </Text>
        </Box>
        <ChangeLink
          onPress={() =>
            router.replace({ pathname: routes.storeLocator, params: { back: routes.cart } })
          }
          accessibilityLabel={formatMessage({
            id: 'changeStoreAriaLabel',
          })}
          isUnderlined
          _text={{
            fontSize: 'xs',
          }}
        >
          {formatMessage({ id: 'change' })}
        </ChangeLink>
      </Box>
      {showAlert && (
        <PulseAnimatedView>
          <Box
            backgroundColor="warn.100"
            borderRadius="md"
            px="$4"
            py="$3"
            flexDirection="row"
            alignItems="center"
            gap="$2.5"
            mt="$3"
          >
            <Icon variant="warning" size={5} />
            <Box flex={1}>
              <Text bold fontSize="xs" lineHeight="xs">
                {inlineAlertTitle}
              </Text>
              <Text fontSize="xs">{inlineAlertContent}</Text>
            </Box>
          </Box>
        </PulseAnimatedView>
      )}
      {alert}
    </Box>
  );
};
