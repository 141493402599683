import { Box, HStack, Text, VStack } from '@fhs-legacy/universal-components';

import theme from './theme';

export const Item = VStack.withConfig({
  backgroundColor: Styles.color.cardBackground,
  borderRadius: 10,
  borderWidth: 0,
  borderColor: 'transparent',
  marginBottom: '$4',
});

export const ItemOffer = Box.withConfig({
  backgroundColor: 'token.background-input-disabled',
  borderRadius: Styles.borderRadius,
  borderBottomRadius: 0,
  paddingY: 3,
  paddingX: 4,
});

export const ItemOfferHeading = Text.withConfig<{ $isApplied?: boolean }>(p => ({
  fontSize: 'xs',
  letterSpacing: 'xl',
  textTransform: 'uppercase',
  color: p.$isApplied ? Styles.color.black : Styles.color.red,
  marginBottom: '$1',
}));

export const CartItemEditButtonContainer = HStack.withConfig({
  alignItems: 'center',
});

export const CartItemDescription = Text.withConfig({
  variant: 'copyTwo',
  fontSize: 'sm',
  color: theme.cartItemDescriptionColor,
  marginTop: 1,
});

export const SeeMoreDescription = Text.withConfig({
  variant: 'copyTwo',
  fontSize: 'sm',
  color: theme.cartItemDescriptionColor,
  fontWeight: 'bold',
  textDecorationLine: 'underline',
});
