import { IOrderAddressInput } from 'remote/queries/order';
import { StoreProxy } from 'state/store';

export const buildStoreAddress = (store: StoreProxy): IOrderAddressInput => {
  const { address1, address2, city, country, postalCode, stateProvince } =
    store.physicalAddress ?? {};

  return {
    addressLine1: address1 || '',
    addressLine2: address2,
    city: city || '',
    country: country || '',
    zip: postalCode || '',
    state: stateProvince || '',
    phoneNumber: store.phoneNumber || '',
  };
};
