import { View, ViewProps } from 'react-native';

import { Text } from '@fhs/ui';

export const BaseFee = ({
  style,
  formattedFee,
}: {
  style?: ViewProps['style'];
  formattedFee?: string;
}) => {
  return (
    <View style={style}>
      <Text.Heading type="five">Base Fee: {formattedFee}</Text.Heading>
      <Text.Paragraph size="sm">Fixed fee applied to each delivery order.</Text.Paragraph>
    </View>
  );
};
