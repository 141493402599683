import * as React from 'react';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '@fhs-legacy/universal-components';
import ConfirmDialog from 'components/confirm-dialog';
import { InputWithButton } from 'components/input-with-button';
import { SavedPromotionsModal } from 'components/saved-promotions-modal';
import { RotateAnimatedView } from 'components/ucl';
import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import {
  CTAButtonText,
  CallToActionHeader,
  Divider,
  ErrorMessage,
  MessageContainer,
  OptionsContainer,
  PromoCTA,
  PromoExpandToggleIcon,
  SuccessCode,
  SuccessMessage,
  Wrapper,
} from './cart-promo-codes.styled';
import { ICartPromoCodesViewProps } from './types';

export const CartPromoCodesView: FC<React.PropsWithChildren<ICartPromoCodesViewProps>> = ({
  onSubmit,
  codeInput,
  isLoading,
  isExpanded,
  setIsExpanded,
  onChangeInput,
  errorMessageId,
  appliedOfferName,
  appliedOfferError,
  appliedOfferDescription,
  cartPromoCodeOffers,
  removeAppliedOffer,
  showSavedPromotionsModal,
  onSelectedSavedPromotion,
  updateSavedPromotionsModal,
  showConfirmSwitchOffersDialog,
  onConfirmSwitchOffers,
  onCancelSwitchOffers,
}) => {
  const { formatMessage } = useIntl();

  const haveAppliedCartPromoOffer = useMemo(
    () => appliedOfferName && appliedOfferDescription,
    [appliedOfferDescription, appliedOfferName]
  );

  const tabIndex = isExpanded ? 0 : -1;

  return (
    <Wrapper testID="cart-promo-code-container">
      <PromoCTA
        onPress={() => setIsExpanded(!isExpanded)}
        accessibilityLabel={formatMessage({ id: 'togglePromoCodeAria' })}
        testID="cart-promo-code-toggle-button"
      >
        <CallToActionHeader>{formatMessage({ id: 'promoCodeCTA' })}</CallToActionHeader>
        <CTAButtonText>{formatMessage({ id: 'togglePromoCode' })}</CTAButtonText>
        <PromoExpandToggleIcon>
          <RotateAnimatedView isRotated={isExpanded} toDeg={90}>
            <Icon
              variant="next"
              color={theme.token('icon-button-secondary')}
              width="$4"
              {...hiddenAccessibilityPlatformProps}
            />
          </RotateAnimatedView>
        </PromoExpandToggleIcon>
      </PromoCTA>
      <>
        <InputWithButton
          buttonLabel={formatMessage({ id: 'apply' })}
          label={formatMessage({ id: 'enterPromoCode' })}
          name="cartPromoCode"
          value={codeInput}
          // @ts-expect-error TS(2322) FIXME: Type '({ target: { value } }: ChangeEvent<HTMLInpu... Remove this comment to see the full error message
          onChange={onChangeInput}
          isLoading={isLoading}
          onSubmit={onSubmit}
          buttonTabIndex={tabIndex}
          tabIndex={tabIndex}
        />
        <MessageContainer testID="cart-promo-code-message-container">
          {!!errorMessageId && (
            <ErrorMessage testID="cart-promo-code-error-message">
              {formatMessage({ id: errorMessageId })}
            </ErrorMessage>
          )}
          {!!haveAppliedCartPromoOffer && (
            <>
              <SuccessCode testID="cart-promo-code-success-name">{appliedOfferName}</SuccessCode>
              <SuccessMessage testID="cart-promo-code-success-description">
                {appliedOfferDescription}
              </SuccessMessage>
              {appliedOfferError && (
                <ErrorMessage testID="cart-promo-code-offer-error-message">
                  {formatMessage({ id: 'cartPromotionOfferRequirementsNotMetError' })}
                </ErrorMessage>
              )}
            </>
          )}
        </MessageContainer>
        <OptionsContainer>
          <PromoCTA
            onPress={() => updateSavedPromotionsModal(true)}
            accessibilityLabel={formatMessage({ id: 'togglePromotionModalAria' })}
            testID="toggle-promotion-modal"
          >
            <CTAButtonText>{formatMessage({ id: 'togglePromotionModal' })}</CTAButtonText>
          </PromoCTA>
          {!!haveAppliedCartPromoOffer && (
            <PromoCTA
              onPress={() => removeAppliedOffer()}
              accessibilityLabel={formatMessage({ id: 'removeAppliedCartPromoCodeAriaLabel' })}
              testID="cart-promo-code-remove-button"
            >
              <CTAButtonText>{formatMessage({ id: 'remove' })}</CTAButtonText>
            </PromoCTA>
          )}
        </OptionsContainer>
        {showSavedPromotionsModal && (
          <SavedPromotionsModal
            onDismiss={() => updateSavedPromotionsModal(false, true)}
            // @ts-expect-error TS(2322) FIXME: Type '(selectedOffer: IOffer) => void' is not assi... Remove this comment to see the full error message
            onSelectPromotion={onSelectedSavedPromotion}
            cartPromoCodeOffers={cartPromoCodeOffers}
          />
        )}
        {showConfirmSwitchOffersDialog && (
          <ConfirmDialog
            showDialog={showConfirmSwitchOffersDialog}
            heading={formatMessage({ id: 'oneOfferPerOrder' })}
            body={formatMessage({ id: 'replaceOffer' })}
            confirmLabel={formatMessage({ id: 'yes' })}
            cancelLabel={formatMessage({ id: 'no' })}
            onConfirm={onConfirmSwitchOffers}
            onCancel={onCancelSwitchOffers}
            onDismiss={onCancelSwitchOffers}
            modalAppearanceEventMessage="Confirm Replace Coupon"
          />
        )}
        <Divider />
      </>
    </Wrapper>
  );
};
