import { Box, Header, Text } from '@fhs-legacy/universal-components';
import { WEB_APP_CTA_MAX_WIDTH } from 'utils/constants';

export const Wrapper = Box.withConfig({
  paddingTop: '$8',
  paddingBottom: '$24',
  paddingX: { base: '$10', desktop: 0 },
  maxW: WEB_APP_CTA_MAX_WIDTH,
  alignSelf: 'center',
});

export const IconWrapper = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'stretch',
  justifyContent: 'center',
  height: '$20',
});

export const HeadingText = Header.withConfig({
  color: Styles.color.black,
  fontSize: '2xl',
  textAlign: 'center',
  width: 'full',
  accessibilityLevel: 1,
  marginY: '$4',
  alignSelf: 'center',
});

export const BodyText = Text.withConfig({
  fontSize: 'md',
  textAlign: 'center',
  marginBottom: '$4',
});
