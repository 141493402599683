import { useMemo } from 'react';

import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { useServiceModeContext } from '@fhs-legacy/frontend/src/state/service-mode';
import { useStoreContext } from '@fhs-legacy/frontend/src/state/store';
import {
  isDelivery as _isDelivery,
  isPickup as _isPickup,
  isDriveThru,
} from '@fhs-legacy/frontend/src/utils/service-mode';

import { CAREERS_PATH, CATERING_PATH, DONATE_PATH, FOUNDATION_PATH } from './constants';

export function useHrefs() {
  const { noStoreSelected } = useStoreContext();
  const { serviceMode } = useServiceModeContext();
  const isDelivery = _isDelivery(serviceMode);
  const isPickup = _isPickup(serviceMode) || isDriveThru(serviceMode);
  const pickUpHref = noStoreSelected || !isPickup ? '/store-locator/service-mode' : '/menu';
  const deliveryHref = useFlag(LaunchDarklyFlag.TEMP_HIDE_DELIVERY_CTA)
    ? ''
    : noStoreSelected || !isDelivery
    ? '/store-locator/address'
    : '/menu';
  const cateringLink: string = useFlag(LaunchDarklyFlag.HOME_CATERING_URL) || CATERING_PATH;
  const cateringHref = useFlag(LaunchDarklyFlag.ENABLE_CATERING_CTA) ? cateringLink : '';

  return useMemo(
    () => ({
      pickUpHref,
      deliveryHref,
      cateringHref,
      foundationHref: FOUNDATION_PATH,
      careersHref: CAREERS_PATH,
      donateHref: DONATE_PATH,
    }),
    [pickUpHref, deliveryHref, cateringHref]
  );
}
