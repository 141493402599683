import React, { VFC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

import { ActionSheet, Button } from '@fhs/ui';
import { ScrollView, Text } from '@fhs-legacy/universal-components';
import { IComboSlotOption, IItem } from '@rbi-ctg/menu';
import ActionButton from 'components/action-button';
import { ProductCtaWrapper } from 'components/product-detail/product-cta';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import useDialogModal from 'hooks/use-dialog-modal';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { ClickEventComponentNames } from 'state/crm-events/constants';
import { useProductWizardContext } from 'state/product-wizard';
import { useProductWizardFlow } from 'state/product-wizard/hooks/use-product-wizard-flow';
import {
  ProductWizardStep,
  UserSelectionModifierMap,
  UserSelectionModifierSlotMap,
} from 'state/product-wizard/types';
import { ProductWizardUtils } from 'state/product-wizard/utils';
import { isWeb } from 'utils/environment';

import { ProductCustomizationBackButton } from './product-customization-back-button';
import { ProductCustomizationDrawerHeader } from './product-customization-drawer-header';
import { ProductCustomizationHeader } from './product-customization-header';
import { ProductCustomizationModifiers } from './product-customization-modifiers';
import { ProductCustomizationOptions } from './product-customization-options';
import {
  ProductCustomizationFixedHeader,
  ProductCustomizationPanel,
} from './product-customization.styled';
import { IProductCustomizationProps, SelectModifierFn, SelectionError } from './types';
import { getModifiersDefaultOptions } from './utils';

export const ProductCustomization: VFC<IProductCustomizationProps> = ({ itemToCustomize }) => {
  useRumPageView('product-customization', 'Product Customization');
  const { formatMessage } = useIntl();
  const { submitCustomizations, userSelections, menuObject, isSimplyOffer } =
    useProductWizardContext();
  const { currentStep, slotKeyToCustomize, goToHome } = useProductWizardFlow();
  // Local state for user's draft changes
  const [selectedItem, setSelectedItem] = useState<IItem>(itemToCustomize);
  const [modifierSelections, setModifierSelections] = useState<UserSelectionModifierSlotMap>(
    userSelections.modifiers[slotKeyToCustomize]
  );

  const [submitted, setSubmitted] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = useCallback(() => setCollapsed(!collapsed), [collapsed]);

  const [defaultModifiersMap] = useState(() => getModifiersDefaultOptions(selectedItem));

  const handleResetModifierSelections = useCallback(
    (item = selectedItem) => {
      const defaultModifiers = ProductWizardUtils.ComputeDefaultModifiersSelections(item);

      setModifierSelections(defaultModifiers);
      submitCustomizations({
        modifiers: defaultModifiers,
        selectedItem: item,
        slotKey: slotKeyToCustomize,
      });
      setHasUnsavedChanges(false);
    },
    [selectedItem, slotKeyToCustomize, submitCustomizations]
  );

  const handleComboSlotSelectionChange = useCallback(
    (comboSlotOption: IComboSlotOption) => {
      const item = comboSlotOption.option as IItem;
      setSelectedItem(item);
      handleResetModifierSelections(item);
      setHasUnsavedChanges(true);
    },
    [handleResetModifierSelections]
  );

  const handleChangeModifierSelections = useCallback<SelectModifierFn>(
    (selections, modifier) => {
      const mappedSelections = Object.values(selections).reduce((acc, selection) => {
        acc[selection._key] = {
          item: selectedItem,
          itemId: selectedItem._id,
          modifier: selection,
          option: modifier,
          comboSlotId: userSelections?.comboSlot?.get(slotKeyToCustomize)?.comboSlot?._id,
          isSanityDefaultSelection: false,
        };
        return acc;
      }, {} as UserSelectionModifierMap);

      setModifierSelections(current => {
        return {
          ...current,
          [modifier._key]: mappedSelections,
        };
      });
      setHasUnsavedChanges(true);
    },
    [selectedItem, slotKeyToCustomize, userSelections]
  );

  const [MissingSelectionsDialog, openMissingSelectionsDialog] = useDialogModal({
    modalAppearanceEventMessage: 'Menu option selection errors.',
  });
  const missingSelectionsErrors = useMemo(
    () =>
      Object.values(modifierSelections).reduce((errors, modifierMap) => {
        const modifierError = Object.values(modifierMap).reduce(
          (missingError, action) => ({
            name: missingError.name || action.option.name.locale,
            minAmount: missingError.minAmount || action.option.minAmount,
            quantity: missingError.quantity + action.modifier.quantity,
          }),
          { minAmount: 0, quantity: 0, name: '' }
        );
        if (modifierError.minAmount > modifierError.quantity) {
          errors.push(modifierError);
        }
        return errors;
      }, [] as SelectionError[]),
    [modifierSelections]
  );

  const { logRBIEvent } = useCRMEventsContext();
  const handleSubmit = useCallback(() => {
    logRBIEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.BUTTON,
        text: 'Update Item',
      },
    });
    setSubmitted(true);
    if (missingSelectionsErrors.length) {
      openMissingSelectionsDialog();
      return;
    }
    submitCustomizations({
      modifiers: modifierSelections,
      selectedItem,
      slotKey: slotKeyToCustomize,
    });
    goToHome();
  }, [
    goToHome,
    logRBIEvent,
    missingSelectionsErrors.length,
    modifierSelections,
    openMissingSelectionsDialog,
    selectedItem,
    slotKeyToCustomize,
    submitCustomizations,
  ]);

  const discardUnsavedChanges = useCallback(() => {
    goToHome();
  }, [goToHome]);

  const [ConfirmationDialog, openConfirmationDialog] = useDialogModal({
    onConfirm: handleSubmit,
    onCancel: discardUnsavedChanges,
    onDismiss: discardUnsavedChanges,
    showCancel: true,
    modalAppearanceEventMessage: 'Confirmation: Product Wizard - Discard unsaved changes',
  });

  const handleBackButtonClick = useCallback(() => {
    if (hasUnsavedChanges) {
      openConfirmationDialog();
    } else {
      goToHome();
    }
  }, [hasUnsavedChanges, openConfirmationDialog, goToHome]);

  const comboSlot = useMemo(
    () => userSelections.comboSlot?.get(slotKeyToCustomize)?.comboSlot,
    [slotKeyToCustomize, userSelections.comboSlot]
  );

  const comboSlotOptions = comboSlot?.options || [];
  const hasAvailableOptions = comboSlotOptions.length > 1;
  const showExpandedOptions = !collapsed && hasAvailableOptions;

  // Prevents rendering two mobile header back buttons
  const showBackButton = currentStep === ProductWizardStep.ProductCustomization;

  const pickerAspectExclusions = useMemo(() => {
    return ProductWizardUtils.GetPickerAspectExclusions(menuObject);
  }, [menuObject]);

  const ScrollComponent: any = isSimplyOffer ? ActionSheet.ScrollView : ScrollView;

  return (
    <ProductCustomizationPanel testID="product-customization">
      <ConfirmationDialog
        heading={formatMessage({ id: 'unsavedChangesModalHeader' })}
        body={formatMessage({ id: 'unsavedChangesModalDescription' })}
        confirmLabel={formatMessage({ id: 'save' })}
        cancelLabel={formatMessage({ id: 'discard' })}
      />
      <MissingSelectionsDialog
        heading={formatMessage({ id: 'missingItems' })}
        bodyComponent={missingSelectionsErrors.map(error => (
          <Text key={error.name}>
            {formatMessage(
              { id: 'makeAdditionalSelections' },
              { name: error.name, quantity: error.minAmount - error.quantity }
            )}
          </Text>
        ))}
      />
      <ProductCustomizationFixedHeader>
        {showBackButton && (
          <ProductCustomizationBackButton onBackButtonClick={handleBackButtonClick} />
        )}
        <ProductCustomizationDrawerHeader onClose={handleBackButtonClick} />
        <ProductCustomizationHeader
          comboSlot={comboSlot}
          isCollapsed={collapsed}
          isClickable={hasAvailableOptions}
          selectedItem={selectedItem}
          slotKey={slotKeyToCustomize}
          toggleCollapse={toggleCollapsed}
          modifierSelections={modifierSelections}
        />
      </ProductCustomizationFixedHeader>
      <ScrollComponent _web={{ bg: Styles.color.white }}>
        {showExpandedOptions && (
          <ProductCustomizationOptions
            comboSlot={comboSlot}
            comboSlotOptions={comboSlotOptions}
            selectedItem={selectedItem}
            onSelectComboSlot={handleComboSlotSelectionChange}
            modifierSelections={modifierSelections}
          />
        )}
        <ProductCustomizationModifiers
          modifierSelections={modifierSelections}
          modifierDefaults={defaultModifiersMap}
          onModifierSelectionsChange={handleChangeModifierSelections}
          onResetModifierSelections={handleResetModifierSelections}
          pickerAspectExclusions={pickerAspectExclusions}
          selectedItem={selectedItem}
        />
      </ScrollComponent>
      <ProductCtaWrapper
        isSimplyOffer={isSimplyOffer}
        elements={[
          {
            element: isSimplyOffer ? (
              <View style={{ width: '100%' }}>
                <Button
                  size="xl"
                  disabled={submitted && missingSelectionsErrors.length > 0}
                  onPress={handleSubmit}
                >
                  <Button.Text>{formatMessage({ id: 'updateItem' })}</Button.Text>
                </Button>
              </View>
            ) : (
              <ActionButton
                disabled={submitted && missingSelectionsErrors.length > 0}
                onPress={handleSubmit}
                fullWidth={!isWeb}
                testID="customization-submit-button"
                _web={{
                  w: 'full',
                  px: '$4',
                }}
              >
                {formatMessage({ id: 'updateItem' })}
              </ActionButton>
            ),
          },
        ]}
      />
    </ProductCustomizationPanel>
  );
};

export default ProductCustomization;
