import { useFeatureLoyaltyClaimPointsContentQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

export const useLoyaltyClaimPoints = () => {
  const { featureClaimPointsUIId = '', featureIdsLoading } = useFeaturesContext();
  const { data, loading } = useFeatureLoyaltyClaimPointsContentQuery({
    variables: { featureClaimPointsUIId },
    skip: !featureClaimPointsUIId,
  });

  return {
    data: data?.ClaimPointsUI,
    loading: featureIdsLoading || loading,
  };
};
