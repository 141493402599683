export enum OfferPlatform {
  Mobile = 'Mobile',
  Kiosk = 'Kiosk',
  Web = 'Web',
}

export enum RuleType {
  AllowRedemptionWithoutLogin = 'allow-redemption-without-login',
  And = 'and',
  BetweenDates = 'between-dates',
  BetweenTimes = 'between-times',
  CartProperty = 'cart-property',
  CoolDown = 'cool-down',
  DayOfWeekBand = 'day-of-week-band',
  FirstOrderOnly = 'first-order-only',
  Limit = 'limit',
  Or = 'or',
  OrderHistory = 'order-history',
  Purchase = 'purchase',
  RequiresAssignment = 'requires-assignment',
  RestaurantGroupRestrictions = 'restaurant-group-restrictions',
  PaymentCardType = 'payment-card-type',
}

export enum CartPropertyOperator {
  lessThan = '<',
  lessThanOrEqual = '<=',
  greaterThan = '>',
  greaterThanOrEqual = '>=',
  equalTo = '=',
  nil = 'is-nil',
  not = 'not',
  null = 'is-null',
  undefined = 'is-undefined',
}
