import { Box, Header, Text } from '@fhs-legacy/universal-components';

export const Wrapper = Box.withConfig({
  marginX: 0,
  marginY: '$5',
  _text: {
    textAlign: 'center',
    margin: 0,
    color: Styles.color.grey.one,
  },
});

export const Title = Header.withConfig({
  marginTop: 0,
  marginRight: 0,
  marginBottom: '3px',
  marginLeft: 0,
  paddingTop: '$1.5',
  textAlign: 'center',
  color: Styles.color.grey.one,
  fontSize: 'lg',
  variant: 'headerFour',
});

export const CancellationPolicyText = Text.withConfig({
  textAlign: 'center',
});
