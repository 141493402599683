import * as React from 'react';
import { FC, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { LayoutAnimation, Platform, UIManager } from 'react-native';

import { Icon } from '@fhs-legacy/universal-components';
import { IncentiveEvaluationMessages } from 'components/incentive-evaluation-messages/incentive-evaluation-messages';
import { RotateAnimatedView } from 'components/ucl';
import { LoyaltyIncentivesGrid } from 'pages/loyalty/loyalty-incentives-components';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { routes } from 'utils/routing';

import { IncentiveCard } from '../loyalty-incentives-components/incentive-card/incentive-card';
import { REWARD_CARD_HEIGHT } from '../loyalty-incentives-components/incentive-card/incentive-card.styled';
import { parseIncentiveData } from '../loyalty-incentives-components/incentive-card/parse-incentive-data';
import { RewardCardContent } from '../loyalty-incentives-components/incentive-card/reward-card-content';

import { POINTS_TRANSLATION_KEY } from './constants';
import {
  AccordionTrigger,
  CategoryHeader,
  CategoryImage,
  CategoryLabel,
  DelayedUnmountRewardContainer,
  LoyaltyRewardsTitle,
  RewardCategoryAccordion,
} from './loyalty-rewards.styled';
import { ILoyaltyRewardsViewProps } from './types';

if (Platform.OS === 'android' && UIManager.setLayoutAnimationEnabledExperimental) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

export const LoyaltyRewardsViewBase: FC<React.PropsWithChildren<ILoyaltyRewardsViewProps>> = ({
  handleOnPress,
  sanityRewardsMap,
  selectedCategories,
  setSelectedCategories,
  engineRewardsMap,
  rewardCategories,
  rewardsEvaluationMap,
  title,
  selectedReward,
  refetchRewards,
  isRefreshingRewards,
  isWidget,
  setSelectedRewardIdFromWidget,
}) => {
  const [lastSelectedCategory, setLastSelectedCategory] = useState('');
  const lockOnImageEnabled = Boolean(useFlag(LaunchDarklyFlag.ENABLE_LOCK_ON_REWARD_IMAGE));
  const { formatMessage } = useIntl();
  const enableRewardPageStandAlone = Boolean(
    useFlag(LaunchDarklyFlag.ENABLE_REWARD_PAGE_STAND_ALONE)
  );
  const loyaltyListRoute = enableRewardPageStandAlone
    ? formatMessage({ id: 'routes.loyaltyHome' })
    : routes.loyaltyRewardsList;

  const AccordionWithRewardItems = useMemo(
    () =>
      rewardCategories.map(category => {
        const categoryLabel = category.label?.en;
        if (!category.rewards?.length || !categoryLabel) {
          return null;
        }

        const isCategorySelected = selectedCategories.includes(categoryLabel);

        const categoryHeight = category.rewards.length * REWARD_CARD_HEIGHT;
        const onPressCategoryHeader = () => {
          const lastSelectedCat = !isCategorySelected ? categoryLabel : '';
          setLastSelectedCategory(lastSelectedCat);

          // Automatically animate views to their new positions when the next layout happens
          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

          return setSelectedCategories(selected => {
            const entries = new Set(selected);
            if (entries.has(categoryLabel)) {
              entries.delete(categoryLabel);
            } else {
              entries.add(categoryLabel);
            }
            return Array.from(entries);
          });
        };

        const isCurrentSelection = lastSelectedCategory === categoryLabel;

        const formattedCategoryLabelId = categoryLabel.replace(/\s/g, '_');
        const categoryHeaderId = `${formattedCategoryLabelId}-header`;
        const categorySectionId = `${formattedCategoryLabelId}-section`;

        const categoryHeaderAccessibilityHint = `${categoryLabel} ${formatMessage({
          id: POINTS_TRANSLATION_KEY,
        })}`;

        return (
          <RewardCategoryAccordion
            open={isCategorySelected}
            isCurrentSelection={isCurrentSelection}
            categoryHeight={categoryHeight}
            key={categoryLabel}
          >
            <CategoryHeader>
              <AccordionTrigger
                key={categoryHeaderId}
                aria-controls={categorySectionId}
                aria-expanded={isCategorySelected}
                onPress={onPressCategoryHeader}
              >
                {category?.image?.locale && (
                  <CategoryImage
                    alt={category.imageDescription?.locale || ''}
                    image={category.image.locale}
                    objectFitContain
                  />
                )}
                <CategoryLabel accessibilityLabel={categoryHeaderAccessibilityHint}>
                  {categoryLabel}
                </CategoryLabel>
                <RotateAnimatedView isRotated={isCategorySelected} fromDeg={0} toDeg={180}>
                  <Icon
                    variant="select"
                    aria-hidden
                    accessibilityLabel={formatMessage({
                      id: isCategorySelected ? 'upArrowChevronIcon' : 'downArrowChevronIcon',
                    })}
                  />
                </RotateAnimatedView>
              </AccordionTrigger>
            </CategoryHeader>
            <DelayedUnmountRewardContainer
              id={categorySectionId}
              role="region"
              aria-labelledby={categoryHeaderId}
              isVisible={isCategorySelected}
              delayTime={0}
            >
              {category.rewards.map(reward => {
                const rewardId = reward?._id;
                const incentive = sanityRewardsMap[rewardId || ''];
                const loyaltyRewardId = incentive?.loyaltyEngineId || '';
                const engineReward = engineRewardsMap[loyaltyRewardId];
                const rewardEvaluationResults = rewardsEvaluationMap[loyaltyRewardId];
                return (
                  incentive && (
                    <IncentiveCard
                      key={rewardId}
                      incentiveData={parseIncentiveData(incentive)}
                      handlePress={handleOnPress}
                      locked={lockOnImageEnabled && !!engineReward?.locked}
                      hasMessage={!!rewardEvaluationResults}
                    >
                      <RewardCardContent engineReward={engineReward} />
                      {!!rewardEvaluationResults && (
                        <IncentiveEvaluationMessages
                          incentiveEvaluationResults={rewardEvaluationResults}
                          hideIcon
                          isSmall
                          fitContentWidth
                        />
                      )}
                    </IncentiveCard>
                  )
                );
              })}
            </DelayedUnmountRewardContainer>
          </RewardCategoryAccordion>
        );
      }),
    [
      engineRewardsMap,
      handleOnPress,
      lastSelectedCategory,
      lockOnImageEnabled,
      rewardCategories,
      rewardsEvaluationMap,
      sanityRewardsMap,
      selectedCategories,
      setSelectedCategories,
    ]
  );

  return (
    <LoyaltyIncentivesGrid
      selectedIncentive={selectedReward}
      refetch={refetchRewards}
      isRefreshing={isRefreshingRewards}
      goBack={loyaltyListRoute}
      engineIncentivesMap={engineRewardsMap}
      offersInCooldown={false}
      isWidget={isWidget}
      setSelectedRewardIdFromWidget={setSelectedRewardIdFromWidget}
    >
      {title && <LoyaltyRewardsTitle>{title}</LoyaltyRewardsTitle>}
      {AccordionWithRewardItems}
    </LoyaltyIncentivesGrid>
  );
};
