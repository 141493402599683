import React from 'react';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { usePaymentContext } from 'state/payment';
import { routes } from 'utils/routing';

import { ADD_NEW_GIFT_CARD_TRANSLATION_ID } from './constants';
import TransferGiftCardLink from './transfer-gift-card-link';

export default () => {
  const { formatMessage } = useIntl();
  const { getPrepaidPaymentMethod } = usePaymentContext();
  const hasGiftCard = !!getPrepaidPaymentMethod();

  return hasGiftCard ? (
    <TransferGiftCardLink />
  ) : (
    <ActionButton
      fullWidth
      variant={ActionButtonVariants.OUTLINE}
      to={routes.addGiftCard}
      testID="add-new-gift-card"
    >
      {formatMessage({
        id: ADD_NEW_GIFT_CARD_TRANSLATION_ID,
      })}
    </ActionButton>
  );
};
