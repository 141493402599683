import { Box, HStack, Icon } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import UnstyledButton from 'components/unstyled-button';

import theme from './theme';

export const StyledButtonBox = Box.withConfig({
  flexDirection: 'row',
  flexWrap: 'wrap',
});

export const StyledRecentOrderCard = HStack.withConfig({
  borderBottomWidth: '1px',
  borderBottomColor: Styles.color.white,
  justifyContent: 'space-between',
  backgroundColor: Styles.color.cardBackground,
  paddingY: '$5',
  paddingX: '$4',
});

export const StyledActionButton = ActionButton;

export const IconButton = UnstyledButton.withConfig<{ disabled?: boolean }>(p => ({
  color: p.disabled ? '#777777' : null,
  width: 26,
  height: 26,
  borderColor: p.disabled ? theme.fillDisabled : theme.fill,
  borderWidth: 1,
  borderRadius: 50,
  marginTop: '$1',
}));

export const AddIcon = Icon.withConfig<{ isDisabled: boolean }>(props => ({
  color: props.isDisabled ? theme.fillDisabled : theme.fill,
  variant: 'add',
}));
