import { withStaticProperties } from '@fhs/utils';

import { AnchorListHorizontal } from './anchor-list-horizontal';
import { AnchorListVertical } from './anchor-list-vertical';
import { AnchorSectionList as _AnchorSectionList } from './anchor-section-list';
import { SectionList } from './section-list';

export const AnchorSectionList = withStaticProperties(_AnchorSectionList, {
  SectionList,
  AnchorListHorizontal,
  AnchorListVertical,
});
