import { cloneElement } from 'react';
import type { ReactElement } from 'react';

import { useFormFieldContext } from './form-field';

export type FormFieldControlProps = {
  children: ReactElement;
};
export function FormFieldControl(props: FormFieldControlProps) {
  const formFieldContext = useFormFieldContext();

  return cloneElement(props.children, {
    'aria-label': formFieldContext.labelText,
    ref: (el: { focus?: () => void }) =>
      formFieldContext.setFocusControl(() => {
        el?.focus?.();
      }),
  });
}
