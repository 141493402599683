import { Image } from 'expo-image';
import { Pressable, StyleSheet } from 'react-native';

export function HotSauceBarCard() {
  return (
    <Pressable>
      <Image
        alt="Hot Sauce Bar is back. Add some flavor to any sub when you dine-in or pick-up"
        source={require('./hot-sauce-bar-card-banner.webp')}
        placeholder={{ thumbhash: 'GfoGDgxpdpZWefl4d4aHc3pI+myH+lc' }}
        style={[styles.banner]}
      />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  banner: {
    aspectRatio: 568 / 635,
  },
});
