// Declare your variables
const INSET = 50;
const BOTTOM_INSET = 170;

export const TOAST_MANAGER_ROUTE_POSITIONS = {
  default: {
    top: {
      top: INSET,
      left: 0,
      right: 0,
    },
    'top-right': {
      top: INSET,
      right: 0,
    },
    'top-left': {
      top: INSET,
      left: 0,
    },
    bottom: {
      bottom: BOTTOM_INSET,
      left: 0,
      right: 0,
    },
    'bottom-left': {
      bottom: BOTTOM_INSET,
      left: 0,
    },
    'bottom-right': {
      bottom: BOTTOM_INSET,
      right: 0,
    },
  },
  'v2/menu/(menu)/[slug]': {
    top: {
      top: INSET,
      left: 0,
      right: 0,
    },
    'top-right': {
      top: INSET,
      right: 0,
    },
    'top-left': {
      top: INSET,
      left: 0,
    },
    bottom: {
      bottom: 20,
      left: 0,
      right: 0,
    },
    'bottom-left': {
      bottom: BOTTOM_INSET,
      left: 0,
    },
    'bottom-right': {
      bottom: BOTTOM_INSET,
      right: 0,
    },
  },
};
