import { useField } from 'formik';

import { addWithConfig } from '../../utils';
import { usePropsWithFormikFormControlName } from '../formik-form-control';
import { Radio } from '../radio/Radio';

import type { FormikRadioGroupProps, _FormikRadioComponentType } from './types';

const FormikRadioGroup = (props: FormikRadioGroupProps) => {
  const { name, radioGroupProps, ...rest } = usePropsWithFormikFormControlName(props);
  const [field, , helpers] = useField(name);

  return (
    <Radio.Group
      name={name}
      value={field.value}
      onChange={(nextValue) => helpers.setValue(nextValue)}
      {...radioGroupProps}
      {...rest}
    />
  );
};

// @ts-ignore BaseRadio is a function so it does have access to bind, it's not in the react type though
export const FormikRadio: _FormikRadioComponentType = Radio.bind({});
FormikRadio.Group = FormikRadioGroup;

const FormikRadioWithConfig = addWithConfig(FormikRadio);
FormikRadioWithConfig.Group = addWithConfig(FormikRadio.Group);

export default FormikRadioWithConfig;
