import { Redirect } from 'expo-router';

import { AuthContainer, SignIn } from '@fhs/auth';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';

export default function SigninPage() {
  const enableSimplyAuth = useFlag<boolean>(LaunchDarklyFlag.ENABLE_SIMPLY_AUTH_FLOW);
  return enableSimplyAuth ? (
    <AuthContainer>
      <SignIn />
    </AuthContainer>
  ) : (
    <Redirect href="/(auth)/signin" />
  );
}
