export interface ITransactionDateOption {
  value: string;
  label: string;
}

export interface ITransactionDateInputFormProps {
  title: string;
  options: ITransactionDateOption[];
  onChange: (val: string) => void;
}

export interface IRadioOptionProps {
  value: string;
  label: string;
}
