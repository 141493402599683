import { router } from 'expo-router';

import useDialogModal from 'hooks/use-dialog-modal';
import { routes } from 'utils/routing';

interface IUseOfferUnavailableModalProps {
  onCancelRedemption(): void;
}

export default function useOfferUnavailableModal({
  onCancelRedemption,
}: IUseOfferUnavailableModalProps) {
  // @ts-ignore FIXME: Expression produces a union type that is too complex to represent.ts(2590)
  return useDialogModal({
    showCancel: true,
    allowDismiss: false,
    onConfirm: () => {
      onCancelRedemption();
    },
    onCancel: () => {
      onCancelRedemption();
      router.navigate(routes.base);
    },
    modalAppearanceEventMessage: 'Cancel Redemption: Offer unavailable',
  });
}
