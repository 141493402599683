import { isEqual } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { FlatList, ScrollView } from 'react-native';

import { StoreLocatorEmptyStates } from 'components/store-locator-empty-states';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { useStoreLocatorPrefetch } from 'pages/store-locator/hooks/use-store-locator-prefetch';
import { useGeolocation } from 'state/geolocation';
import { OrderLocatorTab } from 'state/launchdarkly/variations';
import { STORE_LOCATOR_STORE_LIST_ITEM_AT } from 'utils/test-ids';

import LoadingIndicator from '../../loading-indicator';
import { StoreActions } from '../store-actions';
import { StoreCard } from '../store-card';
import type { StoreCardProps } from '../store-card/types';

import { StoreCardWrapper } from './store-card-list.styled';

interface IStoreCardListProps {
  currentTabHasError?: boolean;
  isSearching?: boolean;
  restaurants: IRestaurantNode[];
  tab: OrderLocatorTab;
  onCardPress?: (storeId?: string, storeNumber?: string, storeAddress?: string) => void;
}

const StoreCardItem = ({ isSelected, restaurant, onCardClick, testID }: StoreCardProps) => {
  return (
    <StoreCardWrapper>
      <StoreCard
        restaurant={restaurant}
        onCardClick={onCardClick}
        isSelected={isSelected}
        testID={testID}
      />
      {isSelected ? <StoreActions restaurant={restaurant} /> : null}
    </StoreCardWrapper>
  );
};

const StoreCardList: React.FC<IStoreCardListProps> = ({
  currentTabHasError = false,
  isSearching,
  restaurants = [],
  tab,
  onCardPress,
}) => {
  const { formatMessage } = useIntl();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const { coordinatesAvailable, isPermissionGranted } = useGeolocation();
  const { prefetchRestaurantValuesForOrder } = useStoreLocatorPrefetch();

  const handleCardClick = (index: number) => {
    if (selectedIndex === index) {
      setSelectedIndex(null);
    } else {
      setSelectedIndex(index);

      prefetchRestaurantValuesForOrder(restaurants[index]);
    }
  };

  if (isSearching) {
    return <LoadingIndicator ariaLabel={formatMessage({ id: 'locationSearch' })} />;
  }

  if (restaurants.length === 0) {
    return (
      <ScrollView>
        <StoreLocatorEmptyStates
          tab={tab}
          locationPermissionsGranted={isPermissionGranted}
          coordinatesAvailable={coordinatesAvailable}
          currentTabError={currentTabHasError}
        />
      </ScrollView>
    );
  }

  return (
    <FlatList
      keyExtractor={(item, index) => item?.id || index + '_restaurant'}
      data={restaurants}
      renderItem={({ item, index }) => {
        const handleCardPress = onCardPress
          ? () =>
              onCardPress(
                String(item.id),
                String(item.number),
                String(item.physicalAddress?.address1)
              )
          : () => handleCardClick(index);

        return (
          <StoreCardItem
            testID={STORE_LOCATOR_STORE_LIST_ITEM_AT(index)}
            restaurant={item}
            onCardClick={handleCardPress}
            isSelected={index === selectedIndex}
          />
        );
      }}
    />
  );
};

export default React.memo(StoreCardList, (prevProps, nextProps) => {
  if (prevProps.tab !== nextProps.tab) {
    return false;
  }
  if (prevProps.isSearching !== nextProps.isSearching) {
    return false;
  }
  if (prevProps.currentTabHasError !== nextProps.currentTabHasError) {
    return false;
  }
  if (!isEqual(prevProps.restaurants, nextProps.restaurants)) {
    return false;
  }

  return true;
});
