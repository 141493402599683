import { router } from 'expo-router';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { IBackendCartEntries } from '@rbi-ctg/menu';
import { FavoriteCard } from 'components/favorite-card';
import { ProductCardListSkeleton } from 'components/product/product.skeleton';
import {
  GetUserFavoritesRefsDocument,
  GetUserReorderFavoritesDocument,
  useDeleteFavoriteMutation,
  useGetUserReorderFavoritesQuery,
} from 'generated/rbi-graphql';
import useDialogModal from 'hooks/use-dialog-modal';
import { useToast } from 'hooks/use-toast';
import { useAuthContext } from 'state/auth';
import { useFavoritesContext } from 'state/favorites';
import { useStoreContext } from 'state/store';
import { isCartEntryInMenu } from 'utils/cart';
import { routes } from 'utils/routing';

import { LoggedOutEmptyState, NoFavoritesEmptyState } from './empty-state';
import { Wrapper } from './favorites-card-list.styled';
import { FavoriteCardData } from './types';

/**
 *
 * FavoritesCardList displays all the favorite orders and allows the guest to reorder, edit, and remove
 *
 */
const InnerFavoritesCardList = () => {
  const toast = useToast();
  const { data, loading, refetch } = useGetUserReorderFavoritesQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [deleteFavorite] = useDeleteFavoriteMutation({
    onCompleted: () =>
      toast.show({ text: formatMessage({ id: 'removeFavoriteSucess' }), variant: 'positive' }),
  });
  const { formatMessage } = useIntl();

  const { isAuthenticated } = useAuthContext();
  const { noStoreSelected } = useStoreContext();
  const { setFavorite } = useFavoritesContext();

  const [RemoveFavoriteModal, openRemoveFavoriteModal] = useDialogModal({
    // @ts-expect-error TS(2322) FIXME: Type '({ id }: { id: string; }) => void' is not as... Remove this comment to see the full error message
    onConfirm: ({ id }: { id: string }) => {
      deleteFavorite({
        variables: {
          favoriteId: id,
        },
        refetchQueries: [
          { query: GetUserReorderFavoritesDocument },
          { query: GetUserFavoritesRefsDocument },
        ],
      });
    },
    showCancel: true,
    modalAppearanceEventMessage: 'Remove Favorite',
  });

  const handleEditFavorite = (id: string) => {
    if (noStoreSelected) {
      router.replace({
        pathname: routes.storeLocator,
        params: { back: `${routes.favorites}/${id}/edit` },
      });
    } else {
      router.navigate(`${routes.favorites}/${id}/edit`);
    }
  };

  const handleRemoveFavorite = (id: string) => {
    openRemoveFavoriteModal({ id });
  };

  const favorites = data?.userFavorites?.favorites;

  const { shouldRefetchFavorites, setShouldRefetchFavorites } = useFavoritesContext();
  const shouldRefetch = !favorites || shouldRefetchFavorites;

  useEffect(() => {
    setFavorite(null);
  }, [favorites]);

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
      setShouldRefetchFavorites(false);
    }
  }, [refetch, setShouldRefetchFavorites, shouldRefetch]);

  const favoriteOrders = useMemo(() => {
    return favorites?.reduce((favsAccum: FavoriteCardData[], favorite) => {
      const entries = (favorite?.entries || ([] as unknown)) as IBackendCartEntries[];
      const filteredEntries = entries.filter(isCartEntryInMenu);

      if (filteredEntries.length) {
        favsAccum.push({
          id: favorite?._id || '',
          name: favorite?.name || '',
          entries: filteredEntries,
        });
      }

      return favsAccum;
    }, []);
  }, [favorites]);

  if (loading) {
    return <ProductCardListSkeleton hasFooter />;
  }

  if (!favoriteOrders?.length) {
    if (!isAuthenticated) {
      return <LoggedOutEmptyState />;
    }
    return <NoFavoritesEmptyState />;
  }

  return (
    <>
      {favoriteOrders?.map((favorite, i) => {
        if (!favorite) {
          return null;
        }

        return (
          <FavoriteCard
            key={i}
            id={favorite.id}
            name={favorite.name}
            entries={favorite.entries}
            onEditFavorite={handleEditFavorite}
            onRemoveFavorite={handleRemoveFavorite}
          />
        );
      })}

      <RemoveFavoriteModal
        buttonLabel={formatMessage({ id: 'remove' })}
        heading={formatMessage({ id: 'removeFavorite' })}
        body={formatMessage({ id: 'removeFavoriteConfirm' })}
        showCloseButton
      />
    </>
  );
};

const FavoritesCardList = () => (
  <Wrapper>
    <InnerFavoritesCardList />
  </Wrapper>
);

export default FavoritesCardList;
