import { useIntl } from 'react-intl';

import { IconCalendar, IconDelivery, IconDineIn, tokens } from '@fhs/ui';
import { ServiceMode } from 'generated/graphql-gateway';

import { Badge, Label, Wrapper } from './styled';

interface IFireOrderBadge {
  inProgress: boolean;
  serviceMode: ServiceMode;
}

export const FireOrderBadge = ({ inProgress, serviceMode }: IFireOrderBadge) => {
  const { formatMessage } = useIntl();

  /**
   * Gets an SVG icon based on the service mode.
   * @returns The icon to be displayed for a given service mode.
   */
  function getIcon() {
    switch (serviceMode) {
      case ServiceMode.EAT_IN:
        return <IconDineIn color={tokens.colors.$white} />;
      case ServiceMode.DELIVERY:
        return <IconDelivery color={tokens.colors.$white} />;
      case ServiceMode.TAKEOUT:
        return <IconCalendar color={tokens.colors.$white} />;
      default:
        return <IconCalendar color={tokens.colors.$white} />;
    }
  }
  /**
   * Gets the text to display in the badge by service mode.
   * @returns The text for a service mode.
   */
  function getLabelText() {
    switch (serviceMode) {
      case ServiceMode.EAT_IN:
        return inProgress
          ? formatMessage({ id: 'scheduleForDineIn' })
          : formatMessage({ id: 'pickupModeDineInHeading' });
      case ServiceMode.DELIVERY:
        return inProgress
          ? formatMessage({ id: 'scheduleForDelivery' })
          : formatMessage({ id: 'delivery' });
      case ServiceMode.TAKEOUT:
        return inProgress
          ? formatMessage({ id: 'scheduleForPickup' })
          : formatMessage({ id: 'pickupModeTakeoutHeading' });
      default:
        return formatMessage({ id: 'scheduleForPickup' });
    }
  }

  return (
    <Wrapper>
      <Badge>
        {getIcon()}
        <Label>{getLabelText()}</Label>
      </Badge>
    </Wrapper>
  );
};
