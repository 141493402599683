import { IGetPaymentMethodOptionsProps } from './types';

export const getPaymentMethodOptionProps = ({
  defaultPaymentMethodId,
  setDefaultPaymentMethodId,
  accountIdentifier,
}: IGetPaymentMethodOptionsProps) => {
  return {
    selected: defaultPaymentMethodId === accountIdentifier,
    onPress: () => setDefaultPaymentMethodId(accountIdentifier),
    revaultRequired: false,
  };
};
