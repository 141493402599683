import { IUsePriceOrderParams } from 'hooks/price-order/types';
import { ICommitOrderMutationInput, IOrderAddressInput } from 'remote/queries/order';
import { ServiceMode } from 'state/service-mode';

export interface ICommitOrderOptions extends ICommitOrderMutationInput {
  ccExpiry?: string | null;
  order: {
    deliveryInstructions?: string;
    orderPhoneNumber?: string;
    serviceMode: ServiceMode;
    tipAmount?: number;
    fireOrderIn?: number;
    deliveryAddress?: Partial<IOrderAddressInput>;
  };
  skipCoolingPeriod?: boolean;
}

export interface IUseCommitOrderParams extends Omit<IUsePriceOrderParams, 'cartEntries'> {
  onCompleted?(): void;
  rbiOrderId?: string | null;
}

export interface ICommitOrderInput extends Omit<ICommitOrderOptions, 'rbiOrderId'> {}
