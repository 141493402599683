import * as React from 'react';
import { useIntl } from 'react-intl';

import { Container } from '@fhs-legacy/universal-components';
import {
  StoreActionsButton,
  StoreActionsCard,
} from 'pages/store-locator/new-ui/store-actions/components';

interface IStoreActionsViewProps {
  onPress: () => void;
}

export const StoreSelectAction: React.VFC<IStoreActionsViewProps> = ({ onPress }) => {
  const { formatMessage } = useIntl();
  return (
    <Container width="full">
      <StoreActionsCard>
        <StoreActionsButton icon="add" text={formatMessage({ id: 'select' })} onPress={onPress} />
      </StoreActionsCard>
    </Container>
  );
};
