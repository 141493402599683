import React from 'react';

import { TransactionDate, TransactionDateAndTime, TransactionTime } from './transaction.styled';
import { useTransactionDateFormatters } from './utils';

export const DateAndTimeCell = ({ timeStamp }: { timeStamp: string }) => {
  const { formatTransactionDate } = useTransactionDateFormatters();
  const { date, time } = formatTransactionDate(timeStamp);

  return (
    <TransactionDateAndTime>
      <TransactionDate>{date}</TransactionDate>
      <TransactionTime>{time}</TransactionTime>
    </TransactionDateAndTime>
  );
};
