import { IActionButtonProps } from 'components/action-button';

export interface IPlaceOrderButtonProps extends IActionButtonProps {
  isLoading?: boolean;
  confirmation: boolean;
  reloadAmount?: number;
  placeOrderAriaLabel?: string;
}

export interface IShowPlaceOrderText {
  reloadAndPlaceOrder?: number;
  confirmation: boolean;
  secureOrderText: boolean;
}

export interface IPlaceOrderTextProps {
  confirmation: boolean;
  reloadAmount?: number;
}
