import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { Box, makeUclComponent } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';
import { SIGNUP_FORM_SCROLL_CONTAINER } from 'utils/test-ids';

export const SignUpHomeContainer = Box.withConfig({
  flex: 1,
  background: theme.token('background-default'),
  maxWidth: {
    base: '100%',
    legacy_sm: '480px',
  },
  marginX: {
    legacy_sm: 'auto',
  },
  _android: {
    marginTop: 6,
  },
});

export const SignUpHomeImageContainer = Box.withConfig({
  display: {
    base: 'none',
    desktop: 'unset',
  },
  flex: {
    desktop: 1,
  },
  maxWidth: {
    desktop: '50%',
  },
});

export const SignUpPanel = makeUclComponent(KeyboardAwareScrollView).withConfig({
  keyboardShouldPersistTaps: 'always',
  testID: SIGNUP_FORM_SCROLL_CONTAINER,
  marginBottom: {
    desktop: '$7',
  },
  borderBottomRadius: {
    desktop: 8,
  },
  borderTopRadius: 16,
  backgroundColor: theme.token('background-pattern'),
  contentContainerStyle: {
    padding: 28,
  },
});
