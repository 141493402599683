import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconWarningFill = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path fill="currentColor" d="M1 22.1h22l-11-19-11 19Zm12-3h-2v-2h2v2Zm0-4h-2v-4h2v4Z" />
    </Svg>
  );
};
