import { ApolloError } from '@apollo/client';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { GestureResponderEvent } from 'react-native';

import { useResendCurrentLoginOtpMutation } from 'generated/graphql-gateway';
import { HttpErrorCodes } from 'remote/constants';
import { getStoredOtpCredentials } from 'state/auth/hooks/use-account-authentication';
import { platform } from 'utils/environment';

interface IUseResendOtpProps {
  setOtpValidationError: (value: string | null) => void;
  setResendOrSendNewOtpError: (value: string | null) => void;
  setShowHasSentResentOtpMessage: (value: boolean) => void;
}

export const useResendOtp = ({
  setOtpValidationError,
  setResendOrSendNewOtpError,
  setShowHasSentResentOtpMessage,
}: IUseResendOtpProps) => {
  const { formatMessage } = useIntl();
  const { email, phoneNumber } = getStoredOtpCredentials() || {};

  const [resendCurrentLoginOtp, { loading }] = useResendCurrentLoginOtpMutation({
    onCompleted: () => {
      setResendOrSendNewOtpError(null);
      setShowHasSentResentOtpMessage(true);
    },

    onError: (error: ApolloError) => {
      switch (error?.graphQLErrors?.[0]?.extensions?.statusCode) {
        case HttpErrorCodes.TooManyRequests:
          setResendOrSendNewOtpError(formatMessage({ id: 'pleaseTryAgainLater' }));
          break;
        default:
          setResendOrSendNewOtpError(formatMessage({ id: 'authError' }));
          break;
      }
    },
  });

  const resendOtp = useCallback(
    (ev: GestureResponderEvent) => {
      ev.preventDefault();
      setShowHasSentResentOtpMessage(false);
      setOtpValidationError(null);

      return resendCurrentLoginOtp({
        variables: {
          input: {
            email,
            phoneNumber,
            platform: platform(),
          },
        },
      });
    },
    [
      email,
      phoneNumber,
      resendCurrentLoginOtp,
      setOtpValidationError,
      setShowHasSentResentOtpMessage,
    ]
  );

  return {
    resendOtp,
    isLoading: loading,
  };
};
