import React, { useMemo } from 'react';

import { useMqSelect } from '@fhs/ui';
import { FlatList, Header } from '@fhs-legacy/universal-components';
import { IMainMenuViewOption } from '@rbi-ctg/menu';
import { MenuCardsSection } from 'components/features/components/menu-cards-section';
import { Calories } from 'components/price-and-calories';
import { usePathname } from 'hooks/use-pathname';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMainMenuContext } from 'state/main-menu';
import { optionToUrl } from 'utils/menu';
import { useIsBrowseMenu } from 'utils/menu/hooks/use-is-browse-menu';
import { isSectionRoute } from 'utils/routing';

import { MenuTile } from './menu-tile';
import { getCaloriesAndPrice } from './menu-tile/helpers';
import { useCalories } from './menu-tile/hooks';
import { MainMenuObject } from './menu-tile/types';
import MenuTileGridContainer from './menu-tile-grid-container';
import { Separator } from './separator';
import {
  StyledItemPrice,
  StyledLink,
  StyledListItemCalories,
  StyledPriceAndCaloriesListWrapper,
  TileCard,
  TileFooter,
  TileImage,
} from './styled';
import { OfferTile, RewardsTile } from './tile';
import { IMenuTileGridProps } from './types';

type MenuItem = IMainMenuViewOption | { _id: string };

const MenuTileGrid = React.memo<IMenuTileGridProps>(({ data = [], gridTitle }) => {
  const { getPricingAndAvailability, isStaticMenu } = useMainMenuContext();
  const enableAdaptableMenuCards = useFlag(LaunchDarklyFlag.ENABLE_ADAPTABLE_MENU_CARDS);
  const enableOffersTileOnMenu = useFlag(LaunchDarklyFlag.ENABLE_OFFERS_TILE_ON_MENU);
  const pathname = usePathname();
  const filteredMenuData: Array<MenuItem> = useMemo(() => {
    const menuData: Array<MenuItem> = !isStaticMenu
      ? data.filter(option => getPricingAndAvailability(option._id)?.isAvailable)
      : data;
    if (!isSectionRoute(pathname)) {
      menuData.unshift({ _id: 'reward-tile' });
      if (enableOffersTileOnMenu) {
        menuData.unshift({ _id: 'offer-tile' });
      }
    }
    return menuData;
  }, [isStaticMenu, data, enableOffersTileOnMenu, pathname, getPricingAndAvailability]);

  const isMobile = useMqSelect({ $ltDesktop: true }, false);

  const { isBrowseMenu } = useIsBrowseMenu(pathname);
  const getCalories = useCalories();

  const numColumns = isMobile ? 1 : 3;
  const listKey = isMobile ? 'mobile-list' : 'desktop-list';

  return (
    <MenuTileGridContainer testID="menu-tile-grid">
      {gridTitle && <Header variant="headerThree">{gridTitle}</Header>}
      <FlatList
        margin={{ desktop: 'auto' }}
        ListHeaderComponent={enableAdaptableMenuCards ? <MenuCardsSection /> : null}
        key={listKey}
        data={filteredMenuData}
        flex={1}
        ItemSeparatorComponent={isMobile ? Separator : null}
        numColumns={numColumns}
        showsVerticalScrollIndicator={false}
        keyExtractor={(mainMenuObject: MenuItem) => mainMenuObject._id}
        renderItem={({ item }: { item: MenuItem }) => {
          if (item._id === 'offer-tile') {
            return <OfferTile />;
          }
          if (item._id === 'reward-tile') {
            return <RewardsTile />;
          }
          const menuItem = item as IMainMenuViewOption;
          const linkPath = optionToUrl(menuItem, isBrowseMenu);
          const { calories, price } = getCaloriesAndPrice(
            item as MainMenuObject,
            getCalories,
            undefined
          );
          const shouldRenderPriceAndCalories = menuItem._type !== 'section';
          let itemContent = (
            <MenuTile
              objectFitContain={false}
              showStaticMenu={false}
              item={menuItem as MainMenuObject}
            />
          );
          if (!isMobile) {
            itemContent = (
              <StyledLink to={linkPath}>
                <TileCard alignItems={'center'}>
                  <TileImage
                    isMenuItem={shouldRenderPriceAndCalories}
                    objectFitContain={true}
                    image={menuItem.image}
                    alt={menuItem.imageDescription?.locale ?? 'menu item image'}
                  />
                  <TileFooter isMenuItem={shouldRenderPriceAndCalories}>
                    {menuItem.name?.locale}
                    {shouldRenderPriceAndCalories && (
                      <StyledPriceAndCaloriesListWrapper>
                        <StyledItemPrice>{price}</StyledItemPrice>
                        <Calories>
                          <StyledListItemCalories>{calories}</StyledListItemCalories>
                        </Calories>
                      </StyledPriceAndCaloriesListWrapper>
                    )}
                  </TileFooter>
                </TileCard>
              </StyledLink>
            );
          }

          return itemContent;
        }}
      />
    </MenuTileGridContainer>
  );
});

export default MenuTileGrid;
