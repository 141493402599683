import { router } from 'expo-router';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import { useStoreClosedActionSheet } from 'components/store-closed-action-sheet';
import { IRestaurantNode } from 'generated/graphql-gateway';
import { useSelectRestaurant } from 'hooks/store-card';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode, useOrderContext } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';
import { routes } from 'utils/routing';
import { isCatering as _isCatering } from 'utils/service-mode';

import { ButtonContainer, CtaWrapper } from './styled';
import { IModalFooter } from './types';

export const ModalFooter = ({ showOrderCTA, store }: IModalFooter) => {
  const { formatMessage } = useIntl();

  const enableHideOrderHereButton = useFlag(
    LaunchDarklyFlag.ENABLE_HIDE_STORE_MODAL_ORDER_HERE_BUTTON
  );
  const enableOrderingFlag = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const enableOrdering = isNil(enableOrderingFlag) || enableOrderingFlag;

  const { fetchingPosData } = useOrderContext();
  const { restaurantCanBeSelected } = useServiceModeStatus(store);
  const { serviceMode } = useServiceModeContext();
  const isCatering = _isCatering(serviceMode);
  const orderButtonEnabled = restaurantCanBeSelected && !fetchingPosData;

  const { requestScheduleFutureOrderingConfirmation, storeClosedActionSheet } =
    useStoreClosedActionSheet({
      restaurant: store as unknown as IRestaurantNode,
      isCatering,
    });

  const { selectRestaurant } = useSelectRestaurant({ restaurant: store });
  const handleOnSelectStore = useCallback(async () => {
    if (!(await requestScheduleFutureOrderingConfirmation())) {
      router.navigate(routes.storeLocator);
      return;
    }
    if (isCatering) {
      selectRestaurant({
        requestedServiceMode: ServiceMode.CATERING_PICKUP,
      });
    } else {
      selectRestaurant();
    }
  }, [requestScheduleFutureOrderingConfirmation, isCatering, selectRestaurant]);

  if (!showOrderCTA || !enableOrdering) {
    return null;
  }

  return (
    <ButtonContainer>
      <CtaWrapper>
        {!enableHideOrderHereButton && (
          <ActionButton
            isLoading={fetchingPosData}
            testID="store-modal-order-here"
            fullWidth
            disabled={!orderButtonEnabled}
            onPress={handleOnSelectStore}
          >
            {formatMessage({ id: 'orderHere' })}
          </ActionButton>
        )}
      </CtaWrapper>
      {storeClosedActionSheet}
    </ButtonContainer>
  );
};
