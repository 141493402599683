import { Box, Header, Text } from '@fhs-legacy/universal-components';

export const ContentContainer = Box.withConfig({
  paddingY: '0',
  marginBottom: '$6',
});

export const ModalHeading = Header.withConfig({
  marginTop: '$2',
});

export const ModalBody = Text.withConfig({
  fontSize: 'md',
  marginY: '$2',
});
