import { useIsMobileBp } from 'hooks/breakpoints';
import { useFeatureLayout } from 'hooks/use-feature-layout';
import { useUIContext } from 'state/ui';

export const useCartLoadingImage = () => {
  const isMobile = useIsMobileBp();
  const { buildImageUrl } = useUIContext();
  const { featureLayout } = useFeatureLayout();

  const cartInitialLoader = featureLayout?.loadingGraphics?.cartInitialLoader;
  const graphic = isMobile ? cartInitialLoader?.mobileGraphic : cartInitialLoader?.desktopGraphic;
  const imageId = graphic?.asset?._id;
  return {
    src: imageId ? buildImageUrl(imageId) : '',
    alt: cartInitialLoader?.title?.locale || undefined,
    text: cartInitialLoader?.text?.locale,
  };
};
