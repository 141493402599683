import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconDialog = (props: IconProps) => {
  const { size = 20, ...otherProps } = props;
  return (
    <Svg width={size} height={size} fill="none" {...otherProps}>
      <Path
        fill="#fff"
        d="M16.667 1.667H3.333c-.916 0-1.666.75-1.666 1.666v15L5 15h11.667c.916 0 1.666-.75 1.666-1.667v-10c0-.916-.75-1.666-1.666-1.666Z"
      />
    </Svg>
  );
};
