import { usePathname as _usePathname, useFocusEffect } from 'expo-router';
import { useCallback, useState } from 'react';

// There is a bug in expo-router where the `usePathname` after a router.navigate
// returns the path that is being pushed, even though it isn't correlated to this page
// By using a `useFocusEffect`, this only gets triggered while the screen is primary
export function usePathname() {
  const p = _usePathname();
  const [pathname, setPathname] = useState(p);

  useFocusEffect(
    useCallback(() => {
      setPathname(p);
    }, [p])
  );

  return pathname;
}
