import { ReactNode, useCallback, useMemo } from 'react';

import { radioItemContext, useRadioGroup } from './context';

export type RadioItemProps<KeyType extends string> = {
  children: ReactNode;
  /** Identifier for the radio. This is what makes it's way to the radio group state. */
  value: KeyType;
  disabled?: boolean;
  label: string;
};

export function RadioItem<KeyType extends string>({
  value,
  children,
  disabled = false,
  label,
}: RadioItemProps<KeyType>) {
  const { value: selectedValue, onChange } = useRadioGroup();

  const selected = selectedValue === value;

  const handlePress = useCallback(() => {
    if (!selected && !disabled) {
      onChange(value);
    }
  }, [selected, onChange, value, disabled]);

  const contextValue = useMemo(
    () => ({
      value,
      selected,
      disabled,
      label,
      handlePress,
    }),
    [selected, value, disabled, label, handlePress]
  );

  return <radioItemContext.Provider value={contextValue}>{children}</radioItemContext.Provider>;
}
