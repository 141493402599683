import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconStarRateFill = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M7.99 19.907c-.308.248-.63.258-.967.029-.336-.229-.447-.534-.331-.915l1.529-4.973-3.866-2.744c-.327-.248-.428-.558-.303-.93.125-.37.38-.557.764-.557h4.847l1.53-5.116a.796.796 0 0 1 .317-.443A.856.856 0 0 1 12 4.1c.173 0 .337.052.49.157.154.105.26.252.318.443l1.529 5.116h4.847c.384 0 .64.186.764.558.125.371.024.681-.303.929l-3.866 2.744 1.53 4.973c.115.381.004.686-.332.915-.337.229-.66.22-.967-.029L12 16.877l-4.01 3.03Z"
      />
    </Svg>
  );
};
