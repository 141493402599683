import { useFeatureMenuAdaptableCardsQuery } from 'generated/sanity-graphql';

export const useAdaptableMenuCardsQuery = (featureMenuId: string) => {
  const { data: response, loading } = useFeatureMenuAdaptableCardsQuery({
    variables: { featureMenuId },
    skip: !featureMenuId,
    fetchPolicy: 'no-cache',
  });

  return { response, menuCardsLoading: loading };
};
