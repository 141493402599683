import { HStack, Text } from '@fhs-legacy/universal-components';
import Link from 'components/link';
import { theme } from 'styles/configure-theme';

export const Wrapper = HStack.withConfig({
  width: 'full',
  paddingTop: 2,
  height: 12,
  background: Styles.color.white,
});

export const TabLink = Link.withConfig<{ active?: boolean }>(p => ({
  isUnderlined: false,
  margin: 0,
  fontFamily: Styles.fontFamily.base,
  textAlign: 'center',
  textTransform: 'none',
  maxWidth: 'full',
  borderBottomWidth: 2,
  borderBottomColor: p.active ? Styles.color.primary : 'transparent',
  paddingX: 2,
  justifyContent: 'center',
  alignItems: 'center',

  _focus: {
    borderBottomColor: p.active ? Styles.color.primary : 'transparent',
  },
}));

export const TabLinkText = Text.withConfig<{ active?: boolean }>(p => ({
  fontWeight: p.active ? '700' : '400',
  variant: 'copyOne',
  marginBottom: '$3',
  color: theme.token(p.active ? 'text-active' : 'text-default'),
}));
