import { Stack, router, useLocalSearchParams } from 'expo-router';
import Head from 'expo-router/head';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { IHistoricalOrder } from '@rbi-ctg/menu';
import { useSupportData } from 'hooks/use-support-data';

import {
  IUserOrderFragment,
  RbiOrderStatus,
  useGetUserOrdersQuery,
} from '../../../generated/rbi-graphql';
import useAuth from '../../../hooks/auth/use-auth';
import { useRefundEligibility } from '../../../hooks/refund-eligibility';
import { useOrderDetails } from '../../../hooks/use-order-details';
import { routes } from '../../../utils/routing';
import { ErrorView } from '../error-view';
import { LoadingView } from '../loading-view';
import { InnerBox } from '../support-form-v2.styled';
import { ISupportPageV2Props } from '../types';

import { SupportV2OrdersListView } from './support-v2-orders-list-view';
import { getDropdownOptions, groupOrdersByYearAndMonth } from './utils';

/**
 * Displays list of a users recent orders for them to select from
 */
const SupportV2OrdersListPage: FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage, locale } = useIntl();
  // const pathname = usePathname();
  const params = useLocalSearchParams<ISupportPageV2Props>();
  const { orderId: preselectedOrderId, subcategoryId, categoryId } = params;

  const {
    order: preselectedOrder,
    loading: isPreselectedOrderLoading,
    error: preselectedOrderError,
  } = useOrderDetails(preselectedOrderId?.toString() ?? null);

  const {
    isAuthenticated,
    // setOriginLocation
  } = useAuth();

  /** Tech Debt
   * There is a similar query in the useUserOrders hook which should be updated to use useGetUserOrdersQuery as well.
   * Then this `useGetUserOrdersQuery` could be replaced with the `useUserOrders` hook, removing duplication
   */
  const { data: userOrders, loading: isLoadingOrders } = useGetUserOrdersQuery({
    variables: {
      orderStatuses: [
        RbiOrderStatus.INSERT_SUCCESSFUL,
        RbiOrderStatus.UPDATE_SUCCESSFUL,
        RbiOrderStatus.CATERING_PLACED,
        RbiOrderStatus.CATERING_SUCCESSFUL,
        RbiOrderStatus.REFUND_SUCCESSFUL,
        RbiOrderStatus.REFUND_ERROR,
        RbiOrderStatus.REFUND_REQUESTED,
      ],
      shouldFilterByRegion: true,
    },
    skip: !isAuthenticated || Boolean(preselectedOrderId),
    context: { shouldNotBatch: true },
  });
  const pageTitleText = formatMessage({ id: 'recentOrders' });
  const orders = (userOrders?.userOrders.orders || []) as IUserOrderFragment[];
  const [selectedYearAndMonth, setSelectedYearAndMonth] = useState('');
  const [selectedOrder, setSelectedOrder] = useState<IUserOrderFragment | IHistoricalOrder | null>(
    null
  );

  const { isSupportDataLoading, deliveryNeverArriveSubcategory, deliveryMissingItemsSubcategory } =
    useSupportData();

  const { isOrderEligibleForRefund, isDeterminingRefundEligibility, refundIneligibilityReason } =
    useRefundEligibility({
      orderId: selectedOrder?.rbiOrderId?.toString() || preselectedOrderId?.toString(),
    });

  useEffect(() => {
    if (preselectedOrder) {
      setSelectedOrder(preselectedOrder);
    }
  }, [preselectedOrder, setSelectedOrder]);

  /** When an order is selected and eligibility has been determined. */
  useEffect(() => {
    if (isAuthenticated && !isDeterminingRefundEligibility && !isPreselectedOrderLoading) {
      const orderId = selectedOrder?.rbiOrderId || preselectedOrderId;

      if (selectedOrder) {
        const missingItemsSubcategoryId = deliveryMissingItemsSubcategory?._id;
        const neverArrivedSubcategoryId = deliveryNeverArriveSubcategory?._id;
        // If orderId is in route, going back would send them forward again.
        const isOrderIdPreselected = Boolean(preselectedOrderId);

        const missingParams = {
          ...(categoryId && { categoryId }),
          /**
           * Still passing subcategory ID for furture combination
           * of customer initiated refund pages.
           */
          ...(subcategoryId && { subcategoryId }),
        };

        if (isOrderEligibleForRefund && subcategoryId === missingItemsSubcategoryId) {
          return router[isOrderIdPreselected ? 'replace' : 'navigate']({
            pathname: `${routes.missingItems}/${selectedOrder.rbiOrderId}`,
            params: missingParams,
          });
        } else if (isOrderEligibleForRefund && subcategoryId === neverArrivedSubcategoryId) {
          return router[isOrderIdPreselected ? 'replace' : 'navigate']({
            pathname: `${routes.missingOrder}/${selectedOrder.rbiOrderId}`,
            params: missingParams,
          });
        }
        return router[isOrderIdPreselected ? 'replace' : 'navigate']({
          pathname: routes.supportForm,
          params: {
            ...(categoryId && { categoryId }),
            ...(subcategoryId && { subcategoryId }),
            ...(orderId && { orderId }),
            alertMessage: refundIneligibilityReason.toString(),
          },
        });
      }
    }
  }, [
    isOrderEligibleForRefund,
    isDeterminingRefundEligibility,
    selectedOrder,
    deliveryMissingItemsSubcategory,
    deliveryNeverArriveSubcategory,
    refundIneligibilityReason,
    subcategoryId,
    params,
    categoryId,
    isAuthenticated,
    preselectedOrderId,
    isPreselectedOrderLoading,
  ]);

  /**
   * If the user has no orders, take them straight to the support form.
   */
  useEffect(() => {
    if (!isLoadingOrders && userOrders?.userOrders.orders?.length === 0) {
      router.replace({
        pathname: routes.supportForm,
        params: {
          ...(categoryId && { categoryId }),
          ...(subcategoryId && { subcategoryId }),
        },
      });
    }
  }, [categoryId, isLoadingOrders, subcategoryId, userOrders]);

  const ordersGroupedByYearAndMonth = useMemo(() => groupOrdersByYearAndMonth(orders), [orders]);
  const dropdownOptions = useMemo(
    () => getDropdownOptions(ordersGroupedByYearAndMonth, locale),
    [locale, ordersGroupedByYearAndMonth]
  );

  const onClickSignin = () => {
    // TODO: Expo-router; do we need this origin location?
    // setOriginLocation(appendObjectToQueryString(pathname, params));
    router.navigate(routes.signIn);
  };

  /**
   * set this value after the orders have loaded and `dropdownOptions` has values
   */
  useEffect(() => {
    if (dropdownOptions?.length) {
      setSelectedYearAndMonth(dropdownOptions[0].value);
    }
  }, [dropdownOptions]);

  const handleChangeYearAndMonth = useCallback(
    (value: string) => setSelectedYearAndMonth(value),
    [setSelectedYearAndMonth]
  );

  if (
    isLoadingOrders ||
    isSupportDataLoading ||
    isDeterminingRefundEligibility ||
    /**
     * The page should stay in a loading state waiting for
     * a redirect. order or order loading in case it errors.
     */
    preselectedOrder ||
    isPreselectedOrderLoading
  ) {
    return <LoadingView />;
  }

  if ((isAuthenticated && !userOrders?.userOrders) || preselectedOrderError) {
    return <ErrorView message={formatMessage({ id: 'looksLikeWereExperiencingIssues' })} />;
  }

  return (
    <InnerBox>
      <Stack.Screen options={{ title: 'Recent Orders' }} />
      <Head>
        <title>{`${pageTitleText} - Firehouse Subs`}</title>
      </Head>
      <SupportV2OrdersListView
        dropdownOptions={dropdownOptions}
        items={ordersGroupedByYearAndMonth[selectedYearAndMonth] || []}
        onYearAndMonthChange={handleChangeYearAndMonth}
        selectedYearAndMonth={selectedYearAndMonth}
        isAuthenticated={isAuthenticated}
        onClickSignin={onClickSignin}
        onSelectOrder={setSelectedOrder}
      />
    </InnerBox>
  );
};

export default SupportV2OrdersListPage;
