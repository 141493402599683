import { router } from 'expo-router';
import { useCallback } from 'react';

import { usePathname } from 'hooks/use-pathname';
import { useStoreContext } from 'state/store';

interface UseStoreRequiredResult {
  handleStoreLocatorRedirect: () => void;
  isStoreRequired: boolean;
  isFetchingStore: boolean;
  hasCalledSetStore: boolean;
}

export const useStoreRequired = (): UseStoreRequiredResult => {
  const { noStoreSelected, isFetchingStore, hasCalledSetStore } = useStoreContext();
  const pathname = usePathname();

  const handleStoreLocatorRedirect = useCallback(() => {
    router.replace({ pathname: '/store-locator/service-mode', params: { back: pathname } });
  }, [pathname]);

  return {
    handleStoreLocatorRedirect,
    isStoreRequired: noStoreSelected,
    isFetchingStore,
    hasCalledSetStore,
  };
};
