import { useLocalSearchParams } from 'expo-router';
import { omit } from 'lodash';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import useOneTrustSdk, {
  isOptIn,
} from 'components/layout/cookie-banner-container/one-trust-cookie-banner/use-one-trust-sdk';
import { useApiKey } from 'hooks/configs/use-api-key';
import { appendObjectToQueryString } from 'hooks/navigation/utils';
import { usePathname } from 'hooks/use-pathname';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

export default function useGoogleAnalytics() {
  const pathname = usePathname();
  const params = useLocalSearchParams();
  const googleAnalyticsKey: string = useApiKey({ key: 'googleAnalytics' });
  const { otConsentGroups, loaded: otLoaded } = useOneTrustSdk();
  const enableOneTrustCookieConsentBanner = useFlag(
    LaunchDarklyFlag.ENABLE_ONE_TRUST_COOKIE_CONSENT_BANNER
  );
  const enableGoogleAnalytics = useFlag(LaunchDarklyFlag.ENABLE_GOOGLE_ANALYTICS);

  // If the user has not opted in to any tracking, do not load GA
  // If the cookie consent banner is disabled, assume the user has opted in to tracking
  const isTrackingAllowed = enableOneTrustCookieConsentBanner
    ? isOptIn(otConsentGroups) && otLoaded
    : true;

  useEffect(() => {
    if (enableGoogleAnalytics && googleAnalyticsKey && isTrackingAllowed) {
      ReactGA.initialize(googleAnalyticsKey, {
        gaOptions: {
          allowAdPersonalizationSignals: false,
        },
        // https://developers.google.com/analytics/devguides/collection/gtagjs/display-features
        gtagOptions: {
          allow_ad_personalization_signals: false,
        },
      });
    }
  }, [enableGoogleAnalytics, googleAnalyticsKey, isTrackingAllowed]);

  useEffect(() => {
    if (enableGoogleAnalytics && googleAnalyticsKey && isTrackingAllowed) {
      const currentPath = appendObjectToQueryString(pathname, omit(params, '*'));
      ReactGA.send({ hitType: 'pageview', page: currentPath, title: currentPath });
    }
  }, [googleAnalyticsKey, pathname, params, isTrackingAllowed, enableGoogleAnalytics]);
}
