import * as React from 'react';
import { FC } from 'react';

import { makeUclComponent } from '@fhs-legacy/universal-components';
import { ISanityTextNode } from '@rbi-ctg/menu';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { StyledText } from './loyalty-text-widget.styled';

export interface ITextWidgetProps {
  text: ISanityTextNode;
}

const LoyaltyTextWidget: FC<React.PropsWithChildren<ITextWidgetProps>> = ({
  text,
}: ITextWidgetProps) => {
  const enableMyCodeNewUI = useFlag(LaunchDarklyFlag.ENABLE_MY_CODE_NEW_UI);

  return <StyledText newUI={enableMyCodeNewUI}>{text.locale}</StyledText>;
};

export default makeUclComponent(LoyaltyTextWidget);
