import { Locale } from 'date-fns';
import React, { useContext, useEffect } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { SupportedLanguages } from '@rbi-ctg/frontend';
import { ISOs } from 'utils/form/constants';
import polyfillPluralRules from 'utils/intl/polyfills/polyfill-plural-rules-locale';
import { addLoggingContext } from 'utils/logger';

import useIntlHook from './hook';
import { REGIONS } from './types';

export interface IIntlProviderContext {
  region: REGIONS;
  feCountryCode: ISOs;
  language: SupportedLanguages;
  locale: string;
  messages: Record<string, string>;
  dateFormat: Locale;
}

export const IntlContext = React.createContext<IIntlProviderContext>({} as IIntlProviderContext);

export const useLocale = () => useContext(IntlContext);

const defaultLocale = 'en-US';

export function IntlProvider({ children }: { children: React.ReactNode }) {
  const value = useIntlHook();
  useEffect(() => {
    polyfillPluralRules(value.locale);
  }, [value.locale]);

  const region = value?.region;
  useEffect(() => {
    addLoggingContext({ region });
  }, [region]);

  return (
    <IntlContext.Provider value={value}>
      <ReactIntlProvider
        defaultLocale={defaultLocale}
        locale={value.locale}
        messages={value.messages}
      >
        {children}
      </ReactIntlProvider>
    </IntlContext.Provider>
  );
}

export default IntlContext.Consumer;
