import { useFeatureLoyaltyRewardListCategoriesQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

export const useFeatureLoyaltyRewardListCategories = () => {
  const { featureLoyaltyRewardListCategoriesId = '', featureIdsLoading } = useFeaturesContext();
  const { data, loading } = useFeatureLoyaltyRewardListCategoriesQuery({
    variables: { featureLoyaltyRewardListCategoriesId },
    skip: !featureLoyaltyRewardListCategoriesId,
  });

  return {
    data: data?.RewardList?.rewardCategories,
    loading: featureIdsLoading || loading,
  };
};
