import { Text } from '@fhs-legacy/universal-components';

export const StyledRewardPointCost = Text.withConfig<{ usePrimaryColor?: boolean }>(p => ({
  textTransform: 'capitalize',
  fontWeight: Styles.fontWeight.heavy,
  ...(p.usePrimaryColor
    ? {
        color: Styles.color.primary,
      }
    : {}),
}));
