import { Path, Rect, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconPaymentTypeApplePay = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Rect width={24} height={16} y={4.101} fill="#fff" rx={2} />
      <Rect width={23} height={15} x={0.5} y={4.601} stroke="#000" strokeOpacity={0.1} rx={1.5} />
      <Path
        fill="currentColor"
        d="M13.44 8.703c-.348.42-.907.751-1.465.704-.07-.568.204-1.171.524-1.544.348-.431.96-.739 1.454-.762.058.59-.17 1.17-.512 1.602Zm.507.816c-.809-.047-1.5.467-1.885.467-.39 0-.977-.443-1.617-.431-.831.011-1.605.49-2.03 1.253-.872 1.526-.226 3.785.617 5.027.413.614.907 1.289 1.559 1.265.616-.024.86-.408 1.605-.408.75 0 .966.408 1.617.396.675-.012 1.1-.615 1.512-1.23.471-.698.663-1.378.675-1.413-.012-.012-1.303-.514-1.315-2.028-.011-1.266 1.018-1.869 1.065-1.904-.582-.876-1.49-.97-1.803-.994Z"
      />
    </Svg>
  );
};
