import { Box, FormControl, Input, Text } from '@fhs-legacy/universal-components';

export const DescriptionInput = Input.withConfig({
  width: '100%',
  borderWidth: 1,
  borderColor: Styles.color.grey.three,
  borderStyle: 'solid',

  style: {
    shadowColor: Styles.shadowColor,
    shadowOffset: {
      width: 0,
      height: 0.5,
    },
    shadowRadius: 1.5,
    shadowOpacity: 1,
  },

  color: Styles.color.secondary,
  fontFamily: Styles.fontFamily.base,
  padding: '$3',
  fontSize: 'md',
  marginTop: '$2',
}); /* TODO: RN - This was a <input> tag. Verify its styled properly*/

export const ErrorMessage = Text.withConfig({
  color: Styles.color.error,
  margin: 0,
  fontSize: 'xs',
});

export const Legend = Box.withConfig({
  _text: {
    fontSize: 'xl',
    fontWeight: 'bold',
  },

  marginTop: 0,
  marginRight: 0,
  marginBottom: '$4',
  marginLeft: 0,
  flexDir: 'row',
}); /* TODO: RN - This was a <legend> tag. Verify its styled properly*/

export const RequiredLabel = Text.withConfig({
  color: Styles.color.error,
  marginLeft: '$1',
  fontWeight: 'bold',
});

export const DescriptionContainer = FormControl.withConfig({
  borderWidth: 0,
  borderColor: 'black',
  borderStyle: 'solid',
  marginY: '$4',
}); /* TODO: RN - This was a <input> tag. Verify its styled properly*/
