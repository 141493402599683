import React from 'react';
import { useIntl } from 'react-intl';

import { useAuthContext } from 'state/auth';
import { routes } from 'utils/routing';

import { UnauthenticatedEmptyStateImage } from './images';
import {
  EmptyStateWrapper,
  HeaderText,
  ImageWrapper,
  StyledActionButtonLink,
  SubText,
} from './styled';

const LoggedOutEmptyState = () => {
  const { setOriginLocation } = useAuthContext();
  const { formatMessage } = useIntl();

  return (
    <EmptyStateWrapper>
      <ImageWrapper>
        <UnauthenticatedEmptyStateImage />
      </ImageWrapper>
      <HeaderText>{formatMessage({ id: 'unauthenticatedRecentItemsHeader' })}</HeaderText>
      <SubText>{formatMessage({ id: 'unauthenticatedRecentItemsSubtext' })}</SubText>
      <StyledActionButtonLink to={routes.signIn} onPress={() => setOriginLocation('GO_BACK')}>
        {formatMessage({ id: 'logIn' })}
      </StyledActionButtonLink>
    </EmptyStateWrapper>
  );
};

export default LoggedOutEmptyState;
