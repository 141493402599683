import { Reducer, useEffect, useReducer } from 'react';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { parseStringifiedJSON } from 'utils/parse-string';

import {
  IOffersFilters,
  OfferFilterAction,
  OfferFiltersActionType,
  OffersServiceMode,
} from './types';

const initialState: IOffersFilters = {
  [OffersServiceMode.PICKUP_ONLY]: false,
  [OffersServiceMode.DELIVERY_ONLY]: false,
  inStoreEnabled: false,
};

const getAppliedOffersFiltersFromStorage = () => {
  const storedAppliedOffers = LocalStorage.getItem(StorageKeys.LOYALTY_OFFERS_APPLIED_FILTERS);
  return parseStringifiedJSON({ value: storedAppliedOffers, defaultValue: initialState }) || {};
};

const updateAppliedOffersFiltersInStorage = (appliedFilters: IOffersFilters) => {
  LocalStorage.setItem(StorageKeys.LOYALTY_OFFERS_APPLIED_FILTERS, JSON.stringify(appliedFilters));
};

const reducer: Reducer<IOffersFilters, OfferFilterAction> = (
  state: IOffersFilters,
  action: OfferFilterAction
) => {
  let newState = state;
  switch (action.type) {
    case OfferFiltersActionType.TOGGLE_PICKUP:
      newState = {
        ...state,
        [OffersServiceMode.PICKUP_ONLY]: !state[OffersServiceMode.PICKUP_ONLY],
      };
      break;
    case OfferFiltersActionType.TOGGLE_DELIVERY:
      newState = {
        ...state,
        [OffersServiceMode.DELIVERY_ONLY]: !state[OffersServiceMode.DELIVERY_ONLY],
      };
      break;
    case OfferFiltersActionType.TOGGLE_INSTORE:
      newState = {
        ...state,
        inStoreEnabled: !state.inStoreEnabled,
      };
      break;
    case OfferFiltersActionType.SELECT_PICKUP:
      newState = {
        ...initialState,
        pickUpOnly: true,
      };
      break;
    case OfferFiltersActionType.SELECT_DELIVERY:
      newState = {
        ...initialState,
        deliveryOnly: true,
      };
      break;
    default:
      newState = state;
      break;
  }
  updateAppliedOffersFiltersInStorage(newState);
  return newState;
};

export const useLoyaltyOffersFilters = () => {
  const enableLoyaltyOffersFilters = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_OFFERS_FILTERS);
  const [appliedFilters, toggleFilter] = useReducer(
    reducer,
    initialState,
    getAppliedOffersFiltersFromStorage
  );
  const { serviceMode } = useServiceModeContext();

  useEffect(() => {
    if (serviceMode) {
      // Toggle Delivery Filter ON
      if (serviceMode === ServiceMode.DELIVERY) {
        toggleFilter({ type: OfferFiltersActionType.SELECT_DELIVERY });
      }

      // Toggle PickUp Filter ON
      if (
        [
          ServiceMode.CURBSIDE,
          ServiceMode.DRIVE_THRU,
          ServiceMode.EAT_IN,
          ServiceMode.TAKEOUT,
        ].includes(serviceMode)
      ) {
        toggleFilter({ type: OfferFiltersActionType.SELECT_PICKUP });
      }
    }
  }, [serviceMode]);

  return {
    // returning with a conditional flag to use the right filter if a service mode was selected already
    appliedFilters: enableLoyaltyOffersFilters ? appliedFilters : initialState,
    toggleFilter,
  };
};
