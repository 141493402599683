import React from 'react';

import { Box, HStack, Icon } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

import UnstyledButton from '../unstyled-button';

import localeTheme from './theme';

const Container = UnstyledButton.withConfig(() => ({
  paddingY: '5',
  borderBottomWidth: '1px',
  borderBottomColor: Styles.color.grey.four,
}));

const MainContent = Box.withConfig(() => ({
  width: '90%',
  _text: {
    ...(localeTheme.arrowButtonColor && { color: localeTheme.arrowButtonColor }),
    textAlign: 'left',
    textDecoration: 'none',
    isTruncated: true,
    noOfLines: 1,
  },
}));

export const ArrowButton: React.FC<
  React.PropsWithChildren<React.ComponentProps<typeof UnstyledButton>>
> = ({ children, ...props }) => (
  <Container {...props}>
    <HStack justifyContent="space-between" alignItems="center">
      <MainContent>{children}</MainContent>
      <Icon variant="next" color={theme.token('icon-default')} accessible={false} />
    </HStack>
  </Container>
);
