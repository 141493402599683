import React from 'react';

import { View } from '@fhs-legacy/universal-components';
import { BrightEdgeContainer } from 'components/bright-edge-container';
import { WEB_APP_DESKTOP_LG_MAX_WIDTH } from 'utils/constants';

import { Row } from './shared/row';
import { IRow as IRowProps } from './shared/types';
import { useFeatureFooter } from './use-feature-footer';

const FooterContainer = View.withConfig({
  background: 'token.background-footer',
});

const FooterInnerContainer = View.withConfig({
  paddingX: '$4',
  paddingTop: { desktop: '$2' },
  width: { base: 'full', desktop: WEB_APP_DESKTOP_LG_MAX_WIDTH },
  mx: 'auto',
});

const FooterContent = () => {
  const { featureFooter, featureFooterLoading } = useFeatureFooter();
  return featureFooterLoading ? null : (
    <FooterContainer>
      <FooterInnerContainer>
        <BrightEdgeContainer />
        {featureFooter?.rows?.map(
          (row: IRowProps, index: number) => row && <Row key={row._key ?? index} {...row} />
        )}
      </FooterInnerContainer>
    </FooterContainer>
  );
};

export default FooterContent;
