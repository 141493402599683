import { G, Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconDriveThru = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <G fill="currentColor">
        <Path
          fillRule="evenodd"
          d="M20.78 8.412h-.538c-.268 0-.537.134-.537.537v1.073c0 .269.269.537.537.537h.94v8.992c0 .268.268.537.537.537h.536c.269 0 .537-.269.537-.537v-8.992h.671c.269 0 .537-.268.537-.537v-.537c0-.268-.268-.536-.537-.536l-2.684-.537Z"
          clipRule="evenodd"
        />
        <Path d="M15.92 5.11c-.2-.59-.76-1.01-1.42-1.01h-11c-.66 0-1.21.42-1.42 1.01L0 11.1v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99ZM3.5 15.1c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5Zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5ZM2 10.1l1.5-4.5h11l1.5 4.5H2Z" />
      </G>
    </Svg>
  );
};
