import React, { createContext, useContext, useEffect, useState } from 'react';

import { IBaseProps, SetState } from '@rbi-ctg/frontend';
import { usePathname } from 'hooks/use-pathname';
import { useAuthContext } from 'state/auth';
import noop from 'utils/noop';

export interface IReversedUIContext {
  reversedUI: boolean;
  setReversedUI: SetState<boolean>;
}

export const ReversedUIContext = createContext<IReversedUIContext>({
  reversedUI: false,
  setReversedUI: noop,
});

export const ReversedUIProvider = ({ children }: IBaseProps) => {
  const { isAuthenticated } = useAuthContext();
  const isHome = usePathname() === '/';

  const [reversedUI, setReversedUI] = useState(false);

  useEffect(() => {
    if (!isHome || !isAuthenticated) {
      setReversedUI(false);
    }
  }, [isAuthenticated, isHome]);

  return (
    <ReversedUIContext.Provider
      value={{
        reversedUI,
        setReversedUI,
      }}
    >
      {children}
    </ReversedUIContext.Provider>
  );
};

export const useReversedUIContext = () => useContext(ReversedUIContext);
