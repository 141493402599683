import { Link } from 'expo-router';
import { StyleSheet, View } from 'react-native';

import { IconInfoFill, Text, XStack, tokens } from '@fhs/ui';

import { HomeAlertProps } from './types';

export function HomeAlert({ text, link, ...xStackProps }: HomeAlertProps) {
  return (
    <XStack {...xStackProps} style={[styles.container, xStackProps.style]}>
      <View>
        <IconInfoFill size={20} color="white" />
      </View>
      <View style={styles.messageContainer}>
        <Text style={styles.text}>{text}</Text>
      </View>
      {link && (
        <View style={styles.action} testID="mobile-home-alert-link">
          <Link href={link.href}>
            <Text style={[styles.text, styles.actionText]}>{link.text}</Text>
          </Link>
        </View>
      )}
    </XStack>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: tokens.colors.$houseDark,
    paddingHorizontal: 16,
    paddingVertical: 12,
    gap: 8,
    justifyContent: 'flex-start',
    maxWidth: '100%',
    alignItems: 'center',
  },
  messageContainer: {
    flexGrow: 1,
    flexShrink: 1,
    wordWrap: 'wrap',
  },
  text: { color: tokens.colors.$white },
  action: {
    marginLeft: 'auto',
  },
  actionText: {
    textDecorationLine: 'underline',
  },
});
