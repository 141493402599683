import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { IBaseProps } from '@rbi-ctg/frontend';
import { useAuthContext } from 'state/auth';
import { useDayPartContext } from 'state/day-part';
import { useNetworkContext } from 'state/network';
import { useServiceModeContext } from 'state/service-mode';

import useOffers from './hooks/use-offers';

export { RedemptionMethod, Status } from './hooks/use-offer-redemption';

export type OffersValue = Omit<ReturnType<typeof useOffers>, 'OfferUnavailableDialog'>;

export const OffersContext = React.createContext<OffersValue>({} as OffersValue);
export const useOffersContext = () => useContext<OffersValue>(OffersContext);

export const OffersProvider: React.FC<React.PropsWithChildren<IBaseProps>> = props => {
  const { user } = useAuthContext();
  const { activeDayParts } = useDayPartContext();
  const { query } = useNetworkContext();
  const { serviceMode } = useServiceModeContext();
  const { formatMessage } = useIntl();

  const {
    OfferUnavailableDialog,
    clearSelectedOffer,
    isSelectedOfferCartEntry,
    loading,
    offers,
    offersFeedback,
    offerValidationErrors,
    lockedOffers,
    onCancelRedemption,
    onConfirmRedemption,
    onSelectMobileRedemption,
    onSelectRestaurantRedemption,
    openOfferUnavailableDialog,
    promoCodeOffers,
    togglePromoCodeOfferRedeemable,
    cartPromoCodeOffers,
    redemptionMethod,
    redeemedOffer,
    setRedeemedOffer,
    refetchOffersList,
    selectOfferById,
    selectedOffer,
    selectedOfferCartEntry,
    selectedOfferPrice,
    setOfferValidationErrors,
    setRedemptionMethod,
    setSelectedOfferCartEntry,
    setSelectedOfferPrice,
    queryRedeemableOffers,
    validateOfferRedeemable,
    refreshOffers,
    activateOffer,
    redemptionInProgress,
    setRedemptionInProgress,
    setUpsellOfferId,
    upsellOfferId,
    updatingOffers,
  } = useOffers({
    activeDayParts,
    query,
    serviceMode,
    user,
  });

  const context = useMemo(
    () => ({
      clearSelectedOffer,
      isSelectedOfferCartEntry,
      loading,
      offers,
      offersFeedback,
      offerValidationErrors,
      lockedOffers,
      onCancelRedemption,
      onConfirmRedemption,
      onSelectMobileRedemption,
      onSelectRestaurantRedemption,
      openOfferUnavailableDialog,
      promoCodeOffers,
      togglePromoCodeOfferRedeemable,
      cartPromoCodeOffers,
      redemptionMethod,
      redeemedOffer,
      setRedeemedOffer,
      refetchOffersList,
      selectOfferById,
      selectedOffer,
      selectedOfferCartEntry,
      selectedOfferPrice,
      setOfferValidationErrors,
      setRedemptionMethod,
      setSelectedOfferCartEntry,
      setSelectedOfferPrice,
      queryRedeemableOffers,
      validateOfferRedeemable,
      refreshOffers,
      activateOffer,
      redemptionInProgress,
      setRedemptionInProgress,
      setUpsellOfferId,
      upsellOfferId,
      updatingOffers,
    }),
    [
      clearSelectedOffer,
      isSelectedOfferCartEntry,
      loading,
      offers,
      offersFeedback,
      offerValidationErrors,
      lockedOffers,
      onCancelRedemption,
      onConfirmRedemption,
      onSelectMobileRedemption,
      onSelectRestaurantRedemption,
      openOfferUnavailableDialog,
      promoCodeOffers,
      togglePromoCodeOfferRedeemable,
      cartPromoCodeOffers,
      redemptionMethod,
      redeemedOffer,
      setRedeemedOffer,
      refetchOffersList,
      selectOfferById,
      selectedOffer,
      selectedOfferCartEntry,
      selectedOfferPrice,
      setOfferValidationErrors,
      setRedemptionMethod,
      setSelectedOfferCartEntry,
      setSelectedOfferPrice,
      queryRedeemableOffers,
      validateOfferRedeemable,
      refreshOffers,
      activateOffer,
      redemptionInProgress,
      setRedemptionInProgress,
      setUpsellOfferId,
      upsellOfferId,
      updatingOffers,
    ]
  );

  return (
    <OffersContext.Provider value={context}>
      {props.children}

      <OfferUnavailableDialog
        body={formatMessage({ id: 'couponErrorBody' })}
        confirmLabel={formatMessage({ id: 'couponErrorConfirm' })}
        heading={formatMessage({ id: 'couponErrorHeading' })}
      />
    </OffersContext.Provider>
  );
};

export default OffersContext.Consumer;
