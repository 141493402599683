import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { shallow } from '@fhs/zustand';

import { useMediaQueries } from './store';
import type {
  MediaQueriesStoreState,
  MediaQueryKey,
  NamedResolvedStyles,
  NamedStylesWithQueries,
  StyleType,
} from './types';

type UseStylesHookType<T> = () => NamedResolvedStyles<T>;

// Returns a hook that resolves styles based on the matching media queries.
// For example:
//
// ```
// const useMqStyles = createMqStyles({
//   wrapper: {
//     // Media Queries take precendence based on the order they are defined. The earlier in the block, the lower precedence.
//     // For example if you define a $md style object followed by a $gtSm style object. When you are at the $md breakpoint,
//     // all properties from $gtSm that conflict with $md will override $md. This is equivalent to
//     // <Component style={[$mdStyles, $gtSmStyles]} />
//
//     $base: {
//       // $base is the basic media query. It is always true.
//       backgroundColor: 'red',
//       marginLeft: 20
//     },
//
//     $sm: {
//       // since we defined $sm after $base when we mach $sm
//       // we will override $base's backgroundColor with our own.
//       backgroundColor: 'blue',
//
//       // since we did not define a `marginLeft` property, it will come through from $base since both queries
//       will apply at the $sm breakpoint.
//     }
//   },
//   text: {
//     $base: {
//       fontSize: 20,
//     }
//   }
// });
//
// const OurComponent = (props) => {
//   const styles = useStyles();
//   return (
//     <View style={styles.wrapper}>
//       <Text style={styles.text}>
//         Hello World!
//       </Text>
//    </View>
//   );
// };
// ```
export function createMqStyles<T>(styles: NamedStylesWithQueries<T>): UseStylesHookType<T> {
  const names = Object.keys(styles) as Array<keyof typeof styles>;
  const mediaQueryKeyTrackerSet = new Set<MediaQueryKey>();

  const registeredStyles = {} as NamedStylesWithQueries<T>;

  for (const name of names) {
    // Create a separate stylesheet for each name where the name in the sheet is the queryKey
    // for example if we have a name of `someClass: { $base: { backgroundColor: 'red' }, $md: { backgroundColor: 'blue' } }`
    // the names in the sheet are `$base` and $md.
    registeredStyles[name] = StyleSheet.create(styles[name]);

    const queryKeys = Object.keys(styles[name]) as Array<MediaQueryKey>;

    // Keep track of which media query keys we actually care about.
    // This lets us optimize our selector so we only rerender when the specific media queries
    // we care about have changed.
    for (const queryKey of queryKeys) {
      mediaQueryKeyTrackerSet.add(queryKey);
    }
  }

  function selectActiveMediaQueries(mediaQueryStoreState: MediaQueriesStoreState) {
    const result: Array<MediaQueryKey> = [];

    for (const mediaQueryKey of mediaQueryKeyTrackerSet) {
      if (mediaQueryStoreState[mediaQueryKey]) {
        result.push(mediaQueryKey);
      }
    }

    return result;
  }

  return function useMqStyles() {
    // With the useMediaQueries hook and selector
    // this will only rerender when a media query we were tracking
    // changes
    const activeQueries = useMediaQueries(selectActiveMediaQueries, shallow);

    return useMemo(() => {
      const resolved = {} as NamedResolvedStyles<T>;
      const activeQuerySet = new Set(activeQueries);

      for (const name of names) {
        resolved[name] = [] as Array<StyleType>;

        // We have to iterate over the queries as they are defined instead of only the active queries
        // so we preserve the order.
        // This makes it predictable during author time which properties will take precedence.
        const queriesForName = Object.keys(registeredStyles[name]) as Array<
          keyof (typeof registeredStyles)[typeof name]
        >;

        for (const query of queriesForName) {
          if (!activeQuerySet.has(query as MediaQueryKey)) {
            // Query doesn't match current state so we ignore.
            continue;
          }

          const registeredStyleForQuery = registeredStyles[name][query];
          if (registeredStyleForQuery) {
            resolved[name].push(registeredStyleForQuery);
          }
        }
      }

      return resolved;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeQueries.toString()]);
  };
}
