import { router } from 'expo-router';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

import { Text } from '@fhs-legacy/universal-components';
import { IBackendCartEntries, IServerOrder } from '@rbi-ctg/menu';
import OrderCancelledModal from 'components/order-cancelled-modal';
import { useConfigValue } from 'hooks/configs/use-config-value';
import useDialogModal from 'hooks/use-dialog-modal';
import useErrorModal from 'hooks/use-error-modal';
import { useEtaCounter } from 'hooks/use-eta-counter/';
import useOrderNumber from 'hooks/use-order-number';
import usePolling from 'hooks/use-polling';
import { getFeesByGroup, getStandardizedName } from 'pages/cart/your-cart/totals/utils';
import { CartEntry } from 'pages/receipt/cart-entry';
import getRewardsDiscount from 'pages/receipt/receipt-details/get-rewards-discount';
import fetchDeliveryStatus from 'remote/api/delivery-status';
import { useDeliveryConfirmationContext } from 'state/delivery-confirmation';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { DeliveryStatus } from 'state/order/types';
import { CASH_ACCOUNT_IDENTIFIER } from 'state/payment/constants';
import { ServiceMode } from 'state/service-mode/types';
import { useUIContext } from 'state/ui';
import { theme } from 'styles/configure-theme';
import { computeDeliveryFee } from 'utils/cart/helper';
import { readableHour } from 'utils/restaurant';
import { TWELVE_HOUR_TIME_PARSE_FORMAT } from 'utils/restaurant/constants';
import { routes } from 'utils/routing';

import { CashPaymentReminder } from '../cash-payment-reminder';

import CourierDetail from './courier-detail';
import DeliveryProgress from './delivery-progress';
import {
  Card,
  CardHeading,
  CardText,
  DeliveryCardContainer,
  ETA,
  FeeDeliveryItem,
  TextGroup,
  TotalRow,
} from './styled';

interface IDeliveryDetailsProps {
  serverOrder: IServerOrder;
}

// this list is not exhaustive, more statuses can be added
const failedDeliveryStatuses = [
  DeliveryStatus.ORDER_TIMEOUT,
  DeliveryStatus.ORDER_ERROR,
  DeliveryStatus.ORDER_CANCELLED,
];

const DeliveryDetails: React.FC<React.PropsWithChildren<IDeliveryDetailsProps>> = ({
  serverOrder,
}) => {
  const { delivery, rbiOrderId } = serverOrder;
  const [deliveryStatus, setDeliveryStatus] = useState(delivery!.status);
  const [driver, setDriver] = useState(delivery!.driver || null);
  const [dropoff, setDropoff] = useState(delivery?.dropoff);
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale } = useUIContext();
  const eta = useEtaCounter(deliveryStatus, dropoff);
  const { setBringgUuids } = useDeliveryConfirmationContext();
  const orderNumber = useOrderNumber(serverOrder);
  const enableHideTaxLine = useFlag(LaunchDarklyFlag.ENABLE_HIDE_TAX_LINE);
  const hideTipAmount = useFlag(LaunchDarklyFlag.HIDE_TIP_AMOUNT_CHECKOUT);
  const enableDiscountsOnOrderReceipt = useFlag(LaunchDarklyFlag.ENABLE_DISCOUNTS_ON_ORDER_RECEIPT);
  const enableFeesOnOrderReceipt = useFlag(LaunchDarklyFlag.ENABLE_FEES_ON_ORDER_RECEIPT);
  const geographicalFeeLabelIdMap = { CO: 'geographicalFeeCO' };
  const dropoffState = delivery?.dropoff.state;

  const rewardsDiscountCents = useMemo(
    () =>
      getRewardsDiscount({
        discounts: serverOrder.cart.discounts || [],
        enableDiscountsOnOrderReceipt,
      }),
    [serverOrder, enableDiscountsOnOrderReceipt]
  );

  const fees = serverOrder.cart.fees ?? [];
  const itemFees = getFeesByGroup(fees);

  // Use a custom label for geographical delivery fees as required
  const geographicalFeeLabelId =
    dropoffState && geographicalFeeLabelIdMap.hasOwnProperty(dropoffState)
      ? geographicalFeeLabelIdMap[dropoffState]
      : 'geographicalFee';

  const timeFormatConfig = useConfigValue({
    key: 'timeFormat',
    defaultValue: TWELVE_HOUR_TIME_PARSE_FORMAT,
  });
  const { stopPolling } = usePolling({
    queryOptions: fetchDeliveryStatus(rbiOrderId),
    interval: 5000,
    retry: 3,
    onResponse: data => {
      setDropoff(data?.delivery?.dropoff);
      setDeliveryStatus(data?.delivery?.status);
      setDriver(data?.delivery?.driver);
      // Needed to track bringg delivery driver coords
      const share_uuid = data?.delivery?.shareUuid;
      const order_uuid = data?.delivery?.task?.uuid;
      setBringgUuids({ share_uuid, order_uuid });
    },
    onError: () => {
      openErrDialog({
        message: 'There was an error fetching your delivery status. Please try again later.',
      });
    },
  });

  const [ErrorDialog, openErrDialog] = useErrorModal({
    onConfirm: () => router.navigate(routes.orders),
    modalAppearanceEventMessage: 'Error: Fetching Delivery Details Failure',
  });

  const [CancellationModal, openCancellationModal] = useDialogModal({
    // @ts-expect-error TS(2322) FIXME: Type 'FC<IOrderCancelledModalProps>' is not assign... Remove this comment to see the full error message
    Component: OrderCancelledModal,
    onOpen: stopPolling,
    onDismiss: () => router.navigate(routes.base),
  });

  useEffect(() => {
    if (deliveryStatus === DeliveryStatus.ORDER_CANCELLED) {
      openCancellationModal();
    }
  }, [deliveryStatus, openCancellationModal]);

  if (!delivery) {
    return null;
  }

  const cardType = serverOrder?.cart?.payment?.cardType || null;
  const ccLast4 = serverOrder?.cart?.payment?.ccLast4 || null;

  const feeCents = delivery?.feeCents || 0;
  const feeDiscountCents = delivery?.feeDiscountCents || 0;
  const geographicalFeeCents = delivery?.geographicalFeeCents || 0;
  const serviceFeeCents = delivery.serviceFeeCents || 0;
  const smallCartFeeCents = delivery?.smallCartFeeCents || 0;
  const deliveryFeeCents = computeDeliveryFee({
    feeCents,
    feeDiscountCents,
    geographicalFeeCents,
    serviceFeeCents,
    smallCartFeeCents,
  });
  const isFreeDelivery = deliveryFeeCents === 0;
  const DeliveryFee = () =>
    isFreeDelivery ? (
      <FeeDeliveryItem>
        {formatMessage({ id: 'free' })}
        &nbsp;<Text textDecoration="line-through">{formatCurrencyForLocale(feeDiscountCents)}</Text>
      </FeeDeliveryItem>
    ) : (
      <CardText>{formatCurrencyForLocale(deliveryFeeCents)}</CardText>
    );

  const getDeliveryEtaText = (status: DeliveryStatus): string => {
    switch (status) {
      case DeliveryStatus.DRIVER_AT_CUSTOMER:
        return formatMessage({ id: 'deliveryDriverArrived' });
      case DeliveryStatus.ORDER_DROPPED_OFF:
        return formatMessage({ id: 'deliveryDelivered' });
      default:
        return eta || '...';
    }
  };

  const isCashPayment = cardType === CASH_ACCOUNT_IDENTIFIER;
  const showCashPaymentMessage = isCashPayment && !failedDeliveryStatuses.includes(deliveryStatus);

  // TODO: check for cancelled orders and orders that have already been delivered
  return (
    <>
      {showCashPaymentMessage && <CashPaymentReminder serviceMode={ServiceMode.DELIVERY} />}
      <DeliveryCardContainer backgroundColor={theme.token('background-default')}>
        <Card>
          <ETA>
            {formatMessage({ id: 'eta' })}:{' '}
            <Text testID="order-preparation-confirmation-eta">
              {getDeliveryEtaText(deliveryStatus)}
            </Text>
          </ETA>

          <CardHeading testID="order-preparation-confirmation" data-private>
            {formatMessage({ id: 'deliveringToYourDoorAt' })}
            {'\n'}
            {delivery.dropoff.addressLine1}
          </CardHeading>
          <DeliveryProgress deliveryStatus={deliveryStatus} />
          {driver && <CourierDetail driver={driver} />}
        </Card>

        <Card>
          <CardHeading>{formatMessage({ id: 'orderDetailsHeading' })}</CardHeading>
          <TextGroup>
            {serverOrder.cart?.ticketNumber ? (
              <CardText>
                {formatMessage({ id: 'orderNumber' })}: {orderNumber}
              </CardText>
            ) : null}
            {cardType && ccLast4 && (
              <CardText>
                {formatMessage(
                  { id: 'paymentXEndingInY' },
                  {
                    cardType,
                    ccLast4,
                  }
                )}
              </CardText>
            )}
            <CardText>
              {formatMessage({ id: 'orderTime' })}:{' '}
              {readableHour({
                timeString: serverOrder.createdAt,
                ignoreMdmTimeParse: true,
                timeFormat: timeFormatConfig,
              })}
            </CardText>
          </TextGroup>

          <CardHeading>{formatMessage({ id: 'deliveryInfo' })}</CardHeading>
          <TextGroup>
            <CardText testID="order-confirmation-customer-name" data-private fontWeight="bold">
              {serverOrder.cart.customerName}
            </CardText>
            <CardText testID="order-confirmation-dropoff-address" data-private>
              {delivery.dropoff.addressLine1}
              {'\n'}
              {delivery.dropoff.addressLine2 && (
                <>
                  {formatMessage(
                    { id: 'unitNumber' },
                    { unitNumber: delivery.dropoff.addressLine2 }
                  )}
                  {'\n'}
                </>
              )}
              {delivery.dropoff.city}, {delivery.dropoff.state} {delivery.dropoff.zip}
            </CardText>
            {delivery.dropoff.phoneNumber && (
              <CardText testID="order-confirmation-phone-number" data-private>
                {delivery.dropoff.phoneNumber}
              </CardText>
            )}
            {delivery.instructions && (
              <CardText testID="order-confirmation-instructions" data-private marginTop={4}>
                {formatMessage({ id: 'instructions' })}: {delivery.instructions}
              </CardText>
            )}
          </TextGroup>

          <CardHeading>{formatMessage({ id: 'yourOrder' })}</CardHeading>
          <TextGroup>
            <View>
              {serverOrder.cart.cartEntries.map((cartEntry: IBackendCartEntries, idx: number) => (
                <CartEntry
                  currencyFormatter={formatCurrencyForLocale}
                  entry={cartEntry}
                  key={cartEntry.name! + idx}
                  index={idx}
                />
              ))}
            </View>
          </TextGroup>

          {enableFeesOnOrderReceipt &&
            !!itemFees &&
            Object.entries(itemFees).map(([name, entryCents]: [string, number], index) => (
              <TotalRow key={index}>
                <CardText>{formatMessage({ id: `${getStandardizedName({ name })}Fee` })}</CardText>

                <CardText>{formatCurrencyForLocale(entryCents)}</CardText>
              </TotalRow>
            ))}
          {rewardsDiscountCents !== 0 && (
            <TotalRow>
              <CardText>
                {formatMessage({
                  id: 'rewardSavings',
                })}
              </CardText>
              <CardText>{formatCurrencyForLocale(-rewardsDiscountCents)}</CardText>
            </TotalRow>
          )}
          <TotalRow>
            <CardText>{formatMessage({ id: 'subtotal' })}</CardText>
            <CardText>{formatCurrencyForLocale(serverOrder.cart.subTotalCents)}</CardText>
          </TotalRow>
          {!enableHideTaxLine && (
            <TotalRow>
              <CardText>{formatMessage({ id: 'tax' })}</CardText>
              <CardText>{formatCurrencyForLocale(serverOrder.cart.taxCents)}</CardText>
            </TotalRow>
          )}
          <TotalRow>
            <CardText>{formatMessage({ id: 'deliveryFee' })}</CardText>
            <DeliveryFee />
          </TotalRow>
          {geographicalFeeCents > 0 && (
            <TotalRow>
              <CardText>{formatMessage({ id: geographicalFeeLabelId })}</CardText>
              <CardText>{formatCurrencyForLocale(geographicalFeeCents)}</CardText>
            </TotalRow>
          )}
          {serviceFeeCents > 0 && (
            <TotalRow>
              <CardText>{formatMessage({ id: 'serviceFee' })}</CardText>
              <CardText>{formatCurrencyForLocale(serviceFeeCents)}</CardText>
            </TotalRow>
          )}
          {smallCartFeeCents > 0 && (
            <TotalRow>
              <CardText>{formatMessage({ id: 'smallCartFee' })}</CardText>
              <CardText>{formatCurrencyForLocale(smallCartFeeCents)}</CardText>
            </TotalRow>
          )}
          {!hideTipAmount && (
            <TotalRow testID="tipCents">
              <CardText>{formatMessage({ id: 'tip' })}</CardText>
              <CardText>{formatCurrencyForLocale(delivery.tipCents)}</CardText>
            </TotalRow>
          )}
          <TotalRow marginTop={4}>
            <CardText fontWeight="bold">{formatMessage({ id: 'total' })}</CardText>
            <CardText fontWeight="bold">
              {formatCurrencyForLocale(serverOrder.cart.totalCents)}
            </CardText>
          </TotalRow>
        </Card>
      </DeliveryCardContainer>
      <ErrorDialog />
      <CancellationModal />
    </>
  );
};

export default DeliveryDetails;
