import { isSSG } from 'utils/environment';
import noop from 'utils/noop';

const Braze = isSSG ? null : require('@braze/web-sdk');

/**
 * Braze methods don't work on SSG since they use many references to DOM and navigator object
 */
const noopIfSsg = <Fn>(fn: Fn): Fn => (isSSG ? (noop as Fn) : fn);

export const changeUser = noopIfSsg((userId: string, signature?: string) => {
  try {
    // This is resulting in an un catch error in the Braze Web SDK
    Braze?.changeUser(userId, signature);
  } catch (error) {}
  try {
    Braze?.openSession();
  } catch (error) {}
});

export const initializeBraze: typeof Braze.initialize = noopIfSsg((apiKey, options) => {
  try {
    const initResult = Braze?.initialize(apiKey, options);
    Braze?.automaticallyShowInAppMessages();
    Braze?.openSession();
    return initResult;
  } catch (error) {
    return null;
  }
});
export const openBrazeSession = noopIfSsg(Braze?.openSession);
export const destroyBraze = noopIfSsg(Braze?.destroy);
export const wipeBrazeData = noopIfSsg(Braze?.wipeData);
export const setLanguage = noopIfSsg((language: string | null) => {
  Braze?.getUser()?.setLanguage(language);
});
export const setFirstName = noopIfSsg((name: string) => {
  Braze?.getUser()?.setFirstName(name);
});
export const setPhoneNumber = noopIfSsg((number: string) => {
  Braze?.getUser()?.setPhoneNumber(number);
});
export const setEmail = noopIfSsg((email: string) => {
  Braze?.getUser()?.setEmail(email);
});
export const setDateOfBirth = noopIfSsg((year: number, month: number, day: number) => {
  Braze?.getUser()?.setDateOfBirth(year, month, day);
});
export const setCustomUserAttribute = noopIfSsg(
  (key: string, value: string | number | boolean | object | Date | null) => {
    Braze?.getUser()?.setCustomUserAttribute(key, value);
  }
);

export const logCustomEvent = noopIfSsg(Braze?.logCustomEvent);
export const logPurchase = noopIfSsg(Braze?.logPurchase);

export const requestImmediateDataFlush = noopIfSsg(Braze?.requestImmediateDataFlush);
export const enableBrazeSdk = noopIfSsg(Braze?.enableSDK);
export const disableBrazeSdk = noopIfSsg(Braze?.disableSDK);
