import * as React from 'react';
import { FC } from 'react';

import { Box } from '@fhs-legacy/universal-components';

import { Footer, Group, HeaderText, HeaderWrapper, SubHeaderText } from './styled';
import { IListItemGroupProps } from './types';

/**
 *
 * ListItemGroup provides a consistent layout to wrap list item rows in a header and footer
 *
 */
const ListItemGroup: FC<React.PropsWithChildren<IListItemGroupProps>> = ({
  items,
  header,
  headerAction,
  subHeader,
  footer,
  isNested,
  ItemSeparator,
  testID = 'list-item-group',
}) => {
  if (!items?.length) {
    return null;
  }

  return (
    <Group testID={testID}>
      {(header || headerAction) && (
        <HeaderWrapper>
          <HeaderText $isNested={isNested} testID="list-item-group-header">
            {header}
          </HeaderText>
          {headerAction}
        </HeaderWrapper>
      )}
      {subHeader && <SubHeaderText>{subHeader}</SubHeaderText>}
      <Box testID="list-item-group-items">
        {items.map((item, index) => (
          <Box key={`list-item-group-items-${index}`}>
            {item}
            {index < items.length - 1 && ItemSeparator}
          </Box>
        ))}
        {footer ? <Footer testID="list-item-group-footer">{footer}</Footer> : undefined}
      </Box>
    </Group>
  );
};

export default ListItemGroup;
