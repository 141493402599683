import { ILocation } from '@rbi-ctg/frontend';
import { IErrorModal } from 'hooks/use-error-modal';
import { UserDetails } from 'state/auth/hooks/types';
import { FormValidationState } from 'utils/form';
import { IPlaceAddress, IPlaceData } from 'utils/geolocation';

export interface IGetLocationFromAddressOptions {
  coordsOnly?: boolean;
}

export type IGetLocationFromAddressResult = Promise<Partial<IPlaceData>>;
export interface IDeliveryAddressFormProps {
  instructions?: string;
  user?: UserDetails | null;
  getDetailsPlaceData: (id: string) => Promise<IPlaceData>;
  setCreateAddressView: (setView: boolean) => void;
  showDeliveryAddresses: boolean;
  setAddress: (address: SelectedDeliveryAddress) => void;
  isLoading: boolean;
}

export interface IPhoneAddressValidation {
  phoneNumber: FormValidationState;
  address: FormValidationState;
}

export enum ActionStatus {
  ERROR = 'ERROR',
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  QUOTE_UNAVAILABLE = 'QUOTE_UNAVAILABLE',
  SUCCESS = 'SUCCESS',
  UPDATE = 'UPDATE',
}

export type SelectedDeliveryAddress = {
  address: IPlaceAddress;
  phoneNumber: string;
  deliveryInstructions?: string;
  placeId?: string;
  coordinates?: ILocation;
  shouldSaveAddress?: boolean;
};

export type AddressModalAction =
  | { type: ActionStatus.IDLE }
  | { type: ActionStatus.QUOTE_UNAVAILABLE }
  | { type: ActionStatus.SUCCESS }
  | { type: ActionStatus.ERROR; error: IErrorModal }
  | { type: ActionStatus.PENDING; address: SelectedDeliveryAddress };

export interface AddressModalState {
  address: SelectedDeliveryAddress | null;
  error: IErrorModal | null;
  status: ActionStatus;
}

export interface IDeliveryAddressModalProps {
  redirectUrl: string;
}
