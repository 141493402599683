import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { CheckoutErrorCellContainer, Dot, ErrorText } from './styled';

interface ICheckoutErrorsModalProps {
  showDot?: boolean;
  checkoutErrorId: string;
  message?: string | ReactNode;
}

const CheckoutErrorCell: React.FC<React.PropsWithChildren<ICheckoutErrorsModalProps>> = ({
  showDot = false,
  checkoutErrorId,
  message,
}) => {
  const { formatMessage } = useIntl();

  return (
    <CheckoutErrorCellContainer>
      {showDot && <Dot testID="checkout-error-dot" />}
      <ErrorText variant="copyTwo">{message || formatMessage({ id: checkoutErrorId })}</ErrorText>
    </CheckoutErrorCellContainer>
  );
};

export default CheckoutErrorCell;
