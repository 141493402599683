import { useCallback, useMemo } from 'react';

import {
  GetStaticPageQuery,
  IGetStaticPageQuery,
  IGetStaticPageVariables,
  IStaticPage,
} from 'remote/queries/static-page';
import { useErrorContext } from 'state/errors';
import { useLazySanityQuery } from 'state/graphql';

export const useStaticPage = () => {
  const { setSanityDataRef } = useErrorContext();
  const [
    getPage,
    {
      data: normalPageData,
      error: normalPageError,
      loading: normalPageLoading,
      refetch: normalPageRefetch,
    },
  ] = useLazySanityQuery<IGetStaticPageQuery, IGetStaticPageVariables>(GetStaticPageQuery);

  const retrieveStaticPageById = useCallback(
    (id: string): void => {
      const pageGetter = getPage;
      pageGetter({ variables: { id } });
    },
    [getPage]
  );

  const currentPage = useMemo<IStaticPage | undefined>(() => {
    const staticPage = normalPageData?.StaticPage;
    setSanityDataRef(staticPage);
    return staticPage;
  }, [normalPageData, setSanityDataRef]);

  const error = normalPageError;
  const loading = normalPageLoading;
  const refetch = normalPageRefetch;

  return { currentPage, error, loading, refetch, retrieveStaticPageById };
};
