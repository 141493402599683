import React from 'react';
import { useIntl } from 'react-intl';

import { Checkbox, HStack, Text } from '@fhs-legacy/universal-components';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { isFalse } from 'utils';

import { ACCEPT_EMAILS_AND_SPECIAL_OFFERS_MESSAGE_ID } from '../checkbox-labels/constants';

interface IPromotionalEmailsCheckbox {
  checked: boolean;
  disabled: boolean;
  onChange: (isSelected: boolean) => void;
}

const PromotionalEmailsCheckbox: React.FC<React.PropsWithChildren<IPromotionalEmailsCheckbox>> = ({
  checked,
  disabled,
  onChange,
}) => {
  const { formatMessage } = useIntl();
  const label = ACCEPT_EMAILS_AND_SPECIAL_OFFERS_MESSAGE_ID;
  const enableCommPrefFlag = useFlag(LaunchDarklyFlag.ENABLE_COMMUNICATION_PREFERENCES);
  const enableCommunicationPreferences = !isFalse(enableCommPrefFlag);

  return !enableCommunicationPreferences ? (
    <HStack alignItems="center" my="$4">
      <Checkbox
        value={label}
        aria-label={label}
        isDisabled={disabled}
        name="promotionalEmails"
        isChecked={checked}
        onChange={onChange}
      />
      <Text ml="$3" flex="1" testID={`opt-in-label-${label}`}>
        {formatMessage({ id: label })}
      </Text>
    </HStack>
  ) : null;
};

export default PromotionalEmailsCheckbox;
