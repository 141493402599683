import React, { useCallback } from 'react';

import { loadScript } from 'components/payments/integrations/hooks/utils';
import { useConfigValue } from 'hooks/configs/use-config-value';

import { IEncryptionConfig, ISafetechEncryption } from './types';
import { ensureSafetechConfig } from './utils';

declare global {
  interface Window {
    ProtectPANandCVV: Function;
    ValidatePANChecksum: Function;
    PIE: {
      key_id: string;
      phase: string;
    };
  }
}

const SafetechEncryption: React.FC<ISafetechEncryption> = ({
  cardNumber,
  cvv,
  onResult,
  onError,
}) => {
  const config: IEncryptionConfig = useConfigValue({
    key: 'orbital',
    defaultValue: null,
    isRegionalized: true,
  });

  const encryptCard = useCallback(async () => {
    ensureSafetechConfig(config);
    const { baseUrl, pieFormat, subscriberId, mode } = config;
    const embed = mode === 'eFPE';

    const getKeyScriptUrl: string = `${baseUrl}/pie/v1/${pieFormat}${subscriberId}/getkey.js`;
    const encryptionScriptUrl: string = `${baseUrl}/pie/v1/encryption.js`;

    // Load scripts
    await loadScript(getKeyScriptUrl);
    await loadScript(encryptionScriptUrl);

    const hasValidChecksum = await window.ValidatePANChecksum(cardNumber);
    if (!hasValidChecksum) {
      return onError({
        error: 'PAN has invalid checksum',
      });
    }
    const result = await window.ProtectPANandCVV(cardNumber, cvv, !embed);
    if (!result) {
      return onError({
        error: 'Encryption failed',
      });
    }

    const [cryptCard, cryptCvv, integrityCheck] = result;

    // Return encrypted card details and orbital configs
    onResult({
      cryptCard,
      cryptCvv,
      integrityCheck,
      pieFormat,
      subscriberId,
      mode,
      keyId: window.PIE.key_id,
      phase: parseInt(window.PIE.phase, 10),
    });
  }, [cardNumber, config, cvv, onError, onResult]);

  encryptCard();

  return null;
};

export default SafetechEncryption;
