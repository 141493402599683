/* eslint-disable complexity */
import { usePropsResolution } from '@fhs-legacy/native-base';

import { addWithConfig } from '../../utils';
import { Box } from '../box';
import { HStack } from '../h-stack';
import { Header } from '../header';
import { Image } from '../image';
import { InlineAlert } from '../inline-alert';
import { Text } from '../text';
import { VStack } from '../v-stack';

import type { HorizontalCarouselItemProps } from './types';

export const HorizontalCarouselItem = ({
  cta,
  header,
  image,
  imageAlt,
  imageUri,
  items,
  unavailable,
  unavailableMessage,
  ...props
}: HorizontalCarouselItemProps) => {
  const resolvedProps = usePropsResolution('HorizontalCarouselItem', { unavailable, ...props });
  // eslint-disable-next-line no-unsafe-optional-chaining
  const itemsLeft = items && items?.length > 2 ? items?.length - 2 : null;
  return (
    <Box {...resolvedProps} accessibilityRole="menu" mr="$4">
      <HStack px="$5" pt="$3" space="2">
        {image || (
          <Image
            accessibilityIgnoresInvertColors
            resizeMode="contain"
            source={{
              uri: imageUri,
            }}
            alt={imageAlt}
            height={['70', '100']}
            width={['70', '100']}
          />
        )}
        <VStack
          pt={!items || unavailable ? '$6' : '$0'}
          justifyContent="space-between"
          pb={5}
          minHeight={!unavailable ? ['132', '154'] : null}
          flexShrink={1}
        >
          <Box>
            <Header variant="headerThree" mb="$2" numberOfLines={2}>
              {header}
            </Header>
            {!unavailable ? (
              <Box mb="$2">
                {items?.slice(0, 2).map((item) => (
                  <Text variant="copyTwo" key={item}>
                    {item}
                  </Text>
                ))}
                {/* TODO: add localized version of "+{itemsLeft} more" */}
                {itemsLeft && <Text variant="copyTwo">{`+${itemsLeft} more`}</Text>}
              </Box>
            ) : null}
          </Box>
          {!unavailable ? cta : null}
        </VStack>
      </HStack>
      {unavailable && (
        <InlineAlert
          borderBottomRadius="12"
          borderTopRadius="0"
          width="full"
          status="info"
          /* TODO: add localized version of message: "Not available at selected store" */
          message={unavailableMessage}
        />
      )}
    </Box>
  );
};

export default addWithConfig(HorizontalCarouselItem);
