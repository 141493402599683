import { ColorValue, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { withStaticProperties } from '@fhs/utils';

import { createMqStyles } from '../../mq-styles';
import { tokens } from '../../tokens';
import { XStack, type XStackProps } from '../stack/x-stack';

export type HeaderFrameProps = XStackProps & {
  backgroundColor?: ColorValue;
  shouldApplySafeAreaInset?: boolean;
};
function Frame({
  backgroundColor = tokens.colors.$white,
  shouldApplySafeAreaInset = true,
  ...props
}: HeaderFrameProps) {
  const frameStyles = useFrameStyles();
  return (
    <SafeAreaView edges={shouldApplySafeAreaInset ? ['top'] : []} style={{ backgroundColor }}>
      <XStack {...props} style={[frameStyles.frame, props.style]} />
    </SafeAreaView>
  );
}

export type HeaderFrameLeftProps = XStackProps;
function Left(props: HeaderFrameLeftProps) {
  const leftStyles = useLeftStyles();
  return <XStack {...props} style={[styles.section, leftStyles.left, props.style]} />;
}

export type HeaderFrameMiddleProps = XStackProps;
function Middle(props: HeaderFrameMiddleProps) {
  return (
    <View style={styles.middle}>
      <XStack {...props} style={[styles.section, props.style]} />
    </View>
  );
}

export type HeaderFrameRightProps = XStackProps;
function Right(props: HeaderFrameRightProps) {
  return <XStack {...props} style={[styles.section, styles.right, props.style]} />;
}

const useFrameStyles = createMqStyles({
  frame: {
    $base: {
      position: 'relative',
      minHeight: 56,
      justifyContent: 'space-between',
      paddingHorizontal: 16,
    },
    $gteDesktop: {
      minHeight: 68,
      paddingHorizontal: 32,
    },
  },
});

const useLeftStyles = createMqStyles({
  left: {
    $base: {
      justifyContent: 'flex-start',
      flexShrink: 1,
      flexGrow: 1,
      gap: 20,
      paddingRight: 20,
    },
    $gteDesktop: {
      gap: 32,
      paddingRight: 32,
    },
  },
});

const styles = StyleSheet.create({
  section: {
    alignItems: 'center',
    pointerEvents: 'box-none',
  },
  middle: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
    // pointer-events: 'box-none' makes it so the middle view doesn't block clicks to the left and right views underneath
    // using 'box-none' instead of 'none' so subviews (like the logo) can still be clickable.
    pointerEvents: 'box-none',
  },
  right: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 0,
    flexShrink: 0,
  },
});

export const HeaderFrame = withStaticProperties(Frame, {
  Left,
  Middle,
  Right,
});
