import { useGetNearbyRestaurantsV2Query } from 'generated/graphql-gateway';
import { useGetRestaurantsQuery } from 'generated/rbi-graphql';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode } from 'state/service-mode';
import { convertMilesToMeters } from 'utils/distance';

type Coordinates = {
  userLat: number | undefined;
  userLng: number | undefined;
  searchRadius: number;
};

const useGetNearbyRestaurantsLegacy = (coordinates: Coordinates) => {
  const {
    data: restaurantsData,
    loading: loadingRestaurant,
    error,
  } = useGetRestaurantsQuery({
    variables: {
      input: {
        first: 20,
        coordinates,
      },
    },
  });

  return {
    restaurants: restaurantsData?.restaurants?.nodes || [],
    loading: loadingRestaurant,
    error,
  };
};

const useGetNearbyRestaurantsV2 = (coordinates: Coordinates, serviceMode: ServiceMode) => {
  const { data, loading, error } = useGetNearbyRestaurantsV2Query({
    variables: {
      input: {
        coordinates,
        pagination: {
          first: 20,
        },
        serviceModes: serviceMode ? [serviceMode] : [],
        radiusStrictMode: false,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return {
    restaurants: data?.nearbyRestaurants?.nodes || [],
    loading,
    error,
  };
};

export const useGetNearbyRestaurantsFromDelivery = (
  coordinates: Coordinates,
  serviceMode: ServiceMode,
  searchRadiusInMiles: number
) => {
  const isRestaurantQueryCachingEnabled = useFlag(LaunchDarklyFlag.ENABLE_RESTAURANT_QUERY_CACHE);

  const useNearbyQuery = isRestaurantQueryCachingEnabled
    ? useGetNearbyRestaurantsV2
    : useGetNearbyRestaurantsLegacy;

  const queryCoordinates = {
    ...coordinates,
    searchRadius: convertMilesToMeters(searchRadiusInMiles),
  };
  const { restaurants, loading, error } = useNearbyQuery(queryCoordinates, serviceMode);

  return {
    restaurants,
    loading,
    error,
  };
};
