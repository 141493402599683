import { router } from 'expo-router';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import useAuth from 'hooks/auth/use-auth';
import useErrorModal from 'hooks/use-error-modal';
import AuthContainer from 'pages/authentication/auth-container';
import { isEmailValid } from 'utils/form';

import { CenteredText, Heading } from '../styled';

interface IMissingLinkProps {
  username: string;
}

const MissingLink: React.FC<React.PropsWithChildren<IMissingLinkProps>> = ({ username }) => {
  const { signIn } = useAuth();
  const { formatMessage } = useIntl();
  const [resendEmailIsLoading, setResendEmailIsLoading] = useState(false);
  const [ErrorDialog, openErrDialog] = useErrorModal({
    onConfirm: () => router.navigate('/signin'),
    modalAppearanceEventMessage: 'Error: Invalid Password Reset Email Provided',
  });

  const newLinkText = formatMessage({ id: 'authNewLink' });
  const sendNewLinkText = formatMessage(
    { id: 'authExpiredLink' },
    {
      sendNewLinkText: newLinkText,
    }
  );

  const resendEmailLink = async () => {
    setResendEmailIsLoading(true);
    if (!username || !isEmailValid(username)) {
      setResendEmailIsLoading(false);
      openErrDialog({
        message: formatMessage({ id: 'invalidEmailProvided' }),
      });
    } else {
      try {
        await signIn({
          email: username,
          navigateOnSuccessParams: { email: username },
        });
      } catch (error) {
        setResendEmailIsLoading(false);
        openErrDialog({
          message: formatMessage({ id: 'authError' }),
        });
      }
    }
  };

  return (
    <>
      <AuthContainer>
        <Heading>{formatMessage({ id: 'authMissingLink' })}</Heading>
        <CenteredText>{sendNewLinkText}</CenteredText>
        <ActionButton
          testID="missing-link-button"
          isLoading={resendEmailIsLoading}
          onPress={resendEmailLink}
        >
          {newLinkText}
        </ActionButton>
      </AuthContainer>

      <ErrorDialog buttonLabel={formatMessage({ id: 'tryAgain' })} />
    </>
  );
};

export default MissingLink;
