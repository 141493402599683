import { Link, router } from 'expo-router';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useHomepageReorder } from '@fhs-legacy/frontend/src/state/order/hooks/use-homepage-reorder';
import { Box } from '@fhs-legacy/universal-components';
import { IBackendCartEntries, IHistoricalOrder } from '@rbi-ctg/menu';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import useErrorModal from 'hooks/use-error-modal';
import useOrderNumber from 'hooks/use-order-number';
import { OrderStatus, ServiceMode, useOrderContext } from 'state/order';
import { useReorder } from 'state/order/hooks/use-reorder';
import { useReorderLogs } from 'state/order/hooks/use-reorder-logs';
import { useStoreContext } from 'state/store';
import { useCreateOrderAriaLabel } from 'utils/accessibility/aria';
import { everyCartEntriesInMenu } from 'utils/cart';
import { routes } from 'utils/routing';
import { isCatering } from 'utils/service-mode';

import { FireOrderBadge } from './fire-order-badge';
import OrderAction from './order-action';
import { RewardText } from './reward-text';
import {
  CateringPendingApproval,
  Col,
  FormattedDate,
  ItemNameContainer,
  Items,
  MoreItems,
  MoreItemsText,
  NumItems,
  OrderBox,
  OrderNumber,
  OrderNumberText,
  OrderNumberWrapper,
  SubtitleContainer,
  Tile,
} from './styled';

interface IOrderProps {
  order: IHistoricalOrder;
}

const Order = ({ order }: IOrderProps) => {
  const {
    reorder: { reorderedOrderId },
  } = useOrderContext();
  const { store, fetchStore } = useStoreContext();
  const { disabled } = useReorder(order);
  const { formatMessage } = useIntl();
  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Fetch Store Failure',
  });

  const {
    handleReorderCallback,
    recentOrderLoading,
    isReorderingLoading,
    confirmLocationActionSheetComponent,
    confirmStoreActionSheetComponent,
  } = useHomepageReorder(order);

  const { logReorderRecentsCtaPress, logReorderRecentsViewDetailsPress } = useReorderLogs();

  const handleReorderWithLogging = () => {
    logReorderRecentsCtaPress();
    handleReorderCallback();
  };

  const isReorderCustomLoading = Boolean(recentOrderLoading || isReorderingLoading);

  const hasUnavailableItems = order?.order?.hasUnavailableItem;

  const formattedDate = useMemo(
    () =>
      new Date(order.createdAt).toLocaleString(formatMessage({ id: 'localeDateTime' }), {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }),
    [formatMessage, order.createdAt]
  );

  const { itemCSV, numItems, rewardRedeemed } = useMemo(() => {
    const cartEntries = order?.cart?.cartEntries ?? [];
    const csvForItem = cartEntries.map((entry: IBackendCartEntries) => entry.name).join(', ');
    const totalItemsOrdered = cartEntries.reduce((acc: number, item: IBackendCartEntries) => {
      if (item.lineId === 'discount-offer') {
        return acc;
      }
      return acc + item.quantity;
    }, 0);

    const itemsCount = `${totalItemsOrdered} ${(totalItemsOrdered === 1
      ? formatMessage({ id: 'item' })
      : formatMessage({ id: 'items' })
    ).toLowerCase()}`;

    // order redeemed a reward if an entry has `redeemable` as true
    const wasRewardRedeemed = cartEntries.some((entry: IBackendCartEntries) => entry.redeemable);

    return { itemCSV: csvForItem, numItems: itemsCount, rewardRedeemed: wasRewardRedeemed };
  }, [order, formatMessage]);

  const showCateringPendingApproval =
    isCatering(order?.cart.serviceMode) && order?.status === OrderStatus.CATERING_PLACED;

  const handleFinishOrder = async () => {
    const orderStoreId = order.cart?.storeId ? order.cart.storeId.toString() : '';

    // Store needs to be re-fetched if the current one is different is missing.
    if (store.number && store.number !== orderStoreId) {
      try {
        await fetchStore(orderStoreId);
      } catch {
        return openErrorDialog({ message: formatMessage({ id: 'couldNotLocateStore' }) });
      }
    }
    router.navigate(`${routes.orderConfirmation}/${order.rbiOrderId}`);
  };

  // Show the first item and tell the user how many other items there is in the order
  const itemsArr = itemCSV.split(',');
  const remainingItems = itemsArr.length - 1;
  const renderMoreItems = () => (
    <Link
      href={{
        pathname: `/receipt/${order.rbiOrderId}`,
        params: { back: '/account/orders' },
      }}
      asChild
    >
      <MoreItems>
        <MoreItemsText>{formatMessage({ id: 'moreItems' }, { remainingItems })}</MoreItemsText>
      </MoreItems>
    </Link>
  );
  const createAriaLabel = useCreateOrderAriaLabel();
  const orderNumber = useOrderNumber(order);
  const disableReorder = useMemo(
    () => disabled || !everyCartEntriesInMenu(order?.cart?.reorderCartEntries || []),
    [disabled, order]
  );

  return (
    <>
      <Tile testID={`recent-order-${order.rbiOrderId}`}>
        <Col>
          <FireOrderBadge
            inProgress={order?.order?.inProgress ?? false}
            serviceMode={order.cart.serviceMode}
          />
          <OrderNumberWrapper>
            {orderNumber && (
              <>
                <OrderNumberText variant="headerThree">{`${formatMessage({
                  id: 'orderNumber',
                })}:`}</OrderNumberText>
                <OrderNumber variant="headerThree">{orderNumber}</OrderNumber>
              </>
            )}
          </OrderNumberWrapper>
          <FormattedDate variant="headerThree">{formattedDate}</FormattedDate>
          {showCateringPendingApproval && (
            <CateringPendingApproval>
              {formatMessage({ id: 'cateringOrderPendingApproval' })}
            </CateringPendingApproval>
          )}
          <SubtitleContainer variant="headerFour">
            <NumItems>{numItems}</NumItems>
          </SubtitleContainer>
          <ItemNameContainer>
            {rewardRedeemed && <RewardText text={formatMessage({ id: 'rewards' })} />}
            <Items>
              {itemsArr[0]}
              {remainingItems > 0 && renderMoreItems()}
            </Items>
          </ItemNameContainer>
        </Col>
        <Col>
          <Box width="full">
            <ActionButton
              testID="recent-order-view-details"
              variant={ActionButtonVariants.OUTLINE}
              fullWidth
              onPress={logReorderRecentsViewDetailsPress}
              to={{
                pathname: `/order-confirmation/${order.rbiOrderId}`,
                params: { back: '/account/orders' },
              }}
              aria-label={createAriaLabel({
                buttonType: 'view',
                order,
              })}
            >
              {formatMessage({ id: 'viewDetails' })}
            </ActionButton>
            {order.cart.serviceMode !== ServiceMode.CATERING_PICKUP && (
              <OrderBox>
                <OrderAction
                  order={order}
                  loading={reorderedOrderId === order.rbiOrderId && isReorderCustomLoading}
                  disabled={disableReorder || isReorderCustomLoading || hasUnavailableItems}
                  onTrack={handleFinishOrder}
                  onReorder={handleReorderWithLogging}
                  onFinish={handleFinishOrder}
                />
              </OrderBox>
            )}
          </Box>
        </Col>
        <ErrorDialog />
      </Tile>
      {confirmLocationActionSheetComponent}
      {confirmStoreActionSheetComponent}
    </>
  );
};

export default Order;
