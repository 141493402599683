import { HeadingTextProps, Text } from '../text';

export type HeaderTitleTextProps = Partial<Omit<HeadingTextProps, 'children'>> & {
  text: string;
};

export function HeaderTitleText({ text, ...props }: HeaderTitleTextProps) {
  return (
    <Text.Heading type="four" accessibilityLevel={1} {...props}>
      {text}
    </Text.Heading>
  );
}
