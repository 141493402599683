import React, { useLayoutEffect, useMemo } from 'react';

import { ActionSheet, useMqSelect } from '@fhs/ui';
import { IItem } from '@rbi-ctg/menu';
import { ScreenScrollView } from 'components/layout/screen-scroll-view';
import { ProductOverview } from 'components/product-detail/product-overview';
import { useScrollRestoration } from 'hooks/use-scroll-restoration';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuContext } from 'state/menu';
import { useProductWizardContext } from 'state/product-wizard';
import { useProductWizardFlow } from 'state/product-wizard/hooks/use-product-wizard-flow';
import { ProductWizardStep } from 'state/product-wizard/types';
import { useScrollContext } from 'state/scroll';

import { NutritionInformation } from '../nutrition-information';
import { ProductHomeCta, ProductStaticMenuCta } from '../product-cta';
import { ProductCustomization } from '../product-customization';
import { ProductHome } from '../product-home';

import { ProductCustomizationContainer } from './product-customization-container';

export interface IProductDetailLayoutProps {
  currentStep: ProductWizardStep;
  showStaticMenu: boolean;
}

export const ProductLayout: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isMobile = useMqSelect({ $ltDesktop: true }, false);

  const { showStaticMenu } = useMenuContext();
  const { currentStep, slotKeyToCustomize } = useProductWizardFlow();
  const { userSelections, isSimplyOffer } = useProductWizardContext();
  const { scrollRef, scrollTo } = useScrollContext();
  const { contentOffset } = useScrollRestoration(String(currentStep));
  const useEnableShowPickerAspectsInStaticMenu = useFlag(
    LaunchDarklyFlag.ENABLE_SHOW_PICKER_ASPECTS_IN_STATIC_MENU
  );

  const showStaticPickers = showStaticMenu && useEnableShowPickerAspectsInStaticMenu;

  const itemToCustomize = useMemo<IItem | null>(
    () => userSelections.comboSlot?.get(slotKeyToCustomize)?.selectedItem || null,
    [slotKeyToCustomize, userSelections.comboSlot]
  );

  const showCustomization =
    !!itemToCustomize && currentStep === ProductWizardStep.ProductCustomization;

  useLayoutEffect(() => {
    if (contentOffset) {
      scrollTo({
        y: contentOffset?.y,
        animated: false,
      });
    }
  }, [contentOffset, scrollTo]);

  const ScrollComponent: any = isSimplyOffer ? ActionSheet.ScrollView : ScreenScrollView;

  const hideCta =
    isSimplyOffer && isMobile && currentStep === ProductWizardStep.ProductCustomization;

  return (
    <>
      {showCustomization ? (
        <ProductCustomizationContainer>
          <ProductCustomization itemToCustomize={itemToCustomize} />
        </ProductCustomizationContainer>
      ) : null}

      <ScrollComponent ref={scrollRef} flex={1} isSimplyOffer={isSimplyOffer}>
        <ProductOverview />
        {(!showStaticMenu || showStaticPickers) && (
          <ProductHome showStaticPickers={showStaticPickers} />
        )}
        <NutritionInformation />
      </ScrollComponent>
      {!hideCta && (
        <>
          {showStaticMenu || showStaticPickers ? (
            <ProductStaticMenuCta showStaticPickers={showStaticPickers} />
          ) : (
            <ProductHomeCta />
          )}
        </>
      )}
    </>
  );
};
