import { useEffect, useRef } from 'react';

import { useDropdownSetters, useDropdownState } from './context';

export type DropdownClosePaneOnChangeProps<T> = {
  value: T;
};
export function DropdownClosePaneOnChange<T>({ value }: DropdownClosePaneOnChangeProps<T>) {
  const { setIsExpanded } = useDropdownSetters();
  const { isExpanded } = useDropdownState();
  const prevValueRef = useRef<null | T>(null);

  useEffect(() => {
    if (!isExpanded) {
      // Clear previous value on close
      prevValueRef.current = null;
    }

    if (prevValueRef.current === null) {
      // If no previous value, set to current value
      prevValueRef.current = value;
    }

    if (value !== prevValueRef.current) {
      // close if values are different and set previous value to current value
      prevValueRef.current = value;
      setIsExpanded(false);
    }
  }, [isExpanded, value, setIsExpanded]);

  return null;
}
