import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconFilter = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M8.25 3.545c-.69 0-1.25.547-1.25 1.222 0 .675.56 1.223 1.25 1.223S9.5 5.442 9.5 4.767s-.56-1.222-1.25-1.222Zm-3.537 0c.515-1.424 1.904-2.444 3.537-2.444s3.022 1.02 3.537 2.444h8.963c.69 0 1.25.547 1.25 1.222 0 .675-.56 1.223-1.25 1.223h-8.963c-.515 1.424-1.904 2.444-3.537 2.444S5.228 7.414 4.713 5.99H3.25C2.56 5.99 2 5.442 2 4.767s.56-1.222 1.25-1.222h1.463Zm11.037 7.333c-.69 0-1.25.548-1.25 1.223s.56 1.222 1.25 1.222S17 12.776 17 12.1c0-.675-.56-1.223-1.25-1.223Zm-3.537 0c.515-1.424 1.904-2.444 3.537-2.444s3.022 1.02 3.537 2.444h1.463c.69 0 1.25.548 1.25 1.223s-.56 1.222-1.25 1.222h-1.463c-.515 1.424-1.904 2.444-3.537 2.444s-3.022-1.02-3.537-2.444H3.25c-.69 0-1.25-.547-1.25-1.222 0-.675.56-1.223 1.25-1.223h8.963ZM8.25 18.212c-.69 0-1.25.547-1.25 1.222 0 .675.56 1.222 1.25 1.222s1.25-.547 1.25-1.222c0-.675-.56-1.222-1.25-1.222Zm-3.537 0c.515-1.424 1.904-2.445 3.537-2.445s3.022 1.02 3.537 2.445h8.963c.69 0 1.25.547 1.25 1.222 0 .675-.56 1.222-1.25 1.222h-8.963c-.515 1.424-1.904 2.445-3.537 2.445s-3.022-1.02-3.537-2.445H3.25c-.69 0-1.25-.547-1.25-1.222 0-.675.56-1.222 1.25-1.222h1.463Z"
      />
    </Svg>
  );
};
