export enum LoyaltyDashboardWidgets {
  DoubleButton = 'doubleButtonWidget',
  Greeting = 'greetingWidget',
  History = 'historyWidget',
  Links = 'linkWidget',
  Logo = 'logoWidget',
  MarketingTiles = 'marketingTileGroupWidget',
  PointsMeter = 'pointsMeterWidget',
  Reward = 'pointsAndRewardsWidget',
  Tiers = 'tiersWidget',
  Notifications = 'notificationsWidget',
  RewardsListWidget = 'rewardsListWidget',
  FeatureRewardsCarouselWidget = 'featuredRewardsCarouselWidget',
}
