import * as React from 'react';
import { FC } from 'react';

import ActionButton from 'components/action-button';
import Dialog from 'components/dialog';

import { ContentContainer, ModalBody, ModalHeading } from './geolocation-denied-modal.styled';
import { IGeolocationDeniedModalViewProps } from './types';

export const GeolocationDeniedModalView: FC<
  React.PropsWithChildren<IGeolocationDeniedModalViewProps>
> = ({
  isOpen,
  dismissModal,
  openSettings,
  modalBodyText,
  modalHeadingText,
  openSettingsButtonText,
}) => {
  return (
    <Dialog
      showDialog={isOpen}
      testID="geolocation-denied-modal"
      headingComponent={
        <ModalHeading testID="geolocation-denied-heading">{modalHeadingText}</ModalHeading>
      }
      bodyComponent={
        <ContentContainer testID="geolocation-denied-body">
          <ModalBody>{modalBodyText}</ModalBody>
        </ContentContainer>
      }
      actions={
        openSettingsButtonText ? (
          <ActionButton fullWidth testID="geolocation-denied-open-settings" onPress={openSettings}>
            {openSettingsButtonText}
          </ActionButton>
        ) : null
      }
      modalAppearanceEventMessage="Previously Denied Location Services Modal"
      showCloseButton
      onDismiss={dismissModal}
    />
  );
};

export default GeolocationDeniedModalView;
