import { forwardRef } from 'react';
import { View } from 'react-native';

import { Button, ButtonProps } from '@fhs/ui';

export type SignUpButtonProps = Omit<ButtonProps, 'children'>;

export const SignUpButton = forwardRef<View, SignUpButtonProps>(function SignUpButton(props, ref) {
  return (
    <Button
      ref={ref}
      type="outline"
      aria-label="Open sign up"
      size="sm"
      {...props}
      testID="app-header-signup-button"
    >
      <Button.Text>Sign Up</Button.Text>
    </Button>
  );
});
