import { usePathname } from 'expo-router';
import { useEffect, useMemo } from 'react';

import { MaxCartTotalAlert } from '@fhs-legacy/frontend/src/components/max-cart-total-alert';
import { useCartLimit } from '@fhs-legacy/frontend/src/hooks/use-cart-limit';
import { useStoreRequired } from '@fhs-legacy/frontend/src/state/store/hooks/use-store-required';
import { useUIContext } from '@fhs-legacy/frontend/src/state/ui';
import Styles from '@fhs-legacy/frontend/src/styles';
import { isMenuTabRoute, isSectionRoute } from '@fhs-legacy/frontend/src/utils/routing';
import { useToast } from '@fhs-legacy/universal-components';

const TOAST_ID = 'LIMIT_PRICE_TOAST';

export const MaxCartAmountToast = () => {
  const pathname = usePathname();
  const { formatCurrencyForLocale } = useUIContext();
  const { show } = useToast({
    placement: 'bottom',
  });
  const { isFetchingStore } = useStoreRequired();

  const { isOverCartLimit, maxCartTotal } = useCartLimit();
  const shouldShowToast = useMemo(() => {
    return (
      !isFetchingStore && isOverCartLimit && (isMenuTabRoute(pathname) || isSectionRoute(pathname))
    );
  }, [isFetchingStore, isOverCartLimit, pathname]);

  useEffect(() => {
    if (shouldShowToast) {
      const formattedCartMaxTotal = formatCurrencyForLocale(maxCartTotal);
      show({
        id: TOAST_ID,
        variant: 'informative',
        text: `We only accept orders up to ${formattedCartMaxTotal} in our app`,
        render: () => <MaxCartTotalAlert color={Styles.color.white} />,
      });
    }
  }, [formatCurrencyForLocale, maxCartTotal, shouldShowToast, show]);

  return null;
};
