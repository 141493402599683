import { FC } from 'react';

import { Accordion, AccordionItem } from 'components/accordion';
import { IconType } from 'components/accordion/types';

import { ModifierGroupBase } from './product-customization-modifier-group.base';
import { IModifierGroupProps } from './types';

export const ModifierGroup: FC<IModifierGroupProps> = props => {
  const { displayGroupName, autoExpandAccordion } = props;

  return (
    <Accordion>
      <AccordionItem
        title={displayGroupName}
        iconType={IconType.ARROW}
        iconFromDeg={270}
        autoExpand={autoExpandAccordion}
        _accordionActivatorButton={{
          paddingRight: '$10',
          _web: {
            paddingRight: '$4',
          },
        }}
      >
        <ModifierGroupBase {...props} />
      </AccordionItem>
    </Accordion>
  );
};
