import { StyleSheet } from 'react-native';

import { tokens } from '../..';

import { CheckboxIndicator } from './checkbox-indicator';
import { CheckboxPressable, type CheckboxPressableProps } from './checkbox-pressable';
import { useCheckbox } from './context';

export type CheckboxControlProps = CheckboxPressableProps;

export const CheckboxControl = (props: CheckboxControlProps) => {
  const checkbox = useCheckbox();

  const isCheckboxSelected = Boolean(checkbox.checked); // purposefully casting 'mixed' to true;

  return (
    <CheckboxPressable
      borderWidth={0}
      outlineFocusedStyle={isCheckboxSelected && styles.outlineFocusedSelected}
      {...props}
      style={[styles.checkboxWrapper, props.style]}
    >
      <CheckboxIndicator />
    </CheckboxPressable>
  );
};

const styles = StyleSheet.create({
  checkboxWrapper: {
    alignSelf: 'center',
  },
  outlineFocusedSelected: {
    borderColor: tokens.colors.$houseRedDarkenOpacity10,
  },
});
