import { router, useFocusEffect } from 'expo-router';
import { isEmpty, isEqual, sortBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { RefreshControl } from 'react-native';

import { View } from '@fhs-legacy/universal-components';
import { AnimatedFlatList } from 'components/animated-flat-list';
import LoadingAnimation from 'components/loading-animation';
import { OffersEmptyState } from 'components/offers/ui-refresh/offers-empty-state';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import { useToast } from 'hooks/use-toast';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { useFlag } from 'state/launchdarkly';
import { useLoyaltyContext } from 'state/loyalty';
import { IncentiveEvaluationMap } from 'state/loyalty/hooks/types';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import {
  ICartEntryType,
  useInRestaurantRedemptionContext,
} from 'state/loyalty/in-restaurant-redemption';
import { IncentiveSource, LoyaltyOffer } from 'state/loyalty/types';
import { isDiscountLoyaltyOffer } from 'state/loyalty/utils';
import { useOrderContext } from 'state/order';
import { isNative } from 'utils/environment';
import { EventName, emitEvent } from 'utils/event-hub';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import { routes } from 'utils/routing';
import { blockContentToPlainText } from 'utils/sanity';

import { AnimatedOfferItem } from '../loyalty-incentives-components/animated-offer-item';
import { parseIncentiveData } from '../loyalty-incentives-components/incentive-card/parse-incentive-data';
import { IncentiveDetails } from '../loyalty-incentives-components/incentive-details';
import { LoyaltyOffersItem } from '../loyalty-incentives-components/loyalty-offers-item';
import { useLoyaltyIncentivesAvailability } from '../loyalty-incentives-components/use-loyalty-incentives-availability';

import EndOfOffers from './end-of-offers/loyalty-end-of-offers';
import { LoyaltyOffersCooldown } from './loyalty-offers-cooldown';
import { LoyaltyOffersFilterButtons } from './loyalty-offers-filters';
import { LoyaltyIncentivesContainer, StyledLoyaltyLoader } from './loyalty-offers.styled';
import { LoyaltyPromoCode } from './loyalty-promo-code';
import { OffersInCart } from './offers-in-cart/offers-in-cart';
import { useLoyaltyOffersFilters } from './use-loyalty-offers-filters';
import useLoyaltyOffersFlow from './use-loyalty-offers-flow';
import { getSelectedIncentive, reduceOffers } from './utils';

export const LoyaltyOffersBase = () => {
  const { formatMessage } = useIntl();
  const { loyaltyUserId } = useLoyaltyUser();
  const { queryLoyaltyOffers, queryLoyaltyUserStandardOffers, refetchCmsOffers } =
    useLoyaltyContext();
  const loyaltyCmsOffers = useAppSelector(selectors.loyalty.selectCmsOffers);
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const offersLoading = useAppSelector(selectors.loyalty.selectOffersLoading);
  const userOffers = useAppSelector(selectors.loyalty.selectUserOffers);
  const unauthenticatedOffers = useAppSelector(selectors.loyalty.selectOffers);
  const personalizedOffers = useAppSelector(selectors.loyalty.selectPersonalizedOffers);
  const shouldRefetchOffers = useAppSelector(selectors.loyalty.selectShouldRefetchOffers);
  const dispatch = useAppDispatch();
  const { inRestaurantRedemptionCart, removeInRestaurantRedemptionEntry } =
    useInRestaurantRedemptionContext();
  const enableSortOffersForServiceMode = useFlag(
    LaunchDarklyFlag.ENABLE_SORT_OFFERS_FOR_SERVICE_MODE
  );
  const enableLoyaltyPromoCodes = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_PROMO_CODES);
  const enableLoyaltyOffersFilters = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_OFFERS_FILTERS);
  const { removeFromCart, ...order } = useOrderContext();

  const { checkLoyaltyOfferAvailability } = useLoyaltyIncentivesAvailability();
  const toast = useToast();

  const { offerEngineId: loyaltyEngineId, isConfigOffer } = useLoyaltyOffersFlow();
  const { appliedFilters, toggleFilter } = useLoyaltyOffersFilters();
  const skipAnimation = useRef(true);
  const isBaseOffersRoute = !loyaltyEngineId;
  const [offers, offersInCart] = useMemo(
    () =>
      !loyaltyCmsOffers
        ? [[], []]
        : reduceOffers({
            cmsOffers: loyaltyCmsOffers,
            personalizedOffers,
            baseOffers: loyaltyUserId ? userOffers : unauthenticatedOffers,
            appliedOffers,
            inRestaurantRedemptionCart,
            checkLoyaltyOfferAvailability,
            enableSortOffersForServiceMode,
            // @ts-expect-error TS(2322) FIXME: Type '(offerFeedbackMap: IncentiveEvaluationMap) =... Remove this comment to see the full error message
            setOffersFeedbackMap: (offerFeedbackMap: IncentiveEvaluationMap) =>
              dispatch(actions.loyalty.setOffersFeedbackMap(offerFeedbackMap)),
            appliedFilters,
          }),
    [
      loyaltyCmsOffers,
      loyaltyUserId,
      userOffers,
      unauthenticatedOffers,
      appliedOffers,
      inRestaurantRedemptionCart,
      checkLoyaltyOfferAvailability,
      enableSortOffersForServiceMode,
      dispatch,
      personalizedOffers,
      appliedFilters,
    ]
  );
  const allOffers = useMemo(() => [...offersInCart, ...offers], [offersInCart, offers]);
  const offersTrackedRef = useRef<LoyaltyOffer[]>();

  const { logRBIEvent } = useCRMEventsContext();
  // TODO: extract this method to a reusable place
  const removeLoyaltyOffer = useCallback(
    (offerToRemove: LoyaltyOffer) => {
      const appliedOfferToRemove = appliedOffers.find(
        offer => offer.id === offerToRemove.loyaltyEngineId
      );
      if (appliedOfferToRemove?.cartId) {
        removeFromCart(appliedOfferToRemove);
        return true;
      }

      return false;
    },
    [appliedOffers, removeFromCart]
  );

  const handleRemove = useCallback(
    (offerToRemove: LoyaltyOffer) => {
      const redemptionOfferToRemove = inRestaurantRedemptionCart.find(
        cartEntry =>
          cartEntry.type === ICartEntryType.OFFER &&
          cartEntry.details.offer._id === offerToRemove._id
      );

      let showNotification = false;

      if (redemptionOfferToRemove) {
        removeInRestaurantRedemptionEntry(redemptionOfferToRemove);
        showNotification = true;
      } else if (isDiscountLoyaltyOffer(offerToRemove)) {
        dispatch(actions.loyalty.setSelectedOffer(null));
        dispatch(actions.loyalty.resetAppliedOffers());
        showNotification = true;
      } else {
        showNotification = removeLoyaltyOffer(offerToRemove);
      }

      if (showNotification) {
        toast.show({
          text: formatMessage({ id: 'offerAppliedSuccess' }),
          variant: 'positive',
        });
      }
    },
    [
      inRestaurantRedemptionCart,
      removeInRestaurantRedemptionEntry,
      dispatch,
      removeLoyaltyOffer,
      toast,
      formatMessage,
    ]
  );

  const cartTotal: number = useMemo(
    () => order?.calculateCartTotal && order.calculateCartTotal(),
    [order]
  );

  const fetchOffers = useCallback(() => {
    // Reset shouldRefetchOffers flag
    dispatch(actions.loyalty.setShouldRefetchOffers(false));

    if (loyaltyUserId) {
      queryLoyaltyUserStandardOffers({ loyaltyId: loyaltyUserId, subtotalAmount: cartTotal });
    } else {
      queryLoyaltyOffers({ subtotalAmount: cartTotal });
    }
  }, [cartTotal, loyaltyUserId, queryLoyaltyOffers, queryLoyaltyUserStandardOffers, dispatch]);

  useFocusEffect(
    useCallback(() => {
      const sortOrdersById = (offersToSort: LoyaltyOffer[] | undefined) =>
        sortBy(offersToSort, offer => offer._id);

      if (
        isBaseOffersRoute &&
        !shouldRefetchOffers &&
        !offersLoading &&
        (!offersTrackedRef.current ||
          !isEqual(sortOrdersById(offersTrackedRef.current), sortOrdersById(allOffers)))
      ) {
        logRBIEvent({
          name: CustomEventNames.OFFERS_LISTED,
          type: EventTypes.UserContent,
          attributes: {
            offers: allOffers.map(offer => ({
              id: offer.loyaltyEngineId ?? '',
              name: blockContentToPlainText(offer?.name?.localeRaw),
            })),
          },
        });
        offersTrackedRef.current = allOffers;
      }
    }, [logRBIEvent, isBaseOffersRoute, offersLoading, shouldRefetchOffers, allOffers])
  );

  useFocusEffect(
    React.useCallback(() => {
      emitEvent(EventName.SCREEN_RENDER, {
        screenId: 'loyaltyOffers',
      });
    }, [])
  );

  // Refetch offers if shouldRefetchOffers is true or an offer is added to cart
  useEffect(() => {
    if (shouldRefetchOffers) {
      fetchOffers();
    }
  }, [fetchOffers, shouldRefetchOffers]);

  useFocusEffect(
    useCallback(() => {
      if (isBaseOffersRoute) {
        fetchOffers();
      }
    }, [isBaseOffersRoute, fetchOffers])
  );

  const selectedIncentive = getSelectedIncentive(loyaltyEngineId, offers, offersInCart, true);

  const handlePress = useCallback(
    (incentiveId: string, incentiveName: string, incentiveSanityId: string) => {
      if (incentiveSanityId !== selectedIncentive?._id) {
        logRBIEvent({
          name: CustomEventNames.OFFER_SELECTED,
          type: EventTypes.Other,
          attributes: {
            engineId: incentiveId,
            sanityId: incentiveSanityId,
            name: incentiveName,
          },
        });

        router.navigate(`${routes.loyaltyOfferList}/${incentiveId}`);
      }
    },
    [logRBIEvent, selectedIncentive]
  );

  useRumPageView(
    `loyalty-offers${selectedIncentive ? '-detail' : '-list'}`,
    `Loyalty Offers${selectedIncentive ? ' Detail' : ' List'}`
  );

  useFocusEffect(
    useCallback(() => {
      if (loyaltyEngineId && !selectedIncentive && !offersLoading && !isEmpty(offers)) {
        // TODO: ReactNavigation review this error case
        toast.show({
          text: formatMessage(
            { id: 'incentiveFeedbackInvalidStore' },
            { incentiveType: formatMessage({ id: 'offer' }) }
          ),
          variant: 'negative',
        });
        router.replace(isConfigOffer ? routes.menu : routes.loyaltyOfferList);
      }
    }, [
      formatMessage,
      isConfigOffer,
      loyaltyEngineId,
      offers,
      offersLoading,
      selectedIncentive,
      toast,
    ])
  );

  const renderItem = useCallback(
    ({ item: offer }: { item: LoyaltyOffer }) => {
      const incentiveData = parseIncentiveData(offer);
      const isPromoCode = (incentiveData.metadata || []).some(
        data => data?.value === IncentiveSource.PROMOTION_CODE
      );

      const renderOfferItem = () => {
        return <LoyaltyOffersItem handlePress={handlePress} incentiveData={incentiveData} />;
      };
      // Animate only if skipAnimation is disabled and offer source is Promo Code campaing
      return !skipAnimation.current && isPromoCode ? (
        <AnimatedOfferItem setSkipAnimation={(skip: boolean) => (skipAnimation.current = skip)}>
          {renderOfferItem()}
        </AnimatedOfferItem>
      ) : (
        renderOfferItem()
      );
    },
    [handlePress]
  );

  const renderListEmptyComponent = useCallback(() => {
    if (offersLoading) {
      return <LoadingAnimation />;
    }
    if (offersInCart.length === 0) {
      return <OffersEmptyState />;
    }

    return null;
  }, [offersLoading, offersInCart]);

  const isSelectedIncentiveLoading = !isBaseOffersRoute && !selectedIncentive;

  if (isSelectedIncentiveLoading) {
    return <StyledLoyaltyLoader />;
  }

  const onRefresh = () => {
    // When a user manually refreshes we have to fetch the CMS offers
    // and the engine offers. These two offers have been implemented in different
    // ways and signal different loading mechanisms (one is through redux, the other a hook)
    // To standardize the way this component responds to loading, I've opted to
    // manually set the offers loading (which is currently what happens when you call fetchOffers)
    // I'm doing this eagerly so the refresh control doesn't close. FlatList will close the RefreshControl
    // if the refreshing state doesn't get signalled in an appropriate time.
    //
    // In the future, this could be refactored and simplified if the `refetchCmsOffers` worked through redux
    // in the same discipline.
    dispatch(actions.loyalty.setOffersLoading(true));

    refetchCmsOffers()
      .then(fetchOffers)
      .then(() => dispatch(actions.loyalty.setOffersLoading(false)));
  };

  const onPromoCodeRedeemSuccess = ({
    configOffer,
    personalizedOffer,
  }: {
    configOffer: LoyaltyOffer;
    personalizedOffer: LoyaltyOffer;
  }) => {
    skipAnimation.current = false;
    toast.show({
      text: formatMessage({ id: 'promoCodeAddedToOffers' }),
      variant: 'positive',
    });
    dispatch(actions.loyalty.unshiftCmsOffers([configOffer]));
    dispatch(actions.loyalty.unshiftPersonalizedOffer(personalizedOffer));
    // Since redux action is async, we need to wait for the state to be updated before we can
    // navigate to the page
    setTimeout(() => router.navigate(`/rewards/offers/${personalizedOffer.loyaltyEngineId}`), 500);
  };

  const canShowPromoCode = enableLoyaltyPromoCodes && !selectedIncentive;

  return (
    <LoyaltyIncentivesContainer
      accessible
      accessibilityRole="header"
      accessibilityLabel={formatMessage({ id: 'offers' })}
    >
      {canShowPromoCode && <LoyaltyPromoCode onPromoCodeRedeemSuccess={onPromoCodeRedeemSuccess} />}
      {enableLoyaltyOffersFilters && !selectedIncentive && (
        <LoyaltyOffersFilterButtons appliedFilters={appliedFilters} toggleFilter={toggleFilter} />
      )}
      <View flex={1} mx={selectedIncentive ? 0 : '$3'} marginTop={canShowPromoCode ? '$0' : '$4'}>
        {selectedIncentive ? (
          <IncentiveDetails engineIncentivesMap={{}} selectedIncentive={selectedIncentive} />
        ) : (
          <AnimatedFlatList
            ListHeaderComponent={
              <>
                <LoyaltyOffersCooldown />
                {
                  /* If it's a widget, don't allow to pull on reload */
                  offersInCart?.length ? (
                    <OffersInCart
                      offers={offersInCart}
                      handlePress={handlePress}
                      handleRemove={handleRemove}
                    />
                  ) : null
                }
              </>
            }
            ListFooterComponent={offers?.length ? <EndOfOffers /> : null}
            {...(isNative
              ? {
                  refreshControl: (
                    <RefreshControl refreshing={offersLoading} onRefresh={onRefresh} />
                  ),
                }
              : {})}
            ListEmptyComponent={renderListEmptyComponent}
            data={offers}
            initialNumToRender={5}
            keyExtractor={(item, index) => item.loyaltyEngineId || `${index}`}
            renderItem={renderItem}
          />
        )}
      </View>
    </LoyaltyIncentivesContainer>
  );
};
