import { useField } from 'formik';
import { Platform } from 'react-native';

import { addWithConfig } from '../../utils';
import { Checkbox } from '../checkbox';
import { usePropsWithFormikFormControlName } from '../formik-form-control';

import type { FormikCheckboxProps } from './types';

export const FormikCheckbox = (props: FormikCheckboxProps) => {
  const { name, children, checkboxProps, ...rest } = usePropsWithFormikFormControlName(props);
  const [field, meta, helpers] = useField(name);
  const isChecked = field.value;

  return (
    <Checkbox
      isInvalid={Boolean(meta.error)}
      isChecked={isChecked}
      value={field.value}
      onChange={(isSelected) => {
        helpers.setValue(isSelected);
        if (Platform.OS !== 'web') {
          // Will set touched on blur for web
          helpers.setTouched(true);
        }
      }}
      // @ts-ignore - web only
      onBlur={() => {
        if (Platform.OS === 'web') {
          helpers.setTouched(true);
        }
      }}
      {...rest}
      {...checkboxProps}
    >
      {children}
    </Checkbox>
  );
};

export default addWithConfig(FormikCheckbox);
