import { Box } from '@fhs-legacy/universal-components';

export const screenReaderOnly = Box.withConfig({
  height: '$0.5',
  margin: '-1px',
  overflow: 'hidden',
  padding: '0',
  position: 'absolute',
  width: '$0.5',
});
