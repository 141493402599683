import { Box, Header } from '@fhs-legacy/universal-components';
import { List } from 'components/list';

// TODO - RN implement box-shadow: ${Styles.boxShadow};
export const InnerBox = Box.withConfig({
  backgroundColor: Styles.color.white,
  flex: 1,
  width: {
    desktop: 'full',
  },
  margin: {
    desktop: 'auto',
  },
  maxWidth: {
    desktop: Styles.layout.smallerSectionMaxWidth,
  },
  borderRadius: {
    desktop: Styles.borderRadius,
  },
  minHeight: {
    desktop: 0,
  },
});

export const PageHeading = Header.withConfig({
  variant: 'headerTwo',
  textAlign: 'center',
  lineHeight: '32px',
});

export const PageSubHeading = Header.withConfig({
  variant: 'headerOne',
  textAlign: 'center',
  lineHeight: '32px',
});

export const SectionHeading = Header.withConfig({
  variant: 'headerTwo',
  textAlign: 'center',
  fontSize: '2xl',
  lineHeight: '2xl',
});

export const NeedHelpHeading = SectionHeading.withConfig({
  fontSize: 'xl',
  lineHeight: '0',
});

export const ContentLoadingContainer = Box.withConfig({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
});

export const ContentWrap = Box.withConfig({});

export const ButtonWrap = ContentWrap.withConfig({
  marginTop: '10',
  width: 'full',
  alignItems: 'center',
});

export const TitleWrap = ContentWrap.withConfig({
  marginY: '5',
});

export const MainWrap = ContentWrap.withConfig({
  paddingX: '5',
  marginTop: '0',
  marginBottom: '5',
});

export const ListStyled = List.withConfig({
  marginX: 6,
  showsVerticalScrollIndicator: false,
});
