import { Box, Text } from '@fhs-legacy/universal-components';

export const CheckoutErrorCellContainer = Box.withConfig({
  flexDirection: 'row',
});

export const Dot = Box.withConfig({
  marginX: 2,
  marginY: 2,
  height: 1,
  width: 1,
  backgroundColor: Styles.color.black,
  borderRadius: 'full',
});

export const ErrorText = Text.withConfig({
  lineHeight: 20,
  marginBottom: 4,
  textAlign: 'center',
});
