import { Box, Text } from '@fhs-legacy/universal-components';

export const ReloadWrapper = Box.withConfig({
  marginX: 0,
  marginY: '$4',
});

// Only used on TH component
export const ReloadExplanationText = Text.withConfig({});

export const InstructionText = Text.withConfig({
  marginTop: '$3',
  marginX: 0,
  marginBottom: '$2.5',
  fontSize: 'sm',
  width: '100%',
  padding: 0,
  fontWeight: '700',
});

export const LoadContainer = Box.withConfig({
  marginTop: '$6',
});

export const BoldText = Text.withConfig({
  fontWeight: 'bold',
});
