import { useIntl } from 'react-intl';

import { IconDialog } from '@fhs/ui';
import { Box } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';

export const PypestreamNeedHelpCTA = ({
  onPress,
  isLoading,
}: {
  onPress: () => void;
  isLoading: boolean;
}) => {
  const { formatMessage } = useIntl();

  return (
    // @ts-ignore: Fix: Expression produces a union type that is too complex to represent.ts(2590)
    <ActionButton
      testID="need-help-home-cta"
      borderRadius="12px"
      borderBottomRadius="0px"
      position="absolute"
      right="$0"
      bottom="$0"
      width="184px"
      leftIcon={
        <Box mt="$0" mr="$1">
          <IconDialog />
        </Box>
      }
      onPress={onPress}
      isLoading={isLoading}
      disabled={isLoading}
    >
      {formatMessage({ id: 'needHelp' })}
    </ActionButton>
  );
};
