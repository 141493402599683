import { router, useLocalSearchParams } from 'expo-router';
import { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';

import { ActionSheet, Text, useMqSelect } from '@fhs/ui';
import ConfirmDialog from '@fhs-legacy/frontend/src/components/confirm-dialog';
import {
  ClickEventComponentNames,
  CustomEventNames,
  EventTypes,
  useCRMEventsContext,
} from '@fhs-legacy/frontend/src/state/crm-events';

import { SimplyOfferClickEventLabels, SimplyOfferEventNames } from '../../analytics';
import { useOfferDetailUi } from '../../queries/loyalty.queries';
import { useIsOfferCartDirty, useOfferCart } from '../../state/offer-guide-cart-state';

import { ContentRenderer } from './content-renderer';
import { Navigation } from './navigation';

const initialExitDialog = {
  onDismiss: () => {},
  onConfirm: () => {},
  showDialog: false,
};

export function OfferGuideScreen() {
  const { offerId } = useLocalSearchParams<{ offerId: string }>();
  const setOffer = useOfferCart(state => state.setOffer);
  const reset = useOfferCart(state => state.reset);
  const { data: offer } = useOfferDetailUi(offerId);

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (offer) {
      setOffer(offer);
    }
  }, [setOffer, offer]);

  return <OfferGuideContent />;
}

export function OfferGuideContent() {
  const { offerId } = useLocalSearchParams<{ offerId: string }>();
  const { logRBIEvent } = useCRMEventsContext();
  const { height: screenHeight } = useWindowDimensions();
  const isDesktop = useMqSelect({ $gteDesktop: true }, false);
  const [confirmExitDialog, setConfirmExitDialog] = useState(initialExitDialog);

  const {
    currentStepIndex,
    goToPrevStep,
    goToStep,
    selectedEligibleItem,
    setSelectedEligibleItem,
    onBackAttempt,
    offerInfo,
  } = useOfferCart();

  const isDirty = useIsOfferCartDirty();

  const close = useCallback(() => {
    router.dismiss();
  }, []);

  const onClose = useCallback(() => {
    if (isDirty) {
      logRBIEvent({
        name: SimplyOfferEventNames.OFFER_GUIDE_EXIT_DIALOG_SHOWN,
        type: EventTypes.Other,
        attributes: { offerId, sanityId: offerInfo?.cmsId },
      });
      setConfirmExitDialog({
        showDialog: true,
        onConfirm: () => {
          setConfirmExitDialog(initialExitDialog);
          logRBIEvent({
            name: CustomEventNames.CLICK_EVENT,
            type: EventTypes.Other,
            attributes: {
              component: ClickEventComponentNames.BUTTON,
              text: SimplyOfferClickEventLabels.OFFER_GUIDE_LEAVE_OFFER,
            },
          });
          close();
        },
        onDismiss: () => {
          setConfirmExitDialog(initialExitDialog);
          logRBIEvent({
            name: CustomEventNames.CLICK_EVENT,
            type: EventTypes.Other,
            attributes: {
              component: ClickEventComponentNames.BUTTON,
              text: SimplyOfferClickEventLabels.OFFER_GUIDE_STAY_ON_OFFER,
            },
          });
        },
      });
      return;
    }
    close();
    return;
  }, [isDirty, close, logRBIEvent, offerId, offerInfo?.cmsId]);

  const onPressPrev = useCallback(() => {
    if (onBackAttempt) {
      const preventDefault = onBackAttempt();
      if (preventDefault) {
        return;
      }
    }
    if (selectedEligibleItem) {
      setSelectedEligibleItem(undefined);
      return;
    }
    if (currentStepIndex === 0) {
      onClose();
      return;
    }
    goToPrevStep();
    logRBIEvent({
      name: SimplyOfferEventNames.OFFER_GUIDE_WENT_PREV_STEP,
      type: EventTypes.Other,
      attributes: { offerId, sanityId: offerInfo?.cmsId },
    });
  }, [
    goToPrevStep,
    onClose,
    selectedEligibleItem,
    currentStepIndex,
    setSelectedEligibleItem,
    onBackAttempt,
    logRBIEvent,
    offerId,
    offerInfo?.cmsId,
  ]);

  const onPressStep = useCallback(
    (index: number) => {
      setSelectedEligibleItem(undefined);
      goToStep(index);
      logRBIEvent({
        name: SimplyOfferEventNames.OFFER_GUIDE_STEP_SELECTED,
        type: EventTypes.Other,
        attributes: { offerId, sanityId: offerInfo?.cmsId, stepIndex: index },
      });
    },
    [goToStep, setSelectedEligibleItem, logRBIEvent, offerId, offerInfo?.cmsId]
  );

  const containerStyle = isDesktop ? { height: screenHeight } : {};

  return (
    <ActionSheet
      onRequestClose={onPressPrev}
      isVisible
      onClose={close}
      containerStyle={{ height: '90%' }}
    >
      <View style={[styles.container, containerStyle]}>
        <Navigation onPressPrev={onPressPrev} onPressStep={onPressStep} onClose={onClose} />
        <ContentRenderer />
      </View>
      <ConfirmDialog
        modalAppearanceEventMessage="Confirm Close Offer Selection"
        showDialog={confirmExitDialog.showDialog}
        onConfirm={confirmExitDialog.onDismiss}
        onDismiss={confirmExitDialog.onDismiss}
        onCancel={confirmExitDialog.onConfirm}
        confirmLabel="Stay Here"
        cancelLabel="Leave"
        bodyComponent={
          <Text style={{ marginBottom: 20 }}>
            The offer won't be applied and you are going to lose all your choices and
            customizations.
          </Text>
        }
        heading="Are You Sure You Want to Leave?"
      />
    </ActionSheet>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
});
