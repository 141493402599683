import { useCallback, useMemo, useState } from 'react';

import { useListOffersByUserIdQuery } from 'generated/rbi-graphql';
import { useMemoWithCompare } from 'hooks/use-memo-with-compare';
import { UserDetails } from 'state/auth/hooks/use-current-user';
import { IDayPartBoundary } from 'state/day-part/hooks/use-active-day-parts';
import { useActivateOfferMutation } from 'state/graphql/hooks/use-activate-offer-mutation';
import { LaunchDarklyFlag } from 'state/launchdarkly';
import { Query } from 'state/network/hook';
import { ServiceMode } from 'state/service-mode';
import logger from 'utils/logger';
import noop from 'utils/noop';

import { UIPattern } from '../types';
import {
  combineSortedOffersWithAvailableOfferCounts,
  countAvailableOffersByCouponId,
} from '../util';
import sortOffersToDisplay from '../util/sortOffersToDisplay';

import { shouldSkipOffersQuery } from './constants';
import useLockedOffers from './use-locked-offers';
import useOffersList from './use-offer-list';
import useOfferRedemption from './use-offer-redemption';
import useOfferUnavailableModal from './use-offer-unavailable-modal';
import usePromoCodeOffers from './use-promo-code-offers';
import useSelectedOffer from './use-selected-offer';

interface IUseOffersProps {
  activeDayParts: IDayPartBoundary[];
  query: Query;
  serviceMode: ServiceMode | null;
  user: UserDetails | null;
}

const useOffers = ({ query, serviceMode, user }: IUseOffersProps) => {
  const [activateOfferMutation] = useActivateOfferMutation();
  const [redemptionInProgress, setRedemptionInProgress] = useState(false);
  const [upsellOfferId, setUpsellOfferId] = useState<string | null>(null);

  const {
    data: offersByUserId,
    refetch: refetchOffersByUserIdOriginal,
    loading: offersByUserIdLoading,
  } = useListOffersByUserIdQuery({
    fetchPolicy: 'cache-and-network',
    skip: !user || shouldSkipOffersQuery,
  });

  const refetchOffersByUserId = user ? refetchOffersByUserIdOriginal : noop;
  const isAuthenticated = !!user?.cognitoId;

  const {
    offers: rawOffers,
    offersFeedback,
    queryRedeemableOffers,
    redeemUnauthenticatedOffer,
    refetch: refetchOffersList,
    standardOffers,
    updatingOffers,
  } = useOffersList({
    serviceMode,
    isAuthenticated,
    skip: !user && shouldSkipOffersQuery,
  });

  /**
   * combine sortedOffers with counts for all offer lists to consume
   */
  const prememoSortedOffers = useMemo(
    () =>
      combineSortedOffersWithAvailableOfferCounts(
        rawOffers,
        (offersByUserId && countAvailableOffersByCouponId(offersByUserId)) || {}
      ),
    [rawOffers, offersByUserId]
  );

  const sortedOffers = useMemoWithCompare(() => prememoSortedOffers, [prememoSortedOffers]);

  const refreshOffers = useCallback(
    /**
     * Refetch sorted offers, offersByUserId, and evaluateUserOffers in parallel
     */
    async () => {
      try {
        if (isAuthenticated) {
          // queryRedeemableOffers will trigger refetchOffersList but only for auth user
          await Promise.all([refetchOffersByUserId() as any, queryRedeemableOffers()]);
        } else {
          await Promise.all([refetchOffersList(), refetchOffersByUserId() as any]);
        }
      } catch (error) {
        // TODO: retry or error state
        logger.error({ error, message: 'Refresh Offers Error' });
      }
      return;
    },
    [queryRedeemableOffers, refetchOffersByUserId, refetchOffersList, isAuthenticated]
  );

  const activateOffer = useCallback(
    /**
     * Activates an offer
     *
     * @param tokenId pulled from offerFeedback
     */
    async (tokenId: string) => {
      try {
        const resp = await activateOfferMutation({ variables: { tokenId } });
        const isOfferActivated = resp?.data?.activateLoyaltyOffer || false;
        return { isOfferActivated };
      } catch (error) {
        logger.error({ error, message: 'Activate Offer Error' });
        throw error;
      } finally {
        refreshOffers();
      }
    },
    [activateOfferMutation, refreshOffers]
  );

  const { lockedOffers } = useLockedOffers({
    sortedOffers,
    offersFeedback,
  });

  /**
   * promo code offers are separated out because we want to be able to
   *  update their redeemable state without refetching all the offers
   */
  const { promoCodeOffers, togglePromoCodeOfferRedeemable } = usePromoCodeOffers({
    sortedOffers,
    offersFeedback,
    uiPattern: UIPattern.PROMO,
    launchDarklyFlag: LaunchDarklyFlag.ENABLE_PROMO_CODE_OFFERS,
  });

  /**
   * cart promo code offers are separated out because they are not
   *  displayed on the offers page and have a completely separate UX
   */
  const { promoCodeOffers: cartPromoCodeOffers } = usePromoCodeOffers({
    sortedOffers,
    offersFeedback,
    uiPattern: UIPattern.CART_PROMO,
    launchDarklyFlag: LaunchDarklyFlag.ENABLE_PROMO_CODE_AT_CHECKOUT,
  });

  /**
   * offersToDisplay combines the base sorted offers (either from FE or BE)
   *  as well as any promo code offers. This effect listens for any changes
   *  to either the main offers or promo code offers and updates the offersToDisplay
   * example of change: a user unlocks a promo code offer. instead of re-fetching all
   *  offers to get the updated unlocked state, we just update the state on the FE
   *  and update this setOffersToDisplay state.
   */
  const offers = useMemoWithCompare(
    () => sortOffersToDisplay(sortedOffers, standardOffers, promoCodeOffers),
    [promoCodeOffers, sortedOffers, standardOffers]
  );

  const {
    clearSelectedOffer,
    isSelectedOfferCartEntry,
    selectOfferById,
    selectedOffer,
    selectedOfferCartEntry,
    selectedOfferPrice,
    setSelectedOfferCartEntry,
    setSelectedOfferPrice,
  } = useSelectedOffer({
    offers,
    lockedOffers,
    cartPromoCodeOffers,
  });

  const {
    offerValidationErrors,
    onCancelRedemption,
    onConfirmRedemption,
    onSelectMobileRedemption,
    onSelectRestaurantRedemption,
    redemptionMethod,
    redeemedOffer,
    setRedeemedOffer,
    setOfferValidationErrors,
    setRedemptionMethod,
    validateOfferRedeemable,
  } = useOfferRedemption({
    clearSelectedOffer,
    query,
    queryRedeemableOffers,
    redeemUnauthenticatedOffer,
    user,
  });

  const [OfferUnavailableDialog, openOfferUnavailableDialog] = useOfferUnavailableModal({
    onCancelRedemption,
  });

  return {
    OfferUnavailableDialog,
    clearSelectedOffer,
    isSelectedOfferCartEntry,
    loading: updatingOffers || offersByUserIdLoading,
    offers,
    offersFeedback,
    offerValidationErrors,
    lockedOffers,
    promoCodeOffers,
    togglePromoCodeOfferRedeemable,
    cartPromoCodeOffers,
    onCancelRedemption,
    onConfirmRedemption,
    onSelectMobileRedemption,
    onSelectRestaurantRedemption,
    openOfferUnavailableDialog,
    redemptionMethod,
    redeemedOffer,
    setRedeemedOffer,
    refetchOffersList,
    selectOfferById,
    selectedOffer,
    selectedOfferCartEntry,
    selectedOfferPrice,
    setOfferValidationErrors,
    setRedemptionMethod,
    setSelectedOfferCartEntry,
    setSelectedOfferPrice,
    queryRedeemableOffers,
    validateOfferRedeemable,
    refreshOffers,
    activateOffer,
    redemptionInProgress,
    setRedemptionInProgress,
    upsellOfferId,
    setUpsellOfferId,
    updatingOffers,
  };
};

export default useOffers;
