import React from 'react';
import { useIntl } from 'react-intl';

import { Header } from '@fhs-legacy/universal-components';
import BlockRenderer from 'components/sanity-block-renderer';
import { PromotionType } from 'generated/graphql-gateway';
import { Maybe } from 'generated/sanity-graphql';
import { parseIncentiveData } from 'pages/loyalty/loyalty-incentives-components/incentive-card/parse-incentive-data';
import { useLoyaltyIncentivesAvailability } from 'pages/loyalty/loyalty-incentives-components/use-loyalty-incentives-availability';
import { selectors, useAppSelector } from 'state/global-state';
import { IIncentiveEvaluationResult } from 'state/loyalty/hooks/types';
import { getEvaluationResultMessage } from 'state/loyalty/hooks/utils/helpers';
import { LoyaltyOffer } from 'state/loyalty/types';
import { getCmsOffersMapByCmsId } from 'state/loyalty/utils';

import { IncentiveErrorHead } from './incentive-error-head';
import { IncentiveContainer, IncentiveStatus } from './styled';

interface IncentiveOfferHeadProps {
  loyaltyOfferId?: Maybe<string>;
  cmsOfferId?: Maybe<string>;
}

export function IncentiveOfferHead({ loyaltyOfferId, cmsOfferId }: IncentiveOfferHeadProps) {
  const formatters = useIntl();
  const { formatMessage } = formatters;
  const cmsOffers = useAppSelector(selectors.loyalty.selectCmsOffers);
  const cmsOffersMap = getCmsOffersMapByCmsId(cmsOffers);
  const selectedOffer: LoyaltyOffer | undefined = cmsOffersMap[cmsOfferId || ''];
  const offersFeedbackMap = useAppSelector(selectors.loyalty.selectOfferFeedbackMap);
  const { checkLoyaltyOfferAvailability } = useLoyaltyIncentivesAvailability();

  const isSelectedLoyaltyOfferAvailable = checkLoyaltyOfferAvailability(selectedOffer);

  const offerFeedback: IIncentiveEvaluationResult[] | undefined =
    offersFeedbackMap[loyaltyOfferId || ''];
  const offerFeedbackContainErrors = offerFeedback && offerFeedback.length > 0;
  const isOfferApplied = !offerFeedbackContainErrors && isSelectedLoyaltyOfferAvailable;
  if (selectedOffer && isOfferApplied) {
    const incentiveData = parseIncentiveData(selectedOffer);

    return (
      <IncentiveContainer>
        <IncentiveStatus>{formatMessage({ id: 'offerApplied' })}</IncentiveStatus>
        <Header variant="headerTwo">{incentiveData.name}</Header>
        {incentiveData.description && (
          <BlockRenderer marginBottom="$2" content={incentiveData.description} />
        )}
      </IncentiveContainer>
    );
  }

  const offerInvalidStatusMessage = formatMessage({
    id: 'offerInvalid',
  });
  if (selectedOffer && offerFeedbackContainErrors) {
    const incentiveData = parseIncentiveData(selectedOffer);
    return (
      <IncentiveErrorHead
        title={incentiveData.name}
        description={getEvaluationResultMessage({
          evaluationResult: offerFeedback[0],
          formatters,
          incentiveType: PromotionType.OFFER,
        })}
        statusMessage={offerInvalidStatusMessage}
      />
    );
  }

  return (
    <IncentiveErrorHead
      description={formatMessage({ id: 'offerNotAvailableInRestaurantPos' })}
      statusMessage={offerInvalidStatusMessage}
    />
  );
}
