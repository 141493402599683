import {
  LoyaltyOfferRedemptionType,
  LoyaltyServiceMode,
  useGetLoyaltyOffersQuery,
} from 'generated/graphql-gateway';
import { useAuthContext } from 'state/auth';
import { useOrderContext } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';

/**
 * Hit the engine with the most common context.
 */
export const useGetLoyaltyOffersQueryWithContext = ({
  variables: { redemptionTypes = [LoyaltyOfferRedemptionType.STANDARD], ids } = {},
  skip,
  notifyOnNetworkStatusChange,
}: {
  variables?: {
    ids?: string[];
    redemptionTypes?: LoyaltyOfferRedemptionType[];
  };
  skip?: boolean;
  notifyOnNetworkStatusChange?: boolean;
} = {}) => {
  const { loading: authLoading, user } = useAuthContext();
  const { store } = useStoreContext();
  const { serviceMode } = useServiceModeContext();
  const { calculateCartTotal } = useOrderContext();

  const storeId = store?.number;
  const subtotalAmount: number = calculateCartTotal();

  return useGetLoyaltyOffersQuery({
    variables: {
      loyaltyId: user?.loyaltyId,
      where: {
        ids,
        serviceMode: LoyaltyServiceMode[serviceMode || ''] || undefined,
        redemptionTypes,
        storeId,
        subtotalAmount,
        omitInvalids: false,
      },
    },
    skip: skip || authLoading,
    notifyOnNetworkStatusChange,
  });
};
