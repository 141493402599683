import React, { useEffect, useRef } from 'react';
import { Animated, StyleProp, StyleSheet, ViewStyle } from 'react-native';

export type SkeletonProps = {
  style?: StyleProp<ViewStyle>;
};

export const Skeleton = ({ style = {} }: SkeletonProps) => {
  const opacityRef = useRef(new Animated.Value(1));

  useEffect(() => {
    const pulseAnimation = Animated.loop(
      Animated.sequence([
        Animated.timing(opacityRef.current, {
          toValue: 0.5,
          duration: 500,
          useNativeDriver: true,
        }),
        Animated.timing(opacityRef.current, {
          toValue: 1,
          duration: 500,
          useNativeDriver: true,
        }),
      ])
    );

    pulseAnimation.start();

    return () => {
      pulseAnimation.stop();
    };
  }, []);

  return <Animated.View style={[styles.skeleton, { opacity: opacityRef.current }, style]} />;
};

const styles = StyleSheet.create({
  skeleton: {
    backgroundColor: '#ccc',
    height: 12,
    borderRadius: 100,
  },
});
