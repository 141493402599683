import { Box } from '@fhs-legacy/universal-components';

export const StyledButtonWidthLimit = Box.withConfig({
  width: {
    desktop: '335px',
  },
  marginX: {
    desktop: 'auto',
  },
});

export const TextInputWrapper = Box.withConfig({
  paddingX: '1',
  width: 'full',
});

export const StyledBoxActionButton = Box.withConfig({
  marginTop: '4',
});

export const CheckBoxWrapper = Box.withConfig({
  marginLeft: '$1',
  marginTop: '$3',
});
