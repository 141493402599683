import { router, useGlobalSearchParams } from 'expo-router';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useWindowDimensions } from 'react-native';

import { useIsDesktopBp } from 'hooks/breakpoints';
import { useConfigValue } from 'hooks/configs/use-config-value';
import useEffectOnce from 'hooks/use-effect-once';
import { usePrevious } from 'hooks/use-previous';
import { useAuthContext } from 'state/auth';
import { withForterHeaders } from 'state/graphql/links/with-forter-headers';
import { useLocale } from 'state/intl';
import { NAV_HEIGHT } from 'styles/constants/enums';
import { routes } from 'utils/routing';

import {
  BRAND,
  PYPESTREAM_INTERFACE_ID,
  PYPESTREAM_MOUNTED,
  PYPESTREAM_SCRIPT_ID,
  REDIRECT_TO_LOGIN,
  STAGE,
} from './constants';
import { IPypestreamContext } from './types';

declare global {
  interface Window {
    Pypestream?: {
      isVisible: () => boolean;
      shutdown: any;
      boot: (arg1: { [key: string]: string | boolean | object }, arg2: HTMLElement | null) => void;
      updatePypestreamConfig: (arg1: { [key: string]: string | boolean | object }) => void;
      onMount: (arg: Function) => void;
      show: () => void;
    };
  }
}

export const PypestreamContext = createContext<IPypestreamContext>({} as IPypestreamContext);

export const usePypestreamContext = () => useContext<IPypestreamContext>(PypestreamContext);

export const PypestreamProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [redirectToHomePage, setRedirectToHomePage] = useState<boolean>(false);
  const [mountPanelAfterSignIn, setMountPanelAfterSignIn] = useState<boolean>(false);

  const { formatMessage } = useIntl();
  const { language, feCountryCode: isoCountryCode } = useLocale();
  const { user, isAuthenticated, setOriginLocation } = useAuthContext();
  const params = useGlobalSearchParams();
  const isDesktop = useIsDesktopBp();
  const { height: screenHeight } = useWindowDimensions();
  const { scriptUrl, appId } = useConfigValue({ key: 'pypestream' });

  const name = user?.details?.name ?? '';
  const rbiOrderId = params.orderId ?? '';
  const id = `pypestreamWelcome${name ? 'User' : 'Anon'}`;
  const welcomeMessage = formatMessage({ id }, { brand: BRAND, name });
  const cognitoId = user?.cognitoId ?? '';
  const forterDeviceId = withForterHeaders?.deviceId ?? '';
  const forterToken = withForterHeaders?.ttiForterToken ?? '';

  const previousCognitoId = usePrevious(cognitoId) ?? '';
  const cognitoIdHasChanged = cognitoId !== previousCognitoId;
  const holdLoaderWhileAuthResolves = isAuthenticated && !cognitoId;

  const passthru = useMemo(
    () => [
      { attribute: 'stage', label: 'stage', value: STAGE, visible: 'wpm' },
      {
        attribute: 'forterDeviceId',
        label: 'forterDeviceId',
        value: forterDeviceId,
        visible: 'wpm',
      },
      { attribute: 'forterToken', label: 'forterToken', value: forterToken, visible: 'wpm' },
      {
        attribute: 'isoCountryCode',
        label: 'isoCountryCode',
        value: isoCountryCode,
        visible: 'wpm',
      },
      { attribute: 'isRN', label: 'isRN', value: true, visible: 'wpm' },
      {
        attribute: 'userAgent',
        label: 'userAgent',
        value: window.navigator.userAgent,
        visible: 'wpm',
      },
      { attribute: 'rbiOrderId', label: 'orderId', value: rbiOrderId, visible: 'wpm' },
    ],
    [forterDeviceId, forterToken, isoCountryCode, rbiOrderId]
  );

  useEffectOnce(() => {
    function listenForMessage(event: MessageEvent<any>) {
      if (
        !isAuthenticated &&
        event.origin?.endsWith('pypestream.com') &&
        event.data === REDIRECT_TO_LOGIN
      ) {
        // user is not logged in,
        // this makes it so that signing in will redirect the user back to support or home
        if (isDesktop) {
          setOriginLocation(routes.base);
          setMountPanelAfterSignIn(true);
          router.navigate(routes.signIn);
        } else {
          resetAndUnmountPanel();
          setOriginLocation(routes.support);
          router.replace(routes.signIn);
        }
      }
      if (event.data.type === 'CHAT_HIDE') {
        setIsHidden(true);
      }
      if (event.data.type === PYPESTREAM_MOUNTED && !holdLoaderWhileAuthResolves) {
        setIsLoading(false);
        if (cognitoId) {
          setOriginLocation(null);
        }
      }
    }
    window.addEventListener('message', listenForMessage, false);
    return () => {
      window.removeEventListener('message', listenForMessage);
      window.Pypestream?.shutdown();
    };
  });

  const resetAndUnmountPanel = useCallback(() => {
    setIsLoading(false);
    setIsHidden(true);
    setIsMounted(false);
    setRedirectToHomePage(false);
    window.Pypestream?.shutdown();
  }, []);

  useEffect(() => {
    if (cognitoIdHasChanged) {
      if (cognitoId) {
        setOriginLocation(null);
      } else {
        // the panel needs to be unmounted on desktop (if already) and on mobile always to re-start the chat
        if (!isDesktop || (isDesktop && isMounted)) {
          resetAndUnmountPanel();
        }
      }
    }
  }, [
    cognitoId,
    cognitoIdHasChanged,
    isDesktop,
    isMounted,
    resetAndUnmountPanel,
    setOriginLocation,
  ]);

  const handlePanel = useCallback(() => {
    if (!isMounted) {
      setIsLoading(true);
      window.Pypestream?.boot(
        {
          APP_ID: appId,
          disableWidgetSlideInOutAnimation: true,
          initialMessages: {
            isPersistent: true,
            showNextThinkingBubble: true,
            welcomeMessage,
          },
          language,
          passthrough: JSON.stringify(passthru),
          play_chime: false,
          token: cognitoId,
          withInlineAppLoader: false,
        },
        document.getElementById(PYPESTREAM_INTERFACE_ID)
      );
      window.Pypestream?.onMount(() => {
        setIsLoading(false);
        setIsHidden(false);
        setIsMounted(true);
        setRedirectToHomePage(false);
        setMountPanelAfterSignIn(false);
        // Sets the correct height once the panel is mounted - only for mobile
        if (!isDesktop) {
          document
            .getElementById('pypestream-wrapper')
            ?.style.setProperty('height', `${screenHeight - NAV_HEIGHT.MOBILE}px`, 'important');
        }
      });
      return;
    }
    if (isHidden) {
      window.Pypestream?.show();
      setIsHidden(false);
    }
  }, [
    appId,
    cognitoId,
    isDesktop,
    isHidden,
    isMounted,
    language,
    passthru,
    screenHeight,
    welcomeMessage,
  ]);

  const handleOpenPanel = useCallback(() => {
    if (!document.getElementById(PYPESTREAM_SCRIPT_ID)) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `${scriptUrl}?ts=${Date.now()}`;
      script.async = true;
      script.id = PYPESTREAM_SCRIPT_ID;
      script.onload = () => handlePanel();
      document.head.appendChild(script);
    } else {
      setIsLoading(false);
      setRedirectToHomePage(false);
      handlePanel();
    }
  }, [handlePanel, scriptUrl]);

  const value = useMemo(
    () => ({
      isLoading,
      isHidden,
      isMounted,
      handlePanel,
      resetAndUnmountPanel,
      handleOpenPanel,
      redirectToHomePage,
      setRedirectToHomePage,
      mountPanelAfterSignIn,
      setMountPanelAfterSignIn,
    }),
    [
      isLoading,
      isHidden,
      isMounted,
      handlePanel,
      resetAndUnmountPanel,
      handleOpenPanel,
      redirectToHomePage,
      mountPanelAfterSignIn,
    ]
  );
  return <PypestreamContext.Provider value={value}>{children}</PypestreamContext.Provider>;
};
