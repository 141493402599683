import {
  IPickerItem,
  IQuickConfigRule,
  ISanityItemOption,
  ISanityItemOptionModifier,
} from '@rbi-ctg/menu';

export interface IUIRule {
  itemOption: ISanityItemOption;
  modifier: ISanityItemOptionModifier;
}

export const rulesForItem = (
  rules: IQuickConfigRule[],
  pickerSelection: IPickerItem = {} as IPickerItem
): IUIRule[] =>
  pickerSelection.options && pickerSelection._type === 'item'
    ? rules.reduce<IUIRule[]>((acc, { itemOptions, modifier: ruleModifier }) => {
        const itemOption = pickerSelection.options.find(
          ({ _key }) => !!itemOptions.find(({ value }) => value === _key)
        );

        if (!itemOption) {
          return acc;
        }

        const modifier = itemOption.options.find(({ _key }) => _key === ruleModifier.value);

        if (!modifier) {
          return acc;
        }

        return acc.concat({
          itemOption,
          modifier,
        });
      }, [])
    : [];
