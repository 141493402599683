import { isBefore, subMinutes } from 'date-fns';
import { useCallback } from 'react';

import { IHistoricalOrder, IServerOrder } from '@rbi-ctg/menu';
import { IGetUserOrderQuery } from 'generated/rbi-graphql';
import { IDayPartBoundary } from 'state/day-part/hooks/use-active-day-parts';

import { getDateRoundUpToNearestFiveMinutes } from '../utils';

interface IUseOrderTimedFire {
  serverOrder: IGetUserOrderQuery['order'] | IServerOrder | IHistoricalOrder | null;
  cartEntriesActiveDayParts?: IDayPartBoundary[];
}

// Stop accepting orders 10 minutes before daypart ends
const DAY_PART_END_AHEAD_MINUTES = 10;

export const useOrderTimedFireLazy = ({
  cartEntriesActiveDayParts,
}: Pick<IUseOrderTimedFire, 'cartEntriesActiveDayParts'>) => {
  const isPickupTimeAvailable = useCallback(
    (date: any) => {
      if (!cartEntriesActiveDayParts) {
        return false;
      }
      return !cartEntriesActiveDayParts.some(cartEntryMenuItemDayPart => {
        const dayPartLastCall = subMinutes(
          new Date(cartEntryMenuItemDayPart.endTime),
          DAY_PART_END_AHEAD_MINUTES
        );
        return isBefore(dayPartLastCall, date);
      });
    },
    [cartEntriesActiveDayParts]
  );

  return useCallback(
    ({ serverOrder }: Pick<IUseOrderTimedFire, 'serverOrder'>) => {
      /**
       * Defines the pickup time based on the order created date and fireOrderIn, rounding up to the nearest five minutes slot
       * fireOrderIn - the number of seconds after the order is placed when it should be released to kitchen
       */

      let pickupDate = new Date();
      if (serverOrder?.createdAt) {
        pickupDate = new Date(serverOrder?.createdAt);

        // Converts fireOrderInToMs from seconds to milliseconds
        const fireOrderInToMs = (serverOrder?.fireOrderIn || 0) * 1000;

        pickupDate = getDateRoundUpToNearestFiveMinutes(
          new Date(pickupDate.getTime() + fireOrderInToMs)
        );
      }

      return {
        pickupDate,
        isPickupTimeAvailable,
      };
    },
    [isPickupTimeAvailable]
  );
};

export const useOrderTimedFire = ({
  serverOrder,
  cartEntriesActiveDayParts,
}: IUseOrderTimedFire) => {
  return useOrderTimedFireLazy({ cartEntriesActiveDayParts })({
    serverOrder,
  });
};
