import { useCallback, useEffect } from 'react';

import { useLocalStorageState } from 'hooks/use-local-storage-state';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import { StorageKeys } from 'utils/local-storage';

import { addLegacyOffer, addOffer, addReward } from './add-in-restaurant-cart-entry';
import { MIN_IN_RESTAURANT_CART_QUANTITY } from './constants';
import {
  ICart,
  ICartEntry,
  ICartEntryType,
  createCartEntry,
  existCartEntry,
  filterCartEntries,
  removeCartEntry,
  updateCartEntry,
} from './incentive-cart';
import { IUseInRestaurantCart } from './types';
import { isCartEntryQuantityValid } from './utils';

/**
 * useInRestaurantCart holds the cart state and expose an API to interact with.
 */
export const useInRestaurantCart = (): IUseInRestaurantCart => {
  const { ldUserIsAuthenticated } = useLDContext();
  const enableLoyaltyStandardOffers = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_STANDARD_OFFERS);
  const [cart, updateCart, resetCart] = useLocalStorageState<ICart>({
    key: StorageKeys.IN_RESTAURANT_REDEMPTION,
    defaultReturnValue: [],
  });
  const [lastModificationDate, updateLastModificationDate, clearLastModificationDate] =
    useLocalStorageState<Date | undefined>({
      key: StorageKeys.IN_RESTAURANT_REDEMPTION_LAST_MOD,
      defaultReturnValue: undefined,
    });

  const existEntryTypeInRestaurantCart: IUseInRestaurantCart['existEntryTypeInRestaurantCart'] =
    useCallback(type => existCartEntry(cart, (ce: ICartEntry) => ce.type === type), [cart]);

  // Cart Entry CRUD
  const addInRestaurantCartEntry: IUseInRestaurantCart['addInRestaurantCartEntry'] = useCallback(
    item => {
      const newCartEntry = createCartEntry(item, {
        initialQuantity: MIN_IN_RESTAURANT_CART_QUANTITY,
      });
      let isItemAdded = true;
      updateCart(previousCart => {
        let cartResult = previousCart;
        switch (newCartEntry?.type) {
          case ICartEntryType.REWARD: {
            cartResult = addReward(previousCart, newCartEntry);
            break;
          }
          case ICartEntryType.OFFER: {
            cartResult = addOffer(previousCart, newCartEntry);
            break;
          }
          case ICartEntryType.LEGACY_OFFER: {
            cartResult = addLegacyOffer(previousCart, newCartEntry);
            break;
          }
          default:
        }
        // the cart is updated if the item was added correctly
        isItemAdded = cartResult !== previousCart;

        return isItemAdded ? cartResult : previousCart;
      });

      updateLastModificationDate(new Date());
      return isItemAdded ? newCartEntry : null;
    },
    [updateCart, updateLastModificationDate]
  );

  const removeInRestaurantCartEntry: IUseInRestaurantCart['removeInRestaurantCartEntry'] =
    useCallback(
      cartEntry => {
        updateCart(prevCart => removeCartEntry(prevCart, cartEntry));
        clearLastModificationDate();
      },
      [clearLastModificationDate, updateCart]
    );

  const removeTypeFromCart = useCallback(
    (typeToRemove: ICartEntryType) => {
      updateCart(prevCart => filterCartEntries(prevCart, ce => ce.type !== typeToRemove));
    },
    [updateCart]
  );

  const clearInRestaurantCartAllRewards: IUseInRestaurantCart['clearInRestaurantCartAllRewards'] =
    useCallback(() => {
      removeTypeFromCart(ICartEntryType.REWARD);
    }, [removeTypeFromCart]);

  const updateInRestaurantCartEntryQuantity: IUseInRestaurantCart['updateInRestaurantCartEntryQuantity'] =
    useCallback(
      (cartEntry, quantity) => {
        if (cartEntry.type === ICartEntryType.REWARD && isCartEntryQuantityValid(quantity)) {
          updateCart(prevCart => updateCartEntry(prevCart, cartEntry, { quantity }));
        }
      },
      [updateCart]
    );

  /**
   * The hook propose is legacy compatibility, the only dependencies are the flag
   * `enableOffers3_0` and `ldUserIsAuthenticated` because avoiding memoizing functions
   * the only received updates should be related to the flags.
   */
  useEffect(() => {
    // LD targeting issues, here we are assuming that the feature is available only for logged in users
    if (!ldUserIsAuthenticated) {
      return;
    }

    const offerTypeToRemove = enableLoyaltyStandardOffers
      ? ICartEntryType.LEGACY_OFFER
      : ICartEntryType.OFFER;

    if (existEntryTypeInRestaurantCart(offerTypeToRemove)) {
      removeTypeFromCart(offerTypeToRemove);
    }
  }, [
    enableLoyaltyStandardOffers,
    existEntryTypeInRestaurantCart,
    ldUserIsAuthenticated,
    removeTypeFromCart,
  ]);

  return {
    inRestaurantCart: cart,
    addInRestaurantCartEntry,
    removeInRestaurantCartEntry,
    updateInRestaurantCartEntryQuantity,
    resetInRestaurantCart: resetCart,
    existEntryTypeInRestaurantCart,
    clearInRestaurantCartAllRewards,
    isInRestaurantCartEmpty: !cart?.length,
    lastModificationDate,
    updateLastModificationDate,
    removeTypeFromCart,
  };
};
