import { WatchQueryFetchPolicy } from '@apollo/client';

import { IHistoricalOrder } from '@rbi-ctg/menu';
import {
  IGetUserRecentOrderQueryVariables,
  IRecentOrder,
  useGetUserRecentOrderQuery,
} from 'generated/rbi-graphql';
import { useAuthContext } from 'state/auth';

export const useGetUserRecentOrder = (
  variables: IGetUserRecentOrderQueryVariables,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
  skip?: boolean
) => {
  const { isAuthenticated } = useAuthContext();
  const { data, error, loading, refetch, fetchMore } = useGetUserRecentOrderQuery({
    variables: {
      ...variables,
    },
    context: { shouldNotBatch: true },
    skip: !isAuthenticated || skip,
    fetchPolicy,
  });

  const recentOrder = data?.userRecentOrder as unknown as IRecentOrder;
  const historicalOrder = recentOrder as unknown as IHistoricalOrder;

  return {
    error,
    loading,
    recentOrder,
    historicalOrder,
    storeName: recentOrder?.storeName,
    orderType: recentOrder?.orderType,
    refetch,
    fetchMore,
  };
};
