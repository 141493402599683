import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { PromotionType } from 'generated/graphql-gateway';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { usePrevious } from 'hooks/use-previous';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { parseEntry } from 'state/global-state/models/loyalty/offers/offers.utils';
import { updateAppliedRewardsInStorage } from 'state/global-state/models/loyalty/rewards/rewards.utils';
import {
  IEvaluateLoyaltyUserRewardsParams,
  IUseLoyaltyRewards,
  UseLoyaltyRewards,
} from 'state/loyalty/hooks/types';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';

import useLoyaltyRewardsEvaluation from './use-loyalty-rewards-evaluation';

export const useLoyaltyRewards: UseLoyaltyRewards = (loyaltyUser): IUseLoyaltyRewards => {
  const { refetchRewards, engineRewardsMap } = useLoyaltyRewardsList();

  const [shouldApplyStorageRewards, setShouldApplyStorageRewards] = useState(false);
  const dispatch = useAppDispatch();
  const availableLoyaltyRewardsMap = useAppSelector(
    selectors.loyalty.selectAvailableLoyaltyRewardsMap
  );

  const loyaltyUserId = loyaltyUser?.id;
  const previousLoyaltyUserId = usePrevious(loyaltyUserId);

  useEffect(() => {
    // loyalty user id will populate upon logging in / initial page load
    if (!previousLoyaltyUserId && loyaltyUserId) {
      setShouldApplyStorageRewards(true);
    }
    // occurs when a user logs out
    else if (previousLoyaltyUserId && !loyaltyUserId) {
      updateAppliedRewardsInStorage({});
    }
  }, [previousLoyaltyUserId, loyaltyUserId]);

  useEffect(() => {
    if (!isEmpty(availableLoyaltyRewardsMap) && shouldApplyStorageRewards) {
      dispatch(actions.loyalty.rehydrateAppliedReward());
      setShouldApplyStorageRewards(false);
    }
  }, [availableLoyaltyRewardsMap, dispatch, shouldApplyStorageRewards]);

  const { store } = useStoreContext();
  const storeId = store?.number;
  const { serviceMode } = useServiceModeContext();
  const { evaluateLoyaltyRewardsQuery } = useLoyaltyRewardsEvaluation();
  const evaluateLoyaltyUserRewards = useCallback(
    async ({
      loyaltyId,
      appliedLoyaltyRewards,
      cartEntries,
      subtotalAmount,
      paymentMethod,
    }: IEvaluateLoyaltyUserRewardsParams) => {
      const appliedIncentives = Object.values(appliedLoyaltyRewards).map(({ rewardId }) => ({
        id: rewardId,
        type: PromotionType.REWARD,
      }));
      const appliedRewardsIds = appliedIncentives.map(({ id }) => id);
      const cartEntriesAdapter = cartEntries?.reduce(parseEntry(appliedLoyaltyRewards), []);

      const evaluationResult = await evaluateLoyaltyRewardsQuery({
        loyaltyId,
        appliedIncentives,
        cartEntries: cartEntriesAdapter,
        rewardIds: appliedRewardsIds,
        paymentMethod,
        serviceMode,
        storeId,
        subtotalAmount,
      });

      return evaluationResult;
    },
    [evaluateLoyaltyRewardsQuery, serviceMode, storeId]
  );

  return { evaluateLoyaltyUserRewards, refetchRewards, engineRewardsMap };
};
