import { ICombo, IComboSlotSelections, IItem } from '@rbi-ctg/menu';
import { IModifierSelectionAction } from 'utils/wizard';

const SODIUM_LIMIT = 2300;

interface IComputeSodiumArgs {
  selectedOpt: ICombo | IItem;
  comboSlotSelections: IComboSlotSelections | undefined;
  modifierSelections: IModifierSelectionAction[] | undefined;
}

export function computeSodium({
  selectedOpt,
  comboSlotSelections,
  modifierSelections,
}: IComputeSodiumArgs): number {
  let sodium = 0;
  //sodium for selected option mainItem
  if (selectedOpt._type === 'combo' && selectedOpt.mainItem) {
    sodium += selectedOpt?.mainItem?.nutrition?.sodium || 0;
  }

  //sodium for each combo slot selection
  if (comboSlotSelections) {
    Object.values(comboSlotSelections).forEach(selection => {
      selection.selections.forEach(choice => {
        sodium += (choice.option?.option?.nutrition?.sodium || 0) * (choice.quantity || 1);
      });
    });
  }

  //sodium for each modifier
  if (modifierSelections) {
    modifierSelections.forEach(selection => {
      sodium += (selection.modifier?.nutrition?.sodium || 0) * (selection.modifier.quantity || 0);
    });
  }

  return sodium;
}

export function hasExcessSodium(args: IComputeSodiumArgs): boolean {
  const totalSodium = computeSodium(args);
  return isOverSodiumLimit(totalSodium);
}

export function isOverSodiumLimit(value: number): boolean {
  return value > SODIUM_LIMIT;
}
