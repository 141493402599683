import { Box, HStack, Text, addWithConfig } from '@fhs-legacy/universal-components';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';

export const PointsExpirationContainer = Box.withConfig({
  marginBottom: '$2',
});

export const PointsExpirationContent = Box.withConfig({
  flexDirection: 'column',
  alignItems: 'center',
});

export const AvailableBalance = HStack.withConfig({
  marginBottom: '$4',
});

export const AvailableBalanceText = Text.withConfig({
  fontSize: 'xs',
  lineHeight: '16px',
});

export const Points = Box.withConfig({
  flexDirection: 'row',
  marginX: '$2',
  _text: {
    fontSize: 'xs',
    fontWeight: 'bold',
  },
});

export const StyledLoyaltyPointsIcon = addWithConfig(LoyaltyPointsIcon);

export const rewardsLogoStyles = {
  width: 'full',
  height: '30px',
  marginTop: '$2',
};
