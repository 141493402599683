import React, { ErrorInfo, PureComponent } from 'react';
import { IntlShape, injectIntl } from 'react-intl';

import { Box, Header, Text } from '@fhs-legacy/universal-components';
import { isProduction } from 'utils/environment';
import logger from 'utils/logger';

import Icon from './icon';

const CenteredText = Box.withConfig({
  _text: {
    textAlign: 'center',
  },
});

interface IErrorBoundaryProps {
  intl: IntlShape;
}

type ErrorBoundaryState = { error: Error; info: ErrorInfo } | { error: null; info: null };

class InternalErrorBoundary extends PureComponent<
  React.PropsWithChildren<IErrorBoundaryProps>,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    error: null,
    info: null,
  };

  static getDerivedStateFromError(error: Error, info: ErrorInfo) {
    return { error, info };
  }

  componentDidUpdate(_: IErrorBoundaryProps, prevState: ErrorBoundaryState) {
    const { error } = this.state;
    if (error && prevState.error !== error) {
      logger.error(error);
    }
  }

  render() {
    if (this.state.error) {
      // replace with custom fallback UI
      return (
        <CenteredText>
          <Header variant="headerTwo">
            {this.props.intl.formatMessage({ id: 'somethingWrong' })}
          </Header>
          <Icon />
          {!isProduction ? (
            <Text>
              <Header variant="headerFour">{this.state.error.message}</Header>
              {this.state.info.componentStack || this.state.error.stack}
            </Text>
          ) : null}
        </CenteredText>
      );
    }
    return this.props.children;
  }
}

const ErrorBoundary: React.FC<React.PropsWithChildren<{ intl: IntlShape }>> = ({
  children,
  intl,
}) => {
  return <InternalErrorBoundary intl={intl}>{children}</InternalErrorBoundary>;
};

export default injectIntl(ErrorBoundary);
