import { useRef } from 'react';

import Picture from 'components/picture';
import StaticMap from 'components/static-map';

import { HeaderContainer } from './styled';
import { IModalHeader } from './types';

export const ModalHeader = ({ store }: IModalHeader) => {
  const { name: storeName, restaurantImage, latitude: lat, longitude: lng } = store;
  const { current: position } = useRef({ lat, lng });

  return (
    <HeaderContainer>
      {restaurantImage ? (
        <Picture image={restaurantImage} alt={storeName} />
      ) : (
        <StaticMap initialZoomIOS={8000} position={position} />
      )}
    </HeaderContainer>
  );
};
