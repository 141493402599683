import { router } from 'expo-router';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { MqSwitch } from '@fhs/ui';
import { Icon } from '@fhs-legacy/universal-components';
import ConfirmDialog from 'components/confirm-dialog';
import { DialogActionSheet } from 'components/confirmation-actionsheet';
import { theme } from 'styles/configure-theme';
import DateSingleton, { DateSingletonInstanceKey } from 'utils/dateTime/DateSingleton';
import { routes } from 'utils/routing';

import { BodyText, CustomText, HeaderText, Wrapper } from './styled';

const dateSingleton = DateSingleton.getInstance(DateSingletonInstanceKey.cateringConfirmation);

const CateringConfirmationDataSheet = () => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(() => {
    if (dateSingleton.isDateExpired()) {
      dateSingleton.saveDate();
      return true;
    }
    return false;
  });

  const headingComponent = (
    <Wrapper>
      <Icon
        variant="catering"
        testID="catering-icon"
        color={theme.token('text-active')}
        alignSelf="center"
        size="20"
      />
      <HeaderText>
        {formatMessage(
          { id: 'cateringDialogTitle' },
          {
            cateringTextComponent: (
              <CustomText color={theme.token('text-active')}>
                {formatMessage({ id: 'catering' })}
              </CustomText>
            ),
          }
        )}
      </HeaderText>
    </Wrapper>
  );

  return (
    <MqSwitch
      $base={() => (
        <DialogActionSheet
          isOpen={isOpen}
          onConfirm={() => setIsOpen(false)}
          onDismiss={() => setIsOpen(false)}
          onPressDismissButton={() => {
            setIsOpen(false);
            router.navigate(routes.storeLocator);
          }}
          confirmButtonLabel={formatMessage({ id: 'confirmCatering' })}
          dismissButtonLabel={formatMessage({ id: 'switchServiceMode' })}
          titleString={formatMessage({ id: 'cateringDialogTitleString' })}
          title={headingComponent}
          body={<BodyText>{formatMessage({ id: 'cateringDialogBody' })}</BodyText>}
        />
      )}
      $gteDesktop={() => (
        <ConfirmDialog
          showDialog={isOpen}
          onConfirm={() => setIsOpen(false)}
          onDismiss={() => setIsOpen(false)}
          onCancel={() => router.navigate(routes.storeLocator)}
          confirmLabel={formatMessage({ id: 'confirmCatering' })}
          cancelLabel={formatMessage({ id: 'switchServiceMode' })}
          modalAppearanceEventMessage={formatMessage({ id: 'cateringDialogTitleString' })}
          headingComponent={headingComponent}
          bodyComponent={<BodyText>{formatMessage({ id: 'cateringDialogBody' })}</BodyText>}
        />
      )}
    />
  );
};

export default CateringConfirmationDataSheet;
