import { endOfDay, isAfter, isBefore } from 'date-fns';
import { mapValues } from 'lodash';

import { SupportedLanguages } from '@rbi-ctg/frontend';
import { IOffer, IUpsellOffer } from '@rbi-ctg/menu';
import { Maybe } from 'generated/graphql-gateway';
import { IListOffersByUserIdQuery, IOfferFeedbackEntryFragment } from 'generated/rbi-graphql';
import { RuleType, isRedeemableWithLimit } from 'utils/offers';
import { getExpirationDateFromRuleset } from 'utils/offers/rule-sets/get-expiration-date';
import { parseSanityObject } from 'utils/sanity';
import { PosVendors } from 'utils/vendor-config';

import { LOCALE_BLOCK_KEYS, templateKeyValuePairToObject } from '../hooks/use-locked-offers';
import { UIPattern } from '../types';

/**
 * make an object like this to help with lookups later
 * {
 *  couponId1: 2,
 *  couponId4: 5
 * }
 */
export const countAvailableOffersByCouponId = ({
  listUserCouponTokens,
}: IListOffersByUserIdQuery) =>
  listUserCouponTokens?.tokens
    ?.map(nextCoupon => nextCoupon?.couponId)
    .filter((couponId): couponId is string => !!couponId)
    // lets count (equivalent to _.countBy)
    .reduce((coupons, couponId) => {
      coupons[couponId] = (coupons[couponId] ?? 0) + 1;
      return coupons;
    }, {} as Record<string, number>);

export const combineSortedOffersWithAvailableOfferCounts = (
  sortedOffers: IOffer[],
  offerCountByCouponId: { [key: string]: number }
) =>
  sortedOffers.map(
    offer =>
      ({
        ...offer,
        numberAvailable: offer?._id ? offerCountByCouponId[offer._id] : 1,
      } as IOffer)
  );

const allowedKeys = new Set(Object.values(PosVendors));

const mapOfferDataToVendorConfigs = (offerData: IOffer = {} as IOffer) => {
  const keys = Object.keys(offerData?.vendorConfigs || {});
  return keys.reduce((acc, key) => {
    if (allowedKeys.has(key as PosVendors)) {
      const config = offerData?.vendorConfigs?.[key];
      acc[key] = {
        pluType: config?.pluType || null,
        parentSanityId: config?.parentSanityId || null,
        pullUpLevels: config?.pullUpLevels || null,
        constantPlu: config?.constantPlu || null,
        quantityBasedPlu: config?.quantityBasedPlu
          ? {
              quantity: config?.quantityBasedPlu?.quantity,
              plu: config?.quantityBasedPlu?.plu,
              qualifier: config?.quantityBasedPlu?.qualifier,
            }
          : null,
        multiConstantPlus: config?.multiConstantPlus
          ? {
              quantity: config?.multiConstantPlus?.quantity,
              plu: config?.multiConstantPlus?.plu,
              qualifier: config?.multiConstantPlus?.qualifier,
            }
          : null,
        parentChildPlu: config?.parentChildPlu
          ? {
              plu: config?.parentChildPlu?.plu,
              childPlu: config?.parentChildPlu?.childPlu,
            }
          : null,
        sizeBasedPlu: config?.sizeBasedPlu
          ? {
              comboPlu: config?.sizeBasedPlu?.comboPlu,
              comboSize: config?.sizeBasedPlu?.comboSize,
            }
          : null,
      };
    }
    return acc;
  }, {});
};

/**
 * Shapes up the parsed `upsellOptions` data in order to match the
 * `IUpsellOffer` interface and be consumed by the FE components.
 * @param offerData parsed offer details
 * @param language content language
 * @returns list of upsell options, ready to be consumed by components.
 */
const buildUpsellOptions = (
  offerData: IOffer,
  language: SupportedLanguages
): Array<IUpsellOffer> => {
  const { upsellOptions } = offerData;
  if (!upsellOptions?.length) {
    return [];
  }

  return upsellOptions.map(offer => ({
    _id: offer._id,
    description: {
      localeRaw: offer?.description?.[language],
    },
    localizedImage: {
      locale: offer?.localizedImage?.[language],
    },
    name: {
      localeRaw: offer?.name?.[language],
    },
  }));
};

/**
 * Transforms offer feedback data in order to match the `IOffer` interface.
 *
 * Note this is required because actual offer data comes from a Sanity GROQ
 * query, encoded as a JSON string, and we need to perform the transforms that
 * otherwise happen in the GraphQL layer.
 *
 * @param offerFeedback parsed offer details
 * @param language content language
 * @returns menu offer, ready to be consumed by components.
 */
export const mapOfferDataToNewOffer = ({
  offerFeedback,
  language,
}: {
  offerFeedback: IOfferFeedbackEntryFragment;
  language: SupportedLanguages;
}): IOffer => {
  const offerData = JSON.parse(offerFeedback.offerDetails ?? '{}') as IOffer;
  let endDate: Maybe<Date>;
  // Parse variables Template
  const variables = templateKeyValuePairToObject({
    variables: Object.assign(offerFeedback.offerVariables || {}),
  });
  // we use formation end date as default. If formation does not provide, we then use sanity end date.
  if (variables?.endDate) {
    // Need to retrieve the endDate from evaluate offers call
    endDate = new Date(variables.endDate);
  } else {
    // Get Sanity date
    const ruleSet = offerData.ruleSet || [];
    endDate = getExpirationDateFromRuleset(ruleSet);
  }

  // Turn all values into string
  const variablesStringValues = mapValues(variables, val => (val ? val.toString() : ''));
  const updatedObj = parseSanityObject({
    doc: offerData,
    localeBlockKeys: LOCALE_BLOCK_KEYS,
    variables: variablesStringValues,
  });

  const upsellOptions = buildUpsellOptions(offerData, language);

  // map vendor configs
  const vendorConfigs = mapOfferDataToVendorConfigs(offerData);

  // Map to existing sanity offer shape.
  return {
    ...updatedObj,
    expiresOnDatetime: endDate as Date,
    tokenId: offerFeedback?.tokenId,
    isRedeemable: offerFeedback.redemptionEligibility.isRedeemable,
    rank: offerFeedback.rank,
    name: {
      localeRaw: offerData?.name?.[language],
    },
    headerSuperText: {
      locale: offerData?.headerSuperText?.[language],
    },
    description: {
      localeRaw: offerData?.description?.[language],
    },
    moreInfo: {
      localeRaw: offerData?.moreInfo?.[language],
    },
    howToRedeem: {
      localeRaw: offerData?.howToRedeem?.[language],
    },
    offerCTA: {
      actionText: {
        locale: offerData?.offerCTA?.actionText?.[language],
      },
      route: offerData?.offerCTA?.route || null,
    },
    localizedImage: {
      locale: offerData?.localizedImage?.[language],
    },
    lockedOffersPanel: {
      completedChallengeHeader: {
        localeRaw: offerData?.lockedOffersPanel?.completedChallengeHeader?.[language],
      },
      completedChallengeDescription: {
        localeRaw: offerData?.lockedOffersPanel?.completedChallengeDescription?.[language],
      },
    },
    promoCodePanel: {
      promoCodeDescription: {
        localeRaw: offerData?.promoCodePanel?.promoCodeDescription?.[language],
      },
      promoCodeLabel: {
        localeRaw: offerData?.promoCodePanel?.promoCodeLabel?.[language],
      },
      promoCodeLink: offerData?.promoCodePanel?.promoCodeLink || null,
    },
    upsellOptions,
    vendorConfigs,
  };
};

export const getOfferStartAndEndDate = (offer: IOffer) => {
  let endDate = '';
  let startDate = '';
  (offer.ruleSet ?? []).forEach(rule => {
    if (rule.ruleSetType === RuleType.BetweenDates) {
      endDate = rule.endDate ?? '';
      startDate = rule.startDate ?? '';
    }

    if (rule.ruleSetType === RuleType.And || rule.ruleSetType === RuleType.Or) {
      rule.ruleSet.forEach(r => {
        if (r.ruleSetType === RuleType.BetweenDates) {
          endDate = r.endDate ?? '';
          startDate = r.startDate ?? '';
        }
      });
    }
  });

  if (offer?.expiresOnDatetime) {
    endDate = new Date(offer.expiresOnDatetime).toString();
  }
  return {
    endDate,
    startDate,
  };
};

// we want to avoid displaying offers the user cannot redeem
// some of this feedback comes from the evaluateUserOffers query
// and some has to be determined by analyzing the offer's rules
// and checking if the user has redeemed the offer as an
// "unauthenticated redemption"
export const shouldDisplayOffer = (
  offer: IOffer,
  unauthenticatedRedemptionsForOffer: string[]
): boolean => {
  // Should only include Standard Offers UI pattern or no UI pattern specified
  // promo code & locked offers require login and have their own display logic
  const shouldIncludeForUIPattern = !offer.uiPattern || offer.uiPattern === UIPattern.STANDARD;

  const isRedeemableAccordingToLimitRule = isRedeemableWithLimit(
    offer.ruleSet,
    unauthenticatedRedemptionsForOffer
  );

  const { endDate, startDate } = getOfferStartAndEndDate(offer);
  const isExpired = isAfter(Date.now(), endOfDay(new Date(endDate)));
  const isNotStarted = isBefore(Date.now(), new Date(startDate));

  return (
    shouldIncludeForUIPattern &&
    isRedeemableAccordingToLimitRule &&
    !!offer?.option &&
    !isExpired &&
    !isNotStarted
  );
};
