import React from 'react';

import { WordmarkReversed } from '@fhs/ui';

import { SocialIcons } from './social-icons';
import { WordmarkSocialIconsContainer } from './styled';
import { IWordmarkAndSocialIcons as IWordmarkAndSocialIconsProps } from './types';

export const WordmarkAndSocialIcons: React.FC<IWordmarkAndSocialIconsProps> = ({
  socialIconsWidget,
}) => {
  const socialIconsProps = {
    instagramUrl: socialIconsWidget?.instagramUrl?.locale,
    facebookUrl: socialIconsWidget?.facebookUrl?.locale,
    youtubeUrl: socialIconsWidget?.youtubeUrl?.locale,
    twitterUrl: socialIconsWidget?.twitterUrl?.locale,
    tiktokUrl: socialIconsWidget?.tiktokUrl?.locale,
  };

  return (
    <WordmarkSocialIconsContainer>
      <WordmarkReversed height={40} width={160} />
      <SocialIcons {...socialIconsProps} />
    </WordmarkSocialIconsContainer>
  );
};
