import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconChevronLeft = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M17 20.23L15.1954 22L5 12L15.1954 2L17 3.77L8.60918 12L17 20.23Z"
      />
    </Svg>
  );
};
