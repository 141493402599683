import enUS, { format, parseISO } from 'date-fns';

import { IUserOrderFragment } from 'generated/rbi-graphql';
import { LOCALE_DATA } from 'state/intl/constants';

import { IGroupedOrders } from './types';

interface ISelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}

/**
 * map react-intl's locale strings to date-fns locale objects
 */
export const localeStringToDateFnsLocale = (locale: string) => {
  return LOCALE_DATA[locale]?.dateFormat || enUS;
};

/**
 * groups a list of orders by date to assist with filtering by month and year
 */
export const groupOrdersByYearAndMonth = (orders: IUserOrderFragment[]): IGroupedOrders =>
  orders.reduce((groups, order) => {
    const yearAndMonthKey = format(parseISO(order.createdAt), 'yyyy-MM');

    if (groups[yearAndMonthKey]) {
      groups[yearAndMonthKey].push(order);
    } else {
      groups[yearAndMonthKey] = [order];
    }

    return groups;
  }, {});

/**
 * generates options for a select element
 */
export const getDropdownOptions = (
  groupedOrders: IGroupedOrders,
  locale: string
): ISelectOption[] => {
  // yearMonthKey is a string like 2020-05
  return Object.keys(groupedOrders).map(yearMonthKey => {
    // Use the first order from a month's list of orders to generate the label for the month
    // This is more reliable than generating a label from yearMonthKey which is missing a day and time
    // There may be a bug in the edgecase where an order created within the first or last few hours of a month
    // appear grouped under the wrong month if a customer looks at the list in a different timezone from where the order was placed
    const firstOrderInGroup = groupedOrders[yearMonthKey][0];
    const label = format(parseISO(firstOrderInGroup.createdAt), 'MMMM yyyy', {
      locale: localeStringToDateFnsLocale(locale),
    });

    return {
      label,
      value: yearMonthKey,
    };
  });
};
