import React from 'react';
import { useIntl } from 'react-intl';

import { Box, Header, Icon, ScrollView } from '@fhs-legacy/universal-components';
import { ActionsheetContent, ActionsheetWrapper } from 'components/action-sheet';
import { useGetNearbyRestaurants } from 'hooks/use-get-nearby-restaurants';
import { StoreCardList } from 'pages/store-locator/new-ui/store-card-list';
import { SearchBar } from 'pages/store-locator/search-bar';
import { useGeolocation } from 'state/geolocation';
import { OrderLocatorTab } from 'state/launchdarkly/variations';

import {
  HeaderWrapper,
  IconWrapper,
  SearchBarLabel,
  SearchBarWrapper,
  SearchContainer,
} from './styled';
import { IFavoriteStoreSearch } from './types';

export const FavoriteStoreSearch = ({ isOpen, onClose, onStoreSelect }: IFavoriteStoreSearch) => {
  const { formatMessage } = useIntl();
  const { setManualAddress, updateManualCoordinates } = useGeolocation();

  const { restaurants, loading, error } = useGetNearbyRestaurants();

  const handleFavoriteSelect = (storeId = '', storeNumber = '', storeAddress = '') => {
    onStoreSelect({ storeId, storeNumber, storeAddress });
    setManualAddress('');
    updateManualCoordinates('');
    onClose();
  };

  return (
    <ActionsheetWrapper onOpenEventMessage="Scan Successful" isOpen={isOpen} onClose={onClose}>
      <ActionsheetContent width="full">
        <ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          height="full"
          width="full"
          keyboardShouldPersistTaps="handled"
        >
          <HeaderWrapper>
            <IconWrapper onPress={onClose}>
              <Icon variant="back" color="token.icon-default" />
            </IconWrapper>
            <Header variant="headerTwo" justifyContent="center" alignItems="center">
              {formatMessage({ id: 'searchLocation' })}
            </Header>
            <Box />
          </HeaderWrapper>
          <SearchContainer>
            <SearchBarWrapper>
              <SearchBarLabel>{formatMessage({ id: 'location' })}</SearchBarLabel>
              <SearchBar />
            </SearchBarWrapper>
          </SearchContainer>
          <Box flex={1} marginTop="$8" paddingX="0">
            <StoreCardList
              tab={OrderLocatorTab.NEARBY}
              currentTabHasError={!!error}
              isSearching={loading}
              restaurants={restaurants}
              onCardPress={handleFavoriteSelect}
            />
          </Box>
        </ScrollView>
      </ActionsheetContent>
    </ActionsheetWrapper>
  );
};
