import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconNearMe = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path fill="currentColor" d="M21 3.1 3 10.63v.98l6.84 2.65 2.64 6.84h.98L21 3.1Z" />
    </Svg>
  );
};
