import { useCallback } from 'react';

import { EventTypes } from '@fhs-legacy/frontend/src/state/crm-events';
import { useCRMEventsContext } from '@fhs-legacy/frontend/src/state/crm-events';
import { actions, useAppDispatch } from '@fhs-legacy/frontend/src/state/global-state';

import { SimplyOfferEventNames } from '../analytics';

import { useLoyaltyLegacyStates } from './use-loyalty-legacy-states';

export const useRemoveOffer = () => {
  const { setOfferValidationError, selectedOffer } = useLoyaltyLegacyStates();
  const { logRBIEvent } = useCRMEventsContext();
  const dispatch = useAppDispatch();
  const removeOffer = useCallback(() => {
    dispatch(actions.loyalty.setSelectedOffer(null));
    dispatch(actions.loyalty.resetAppliedOffers());
    dispatch(actions.loyalty.setShouldRefetchOffers(true));
    setOfferValidationError(false);
    logRBIEvent({
      name: SimplyOfferEventNames.OFFER_REMOVED_FROM_CART,
      type: EventTypes.Other,
      attributes: { offerId: selectedOffer?._id },
    });
  }, [dispatch, logRBIEvent, selectedOffer?._id, setOfferValidationError]);

  return { removeOffer };
};
