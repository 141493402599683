import { router, useLocalSearchParams } from 'expo-router';
import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';

import { IconAdd, IconOffer, Pressable, Text, createMqStyles, tokens } from '@fhs/ui';
import ConfirmDialog from '@fhs-legacy/frontend/src/components/confirm-dialog';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';

import { useLoyaltyLegacyStates } from '../../hooks/use-loyalty-legacy-states';

import { PromoCodeDrawer } from './promo-code-drawer';

export const PromoCode = () => {
  const params = useLocalSearchParams<{ promoCode?: string }>();
  const [isOpen, setIsOpen] = useState(!!params.promoCode);
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const { isAuthenticated } = useLoyaltyLegacyStates();
  const onOpen = useCallback(() => {
    if (!isAuthenticated) {
      setShowSignInDialog(true);
      return;
    }
    setIsOpen(true);
  }, []);
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const mqStyles = useMqStyles();

  const onDismiss = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (!isOpen && params.promoCode) {
      // This delay is necessary in order to allow the component to be ready to show the Actionsheet.
      setTimeout(onOpen, 500);
    }
  }, [isOpen, onOpen, params.promoCode]);

  const onConfirmSignIn = useCallback(() => {
    setShowSignInDialog(false);
    router.navigate(routes.signIn);
  }, []);

  return (
    <>
      <Pressable onPress={onOpen} style={mqStyles.button}>
        <View style={mqStyles.container}>
          <View style={mqStyles.iconCircle}>
            <IconOffer size={24} color={tokens.colors.$white} />
          </View>
          <Text weight="bold">{'Have a Promo Code?'}</Text>
          <IconAdd size={24} style={mqStyles.iconAdd} />
        </View>
      </Pressable>
      <ConfirmDialog
        modalAppearanceEventMessage="Sign In for Promo Codes"
        showDialog={showSignInDialog}
        onConfirm={onConfirmSignIn}
        onDismiss={() => setShowSignInDialog(false)}
        onCancel={() => setShowSignInDialog(false)}
        confirmLabel="Sign In"
        cancelLabel="Cancel"
        bodyComponent={
          <Text style={{ marginBottom: 20 }}>
            Please, sign in to enable exclusive offers through promo codes.
          </Text>
        }
        heading="Sign In for Promo Codes"
      />
      {isOpen ? (
        <PromoCodeDrawer
          isOpen={isOpen}
          onDismiss={onDismiss}
          promoCode={params.promoCode?.toString()}
        />
      ) : null}
    </>
  );
};

const useMqStyles = createMqStyles({
  container: {
    $base: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    $gteDesktop: {
      flex: 1,
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  button: {
    $base: {
      width: '100%',
      borderRadius: 8,
      borderColor: tokens.colors.$blackOpacity10,
      borderWidth: 1,
    },
    $ltDesktop: {
      marginTop: 12,
    },
    $gteDesktop: {
      height: 72,
      width: '50%',
    },
  },
  iconAdd: {
    $base: {
      marginLeft: 'auto',
      marginRight: 12,
    },
    $gteDesktop: {
      margin: 10,
    },
  },
  iconCircle: {
    $base: {
      height: 40,
      width: 40,
      borderRadius: 40,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: tokens.colors.$houseRed,
      margin: 12,
    },
  },
});
