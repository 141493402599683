import { StyleSheet } from 'react-native';

import { tokens } from '../../tokens';
import { Text, TextProps } from '../text';

export type HeaderTextProps = Omit<TextProps, 'weight'> & { isHighlighted?: boolean };
export function HeaderText(props: HeaderTextProps) {
  return (
    <Text
      weight="semibold"
      {...props}
      style={[styles.base, props.isHighlighted && styles.isHighlighted, props.style]}
    />
  );
}

const styles = StyleSheet.create({
  base: {
    fontSize: 16,
    lineHeight: 26,
    color: tokens.colors.$houseDark,
  },
  isHighlighted: {
    color: tokens.colors.$houseRedDarken,
  },
});
