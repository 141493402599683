import { IntlFormatters } from 'react-intl';

import { ServiceMode } from 'state/service-mode/types';

export const SERVICE_MODES = [
  ServiceMode.CATERING_DELIVERY,
  ServiceMode.CATERING_PICKUP,
  ServiceMode.DELIVERY,
  ServiceMode.DRIVE_THRU,
  ServiceMode.EAT_IN,
  ServiceMode.TAKEOUT,
  ServiceMode.CURBSIDE,
  ServiceMode.PICKUP_WINDOW,
];

export const PICKUP_SERVICE_MODES = [
  ServiceMode.DRIVE_THRU,
  ServiceMode.EAT_IN,
  ServiceMode.TAKEOUT,
  ServiceMode.CURBSIDE,
  ServiceMode.PICKUP_WINDOW,
];

export const DELIVERY_SERVICE_MODES = [ServiceMode.DELIVERY];

export const CATERING_SERVICE_MODES = [ServiceMode.CATERING_DELIVERY, ServiceMode.CATERING_PICKUP];

// NOTE: catering options, even CATERING_PICKUP,
// are considered invalid for pickup only offers
export const NON_PICKUP_SERVICE_MODES = DELIVERY_SERVICE_MODES.concat(CATERING_SERVICE_MODES);

export const NON_CATERING_SERVICE_MODES = PICKUP_SERVICE_MODES.concat(DELIVERY_SERVICE_MODES);

type CateringServiceMode = ServiceMode.CATERING_DELIVERY | ServiceMode.CATERING_PICKUP;

export const isCatering = (serviceMode: ServiceMode | null): serviceMode is CateringServiceMode =>
  serviceMode === ServiceMode.CATERING_DELIVERY || serviceMode === ServiceMode.CATERING_PICKUP;

export const isCateringDelivery = (
  serviceMode: ServiceMode | null
): serviceMode is CateringServiceMode => serviceMode === ServiceMode.CATERING_DELIVERY;

export const isCateringPickup = (
  serviceMode: ServiceMode | null
): serviceMode is CateringServiceMode => serviceMode === ServiceMode.CATERING_PICKUP;

export const isDelivery = (serviceMode: ServiceMode | null): serviceMode is ServiceMode.DELIVERY =>
  serviceMode === ServiceMode.DELIVERY;

export const isDriveThru = (
  serviceMode: ServiceMode | null
): serviceMode is ServiceMode.DRIVE_THRU => serviceMode === ServiceMode.DRIVE_THRU;

export const isPickup = (serviceMode: ServiceMode | null): serviceMode is ServiceMode.DELIVERY =>
  serviceMode ? PICKUP_SERVICE_MODES.includes(serviceMode) : false;

export const getLocalizedServiceMode = (
  serviceMode: ServiceMode,
  formatMessage: IntlFormatters['formatMessage']
): string => {
  switch (serviceMode) {
    case ServiceMode.DRIVE_THRU:
      return formatMessage({ id: 'driveThru' });
    case ServiceMode.EAT_IN:
      return formatMessage({ id: 'dineIn' });
    case ServiceMode.TAKEOUT:
      return formatMessage({ id: 'pickUp' });
    case ServiceMode.CURBSIDE:
      return formatMessage({ id: 'curbside' });
    case ServiceMode.DELIVERY:
      return formatMessage({ id: 'delivery' });
    case ServiceMode.CATERING_DELIVERY:
      return formatMessage({ id: 'cateringDelivery' });
    case ServiceMode.CATERING_PICKUP:
      return formatMessage({ id: 'cateringPickUp' });
    default:
      return '';
  }
};
