import React from 'react';

import useAuth from 'hooks/auth/use-auth';
import useAuthRedirects from 'hooks/auth/use-auth-redirects';

const AuthRequired = ({ children }) => {
  const { isAuthenticated } = useAuth();

  useAuthRedirects();

  return isAuthenticated && children;
};

export default AuthRequired;

/**
 * HOC that requires a user to be signed in before rendering the passed
 * in <Component />. If a user is not signed in it redirects to /signup.
 */
export const withAuthRequired = Component => props => {
  return (
    <AuthRequired>
      <Component {...props} />
    </AuthRequired>
  );
};
