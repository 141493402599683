import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';

import { PromotionType } from 'generated/graphql-gateway';
import { selectors, useAppSelector } from 'state/global-state';
import { IAppliedReward, IIncentiveEvaluationResult } from 'state/loyalty/hooks/types';
import { getEvaluationResultMessage } from 'state/loyalty/hooks/utils/helpers';

import { IncentiveErrorHead } from './incentive-error-head';

interface IncentiveRewardHeadProps {
  cartId: string;
}

export function IncentiveRewardHead({ cartId }: IncentiveRewardHeadProps) {
  const formatters = useIntl();
  const { formatMessage } = formatters;

  const availableLoyaltyRewardsMap = useAppSelector(
    selectors.loyalty.selectAvailableLoyaltyRewardsMap
  );
  const appliedRewardsFeedbackMap = useAppSelector(
    selectors.loyalty.selectAppliedRewardsFeedbackMap
  );
  const appliedLoyaltyRewardsMap = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);

  const appliedLoyaltyReward: IAppliedReward | undefined = appliedLoyaltyRewardsMap[cartId];
  if (!appliedLoyaltyReward) {
    return null;
  }
  const loyaltyRewardErrors: IIncentiveEvaluationResult[] | undefined =
    appliedRewardsFeedbackMap[appliedLoyaltyReward.rewardId];
  if (!loyaltyRewardErrors?.length) {
    return null;
  }
  if (isEmpty(availableLoyaltyRewardsMap)) {
    return null;
  }

  // availableLoyaltyRewardsMap can be empty. Prevent error by using optional chaining
  const errorTitle = availableLoyaltyRewardsMap[appliedLoyaltyReward.rewardBenefitId]?.name ?? '';

  const errorDescription = getEvaluationResultMessage({
    evaluationResult: loyaltyRewardErrors[0],
    formatters,
    incentiveType: PromotionType.REWARD,
  });

  return (
    <IncentiveErrorHead
      title={errorTitle}
      description={errorDescription}
      statusMessage={formatMessage({
        id: 'rewardInvalid',
      })}
    />
  );
}
