import { router, usePathname } from 'expo-router';
import { isNumber, isUndefined } from 'lodash';
import { FC } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { IconDelete } from '@fhs/ui';
import { useStoreContext } from '@fhs-legacy/frontend/src/state/store';
import { Icon, InlineAlert, Text, VStack } from '@fhs-legacy/universal-components';
import { Box } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { CustomDisclaimer } from 'components/offers/ui-refresh/offer-disclaimer';
import { StyledSignUpToRedeemLink } from 'components/offers/ui-refresh/redemption-button.styled';
import { SelectedStoreAndServiceModeButton } from 'components/selected-store-and-service-mode-button';
import { useRewardsFlow } from 'hooks/use-rewards-flow';
import useRoutes from 'hooks/use-routes';
import { LoyaltyOffersCooldownDrawer } from 'pages/loyalty/loyalty-offers/loyalty-offers-cooldown-drawer';
import { useAuthContext } from 'state/auth';
import { selectors, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { FixableEvaluationErrors, IncentiveEvaluationErrorCodes } from 'state/loyalty/hooks/types';
import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { routes } from 'utils/routing';

import { DISABLE_IN_RESTAURANT_FOR_INVALID_SERVICE_MODE } from '../constants';
import { PointsNeededText, PointsNeededTextDetail } from '../incentive-card/incentive-card.styled';

import { CAN_REPLACE_REWARD, SHOULD_DISPLAY_ICON } from './constants';
import {
  BackgroundImage,
  ButtonsWrapper,
  DisclaimerText,
  ImageWrapper,
  RewardDescription,
  RewardDetailsContainer,
  RewardName,
  StyledActionButton,
  StyledDisclaimerContainer,
  StyledProgressMeter,
  StyledRewardImage,
  Wrapper,
} from './incentive-details.styled';
import { RewardPoints } from './reward-points';
import { IIncentiveDetailsViewProps } from './types';

export const IncentiveDetailsView: FC<React.PropsWithChildren<IIncentiveDetailsViewProps>> = ({
  name,
  image,
  description,
  moreInfo,
  isOffer,
  pointCost,
  locked,
  remainingPointsNeededForRedemption,
  redemptionPercentage,
  handleRedeemRewardPress,
  handleInRestaurantRedemptionPress,
  errorMessage,
  checkingAvailability,
  backgroundImage,
  canAddToInRestaurantOrder = false,
  canAddToMobileOrder = false,
  showRemoveRewardButton = false,
  handleRemoveRewardsPress,
  incentiveEvaluationResults,
  offersInCooldown = false,
  limitPerOrderMet = false,
}) => {
  const { formatMessage } = useIntl();
  const { noStoreSelected } = useStoreContext();

  const { canUseInStoreRedemption, canUseMobileRedemption, rewardRedemptionMode } =
    useRewardsFlow();
  const _canAddToMobileOrder = canAddToMobileOrder && canUseMobileRedemption;
  const _canAddToInRestaurantOrder = canAddToInRestaurantOrder && canUseInStoreRedemption;

  const meterEnabled = Boolean(useFlag(LaunchDarklyFlag.ENABLE_METER_ON_REWARDS));

  const addRewardButtonLabel =
    limitPerOrderMet && CAN_REPLACE_REWARD
      ? `${formatMessage({ id: 'change' })} ${formatMessage({ id: 'reward' })}`
      : formatMessage({ id: 'continue' });

  const inRestaurantRedemptionText = formatMessage({ id: 'redeemInRestaurant' });

  const hasServiceModeError = (
    evaluationResults: IIncentiveDetailsViewProps['incentiveEvaluationResults']
  ) =>
    !!evaluationResults?.some(
      evaluationResult =>
        evaluationResult.code === IncentiveEvaluationErrorCodes.INVALID_SERVICE_MODE
    );
  const maximumDailyRewardRedemptionLimit = useAppSelector(
    selectors.loyalty.selectMaxDailyRewardRedemptionLimit
  );
  const dailyRewardTransactionCount = useAppSelector(
    selectors.loyalty.selectDailyRewardTransactionCount
  );
  const hasReachedMaxRedemptionLimit =
    !isOffer && maximumDailyRewardRedemptionLimit <= dailyRewardTransactionCount;
  const hasBlockingErrors =
    hasReachedMaxRedemptionLimit ||
    !!errorMessage ||
    (offersInCooldown && isOffer) ||
    // Disable buttons if Feedback is not included in fixable incentive errors
    incentiveEvaluationResults?.some(
      evaluationResult =>
        !FixableEvaluationErrors.has(evaluationResult.code as IncentiveEvaluationErrorCodes)
    );

  const disableMobileOrderButton =
    hasBlockingErrors || hasServiceModeError(incentiveEvaluationResults);

  const disableInRestaurantOrderButton =
    hasBlockingErrors ||
    (DISABLE_IN_RESTAURANT_FOR_INVALID_SERVICE_MODE &&
      hasServiceModeError(incentiveEvaluationResults));

  const { isAuthenticated, setOriginLocation } = useAuthContext();
  const pathname = usePathname();
  const { getLocalizedRouteForPath } = useRoutes();
  const routeToSignIn = (event: any) => {
    event.preventDefault();
    setOriginLocation(pathname);
    router.navigate(getLocalizedRouteForPath(routes.signIn));
  };

  const renderScreenCTAs = () => {
    const handleStoreLocatorRedirect = () => {
      router.navigate({
        pathname: routes.storeLocator,
        params: { back: pathname, rewardRedemptionMode },
      });
    };

    if (noStoreSelected) {
      return (
        <StyledActionButton
          disabled={disableMobileOrderButton}
          onPress={handleStoreLocatorRedirect}
          isLoading={checkingAvailability}
          leftIcon={
            SHOULD_DISPLAY_ICON ? (
              <Icon variant="mobile" width="24px" {...hiddenAccessibilityPlatformProps} />
            ) : undefined
          }
        >
          {formatMessage({ id: 'selectStoreToRedeem' })}
        </StyledActionButton>
      );
    }

    if (showRemoveRewardButton) {
      return (
        <ActionButton
          fullWidth
          testID="need-help-cta"
          onPress={handleRemoveRewardsPress}
          variant={ActionButtonVariants.OUTLINE}
          mt="$4"
          leftIcon={
            <Box mt="$0.5" mr="$">
              <IconDelete color={theme.token('icon-button-secondary')} />
            </Box>
          }
        >
          {formatMessage({ id: 'removeFromCart' })}
        </ActionButton>
      );
    }

    return (
      <>
        {_canAddToMobileOrder && (
          <StyledActionButton
            disabled={disableMobileOrderButton}
            onPress={handleRedeemRewardPress}
            isLoading={checkingAvailability}
            leftIcon={
              SHOULD_DISPLAY_ICON ? (
                <Icon variant="mobile" width="24px" {...hiddenAccessibilityPlatformProps} />
              ) : undefined
            }
          >
            {addRewardButtonLabel}
          </StyledActionButton>
        )}
        {_canAddToInRestaurantOrder && (
          <StyledActionButton
            disabled={disableInRestaurantOrderButton}
            onPress={handleInRestaurantRedemptionPress}
            isLoading={checkingAvailability}
            marginTop="$3"
            leftIcon={
              SHOULD_DISPLAY_ICON ? (
                <Icon variant="store" width="24px" {...hiddenAccessibilityPlatformProps} />
              ) : undefined
            }
            variant={
              _canAddToMobileOrder && _canAddToInRestaurantOrder
                ? ActionButtonVariants.OUTLINE
                : undefined
            }
          >
            {inRestaurantRedemptionText}
          </StyledActionButton>
        )}
      </>
    );
  };

  return (
    <>
      <VStack safeArea height="full">
        <Wrapper flex={1}>
          <ImageWrapper>
            {backgroundImage && <BackgroundImage alt={name} image={backgroundImage} />}
            {image && <StyledRewardImage alt={name} image={image} objectFitContain />}
          </ImageWrapper>
          <RewardDetailsContainer>
            {name && <RewardName>{name}</RewardName>}
            {description && <RewardDescription content={description} />}
            {!isUndefined(pointCost) && (
              <RewardPoints pointCost={pointCost} locked={Boolean(locked)} />
            )}
            {locked && (
              <>
                {meterEnabled && isNumber(redemptionPercentage) && (
                  <StyledProgressMeter percentage={redemptionPercentage} />
                )}
                {isNumber(remainingPointsNeededForRedemption) && (
                  <PointsNeededText>
                    {formatMessage(
                      { id: 'yourXPointsAway' },
                      {
                        points: (
                          <PointsNeededTextDetail>
                            {remainingPointsNeededForRedemption}
                          </PointsNeededTextDetail>
                        ),
                      }
                    )}
                  </PointsNeededText>
                )}
              </>
            )}
            {!isOffer && moreInfo && <DisclaimerText content={moreInfo} />}
          </RewardDetailsContainer>

          {hasReachedMaxRedemptionLimit && (
            <InlineAlert
              status="error"
              title={formatMessage({
                id: 'dailyRedemptionLimit',
              })}
              borderRadius={8}
              marginTop="$4"
              message={formatMessage(
                { id: 'dailyRedemptionLimitMessage' },
                {
                  boldText: (
                    <Text bold={true}>
                      {formatMessage(
                        { id: 'dailyRedemptionLimitDetail' },
                        {
                          maxLimitRedemption: maximumDailyRewardRedemptionLimit,
                        }
                      )}
                    </Text>
                  ),
                }
              )}
            />
          )}
          {!!errorMessage && (
            <InlineAlert
              marginY="$4"
              status="info"
              maxWidth={{ base: 'full' }}
              message={errorMessage}
              alignSelf="center"
            />
          )}

          {isOffer && moreInfo && (
            <StyledDisclaimerContainer>
              <CustomDisclaimer content={moreInfo} />
            </StyledDisclaimerContainer>
          )}
          <Box h="$12" />
        </Wrapper>
        <Box>
          <SelectedStoreAndServiceModeButton />
          <ButtonsWrapper $errorMessage={!!errorMessage}>
            {!locked &&
              (!isAuthenticated ? (
                <StyledSignUpToRedeemLink
                  testID="sign-up-to-redeem"
                  to={routes.signIn}
                  replace
                  onPress={routeToSignIn}
                >
                  {formatMessage({ id: 'signUpToRedeem' })}
                </StyledSignUpToRedeemLink>
              ) : (
                <>{renderScreenCTAs()}</>
              ))}
          </ButtonsWrapper>
        </Box>
      </VStack>
      {offersInCooldown && <LoyaltyOffersCooldownDrawer />}
    </>
  );
};
