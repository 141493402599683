import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { tokens } from '@fhs/ui';
import { Skeleton, Text, TextProps } from '@fhs-legacy/universal-components';
import { useUIContext } from 'state/ui';
import { centsToDollarString } from 'utils';

export interface ICurrencyProps {
  isLoading?: boolean;
  amount?: number;
  prefix?: string;
  suffix?: string;
  hideCurrencySymbol?: boolean;
  id?: string;
  testID?: string;
  textProps?: TextProps;
  isBold?: boolean;
  isFree?: boolean;
  isDiscounted?: boolean;
  originalAmount?: number;
}

export const Currency: FC<React.PropsWithChildren<ICurrencyProps>> = ({
  amount = 0,
  prefix = '',
  suffix = '',
  hideCurrencySymbol = false,
  isLoading,
  testID,
  textProps,
  isBold = false,
  isFree = false,
  isDiscounted = false,
  originalAmount,
}) => {
  const { formatCurrencyForLocale } = useUIContext();
  const { formatMessage } = useIntl();
  const formattedAmount = hideCurrencySymbol
    ? centsToDollarString(amount)
    : formatCurrencyForLocale(amount);

  if (isLoading) {
    return <Skeleton.Text w="10" />;
  }
  let formattedOriginalAmount = '0';
  if (isDiscounted && originalAmount) {
    formattedOriginalAmount = hideCurrencySymbol
      ? centsToDollarString(originalAmount)
      : formatCurrencyForLocale(originalAmount);
  }
  const shouldStrikethroughPrice = !!(isDiscounted && originalAmount);
  return (
    <>
      <Text
        testID={testID}
        accessibilityLabel={`${prefix}${formattedAmount}${suffix}`}
        variant="copyOne"
        strikeThrough={shouldStrikethroughPrice}
        {...textProps}
        fontWeight={isBold ? 'bold' : 'normal'}
        color={tokens.colors.$black}
      >
        {prefix}
        {isDiscounted && originalAmount ? formattedOriginalAmount : formattedAmount}
        {suffix}
      </Text>
      {isDiscounted && !isFree && (
        <Text bold color={tokens.colors.$success} marginLeft="$1">
          {formattedAmount}
        </Text>
      )}
      {isFree && (
        <Text bold color={tokens.colors.$success} marginLeft="$1">
          {formatMessage({ id: 'free' })}
        </Text>
      )}
    </>
  );
};

export default Currency;
