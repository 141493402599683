import React from 'react';

import { useGetNearbyRestaurants } from 'hooks/use-get-nearby-restaurants';
import { useGeolocation } from 'state/geolocation';
import { OrderLocatorTab } from 'state/launchdarkly/variations';

import StoreCardList from './store-card-list';

type Props = {
  handleSelectStore(storeId: string): void;
};

export function NearbyStoreCards({ handleSelectStore }: Props) {
  const { coordinatesAvailable, isPermissionChecking, isPermissionGranted } = useGeolocation();
  const { restaurants, loading, error } = useGetNearbyRestaurants();

  /**
   *   - loading
   *     - AND we have a coordinates to search
   *       (we need manual|automatic coord to search something - without that we don't search)
   *       (this also handles the case where we know permissions are denied, but we have manual coords)
   *   - we're checking permissions
   *   - we have granted permissions
   *     - AND we have no available coords to search
   *     (ex. we're in the waiting time between getting granted permissions
   *      and actually receiving the user's coords)
   */
  const isSearchingNearby =
    (loading && coordinatesAvailable) ||
    isPermissionChecking ||
    (isPermissionGranted && !coordinatesAvailable);

  return (
    <StoreCardList
      currentTabHasError={!!error}
      isSearching={isSearchingNearby}
      onSelectStore={handleSelectStore}
      restaurants={restaurants}
      tab={OrderLocatorTab.NEARBY}
    />
  );
}
