import { useCallback } from 'react';

import { TipOption } from '@fhs/cart/src/components/pick-up-tip-selector';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { TipsForStoreEmployees, defaultTipsForStoreEmployees } from 'state/launchdarkly/variations';
import { useOrderContext } from 'state/order';
import { ServiceMode } from 'state/order';
import { useStoreContext } from 'state/store';
import { isDelivery } from 'utils/service-mode';

export const MAX_TIPS_AMOUNT_IN_CENTS = 6300;

export const usePickupTips = (serviceMode: ServiceMode | null) => {
  const isDeliveryOrder = isDelivery(serviceMode);
  const { store } = useStoreContext();
  const { logRBIEvent } = useCRMEventsContext();

  const storeHasPickUpTips = Boolean(store.hasPickUpTips);

  const {
    selectedPickupTip,
    setSelectedPickupTip,
    otherPickupTip,
    setOtherPickupTip,
    pickUpTipsCents,
  } = useOrderContext();

  const tipsForStoreEmployees =
    useFlag<TipsForStoreEmployees>(LaunchDarklyFlag.TIP_FOR_STORE_EMPLOYEES) ||
    defaultTipsForStoreEmployees;

  const pickUpTipsEnabled =
    tipsForStoreEmployees?.enabled && storeHasPickUpTips && !isDeliveryOrder;

  const logTipSelection = useCallback(
    (tip: string) => {
      logRBIEvent({
        name: CustomEventNames.INSTORE_TIP_SELECTED,
        type: EventTypes.Other,
        attributes: {
          pickUpTipCents: tip,
        },
      });
    },
    [logRBIEvent]
  );

  const handleTipSelect = useCallback(
    (tip: string) => {
      logTipSelection(tip);
      setSelectedPickupTip(tip);
    },
    [logTipSelection, setSelectedPickupTip]
  );

  // Format the pick up tips in options
  const pickUpPipOptions: TipOption[] | undefined = pickUpTipsEnabled
    ? Object.entries(tipsForStoreEmployees.tipAmounts)
        .map(([key, value]) => ({
          label: key,
          value: value.toString(),
        }))
        .sort((a, b) => Number(a.value) - Number(b.value))
    : undefined;

  return {
    pickUpTipsEnabled,
    selectedTip: selectedPickupTip,
    otherTip: pickUpTipsEnabled ? otherPickupTip : undefined,
    pickUpPipOptions,
    pickUpTipsCents: pickUpTipsEnabled ? pickUpTipsCents : 0,
    setSelectedTip: handleTipSelect,
    setOtherTip: setOtherPickupTip,
  };
};
