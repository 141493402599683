import React from 'react';
import { useIntl } from 'react-intl';

import { FormikFormControl, FormikInput } from '@fhs-legacy/universal-components';
import { SIGNUP_FORM_PHONE_INPUT } from 'utils/test-ids';

import { FieldContainer } from './signup.styled';

const PhoneNumberField = ({
  hintMessage,
  isRequired = false,
}: {
  hintMessage?: string | null;
  isRequired?: boolean;
}) => {
  const { formatMessage } = useIntl();
  return (
    <FieldContainer>
      <FormikFormControl
        isRequired={isRequired}
        label={formatMessage({ id: 'phoneNumber' })}
        name="phoneNumber"
      >
        <FormikInput
          inputProps={{
            placeholder: `${formatMessage({ id: 'phoneNumber' })}`,
            testID: SIGNUP_FORM_PHONE_INPUT,
            placeholderTextColor: '#979797',
            autoCorrect: false,
            autoComplete: 'tel',
            keyboardType: 'phone-pad',
            // @ts-expect-error TS(2322) FIXME: Type '{ placeholder: string; testID: string; place... Remove this comment to see the full error message
            hintMessage,
          }}
        />
      </FormikFormControl>
    </FieldContainer>
  );
};

export default PhoneNumberField;
