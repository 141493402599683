type ScreenSize = 'desktop' | 'mobile';

export const getWidgetSpacing = {
  vertical: (mq?: ScreenSize) => {
    return mq === 'desktop' ? '$6' : '$4';
  },
  horizontal: (mq?: ScreenSize) => {
    return mq === 'desktop' ? 0 : '$5';
  },
};
