import React from 'react';

import { Box, HStack } from '@fhs-legacy/universal-components';

import { DELIVERY_PROGRESS_COLOR } from './constants';

const ProgressBarLine = HStack.withConfig({
  position: 'absolute',
  top: '50px',
  left: '45px',
  right: '35px',
  height: '$1',
});

const CompleteSegment = Box.withConfig({
  backgroundColor: DELIVERY_PROGRESS_COLOR,
});

const RemainingSegment = Box.withConfig({
  backgroundColor: Styles.color.grey.four,
});

interface ProgressBarProps {
  percentComplete: number;
}

const ProgressBar: React.FC<React.PropsWithChildren<ProgressBarProps>> = ({
  percentComplete = 0,
}) => {
  return (
    <ProgressBarLine>
      <CompleteSegment width={`${percentComplete}%`} />
      <RemainingSegment width={`${100 - percentComplete}%`} />
    </ProgressBarLine>
  );
};

export default ProgressBar;
