import React, { useCallback, useMemo } from 'react';

import { Checkbox } from '@fhs-legacy/universal-components';
import { useCRMEventsContext } from 'state/crm-events';

import { ModifierListItem } from './modifier-list-item';
import { IModifierTypeUIProps } from './types';
import {
  getModifierOptionMultiplier,
  getSingleSelectedOption,
  logProductModifierCustomization,
  transformSelectedOptionToSelections,
} from './utils';

export const ModifierCheckbox = ({
  modifier,
  selections,
  onSelectionsChange,
  selectedItem,
}: IModifierTypeUIProps) => {
  const selectedOption = useMemo(() => getSingleSelectedOption(selections), [selections]);
  const isChecked = getModifierOptionMultiplier(selectedOption) === 1;
  const { logRBIEvent } = useCRMEventsContext();
  const handleCheckClick = useCallback(() => {
    const newSelectedOptionMultiplier = isChecked ? 0 : 1;
    const newSelectedOption = modifier.options.find(
      opt => getModifierOptionMultiplier(opt) === newSelectedOptionMultiplier
    );
    if (newSelectedOption) {
      onSelectionsChange(transformSelectedOptionToSelections(newSelectedOption));
      logProductModifierCustomization(
        newSelectedOption.name?.locale ?? '',
        modifier.name.locale,
        newSelectedOption._key,
        logRBIEvent
      );
    }
  }, [isChecked, modifier.options, onSelectionsChange, logRBIEvent]);

  const value = `checkbox-${modifier.name?.locale}`;

  return (
    <ModifierListItem
      isListItemClickable
      content={modifier}
      options={modifier.options}
      selectedOption={selectedOption}
      onListItemClick={handleCheckClick}
      selectedItem={selectedItem}
      controls={
        <Checkbox
          testID="modifier-checkbox-control"
          isChecked={isChecked}
          onChange={handleCheckClick}
          value={value}
          name={value}
          accessibilityLabel={value}
        />
      }
    />
  );
};
