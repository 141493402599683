import { PropsWithChildren } from 'react';

import ErrorNetwork from 'components/error-network';
import { CookieBannerContainer } from 'components/layout/cookie-banner-container';
import useLogPageView from 'components/layout/use-log-page-view';
import { useResetStoreResetCartResetServiceModeTimeout } from 'hooks/reset-store-timeout';
import { useDatadogRum } from 'hooks/rum/use-datadog-rum';
import { useForter } from 'hooks/use-forter';
import useGoogleAnalytics from 'hooks/use-google-analytics';

/**
 * Once the React Navigate is fully tested and in PROD, we can deprecate the ENABLE_REACT_NATIVE_NAVIGATION feature flag.
 * At which point this file will have its web counterpart instead of app-container-rn and app-container-web.
 */
export const AppContainer = ({ children: AppContainerComponent }: PropsWithChildren) => {
  useGoogleAnalytics();
  useResetStoreResetCartResetServiceModeTimeout();
  useDatadogRum();
  useForter();
  useLogPageView();

  return (
    <>
      <CookieBannerContainer />
      {AppContainerComponent}
      <ErrorNetwork />
    </>
  );
};
