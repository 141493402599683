import { useQuery } from '@fhs/client';
import { create } from '@fhs/zustand';
import useGooglePay from '@fhs-legacy/frontend/src/state/google-pay/hooks/use-google-pay';
import { PaymentMethod } from 'components/payments/integrations/hooks/types';
import { PaymentMethodType } from 'generated/graphql-gateway';

import { getLegacyUserPaymentInformationQuery } from '../api';

export const usePaymentsStore = create<{
  selectedPaymentMethodId: null | string;
  setSelectedPaymentMethodId(payment: string): void;
}>(set => ({
  selectedPaymentMethodId: null,
  setSelectedPaymentMethodId: selectedPaymentMethodId => {
    set({ selectedPaymentMethodId });
  },
}));

export const usePaymentAdapter = () => {
  const { data, isPending } = useQuery(getLegacyUserPaymentInformationQuery);
  const { canUseGooglePay } = useGooglePay();
  const selectPaymentMethodId = usePaymentsStore(s => s.setSelectedPaymentMethodId);
  const selectedPaymentMethodId =
    usePaymentsStore(s => s.selectedPaymentMethodId) ??
    data?.paymentPreferences?.storedPaymentMethodId;

  const availablePaymentMethods: PaymentMethod[] = [
    // ..._addMethodWhen(canUseApplePay, applePayCardDetails),
    ..._addMethodWhen(canUseGooglePay, {
      type: PaymentMethodType.GOOGLE_PAY,
      id: PaymentMethodType.GOOGLE_PAY,
    }),
    ...(data?.storedPaymentMethods ?? []),
  ];

  const selectedPaymentMethod = availablePaymentMethods?.find(
    s => s?.id === selectedPaymentMethodId
  );
  return {
    availablePaymentMethods,
    selectedPaymentMethod,
    selectPaymentMethod(methodId: string) {
      selectPaymentMethodId(methodId);
    },
    isPending,
  };
};

/**
 * Adds a payment method to an array if its type is enabled. This can be used when conditionally spreading
 * an array into another array.
 * @param condition boolean value that states whether the payment method is enabled/disabled
 * @param paymentMethod payment method that may or may not be added to the array
 * @returns an array with the enabled payment method or an empty array
 */
export const _addMethodWhen = (condition: boolean, paymentMethod: any) =>
  condition ? [paymentMethod] : [];
