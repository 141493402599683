import { ReactElement } from 'react';

import { FormControlLabelProps, IconProps, InputProps } from '@fhs-legacy/universal-components';

export interface TextInputProps extends InputProps {
  label?: string;
  hintMessage?: string;
  errorMessage?: string;
  required?: boolean;
  maxLength?: number;
  endIcon?: ReactElement<IconProps> | null;
  labelProps?: FormControlLabelProps;
}
