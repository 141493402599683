import {
  Box,
  Header as DefaultHeader,
  Text,
  makeUclComponent,
} from '@fhs-legacy/universal-components';
import LoadingAnimation from 'components/loading-animation';
import { LoyaltyNoRewardPoints } from 'pages/loyalty/loyalty-no-reward-points';

import LoyaltyClaimPointsLink from '../loyalty-claim-points/loyalty-claim-points-link';

export const MainHeader = DefaultHeader.withConfig({
  textAlign: 'center',
  fontSize: { base: 'lg', desktop: '32px' },
});

export const SubText = Text.withConfig({
  textAlign: 'center',
  fontSize: 'xs',
  lineHeight: '16px',
  marginX: 'auto',
  marginTop: '$2',
  paddingBottom: '$4',
  width: '280px',
});

const Header = Text.withConfig({
  fontWeight: Styles.fontWeight.heavy,
});

export const RightHeader = Header.withConfig({
  flex: 1,
  fontSize: 'xs',
  lineHeight: '16px',
  textAlign: 'right',
});

export const LeftHeader = Header.withConfig({
  flexGrow: 1,
  textAlign: 'left',
  fontSize: 'xs',
  lineHeight: '16px',
});

export const CenterHeader = Header.withConfig({
  paddingRight: '$4',
  fontSize: 'xs',
  lineHeight: '16px',
  flexGrow: 1,
  textAlign: 'left',
});

export const StyledLoadingAnimation = makeUclComponent(LoadingAnimation).withConfig({
  marginTop: '$12',
});

export const LoyaltyContainer = Box.withConfig({
  backgroundColor: Styles.color.background,
});

export const HistoryContainer = Box.withConfig({
  alignSelf: 'center',
  height: 'full',
  width: 'full',
  maxWidth: '383px',
  paddingX: '$5',
  paddingBottom: '$9',
  marginTop: '$4',
  _web: {
    flexShrink: 'unset',
  },
});

export const RefreshContainer = Box;
// TODO: RN migrate this
// export const RefreshContainer = styled.div`
//   & > div:first-child {
//     min-height: 100vh;
//   }
// `;

export const TableWrap = Box.withConfig<{ idx?: Number; bgColor?: String }>(props => ({
  position: 'relative',
  bgColor: props.bgColor || Styles.color.white,
  // opacity: 0,
  // TODO: RN - animation
  // animation: ${slideIn} 0.45s ${({ idx = 0 }) => 0.1 * idx}s forwards;
  borderRadius: Styles.borderRadius,
  borderWidth: '0px',
  borderStyle: 'solid',
  borderColor: Styles.color.grey.four,
  // maxWidth: '300px',
  width: 'full',
}));

export const TransactionDateAndTime = Box.withConfig({
  alignItems: 'flex-start',
});

export const TransactionDate = Text.withConfig({
  fontSize: 'xs',
  lineHeight: '16px',
});

export const TransactionTime = Text.withConfig({
  fontSize: 'xs',
  lineHeight: '16px',
});

export const StyledLoyaltyClaimPointsLink = LoyaltyClaimPointsLink.withConfig({
  marginY: '$2',
  _textProps: {
    color: 'token.text-button-secondary',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
});

export const StyledLoyaltyNoRewardPoints = LoyaltyNoRewardPoints;
