import { useIntl } from 'react-intl';

import { ILinkProps } from 'components/link';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { isWeb } from 'utils/environment';

/**
 * Checks if `to` props is to a ccpa link.
 * If it is, we forward all the props with the exception of `children`, `to`, and `onPress`--replace children with translated CCPA copy and trigger the one-trust modal on press,
 * if it isn't, we forward all the props
 */
export function useGetMaybeCcpaLinkProps(): (linkProps: ILinkProps) => ILinkProps {
  const oneTrustFlagEnabled = useFlag(LaunchDarklyFlag.ENABLE_ONE_TRUST_COOKIE_CONSENT_BANNER);
  const ccpaLinkFlagEnabled = useFlag(LaunchDarklyFlag.ENABLE_CCPA_DO_NOT_SELL_BUTTON);
  const { formatMessage } = useIntl();

  return (linkProps: ILinkProps) => {
    const isCcpaLink = (
      typeof linkProps.to === 'object' ? linkProps.to.pathname : linkProps.to
    )?.includes?.('ccpa-compliance');
    const showCcpaLink = isCcpaLink && oneTrustFlagEnabled && ccpaLinkFlagEnabled && isWeb;

    if (!showCcpaLink) {
      return linkProps;
    }

    const { children: _children, to: _to, ...linkPropsToForward } = linkProps;
    return {
      ...linkPropsToForward,
      onPress: () => window?.OneTrust?.ToggleInfoDisplay(),
      children: formatMessage({ id: 'CCPADontSellButton' }),
    };
  };
}
