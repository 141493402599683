import { Svg, SvgPath, type SvgProps, SvgRect, registerSVG } from '../../../src/components/svg';

export function AmazonPay(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgRect y="4" width="24" height="16" rx="2" fill="white" />
      <SvgRect
        x="0.5"
        y="4.5"
        width="23"
        height="15"
        rx="1.5"
        stroke="black"
        strokeOpacity="0.3"
        fill="white"
      />
      <SvgPath
        d="M15.5116 15.232C11.3841 17.1963 8.82258 15.5528 7.18285 14.5546C7.08139 14.4917 6.90893 14.5694 7.05856 14.7412C7.60484 15.4036 9.39509 17 11.7319 17C14.0703 17 15.4614 15.7241 15.6355 15.5015C15.8083 15.2808 15.6862 15.1591 15.5115 15.232H15.5116ZM16.6707 14.5919C16.5599 14.4475 15.9968 14.4206 15.6424 14.4642C15.2874 14.5064 14.7546 14.7234 14.8009 14.8536C14.8247 14.9024 14.8732 14.8805 15.1171 14.8586C15.3616 14.8342 16.0465 14.7478 16.1893 14.9343C16.3326 15.1222 15.9708 16.0171 15.9047 16.1615C15.8409 16.3058 15.9291 16.343 16.049 16.2469C16.1673 16.1508 16.3815 15.9019 16.5252 15.5498C16.6679 15.1957 16.7549 14.7018 16.6707 14.5919H16.6707Z"
        fill="#FF9900"
      />
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7361 11.2238C12.7361 11.7392 12.7491 12.169 12.4886 12.6267C12.2784 12.9989 11.9453 13.2278 11.5732 13.2278C11.0652 13.2278 10.7693 12.8407 10.7693 12.2695C10.7693 11.1419 11.7797 10.9372 12.7361 10.9372V11.2238ZM14.0702 14.4484C13.9828 14.5265 13.8562 14.5321 13.7576 14.48C13.3185 14.1153 13.2403 13.946 12.9985 13.598C12.2728 14.3386 11.7592 14.56 10.8177 14.56C9.705 14.56 8.83789 13.8734 8.83789 12.4984C8.83789 11.4248 9.42033 10.6935 10.2483 10.3363C10.9666 10.0199 11.9695 9.96409 12.7361 9.87667V9.70547C12.7361 9.39099 12.7603 9.01887 12.5761 8.74722C12.4142 8.50346 12.1053 8.40298 11.8336 8.40298C11.3294 8.40298 10.8791 8.6616 10.7693 9.19748C10.747 9.31659 10.6595 9.43382 10.5405 9.43939L9.25654 9.30173C9.14864 9.27749 9.02957 9.19007 9.05933 9.02443C9.35518 7.46893 10.76 7 12.0178 7C12.6616 7 13.5027 7.1712 14.0107 7.65872C14.6545 8.2597 14.5931 9.06166 14.5931 9.93432V11.996C14.5931 12.6156 14.8498 12.8872 15.0917 13.2222C15.1773 13.3413 15.1959 13.4846 15.088 13.5738C14.8182 13.799 14.3381 14.2177 14.0739 14.4521L14.0702 14.4484"
        fill="black"
      />
      <SvgPath
        d="M15.5116 15.232C11.3841 17.1963 8.82258 15.5528 7.18285 14.5546C7.08139 14.4917 6.90893 14.5694 7.05856 14.7412C7.60484 15.4036 9.39509 17 11.7319 17C14.0703 17 15.4614 15.7241 15.6355 15.5015C15.8083 15.2808 15.6862 15.1591 15.5115 15.232H15.5116ZM16.6707 14.5919C16.5599 14.4475 15.9968 14.4206 15.6424 14.4642C15.2874 14.5064 14.7546 14.7234 14.8009 14.8536C14.8247 14.9024 14.8732 14.8805 15.1171 14.8586C15.3616 14.8342 16.0465 14.7478 16.1893 14.9343C16.3326 15.1222 15.9708 16.0171 15.9047 16.1615C15.8409 16.3058 15.9291 16.343 16.049 16.2469C16.1673 16.1508 16.3815 15.9019 16.5252 15.5498C16.6679 15.1957 16.7549 14.7018 16.6707 14.5919H16.6707Z"
        fill="#FF9900"
      />
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7361 11.2238C12.7361 11.7392 12.7491 12.169 12.4886 12.6267C12.2784 12.9989 11.9453 13.2278 11.5732 13.2278C11.0652 13.2278 10.7693 12.8407 10.7693 12.2695C10.7693 11.1419 11.7797 10.9372 12.7361 10.9372V11.2238ZM14.0702 14.4484C13.9828 14.5265 13.8562 14.5321 13.7576 14.48C13.3185 14.1153 13.2403 13.946 12.9985 13.598C12.2728 14.3386 11.7592 14.56 10.8177 14.56C9.705 14.56 8.83789 13.8734 8.83789 12.4984C8.83789 11.4248 9.42033 10.6935 10.2483 10.3363C10.9666 10.0199 11.9695 9.96409 12.7361 9.87667V9.70547C12.7361 9.39099 12.7603 9.01887 12.5761 8.74722C12.4142 8.50346 12.1053 8.40298 11.8336 8.40298C11.3294 8.40298 10.8791 8.6616 10.7693 9.19748C10.747 9.31659 10.6595 9.43382 10.5405 9.43939L9.25654 9.30173C9.14864 9.27749 9.02957 9.19007 9.05933 9.02443C9.35518 7.46893 10.76 7 12.0178 7C12.6616 7 13.5027 7.1712 14.0107 7.65872C14.6545 8.2597 14.5931 9.06166 14.5931 9.93432V11.996C14.5931 12.6156 14.8498 12.8872 15.0917 13.2222C15.1773 13.3413 15.1959 13.4846 15.088 13.5738C14.8182 13.799 14.3381 14.2177 14.0739 14.4521L14.0702 14.4484"
        fill="black"
      />
    </Svg>
  );
}

registerSVG('AmazonPay', AmazonPay);
