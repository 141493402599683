import { useId } from 'react';
import { ClipPath, Defs, G, Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconForkAxe = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;
  const id = useId();

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <G clipPath={`url(#${id}`}>
        <Path
          fill="currentColor"
          fillRule="evenodd"
          d="M0 12c0 6.617 5.383 12 12 12 6.616 0 12-5.384 12-12 0-6.617-5.384-12-12-12C5.383 0 0 5.383 0 12Zm1.076 0c0-6.024 4.9-10.924 10.924-10.924 6.024 0 10.924 4.9 10.924 10.924 0 6.023-4.901 10.924-10.924 10.924-6.024 0-10.924-4.901-10.924-10.924Zm6.656-6.073c.468-.583 1.075-1.338.964-1.61l-1.191.576-1.19.576c-.577.434-.986.96-1.39 1.48-.654.84-1.295 1.664-2.606 2.054.689 1.39 1.621 2.195 3.15 2.766h.23c.01-.9.242-1.344.488-1.816.125-.238.253-.482.358-.796l.154-.077.324.248c1.239.945 2.563 1.955 3.747 2.979-.592.596-1.768 1.242-3.001 1.919-1.824 1.001-3.773 2.07-4.144 3.152l.077.537c.175.392.405.561.922.615 1.633-.773 5.97-4.178 7.068-5.532 1.227.31 4.87 2.696 5.609 3.611.166.206.316.535.472.877.236.52.487 1.072.834 1.274.781.383 1.542-.103 1.767-.46.618-.692-1.424-2.101-2.344-2.736-.145-.1-.262-.181-.337-.237-1.386-1.04-3.173-2.201-4.119-2.816-.393-.256-.64-.417-.652-.435l.998-.921c.192.03.368.089.542.147.372.123.735.244 1.225.083.925-.303 4.915-3.408 5.301-4.225.006-.117-.05-.153-.153-.154L17.53 9.234l-.23-.307 3.073-2.536c.204-.176.038-.45-.154-.384l-3.457 2.074-.154-.076c.294-.894 2.267-2.1 3.073-2.536.132-.141.103-.257-.076-.307-1.036.223-5.121 2.924-5.686 3.688-.347.47-.352 1.004-.307 1.69l-1.383.922c-.55-.044-4.129-3.001-4.379-3.611l.077-.768-.615-.615c.092-.135.246-.326.419-.541Z"
          clipRule="evenodd"
        />
      </G>
      <Defs>
        <ClipPath id={id}>
          <Path fill="#fff" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
