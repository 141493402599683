import React from 'react';

import { Wordmark } from '@fhs/ui';
import { VStack } from '@fhs-legacy/universal-components';
import { routes } from 'utils/routing';

import { LogoLink } from './sign-up-headline.styled';
import { ISignUpHeadlineProps } from './types';

const SignUpHeadline: React.VFC<ISignUpHeadlineProps> = () => {
  return (
    <VStack>
      <LogoLink to={routes.base}>
        <Wordmark
          height={96}
          width={256}
          testID="sign-up-loyalty-logo"
          style={{ padding: 16, marginBottom: 4 }}
        />
      </LogoLink>
    </VStack>
  );
};

export default SignUpHeadline;
