import { Box, Text } from '@fhs-legacy/universal-components';

export const PullToRefreshContainer = Box.withConfig<{ isRefreshing: boolean }>(p => ({
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  minHeight: p.isRefreshing ? '50px' : '0px',
}));

export const ReleaseMessage = Text.withConfig({
  fontWeight: 'bold',
});

export const PullDownMessage = Text.withConfig({
  fontWeight: 'bold',
});
