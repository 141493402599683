import { StyleSheet, View, ViewProps } from 'react-native';

import { tokens } from '../../tokens';
import { Text } from '../text/text';

import { useButtonContext } from './button';

export type ButtonBadgeProps = Omit<ViewProps, 'children'> & {
  text: string;
};
export function ButtonBadge({ text, ...props }: ButtonBadgeProps) {
  const buttonContext = useButtonContext();
  return (
    <View {...props} style={[styles.badge, styles[`badge-${buttonContext.size}`], props.style]}>
      <Text weight="semibold" style={[styles.badgeText, styles[`badgeText-${buttonContext.size}`]]}>
        {text}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  badge: {
    position: 'absolute',
    backgroundColor: tokens.colors.$houseDark,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 8,
    minHeight: 24,
    minWidth: 24,
    right: 2,
    top: '-45%',
  },
  // TODO: adjust badge style based on button size
  'badge-xs': {},
  'badge-sm': {},
  'badge-md': {},
  'badge-lg': {},
  'badge-xl': {},

  badgeText: {
    color: tokens.colors.$white,
    fontVariant: ['tabular-nums'],
    fontSize: 12,
    lineHeight: 16,
  },
  // TODO: adjust badge text style based on button size
  'badgeText-xs': {},
  'badgeText-sm': {},
  'badgeText-md': {},
  'badgeText-lg': {},
  'badgeText-xl': {},
});
