import { IntlFormatters } from 'react-intl';

const HOUR_IN_MS = 1000 * 60 * 60;
const DAY_IN_MS = HOUR_IN_MS * 24;

export const countDown = (expireDate: Date, formatMessage: IntlFormatters['formatMessage']) => {
  const now = new Date().getTime();
  const countdown = expireDate.getTime() - now;
  if (countdown > 0) {
    const days = Math.floor(countdown / DAY_IN_MS);
    const hours = Math.floor((countdown % DAY_IN_MS) / HOUR_IN_MS);
    if (days >= 1) {
      return formatMessage({ id: 'offerExpiresDays' }, { days });
    }
    return formatMessage({ id: 'offerExpiresHours' }, { hours });
  }
  return formatMessage({ id: 'promotionEnded' });
};
