import { useVerifyPhoneNumberMutation } from 'generated/rbi-graphql';
import { platform, welcomeEmailDomain } from 'utils/environment';
import logger from 'utils/logger';

interface IGetSessionIdAndChallengeCodeOtp {
  sessionId: string;
  code: string;
}

const useUserPhoneVerification = () => {
  const [verifyPhoneNumber, { loading }] = useVerifyPhoneNumberMutation();
  const verifyPhone = async ({ sessionId, code }: IGetSessionIdAndChallengeCodeOtp) => {
    try {
      await verifyPhoneNumber({
        variables: {
          input: {
            code,
            sessionId,
            platform: platform(),
            stage: welcomeEmailDomain(),
          },
        },
      });
    } catch (error) {
      logger.error({ error, message: 'Failed to verify phone number' });
      throw error;
    }
  };
  return {
    loading,
    verifyPhone,
  };
};

export default useUserPhoneVerification;
