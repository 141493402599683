import { router, usePathname } from 'expo-router';
import { get } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { DialogActionSheet } from 'components/confirmation-actionsheet';
import RestaurantRedemptionModal from 'components/offer-redemption-modal/redemption-modal';
import ReplaceIncentiveModal from 'components/replace-incentive-modal';
import { IncentiveType } from 'components/replace-incentive-modal/types';
import {
  LoyaltyPromotionType,
  useCreateOrderlessTransactionMutation,
} from 'generated/graphql-gateway';
import { OfferType } from 'generated/rbi-graphql';
import { useFeatureEarningCalculationQuery } from 'generated/sanity-graphql';
import useDialogModal from 'hooks/use-dialog-modal';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { useRewardsFlow } from 'hooks/use-rewards-flow';
import { useSwitchCartMethodDialog } from 'hooks/use-switch-cart-method-dialog';
import { useToast } from 'hooks/use-toast';
import { parseIncentiveData } from 'pages/loyalty/loyalty-incentives-components/incentive-card/parse-incentive-data';
import {
  getMenuItemDescriptorForIncentive,
  getNavigationParamsForIncentive,
  makeUrlForIncentive,
} from 'pages/loyalty/loyalty-incentives-components/incentive-details/utils';
import { ModalRewardItemUnavailable } from 'pages/loyalty/loyalty-incentives-components/modal-reward-item-unavailable-modal';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { ICreateCartEntryParams } from 'state/loyalty/in-restaurant-redemption/hooks/use-in-restaurant-redemption-cart';
import { canStackReward } from 'state/loyalty/in-restaurant-redemption/hooks/use-in-restaurant-redemption-cart/utils';
import {
  LoyaltyAppliedOffer,
  isLegacyOffer,
  isLoyaltyOffer,
  isReward,
  isSurpriseOffer,
} from 'state/loyalty/types';
import {
  getCanStackOfferCheck,
  isAppliedOfferSwap,
  isDiscountLoyaltyOffer,
} from 'state/loyalty/utils';
import { useMenuContext } from 'state/menu';
import { useOrderContext } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { DIFFERENCE_TIME_UNITS, getDifferenceToNow } from 'utils/dateTime';
import logger from 'utils/logger';
import { routes } from 'utils/routing';
import { getFirstStringInLocaleBlockContent } from 'utils/sanity';

import { AddToCartArgs, Incentive, RedemptionMethod } from './types';

/**
 * Wraps the param Component in another component in order to add the function
 * addIncentiveToCart which takes an incentive and a redemption type and adds it to the loyaltyContext
 * It also handles and shows the related modals for each situation.
 * Eg: Shows "only one coupon allowed" if you already have an Offer in the cart.
 *
 * @param Component The component you want to wrap with this logic
 */
const withAddIncentiveToCart = (Component: React.FC<React.PropsWithChildren<any>>) => {
  return (props: any) => {
    // Utils
    const toast = useToast();
    const { formatMessage } = useIntl();
    const pathname = usePathname();
    const enableCooldownToast = useFlag(LaunchDarklyFlag.ENABLE_COOLDOWN_TOAST_INFO);
    const enableMultipleRewards = useFlag(LaunchDarklyFlag.ENABLE_MULTIPLE_REWARDS_PER_ORDER);
    const enableLoyaltyAlohaIntegration = useFlag(
      LaunchDarklyFlag.ENABLE_LOYALTY_ALOHA_INTEGRATION
    );
    const isFHSLoyaltyUpdateFatureEnabled = useFlag(LaunchDarklyFlag.ENABLE_FHS_LOYALTY_UPDATE);

    // State
    const [incentiveName, setIncentiveName] = useState<string>('');
    const [selectedIncentive, setSelectedIncentive] = useState<Incentive>();
    const [showOfferRedemptionModal, setShowOfferRedemptionModal] = useState(false);
    const [openReplaceDialog, setOpenReplaceDialog] = useState(false);
    const [selectedArgs, setSelectedArgs] = useState<AddToCartArgs>();
    const [openReplaceRewardDialog, setOpenReplaceRewardDialog] = useState<boolean>(false);
    const [openReplaceOfferDialog, setOpenReplaceOfferDialog] = useState<boolean>(false);

    // Context
    const { engineRewardsMap } = useLoyaltyRewardsList();
    const { noStoreSelected } = useStoreContext();
    const { setSelectedStaticMenuItem } = useMenuContext();
    const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
    const dispatch = useAppDispatch();
    const { serviceMode } = useServiceModeContext();
    const { removeFromCart, cartEntries } = useOrderContext();
    const { logRBIEvent } = useCRMEventsContext();
    const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
    const appliedLoyaltyRewardsArray = useAppSelector(
      selectors.loyalty.selectAppliedLoyaltyRewardsArray
    );
    const hasAppliedRewards = appliedLoyaltyRewardsArray && appliedLoyaltyRewardsArray.length > 0;
    const hasAppliedOffers = appliedOffers.length > 0;
    const {
      inRestaurantRedemptionCart,
      inRestaurantLoyaltyEnabledAtRestaurant,
      inRestaurantRedemptionEnabled,
      addInRestaurantRedemptionEntry,
      enableOfferRedemption,
      clearInRestaurantRedemptionAllRewards,
    } = useInRestaurantRedemptionContext();
    const { loyaltyUser, loyaltyUserId } = useLoyaltyUser();
    // @ts-ignore
    const [InvalidIncentiveModal, openInvalidIncentiveModal] = useDialogModal({
      modalAppearanceEventMessage: 'Invalid In Restaurant Incentive Redemption',
    });
    const [createOrderlessTransaction] = useCreateOrderlessTransactionMutation({
      variables: {
        input: {
          loyaltyId: loyaltyUserId,
          channel: 'ORDERLESS',
          incentives: [
            { id: selectedIncentive?.loyaltyEngineId || '', type: LoyaltyPromotionType.OFFER },
          ],
        },
      },
      onCompleted: () => {
        setShowOfferRedemptionModal(true);
      },
      onError: () =>
        openInvalidIncentiveModal({ message: formatMessage({ id: 'unableToPlaceOrder' }) }),
    });

    const { data: earningCalculationData } = useFeatureEarningCalculationQuery({
      variables: { id: 'earningCalculation' },
    });

    const diffInMin = getDifferenceToNow(
      DIFFERENCE_TIME_UNITS.MINUTES,
      loyaltyUser?.offerRedemptionAvailability?.availableAfter || ''
    );

    const showOfferCooldownToast = useCallback(() => {
      //If the diffInMin is greater than 0 it means that the user already have an offer in course so we do nothing.
      if (!enableCooldownToast || diffInMin > 0 || !serviceMode) {
        return;
      }

      toast.show({
        text: formatMessage(
          { id: 'offersCooldownInfo' },
          {
            period: earningCalculationData?.EarningCalculation?.offerRedemptionCooldownPeriod,
          }
        ),
        variant: 'neutral',
      });
    }, [
      diffInMin,
      earningCalculationData?.EarningCalculation?.offerRedemptionCooldownPeriod,
      enableCooldownToast,
      formatMessage,
      serviceMode,
      toast,
    ]);

    // Dialogs

    const [ItemUnavailableModal, openItemUnavailableModal] = useDialogModal<
      {},
      { rewardName: string }
    >({
      // @ts-expect-error TS(2322) FIXME: Type 'FC<IUseDialogComponentProps & { rewardName: ... Remove this comment to see the full error message
      Component: ModalRewardItemUnavailable,
    });

    const [ReadyToRedeemDialog, openReadyToRedeemDialog] = useDialogModal({
      modalAppearanceEventMessage: 'Confirm Ready to Redeem',
      onConfirm: () => {
        if (selectedIncentive && isLoyaltyOffer(selectedIncentive)) {
          const name = selectedIncentive.name;
          logRBIEvent({
            name: CustomEventNames.LEGACY_OFFER_ADDED_IN_STORE_ORDER,
            type: EventTypes.Other,
            attributes: {
              sanityId: selectedIncentive._id || '',
              // @ts-expect-error TS(2345) FIXME: Argument of type '{ readonly __typename?: "LocaleB... Remove this comment to see the full error message
              name: name ? getFirstStringInLocaleBlockContent(name) : '',
            },
          });
        }
        if (loyaltyUserId && selectedIncentive?.loyaltyEngineId) {
          createOrderlessTransaction();
        } else {
          setShowOfferRedemptionModal(true);
        }
        if (
          selectedIncentive &&
          (isLoyaltyOffer(selectedIncentive) || isLegacyOffer(selectedIncentive))
        ) {
          showOfferCooldownToast();
        }
      },
      showCancel: false,
    });
    const {
      SwitchCartMethodDialog,
      shouldShowSwitchToMobileDialog,
      shouldShowSwitchToStoreDialog,
      openSwitchCartMethod,
    } = useSwitchCartMethodDialog();

    const { rewardRedemptionMode } = useRewardsFlow();

    // Handle add to cart flow
    const addIncentiveToCart = (args: AddToCartArgs) => {
      setSelectedIncentive(args.incentive);
      setSelectedArgs(args);
      if (isLoyaltyOffer(args.incentive)) {
        const canStackOfferToMobile = getCanStackOfferCheck((of: LoyaltyAppliedOffer) => of);
        const canStackOfferToInRestaurant = getCanStackOfferCheck(entry =>
          get(entry, 'details.offer', null)
        );

        const hasRewardIncentiveWithOffers = isFHSLoyaltyUpdateFatureEnabled && hasAppliedRewards;
        // If the flag is ON - do something
        // expected: showReplaceModal = true
        const showReplaceOfferModal =
          !canStackOfferToMobile(args.incentive, appliedOffers) ||
          !canStackOfferToInRestaurant(args.incentive, inRestaurantRedemptionCart) ||
          hasRewardIncentiveWithOffers;
        // If the flag is OFF - do something
        // expected: showReplaceModal = false
        if (showReplaceOfferModal) {
          return setOpenReplaceOfferDialog(true);
        }
      }

      if (
        isReward(args.incentive) &&
        args.method === RedemptionMethod.MOBILE &&
        !shouldShowSwitchToMobileDialog
      ) {
        const appliedRewards = cartEntries?.filter(
          (item: any) => appliedLoyaltyRewards?.[item.cartId]
        )?.length;
        const hasRewardIncentiveApplied = appliedRewards > 0;
        // Evaluates loyalty update for flag: ENABLE_FHS_LOYALTY_UPDATE ON/OFF
        const hasOfferIncentiveApplied = isFHSLoyaltyUpdateFatureEnabled && hasAppliedOffers;
        if (hasRewardIncentiveApplied || hasOfferIncentiveApplied) {
          setOpenReplaceRewardDialog(true);
          return;
        }
      }

      if (noStoreSelected) {
        router.navigate({
          pathname: routes.storeLocator,
          params: { back: pathname, rewardRedemptionMode },
        });
        return;
      }
      redeemIncentive(args);
    };

    const redeemIncentive = (args: AddToCartArgs) => {
      if (args) {
        if (args.method === RedemptionMethod.MOBILE) {
          if (shouldShowSwitchToMobileDialog) {
            openSwitchCartMethod({ onConfirm: () => mobileRedemption(args) });
          } else {
            mobileRedemption(args);
          }
          return;
        }
        tryInRestaurantRedemption(args);
      }
    };
    const onDismissReplaceReward = useCallback(() => {
      setOpenReplaceRewardDialog(false);
      router.canGoBack() ? router.back() : router.replace(routes.rewards);
    }, []);

    const tryInRestaurantRedemption = (args: AddToCartArgs) => {
      if (shouldShowSwitchToStoreDialog) {
        openSwitchCartMethod({
          onConfirm: () => handleInRestaurantRedemption(args),
        });
      } else {
        handleInRestaurantRedemption(args);
      }
    };

    const handleInRestaurantRedemption = (args: AddToCartArgs) => {
      const isInStoreLoyaltyRedemptionEnabled =
        inRestaurantRedemptionEnabled && inRestaurantLoyaltyEnabledAtRestaurant;
      const shouldRedeemInRestaurant =
        isInStoreLoyaltyRedemptionEnabled && (isReward(args.incentive) || enableOfferRedemption);

      if (shouldRedeemInRestaurant) {
        inRestaurantRedemption(args);
      } else {
        openReadyToRedeemDialog();
      }
    };

    const mobileRedemption = useCallback(
      ({ incentive }: AddToCartArgs) => {
        if (isLoyaltyOffer(incentive)) {
          dispatch(actions.loyalty.setSelectedOffer(incentive));

          if (isDiscountLoyaltyOffer(incentive)) {
            if (!incentive.isStackable) {
              appliedOffers.forEach(offer => {
                // removes offer from cart if the applied offer is not stackable or a swap offer
                if (!offer.isStackable && !isAppliedOfferSwap(offer)) {
                  removeFromCart({ cartId: offer.cartId });
                }
              });
            }

            // Discount offers should not show menu item details
            dispatch(
              actions.loyalty.applyOffer({
                id: incentive.loyaltyEngineId,
                type: OfferType.GLOBAL,
                isStackable: incentive.isStackable,
                isSurprise: isSurpriseOffer(incentive),
                cmsId: incentive._id,
                cartId: 'discount-offer',
              })
            );
            toast.show({
              text: formatMessage({ id: 'offerAddedToCart' }),
              variant: 'positive',
            });

            router.navigate(routes.menu);
            return;
          }
        }

        const engineReward = engineRewardsMap[incentive?.loyaltyEngineId || ''];
        const menuItemDescriptor = getMenuItemDescriptorForIncentive(
          incentive,
          engineReward?.rewardBenefitId
        );
        const incentiveMenuPath = makeUrlForIncentive(incentive, menuItemDescriptor);
        if (!menuItemDescriptor || !incentiveMenuPath) {
          logger.warn(
            `Attempting to make a url for reward that is missing data: reward ${incentive._id}`
          );
          const incentiveData = parseIncentiveData(incentive);
          setIncentiveName(incentiveData.name);
          openItemUnavailableModal();
          return;
        }

        // Save incentive data before redirecting to StoreLocator
        if (noStoreSelected) {
          const searchIndex = incentiveMenuPath.indexOf('?');
          const search = searchIndex !== -1 ? incentiveMenuPath.slice(searchIndex) : '';
          setSelectedStaticMenuItem({
            itemId: `${menuItemDescriptor._id}-${menuItemDescriptor._type}`,
            itemName: incentiveName || '',
            search,
          });
        }

        const navigationParams = getNavigationParamsForIncentive(incentive, menuItemDescriptor);
        const pathname = navigationParams?.pathname;

        if (pathname) {
          router.navigate({
            pathname,
            params: navigationParams?.params,
          });
        }

        if (isLoyaltyOffer(incentive) || isLegacyOffer(incentive)) {
          showOfferCooldownToast();
        }
      },
      [
        appliedOffers,
        dispatch,
        engineRewardsMap,
        formatMessage,
        incentiveName,
        noStoreSelected,
        openItemUnavailableModal,
        pathname,
        removeFromCart,
        setSelectedStaticMenuItem,
        showOfferCooldownToast,
        toast,
      ]
    );

    const createInRestaurantCartEntry = useCallback(
      (incentive: AddToCartArgs['incentive']) => {
        let inRestaurantCartEntry: ICreateCartEntryParams | undefined;
        if (!isReward(incentive)) {
          inRestaurantCartEntry = { offer: incentive };
        } else {
          const engineReward = engineRewardsMap[incentive?.loyaltyEngineId || ''];
          if (engineReward) {
            inRestaurantCartEntry = {
              reward: incentive,
              engineReward,
            };
          }
        }
        return inRestaurantCartEntry;
      },
      [engineRewardsMap]
    );

    const inRestaurantRedemption = ({ incentive }: AddToCartArgs) => {
      if (
        !enableLoyaltyAlohaIntegration &&
        !enableMultipleRewards &&
        inRestaurantRedemptionCart.length > 0 &&
        isReward(incentive)
      ) {
        setOpenReplaceRewardDialog(true);
        return;
      }

      const inRestaurantCartEntry = createInRestaurantCartEntry(incentive);
      if (inRestaurantCartEntry) {
        if (!canStackReward(inRestaurantRedemptionCart)) {
          return setOpenReplaceDialog(true);
        }
        addInRestaurantRedemptionEntry(inRestaurantCartEntry);
        router.navigate(routes.redeem);
        if (isLoyaltyOffer(incentive) || isLegacyOffer(incentive)) {
          showOfferCooldownToast();
        }
      }
    };

    const onConfirmReplaceOffer = (args: AddToCartArgs) => {
      if (isFHSLoyaltyUpdateFatureEnabled && hasAppliedRewards) {
        const cartIdToRemove = cartEntries?.filter(
          (item: any) => appliedLoyaltyRewards?.[item.cartId]
        )[0]?.cartId;

        removeFromCart({
          cartId: cartIdToRemove,
        });
      }
      const selectedArguments = args || selectedArgs;
      return redeemIncentive(selectedArguments);
    };
    const onConfirmReplaceReward = useCallback(() => {
      if (isFHSLoyaltyUpdateFatureEnabled && hasAppliedOffers) {
        dispatch(actions.loyalty.resetAppliedOffers());
        if (selectedArgs) {
          mobileRedemption(selectedArgs);
        }
      }
      if (selectedArgs?.method === RedemptionMethod.MOBILE) {
        // get the cartId of the applied reward to replace it with the new selected one
        const cartIdToRemove = cartEntries?.filter(
          (item: any) => appliedLoyaltyRewards?.[item.cartId]
        )[0]?.cartId;
        if (!cartIdToRemove) {
          return;
        }
        removeFromCart({
          cartId: cartIdToRemove,
        });
        mobileRedemption(selectedArgs);
        setOpenReplaceRewardDialog(false);
        return;
      }
      if (selectedIncentive) {
        const inRestaurantCartEntry = createInRestaurantCartEntry(selectedIncentive);
        if (inRestaurantCartEntry) {
          clearInRestaurantRedemptionAllRewards();
          addInRestaurantRedemptionEntry(inRestaurantCartEntry);
          setOpenReplaceDialog(false);
          setOpenReplaceRewardDialog(false);
          setTimeout(() => {
            router.replace(routes.redeem);
          }, 500);
        }
      }
    }, [
      selectedArgs,
      selectedIncentive,
      cartEntries,
      removeFromCart,
      mobileRedemption,
      appliedLoyaltyRewards,
      createInRestaurantCartEntry,
      clearInRestaurantRedemptionAllRewards,
      addInRestaurantRedemptionEntry,
    ]);

    const onDismissRestaurantRedemptionModal = () => {
      dispatch(actions.loyalty.setShouldRefetchOffers(true));
      setShowOfferRedemptionModal(false);
      router.replace(routes.loyaltyOfferList);
    };

    return (
      <>
        {openReplaceRewardDialog && (
          <ReplaceIncentiveModal
            isOpen={openReplaceRewardDialog}
            onConfirm={onConfirmReplaceReward}
            onDismiss={onDismissReplaceReward}
            incentiveType={
              isFHSLoyaltyUpdateFatureEnabled ? IncentiveType.INCENTIVE : IncentiveType.REWARD
            }
          />
        )}
        {openReplaceOfferDialog && (
          <ReplaceIncentiveModal
            isOpen={openReplaceOfferDialog}
            onConfirm={onConfirmReplaceOffer}
            onDismiss={() => setOpenReplaceOfferDialog(false)}
            incentiveType={
              isFHSLoyaltyUpdateFatureEnabled ? IncentiveType.INCENTIVE : IncentiveType.OFFER
            }
          />
        )}
        <SwitchCartMethodDialog />
        <ItemUnavailableModal rewardName={incentiveName} />
        <DialogActionSheet
          isOpen={openReplaceDialog}
          onConfirm={onConfirmReplaceReward}
          confirmButtonLabel={formatMessage({ id: 'replaceItem' })}
          onDismiss={() => setOpenReplaceDialog(false)}
          title={formatMessage({ id: 'replaceReward' })}
          body={formatMessage({ id: 'replaceRewardText' })}
        />
        {selectedIncentive && isLoyaltyOffer(selectedIncentive) && (
          <>
            <InvalidIncentiveModal heading={formatMessage({ id: 'uhoh' })} showCloseButton />
            <ReadyToRedeemDialog
              testID="ready-to-redeem-dialog"
              heading={formatMessage({ id: 'couponWillExpire' })}
              body={formatMessage({ id: 'confirmDialogBody' })}
              buttonLabel={formatMessage({ id: 'redeem' })}
              showCloseButton
            />
            {showOfferRedemptionModal && (
              <RestaurantRedemptionModal
                selectedOffer={selectedIncentive}
                redemptionStartTime={Date.now()}
                onDismiss={onDismissRestaurantRedemptionModal}
              />
            )}
          </>
        )}
        <Component {...props} addIncentiveToCart={addIncentiveToCart} />
      </>
    );
  };
};

export default withAddIncentiveToCart;
