import { isNumber } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';

import { RewardsBadge } from '@fhs-legacy/universal-components';
import { IRewardFragment, Maybe } from 'generated/graphql-gateway';
import { POINTS_TRANSLATION_KEY } from 'pages/loyalty/loyalty-rewards/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { RewardPointCost } from '../reward-point-cost';

import {
  PointTextContainer,
  PointsNeededContainer,
  PointsNeededText,
  PointsNeededTextDetail,
  StyledPointsIcon,
  StyledPointsText,
  StyledProgressMeter,
  StyledRewardLock,
} from './incentive-card.styled';

export const RewardCardContent = ({ engineReward }: { engineReward: Maybe<IRewardFragment> }) => {
  const { formatMessage } = useIntl();
  const meterEnabled = Boolean(useFlag(LaunchDarklyFlag.ENABLE_METER_ON_REWARDS));

  if (!engineReward) {
    return null;
  }

  const { pointCost, locked, redemptionPercentage, remainingPointsNeededForRedemption } =
    engineReward;

  const accessibilityLabel =
    pointCost > 0
      ? `${pointCost} ${formatMessage({
          id: POINTS_TRANSLATION_KEY,
        })}`
      : formatMessage({ id: 'free' });

  return (
    <>
      {isNumber(pointCost) && (
        <PointTextContainer accessibilityLabel={accessibilityLabel}>
          {locked ? (
            <RewardsBadge>{pointCost.toString()}</RewardsBadge>
          ) : (
            <>
              <StyledPointsIcon />
              <RewardPointCost pointCost={pointCost}>
                <StyledPointsText>{pointCost}</StyledPointsText>
              </RewardPointCost>
            </>
          )}
        </PointTextContainer>
      )}
      {locked && (
        <>
          {meterEnabled && isNumber(redemptionPercentage) ? (
            <StyledProgressMeter percentage={redemptionPercentage} />
          ) : null}
          {remainingPointsNeededForRedemption ? (
            <PointsNeededContainer>
              <StyledRewardLock />
              <PointsNeededText numberOfLines={1}>
                {formatMessage(
                  { id: 'yourXPointsAway' },
                  {
                    points: (
                      <PointsNeededTextDetail>
                        {remainingPointsNeededForRedemption}
                      </PointsNeededTextDetail>
                    ),
                  }
                )}
              </PointsNeededText>
            </PointsNeededContainer>
          ) : null}
        </>
      )}
    </>
  );
};
