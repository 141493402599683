import { keyBy } from 'lodash';

import { ICartEntry } from '@rbi-ctg/menu';
import { IAppliedOffer } from 'generated/graphql-gateway';
import { CartEntryType } from 'utils/cart/types';

import { ILimitProps, IMinimumProps } from './types';

const determineLimit = ({ maxLimit, maxCateringLimit, isCatering }: ILimitProps): number =>
  isCatering ? maxCateringLimit : maxLimit;

const determineMinimum = ({ minLimit, minCateringLimit, isCatering }: IMinimumProps): number =>
  isCatering ? minCateringLimit : minLimit;

const isOfferType = (type: CartEntryType) => /^offer/i.test(type);

const replaceEntryArrayItem = (array: ICartEntry[], newEntry: ICartEntry) => {
  const index = array.findIndex(entry => String(entry.cartId) === String(newEntry.cartId));
  return index !== -1
    ? [...array.slice(0, index), newEntry, ...array.slice(index + 1)]
    : [...array, newEntry];
};

// This function traverses cartEntries and when finds an item in any level, it replaces
// the item with newEntry
const replaceEntry = (cartEntries: ICartEntry[], from: string, newEntry: ICartEntry) => {
  const iterator = (entry: ICartEntry, index: number, array: ICartEntry[]) => {
    if (entry._id === from) {
      array[index] = newEntry;
      return true;
    }
    return entry.children.length && entry.children.some(iterator);
  };

  cartEntries.some(iterator);

  return [...cartEntries];
};

// Util function to find item by id in cartEntry with children
const deepFindCartEntry = (cartEntry: ICartEntry, id: string) => {
  let result;

  if (cartEntry._id === id) {
    return cartEntry;
  }

  const found = cartEntry.children.some(child => (result = deepFindCartEntry(child, id)));

  return found && result;
};

// This function traverses the cartEntries keeping reference to the entry in the root level
// while searching for an entry in children
const findEntriesByCmsId = (cartEntries: ICartEntry[], id: string) => {
  return cartEntries.reduce(
    (acc: { rootEntry: ICartEntry | null; childEntry: ICartEntry | null }, entry) => {
      const foundChild = deepFindCartEntry(entry, id);
      if (foundChild && !acc.rootEntry) {
        acc = { rootEntry: entry, childEntry: foundChild };
      }
      return acc;
    },
    { rootEntry: null, childEntry: null }
  );
};

const validateCartEntries = (
  cartEntries: ICartEntry[],
  appliedOffers: IAppliedOffer[],
  incentivesIds: Set<string>,
  validateCallback: (entry: ICartEntry) => void
) => {
  const appliedOffersByCartId = keyBy(appliedOffers, 'cartId');
  cartEntries.forEach(entry => {
    const { _id, type, cartId } = entry;
    const isOffer = incentivesIds.has(_id);

    if (!isOffer) {
      return;
    }

    if (
      (type !== CartEntryType.offerCombo && type !== CartEntryType.offerItem) ||
      !appliedOffersByCartId[cartId]
    ) {
      validateCallback(entry);
    }
  });
};

const getDateRoundUpToNearestFiveMinutes = (date = new Date()) => {
  const ms = 1000 * 60 * 5; // convert 5 minutes to ms
  const roundedDate = new Date(Math.ceil(date.getTime() / ms) * ms);
  return roundedDate;
};

export {
  determineLimit,
  determineMinimum,
  isOfferType,
  findEntriesByCmsId,
  replaceEntryArrayItem,
  replaceEntry,
  validateCartEntries,
  getDateRoundUpToNearestFiveMinutes,
};
