import React from 'react';
import { useIntl } from 'react-intl';

import { HStack, Pressable, VStack } from '@fhs-legacy/universal-components';
import RadioIndicator from 'components/radio-indicator';

import { DescriptionText, HeaderText } from './styled';
import { IPickupServiceModeOptionProps } from './types';

export const PickupServiceModeOption: React.FC<
  React.PropsWithChildren<IPickupServiceModeOptionProps>
> = ({ serviceMode, header, unavailable, description, hidden = false, isSelected, onSelect }) => {
  const { formatMessage } = useIntl();

  if (hidden) {
    return null;
  }

  const headerText = unavailable ? `${header} (${formatMessage({ id: 'unavailable' })})` : header;

  return (
    <Pressable
      isDisabled={unavailable}
      testID={`service-mode-${serviceMode}`}
      onPress={onSelect}
      paddingX="$6"
      accessibilityLabel={`cart-service-mode-selection-${headerText}`}
      accessibilityRole="radio"
      accessibilityValue={{ text: isSelected ? 'selected' : '' }}
      accessibilityHint={String(description)}
    >
      <HStack>
        <RadioIndicator
          isDisabled={unavailable}
          isSelected={isSelected}
          alignSelf="flex-start"
          marginTop={1}
        />
        <VStack padding="$1" marginLeft="$2" flexShrink={1}>
          <HeaderText unavailable={unavailable} variant="headerThree" margin={0}>
            {headerText}
          </HeaderText>
          <DescriptionText unavailable={unavailable}>{description}</DescriptionText>
        </VStack>
      </HStack>
    </Pressable>
  );
};
