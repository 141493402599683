import { Box } from '@fhs-legacy/universal-components';

export const MaxWidth = Box.withConfig({
  maxWidth: '962px',
  width: '100%',
  marginX: 'auto',
});

export const TopSelectionContainer = MaxWidth.withConfig({
  zIndex: 1,
  paddingY: '$2',
  paddingTop: {
    base: '$4',
  },
});

export const MaxWidthFullHeight = MaxWidth.withConfig({
  flex: 1,
  display: 'flex',
});

export const SearchContainer = Box.withConfig({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});
