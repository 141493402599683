import { useRef } from 'react';

import useEffectOnUnmount from './use-effect-on-unmount';

const useIsComponentMounted = () => {
  const mounted = useRef(true);
  useEffectOnUnmount(() => {
    mounted.current = false;
  });

  return mounted;
};

export default useIsComponentMounted;
