/* eslint-disable filename-rules/match */
import type { ComponentProps, ComponentType, ElementRef } from 'react';
import { forwardRef } from 'react';

import { StyledProps, usePropsResolution, useStyledSystemPropsResolver } from '@fhs-legacy/native-base';
import { PlatformProps } from '@fhs-legacy/native-base/src/components/types';

import type { ForwardRefReturnType } from '../react';

export type NBStyledComponentProps<
  C extends ComponentType,
  ExtraProps = StyledProps & PlatformProps<ComponentProps<C> & StyledProps>,
> = Omit<ComponentProps<C>, keyof ExtraProps> & ExtraProps;

/**
 * Makes a NativeBase compatible styled component from any component
 * that accepts a `styled` prop.
 *
 * ```tsx
 * const CustomView = makeNBComponent(View);
 *
 * <CustomView p="6"/>
 * ```
 */
export const makeNBComponent = <C extends ComponentType<any>>(
  Comp: C,
  themeName = 'fake_component',
): ForwardRefReturnType<ElementRef<C>, NBStyledComponentProps<C>> => {
  return forwardRef<ElementRef<C>, NBStyledComponentProps<C>>(
    (props: NBStyledComponentProps<C>, ref: any) => {
      const resolvedProps = usePropsResolution(themeName as any, props);
      const [style, restProps] = useStyledSystemPropsResolver(resolvedProps);

      return <Comp {...restProps} style={style} ref={ref} />;
    },
  );
};
