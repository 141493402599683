import { Svg, SvgPath, type SvgProps, SvgRect, registerSVG } from '../../../src/components/svg';

export function Visa(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 25 25">
      <SvgRect x="0.541016" y="4.33984" width="24" height="16" rx="2" fill="white" />
      <SvgRect
        x="1.04102"
        y="4.83984"
        width="23"
        height="15"
        rx="1.5"
        stroke="black"
        strokeOpacity="0.3"
        fill="white"
      />
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.04103 14.8048H7.82946L6.92094 11.3138C6.87781 11.1532 6.78625 11.0113 6.65157 10.9444C6.31545 10.7762 5.94507 10.6424 5.54102 10.5749V10.4405H7.49275C7.76212 10.4405 7.96415 10.6424 7.99782 10.8769L8.46921 13.395L9.68019 10.4405H10.8581L9.04103 14.8048ZM11.5314 14.8047H10.3872L11.3294 10.4404H12.4736L11.5314 14.8047ZM13.9534 11.6495C13.987 11.4145 14.1891 11.2801 14.4248 11.2801C14.7951 11.2463 15.1986 11.3138 15.5353 11.4814L15.7373 10.5417C15.4006 10.4073 15.0302 10.3398 14.6941 10.3398C13.5836 10.3398 12.7755 10.9444 12.7755 11.7834C12.7755 12.4216 13.3479 12.7568 13.7519 12.9587C14.1891 13.16 14.3574 13.2944 14.3237 13.4957C14.3237 13.7977 13.987 13.9321 13.6509 13.9321C13.2469 13.9321 12.8428 13.8314 12.473 13.6632L12.271 14.6035C12.675 14.7711 13.1122 14.8385 13.5162 14.8385C14.7615 14.8717 15.5353 14.2678 15.5353 13.3613C15.5353 12.2197 13.9534 12.1528 13.9534 11.6495ZM19.5411 14.8047L18.6326 10.4404H17.6567C17.4547 10.4404 17.2526 10.5748 17.1853 10.7762L15.5029 14.8047H16.6808L16.9159 14.167H18.3632L18.4979 14.8047H19.5411ZM17.8239 11.6157L18.16 13.2605H17.2178L17.8239 11.6157Z"
        fill="#1434CB"
      />
    </Svg>
  );
}

registerSVG('Visa', Visa);
