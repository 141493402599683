import { View } from '@fhs-legacy/universal-components';
import BlockRenderer from 'components/sanity-block-renderer';
import { theme } from 'styles/configure-theme';

import { LoyaltyLoader } from '../loyalty-loader';

export const StyledBlockRenderer = BlockRenderer.withConfig({
  margin: 0,
  fontSize: 'xs',
});

export const LoyaltyIncentivesContainer = View.withConfig({
  backgroundColor: theme.token('background-hero-light'),
  width: 'full',
  flex: 1,
  flexDirection: 'column',
});

export const StyledLoyaltyLoader = LoyaltyLoader;
