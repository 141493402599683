import { QueryHookOptions, useQuery } from '@apollo/client';
import { isNil } from 'lodash';
import { useEffect, useMemo } from 'react';

import { IServerOrder } from '@rbi-ctg/menu';
import { DeliveryStatus, IGetOrderQueryVariables } from 'generated/rbi-graphql';
import useEffectOnUnmount from 'hooks/use-effect-on-unmount';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { usePrevious } from 'hooks/use-previous';
import { GetOrderQuery, IGetOrderQueryResponse } from 'remote/queries/order';
import { OrderStatus } from 'state/order';
import { orderPollFailure, orderPollSuccessful } from 'state/order/order-state-utils';
import noop from 'utils/noop';

import { handleOrderStatusChange } from './on-order-status-change';
import { IUseOrderStatusParams } from './types';

// default order status polling interval, in ms
const DEFAULT_POLL_INTERVAL = 1500;

export const useOrderStatus = ({
  failureStatuses,
  onSuccess = noop,
  orderStatusPollInterval = DEFAULT_POLL_INTERVAL,
  rbiOrderId,
  successStatuses,
  // NOTE: currently this `skip` prop is being used as a means to "skip polling"
  // we need the query to run at least once in order to have `serverOrder`
  // defined for the remaining change status logic to work.
  // this value is only being used to evaluate whether we should start/stop polling
  // TODO: refactor all of this logic so that we don't need multiple instances of this hook
  skip,
}: IUseOrderStatusParams) => {
  const rbiOrderIdIsNil = isNil(rbiOrderId);
  const queryParams: QueryHookOptions<IGetOrderQueryResponse, IGetOrderQueryVariables> = {
    skip: rbiOrderIdIsNil,
  };

  if (rbiOrderId) {
    queryParams.variables = {
      rbiOrderId,
    };
  }

  const queryResult = useQuery<IGetOrderQueryResponse, IGetOrderQueryVariables>(
    GetOrderQuery,
    queryParams
  );

  const { data, startPolling, stopPolling } = queryResult;

  const serverOrder = useMemo<IServerOrder | null>(
    () => (data && data.order ? data.order : null),
    [data]
  );

  const orderStatus = serverOrder && serverOrder.status;
  const orderDeliveryStatus: DeliveryStatus | null =
    serverOrder && serverOrder.delivery && serverOrder.delivery.status;

  const prevOrderStatus = usePrevious<OrderStatus | null>(orderStatus);
  const prevOrderDeliveryStatus = usePrevious<DeliveryStatus | null>(orderDeliveryStatus);

  const failure =
    !!serverOrder &&
    serverOrder.rbiOrderId === rbiOrderId &&
    orderPollFailure({
      deliveryFailureStatus: failureStatuses.delivery,
      order: serverOrder,
      orderFailureStatus: failureStatuses.pos,
    });

  const success =
    !!serverOrder &&
    serverOrder.rbiOrderId === rbiOrderId &&
    orderPollSuccessful({
      deliverySuccessStatus: successStatuses.delivery,
      order: serverOrder,
      orderSuccessStatus: successStatuses.pos,
    });

  // use pollInterval value to control whether we start/stop polling
  const pollInterval = skip || rbiOrderIdIsNil || success || failure ? 0 : orderStatusPollInterval;
  // stop polling if it's enabled and shouldn't be
  useEffect(() => {
    if (pollInterval > 0) {
      startPolling(pollInterval);
    } else {
      stopPolling();
    }
  }, [pollInterval, startPolling, stopPolling]);

  useEffectOnUpdates(() => {
    if (!serverOrder) {
      return;
    }

    handleOrderStatusChange({
      failure,
      onSuccess,
      orderStatus,
      orderDeliveryStatus,
      prevOrderStatus,
      prevOrderDeliveryStatus,
      serverOrder,
      success,
    });
  }, [
    failure,
    orderStatus,
    orderDeliveryStatus,
    prevOrderStatus,
    prevOrderDeliveryStatus,
    serverOrder,
    success,
  ]);

  useEffectOnUnmount(() => {
    stopPolling();
  });

  return {
    ...queryResult,
    failure,
    orderStatus,
    orderDeliveryStatus,
    prevOrderStatus,
    prevOrderDeliveryStatus,
    serverOrder,
    success,
  };
};
