import { useEffect, useReducer, useRef } from 'react';

import { selectors, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { DIFFERENCE_TIME_UNITS, getDifferenceToNow } from 'utils/dateTime';

// Returns the number of seconds until offers redemption is available again.
export function useLoyaltyOffersCooldownCountdown(checkInterval: number = 10 * 1000): number {
  const isFlagEnabled = Boolean(useFlag(LaunchDarklyFlag.ENABLE_GLOBAL_OFFERS_COOLDOWN));
  const timeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);
  const [, triggerRender] = useReducer((count: number) => count + 1, 0);

  const isAvailableAfterTimestamp = useAppSelector(
    selectors.loyalty.selectOfferRedemptionAvailableAfter
  );

  const differenceInSeconds = !isAvailableAfterTimestamp
    ? -1
    : getDifferenceToNow(DIFFERENCE_TIME_UNITS.SECONDS, isAvailableAfterTimestamp);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    if (isFlagEnabled && differenceInSeconds > 0) {
      // Will become available in the future
      timeoutRef.current = setTimeout(() => triggerRender(), checkInterval);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  });

  if (!isAvailableAfterTimestamp || !isFlagEnabled) {
    return -1; // return negative seconds so cooldown is not enabled
  }

  return differenceInSeconds;
}
