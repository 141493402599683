import { computeCompositeModifierPlu, computeSimpleModifierPlu } from 'utils/vendor-config';

import { IComputeItemOptionModifierPlu } from './types';

export const computePluForItemOptionModifier = ({
  item,
  modifier,
  vendor,
  prices,
}: IComputeItemOptionModifierPlu) => {
  const complexPlu = computeCompositeModifierPlu({ item, modifier, vendor });

  if (!!complexPlu && typeof prices?.[complexPlu] === 'number') {
    return complexPlu;
  }

  const simplePlu = computeSimpleModifierPlu({ modifier, vendor });

  if (!!simplePlu && typeof prices?.[simplePlu] === 'number') {
    return simplePlu;
  }

  return null;
};

export function transformPlus<Plus extends Record<string, any> | {}>(plus: Plus) {
  return Object.entries(plus).reduce<Plus>((mappedPlus, [key, value]) => {
    mappedPlus[key.replace('plu_', '')] = value;
    return mappedPlus;
  }, {} as Plus);
}
