import { useEffect, useMemo, useState } from 'react';

import {
  IFeatureOffersQuery,
  IImageFragment,
  IOffersPageMarketingTileFragment,
  useFeatureOffersQuery,
} from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';
import {
  maybeLinkAction,
  maybeLocale,
  maybeNumber,
  maybeOffersPageMarketingTiles,
} from 'utils/graphql';

export interface IOffersSelectRestaurantCta {
  body: string | null;
  title: string | null;
  link: string | null;
  ctaButtonText: string | null;
  minimumNumberOfOffers: number | null;
}

export interface IFeatureOffersBrowsingPanel {
  endOfOffersMessage: string | null;
  endOfOffersImage: IImageFragment | null;
  emptyStateTitle: string | null;
  emptyStateBody: string | null;
  emptyStateImage: IImageFragment | null;
  offersSelectRestaurantCta: IOffersSelectRestaurantCta;
}

export interface ILinkAction {
  actionText: string | null;
  route: string | null;
}

export interface IFeatureOffersDetailsPanel {
  emptyStateAction: ILinkAction;
  emptyStateTitle: string | null;
  emptyStateBody: string | null;
  emptyStateImage: IImageFragment | null;
  missingOfferAction: ILinkAction;
  missingOfferTitle: string | null;
  missingOfferBody: string | null;
  missingOfferImage: IImageFragment | null;
}

export interface IFeatureOffers {
  offersBrowsingPanel: IFeatureOffersBrowsingPanel | null;
  offersDetailsPanel: IFeatureOffersDetailsPanel | null;
  defaultBackgroundImage: IImageFragment | null;
  offersPageMarketingTiles: IOffersPageMarketingTileFragment[];
}

const processFeatureOffers = (data: IFeatureOffersQuery | undefined): IFeatureOffers | null => {
  const FeatureOffers = data?.FeatureOffers;

  if (!FeatureOffers) {
    return null;
  }

  const {
    defaultBackgroundImage,
    offersBrowsingPanel,
    offersDetailsPanel,
    offersPageMarketingTiles,
  } = FeatureOffers;

  const { offersSelectRestaurantCta: selectRestaurantCtaData } = offersBrowsingPanel || {};

  return {
    offersBrowsingPanel: offersBrowsingPanel
      ? {
          offersSelectRestaurantCta: {
            title: maybeLocale<string>(selectRestaurantCtaData?.title),
            body: maybeLocale<string>(selectRestaurantCtaData?.body),
            link: maybeLocale<string>(selectRestaurantCtaData?.link),
            minimumNumberOfOffers: maybeNumber(selectRestaurantCtaData?.minimumNumberOfOffers),
            ctaButtonText: maybeLocale<string>(selectRestaurantCtaData?.ctaButtonText),
          },
          endOfOffersMessage: maybeLocale<string>(offersBrowsingPanel?.endOfOffersMessage),
          endOfOffersImage: maybeLocale<IImageFragment>(offersBrowsingPanel?.endOfOffersImage),
          emptyStateTitle: maybeLocale<string>(offersBrowsingPanel?.emptyStateTitle),
          emptyStateBody: maybeLocale<string>(offersBrowsingPanel?.emptyStateBody),
          emptyStateImage: maybeLocale<IImageFragment>(offersBrowsingPanel?.emptyStateImage),
        }
      : null,
    offersDetailsPanel: offersDetailsPanel
      ? {
          emptyStateAction: maybeLinkAction(offersDetailsPanel?.emptyStateAction),
          emptyStateTitle: maybeLocale<string>(offersDetailsPanel?.emptyStateTitle),
          emptyStateBody: maybeLocale<string>(offersDetailsPanel?.emptyStateBody),
          emptyStateImage: maybeLocale<IImageFragment>(offersDetailsPanel?.emptyStateImage),
          missingOfferAction: maybeLinkAction(offersDetailsPanel?.missingOfferAction),
          missingOfferTitle: maybeLocale<string>(offersDetailsPanel?.missingOfferTitle),
          missingOfferBody: maybeLocale<string>(offersDetailsPanel?.missingOfferBody),
          missingOfferImage: maybeLocale<IImageFragment>(offersDetailsPanel?.missingOfferImage),
        }
      : null,
    defaultBackgroundImage: defaultBackgroundImage ?? null,
    offersPageMarketingTiles: maybeOffersPageMarketingTiles(offersPageMarketingTiles) ?? null,
  };
};

type IUseFeatureOffersValue = {
  featureOffersLoading: boolean;
  featureOffers: IFeatureOffers | null;
};

export const useFeatureOffers = (): IUseFeatureOffersValue => {
  const { featureOffersId, featureIdsLoading } = useFeaturesContext();
  // wait to fetch featureOffers until featureOffersId exists
  const [featureOffersIdVar, setFeatureOffersIdVar] = useState<string>('');

  useEffect(() => {
    // only setState after useFeaturesContext is complete
    if (!featureIdsLoading && featureOffersId) {
      if (featureOffersIdVar !== featureOffersId) {
        setFeatureOffersIdVar(featureOffersId);
      }
    }
  }, [featureIdsLoading, featureOffersId, featureOffersIdVar]);

  const { data, loading } = useFeatureOffersQuery({
    variables: { featureOffersId: featureOffersIdVar },
    skip: !featureOffersId || !featureOffersIdVar || featureIdsLoading,
  });

  const featureOffers = useMemo(() => processFeatureOffers(data), [data]);
  const featureOffersLoading = featureIdsLoading || loading;
  return {
    featureOffersLoading,
    featureOffers,
  };
};
