import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconChevronUp = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path fill="currentColor" d="M16.59 16.1 12 11.52 7.41 16.1 6 14.69l6-6 6 6-1.41 1.41Z" />
    </Svg>
  );
};
