import delv from 'dlv';
import Head from 'expo-router/head';
import React from 'react';
import { Platform } from 'react-native';

import { ISanityImage } from '@rbi-ctg/menu';
import { fullBrandName } from 'utils/environment';
import { routes } from 'utils/routing';

interface IStoreMeta {
  phoneNumber: string;
  city: string;
  state: string;
  postalCode: string;
  streetAddress: string;
  atCopy: string;
  image?: ISanityImage;
  storeSlug: string;
}

interface ILdJsonRestaurant {
  '@context': 'http://schema.org';
  '@type': 'Restaurant';
  address: {
    '@type': 'PostalAddress';
    addressLocality: string;
    addressRegion: string;
    postalCode: string;
    streetAddress: string;
  };
  name: string;
  telephone: string;
  url: string;
  image?: string;
}

const StoreMeta: React.FC<IStoreMeta> = ({
  phoneNumber,
  city,
  state,
  postalCode,
  streetAddress,
  atCopy,
  image,
  storeSlug,
}) => {
  if (Platform.OS !== 'web') {
    return null;
  }

  const ldJsonRestauranData: ILdJsonRestaurant = {
    '@context': 'http://schema.org',
    '@type': 'Restaurant',
    address: {
      '@type': 'PostalAddress',
      addressLocality: city,
      addressRegion: state,
      postalCode,
      streetAddress,
    },
    name: `${fullBrandName()} ${atCopy} ${streetAddress}`,
    telephone: phoneNumber,
    url: window.location.href,
  };

  if (image) {
    ldJsonRestauranData.image = delv(image, 'asset.url');
  }

  const canonicalUrl = `https://${location.hostname}${routes.store}/${storeSlug}`;

  return (
    <Head>
      <title>{streetAddress}</title>
      <link rel="canonical" href={canonicalUrl} />
      <script type="application/ld+json">{JSON.stringify(ldJsonRestauranData)}</script>
    </Head>
  );
};

export default StoreMeta;
