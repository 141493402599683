import { type ReactNode } from 'react';

import { YStack, type YStackProps } from '../stack/y-stack';

import { type ActionHandlerFn } from './types';

export function AccessibleActionBlock(props: YStackProps) {
  return <YStack {...props} />;
}

export const useRegisterAccessibleAction = (_label: string, handler: ActionHandlerFn) => {
  return handler;
};

export const AccessibleAction = ({
  label: _label,
  handler,
  render,
}: {
  label: string;
  handler: ActionHandlerFn;
  render: (handler: ActionHandlerFn) => ReactNode;
}) => {
  return render(handler);
};
