import { makeUclComponent } from '@fhs-legacy/universal-components';
import Link from 'components/link';

export const PrepaidTransferBalanceLink = makeUclComponent(Link).withConfig({
  width: '100%',
  padding: '$3',
  fontWeight: 'bold',
  textAlign: 'center',
  isUnderlined: false,
  textTransform: 'capitalize',
  color: '__legacyToken.text-link-default',

  _hover: {
    color: '__legacyToken.text-link-hover',
  },
});
