import React from 'react';

import { LoyaltyLogoContainer, StyledLogo } from './loyalty-logo-widget.styled';

const LoyaltyLogoWidget = () => {
  return (
    <LoyaltyLogoContainer testID="loyalty-logo-widget">
      <StyledLogo />
    </LoyaltyLogoContainer>
  );
};

export default LoyaltyLogoWidget;
