import { ActivityIndicator, Pressable, StyleSheet, View } from 'react-native';

import {
  IconPaymentTypeAddNewPayment,
  IconPaymentTypeAmex,
  IconPaymentTypeGooglePay,
  IconPaymentTypeMastercard,
  IconPaymentTypeVisa,
  Text,
  tokens,
} from '@fhs/ui';
import { PaymentMethodType } from 'generated/graphql-gateway';

import { usePaymentAdapter } from '../../state/payments';

const iconMap = {
  GOOGLE: <IconPaymentTypeGooglePay size={24} />,
  VISA: <IconPaymentTypeVisa size={24} />,
  MASTERCARD: <IconPaymentTypeMastercard size={24} />,
  AMEX: <IconPaymentTypeAmex size={24} />,
};

type Props = {
  onClose(): void;
};

export function PaymentMethodSelection(props: Props) {
  const { isPending, availablePaymentMethods, selectPaymentMethod } = usePaymentAdapter();

  if (isPending) {
    return <ActivityIndicator />;
  }

  return (
    <>
      {availablePaymentMethods.map(method => {
        switch (method.type) {
          case PaymentMethodType.GOOGLE_PAY:
            return (
              <PaymentEntry
                onPress={() => {
                  selectPaymentMethod(method.id);
                  props.onClose();
                }}
                key="google pay"
                icon={iconMap.GOOGLE}
                text="Google Pay"
              />
            );
          case PaymentMethodType.SCHEME:
            if ('brand' in method) {
              return (
                <PaymentEntry
                  onPress={() => {
                    selectPaymentMethod(method.id);
                    props.onClose();
                  }}
                  key={method.id}
                  icon={iconMap.VISA}
                  text={`${method.brand} • • • • ${method.last4}`}
                />
              );
            }

            return null;
          default:
            return null;
        }
      })}
      <Pressable style={styles.row}>
        <View style={styles.innerRow}>
          <View style={styles.iconCircle}>
            <IconPaymentTypeAddNewPayment size={24} />
          </View>
          <Text.Ui weight="semibold" size="lg">
            Add New Credit Card
          </Text.Ui>
        </View>
      </Pressable>
    </>
  );
}

function PaymentEntry(props: { icon: React.ReactNode; text: string; onPress: () => void }) {
  return (
    <Pressable style={styles.row} onPress={props.onPress}>
      <View style={styles.innerRow}>
        <View style={styles.iconCircle}>{props.icon}</View>
        <Text.Ui weight="semibold" size="lg">
          {props.text}
        </Text.Ui>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  row: {
    borderBottomColor: tokens.colors.$black10,
    borderBottomWidth: 1,
    width: '100%',
    height: 64,
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  innerRow: {
    width: 390,
    marginHorizontal: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
  },

  iconCircle: {
    height: 40,
    width: 40,
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: tokens.colors.$houseLight,
    marginRight: 12,
  },
});
