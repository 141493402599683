import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconMobile = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M17 1.11 7 1.1c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-18c0-1.1-.9-1.99-2-1.99Zm0 17.99H7v-14h10v14Z"
      />
    </Svg>
  );
};
