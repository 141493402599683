import delv from 'dlv';

import {
  IBackendCartEntries,
  ICartEntry,
  IComboSlot,
  IComboSlotOption,
  IComboSlotSelectionsWithData,
  IItem,
  IItemOption,
  IItemOptionModifier,
  IModifierSelection,
  IOffer,
  ISanityItemOption,
  IWithPricingProps,
} from '@rbi-ctg/menu';
import { GetOfferPriceFunction } from 'components/menu-order-wizard/wizard-ui-helpers';

import {
  IWithVendorConfig,
  PluTypes,
  getVendorConfig,
  reduceMultiConstantPluQuantity,
} from '../vendor-config';

import { computeSelectedOption } from './compute-selected-option';
import { isDefaultOrLess } from './modifiers';
import { computePluForItemOptionModifier } from './plu';
import brandedPriceForCartEntry from './price-for-cart-entry';

type IComboSlotSelectionsNoData = Exclude<IComboSlotSelectionsWithData, 'data'>;

export interface IComboSlotSelectionsNoDataInSelections {
  [comboSlotId: string]: IComboSlotSelectionsNoData;
}

type PricingFunction = (
  item: IWithVendorConfig,
  quantity?: number,
  modifierSelections?: IModifierSelection[],
  comboSlotSelections?: IComboSlotSelectionsNoDataInSelections
) => number;
export interface IComputePriceOptions {
  comboSlotSelections: IComboSlotSelectionsNoDataInSelections;
  item: IWithVendorConfig;
  modifierSelections: IModifierSelection[];
  pickerSelections: {
    [identifier: string]: string;
  };
  pricingFunction: PricingFunction;
  quantity: number;
  isOffer: boolean;
  selectedOffer: IOffer | null;
  getOfferPrice: GetOfferPriceFunction;
}

type Totals = [number, number];

function computeSelectionPrice({
  item,
  modifierSelections,
  pricingFunction,
  quantity,
  offerTotal,
  comboSlotSelections = {},
}: {
  item: IWithVendorConfig;
  modifierSelections: IModifierSelection[];
  pricingFunction: PricingFunction;
  quantity: number;
  offerTotal?: number;
  comboSlotSelections?: IComboSlotSelectionsNoDataInSelections;
}): Totals {
  // price of all the items no mods
  // baseTotal is the item/combo price with no mods including the quantity `comboPrice * quantity`
  const baseTotal = pricingFunction(item, quantity);

  // price of just the mods/upsells for all items
  // extraTotal is any mods or upsells not including the item/combo cost `(mods + upsells) * quantity`
  const extraTotal =
    pricingFunction(item, quantity, modifierSelections, comboSlotSelections) - baseTotal;

  // If its an offer override the base cost
  // offerTotal should include the quantity so it matches with baseTotal `offerPrice * quantity`
  const finalBaseTotal = offerTotal ?? baseTotal;
  return [finalBaseTotal, extraTotal];
}

// NOTE #1: When using this function you will want to add all of the return numbers together to get a grand total for the menu item
export function computeCurrentPrice({
  comboSlotSelections = {},
  item,
  modifierSelections = [],
  pickerSelections,
  pricingFunction,
  quantity = 1,
  isOffer = false,
  selectedOffer,
  getOfferPrice,
}: IComputePriceOptions): Totals {
  let offerTotal;
  if (isOffer) {
    const offerMarketPrice = pricingFunction(
      {
        vendorConfigs: delv(selectedOffer ?? {}, 'marketPrice.vendorConfigs', {}),
      },
      quantity
    );
    const offerRestaurantPosPrice = pricingFunction(
      {
        vendorConfigs: delv(selectedOffer ?? {}, 'vendorConfigs', {}),
      },
      quantity
    );
    // offerTotal should include the quantity so it matches with baseTotal `offerPrice * quantity`
    offerTotal = getOfferPrice({
      marketPrice: offerMarketPrice,
      offerPrice: selectedOffer && selectedOffer.offerPrice,
      restaurantPosPrice: offerRestaurantPosPrice,
    });
  }

  if (item._type === 'item') {
    // See Note #1 above
    return computeSelectionPrice({
      item,
      modifierSelections,
      pricingFunction,
      quantity,
      offerTotal,
    });
  }

  const selection = computeSelectedOption(
    pickerSelections,
    // @ts-ignore FIXME Type 'IWithVendorConfig' is missing the following properties from type 'IItem': options, _id, image, nutrition, and 2 more.ts(2345)
    item
  );

  if (selection) {
    // See Note #1 above
    return computeSelectionPrice({
      item: selection,
      modifierSelections,
      pricingFunction,
      quantity,
      comboSlotSelections,
      offerTotal,
    });
  }

  return [0, 0];
}

interface IPriceItemOptionModifierPlu extends IWithPricingProps {
  item: IBackendCartEntries | ICartEntry | IItem;
  modifier: IBackendCartEntries | ICartEntry | (IItemOptionModifier & { quantity?: number });
}

interface IPriceItemOptionModifier extends IWithPricingProps {
  item: IItem | ICartEntry;
  itemOption: ISanityItemOption | IItemOption;
  modifier: IItemOptionModifier & { quantity?: number };
}

export function priceForItemOptionModifierPlu({
  item,
  modifier,
  prices,
  vendor,
}: IPriceItemOptionModifierPlu): number {
  const plu = computePluForItemOptionModifier({
    item,
    modifier,
    vendor,
    prices,
  });

  // if plu could not be computed, we cannot get
  //  a price for this modifier
  if (!plu) {
    return 0;
  }

  const price = prices && plu in prices ? prices[plu] : 0;

  const modifierVendorConfig = getVendorConfig(modifier, vendor);

  if (!modifierVendorConfig) {
    return 0;
  }

  // if modifier is a multi-constant plu, we need to use
  // the quantity indicated by the plu to determine price
  if (modifierVendorConfig.pluType === PluTypes.MULTI_CONSTANT) {
    const quantity = reduceMultiConstantPluQuantity(modifierVendorConfig.multiConstantPlus || []);

    return price * quantity;
  }

  return price * (modifier.quantity || 1);
}

export function priceForItemOptionModifierBooleanOrStepper({
  item,
  itemOption,
  modifier,
  prices,
  vendor,
}: IPriceItemOptionModifier): number {
  // we do not charge the user for modifiers that are
  // default or any modifier with a smaller or equal
  // multiplier to the default
  const defaultOrLess = isDefaultOrLess(itemOption, modifier);

  if (defaultOrLess && !itemOption.upsellModifier) {
    return 0;
  }

  return priceForItemOptionModifierPlu({
    item,
    modifier,
    prices,
    vendor,
  });
}

export function priceForItemOptionModifierSelection({
  item,
  itemOption,
  modifier,
  prices,
  vendor,
}: IPriceItemOptionModifier): number {
  if (itemOption.allowMultipleSelections) {
    // @todo implement pricing for allowMultipleSelections
    return 0;
  }

  if (modifier.default && !itemOption.upsellModifier) {
    return 0;
  }

  return priceForItemOptionModifierPlu({
    item,
    modifier,
    prices,
    vendor,
  });
}

export const isComboSlotSelectionPremium = (selectedOption: IComboSlotOption) => {
  return selectedOption.isPremium ?? false;
};

export const isOptionInComboSlot = (
  comboSlot: IComboSlot,
  comboSlotOption: IComboSlotOption | undefined
) => {
  if (!comboSlotOption?._key) {
    return false;
  }
  return !!comboSlot.options.find(slotOption => slotOption._key === comboSlotOption._key);
};

export const priceForCartEntry = brandedPriceForCartEntry;
