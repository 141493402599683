import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconMoreVertical = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M10 18.1c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2Zm0-12c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2Zm0 6c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2Z"
      />
    </Svg>
  );
};
