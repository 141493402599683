import React from 'react';

import { UclTheme } from '@fhs-legacy/universal-components';

import useProviders from '../../providers';

type Props = React.PropsWithChildren<{
  uclTheme: UclTheme;
  onErrorBoundaryRequestReload: () => void;
}>;

export default function ReduceProviders({
  children,
  uclTheme,
  onErrorBoundaryRequestReload,
}: Props) {
  const providers = useProviders({
    uclTheme,
    onErrorBoundaryRequestReload,
  });

  return (
    <>
      {providers.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>;
      }, children)}
    </>
  );
}
