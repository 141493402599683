import { StyleProp, View, ViewStyle } from 'react-native';

import { Skeleton, SkeletonTextBlock, createMqStyles, tokens } from '@fhs/ui';

export function OfferCardSkeleton({
  style,
  textBlockLines = 3,
}: {
  style?: StyleProp<ViewStyle>;
  textBlockLines?: number;
}) {
  const styles = useMqStyles();

  return (
    <View style={[styles.container, style]}>
      <Skeleton style={styles.imageContainer} />
      <View style={styles.contentContainer}>
        <SkeletonTextBlock lines={textBlockLines} style={styles.textContainer} />
        <Skeleton style={styles.skeletonButton} />
      </View>
    </View>
  );
}
const useMqStyles = createMqStyles({
  container: {
    $base: {
      width: 215,
      height: 297,
      borderColor: tokens.colors.$black10,
      borderWidth: 1,
      borderRadius: 8,
      alignItems: 'center',
      flex: 1,
    },
    $ltDesktop: {
      width: 160,
      height: 232,
    },
  },
  imageContainer: {
    $base: {
      height: 140,
      width: '100%',
      borderRadius: 0,
      borderTopStartRadius: 8,
      borderTopEndRadius: 8,
      backgroundColor: tokens.colors.$black10,
      borderBottomWidth: 1,
      borderBottomColor: tokens.colors.$black10,
    },
    $ltDesktop: {
      height: 96,
    },
  },
  contentContainer: {
    $base: {
      flex: 1,
      padding: 16,
      width: '100%',
      justifyContent: 'space-between',
    },
    $ltDesktop: {
      padding: 12,
    },
  },
  textContainer: {
    $base: {
      width: '100%',
      minHeight: 65,
    },
  },
  skeletonButton: {
    $base: {
      height: 34,
    },
    $ltDesktop: {
      height: 31,
    },
  },
});
