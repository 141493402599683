import { Path, Rect, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconPaymentTypeAmex = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Rect width={24} height={16} y={4.101} fill="#1F72CD" rx={2} />
      <Path
        fill="#fff"
        fillRule="evenodd"
        d="M5.031 8.97 3 14.18h2.432l.301-.83h.69l.301.83h2.677v-.633l.238.634h1.385l.239-.648v.648h5.567l.677-.81.634.81 2.859.007-2.038-2.598L21 8.97h-2.815l-.659.794-.614-.794h-6.056l-.52 1.345-.533-1.345H7.377v.613l-.27-.613H5.03Zm.47.74h1.186l1.348 3.533V9.71h1.298l1.041 2.533.96-2.533h1.291v3.74h-.786l-.006-2.93-1.147 2.93h-.703L8.83 10.52v2.93H7.213l-.307-.84H5.25l-.306.838h-.867L5.502 9.71Zm11.041 0h-3.197v3.737h3.148l1.014-1.238.978 1.238h1.022l-1.486-1.858 1.486-1.879h-.978l-1.01 1.224-.977-1.224Zm-10.463.633-.546 1.493h1.091l-.545-1.493Zm8.055.824v-.683h1.995l.87 1.091-.908 1.098h-1.957v-.745h1.744v-.761h-1.744Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
