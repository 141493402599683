import {
  Box,
  HStack,
  Header,
  Icon,
  ScrollView,
  addWithConfig,
} from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import { IncentiveEvaluationMessages } from 'components/incentive-evaluation-messages/incentive-evaluation-messages';
import { DisclaimerContainer } from 'components/offers/ui-refresh/offer-disclaimer';
import Picture from 'components/picture';
import { ProgressMeter } from 'components/progress-meter';
import SanityBlockRenderer from 'components/sanity-block-renderer';
import { theme as globalTheme } from 'styles/configure-theme';

import { HIGHLIGHT_COLOR } from '../constants';
import { RewardPointCost } from '../reward-point-cost';

import theme from './theme';

export const ImageWrapper = Box.withConfig({
  alignItems: 'center',
  flexShrink: 0,
});

export const StyledRewardImage = Picture.withConfig({
  width: '80%',
  marginTop: '$4',
  zIndex: Styles.zIndex.normal,
  height: '$48',
});

export const BackgroundImage = Picture.withConfig({
  position: 'absolute',
  left: 0,
  top: 0,
  width: 'full',
  height: '100%',
});

export const RewardDescription = SanityBlockRenderer.withConfig({
  color: theme.rewardDescriptionColor,
  textAlign: 'center',
  textProps: {
    textAlign: 'center',
    fontSize: { base: 'xs', desktop: '18px' },
  },
});

export const ButtonContainer = Box.withConfig({});

export const RewardDetailsContainer = Box.withConfig({
  alignSelf: 'center',
  alignItems: 'center',
  paddingX: { base: '$2', desktop: '$0' },
  width: { base: 'full', desktop: '600px' },
});

export const DisclaimerText = SanityBlockRenderer.withConfig({
  maxWidth: '512px',
  marginY: '$8',
  marginX: 'auto',
  textProps: {
    color: theme.disclaimerTextColor,
    textAlign: 'center',
    fontSize: 'xs',
  },
});

export const StyledRewardLock = Box.withConfig({});

export const LockContainer = Box.withConfig({
  right: '45px',
});

export const PointsContainer = Box.withConfig({
  alignItems: 'center',
  marginTop: '$4',
});

export const PointsTextContainer = HStack.withConfig({
  alignItems: 'center',
});

export const RewardLabel = Box.withConfig({
  backgroundColor: HIGHLIGHT_COLOR,
  paddingTop: '$1.5',
  paddingBottom: '$2',
  paddingX: '7px',
  borderRadius: '8px',
  color: Styles.color.white,
  marginTop: '$1',
  marginBottom: '0',
  marginX: '$1.5',
  fontSize: 'sm',
});

export const StyledRewardLockIcon = Icon.withConfig({
  variant: 'locked',
  size: 18,
  color: globalTheme.token('icon-default'),
  mr: '$1',
});

export const StyledIncentiveEvaluationMessages = IncentiveEvaluationMessages.withConfig({
  textAlign: 'center',
});

export const ButtonsWrapper = Box.withConfig<{ $errorMessage?: boolean }>(props => ({
  width: 'full',
  marginTop: props.$errorMessage ? '0' : '$2',
  paddingTop: '$1',
  paddingX: '$7',
  paddingBottom: '$4',
}));

export const StyledDisclaimerContainer = DisclaimerContainer.withConfig({
  marginTop: '$4',
  alignSelf: 'center',
});

export const Wrapper = ScrollView.withConfig({
  paddingTop: '$12',
  paddingX: '$5',
  marginBottom: { desktop: '$6' },
  paddingBottom: { desktop: '$16' },
});

export const RewardName = Header.withConfig({
  textAlign: 'center',
  fontSize: { base: 'md', desktop: '2xl' },
  marginTop: '$4',
  maxWidth: '960px',
});

export const StyledRewardPointCost = RewardPointCost.withConfig({
  fontSize: 'sm',
  lineHeight: 'md',
  textTransform: 'capitalize',
  fontFamily: 'heading',
  alignItems: 'center',
});

export const StyledPointsIcon = LoyaltyPointsIcon.withConfig({
  width: '15px',
  height: '15px',
  marginRight: '8px',
  fill: HIGHLIGHT_COLOR,
  marginBottom: '3px',
});

export const StyledProgressMeter = ProgressMeter.withConfig({
  borderWidth: 0,
  marginY: '$4',
  width: '240px',
  bgColor: 'blackOpacity.4',
  _progress: {
    background: HIGHLIGHT_COLOR,
  },
});

export const StyledActionButton = addWithConfig(ActionButton).withConfig({
  minWidth: '100%',
});
