import type { Link } from 'expo-router';
import type { ComponentProps } from 'react';
import type { ViewProps } from 'react-native';

type HrefType = ComponentProps<typeof Link>['href'];

export type OrderDisplayItem = {
  quantity: number;
  name: string;
  image: string;
};

export enum OrderDisplayType {
  ORDER_IN_PROGRESS = 'order-in-progress',
  REORDER = 'reorder',
}

export type OrderDisplayProps = Omit<ViewProps, 'children'> & {
  type: OrderDisplayType;
  storeName: string;
  items: Array<OrderDisplayItem>;
  href: HrefType;
  serviceModeTitle: string;
  reorderLoading?: boolean;
  reorderCallback: () => void;
  logReorderCardPress: () => void;
};
