import React from 'react';

import { Box } from '@fhs-legacy/universal-components';
import { INutritionInfo } from '@rbi-ctg/menu';

import { NutritionItem } from './nutrition-item';
import { NutritionItemGroup } from './nutrition-item-group';
import { TableHeader } from './table-header';
import { SanityNutritionTable } from './types';

type NutritionTableProps = {
  nutritionTable: SanityNutritionTable;
  nutritionInfo: INutritionInfo;
  roundNutritionValue: (val: number) => number;
};

export const NutritionTable: React.FC<React.PropsWithChildren<NutritionTableProps>> = ({
  nutritionInfo,
  nutritionTable,
  roundNutritionValue,
}) => {
  const title = nutritionTable.title?.locale;
  const items = nutritionTable.items ?? [];
  if (!title || !items.length) {
    return null;
  }

  return (
    <Box testID="nutrition-table">
      <TableHeader title={title} />
      {items.map((item, index) => {
        if (item?.__typename === 'NutritionItem') {
          return (
            <NutritionItem
              key={item.nutritionKey || index}
              nutritionInfo={nutritionInfo}
              nutritionItem={item}
              roundNutritionValue={roundNutritionValue}
            />
          );
        } else if (item?.__typename === 'NutritionItemGroup') {
          return (
            <NutritionItemGroup
              key={item._key || index}
              nutritionInfo={nutritionInfo}
              nutritionItemGroup={item}
              roundNutritionValue={roundNutritionValue}
            />
          );
        }
        return null;
      })}
    </Box>
  );
};
