import { useMemo } from 'react';

import { useAuthContext } from 'state/auth';
import { useOrderContext } from 'state/order';
import { isCartEntryInMenu } from 'utils/cart';

export const useRecentItemsAvailable = () => {
  const { user } = useAuthContext();
  const uniquePurchasedItems = user?.uniquePurchasedItems;
  const hasUniquePurchasedItems = useMemo(
    () =>
      Array.isArray(uniquePurchasedItems) &&
      uniquePurchasedItems.length > 0 &&
      uniquePurchasedItems.some(isCartEntryInMenu),
    [uniquePurchasedItems]
  );
  const { isCatering } = useOrderContext();
  return !isCatering && hasUniquePurchasedItems;
};
