import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, Icon, Text, VStack } from '@fhs-legacy/universal-components';
import PaymentMethodOption from 'components/payment-method-option';
import { RotateAnimatedView } from 'components/ucl';
import { IPaymentMethod } from 'state/payment/types';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { routes } from 'utils/routing';

import { AddNewCardLink } from './add-new-card-link';
import { PAYMENT_METHOD_OPTION_HEIGHT } from './constants';
import { HoverTheme } from './types';

export { HoverTheme } from './types';

interface IPaymentMethodsContainer {
  border?: string;
  hoverTheme: HoverTheme;
}
interface IDisplayProps {
  open: boolean;
  children?: ReactNode[];
  hasAddPaymentMethod?: boolean;
}

interface IPaymentMethods {
  selectedMethod: IPaymentMethod | undefined;
  nonSelectedMethods: IPaymentMethod[];
  onOptionClick: (arg0: string) => void;
  onNavigatePress?: (arg0: MouseEvent, arg1: string) => void;
  hoverTheme?: HoverTheme;
}

interface IPaymentMethodsContainer {
  hoverTheme: HoverTheme;
}

const DisplayToggle = Text.withConfig({
  position: 'absolute',
  right: '$3.5',
  top: '$4',
  zIndex: Styles.zIndex.normal,
});

export const PaymentMethodsContainer = Box.withConfig<IPaymentMethodsContainer>({
  position: 'relative',
  width: 'full',
  borderWidth: 1,
  borderColor: 'rgba(0, 0, 0, 0.1)',
  borderStyle: 'solid',
  borderRadius: 4,
  overflow: 'hidden',
});

const PaymentMethods = Box;

const HiddenPaymentMethods = VStack.withConfig<IDisplayProps>(props => {
  const heightScale = props.hasAddPaymentMethod
    ? React.Children.count(props.children)
    : React.Children.count(props.children) - 1;

  return {
    height: `${props.open ? heightScale * PAYMENT_METHOD_OPTION_HEIGHT : 0}px`,
    overflow: 'hidden',
  };
});

const PaymentMethodSelect: React.FC<React.PropsWithChildren<IPaymentMethods>> = ({
  selectedMethod,
  nonSelectedMethods,
  onOptionClick,
  onNavigatePress,
  hoverTheme = HoverTheme.DARK,
}) => {
  const { formatMessage } = useIntl();
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);

  const handleOptionClick = useCallback(
    (fdAccountId: string) => {
      onOptionClick(fdAccountId);
      // Delay closing payment methods UI
      setTimeout(() => setShowPaymentMethods(false), 200);
    },
    [onOptionClick]
  );

  const handleTogglePaymentMethods = useCallback(() => {
    if (nonSelectedMethods.length > 0 || onNavigatePress) {
      setShowPaymentMethods(currentShowPaymentMethods => !currentShowPaymentMethods);
    }
  }, [setShowPaymentMethods, nonSelectedMethods.length, onNavigatePress]);

  const handleNavigatePress = useCallback(
    (e: any) => {
      if (onNavigatePress) {
        onNavigatePress(e, routes.payment);
      }
    },
    [onNavigatePress]
  );

  useEffect(() => {
    setShowPaymentMethods(!selectedMethod);
  }, [selectedMethod]);

  // Show the arrow if the user has more that one payment method
  // or if the user has a selected payment method and the dropdown has a link to add a new CC
  const showArrow = !!nonSelectedMethods.length || !!(selectedMethod && onNavigatePress);

  return (
    <PaymentMethodsContainer testID="payment-methods-container" hoverTheme={hoverTheme}>
      {selectedMethod ? (
        <PaymentMethods>
          {/* TODO: RN - Check if we can reuse display-toggle */}
          {showArrow ? (
            <DisplayToggle
              testID="payment-options-display-toggle"
              onPress={handleTogglePaymentMethods}
              aria-expanded={showPaymentMethods}
              aria-controls="payment-methods"
            >
              <RotateAnimatedView isRotated={showPaymentMethods}>
                <Icon
                  title="Select"
                  variant="select"
                  width="24px"
                  {...hiddenAccessibilityPlatformProps}
                />
              </RotateAnimatedView>
            </DisplayToggle>
          ) : (
            <></>
          )}
          <PaymentMethodOption
            key={selectedMethod.fdAccountId}
            method={selectedMethod}
            selected
            onPress={handleTogglePaymentMethods}
            showDefault={false}
            disableHover
            hideBorder={!showPaymentMethods}
          />
        </PaymentMethods>
      ) : (
        <></>
      )}
      <HiddenPaymentMethods open={showPaymentMethods} hasAddPaymentMethod={!!onNavigatePress}>
        {nonSelectedMethods.map((method: IPaymentMethod) => {
          const accountIdentifier = method.accountIdentifier ?? method.fdAccountId ?? '';
          return (
            <PaymentMethodOption
              key={accountIdentifier}
              method={method}
              onPress={() => handleOptionClick(accountIdentifier)}
              showDefault={false}
              // @ts-expect-error TS(2322) FIXME: Type '{ key: string; method: IPaymentMethod; onPre... Remove this comment to see the full error message
              hoverTheme={hoverTheme}
              disableHover
            />
          );
        })}
        {onNavigatePress && (
          <AddNewCardLink hoverTheme={hoverTheme} onPress={handleNavigatePress}>
            {formatMessage({ id: 'addNewPaymentMethod' })}
          </AddNewCardLink>
        )}
      </HiddenPaymentMethods>
    </PaymentMethodsContainer>
  );
};

export default PaymentMethodSelect;
