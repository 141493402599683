import { useIntl } from 'react-intl';

import { PromotionType } from 'generated/graphql-gateway';
import { useCartContext } from 'state/cart';
import { selectors, useAppSelector } from 'state/global-state';
import { IncentiveEvaluationErrorCodes } from 'state/loyalty/hooks/types';
import { getEvaluationResultMessage } from 'state/loyalty/hooks/utils/helpers';
import { useOrderContext } from 'state/order';

import { IGetErrorMessagesProps } from './types';

export const useGetPaymentErrors = ({ cartPriceTooLow }: { cartPriceTooLow: boolean }) => {
  const { cartCateringPriceTooLow } = useCartContext();
  const { cartPriceLimitExceeded } = useOrderContext();
  const formatters = useIntl();
  const incentiveEvaluationErrors = useAppSelector(state =>
    selectors.loyalty.selectIncentiveErrorsWithCode(
      state,
      IncentiveEvaluationErrorCodes.INVALID_PAYMENT_METHOD
    )
  );

  // This method returns an array to handle other errors in the future
  // similar to `getErrorMessages`
  const getLoyaltyPaymentErrorMessages = () => {
    if (!incentiveEvaluationErrors.length) {
      return [];
    }

    return [
      {
        messageId: '',
        message: getEvaluationResultMessage({
          evaluationResult: incentiveEvaluationErrors[0],
          formatters,
          incentiveType: PromotionType.OFFER,
        }),
      },
    ];
  };

  const getErrorMessages = ({
    areDeliveryDetailsWithError,
    invalidAddressLine2,
    cartCateringPriceTooLow: serverOrderCartCateringPriceTooLow,
    cartPriceTooLow: serverOrderCartPriceTooLow,
    checkoutPaymentMethodNeedRevaultError,
    curbsideOrderError,
    invalidOfferError,
    invalidPaymentMethodError,
    invalidReloadError,
    invalidTipAmountError,
    noDirectPayAndNoPrepaidMethodError,
    pricedAndAvailableError,
    storeIsClosing,
    unableToReloadCardError,
    encryptionFail,
  }: IGetErrorMessagesProps) => {
    const paymentErrors = [
      { messageId: 'curbsideOrderError', hasError: curbsideOrderError },
      { messageId: 'invalidOfferError', hasError: invalidOfferError },
      { messageId: 'invalidPaymentMethodError', hasError: invalidPaymentMethodError },
      { messageId: 'invalidTipAmountError', hasError: invalidTipAmountError },
      { messageId: 'maximumPriceError', hasError: !!cartPriceLimitExceeded },
      {
        messageId: 'minimumPriceError',
        hasError: !!cartPriceTooLow || !!serverOrderCartPriceTooLow,
      },
      {
        messageId: 'minimumPriceForCateringError',
        hasError: !!cartCateringPriceTooLow || !!serverOrderCartCateringPriceTooLow,
      },
      { messageId: 'unableToReloadCardError', hasError: unableToReloadCardError },
      { messageId: 'pricedAndAvailableError', hasError: pricedAndAvailableError },
      { messageId: 'areDeliveryDetailsValid', hasError: areDeliveryDetailsWithError },
      { messageId: 'invalidAddressLine2', hasError: invalidAddressLine2 },
      {
        messageId: 'noDirectPayAndNoPrepaidMethodError',
        hasError: noDirectPayAndNoPrepaidMethodError,
      },
      { messageId: 'invalidReloadError', hasError: invalidReloadError },
      {
        messageId: 'checkoutPaymentMethodNeedRevaultError',
        hasError: checkoutPaymentMethodNeedRevaultError,
      },
      { messageId: 'storeIsClosingError', hasError: storeIsClosing },
      { messageId: 'paymentAddingError', hasError: encryptionFail },
    ];

    const paymentErrorMessageIds = paymentErrors.flatMap(item =>
      item.hasError ? [{ ...item }] : []
    );
    return paymentErrorMessageIds;
  };

  return {
    getErrorMessages,
    getLoyaltyPaymentErrorMessages,
  };
};
