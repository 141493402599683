{
  "addToCart": "Ajouter",
  "addToCartError": "Impossible d'ajouter à la commande. Svp essayer de nouveau.",
  "addToCartSuccess": "ajouté à la commande",
  "change": "Modifier",
  "chooseALocation": "Choisir un restaurant",
  "confirm": "Confirmer",
  "containsAllergens": "Contient : {allergens}",
  "containsAllergensDisclaimer": "Contient : {allergens}",
  "deliveryDetailsInstructions": "Instructions de livraison (optionnel)",
  "dineIn": "En restaurant",
  "driveThru": "Service au volant",
  "enjoyYourOrder": "Savourez votre commande. À bientôt!",
  "euNutritionValues": "{calories} / {joules}",
  "giftSent": "Votre cadeau est en route!",
  "giftSentError": "Malheureusement, nous n’avons pas pu envoyer votre cadeau {gift} à {email}. Veuillez réessayer plus tard ou communiquez avec le {supportLink}.",
  "giftSentSuccess": "Vous avez envoyé un cadeau {gift} à {email}.",
  "hoursAndInfo": "Heures et info",
  "included": "Inclus",
  "locations": "Restaurants",
  "mayContainAllergens": "Peut contenir : {allergens}",
  "mayContainAllergensViaSharedEquipment": "Peut contenir via un équipement partagé : {allergens}",
  "nearby": "À proximité",
  "nutritionalInformation": "Renseignements nutritionnels",
  "offerApplied": "Offre appliquée",
  "offers": "Nos offres",
  "orderNow": "Commander maintenant",
  "orderNumber": "Numéro de commande",
  "orderPayment": "Paiement de la commande",
  "orderTime": "Heure de la commande",
  "paymentDetails": "Détails liés au paiement",
  "pickUp": "À emporter",
  "pickupModeContentHeading": "Commander maintenant",
  "pickUpTime": "Heure de cueillette",
  "recent": "Recents",
  "remove": "Supprimer",
  "required": "Requis",
  "rewardsPointsBalanceIsNow": "Votre solde de points FidéliTim est de {remainingPoints}",
  "selectGiftError": "Veuillez sélectionner un cadeau.",
  "serviceModeDetailsDineInHeader": "En restaurant",
  "serviceModeDetailsDriveThruDescription": "Avancez votre voiture et laissez-nous savoir que vous avez passé une commande en ligne.",
  "serviceModeDetailsDriveThruHeader": "Service au volant",
  "serviceModeDetailsPickupHeader": "À emporter",
  "shareMyLocation": "Partager ma position",
  "storeConfirmationDriveThru": "Service au volant à",
  "storeConfirmationEatIn": "En restaurant à",
  "storeLocatorEmptyStateNearbyNoLocationBody": "Partagez votre emplacement pour que nous puissions vous recommander des restaurants à proximité.",
  "takeAdvantageOfLoyaltyProgram": "Profitez de notre programme de fidélisation",
  "yourAddress": "Votre adresse"
}
