import { useFocusEffect } from 'expo-router';
import { useCallback, useState } from 'react';

import { useAnchorSectionList } from './context';
import { useScrollToSection } from './use-scroll-to-section';

let lastScrollOffset: number = 0;

export const useInitialAutoScroll = () => {
  const [scrollDone, setScrollDone] = useState(false);
  const scrollToSection = useScrollToSection();
  const { flashListRef, defaultSectionId } = useAnchorSectionList();

  const triggerInitialAutoScroll = useCallback(() => {
    if (scrollDone) {
      return;
    }
    if (!defaultSectionId && !lastScrollOffset) {
      setScrollDone(true);
      return;
    }
    setTimeout(() => {
      if (lastScrollOffset) {
        flashListRef.current?.scrollToOffset({
          offset: lastScrollOffset,
          animated: false,
        });
      } else if (defaultSectionId) {
        scrollToSection(defaultSectionId, { animated: false });
      }
      setScrollDone(true);
    }, 200);
  }, [defaultSectionId, flashListRef, scrollDone, scrollToSection]);

  const saveLastScrollOffset = useCallback(
    (offset: number) => {
      if (scrollDone) {
        lastScrollOffset = offset;
      }
    },
    [scrollDone]
  );

  useFocusEffect(
    useCallback(() => {
      return () => {
        setScrollDone(false);
      };
    }, [])
  );

  return {
    scrollDone,
    triggerInitialAutoScroll,
    saveLastScrollOffset,
  };
};
