import type { IStateProps } from '@fhs-legacy/native-base/src/hooks/useThemeProps/propsFlattener';

import type { MergedThemeTypes } from '../../../themes/types';
import type { AllComponentsPropsMap } from '../../components/types';

export interface IUclCustomTheme {}

export interface IUclBaseTheme extends MergedThemeTypes {}

/**
 * Universal Components Library (UCL) theme interface.
 *
 * This is defined by the types from the theme.
 *
 * Extend this by declaring UclCustomTheme and extending the brand.
 *
 * If no brand is defined, the default is the types of all brands.
 *
 * Additional
 *
 * ```typescript
 * declare module '@fhs-legacy/universal-components'{
 *   export interface UclCustomTheme extends BkTheme{}
 * }
 * ```
 */
export type UclTheme = IUclCustomTheme extends { primitives: {} } ? IUclCustomTheme : IUclBaseTheme;

type UclNativeBaseTheme = UclTheme['nativeBaseTheme'];
/**
 * Changes types of NativeBase theme to make options
 * available to NB component props
 */
declare module '@fhs-legacy/native-base' {
  interface ICustomTheme extends UclNativeBaseTheme {}
  // @ts-ignore
  export function usePropsResolution<ComponentName extends keyof AllComponentsPropsMap>(
    component: ComponentName,
    incomingProps: any,
    state?: IStateProps,
    config?: {
      componentTheme?: any;
      ignoreProps?: string[];
      resolveResponsively?: string[];
    },
  ): AllComponentsPropsMap[ComponentName];
}
