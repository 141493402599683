import React from 'react';

import { Box, Text } from '@fhs-legacy/universal-components';
import { IOffer } from '@rbi-ctg/menu';
import Link from 'components/link';
import SanityBlockRenderer, {
  IBlockContentOverrides,
  ISerializers,
  SanityBlockContentLinkProps,
} from 'components/sanity-block-renderer';
import { useFeatureDisclaimers } from 'hooks/use-feature-disclaimers';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { LoyaltyOffer } from 'state/loyalty/types';
import { isMoreInfoDisclaimerAvailable } from 'utils/offers';

import { shouldUseCustomDisclaimer } from './should-use-custom-disclaimer';
import theme from './theme';

export const DisclaimerContainer = Box.withConfig({
  maxWidth: '576px',
  marginTop: {
    base: '12',
    desktop: '8',
  },
  marginBottom: {
    base: '12',
    desktop: '6',
  },
  textAlign: 'center',
});

const DisclaimerText = Text.withConfig({
  color: theme.disclaimerTextCssColor,
  fontSize: 'xs',
  margin: 0,
  textAlign: 'center',
});

// TODO - RN: Use withConfig when ExternalLink is migrated to RN
const DisclaimerLink = Link.withConfig({
  isExternal: true,
  fontSize: 'xs',
  lineHeight: 16 * 1.33,
  color: theme.disclaimerTextCssColor,
  textDecoration: 'underline',
});

const disclaimerBlockContentOverrides: IBlockContentOverrides = {
  // @ts-expect-error TS(2339) FIXME: Property 'textProps' does not exist on type 'Props... Remove this comment to see the full error message
  normal: p => <DisclaimerText {...p.textProps}>{p.children}</DisclaimerText>,
};

const disclaimerBlockSerializers: ISerializers = {
  marks: {
    link: ({ children = [], mark = {} }: SanityBlockContentLinkProps) => {
      const [linkText] = children;
      const { href } = mark;
      return href && linkText ? <DisclaimerLink to={href}>{linkText}</DisclaimerLink> : null;
    },
  },
};

export const CustomDisclaimer = ({
  content,
}: {
  content: React.ComponentProps<typeof SanityBlockRenderer>['content'];
}) => {
  return (
    <SanityBlockRenderer
      blockContentOverrides={disclaimerBlockContentOverrides}
      serializers={disclaimerBlockSerializers}
      content={content}
    />
  );
};

interface IDisclaimerProps {
  offer: IOffer | LoyaltyOffer | null;
}

export const OfferDisclaimer: React.FC<React.PropsWithChildren<IDisclaimerProps>> = ({ offer }) => {
  const { featureDisclaimers } = useFeatureDisclaimers();
  const { locale: generalOfferDisclaimer } = featureDisclaimers?.offers || {};

  const enableMoreInfoDisclaimer = useFlag(
    LaunchDarklyFlag.ENABLE_MORE_INFO_OPTIONAL_OFFER_DISCLAIMER
  );

  if (!offer) {
    return null;
  }

  const isMoreInfoDisclaimer = isMoreInfoDisclaimerAvailable(offer);
  const shouldUseMoreInfoDisclaimer = shouldUseCustomDisclaimer(
    isMoreInfoDisclaimer,
    enableMoreInfoDisclaimer
  );

  return (
    <DisclaimerContainer>
      {shouldUseMoreInfoDisclaimer ? (
        <CustomDisclaimer content={offer?.moreInfo?.localeRaw} />
      ) : (
        <DisclaimerText variant="copyOne">{generalOfferDisclaimer}</DisclaimerText>
      )}
    </DisclaimerContainer>
  );
};
