import { withStaticProperties } from '@fhs/utils';

import { Checkbox as _Checkbox } from './checkbox';
import { CheckboxControl as Control } from './checkbox-control';
import { CheckboxIndicator as Indicator } from './checkbox-indicator';
import { CheckboxLabel as Label } from './checkbox-label';
import { CheckboxPressable as Pressable } from './checkbox-pressable';
import { CheckboxTouchableWithoutFeedback as TouchableWithoutFeedback } from './checkbox-touchable-without-feedback';

export const Checkbox = withStaticProperties(_Checkbox, {
  Pressable,
  TouchableWithoutFeedback,
  Label,
  Control,
  Indicator,
});

export { useCheckbox } from './context';
export type { CheckboxContext } from './context';
export type { CheckboxControlProps } from './checkbox-control';
export type { CheckboxProps } from './checkbox';
export type { CheckboxLabelProps } from './checkbox-label';
export type { CheckboxPressable } from './checkbox-pressable';
export type { CheckboxIndicatorProps } from './checkbox-indicator';
export type { CheckboxTouchableWithoutFeedback } from './checkbox-touchable-without-feedback';
