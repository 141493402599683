import { HStack } from '@fhs-legacy/universal-components';

import theme from './theme';

export const CartItemEditContainer = HStack.withConfig({
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTopWidth: 1,
  borderColor: theme.cartItemContainerBorder,
  paddingTop: '$2',
  paddingBottom: '$2',
  marginX: '$4',
});

export default CartItemEditContainer;
