import * as React from 'react';

import { CenterHeader, LeftHeader, RightHeader } from '../loyalty-history.styled';
import { Transaction, TransactionTable } from '../types';

import { HEADER_IDS } from './constants';

const getTransactionTableHistoryStructure = (
  formatMessage: Function,
  transactions: Transaction[],
  monthHeader: string
): TransactionTable => ({
  head: [
    {
      dateTime: <LeftHeader>{monthHeader}</LeftHeader>,
      rewards: <CenterHeader>{formatMessage({ id: HEADER_IDS.POINTS })}</CenterHeader>,
      purchaseAmount: (
        <RightHeader>{formatMessage({ id: HEADER_IDS.PURCHASE_AMOUNT })}</RightHeader>
      ),
    },
  ],
  body: transactions,
});

export default getTransactionTableHistoryStructure;
