import { Box, HStack, Header, Text } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

export const EntryDescriptionRow = Text.withConfig({
  width: 'full',
  margin: 0,
  lineHeight: 'lg',
  variant: 'copyTwo',
});

export const Item = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'baseline',
  flexWrap: 'wrap',
  padding: 0,
  marginBottom: '$1',
});

export const ItemHeader = HStack.withConfig({
  justifyContent: 'space-between',
  width: 'full',
  alignItems: 'flex-start',
  marginBottom: '$1',
});

export const ItemName = Header.withConfig({
  variant: 'headerFour',
  margin: 0,
  flexBasis: '80%',
  flexShrink: 1,
});

export const ItemPrice = Box.withConfig({
  justifyContent: 'center',
  flexShrink: 0,
  flexGrow: 0,
  textAlign: 'right',
});

export const ItemQuantityBadge = Box.withConfig({
  paddingX: '$3',
  _text: {
    fontSize: 'xs',
  },
  backgroundColor: theme.token('background-badge-cart-entry'),
  borderRadius: 'full',
});
