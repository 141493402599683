import { router } from 'expo-router';
import { useLayoutEffect } from 'react';

import { useRefundEligibility } from 'hooks/refund-eligibility/use-refund-eligibility';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { routes } from 'utils/routing';
import { useSupportLink } from 'utils/support/use-support-link';

export interface IUseRedirectToSupportFormIfRefundIneligibleInput {
  orderId: string;
  categoryId: string;
  subcategoryId: string;
}

/**
 * This hook redirects the customer to the support flow if the order passed in is not eligible for a refund
 */
export const useRedirectToSupportFormIfRefundIneligible = ({
  orderId,
  categoryId,
  subcategoryId,
}: IUseRedirectToSupportFormIfRefundIneligibleInput) => {
  const { isDeterminingRefundEligibility, isOrderEligibleForRefund, refundIneligibilityReason } =
    useRefundEligibility({ orderId });
  const supportLink = useSupportLink();
  const enableGuestAndMultiStepSupportForm = useFlag(
    LaunchDarklyFlag.ENABLE_GUEST_AND_MULTI_STEP_SUPPORT_PAGE
  );

  /**
   * useLayoutEffect can prevent the component consuming this hook from rendering before redirecting,
   * eliminating a distracting flash of unintended content from showing
   */
  useLayoutEffect(() => {
    if (isDeterminingRefundEligibility) {
      return;
    }

    if (!isOrderEligibleForRefund && categoryId && subcategoryId) {
      const newQuery = new URLSearchParams({
        orderId,
        categoryId,
        subcategoryId,
      }).toString();
      // multistep support form allows including an orderId with submission
      const url = enableGuestAndMultiStepSupportForm
        ? `${routes.supportForm}?${newQuery}`
        : supportLink;

      router.navigate({
        pathname: url,
        params: { refundIneligibilityReason: refundIneligibilityReason.toString() },
      });
    }
  }, [
    categoryId,
    enableGuestAndMultiStepSupportForm,
    isDeterminingRefundEligibility,
    isOrderEligibleForRefund,
    orderId,
    refundIneligibilityReason,
    subcategoryId,
    supportLink,
  ]);

  return { isDeterminingRefundEligibility };
};
