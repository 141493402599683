import { Box, Center, Image, Text, VStack, addWithConfig } from '@fhs-legacy/universal-components';
import Countdown from 'components/countdown';
import BlockRenderer from 'components/sanity-block-renderer';

import theme from './theme';

export const StyledCountdown = Countdown;

export const ModalInner = VStack.withConfig({
  backgroundColor: Styles.color.background,
  height: 'full',
  width: 'full',
  overflow: 'scroll',
});

export const PictureHeading = Box.withConfig({
  margin: '$8',
  flexGrow: 0,
  height: 'auto',
  width: {
    desktop: '400px',
  },
});

export const BodyContent = Box.withConfig({
  flexGrow: 1,
  position: 'relative',
  padding: '$4',
  _text: {
    color: theme.bodyContentColor,
    textAlign: 'center',
  },
  alignItems: {
    desktop: 'center',
  },
});

export const ShortCodeWrapper = Box.withConfig({
  alignSelf: 'center',
  backgroundColor: Styles.color.black,
  borderRadius: 'xl',
  shadow: 1,
  padding: '$1',
  _text: {
    textAlign: 'center',
    color: 'white',
    fontFamily: 'heading',
    fontSize: 'md',
    letterSpacing: 'md',
    lineHeight: 'sm',
  },
});

export const ShortCode = Box.withConfig<{ qrcode: boolean }>(({ qrcode }) => ({
  padding: '$1.5',
  _text: {
    textAlign: 'center',
    color: 'white',
    fontFamily: 'heading',
    fontSize: qrcode ? 'md' : '4xl',
    letterSpacing: 'lg',
    lineHeight: 'sm',
    fontWeight: 'bold',
  },
}));

export const CouponTitle = Box.withConfig({
  marginTop: '$2',
  marginBottom: '$1.5',
  marginX: 0,
  _text: {
    color: Styles.color.black,
    textAlign: 'center',
  },
});

export const Name = addWithConfig(BlockRenderer).withConfig({
  textProps: {
    textAlign: 'center',
    textTransform: 'uppercase',
    lineHeight: 'xs',
    fontSize: {
      base: '4xl',
      desktop: '3xl',
    },
    fontFamily: 'heading',
    fontWeight: 'bold',
  },
});

export const Description = addWithConfig(BlockRenderer).withConfig({
  textProps: {
    textAlign: 'center',
    color: Styles.color.black,
    fontSize: {
      base: 'lg',
      desktop: 'xl',
    },
  },
});

export const Directions = Center.withConfig({
  marginTop: '$4',
});

export const Img = Image.withConfig({
  marginY: '$4',
  marginX: 'auto',
});

export const RedeemInNext = Text.withConfig({
  fontSize: 'lg',
  fontWeight: 'bold',
  color: Styles.color.black,
  marginBottom: '$1',
});

export const UseCodeAtCashier = Text.withConfig({
  marginTop: '$4',
  marginBottom: '$1.5',
  marginX: 0,
  color: Styles.color.black,
  fontWeight: 'bold',
  fontSize: 'lg',
  textAlign: 'center',
});

export const QRCodeContainer = Box.withConfig({
  borderRadius: 'md',
  backgroundColor: 'white',
  padding: '$0.5',
  width: 'full',
});
