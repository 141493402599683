import { Box } from '@fhs-legacy/universal-components';
// TODO: Make TH separator with style

export const Separator = Box.withConfig<{ highlighted: boolean }>(
  (
    p // eslint-disable-line @typescript-eslint/no-unused-vars
  ) => ({
    height: '1px',
    // TODO: use highlighted property
    backgroundColor: Styles.color.grey.four,
  })
);
