import { WatchQueryFetchPolicy } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import {
  LoyaltyRewardType,
  LoyaltyServiceMode,
  useLoyaltyUserRewardsQuery,
} from 'generated/graphql-gateway';
import { useCmsConfigRewardsQuery, useLoyaltyRewardsQuery } from 'generated/sanity-graphql';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import {
  IEngineRewardsMap,
  IPersonalizedRewardMap,
  ISanityRewardsMap,
  LoyaltyReward,
} from 'state/loyalty/types';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';

interface IParsedEngineRewards {
  engineRewardIds: string[];
  engineRewardsMap: IEngineRewardsMap;
  personalizedRewardIds: string[];
  personalizedRewardMap: IPersonalizedRewardMap;
}

export const useLoyaltyRewardsList = (fetchPolicy: WatchQueryFetchPolicy = 'cache-first') => {
  const { serviceMode: ctxServiceMode } = useServiceModeContext();
  const serviceMode = LoyaltyServiceMode[ctxServiceMode || ''];
  const { store } = useStoreContext();

  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUserId } = useLoyaltyUser();

  // To Do: move this logic to loyalty GQL server in order to make 1 call on the frontend
  // and to avoid creating rewards map
  const {
    data: engineResponse,
    loading: engineRewardsLoading,
    refetch: refetchEngineRewards,
  } = useLoyaltyUserRewardsQuery({
    skip: !loyaltyEnabled || !loyaltyUserId,
    variables: {
      loyaltyId: loyaltyUserId || '',
      where: {
        ignorePointBalance: true,
        serviceMode: serviceMode || undefined,
        storeId: store?.number,
      },
    },
    fetchPolicy,
  });

  const { engineRewardIds, engineRewardsMap, personalizedRewardIds, personalizedRewardMap } =
    useMemo(
      () =>
        (engineResponse?.loyaltyUserV2?.rewards || []).reduce(
          (acc: IParsedEngineRewards, reward) => {
            // Extract engineIds to query for rewards in sanity
            if (reward?.id) {
              if (reward?.type === LoyaltyRewardType.PERSONALIZED && reward.sanityId) {
                acc.personalizedRewardIds.push(reward.sanityId);
                acc.engineRewardsMap[reward.sanityId] = reward;
                acc.personalizedRewardMap[reward.id] = reward;
              } else {
                acc.engineRewardIds.push(reward.id);
                acc.engineRewardsMap[reward.id] = reward;
              }
            }
            return acc;
          },
          {
            engineRewardIds: [],
            engineRewardsMap: {},
            personalizedRewardIds: [],
            personalizedRewardMap: {},
          }
        ),
      [engineResponse]
    );

  // fetch all config rewards by ID
  const { data: configRewardsData, loading: configRewardsLoading } = useCmsConfigRewardsQuery({
    variables: {
      ids: personalizedRewardIds,
    },
    skip: !personalizedRewardIds?.length,
  });

  const {
    data,
    loading: sanityRewardsLoading,
    refetch: refetchCmsRewards,
  } = useLoyaltyRewardsQuery({
    skip: !engineRewardIds?.length,
  });

  const refetchRewards = useCallback(
    async (loyaltyId: string) => {
      await refetchEngineRewards({
        loyaltyId,
        where: {
          ignorePointBalance: true,
          serviceMode: serviceMode || undefined,
          storeId: store?.number,
        },
      });
      await refetchCmsRewards();
    },
    [refetchCmsRewards, refetchEngineRewards, serviceMode, store]
  );

  const rewardsByEngineId: LoyaltyReward[] | null = useMemo(() => {
    if (data?.allRewards) {
      const validRewards = data.allRewards.filter(
        reward => engineRewardsMap[reward?.loyaltyEngineId || '']
      );

      return [...validRewards, ...(configRewardsData?.allConfigRewards ?? [])];
    }
    return null;
  }, [data, engineRewardsMap, configRewardsData]);

  const sanityRewardsMap = useMemo(() => {
    if (!rewardsByEngineId) {
      return null;
    }

    const rewardsMap = rewardsByEngineId.reduce((acc: ISanityRewardsMap, reward) => {
      acc[reward._id] = reward;
      return acc;
    }, {});

    return Object.keys(personalizedRewardMap).reduce((acc: ISanityRewardsMap, id) => {
      const { sanityId } = personalizedRewardMap[id];
      if (sanityId) {
        rewardsMap[id] = acc[sanityId];
      }
      return rewardsMap;
    }, rewardsMap);
  }, [personalizedRewardMap, rewardsByEngineId]);

  const rewardsLoading = engineRewardsLoading || sanityRewardsLoading || configRewardsLoading;

  return {
    rewards: rewardsByEngineId || null,
    loading: rewardsLoading,
    engineRewardsMap,
    sanityRewardsMap,
    refetchRewards,
  };
};
