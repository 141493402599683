import { FormControlTextInput } from '../form-control-text-input';
import { IconAccount } from '../icon';

import { MaskInputProps } from '.';

export function NameInput(props: MaskInputProps) {
  return (
    <FormControlTextInput
      label={'Name'}
      placeholder={'John Doe'}
      keyboardType="default"
      leftIcon={<IconAccount size={24} />}
      onChangeText={props.onChange}
      value={props.value}
    />
  );
}
