import { format, subDays } from 'date-fns';

import { SmgCodeStatus } from 'generated/graphql-gateway';

import { ITransactionDateOption } from './transaction-form/transaction-date-input-form';
import { ClaimMethod, ClaimPointsUIData } from './types';

export const CODE_SEARCH_PARAM = 'code';

export const mapStatusCodeToSanityContent = (statusCode?: string, data?: ClaimPointsUIData) => {
  let sanityContent: any = null;

  switch (statusCode) {
    case SmgCodeStatus.THROTTLING_ERROR:
      sanityContent = data?.claimPointsTooManyRequests;
      break;
    case SmgCodeStatus.CODE_EXISTS:
      sanityContent = data?.claimPointsDuplicateTransactionId;
      break;
    case SmgCodeStatus.EXPIRED:
      sanityContent = data?.claimPointsExpiredContent;
      break;
    case SmgCodeStatus.RETRY_REQUESTED:
      sanityContent = data?.claimPointsPendingContent;
      break;
    case SmgCodeStatus.TRANSACTION_CLAIMED:
      sanityContent = data?.claimPointsAlreadyClaimedContent;
      break;
    case SmgCodeStatus.NOT_FOUND:
      sanityContent = data?.claimPointsTransactionNotFoundContent;
      break;
    case SmgCodeStatus.INVALID_RESTAURANT:
      sanityContent = data?.claimPointsInvalidRestaurantContent;
      break;
    case SmgCodeStatus.LIMIT_EXCEEDED:
      sanityContent = data?.claimPointsDailyAmountExceededContent;
      break;
    case SmgCodeStatus.INVALID_FORMAT:
    default: {
      sanityContent = data?.claimPointsInvalidCodeContent;
      break;
    }
  }

  return sanityContent;
};

export const getBackUrl = (
  formatMessage: (obj: { id: string }) => string,
  baseUrl: string,
  claimMethod?: ClaimMethod
) => {
  let url = baseUrl;

  switch (claimMethod) {
    case ClaimMethod.MANUAL:
      url = `${baseUrl}${formatMessage({ id: 'routes.claimPointsSurvey' })}`;
      break;
    case ClaimMethod.QR_SCAN:
      url = `${baseUrl}${formatMessage({ id: 'routes.scan' })}`;
      break;
    default:
      url = baseUrl;
      break;
  }

  return url;
};

const HOURS_IN_DAY = 24;
export const generateTransactionDateOptions = (
  validReceiptHours: number
): ITransactionDateOption[] => {
  const dropdownOptions: ITransactionDateOption[] = [];
  const validDays = Math.floor(validReceiptHours / HOURS_IN_DAY);

  if (!validDays) {
    return dropdownOptions;
  }

  const today = Date.now();
  for (let day = 0; day < validDays; day++) {
    const optionDay = subDays(today, day);

    dropdownOptions.push({
      value: format(optionDay, 'MM/dd/yyyy'),
      label: format(optionDay, 'EEEE, MMMM dd, y'),
    });
  }

  return dropdownOptions;
};
