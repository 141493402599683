import { theme } from 'styles/configure-theme';

import { ICartItemTheme } from './types';

const cartItemTheme: ICartItemTheme = {
  cartItemContainerBorder: theme.token('border-color-default'),
  cartItemDescriptionColor: Styles.color.grey.one,
  headerColor: Styles.color.grey.four,
  cartItemButtonVariant: 'ghost',
  cartItemButtonColor: theme.token('text-default'),
};

export default cartItemTheme;
