import { useMemo, useState } from 'react';

import { IServerOrder } from '@rbi-ctg/menu';
import { CartPaymentCardType } from 'generated/rbi-graphql';
import { ICommitOrderInput } from 'hooks/commit-order/types';
import { useConfigValue } from 'hooks/configs/use-config-value';
import useEffectOnce from 'hooks/use-effect-once';
import { getNonce } from 'remote/api/first-data';
import { IPayment } from 'remote/queries/order';
import { useAuthContext } from 'state/auth';
import { UserDetails } from 'state/auth/hooks/use-current-user';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import { IOrder } from 'state/order/types';
import { IPaymentContext } from 'state/payment';
import {
  CardTypes,
  IApplePayDetails,
  IGooglePayDetails,
  IPaymentMethod,
  IReloadPrepaidCard,
} from 'state/payment/types';
import useRewards from 'state/rewards/use-rewards';
import { ISOs, getCountryAndCurrencyCodes } from 'utils/form/constants';
import logger from 'utils/logger';
import {
  IPaymentProcessorBillingAddress,
  getFraudPreventionValues,
  initialPaymentState,
  splitExpiry,
  testCardPaymentState,
} from 'utils/payment';
import { isApplePay, isGooglePay } from 'utils/payment/native-payment';

enum PaymentMode {
  ADDING_NEW_CARD,
  SELECTING_EXISTING_CARD,
}

interface CommitOrderPayload {
  billingAddress?: IPaymentProcessorBillingAddress;
  ccBin?: string | null;
  ccExpiry?: string | null;
  ccLast4?: string;
  creditType?: CartPaymentCardType | null;
  fdAccessToken?: string;
  fdAccountId?: string;
  fdNonce?: string;
  fullName?: string;
  payment?: IPayment;
  order: IOrder;
  rbiOrderId?: string;
  storeEmail?: string;
}

interface IPrepaidMethodReload {
  commitOrder: (options: ICommitOrderInput) => void;
  handleCommitError: (error: any) => void;
  order: IOrder;
  payment: IPaymentContext;
  paymentMethods: IPaymentMethod[];
  user: UserDetails | null;
  serverOrder: IServerOrder | null;
  selectedPaymentMethod: IPaymentMethod | undefined;
  shouldCommitOrder: boolean;
  storeEmail: string;
}

const usePrepaidMethodReload = ({
  commitOrder,
  handleCommitError,
  order,
  payment,
  paymentMethods,
  user,
  serverOrder,
  selectedPaymentMethod,
  shouldCommitOrder,
  storeEmail,
}: IPrepaidMethodReload) => {
  const auth = useAuthContext();
  const autoFill = useFlag(LaunchDarklyFlag.AUTO_FILL_TEST_CARD);
  const billingCountry = (user?.details?.isoCountryCode as ISOs) || ISOs.USA;
  const [needReloadAgain, setNeedReloadAgain] = useState(false);
  const paymentFieldVariations =
    useFlag<PaymentFieldVariations>(LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS) ||
    defaultPaymentFieldVariation;

  const urlsConfig = useConfigValue({ key: 'urls', defaultValue: {} });
  const fdUrl = useMemo(
    () => urlsConfig.firstData || urlsConfig.firstDataTh,
    [urlsConfig.firstData, urlsConfig.firstDataTh]
  );

  const userDetailsName = auth.user?.details.name;

  const [reloadPaymentValues, setReloadPaymentValues] = useState(
    initialPaymentState({ billingCountry, userDetailsName })
  );

  const enableGiftCard = useFlag(LaunchDarklyFlag.ENABLE_GIFT_CARD);
  const onlySendPostalCode = useFlag(LaunchDarklyFlag.SEND_POSTAL_CODE_ONLY_FOR_FIRST_DATA_PAYMENT);

  const prepaidPaymentMethod = payment.getPrepaidPaymentMethod();
  const accountIdentifier =
    prepaidPaymentMethod?.accountIdentifier ?? prepaidPaymentMethod?.fdAccountId ?? '';
  const prepaidPaymentMethodSelected =
    !!accountIdentifier && payment.checkoutPaymentMethodId === accountIdentifier;

  const { reloadAmount } = useRewards({
    prepaidCard: prepaidPaymentMethod,
    serverOrder,
    order,
    user,
  });

  // Autofill CC information
  useEffectOnce(() => {
    if (autoFill) {
      setReloadPaymentValues(
        testCardPaymentState({ paymentFieldVariations, isWorldpay: payment.isWorldpay })
      );
    }
  });

  const showReloadPaymentMethods =
    enableGiftCard && prepaidPaymentMethodSelected && reloadAmount > 0;

  const allowedPaymentMethods = payment.paymentMethods.filter(
    method => !method.prepaid && !method.cash
  );
  const mode: PaymentMode =
    allowedPaymentMethods.length > 0
      ? PaymentMode.SELECTING_EXISTING_CARD
      : PaymentMode.ADDING_NEW_CARD;

  const reloadWithApplePay =
    showReloadPaymentMethods && isApplePay(payment.prepaidReloadPaymentMethodId);

  const reloadWithGooglePay =
    showReloadPaymentMethods && isGooglePay(payment.prepaidReloadPaymentMethodId);

  const maybeAddNewCard = (): Promise<string | void> => {
    if (
      !reloadWithApplePay &&
      !reloadWithGooglePay &&
      reloadPaymentValues.saveCard &&
      mode === PaymentMode.ADDING_NEW_CARD
    ) {
      return payment.addPaymentMethod(
        payment.transformPaymentValues({
          paymentValues: reloadPaymentValues,
          paymentFieldVariations,
        })
      );
    }
    return Promise.resolve();
  };

  const getPrepaidBalance = (methods: IPaymentMethod[]) => {
    const prepaidAccount = methods.find((method: IPaymentMethod) => Boolean(method.prepaid));
    return payment.getBalanceFromPaymentMethods(prepaidAccount!);
  };

  const maybeReloadGiftCard = async (
    input?: IApplePayDetails | IGooglePayDetails
  ): Promise<number> => {
    setNeedReloadAgain(false);

    const shouldReloadGiftCard = reloadAmount > 0 && prepaidPaymentMethodSelected;

    if (!shouldReloadGiftCard) {
      return Promise.resolve(getPrepaidBalance(paymentMethods));
    }

    // Add the new payment method if is necessary
    const paymentMethodId = (await maybeAddNewCard()) || payment.prepaidReloadPaymentMethodId;

    const prepaidBalance = getPrepaidBalance(paymentMethods);
    const { countryCode } = getCountryAndCurrencyCodes(billingCountry);
    const prepaidFdAccountId =
      prepaidPaymentMethod?.accountIdentifier ?? prepaidPaymentMethod?.fdAccountId ?? '';

    let args: IReloadPrepaidCard = {
      countryCode,
      fundValue: reloadAmount,
      prepaidBalance,
      prepaidFdAccountId,
    };

    switch (paymentMethodId) {
      case CardTypes.APPLE_PAY:
        args = {
          ...args,
          applePayDetails: input as IApplePayDetails,
          fdAccountId: payment.prepaidReloadPaymentMethodId,
        };
        break;
      case CardTypes.GOOGLE_PAY:
        args = {
          ...args,
          fdAccountId: payment.prepaidReloadPaymentMethodId,
          googlePayDetails: input as IGooglePayDetails,
        };
        break;
      default:
        args = { ...args, fdAccountId: paymentMethodId };
    }

    // return original balance if reload failed
    return payment.reloadPrepaidCard(args).then(updatedBalance => updatedBalance ?? prepaidBalance);
  };

  const buildCommitOrderPayload = async (prepaidBalance: number): Promise<CommitOrderPayload> => {
    if (!serverOrder) {
      return Promise.reject('Required parameters are not defined.');
    }

    if (prepaidBalance < serverOrder.cart.totalCents) {
      setNeedReloadAgain(true);
      return Promise.reject('Insufficient prepaid balance.');
    }

    const { rbiOrderId } = serverOrder;

    if (prepaidPaymentMethodSelected) {
      return Promise.resolve({
        fdAccountId:
          prepaidPaymentMethod?.accountIdentifier ?? prepaidPaymentMethod?.fdAccountId ?? '',
        order,
        rbiOrderId,
        storeEmail,
      });
    }

    if (reloadWithApplePay || reloadWithGooglePay) {
      return Promise.resolve({
        order,
        rbiOrderId,
        storeEmail,
      });
    }

    if (selectedPaymentMethod) {
      return Promise.resolve({
        creditType: selectedPaymentMethod.credit
          ? (selectedPaymentMethod.credit.cardType as unknown as CartPaymentCardType)
          : null,
        fdAccountId:
          selectedPaymentMethod.accountIdentifier ?? selectedPaymentMethod.fdAccountId ?? '',
        order,
        rbiOrderId,
        storeEmail,
      });
    }

    // Otherwise we have to jump through hoops to get more values from First Data
    const { fdAccessToken, fdApiKey, fdCustomerId, fdPublicKey, algorithm } =
      await payment.getEncryptionDetails();

    const reshapedPaymentValues = payment.transformPaymentValues({
      paymentValues: reloadPaymentValues,
      paymentFieldVariations,
    });
    const nonceResponse = await getNonce(
      reshapedPaymentValues,
      fdPublicKey,
      fdApiKey,
      fdAccessToken,
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
      fdCustomerId,
      fdUrl,
      onlySendPostalCode,
      algorithm
    );
    const nonce = await nonceResponse.json();

    const fraudPreventionValues = getFraudPreventionValues(reloadPaymentValues);
    const { expiryMonth, expiryYear } = splitExpiry(fraudPreventionValues.expiry ?? '');

    return {
      creditType: reloadPaymentValues.cardType as CartPaymentCardType,
      payment: {
        billingAddress: fraudPreventionValues.billingAddress,
        ccMetadata: {
          ccBin: fraudPreventionValues.ccBin,
          ccExpiryMonth: expiryMonth,
          ccExpiryYear: expiryYear,
          ccLast4: fraudPreventionValues.ccLast4,
        },
        firstDataInput: {
          accountIdentifier: reloadPaymentValues.saveCard ? accountIdentifier : undefined,
          fdAccessToken: reloadPaymentValues.saveCard ? undefined : fdAccessToken,
          fdNonce: reloadPaymentValues.saveCard ? undefined : nonce.token.tokenId,
        },
        fullName: fraudPreventionValues.fullName,
      },
      order,
      rbiOrderId,
      storeEmail,
    };
  };

  const reloadGiftCardAndCheckout = async (data?: IApplePayDetails | IGooglePayDetails) => {
    // should never happen, since user cannot submit without
    // successfully pricing an order
    if (!serverOrder) {
      throw new Error('unreachable');
    }

    try {
      // reloads prepaid if necessary
      const currentPrepaidBalance = await maybeReloadGiftCard(data);

      if (shouldCommitOrder) {
        // commit order if prepaid balance is sufficient
        const commitOrderPayload = await buildCommitOrderPayload(currentPrepaidBalance);
        await commitOrder(commitOrderPayload);
      }
    } catch (e) {
      logger.error(`Error reloading giftcard: ${e}`);
      handleCommitError(e);
    }
  };

  return {
    reloadAddPaymentMethod: mode === PaymentMode.ADDING_NEW_CARD,
    reloadAmount,
    reloadGiftCardAndCheckout,
    reloadPaymentValues,
    needReloadAgain,
    showReloadPaymentMethods,
    setReloadPaymentValues,
  };
};

export default usePrepaidMethodReload;
