import { ApolloClient, from } from '@apollo/client';
import { ReactNode } from 'react';

import { getConfiguredCache } from 'state/graphql/cache';
import { httpLink } from 'state/graphql/links/http-link';
import { stripTypenameLink } from 'state/graphql/links/strip-typename';
import { withErrorLogger } from 'state/graphql/links/with-error-logger';
import { withHeaders } from 'state/graphql/links/with-headers';
import { isSSG } from 'utils/environment';

import { headers } from './headers';

/**
 * Apollo Client instance
 *
 * Client should only be instantiated once per app
 * @see https://github.com/apollographql/apollo-client-devtools/issues/822
 */
export const client = new ApolloClient({
  cache: getConfiguredCache(),
  link: from(
    [withHeaders(headers), withErrorLogger, stripTypenameLink, httpLink].filter(Boolean) as any[]
  ),
  headers,
  ssrMode: isSSG,
});

/**
 * Wait for queries to load and returns apollo cache.
 * It should be called before `renderToString` on server side in order to have ready the UI.
 * It's used on node_modules/expo-router/build/static/renderStaticContent.js for apollo cache injection on HTML.
 */
global.getInitialStateFromTree = async (app: ReactNode) => {
  const { getDataFromTree } = await import('@apollo/client/react/ssr');
  await getDataFromTree(app);
  return client.extract();
};
