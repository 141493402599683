import { View, ViewProps } from 'react-native';

import { Text } from '@fhs/ui';

export const LongDistanceFee = ({
  style,
  formattedFee,
  isDynamic,
}: {
  style?: ViewProps['style'];
  formattedFee?: string;
  isDynamic?: boolean;
}) => {
  return (
    <View style={style}>
      <Text.Heading type="five">
        Long Distance Fee:
        {isDynamic && (
          <Text.Ui weight="bold" size="sm">
            {' '}
            {formattedFee}
          </Text.Ui>
        )}
      </Text.Heading>
      {!isDynamic && (
        <>
          <Text.Paragraph size="sm">
            • 0 - 1.99 miles away:{' '}
            <Text.Ui weight="bold" size="sm">
              N/A
            </Text.Ui>
          </Text.Paragraph>
          <Text.Paragraph size="sm">
            • 2 - 5.99 miles away:{' '}
            <Text.Ui weight="bold" size="sm">
              $0.80
            </Text.Ui>
          </Text.Paragraph>
          <Text.Paragraph size="sm">
            • 6 - 9.99 miles away:{' '}
            <Text.Ui weight="bold" size="sm">
              $6.00
            </Text.Ui>
          </Text.Paragraph>
        </>
      )}
    </View>
  );
};
