import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { MqSwitch } from '@fhs/ui';
import { Box } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { DeliveryTerms } from 'components/address-modal/delivery-terms';
import { SwitchServiceModeDialog } from 'components/switch-service-mode-dialog';
import { StoreChangeDisclaimer } from 'pages/store-locator/store-change-disclaimer';
import { useMainMenuContext } from 'state/main-menu';
import { useOrderContext } from 'state/order';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';

import { ButtonContainer, DeliveryInfoContainer, LoadingText } from './styled';

interface IDeliveryFormFooterProps {
  submitAddress: () => void;
  setCreateAddressView: (show: boolean) => void;
  showDeliveryAddresses: boolean;
  isDisabled: boolean;
  isLoading: boolean;
}

const DeliveryAddressFormFooter: React.FC<React.PropsWithChildren<IDeliveryFormFooterProps>> = ({
  submitAddress,
  setCreateAddressView,
  showDeliveryAddresses,
  isDisabled,
  isLoading,
}) => {
  const [showChangeToPickUpDialog, setShowChangeToPickUpDialog] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const { serviceMode } = useServiceModeContext();

  const { cartEntries } = useOrderContext();
  const { clearStoreMenuCache } = useMainMenuContext();

  const switchToDeliveryAction = () => {
    clearStoreMenuCache();
    submitAddress();
  };

  const switchModeAction = () => {
    const isDelivery = serviceMode == ServiceMode.DELIVERY;
    if (!isDelivery && Array.isArray(cartEntries) && cartEntries.length > 0) {
      setShowChangeToPickUpDialog(true);
    } else {
      submitAddress();
    }
  };

  return (
    <>
      <DeliveryInfoContainer>
        <StoreChangeDisclaimer />
        <MqSwitch $ltDesktop={() => <DeliveryTerms />} />
      </DeliveryInfoContainer>
      <Box>
        <ButtonContainer>
          <ActionButton
            onPress={switchModeAction}
            fullWidth
            disabled={isLoading || isDisabled}
            isLoading={isLoading}
            testID="deliver-here"
            _text={{ fontSize: 'lg' }}
          >
            {formatMessage({ id: 'deliverHere' })}
          </ActionButton>
        </ButtonContainer>
        {showDeliveryAddresses && (
          <ButtonContainer>
            <ActionButton
              testID="back-to-saved-addresses"
              fullWidth
              variant={ActionButtonVariants.OUTLINE}
              onPress={() => setCreateAddressView(false)}
              _text={{ fontSize: 'md' }}
            >
              {formatMessage({ id: 'backToSavedAddresses' })}
            </ActionButton>
          </ButtonContainer>
        )}
      </Box>
      {isLoading && (
        <Box>
          <LoadingText>{formatMessage({ id: 'deliveryCheckingMessage' })}</LoadingText>
        </Box>
      )}
      {showChangeToPickUpDialog && (
        <SwitchServiceModeDialog
          showDialog
          onConfirm={switchToDeliveryAction}
          onDismiss={() => setShowChangeToPickUpDialog(false)}
          switchingFromPickUpToDelivery={true}
        />
      )}
    </>
  );
};

export default DeliveryAddressFormFooter;
