/**
 * mParticle Provider RN Implementation
 * We will uncomment logic as we continue to implement various portions and swap in RN functionality.
 */

import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { Platform } from 'react-native';

import { useApiKey } from 'hooks/configs/use-api-key';
import { AllowedEvent } from 'state/crm-events/types';
import { normalizeBooleans, sanitizeValues } from 'state/crm-events/utils';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import AuthStorage from 'utils/cognito/storage';
import { addContext as addLoggerContext } from 'utils/datadog';
import { getDeviceId } from 'utils/device-id';
import { appVersion, brand, env, isNativeIOS } from 'utils/environment';
import { getNativeLocationPermissions } from 'utils/geolocation';
import { initGTM, sendGoogleTagManagerEvent } from 'utils/google-tag-manager';
import { StorageKeys } from 'utils/local-storage';
import logger from 'utils/logger';
import noop from 'utils/noop';

import {
  AmplitudeTypes,
  Revenue,
  add,
  getSessionId,
  init,
  revenue,
  setDeviceId as setAmplitudeDeviceId,
  setSessionId as setAmplitudeSessionId,
  setUserId,
  track,
} from './amplitude-package';
import { IAmplitudeUserAttributes, ValidPropertyType } from './types';
import { extractAmplitudeUserAttributes, setAmplitudeUserAttributes } from './utils';

interface ILogAmplitudeCustomEventProps {
  name: AllowedEvent['name'];
  attributes: Record<string, string>;
}
export interface IAmplitudeContext {
  deviceId: string;
  logAmplitudeCustomEvent: (event: ILogAmplitudeCustomEventProps) => void;
  logAmplitudeRevenueEvent: (props: {
    totalAmount: number;
    eventProperties: Record<string, string | number | boolean>;
  }) => void;
  sessionId: string;
  setAmplitudeUserId: ({ customerId }: { customerId: string }) => void;
  unsetAmplitudeUserId: () => void;
  updateAmplitudeUserAttributes: (userAttributes: IAmplitudeUserAttributes) => void;
  updateUserLocationPermissionStatus: () => Promise<void>;
}

const AmplitudeContext = React.createContext<IAmplitudeContext>({
  deviceId: 'fake-id',
  logAmplitudeCustomEvent: noop,
  logAmplitudeRevenueEvent: noop,
  sessionId: 'fake-id',
  setAmplitudeUserId: noop,
  unsetAmplitudeUserId: noop,
  updateAmplitudeUserAttributes: noop,
  updateUserLocationPermissionStatus: () => Promise.resolve(),
});

export const useAmplitudeContext = () => useContext<IAmplitudeContext>(AmplitudeContext);

/**
 * - Sets the session ID.
 * - Sets the user ID.
 * - Sets the device ID.
 * - Exposes logging and initialize methods.
 */
export function AmplitudeProvider(props: { children: ReactNode }) {
  const [deviceId, setDeviceId] = useState('');
  const [sessionId, setSessionId] = useState('');
  const { region } = useLocale();
  const amplitudeKey = useApiKey({ key: 'amplitude' });
  const { updateUserDeviceId: updateLaunchDarklyDeviceId } = useLDContext();
  const enableGoogleTagManager = useFlag(LaunchDarklyFlag.ENABLE_GOOGLE_TAG_MANAGER);

  const setAmplitudeUserId: any = useCallback(
    ({ customerId }: { customerId: string | undefined }) => {
      if (customerId) {
        setUserId(customerId);
      }
    },
    []
  );

  const unsetAmplitudeUserId = useCallback(() => {
    setUserId('');
  }, []);

  /**
   * We never log PII to Amplitude.
   * Though you may pass any object to this method,
   * `extractAmplitudeUserAttributes` will only add the attributes we want.
   */
  const updateAmplitudeUserAttributes = useCallback(
    async (userAttributes: IAmplitudeUserAttributes = {}) => {
      const cognitoId = AuthStorage.getItem(StorageKeys.USER_AUTH_TOKEN);
      if (!cognitoId) {
        return;
      }

      // Ensures user ID is set
      setAmplitudeUserId({ cognitoId });

      const globalUserAttributes = {
        brand: brand().toUpperCase(),
        region,
        env: env() as string,
      };

      const sanitizedAttributes = sanitizeValues({ ...userAttributes, ...globalUserAttributes });
      const normalizedAttributes = normalizeBooleans(sanitizedAttributes);

      const amplitudeUserAttributes = extractAmplitudeUserAttributes(normalizedAttributes);
      setAmplitudeUserAttributes(amplitudeUserAttributes);
    },
    [region, setAmplitudeUserId]
  );

  const addGTMPlugin = () => {
    if (Platform.OS === 'web' && enableGoogleTagManager) {
      const gtmPlugin: AmplitudeTypes.DestinationPlugin = {
        name: 'google-tag-manager',
        type: AmplitudeTypes.PluginType.DESTINATION,
        setup: initGTM,
        execute: sendGoogleTagManagerEvent,
      };

      add(gtmPlugin);
    }
  };

  /**
   * @deprecated
   * Logs a custom event to Amplitude.
   * Please use the logRBIEvent method from useCRMEventsContext instead of this.
   * Note - this is used inside of the logRBIEvent in CRMEventsContext.
   */
  const logAmplitudeCustomEvent = useCallback((event: ILogAmplitudeCustomEventProps) => {
    try {
      const globalAttrs = {
        brand: brand().toUpperCase(),
        region,
        env: env() as string,
      };
      track(event.name, { ...globalAttrs, ...event.attributes });
    } catch (err) {
      logger.error('Error logging event to Amplitude');
    }
  }, []);

  const logAmplitudeRevenueEvent = useCallback(
    ({
      totalAmount,
      eventProperties,
    }: {
      totalAmount: number;
      eventProperties: {
        [key: string]: ValidPropertyType;
      };
    }) => {
      try {
        const revenueEvent = new Revenue();
        revenueEvent.setRevenue(totalAmount).setEventProperties(eventProperties);
        revenue(revenueEvent);
      } catch (error) {
        logger.warn({ error, message: 'Error logging Amplitude revenue event' });
      }
    },
    []
  );

  const updateUserLocationPermissionStatus = useCallback(async () => {
    const status = await getNativeLocationPermissions();
    if (!status) {
      return;
    }
    if (isNativeIOS()) {
      updateAmplitudeUserAttributes({ 'IOS Location Permissions': status });
    } else {
      updateAmplitudeUserAttributes({ 'Android Location Permissions': status });
    }
  }, [updateAmplitudeUserAttributes]);

  /**
   * Initialize the Amplitude client.
   * If a user is already logged in, we will initialize with the user's cognito ID.
   * Sets Session and Device IDs.
   */
  useState(async function initializeAmplitudeSdk() {
    /**
     * Initialize the SDK
     */
    addGTMPlugin();
    const cognitoId = AuthStorage.getItem(StorageKeys.USER_AUTH_TOKEN);
    const options: AmplitudeTypes.ReactNativeOptions = {
      trackingSessionEvents: true,
      appVersion,
      defaultTracking: { sessions: true },
    };

    if (cognitoId) {
      init(amplitudeKey, cognitoId, options);

      // This will automatically add global user attributes.
      updateAmplitudeUserAttributes();
    } else {
      init(amplitudeKey, undefined, options);
    }

    /**
     * Update the Device ID
     */
    try {
      /**
       * We are using a custom util that is a promise, but we could potentially also use Amplitude's method.
       * We must also lowercase the device ID as mParticle was doing this for us previously.
       * (also called getDeviceId).
       */
      const id = (await getDeviceId())?.toLowerCase();
      if (id) {
        setAmplitudeDeviceId(id);
        updateLaunchDarklyDeviceId(id);
        setDeviceId(id);
      }
    } catch (error) {
      logger.error({ error, message: 'Error getting the device ID' });
    }

    /**
     * Configure the Session ID
     */
    const MISSING_SESSION_ERROR_MESSAGE = 'No Amplitude session ID available';
    try {
      const amplitudeSessionId = getSessionId()?.toString();

      if (!amplitudeSessionId) {
        throw new Error(MISSING_SESSION_ERROR_MESSAGE);
      }
      setSessionId(amplitudeSessionId);
      addLoggerContext('session', amplitudeSessionId);
    } catch (error) {
      /**
       * This happens when the web does not have a sessionId yet for iOS to use.
       * We won't bother making it an error since there is nothing for us to do.
       */

      /**
       * Setting a custom session ID. Note, the session ID does note have to be unique across users.
       * https://www.docs.developers.amplitude.com/data/sdks/typescript-react-native/#custom-user-id
       * https://help.amplitude.com/hc/en-us/articles/115002323627-Track-sessions-in-Amplitude#:~:text=The%20session%20ID%20does%20not,you%20use%20to%20group%20sessions.
       */
      const newSessionID = Date.now();
      setAmplitudeSessionId(newSessionID);
      setSessionId(String(newSessionID));
      addLoggerContext('session', String(newSessionID));

      let errorMessage = MISSING_SESSION_ERROR_MESSAGE;
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      if (errorMessage.includes(MISSING_SESSION_ERROR_MESSAGE)) {
        logger.warn(errorMessage);
        return;
      }
      logger.error({
        message: `Failed to get Amplitude SessionID: ${String(error)}`,
      });
    }
  });

  const amplitudeContext = useMemo<IAmplitudeContext>(
    () => ({
      deviceId,
      logAmplitudeCustomEvent,
      logAmplitudeRevenueEvent,
      sessionId,
      setAmplitudeUserId,
      unsetAmplitudeUserId,
      updateAmplitudeUserAttributes,
      updateUserLocationPermissionStatus,
    }),
    [
      deviceId,
      logAmplitudeCustomEvent,
      logAmplitudeRevenueEvent,
      sessionId,
      setAmplitudeUserId,
      unsetAmplitudeUserId,
      updateAmplitudeUserAttributes,
      updateUserLocationPermissionStatus,
    ]
  );

  return (
    <AmplitudeContext.Provider value={amplitudeContext}>{props.children}</AmplitudeContext.Provider>
  );
}

export default AmplitudeContext.Consumer;
