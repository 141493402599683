import { Box, Pressable, Text } from '@fhs-legacy/universal-components';
import { ActionsheetContent, ActionsheetFooter } from 'components/action-sheet';

const ACTION_SHEET_MAX_WIDTH = '380px';

export const TimeSelector = Pressable.withConfig<{ $isSelected?: boolean }>(props => ({
  borderBottomWidth: '1',
  borderBottomColor: 'token.border-color-default',
  mb: '$2',
  paddingY: '$2',
  width: 'full',
  opacity: props.$isSelected ? 1 : 0.4,
}));

export const MockedSelectorDate = Box.withConfig({
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const MockedSelectorTime = Box.withConfig({});

export const MockedSelectorTitle = Text.withConfig({
  fontSize: 'xs',
  fontWeight: 'normal',
});

export const ActionButtonContainer = ActionsheetFooter.withConfig({
  padding: '$4',
  width: 'full',
  _web: {
    maxWidth: ACTION_SHEET_MAX_WIDTH,
  },
});

export const StyledActionSheetContent = ActionsheetContent.withConfig({
  width: 'full',
  marginX: 'auto',
  padding: '$4',
  _web: {
    maxWidth: ACTION_SHEET_MAX_WIDTH,
    alignItems: 'unset', // fixes DatePicker to be able scroll on web
  },
});
