import { useMutation } from '@apollo/client';
import { merge } from 'lodash';
import { useCallback, useMemo } from 'react';

import { ICartEntry, ISanityImage } from '@rbi-ctg/menu';
import { DeliveryStatus } from 'generated/rbi-graphql';
import { useOrderStatus } from 'hooks/order-status';
import useIsSignUpAfterCart from 'hooks/use-is-sign-up-after-cart';
import {
  IPriceOrderMutationResponse,
  IPriceOrderMutationVariables,
  PriceOrderMutation,
} from 'remote/queries/order';
import { UserDetails } from 'state/auth/hooks/use-current-user';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOffersContext } from 'state/offers';
import { OrderStatus, useOrderContext } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';
import { buildPriceDeliveryInput, buildPriceOrderInput } from 'utils/cart';
import { computeTotalDiscountAmount } from 'utils/cart/helper';
import { isDelivery } from 'utils/service-mode';
import { PerformanceMarks, setMark } from 'utils/timing';

import { IPriceOrderParams, IUsePriceOrderParams } from './types';

// default order status polling interval, in ms
const DEFAULT_POLL_INTERVAL = 1000;

type MutationResponse = IPriceOrderMutationResponse;
type MutationVariables = IPriceOrderMutationVariables;

export const sanitizeSanityImage = (image: ISanityImage) => {
  // We're knowingly breaking the contract of ICartEntry and ISanityImage here
  // we can remove these once we update @rbi-ctg/menu ICartEntry and ISanityImage
  // to make these properties options
  // @ts-expect-error TS(2790) FIXME: The operand of a 'delete' operator must be optiona... Remove this comment to see the full error message
  delete image?.asset?.metadata;
};

export const sanitizeCart = (cartEntries: ICartEntry[]): ICartEntry[] => {
  cartEntries.forEach(cartEntry => {
    if (!cartEntry.children.length && cartEntry.image?.asset?.metadata) {
      sanitizeSanityImage(cartEntry.image);

      return;
    }

    if (cartEntry.image) {
      sanitizeSanityImage(cartEntry.image);
    }

    sanitizeCart(cartEntry.children);
  });

  return cartEntries;
};

export const usePriceOrder = ({
  cartEntries,
  orderStatusPollInterval = DEFAULT_POLL_INTERVAL,
}: IUsePriceOrderParams) => {
  const { updateDeliveryFees } = useServiceModeContext();
  const offers = useOffersContext();
  const { updateTipAmount, calculateCartTotalWithDiscount, quoteId } = useOrderContext();
  const isSignUpAfterCart = useIsSignUpAfterCart();
  const [fireMutation, mutationResult] = useMutation<MutationResponse, MutationVariables>(
    PriceOrderMutation,
    {
      context: { shouldNotBatch: true },
    }
  );
  const enablePaymentErrorContinue = useFlag(LaunchDarklyFlag.ENABLE_PAYMENT_ERROR_CONTINUE);
  const hideTipAmountCheckout = useFlag(LaunchDarklyFlag.HIDE_TIP_AMOUNT_CHECKOUT);

  const rbiOrderId = mutationResult.data ? mutationResult.data.priceOrder.rbiOrderId : null;

  const successStatuses = enablePaymentErrorContinue
    ? [OrderStatus.PRICE_SUCCESSFUL, OrderStatus.PAYMENT_ERROR, OrderStatus.VALIDATION_ERROR]
    : [OrderStatus.PRICE_SUCCESSFUL, OrderStatus.VALIDATION_ERROR];

  const isCartTotalNegative = useMemo(() => {
    const { isCartTotalNegative = false } = calculateCartTotalWithDiscount?.(cartEntries) || {};

    return isCartTotalNegative;
  }, [cartEntries, calculateCartTotalWithDiscount]);

  const queryResult = useOrderStatus({
    failureStatuses: {
      delivery: [DeliveryStatus.QUOTE_ERROR, DeliveryStatus.QUOTE_UNAVAILABLE],
      pos: OrderStatus.PRICE_ERROR,
      catering: OrderStatus.PRICE_ERROR,
    },
    onSuccess(rbiOrder) {
      const { selectedOffer } = offers;

      if (selectedOffer) {
        offers.validateOfferRedeemable({
          selectedOffer,
          rbiOrderId: rbiOrder.rbiOrderId,
        });
      }

      if (isDelivery(rbiOrder.cart.serviceMode)) {
        const otherDiscountAmount = computeTotalDiscountAmount(rbiOrder.cart.discounts);
        updateTipAmount({
          subTotalCents: hideTipAmountCheckout ? 0 : rbiOrder.cart.subTotalCents,
          otherDiscountAmount,
        });

        if (rbiOrder?.delivery) {
          const { totalFeeCents, itemizedFees } = rbiOrder.delivery;

          updateDeliveryFees({
            deliveryTotalFee: totalFeeCents ?? 0,
            fees: itemizedFees,
          });
        }
      }
      setMark(PerformanceMarks.PRICE_END);
    },
    orderStatusPollInterval,
    rbiOrderId,
    successStatuses: {
      delivery: DeliveryStatus.QUOTE_SUCCESSFUL,
      pos: successStatuses,
      catering: successStatuses,
    },
    skip: isCartTotalNegative,
  });

  const { orderStatus, serverOrder } = queryResult;

  const priceOrder = useCallback(
    (options: Omit<IPriceOrderParams, 'cartEntries' | 'redeemReward'>, user: UserDetails) => {
      if (!cartEntries.length || isSignUpAfterCart || isCartTotalNegative) {
        return;
      }

      const buildPriceOrderArgs = merge({ cartEntries: sanitizeCart(cartEntries) }, options);
      if (!buildPriceOrderArgs.isStoreAvailable) {
        return;
      }

      const delivery = buildPriceDeliveryInput(options, user, quoteId);
      const input = buildPriceOrderInput(buildPriceOrderArgs);

      setMark(PerformanceMarks.PRICE_START);

      return fireMutation({
        variables: {
          delivery,
          input,
        },
      });
    },
    [cartEntries, fireMutation, isSignUpAfterCart, isCartTotalNegative]
  );

  return {
    loading: mutationResult.loading || queryResult.loading,
    order: queryResult,
    orderStatus,
    priceOrder,
    priceOrderFailure: queryResult.failure,
    priceOrderSuccess: queryResult.success,
    priceResult: mutationResult,
    rbiOrderId,
    serverOrder,
  };
};
