import { useCallback } from 'react';

import { MenuObject } from '@rbi-ctg/menu';
import { useDayPartContext } from 'state/day-part';
import { DAY_PART_SELECTIONS } from 'state/main-menu/types';
import { useStoreContext } from 'state/store';
import { getMenuItemDayParts, isAvailableForActiveDayParts } from 'utils/availability';
import { findBurgersForBreakfastDayPart } from 'utils/daypart';

const useIsMenuDataAvailableByDayPart = (): ((menuData: MenuObject) => boolean) => {
  const { activeDayParts, dayParts } = useDayPartContext();
  const store = useStoreContext();
  const hasBurgersForBreakfast = store?.store?.hasBurgersForBreakfast;

  return useCallback(
    (menuData: MenuObject) => {
      // Curently TH doesn't have day parts configured.
      // So we must check for day parts before we try to filter the menu.
      if (!dayParts?.length) {
        return true;
      }

      const menuDataDayParts = getMenuItemDayParts(menuData);
      const burgersForBreakfastDayPart = findBurgersForBreakfastDayPart(activeDayParts);

      // Burgers for Breakfast
      if (
        burgersForBreakfastDayPart &&
        menuDataDayParts.includes(DAY_PART_SELECTIONS.BURGERS_FOR_BREAKFAST.toLowerCase())
      ) {
        return !!hasBurgersForBreakfast;
      }
      // Regular
      return isAvailableForActiveDayParts({ activeDayParts, menuData });
    },
    [activeDayParts, dayParts, hasBurgersForBreakfast]
  );
};

export default useIsMenuDataAvailableByDayPart;
