import { Box } from '@fhs-legacy/universal-components';

export const FormContainer = Box.withConfig({
  marginTop: 2,
  marginX: 2,
});

export const LabelContainer = Box.withConfig({
  marginLeft: 3,
  alignItems: 'center',
  maxWidth: '100%',
  paddingRight: '$5',
});
