import { Icon } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

import { IFavoriteIcon } from './types';

export const FavoriteIcon = ({ isFavorite, disabled, loading }: IFavoriteIcon) => {
  const color =
    !isFavorite || disabled || loading ? theme.token('icon-disabled') : theme.token('icon-active');

  return <Icon variant="favoriteFill" color={color} />;
};
