import { Link } from 'expo-router';
import { StyleSheet, View } from 'react-native';

import { Button, IconAdd, tokens } from '@fhs/ui';

export function CartMissingAnything() {
  return (
    <View style={styles.container}>
      <Link href="/v2/menu">
        <Button type="unstyled" style={styles.button}>
          <Button.Icon>
            <IconAdd color={tokens.colors.$houseDark} />
          </Button.Icon>
          <Button.Text>Missing Anything?</Button.Text>
        </Button>
      </Link>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 69,
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: tokens.colors.$blackOpacity04,
  },

  button: {
    width: '100%',
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: tokens.colors.$blackOpacity10,
  },
});
