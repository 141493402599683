import { Slot, router } from 'expo-router';
import React from 'react';

import { AuthContext } from '@fhs-legacy/frontend/src/state/auth';

export default class AuthLayout extends React.Component {
  static contextType = AuthContext;

  componentDidMount(): void {
    if (this.context.isAuthenticated) {
      router.canGoBack() ? router.back() : router.replace('/');
    }
  }

  render() {
    return <Slot />;
  }
}
