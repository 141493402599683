import { Box, makeUclComponent } from '@fhs-legacy/universal-components';
import { Accordion, AccordionItem } from 'components/accordion';

export const Wrapper = Box.withConfig({
  borderWidth: 1,
  borderColor: Styles.color.grey.four,
  borderStyle: 'solid',
  borderTopLeftRadius: Styles.borderRadius,
  borderTopRightRadius: Styles.borderRadius,
  borderBottomRightRadius: Styles.borderRadius,
  borderBottomLeftRadius: Styles.borderRadius,
  backgroundColor: Styles.color.cardBackground,
});

export const InnerAccordionItem = Box.withConfig({
  borderTopWidth: 1,
  borderTopColor: Styles.color.grey.four,
  borderTopStyle: 'solid',
});

export const StyledAccordionItem = makeUclComponent(AccordionItem);

export const StyledAccordion = makeUclComponent(Accordion);
