import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconFavoriteFill = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M12.673 20.843a1 1 0 0 1-1.344-.003l-.779-.71C5.4 15.46 2 12.38 2 8.6c0-3.08 2.42-5.5 5.5-5.5a5.97 5.97 0 0 1 3.654 1.28 1.4 1.4 0 0 0 1.692 0A5.97 5.97 0 0 1 16.5 3.1c3.08 0 5.5 2.42 5.5 5.5 0 3.78-3.4 6.86-8.55 11.54l-.777.703Z"
      />
    </Svg>
  );
};
