import * as Linking from 'expo-linking';
import { useCallback } from 'react';
import { Platform } from 'react-native';

import { useBoxRef } from '@fhs/utils';
import { useCRMEventsContext } from 'state/crm-events';
import { EventTypes } from 'state/crm-events/constants';
import logger from 'utils/logger';
import {
  appTrackingTransparency,
  enableLocation,
  enablePush,
  openPushSetting,
} from 'utils/permissions';
import { promptReview } from 'utils/review/prompt-review';

export type OsAction = 'push' | 'location' | 'review' | 'att' | 'pushSetting';

function sanitizePath(pathStr: string): null | string {
  const parsed = Linking.parse(pathStr);

  if (!parsed.path) {
    return null;
  }

  return (
    parsed.path
      .trim()
      .toLowerCase()
      // strip any beginning or ending slashes
      .replace(/(^\/+|\/+$)/g, '')
  );
}

function getOsAction(pathStr: string): OsAction | null {
  const sanitizedPath = sanitizePath(pathStr);

  if (!sanitizedPath || !sanitizedPath.startsWith('os/')) {
    return null;
  }

  const pathSegments = sanitizedPath.split('/');
  // We only support paths in *two* segments, for example
  // os/ACTION we will not process urls with more or fewer
  // segments such as os/ACTION/subsection
  if (pathSegments.length !== 2) {
    return null;
  }

  const [_osSegment, osActionSegment] = pathSegments;

  switch (osActionSegment) {
    case 'push': {
      return 'push';
    }

    case 'location': {
      return 'location';
    }

    case 'review': {
      return 'review';
    }

    case 'att': {
      return 'att';
    }

    // this is 'pushsetting' instead of 'pushSetting' because the sanitizer forces everything to lower-case
    // we still want the action name to be camel case though.
    case 'pushsetting': {
      return 'pushSetting';
    }

    default: {
      return null;
    }
  }
}

function isValidOsPath(pathStr: string): boolean {
  try {
    return Boolean(getOsAction(pathStr));
  } catch (error) {
    logger.warn(`Error checking Os path validity of path: ${pathStr} ${error}`);
    return false;
  }
}

async function doOsAction(
  action: OsAction,
  logRBIEvent: ReturnType<typeof useCRMEventsContext>['logRBIEvent']
): Promise<void> {
  if (Platform.OS === 'web') {
    return;
  }

  switch (action) {
    case 'push': {
      try {
        const response = await enablePush();

        logRBIEvent({
          name: 'Push Notifications Response',
          type: EventTypes.Other,
          attributes: {
            response: response?.status ?? 'undetermined',
          },
        });
      } catch (error) {
        logger.warn(`Error enabling push: ${error}`);
      }

      return;
    }

    case 'location': {
      try {
        await enableLocation();
      } catch (error) {
        logger.warn(`Error enabling location: ${error}`);
      }

      return;
    }

    case 'review': {
      try {
        await promptReview();

        logRBIEvent({
          name: 'App Review Pop Up',
          type: EventTypes.Other,
          attributes: {},
        });
      } catch (error) {
        logger.warn(`Error prompting user for app review: ${error}`);
      }

      return;
    }

    case 'att': {
      if (Platform.OS !== 'ios') {
        return;
      }

      try {
        await appTrackingTransparency();
      } catch (error) {
        logger.warn(`Error requesting user authorization for App Tracking Transparency: ${error}`);
      }

      return;
    }

    case 'pushSetting': {
      try {
        await openPushSetting();
      } catch (error) {
        logger.warn(`Error opening user's phone settings: ${error}`);
      }

      return;
    }
  }
}

export function useMaybeOsActionHandler() {
  // Wrapping this logRBIEvent in a ref because it is referentially unstable and we are using it in an effect.
  // We don't want to trigger the effect when it changes.
  const { logRBIEvent } = useCRMEventsContext();
  const logRBIEventBoxRef = useBoxRef(logRBIEvent);

  // returned boolean is whether or not the path was handled or not
  return useCallback(
    async (path: string): Promise<boolean> => {
      if (!isValidOsPath(path)) {
        return false;
      }

      const action = getOsAction(path);
      if (!action) {
        return false;
      }

      await doOsAction(action, logRBIEventBoxRef.current);

      return true;
    },
    [logRBIEventBoxRef]
  );
}
