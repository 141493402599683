import { useEffect } from 'react';

import { IUseAlertOrderCateringMinimum } from './types';

const useAlertOrderCateringMinimum = ({
  calculateCartTotal,
  setCartCateringPriceTooLow,
  isCatering,
  checkoutCateringPriceMinimum = 0,
  cartEntries,
}: IUseAlertOrderCateringMinimum) => {
  useEffect(() => {
    if (!isCatering) {
      setCartCateringPriceTooLow(false);
      return;
    }

    setCartCateringPriceTooLow(calculateCartTotal() < checkoutCateringPriceMinimum);
  }, [
    calculateCartTotal,
    isCatering,
    checkoutCateringPriceMinimum,
    setCartCateringPriceTooLow,
    cartEntries,
  ]);
};

export default useAlertOrderCateringMinimum;
