const DEGREES_PER_PI = 180;
const EARTH_RADIUS_METERS = 6378137;
const METERS_PER_MILE = 1609.34;

const isNil = (value: any): value is null | undefined => value == null;

interface ICoordinates {
  latitude: number;
  longitude: number;
}

interface ILocation {
  lat: number;
  lng: number;
}

interface ICoordinateAtDistanceAndBearing {
  lat: number;
  lng: number;
  bearing: number;
  distance: number;
}

interface IBoundCoordinates {
  distance?: number;
  lat?: number;
  lng?: number;
}

interface IMinMaxBounds {
  minLat?: number;
  maxLat?: number;
  minLng?: number;
  maxLng?: number;
}

enum CardinalDirection {
  NORTH = 0,
  EAST = 90,
  SOUTH = 180,
  WEST = 270,
}

/**
 * The Geography class provides a set of convenience methods for working with Earth's geography.
 */
export class Geography {
  /**
   * Given a starting point lat/lng, a bearing in degrees and distance,
   * calculates the resulting lat/lng using reverse haversine
   *
   * @param {ICoordinateAtDistanceAndBearing} options
   * * bearing - the angle bearing for the desired, new point.
   * * distance - the distance in meters to the desired, new point.
   * * lat - the latitude of starting point.
   * * lng - the longitude of starting point.
   *
   * @returns {ILocation}
   * The lat/lng coordinates in degrees, calculated from given parameters.
   */
  public static coordinateAtDistanceAndBearing({
    bearing,
    distance,
    lat,
    lng,
  }: ICoordinateAtDistanceAndBearing): ILocation {
    const lat1 = this.toRadians(lat);
    const lng1 = this.toRadians(lng);
    const angularDistance = distance / EARTH_RADIUS_METERS;

    const lat2 = Math.asin(
      Math.sin(lat1) * Math.cos(angularDistance) +
        Math.cos(lat1) * Math.sin(angularDistance) * Math.cos(bearing)
    );

    const lng2 =
      lng1 +
      Math.atan2(
        Math.sin(bearing) * Math.sin(angularDistance) * Math.cos(lat1),
        Math.cos(angularDistance) - Math.sin(lat1) * Math.sin(lat2)
      );

    return { lat: this.toDegrees(lat2), lng: this.toDegrees(lng2) };
  }

  /**
   * Compute the distance between two sets of coordinates, defined using latitude and longitude.
   * By default, the distance is computed in miles.
   *
   * See https://www.movable-type.co.uk/scripts/latlong.html
   *
   * @param {ICoordinates} point1
   * The first set of coordinates.
   *
   * @param {ICoordinates} point2
   * The second set of coordinates.
   *
   * @param {boolean} inMiles
   * Optionally return the distance in miles.
   *
   * @returns {number}
   * The distance between the two sets of coordinates.
   */
  public static coordinateDistance(
    point1: ICoordinates,
    point2: ICoordinates,
    inMiles = true
  ): number {
    // convert the latitude values to radians
    const latitude1 = this.toRadians(point1.latitude);
    const latitude2 = this.toRadians(point2.latitude);

    // compute the delta of latitude and longitude, in radians
    const latitudeDelta = this.toRadians(point2.latitude - point1.latitude);
    const longitudeDelta = this.toRadians(point2.longitude - point1.longitude);

    const a: number =
      Math.sin(latitudeDelta / 2) ** 2 +
      Math.cos(latitude1) * Math.cos(latitude2) * Math.sin(longitudeDelta / 2) ** 2;

    const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    /* eslint-enable @typescript-eslint/no-magic-numbers */

    const distance: number = EARTH_RADIUS_METERS * c;
    return inMiles ? this.toMiles(distance) : distance;
  }

  /**
   * Given a starting point latitude/longitude and a distance,
   * calculates the minimum and maximum latitude/longitude
   *
   * @param {IBoundCoordinates} coordinates
   * * distance - the distance in meters to the desired, new point.
   * * lat - the latitude of starting point.
   * * lng - the longitude of starting point.
   *
   * @returns {IMinMaxBounds}
   * The minimum and maximum latitude/longitude
   */
  public static getBounds(coordinates: IBoundCoordinates): IMinMaxBounds | null {
    const { distance, lat, lng } = coordinates;

    if (isNil(lat) || isNil(lng) || isNil(distance)) {
      return null;
    }

    const { lat: maxLat } = this.coordinateAtDistanceAndBearing({
      bearing: this.toRadians(CardinalDirection.NORTH),
      distance,
      lat,
      lng,
    });
    const { lng: maxLng } = this.coordinateAtDistanceAndBearing({
      bearing: this.toRadians(CardinalDirection.EAST),
      distance,
      lat,
      lng,
    });
    const { lat: minLat } = this.coordinateAtDistanceAndBearing({
      bearing: this.toRadians(CardinalDirection.SOUTH),
      distance,
      lat,
      lng,
    });
    const { lng: minLng } = this.coordinateAtDistanceAndBearing({
      bearing: this.toRadians(CardinalDirection.WEST),
      distance,
      lat,
      lng,
    });

    return { maxLat, maxLng, minLat, minLng };
  }

  /**
   * Convert radians to degrees.
   *
   * @param {number} radians
   * The value, in radians, to convert to degrees.
   *
   * @returns {number}
   * The value, in degrees, converted from degrees.
   */
  public static toDegrees(radians: number): number {
    return radians * (DEGREES_PER_PI / Math.PI);
  }

  /**
   * Convert meters to miles.
   *
   * @param {number} meters
   * The value, in meters to convert to miles.
   *
   * @returns {number}
   * The value, in meters converted to miles.
   */
  public static toMiles(meters: number): number {
    return meters / METERS_PER_MILE;
  }

  /**
   * Convert degrees to radians.
   *
   * @param {number} degrees
   * The value, in degrees to convert to radians.
   *
   * @returns {number}
   * The value, in degrees converted to radians.
   */
  public static toRadians(degrees: number): number {
    return degrees * (Math.PI / DEGREES_PER_PI);
  }
}
