import { useCallback, useMemo } from 'react';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  StoreCardButtonOptions,
  StoreCardButtonOptionsFlagVariation,
  defaultStoreCardButtonOptionsOrder,
} from 'state/launchdarkly/variations';

const convertFlagValueToArray = (
  flagVariation: StoreCardButtonOptionsFlagVariation
): StoreCardButtonOptions[] => Object.values(flagVariation);

/**
 * @name useStoreCardButtonOptions
 * @description handles converting the raw JSON object from LD into an array of
 * button options & handles additional logic for whether or not they are valid
 */
export default function useStoreCardButtonOptions() {
  const storeCardButtonOptionsFlagValue = useFlag<StoreCardButtonOptionsFlagVariation>(
    LaunchDarklyFlag.STORE_CARD_BUTTON_OPTIONS
  );

  const enableOffers = useFlag(LaunchDarklyFlag.ENABLE_OFFERS);

  const filterOptions = useCallback(
    (options: StoreCardButtonOptions[]) => {
      return options.filter((option: StoreCardButtonOptions) => {
        const isValidOption = Object.values(StoreCardButtonOptions).includes(option);

        switch (option) {
          case StoreCardButtonOptions.OFFERS:
            return isValidOption && enableOffers;
          default:
            return isValidOption;
        }
      });
    },
    [enableOffers]
  );

  const storeCardButtonOptions = useMemo(() => {
    const options = storeCardButtonOptionsFlagValue
      ? convertFlagValueToArray(storeCardButtonOptionsFlagValue)
      : defaultStoreCardButtonOptionsOrder;

    return filterOptions(options);
  }, [storeCardButtonOptionsFlagValue, filterOptions]);

  return storeCardButtonOptions;
}
