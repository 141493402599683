import React from 'react';

export type DatetimeShowProps = (
  | {
      after: Date;
      before?: Date;
    }
  | { after?: Date; before: Date }
) & {
  default?: React.ReactNode;
  children: React.ReactNode;
};

/**
 * Renders children if now datetime is after (or equal) to given after prop datetime and before (or equal) to given before prop datetime.
 * otherwise renders default or null.
 * DatetimeShow only compares now datetime with prop datetime on render (we do not trigger a rerender when now datetime rolls past prop datetime).
 */
export function DatetimeShow(props: DatetimeShowProps) {
  const now = Date.now();
  const isNowAfterPropDate = props.after ? now >= props.after.getTime() : true;
  const isNowBeforePropDate = props.before ? now <= props.before.getTime() : true;

  if (isNowAfterPropDate && isNowBeforePropDate) {
    return props.children;
  }

  return props.default ?? null;
}
