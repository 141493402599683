import React from 'react';

import { Spinner } from '@fhs-legacy/universal-components';

import { ILoadingAnimationProps } from './interfaces';

const LoadingAnimation = (props: ILoadingAnimationProps) => {
  return <Spinner {...props} />;
};

export default LoadingAnimation;
