import { useState } from 'react';

import { BottomDrawer } from './bottom-drawer';
import { DialogStateReturn } from './types';

export const useBottomDrawerUIState = () => {
  const [visible, setVisible] = useState(false);
  const dialogState: DialogStateReturn = {
    visible,
    show: () => setVisible(true),
    hide: () => setVisible(false),
    toggle: () => setVisible(current => !current),
  };
  return {
    BottomDrawer,
    dialogState,
  };
};
