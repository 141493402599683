import { TransactionReferenceIdType } from 'enums/loyalty';
import { ITransactionFragment } from 'generated/graphql-gateway';

interface IParsedLoyaltyTransaction {
  channel: string;
  bonusPointsEarned: number;
  pointsEarned: number;
  pointsUsed: number;
  pointsDeducted: number | null | undefined;
  totalPayment: number;
  timeStamp: string;
  rbiOrderId: string | undefined;
  hasRewards: boolean;
}

/**
 *
 * Parses loyalty api transaction data to be used for rewards history view.
 * Can be split to brand specific files if need be in the future.
 *
 */
export const parseLoyaltyTransaction = ({
  transactionDetails,
  createdAt: timeStamp,
  channel,
  bonusPointsEarned,
  pointsUsed,
  pointsEarned,
  pointsDeducted,
  additionalReferenceIds,
  hasRewards,
}: ITransactionFragment): IParsedLoyaltyTransaction => {
  const orderReference = additionalReferenceIds?.find(
    ({ type }) => type === TransactionReferenceIdType.RBIOrderId
  );
  const totalPayment =
    transactionDetails?.payments.reduce((acc, { amount }) => acc + amount, 0) ?? 0;

  return {
    hasRewards,
    channel: channel.toUpperCase(),
    bonusPointsEarned,
    pointsEarned,
    pointsUsed,
    pointsDeducted,
    totalPayment,
    timeStamp,
    rbiOrderId: orderReference?.id,
  };
};
