import { IFeatureFooterQuery, useFeatureFooterQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

interface IUseFeatureFooterValue {
  featureFooterLoading: boolean;
  featureFooter: IFeatureFooterQuery['FeatureFooter'] | null;
}

export const useFeatureFooter = (): IUseFeatureFooterValue => {
  const { featureFooterId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useFeatureFooterQuery({
    variables: { featureFooterId },
    skip: !featureFooterId,
  });

  const featureFooter = data?.FeatureFooter ?? null;
  const featureFooterLoading = featureIdsLoading || loading;

  return {
    featureFooterLoading,
    featureFooter,
  };
};
