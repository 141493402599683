import { ICartEntry, IComboSlot, IComboSlotSelections, IItem, IPicker } from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import {
  IUserSelection,
  Product,
  ProductMenuObject,
  SetDefaultSelectionsArgs,
  UserSelectionComboSlot,
  UserSelectionModifier,
  UserSelectionModifierSlotMap,
} from 'state/product-wizard/types';
import { CartEntryType } from 'utils/cart/types';
import { IModifierSelectionAction, getSelectionsFromMenuData } from 'utils/wizard';

import { ENABLE_MAIN_ITEM_DETAILED_CUSTOMIZATION } from './constants';

export class ProductWizardUtils {
  private static SLOT_KEY = 'slot';

  public static SetDefaultSelections({
    menuObject,
    pickerSelectionOverrides,
    cartEntryOverrides,
  }: SetDefaultSelectionsArgs): IUserSelection {
    const currentSelections = getSelectionsFromMenuData({
      data: menuObject,
      pickerSelectionOverrides,
      selectionsEntry: cartEntryOverrides,
    });

    const selectedProduct = ProductWizardUtils.ComputeSelectedOption(
      menuObject,
      currentSelections.pickerSelections
    );
    const comboSlotSelections = ProductWizardUtils.ComputeComboSlotsSelections(
      selectedProduct,
      currentSelections.comboSlotSelections || {}
    );

    const defaultModifiers: UserSelectionModifier = {};
    const cartEntryModifiersMap = cartEntryOverrides
      ? ProductWizardUtils.GetCartEntryModifiersSelectionsMap(cartEntryOverrides)
      : {};
    comboSlotSelections.forEach(({ selectedItem }, slotKey) => {
      if (selectedItem) {
        const slotCartEntryModifiers = cartEntryOverrides
          ? currentSelections.modifierSelections?.filter(
              selection =>
                selection.item?._id === selectedItem._id &&
                !!cartEntryModifiersMap[
                  ProductWizardUtils.GetModifierOptionKey(
                    selection.option._key,
                    selection.modifier._key
                  )
                ]
            )
          : [];
        defaultModifiers[slotKey] = ProductWizardUtils.ComputeDefaultModifiersSelections(
          selectedItem,
          slotCartEntryModifiers
        );
      }
    });

    return {
      pickerAspects: currentSelections.pickerSelections,
      comboSlot: comboSlotSelections,
      modifiers: defaultModifiers,
    };
  }

  public static GetSummarySlotKey(index: number) {
    return `${ProductWizardUtils.SLOT_KEY}_${index}`;
  }

  private static GetSelectionsFromItem(item: IItem): IItem[] {
    if (!ENABLE_MAIN_ITEM_DETAILED_CUSTOMIZATION) {
      return [item];
    }

    // TODO - GST-3340 Sauce Selection Customization: Remove this feature after content team has implemented detailed customization on their side.
    // When ENABLE_MAIN_ITEM_DETAILED_CUSTOMIZATION is truthy then we had to do two tasks:
    // 1: Remove options from main item.
    // 2: Create virtual items used to show item itemOptions separated from the item. This new kind of items are created from the item and an itemOption element.They need to have the same _id value as the item so everything on the order keeps working as always.
    const mainItemSelection = {
      ...item,
      options: [],
    };
    const virtualItemSelections: IItem[] = (item.options || []).map(itemOption => ({
      image: item.image,
      nutrition: item.nutrition,
      nutritionWithModifiers: item.nutritionWithModifiers,
      vendorConfigs: item.vendorConfigs,
      redeemable: item.redeemable,
      _id: item._id,
      _type: item._type,
      options: [itemOption],
      name: {
        locale: itemOption.name.locale,
      },
      isVirtualItem: true,
    }));

    return [mainItemSelection, ...virtualItemSelections];
  }

  public static ComputeComboSlotsSelections(
    selectedProduct: Product,
    comboSlotSelections: IComboSlotSelections | undefined
  ): UserSelectionComboSlot {
    const result: UserSelectionComboSlot = new Map();
    if (!selectedProduct) {
      return new Map();
    }
    switch (selectedProduct._type) {
      case MenuObjectTypes.ITEM:
        const selectionsFromItem = ProductWizardUtils.GetSelectionsFromItem(selectedProduct);
        selectionsFromItem.forEach((selectedItem, idx) => {
          result.set(ProductWizardUtils.GetSummarySlotKey(idx), {
            comboSlot: null,
            selectedItem,
          });
        });
        break;
      case MenuObjectTypes.COMBO:
        let slotCounter = 0;
        const { mainItem } = selectedProduct;
        if (mainItem && !mainItem.isDummyItem) {
          const selectionsFromItem = ProductWizardUtils.GetSelectionsFromItem(mainItem);
          selectionsFromItem.forEach(selectedItem => {
            result.set(ProductWizardUtils.GetSummarySlotKey(slotCounter), {
              comboSlot: null,
              selectedItem,
            });
            slotCounter++;
          });
        }

        if (!comboSlotSelections) {
          break;
        }

        const visibleComboSlots = selectedProduct.options.filter(opt => opt.uiPattern !== 'hidden');
        visibleComboSlots.forEach(comboSlot => {
          if (ProductWizardUtils.IsComboSlotLockedOrCollapsed(comboSlot)) {
            result.set(ProductWizardUtils.GetSummarySlotKey(slotCounter), {
              comboSlot,
              selectedItem: null,
            });
            slotCounter++;
          } else {
            const selections = comboSlotSelections[comboSlot._id]?.selections;
            if (!selections.length) {
              if (comboSlot.options[0]?.option._type === MenuObjectTypes.ITEM) {
                result.set(ProductWizardUtils.GetSummarySlotKey(slotCounter), {
                  comboSlot,
                  selectedItem: comboSlot.options[0].option,
                });
                slotCounter++;
              }
            } else {
              selections.forEach(selection => {
                for (let i = 0; i < selection.quantity; i++) {
                  if (selection.option.option._type === MenuObjectTypes.ITEM) {
                    result.set(ProductWizardUtils.GetSummarySlotKey(slotCounter), {
                      comboSlot,
                      selectedItem: selection.option.option,
                    });
                    slotCounter++;
                  }
                }
              });
            }
          }
        });
        break;
      default:
        break;
    }
    return result;
  }

  public static ComputeDefaultModifiersSelections(
    selectedItem: IItem,
    cartEntryModifiers: IModifierSelectionAction[] = []
  ): UserSelectionModifierSlotMap {
    const { validatedItem, modifierKeysWithoutDefaults } =
      ProductWizardUtils.ValidateAndCompleteModifiersDefaults(selectedItem);
    const sanityDefaultsMap = (
      getSelectionsFromMenuData({ data: selectedItem })?.modifierSelections || []
    ).reduce(
      (map, selectionAction) => ({
        ...map,
        [selectionAction.modifier._key]: !!selectionAction.modifier.default,
      }),
      {} as Record<string, boolean>
    );

    const selectionsFromMenu = getSelectionsFromMenuData({ data: validatedItem });
    const defaultSlotMap = ProductWizardUtils.TransformModifierSelectionActionsToSlotMap(
      selectionsFromMenu?.modifierSelections || [],
      sanityDefaultsMap,
      selectionAction => {
        return (
          !modifierKeysWithoutDefaults[selectionAction.option._key] &&
          !!selectionAction.modifier.default
        );
      }
    );
    const cartEntrySlotMap = ProductWizardUtils.TransformModifierSelectionActionsToSlotMap(
      cartEntryModifiers,
      sanityDefaultsMap,
      () => false
    );
    const mergedModifiersSlotMap: UserSelectionModifierSlotMap = {};
    Object.keys(defaultSlotMap).forEach(modifierKey => {
      mergedModifiersSlotMap[modifierKey] =
        cartEntrySlotMap[modifierKey] || defaultSlotMap[modifierKey];
    });
    return mergedModifiersSlotMap;
  }

  private static TransformModifierSelectionActionsToSlotMap(
    modifierSelections: IModifierSelectionAction[],
    sanityDefaultsMap: Record<string, boolean>,
    isSanityDefaultSelectionFn: (selectionAction: IModifierSelectionAction) => boolean
  ) {
    const slotMap: UserSelectionModifierSlotMap = {};
    modifierSelections.forEach(selectionAction => {
      slotMap[selectionAction.option._key] = {
        ...slotMap[selectionAction.option._key],
        [selectionAction.modifier._key]: {
          ...selectionAction,
          modifier: {
            ...selectionAction.modifier,
            default: sanityDefaultsMap[selectionAction.modifier._key],
          },
          isSanityDefaultSelection: isSanityDefaultSelectionFn(selectionAction),
        },
      };
    });
    return slotMap;
  }

  private static GetCartEntryModifiersSelectionsMap(cartEntry: ICartEntry): Record<string, string> {
    return (function recursiveFn(currentItem): Record<string, string> {
      return (currentItem.children || []).reduce((modifiersSelectionsMap, child) => {
        if (child.type === CartEntryType.itemOptionModifier) {
          return {
            ...modifiersSelectionsMap,
            [ProductWizardUtils.GetModifierOptionKey(currentItem._id, child._id)]: true,
          };
        }
        return { ...modifiersSelectionsMap, ...recursiveFn(child) };
      }, {});
    })(cartEntry);
  }

  public static GetModifierOptionKey(optionKey: string, modifierKey: string): string {
    return `${optionKey}/${modifierKey}`;
  }

  // If type picker, resolves a picker's options to a single menu item
  // Else, returns item passed - an item or combo type
  public static ComputeSelectedOption(
    item: ProductMenuObject,
    pickerSelections: Record<string, string> | undefined
  ): Product {
    if (item._type === 'combo' || item._type === 'item') {
      return item;
    }
    const { options } = item;
    const pickerValues = Object.values(pickerSelections || {});
    // if no selection chose the first as default
    if (!pickerSelections || pickerValues.length < 1) {
      return options && (options[0]?.option as Product);
    }

    const resolution = options.find(itemOpt => {
      return itemOpt.pickerItemMappings.every(mapping => {
        return pickerSelections[mapping.pickerAspect._id] === mapping.pickerAspectValueIdentifier;
      });
    });

    return resolution?.option as Product;
  }

  public static IsCustomizable(option: IItem | IComboSlot | IPicker): boolean {
    switch (option?._type) {
      case MenuObjectTypes.ITEM:
        const hasModifiers = option.options?.length > 0;
        return hasModifiers && option.options.some(itemOption => itemOption.options?.length > 1);
      case MenuObjectTypes.COMBO_SLOT:
        return option.uiPattern === 'show' && option.options?.length > 1;
      default:
        return false;
    }
  }

  public static IsComboSlotLockedOrCollapsed(comboSlot: IComboSlot) {
    return comboSlot.uiPattern === 'collapsed' || comboSlot.uiPattern === 'locked';
  }

  // Private functions
  private static ValidateAndCompleteModifiersDefaults(item: IItem) {
    const modifierKeysWithoutDefaults: Record<string, boolean> = {};
    const modifiersWithDefaults = (item.options || []).map(modifier => {
      const modifierHasDefault = (modifier.options || []).some(opt => opt.default);
      if (!modifier.options.length || modifierHasDefault) {
        return modifier;
      }
      modifierKeysWithoutDefaults[modifier._key] = true;
      const [firstModifierOption, ...restModifierOptions] = modifier.options;
      return {
        ...modifier,
        options: [{ ...firstModifierOption, default: true }, ...restModifierOptions],
      };
    });

    return {
      validatedItem: {
        ...item,
        options: modifiersWithDefaults,
      },
      modifierKeysWithoutDefaults,
    };
  }

  public static GetPickerAspectExclusions(menuObject: ProductMenuObject) {
    if (menuObject._type !== MenuObjectTypes.PICKER) {
      return;
    }
    const exclusions = new Set<string>();
    (menuObject.pickerAspectItemOptionMappings || []).forEach(mapping => {
      mapping.options?.forEach(mappingOption => {
        exclusions.add(mappingOption.value);
      });
    });
    return exclusions;
  }
}
