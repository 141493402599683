import { useFocusEffect } from 'expo-router';
import { useCallback } from 'react';

const usePreventBodyScroll = (disableBodyScroll: boolean | undefined = false) => {
  useFocusEffect(
    useCallback(() => {
      if (disableBodyScroll) {
        document.documentElement.style.overflow = 'hidden';
        document.body.style.overflow = 'hidden';
        return;
      }

      return () => {
        if (disableBodyScroll) {
          document.documentElement.style.overflow = 'auto';
          document.body.style.overflow = 'auto';
        }
      };
    }, [disableBodyScroll])
  );
};

export default usePreventBodyScroll;
