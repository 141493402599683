import { useCallback, useMemo, useRef } from 'react';
import {
  type LayoutChangeEvent,
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  type ScrollView,
} from 'react-native';

/* Used for scrolling an item to the first visible position in the scroll view */
export function useScrollIntoFirstPositionInView(
  direction: 'horizontal' | 'vertical' = 'horizontal'
) {
  const scrollerRef = useRef<ScrollView>(null);
  const scrollerLayoutRef = useRef({ x: 0, y: 0, width: 0, height: 0 });
  const scrollerContentOffsetRef = useRef({ x: 0, y: 0 });

  const handleScrollerLayout = useCallback((event: LayoutChangeEvent) => {
    scrollerLayoutRef.current = event.nativeEvent.layout;
  }, []);
  const handleScrollerScroll = useCallback((event: NativeSyntheticEvent<NativeScrollEvent>) => {
    scrollerContentOffsetRef.current = event.nativeEvent.contentOffset;
  }, []);

  const scrollIntoFirstPositionInView = useCallback(
    ({ position, offset }: { position: number; offset?: number }) => {
      const scrollOffset = offset ?? 0;

      const targetPosition = Math.ceil(position - scrollOffset);
      scrollerRef.current?.scrollTo(
        direction === 'horizontal' ? { x: targetPosition } : { y: targetPosition }
      );
      return;
    },
    [direction]
  );

  return useMemo(
    () => ({
      scrollerRef,
      scrollIntoFirstPositionInView,
      handleScrollerLayout,
      handleScrollerScroll,
    }),
    [handleScrollerLayout, handleScrollerScroll, scrollIntoFirstPositionInView]
  );
}
