import { useIntl } from 'react-intl';

import { Box } from '@fhs-legacy/universal-components';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { readableTimeInterval } from 'utils/restaurant';
import { TWELVE_HOUR_TIME_PARSE_FORMAT } from 'utils/restaurant/constants';

import { InnerHeader, InnerText, StoreHourContainer } from './styled';
import { IHoursProps } from './types';

export const Hours = ({ title, hours }: IHoursProps) => {
  const { formatMessage } = useIntl();
  const timeFormatConfig = useConfigValue({
    key: 'timeFormat',
    defaultValue: TWELVE_HOUR_TIME_PARSE_FORMAT,
  });
  const hoursOfOperation = readableTimeInterval({
    fallbackMessage: formatMessage({ id: 'hoursOfOperationFallbackMessage' }),
    open24HoursMessage: formatMessage({ id: 'open24Hours' }),
    timeFormat: timeFormatConfig,
  });

  const storeHoursSummary: [string, string][] = [
    ['monday', 'mon'],
    ['tuesday', 'tue'],
    ['wednesday', 'wed'],
    ['thursday', 'thr'],
    ['friday', 'fri'],
    ['saturday', 'sat'],
    ['sunday', 'sun'],
  ];

  if (!hours) {
    return null;
  }

  return (
    <Box>
      <InnerHeader variant="headerThree" marginY="$2">
        {title}
      </InnerHeader>
      {storeHoursSummary.map(([messageId, abbreviatedDay]) => {
        const openingHours = hours[`${abbreviatedDay}Open`];
        const closingHours = hours[`${abbreviatedDay}Close`];
        return (
          <StoreHourContainer key={messageId}>
            <InnerText>{formatMessage({ id: messageId })}</InnerText>
            <InnerText>{hoursOfOperation(openingHours, closingHours)}</InnerText>
          </StoreHourContainer>
        );
      })}
    </Box>
  );
};
