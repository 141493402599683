import { router } from 'expo-router';
import { useCallback } from 'react';

import { ICartEntry } from '@rbi-ctg/menu';
import { useRedeemReward } from 'components/cart-item/redeem-reward/use-redeem-reward/use-redeem-reward';
import { usePathname } from 'hooks/use-pathname';
import { useCartContext } from 'state/cart';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { useGetAvailableRewards } from 'state/loyalty/hooks/use-get-available-rewards';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { useOffersContext } from 'state/offers';
import { useOrderContext } from 'state/order';
import { routes } from 'utils/routing';

enum IncrementorActions {
  Increment = 'increment',
  Decrement = 'decrement',
}

export const useCartItemEditingTools = ({ item }: { item: ICartEntry }) => {
  const { confirmRemoveItemFromCart, updateItemsQuantity, cartEntries, syncEntries } =
    useCartContext();
  const { isSelectedOfferCartEntry } = useOffersContext();
  const { setCartIdEditing } = useOrderContext();
  const pathname = usePathname();
  const { getAvailableRewardFromCartEntry } = useGetAvailableRewards();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser } = useLoyaltyUser();
  const { incentiveNotInMenu } = useRedeemReward(item);
  const dispatch = useAppDispatch();
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);

  const handleEditItem = () => {
    // If its an extra take them to the extras modal
    if (item.isExtra) {
      return router.navigate(`${routes.cart}/extras`);
    }

    // Updates the cartIdEditing to the new entry only if it exists
    if (
      isSelectedOfferCartEntry(item) ||
      cartEntries.find(({ cartId }) => cartId === item.cartId)
    ) {
      setCartIdEditing(item.cartId);
    }

    // If its a reorder item always take directly to the item. Only allow picker changes if we haven't ordered it before.
    const baseUrl = item.reorder
      ? `/menu/${item.type.toLowerCase()}-${item._id}`
      : item.url.split('?')[0];

    const routeParams = { isEdit: 'true', isFromCart: pathname.startsWith(routes.cart).toString() };

    syncEntries();

    router.back();
    router.navigate({ pathname: baseUrl, params: routeParams });
  };

  const handleRemoveItem = useCallback(
    () => confirmRemoveItemFromCart(item.cartId),
    [item, confirmRemoveItemFromCart]
  );

  const handleQuantityChange = useCallback(
    (quantity: number, incrementorAction: IncrementorActions) => {
      if (loyaltyEnabled) {
        const { rewardBenefitId } = getAvailableRewardFromCartEntry(item) || {};
        const numberOfAppliedRewards = appliedLoyaltyRewards[item.cartId]?.timesApplied;
        const quantityLessThanAppliedRewards = quantity < numberOfAppliedRewards;
        const { applyReward, unApplyReward } = actions.loyalty;
        if (quantityLessThanAppliedRewards && rewardBenefitId) {
          dispatch(
            unApplyReward({
              rewardBenefitId,
              cartId: item.cartId,
              loyaltyUser,
            })
          );
        }

        // if incentive is not in menu, any quantity update should apply or remove the incentive
        if (
          incentiveNotInMenu &&
          incrementorAction === IncrementorActions.Increment &&
          rewardBenefitId
        ) {
          dispatch(applyReward({ rewardBenefitId, cartId: item.cartId }));
        }
      }

      updateItemsQuantity(item.cartId, quantity);
    },
    [
      appliedLoyaltyRewards,
      dispatch,
      getAvailableRewardFromCartEntry,
      incentiveNotInMenu,
      item,
      loyaltyEnabled,
      loyaltyUser,
      updateItemsQuantity,
    ]
  );

  return {
    handleEditItem,
    handleRemoveItem,
    handleQuantityChange,
  };
};
