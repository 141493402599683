import React from 'react';
import { useIntl } from 'react-intl';

import { IconDialog } from '@fhs/ui';
import { Box } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import { useAuthContext } from 'state/auth';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { routes } from 'utils/routing';

const SubmitFeedbackLink = () => {
  const { formatMessage } = useIntl();
  const { logRBIEvent } = useCRMEventsContext();
  const { user } = useAuthContext();

  return (
    <Box py="$2">
      <ActionButton
        fullWidth
        testID="need-help-cta"
        to={routes.support}
        onPress={() => {
          logRBIEvent({
            name: CustomEventNames.PYPESTREAM_SUPPORT_OPEN,
            type: EventTypes.UserContent,
            attributes: {
              userCognitoId: user?.cognitoId,
            },
          });
        }}
        leftIcon={
          <Box mt="$0" mr="$1">
            <IconDialog />
          </Box>
        }
        borderRadius="12px"
      >
        {formatMessage({ id: 'needHelp' })}
      </ActionButton>
    </Box>
  );
};

export default SubmitFeedbackLink;
