import { parse } from 'date-fns';

import { IBetweenDates, IBetweenTimes, Rule } from '@rbi-ctg/offers';

import { RuleType, findAllRulesOfTypeThatApply } from '.';

const getExpirationDateFromBetweenDates = (item: IBetweenDates) => {
  if (!item.endDate) {
    return null;
  }

  try {
    const backupDate = new Date();
    const parsedDate = parse(item.endDate, 'yyyy-MM-dd', backupDate);

    // Detect "Invalid Date" Dates resulted from the "parse" method
    if (isNaN(parsedDate.getTime())) {
      return null;
    }

    return parsedDate === backupDate ? null : parsedDate;
  } catch (e) {
    return null;
  }
};

const getExpirationDateFromBetweenTimes = (item: IBetweenTimes) => {
  if (!item.endTime) {
    return null;
  }
  try {
    const [hours, minutes] = item.endTime.split(':');

    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    date.setSeconds(0);

    return date;
  } catch {
    return null;
  }
};

const truthyFilter = (item: any) => !!item;

/**
 * Get the oldest expiration time from a set of rules.
 */
export const getExpirationDateFromRuleset = (ruleSet: Rule[] | null) => {
  const betweenDates = findAllRulesOfTypeThatApply<IBetweenDates>(
    RuleType.BetweenDates,
    ruleSet
  ).map(getExpirationDateFromBetweenDates);

  const betweenTimes = findAllRulesOfTypeThatApply<IBetweenTimes>(
    RuleType.BetweenTimes,
    ruleSet
  ).map(getExpirationDateFromBetweenTimes);

  //@todo: add weekday times

  const dates = [...betweenDates, ...betweenTimes]
    .filter(truthyFilter)
    .sort((a: any, b: any) => b - a);

  return dates[0] || null;
};
