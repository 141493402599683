import * as ExpoUpdates from 'expo-updates';
import { DevSettings, Platform } from 'react-native';

import { ErrorCard, IconErrorFill, IconRefresh, tokens } from '@fhs/ui';

export function MenuErrorFallback() {
  const handleRefresh = () => {
    if (Platform.OS === 'web') {
      window.location.reload();
    } else if (__DEV__) {
      DevSettings.reload();
    } else {
      ExpoUpdates.reloadAsync();
    }
  };
  return (
    <ErrorCard
      title="Oops, Something Went Wrong!"
      description="Brace yourself till we get the error fixed.
You may refresh the page or try again later"
      buttonTitle="Refresh Page"
      buttonHandle={handleRefresh}
      buttonIcon={<IconRefresh color={tokens.colors.$white} />}
      icon={<IconErrorFill size={80} color={tokens.colors.$blackOpacity10} />}
    />
  );
}
