import { createContext, useContext } from 'react';

type Layout = {
  pageX: number;
  width: number;
  pageY: number;
  height: number;
};

type SetTriggerLayoutFn = (layout: Layout) => void;
type SetIsExpandedFn = (isExpanded: boolean) => void;

export const DropdownStateContext = createContext<{ triggerLayout: Layout; isExpanded: boolean }>({
  triggerLayout: { pageX: 0, width: 0, pageY: 0, height: 0 },
  isExpanded: false,
});

export function useDropdownState() {
  return useContext(DropdownStateContext);
}

export const DropdownSetterContext = createContext<{
  setTriggerLayout: SetTriggerLayoutFn;
  setIsExpanded: SetIsExpandedFn;
}>({
  setTriggerLayout: () => {},
  setIsExpanded: () => {},
});

export function useDropdownSetters() {
  return useContext(DropdownSetterContext);
}
