import { Link } from 'expo-router';
import React, { useCallback, useMemo } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { Text, createMqStyles } from '@fhs/ui';
import { EventTypes, useCRMEventsContext } from '@fhs-legacy/frontend/src/state/crm-events';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';
import { InlineAlert } from '@fhs-legacy/universal-components';

import { SimplyOfferEventNames } from '../../analytics';
import { useLoyaltyLegacyStates } from '../../hooks/use-loyalty-legacy-states';

interface Props {
  style?: StyleProp<ViewStyle>;
}

export const SwitchServiceModeInline = ({ style }: Props) => {
  const { logRBIEvent } = useCRMEventsContext();
  const mqStyles = useMqStyles();
  const { serviceMode, isAuthenticated } = useLoyaltyLegacyStates();
  const link = useMemo(() => {
    if (serviceMode === 'DELIVERY') {
      return '/store-locator/service-mode';
    }
    return '/store-locator/address';
  }, [serviceMode]);
  const text = useMemo(() => {
    if (serviceMode === 'DELIVERY') {
      return 'Switch to Pickup';
    }
    return 'Switch to Delivery';
  }, [serviceMode]);

  const handlePress = useCallback(() => {
    logRBIEvent({
      name: SimplyOfferEventNames.OFFER_SWITCH_SERVICE_MODE,
      type: EventTypes.Other,
      attributes: {
        currentServiceMode: serviceMode as String,
      },
    });
  }, [serviceMode, logRBIEvent]);

  return (
    <InlineAlert
      iconCentered
      status="info"
      borderRadius={8}
      style={[mqStyles.inlineText, style]}
      message={
        <Text.Ui style={[mqStyles.inlineText]} size="md">
          Looking for more offers? {'\n'}
          <Link href={link} onPress={handlePress}>
            <Text.Ui style={mqStyles.textHighlighted} size="md">
              {text}
            </Text.Ui>
          </Link>{' '}
          for additional options.
          {!isAuthenticated && (
            <>
              {'\n\n Or '}
              <Link href={routes.signIn}>
                <Text.Ui style={mqStyles.textHighlighted} size="md">
                  Sign In
                </Text.Ui>
              </Link>
              {' to see exclusive offers.'}
            </>
          )}
        </Text.Ui>
      }
    />
  );
};

const useMqStyles = createMqStyles({
  inlineText: {
    $base: {
      fontSize: 12,
      flex: 1,
    },
    $gteDesktop: {
      fontSize: 14,
    },
  },
  textHighlighted: {
    $base: {
      textDecorationLine: 'underline',
      fontSize: 12,
    },
    $gteDesktop: {
      fontSize: 14,
    },
  },
});
