import { format, parseISO } from 'date-fns';
import * as React from 'react';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Box, Select, Text, VStack } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import { List } from 'components/list';
import { LOCALES } from 'state/intl/types';

import { ArrowButton } from '../../../components/arrow-button';
import noop from '../../../utils/noop';
import { ContentWrap } from '../support-form-v2.styled';

import { RecentOrdersDescriptionText } from './styled';
import { ISupportV2OrdersListViewProps } from './types';
import { localeStringToDateFnsLocale } from './utils';

const dateFormatForLocale = (locale: string) => (locale === LOCALES.fr_ca ? 'd MMMM' : 'MMMM d');

export const SupportV2OrdersListView: FC<
  React.PropsWithChildren<ISupportV2OrdersListViewProps>
> = ({
  dropdownOptions,
  items,
  onYearAndMonthChange,
  selectedYearAndMonth,
  onSelectOrder = noop,
  onClickSignin = noop,
  isAuthenticated = false,
}) => {
  const { formatMessage, locale } = useIntl();

  return (
    <ContentWrap marginX="4" pt="$4" flex="1">
      <RecentOrdersDescriptionText>
        {formatMessage({ id: 'refundEligibilityInfo' })}
      </RecentOrdersDescriptionText>
      {isAuthenticated ? (
        <Box flex="1">
          <Text>{formatMessage({ id: 'filterByMonth' })}</Text>
          <Select
            testID="year-and-month-filter"
            onValueChange={onYearAndMonthChange}
            selectedValue={selectedYearAndMonth}
          >
            {dropdownOptions.map(o => (
              <Select.Item key={`${o.value}+${o.label}`} value={o.value} label={o.label} />
            ))}
          </Select>

          <List marginBottom="0">
            {items.map(item => (
              <ArrowButton
                onPress={() => onSelectOrder(item)}
                key={item._id}
                testID="order-list-item-link"
              >
                <VStack>
                  <Text>
                    {format(parseISO(item.createdAt), dateFormatForLocale(locale), {
                      locale: localeStringToDateFnsLocale(locale),
                    })}
                  </Text>
                  <Text>
                    {formatMessage({ id: 'xItems' }, { xItems: item.cart.cartEntries?.length })}
                  </Text>
                </VStack>
              </ArrowButton>
            ))}
          </List>
        </Box>
      ) : (
        <ActionButton onPress={onClickSignin} testID="sign-in-button">
          {formatMessage({ id: 'signInToViewOrders' })}
        </ActionButton>
      )}
    </ContentWrap>
  );
};
