import { Box, Text, makeUclComponent } from '@fhs-legacy/universal-components';
import { ExpandableContent } from 'components/expandable-content';
import { WEB_APP_DESKTOP_LG_MAX_WIDTH } from 'utils/constants';

export const PaymentLabel = Text.withConfig({
  marginX: 0,
  marginY: '$3',
});

export const AddCardTitle = Box.withConfig({
  _text: {
    fontSize: 'sm',
    fontWeight: 'normal',
  },
});

export const PaymentSection = Box.withConfig<{ ref?: React.RefObject<HTMLDivElement> }>({
  width: '100%',
  maxWidth: WEB_APP_DESKTOP_LG_MAX_WIDTH,
  alignSelf: 'center',
  paddingX: '$4',
  paddingY: 0,
});

export const PaymentInfoBackground = Box.withConfig<{ $hostedPayment?: boolean }>({
  backgroundColor: {
    desktop: Styles.color.cardBackground,
  },

  borderTopLeftRadius: {
    desktop: Styles.borderRadius,
  },

  borderTopRightRadius: {
    desktop: Styles.borderRadius,
  },

  borderBottomRightRadius: {
    desktop: Styles.borderRadius,
  },

  borderBottomLeftRadius: {
    desktop: Styles.borderRadius,
  },
});

export const CartPriceTooLow = Box.withConfig({
  _text: {
    textAlign: 'center',
    color: Styles.color.alert.warning.background,
  },

  padding: '$2',
  borderWidth: 2,
  borderColor: Styles.color.alert.warning.background,
  borderStyle: 'solid',
  marginTop: '$4',
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0,
});

export const AddMoreText = Box.withConfig({
  _text: {
    fontWeight: Styles.fontWeight.heavy,
    textAlign: 'center',
    color: Styles.color.alert.warning.background,
  },

  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: '$1',
});
export const DisclaimerCardOfferInvalid = Box.withConfig({
  _text: {
    textAlign: 'center',
  },

  padding: '$4',
});

export const DisclaimerCatering = Text.withConfig({
  fontSize: 'xs',
  textAlign: 'center',
});

export const ErrorContainer = Box.withConfig({
  marginTop: '$4',
  backgroundColor: '#fae7ea',

  _text: {
    textAlign: 'center',
  },
});

export const ErrorMessage = Text.withConfig({
  marginX: '$8',
  marginY: 0,
  paddingX: 0,
  paddingY: '11px',
  color: Styles.color.primary,
});

export const AddInlineGiftCardWrapper = Box.withConfig({
  marginTop: '$4',
});

export const StyledIconCreditCard = Box;

export const ExpandableContentTitle = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'center',

  _text: {
    fontWeight: 'normal',
    fontFamily: Styles.fontFamily.base,
    fontSize: 'sm',
  },
});

export const CreditCardFormInputsWrapper = Box.withConfig({
  padding: {
    base: '$4',
    desktop: 0,
  },
});

export const StyledExpandableContent = makeUclComponent(ExpandableContent);

export const SignUpToContinueDisclaimer = Text.withConfig({
  color: Styles.color.grey.one,
  fontSize: 'sm',
  fontWeight: Styles.fontWeight.normal,
  textAlign: 'center',
  marginBottom: '$8',
});

export const WarningContainer = Box.withConfig({
  paddingTop: '$2',
  paddingBottom: '$3',
  paddingX: 0,
  marginRight: '$3',
});
