import React from 'react';
import { useIntl } from 'react-intl';

import { Text } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import ItemUnavailableMessage from 'components/item-unavailable-message';
import useDialogModal from 'hooks/use-dialog-modal';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { ClickEventComponentNames } from 'state/crm-events/constants';
import { IValidDayPart } from 'state/day-part/hooks/use-active-day-parts';
import { OptionQuantityError } from 'utils/wizard/requirements';

import { ItemStatus } from '../hooks/enums';

interface CtaButtonProps {
  buttonCSS: Record<string, any>; // This is just UCL styling props now.
  cta: {
    children: JSX.Element[];
    onClick: () => void;
  };
  currentPrice: string;
  dayParts: readonly IValidDayPart[];
  handleFindRestaurantClick: () => void;
  isCurrentPriceValid: boolean;
  isEditing: boolean;
  isFinalStep: boolean;
  itemDayParts: string[];
  itemStatus: ItemStatus;
  quantity: number;
  requirementsErrors: OptionQuantityError[];
  showActionOrMessageInBottom: boolean;
  titleText: string;
}

export const CtaButton: React.FC<React.PropsWithChildren<CtaButtonProps>> = ({
  buttonCSS,
  cta,
  currentPrice,
  dayParts,
  handleFindRestaurantClick,
  isCurrentPriceValid,
  isEditing,
  isFinalStep,
  itemDayParts,
  itemStatus,
  quantity,
  requirementsErrors,
  showActionOrMessageInBottom,
  titleText,
}) => {
  const { formatMessage } = useIntl();

  const [ErrorModal, openErrorModal] = useDialogModal({
    modalAppearanceEventMessage: 'Menu option selection errors.',
  });

  const { logRBIEvent } = useCRMEventsContext();
  switch (itemStatus) {
    case ItemStatus.CHECK_STORE_AVAILABILITY:
      return (
        <ActionButton
          variant={ActionButtonVariants.BOX_SHADOW_PRIMARY}
          accessibilityLabel={formatMessage({ id: 'checkAvailability' })}
          testID="menu-select-restaurant-button"
          onPress={handleFindRestaurantClick}
          fullWidth
          // TODO: RN - do we still need this prop/whatever it was doing before?
          // onlyIcon={isMobile}
          {...buttonCSS}
        >
          {formatMessage({ id: 'checkAvailability' })}
        </ActionButton>
      );
    case ItemStatus.DAYPART_UNAVAILABLE:
      return (
        <ItemUnavailableMessage
          dayParts={dayParts}
          itemDayParts={itemDayParts}
          marginAndBorders={!showActionOrMessageInBottom}
        />
      );
    case ItemStatus.AVAILABLE_IN_STORE:
      const UPDATE_STRING = 'updateQuantityAndItemWithPrice';
      const ADD_STRING = 'addQuantityAndItemWithPrice';
      const messageId = !isFinalStep ? 'continue' : isEditing ? UPDATE_STRING : ADD_STRING;
      const ariaLabel = formatMessage(
        { id: messageId },
        { itemName: titleText, quantity, price: currentPrice }
      );

      const ctaContinuation =
        isFinalStep && requirementsErrors.length ? openErrorModal : cta.onClick;

      const handlePress = () => {
        if (messageId === UPDATE_STRING || messageId === ADD_STRING) {
          logRBIEvent({
            name: CustomEventNames.CLICK_EVENT,
            type: EventTypes.Other,
            attributes: {
              component: ClickEventComponentNames.BUTTON,
              text: messageId === UPDATE_STRING ? 'Product Update' : 'Product Addition',
            },
          });
        }
        ctaContinuation();
      };
      return (
        <>
          <ActionButton
            // TODO: RN - do we still need this prop/whatever it was doing before?
            // onlyIcon={isMobile}
            variant={ActionButtonVariants.BOX_SHADOW_PRIMARY}
            accessibilityLabel={ariaLabel}
            testID="menu-action-button"
            perceptible
            disabled={!isCurrentPriceValid}
            onPress={handlePress}
            width="85%"
            {...buttonCSS}
          >
            {cta.children.join(' — ')}
          </ActionButton>

          <ErrorModal
            heading={formatMessage({ id: 'missingItems' })}
            bodyComponent={requirementsErrors.map(error => (
              <Text key={error.name}>
                {formatMessage(
                  { id: 'makeAdditionalSelections' },
                  { name: error.name, quantity: error.minAmount - error.quantity }
                )}
              </Text>
            ))}
          />
        </>
      );
    case ItemStatus.MAX_CART_QUANTITY_MET: {
      const errorMessage = formatMessage({ id: 'limitReached' });
      return (
        <ActionButton
          variant={ActionButtonVariants.BOX_SHADOW_PRIMARY}
          accessibilityLabel={errorMessage}
          testID="menu-action-button"
          perceptible
          disabled
          onPress={cta.onClick}
          fullWidth
          {...buttonCSS}
        >
          {errorMessage}
        </ActionButton>
      );
    }
    default:
      return null;
  }
};
