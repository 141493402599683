import { Button } from '@fhs-legacy/universal-components';

import { baseLinkCss } from './styled';
import { HoverTheme, IHover } from './types';

export const AddNewCardLink = Button.withConfig<IHover>(p => ({
  _text: {
    textAlign: 'left',
    color: p.hoverTheme === HoverTheme.DARK ? Styles.color.white : '#000',
    fontSize: 'sm',
    fontFamily: Styles.fontFamily.body,
  },

  padding: 0,
  paddingLeft: '$3',

  _hover: {
    backgroundColor: Styles.color.primary,
    color: p.hoverTheme === HoverTheme.DARK ? 'inherit' : Styles.color.white,
  },

  ...baseLinkCss,
}));
