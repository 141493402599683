import React, { VFC } from 'react';

import { TextProps } from '@fhs-legacy/universal-components';
import SanityBlockRenderer from 'components/sanity-block-renderer';

import { INutritionDisclaimer } from '../types';

const paragraphStyles: TextProps = {
  variant: 'copyTwo',
  textAlign: 'left',
  fontSize: 'xs',
  marginBottom: '$4',
};

// TODO - RN: Variant styles do not apply to SanityBlockRenderer links
const linkStyles: TextProps = {
  variant: 'copyTwo',
  fontSize: 'xs',
  textDecorationLine: 'underline',
  top: '2px',
  color: `${Styles.color.black}`,
};

export const NutritionDisclaimer: VFC<INutritionDisclaimer> = ({ content }) => (
  <SanityBlockRenderer
    testID="nutrition-disclaimer"
    content={content}
    textProps={paragraphStyles}
    linkProps={linkStyles}
  />
);

export default NutritionDisclaimer;
