import { capitalize } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
  ICartEntry,
  ICombo,
  IComboSlot,
  IItemOption,
  IPickerOption,
  MenuObject,
} from '@rbi-ctg/menu';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { EnablePremiumComboSlotsVariations } from 'state/launchdarkly/variations';
import { useMenuContext } from 'state/menu';
import { useOffersContext } from 'state/offers';
import { useUIContext } from 'state/ui';
import { CartEntryType, ComboSlotUiPattern } from 'utils/cart/types';
import { maybeMapCartEntryToMenuObjectIdentifier } from 'utils/menu';

interface UiPatternOption {
  _id?: string;
  uiPattern?: ComboSlotUiPattern | null;
}
interface UiPatternOptionMap {
  [id: string]: UiPatternOption;
}

/**
 * This hook takes a cart entry item and returns description
 * composed of comboSlot & itemOption selections.
 *
 * Return type can be either in the form of selection names,
 * or a single string containing comma separated selection names
 * depending on options specified.
 *
 * Note: includeModifiers also controls if we show comboSlot items.
 */
export function useComposeDescription(
  item: ICartEntry,
  options: {
    includeQuantity?: boolean;
  } = {}
): string[] {
  const enablePremiumComboSlotsVariation = useFlag<EnablePremiumComboSlotsVariations>(
    LaunchDarklyFlag.ENABLE_PREMIUM_COMBO_SLOTS
  );
  const includeAllPrices =
    enablePremiumComboSlotsVariation !== EnablePremiumComboSlotsVariations.NONE;

  const { includeQuantity = false } = options;
  const { formatCurrencyForLocale } = useUIContext();

  const { menuObject } = useCartEntryMenuObject({ cartEntry: item });

  const itemPricesToIgnore = getMenuObjectPricesToIgnore(menuObject);

  const uiPatternMap = getMenuObjectUiPatternMap(menuObject);

  const getDescription = (
    cartEntryChildren: ICartEntry[] = [],
    quantity: number,
    ignoredPricesSet: Set<string>
  ): string[] => {
    return cartEntryChildren.reduce<string[]>((desc, child) => {
      const uiPattern = child.uiPattern || uiPatternMap[child.sanityId];
      if (
        (child.type === CartEntryType.comboSlot && uiPattern === ComboSlotUiPattern.HIDDEN) ||
        uiPattern === ComboSlotUiPattern.COLLAPSED
      ) {
        return desc;
      }
      if (child.type === CartEntryType.item || child.type === CartEntryType.itemOptionModifier) {
        const itemName = (child.name || '').split(' ').map(capitalize).join(' ');
        const displayName =
          includeQuantity && child.quantity > 1 ? `${child.quantity || ''} ${itemName}` : itemName;
        const shouldIgnorePrice = ignoredPricesSet.has(child._id);
        const price = shouldIgnorePrice ? 0 : (child.price || 0) * quantity;
        desc.push(
          (child.type === CartEntryType.itemOptionModifier || includeAllPrices) && price > 0
            ? displayName.concat(` + ${formatCurrencyForLocale(price)}`)
            : displayName
        );
      }

      return desc.concat(getDescription(child.children, quantity, ignoredPricesSet));
    }, []);
  };

  const description = getDescription(item.children, item.quantity, itemPricesToIgnore);

  return description;
}

// TODO: FPF - Create separate files for this helpers

const useCartEntryMenuObject = ({ cartEntry }: { cartEntry: ICartEntry }) => {
  const { isSelectedOfferCartEntry } = useOffersContext();
  const { asyncGetMenuObject } = useMenuContext() || {};
  const [menuObject, setMenuObject] = useState<MenuObject | null>(null);

  const menuIdentifier = useMemo(
    () =>
      !isSelectedOfferCartEntry(cartEntry)
        ? maybeMapCartEntryToMenuObjectIdentifier(cartEntry)
        : null,
    [cartEntry, isSelectedOfferCartEntry]
  );

  useEffect(() => {
    // If a mapping cant be done, return cartEntry unmapped
    if (!menuIdentifier || !asyncGetMenuObject) {
      setMenuObject(null);
    } else {
      const getMenuObject = async () => {
        const { data } = await asyncGetMenuObject(menuIdentifier);
        if (data) {
          setMenuObject(data);
        }
      };
      getMenuObject();
    }
  }, [asyncGetMenuObject, menuIdentifier]);

  return { menuObject };
};

const getMenuObjectPricesToIgnore = (menuObject: MenuObject | null) => {
  const pricesToIgnore = new Set<string>();
  const mergedOptions = [
    ...(menuObject?.options || []),
    ...((menuObject as ICombo)?.mainItem?.options || []),
  ];
  mergedOptions.forEach((option: IComboSlot | IItemOption | MenuObject | IPickerOption) => {
    if ((option as IItemOption).allowMultipleSelections) {
      (option as IItemOption).options.forEach(opt => pricesToIgnore.add(opt._key));
    }
  });
  return pricesToIgnore;
};

const getMenuObjectUiPatternMap = (menuObject: MenuObject | null) => {
  const uiPatternMap = ((menuObject?.options as UiPatternOption[]) || []).reduce((acc, option) => {
    if (option._id && option.uiPattern) {
      acc[option._id] = option;
    }
    return acc;
  }, {} as UiPatternOptionMap);
  return uiPatternMap;
};
