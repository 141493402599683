import { keyBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import shallowEqual from 'shallowequal';

import { useFeatureSortedLoyaltyOffersQuery } from 'generated/sanity-graphql';
import usePosVendor from 'hooks/menu/use-pos-vendor';
import { useStoreContext } from 'state/store';
import logger from 'utils/logger';

import { createLoyaltyOffer } from './create-loyalty-offer';
import { useGetLoyaltyOffersQueryWithContext } from './hooks/engine/use-engine-query-with-context';
import { useLoyaltyPersonalizedOfferList } from './hooks/use-loyalty-personalized-offer-list';
import { createOfferValidator } from './offer-validator';
import type {
  ICmsConfigOffer,
  ICmsOffer,
  ICmsTemplateOffer,
  IEngineOffer,
  ILoyaltyOffer,
} from './types';

export const useLoyaltyOfferList = () => {
  const { prices } = useStoreContext();
  const { vendor } = usePosVendor();

  const {
    data: sortedCmsOffers,
    loading: loadingSortedCmsOffers,
    refetch: refetchSortedCmsOffers,
  } = useFeatureSortedLoyaltyOffersQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: 'feature-loyalty-offers-ui-singleton',
    },
  });

  const {
    data: engineOffersData,
    loading: loadingEngineOffers,
    refetch: refetchEngineOffers,
    updateQuery,
  } = useGetLoyaltyOffersQueryWithContext({
    notifyOnNetworkStatusChange: true,
  });

  const engineOffers = engineOffersData?.loyaltyOffersV2 ?? null;
  const sortedSystemwideOffers = sortedCmsOffers?.LoyaltyOffersUI?.sortedSystemwideOffers ?? null;

  const {
    personalizedOffers,
    loading: loadingPersonalizedOffersData,
    updateQuery: updatePersonalizedOffersQuery,
  } = useLoyaltyPersonalizedOfferList({ engineOffers });

  const refetch = useCallback(() => {
    refetchSortedCmsOffers();
    refetchEngineOffers();
  }, [refetchEngineOffers, refetchSortedCmsOffers]);

  const [offerList, setOfferList] = useState<ILoyaltyOffer[]>([]);

  /**
   * Use this function when a new offer should be added on the fly to the list to avoid refetching the entire list.
   * Assuming this data is from the server it updates the queries' cache to have the most updated data in the `data`
   * apollo object but not executing the entire list creation.
   */
  const addOfferToList = useCallback(
    ({
      engineOffer,
      configOffer,
      offerTemplate,
    }: {
      engineOffer: IEngineOffer;
      configOffer: ICmsConfigOffer;
      offerTemplate?: ICmsTemplateOffer;
    }) => {
      const newLoyaltyOffer = createLoyaltyOffer(configOffer, engineOffer);

      // add the element at the top of the list to display the element in the right order
      setOfferList(prev => [newLoyaltyOffer, ...(prev || [])]);
      updateQuery(prevData => {
        const prevLoyaltyOffers = prevData.loyaltyOffersV2 || [];
        const newLoyaltyEngineOffers = [engineOffer, ...prevLoyaltyOffers];

        // update dependent query
        updatePersonalizedOffersQuery({
          configOffers: [configOffer],
          ...(offerTemplate && { offerTemplates: [offerTemplate] }),
        });
        return {
          loyaltyOffersV2: newLoyaltyEngineOffers,
        };
      });
    },
    [updatePersonalizedOffersQuery, updateQuery]
  );

  useEffect(() => {
    if (!sortedSystemwideOffers?.length || !engineOffers?.length || loadingPersonalizedOffersData) {
      return;
    }

    const loyaltyOffersResult: ILoyaltyOffer[] = [];
    // every cms offer must have an engine offer associated to it
    // creating a map to access to the engine by loyalty engine id
    const engineOffersMap = keyBy(engineOffers, 'id');
    // concat personalized offers first because we have to display them at the top of the list
    const cmsOffers: (ICmsOffer | null)[] = [...personalizedOffers, ...sortedSystemwideOffers];

    const validateCmsOffer = createOfferValidator({ prices, vendor });
    const errorsToPrint: Record<string, string> = {};
    cmsOffers.forEach(cmsOfferEntry => {
      if (cmsOfferEntry) {
        // get associated engine offer by loyalty engine id
        const engineOffer = engineOffersMap[cmsOfferEntry.loyaltyEngineId || ''];

        if (!engineOffer) {
          errorsToPrint[
            cmsOfferEntry._id
          ] = `No engine offer with the id ${cmsOfferEntry.loyaltyEngineId}`;
          return;
        }

        const { isValid, error } = validateCmsOffer(cmsOfferEntry);
        if (!isValid) {
          errorsToPrint[cmsOfferEntry._id] = error.code;
          return;
        }

        const loyaltyOffer = createLoyaltyOffer(cmsOfferEntry, engineOffer);
        loyaltyOffersResult.push(loyaltyOffer);
      }
    });

    logger.debug(`Filtered Offer Errors ${JSON.stringify(errorsToPrint)}`);

    setOfferList(prevData =>
      // modify the state only if there is shallow difference to prevent unnecessary render
      shallowEqual(prevData, loyaltyOffersResult) ? prevData : loyaltyOffersResult
    );
  }, [
    engineOffers,
    engineOffers?.length,
    loadingPersonalizedOffersData,
    personalizedOffers,
    prices,
    sortedSystemwideOffers,
    sortedSystemwideOffers?.length,
    vendor,
  ]);

  return {
    addOfferToList,
    offers: offerList,
    loading: loadingSortedCmsOffers || loadingEngineOffers || loadingPersonalizedOffersData,
    refetch,
  };
};
