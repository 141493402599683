import { IconClose } from '@fhs/ui';
import { HStack, Pressable, Text, VStack } from '@fhs-legacy/universal-components';
import { Box } from '@fhs-legacy/universal-components';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import Picture from 'components/picture';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import {
  ICart,
  ICartEntryReward,
} from 'state/loyalty/in-restaurant-redemption/hooks/use-in-restaurant-redemption-cart';

import { BorderedContent, Header } from './styled';

const filterRewards = (cartEntry: ICart[number]): cartEntry is ICartEntryReward =>
  !!(cartEntry.details as any).reward;

const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const AddedRewards = () => {
  const { inRestaurantRedemptionCart, removeInRestaurantRedemptionEntry } =
    useInRestaurantRedemptionContext();
  const handleRemove = (entry: ICartEntryReward) => {
    removeInRestaurantRedemptionEntry(entry);
  };
  return (
    <BorderedContent>
      <Header mb={2}>Rewards Added</Header>
      {inRestaurantRedemptionCart.filter(filterRewards).map(entry => {
        return (
          <BorderedContent alignItems="center" flexDirection="row">
            <Picture
              image={entry.details.reward.image?.locale}
              alt={entry.details.reward.name?.locale ?? ''}
              size="$16"
              p="$2"
              mr="$4"
              objectFitContain
            />
            <VStack key={entry.referenceId} flex={1}>
              <Text mb={2}>{entry.details.reward.name?.locale}</Text>
              <HStack alignItems="center">
                <Box
                  backgroundColor="#008143"
                  _text={{ fontSize: 'xs' }}
                  py="$0.5"
                  px="$2"
                  borderRadius="sm"
                  mr="$3"
                >
                  Reward
                </Box>
                <LoyaltyPointsIcon height="15" mr="$0.5" />
                <Text fontSize="xs" mt="$0.5">
                  {numberWithCommas(entry.details.engineReward.pointCost)}
                </Text>
              </HStack>
            </VStack>
            <Pressable onPress={() => handleRemove(entry)}>
              <Box
                borderRadius="full"
                borderWidth={1}
                borderColor="#B1080F"
                p="$1.5"
                cursor="pointer"
              >
                <IconClose size={16} color="#B1080F" />
              </Box>
            </Pressable>
          </BorderedContent>
        );
      })}
    </BorderedContent>
  );
};

export default AddedRewards;
