import { IntlFormatters } from 'react-intl';

import { ServiceMode } from 'state/service-mode/types';
import { StoreProxy } from 'state/store/hooks';

import { getCateringPickupMessage } from './catering-pickup-message';

export const getAddressMessage = ({
  store,
  noStoreSelected,
  isStoreOpenAndUnavailable,
  serviceMode,
  userAddress,
  formatMessage,
  isMobile,
}: {
  store: StoreProxy;
  noStoreSelected: boolean;
  isStoreOpenAndUnavailable: boolean;
  serviceMode: string;
  userAddress: string;
  formatMessage: IntlFormatters['formatMessage'];
  isMobile: boolean;
}) => {
  let addressMessage: string = formatMessage({ id: 'howWouldYouLikeToReceiveThisOrder' });
  const storeAddress = store.physicalAddress?.address1 || '';

  if (isStoreOpenAndUnavailable) {
    addressMessage = formatMessage({ id: 'yourSelectedRestaurantIs' }, { storeAddress });
  } else if (!noStoreSelected) {
    switch (serviceMode) {
      case ServiceMode.EAT_IN:
        addressMessage = `${formatMessage({ id: 'dineInAt' })} ${storeAddress}`;
        break;
      case ServiceMode.CATERING_PICKUP:
        addressMessage = isMobile
          ? getCateringPickupMessage(formatMessage, storeAddress)
          : `${formatMessage({ id: 'cateringPickUpAt' })} ${storeAddress}`;
        break;
      case ServiceMode.CURBSIDE:
        addressMessage = `${formatMessage({ id: 'curbsideAt' })} ${storeAddress}`;
        break;
      case ServiceMode.TAKEOUT:
        addressMessage = `${formatMessage({ id: 'pickUpAt' })} ${storeAddress}`;
        break;
      case ServiceMode.DRIVE_THRU:
        addressMessage = `${formatMessage({ id: 'driveThruAt' })} ${storeAddress}`;
        break;
      case ServiceMode.DELIVERY:
        const address = userAddress?.split(',')[0] || '';
        addressMessage = `${formatMessage({ id: 'deliverTo' })} ${address}`;
        break;
      default:
        addressMessage = `${formatMessage({ id: 'orderingFrom' })} ${storeAddress}`;
        break;
    }
  }
  // user has not selected a pickup location yet, but has catering pickup mode
  if (noStoreSelected && serviceMode === ServiceMode.CATERING_PICKUP) {
    addressMessage = formatMessage({ id: 'pickUpAtSelectALocation' });
  }
  return addressMessage;
};

export const getOrderingDisabledStoreSelectionMessage = ({
  store,
  formatMessage,
  isStoreOpenAndAvailable,
}: {
  store: StoreProxy;
  formatMessage: IntlFormatters['formatMessage'];
  isStoreOpenAndAvailable: boolean;
}): string => {
  const storeAddress = store.physicalAddress?.address1 || '';
  return isStoreOpenAndAvailable
    ? formatMessage({ id: 'yourSelectedRestaurantIs' }, { storeAddress })
    : formatMessage({ id: 'selectRestaurantToViewOffers' });
};
