import { IRestaurantNode, useGetNearbyRestaurantsV2Query } from 'generated/graphql-gateway';
import { useGetRestaurantsQuery } from 'generated/rbi-graphql';
import { useGeolocation } from 'state/geolocation';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { isValidPosition } from 'utils/geolocation';
import DEFAULT_SEARCH_RADIUS_M from 'utils/restaurant/default-search-radius-meters';

type Coordinates = {
  userLat: number | undefined;
  userLng: number | undefined;
  searchRadius: number;
};

const useGetNearbyRestaurantsLegacy = (coordinates: Coordinates) => {
  const { activeCoordinates } = useGeolocation();

  const {
    data: restaurantsData,
    loading: loadingRestaurant,
    error,
  } = useGetRestaurantsQuery({
    variables: {
      input: {
        first: 20,
        coordinates,
      },
    },
    skip: !isValidPosition(activeCoordinates),
  });

  return {
    restaurants: (restaurantsData?.restaurants?.nodes as IRestaurantNode[]) || [],
    loading: loadingRestaurant,
    error,
  };
};

const useGetNearbyRestaurantsV2 = (coordinates: Coordinates) => {
  const { serviceMode } = useServiceModeContext();
  const { activeCoordinates } = useGeolocation();

  const { data, loading, error } = useGetNearbyRestaurantsV2Query({
    variables: {
      input: {
        coordinates,
        pagination: {
          first: 20,
        },
        serviceModes: serviceMode ? [serviceMode] : [],
        radiusStrictMode: false,
      },
    },
    skip: !isValidPosition(activeCoordinates),
    fetchPolicy: 'cache-and-network',
  });

  return {
    restaurants: (data?.nearbyRestaurants?.nodes as IRestaurantNode[]) || [],
    loading,
    error,
  };
};

export const useGetNearbyRestaurants = () => {
  const { activeCoordinates } = useGeolocation();

  const isRestaurantQueryCachingEnabled = useFlag(LaunchDarklyFlag.ENABLE_RESTAURANT_QUERY_CACHE);
  const defaultDiagonal =
    useFlag(LaunchDarklyFlag.RADIUS_FOR_STORE_LOCATOR) || DEFAULT_SEARCH_RADIUS_M;

  const useNearbyQuery = isRestaurantQueryCachingEnabled
    ? useGetNearbyRestaurantsV2
    : useGetNearbyRestaurantsLegacy;

  const coordinates = {
    // Limiting the decimal places to 3 to get the best results from our cache implementation
    // Before: 7 decimal places = 1/2 inch
    // After: 3 decimal places = 111 meters/football field
    userLat: activeCoordinates ? Number(activeCoordinates.lat.toFixed(3)) : undefined,
    userLng: activeCoordinates ? Number(activeCoordinates.lng.toFixed(3)) : undefined,
    searchRadius: defaultDiagonal,
  };

  const { restaurants, loading, error } = useNearbyQuery(coordinates);

  return {
    restaurants,
    loading,
    error,
  };
};
