import { MutationResult } from '@apollo/client';

export function mutationDidSucceed<T>({
  called,
  data,
  error,
  loading,
}: MutationResult<T>): boolean {
  return !!(called && !loading && !error && data);
}
