import { StyleProp, View, ViewStyle } from 'react-native';

import { Skeleton, SkeletonTextBlock, createMqStyles, tokens } from '@fhs/ui';

export function OfferDetailsSkeleton({
  style,
  textBlockLines = 3,
}: {
  style?: StyleProp<ViewStyle>;
  textBlockLines?: number;
}) {
  const styles = useMqStyles();

  return (
    <View style={[styles.container, style]}>
      <Skeleton style={styles.imageContainer} />
      <View style={styles.contentContainer}>
        <SkeletonTextBlock lines={2} style={styles.textContainer} />
        <SkeletonTextBlock lines={textBlockLines} style={styles.textContainer} />
        <View style={styles.footer}>
          <Skeleton style={styles.skeletonButton} />
        </View>
      </View>
    </View>
  );
}
const useMqStyles = createMqStyles({
  container: {
    $base: {
      backgroundColor: tokens.colors.$white,
      borderColor: tokens.colors.$black10,
      borderWidth: 1,
      borderRadius: 8,
      alignItems: 'center',
      flex: 1,
    },
    $gteDesktop: {
      width: 520,
    },
  },
  imageContainer: {
    $base: {
      height: 216,
      width: '100%',
      borderRadius: 0,
      backgroundColor: tokens.colors.$black10,
      borderBottomWidth: 1,
      borderBottomColor: tokens.colors.$black10,
    },
  },
  contentContainer: {
    $base: {
      flex: 1,
      padding: 16,
      width: '100%',
      justifyContent: 'space-between',
      gap: 12,
    },
    $ltDesktop: {
      height: '100%',
      padding: 12,
    },
    $gteDesktop: {
      flex: 1,
    },
  },
  textContainer: {
    $base: {
      width: '100%',
    },
  },
  skeletonButton: {
    $base: {
      height: 48,
    },
  },
  footer: {
    $base: {
      height: 64,
    },
  },
});
