import { router } from 'expo-router';
import * as React from 'react';
import { FC } from 'react';

import ActionsModal from '../actions-modal';

import { useShortCodeConfirmationModal } from './use-short-code-confirmation-modal';

interface IShortCodeConfirmationModalProps {
  onAction: () => void;
  onDismiss: () => void;
}

const ShortCodeConfirmationModal: FC<React.PropsWithChildren<IShortCodeConfirmationModalProps>> = ({
  onAction,
  onDismiss,
}) => {
  const { data, loading } = useShortCodeConfirmationModal();
  const { actionButton, body, header, image, dismissButtonText } =
    data?.shortCodeConfirmationModalContent || {};
  const parsedImage = image?.locale?.app;

  const handleAction = () => {
    if (onAction) {
      onAction();
    }
    router.navigate(actionButton?.buttonPath?.locale || '');
  };

  if (loading || !data) {
    return null;
  }

  const actions = [
    {
      dataTestId: 'short-code-confirmation-action',
      actionLabel: actionButton?.buttonText?.locale || '',
      actionHandler: handleAction,
    },
    ...(dismissButtonText?.locale
      ? [
          {
            dataTestId: 'short-code-dismiss-action',
            actionLabel: dismissButtonText?.locale || '',
            actionHandler: onDismiss,
            textOnly: true,
          },
        ]
      : []),
  ];

  return (
    <ActionsModal
      actions={actions}
      bodyText={body?.locale || ''}
      testID="short-code-confirmation-modal"
      headerText={header?.locale || ''}
      image={parsedImage}
      imageAlt="short-code-confirmation"
      modalAppearanceEventMessage="Loyalty Shortcode Confirmation Modal"
    />
  );
};

export default ShortCodeConfirmationModal;
