import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { Pypestream } from './pypestream';

export { PYPESTREAM_INTERFACE_ID, PYPESTREAM_SCRIPT_ID } from '../../state/pypestream/constants';

export const PypestreamWrapper = () => {
  const enablePypestream = useFlag(LaunchDarklyFlag.ENABLE_PYPESTREAM);

  if (!enablePypestream) {
    return null;
  }

  return <Pypestream />;
};
