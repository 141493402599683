import React, { useEffect, useMemo, useState } from 'react';

import { Box, HStack, Text } from '@fhs-legacy/universal-components';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import { getCountdownValue } from 'utils/dateTime';
import { screenReaderOnly } from 'utils/style/screen-reader-only';

const formatNumber = (n: number) => `${n}`.padStart(2, '0');

interface ICountdownProps {
  endTime: number;
  className?: string;
  onFinish: () => void;
  useClockStyle?: boolean;
}

const ClockWrapper = HStack.withConfig({
  justifyContent: 'center',
  alignItems: 'center',
});

const ClockMinAndSec = Box.withConfig({
  backgroundColor: 'white',
  borderRadius: Styles.borderRadius,
  width: '$13.5',
  height: '$13.5',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '$1',
  _text: {
    lineHeight: 'xs',
    fontWeight: 'bold',
    color: Styles.color.black,
    fontSize: '4xl',
  },
});

const ClockColon = Box.withConfig({
  height: '$13.5',
  paddingY: 0,
  paddingX: '$1',
  color: Styles.color.black,
  _text: {
    lineHeight: 'sm',
    fontWeight: 'bold',
    color: Styles.color.black,
    fontSize: '4xl',
  },
});

const ScreenReader = screenReaderOnly;

const Countdown = ({ endTime, onFinish, useClockStyle }: ICountdownProps) => {
  const [nowTime, setNowTime] = useState(() => Date.now());

  const timeLeft = useMemo(() => endTime - nowTime, [endTime, nowTime]);

  const { minutes, seconds, screenReaderSec, screenReaderMins } = useMemo(
    () => getCountdownValue(timeLeft),
    [timeLeft]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setNowTime(Date.now());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (onFinish && timeLeft <= 0) {
    onFinish();
    return null;
  }

  // const formattedDay = formatNumber(days);
  const formattedMinutes = formatNumber(minutes);
  const formattedSeconds = formatNumber(seconds);
  const formattedScreenReaderMins = formatNumber(screenReaderMins);
  const formattedScreenReaderSec = formatNumber(screenReaderSec);

  // TODO: Maybe fix the formattedScreenReader days and hours??
  const screenOnlyContent = `${formattedScreenReaderMins}:${formattedScreenReaderSec}`;

  const screenOnlyElement = (
    <VisuallyHidden accessibilityLabel="timer">
      <ScreenReader accessibilityLiveRegion="assertive">{screenOnlyContent}</ScreenReader>
    </VisuallyHidden>
  );

  let clockElement;

  if (useClockStyle) {
    clockElement = (
      <ClockWrapper testID="countdown-clock">
        <ClockMinAndSec testID="minutes">{formattedMinutes}</ClockMinAndSec>
        <ClockColon>:</ClockColon>
        <ClockMinAndSec testID="seconds">{formattedSeconds}</ClockMinAndSec>
      </ClockWrapper>
    );
  } else {
    clockElement = (
      <Text>
        {formattedMinutes}:{formattedSeconds}
      </Text>
    );
  }

  return (
    <>
      {clockElement}
      {screenOnlyElement}
    </>
  );
};

export default Countdown;
