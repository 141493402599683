import { Stack, useLocalSearchParams } from 'expo-router';
import Head from 'expo-router/head';
import React, { useEffect, useMemo } from 'react';

import { Box } from '@fhs-legacy/universal-components';
import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';
import useEffectOnce from 'hooks/use-effect-once';
import NotFound from 'pages/not-found';
import { RenderCurrentStaticPage } from 'pages/static/render-current-static-page';
import { IStaticPage } from 'remote/queries/static-page';
import { useLocale } from 'state/intl';
import { usePageManagerContext } from 'state/static-page-manager';
import { useGetStaticPageByRoute } from 'state/static-page-manager/hooks/use-get-static-page-by-route';

import { StaticContentLanguageProvider } from './static-content-language';
import { StaticPageSkeleton } from './static-page.skeleton';

const Container = Box.withConfig({
  flex: 1,
  paddingTop: 0,
  marginTop: 0,
});

const StaticPageContainer = () => {
  const { staticPage = '' } = useLocalSearchParams();
  const {
    currentPage,
    retrieveStaticPageById,
    routesLoading,
    routes,
    loadRoutes,
    loadRoutesHasBeenCalled,
  } = usePageManagerContext();
  const { language: appUILanguage } = useLocale();

  useEffectOnce(() => {
    // If the loadRoutes query has not been called yet, load the static routes list
    if (!loadRoutesHasBeenCalled) {
      loadRoutes();
    }
  });

  const { route: targetPageRoute, defaultLanguage: targetPageDefaultLanguage } = useMemo(() => {
    for (const route of routes || []) {
      if (route.localePath?.en?.current === staticPage) {
        return { route, defaultLanguage: 'en' } as const;
      }

      if (route.localePath?.fr?.current === staticPage) {
        return { route, defaultLanguage: 'fr' } as const;
      }

      if (route.path.current === staticPage) {
        return { route, defaultLanguage: appUILanguage } as const;
      }
    }

    return {};
  }, [routes, staticPage, appUILanguage]);

  useEffect(() => {
    if (!targetPageRoute) {
      return;
    }

    retrieveStaticPageById(targetPageRoute._id);
  }, [targetPageRoute, retrieveStaticPageById]);

  const is404 = !targetPageRoute && loadRoutesHasBeenCalled && !routesLoading;

  if (is404) {
    return <NotFound />;
  }

  if (
    !loadRoutesHasBeenCalled ||
    routesLoading ||
    !currentPage ||
    !targetPageRoute ||
    currentPage._id !== targetPageRoute._id
  ) {
    return (
      <>
        <Stack.Screen options={{ title: '' }} />
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
      </>
    );
  }

  return (
    <StaticPage targetPageDefaultLanguage={targetPageDefaultLanguage} currentPage={currentPage} />
  );
};

const StaticPage = ({
  targetPageDefaultLanguage,
  currentPage,
}: {
  targetPageDefaultLanguage?: 'en' | 'fr';
  currentPage: IStaticPage;
}) => {
  return (
    <StaticContentLanguageProvider defaultLanguage={targetPageDefaultLanguage}>
      <Box testID="static-page" flex={1}>
        <Head>
          <title>{`${currentPage.title} - Firehouse Subs`}</title>
        </Head>
        <Stack.Screen options={{ title: currentPage.title }} />
        <Container>
          <RenderCurrentStaticPage currentPage={currentPage} />
        </Container>
      </Box>
    </StaticContentLanguageProvider>
  );
};

/**
 * This function takes a pathname as param and render a Static Page component that can be embedded
 * inside a page / component so we're not limited to using routes for static content.
 */
export const StaticPageEmbedded = ({
  pathname,
  currentPageOverrideCss,
}: {
  pathname: string;
  currentPageOverrideCss?: string;
}) => {
  const { currentPage, getStaticPageByRouteId, is404 } = useGetStaticPageByRoute();

  useEffect(() => {
    getStaticPageByRouteId(pathname);
  }, [getStaticPageByRouteId, pathname]);

  if (is404) {
    return <NotFound />;
  }

  return (
    <Box testID="static-page-embeded" flex={1}>
      {currentPage ? (
        <RenderCurrentStaticPage
          currentPage={currentPage}
          currentPageOverrideCss={currentPageOverrideCss}
        />
      ) : (
        <LoadingContainer>
          <StaticPageSkeleton />
        </LoadingContainer>
      )}
    </Box>
  );
};

export default StaticPageContainer;
