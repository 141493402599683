import React from 'react';

import { useLoyaltyOffersUiLoggedOutQuery } from 'generated/sanity-graphql';
import { maybeLocale } from 'utils/graphql';

import { Body, Container, StyledButton, Title } from './loyalty-end-of-offers-logged-out.styled';

const LoyaltyEndOfOffersLoggedOut = () => {
  const { data } = useLoyaltyOffersUiLoggedOutQuery({
    variables: {
      id: 'feature-loyalty-offers-ui-singleton',
    },
  });

  const endOfOffersLoggedOut = data?.LoyaltyOffersUI?.endOfOffersLoggedOut;
  const title = maybeLocale(endOfOffersLoggedOut?.title);
  const body = maybeLocale(endOfOffersLoggedOut?.body);
  const buttonText = maybeLocale(endOfOffersLoggedOut?.ctaButtonText);
  const buttonLink = maybeLocale(endOfOffersLoggedOut?.link);

  return title ? (
    <Container>
      <Title>{title}</Title>
      {body && <Body>{body}</Body>}
      {buttonText && buttonLink && <StyledButton to={buttonLink}>{buttonText}</StyledButton>}
    </Container>
  ) : null;
};

export default LoyaltyEndOfOffersLoggedOut;
