import { StyleSheet, View } from 'react-native';

import { DatetimeShow, OrderDisplay, ScrollView, XStack, tokens } from '@fhs/ui';
import Footer from '@fhs-legacy/frontend/src/components/footer';
import { region } from '@fhs-legacy/frontend/src/utils/environment';

import {
  frenchDipCAUrl,
  frenchDipUSUrl,
  hotSauceBarCanadaEndDate,
  hotSauceBarCanadaStartDate,
} from './constants';
import { HomeAlert } from './home-alert';
import { LtoContentCA } from './lto-content/lto-content-ca';
import { LtoContentUS } from './lto-content/lto-content-us';
import {
  CareersCard,
  CateringCard,
  FrenchDipCard,
  HookAndLadderCard,
  HotSauceBarCard,
  PublicSafetyFoundationCard,
  RapidRescueCard,
} from './marketing-cards-desktop';
import { HotSauceBarCanadaCard } from './marketing-cards-desktop/hot-sauce-bar-canada-card';
import { ScreenHomeProps } from './types';

function MarketingCards({
  cateringHref,
  pickUpHref,
  foundationHref,
  careersHref,
}: {
  cateringHref: string;
  pickUpHref: string;
  foundationHref: string;
  careersHref: string;
  donateHref: string;
}) {
  return (
    <>
      <RapidRescueCard pickUpHref={pickUpHref} />
      {region() === 'US' && (
        <LtoContentUS
          ltoContent={<FrenchDipCard url={frenchDipUSUrl} />}
          // Default to nothing on desktop
          default={null}
        />
      )}
      {region() === 'CA' && (
        <LtoContentCA
          ltoContent={<FrenchDipCard url={frenchDipCAUrl} />}
          // Default to nothing on desktop
          default={null}
        />
      )}
      {region() === 'US' ? (
        <HotSauceBarCard />
      ) : (
        <DatetimeShow
          after={hotSauceBarCanadaStartDate}
          before={hotSauceBarCanadaEndDate}
          default={<PublicSafetyFoundationCard foundationHref={foundationHref} />}
        >
          <HotSauceBarCanadaCard />
        </DatetimeShow>
      )}
      <XStack>
        <CareersCard careersHref={careersHref} />
        <HookAndLadderCard pickUpHref={pickUpHref} />
      </XStack>
      {Boolean(cateringHref) && <CateringCard cateringHref={cateringHref} />}
    </>
  );
}

export function ScreenHomeDesktop(props: ScreenHomeProps) {
  const hasAlert = Boolean(props.alert?.text);

  return (
    <ScrollView contentContainerStyle={styles.wrapper}>
      <View style={styles.mainContent}>
        {hasAlert && <HomeAlert text={props.alert.text} link={props.alert.link} />}
        {props.userPointExpireAlert && props.userPointExpireAlert.text && (
          <HomeAlert
            text={props.userPointExpireAlert.text}
            link={props.userPointExpireAlert.link}
          />
        )}
        {props.displayedOrder && (
          <OrderDisplay
            type={props.displayedOrder.type}
            href={props.redirectHref}
            storeName={props.displayedOrder.storeName}
            items={props.displayedOrder.items}
            style={styles.orderDisplay}
            serviceModeTitle={props.displayedOrder.serviceModeTitle}
            reorderCallback={props.onReorder}
            reorderLoading={props.reorderLoading}
            logReorderCardPress={props.logReorderCardPress}
          />
        )}
        <MarketingCards
          cateringHref={props.cateringRedirectPath}
          pickUpHref={props.pickUpRedirectPath}
          foundationHref={props.foundationPath}
          careersHref={props.careersPath}
          donateHref={props.donateHref}
        />
      </View>
      <Footer />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: tokens.colors.$white,
    flexGrow: 1,
  },
  orderDisplay: {
    marginVertical: 24,
    marginHorizontal: 32,
  },
  mainContent: {
    maxWidth: 1920,
    marginHorizontal: 'auto',
    width: '100%',
    flexGrow: 1,
  },
  horizontalHalfCardsStack: {
    justifyContent: 'space-between',
  },
});
