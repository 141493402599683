import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { XStack, type XStackProps } from '../stack';

import { useQuantity } from './context';
import { QuantityCounterBox, type QuantityCounterBoxProps } from './quantity-box';
import { QuantityStepperButton } from './quantity-stepper-button';

type QuantityStepperProps = Partial<XStackProps> & {
  valueBoxProps?: QuantityCounterBoxProps;
};

export function QuantityStepper({ valueBoxProps, ...containerProps }: QuantityStepperProps) {
  const { accessibilityHint, label, disabled, value, onChangeValue, options } = useQuantity();

  const selectedOptionsIndex = useMemo(
    () => options.findIndex(opt => opt.value === value) ?? 0,
    [options, value]
  );

  const selectedOption = (options[selectedOptionsIndex] || options[0]) ?? {
    label: null,
    value: null,
  };

  const isDecrementDisabled = disabled || selectedOptionsIndex <= 0;
  const isIncrementDisabled = disabled || selectedOptionsIndex >= options.length - 1;

  const handleIncrement = () => {
    if (!isIncrementDisabled) {
      onChangeValue(options[selectedOptionsIndex + 1].value);
    }
  };

  const handleDecrement = () => {
    if (!isDecrementDisabled) {
      onChangeValue(options[selectedOptionsIndex - 1].value);
    }
  };

  return (
    <XStack
      accessible
      role="spinbutton"
      aria-disabled={disabled}
      aria-valuetext={String(value)}
      aria-label={label}
      accessibilityHint={accessibilityHint}
      accessibilityActions={[
        { name: 'increment', label: 'increment' },
        { name: 'decrement', label: 'decrement' },
      ]}
      onAccessibilityAction={event => {
        switch (event.nativeEvent.actionName) {
          case 'increment':
            handleIncrement();
            break;
          case 'decrement':
            handleIncrement();
            break;
        }
      }}
      {...containerProps}
      style={[styles.container, containerProps.style]}
    >
      <QuantityStepperButton
        disabled={isDecrementDisabled}
        iconName="REMOVE"
        handlePress={() => {
          handleDecrement();
        }}
      />

      <QuantityCounterBox value={selectedOption.label} {...valueBoxProps} />

      <QuantityStepperButton
        disabled={isIncrementDisabled}
        iconName="ADD"
        handlePress={() => {
          handleIncrement();
        }}
      />
    </XStack>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
});
