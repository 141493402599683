import { useState } from 'react';

export const useToggleState = (initialState = false): [boolean, () => void, () => void] => {
  const [state, setState] = useState(initialState);

  const toggleState = () => setState((currentState: boolean) => !currentState);
  const closeToggle = () => setState(false);

  return [state, toggleState, closeToggle];
};
