import { forwardRef } from 'react';
import { Platform, StyleSheet, View } from 'react-native';

import { tokens } from '../../tokens';
import { Pressable, type PressableProps } from '../pressable';

export type ListItemButtonProps = PressableProps;
export const ListItemButton = forwardRef<View, ListItemButtonProps>(function ListItem(
  props: PressableProps,
  forwardedRef
) {
  return <Pressable ref={forwardedRef} {...props} style={[styles.listItemButton, props.style]} />;
});

const styles = StyleSheet.create({
  listItemButton: {
    paddingVertical: 16,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    ...Platform.select({ web: { cursor: 'pointer' } }),
  },
});
