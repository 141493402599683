import { Maybe } from 'graphql/jsutils/Maybe';
import React, { FC } from 'react';

import { ILoyaltyFeaturedRewardsCarouselWidgetProps } from 'pages/loyalty/loyalty-widgets/loyalty-featured-rewards-carousel-widget/types';

export type Widget =
  | FC<React.PropsWithChildren<Omit<IWidgetKey, 'componentKey'>>>
  | FC<React.PropsWithChildren<ILoyaltyFeaturedRewardsCarouselWidgetProps>>;

export interface IWidgetElement {
  id: string;
  element: JSX.Element;
}

export type IWidgetMap<TWidgets extends string> = Record<TWidgets, Widget>;

export interface IWidgetKey {
  readonly componentKey?: Maybe<string>;
  readonly _key?: Maybe<string>;
  readonly [key: string]: any;
}

export const hasComponentKey = <T extends Object>(
  widget: Maybe<T & { componentKey?: Maybe<string> }>
): widget is T & { componentKey: string } => {
  return !!widget?.componentKey;
};
