import { SimplyOfferEvents } from '@fhs/loyalty';
import { ServiceMode } from '@rbi-ctg/menu';
import {
  AllowedEvent as AllowedEventMParticle,
  EventTypes,
  IEventBase,
  Restaurant,
} from '@rbilabs/mparticle-client';
import { REGIONS } from 'state/intl/types';
import { DeliveryFees } from 'state/service-mode/types';

import {
  CustomEventNames,
  DialogViewComponentNames,
  ErrorDialogViewComponentNames,
} from './constants';

interface IUpdateUserAttributes extends IEventBase {
  name: CustomEventNames.UPDATE_USER_ATTRIBUTES;
  type: EventTypes.UserContent;
}

interface ISaveNewPaymentMethod extends IEventBase {
  name: CustomEventNames.SAVE_NEW_PAYMENT_METHOD;
  type: EventTypes.UserContent;
}

interface IPageViewEvent extends IEventBase {
  quotedFeeCents?: number;
}

interface ISupportFormSubmitted extends IEventBase {
  name: CustomEventNames.SUPPORT_FORM_SUBMITTED;
  type: EventTypes.UserContent;
  attributes: {
    caseId: string;
    userEmail: string;
    orderDateTime?: string | undefined;
    userAccountRegion?: string;
    userRegion: REGIONS;
  };
}

interface IPypestreamSupportOpen extends IEventBase {
  name: CustomEventNames.PYPESTREAM_SUPPORT_OPEN;
  type: EventTypes.UserContent;
}

interface IStoreLocatorTabView extends IEventBase {
  name: CustomEventNames.STORE_LOCATOR_TAB_VIEW;
  type: EventTypes.Other;
}

export interface IStoreSelectedEventWithCache extends IEventBase {
  name: 'Store Selected';
  type: EventTypes.Other;
  attributes: Restaurant & { isCacheEnabled: boolean };
}

export interface IOffersListed extends IEventBase {
  name: CustomEventNames.OFFERS_LISTED;
  type: EventTypes.UserContent;
  attributes: {
    offers: { id: string; name: string }[];
  };
}

export interface IHomepageReorder extends IEventBase {
  name: CustomEventNames.HOMEPAGE_REORDER;
  type: EventTypes.Other;
}

export interface IChangeServiceMode extends IEventBase {
  name: CustomEventNames.SELECT_SERVICE_MODE;
  type: EventTypes.Other;
  attributes: IChangeServiceModeAttributes;
}

export type IChangeServiceModeAttributes = {
  oldServiceMode: ServiceMode;
  newServiceMode: ServiceMode;
};

export interface IDeliveryFeesSectionDisplayed extends IEventBase {
  name: CustomEventNames.DELIVERY_FEES_SECTION_DISPLAYED;
  type: EventTypes.Other;
  attributes: IDeliveryFeesSectionDisplayedAttributes;
}

export type IDeliveryFeesSectionDisplayedAttributes = {
  breakdownFeesEnabled?: boolean;
  donationCents?: number;
  fees?: DeliveryFees;
  taxCents?: number;
  tipCents?: number;
};

export interface IDeliveryStoreAssigned extends IEventBase {
  name: CustomEventNames.DELIVERY_STORE_ASSIGNED;
  type: EventTypes.Other;
  attributes: IDeliveryStoreAssignedAttributes;
}

export type IDeliveryStoreAssignedAttributes = {
  quotedFeeCents: number;
} & Restaurant;

export interface IDiscountNotApplied extends IEventBase {
  name: CustomEventNames.DISCOUNT_NOT_APPLIED;
  type: EventTypes.Other;
  attributes: {
    offer: { id: string; loyaltyEngineId: string; name: string };
  };
}

export interface IScheduleFutureOrderAttributes extends Record<string, any> {
  restaurantId: string | null;
  storeId: string | null;
  isRestaurantClosed: boolean;
  restaurantAddress: string | null;
  restaurantZip: string | null;
  restaurantCity: string | null;
  restaurantState: string | null;
  restaurantCountry: string | null;
}

export interface IScheduleFutureOrder extends IEventBase {
  name: CustomEventNames.SCHEDULE_FUTURE_ORDER;
  type: EventTypes.Other;
  attributes: IScheduleFutureOrderAttributes;
}

export interface IChangeRestaurantAttributes extends Record<string, any> {
  currentRestaurantId: string | null;
  currentStoreId: string | null;
  isCurrentRestaurantClosed: boolean;
  restaurantAddress: string | null;
  restaurantZip: string | null;
  restaurantCity: string | null;
  restaurantState: string | null;
  restaurantCountry: string | null;
}

export interface IChangeRestaurant extends IEventBase {
  name: CustomEventNames.CHANGE_RESTAURANT;
  type: EventTypes.Other;
  attributes: IChangeRestaurantAttributes;
}

export interface IErrorDialogView extends IEventBase {
  name: CustomEventNames.ERROR_DIALOG_VIEW;
  type: EventTypes.Other;
  attributes: {
    component: ErrorDialogViewComponentNames;
    errorMessage: string | string[];
  };
}

interface IDialogView extends IEventBase {
  name: CustomEventNames.DIALOG_VIEW;
  type: EventTypes.Other;
  attributes: {
    component: DialogViewComponentNames;
  };
}

export type AllowedEvent =
  | AllowedEventMParticle
  | IUpdateUserAttributes
  | ISaveNewPaymentMethod
  | ISupportFormSubmitted
  | IPypestreamSupportOpen
  | IStoreSelectedEventWithCache
  | IStoreLocatorTabView
  | IOffersListed
  | IHomepageReorder
  | IChangeServiceMode
  | IDeliveryFeesSectionDisplayed
  | IDiscountNotApplied
  | IErrorDialogView
  | IScheduleFutureOrder
  | IChangeRestaurant
  | IDialogView
  | IPageViewEvent
  | SimplyOfferEvents;

export interface ILogRBIEvent {
  (event: AllowedEvent): void;
}

export enum SignInPhases {
  START = 'start',
  COMPLETE = 'complete',
}

/**
 * Used for attribution tracking
 */
export interface IUtmParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

interface IUtmParamsAttributes {
  'UTM Source'?: string;
  'UTM Medium'?: string;
  'UTM Campaign'?: string;
  'UTM Term'?: string;
  'UTM Content'?: string;
}

export interface ICRMEventsUniversalAttributes extends IUtmParamsAttributes {
  'Service Mode': string;
  'Pickup Mode': string;
  isSmallScreen: boolean;
  isMobileWeb: boolean;
  currentBuild: string;
}

export interface ICRMEventsAttributes extends IUtmParamsAttributes {
  brand?: string;
  region?: string;
  env?: string;
  'Date of Birth'?: string;
  'Email Opt In'?: string;
  ccToken?: string;
  favoriteStores?: string;
  language?: string;
  Locale?: string;
  'Legacy User'?: string;
  'RBI Cognito ID'?: string;
  'IOS Location Permissions'?: string;
  'Android Location Permissions'?: string;
}

export interface IFeatureFlags {
  isSimplyBetterMenuListViewEnabled: boolean;
  isSimplyBetterMenuDetailViewEnabled: boolean;
  isSimplyBetterOffersUIEnabled: boolean;
  isSimplyBetterCartServiceEnabled: boolean;
  isSimplyBetterCartServiceOffersEnabled: boolean;
}
