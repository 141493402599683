import { Image, ImageProps } from 'expo-image';
import { Link } from 'expo-router';
import { Pressable, StyleSheet } from 'react-native';

export type CareersCardProps = Omit<ImageProps, 'children' | 'source'> & {
  careersHref: string;
};

export function CareersCard({ careersHref, ...imageProps }: CareersCardProps) {
  return (
    <Link href={careersHref} target="_blank" asChild>
      <Pressable>
        <Image
          source={require('./careers-banner.webp')}
          placeholder={{ thumbhash: 'ligKF4QXZ4ZgipeYdYmnKHeaWRC3BYAM' }}
          {...imageProps}
          style={[styles.careersCard, imageProps.style]}
        />
      </Pressable>
    </Link>
  );
}

const styles = StyleSheet.create({
  careersCard: {
    aspectRatio: 1354 / 1270,
  },
});
