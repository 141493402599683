import React from 'react';
import { useIntl } from 'react-intl';

import { IBackendCartEntries } from '@rbi-ctg/menu';
import Currency from 'components/currency';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { EnablePremiumComboSlotsVariations } from 'state/launchdarkly/variations';
import { theme } from 'styles/configure-theme';
import priceForCartEntry from 'utils/menu/price-for-cart-entry';

import { Item, ItemHeader, ItemName, ItemPrice } from './cart-entry.styled';
import resolveEntryChild from './resolve-entry-child';

interface ICartEntry {
  entry: IBackendCartEntries;
  currencyFormatter: Function;
  index?: number;
}

const CartEntry = ({ entry, currencyFormatter }: ICartEntry) => {
  const quantity = entry.quantity || 1;
  const amount = priceForCartEntry(entry);
  const enablePremiumComboSlotsVariation = useFlag<EnablePremiumComboSlotsVariations>(
    LaunchDarklyFlag.ENABLE_PREMIUM_COMBO_SLOTS
  );
  const enableShowAllPrices =
    enablePremiumComboSlotsVariation !== EnablePremiumComboSlotsVariations.NONE;
  const { formatMessage } = useIntl();

  return (
    <Item>
      <ItemHeader>
        <ItemName>
          {quantity > 1 && `${quantity}x `}
          {entry.isDonation ? formatMessage({ id: 'donation', description: '' }) : entry.name}
        </ItemName>
        <ItemPrice>
          <Currency
            amount={amount}
            textProps={{
              ...(amount === 0 && { color: theme.token('icon-default') }),
              variant: 'headerFour',
              margin: 0,
              textAlign: 'right',
            }}
          />
        </ItemPrice>
      </ItemHeader>

      {resolveEntryChild(entry, currencyFormatter, enableShowAllPrices)}
    </Item>
  );
};

export default CartEntry;
