export enum ITU_COUNTRY_CODES {
  CA = '+1',
  DE = '+49',
  GB = '+44',
  US = '+1',
  CH = '+41',
  ZA = '+27',
  NZ = '+64',
}

export const PHONE_NUMBER_LENGTH = 10;
