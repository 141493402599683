import { Text, VStack } from '@fhs-legacy/universal-components';
import Link from 'components/link';

export const SignUpHeadlineContainer = VStack.withConfig({
  marginBottom: '9',
  paddingTop: {
    legacy_sm: '6',
    desktop: '20',
  },
});

export const GetStartedText = Text.withConfig({
  variant: 'copyTwo',
  textAlign: 'center',
  marginBottom: '1px',
});

export const RewardsLinkStyled = Link.withConfig({
  isInline: true,
  isUnderlined: true,
  _text: {
    variant: 'copyTwo',
    textDecoration: 'underline',
    fontSize: 'xs',
    paddingTop: '3px',
  },
});

export const LogoLink = Link.withConfig({
  justifyContent: 'center',
});
