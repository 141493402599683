import { useField } from 'formik';
import React from 'react';

import { usePropsWithFormikFormControlName } from '@fhs-legacy/universal-components';
import { CountrySelect } from 'components/country-select';
import { ICountrySelectBaseProps } from 'components/country-select/types';

export const FormikCountrySelect: React.FC<
  React.PropsWithChildren<ICountrySelectBaseProps>
> = props => {
  const { name, ...rest } = usePropsWithFormikFormControlName(props);
  const [field, meta] = useField(name);

  return (
    <CountrySelect
      {...field}
      errorMessage={meta.touched && meta.error ? meta.error : undefined}
      {...rest}
    />
  );
};
