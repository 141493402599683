import { ReactElement } from 'react';
import { GestureResponderEvent } from 'react-native';

import { ButtonProps, IconProps } from '@fhs-legacy/universal-components';
import { HapticsImpactStyle } from 'utils/haptic';

export enum ActionButtonVariants {
  PRIMARY,
  OUTLINE,
  INVERSE,
  BOX_SHADOW_INVERSE,
  BOX_SHADOW_PRIMARY,
  TEXT_ONLY,
  OUTLINE_REVERSED,
}

export enum ActionButtonSizes {
  SMALL = 'small',
  LARGE = 'large',
}

type AttributesMap = { [key: string]: string | number | null };

export type IconElementProp = ReactElement<IconProps>;

export interface IActionButtonProps
  extends Omit<
    ButtonProps,
    'onPress' | 'variant' | 'size' | 'rightIcon' | 'leftIcon' | 'startIcon' | 'endIcon'
  > {
  eventAttributes?: AttributesMap;
  size?: ActionButtonSizes;
  onPress?: (ev: GestureResponderEvent) => void;
  onNonVisualPress?: (ev: GestureResponderEvent) => void;
  isLoading?: boolean;
  noLogOnLongLoading?: boolean;
  testID?: string;
  to?: string | { pathname: string; params: Record<string, string> };
  replace?: boolean;
  linkPath?: string;
  perceptible?: boolean;
  hapticFeedbackStyle?: HapticsImpactStyle;
  variant?: ActionButtonVariants;
  tabIndex?: 0 | -1 | undefined;
  fullWidth?: boolean;
  leftIcon?: IconElementProp;
  rightIcon?: IconElementProp;
  startIcon?: IconElementProp;
  endIcon?: IconElementProp;
}

export interface IActionLinkProps extends IActionButtonProps {
  to: string;
}
