import { useMemo } from 'react';

import { MenuObjectTypes } from 'enums/menu';
import { useDayPartContext } from 'state/day-part';
import { useProductWizardContext } from 'state/product-wizard';

export const useProductAvailability = () => {
  const { activeDayParts, dayParts } = useDayPartContext();
  const { selectedProduct } = useProductWizardContext();

  const itemDayParts = useMemo(() => {
    if (selectedProduct._type === MenuObjectTypes.ITEM) {
      return selectedProduct?.operationalItem?.daypart || [];
    }
    if (selectedProduct._type === MenuObjectTypes.COMBO) {
      return selectedProduct?.mainItem?.operationalItem?.daypart || [];
    }
    return [];
  }, [selectedProduct]);

  const isItemAvailableForDaypart =
    !itemDayParts.length || // item not associated with any daypart
    !dayParts.length || // brand has no dayparts
    activeDayParts.some(daypart => daypart?.key && itemDayParts.includes(daypart?.key));

  return {
    dayParts,
    isItemAvailableForDaypart,
    itemDayParts,
  };
};
