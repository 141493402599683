import { CurrentSelectedMethodType } from '../types';

type GetCurrentSelectedMethodTypeParams = {
  isAddGiftCardMethodSelected: boolean;
  isAddNewCardMethodSelected: boolean;
  selectedMethodExist: boolean;
};

function getCurrentSelectedMethodType({
  isAddGiftCardMethodSelected,
  isAddNewCardMethodSelected,
  selectedMethodExist,
}: GetCurrentSelectedMethodTypeParams): CurrentSelectedMethodType {
  let currentSelectedMethodType = CurrentSelectedMethodType.ADD_NEW_PAYMENT_METHOD;
  if (isAddGiftCardMethodSelected) {
    currentSelectedMethodType = CurrentSelectedMethodType.ADD_NEW_GIFT_CARD;
  }
  if (isAddNewCardMethodSelected) {
    currentSelectedMethodType = CurrentSelectedMethodType.ADD_NEW_CARD;
  }
  if (selectedMethodExist) {
    currentSelectedMethodType = CurrentSelectedMethodType.SELECTED_PAYMENT_METHOD_OPTION;
  }
  return currentSelectedMethodType;
}

export default getCurrentSelectedMethodType;
