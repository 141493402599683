import { ApolloError } from '@apollo/client';
import { router } from 'expo-router';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import Link from 'components/link';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { ModalCb } from 'hooks/use-error-modal';
import useRoutes from 'hooks/use-routes';
import {
  CONTACT_US_ROUTE_KEY,
  getErrorDialogOptions,
} from 'pages/cart/payment/get-error-dialog-options';
import { GraphQLErrorCodes, parseGraphQLErrorCodes } from 'utils/errors';
import { GraphQLError } from 'utils/network';
import { routes } from 'utils/routing';

export interface IHandleCommitErrorInput {
  commitError: ApolloError | GraphQLError | undefined;
  dismissErrorDialog: () => void;
  errorMsgId: string;
  onCoolingPeriodConfirm: () => void;
  openErrorDialog: ModalCb;
  confirmationTextId?: 'placeOrder' | 'continue';
}

export const useHandleCommitError = ({
  commitError,
  dismissErrorDialog,
  errorMsgId,
  onCoolingPeriodConfirm,
  openErrorDialog,
  confirmationTextId = 'placeOrder',
}: IHandleCommitErrorInput) => {
  const { getLocalizedRouteForPath } = useRoutes();
  const { formatMessage } = useIntl();
  const handleCommitError = useCallback(
    (error: ApolloError | GraphQLError) => {
      let errorDialog;
      const errorCode = parseGraphQLErrorCodes(error)[0]?.errorCode;

      if (errorCode === GraphQLErrorCodes.COMMIT_ORDER_COOLING_PERIOD) {
        errorDialog = {
          body: formatMessage({ id: 'errorDialogCooldownMessage' }),
          title: formatMessage({ id: 'errorDialogCooldownTitle' }),
          message: formatMessage({ id: 'errorDialogCooldownMessage' }),
          dismissText: formatMessage({ id: 'recentOrders' }),
          confirmationText: formatMessage({ id: confirmationTextId }),
          onDismiss: () => router.navigate(routes.orders),
          onConfirm: () => {
            dismissErrorDialog();
            onCoolingPeriodConfirm();
          },
        };
      } else {
        const body = formatMessage(
          { id: errorMsgId },
          {
            link: (
              <Link
                to={getLocalizedRouteForPath(CONTACT_US_ROUTE_KEY)}
                onPress={dismissErrorDialog}
              >
                {formatMessage({ id: 'here' })}
              </Link>
            ),
          }
        );

        errorDialog = getErrorDialogOptions({ body, error, formatMessage, errorMsgId });
      }

      openErrorDialog(errorDialog);
    },
    [
      openErrorDialog,
      formatMessage,
      confirmationTextId,

      dismissErrorDialog,
      onCoolingPeriodConfirm,
      errorMsgId,
      getLocalizedRouteForPath,
    ]
  );

  useEffectOnUpdates(() => {
    if (commitError) {
      handleCommitError(commitError);
    }
  }, [commitError]);

  return { handleCommitError };
};
