import { Box } from '@fhs-legacy/universal-components';

export const QuantityDetail = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  borderTopWidth: 1,
  borderTopColor: Styles.color.grey.four,
  borderStyle: 'solid',
  height: '$10',
  paddingX: 1.5,
  paddingY: 0,
  margin: 0,
  width: 'full',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '100%',
});

export const ModifierSelectionPrice = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'center',

  _text: {
    fontSize: 'md',
    fontFamily: Styles.fontFamily.body,
  },
});
