import React, { FC } from 'react';

import { Icon } from '@fhs-legacy/universal-components';
import List, { ListItem, ListItemTitle } from 'components/list';
import { RotateAnimatedView } from 'components/ucl';
import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import noop from 'utils/noop';

import { accordionItemTestIds } from './constants';
import {
  AccordionActivatorButton,
  AccordionContentWrapper,
  AccordionWrapper,
  SpreadContentContainer,
} from './styled';
import { IAccordionItemProps, IconType } from './types';
import { useAccordionItem } from './use-accordion-item';

/**
 * Wraps a list of `AccordionItem`s
 *
 */
export const Accordion: FC<React.PropsWithChildren<unknown>> = props => (
  <List {...props} testID="accordion" />
);

const {
  accordionActivatorButtonTestId,
  accordionItemContentTestId,
  accordionItemTestId,
  accordionItemTitleTestId,
} = accordionItemTestIds;

/**
 * Accordion item shows expandable content when clicked.
 */
export const AccordionItem: FC<React.PropsWithChildren<IAccordionItemProps>> = ({
  contentWrapper: ContentWrapper = AccordionContentWrapper,
  activeHighlight,
  children,
  onInteract = noop,
  title,
  iconType,
  iconFromDeg = 0,
  autoExpand = false,
  _accordionActivatorButton,
  _listItemTitle,
  ...props
}) => {
  const { handleToggle, isActive } = useAccordionItem({ onInteract, defaultValue: autoExpand });

  let accordionIcon = (
    <RotateAnimatedView isRotated={isActive} toDeg={45}>
      <Icon
        variant="add"
        color={theme.token('icon-default')}
        width="24px"
        {...hiddenAccessibilityPlatformProps}
      />
    </RotateAnimatedView>
  );
  if (iconType === IconType.ARROW) {
    accordionIcon = (
      <RotateAnimatedView isRotated={isActive} fromDeg={iconFromDeg} toDeg={90}>
        <Icon
          variant="next"
          color={theme.token('icon-default')}
          width="16px"
          {...hiddenAccessibilityPlatformProps}
        />
      </RotateAnimatedView>
    );
  }

  return (
    <AccordionWrapper>
      <ListItem
        {...props}
        activeHighlight={activeHighlight && isActive}
        testID={accordionItemTestId}
      >
        <AccordionActivatorButton
          onPress={() => handleToggle()}
          testID={accordionActivatorButtonTestId}
          {..._accordionActivatorButton}
        >
          <SpreadContentContainer>
            <ListItemTitle testID={accordionItemTitleTestId} {..._listItemTitle}>
              {title}
            </ListItemTitle>
            {accordionIcon}
          </SpreadContentContainer>
        </AccordionActivatorButton>
        {isActive && (
          <ContentWrapper testID={accordionItemContentTestId}>{children}</ContentWrapper>
        )}
      </ListItem>
    </AccordionWrapper>
  );
};
