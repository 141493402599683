import { Path, Rect, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconPaymentTypeDiscover = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Rect width={24} height={16} y={4.101} fill="#fff" rx={2} />
      <Rect width={23} height={15} x={0.5} y={4.601} stroke="#000" strokeOpacity={0.1} rx={1.5} />
      <Path fill="#FD6020" d="m4 19.1 19-6v5a1 1 0 0 1-1 1H4Z" />
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.63 10.175c.739 0 1.145.322 1.145.93a.875.875 0 0 1-.738.932l.996 1.325h-.775l-.848-1.29h-.074v1.29h-.627v-3.188h.922Zm-.295 1.468h.185c.406 0 .59-.18.59-.501 0-.287-.184-.466-.59-.466h-.185v.967Zm-2.803 1.72h1.77v-.538H18.16v-.86h1.107v-.537h-1.107v-.716h1.144v-.537h-1.771v3.187Zm-1.845-1.04-.849-2.149h-.664l1.365 3.26h.332l1.365-3.26h-.664l-.885 2.15Zm-7.489-.537a1.66 1.66 0 0 0 1.66 1.648c.296 0 .554-.072.812-.18v-.715a.973.973 0 0 1-.738.358c-.59 0-1.07-.43-1.07-1.003v-.072c-.037-.573.443-1.074 1.033-1.11.295 0 .59.143.775.358v-.716c-.221-.144-.516-.18-.775-.18-.959-.071-1.697.681-1.697 1.612Zm-1.144-.394c-.369-.143-.48-.215-.48-.394.037-.215.222-.394.443-.358.185 0 .37.108.517.25l.332-.429c-.258-.215-.59-.358-.923-.358a.936.936 0 0 0-.996.86v.035c0 .43.185.68.775.86.148.036.295.107.443.179.11.072.184.18.184.322 0 .251-.221.466-.442.466H6.87a.73.73 0 0 1-.664-.43l-.406.394c.221.394.664.61 1.107.61a1.03 1.03 0 0 0 1.106-.968v-.107c-.037-.43-.221-.645-.959-.932Zm-2.14 1.97h.628v-3.188h-.627v3.188ZM2 10.174h1.107c.885.036 1.586.753 1.55 1.612 0 .466-.222.896-.591 1.218-.332.25-.738.394-1.144.358H2v-3.188Zm.811 2.651c.296.036.628-.072.849-.25.221-.216.332-.502.332-.825 0-.286-.11-.573-.332-.788a1.193 1.193 0 0 0-.849-.25h-.184v2.113h.184Z"
        clipRule="evenodd"
      />
      <Path
        fill="#FD6020"
        fillRule="evenodd"
        d="M12.552 10.1c-.923 0-1.697.717-1.697 1.648 0 .896.737 1.648 1.697 1.684.959.035 1.697-.717 1.734-1.648-.037-.931-.775-1.683-1.734-1.683Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
