import { useEffect } from 'react';

import { useGeolocation } from 'state/geolocation';
import { useMaybeOsActionHandler } from 'utils/os-action';

export function useAutoPromptLocationPermission() {
  const { isPermissionKnown } = useGeolocation();

  const handleMaybeOsAction = useMaybeOsActionHandler();

  useEffect(() => {
    if (!isPermissionKnown) {
      // Ask for location permission if user has not already answered previously
      handleMaybeOsAction('/os/location');
    }
  }, [isPermissionKnown, handleMaybeOsAction]);
}
