import { FC } from 'react';
import { useIntl } from 'react-intl';

import { MqSwitch } from '@fhs/ui';
import { Text } from '@fhs-legacy/universal-components';
import { useFeatureMenu } from 'hooks/use-feature-menu';

import {
  ProductCustomizationDrawerClose,
  ProductCustomizationDrawerHeaderContainer,
} from './product-customization.styled';
import { IProductCustomizationDrawerHeaderProps } from './types';

export const ProductCustomizationDrawerHeader: FC<IProductCustomizationDrawerHeaderProps> = ({
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const { featureMenu } = useFeatureMenu();
  const title =
    featureMenu?.menuSummaryHeaderModifiable?.locale ||
    formatMessage({ id: 'productDetailSummaryCustomizableTitle' });

  return (
    <MqSwitch
      $base={() => null}
      $gteDesktop={() => (
        <ProductCustomizationDrawerHeaderContainer>
          <ProductCustomizationDrawerClose
            variant="cancel"
            onPress={onClose}
            accessibilityLabel={formatMessage({ id: 'close' })}
            accessibilityRole="button"
          />
          <Text color="white" fontWeight="bold">
            {title}
          </Text>
        </ProductCustomizationDrawerHeaderContainer>
      )}
    />
  );
};
