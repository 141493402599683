import { withStaticProperties } from '@fhs/utils';

import { Text as _Text } from './text';
import { HeadingText } from './text-heading';
import { ParagraphText } from './text-paragraph';
import { UiText } from './text-ui';

export type { TextProps } from './text';
export type { UiTextProps } from './text-ui';
export type { HeadingTextProps } from './text-heading';
export type { ParagraphTextProps } from './text-paragraph';

export const Text = withStaticProperties(_Text, {
  Ui: UiText,
  Heading: HeadingText,
  Paragraph: ParagraphText,
});
