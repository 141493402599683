import { Box, HStack, Text } from '@fhs-legacy/universal-components';

export const Wrapper = HStack.withConfig({
  padding: '$5',
  marginBottom: '$4',
  backgroundColor: Styles.color.cardBackground,
  justifyContent: 'center',
  alignItems: 'center',
  width: 'full',
  borderRadius: 'lg',
});

export const TextWrapper = Box.withConfig({
  marginLeft: '$4',
  textAlign: 'left',
});

export const ReminderTitle = Text.withConfig({
  fontSize: 'xl',
});

export const ReminderSubtitle = Text.withConfig({
  fontSize: 'xs',
});
