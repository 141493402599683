import { IFeatureAccountFormQuery, useFeatureAccountFormQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

interface IUseFeatureAccountFormValue {
  featureAccountFormLoading: boolean;
  featureAccountForm: IFeatureAccountFormQuery['FeatureAccountForm'];
}

export const useFeatureAccountForm = (): IUseFeatureAccountFormValue => {
  const { featureAccountFormId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useFeatureAccountFormQuery({
    variables: { featureAccountFormId },
    skip: !featureAccountFormId,
  });

  const featureAccountFormLoading = featureIdsLoading || loading;
  const featureAccountForm = data?.FeatureAccountForm ?? null;

  return {
    featureAccountForm,
    featureAccountFormLoading,
  };
};
