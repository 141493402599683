import { IRestaurantNode, useGetRecentRestaurantsQuery } from 'generated/graphql-gateway';

export const useGetRecentRestaurants = () => {
  const { data, loading, error } = useGetRecentRestaurantsQuery({
    variables: {
      input: {
        first: 20,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return {
    restaurants: (data?.recentRestaurants?.nodes as IRestaurantNode[]) || [],
    loading,
    error,
  };
};
