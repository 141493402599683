import { useControllableState } from '../../hooks';
import { addWithConfig } from '../../utils';
import { Box } from '../box';
import { FlatList } from '../flat-list';
import { Header } from '../header';
import { Icon } from '../icon';
import { Pressable } from '../pressable';
import { Text } from '../text';
import { VStack } from '../v-stack';

import { TimePickerProps, _TimePickerPressableProps } from './types';

const TimePickerPressable = ({
  isSelected,
  accessibilityLabel,
  isDisabled,
  time,
  ...props
}: _TimePickerPressableProps) => {
  return (
    <Pressable
      testID="time-picker-pressable"
      accessibilityRole="radio"
      accessibilityLabel={accessibilityLabel}
      accessibilityHint=""
      accessibilityState={{ selected: isSelected, disabled: isDisabled }}
      isDisabled={isDisabled}
      flexDir="row"
      p="$4"
      borderColor="token.border-color-default"
      borderBottomWidth={1}
      width="full"
      _pressed={{ bg: 'token.background-hover' }}
      _hover={{ bg: 'token.background-hover' }}
      height="52px"
      alignItems="center"
      {...props}
    >
      {isSelected && !isDisabled && <Icon variant="check" color="token.icon-active" mr="$2" />}
      <Text fontSize="14px" color={isDisabled ? 'token.text-disabled' : 'token.text-default'}>
        {time}
      </Text>
    </Pressable>
  );
};
export const TimePicker = <ValueType extends string>({
  accessibilityHint,
  accessibilityLabel,
  header,
  data,
  defaultValue,
  value,
  onChangeValue,
  warningMessage,
  ...boxProps
}: TimePickerProps<ValueType>) => {
  const [selectedTime, setSelectedTime] = useControllableState({
    defaultValue: defaultValue || data[0].value,
    value,
    handleValueChange: onChangeValue,
  });

  return (
    <VStack
      width="full"
      accessibilityLabel={accessibilityLabel}
      accessibilityHint={accessibilityHint}
      accessibilityRole="radiogroup"
      {...boxProps}
    >
      <Header variant="headerTwo">{header}</Header>
      {warningMessage && (
        <Text fontSize="14px" color="errorRed" marginBottom="$2">
          {warningMessage}
        </Text>
      )}
      <Box maxH="260px">
        <FlatList
          data={data}
          keyExtractor={item => item.value}
          renderItem={({ item }) => {
            return (
              <TimePickerPressable
                key={item.value}
                isSelected={selectedTime === item.value}
                onPress={() => setSelectedTime(item.value)}
                time={item.time}
                isDisabled={item.isDisabled || false}
                accessibilityLabel={item.accessibilityLabel}
                accessibilityHint=""
              />
            );
          }}
        />
      </Box>
    </VStack>
  );
};

export default addWithConfig(TimePicker);
