import React, { useCallback, useEffect, useState } from 'react';

import { loadScript } from 'components/payments/integrations/hooks/utils';
import { useConfigValue } from 'hooks/configs/use-config-value';

import { IEncryptionConfig, ISafetechEncryption } from './types';
import { ensureSafetechConfig } from './utils';

declare global {
  interface Window {
    ProtectPANandCVV: Function;
    ValidatePANChecksum: Function;
    PIE: {
      key_id: string;
      phase: string;
    };
    encrypt: Function;
  }
}

const ENCRYPTION_FAILED_ERROR = {
  error: 'Encryption failed',
};

const INVALID_PAN_CHECKSUM_ERROR = {
  error: 'PAN has invalid checksum',
};

const SafetechEncryptionInteractive: React.FC<ISafetechEncryption> = ({
  cardNumber,
  cvv,
  onResult,
  onError,
}) => {
  const config: IEncryptionConfig = useConfigValue({
    key: 'orbital',
    defaultValue: null,
    isRegionalized: true,
  });

  const [isInit, setIsInit] = useState(false);

  const loadScripts = useCallback(async () => {
    ensureSafetechConfig(config);
    const { baseUrl, pieFormat, subscriberId } = config;

    const getKeyScriptUrl: string = `${baseUrl}/pie/v1/${pieFormat}${subscriberId}/getkey.js`;
    const encryptionScriptUrl: string = `${baseUrl}/pie/v1/encryption.js`;

    await loadScript(getKeyScriptUrl);
    await loadScript(encryptionScriptUrl);
  }, [config]);

  const encryptCard = useCallback(async () => {
    const hasValidChecksum = await window.ValidatePANChecksum(cardNumber);
    if (!hasValidChecksum) {
      return onError(ENCRYPTION_FAILED_ERROR);
    }
    const embed = config.mode === 'eFPE';
    const result = await window.ProtectPANandCVV(cardNumber, cvv, !embed);
    if (!result) {
      return onError(INVALID_PAN_CHECKSUM_ERROR);
    }

    const [cryptCard, cryptCvv, integrityCheck] = result;

    onResult({
      cryptCard,
      cryptCvv,
      integrityCheck,
      pieFormat: config.pieFormat,
      subscriberId: config.subscriberId,
      mode: config.mode,
      keyId: window.PIE.key_id,
      phase: parseInt(window.PIE.phase, 10),
    });
  }, [cardNumber, config.mode, config.pieFormat, config.subscriberId, cvv, onError, onResult]);

  useEffect(() => {
    if (!isInit) {
      setIsInit(true);
      loadScripts().then(() => {
        encryptCard().catch(() => {
          onError(ENCRYPTION_FAILED_ERROR);
        });
      });
    } else {
      encryptCard().catch(() => {
        onError(ENCRYPTION_FAILED_ERROR);
      });
    }
  }, [cardNumber, cvv, config, encryptCard, isInit, loadScripts, onError]);

  return null;
};

export default SafetechEncryptionInteractive;
