import { useIntl } from 'react-intl';

import { HStack } from '@fhs-legacy/universal-components';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

import { Points, StyledPointsIcon } from './loyalty-points.styled';

const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const LoyaltyPoints = () => {
  const { loyaltyUser } = useLoyaltyUser();
  const { formatMessage } = useIntl();
  return (
    <HStack>
      <StyledPointsIcon />
      <Points accessibilityLabel={`${loyaltyUser?.points} ${formatMessage({ id: 'points' })}`}>
        {loyaltyUser?.points && numberWithCommas(loyaltyUser.points)}
      </Points>
    </HStack>
  );
};
