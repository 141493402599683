import React from 'react';

import { Svg, SvgPath, SvgProps } from '@fhs-legacy/universal-components';

export const FhsLocationSvg = (props: SvgProps) => (
  <Svg {...props} viewBox="0 0 61 88" width={61} height={88}>
    <SvgPath
      d="M30.709 19.084c6.328 0 11.458 5.13 11.458 11.459C42.167 36.87 37.037 42 30.709 42S19.25 36.87 19.25 30.543c0-6.329 5.13-11.459 11.459-11.459zm0 4.584a6.875 6.875 0 100 13.75 6.875 6.875 0 000-13.75zm-21.54 19.97l21.54 35.427 21.54-35.426.002.002a25.091 25.091 0 003.666-13.098c0-13.923-11.286-25.209-25.208-25.209C16.787 5.334 5.5 16.62 5.5 30.543c0 4.796 1.34 9.28 3.666 13.098l.003-.002zm46.995 2.382L30.71 87.887 5.253 46.02l-.004.002a29.653 29.653 0 01-4.332-15.48C.917 14.09 14.255.752 30.709.752 47.162.75 60.5 14.089 60.5 30.543a29.655 29.655 0 01-4.332 15.48l-.004-.003z"
      fill="#000"
    />
  </Svg>
);
