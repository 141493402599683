export * from './get-directions-app-uri';

declare interface IBillingAddress {
  city: string;
  street: string;
  country: string;
  postalCode: string;
  state: string;
}
export const buildFormattedBillingAddress = ({
  city,
  street,
  country,
  postalCode,
  state,
}: IBillingAddress) => {
  return `${street}, ${city}, ${state}, ${postalCode}, ${country}`;
};
