import { Link } from 'expo-router';

import { Button, ButtonProps, IconClose } from '@fhs/ui';

type CloseButtonProps = Omit<ButtonProps, 'children' | 'type'>;
export function CloseButton(props: CloseButtonProps) {
  return (
    <Link href="../" asChild>
      <Button aria-label="Close" size="xl" type="ghost" {...props}>
        <Button.Icon>
          <IconClose />
        </Button.Icon>
      </Button>
    </Link>
  );
}
