import * as React from 'react';
import { FC, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import Picture from 'components/picture';
import { IncentiveSource } from 'state/loyalty/types';

import { OffersServiceMode } from '../../loyalty-offers/types';
import { getServiceModeText } from '../../loyalty-offers/utils';
import { ILoyaltyRewardsViewProps } from '../../loyalty-rewards/types';
import { IncentiveCardDescription } from '../incentive-card/incentive-card.styled';
import { ParsedRewardData } from '../incentive-details/types';

import OffersListItem from './offer-list-item';

export interface ILoyaltyIOffersItem {
  handlePress: ILoyaltyRewardsViewProps['handleOnPress'];
  incentiveData: ParsedRewardData;
}

export const LoyaltyOffersItem: FC<ILoyaltyIOffersItem> = memo(
  ({ handlePress, incentiveData }) => {
    const { formatMessage } = useIntl();

    const {
      image,
      imageDescription,
      name: incentiveName,
      id: incentiveSanityId,
      loyaltyEngineId: incentiveId,
      description,
      offerServiceMode,
      metadata,
    } = incentiveData;

    const serviceModeText = getServiceModeText(offerServiceMode, formatMessage);

    const onPress = useCallback(() => {
      handlePress(incentiveId, incentiveName, incentiveSanityId);
    }, [handlePress, incentiveId, incentiveName, incentiveSanityId]);

    const showPromoCodeLabel = metadata?.some(
      data => data?.value === IncentiveSource.PROMOTION_CODE
    );
    const promoCodeLabel = showPromoCodeLabel
      ? formatMessage({ id: 'promoCodeBadgeLabel' })
      : undefined;

    return (
      <OffersListItem
        image={
          <Picture
            image={image}
            alt={imageDescription}
            height="97px"
            width="97px"
            objectFitContain
          />
        }
        header={incentiveName}
        description={description && <IncentiveCardDescription content={description} />}
        serviceModeStyle={
          offerServiceMode === OffersServiceMode.DELIVERY_ONLY ? 'default-outline' : 'default-solid'
        }
        serviceModeText={serviceModeText}
        promoCodeLabel={promoCodeLabel}
        onPress={onPress}
        marginBottom={{ base: '$2', desktop: '$4' }}
      />
    );
  },
  (prev, next) => prev.incentiveData.id === next.incentiveData.id
);
