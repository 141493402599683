import { router, useLocalSearchParams } from 'expo-router';
import React, { useMemo } from 'react';

import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';
import { useGetRestaurantQuery } from 'generated/graphql-gateway';
import { useGetRestaurantsQuery } from 'generated/sanity-graphql';
import useErrorModal from 'hooks/use-error-modal';
import NotFound from 'pages/not-found';
import { mergeRestaurantData } from 'utils/restaurant';

import { ModalBody } from './modal-body';
import { ModalFooter } from './modal-footer';
import { ModalHeader } from './modal-header';
import { IStoreInfoModalProps } from './types';

export const StoreInfoModal = ({ onDismiss, store, loading }: IStoreInfoModalProps) => {
  if (!loading && !store) {
    return <NotFound />;
  }
  if (!loading && store) {
    return (
      <>
        <ModalHeader store={store} />
        <ModalBody store={store} />
        <ModalFooter showOrderCTA store={store} onDismiss={onDismiss} />
      </>
    );
  }
  return (
    <LoadingContainer>
      <LoadingAnimation />
    </LoadingContainer>
  );
};

const StoreInfoModalContainer = () => {
  const params = useLocalSearchParams<{
    idOrNumber?: string;
    storeNumber?: string;
    slug?: string;
  }>();

  const { idOrNumber: storeIdOrNumberFromUrl, storeNumber: storeNumberFromUrl, slug } = params;

  const id = slug ? undefined : storeIdOrNumberFromUrl;
  const storeNumber = slug ? storeIdOrNumberFromUrl : storeNumberFromUrl;

  const [ErrorDialog] = useErrorModal({
    init: true,
    modalAppearanceEventMessage: 'Error: Fetching Restaurant Info Failure',
  });
  const getRestaurantFromSanity = useGetRestaurantsQuery({
    skip: !storeNumber && !id,
    variables: {
      filter: {
        _id: id,
        number: storeNumber,
      },
      limit: 1,
    },
  });
  const secondQueryVariables = useMemo(
    () => ({
      storeId: storeNumber || getRestaurantFromSanity?.data?.allRestaurants[0]?.number,
    }),
    [storeNumber, getRestaurantFromSanity]
  );
  const getRestaurantFromRBI = useGetRestaurantQuery({
    fetchPolicy: 'cache-and-network',
    skip: !secondQueryVariables.storeId,
    variables: secondQueryVariables,
  });

  const { restaurant, error, loading } = useMemo(
    () => ({
      restaurant:
        getRestaurantFromRBI.data &&
        getRestaurantFromSanity.data &&
        getRestaurantFromSanity.data.allRestaurants.length !== 0
          ? mergeRestaurantData({
              rbiRestaurant: getRestaurantFromRBI.data.restaurant,
              sanityStore: getRestaurantFromSanity.data.allRestaurants[0] as any,
            })
          : undefined,
      error: getRestaurantFromRBI.error || getRestaurantFromSanity.error || undefined,
      loading: getRestaurantFromRBI.loading || getRestaurantFromSanity.loading,
    }),
    [getRestaurantFromRBI, getRestaurantFromSanity]
  );

  if (error) {
    return <ErrorDialog />;
  }

  return <StoreInfoModal onDismiss={router.back} store={restaurant} loading={loading} />;
};

export default StoreInfoModalContainer;
