import { Nullable } from '@rbi-ctg/frontend';
import {
  IAllergens,
  IComboSlot,
  INutritionInfo,
  IPickerAspect,
  IPickerAspectOption,
  ISanityImage,
  PickerSelection,
} from '@rbi-ctg/menu';
import { ComboUIPattern, MenuObjectTypes } from 'enums/menu';

export const getTitleText = (pickerName: string) => pickerName;

/**
 * Determines the PickerAspectOptions that the user has actively selected up
 * to the current `step` if a series UI pattern or the
 * full `pickerSelections` otherwise.
 *
 * Needed because the `pickerSelections` object contains the selected values
 * *and* the default values in one object.
 */
export function getChosenPickerAspectOptions({
  pickerAspects,
  pickerSelections,
  step,
  uiPattern,
}: {
  pickerAspects?: IPickerAspect[];
  pickerSelections: { [k: string]: string };
  step: number;
  uiPattern: 'series' | 'hidden';
}): IPickerAspectOption[] | undefined {
  if (!pickerAspects) {
    return;
  }

  const activelyChosenPickerAspects =
    uiPattern === 'series' ? pickerAspects.slice(0, step + 1) : pickerAspects;

  const activelyChosenPickerAspectOptions = activelyChosenPickerAspects
    .map(pickerAspect => {
      const pickerAspectOptionIdentifier = pickerSelections[pickerAspect._id];
      return pickerAspect.pickerAspectOptions.find(
        o => o.identifier === pickerAspectOptionIdentifier
      );
    })
    .filter(Boolean) as IPickerAspectOption[];

  return activelyChosenPickerAspectOptions;
}

export function getDescriptionText(pickerAspectOptions?: IPickerAspectOption[]): string {
  if (!pickerAspectOptions) {
    return '';
  }

  // TODO: use a localization function to grab description
  const joinedOptionDescriptions = pickerAspectOptions
    .map(o => o.description?.locale)
    .filter(Boolean)
    .join(' ');
  return joinedOptionDescriptions;
}

interface IGetOfferPrice {
  marketPrice?: number;
  offerPrice?: number | null;
  restaurantPosPrice?: number;
}

interface ISelections {
  quantity: number;
  option: ISelectionOption;
}

interface ISelectionOption {
  option: IProduct;
}

interface IProduct {
  _id: string;
  name?: string;
  image?: ISanityImage;
  nutrition?: INutritionInfo;
  allergens?: IAllergens;
}

export type GetOfferPriceFunction = typeof getOfferPrice;

export function getOfferPrice({ marketPrice, offerPrice, restaurantPosPrice }: IGetOfferPrice) {
  return marketPrice || restaurantPosPrice || offerPrice || 0;
}

export const getIsComboInSeries = (pickerSelection: Nullable<PickerSelection>) =>
  !!pickerSelection &&
  pickerSelection._type === MenuObjectTypes.COMBO &&
  pickerSelection.uiPattern === ComboUIPattern.series;

// modifiers are shown first for combos w/ ui pattern === "series"
export const getShouldShowModifiers = (customizationStep: number) => customizationStep === 0;

export const getComboSlotId = (
  comboSlots: IComboSlot[],
  customizationStep: number,
  hasMainItemMods: boolean
): string | null => {
  if (customizationStep <= 0) {
    return null;
  }
  // offset the combo slot index by 1 to account for the modifiers step that precedes all combo slots
  const comboSlotIndex = hasMainItemMods ? customizationStep - 1 : customizationStep;
  return comboSlots[comboSlotIndex]._id;
};

export const getTotalQuantity = (selections: ISelections[]) =>
  selections.reduce((totalQuantity: number, selection) => totalQuantity + selection.quantity, 0);

export const getNewSelections = (
  selections: ISelections[],
  selectionToUpdate: IProduct,
  selectionQuantity: number
) => {
  let selectionIndex = -1;
  const newSelections = selections.map((selection, index) => {
    if (selection.option.option === selectionToUpdate) {
      selection.quantity = selectionQuantity;
      selectionIndex = index;
      return selection;
    }
    return selection;
  });
  // push selected element to the end of the selections array
  if (selectionIndex >= 0) {
    newSelections.push(newSelections.splice(selectionIndex, 1)[0]);
  }
  return newSelections;
};

export const getSelectionsBasedOnMaxAmount = (
  selections: ISelections[],
  selectionToUpdate: IProduct,
  slotMaxAmount: number,
  selectionQuantity: number
) => {
  const updatedSelections = getNewSelections(selections, selectionToUpdate, selectionQuantity);
  const totalQuantity = getTotalQuantity(updatedSelections);
  let quantityOfFirstSelection = updatedSelections[0].quantity;
  if (totalQuantity > slotMaxAmount && quantityOfFirstSelection > 0) {
    quantityOfFirstSelection -= 1;
  }
  if (quantityOfFirstSelection === 0) {
    updatedSelections.shift();
    return updatedSelections;
  }
  updatedSelections[0].quantity = quantityOfFirstSelection;
  return updatedSelections;
};
