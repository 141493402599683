import { Image } from 'expo-image';
import { Link } from 'expo-router';
import { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { tokens } from '../../tokens';
import { Button } from '../button';
import { Pressable } from '../pressable';
import { XStack, YStack } from '../stack';
import { Text } from '../text';

import { QuantityBadge } from './quantity-badge';
import { OrderDisplayItem, OrderDisplayProps, OrderDisplayType } from './types';

const ITEM_GAP = 8;
const NUM_ITEMS_TO_DISPLAY = 6;

function OrderCardItemList({ items }: { items: OrderDisplayItem[] }) {
  const [itemsWrapperWidth, setItemsWrapperWidth] = useState<number | null>(null);

  return (
    <XStack
      style={styles.itemsWrapper}
      onLayout={event => setItemsWrapperWidth(event.nativeEvent.layout.width)}
    >
      {items.slice(0, NUM_ITEMS_TO_DISPLAY).map((item, index, sliceArr) => {
        // For the last element, we want to display the amount of remaining items.
        // This is checking if the item in the loop is the last in the slice or in the limit we defined.
        const isLastItem = index === items.length - 1;
        const isLastItemInSlice = index === sliceArr.length - 1;
        const isOverflowItem = isLastItemInSlice && !isLastItem;

        return (
          <XStack
            style={[
              styles.item,
              itemsWrapperWidth
                ? {
                    width:
                      // We have to account for the space between items when trying to evenly divide the available space
                      (itemsWrapperWidth - Math.max(sliceArr.length, 0) * ITEM_GAP) /
                      sliceArr.length,
                  }
                : {},
            ]}
            key={index}
          >
            {isOverflowItem ? (
              <Text.Paragraph size="sm">+{items.length - index}</Text.Paragraph>
            ) : (
              <>
                {item.image && (
                  <Image
                    style={styles.itemImage}
                    source={item.image}
                    alt={item.name}
                    contentFit="contain"
                    testID="display-item-image"
                  />
                )}
                {(items.length === 1 || items.length === 2 || !item.image) && (
                  <Text.Paragraph weight="semibold" size="md" numberOfLines={1}>
                    {item.name}
                  </Text.Paragraph>
                )}
                {item.quantity > 1 && (
                  <QuantityBadge text={`${item.quantity}`} style={styles.quantityBadge} />
                )}
              </>
            )}
          </XStack>
        );
      })}
    </XStack>
  );
}

export function OrderDisplayDesktop({
  type = OrderDisplayType.ORDER_IN_PROGRESS,
  ...props
}: OrderDisplayProps) {
  if (type === OrderDisplayType.ORDER_IN_PROGRESS) {
    const { storeName, items, href, style, ...viewProps } = props;

    return (
      <XStack {...viewProps} style={[styles.wrapper, style]}>
        <YStack style={styles.storeNameTextContentWrapper}>
          <Text.Paragraph weight="semibold" size="md" numberOfLines={1}>
            Order In Progress
          </Text.Paragraph>
          <Text.Paragraph size="sm" numberOfLines={1}>
            {storeName}
          </Text.Paragraph>
        </YStack>
        <View style={styles.divider} />
        <OrderCardItemList items={items} />
        <View style={styles.divider} />
        <View style={styles.buttonWrapper}>
          <Link href={href} asChild>
            <Button type="solid" size="lg">
              <Button.Text>Track Order</Button.Text>
            </Button>
          </Link>
        </View>
      </XStack>
    );
  } else if (type === OrderDisplayType.REORDER) {
    const {
      storeName,
      items,
      serviceModeTitle,
      href,
      style,
      reorderCallback,
      reorderLoading,
      logReorderCardPress,
      ...viewProps
    } = props;

    return (
      <XStack {...viewProps} style={[styles.wrapper, style]}>
        <Link href={href} asChild>
          <Pressable style={styles.storeNameTextContentWrapper} onPress={logReorderCardPress}>
            <Text.Paragraph weight="semibold" size="md" numberOfLines={1}>
              {storeName}
            </Text.Paragraph>
            <Text.Paragraph size="sm" numberOfLines={1}>
              {serviceModeTitle}
            </Text.Paragraph>
          </Pressable>
        </Link>
        <View style={styles.divider} />
        <Link href={href} asChild>
          <Pressable
            testID="reorder-card-redirect"
            style={styles.pressableWrapper}
            onPress={logReorderCardPress}
          >
            <OrderCardItemList items={items} />
          </Pressable>
        </Link>
        <View style={styles.divider} />
        <View style={styles.buttonWrapper}>
          <Button type="solid" size="lg" onPress={reorderCallback} loading={reorderLoading}>
            <Button.Text>Reorder</Button.Text>
          </Button>
        </View>
      </XStack>
    );
  }

  if (__DEV__) {
    throw new Error(`OrderDisplay has no implementation for type '${type}'`);
  }

  return null;
}

const styles = StyleSheet.create({
  wrapper: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    backgroundColor: tokens.colors.$white,
    paddingHorizontal: 32,
    paddingVertical: 24,
    gap: 64,
    alignItems: 'center',
  },
  storeNameTextContentWrapper: {
    height: '100%',
    maxWidth: 220,
    justifyContent: 'center',
    flexGrow: 1,
    flexShrink: 1,
  },
  divider: {
    width: 1,
    height: 32,
    backgroundColor: tokens.colors.$blackOpacity10,
  },
  pressableWrapper: {
    flexGrow: 9999,
  },
  itemsWrapper: {
    flexGrow: 9999,
    flexShrink: 9999,
    gap: ITEM_GAP,
  },
  item: {
    position: 'relative',
    paddingVertical: 4,
    paddingHorizontal: 8,
    backgroundColor: tokens.colors.$blackOpacity04,
    borderRadius: 4,
    gap: 8,
    minHeight: 56,
    justifyContent: 'center',
    alignItems: 'center',
  },
  quantityBadge: {
    right: 9,
    bottom: -5,
  },
  itemImage: {
    aspectRatio: 1 / 1,
    height: '100%',
  },
  buttonWrapper: {
    flexGrow: 0,
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
});
