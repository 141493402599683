import { Maybe } from 'graphql/jsutils/Maybe';

import { IImagesFragment, IOffersPageMarketingTileFragment } from 'generated/sanity-graphql';

type ObjectWithMaybeProperty<TKey extends string | symbol | number, TPropValue> = Maybe<{
  readonly [key in TKey]?: Maybe<TPropValue>;
}>;
export type NoNullFields<Ob> = { [K in keyof Ob]: NonNullable<Ob[K]> };

export const maybeLocale = <TLocaleValue>(
  node: ObjectWithMaybeProperty<'locale', TLocaleValue>
): TLocaleValue | null => node?.locale ?? null;

export const maybeLocaleRaw = <TLocaleRawValue>(
  node: Maybe<ObjectWithMaybeProperty<'localeRaw', TLocaleRawValue>>
) => node?.localeRaw ?? null;

export const maybeNumber = (number: Maybe<number>) => number ?? null;

export const maybeLocaleImages = (
  imageNode: Maybe<ObjectWithMaybeProperty<'locale', IImagesFragment | null>>
) => maybeLocale(imageNode)?.app ?? null;

export const maybeLinkAction = (
  linkActionNode: Maybe<{
    readonly route?: Maybe<string>;
    readonly actionText?: Maybe<ObjectWithMaybeProperty<'locale', string>>;
  }>
) => ({
  actionText: linkActionNode?.actionText?.locale ?? null,
  route: linkActionNode?.route ?? null,
});

const maybeOffersPageMarketingTile = (
  offersPageMarketingTile: Maybe<IOffersPageMarketingTileFragment>
): IOffersPageMarketingTileFragment => ({
  customPosition: offersPageMarketingTile?.customPosition ?? null,
  displayPosition: offersPageMarketingTile?.displayPosition ?? null,
  marketingTileReference: offersPageMarketingTile?.marketingTileReference ?? null,
});
export const maybeOffersPageMarketingTiles = (
  offersMageMarketingTiles: Maybe<
    ReadonlyArray<
      Maybe<{ readonly __typename?: 'OffersPageMarketingTile' } & IOffersPageMarketingTileFragment>
    >
  >
) => offersMageMarketingTiles?.map(tile => maybeOffersPageMarketingTile(tile)) ?? [];
