import { useApolloClient } from '@apollo/client';
import { router } from 'expo-router';
import React from 'react';

import { VStack } from '@fhs-legacy/universal-components';
import LoadingAnimation from 'components/loading-animation';
import useAuth from 'hooks/auth/use-auth';
// import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import useEffectOnce from 'hooks/use-effect-once';
import { useAuthContext } from 'state/auth';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

function SignOut() {
  const client = useApolloClient();
  const { signOut } = useAuth();
  const { resetStore } = useStoreContext();
  const { setServiceMode } = useServiceModeContext();
  const { resetInRestaurantRedemption } = useInRestaurantRedemptionContext();
  const { setOriginLocation } = useAuthContext();
  // useRumPageView('sign-out', 'Sign Out');

  useEffectOnce(() => {
    signOut()
      .then(() => {
        resetStore();
        resetInRestaurantRedemption();
        setServiceMode(null);
        setOriginLocation(null);
      })
      .then(() => client.resetStore())
      .then(() => {
        router.replace({
          pathname: routes.base,
          params: { hasSignedOut: 'true' },
        });
      });
  });

  return (
    <VStack height="full" justifyContent="center">
      <LoadingAnimation />
    </VStack>
  );
}

export default SignOut;
