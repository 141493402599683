import { Image } from 'expo-image';

import { Wordmark } from '@fhs/ui';
import { Box } from '@fhs-legacy/universal-components';

export const PypestreamMobileLoader = () => (
  <>
    <Image
      source={require('../images/support-loader-background.png')}
      alt="support-loader"
      contentFit="cover"
      style={{
        height: '100%',
        width: '100%',
      }}
    />
    <Box position="absolute" top="25%" alignSelf="center">
      <Wordmark width={300} height={75} />
    </Box>
  </>
);
