import { useApolloClient } from '@apollo/client';
import { router, useLocalSearchParams } from 'expo-router';
import Head from 'expo-router/head';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { GestureResponderEvent, StyleSheet } from 'react-native';

import { Pressable, Text } from '@fhs-legacy/universal-components';
import { Box } from '@fhs-legacy/universal-components';
import useEffectOnce from 'hooks/use-effect-once';
import { HttpErrorCodes } from 'remote/constants';
import { useAuthContext } from 'state/auth';
import {
  getStoredOtpCredentials,
  storeOtpCredentials,
} from 'state/auth/hooks/use-account-authentication';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import noop from 'utils/noop';
import { decodeBase64String } from 'utils/parse-string';
import { routes } from 'utils/routing';

import { SignUpHomeContainer, SignUpPanel } from '../auth-home/sign-up-home.styled';
import { CloseButton } from '../close-button';
import SignUpHeadline from '../sign-up-headline/sign-up-headline';

import { DidNotReceiveCodeSendNewOne } from './did-not-receive-code-send-new-one';
import { OtpForm } from './otp-form';
import {
  CodeSentText,
  CodeText,
  ErrorMessage,
  NotReceived,
  SentEmailText,
  TransformedHeadline,
} from './otp.styled';
import { useResendOtp } from './use-resend-otp.hook';
import { useSendNewOtp } from './use-send-new-otp.hook';

const OTP_CODE_URL_PARAM = 'code';
const EMAIL_URL_PARAM = 'user';

export const ConfirmOtp = () => {
  const { isAuthenticated, originLocation, validateLoginOtp, setOtpAuthError } = useAuthContext();
  const client = useApolloClient();
  const { formatMessage } = useIntl();
  const params = useLocalSearchParams<{
    [OTP_CODE_URL_PARAM]: string;
    [EMAIL_URL_PARAM]: string;
  }>();
  const { email, phoneNumber, sessionId } = getStoredOtpCredentials() || {};
  const [isValidatingOtp, setIsValidatingOtp] = useState(false);

  const [hasResentSameOtpOnce, setHasResentSameOtpOnce] = useState(false);
  const enableResendOtpOnce = useFlag(LaunchDarklyFlag.ENABLE_SEND_SAME_OTP_CODE_ONCE);
  const showSendNewOtpForm = !enableResendOtpOnce || (enableResendOtpOnce && hasResentSameOtpOnce);
  const enableResendOTPCounter = useFlag(LaunchDarklyFlag.ENABLE_RESEND_OTP_COUNTER);

  const urlOtpCode = params[OTP_CODE_URL_PARAM];
  const emailFromUrl = params[EMAIL_URL_PARAM];

  const [otpValidationError, setOtpValidationError] = useState<string | null>(null);
  const [resendOrSendNewOtpError, setResendOrSendNewOtpError] = useState<string | null>(null);
  const [lastRequestedCode, setLastRequestedCode] = useState<Date | null>(null);

  const [successMessageType, setSuccessMessageType] = useState<null | 'resent' | 'requested-new'>(
    null
  );

  const hasEmail = Boolean(email?.length);
  const hasPhoneNumber = Boolean(phoneNumber?.length);

  const { sendNewOtp: _sendNewOtp, isLoading: isRequestingNewCode } = useSendNewOtp({
    setOtpValidationError,
    setOtpAuthError,
    setResendOrSendNewOtpError,
    setShowHasSentNewOtpMessage: (requestedNewSuccess: boolean) => {
      if (requestedNewSuccess) {
        setSuccessMessageType('requested-new');
      }
    },
  });

  const sendNewOtp = useCallback(
    async (event: GestureResponderEvent) => {
      await _sendNewOtp(event);

      if (enableResendOTPCounter) {
        setLastRequestedCode(new Date());
      }
    },
    [_sendNewOtp, enableResendOTPCounter]
  );

  const { resendOtp: _resendOtp, isLoading: isResendingOtp } = useResendOtp({
    setOtpValidationError,
    setResendOrSendNewOtpError,
    setShowHasSentResentOtpMessage: (resentSuccess: boolean) => {
      if (resentSuccess) {
        setSuccessMessageType('resent');
      }
    },
  });

  const resendOtp = useCallback(
    async (event: GestureResponderEvent) => {
      await _resendOtp(event);

      if (enableResendOTPCounter) {
        setLastRequestedCode(new Date());
      }

      setHasResentSameOtpOnce(true);
    },
    [_resendOtp, enableResendOTPCounter]
  );

  //Routes user back to sign in page
  const redirectToSignIn = () => router.navigate(routes.signIn);

  // Either if we have a login, or once we validate OTP and get an update for the user being loaded
  // we can then handle the navigate.
  useEffect(() => {
    if (isAuthenticated) {
      const path = originLocation || routes.base;

      // This is a bad hack i'm adding to make expo-router play nicely with our poor setup.
      // The tl;dr is that we have to support being able to go "back" sometimes depending on
      // where we pop the auth modal.
      // Unfortunately we can't use url parameters without a major refactor because the Auth provider
      // handles the navigation from signin to confirm-otp and loses the parameters.
      // This is the easiest way to keep the product working.
      if (path === 'GO_BACK') {
        return router.back();
      }

      router.replace({
        pathname: path,
        params: {
          // This allows us to notify the screen reader to announce a successful sign in only when signing in via OTP.
          triggerSignInAccessibility: 'true',
          clearOrigin: 'true',
        },
      });
    }
  }, [isAuthenticated, originLocation]);

  const handleValidateLogin = useCallback(
    async (otpCode: string) => {
      setIsValidatingOtp(true);
      setSuccessMessageType(null);
      setOtpValidationError(null);
      setOtpAuthError(null);
      setResendOrSendNewOtpError(null);

      try {
        await validateLoginOtp({ otpCode });

        // refetch offers when user navigates to /offers page
        client.reFetchObservableQueries();
      } catch (error) {
        let errorMessage;
        setIsValidatingOtp(false);
        if (!sessionId) {
          errorMessage = formatMessage({ id: 'differentDeviceError' });

          setOtpValidationError(errorMessage);
          setOtpAuthError(errorMessage);
          throw error;
        }

        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        switch (error?.graphQLErrors?.[0]?.extensions?.statusCode) {
          case HttpErrorCodes.InternalServerError:
            errorMessage = formatMessage({ id: 'looksLikeWereExperiencingIssues' });
            break;
          case HttpErrorCodes.TooManyRequests:
            errorMessage = formatMessage({ id: 'maxAttemptsReachedRequestNewCode' });
            break;
          case HttpErrorCodes.Unauthorized:
          default:
            errorMessage = formatMessage({ id: 'invalidOtpProvided' });
            break;
        }

        setOtpValidationError(errorMessage);
        setOtpAuthError(errorMessage);
        throw error;
      }
    },
    [client, formatMessage, sessionId, setOtpAuthError, validateLoginOtp]
  );

  /**
   * Ensure that user's email or phone number exists in local storage or url param
   * If the email and phone number doesn't exist, navigate the user to sign in
   */
  useEffectOnce(() => {
    let eitherEmail = email;
    const eitherPhone = phoneNumber;
    if (emailFromUrl) {
      eitherEmail = decodeBase64String({ value: emailFromUrl }) || email || phoneNumber;
    }

    if (eitherEmail || eitherPhone) {
      storeOtpCredentials({ sessionId, email: eitherEmail, phoneNumber: eitherPhone });
    } else {
      router.navigate(routes.signIn);
    }
  });

  /**
   * Only try submission on mount or when the otpCode in the url changes
   */
  useEffect(() => {
    if (urlOtpCode) {
      handleValidateLogin(urlOtpCode).catch(noop);
    }
  }, [urlOtpCode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TransformedHeadline>{formatMessage({ id: 'authVerifyWithCode' })}</TransformedHeadline>
      {hasEmail && (
        <SentEmailText aria-label={formatMessage({ id: 'visuallyHiddenSignInCodeSentToEmail' })}>
          {formatMessage(
            { id: 'authEmailSentEmail' },
            {
              email: (
                <Text style={styles.emailText} testID="otp-email" data-private>
                  {email}
                </Text>
              ),
              changeLink: (
                <Pressable onPress={redirectToSignIn} testID="change-email-link">
                  <Text textDecorationLine="underline">{formatMessage({ id: 'changeEmail' })}</Text>
                </Pressable>
              ),
            }
          )}
        </SentEmailText>
      )}
      {hasPhoneNumber && (
        <SentEmailText aria-label={formatMessage({ id: 'visuallyHiddenSignInCodeSentToPhone' })}>
          {formatMessage({ id: 'authPhoneSentSms' })}
          &nbsp;
          <Text style={styles.emailText} testID="otp-email" data-private>
            {phoneNumber}
          </Text>
        </SentEmailText>
      )}
      <OtpForm
        errorMessage={otpValidationError}
        initialCode={urlOtpCode}
        loading={isValidatingOtp}
        onSubmit={handleValidateLogin}
      />

      {showSendNewOtpForm ? (
        <DidNotReceiveCodeSendNewOne
          sendNewOtp={sendNewOtp}
          isRequestingNewCode={isRequestingNewCode}
          isValidatingOtp={isValidatingOtp}
          lastRequestedCode={lastRequestedCode}
        />
      ) : (
        <NotReceived>
          <Pressable
            disabled={isValidatingOtp || isResendingOtp}
            onPress={resendOtp}
            testID="otp-resend-form"
          >
            <CodeText>
              <Text>{formatMessage({ id: 'didNotReceiveCodeHeading' })} </Text>
              {isValidatingOtp || isResendingOtp ? (
                <Text>{formatMessage({ id: 'sending' })}</Text>
              ) : (
                <Text textDecorationLine="underline">{formatMessage({ id: 'resendCode' })}</Text>
              )}
            </CodeText>
          </Pressable>
        </NotReceived>
      )}

      {resendOrSendNewOtpError ? (
        <ErrorMessage testID="otp-resend-or-send-new-error">{resendOrSendNewOtpError}</ErrorMessage>
      ) : successMessageType === 'requested-new' ? (
        <>
          {hasEmail && (
            <CodeSentText aria-live="polite">{formatMessage({ id: 'newCodeSent' })}</CodeSentText>
          )}
          {hasPhoneNumber && (
            <CodeSentText aria-live="polite">
              {formatMessage({ id: 'newCodeSentPhone' })}
            </CodeSentText>
          )}
        </>
      ) : successMessageType === 'resent' ? (
        <>
          {hasEmail && (
            <CodeSentText testID="otp-resend-success" aria-live="polite">
              {formatMessage({ id: 'signInCodeSentToEmailAgain' })}
            </CodeSentText>
          )}

          {hasPhoneNumber && (
            <CodeSentText testID="otp-resend-success" aria-live="polite">
              {formatMessage({ id: 'signInCodeSentToPhoneAgain' })}
            </CodeSentText>
          )}
        </>
      ) : null}
    </>
  );
};

export default function ConfirmOtpPage() {
  const { formatMessage } = useIntl();

  return (
    <>
      <Head>
        <title>{`${formatMessage({ id: 'signUp' })} - Firehouse Subs`}</title>
      </Head>
      <SignUpHomeContainer testID="signup-home-container">
        <CloseButton style={styles.closeButton} />
        <Box alignItems="center" paddingBottom="$6">
          <SignUpHeadline />
        </Box>
        <SignUpPanel>
          <ConfirmOtp />
        </SignUpPanel>
      </SignUpHomeContainer>
    </>
  );
}

const styles = StyleSheet.create({
  closeButton: {
    marginRight: 'auto',
    marginTop: 8,
    marginLeft: 8,
  },
  emailText: {
    fontWeight: 'bold',
  },
});
