import { useMemo } from 'react';

import { ICartEntry } from '@rbi-ctg/menu';
import { useCartContext } from 'state/cart';
import { useFlag } from 'state/launchdarkly';
import { useOrderContext } from 'state/order';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import { priceForCartEntry } from 'utils/menu';

export const useCartLimit = () => {
  const orderContext = useOrderContext();
  const cartContext = useCartContext();
  /**
   * On checkout screen, cart entries are updated on CartContext (cart changes performance improvement)
   * On other screens, cart entries are updated on OrderContext
   * This line of code allows the hook to decide which Context to use based on its availability.
   */
  const cartEntries = cartContext?.cartEntries || orderContext?.cartPreviewEntries;
  const maxCartTotal: number = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_LIMIT);
  const currentCartTotal = useMemo(
    () =>
      (cartEntries as ICartEntry[]).reduce((total, cartEntry) => {
        if (cartEntry.isDonation) {
          return total;
        }
        return total + priceForCartEntry(cartEntry);
      }, 0),
    [cartEntries]
  );
  return {
    isOverCartLimit: !!maxCartTotal && currentCartTotal > maxCartTotal,
    maxCartTotal,
  };
};
