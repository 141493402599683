import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconDelivery = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M19 7.1c0-1.1-.9-2-2-2h-3v2h3v2.65l-3.48 4.35H10v-5H6c-2.21 0-4 1.79-4 4v3h2c0 1.66 1.34 3 3 3s3-1.34 3-3h4.48L19 10.45V7.1Zm-12 10c-.55 0-1-.45-1-1h2c0 .55-.45 1-1 1Zm3-11H5v2h5v-2Zm6 10c0-1.66 1.34-3 3-3s3 1.34 3 3-1.34 3-3 3-3-1.34-3-3Zm2 0c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
