import { forwardRef } from 'react';
import { StyleSheet, View } from 'react-native';

import { tokens } from '../../tokens';
import { Button, ButtonIconProps, ButtonProps } from '../button';

export type IconButtonProps = Omit<ButtonProps, 'children'> & {
  children: ButtonIconProps['children'];
};

export const IconButton = forwardRef<View, IconButtonProps>(function IconButton(
  { children, type = 'solid', ...buttonProps },
  ref
) {
  return (
    <Button
      ref={ref}
      {...buttonProps}
      type={type === 'ghost' ? 'unstyled' : type}
      pressedStyle={[buttonPressedStyles[type], buttonProps.pressedStyle]}
      outlinePressedStyle={[outlinePressedStyles[type], buttonProps.outlinePressedStyle]}
      outlineHoveredStyle={[outlineHoveredStyles[type], buttonProps.outlineHoveredStyle]}
    >
      <Button.Icon>{children}</Button.Icon>
    </Button>
  );
});

const buttonPressedStyles = StyleSheet.create({
  ghost: {
    opacity: 0.7,
  },
});

const outlinePressedStyles = StyleSheet.create({
  ghost: {
    borderColor: tokens.colors.$transparent,
  },
});

const outlineHoveredStyles = StyleSheet.create({
  ghost: {
    borderColor: tokens.colors.$transparent,
  },
});
