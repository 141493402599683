import { useLocalSearchParams } from 'expo-router';

interface ILoyaltyOffersFlow {
  offerEngineId: string;
  isConfigOffer?: boolean;
}

// This hook should be removed when we split OfferList from OfferDetails components
const useLoyaltyOffersFlow = (): ILoyaltyOffersFlow => {
  const params = useLocalSearchParams<{ incentiveId?: string; isConfigOffer?: string }>();
  const offerEngineId = (params?.incentiveId || '').trim();
  const isConfigOffer = (params?.isConfigOffer && params.isConfigOffer === 'true') || false;

  return {
    offerEngineId,
    isConfigOffer,
  };
};

export default useLoyaltyOffersFlow;
