import Svg, { Path } from 'react-native-svg';

import { IconProps } from '../types';

export const IconShoppingBag = (props: IconProps) => {
  const { size = 24, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M8.531 1.5a.54.54 0 0 0-.392.17L6.651 3.238a.596.596 0 0 0-.16.356l-1.488 16.18a.601.601 0 0 0 .16.468l1.984 2.088c.104.11.245.171.392.171h11.906a.542.542 0 0 0 .403-.182.601.601 0 0 0 .151-.432l-.992-19.833a.568.568 0 0 0-.554-.553H8.531Zm-.955 2.414L7.9 3.57l-.85 17.007-.92-.967L7.577 3.914Zm11.285 17.42H8.123l.934-18.667h8.87l.934 18.666ZM13.492 6.258c1.644 0 2.977-.468 2.977-1.044 0-.577-1.333-1.044-2.977-1.044s-2.976.467-2.976 1.044c0 .576 1.332 1.044 2.976 1.044Z"
      />
    </Svg>
  );
};
