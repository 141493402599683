import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconFacebook = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.36 22.1H9.12v-10H7V8.652h2.12V6.584c0-2.81 1.195-4.483 4.596-4.483h2.83v3.447h-1.77c-1.323 0-1.41.481-1.41 1.38l-.006 1.725h3.205l-.375 3.446h-2.83v10.002Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
