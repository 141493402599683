import * as React from 'react';
import { useIntl } from 'react-intl';

import RewardsLinkBase from './rewards-link.base';

const RewardsLink = () => {
  const { formatMessage } = useIntl();
  return <RewardsLinkBase textLabel={formatMessage({ id: 'rewards' })} />;
};
export default RewardsLink;
