export enum EventName {
  APP_FLOW_DOWNLOAD_START = 'app_flow_download_start',
  APP_FLOW_DOWNLOAD_END = 'app_flow_download_end',
  JAVASCRIPT_START = 'javascript_start',
  MARKETING_IMAGE_LOADED = 'marketing_image_loaded',
  MAIN_HERO_IMAGE_LOADED = 'main_hero_image_loaded',
  OFFERS_CAROUSEL_RENDER = 'offers_carousel_render',
  REACT_START = 'react_start',
  MENU_TILE_IMAGE_LOADED = 'menu_tile_image_loaded',
  MENU_ITEM_CALORIES_LOADED = 'menu_item_calories_loaded',
  MENU_TILE_CLICKED = 'menu_tile_clicked',
  RESTAURANT_SELECTED_ON_ORDER = 'restaurant_selected_on_order',
  RESTAURANT_SELECTED_ON_OFFERS = 'restaurant_selected_on_offers',
  BOTTOM_TAB_PRESSED = 'bottom_tab_pressed',
  SCREEN_RENDER = 'screen_render',
  DEEPLINK = 'deep_link',
  STORE_LOCATOR_SEARCHING = 'store_locator_searching',
  STORE_LOCATOR_UNMOUNT = 'store_locator_unmount',
  NAVIGATION_STATE_CHANGED = 'navigation_state_changed',
  QR_CODE_LOADED = 'qr_code_loaded',
  STORE_LOCATOR_RESTAURANTS_SEARCHING_FINISHED = 'store_locator_restaurants_searching_end',
  CART_RENDER = 'cart_render',
  CART_CONTINUE_LOAD_FINISHED = 'cart_continue_load_end',
  CART_CONTENT_UPDATE = 'cart_content_update',
  CART_TOTALS_LOAD_FINISHED = 'cart_totals_load_finished',
  LOGGER_INFO = 'logger.info',
  LOGGER_ERROR = 'logger.error',
  LOGGER_WARN = 'logger.warn',
  LOGGER_TRACE = 'logger.trace',
  LOGGER_DEBUG = 'logger.debug',
  LOGGER_FATAL = 'logger.fatal',
  CART_STEP_2_RENDER = 'cart_step_2_render',
  CART_STEP_2_PRICES_LOADING_END = 'cart_step_2_prices_loading_end',
  CART_STEP_2_UNMOUNT = 'cart_step_2_unmount',
  PLACE_PICKUP_ORDER_START = 'place_pickup_order_start',
  PLACE_PICKUP_ORDER_END = 'place_pickup_order_end',
}

export interface DeeplinkEventArgs {
  route: string;
}

export interface BottomTabEventArgs {
  // TODO React Native Navigation: this should be an enum
  // TODO make this required
  screenId?: string;
  route?: string;
}

export type ScreenId =
  | 'home'
  | 'recentItems'
  | 'menu'
  | 'loyaltyOffers'
  | 'loyaltyRewards'
  | 'signupHome'
  | 'inRestaurantRedemption'
  | 'scan';

export interface ScreenEventArgs {
  // TODO React Native Navigation: this should be an enum
  screenId: ScreenId;
}
