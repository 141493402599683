import { useCallback, useEffect, useState } from 'react';

import { useConfigValue } from 'hooks/configs/use-config-value';
import { setOptOut } from 'state/amplitude/amplitude-package';
import { useBranchContext } from 'state/branch';

import useOneTrustSdk, { isOptIn } from './use-one-trust-sdk';

enum OneTrustGlobalConsentState {
  OPT_OUT = 'Opt-Out',
  OPT_IN = 'Opt-In',
}

export const OneTrustCookieBanner = () => {
  const { initBranch } = useBranchContext();
  const { loaded: otSdkLoaded, otConsentGroups } = useOneTrustSdk();
  const cookieCompliance = useConfigValue({ key: 'oneTrust', defaultValue: {} }).cookieCompliance;
  const [servicesLoaded, setServiceLoaded] = useState(false);

  // Function to initialize 3rd party services
  const initServicesWithCookies = useCallback(() => {
    if (!window.branch) {
      initBranch();
    }
  }, [initBranch]);

  // loads 3rd party services once OneTrust sdk is loaded
  useEffect(() => {
    if (otConsentGroups && otSdkLoaded && !servicesLoaded) {
      // CCPA is opt-in by default, e.g. users are tracked by default:
      // New users would have no stored consent state, so start our tracking services
      // or the user has explicitly accepted or chosen to close the dialog, so start our tracking services
      if (isOptIn(otConsentGroups)) {
        initServicesWithCookies();
      }
    }
  }, [initServicesWithCookies, otConsentGroups, otSdkLoaded, servicesLoaded]);

  const servicesInitCheck = useCallback(() => {
    const areServicesInitialized = !!window.branch;
    if (areServicesInitialized) {
      setServiceLoaded(true);
    }
    return areServicesInitialized;
  }, []);

  // Sets a interval to check if all 3rd party services are initialized
  useEffect(() => {
    const id = setInterval(servicesInitCheck, 1000);
    if (servicesLoaded) {
      clearInterval(id);
    }
    return () => clearInterval(id);
  }, [servicesInitCheck, servicesLoaded]);

  // Switches off tracking for each service if data tracking is opted out
  useEffect(() => {
    const { globalConsentState, consentCategoryMParticle, consentCategoryBranch } =
      cookieCompliance;

    // Do nothing if services have not loaded
    if (!servicesLoaded) {
      return;
    }

    // reads consent state for each consent category
    const mparticleConsentState = otConsentGroups[consentCategoryMParticle];
    const branchConsentState = otConsentGroups[consentCategoryBranch];

    // OneTrust is very confusing. Opt-out state means the user is not blocking us from tracking/selling them so this is a double negation
    // More below....
    const isDoNotSellOptOutState = globalConsentState === OneTrustGlobalConsentState.OPT_OUT;

    // Making this variable to make it human readable since the double negation is clearly mind bending
    const isOptedOutOfTracking = !isDoNotSellOptOutState;

    // Opt-out or Opt-in of Amplitude if the user completely opted out of tracking or if it blocked Braze specifically.
    // This previously was used for MParticle but is now controlling Amplitude. We need to use this implementation
    // To respect users' previous preferences.
    const optOutOfMParticle = mparticleConsentState === 0 || isOptedOutOfTracking;
    setOptOut(optOutOfMParticle); // This says mParticle - but is Amplitudes opt-out method.

    // Opt-out or Opt-in of Branch if the user completely opted out of tracking or if it blocked Branch specifically
    const optOutOfBranch = branchConsentState === 0 || isOptedOutOfTracking;
    window.branch?.disableTracking(optOutOfBranch);
  }, [cookieCompliance, otConsentGroups, servicesLoaded]);

  return null;
};
