import React from 'react';

import Link from 'components/link';
import { ILinkWidgetFragment } from 'generated/sanity-graphql';
import { useGetMaybeCcpaLinkProps } from 'hooks/one-trust/use-get-maybe-ccpa-link-props';

import { Divisor } from './styled';

type ILinksProps = {
  links: ILinkWidgetFragment[];
  isHorizontalLink?: boolean;
};

export const Links = ({ links, isHorizontalLink }: ILinksProps) => {
  const getMaybeLinkProps = useGetMaybeCcpaLinkProps();

  return (
    <>
      {links.map((widget, index) => {
        const lastLink = links.length === index + 1;
        return (
          <React.Fragment key={widget?._key ?? index}>
            <Link
              {...getMaybeLinkProps({
                to: widget?.link?.locale ?? '',
                children: widget?.text?.locale,
                _text: {
                  variant: 'copyTwo',
                  color: 'token.text-reversed',
                },
                mb: !isHorizontalLink ? '$1' : '$0',
              })}
            />
            {isHorizontalLink && !lastLink ? <Divisor /> : null}
          </React.Fragment>
        );
      })}
    </>
  );
};
