import { Box, HStack, Icon } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

export const ProductCustomizationPanel = Box.withConfig({
  height: 'full',
});

export const ProductCustomizationFixedHeader = Box.withConfig({
  borderBottomWidth: 4,
  borderColor: theme.token('background-default'),
  backgroundColor: theme.token('background-pattern'),
});

export const ProductCustomizationDrawerHeaderContainer = HStack.withConfig({
  paddingY: '$5',
  background: 'black',
  justifyContent: 'center',
});

export const ProductCustomizationDrawerClose = Icon.withConfig({
  color: 'white',
  position: 'absolute',
  left: '$5',
  top: '$4',
});
