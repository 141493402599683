import {
  IImageFragment,
  IItemUnavailableModal,
  ILocaleImage,
  ILocaleString,
} from 'generated/sanity-graphql';
import { NoNullFields } from 'utils/graphql';

type ParseTypes<T> = {
  [K in keyof T]: T[K] extends ILocaleImage | undefined
    ? IImageFragment
    : T[K] extends ILocaleString | undefined
    ? string
    : T[K];
};

type RemoveKeys<T> = NoNullFields<Omit<T, '_type' | '_key' | '__typename'>>;

export type IParsedItemUnavailableModalData = ParseTypes<RemoveKeys<IItemUnavailableModal>>;
export const requiredKeys: readonly (keyof IParsedItemUnavailableModalData)[] = [
  'continue',
  'body',
  'header',
  'image',
  'selectNew',
  'itemNotAvailableForDelivery',
];

export interface IUseFeatureStaticMenu {
  itemUnavailableModal: IParsedItemUnavailableModalData | null;
  featureStaticMenuLoading: boolean;
}

export type HasKeys<T> = {
  [P in keyof T]?: ILocaleString | ILocaleImage;
};
