import { gql } from '@apollo/client';

import VendorConfigsFragment from './vendor-configs';

export const CartEntryFragment = gql`
  fragment CartEntryFragment on CartEntries {
    _id: lineId
    lineId
    image
    isDonation
    isExtra
    name
    pickerSelections
    price
    quantity
    sanityId
    type
    url
    redeemable
    vendorConfigs {
      ...VendorConfigsFragment
    }
  }

  ${VendorConfigsFragment}
`;

export default CartEntryFragment;
