import React from 'react';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { CookieBannerContainer as DefaultCookieBannerContainer } from './default-cookie-banner';
import { OneTrustCookieBanner } from './one-trust-cookie-banner';

export function CookieBannerContainer() {
  const enableCookieBanner = useFlag(LaunchDarklyFlag.ENABLE_COOKIE_BANNER);
  const enableOneTrustCookieBanner = useFlag(
    LaunchDarklyFlag.ENABLE_ONE_TRUST_COOKIE_CONSENT_BANNER
  );

  if (enableCookieBanner) {
    return <DefaultCookieBannerContainer />;
  }

  if (enableOneTrustCookieBanner) {
    return <OneTrustCookieBanner />;
  }

  return null;
}
