import { useIntl } from 'react-intl';

import { MenuObjectTypes } from 'enums/menu';

import { IUseNutritionFactsProps, NutritionFacts } from './types';
import { emptyNutritionFacts, getAllergenGroups, sumModifierNutrition } from './utils';

type UseNutritionFacts = (props: IUseNutritionFactsProps) => NutritionFacts[];

export const useNutritionFacts: UseNutritionFacts = ({
  pickerSelection,
  comboSlotSelections = {},
  itemName,
  itemImage,
  displayNutritionWithModifiersFromSanity,
}) => {
  const { formatMessage } = useIntl();
  const nutritionFacts = [] as NutritionFacts[];

  if (pickerSelection._type === MenuObjectTypes.COMBO) {
    const { mainItem } = pickerSelection;

    if (mainItem) {
      nutritionFacts.push({
        itemName: mainItem.name?.locale,
        itemImage: mainItem.image,
        facts: sumModifierNutrition(mainItem, displayNutritionWithModifiersFromSanity),
        allergenGroups: mainItem.allergens
          ? getAllergenGroups(mainItem.allergens, formatMessage)
          : [],
      });
    }

    Object.values(comboSlotSelections)
      // filter out combo sides/NCR combo sides/anything else we have decided not to show
      .filter(selection => selection.data?.uiPattern !== 'hidden')
      .forEach(({ selections }) => {
        const data = selections.map(({ option: { option } }) => ({
          itemName: option.name.locale,
          itemImage: option.image,
          facts: option.nutrition || emptyNutritionFacts,
          allergenGroups: option.allergens
            ? getAllergenGroups(option.allergens, formatMessage)
            : [],
        }));

        nutritionFacts.push(...data);
      });
  } else {
    nutritionFacts.push({
      itemName,
      itemImage,
      facts: sumModifierNutrition(pickerSelection, displayNutritionWithModifiersFromSanity),
      allergenGroups: pickerSelection.allergens
        ? getAllergenGroups(pickerSelection.allergens, formatMessage)
        : [],
      additionalItemInformation: pickerSelection.additionalItemInformation,
    });
  }

  return nutritionFacts;
};
