import { IRestaurantGroupRestrictionList, Rule } from '@rbi-ctg/offers';

import { findAllRulesOfTypeThatApply } from './rule-of-type-will-apply';
import { RuleType } from './types';

export const evaluateRestaurantGroupRestriction = (
  ruleSet: Rule[] | null,
  storeId: string | null
) => {
  if (!ruleSet?.length) {
    return true;
  }
  // Recurses all rules and accumulates those that apply
  // Rulesets should typically have only one restaurant-group-restriction rule
  const restaurantRestrictionGroups = findAllRulesOfTypeThatApply<IRestaurantGroupRestrictionList>(
    RuleType.RestaurantGroupRestrictions,
    ruleSet
  );

  const hasRestaurantRestrictions = restaurantRestrictionGroups.length;

  const storeIdWithinRestrictionList = () => {
    return restaurantRestrictionGroups.some(({ restaurantGroupRestrictionList }) => {
      const hasRestaurantList = restaurantGroupRestrictionList?.length;
      return (
        hasRestaurantList &&
        restaurantGroupRestrictionList.some(data => {
          return data?.restaurants.some(({ number }) => number === storeId);
        })
      );
    });
  };

  return !hasRestaurantRestrictions || (!!storeId && storeIdWithinRestrictionList());
};
