import React from 'react';

import { Icon } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import { CART_PAGE_CONTINUE_BUTTON, CART_PLACE_ORDER_BUTTON } from 'utils/test-ids';

import { IPlaceOrderButtonProps } from './types';
import { usePlaceOrderText } from './use-place-order-text';

const PlaceOrderButton: React.FC<React.PropsWithChildren<IPlaceOrderButtonProps>> = ({
  placeOrderAriaLabel,
  reloadAmount,
  disabled,
  onPress,
  isLoading,
  confirmation,
}) => {
  const { text, showIcon } = usePlaceOrderText({ confirmation, reloadAmount });
  return (
    <ActionButton
      fullWidth
      onPress={onPress}
      accessibilityLabel={confirmation ? placeOrderAriaLabel : undefined}
      accessibilityRole="button"
      testID={confirmation ? CART_PLACE_ORDER_BUTTON : CART_PAGE_CONTINUE_BUTTON}
      disabled={disabled}
      isLoading={isLoading}
      startIcon={showIcon ? <Icon variant="locked" /> : undefined}
    >
      {text}
    </ActionButton>
  );
};

export default PlaceOrderButton;
