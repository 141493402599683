import { AmplitudeTypes } from 'state/amplitude/amplitude-package';
import { GtmEvent } from 'types';
import logger from 'utils/logger';

let didImport = false;

export const initGTM = () => {
  if (didImport) {
    return Promise.resolve();
  }
  didImport = true;
  return import('./init')
    .then(({ initGoogleTagManager }) => {
      initGoogleTagManager();
    })
    .catch(error => {
      didImport = false;
      logger.error({ error, message: 'Google Tag Manager: import error' });
    });
};

export async function sendGoogleTagManagerEvent(
  event: AmplitudeTypes.Event
): Promise<AmplitudeTypes.Result> {
  window.dataLayer?.push({
    event: event.event_type,
    ...event,
    // @TODO: Fix Type
  } as unknown as GtmEvent);

  return {
    code: 200,
    event,
    message: 'Event pushed onto GTM Data Layer',
  };
}
