import React from 'react';

import { Column } from './column';
import { RowContainer } from './styled';
import { IColumn as IColumnProps, IRow as IRowProps } from './types';

export const Row = (props: IRowProps) => (
  <RowContainer>
    {(props.columns ?? []).map((column: IColumnProps, index: number) => {
      // columns don't change position between renders, so `index` as key here is fine
      return column && <Column key={column._key || index} {...column} />;
    })}
  </RowContainer>
);
