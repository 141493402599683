import { theme } from 'styles/configure-theme';

export const showQuantityLabel = false;

export const displayValueStyleProps = () => ({
  background: 'transparent',
  height: 8,
  minWidth: 8,
  width: 8,
});

export const incrementorStyleProps = ({ disabled }: { disabled?: boolean }) => ({
  padding: 1,
  borderRadius: 20,
  borderWidth: 1,
  borderColor: theme.token(disabled ? 'icon-disabled' : 'text-active'),
  _icon: {
    color: theme.token(disabled ? 'icon-disabled' : 'text-active'),
    size: 'xs',
  },
  _pressed: {
    backgroundColor: theme.token('background-button-secondary-hover'),
  },
});
