import { useCallback, useRef } from 'react';

import { useDisclose } from '@fhs-legacy/universal-components';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { useOpenClosedText } from 'pages/store-locator/new-ui/store-card/hooks/use-open-closed-text';
import { useCRMEventsContext } from 'state/crm-events';
import { DialogViewComponentNames } from 'state/crm-events/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import DateSingleton, { DateSingletonInstanceKey } from 'utils/dateTime/DateSingleton';

import { StoreClosedActionSheet } from './StoreClosedActionSheet';
import { IUseStoreClosedActionSheetHook } from './types';

const dateSingletonInstance = DateSingleton.getInstance(
  DateSingletonInstanceKey.storeClosedConfirmation
);

/**
 * Hook for request user confirmation on store closed future ordering
 */
export const useStoreClosedActionSheet: IUseStoreClosedActionSheetHook = ({
  restaurant,
  shouldCheckSingletonDate,
  isCatering,
}) => {
  const { isOpen, onClose, onOpen } = useDisclose();
  const enableFutureOrdering = useFlag(LaunchDarklyFlag.ENABLE_FUTURE_ORDERING);
  const requestResolverRef = useRef<(param: boolean) => void>();
  const { isOpen: isRestaurantOpen } = useOpenClosedText(restaurant as IRestaurantNode);
  const { isDelivery } = useServiceModeContext();
  const { logScheduleFutureOrder, logChangeRestaurant, logDialogView } = useCRMEventsContext();

  const handleChangeRestaurant = useCallback(() => {
    dateSingletonInstance.saveDate();

    const { physicalAddress } = restaurant;
    const currentDate = dateSingletonInstance.getDate();
    const orderTime = currentDate ? new Date(currentDate).toLocaleString() : 'no date provided';

    logChangeRestaurant({
      isCurrentRestaurantClosed: !isRestaurantOpen,
      orderTime,
      currentRestaurantId: restaurant._id || null,
      currentStoreId: restaurant.storeId || null,
      restaurantAddress: physicalAddress?.address1 || null,
      restaurantCity: physicalAddress?.city || null,
      restaurantCountry: physicalAddress?.country || null,
      restaurantState: physicalAddress?.stateProvince || null,
      restaurantZip: physicalAddress?.postalCode || null,
    });

    onClose();
    requestResolverRef.current?.(false);
  }, [isRestaurantOpen, restaurant, logChangeRestaurant, onClose]);

  const handleScheduleFutureOrder = useCallback(() => {
    dateSingletonInstance.saveDate();

    const { physicalAddress } = restaurant;
    const currentDate = dateSingletonInstance.getDate();
    const orderTime = currentDate ? new Date(currentDate).toLocaleString() : 'no date provided';

    logScheduleFutureOrder({
      isRestaurantClosed: !isRestaurantOpen,
      orderTime,
      restaurantId: restaurant._id || null,
      storeId: restaurant.storeId || null,
      restaurantAddress: physicalAddress?.address1 || null,
      restaurantCity: physicalAddress?.city || null,
      restaurantCountry: physicalAddress?.country || null,
      restaurantState: physicalAddress?.stateProvince || null,
      restaurantZip: physicalAddress?.postalCode || null,
    });

    onClose();
    requestResolverRef.current?.(true);
  }, [isRestaurantOpen, restaurant, logScheduleFutureOrder, onClose]);

  const requestScheduleFutureOrderingConfirmation = useCallback(async () => {
    if (!enableFutureOrdering || isRestaurantOpen || isCatering || isDelivery) {
      return true;
    }
    if (shouldCheckSingletonDate) {
      // If singletonDate date haven't expired, exit from request confirmation flow
      if (!dateSingletonInstance.isDateExpired()) {
        return Promise.resolve(true);
      }
      // Otherwise save current date and request user confirmation
      dateSingletonInstance.saveDate();
    }
    logDialogView(DialogViewComponentNames.STORE_CLOSED);
    onOpen();
    return new Promise<boolean>(resolve => {
      requestResolverRef.current = resolve;
    });
  }, [
    enableFutureOrdering,
    isRestaurantOpen,
    onOpen,
    shouldCheckSingletonDate,
    isCatering,
    isDelivery,
    logDialogView,
  ]);
  const storeClosedActionSheet = enableFutureOrdering ? (
    <StoreClosedActionSheet
      restaurant={restaurant}
      isOpen={isOpen}
      onChangeRestaurant={handleChangeRestaurant}
      onScheduleFutureOrder={handleScheduleFutureOrder}
    />
  ) : null;
  return {
    requestScheduleFutureOrderingConfirmation,
    storeClosedActionSheet,
  };
};
