import { LoyaltyRewards } from 'pages/loyalty/loyalty-rewards';
import { useFlag } from 'state/launchdarkly';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

const LoyaltyRewardsListWidget = () => {
  const enableRewardPageStandAlone = useFlag(LaunchDarklyFlag.ENABLE_REWARD_PAGE_STAND_ALONE);

  return <LoyaltyRewards isWidget={!enableRewardPageStandAlone} />;
};

export default LoyaltyRewardsListWidget;
