import { useCallback } from 'react';

import { ICartEntry, ISection, MenuObject } from '@rbi-ctg/menu';
import { useMenuCalories } from 'hooks/menu/use-menu-calories';
import { computeCurrentCalories } from 'utils/menu';
import { convertModActionsToSelections, getSelectionsFromMenuData } from 'utils/wizard';

export interface FormatRecentItemsCaloriesArgs {
  menuItem?: Exclude<MenuObject, ISection>;
  frontendEntry: ICartEntry | null;
  quantity: number;
}

export const useRecentItemsCalories = () => {
  const { formatCalories } = useMenuCalories();

  return useCallback(
    ({ menuItem, frontendEntry, quantity }: FormatRecentItemsCaloriesArgs): string | undefined => {
      if (!menuItem || !frontendEntry) {
        return undefined;
      }

      const selections = getSelectionsFromMenuData({
        data: menuItem,
        selectionsEntry: frontendEntry,
      });
      const currentCalories = computeCurrentCalories({
        item: menuItem,
        quantity,
        comboSlotSelections: selections?.comboSlotSelections,
        pickerSelections: frontendEntry.pickerSelections,
        modifierSelections: convertModActionsToSelections(selections?.modifierSelections ?? []),
      });

      return formatCalories(currentCalories);
    },
    [formatCalories]
  );
};
