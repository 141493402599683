import { Path, Svg } from 'react-native-svg';

import { tokens } from '../../..';
import { type IconProps } from '../types';

export const IconArrowUp = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? tokens.colors.$black}
      {...otherProps}
    >
      <Path
        d="M11.25 19.5V7.373l-5.696 5.696L4.5 12 12 4.5l7.5 7.5-1.054 1.07-5.696-5.697V19.5h-1.5Z"
        fill="currentColor"
      />
    </Svg>
  );
};
