import { Box, Header as UCLHeader } from '@fhs-legacy/universal-components';
import theme from 'components/error-boundary-static/theme';

export const Container = Box.withConfig({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

export const Inner = Box.withConfig({
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: '8',
});

export const Header = UCLHeader.withConfig({
  textTransform: theme.textTransform,
  textAlign: 'center',
  marginY: '5',

  maxWidth: {
    base: '3/4',
    desktop: '500px',
  },
});
