import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { IItem, IPrices, ISanityCombo, ServiceMode } from '@rbi-ctg/menu';
import { ItemAvailabilityStatus } from 'enums/menu';
import {
  ILoyaltyEngineRewardsQuery,
  LoyaltyEngineRewardsDocument,
  PaymentMethod,
  PromotionType,
} from 'generated/graphql-gateway';
import usePosVendor from 'hooks/menu/use-pos-vendor';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { useDayPartContext } from 'state/day-part';
import { IDayPartBoundary, IValidDayPart } from 'state/day-part/hooks/use-active-day-parts';
import { actions, useAppDispatch } from 'state/global-state';
import { ICartEntryAdapter } from 'state/global-state/models/loyalty/offers/offers.types';
import { client } from 'state/graphql/client';
import { IIncentiveEvaluationResult, IncentiveEvaluationMap } from 'state/loyalty/hooks/types';
import { createIncentivesFeedbackMaps } from 'state/loyalty/hooks/utils/helpers';
import { IEngineRewardsMap, ISanityRewardsMap, LoyaltyReward } from 'state/loyalty/types';
import { useStoreContext } from 'state/store';
import { getAvailabilityStatus, itemIsAvailable } from 'utils/availability';
import logger from 'utils/logger';
import { IWithVendorConfig, PosVendors } from 'utils/vendor-config';

import {
  buildOutOfDayPartEvaluationResult,
  buildVendorEvaluationResult,
} from './utils/incentives-availability';

interface IBaseRewardEvaluationParams {
  activeDayParts: IDayPartBoundary[];
  dayParts: readonly IValidDayPart[];
  prices: IPrices;
  vendor: PosVendors | null;
}
interface CheckRewardAvailabilityFunctionParams extends IBaseRewardEvaluationParams {
  reward: LoyaltyReward;
}

type CheckRewardAvailabilityFunction = (
  params: CheckRewardAvailabilityFunctionParams
) => IIncentiveEvaluationResult | null;

interface IRewardsMaps {
  sanityRewardsMap: ISanityRewardsMap | null;
  engineRewardsMap: IEngineRewardsMap;
}

type BuildAvailabilityMapParams = IRewardsMaps & IBaseRewardEvaluationParams;

export const useRewardsEvaluation = () => {
  const dispatch = useAppDispatch();
  const { engineRewardsMap, sanityRewardsMap } = useLoyaltyRewardsList();
  const { prices } = useStoreContext();
  const { vendor } = usePosVendor();
  const { activeDayParts, dayParts } = useDayPartContext();
  const [rewardsEvaluationMap, setRewardsEvaluationMap] = useState<IncentiveEvaluationMap>({});

  useEffect(() => {
    if (!isEmpty(sanityRewardsMap) && !isEmpty(engineRewardsMap) && !!prices) {
      const evaluationsMap = buildEvaluationResultsMap({
        sanityRewardsMap: sanityRewardsMap!,
        engineRewardsMap,
        activeDayParts,
        dayParts,
        prices,
        vendor,
      });

      setRewardsEvaluationMap(evaluationsMap);
    }
  }, [
    prices,
    activeDayParts,
    dayParts,
    sanityRewardsMap,
    engineRewardsMap,
    setRewardsEvaluationMap,
    vendor,
  ]);

  const evaluateLoyaltyRewardsQuery = useCallback(
    async ({
      loyaltyId,
      appliedIncentives,
      cartEntries,
      rewardIds,
      subtotalAmount,
      serviceMode,
      storeId,
      paymentMethod,
    }: {
      loyaltyId: string;
      appliedIncentives: {
        id: string;
        type: PromotionType;
      }[];
      cartEntries?: ICartEntryAdapter[];
      rewardIds: string[];
      subtotalAmount: number;
      paymentMethod?: PaymentMethod | null;
      serviceMode?: ServiceMode | null;
      storeId?: string | null;
    }): Promise<IncentiveEvaluationMap | null> => {
      let evaluationResult: IncentiveEvaluationMap | null = null;

      try {
        dispatch(actions.loyalty.setIsEvaluatingAppliedLoyaltyRewards(true));

        const { data } = await client.query<ILoyaltyEngineRewardsQuery>({
          query: LoyaltyEngineRewardsDocument,
          fetchPolicy: 'network-only',
          variables: {
            loyaltyId,
            where: {
              appliedIncentives,
              cartEntries,
              rewardIds,
              subtotalAmount,
              omitInvalids: false,
              paymentMethod,
              serviceMode,
              storeId,
              isTransactionCreation: true,
            },
          },
        });
        if (data?.loyaltyRewardsV2) {
          const { incentiveFeedbackMap: rewardFeedbackMap } = createIncentivesFeedbackMaps(
            data.loyaltyRewardsV2
          );

          evaluationResult = rewardFeedbackMap;
        }
      } catch (e) {
        logger.error(`Loyalty Rewards V2 evaluation error: ${e}`);
        throw e;
      } finally {
        if (evaluationResult) {
          dispatch(actions.loyalty.setAppliedLoyaltyRewardsFeedbackMap(evaluationResult));
        }
        dispatch(actions.loyalty.setIsEvaluatingAppliedLoyaltyRewards(false));
        return evaluationResult;
      }
    },
    [dispatch]
  );

  return {
    evaluateLoyaltyRewardsQuery,
    rewardsEvaluationMap,
    engineRewardsMap,
    sanityRewardsMap,
  };
};

const buildEvaluationResultsMap = ({
  sanityRewardsMap,
  engineRewardsMap,
  activeDayParts,
  dayParts,
  prices,
  vendor,
}: BuildAvailabilityMapParams) => {
  const rewardsArray = Object.values(sanityRewardsMap ?? {});
  const evaluationsMap = rewardsArray.reduce((evaluationMap, sanityReward) => {
    const loyaltyEngineId = sanityReward?.loyaltyEngineId ?? '';
    const engineReward = engineRewardsMap[loyaltyEngineId];

    if (!engineReward || engineReward?.locked) {
      evaluationMap[loyaltyEngineId] = [];
    } else {
      const evaluationsResult = checkIncentiveBasicAvailability({
        activeDayParts,
        dayParts,
        prices,
        reward: sanityReward,
        vendor,
      });

      if (evaluationsResult) {
        evaluationMap[loyaltyEngineId] = [evaluationsResult];
      }
    }

    return evaluationMap;
  }, {});

  return evaluationsMap;
};

// This function checks the reward availability and wraps `getAvailabilityStatus` that creates a shallow evaluation of menu items
// It is not an extensive deep evaluation of menu items/combos
const checkIncentiveBasicAvailability: CheckRewardAvailabilityFunction = ({
  activeDayParts,
  dayParts,
  prices,
  reward,
  vendor,
}) => {
  // the reward should be available to check the incentive availability
  const isRewardAvailable = itemIsAvailable(reward as IWithVendorConfig, vendor, prices);
  if (!isRewardAvailable) {
    return buildVendorEvaluationResult();
  }

  const incentive = reward?.incentives?.[0];

  const status = getAvailabilityStatus({
    data: incentive as IItem | ISanityCombo | null,
    activeDayParts,
    prices,
    vendor,
    isExtra: false,
  });

  let returnValue: null | IIncentiveEvaluationResult = null;

  switch (status) {
    case ItemAvailabilityStatus.AVAILABLE:
    case ItemAvailabilityStatus.STORE_NOT_SELECTED:
      break;
    case ItemAvailabilityStatus.OUT_OF_DAYPART:
      returnValue = buildOutOfDayPartEvaluationResult(reward, dayParts);
      break;
    default:
      returnValue = buildVendorEvaluationResult();
  }

  return returnValue;
};

export default useRewardsEvaluation;
