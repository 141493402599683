import { LoyaltyOffer, LoyaltyReward } from 'state/loyalty/types';

export enum RedemptionMethod {
  MOBILE,
  IN_RESTAURANT,
}

export type Incentive = LoyaltyOffer | LoyaltyReward;

export type AddToCartArgs = {
  incentive: Incentive;
  method: RedemptionMethod;
};
