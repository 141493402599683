import { useCallback, useEffect } from 'react';

import { useApiKey } from 'hooks/configs/use-api-key';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { isLocalDev } from 'utils/environment';
import { loadLanguage } from 'utils/intl/language';
import logger from 'utils/logger';

import { destroyBraze, initializeBraze, requestImmediateDataFlush, setLanguage } from './braze-api';

export const useInitializeBraze = () => {
  const brazeKey = useApiKey({ key: 'braze' });
  const brazeUrl = useConfigValue({ key: 'urls', defaultValue: {} }).braze as string;
  const enableHtmlInAppMessagesInBrazeSdkConfig = useFlag(
    LaunchDarklyFlag.ENABLE_HTML_IN_APP_MESSAGES_IN_BRAZE_SDK
  );

  return useCallback(() => {
    if (!brazeKey || !brazeUrl) {
      return undefined;
    }

    const initialized = initializeBraze(brazeKey, {
      baseUrl: brazeUrl,
      noCookies: true,
      enableLogging: isLocalDev === true,
      // `allowUserSuppliedJavascript` is required to support braze in-app message custom html views which certain campaigns
      // like the mystery sub campaign require.
      // This does expose a potential security risk as it lets braze execute javascript in our environment.
      allowUserSuppliedJavascript: enableHtmlInAppMessagesInBrazeSdkConfig,
    });

    if (initialized) {
      setLanguage(loadLanguage());
    } else {
      const errorMessage = '[BRAZE] Uninitialized error';
      logger.error({ message: errorMessage });
    }
  }, [brazeKey, brazeUrl]);
};

// Do things for braze on app mount / unmount
export const useBrazeLifecycle = () => {
  const initialize = useInitializeBraze();

  useEffect(() => {
    initialize();

    return () => {
      requestImmediateDataFlush();
      destroyBraze();
    };
  }, [initialize]);
};

export const BrazeLifecycle = () => {
  useBrazeLifecycle();
  return null;
};
