import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { Box } from '@fhs-legacy/universal-components';
import { ICartEntry } from '@rbi-ctg/menu';
import CartItem from 'components/cart-item';
import useDebouncedFocusEffect from 'hooks/use-debounce-focus-effect';
import useIsSignUpAfterCart from 'hooks/use-is-sign-up-after-cart';
import Totals from 'pages/cart/your-cart/totals';
import { useCartContext } from 'state/cart';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { useLoyaltyContext } from 'state/loyalty';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { useOffersContext } from 'state/offers';
import { useOrderContext } from 'state/order';
import { IPaymentMethod } from 'state/payment/types';
import { useStoreContext } from 'state/store';
import { CartEntryType } from 'utils/cart';
import { mapPaymentMethodToLoyaltyPaymentMethods } from 'utils/payment';

import { CartPromoCodes } from '../cart-promo-codes';

import { AddMoreItemsButton } from './add-more-items-button';
import { LoyaltyDiscountCartOffer } from './cart-offer';
import DonationContainer from './donation-container';
import FoodwareContainer from './foodware-container';
import { RewardDropdown } from './reward-dropdown';
import { CartItems } from './styled';
import YourCartHeading from './your-cart-heading';

interface IYourCartProps {
  checkoutPaymentMethod?: IPaymentMethod | null;
  readOnly?: boolean;
  redeemableItemsSet?: Set<string | unknown>;
  showAddItemsBtn?: boolean;
  showAddExtrasBtn?: boolean;
  showCartPromoCodes?: boolean;
}

const YourCart: React.FC<React.PropsWithChildren<IYourCartProps>> = ({
  checkoutPaymentMethod,
  readOnly = false,
  redeemableItemsSet = new Set(),
  showAddItemsBtn = true,
  showAddExtrasBtn = true,
  showCartPromoCodes = true,
}) => {
  const {
    cartEntries,
    orderStatus,
    pricedAndAvailable = true,
    serverOrder,
    serviceMode,
    shouldSkipPriceOrder,
    calculateCartTotalWithDiscount,
  } = useCartContext();
  const orderCtx = useOrderContext();
  const offerCtx = useOffersContext();
  const { loyaltyUserId } = useLoyaltyUser();
  const {
    queryLoyaltyUserSurpriseOffers,
    loyaltySurpriseOffersEnabled,
    evaluateLoyaltyUserIncentives,
    evaluateLoyaltyUserRewards,
  } = useLoyaltyContext();
  const { store } = useStoreContext();
  const showActionButtons = showAddItemsBtn || showAddExtrasBtn;
  const isSignUpAfterCart = useIsSignUpAfterCart();
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const appliedLoyaltyOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const selectedOfferSelections = useAppSelector(selectors.loyalty.selectSelectedOfferSelections);
  const appliedLoyaltyDiscountOffer = useAppSelector(
    selectors.loyalty.selectDiscountAppliedCmsOffer
  );
  const dispatch = useAppDispatch();
  const rewardApplied = useMemo(
    () => cartEntries.some((item: ICartEntry) => redeemableItemsSet.has(item.cartId)),
    [cartEntries, redeemableItemsSet]
  );

  const cartOfferItem = useMemo(() => {
    if (offerCtx?.selectedOffer && offerCtx?.selectedOfferCartEntry) {
      return {
        ...offerCtx.selectedOfferCartEntry,
        price: offerCtx.selectedOfferPrice || 0,
      };
    }

    return null;
  }, [offerCtx]);

  const appliedRewardsCount = useRef(0);
  const cartEntriesCount = useRef(0);

  const { offerValidationErrors = [] } = offerCtx || {};
  const offerIsValid = offerValidationErrors.length === 0;

  const { selectedOffer, validateOfferRedeemable } = offerCtx ?? {};

  useEffect(() => {
    if (selectedOffer && validateOfferRedeemable) {
      // validateOfferRedeemable will update the offer.offerValidationErrors
      validateOfferRedeemable({
        cart: { cartEntries, serviceMode, store },
        selectedOffer,
        rbiOrderId: serverOrder?.rbiOrderId,
      });
    }
  }, [
    orderCtx.cartEntries,
    serviceMode,
    selectedOffer,
    serverOrder,
    store,
    validateOfferRedeemable,
    cartEntries,
  ]);

  const { cartTotal } = calculateCartTotalWithDiscount();

  useEffect(() => {
    // Resetting offer feedback on mount to avoid carrying over feedback from offer list
    dispatch(actions.loyalty.resetOfferFeedbackMap());
    dispatch(actions.loyalty.setIsDiscountNotApplied(false));
  }, [dispatch]);

  const runIncentivesEvaluation = useCallback(() => {
    if (!loyaltyUserId) {
      return;
    }
    const paymentMethod = mapPaymentMethodToLoyaltyPaymentMethods(checkoutPaymentMethod);

    if (appliedLoyaltyOffers.length) {
      evaluateLoyaltyUserIncentives({
        loyaltyId: loyaltyUserId,
        appliedLoyaltyOffers,
        cartEntries,
        appliedRewards: appliedLoyaltyRewards,
        subtotalAmount: cartTotal,
        paymentMethod,
        appliedLoyaltyDiscountOffer,
        incentiveSelections: selectedOfferSelections,
      });
    }

    if (isEmpty(appliedLoyaltyRewards)) {
      dispatch(actions.loyalty.resetAppliedLoyaltyRewardsFeedbackMap());
      return;
    }

    evaluateLoyaltyUserRewards({
      loyaltyId: loyaltyUserId,
      appliedLoyaltyRewards,
      cartEntries,
      subtotalAmount: cartTotal,
      paymentMethod,
    });
  }, [
    loyaltyUserId,
    appliedLoyaltyRewards,
    appliedLoyaltyOffers,
    checkoutPaymentMethod,
    evaluateLoyaltyUserIncentives,
    evaluateLoyaltyUserRewards,
    cartEntries,
    cartTotal,
    appliedLoyaltyDiscountOffer,
    dispatch,
    selectedOfferSelections,
  ]);

  useDebouncedFocusEffect(
    useCallback(() => {
      const validCartEntriesCount =
        // Donation and Extras entries should not be taken into account for querying offers
        cartEntries?.filter(({ isExtra, isDonation }: ICartEntry) => !isDonation && !isExtra)
          .length ?? 0;
      const appliedRewardsLength = Object.keys(appliedLoyaltyRewards).length;
      const entryAdded = cartEntriesCount.current < validCartEntriesCount;
      const rewardUnapplied = appliedRewardsCount.current > appliedRewardsLength;
      const shouldFetchOffers = entryAdded || rewardUnapplied;
      const cartEntriesWithoutOffers = cartEntries?.filter(
        ({ type }: ICartEntry) =>
          ![
            CartEntryType.offerCombo,
            CartEntryType.offerDiscount,
            CartEntryType.offerItem,
          ].includes(type)
      );

      runIncentivesEvaluation();

      if (loyaltySurpriseOffersEnabled && shouldFetchOffers && loyaltyUserId) {
        queryLoyaltyUserSurpriseOffers({
          loyaltyId: loyaltyUserId,
          cartEntries: cartEntriesWithoutOffers, // passing cart entries without offers to exclude upsize swap suggestions for offer benefits
          appliedRewards: appliedLoyaltyRewards,
          appliedLoyaltyOffers,
        });
      }

      appliedRewardsCount.current = appliedRewardsLength;
      cartEntriesCount.current = validCartEntriesCount;
    }, [
      queryLoyaltyUserSurpriseOffers,
      orderCtx.cartEntries,
      appliedLoyaltyRewards,
      loyaltySurpriseOffersEnabled,
      loyaltyUserId,
      runIncentivesEvaluation,
      appliedLoyaltyOffers,
      pricedAndAvailable,
      cartEntries,
    ]),
    250
  );

  return (
    <Box>
      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        <YourCartHeading />
        {showActionButtons && showAddItemsBtn && <AddMoreItemsButton />}
      </Box>

      <RewardDropdown />
      <Box accessibilityLabel="cart-section-heading-label">
        <CartItems testID="cart-items" marginBottom="$4">
          {appliedLoyaltyDiscountOffer && (
            <LoyaltyDiscountCartOffer selectedLoyaltyOffer={appliedLoyaltyDiscountOffer} />
          )}
          {cartOfferItem && (
            <CartItem
              item={cartOfferItem}
              key={offerCtx?.selectedOfferCartEntry?.cartId || 'this-key-is-not-necessary'}
              selectedOfferId={offerCtx?.selectedOffer?._id}
              rewardApplied={offerIsValid}
            />
          )}
          {cartEntries
            .filter((item: ICartEntry) => cartOfferItem?.cartId !== item.cartId)
            .map((item: ICartEntry) => {
              return (
                <CartItem
                  item={item}
                  key={item.cartId || item._id}
                  readOnly={readOnly}
                  rewardApplied={
                    appliedLoyaltyRewards[item.cartId]?.timesApplied > 0 ||
                    redeemableItemsSet.has(item.cartId)
                  }
                  showReadOnlyQuantity
                />
              );
            })}
        </CartItems>

        <FoodwareContainer />
        <DonationContainer />

        {/* promo code checkout content flow */}
        {showCartPromoCodes && !isSignUpAfterCart && <CartPromoCodes />}
        <Totals
          // @ts-expect-error TS(2322) FIXME: Type '{ orderStatus: OrderStatus | null; serverOrd... Remove this comment to see the full error message
          orderStatus={orderStatus}
          serverOrder={serverOrder}
          serviceMode={serviceMode}
          rewardApplied={rewardApplied}
          shouldSkipPriceOrder={shouldSkipPriceOrder}
        />
      </Box>
    </Box>
  );
};

export default React.memo(YourCart);
