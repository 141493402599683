import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ICartEntry } from '@rbi-ctg/menu';
import { getUserModifications } from 'components/product-detail/product-customization/utils';
import {
  IProductWizardContext,
  IProductWizardProvider,
  IUserManualModifications,
  IUserSelection,
  Product,
  ProductMenuObject,
  ProductWizardStep,
  SubmitCustomizationsFn,
  UserSelectionComboSlot,
} from 'state/product-wizard/types';
import { ProductWizardUtils } from 'state/product-wizard/utils';
import { MIN_CART_QUANTITY } from 'utils/cart';
import logger from 'utils/logger';

import { useEditingCartEntry } from './hooks/use-editing-cart-entry';

const ProductWizardContext = createContext<IProductWizardContext>({
  menuObject: {} as ProductMenuObject,
  productQuantity: MIN_CART_QUANTITY,
  selectedProduct: {} as Product,
  setProductQuantity: () => {},
  submitCustomizations: () => null,
  updatePickerAspectSelections: () => null,
  userSelections: {} as IUserSelection,
  manualModifications: {} as IUserManualModifications,
  isDirty: false,
  _wizardFlowStep: ProductWizardStep.ProductHome,
  _setWizardFlowStep: (_step: ProductWizardStep) => {},
  _slotKeyToCustomize: '',
  _setSlotKeyToCustomize: (_slotKey: string) => {},
  isSimplyOffer: false,
  addItemToSimplyOfferCart: (_cartEntry: ICartEntry) => {},
});

export const ProductWizardProvider: React.FC<React.PropsWithChildren<IProductWizardProvider>> = ({
  product: menuObject,
  isSimplyOffer,
  addItemToSimplyOfferCart,
  children,
}) => {
  const [_wizardFlowStep, _setWizardFlowStep] = useState(ProductWizardStep.ProductHome);
  const [_slotKeyToCustomize, _setSlotKeyToCustomize] = useState('');

  const editingCartEntry = useEditingCartEntry(isSimplyOffer);
  const [manualModifications, setManualModifications] = useState<IUserManualModifications>({
    pickerAspects: false,
    comboSlot: false,
    modifiers: false,
  });
  const [userSelections, setUserSelections] = useState<IUserSelection>(() =>
    ProductWizardUtils.SetDefaultSelections({ menuObject, cartEntryOverrides: editingCartEntry })
  );
  const [selectedProduct, setSelectedProduct] = useState<Product>(() =>
    ProductWizardUtils.ComputeSelectedOption(menuObject, userSelections.pickerAspects)
  );
  const defaultComboSlotSelections = useMemo<UserSelectionComboSlot>(
    () =>
      ProductWizardUtils.SetDefaultSelections({
        menuObject,
        pickerSelectionOverrides: userSelections.pickerAspects,
      }).comboSlot || new Map(),
    [menuObject, userSelections.pickerAspects]
  );

  useEffect(() => {
    if (!userSelections.comboSlot) {
      return;
    }
    const modifications = getUserModifications(
      userSelections.comboSlot,
      userSelections.modifiers,
      defaultComboSlotSelections
    );
    setManualModifications(prev => ({
      ...prev,
      comboSlot: prev.comboSlot || modifications.comboSlot,
      modifiers: prev.modifiers || modifications.modifiers,
    }));
  }, [defaultComboSlotSelections, userSelections.comboSlot, userSelections.modifiers]);

  const isDirty = useMemo<boolean>(() => {
    if (!userSelections.comboSlot) {
      return false;
    }
    const modifications = getUserModifications(
      userSelections.comboSlot,
      userSelections.modifiers,
      defaultComboSlotSelections
    );
    return modifications.comboSlot || modifications.modifiers;
  }, [defaultComboSlotSelections, userSelections.comboSlot, userSelections.modifiers]);

  const [productQuantity, setProductQuantity] = useState(
    editingCartEntry?.quantity || MIN_CART_QUANTITY
  );

  const updatePickerAspectSelections = useCallback(
    (args: any) => {
      setSelectedProduct(ProductWizardUtils.ComputeSelectedOption(menuObject, args));
      setUserSelections(
        ProductWizardUtils.SetDefaultSelections({
          menuObject,
          pickerSelectionOverrides: args,
        })
      );
      setManualModifications(prev => ({ ...prev, pickerAspects: true }));
    },
    [menuObject]
  );

  const submitCustomizations = useCallback<SubmitCustomizationsFn>(
    ({ modifiers, selectedItem, slotKey }) => {
      setUserSelections(current => {
        const comboSlot = current.comboSlot ? new Map(current.comboSlot) : new Map();
        comboSlot.set(slotKey, {
          comboSlot: current.comboSlot?.get(slotKey)?.comboSlot,
          selectedItem,
        });

        return {
          ...current,
          comboSlot,
          modifiers: {
            ...current.modifiers,
            [slotKey]: modifiers,
          },
        };
      });
    },
    []
  );

  if (!selectedProduct) {
    logger.error({
      errorMessage: `No selected product. Check data config for menu item with id: ${menuObject._id} and name: ${menuObject.name.locale}`,
    });
    return null;
  }

  return (
    <ProductWizardContext.Provider
      value={{
        menuObject,
        productQuantity,
        selectedProduct,
        setProductQuantity,
        submitCustomizations,
        updatePickerAspectSelections,
        userSelections,
        manualModifications,
        isDirty,
        _wizardFlowStep,
        _setWizardFlowStep,
        _slotKeyToCustomize,
        _setSlotKeyToCustomize,
        isSimplyOffer,
        addItemToSimplyOfferCart,
      }}
    >
      {children}
    </ProductWizardContext.Provider>
  );
};

export const useProductWizardContext = () => useContext(ProductWizardContext);
