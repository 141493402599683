import { Header } from '@fhs-legacy/universal-components';

const CartErrorHeading = Header.withConfig({
  variant: 'headerTwo',
  fontSize: '2xl',
  lineHeight: '2xl',
  textAlign: 'center',
});

export default CartErrorHeading;
