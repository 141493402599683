import { useEffect } from 'react';
import { AppState } from 'react-native';

import { UseUpdateOnAppStateChange } from './types';

export const useUpdateOnAppStateChange: UseUpdateOnAppStateChange = onAppStateChange => {
  useEffect(() => {
    const subscription = AppState.addEventListener('change', appStateStatus => {
      onAppStateChange(appStateStatus);
    });

    return () => {
      subscription?.remove();
    };
  }, [onAppStateChange]);
};
