import React from 'react';
import { GestureResponderEvent } from 'react-native';

type NativeAnchorProps = React.ComponentPropsWithRef<'a'>;

export interface CardProps {
  /** The image element displayed in the top portion of the card */
  image: React.ReactNode;

  /** Header text */
  header: string;

  /** Description text */
  description: string;

  /** Call to action button text */
  callToAction: string;

  variant?: 'default' | 'campaign';

  bgColor?: string;
  textColor?: string;
  buttonBgColor?: string;
  buttonTextColor?: string;

  /** URL to the target page */
  href: NonNullable<NativeAnchorProps['href']>;

  /** A unique identifier used for press tracking */
  marketingId?: string;

  /**
   * Callback for hooking up analytics, etc.
   */
  onPress?: (
    event: GestureResponderEvent | undefined,
    data: { marketingId: CardProps['marketingId'] }
  ) => void;

  /**
   * The label used for accessibility
   */
  termsAccessibilityLabel?: string;

  /**
   * The text to be used for the terms button.
   *
   * You must also specify one of the following:
   * {@link CardProps.termsLinkHref}, to navigate to a new page upon pressing the
   * terms
   * OR
   * {@link CardProps.onPressTermsLink}, to show the terms in a modal upon
   * pressing the terms
   */
  termsLinkText?: string;

  /**
   * The URL to be used for the terms link.
   * If {@link CardProps.termsLinkText} is provided but
   * {@link CardProps.termsLinkHref} is not, the terms must be shown in a modal
   */
  termsLinkHref?: NativeAnchorProps['href'];

  /**
   * Paired with {@link CardProps.termsLinkHref}, this can be used for press
   * tracking, etc. When {@link CardProps.termsLinkHref} is not defined, this
   * should be used to open a modal with the terms
   */
  onPressTermsLink?: (
    event: GestureResponderEvent | undefined,
    data: { marketingId: CardProps['marketingId'] }
  ) => void;
}
