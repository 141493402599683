import { LoyaltyBarcodePaymentType } from 'pages/cart/types';
import { CardTypes, IPaymentMethod } from 'state/payment/types';
import { CartEntryType } from 'utils/cart';
import { mapPaymentMethodCardTypes } from 'utils/payment/map-payment-method-card-type';
import { isApplePay, isGooglePay } from 'utils/payment/native-payment';

export const mapEntryTypeToOfferType = (entryType: CartEntryType) => {
  let offerType;

  switch (entryType) {
    case CartEntryType.combo:
    case CartEntryType.offerCombo:
      offerType = CartEntryType.offerCombo;
      break;
    case CartEntryType.item:
    case CartEntryType.offerItem:
    default:
      offerType = CartEntryType.offerItem;
      break;
  }

  return offerType;
};

export const getLoyaltyBarcodePaymentTypeId = (paymentMethod: IPaymentMethod) => {
  if (isApplePay(paymentMethod.fdAccountId)) {
    return LoyaltyBarcodePaymentType.APPLE_PAY;
  }
  if (isGooglePay(paymentMethod.fdAccountId)) {
    return LoyaltyBarcodePaymentType.GOOGLE_PAY;
  }

  if (paymentMethod.credit) {
    const cardType = mapPaymentMethodCardTypes(paymentMethod.credit.cardType);
    switch (cardType) {
      case CardTypes.AMEX:
        return LoyaltyBarcodePaymentType.AMEX;
      case CardTypes.VISA:
        return LoyaltyBarcodePaymentType.VISA;
      case CardTypes.MASTERCARD:
        return LoyaltyBarcodePaymentType.MASTERCARD;
      case CardTypes.DISCOVER:
        return LoyaltyBarcodePaymentType.DISCOVER;
      default:
        break;
    }
  }

  if (paymentMethod.prepaid) {
    return null;
  }

  return null;
};
