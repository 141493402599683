import React from 'react';

import { PriceAndCalories } from 'components/price-and-calories';
import { useFormatCalories } from 'hooks/use-format-calories';

import { SimpleWizardItemWrapper, Spacer, Title } from './simple-wizard-item.styled';

interface ISimpleWizardItemProps {
  title: string;
  calories: number;
}

const SimpleWizardItem: React.FC<React.PropsWithChildren<ISimpleWizardItemProps>> = ({
  title,
  calories,
}) => {
  const formatCalories = useFormatCalories();

  return (
    <Spacer>
      <SimpleWizardItemWrapper>
        <Title>{title}</Title>
        <PriceAndCalories
          currentPrice={''}
          formattedCalories={formatCalories(calories)}
          shouldHideCalories={false}
          shouldHidePrice={true}
        />
      </SimpleWizardItemWrapper>
    </Spacer>
  );
};

export default SimpleWizardItem;
