import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconTwitter = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M23.149 5.231a9.084 9.084 0 0 1-2.61.716 4.558 4.558 0 0 0 1.998-2.514c-.878.52-1.85.899-2.886 1.102a4.544 4.544 0 0 0-7.743 4.145 12.9 12.9 0 0 1-9.366-4.748 4.522 4.522 0 0 0-.615 2.285c0 1.576.802 2.967 2.021 3.782a4.526 4.526 0 0 1-2.058-.568v.057a4.547 4.547 0 0 0 3.645 4.456 4.556 4.556 0 0 1-2.052.077 4.549 4.549 0 0 0 4.245 3.156 9.117 9.117 0 0 1-5.644 1.945c-.367 0-.729-.021-1.084-.063A12.862 12.862 0 0 0 7.966 21.1c8.358 0 12.929-6.925 12.929-12.93 0-.197-.005-.393-.013-.588a9.23 9.23 0 0 0 2.267-2.352"
        clipRule="evenodd"
      />
    </Svg>
  );
};
