import { Header, Text, View } from '@fhs-legacy/universal-components';
import { WEB_APP_DESKTOP_LG_MAX_WIDTH } from 'utils/constants';

export const ButtonText = Text.withConfig({
  color: Styles.color.white,
  textAlign: 'center',
  fontFamily: 'heading',
});

export const ButtonSubText = Text.withConfig({
  fontSize: 'xs',
  color: Styles.color.white,
  textAlign: 'center',
  fontFamily: 'heading',
});

export const Heading = Header.withConfig({
  variant: 'headerFour',
  color: Styles.color.black,
  textAlign: 'center',
});

export const OrderConfirmationContainer = View.withConfig({
  alignSelf: 'center',
  flex: 1,
  paddingX: { desktop: '$4' },
  width: {
    base: 'full',
    desktop: WEB_APP_DESKTOP_LG_MAX_WIDTH,
  },
});
