import { ScreenContent } from '@fhs/screen-content';
import { Box, Text, makeUclComponent } from '@fhs-legacy/universal-components';

export const Placeholder = Text.withConfig({
  color: Styles.color.white,
  height: '$2.5',
});

export const WidgetElementWrapper = Box.withConfig({
  height: 'auto',
});

export const StyledScreenContent = makeUclComponent(ScreenContent).withConfig({
  backgroundColor: {
    base: Styles.color.white,
    desktop: 'transparent',
  },
});
