import Base from './base';
import theme from './theme';

const AuthContainer = Base.withConfig({
  justifyContent: 'center',
  position: 'relative',

  ...(theme.isCardDesktop && {
    borderRadius: Styles.borderRadius,
    backgroundColor: Styles.color.white,
    shadow: 1,
  }),
});

export default AuthContainer;
