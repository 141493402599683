import { Box } from '@fhs-legacy/universal-components';
import { WEB_APP_DESKTOP_LG_MAX_WIDTH } from 'utils/constants';

import { IContentProps } from './types';

const Content = Box.withConfig<IContentProps>({
  alignSelf: 'center',
  width: '100%',
  maxWidth: WEB_APP_DESKTOP_LG_MAX_WIDTH,
  paddingX: '$4',

  paddingY: {
    base: '$4',
    desktop: '$10',
  },
});

export default Content;
