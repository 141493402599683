export const HIGHLIGHT_COLOR = Styles.color.loyaltyHighlight;

export const incentiveCardImageDefaultProps = {
  width: '120px',
  height: '80px',
  borderRadius: 5,
  objectFitContain: true,
};

export const SHOULD_SELECT_STORE = false;
export const DISABLE_IN_RESTAURANT_FOR_INVALID_SERVICE_MODE = false;
export const INCENTIVE_MODAL_BG_COLOR = Styles.color.grey.five;
