import Footer from 'components/footer';
import { LoyaltyNotEnrolled } from 'pages/loyalty/loyalty-not-enrolled';
import { useAuthContext } from 'state/auth';

import { StyledScreenContent } from './loyalty-dashboard.styled';
import { LoyaltyDashboardView } from './loyalty-dashboard.view';

const LoyaltyDashboard = () => {
  const { user } = useAuthContext();

  let content = !user?.loyaltyId ? <LoyaltyNotEnrolled /> : <LoyaltyDashboardView />;

  return (
    <StyledScreenContent testID="loyalty-dashboard" after={<Footer />}>
      {content}
    </StyledScreenContent>
  );
};

export default LoyaltyDashboard;
