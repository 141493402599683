import React, { ComponentProps } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { ScrollViewProps, VStack } from '@fhs-legacy/universal-components';
import { IBaseProps, OnClick } from '@rbi-ctg/frontend';
import { CloseButtonIconProps } from 'components/close-button';
import usePreventBodyScroll from 'hooks/use-prevent-body-scroll';
import { theme } from 'styles/configure-theme';

import Header from './header';

export interface IFullPageScreenProps extends IBaseProps {
  heading: string;
  headerBottom?: React.ReactNode;
  footer?: React.ReactNode;
  closeHref?: string;
  onClose?: OnClick;
  disableClose?: boolean;
  skipGoBackOnClose?: boolean;
  styleOverrides?: {
    header?: ComponentProps<typeof Header>;
    scrollView?: ScrollViewProps;
  };
  closeIcon?: CloseButtonIconProps;
  cartFullPageScreenRef?: any;
}

const FullPageScreen = ({
  children,
  heading,
  headerBottom,
  closeHref,
  onClose,
  disableClose,
  closeIcon = 'cancel',
  skipGoBackOnClose = false,
  testID,
  styleOverrides = {},
  cartFullPageScreenRef,
}: IFullPageScreenProps) => {
  usePreventBodyScroll();

  return (
    <>
      <Header
        closeHref={closeHref}
        heading={heading}
        onClose={onClose}
        disableClose={disableClose}
        skipGoBackOnClose={skipGoBackOnClose}
        closeIcon={closeIcon}
        {...styleOverrides.header}
      />
      {/* TODO: RN - Cart has nested FullPageScreens. We will need to address this as part of the RN routing */}
      <VStack
        height="full"
        width="full"
        position="absolute"
        background={theme.token('background-default')}
      >
        {headerBottom}
        <KeyboardAwareScrollView
          keyboardShouldPersistTaps="handled"
          flex={1}
          contentContainerStyle={{ flexShrink: 0 }}
          testID={testID}
          {...styleOverrides.scrollView}
          innerRef={ref => {
            if (cartFullPageScreenRef) {
              cartFullPageScreenRef.current = ref;
            }
          }}
        >
          {children}
        </KeyboardAwareScrollView>
      </VStack>
    </>
  );
};

export default FullPageScreen;
