import { gql } from '@apollo/client';

export const offerRedemptions = gql`
  query listCoupons(
    $expiresAfter: String
    $expiresBefore: String
    $includeRedeemed: Boolean
    $limit: Int
    $resumeAfter: String
    $sortAscending: Boolean
  ) {
    listCoupons(
      expiresAfter: $expiresAfter
      expiresBefore: $expiresBefore
      includeRedeemed: $includeRedeemed
      limit: $limit
      resumeAfter: $resumeAfter
      sortAscending: $sortAscending
    ) {
      count
      coupons {
        couponId
        createdAt
        expiresOn
        redeemedOn
        updatedAt
        userId
      }
      resumeAfter
    }
  }
`;

export const isOfferRedeemable = gql`
  query isOfferRedeemable(
    $couponId: ID!
    $cart: PriceOrderInput
    $rbiOrderId: ID
    $redeemedOn: String!
    $ruleSet: String
  ) {
    isCouponRedeemable(
      cart: $cart
      couponId: $couponId
      rbiOrderId: $rbiOrderId
      redeemedOn: $redeemedOn
      ruleSet: $ruleSet
    ) {
      isRedeemable
      evaluationFeedback {
        code
        condition
        message
        ruleSetType
      }
    }
  }
`;

export const activateLoyaltyOfferMutation = gql`
  mutation activateLoyaltyOffer($tokenId: String!) @authRequired {
    activateLoyaltyOffer(tokenId: $tokenId)
  }
`;
