import { IAllowRedemptionWithoutLogin } from '@rbi-ctg/offers';

import { ruleOfTypeWillApply } from './rule-of-type-will-apply';
import { RuleType } from './types';

export const allowsUnauthenticatedRedemption = ruleOfTypeWillApply<IAllowRedemptionWithoutLogin>(
  RuleType.AllowRedemptionWithoutLogin
);

export default allowsUnauthenticatedRedemption;
