import { IBackendCartEntries, ICartEntry } from '@rbi-ctg/menu';
import { useFeatureFoodwareQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

const useFoodware = () => {
  const enableFoodwareItems = useFlag(LaunchDarklyFlag.ENABLE_FOODWARE_ITEMS);
  const { featureFoodwareId = '', featureIdsLoading } = useFeaturesContext();
  const { data, loading } = useFeatureFoodwareQuery({
    variables: {
      featureFoodwareId,
    },
    skip: featureIdsLoading || !enableFoodwareItems,
  });

  const isFoodware = (cartEntry: ICartEntry | IBackendCartEntries): boolean => {
    if (!enableFoodwareItems || !cartEntry?.isExtra || !data?.FeatureFoodware?.items?.length) {
      return false;
    }

    return (
      data?.FeatureFoodware?.items.findIndex(item => {
        const isBackendCartEntry = 'lineId' in cartEntry;
        const cartEntryId = isBackendCartEntry ? cartEntry.sanityId : cartEntry._id;
        return item?._id === cartEntryId;
      }) > -1
    );
  };

  return {
    isFoodware,
    featureFoodware: data?.FeatureFoodware,
    loading,
  };
};

export default useFoodware;
