import { debounce, uniqBy } from 'lodash';
import React, { TouchEventHandler, useCallback, useMemo, useState } from 'react';

import { IRestaurantNode } from 'generated/rbi-graphql';
import { useIsDesktopBp } from 'hooks/breakpoints';
import { useGetFavoriteRestaurants } from 'hooks/use-get-favorite-restaurants';
import { useGetNearbyRestaurants } from 'hooks/use-get-nearby-restaurants';
import { useGetRecentRestaurants } from 'hooks/use-get-recent-restaurants';
import useMap from 'hooks/use-map';
import { IUseMapArgs } from 'hooks/use-map/types';
import { useGeolocation } from 'state/geolocation';

import useMarkers from '../../hooks/use-markers';
import { StoreLocatorMap } from '../../map';
import { StoreActions } from '../store-actions';
import { StoreCard } from '../store-card';
import { useStoreLocatorContext } from '../store-locator-provider';

import { MapContainer } from './map-view.styled';

const setupEvtListeners = (onDragEnd: TouchEventHandler) => ({
  dragend: debounce(onDragEnd, 200),
});

const MapViewStoreCard: React.VFC<{
  restaurant: IRestaurantNode;
}> = ({ restaurant }) => {
  return (
    <>
      <StoreCard restaurant={restaurant} />
      <StoreActions restaurant={restaurant} />
    </>
  );
};

export const MapView = () => {
  const isDesktop = useIsDesktopBp();

  const [selectedStore, setSelectedStore] = useState<IRestaurantNode | null>(null);
  const [hasPanned, setHasPanned] = useState(false);
  const { currentViewIsMap } = useStoreLocatorContext();
  const { restaurants: storesNearbyData } = useGetNearbyRestaurants();
  const { restaurants: storesFavoriteData } = useGetFavoriteRestaurants();
  const { restaurants: storesRecentData } = useGetRecentRestaurants();

  const { setCoordinatesManual, setManualAddress, activeCoordinates } = useGeolocation();

  const eventListeners: IUseMapArgs['eventListeners'] = useMemo(() => {
    return {
      ...setupEvtListeners(() => {
        setHasPanned(true);
      }),
      onPress: () => setSelectedStore(null),
    };
  }, []);

  const { center, createMarker, map, zoom, resetZoom, panTo } = useMap(
    activeCoordinates
      ? {
          eventListeners,
          position: activeCoordinates,
        }
      : { eventListeners }
  );

  const onPressButtonSearch = useCallback(() => {
    setHasPanned(false);

    setCoordinatesManual({ newCoords: center });
    setManualAddress('');
  }, [center, setCoordinatesManual, setManualAddress]);

  /**
   * TODO:
   *  - should we still include favs & recents in map view, if we have values?
   *  - added recents here bc idk why they weren't there in current implementation
   */
  const storesWithMarkers = useMemo(
    () => uniqBy([...storesNearbyData, ...storesFavoriteData, ...storesRecentData], '_id'),
    [storesFavoriteData, storesNearbyData, storesRecentData]
  );

  const onPress = useCallback(
    (store: IRestaurantNode) => {
      if (!store._id || store._id === selectedStore?._id) {
        return;
      }
      setSelectedStore(store);
    },
    [selectedStore?._id]
  );

  useMarkers({
    createMarker,
    storesFavs: storesFavoriteData,
    storesNearby: storesWithMarkers,
    activeStoreId: selectedStore?._id,
    onPress,
    panTo,
  });

  return (
    <MapContainer>
      <StoreLocatorMap
        map={map}
        currentViewIsMap={currentViewIsMap}
        hasPanned={hasPanned}
        isDesktop={isDesktop}
        onPressSearch={onPressButtonSearch}
        zoom={zoom}
        resetZoom={resetZoom}
      />
      {currentViewIsMap && selectedStore && <MapViewStoreCard restaurant={selectedStore} />}
    </MapContainer>
  );
};

export default MapView;
