import delv from 'dlv';
import React, { useCallback, useMemo } from 'react';

import { Box, Radio } from '@fhs-legacy/universal-components';
import { IPickerAspectUiPattern } from '@rbi-ctg/menu';
import { PickerAspectUiPattern, isUiPatternRadio } from 'state/menu/types';

import NameAndCalories from './name-and-calories';
import { PickerAspectOptionContainer } from './picker-aspect-option.styled';
import { IPickerAspectOptionProps } from './types';

const RadioButtonContainer = Box.withConfig<IPickerAspectUiPattern>({
  paddingTop: '$1',
});

const PickerAspectOption: React.FC<React.PropsWithChildren<IPickerAspectOptionProps>> = props => {
  const name = props.option?.name?.locale;
  const identifier = useMemo(() => props.option?.identifier, [props.option]);
  const uiPattern = delv(props, 'uiPattern', PickerAspectUiPattern.SELECTOR);

  const handleSelection = useCallback(
    (event: any) => {
      event.preventDefault();

      if (props.disabled) {
        return;
      }
      props.handleSelection(identifier);
    },
    [identifier, props]
  );

  return (
    <PickerAspectOptionContainer
      onPress={handleSelection}
      testID={`picker-aspect-option-${name}`}
      disabled={props.disabled}
    >
      <Box width={'full'}>
        <NameAndCalories
          name={name}
          calories={props.calories}
          uiPattern={uiPattern}
          selected={props.selected}
        />
      </Box>
      {isUiPatternRadio({ uiPattern }) && ( // TODO: RN - possibiliy remove? TH may not use radio pickers
        <RadioButtonContainer uiPattern={uiPattern}>
          <Radio isDisabled={props.disabled} value={props.selected ? identifier : ''} />
        </RadioButtonContainer>
      )}
    </PickerAspectOptionContainer>
  );
};

export default PickerAspectOption;
