import type {
  ICart,
  ICartEntry,
  ICartEntryDetailsLegacyOffer,
  ICartEntryDetailsOffer,
  ICartEntryDetailsReward,
  ICreateCartEntryParams,
} from './incentive-cart';
import { ICartEntryType } from './incentive-cart';

export * from './incentive-cart/types';

export type IInRestaurantIncentive =
  | ICartEntryDetailsReward['reward']
  | ICartEntryDetailsReward['engineReward']
  | ICartEntryDetailsLegacyOffer['offer']
  | ICartEntryDetailsOffer['offer'];

export interface IUseInRestaurantCart {
  inRestaurantCart: ICart;
  isInRestaurantCartEmpty: boolean;
  addInRestaurantCartEntry: (item: ICreateCartEntryParams) => ICartEntry | null;
  removeInRestaurantCartEntry: (cartEntry: ICartEntry) => void;
  updateInRestaurantCartEntryQuantity(cartEntry: ICartEntry, quantity: number): void;
  resetInRestaurantCart: () => void;
  clearInRestaurantCartAllRewards: () => void;
  existEntryTypeInRestaurantCart: (entryType: ICartEntryType) => boolean;
  lastModificationDate: Date | undefined;
  updateLastModificationDate: (date: Date) => void;
  removeTypeFromCart: (typeToRemove: ICartEntryType) => void;
}
