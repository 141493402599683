import * as React from 'react';
import { FC } from 'react';

import { Radio } from '@fhs-legacy/universal-components';
import { OnClick } from '@rbi-ctg/frontend';
import { LoyaltyOffer } from 'state/loyalty/types';

import {
  Container,
  Content,
  ImageContainer,
  RadioButtonWrapper,
  TitleText,
  TitleTextWrapper,
} from './modal-surprise-card.styled';

const ModalSurpriseCard: FC<
  React.PropsWithChildren<{
    offer: LoyaltyOffer;
    handleSelectOffer: OnClick;
  }>
> = ({ offer, handleSelectOffer }) => {
  const image = offer.localizedImage?.locale?.app;
  const offerName = offer.name?.localeRaw;
  const alt = offerName[0]?.children[0]?.text || 'Offer';

  if (!image || !offerName) {
    return null;
  }

  const selectOffer = () => {
    handleSelectOffer(offer);
  };

  return (
    <Container onPress={selectOffer}>
      <ImageContainer image={image} alt={alt} />
      <Content>
        <TitleTextWrapper>
          <TitleText content={offerName} />
        </TitleTextWrapper>
        <RadioButtonWrapper>
          <Radio
            value={offer._id}
            accessibilityLabel="Offer Card Radio Button"
            borderColor="black"
          />
        </RadioButtonWrapper>
      </Content>
    </Container>
  );
};

export default ModalSurpriseCard;
