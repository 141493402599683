import * as React from 'react';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { useSupportData } from 'hooks/use-support-data';

import { ErrorView } from '../error-view';
import { LoadingView } from '../loading-view';
import { InnerBox } from '../support-form-v2.styled';

import { SupportV2CategoriesView } from './support-v2-categories-view';

/**
 * Displays list of support category groups as links to their individual pages.
 */
const SupportV2CategoriesPage: FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const { supportCategoryGroups, isSupportDataLoading } = useSupportData();

  if (isSupportDataLoading) {
    return <LoadingView />;
  }

  if (!supportCategoryGroups || supportCategoryGroups.length === 0) {
    return <ErrorView message={formatMessage({ id: 'looksLikeWereExperiencingIssues' })} />;
  }

  const categoryGroups = supportCategoryGroups.map(group => ({
    _id: group?._id || '',
    name: group?.name?.locale ?? '',
  }));

  return (
    <InnerBox>
      <SupportV2CategoriesView categoryGroups={categoryGroups} />
    </InnerBox>
  );
};

export default SupportV2CategoriesPage;
