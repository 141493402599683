import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconYouTube = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M24 16.778V8.224S24 4.1 19.864 4.1H4.134S0 4.1 0 8.224v8.554S0 20.9 4.135 20.9h15.73S24 20.9 24 16.778Zm-7.34-4.265-7.858 4.6V7.91l7.858 4.601Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
