import { FieldProps } from 'formik';
import React from 'react';

import { Checkbox, FormControl, Text } from '@fhs-legacy/universal-components';

import { FormContainer, LabelContainer } from './styled';

export interface ICheckboxProps {
  label: React.ReactNode;
  errorMessage?: string;
}

export const FormikCheckbox: React.FC<React.PropsWithChildren<FieldProps & ICheckboxProps>> = ({
  form,
  field,
  ...props
}) => {
  const { label } = props;
  const { name } = field;
  const { setFieldValue } = form;
  const checked = !!form.values[name];

  const errorMessage =
    form.errors[name] && form.touched[name] ? (form.errors[name] as string) : undefined;
  return (
    <FormControl>
      <FormContainer>
        <Checkbox
          {...field}
          {...props}
          onChange={isSelected => setFieldValue(name, isSelected)}
          defaultIsChecked={checked}
        >
          {label && (
            <LabelContainer>
              <Text>{label}</Text>
            </LabelContainer>
          )}
        </Checkbox>
      </FormContainer>
      {errorMessage && (
        <Text pl="$3" fontSize="xs" color={Styles.color.error}>
          {errorMessage}
        </Text>
      )}
    </FormControl>
  );
};
