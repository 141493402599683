import React from 'react';
import { useIntl } from 'react-intl';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { CancellationPolicyText, Title, Wrapper } from './delivery-cancellation-policy.styled';

export default () => {
  const { formatMessage } = useIntl();
  const isEnabled = useFlag(LaunchDarklyFlag.ENABLE_DELIVERY_CANCELLATION_POLICY);
  if (!isEnabled) {
    return null;
  }
  return (
    <Wrapper>
      <Title>{formatMessage({ id: 'cancellationPolicyTitle' })}</Title>
      <CancellationPolicyText>{formatMessage({ id: 'cancellationPolicy' })}</CancellationPolicyText>
    </Wrapper>
  );
};
