import React, { FC } from 'react';

import { Box, Icon } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants, IActionButtonProps } from 'components/action-button';

interface IRefreshButtonProps extends IActionButtonProps {
  onPress: (ev: any) => void;
}

const Container = Box.withConfig({
  alignItems: 'center',
  flexDirection: 'column',
  width: '5/6',
  maxWidth: {
    desktop: Styles.layout.smallerSectionMaxWidth,
  },
});

export const RefreshButton: FC<React.PropsWithChildren<IRefreshButtonProps>> = ({
  children,
  onPress,
}) => {
  return (
    <Box alignItems="center" marginY="$4" width="full">
      <Container>
        <ActionButton
          fullWidth
          variant={ActionButtonVariants.OUTLINE}
          onPress={onPress}
          startIcon={<Icon variant="refresh" color={Styles.color.primary} aria-hidden />}
        >
          {children}
        </ActionButton>
      </Container>
    </Box>
  );
};
