import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconError = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        fill="currentColor"
        d="M11 15.1h2v2h-2v-2Zm0-8h2v6h-2v-6Zm.99-5C6.47 2.1 2 6.58 2 12.1s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10s-4.48-10-10.01-10Zm.01 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8Z"
      />
    </Svg>
  );
};
