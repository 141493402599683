import { useResendVerificationEmailMutation } from 'generated/graphql-gateway';
import { platform, welcomeEmailDomain } from 'utils/environment';
import logger from 'utils/logger';

const useUserVerification = () => {
  const [resendVerificationEmail, { loading }] = useResendVerificationEmailMutation();
  const sendVerificationEmail = async () => {
    try {
      await resendVerificationEmail({
        variables: {
          input: {
            stage: welcomeEmailDomain(),
            platform: platform(),
          },
        },
      });
    } catch (error) {
      logger.error({ error, message: 'Failed to resend verification link' });
    }
  };
  return {
    loading,
    sendVerificationEmail,
  };
};

export default useUserVerification;
