import React from 'react';
import { useIntl } from 'react-intl';

import { Icon, PressableProps, Text } from '@fhs-legacy/universal-components';
import { primitive } from 'styles/constants/primitives';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import { IconContainer, PlusIconContainer, StyledPressable } from './loyalty-promo-code-cta.styled';

interface Props extends PressableProps {
  buttonLabel?: string;
}
export const LoyaltyPromoCodeCTA = ({ onPress, buttonLabel, style }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <StyledPressable style={style} onPress={onPress}>
      <IconContainer>
        <Icon
          variant="offer"
          color={primitive.$white}
          {...hiddenAccessibilityPlatformProps}
          size={6}
        />
      </IconContainer>
      <Text fontWeight="bold">{buttonLabel ?? formatMessage({ id: 'promoCodeCTA' })}</Text>
      <PlusIconContainer>
        <Icon variant="add" {...hiddenAccessibilityPlatformProps} />
      </PlusIconContainer>
    </StyledPressable>
  );
};
