import React from 'react';

import { Icon, IconProps } from '@fhs-legacy/universal-components';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

type StoreCardIconProps = Pick<IconProps, 'variant' | 'color'>;

export const StoreCardIcon: React.VFC<StoreCardIconProps> = ({ variant, color }) => {
  return <Icon color={color} size="6" variant={variant} {...hiddenAccessibilityPlatformProps} />;
};
