import React, { createContext, useCallback, useContext } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { ICartEntry, MenuObject, SanityMenuObject } from '@rbi-ctg/menu';
import { ItemAvailabilityStatus } from 'enums/menu';
import { useMainMenuContext } from 'state/main-menu';
import { isCombo } from 'utils/menu';
import { ModifierComponentStyle, isDefaultOrLess } from 'utils/menu/modifiers';

import { IPriceItemOptionModifierInput } from './hooks/use-pricing-function';

export type ItemAvailability = {
  availabilityStatus: ItemAvailabilityStatus;
  data?: MenuObject;
};

export type IMenuOptionsContext = {
  priceForItemOptionModifier: (item: IPriceItemOptionModifierInput) => number;
  filterForAvailability: (
    menuData: SanityMenuObject | SanityMenuObject[]
  ) => MenuObject | MenuObject[] | null;
  pricingFunction: (item: ICartEntry) => number;
};

export interface IState {
  data: MenuObject | null;
  loading: boolean;
}

export const MenuOptionsContext = createContext<IMenuOptionsContext>({} as IMenuOptionsContext);
export const useMenuOptionsContext = () => useContext(MenuOptionsContext);

export default MenuOptionsContext.Consumer;

export const MenuOptionsProvider = ({ children }: IBaseProps) => {
  const { getPricingAndAvailability } = useMainMenuContext();

  const pricingFunction = useCallback(
    (item: ICartEntry, quantity?: number) => {
      const { price } = getPricingAndAvailability(item._id) || {};

      return Number(price?.default) * (quantity || 1) || 0;
    },
    [getPricingAndAvailability]
  );

  const filterForAvailability = useCallback(
    (item: SanityMenuObject[] | SanityMenuObject) => {
      if (!Array.isArray(item)) {
        return getPricingAndAvailability(item._id)?.isAvailable ? (item as MenuObject) : null;
      }

      return item.filter(i => getPricingAndAvailability(i._id)?.isAvailable) as MenuObject[];
    },
    [getPricingAndAvailability]
  );

  const priceForItemOptionModifier = useCallback(
    ({ item, itemOption, modifier }: IPriceItemOptionModifierInput): number => {
      const itemId = modifier?._key;

      if (!item || !itemOption || !modifier || isCombo(item)) {
        return 0;
      }

      if (
        !itemOption.componentStyle ||
        itemOption.componentStyle === ModifierComponentStyle.Selector
      ) {
        if (itemOption.allowMultipleSelections) {
          // @todo implement pricing for allowMultipleSelections
          return 0;
        }

        if (modifier.default && !itemOption.upsellModifier) {
          return 0;
        }

        return getPricingAndAvailability(modifier._key)?.price?.default || 0;
      }

      // we do not charge the user for modifiers that are
      // default or any modifier with a smaller or equal
      // multiplier to the default
      const defaultOrLess = isDefaultOrLess(itemOption, modifier);

      if (defaultOrLess && !itemOption.upsellModifier) {
        return 0;
      }

      if (!itemId) {
        return 0;
      }

      return getPricingAndAvailability(itemId)?.price?.default || 0;
    },
    [getPricingAndAvailability]
  );

  return (
    <MenuOptionsContext.Provider
      value={{
        pricingFunction,
        filterForAvailability,
        priceForItemOptionModifier,
      }}
    >
      {children}
    </MenuOptionsContext.Provider>
  );
};
