import { useLocalSearchParams } from 'expo-router';
import { useCallback } from 'react';

import { appendObjectToQueryString } from 'hooks/navigation/utils';

const reloadParam = 'error-reload';

export function useReload(onRequestReload: () => void) {
  const params = useLocalSearchParams<{ 'error-reload': string }>();

  return useCallback(() => {
    onRequestReload?.();
    // Send the user to the home page to avoid a reload loop
    if (params[reloadParam]) {
      window.location.href = '/';
      return;
    }

    params[reloadParam] = '1';
    // This will fire a reload
    window.location.href = appendObjectToQueryString('/', params);
  }, [params, onRequestReload]);
}
